export default ({ size, color = '#000' } = {}) => /* html */`
<svg width="${ size }" height="${ size }" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.4844 38.2162H3.43819C2.59324 38.2165 1.80842 37.7791 1.36414 37.0604C0.919862 36.3417 0.879501 35.4441 1.25748 34.6884L2.21946 33.3413V6.52922L1.25748 5.18212C0.879501 4.42641 0.919862 3.52887 1.36414 2.81014C1.80842 2.09141 2.59324 1.65404 3.43819 1.6543H27.8128C28.6578 1.65404 29.4426 2.09141 29.8869 2.81014C30.3311 3.52887 30.3715 4.42641 29.9935 5.18212L29.0315 6.52922V17.7511" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.582 13.6582C11.7816 14.1403 11.1646 14.8752 10.8283 15.7469C10.2009 15.8006 9.59745 16.0128 9.07456 16.3637C7.54278 17.3848 8.05337 19.9378 8.05337 19.9378C8.5382 20.0215 9.03193 20.0409 9.52183 19.9957" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.1 24.5332L14.6106 25.0438" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.1629 24.5332L16.6523 25.0438" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.6929 13.8111C18.6929 12.9648 17.3211 12.2793 15.6293 12.2793C13.9376 12.2793 12.5658 12.9648 12.5658 13.8111C12.5658 14.8323 14.4039 14.3217 14.4039 14.3217C14.4165 14.6252 14.5568 14.9094 14.7902 15.1039C15.0237 15.2984 15.3284 15.3852 15.6293 15.3428C15.9302 15.3852 16.235 15.2984 16.4684 15.1039C16.7018 14.9094 16.8421 14.6252 16.8547 14.3217C16.8547 14.3217 18.6929 14.8323 18.6929 13.8111Z" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.8798 14.1937L11.1173 13.8111C10.4693 13.4593 9.93724 12.9273 9.5855 12.2793C9.5855 12.2793 8.56432 13.8111 9.07491 14.8323C9.29405 15.2538 9.59708 15.6261 9.96538 15.9263" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.9712 19.5742C19.9712 28.1079 16.3181 27.5946 16.0765 27.5946H15.186C14.9436 27.5946 11.2912 28.1052 11.2912 19.5742" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.6797 13.6582C19.4801 14.1403 20.0971 14.8752 20.4334 15.7469C21.0608 15.8006 21.6642 16.0128 22.1871 16.3637C23.7189 17.3848 23.2083 19.9378 23.2083 19.9378C22.7235 20.0215 22.2297 20.0409 21.7398 19.9957" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.5722 23.7502L19.2141 23.5112C17.0163 22.1538 14.2404 22.1538 12.0427 23.5112L11.6846 23.7502" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.3789 14.1937L20.1441 13.8111C20.7921 13.4593 21.3242 12.9273 21.6759 12.2793C21.6759 12.2793 22.6971 13.8111 22.1865 14.8323C21.9674 15.2538 21.6643 15.6261 21.296 15.9263" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.3313 17.3857C13.4723 17.3857 13.5866 17.5 13.5866 17.641" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.0777 17.641C13.0777 17.5 13.192 17.3857 13.333 17.3857" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.333 17.894C13.192 17.894 13.0777 17.7797 13.0777 17.6387" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.5866 17.6387C13.5866 17.7797 13.4723 17.894 13.3313 17.894" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.9298 17.3857C18.0708 17.3857 18.1851 17.5 18.1851 17.641" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.6718 17.641C17.6718 17.5 17.7861 17.3857 17.927 17.3857" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.927 17.894C17.7861 17.894 17.6718 17.7797 17.6718 17.6387" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.1851 17.6387C18.1851 17.7797 18.0708 17.894 17.9298 17.894" stroke="${ color }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.485 34.8575C19.0373 34.8575 19.485 34.4098 19.485 33.8575C19.485 33.3052 19.0373 32.8575 18.485 32.8575V34.8575ZM2.38818 8.02051H29.1271V6.02051H2.38818V8.02051ZM18.485 32.8575H2.38818V34.8575H18.485V32.8575Z" fill="${ color }"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.9985 35.171C37.0398 37.9302 35.5915 40.4976 33.2086 41.8893C30.8257 43.281 27.8779 43.281 25.495 41.8893C23.1121 40.4976 21.6637 37.9302 21.705 35.171C21.705 28.6166 28.2594 20.9707 29.3509 20.9707C30.4425 20.9707 36.9985 28.6166 36.9985 35.171Z" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.0946 38.8203C26.675 38.8203 25.5243 37.6696 25.5243 36.25" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`
