import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { lighten } from '@material-ui/core/styles/colorManipulator'

import { polygonToSvg } from '@smartcoop/utils/maps'

const PolygonToSvg = (props) => {

  const { shape, color, width, style } = props

  const svgDeets = useMemo(
    () => polygonToSvg(shape),
    [shape]
  )

  const realSize = useMemo(
    () => {
      let w = width
      let h = (width * svgDeets.height) / svgDeets.width

      if (h > width) {
        w = (w * width) / h
        h = width
      }

      return {
        width: w,
        height: h
      }
    },
    [width, svgDeets.height, svgDeets.width]
  )

  return (
    <div style={ {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width,
      ...style
    } }
    >
      <svg
        width={ realSize.width }
        height={ realSize.height }
        viewBox={ svgDeets.viewbox }
      >
        <path
          stroke={ color }
          fill={ lighten(color, 0.85) }
          d={ svgDeets.path }
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </div>
  )
}

PolygonToSvg.propTypes = {
  shape: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  })),
  color: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

PolygonToSvg.defaultProps = {
  shape: [],
  color: '#080808',
  style: {},
  width: 100
}

export default PolygonToSvg
