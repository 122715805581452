import React, { useCallback } from 'react'
// eslint-disable-next-line import/no-unresolved
import Carousel from 'react-elastic-carousel'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import includes from 'lodash/includes'

import { useDialog } from '@smartcoop/dialog'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import VideoModal from '@smartcoop/web-containers/modals/VideoModal'

import { Item, Container } from './styles'

const ElasticCarousel = (props) => {
  const { createDialog } = useDialog()
  const { breakPoints, items, viewType, onClickAction } = props

  const onButtonClick = useCallback(
    (data) => {
      createDialog({
        id: 'video-modal',
        Component: VideoModal,
        props: {
          // eslint-disable-next-line no-nested-ternary
          cover: includes(data?.coverUrl, 'youtu.be') ?
            `//img.youtube.com/vi/${ youtubeShortParser(data?.coverUrl) }/0.jpg`
            : (includes(data?.coverUrl, 'youtube')
              ? `//img.youtube.com/vi/${ youtubeParser(data?.coverUrl) }/0.jpg`
              : data.coverUrl ),
          viewType,
          open: true,
          fileUrl: data.fileUrl,
          name: data.name,
          accessNumber: data.accessNumber,
          liked: data?.liked,
          likes: data?.likes,
          onPressLike: (like) => onClickAction({ fileId: data?.id, accessDate: moment().format(momentBackDateFormat), like })
        }
      })
      onClickAction({ fileId: data?.id, accessDate: moment().format(momentBackDateFormat) })
    },
    [createDialog, onClickAction, viewType]
  )

  const youtubeShortParser = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = url.match(regExp)
    return (match&&match[7].length === 11)? match[7] : false
  }
  const youtubeParser = (url) => {
    const regExp = /^.*((youtube\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = url.match(regExp)
    return (match&&match[7].length === 11)? match[7] : false
  }

  return (
    <Container>
      <Carousel breakPoints={ breakPoints }>
        {items.map((data) => (
          <Item
            viewType={ viewType }
            key={ data.id }
            onClick={ () => {
              onButtonClick(data)
            } }
          >
            <img
              src={
                // eslint-disable-next-line no-nested-ternary
                includes(data?.coverUrl, 'youtu.be') ?
                  `//img.youtube.com/vi/${ youtubeShortParser(data?.coverUrl) }/0.jpg`
                  : (includes(data?.coverUrl, 'youtube')
                    ? `//img.youtube.com/vi/${ youtubeParser(data?.coverUrl) }/0.jpg`
                    : data.coverUrl )
              }
              alt="a"
            />
          </Item>
        ))}
      </Carousel>
    </Container>
  )
}

ElasticCarousel.propTypes = {
  breakPoints: PropTypes.array,
  items: PropTypes.array,
  onClickAction: PropTypes.func,
  viewType: PropTypes.oneOf(['portrait', 'landscape'])
}

ElasticCarousel.defaultProps = {
  breakPoints: [],
  items: [],
  onClickAction: () => {},
  viewType: 'landscape'
}

export default ElasticCarousel
