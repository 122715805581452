import filter from'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import toString from 'lodash/toString'

import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'

export const selectOfflineAnimals = state => {
  const animalsFilter = filter(state.offlineAnimalUserData.animals, item => !!item.slaughterDate === false)
  return animalsFilter
}

export const selectOfflineGrupedAnimals = state => {
  const property = selectCurrentProperty(state)
  const animals = selectOfflineAnimals(state)
  const status = selectOfflineAnimalStatus(state)
  const grupedAnimals = []
  map(animals, item => {
    if(item?.lot?.propertyId === property?.id) {
      const animalStatus = find(status, data => toString(data.id) === toString(item.statusId))
      const animal = {
        ...item,
        animalStatus
      }
      grupedAnimals.push(animal)
    }
  })
  return grupedAnimals
}

export const selectOfflineBulls = state => state.offlineAnimalUserData.bulls

export const selectOfflineBreeds = state => state.offlineAnimalUserData.breeds

export const selectOfflineSlaughterReasons = state => state.offlineAnimalUserData.slaughterReasons

export const selectOfflineLots = state => {
  const property = selectCurrentProperty(state)
  const currentLots = find(state.offlineAnimalUserData.lots, item => item.propertyId === property.id)
  return currentLots?.lots
}

export const selectOfflineAnimalStatus = state => state.offlineAnimalUserData.status

export const selectOfflineInseminationTypes = state => state.offlineAnimalUserData.inseminationTypes

export const selectOfflineDiagnosticTypes = state => state.offlineAnimalUserData.diagnosticTypes

export const selectOfflineAnimalStatusOptions = state => map(state.offlineAnimalUserData.status, item => ({
  label: item.name,
  value: toString(item.id)
}))

export const selectOfflineUnavailableEarrings = state => state.offlineAnimalUserData.unavailaleEarrings

export const selectOfflineInsemination = state => {
  const currentInseminations = []
  const currentAnimal = selectCurrentAnimal(state)
  const dataInseminations = find(state.offlineAnimalUserData.animals, item => item.id === currentAnimal.id)
  const inseminations = dataInseminations?.inseminations
  if(!isEmpty(inseminations)) {
    map(inseminations, data => {
      const bull = find(state.offlineAnimalUserData.bulls, item => item.id === data.bullId)
      currentInseminations.push({ ...data, animal: currentAnimal, bull })
    })
  }
  return currentInseminations
}

export const selectOfflineProtocols = state => {
  const currentAnimal = selectCurrentAnimal(state)
  const animal = find(state.offlineAnimalUserData.animals, item => item.id === currentAnimal.id)
  return map(animal?.protocols, data => ({ ...data, animal: currentAnimal }))
}

export const selectOfflineDiagnostics = state => {
  const currentAnimal = selectCurrentAnimal(state)
  const animal = find(state.offlineAnimalUserData.animals, item => item.id === currentAnimal.id)
  return map(animal?.diagnostics, data => ({ ...data, animal: currentAnimal, bull: find(state.offlineAnimalUserData.bulls, item => item.id === data.bullId) }))
}

export const selectOfflineObservations = state => {
  const currentAnimal = selectCurrentAnimal(state)
  const animal = find(state.offlineAnimalUserData.animals, item => item.id === currentAnimal.id)
  return map(animal?.observations, data => ({ ...data, animal: currentAnimal }))
}

export const selectOfflineDrying = state => {
  const currentDrying = []
  const currentAnimal = selectCurrentAnimal(state)
  const dataDrying = find(state.offlineAnimalUserData.animals, item => item.id === currentAnimal.id)
  const drying = dataDrying?.drying
  if(!isEmpty(drying)) {
    map(drying, data => {
      const bull = find(state.offlineAnimalUserData.bulls, item => item.id === data.bullId)
      currentDrying.push({ ...data, animal: currentAnimal, bull })
    })
  }
  return currentDrying
}

export const selectOfflinePev = state => {
  const currentPev = []
  const currentAnimal = selectCurrentAnimal(state)
  const dataVoluntaryWaitingPeriod = find(state.offlineAnimalUserData.animals, item => item.id === currentAnimal.id)
  const voluntaryWaitingPeriod = dataVoluntaryWaitingPeriod?.voluntaryWaitingPeriod
  if(!isEmpty(voluntaryWaitingPeriod)) {
    map(voluntaryWaitingPeriod, data => {
      const bull = find(state.offlineAnimalUserData.bulls, item => item.id === data.bullId)
      currentPev.push({ ...data, animal: currentAnimal, bull })
    })
  }
  return currentPev
}

export const selectOfflineBirths = state => {
  const currentAnimal = selectCurrentAnimal(state)
  const data = find(state.offlineAnimalUserData.animals, item => item.id === currentAnimal.id)
  const births = data?.births
  return births
}

export const selectOfflineMilkControls = state => {
  const currentProperty = selectCurrentProperty(state)
  const data = filter(state.offlineAnimalUserData.milkControls, item => item.propertyId === currentProperty.id)
  return data
}

export const selectOfflineLotsOptions = state => {
  const lots = selectOfflineLots(state)

  const lotsOptions = map(lots, (item) => ({
    label: item?.name,
    value: item?.id
  }))

  return lotsOptions
}

export const selectOfflineSlaughterReasonsOptions = state => {
  const slaughterReasons = selectOfflineSlaughterReasons(state)

  const slaughterReasonsOptions = map(slaughterReasons, (item) => ({
    description: item?.description,
    id: item?.id
  }))

  return slaughterReasonsOptions
}
