import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 10px;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

export const LeftHeader = styled(Row)`
  padding-left: 20px;
  flex: 0 0 auto;
`

export const Divider = styled.hr`
  border: none;
  border-bottom: 0.935252px solid #E0E0E0;
  margin: 0;
  width: 100%;
`

export const FlexDiv = styled.div`
display: flex;
`
