import React from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import first from 'lodash/first'

import { Avatar } from '@material-ui/core'

import { database } from '@smartcoop/database'
import { profilePictureService } from '@smartcoop/database/services/profilePictureService'
import CheckboxCard from '@smartcoop/web-components/FilteredCheckboxGroup/CheckboxCard'

import { Card } from './styles'

const ContactCard = ({ contact, profilePictures, selected, handleSelect }) => {
  const profilePicture = first(profilePictures)

  return (
    <Card onClick={ handleSelect }>
      <CheckboxCard
        style={ { backgroundColor: 'transparent', margin: 0, paddingLeft: 4 } }
        onClick={ event => event.stopPropagation() }
        checked={ selected }
        onChange={ handleSelect }
      />
      <Avatar src={ profilePicture?.source } size={ 25 } alt={ contact.chatNickname } />
      <span>
        {contact.chatNickname}
      </span>
    </Card>
  )
}

ContactCard.propTypes = {
  contact: PropTypes.object.isRequired,
  profilePictures: PropTypes.array.isRequired,
  selected: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired
}

const enhance = withObservables(['userId'], ({ userId }) => ({
  profilePictures: profilePictureService(database).observeProfilePictureByUserId(userId)
}))

const EnhancedContactCard = enhance(ContactCard)

export default EnhancedContactCard
