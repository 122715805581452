import React, { useMemo } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import I18n, { useT } from '@smartcoop/i18n'
import { circleWithBorder } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import DataTable from '@smartcoop/web-components/DataTable'
import DemandStatus from '@smartcoop/web-components/DemandStatus'
import Icon from '@smartcoop/web-components/Icon'
import Modal from '@smartcoop/web-components/Modal'
import SupplierQuotationStatus from '@smartcoop/web-components/SupplierQuotationStatus'
import DemandStatusFragment from '@smartcoop/web-containers/fragments/shoppingPlatform/DemandStatusFragment/DemandStatusFragment'
import {
  Title
} from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { RowContainer } from './styles'

const HelpModal = (props) => {
  const {
    open,
    statusDescriptions,
    isSupplier,
    id
  } = props

  const t = useT()

  const steps = [
    {
      label: t('completed'),
      color: colors.green
    },
    {
      label: t('on going'),
      color: colors.yellow
    },
    {
      label: t('interrupted'),
      color: colors.red
    },
    {
      label: t('pending'),
      color: colors.white
    }
  ]

  const columns = useMemo(
    () => [
      {
        title: t('status'),
        field: 'statusName',
        render: (row) => isSupplier ? (
          <SupplierQuotationStatus
            style={ { padding: '10px 15px', fontSize: 14 } }
            slug={ row.slug }
          />
        ) : (
          <DemandStatus
            style={ { padding: '10px 15px', fontSize: 14 } }
            slug={ row.slug }
          />
        )
      },
      {
        title: t('description'),
        field: 'description'
      }
    ], [isSupplier, t]
  )
  return (
    <Modal id={ id } open={ open } title={ t('help') } maxWidth="md" >
      <Title style={ { fontSize: 16, fontWeight: 600, marginBottom: 20 } }>
        <I18n>Acompanhe as etapas do processo de compra acessando a demanda e visualizando o fluxo ilustrado abaixo: </I18n>
      </Title>
      <RowContainer>
        {steps.map(({ label, color }) => (
          <Step key={ label }>
            <StepLabel
              icon={ <Icon icon={ circleWithBorder } color={ color } /> }
              style={ { color } }
              active
            >
              <Title style={ { fontSize: 16, fontWeight: 600, marginBottom: 0 } }>{label}</Title>
            </StepLabel>
          </Step>
        ))}
      </RowContainer>
      <RowContainer>
        <DemandStatusFragment
          openingDate={ moment() }
          closingDate={ moment().add(2, 'days') }
          proposalDeadlineDate={ moment().add(4, 'days') }
          organizationDecisionLimitDate={ moment().add(6, 'days') }
          deliveryDate={ moment().add(15, 'days') }
          status="aguardando_resposta"
        />
      </RowContainer>
      <DataTable
        data={ statusDescriptions }
        columns={ columns }
        pageSize={ 20 }
        id="help-modal-table"
      />
    </Modal>
  )
}

HelpModal.propTypes = {
  open: PropTypes.bool.isRequired,
  statusDescriptions: PropTypes.array.isRequired,
  isSupplier: PropTypes.bool,
  id: PropTypes.string.isRequired
}

HelpModal.defaultProps = {
  isSupplier: false
}

export default HelpModal
