import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import float from '@smartcoop/forms/validators/float.validator'
import minNumberValue from '@smartcoop/forms/validators/minNumberValue.validator'
import required from '@smartcoop/forms/validators/required.validator'

const propertyIdentification = ({ t }) => Yup.object().shape({
  name: flow(
    required({ t })
  )(Yup.string()),

  totalArea: flow(
    float({ t }),
    required({ t }),
    minNumberValue({ t, field: t('total area') })
  )(Yup.string()),

  state: flow(
    required({ t })
  )(Yup.string()),

  city: flow(
    required({ t })
  )(Yup.string()),

  addressComplement: Yup.string()
})

export default propertyIdentification
