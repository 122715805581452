import React, { useState, useCallback, useEffect } from 'react'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { send } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { colors } from '@smartcoop/styles'
import { AllowedAudioTypesEnum, AllowedDocumentTypesEnum, AllowedImageTypesEnum, AllowedSizeFile, AllowedVideoTypesEnum } from '@smartcoop/utils/constants'
import Icon from '@smartcoop/web-components/Icon'

import AttachButton from '../../../components/AttachButton'
import AudioRecorder from '../../../components/AudioRecorder/AudioRecorder'
import Textarea from '../../../components/Textarea'
import ConfirmSendModal from '../../../modals/ConfirmSendModal/ConfirmSendModal'
import { ButtonGroup, FooterContainer } from './styles'

const TYPE_ALIAS = {
  'image': AllowedImageTypesEnum,
  'document': AllowedDocumentTypesEnum,
  'video': AllowedVideoTypesEnum,
  'audio': AllowedAudioTypesEnum
}

const ChatFooter = ({ onSendMessage, onSendAudio, onUploadFile, relationalId, blockFields }) => {
  const [message, setMessage] = useState()

  const t = useT()
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()

  const onSubmitMessage = useCallback(() => {
    if (message) {
      onSendMessage(message)
      setMessage('')
    }
  }, [message, onSendMessage])

  useEffect(() => {
    setMessage('')
  }, [relationalId])

  const convertFileListToArray = files => {
    const fileList = []

    for (const file of files) {
      fileList.push(file)
    }

    return fileList
  }

  const validateFileFormat = (files, type) => {
    const fileList = convertFileListToArray(files)

    const validFiles = fileList.filter(file => (
      file.size <= AllowedSizeFile && ((TYPE_ALIAS[type] || []).includes(file.type))
    ))
    const invalidFilesSize = fileList.filter(file => file.size > AllowedSizeFile)
    const invalidFilesType = fileList.filter(file => (!(TYPE_ALIAS[type] || []).includes(file.type)))

    if (invalidFilesSize.length) {
      const filesName = invalidFilesSize.map(item => item.name).join(', ')
      snackbar.warning(t('files {this} exceeded the maximum upload size of 16MB', { this: filesName }))
    }

    if (invalidFilesType.length) {
      const filesName = invalidFilesType.map(item => item.name).join(', ')
      snackbar.warning(t('the file format {this} is not supported', { this: filesName }))
    }

    return validFiles
  }

  const handleSendFiles = async (files, type = 'image') => {
    const validFiles = validateFileFormat(files, type)

    if (validFiles.length) {
      createDialog({
        id: 'confirm-send-files',
        Component: ConfirmSendModal,
        props: {
          files: validFiles,
          handleSubmit: () => onUploadFile(validFiles, type)
        }
      })
    }
  }


  return (
    <FooterContainer>
      <Textarea
        maxLength="5000"
        placeholder={ t('message') }
        onSubmit={ onSubmitMessage }
        onChange={ value => setMessage(value) }
        value={ message }
        disabled={ blockFields }
      />

      <ButtonGroup>
        <AttachButton onUploadFile={ handleSendFiles } disabled={ blockFields } />
        { message ? (
          <button type="button" onClick={ onSubmitMessage } disabled={ blockFields }>
            <Icon icon={ send } color={ colors.primary } size={ 25 } />
          </button>
        ) : (
          <AudioRecorder
            onRecordingComplete={ onSendAudio }
            disabled={ blockFields }
          />
        )}
      </ButtonGroup>
    </FooterContainer>
  )
}

ChatFooter.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
  onSendAudio: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  relationalId: PropTypes.string.isRequired,
  blockFields: PropTypes.bool.isRequired
}

export default ChatFooter
