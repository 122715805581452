import React, { useCallback, useRef } from 'react'

import PropTypes from 'prop-types'

import cepMask from '@smartcoop/forms/masks/cep.mask'
import { searchCep } from '@smartcoop/services/apis/brasilApi'
import TextField from '@smartcoop/web-components/TextField'

const InputCep = (props) => {
  const { onChange, onAddressChange, ...otherProps } = props

  const fieldRef = useRef(null)

  const handleChange = useCallback(
    async (e) => {
      onChange(e)
      if (e.target.value.length === 9) {
        try {
          const address = await searchCep(e.target.value)
          onAddressChange(address)
        } catch (err) {
          fieldRef.current.setCustomError(err.message)
        }
      } else if (fieldRef.current.customError) {
        fieldRef.current.setCustomError(null)
      }
    },
    [onAddressChange, onChange]
  )

  return (
    <TextField
      ref={ fieldRef }
      { ...otherProps }
      type="tel"
      setMask={ cepMask }
      onChange={ handleChange }
    />
  )
}

InputCep.propTypes = {
  onChange: PropTypes.func,
  onAddressChange: PropTypes.func
}

InputCep.defaultProps = {
  onChange: () => {},
  onAddressChange: () => {}
}

export default InputCep
