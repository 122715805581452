import styled from 'styled-components'

import Button from '@smartcoop/web-components/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 32px 30px 23px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 3;
  flex-direction: row;
  margin-bottom: 22px;
`

export const TabButton = styled(Button)`
  flex: none;
  white-space: nowrap;
  margin-right: 12px;
`

export const inputSearchTheme = (theme) => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiFormControl: {
      ...theme.overrides?.MuiFormControl,
      root: {
        ...theme.overrides?.MuiFormControl?.root,
        marginBottom: '0 !important'
      }
    },
    MuiFormHelperText: {
      ...theme.overrides?.MuiFormHelperText,
      root: {
        ...theme.overrides?.MuiFormHelperText?.root,
        marginTop: '0 !important'
      }
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff'
      }
    }
  }
})

export const UpperRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  max-width: 30px;
  margin-top: 20px;
  align-items: center;
`
