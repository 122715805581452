/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState, useMemo } from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import InputNumber from '@smartcoop/web-components/InputNumber'
import InputText from '@smartcoop/web-components/InputText'

const CheckboxTreeItemInput = (props) => {
  const {
    creatableInput,
    onCreateOption
  } = props

  const [value, setValue] = useState()

  const onPress = useCallback(
    (event) => {
      if(event.keyCode === 13 && !isEmpty(value)) {
        event.preventDefault()
        event.stopPropagation()
        onCreateOption(value)
        setValue('')
      }
    },
    [onCreateOption, value]
  )

  const Input = useMemo(() => {
    switch(creatableInput.type) {
      case 'inputNumber':
        return InputNumber

      default:
        return InputText

    }
  }, [creatableInput])

  const handleChange = useCallback(
    (event) => setValue(event.target.value),
    []
  )

  return (
    <Input
      detached
      label={ creatableInput.label }
      name={ creatableInput.name }
      onKeyDown={ onPress }
      value={ value }
      onChange={ handleChange }
      style={ {
        marginBottom: 0,
        marginLeft: 19,
        ...(creatableInput.style || {})
      } }
      { ...creatableInput }
    />
  )
}

CheckboxTreeItemInput.propTypes = {
  creatableInput: PropTypes.object.isRequired,
  onCreateOption: PropTypes.func.isRequired
}

export default CheckboxTreeItemInput
