import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import number from '@smartcoop/forms/validators/number.validator'

const irrigationCropManagementSchema = ({ t }) => Yup.object().shape({
  irrigationPlates: flow(
    number({ t })
  )(Yup.string()),

  irrigationFinalDate: flow(
    date({ t })
  )(Yup.string().nullable()),

  flowRate: flow()(Yup.string()),

  typeOfIrrigation: Yup.string()
})

export default irrigationCropManagementSchema
