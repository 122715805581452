import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'

import withObservables from '@nozbe/with-observables'
import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import Grid from '@material-ui/core/Grid'

import { animalService } from '@smartcoop/database/services/animalService'
import { database } from '@smartcoop/database/web-database'
import registerDiagnosisActionsSchema from '@smartcoop/forms/schemas/dairyFarm/registerDiagnosisActions.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getAnimalPregnancyDiagnosticsTypes } from '@smartcoop/services/apis/smartcoopApi/resources/animalPregnancyDiagnostics'
import { selectCurrentInsemination } from '@smartcoop/stores/insemination/selectorInsemination'
import { colors } from '@smartcoop/styles'
import { AnimalStatusCode } from '@smartcoop/utils/constants'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'

import {
  Container,
  FormContainer,
  ButtonsContainer,
  Item
} from './styles'

const RegisterDiagnosisActionsForm = (props) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel,
    animalsList,
    formRef
  } = props

  const t = useT()
  const currentInsemination = useSelector(selectCurrentInsemination)

  const [selectedAnimals, setSelectedAnimals] = useState([])
  const [diagnosisTypes, setDiagnosisTypes] = useState([])


  const handleSubmit = useCallback(
    (data) => {
      onSubmit({ ...data })
    },
    [onSubmit]
  )

  useEffect(() => {
    if(isEmpty(selectedAnimals)) {
      setDiagnosisTypes([])
    }
    if(selectedAnimals?.length === 1) {
      const getDiagnosisTypes = async () => {
        const { data: { data } } = await getAnimalPregnancyDiagnosticsTypes({} ,{ animalId: selectedAnimals?.[0]?.animalId || defaultValues?.animalId })
        setDiagnosisTypes(map(data, item => ({ label: item?.typeName, value: item?.id })))
      }
      getDiagnosisTypes()
    }
  }, [defaultValues, selectedAnimals])

  const animals = useMemo(() => {
    let filteredAnimals = filter(map(animalsList, item => item?._raw), item => (item?.category === 'vaca' ||
      item?.category === 'novilha') &&
      (toString(item?.statusId) === AnimalStatusCode.INSEMINADA ||
        toString(item?.statusId) === AnimalStatusCode.INSEMINADA_A_CONFIRMAR ||
        toString(item?.statusId) === AnimalStatusCode.PRENHA))

    if(!isEmpty(selectedAnimals)) {
      filteredAnimals = filter([...filteredAnimals], item => toString(item?.statusId) === toString(selectedAnimals?.[0]?.statusId))
    }
    return orderBy(map(filteredAnimals, item => ({ label: item?.earringCode, value: item?.animalId })), item => isNaN(toNumber(item?.label)) ? item?.label : toNumber(item?.label))
  }, [animalsList, selectedAnimals])

  useEffect(() => {
    setSelectedAnimals(filter(map(animalsList, item => item?._raw), item => includes([defaultValues?.animalId], item?.animalId)))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerDiagnosisActionsSchema }
        onSubmit={ handleSubmit }
        schemaProps={ { isEditing: !!defaultValues?.result } }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <InputSelect
              label={ t('earrings') }
              name="animalsId"
              options={ animals }
              multiple
              defaultValue={ [defaultValues?.animalId] }
              disabled={ !!defaultValues?.id }
              onChange={ ({ target : { value } }) => {
                setSelectedAnimals(filter(map(animalsList, item => item?._raw), item => includes(value, item?.animalId)))
              } }
              checkBoxSelectAll={ !isEmpty(selectedAnimals) }
              order={ false }
            />
            <Item>
              <InputSelect
                label={ t('type of diagnosis') }
                name="diagnosisTypeId"
                options={ diagnosisTypes }
                asyncOptionLabelField="typeName"
                asyncOptionValueField="id"
                defaultValue={ defaultValues.diagnosisTypeId }
                selectIfUniq
              />
            </Item>
            <Item>
              <InputDate
                label={ t('accomplished date') }
                name="realizationDate"
                defaultValue={ defaultValues.realizationDate }
                pickerProps={ {
                  maxDate: moment().format(),
                  minDate: currentInsemination?.inseminationDate
                } }
                fullWidth
              />
            </Item>
            <Item>
              <InputSelect
                label={ t('result') }
                name="result"
                defaultValue={ defaultValues.result }
                options={ [
                  {
                    label: t('pregnant'),
                    value: 'Positivo'
                  },
                  {
                    label: t('empty'),
                    value: 'Vazia'
                  },
                  {
                    label: t('inconclusive'),
                    value: 'Inconclusivo'
                  }
                ] }
              />
            </Item>
          </Grid>
        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-cancel-form-button"
              onClick={ onCancel }
              color={ colors.white }
              disabled={ loading }
              fullWidth
              style={ { marginRight: 5 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="web-save-form-button"
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
              fullWidth
              style={ { marginLeft: 5 } }
            >
              <I18n>register</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
}

RegisterDiagnosisActionsForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  animalsList: PropTypes.array,
  defaultValues: PropTypes.object.isRequired,
  formRef: PropTypes.object.isRequired
}

RegisterDiagnosisActionsForm.defaultProps = {
  loading: false,
  animalsList: [],
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false
}

const enhance = withObservables(['propertyId'], ({ propertyId }) => ({
  animalsList: animalService(database).observeAnimals(propertyId)
}))

const EnhancedRegisterDiagnosisActionsForm = enhance(RegisterDiagnosisActionsForm)

export default EnhancedRegisterDiagnosisActionsForm
