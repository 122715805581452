import moment from 'moment/moment'
import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import maxNumberValue from '@smartcoop/forms/validators/maxNumberValue.validator'
import minDate from '@smartcoop/forms/validators/minDate.validator'
import required from '@smartcoop/forms/validators/required.validator'

const productivityContest = ({ t, props }) => props.isRTC ? Yup.object().shape({
  proprietaryId: flow(
    required({ t })
  )(Yup.string()),

  propertyId: flow(
    required({ t })
  )(Yup.string()),

  fieldId: flow(
    required({ t })
  )(Yup.string()),

  note: flow(
  )(Yup.string()),

  expirationDate: flow(
    required({ t }),
    date({ t }),
    minDate({ t, minReceiptDate: moment().format('YYYY-MM-DD') })
  )(Yup.string()),


  certificate: Yup.object().shape({
    ID: flow(
      required({ t }),
      maxNumberValue({ t, name: 'ID', max: 36, ignoreReplace: true })
    )(Yup.string()),
    PGV: flow(
      required({ t }),
      maxNumberValue({ t, name: 'PGV', max: 1, ignoreReplace: true })
    )(Yup.string()),
    PGI: flow(
      required({ t }),
      maxNumberValue({ t, name: 'PGI', max: 3, ignoreReplace: true })
    )(Yup.string()),
    HCC: flow(
      required({ t }),
      maxNumberValue({ t, name: 'HCC', max: 1, ignoreReplace: true })
    )(Yup.string()),
    CRI: flow(
      required({ t }),
      maxNumberValue({ t, name: 'CRI', max: 3, ignoreReplace: true })
    )(Yup.string()),
    SC: flow(
      required({ t }),
      maxNumberValue({ t, name: 'SC', max: 1, ignoreReplace: true })
    )(Yup.string()),
    CS: flow(
      required({ t }),
      maxNumberValue({ t, name: 'CS', max: 1, ignoreReplace: true })
    )(Yup.string()),
    FE: flow(
      required({ t }),
      maxNumberValue({ t, name: 'FE', max: 1, ignoreReplace: true })
    )(Yup.string()),
    AE: flow(
      required({ t }),
      maxNumberValue({ t, name: 'AE', max: 1, ignoreReplace: true })
    )(Yup.string()),
    AS: flow(
      required({ t }),
      maxNumberValue({ t, name: 'AS', max: 1, ignoreReplace: true })
    )(Yup.string()),
    ECSS: flow(
      required({ t }),
      maxNumberValue({ t, name: 'ECSS', max: 8, ignoreReplace: true })
    )(Yup.string())
  })
}) : Yup.object().shape({
  proprietaryId: flow(
    required({ t })
  )(Yup.string()),

  propertyId: flow(
    required({ t })
  )(Yup.string()),

  fieldId: flow(
    required({ t })
  )(Yup.string())
})

export default productivityContest
