import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${ colors.black };
  margin-bottom: 5%;
`

export const BlackSubtitle = styled(Title)`
  font-size: 16px;
`

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  marginTop: 10px;
`

export const Subtitle = styled.span`
  font-size: 14px;
  color: ${ colors.darkGrey };
  margin-bottom: 5%;
`

export const Text = styled.span`
  font-size: 16px;
  color: ${ colors.black };
  margin-bottom: 5%;
`

export const DetailsContainer = styled.div`
  width: 300px;
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`
