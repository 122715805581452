import { call, put, takeLatest } from 'redux-saga/effects'

import { createUserAbsence, deleteUserAbsence, getUserAbsences, patchUserAbsence } from '@smartcoop/services/apis/smartcoopApi/resources/absence'

import { AbsenceActions, AbsenceTypes } from './duckAbsence'

function* createAbsence({ newAbsence, onSuccess = () => {} }) {
  try {
    yield call(createUserAbsence, newAbsence)

    yield put(AbsenceActions.loadAbsences())
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(AbsenceActions.absenceError(error))
  }
}

function* loadAbsences({ onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(getUserAbsences)

    yield put(AbsenceActions.loadAbsencesSuccess(
      data?.data,
      () => onSuccess(data?.data)
    ))
  } catch (err) {
    const error = err.message
    yield put(AbsenceActions.absenceError(error))
    yield call(onError, error)
  }
}

function* editAbsence({ id, params, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(patchUserAbsence, id, params)
    yield put(AbsenceActions.loadAbsences())
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(AbsenceActions.absenceError(error))
    yield call(onError, error)
  }
}

function* deleteAbsence({ id, onError }) {
  try {
    yield call(deleteUserAbsence, id)
    yield put(AbsenceActions.loadAbsences())
  } catch (err) {
    const error = err.message
    yield put(AbsenceActions.absenceError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(AbsenceTypes.CREATE_ABSENCE, createAbsence),
  takeLatest(AbsenceTypes.LOAD_ABSENCES, loadAbsences),
  takeLatest(AbsenceTypes.EDIT_ABSENCE, editAbsence),
  takeLatest(AbsenceTypes.DELETE_ABSENCE, deleteAbsence)
]