import React, { useMemo, useState, useCallback, useEffect } from 'react'

import { getPowerBiToken } from '@smartcoop/services/apis/smartcoopApi/resources/bi'
import Loader from '@smartcoop/web-components/Loader'
import UserReportFragment from '@smartcoop/web-containers/fragments/reports/crm/UserReportFragment/UserReportFragment'

const UserReportScreen = () => {

  const [token, setToken] = useState(null)
  const [isValidating, setIsValidating] = useState(true)

  const getMicrosoftToken = useCallback(async () => {
    const { data, isValidating: validating } = await getPowerBiToken()
    setToken(data)
    setIsValidating(validating)
    return data
  }, [])

  useEffect(() => {
    getMicrosoftToken()
  },[getMicrosoftToken])

  const accessToken = useMemo(
    () => token?.accessToken,
    [token]
  )

  const loading = useMemo(
    () => (!accessToken && isValidating) || !accessToken,
    [accessToken, isValidating]
  )

  return loading ? (
    <Loader width={ 100 } />
  ) : (
    <div style={ { display: 'flex', flex: 1, flexDirection: 'column' } }>
      <div style={ { display: 'flex', flex: 1 } }>
        <UserReportFragment token={ accessToken } getMicrosoftToken={ getMicrosoftToken } />
      </div>
    </div>
  )
}

export default UserReportScreen
