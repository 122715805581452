import * as Yup from 'yup'

const filterLot = () => Yup.object().shape({
  expectedDate: Yup.string(),
  occurrenceDate: Yup.string(),
  gender: Yup.string(),
  isAlive: Yup.string(),
  weight: Yup.string(),
  birthType: Yup.string()

})

export default filterLot
