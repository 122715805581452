/* eslint-disable no-nested-ternary */
import React, { useCallback, forwardRef, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import trimMask from '@meta-awesome/functions/src/trimMask'
import PropTypes from 'prop-types'

import machinerySchema from '@smartcoop/forms/schemas/machinery/machineryRegister.schema'
import I18n, { useT } from '@smartcoop/i18n'
import {
  getMachinesTypes as getMachinesTypesService,
  getMachinesBrands as getMachinesBrandsService
} from '@smartcoop/services/apis/smartcoopApi/resources/machine'
import { selectGetProperties as getPropertiesService, getPropertiesByOwner } from '@smartcoop/services/apis/smartcoopApi/resources/property'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectCurrentOwner } from '@smartcoop/stores/technical/selectorTechnical'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputPhone from '@smartcoop/web-components/InputPhone'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import { Title } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container, ButtonContainer, InputContainer } from './styles'

const CreateMachineryForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, defaultValues, loading, onSubmit, familyGroupAccess, currentProperty } = props

  const t = useT()

  const [available, setAvailable] = useState(defaultValues.available)
  const [saleValue, setSaleValue] = useState(defaultValues.saleValue)

  const isTechnical = useSelector(selectModuleIsTechnical)
  const currentOwner = useSelector(selectCurrentOwner)

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data,
        phone: trimMask(data?.phone || ''),
        rentAvailable: available === 'rent',
        saleAvailable: available === 'sell',
        serviceProvisionAvailable: available === 'service',
        saleValue
      })
    },
    [available, onSubmit, saleValue]
  )

  const availableOptions = useMemo(
    () => (
      [
        {
          label: t('sell'),
          value: 'sell'
        },
        {
          label: t('service'),
          value: 'service'
        },
        {
          label: t('rent'),
          value: 'rent'
        },
        {
          label: t('none'),
          value: 'none'
        }
      ]
    ),
    [t]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ machinerySchema }
        onSubmit={ handleSubmit }
      >
        <InputSelect
          label={ t('property', { howMany: 1 }) }
          name="propertyId"
          options={
            familyGroupAccess
              ? [{
                label:currentProperty?.name,
                value: currentProperty?.id
              }]
              : isTechnical ? getPropertiesByOwner : getPropertiesService
          }
          disabled={ familyGroupAccess }
          urlParams={ { ownerId: currentOwner?.id } }
          defaultValue={ familyGroupAccess ? currentProperty?.id : defaultValues.property?.id }
        />

        <InputText
          name="description"
          label={ t('description') }
          defaultValue={ defaultValues.description }
        />

        <InputSelect
          label={ t('type', { howMany: 1 }) }
          name="machineTypeId"
          options={ getMachinesTypesService }
          asyncOptionLabelField="description"
          defaultValue={ defaultValues.machineType.id }

        />

        <InputSelect
          label={ t('brand', { howMany: 1 }) }
          name="machineBrandId"
          options={ getMachinesBrandsService }
          asyncOptionLabelField="description"
          defaultValue={ defaultValues.machineBrand.id }
        />
        <InputText
          name="model"
          label={ t('model') }
          disabled={ loading }
          defaultValue={ defaultValues.model }
        />

        <InputContainer>
          <InputNumber
            name="year"
            label={ t('year', { howMany: 1 }) }
            maxLength={ 4 }
            style={ { marginRight: 5 } }
            defaultValue={ defaultValues.year }
            notSeparate
          />
          <InputNumber
            name="availableHours"
            label={ t('hours') }
            defaultValue={ defaultValues.availableHours }
          />
        </InputContainer>
        <InputPhone
          label={ t('phone') }
          name="phone"
          defaultValue={ defaultValues.phone }
        />
        <InputText
          name="contactInformation"
          label={ t('observations') }
          defaultValue={ defaultValues.contactInformation }
        />

        <Title style={ { marginTop: 0, fontSize: 16 } }>
          <I18n>available for</I18n>
        </Title>
        <RadioGroup
          detached
          label=''
          options={ availableOptions }
          variant="row"
          style={ { paddingBottom: 20 } }
          onChange={ item => setAvailable(item.value) }
          value={ available }
        />

        { available && available !== 'none' && (
          <InputUnit
            detached
            type="float"
            label={ t('value') }
            disabled={ loading }
            value={ saleValue }
            onChange={ e => setSaleValue(e.target.value) }
          />
        )}

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-field-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>next</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

CreateMachineryForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  familyGroupAccess: PropTypes.bool,
  defaultValues: PropTypes.object.isRequired,
  currentProperty: PropTypes.object
}

CreateMachineryForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false,
  familyGroupAccess: false,
  currentProperty: {}
}

export default CreateMachineryForm
