import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import trimMask from '@meta-awesome/functions/src/trimMask'
import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import Divider from '@material-ui/core/Divider'


import I18n from '@smartcoop/i18n'
import { pencil, trash, tractor } from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import colors from '@smartcoop/styles/colors'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import { formatCurrency , formatPhoneV2 } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import Loader from '@smartcoop/web-components/Loader'
import MachineStatus from '@smartcoop/web-components/MachineStatus'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import MachineDetailsImageGallery from './MachineDetailsImageGallery/MachineDetailsImageGallery'
import {
  ButtonContainer,
  WhiteContainer,
  Title,
  InfoContainer,
  MidContainer,
  DistanceText,
  Text,
  TextInfo,
  TextBox,
  TitleScreen,
  FirstContainer,
  Half,
  EmptyImage,
  StatusContainer,
  EmptyImageContainer
} from './styles'

const MachineDetailsFragment = ({ machine, isUserMachine, onDeleteMachine, manyMachines }) => {
  const [images, setImages] = useState([])
  const [loadingMachineImages, setLoadingMachineImages] = useState(true)
  const history = useHistory()
  const { routes } = useRoutes()

  const distance = useMemo(
    () => (machine.property?.distance ? (`${ machine.property?.distance  } km`) : ''),
    [machine]
  )

  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_MACHINES_ID, fileKey: file?.fileKey })
    return downloadFromBase64(data)
  }, [])

  useEffect(() => {
    setLoadingMachineImages(true)
    Promise.all(machine.machineFiles.map(async(image) => {
      const imageSrc = await renderFile(image)
      return {
        ...image,
        fileUrl: imageSrc
      }
    })).then((bucketImages) => {
      setImages(bucketImages)
      setLoadingMachineImages(false)
    })
  }, [machine, renderFile])


  const machineDetailsFragmentImage = useMemo(
    () => {
      if(isEmpty(images) && !loadingMachineImages) {
        return (
          <EmptyImageContainer>
            <EmptyImage >
              <Icon size={ 80 } icon={ tractor } color={ colors.grey }/>
              <I18n>no image added</I18n>
            </EmptyImage>
          </EmptyImageContainer>
        )
      } if (loadingMachineImages) {
        return (
          <Loader/>
        )}
      return (
        <MachineDetailsImageGallery images={ images } />
      )
    },
    [images, loadingMachineImages]
  )



  const editMachine = useCallback(
    () => {
      history.push(routes.machineryEdit.path, { machine: { ...machine } })

    }, [history, machine, routes.machineryEdit.path]
  )

  const deleteMachine = useCallback(
    () => {
      onDeleteMachine(machine.id)
    }, [machine.id, onDeleteMachine]
  )

  const status = useMemo(() => {
    if(machine?.rentAvailable) return 'rent'
    if(machine?.saleAvailable) return 'sell'
    if(machine?.serviceProvisionAvailable) return 'service'
    return 'none'
  }, [machine])

  return (
    <FirstContainer style={ manyMachines ? { maxHeight: '100%', margin: '0px' } : { maxHeight: 'calc(100vh - 120px)' } }>
      {!manyMachines && (
        <>
          <TitleScreen>
            <I18n>machine details</I18n>

            {isUserMachine && <ButtonContainer>
              <Button
                id="web-edit-machine"
                style={ { flex: '0 0 auto', marginRight: 10 } }
                variant="outlined"
                onClick={ editMachine }
              >
                <>
                  <Icon
                    icon={ pencil }
                    size={ 14 }
                    style={ { marginRight: 5 } }
                  />
                  <I18n>edit</I18n>
                </>
              </Button>
              <Button
                id="deleteCropButton"
                variant="outlined"
                style={ { flex: '0 0 auto' } }
                onClick={ deleteMachine }
              >
                <Icon icon={ trash } size={ 14 } color={ colors.red } />
              </Button>
            </ButtonContainer>
            }
          </TitleScreen>
          <Divider />
        </>
      )}

      <WhiteContainer style={ manyMachines ? { maxHeight: '100%' } : { maxHeight: 'calc(100vh - 120px)' } }>
        <InfoContainer>
          <Title>{machine.description}</Title>
          <DistanceText>{distance}</DistanceText>
        </InfoContainer>

        <MidContainer>
          <Half>
            {status !== 'none' && (
              <StatusContainer>
                <MachineStatus status={ status } style={ { width: '150px' } }/>
              </StatusContainer>
            )}

            <InfoContainer>
              <TextBox>
                <TextInfo><I18n>type</I18n></TextInfo>
                <Text>{machine.machineType.description}</Text>
              </TextBox>

              <TextBox>
                <TextInfo><I18n>model</I18n></TextInfo>
                <Text>{machine.model}</Text>
              </TextBox>

              <TextBox>
                <TextInfo><I18n>brand</I18n></TextInfo>
                <Text>{machine.machineBrand.description}</Text>
              </TextBox>

              <TextBox>
                <TextInfo><I18n params={ { howMany: 1 } }>year</I18n></TextInfo>
                <Text>{machine.year}</Text>
              </TextBox>

              <TextBox>
                <TextInfo><I18n>hours</I18n></TextInfo>
                <Text>{machine.availableHours}</Text>
              </TextBox>

              <TextBox>
                <TextInfo><I18n>contact</I18n></TextInfo>
                <Text>{formatPhoneV2(trimMask(machine.phone)) || <I18n>uninformed</I18n>}</Text>
              </TextBox>

              {status !== 'none' && machine?.saleValue && (
                <TextBox>
                  <TextInfo><I18n>value</I18n></TextInfo>
                  <Text>{formatCurrency(machine?.saleValue)}</Text>
                </TextBox>
              )}
            </InfoContainer>
          </Half>
        </MidContainer>
        {machineDetailsFragmentImage}

      </WhiteContainer>
    </FirstContainer>
  )
}

MachineDetailsFragment.propTypes = {
  machine: PropTypes.object.isRequired,
  isUserMachine: PropTypes.bool,
  onDeleteMachine: PropTypes.func,
  manyMachines: PropTypes.bool
}

MachineDetailsFragment.defaultProps = {
  isUserMachine: false,
  onDeleteMachine: () => {},
  manyMachines: false
}

export default MachineDetailsFragment
