/* eslint-disable no-param-reassign */
import { Database, Q } from '@nozbe/watermelondb'
import moment from 'moment'

import first from 'lodash/first'
import get from 'lodash/get'

import { decrypt, decryptToGroup } from '../utils/cryptography'
import { generateUuid } from '../utils/uuidGenerator'
import { groupMemberService } from './groupMemberService'
import { groupService } from './groupService'
import { profilePictureService } from './profilePictureService'
import { ROUTES } from './routes'
import { syncPendingService } from './syncPendingService'
import { userService } from './userService'

const CONVERSATION_FIELD = [
  'unseenCount',
  'photo',
  'name',
  'favorite',
  'broadcast',
  'group',
  'whoSend',
  'publicKey',
  'registry',
  'activeUser',
  'userCode'
]

export function conversationService(database: Database) {
  async function createOrUpdate(conversationList = []) {
    const privateKey = await userService(database).getPrivateKey()
    const user = await userService(database).getUserData()

    await database.write(async () => {
      const promises = conversationList.map(conversationItem => async () => {
        if (!conversationItem.id) {
          return
        }

        const relationalId = conversationItem.group || conversationItem.broadcast ?
          conversationItem.id :
          generateUuid(user?.userId, conversationItem.userId)


        if (conversationItem.group || conversationItem.broadcast) {
          await groupService(database).createOrUpdate(conversationItem)
          await groupMemberService(database).createOrUpdate(conversationItem.members, conversationItem)
        }

        const decryptedMessage = (conversationItem.group || conversationItem.broadcast) ?
          await decryptToGroup(conversationItem.lastMessage, conversationItem.publicKey) :
          await decrypt(conversationItem.lastMessage, privateKey)

        const register = await database.get('conversation')
          .query(
            Q.or(
              Q.where('conversationId', conversationItem.id || null),
              Q.where('relationalId', relationalId || null)
            )
          )
          .fetch()

        if (register.length > 1) {
          const deleteRegisters = register.filter((_, index) => index > 0)
          const deletedPromises = deleteRegisters.map(item => async () => item.destroyPermanently())
          await Promise.all(deletedPromises.map(fn => fn()))
        }

        if (register[0] && get(register, '[0]._raw._status') !== 'deleted') {
          await register[0].update(item => {
            item.conversationId = conversationItem.id
            CONVERSATION_FIELD.forEach(field => { item[field] = conversationItem[field] })
            item.contactId = conversationItem.userId
            item.lastMessage = decryptedMessage
            item.relationalId = relationalId
            item.userCode = conversationItem.code
            item.publicKey = conversationItem.publicKey
            item.documentFormat = conversationItem.documentFormat
            item.isGeolocation = conversationItem.isGeolocation
            item.status = conversationItem.status
            item.userCode = conversationItem.userCode
            item.deleted = conversationItem.deleted
            item.createdAt = Number(moment(conversationItem.createdAt).format('X'))
          })
          return
        }

        await database.get('conversation').create(item => {
          item.conversationId = conversationItem.id
          CONVERSATION_FIELD.forEach(field => { item[field] = conversationItem[field] })
          item.contactId = conversationItem.userId
          item.lastMessage = decryptedMessage
          item.relationalId = relationalId
          item.userCode = conversationItem.code
          item.publicKey = conversationItem.publicKey
          item.documentFormat = conversationItem.documentFormat
          item.isGeolocation = conversationItem.isGeolocation
          item.status = conversationItem.status
          item.userCode = conversationItem.userCode
          item.deleted = conversationItem.deleted
          item.createdAt = Number(moment(conversationItem.createdAt).format('X'))
        })
      })

      await Promise.all(promises.map(fn => fn()))
    })

    if (conversationList) {
      // Run in background
      profilePictureService(database).createOrUpdate(conversationList)
    }
  }

  async function startGroupConversation(group) {
    return database.write(async () => {
      const data = await database.get('conversation').create(item => {
        item.relationalId = group.id
        item.conversationId = group.id
        item.name = group.groupName
        item.publicKey = group.groupKey || group.broadcastKey
        item.createdAt = Number(moment().format('X'))
        item.lastMessage = group.broadcast ?
          `Você criou a lista de transmissão ${ group.groupName }` :
          `Você criou o grupo ${ group.groupName }`
        item.activeUser = true
        item.group = group.groupChat
        item.documentFormat = group.documentFormat
        item.isGeolocation = group.isGeolocation
        item.status = group.status
        item.broadcast = group.broadcast
      })
      return data
    })
  }

  async function startConversation(relationalId, contact, lastMessage = '') {
    return database.write(async () => {
      const data = await database.get('conversation').create(item => {
        item.relationalId = relationalId
        item.contactId = contact?.contactId
        item.name = contact?.chatNickname
        item.whoSend = contact?.contactId
        item.publicKey = contact?.publicKey
        item.userCode = contact?.userCode
        item.createdAt = Number(moment().format('X'))
        item.lastMessage = lastMessage
        item.activeUser = contact?.activeUser
      })
      return data
    })
  }

  async function startConversationWithBroadcastMember(relationalId, member, localUserId, lastMessage = '') {
    return database.write(async () => {
      const data = await database.get('conversation').create(item => {
        item.relationalId = relationalId
        item.contactId = member?.userId
        item.name = member?.userName
        item.whoSend = localUserId
        item.publicKey = member?.publicKey
        item.userCode = member?.userCode
        item.createdAt = Number(moment().format('X'))
        item.lastMessage = lastMessage
        item.activeUser = true
      })
      return data
    })
  }

  async function updateFavorite(relationalId, isFavorite, justLocalDatabase = false) {
    if (isFavorite) {
      const favorites = await database.get('conversation').query(Q.where('favorite', true)).fetch()

      if (favorites.length >= 3) {
        throw new Error('Ops! Você só pode adicionar 3 favoritos')
      }
    }

    await database.write(async () => {
      if (!justLocalDatabase) {
        await syncPendingService(database).create({
          type: 'PATCH',
          body: {},
          params: {},
          url: `${ ROUTES.UPDATE_FAVORITE }/${ relationalId }/${ isFavorite }`,
          registerType: 'update_favorite'
        })
      }

      const register = await database.get('conversation')
        .query(Q.where('relationalId', relationalId))
        .fetch()


      if (register[0]) {
        await register[0].update(item => {
          item.favorite = isFavorite
        })
      }
    })
  }

  async function deleteConversations(ids) {
    const promises = ids.map(relationalId => async () => {
      await database.write(async () => {
        // Delete all messages
        const messages = await database.collections
          .get('message')
          .query(Q.or(
            Q.where('relationalId', relationalId),
            Q.where('conversationId', relationalId)
          ))
          .fetch()

        messages.map(message => message.prepareMarkAsDeleted())
        await database.batch(messages)

        // Delete all conversations
        const conversations = await database.collections
          .get('conversation')
          .query(Q.or(
            Q.where('relationalId', relationalId),
            Q.where('conversationId', relationalId)
          ))
          .fetch()

        conversations.map(conversation => conversation.prepareMarkAsDeleted())
        await database.batch(conversations)

        await syncPendingService(database).create({
          type: 'DELETE',
          body: {},
          params: {},
          url: `${ ROUTES.DELETE_CONVERSATION }/${ relationalId }`,
          registerType: 'delete_conversation'
        })
      })
    }).reverse()

    await Promise.all(promises.map(fn => fn()))
  }

  async function createConversation(conversation) {
    await database.write(async () => {
      await syncPendingService(database).create({
        type: 'POST',
        body: JSON.stringify(conversation),
        params: {},
        url: `${ ROUTES.CREATE_CONVERSATION }`,
        registerType: 'create_conversation'
      })
    })
  }

  async function updateLastMessage(relationalId, message, messageId) {
    const registers = await database.get('conversation')
      .query(
        Q.or(
          Q.where('relationalId', relationalId),
          Q.where('conversationId', relationalId)
        )
      )
      .fetch()

    if (registers[0]) {
      await registers[0].update(item => {
        item.lastMessage = message
        item.status = 'pending'
        item.registry = messageId
      })
    }
  }

  async function findByRelationalId(relationalId) {
    const conversations = await database.get('conversation')
      .query(Q.where('relationalId', relationalId))
      .fetch()

    return first(conversations)
  }

  async function updateInvalidCode(conversationId, invalidCode = false) {
    const registers = await database.get('conversation')
      .query(
        Q.or(
          Q.where('relationalId', conversationId),
          Q.where('conversationId', conversationId)
        )
      )
      .fetch()

    if (registers[0]) {
      await database.write(async () => {
        await registers[0].update(item => {
          item.invalidCode = invalidCode
        })
      })
    }
  }

  async function updateCodeByContactId(contactId, userCode) {
    await database.write(async () => {
      const registers = await database.get('conversation')
        .query(
          Q.where('contactId', contactId)
        )
        .fetch()

      if (registers[0]) {
        await registers[0].update(item => {
          item.invalidCode = false
          item.userCode = userCode
        })
      }
    })
  }

  function observeConversations(contactId) {
    const conversation = database.collections.get('conversation')

    if (contactId) {
      return conversation
        .query(
          Q.where('contactId', Q.notEq(contactId))
        )
        .observeWithColumns(['lastMessage', 'status', 'favorite', 'updatedAt', 'registry', 'unseenCount'])
    }

    return conversation.query().observeWithColumns(['lastMessage', 'status', 'favorite', 'updatedAt', 'registry', 'unseenCount'])
  }

  function observeConversationById(id = null, relationalId = null) {
    const conversation = database.collections.get('conversation')
    return conversation.query(
      Q.or(
        Q.where('conversationId', id),
        Q.where('relationalId', relationalId)
      )
    ).observeWithColumns(['updatedAt', 'invalidCode', 'favorite', 'name', 'unseenCount', 'userCode'])
  }

  function observeConversationsByFilter(searchParams) {
    const conversation = database.collections.get('conversation')

    return conversation.query(
      Q.where('name', Q.like(`%${ Q.sanitizeLikeString(searchParams) }%`)),
      Q.where('activeUser', Q.eq(true))
    ).observe()
  }

  return {
    createOrUpdate,
    createConversation,
    updateFavorite,
    observeConversations,
    observeConversationById,
    findByRelationalId,
    startConversation,
    updateLastMessage,
    updateInvalidCode,
    updateCodeByContactId,
    observeConversationsByFilter,
    deleteConversations,
    startGroupConversation,
    startConversationWithBroadcastMember
  }
}
