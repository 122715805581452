import React, { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { UserActions } from '@smartcoop/stores/user'
import { withLayout } from '@smartcoop/web-containers/layouts'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import RouterSwitch from '../../RouterSwitch'

const CommercializationScreenRouter = (props) => {
  const { routes } = useRoutes()
  const dispatch = useDispatch()

  useEffect(
    () => () => {
      dispatch(AuthenticationActions.loadPermissions())
      dispatch(UserActions.loadUser())
    },
    [dispatch]
  )

  return <RouterSwitch routes={ routes } { ...props } />
}

export default memo(withLayout('authenticated')(CommercializationScreenRouter))
