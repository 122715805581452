import React, { useCallback, forwardRef, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import growingSeasonRegisterSchema from '@smartcoop/forms/schemas/growingSeason/growingSeasonRegister.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getCrops as getCropsService } from '@smartcoop/services/apis/smartcoopApi/resources/crop'
import { getCultivationsGoal as getCultivationsGoalService } from '@smartcoop/services/apis/smartcoopApi/resources/cultivationsGoal'
import { getUserStateRegistrations as getUserStateRegistrationsService, getStateRegistrationsByUserId } from '@smartcoop/services/apis/smartcoopApi/resources/stateRegistration'
import { FieldActions } from '@smartcoop/stores/field'
import { selectCurrentField } from '@smartcoop/stores/field/selectorField'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectCurrentPropertyId, selectActivities } from '@smartcoop/stores/property/selectorProperty'
import { selectCurrentOwner } from '@smartcoop/stores/technical/selectorTechnical'
import { generateArrayOfDate } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import { Container, ButtonContainer } from './styles'

const GrowingSeasonForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    onSubmit,
    onSuccess,
    onError,
    style,
    childrenPolygonId,
    currentGrowingSeason,
    isPlanned,
    plannedFields,
    setPlannedFields,
    fieldOptions,
    optionsByFields,
    selectedPolygons,
    plannedOptions,
    setSelectedPolygons
  } = props
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()
  const [otherCrop, setOtherCrop] = useState(null)

  const currentField = useSelector(selectCurrentField)
  const isTechnical = useSelector(selectModuleIsTechnical)
  const currentOwner = useSelector(selectCurrentOwner)
  const activities = useSelector(selectActivities)
  const currentPropertyId = useSelector(selectCurrentPropertyId)

  const currentOwnerId = useMemo(
    () => currentOwner?.id,
    [currentOwner]
  )

  const years = useMemo(
    () => generateArrayOfDate(moment().subtract(10, 'year'), moment().add(2, 'year'), 'year'),
    []
  )

  const sowingYearOptions = useMemo(
    () => map(years, (date) => ({ value: date.format('YYYY'), label: date.format('YYYY') })),
    [years]
  )

  const activitiesOptions = useMemo(
    () => map(activities, ({ name, id }) => ({ value: id, label: name })),
    [activities]
  )

  const handleSubmit = useCallback(
    (data) => {
      onSubmit()
      if(isPlanned) {
        dispatch(FieldActions.saveOfflineGrowingSeason(
          map(selectedPolygons, item => {
            const currentFieldIfChildrenPolygon = find(plannedOptions, child => find(child?.childrenPolygons, each => each.id === item))

            return ({
              ...currentGrowingSeason,
              ...data,
              childrenPolygonId: currentFieldIfChildrenPolygon ? item : null,
              t,
              fieldId: currentFieldIfChildrenPolygon?.id || item,
              cropId: otherCrop ? null : data.cropId,
              otherCrop,
              closed: false,
              isPlanned
            })
          }),
          onSuccess,
          onError,
          isPlanned,
          true
        ))
      } else {
        dispatch(FieldActions.saveOfflineGrowingSeason(
          {
            ...currentGrowingSeason,
            ...data,
            childrenPolygonId,
            t,
            cropId: otherCrop ? null : data.cropId,
            otherCrop,
            closed: false,
            isPlanned: isPlanned || false
          },
          onSuccess,
          onError,
          isPlanned,
          true
        ))
      }
    },
    [childrenPolygonId, currentGrowingSeason, dispatch, isPlanned, onError, onSubmit, onSuccess, otherCrop, plannedOptions, selectedPolygons, t]
  )

  const handleCropChange = useCallback(
    (_, selectedItem) => {
      if (selectedItem?.created) {
        setOtherCrop(selectedItem.value)
      } else {
        setOtherCrop(null)
      }
    },
    []
  )

  return (
    <Container>
      <Form
        style={ { ...style } }
        ref={ formRef }
        schemaConstructor={ growingSeasonRegisterSchema }
        onSubmit={ handleSubmit }
      >
        {isPlanned
          ? (
            <InputSelect
              label={ t('field', { howMany: 1 }) }
              options={ fieldOptions }
              value ={ plannedFields }
              onChange={ setPlannedFields }
              multiple
              detached
            />
          ) : (
            <InputText
              label={ t('field', { howMany: 1 }) }
              value={ currentField.fieldName }
              fullWidth
              detached
              disabled
            />
          )
        }

        {isPlanned && !isEmpty(plannedFields) && (
          <InputSelect
            label={ t('area', { howMany: 1 }) }
            options={ optionsByFields }
            value ={ selectedPolygons }
            onChange={ setSelectedPolygons }
            multiple
            detached
          />
        )}

        <InputSelect
          label={ t('crop', { howMany: 1 }) }
          name="cropId"
          options={ getCropsService }
          asyncOptionLabelField="description"
          onChange={ handleCropChange }
          defaultValue={ currentGrowingSeason?.cropId }
        />

        <InputSelect
          label={ t('sowing year') }
          name="sowingYear"
          options={ sowingYearOptions }
          defaultValue={ currentGrowingSeason?.sowingYear }
          orderDirection="desc"
        />

        <InputSelect
          label={ t('goal') }
          name="cultivationGoalId"
          options={ getCultivationsGoalService }
          asyncOptionLabelField="description"
          defaultValue={ currentGrowingSeason?.cultivationGoalId }
        />

        <InputSelect
          name="activityId"
          label={ t('activity', { howMany: 1 }) }
          defaultValue={ currentGrowingSeason?.activityId }
          options={ activitiesOptions }
        />

        <InputSelect
          name="userStateRegistrationsIds"
          label={ t('state registration') }
          defaultValue={ map(currentGrowingSeason?.userStateRegistrations, ({ id }) => id) }
          options={ isTechnical ? getStateRegistrationsByUserId : getUserStateRegistrationsService }
          urlParams={ isTechnical ? { userId: currentOwnerId } : {} }
          queryParams={ { expired: true, propertyId: currentPropertyId } }
          asyncOptionLabelField="stateRegistration"
          asyncOptionValueField="id"
          multiple
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-property-identification-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
            >
              <I18n>next</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

GrowingSeasonForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  setPlannedFields: PropTypes.func,
  setSelectedPolygons: PropTypes.func,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  isPlanned: PropTypes.bool,
  style: PropTypes.object,
  childrenPolygonId: PropTypes.string.isRequired,
  currentGrowingSeason: PropTypes.object,
  plannedFields: PropTypes.array,
  optionsByFields: PropTypes.array,
  selectedPolygons: PropTypes.array,
  plannedOptions: PropTypes.array,
  fieldOptions: PropTypes.array
}

GrowingSeasonForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
  onSubmit: () => {},
  setPlannedFields: () => {},
  setSelectedPolygons: () => {},
  withoutSubmitButton: false,
  isPlanned: false,
  style: {},
  plannedFields: [],
  optionsByFields: [],
  selectedPolygons: [],
  fieldOptions: [],
  plannedOptions: [],
  currentGrowingSeason: {}
}

export default GrowingSeasonForm
