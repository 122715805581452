import React, { useMemo } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import toNumber from 'lodash/toNumber'

import { momentBackDateTimeFormat } from '@smartcoop/utils/dates'
import { formatCurrency } from '@smartcoop/utils/formatters'

import { Container, Row, Column, Text, ValueText, LabelText } from './styles'

const SecuritiesMovement = (props) =>  {
  const {
    value,
    transactionDate,
    dueDate: externalDueDate,
    description,
    positive,
    titleNumber: externalTitleNumber,
    titleName: externalTitleName
  } = props

  const currencyValue = useMemo(
    () => formatCurrency(Math.abs(toNumber(value))),
    [value]
  )

  const formatedValue = useMemo(() => `${ positive ? '+' : '-' } ${ currencyValue }`, [currencyValue, positive])
  const launchDate = useMemo(() => moment(transactionDate, momentBackDateTimeFormat).format('DD/MM/YYYY'), [transactionDate])
  const dueDate = useMemo(() => moment(externalDueDate, momentBackDateTimeFormat).format('DD/MM/YYYY'), [externalDueDate])
  const titleNumber = useMemo(() => externalTitleNumber, [externalTitleNumber])
  const titleName = useMemo(() => externalTitleName, [externalTitleName])

  return (
    <Container>
      <Row>
        <Column>
          <Row>
            <ValueText positive={ positive.toString() }>{formatedValue}</ValueText>
          </Row>
          <Row>
            {titleName && (
              <Column>
                <LabelText>name</LabelText>
                <Text>{ titleName }</Text>
              </Column>
            )}
            {titleNumber && (
              <Column>
                <LabelText>number</LabelText>
                <Text>{ titleNumber }</Text>
              </Column>
            )}
          </Row>
          <Row>
            <Column>
              <LabelText>launch date</LabelText>
              <Text>{ launchDate }</Text>
            </Column>
            <Column>
              <LabelText>due date</LabelText>
              <Text>{ dueDate }</Text>
            </Column>
          </Row>
        </Column>

        <Column>
          <Text>{ description }</Text>
        </Column>
      </Row>
    </Container>
  )
}

SecuritiesMovement.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  transactionDate: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  positive: PropTypes.bool,
  titleNumber: PropTypes.string,
  titleName: PropTypes.string
}

SecuritiesMovement.defaultProps = {
  positive: true,
  titleNumber: '',
  titleName: ''
}

export default SecuritiesMovement
