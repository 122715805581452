import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import api from '@smartcoop/services/apis/smartcoopApi'
import {
  createAdvertising as createAdvertisingService,
  deleteAdvertising as deleteAdvertisingService,
  createAdvertisingFiles as createAdvertisingFilesService,
  updateAdvertising as updateAdvertisingService,
  refuseAdvertising as refuseAdvertisingService,
  sendAdvertising as sendAdvertisingService
} from '@smartcoop/services/apis/smartcoopApi/resources/advertising'
import {
  createChatConversation,
  getChatConversationsByUserId,
  getChatMessagesByConversationId,
  DeleteConversation,
  SendChatSearch,
  getSearchMessageByConversationId,
  sendFileMessageByConversationId,
  updateChatMessageStatus,
  deleteChatMessage,
  SendChatMessageSocket,
  getChatContacts,
  addChatContact,
  deleteChatContact,
  patchConversation,
  updateConversationImage,
  DeleteGroupMember,
  UpdateGroupAdministrator
} from '@smartcoop/services/apis/smartcoopApi/resources/chat'



import { selectIsConnected } from '../network/selectorNetwork'
import { ChatActions, ChatTypes } from './duckChat'

function* createConversation({ newConversation, onSuccess = () => null }) {
  try {
    const { data: newConversationData } = yield call(createChatConversation, newConversation)
    const { data } = yield call(getChatMessagesByConversationId, newConversationData.id)

    if(data?.data){
      yield put(ChatActions.setCurrentConversationMessages(data?.data))
    } else {
      yield put(ChatActions.setCurrentConversationMessages([]))
    }
    onSuccess()
    // yield put(ChatActions.loadConversationSuccess(
    //   data?.data,
    //   // pagination.page,
    //   () => onSuccess(data?.data)
    // ))
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
  }
}


function* loadConversations({ onSuccess = () => {}, onError = () => {} }) {
  try {

    const { data } = yield call(getChatConversationsByUserId)

    yield put(ChatActions.loadConversationSuccess(
      data?.data,
      // pagination.page,
      () => onSuccess(data?.data)
    ))
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
    yield call(onError, error)
  }
}


// eslint-disable-next-line no-unused-vars
function* loadCurrentConversation({ idConversation, onError = () => {} }) {
  try {
    // const { data } = yield call(getChatConversationById, idConversation)
    // console.log(data)
    // if(data?.data){
    //   yield put(ChatActions.setCurrentConversation(data?.data))
    // } else {
    //   yield put(ChatActions.setCurrentConversation([]))
    // }
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
    yield call(onError, error)
  }
}

function* loadCurrentConversationMessages({ idConversation, onError = () => {} }) {
  try {
    const { data } = yield call(getChatMessagesByConversationId, idConversation)

    if(data?.data){
      yield put(ChatActions.setCurrentConversationMessages(data?.data))
    } else {
      yield put(ChatActions.setCurrentConversationMessages([]))
    }
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
    yield call(onError, error)
  }
}

function* sendMessage({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(SendChatMessageSocket, params)
    yield call(onSuccess)
  } catch (error) {
    yield put(ChatActions.chatError(error))
    yield call(onError, error)
  }
}

function* deleteGroupMember({ conversationId, params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(DeleteGroupMember, conversationId, params)
    yield call(onSuccess, data)
  } catch (error) {
    yield put(ChatActions.chatError(error))
    yield call(onError, error)
  }
}

function* deleteOfflineGroupMember({ conversationId, params, onSuccess = () => {}, onError = () => {} }) {
  const isConnected = yield select(selectIsConnected)
  if(!isConnected){
    yield call(onSuccess)
  }
  yield put(ChatActions.deleteGroupMember(conversationId, params, onSuccess, onError))
}

function* updateAdminMember({ conversationId, params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(UpdateGroupAdministrator, conversationId, params)
    yield call(onSuccess, data)
  } catch (error) {
    yield put(ChatActions.chatError(error))
    yield call(onError, error)
  }
}

function* updateOfflineAdminMember({ conversationId, params, onSuccess = () => {}, onError = () => {} }) {
  yield put(ChatActions.UpdateGroupAdministrator(conversationId, params, onSuccess, onError))
}

function* createGroup({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(createChatConversation, params)
    yield call(onSuccess, data)
  } catch (error) {
    yield put(ChatActions.chatError(error))
    yield call(onError, error)
  }
}

function* createOfflineGroup({ params, onSuccess = () => {}, onError = () => {} }) {
  yield put(ChatActions.createGroup(params, onSuccess, onError))
}

function* addGroupMember({ conversationId, params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(api.post, `/v1/conversation/add-group-member/${ conversationId }`, params)
    yield call(onSuccess, data)
    yield call(onSuccess, data)
  } catch (error) {
    yield put(ChatActions.chatError(error))
    yield call(onError, error)
  }
}

function* addOfflineGroupMember({ conversationId, params, onSuccess = () => {}, onError = () => {} }) {
  yield put(ChatActions.addGroupMember(conversationId, params, onSuccess, onError))
}

function* deleteConversation({ conversationIds, userId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const allDeletationRequests = conversationIds.map(
      (conversationId) => call(DeleteConversation, conversationId)
    )
    const allResponse = yield all(allDeletationRequests)
    const sucessDelete = allResponse.every((res) => res.status === 200)

    if (!sucessDelete) {
      throw new Error('Não foi possível excluir essas conversas')
    }

    const { data } = yield call(getChatConversationsByUserId, userId)
    yield put(ChatActions.loadConversationSuccess(
      data?.data
    ))

    yield call(onSuccess)
  } catch (error) {
    yield put(ChatActions.chatError(error))
    yield call(onError, error)
  }
}

function* deleteChatMessages({ chatIds, allUsers, idConversation, onSuccess = () => {}, onError = () => {} }) {

  try {
    const allDeletationRequests = chatIds.map((chatId) => ({ chatId, allUsers }))

    const response = yield call(deleteChatMessage, allDeletationRequests)

    if (response.status !== 201) {
      throw new Error('Não foi possível apagar as mensagens')
    }
    const { data } = yield call(getChatMessagesByConversationId, idConversation)

    if(data?.data){
      yield put(ChatActions.setCurrentConversationMessages(data?.data))
    } else {
      yield put(ChatActions.setCurrentConversationMessages([]))
    }
    yield call(onSuccess)
  } catch (error) {
    yield put(ChatActions.machineError(error))
    yield call(onError, error)
  }
}

function* sendOfflineMessage({ params, onSuccess = () => {}, onError = () => {} }) {
  yield put(ChatActions.updateOfflineMessagesData(params))

  yield put(ChatActions.sendMessage(params, onSuccess, onError))
}


function* loadResearches({ search, onError = () => {} }) {
  try {
    const { data } = yield call(SendChatSearch, { search })
    yield put(ChatActions.loadResearchesSuccess(
      data?.data
    ))
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
    yield call(onError, error)
  }
}

function* loadResearchesMessage({ researchesMessage, conversationId, onError = () => {} }) {
  try {
    const { data } = yield call(getSearchMessageByConversationId, conversationId ,{ search: researchesMessage })
    yield put(ChatActions.loadResearchesMessageSuccess(
      data?.conversation
    ))
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
    yield call(onError, error)
  }
}

function* loadOfflineResearchesMessage({ params, onSuccess = () => {}, onError = () => {} }) {
  yield put(ChatActions.loadResearchesMessage(params, onSuccess, onError))
}

// function* saveOfflineMachine({ params, onSuccess = () => {}, onError = () => {} }) {
//   yield put(ChatActions.saveMachine(params, onSuccess, onError))
// }

// function* saveMachine({ params, onSuccess = () => {}, onError = () => {} }) {
//   try {
//     const { data } = yield call(createMachine, params)
//     yield call(onSuccess, data)
//   } catch (error) {
//     yield put(ChatActions.chatError(error))
//     yield call(onError, error)
//   }
// }

function* uploadFileMessage({ fileMessage, conversationId, uploadType, onError = () => {} }) {
  try {
    yield call(sendFileMessageByConversationId, conversationId, uploadType, fileMessage)

    const { data } = yield call(getChatMessagesByConversationId, conversationId)

    if(data?.data) {
      yield put(ChatActions.setCurrentConversation(data?.data))
    } else {
      yield put(ChatActions.setCurrentConversation([]))
    }
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
    yield call(onError)
  }
}

function* changeMessagesStatus({ receivedStats, idsArray }) {
  try {
    yield call(updateChatMessageStatus, { receivedStats, idsArray })
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
  }
}

function* loadContacts({ searchTerm, onSuccess }) {
  try {
    const { data } = yield call(getChatContacts, { search: searchTerm })

    yield put(ChatActions.loadContactsSuccess(data.result[0]))

    onSuccess()
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
  }
}

function* setNewContact({ userCode }) {
  try {
    yield call(addChatContact, userCode)
    const { data } = yield call(getChatContacts, { search: '' })

    yield put(ChatActions.loadContactsSuccess(data.result[0]))
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
  }
}

function* removeContact({ userCode }) {
  try {
    yield call(deleteChatContact, userCode)
    const { data } = yield call(getChatContacts, { search: '' })

    yield put(ChatActions.loadContactsSuccess(data.result[0]))
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
  }
}

function* markConversationAsFavorite({ id, params, onError = () => {} }) {
  try {
    yield call(patchConversation, id, params)
    yield put(ChatActions.loadConversations())
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
    yield call(onError)
  }
}

function* markMultipleConversationsAsFavorite({ idList, params, onError = () => {} }) {
  try {
    // TODO alterar rotina para quando haver uma rota que aceite N ids
    for (const [index, id] of idList.entries()) {
      yield call(patchConversation, id, params[index])
    }
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
    yield call(onError)
  }
}

function* markConversationAsFavoriteOffline({ id, params, onError = () => {} }) {
  try {
    yield put(ChatActions.markConversationAsFavorite(id, params, onError))
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
    yield call(onError)
  }
}

function* markMultipleConversationsAsFavoriteOffline({ idList, params, onError = () => {} }) {
  try {
    yield put(ChatActions.markMultipleConversationsAsFavorite(idList, params, onError))
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
    yield call(onError)
  }
}

function* updateConversationName({ id, params, onError = () => {} }) {
  try {
    const conversation = yield call(patchConversation, id, params)
    yield put(ChatActions.updateConversationNameSuccess(id, conversation))
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
    yield call(onError)
  }
}

function* updateConversationPhoto({ id, params, onError = () => {} }) {
  try {
    const conversation = yield call(updateConversationImage, id, params)
    yield put(ChatActions.updateConversationPhotoSuccess(id, conversation))
  } catch (err) {
    const error = err.message
    yield put(ChatActions.chatError(error))
    yield call(onError)
  }
}

function* sendAdvertising({ advertisingId, onSuccess = () => null, onError = () => {} }) {
  try {
    const { data } = yield call(sendAdvertisingService, advertisingId)

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield call(onError)
    yield put(ChatActions.chatError(error))
  }
}

function* refuseAdvertising({ advertisingId, reason, onSuccess = () => null, onError = () => {} }) {
  try {
    const { data } = yield call(refuseAdvertisingService, advertisingId, reason)

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield call(onError)
    yield put(ChatActions.chatError(error))
  }
}

function* createAdvertising({ params, onSuccess = () => null, onError = () => {} }) {
  try {
    const { data } = yield call(params?.id ? updateAdvertisingService : createAdvertisingService, params, { advertisingId: params?.id })

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield call(onError)
    yield put(ChatActions.chatError(error))
  }
}

function* deleteAdvertising({ advertisingId, onSuccess = () => null, onError = () => {} }) {
  try {
    const { data } = yield call(deleteAdvertisingService, advertisingId)

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield call(onError)
    yield put(ChatActions.chatError(error))
  }
}

function* createAdvertisingFiles({ formData, onSuccess = () => null, onError = () => {} }) {
  try {
    const { data } = yield call(createAdvertisingFilesService, formData)

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield call(onError)
    yield put(ChatActions.chatError(error))
  }
}

export default [
  takeLatest(ChatTypes.CREATE_CONVERSATION, createConversation),
  takeLatest(ChatTypes.LOAD_CONVERSATIONS, loadConversations),
  takeLatest(ChatTypes.LOAD_CURRENT_CONVERSATION, loadCurrentConversation),
  takeLatest(ChatTypes.LOAD_CURRENT_CONVERSATION_MESSAGES, loadCurrentConversationMessages),
  takeLatest(ChatTypes.SEND_MESSAGE, sendMessage),
  takeLatest(ChatTypes.DELETE_CONVERSATION, deleteConversation),
  takeLatest(ChatTypes.UPDATE_ADMIN_MEMBER, updateAdminMember),
  takeLatest(ChatTypes.UPDATE_OFFLINE_ADMIN_MEMBER, updateOfflineAdminMember),
  takeLatest(ChatTypes.SEND_OFFLINE_MESSAGE, sendOfflineMessage),
  takeLatest(ChatTypes.LOAD_RESEARCHES, loadResearches),
  takeLatest(ChatTypes.LOAD_RESEARCHES_MESSAGE, loadResearchesMessage),
  takeLatest(ChatTypes.LOAD_OFFLINE_RESEARCHES_MESSAGE, loadOfflineResearchesMessage),
  takeLatest(ChatTypes.CHANGE_MESSAGES_STATUS, changeMessagesStatus),
  takeLatest(ChatTypes.DELETE_CHAT_MESSAGES, deleteChatMessages),
  takeLatest(ChatTypes.UPLOAD_FILE_MESSAGE, uploadFileMessage),
  takeLatest(ChatTypes.LOAD_CONTACTS, loadContacts),
  takeLatest(ChatTypes.DELETE_GROUP_MEMBER, deleteGroupMember),
  takeLatest(ChatTypes.DELETE_OFFLINE_GROUP_MEMBER, deleteOfflineGroupMember),
  takeLatest(ChatTypes.SET_NEW_CONTACT, setNewContact),
  takeLatest(ChatTypes.REMOVE_CONTACT, removeContact),
  takeLatest(ChatTypes.CREATE_GROUP, createGroup),
  takeLatest(ChatTypes.CREATE_OFFLINE_GROUP, createOfflineGroup),
  takeLatest(ChatTypes.ADD_GROUP_MEMBER, addGroupMember),
  takeLatest(ChatTypes.ADD_OFFLINE_GROUP_MEMBER, addOfflineGroupMember),

  takeLatest(ChatTypes.MARK_CONVERSATION_AS_FAVORITE, markConversationAsFavorite),
  takeLatest(ChatTypes.MARK_MULTIPLE_CONVERSATIONS_AS_FAVORITE, markMultipleConversationsAsFavorite),
  takeLatest(ChatTypes.MARK_CONVERSATION_AS_FAVORITE_OFFLINE, markConversationAsFavoriteOffline),
  takeLatest(ChatTypes.MARK_MULTIPLE_CONVERSATIONS_AS_FAVORITE_OFFLINE, markMultipleConversationsAsFavoriteOffline),
  takeLatest(ChatTypes.LOAD_CONTACTS, loadContacts),
  takeLatest(ChatTypes.SET_NEW_CONTACT, setNewContact),
  takeLatest(ChatTypes.REMOVE_CONTACT, removeContact),
  takeLatest(ChatTypes.SEND_ADVERTISING, sendAdvertising),
  takeLatest(ChatTypes.REFUSE_ADVERTISING, refuseAdvertising),
  takeLatest(ChatTypes.CREATE_ADVERTISING, createAdvertising),
  takeLatest(ChatTypes.DELETE_ADVERTISING, deleteAdvertising),
  takeLatest(ChatTypes.CREATE_ADVERTISING_FILES, createAdvertisingFiles),
  takeLatest(ChatTypes.UPDATE_CONVERSATION_NAME, updateConversationName),
  takeLatest(ChatTypes.UPDATE_CONVERSATION_PHOTO, updateConversationPhoto)
]
