import React, { useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'
import * as Yup from 'yup'

import find from 'lodash/find'
import flow from 'lodash/fp/flow'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import reduce from 'lodash/reduce'
import toNumber from 'lodash/toNumber'

import { Scope } from '@smartcoop/forms'
import float from '@smartcoop/forms/validators/float.validator'
import minNumberValue from '@smartcoop/forms/validators/minNumberValue.validator'
import number from '@smartcoop/forms/validators/number.validator'
import required from '@smartcoop/forms/validators/required.validator'
import I18n, { useT } from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import DynamicInput from '@smartcoop/web-components/DynamicInput'
import Form from '@smartcoop/web-components/Form'
import { FormLabel, FormLabelSubtitle } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import useStyles from './styles'


const DynamicForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, fields, disabled } = props

  const classes = useStyles()

  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      const onlyFields = reduce(
        fields,
        (acc, field) => [...acc, ...field.formFields],
        []
      )

      const formattedDate = mapValues(data, (formFieldsValues) => mapValues(formFieldsValues, (fieldValue, fieldName) => {
        const formField = find(onlyFields, f => f.name === fieldName)
        switch(formField.type) {
          case 'number':
          case 'float':
          case 'integer':
            return toNumber(fieldValue)
          default:
            return fieldValue
        }
      }))
      onSubmit(formattedDate)
    },
    [fields, onSubmit]
  )

  const formInputTypeSelect = useCallback(
    (type) => {
      switch (type) {
        case 'float':
          return float
        case 'integer':
        case 'number':
          return number
        default:
          return () => (param) => param
      }
    },
    []
  )

  const minimumValueSelect = useCallback(
    (type) => (
      type === 'float' || type === 'integer' || type === 'number'
        ? minNumberValue
        : () => (param) => param
    ),
    []
  )

  const handleSchemaConstructor = useCallback(
    () => Yup.object().shape(reduce(
      fields,
      (acc, field) => {
        const fieldFormFields = reduce(
          field.formFields,
          (formFieldsAcc, formField) => ({
            ...formFieldsAcc,
            [formField.name]: flow(
              formInputTypeSelect(formField.type)({ t }),
              minimumValueSelect(formField.type)({ t, field: formField.label }),
              required({ t })
            )(Yup.string())
          }),
          {}
        )
        return {
          ...acc,
          [field.id]: Yup.object().shape(fieldFormFields)
        }
      },
      {}
    )),
    [fields, formInputTypeSelect, minimumValueSelect, t]
  )

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ handleSchemaConstructor }
      onSubmit={ handleSubmit }
      style={ { display: 'flex', flexDirection: 'column' } }
    >
      {
        map(fields, ({ name, subtitle = null, id, formFields }) => (
          <Scope path={ id } key={ id }>
            <FormLabel>
              { name }
            </FormLabel>
            <FormLabelSubtitle>
              {subtitle}
            </FormLabelSubtitle>
            {map(formFields, ({ name: fieldName, label, type, unit, defaultValue, disabled: internalDisabled, ...rest }) => (
              <DynamicInput
                key={ fieldName }
                type={ type }
                unit={ unit }
                name={ fieldName }
                label={ label }
                defaultValue={ defaultValue }
                disabled={ loading || disabled || internalDisabled }
                { ...rest }
              />
            ))}
          </Scope>
        ))
      }

      {!withoutSubmitButton && (
        <div className={ classes.buttonsContainer }>
          <Button
            id="next-dynamic"
            type="submit"
            variant="contained"
            color="secondary"
            aria-label="next-dynamic"
            disabled={ loading }
            className={ classes.button }
          >
            <I18n>next</I18n>
          </Button>
        </div>
      )}
    </Form>
  )
})

DynamicForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  fields: PropTypes.array.isRequired,
  disabled: PropTypes.bool
}

DynamicForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false,
  disabled: false
}

export default DynamicForm
