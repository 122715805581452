import styled, { css } from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import Button from '@smartcoop/web-components/Button'

export const ContainerListFields = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
  flex: 1;
  max-height: calc(100vh - 231px);

  ${ ({ isCenter }) => isCenter && css`
    justify-content: center;
    align-items: center;
  ` }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 15px;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const ButtonFilter = styled(Button)`
  margin-right: 8px;
  border-color: colors.lightgrey;
@media (max-width: 1200px) {
    margin-right: 0;
    margin-bottom: 5px;
}
@media (max-width: 960px) {
    margin-right: 8px;
    margin-bottom: 0;
}
`

export const ButtonContainer = styled.div`
@media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
@media (max-width: 960px) {
    display: block;
    flex-direction: none;
    align-items: none;
}
`

export const SpaceButton = styled.div`
  padding-right: 20px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

export const TabsContainer = styled.div`
  display: flex;
  width: 100%;
`

export const SearchContainer = styled.div`
  padding: 0 15px;
`

export const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Label = styled.span`
  font-weight: 600;
`
