import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import debounce from 'lodash/debounce'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { emptyFilter, openLayout, cutLayout, machinesPinMarkerCount } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { MachineActions } from '@smartcoop/stores/machine'
import { selectMachinery } from '@smartcoop/stores/machine/selectorMachine'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import Button from '@smartcoop/web-components/Button'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Icon from '@smartcoop/web-components/Icon'
import InputSearch from '@smartcoop/web-components/InputSearch'
import Loader from '@smartcoop/web-components/Loader'
import MachineItem from '@smartcoop/web-components/MachineItem'
import Maps from '@smartcoop/web-components/Maps'
import Control from '@smartcoop/web-components/Maps/components/Control'
import PinMarker from '@smartcoop/web-components/Maps/markers/PinMarker'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import MachineDetailsFragment from './MachineDetailsFragment'
import MachineDetailsModal from './MachineDetailsModal'
import { ContainerListFields, SpaceButton, SearchContainer, ButtonContainer, TabsContainer, MapContainer, Label } from './styles'

const MachineryListScreen = () => {
  const dispatch = useCallback(useDispatch(), [])
  const { createDialog } = useDialog()
  const { routes } = useRoutes()
  const snackbar = useSnackbar()
  const history = useHistory()
  const t = useT()

  const mapRef = useRef(null)
  const location = useLocation()

  const currentProperty = useSelector(selectCurrentProperty)
  const machinery = useSelector(selectMachinery)
  const userWrite = useSelector(selectUserCanWrite)

  const [debouncedFilterText, setDebouncedFilterText] = useState('')
  const [activeTab, setActiveTab] = useState(location?.state?.activeTab || 'all-machines')
  const [selectedMachine, setSelectedMachine] = useState({})
  const [isUserMachine, setIsUserMachine] = useState(false)
  const [filterText, setFilterText] = useState('')
  const [loading, setLoading] = useState(false)
  const [opened, setOpened] = useState(true)
  const [selectedPin, setSelectedPin] = useState('')
  const [available, setAvailable] = useState('')

  const machineryByPropertyId = useMemo(
    () => groupBy(machinery, 'propertyId'),
    [machinery]
  )

  const openModal = useCallback(
    (machines) => createDialog({
      id: 'user-not-found',
      Component: MachineDetailsModal,
      props: { machines }
    }),
    [createDialog]
  )

  const handleMachineryRegisterClick = useCallback(
    () => {
      history.push(routes.machineryRegister.path)
    },
    [history, routes]
  )

  const handleItemClick = useCallback(
    ({ machine }) => {
      setSelectedPin(machine.propertyId)
      if(!isEmpty(selectedMachine) && isEqual(machine.property?.geolocalization, selectedMachine.property?.geolocalization)) {
        return setSelectedMachine(
          {
            ...machine,
            property: {
              ...machine.property,
              geolocalization: isEmpty(selectedMachine.property?.geolocalization) ? {} : {
                ...machine.property?.geolocalization,
                latitude: Number(selectedMachine.property?.geolocalization.latitude) + 0.0000000000001
              }
            }
          }
        )
      }
      return setSelectedMachine({ ...machine })
    },
    [selectedMachine]
  )

  const handleViewClick = useCallback(({ machine }) => {
    setSelectedMachine({ ...machine })
    setSelectedPin(machine?.propertyId)
    openModal([machine])
  }, [openModal])

  const handleParams = useCallback(
    (values) => (
      Object.keys(values)
        .filter(
          (key) => typeof values[key] === 'boolean' || values[key]
        )
        .reduce(
          (prev, curr) => ({ ...prev, [curr]: values[curr] }), {}
        )
    ),
    []
  )

  const toggleLayout = useCallback(() => {
    setOpened((old)=> !old)
  }, [])

  const filters = useMemo(
    () => ({
      my_machines: activeTab === 'my-machines',
      property_id: currentProperty?.id,
      rentAvailable: available === '' ? true : available === 'rent' || null,
      saleAvailable: available === '' ? true : available === 'sell' || null,
      serviceProvisionAvailable: available === '' ? true : available === 'service' || null
    }), [activeTab, available, currentProperty.id]
  )

  const params = useMemo(
    () => {
      const filterParams = ({
        ...filters,
        q: debouncedFilterText
      })
      return handleParams(filterParams)
    },
    [debouncedFilterText, filters, handleParams]
  )

  const iconChange = useMemo(
    () => opened ? openLayout : cutLayout,
    [opened]
  )

  const loadMachinery = useCallback(
    debounce(
      () => {
        dispatch(MachineActions.loadMachinery(
          params,
          (data) => {
            activeTab === 'my-machines' ? setSelectedMachine(data[0]) : setSelectedMachine({})
            setLoading(false)
            setIsUserMachine(activeTab === 'my-machines')
          },
          () => setLoading(false)
        ))
      },
      300
    ),
    [dispatch, params]
  )

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )


  const handleActiveTab = useCallback(
    (tabName) => {
      setLoading(true)
      setActiveTab(tabName)
      setLoading(false)
    }
    ,
    []
  )

  const handleDeleteMachine = useCallback(
    (id) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            setLoading(true)
            dispatch(MachineActions.deleteMachine(id,
              () => {
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'female',
                    this: t('machine', { howMany: 1 })
                  })
                )
                loadMachinery()
              },
              () => {
                setLoading(false)
              }
            ))
          },
          textWarning: t('are you sure you want to delete the machine?')
        }
      })
    },
    [createDialog, dispatch, loadMachinery, snackbar, t]
  )

  useEffect(
    () => {
      if (!isEmpty(currentProperty)) {
        setLoading(true)
        loadMachinery()
      }
    },
    [
      dispatch,
      currentProperty,
      loadMachinery
    ]
  )

  useEffect(()=> {
    mapRef.current && mapRef.current.leafletElement._onResize()
  }, [opened])

  const availableOptions = useMemo(
    () => (
      [
        {
          label: t('all', { gender: 'female' }),
          value: ''
        },
        {
          label: t('sell'),
          value: 'sell'
        },
        {
          label: t('service'),
          value: 'service'
        },
        {
          label: t('rent'),
          value: 'rent'
        }
      ]
    ),
    [t]
  )

  return (
    <SplitedScreenLayout
      withoutGoBack
      withoutDivider
      title={ { name: t('machinery', { howMany: 2 }) } }
      divRightStyle={ { padding: 0 } }
      leftActions={ opened && (
        <>
          <SpaceButton>
            <ButtonContainer>
              <Button
                id="register-machinery"
                onClick={ handleMachineryRegisterClick }
                color="secondary"
                disabled={ loading || !userWrite }
              >
                <I18n>register machinery</I18n>
              </Button>
            </ButtonContainer>
          </SpaceButton>
        </>
      ) }
      leftChildren={ opened && (
        <>
          <TabsContainer>
            <Button
              id="all-machines-button"
              onClick={ () => handleActiveTab('all-machines') }
              title={ t('rental machines') }
              variant="text"
              style={ {
                fontSize: 14,
                flex: 1,
                fontWeight: 'bold',
                backgroundColor: 'transparent',
                borderBottom: '5px solid',
                borderColor: `${ activeTab === 'all-machines' ? colors.black : colors.transparent }`,
                color: `${ activeTab === 'all-machines' ? colors.black : colors.grey }`,
                borderRadius: 0
              } }
            >
              <I18n>search machines</I18n>
            </Button>
            <Button
              id="my-machines-button"
              onClick={ () => handleActiveTab('my-machines') }
              variant="text"
              style={ {
                fontSize: 14,
                flex: 1,
                fontWeight: 'bold',
                backgroundColor: 'transparent',
                borderBottom: '5px solid',
                borderColor: `${ activeTab === 'my-machines' ? colors.black : colors.transparent }`,
                color: `${ activeTab === 'my-machines' ? colors.black : colors.grey }`,
                borderRadius: 0
              } }
            >
              <I18n>my machines</I18n>
            </Button>
          </TabsContainer>
          {activeTab === 'all-machines' && (
            <SearchContainer>
              <RadioGroup
                detached
                label=''
                options={ availableOptions }
                variant="row"
                style={ { marginTop: '5px' } }
                containerStyle={ { justifyContent: 'space-between' } }
                onChange={ item => setAvailable(item.value) }
                value={ available }
              />
            </SearchContainer>

          )}
          <SearchContainer>
            <InputSearch
              style={ { marginTop: 8 } }
              adornmentStyle={ { marginRight: 15 } }
              detached
              onChange={ onChangeSearchFilter }
              value={ filterText }
              placeholder={ t('search') }
              fullWidth
            />
          </SearchContainer>
          <ContainerListFields isCenter={ isEmpty(machinery) }>

            {loading ? (
              <Loader width={ 100 } />
            ) :
              (map(machinery, (item) => (
                <MachineItem
                  machine={ item }
                  key={ item.id }
                  onClick={ handleItemClick }
                  onViewClick={ handleViewClick }
                  active={ item.id === selectedMachine.id }
                  t={ t }
                />
              )))
            }
            {!loading && isEmpty(machinery) && (
              <EmptyState
                text={ t('no results found') }
                icon={ emptyFilter }
              />
            ) }
          </ContainerListFields>
        </>
      ) }
      rightChildren={ isUserMachine ? (
        <>
          {
            loading ? <Loader width={ 100 } /> : (
              !isEmpty(selectedMachine) &&
              <MachineDetailsFragment
                machine={ selectedMachine }
                isUserMachine={ isUserMachine }
                onDeleteMachine={ handleDeleteMachine }
              />
            )
          }
        </>
      ) : (<>
        {loading ? <Loader width={ 100 } /> : (
          <MapContainer>
            <Maps
              ref={ mapRef }
              region={ !isEmpty(selectedMachine?.property?.geolocalization) ? selectedMachine?.property?.geolocalization : null }
              zoom={ !isEmpty(selectedMachine) ? 11 : 7 }
              style={ { flex: 1, overflow: 'none' } }
              cursor="crosshair"
              layer="google"
              maxZoom={ 11 }
            >
              <Control position="topcenter">
                <div
                  style={ {
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '0 10px',
                    paddingRight: '70px'
                  } }
                >
                  <div>
                    <Button
                      id="toggle-Layout"
                      color={ colors.white }
                      style={ {
                        padding: '9px 10px',
                        fontSize: '0.875rem',
                        marginRight: 10
                      } }
                      onClick={ toggleLayout }
                    >
                      <Icon
                        icon={ iconChange }
                        size={ 17 }
                      />
                    </Button>
                  </div>
                  <div>
                    <Button
                      id="details-informations"
                      color={ colors.shadow }
                      style={ {
                        padding: '5.5px 10px',
                        fontColor: colors.white,
                        fontSize: '0.875rem',
                        cursor: 'default',
                        textTransform: 'none'
                      } }
                    >
                      <>
                        <I18n as={ Label }>For more details click on:</I18n>
                        <Icon
                          icon={ machinesPinMarkerCount }
                          size={ 27 }
                          style={ { marginLeft: 6 } }
                        />
                      </>
                    </Button>
                  </div>
                  <div />
                </div>
              </Control>
              {
                map(machineryByPropertyId, (machineryPin) => !isEmpty(machineryPin[0]?.property?.geolocalization) && (
                  <PinMarker
                    key={ machineryPin?.id }
                    coordinate={ machineryPin[0]?.property?.geolocalization }
                    customIcon={ machinesPinMarkerCount }
                    size={ 45 }
                    iconProps={ { color: selectedPin === machineryPin[0]?.propertyId ? '#3eb38a' : '#ff5f6e', count: machineryPin.length } }
                    iconAnchor={ {
                      x: 13,
                      y: 40
                    } }
                    onClick={ () => {
                      setSelectedMachine({})
                      setSelectedPin(machineryPin[0]?.propertyId)
                      openModal(machineryPin)
                    } }
                  />
                ))
              }
            </Maps>
          </MapContainer>
        )}
      </>) }
    />
  )}



export default MachineryListScreen
