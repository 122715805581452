import styled from 'styled-components'


export const CheckboxTouchableContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const CheckboxContainer = styled.div`
  background-color: #FFF;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 30px;
  padding: 11px 15px 11px 15px;
  margin-bottom: 15px;
`

export const IconContainer = styled.div`
  max-width: 10px;
  min-width: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
`

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
`
