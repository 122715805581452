import * as Yup from 'yup'

const filterWeightControl = () => Yup.object().shape({
  date: Yup.object().nullable(),

  earring: Yup.string(),
  weight: Yup.string(),
  height: Yup.string()
})

export default filterWeightControl
