import api from '../api'

export const getActivities = async (params) => {
  const { data } = await api.get('/v1/activities', { params })
  return data
}

export const createActivity = (params) => api.post('/v1/activities', params)

export const editActivity = ({ params }, { activityId }) => api.patch(`/v1/activities/${ activityId }`, params)

export const deleteActivity = ({ activityId }) => api.delete(`/v1/activities/${ activityId }`)
