import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import { colors } from '@smartcoop/styles'

export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid ${ colors.lightGrey };
  margin: 0;
`

export default makeStyles({
  header: {
    display: 'inline-flex',
    flex: '0 0 auto',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  title: {
    flex: 'auto !important',
    marginBottom: 10,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    padding: '12px 13px 0 13px'
  }
})
