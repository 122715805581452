import React, { useRef, useMemo, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { find } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import mapLodash from 'lodash/map'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import {
  erlenmeyer,
  doc,
  shovel
} from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { getFieldsByPropertyId as getFieldsByPropertyIdService } from '@smartcoop/services/apis/smartcoopApi/resources/field'
import { useSnackbar } from '@smartcoop/snackbar'
import { FieldActions } from '@smartcoop/stores/field'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import colors from '@smartcoop/styles/colors'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import  { getPolygonCenter, findMapZoom } from '@smartcoop/utils/maps'
import Button from '@smartcoop/web-components/Button'
import CooperativeDocument from '@smartcoop/web-components/CooperativeDocument'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Icon from '@smartcoop/web-components/Icon'
import InputFile  from '@smartcoop/web-components/InputFile'
import Loader from '@smartcoop/web-components/Loader'
import Maps from '@smartcoop/web-components/Maps'
import Control from '@smartcoop/web-components/Maps/components/Control'
import Polygon from '@smartcoop/web-components/Maps/components/Polygon'
import PinMarker from '@smartcoop/web-components/Maps/markers/PinMarker'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import FieldAnalysisForm from '@smartcoop/web-containers/forms/digitalProperty/field/FieldAnalysisForm/FieldAnalysisForm'
import useFile from '@smartcoop/web-containers/hooks/useFile'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { Container, Header, Title, IconContainer, MapContainer, InfoCard, WhiteCard, CardChildren } from './styles'

const FieldAnalysisRegisterScreen = () => {
  const t = useT()
  const history = useHistory()
  const { routes } = useRoutes()
  const fieldAnalysisFormRef = useRef(null)
  const mapRef = useRef(null)
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const location = useLocation()
  const { createDialog } = useDialog()

  const {
    selectedFiles,
    receivedFiles,
    isEmpty: isEmptyFiles,
    handleAdd,
    createFormData,
    handleRemove
  } = useFile([], location.state?.analysis?.soilAnalysisDocuments ?? [])

  const [fieldAnalysisRegisterScreenFiles, setFieldAnalysisRegisterScreenFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedField, setSelectedField] = useState(location.state?.analysis?.fieldId)
  const [imagesToBeRemoved, setImagesToBeRemoved] = useState([])
  const [fieldsOptions, setFieldsOptions] = useState([])
  const [currentMapZoom, setCurrentMapZoom] = useState(null)
  const [allFields, setAllFields] = useState([])

  const propertyId = useSelector(selectCurrentPropertyId)

  const analysis = useMemo(
    () => ({
      ...location?.state?.analysis
    }), [location]
  )

  const [markerCoordinate, setMarkerCoordinate] = useState(analysis?.pinLocation)

  const loadFieldOptions = useCallback(
    async () => {
      const result = await getFieldsByPropertyIdService({}, { propertyId })
      setAllFields(result)
      setFieldsOptions(mapLodash(result, item => ({ value: item?.id, label: item?.fieldName })))
    },
    [propertyId]
  )

  useEffect(() => {
    loadFieldOptions()
  },[loadFieldOptions])

  const currentField = useMemo(() => find(allFields, item => item.id === selectedField),[allFields, selectedField])

  const mapZoom = useMemo(() =>
    currentField?.polygonCoordinates ? findMapZoom(currentField?.polygonCoordinates) : 17
  , [currentField])

  const mapCenterPosition = useMemo(() =>
    currentField?.polygonCoordinates ? getPolygonCenter(currentField.polygonCoordinates) : null
  , [currentField])

  useEffect(() => {
    if(isEmpty(analysis?.pinLocation) && mapCenterPosition) {
      setMarkerCoordinate(mapCenterPosition)
    }
  },[analysis, mapCenterPosition])

  const handleClose = useCallback(
    () => (
      history.push(routes.fieldList.path, { isAnalysis: true })
    ),
    [history, routes.fieldList.path]
  )

  const onDragendMarker = useCallback((event) => {
    setMarkerCoordinate({
      latitude: event.target._latlng.lat,
      longitude: event.target._latlng.lng
    })
  }, [])

  const onSuccess = useCallback(
    () => {
      setIsLoading(false)
      snackbar.success(
        t(analysis?.id ? 'your analysis was edited': 'your analysis was registered')
      )
      handleClose()
    },
    [analysis, handleClose, snackbar, t]
  )

  const submit = useCallback(
    () => {
      fieldAnalysisFormRef.current.submit()
    },
    []
  )

  const submitNewFiles = useCallback(
    (analysisId) => {
      if (isEmpty(selectedFiles)) {
        onSuccess()
      } else {
        dispatch(FieldActions.saveFieldAnalysisDocument(createFormData('uploads'), analysisId,
          onSuccess,
          () => setIsLoading(false)
        ))
      }
    },
    [createFormData, dispatch, onSuccess, selectedFiles]
  )

  const submitForms = useCallback(
    (data) => {
      setIsLoading(true)
      if(analysis?.id) {
        if(!isEmpty(imagesToBeRemoved)) {
          dispatch(FieldActions.deleteFieldAnalysisDocuments(mapLodash(imagesToBeRemoved, item => ({ ...item, soilAnalysisId: analysis?.id }))))
        }
        dispatch(FieldActions.updateOfflineFieldAnalysis(
          data,
          analysis?.id,
          (response) => submitNewFiles(response?.id),
          () => setIsLoading(false)
        ))
      } else {
        dispatch(FieldActions.saveOfflineFieldAnalysis(
          data,
          selectedField,
          (response) => {
            submitNewFiles(response?.id)
          },
          () => setIsLoading(false),
          true
        ))
      }
    },
    [analysis.id, dispatch, imagesToBeRemoved, selectedField, submitNewFiles]
  )

  const handleDeleteDocument = useCallback(
    (list, removedFile) => {
      createDialog({
        id: 'confirm-delete-doc',
        Component: ConfirmModal,
        props: {
          onConfirm: () => handleRemove(list, removedFile),
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('archive', { howMany: 1 })
          })
        }
      })
    },
    [createDialog, handleRemove, t]
  )

  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_SOIL_ANALYSIS_ID, fileKey: file?.fileKey })
    return downloadFromBase64(data)
  }, [])

  useEffect(() => {
    const getRenderedFiles = async() => {
      const receivedElements = await Promise.all(mapLodash(receivedFiles, async(file) => {
        const fileSrc = await renderFile(file)
        return (
          <CooperativeDocument
            key={ file.id }
            documentName={ file.fileName }
            deleteDocument={ () => {
              setImagesToBeRemoved(old => [...old, file])
              handleDeleteDocument('receivedFiles', file)
            } }
            documentSrc={ fileSrc }
          />
        )}).concat(
        mapLodash(selectedFiles, (file, index) => (
          <CooperativeDocument
            key={ `${ index }-${ file.name }` }
            documentName={ file.name }
            deleteDocument={ () => handleDeleteDocument('selectedFiles', file) }
            documentSrc={ URL.createObjectURL(file) }
          />
        ))
      ))
      setFieldAnalysisRegisterScreenFiles(receivedElements)
    }
    getRenderedFiles()
  },[handleDeleteDocument, receivedFiles, renderFile, selectedFiles]  )


  const currentIconSize = useMemo(() => {
    switch(currentMapZoom) {
      case 18:
        return 60
      case 17:
        return 55
      case 16:
        return 50
      case 15:
        return 45
      default:
        return currentMapZoom * 2
    }
  }, [currentMapZoom])

  return (
    isLoading ? <Loader width={ 100 } /> :
      <SplitedScreenLayout
        title={ { name: t('field', { howMany: 1 }) } }
        divRightStyle={ { padding: 0 } }
        leftChildren={
          <Container>
            <Header>
              <IconContainer>
                <Icon icon={ erlenmeyer } size={ 18 } />
              </IconContainer>

              <Title style={ { fontSize: 18, fontWeight: 600 } }>
                <I18n>{analysis?.id ? 'edit soil analysis' : 'register soil analysis'}</I18n>
              </Title>

            </Header>
            <FieldAnalysisForm
              ref={ fieldAnalysisFormRef }
              onSubmit={ submitForms }
              markerCoordinate={ markerCoordinate }
              withoutSubmitButton
              defaultValues={ analysis }
              selectedField={ selectedField }
              setSelectedField={ setSelectedField }
              propertyId={ propertyId }
              fieldsOptions={ fieldsOptions }
            />

            <div
              style={ {
                height: 42,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              } }
            >
              <InputFile
                idInput="contained-button-file"
                onChange={ handleAdd }
                inputProps={ {
                  accept: 'image/png,image/jpeg,image/tiff,application/pdf,.doc,.docx',
                  multiple: true
                } }
                maxNumberFile={ 20 - selectedFiles.length }
                maxNumberFileDisplay={ 20 }
                buttonProps={ {
                  id: 'attach-documents',
                  style: {
                    flex: '0 0 auto',
                    fontSize: 14,
                    fontWeight: 600,
                    boxShadow: 'none',
                    border: `1px solid ${ colors.lightGrey }`,
                    padding: '4px 8px'
                  },
                  color: colors.white
                } }
              >
                <>
                  <Icon icon={ doc } size={ 16 } style={ { marginRight: 5 } } />
                  <I18n>attach documents</I18n>
                </>
              </InputFile>
            </div>
            <WhiteCard title={ t('archive', { howMany: 2 }) }>
              <CardChildren>
                {isEmptyFiles ? (
                  <EmptyState text={ t('no file') } />
                ) : (
                  fieldAnalysisRegisterScreenFiles
                )}
              </CardChildren>
            </WhiteCard>

            <ButtonsContainer style={ { paddingTop: 30 } }>
              <Button
                id="cancel-field-form"
                onClick={ handleClose }
                style={ { flex: 1 } }
                variant="outlined"
                disabled={ isLoading }
              >
                <I18n>cancel</I18n>
              </Button>

              <div style={ { width: '10%' } } />

              <Button
                id="submit-field-form"
                onClick={ submit }
                style={ { flex: 1 } }
                disabled={ isLoading }
              >
                <I18n>save</I18n>
              </Button>
            </ButtonsContainer>
          </Container>
        }
        rightChildren={
          <>

            <MapContainer>
              <Maps
                zoom={ mapZoom }
                region={ mapCenterPosition }
                layer="google"
                ref={ mapRef }
                onZoomEnd={ () => setCurrentMapZoom(mapRef?.current?.viewport?.zoom) }
              >
                <Control position="topright">
                  <InfoCard>
                    <I18n>{isEmpty(currentField) ? 'select desired field' : 'click and drag the pin to the desired location'}</I18n>
                  </InfoCard>
                </Control>
                <PinMarker
                  coordinate={ markerCoordinate }
                  draggable
                  onDragend={ onDragendMarker }
                  customIcon={ shovel }
                  size={ currentIconSize }
                  iconAnchor={ {
                    x: 13,
                    y: 30
                  } }
                />
                <Polygon
                  points={ currentField?.polygonCoordinates }
                  color={ colors.secondary }
                />
              </Maps>
            </MapContainer>
          </>
        }
      />
  )
}

export default FieldAnalysisRegisterScreen
