import moment from 'moment'

import last from 'lodash/last'

import { conversationService } from '@smartcoop/database/services/conversationService'
import { messageService } from '@smartcoop/database/services/messageService'
import { blobToBase64 } from '@smartcoop/database/utils/base64Blob'
import { database } from '@smartcoop/database/web-database'
import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'

export function useSendFile(conversation, localUser) {
  const t = useT()
  const snackbar = useSnackbar()

  const getConversationDto = () => ({
    id: conversation.relationalId,
    userCodes: [
      {
        userId: localUser?.userId,
        code: localUser?.userCode
      },
      {
        userId: conversation?.contactId,
        code: conversation?.userCode
      }
    ]
  })

  const sendFile = async (files, type) => {
    try {
      // Precisa criar a conversa se ainda não existir
      if (!conversation.conversationId && !conversation.group && !conversation.broadcast) {
        const dto = getConversationDto()
        await conversationService(database).createConversation(dto)
      }

      const promises = files.map(file => async () => {
        const fileExtension = last(file.name.split('.'))
        const dataBase64 = await blobToBase64(file)

        await messageService(database).sendFile({
          senderId: localUser.userId,
          receiverId: conversation.contactId,
          message: dataBase64,
          conversationId: conversation.conversationId
        }, {
          format: fileExtension,
          documentType: type,
          isGroup: conversation.group || conversation.broadcast
        })
      })

      await Promise.all(promises.map(fn => fn()))
    } catch(error) {
      snackbar.error(t('error send file. contact your system administrator'))
    }
  }

  const sendAudio = async file => {
    const blobFile = new File([file], `smartcoop-audio-${ moment().format() }.mp3`, {
      type: 'audio/mpeg'
    })

    const dataBase64 = await blobToBase64(blobFile)

    if (!conversation.conversationId && !conversation.group && !conversation.broadcast) {
      const dto = getConversationDto()
      await conversationService(database).createConversation(dto)
    }

    await messageService(database).sendFile({
      senderId: localUser.userId,
      receiverId: conversation.contactId,
      message: dataBase64,
      conversationId: conversation.conversationId
    }, {
      isGroup: conversation.group || conversation.broadcast
    })
  }

  return {
    sendFile,
    sendAudio
  }
}
