import React from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import upperFirst from 'lodash/upperFirst'

import Divider from '@material-ui/core/Divider'

import I18n from '@smartcoop/i18n'
import {
  calendar
} from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import { momentFriendlyDateFormat, momentBackDateFormat } from '@smartcoop/utils/dates'
import Icon from '@smartcoop/web-components/Icon'

import {
  Container,
  Header,
  ManagementName,
  DateContent,
  TextContent,
  DetailsManagement,
  DetailsText,
  DateSingle,
  HeaderEnd
} from './style'

const RecommendationFragment = (props) => {
  const {
    cropManagementData
  } = props

  return(
    <Container>
      <Header>
        {!isEmpty(cropManagementData.operation) && (
          <TextContent>
            <ManagementName>
              {cropManagementData.operation.name}
            </ManagementName>
            <DateContent>
              <DateSingle>
                <I18n>scheduled to:</I18n>
                <Icon
                  style={ { margin: '0 5px 0 3px' } }
                  size={ 10 } icon={ calendar }
                  color={ colors.green }
                />
                {moment(cropManagementData.predictedDate, momentBackDateFormat).format(momentFriendlyDateFormat)}
              </DateSingle>
            </DateContent>
          </TextContent>
        )}
        <HeaderEnd>
          {cropManagementData?.growingSeason?.field?.fieldName} - {cropManagementData?.growingSeason?.crop?.description}/{cropManagementData?.growingSeason?.sowingYear}
        </HeaderEnd>
      </Header>
      <Divider/>
      <DetailsManagement>
        {cropManagementData?.itens?.formFields ? (
          <>
            <DetailsText>
              <div style={ { fontWeight: 600 } }>
                <I18n>hour</I18n>
              </div>
              <div style={ { fontSize: 16 } }>
                <p>{cropManagementData?.itens?.hour}</p>
              </div>
            </DetailsText>
            {map(cropManagementData.itens?.formFields, (item, itemKey) => (
              <div key={ itemKey }>
                {
                  map(item, (value, key) => (!isEmpty(value) && value && key !== 'referenceId' && key !== 'productId') && (
                    <DetailsText key={ `${ key }-${ itemKey }` }>
                      <div style={ { fontWeight: 600 } }>
                        <I18n>{key.toString()}</I18n>
                      </div>
                      <div style={ { fontSize: 16 } }>
                        <p>{value.name ?? key === 'unit' ? value : upperFirst(value)}</p>
                      </div>
                    </DetailsText>
                  ))
                }
              </div>
            ))}
          </>
        )
          : cropManagementData.itens && (
            map(cropManagementData.itens, (value, key) => (
              <>
                {
                  key !== 'productGroupSeeding' && (
                    <DetailsText key={ key }>
                      <div style={ { fontWeight: 600 } }>
                        <I18n>{key.toString()}</I18n>
                      </div>
                      <div style={ { fontSize: 16 } }>
                        <p>{value.name ?? key === 'unit' ? value : upperFirst(value)}</p>
                      </div>
                    </DetailsText>
                  )
                }
              </>
            )))}
        {cropManagementData.observations && (
          <DetailsText>
            <p style={ { fontWeight: 600 } }>
              Observações
            </p>
            <div style={ { fontSize: 16 } }>
              <p>{cropManagementData.observations}</p>
            </div>
          </DetailsText>
        )}
      </DetailsManagement>
    </Container>
  )
}

RecommendationFragment.propTypes = {
  cropManagementData: PropTypes.object
}

RecommendationFragment.defaultProps = {
  cropManagementData: {}
}

export default RecommendationFragment
