/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useMemo, useEffect, useState } from 'react'
import { ImageOverlay, Popup, Tooltip as TooltipMap } from 'react-leaflet'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'


import moment from 'moment/moment'

import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import round from 'lodash/round'
import size from 'lodash/size'
import upperFirst from 'lodash/upperFirst'

import Badge from '@material-ui/core/Badge'
import Divider from '@material-ui/core/Divider'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import {
  bug,
  chart,
  pencil,
  trash,
  field as fieldIcon,
  bugMarkerRound as bugMarkerRoundIcon,
  emptyCrop,
  emptyManagement,
  cutLayout,
  plantAndHand,
  openLayout,
  productivityChallange,
  bell,
  download
} from '@smartcoop/icons'
import { getCosts } from '@smartcoop/services/apis/smartcoopApi/resources/costs'
import { useSnackbar } from '@smartcoop/snackbar'
import { ChallengesActions } from '@smartcoop/stores/challenges'
import { FieldActions } from '@smartcoop/stores/field'
import { selectCurrentField, selectLoadingCurrentField, selectCurrentFieldHistory, selectFieldNavigationData } from '@smartcoop/stores/field/selectorField'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectFamilyGroupAccess, selectFamilyGroupFinancialDataAccess } from '@smartcoop/stores/property/selectorProperty'
import { selectCurrentOwnerExtraAttributes, selectTechnicalFinancialDataAccess } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUserCanWrite, selectUserExtraAttributes } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import { generateKML } from '@smartcoop/utils/files'
import { GROWING_SEASON_COLOR , getPolygonCenter, findMapZoom } from '@smartcoop/utils/maps'
import AccordionsGroup from '@smartcoop/web-components/AccordionsGroup'
import Button from '@smartcoop/web-components/Button'
import EmptyState from '@smartcoop/web-components/EmptyState'
import GrowingSeasonStatus from '@smartcoop/web-components/GrowingSeasonStatus'
import Icon from '@smartcoop/web-components/Icon'
import Loader from '@smartcoop/web-components/Loader'
import Management from '@smartcoop/web-components/Management'
import Maps from '@smartcoop/web-components/Maps'
import Control from '@smartcoop/web-components/Maps/components/Control'
import Polygon from '@smartcoop/web-components/Maps/components/Polygon'
import PinMarker from '@smartcoop/web-components/Maps/markers/PinMarker'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import PartialLoading from '@smartcoop/web-components/PartialLoading'
import Tooltip from '@smartcoop/web-components/Tooltip'
import CropManagementDetailFragment from '@smartcoop/web-containers/fragments/CropManagementDetailFragment'
import FieldIndexesNavigator from '@smartcoop/web-containers/fragments/FieldIndexesNavigator'
import FieldTimelineChart from '@smartcoop/web-containers/fragments/FieldTimelineChart'
import PestReportDetailsFragment from '@smartcoop/web-containers/fragments/PestReport/PestReportDetailsFragment/PestReportDetailsFragment'
import WeatherForecastCard from '@smartcoop/web-containers/fragments/WeatherForecastCard/WeatherForecastCard'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import ChallengeTermModal from '@smartcoop/web-containers/modals/challenges/ChallengeTermModal'
import FieldCostsModal from '@smartcoop/web-containers/modals/FieldCostsModal'
import PropertyTechnicalVisitModal from '@smartcoop/web-containers/modals/technical/PropertyTechnicalVisitModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import useStyles, {
  Container,
  GrowingContainer,
  LeftContent,
  RightContent,
  GrowingItem,
  TextSowingYear,
  RightContainer,
  MapContainer,
  TitleCropManagements,
  HeaderCropManagementList,
  ContainerListManagements,
  TextFieldInfo
} from './styles'

const FieldDetailsScreen = () => {
  const history = useHistory()
  const { createDialog, removeDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()
  const { routes } = useRoutes()
  const snackbar = useSnackbar()
  const classes = useStyles()
  const mapRef = useRef(null)

  const navigationData = useSelector(selectFieldNavigationData)

  const isFromHistory = useMemo(() => navigationData?.isFromHistory || false, [navigationData])
  const childrenPolygonId = useMemo(() => navigationData?.childrenPolygonId || '', [navigationData])
  const historyGrowingSeason = useMemo(() => navigationData?.historyGrowingSeason || '', [navigationData])
  const isPlanned = useMemo(() => navigationData?.isPlanned || false, [navigationData])
  const growingSeasonId = useMemo(() => navigationData?.growingSeasonId || false, [navigationData])
  const fieldId = useMemo(() => navigationData?.fieldId || false, [navigationData])

  const userWrite = useSelector(selectUserCanWrite)
  const field = useSelector(selectCurrentField)
  const fieldHistory = useSelector(selectCurrentFieldHistory)
  const technicalModule = useSelector(selectModuleIsTechnical)
  const loading = useSelector(selectLoadingCurrentField)

  const [showCropManagement, setShowCropManagement] = useState(false)
  const [cropManagementData, setCropManagementData] = useState({})
  const [loadingField, setLoadingField] = useState(false)
  const [indicatorImage, setIndicatorImage] = useState()
  const [opened, setOpened] = useState(true)
  const [hasBeenRemoved, setHasBeenRemoved] = useState(false)
  const [pendingCostsCount, setPendingCostsCount] = useState(0)

  const userExtraAttributes = useSelector(selectUserExtraAttributes)
  const currentOwnerExtraAttributes = useSelector(selectCurrentOwnerExtraAttributes)

  const technicalFinancialDataAccess = useSelector(selectTechnicalFinancialDataAccess)
  const familyGroupFinancialDataAccess = useSelector(selectFamilyGroupFinancialDataAccess)

  const familyGroupAccess = useSelector(selectFamilyGroupAccess)
  const isTechnical = useSelector(selectModuleIsTechnical)

  const extraAttributes = useMemo(() => userExtraAttributes || {}, [userExtraAttributes])

  // eslint-disable-next-line no-nested-ternary
  const shouldDisplayCosts = useMemo(() => familyGroupAccess ? familyGroupFinancialDataAccess : isTechnical ? technicalFinancialDataAccess && currentOwnerExtraAttributes?.enabledCosts : extraAttributes?.enabledCosts,[currentOwnerExtraAttributes, extraAttributes, familyGroupAccess, familyGroupFinancialDataAccess, isTechnical, technicalFinancialDataAccess])

  const fieldWithGrowingSeason = useMemo(() => {
    let currentPolygons = []
    let currentGrowingSeasons = []

    map(field.growingSeasons, item => {
      const found = includes(map(item.fieldData?.childrenPolygons, cp => cp.id), childrenPolygonId)
      if(found) {
        currentPolygons = item.fieldData?.childrenPolygons
      }
    })

    if(childrenPolygonId) {
      map(field.growingSeasons, item => {
        const found = includes(map(currentPolygons, polygon => polygon?.id), item?.childrenPolygonId)
        if(found) {
          currentGrowingSeasons.push(item)
        }
      })
    } else {
      map(field.growingSeasons, item => {
        const found = !item?.childrenPolygonId
        if(found) {
          currentGrowingSeasons.push(item)
        }
      })
    }

    const currentGrowingSeason = find(currentGrowingSeasons, item => item?.id === growingSeasonId)
    currentGrowingSeasons = [...filter([...currentGrowingSeasons], item => item?.childrenPolygonId !== childrenPolygonId), currentGrowingSeason]

    const updatedField = { ...field, childrenPolygons: childrenPolygonId && !isEmpty(field.growingSeasons) ? currentPolygons : field?.childrenPolygons || [], growingSeasons: childrenPolygonId ? currentGrowingSeasons : field.growingSeasons, polygonCoordinates: currentGrowingSeason?.fieldData?.polygonCoordinates || field?.polygonCoordinates, fieldName:  currentGrowingSeason?.fieldData?.fieldName || field?.fieldName }

    return updatedField
  },[childrenPolygonId, field, growingSeasonId])

  const growingSeasons = useMemo(() => fieldWithGrowingSeason.growingSeasons, [fieldWithGrowingSeason.growingSeasons])

  const [selectedPolygon, setSelectedPolygon] = useState(childrenPolygonId || (fieldWithGrowingSeason?.childrenPolygons ? fieldWithGrowingSeason?.childrenPolygons[0]?.id : null))

  const currentPolygonGrowingSeason = useMemo(() => !isEmpty(fieldWithGrowingSeason?.childrenPolygons) && childrenPolygonId ? find(fieldWithGrowingSeason?.growingSeasons, item => item?.childrenPolygonId === selectedPolygon) : find(fieldWithGrowingSeason.growingSeasons, item => item?.id === growingSeasonId) || (!isEmpty(fieldWithGrowingSeason?.growingSeasons) ? fieldWithGrowingSeason?.growingSeasons[0] : {}),[childrenPolygonId, fieldWithGrowingSeason.childrenPolygons, fieldWithGrowingSeason.growingSeasons, growingSeasonId, selectedPolygon])

  const plagueReports = useMemo(
    // eslint-disable-next-line no-nested-ternary
    () => isFromHistory ? (!isEmpty(historyGrowingSeason?.plagueReports) ? historyGrowingSeason.plagueReports : fieldHistory?.plagueReports)
      : (!isEmpty(currentPolygonGrowingSeason) ? currentPolygonGrowingSeason?.plagueReports : []),
    [currentPolygonGrowingSeason, fieldHistory, historyGrowingSeason, isFromHistory]
  )


  const growingSeason = useMemo(
    // eslint-disable-next-line no-nested-ternary
    () => isFromHistory ? (!isEmpty(historyGrowingSeason) ? historyGrowingSeason : {} )
      : (!isEmpty(currentPolygonGrowingSeason) ? currentPolygonGrowingSeason : {}),
    [currentPolygonGrowingSeason, historyGrowingSeason, isFromHistory]
  )

  const onSuccessRegisterTechnicalVisit = useCallback(
    () => {
      snackbar.success(
        t('your {this} was registered', {
          howMany: 1,
          gender: 'female',
          this: t('technical visit')
        })
      )
      removeDialog({ id: 'register-technical-visit' })
    },
    [removeDialog, snackbar, t]
  )

  const openTechnicalVisitRegister = useCallback(() => {
    createDialog({
      id: 'register-technical-visit',
      Component: PropertyTechnicalVisitModal,
      props: {
        onSuccess: onSuccessRegisterTechnicalVisit,
        growingSeasonId
      }
    })
  }, [createDialog, growingSeasonId, onSuccessRegisterTechnicalVisit])

  const openFieldCostsModal = useCallback(() => {
    createDialog({
      id: 'field-costs-modal',
      Component: FieldCostsModal,
      props: {
        onSuccess: onSuccessRegisterTechnicalVisit,
        growingSeason: {
          ...growingSeason,
          cropsManagements: map(growingSeason?.cropsManagements, crop => {
            const items = [{ ...crop?.cropManagementItem, items: JSON.parse(crop?.cropManagementItem?.items) }]
            return {
              ...crop,
              cropsManagementItems: items
            } })
        }
      }
    })
  }, [createDialog, growingSeason, onSuccessRegisterTechnicalVisit])

  const iconChange = useMemo(
    () => opened ? openLayout : cutLayout,
    [opened]
  )

  const handleCropsRegister = useCallback(() => {
    history.push(routes.growingSeasonRegister.path, { fieldId, childrenPolygonId: selectedPolygon, currentGrowingSeason: growingSeason, field: fieldWithGrowingSeason })
  }, [fieldId, fieldWithGrowingSeason, growingSeason, history, routes.growingSeasonRegister.path, selectedPolygon])

  const handleManagementRegister = useCallback(() => {
    history.push(routes.managementRegister.path, { isPlanned, growingSeason: currentPolygonGrowingSeason, childrenPolygon : find(fieldWithGrowingSeason?.childrenPolygons, item => item.id === selectedPolygon), field: fieldWithGrowingSeason })
  }, [currentPolygonGrowingSeason, fieldWithGrowingSeason, history, isPlanned, routes.managementRegister.path, selectedPolygon])

  const currentCropManagement = useCallback(
    (item) => {
      if (item === cropManagementData) {
        setShowCropManagement(!showCropManagement)
        setCropManagementData(item)
      } else {
        setShowCropManagement(true)
        setCropManagementData(item)
      }
    },
    [cropManagementData, showCropManagement]
  )

  const handleCropDelete = useCallback(() => {
    createDialog({
      id: 'confirm-delete-crop',
      Component: ConfirmModal,
      props: {
        onConfirm: () => {
          dispatch(
            FieldActions.deleteOfflineGrowingSeason(
              growingSeason.id,
              () => {
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'male',
                    this: t('crops', { howMany: 1 })
                  })
                )
                if(isPlanned) {
                  history.goBack()
                }
              },
              () => {},
              true
            )
          )
        },
        textWarning: t('are you sure you want to delete the {this}?', {
          howMany: 1,
          gender: 'male',
          this: t('crops', { howMany: 1 })
        })
      }
    })
  }, [createDialog, dispatch, growingSeason.id, history, isPlanned, snackbar, t])

  const removeProductivityChallengeParticipation = useCallback((type, challenge) => {
    createDialog({
      id: 'confirm-delete-productivity-challange',
      Component: ConfirmModal,
      props: {
        onConfirm: () => {
          dispatch(
            ChallengesActions.deleteOfflineChallenge(
              { productivityChallengeId: challenge?.id, isField: true },
              () => {
                setHasBeenRemoved(true)
                snackbar.success(
                  t('participation denied')
                )
                removeDialog({ id: `accepted-term-modal-${ challenge?.project?.slug }` })
              }
            )
          )
        },
        textWarning: `${ t('are you sure you want to remove your field from') } ${ challenge?.project?.projectName }?`
      }
    })
  }, [createDialog, dispatch, removeDialog, snackbar, t])

  const toggleLayout = useCallback(() => {
    setOpened((old)=> !old)
  }, [])

  const statusGrowing = useMemo(() => {
    if(isPlanned) {
      return 'planejado'
    }
    if ((isFromHistory && !growingSeason.closed) || (!isFromHistory && isEmpty(growingSeason.closed))) {
      return 'ativo'
    }
    return 'encerrado'
  }, [growingSeason, isFromHistory, isPlanned])

  const mapCenterPosition = useMemo(() => {
    if(isFromHistory) {
      if (!isEmpty(fieldHistory?.fieldData?.polygonCoordinates)) {
        return getPolygonCenter(isString(fieldHistory?.fieldData?.polygonCoordinates) ? JSON.parse(fieldHistory?.fieldData?.polygonCoordinates) : fieldHistory?.fieldData?.polygonCoordinates)
      }
    } else if (!isEmpty(fieldWithGrowingSeason.polygonCoordinates)) {
      return getPolygonCenter(fieldWithGrowingSeason.polygonCoordinates)
    }
    return undefined
  }, [fieldHistory, fieldWithGrowingSeason, isFromHistory])

  const mapZoom = useMemo(() => {
    if(isFromHistory) {
      if (!isEmpty(fieldHistory?.fieldData?.polygonCoordinates)) {
        return findMapZoom(isString(fieldHistory?.fieldData?.polygonCoordinates) ? JSON.parse(fieldHistory?.fieldData?.polygonCoordinates) : fieldHistory?.fieldData?.polygonCoordinates )
      }
    } else if (!isEmpty(fieldWithGrowingSeason.polygonCoordinates)) {
      return findMapZoom(fieldWithGrowingSeason.polygonCoordinates)
    }
    return 17
  }, [fieldHistory, fieldWithGrowingSeason, isFromHistory])

  const accordionItems = useMemo(
    () => [
      {
        title: t('field timeline'),
        headerLeft: <Icon icon={ chart } size={ 25 } />,
        headerTitleProps: {
          style: {
            fontWeight: fonts.fontWeight.bold,
            textAlign: 'left'
          }
        },
        children: (
          <>
            <Divider style={ { margin: 0 } } />
            <br style={ { clear: 'both' } } />
            <FieldTimelineChart
              field={ fieldWithGrowingSeason }
              growingSeason={ isFromHistory ? historyGrowingSeason : currentPolygonGrowingSeason }
              isFromHistory={ isFromHistory }
              fieldHistory={ fieldHistory }
            />
          </>
        )
      }
    ],
    [currentPolygonGrowingSeason, fieldHistory, fieldWithGrowingSeason, historyGrowingSeason, isFromHistory, t]
  )

  const countClosedCropsManagement = useMemo(
    () => (reduce(
      map(growingSeason.cropsManagements, cm => cm.realizationDate ? 1 : 0),
      (acc, cm) => cm + acc
    )), [growingSeason]
  )

  const hasAllCropsManagementClosedButOne = useMemo(
    () => (!isEmpty(growingSeason.cropsManagements) &&
    countClosedCropsManagement === size(growingSeason.cropsManagements) - 1), [countClosedCropsManagement, growingSeason]
  )

  const onPestReportDelete = useCallback(
    (id) => {
      setLoadingField(true)
      dispatch(FieldActions.deleteOfflinePestReport(
        id,
        debounce(()=> setLoadingField(false), 300),
        ()=> setLoadingField(false)
      ))
      snackbar.success(
        t('your report was deleted!')
      )
    }
    , [dispatch, snackbar, t]
  )

  const onPestReportEdit = useCallback(
    (pestReport) => {
      history.push(routes.pestReportEdit.path,
        {
          pestReport: { ...pestReport },
          field: find(fieldWithGrowingSeason?.childrenPolygons, item => item.id === selectedPolygon) || fieldWithGrowingSeason,
          map: { mapZoom, mapCenterPosition }
        })
    },
    [fieldWithGrowingSeason, history, mapCenterPosition, mapZoom, routes.pestReportEdit.path, selectedPolygon]
  )

  const handlePestReport = useCallback(() => {
    history.push(routes.pestReportRegister.path, { map: { mapZoom, mapCenterPosition }, field: find(fieldWithGrowingSeason?.childrenPolygons, item => item.id === selectedPolygon) || fieldWithGrowingSeason, growingSeason: currentPolygonGrowingSeason })
  }, [currentPolygonGrowingSeason, fieldWithGrowingSeason, history, mapCenterPosition, mapZoom, routes.pestReportRegister.path, selectedPolygon])


  const openModalAcceptedTerm = useCallback(
    (data, challenge) => {
      createDialog({
        id: `accepted-term-modal-${ challenge?.project?.slug }`,
        Component: ChallengeTermModal,
        props: {
          handleClose: () => {
            removeDialog({ id: `accepted-term-modal-${ challenge?.project?.slug }` })
          },
          field: fieldWithGrowingSeason,
          challengeTerm: data,
          confirmModal: (type) => removeProductivityChallengeParticipation(type, challenge),
          challenge
        }
      })
    },
    [createDialog, fieldWithGrowingSeason, removeDialog, removeProductivityChallengeParticipation]
  )

  const handleChallengeTerm = useCallback(() => {
    map(fieldWithGrowingSeason?.productivityChallenge, item => {
      if(!item?.acceptedTermDate){
        dispatch(ChallengesActions.loadChallengeTerm(fieldWithGrowingSeason.id, item?.project?.slug, (term) => openModalAcceptedTerm(term, item)))
      }
    })
  }, [dispatch, fieldWithGrowingSeason, openModalAcceptedTerm])

  const findPolygonColorById = useCallback((id) => {
    const foundGrowingSeason = find(growingSeasons, item => item?.childrenPolygonId === id)
    return foundGrowingSeason ? GROWING_SEASON_COLOR[foundGrowingSeason?.crop?.slug] : '#fff'
  },[growingSeasons])

  const findPolygonByGrowingSeason = useCallback((polygons) => {
    const foundPolygon = find(polygons, item => item.id === fieldHistory?.childrenPolygonId)

    return foundPolygon?.coordinates
  },[fieldHistory])

  const handleReOpenGrowingSeason = useCallback(() => {
    dispatch(FieldActions.reopenGrowingSeason(
      growingSeasonId,
      () => {
        snackbar.success(t('your growing season was reopened'))
        history.push({
          pathname: routes.fieldList.path
        }, { isPlanned, isFromHistory })
      }
    ))
  },[dispatch, growingSeasonId, history, isFromHistory, isPlanned, routes, snackbar, t])

  const getCostsGrowingSeason = useCallback(async () => {
    const { data } = await getCosts({ growingSeasonId: growingSeason?.id })
    setPendingCostsCount(filter(data, item => item.status === 'pendente')?.length)
  }, [growingSeason])

  useEffect(() => {
    if(!isFromHistory && shouldDisplayCosts && growingSeason?.id) {
      getCostsGrowingSeason()
    }
  }, [getCostsGrowingSeason, growingSeason, isFromHistory, shouldDisplayCosts])

  useEffect(() => {
    if(!isFromHistory) {
      dispatch(FieldActions.loadCurrentField(fieldId, isPlanned))
    } else {
      dispatch(FieldActions.loadCurrentFieldHistory(historyGrowingSeason?.id))
    }
    if (!isEmpty(fieldWithGrowingSeason?.productivityChallenge) && !technicalModule) {
      handleChallengeTerm()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fieldId])


  useEffect(()=> {
    mapRef.current && mapRef.current.leafletElement._onResize()
  }, [opened])

  const currentPolygon = useMemo(() => find(fieldWithGrowingSeason?.childrenPolygons, item => item.id === growingSeason?.childrenPolygonId),[fieldWithGrowingSeason.childrenPolygons, growingSeason.childrenPolygonId])

  return (
    <SplitedScreenLayout
      title={ { name: t('field', { howMany: 2 }) } }
      divRightStyle={ { padding: 0 } }
      handleGoBack={ () => {
        history.push({
          pathname: routes.fieldList.path
        }, { isPlanned, isFromHistory })
      } }
      leftChildren={ opened && (
        <PartialLoading open={ loading } width={ 100 }>
          <RightContainer>
            <>
              {!isEmpty(growingSeason?.id) ? (
                <Container>
                  <GrowingContainer>
                    <LeftContent>
                      <I18n
                        params={ { howMany: 1 } }
                        style={ { fontSize: 18, fontWeight: 700 } }
                      >
                    crops
                      </I18n>
                      {!isEmpty(growingSeason.crop) && (
                        <GrowingItem>
                          <p style={ { margin: '10px 0', fontSize: 18, fontWeight: 600 } }>
                            {upperFirst(growingSeason?.crop?.description)}
                          </p>
                        </GrowingItem>
                      )}
                      {!isEmpty(growingSeason.cultivation) && (
                        <p style={ { margin: 0, fontSize: 16 } }>
                          {growingSeason?.cultivation?.description}
                        </p>
                      )}
                    </LeftContent>
                    <RightContent>
                      {(userWrite && !isFromHistory) && (
                        <div style={ { display: 'flex' } }>
                          <Button
                            id="editCropButton"
                            variant="outlined"
                            style={ { marginRight: 5, minWidth: '30px', minHeight: '30px', border: `1px solid ${ colors.backgroundHtml }`, padding: 0 } }
                            onClick={ handleCropsRegister }
                          >
                            <Icon icon={ pencil } size={ 14 } />
                          </Button>
                          <Button
                            id="deleteCropButton"
                            variant="outlined"
                            style={ { minWidth: '30px', minHeight: '30px', border: `1px solid ${ colors.backgroundHtml }`, padding: 0 } }
                            onClick={ handleCropDelete }
                          >
                            <Icon icon={ trash } size={ 14 } color={ colors.red } />
                          </Button>
                        </div>
                      )}
                      {
                        (!isEmpty(fieldWithGrowingSeason) || !isEmpty(fieldHistory)) && (
                          <TextSowingYear>
                            {growingSeason.sowingYear}
                          </TextSowingYear>
                        )
                      }
                      <GrowingSeasonStatus slug={ statusGrowing } />
                    </RightContent>
                  </GrowingContainer>

                  {(userWrite && isFromHistory) && (
                    <div style={ { display: 'flex', marginTop: '15px' } }>
                      <Button
                        id="re-open-growing-season"
                        variant="outlined"
                        onClick={ handleReOpenGrowingSeason }
                      >
                        <I18n>reopen growing season</I18n>
                      </Button>
                    </div>
                  )}

                  {(userWrite && !isFromHistory && shouldDisplayCosts) && (
                    <>
                      <Button
                        id="costs"
                        variant="outlined"
                        style={ {
                          marginTop: 10,
                          border: '1px solid rgba(21, 24, 24, 0.3)'
                        } }
                        onClick={ openFieldCostsModal }
                      >
                        <>
                          <Badge
                            badgeContent={ <div style={ { height: 15, width: 15, borderRadius: 15, backgroundColor: colors.secondary, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 10 } }>{pendingCostsCount}</div> }
                            overlap="circle"
                            anchorOrigin={ {
                              vertical: 'top',
                              horizontal: 'right'
                            } }
                          >
                            <Icon size={ 18 } icon={ bell } color={ colors.primary }  />
                          </Badge>
                          <I18n style={ { marginLeft: 15 } }>pendencies costs</I18n>
                        </>
                      </Button>
                    </>
                  )}
                  {(userWrite && !isFromHistory && !isPlanned) && (
                    <>
                      <Button
                        id="pestReport"
                        variant="outlined"
                        style={ {
                          marginTop: 10,
                          border: '1px solid rgba(21, 24, 24, 0.3)'
                        } }
                        onClick={ handlePestReport }
                      >
                        <>
                          <Icon icon={ bug } size={ 16 } style={ { marginRight: 6.5 } } />
                          <I18n style={ { marginLeft: 6.5 } }>report occurrence</I18n>
                        </>
                      </Button>
                    </>
                  )}

                  <Divider style={ { margin: '10px 0 0 0' } } />
                </Container>
              ) : (
                <div
                  style={ {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px 0 ',
                    flex: 1,
                    height: '100%'
                  } }
                >
                  <EmptyState
                    icon={ emptyCrop }
                    text={ t('no crops registered') }
                    action={ !userWrite ? undefined : {
                      text: t('register crops'),
                      onClick: handleCropsRegister
                    } }
                  />
                </div>
              )}
            </>
            <Container>
              {!isEmpty(growingSeason) && (
                <>
                  {!isEmpty(growingSeason.cropsManagements) && (
                    <>
                      <HeaderCropManagementList>
                        <TitleCropManagements>
                          <I18n params={ { howMany: 2 } }>cropManagement</I18n>
                        </TitleCropManagements>
                        {(userWrite && !isFromHistory) && (
                          <div style={ { display: 'flex', flex: 1, flexDirection: 'row', width: '100%' } }>
                            <Button
                              id='register-crop-management'
                              color='secondary'
                              onClick={ handleManagementRegister }
                              disabled={ !userWrite }
                              style={ { marginRight: '5px' } }
                            >
                              <I18n>new management</I18n>
                            </Button>
                            <Button
                              id='close-growing-season'
                              onClick={ () => {
                                createDialog({
                                  id: 'confirm-end-growingSeason',
                                  Component: ConfirmModal,
                                  props: {
                                    onConfirm: () => {
                                      dispatch(FieldActions.saveOfflineGrowingSeason(
                                        {
                                          ...growingSeason,
                                          closed: true
                                        },
                                        () => {
                                          snackbar.success(
                                            t('the cultivation have been completed')
                                          )
                                          history.push(routes.fieldList.path)
                                        },
                                        () => {},
                                        isPlanned,
                                        true
                                      ))
                                    },
                                    textWarning: t('are you sure you want to finalize your cultivation?')
                                  }
                                })
                              } }
                              disabled={ !userWrite || !growingSeason?.canClose }
                              style={ { marginLeft: '5px' } }
                            >
                              <I18n>end cultivation</I18n>
                            </Button>
                          </div>
                        )}
                      </HeaderCropManagementList>
                      <Divider style={ { margin: 0 } } />
                    </>
                  )}
                  <ContainerListManagements limit={ !isEmpty(growingSeason.cropsManagements) }>
                    {(!isEmpty(growingSeason?.cropsManagements) || !isEmpty(fieldHistory.cropManagements)) ? (
                      map(isFromHistory ? fieldHistory.cropManagements : growingSeason.cropsManagements, (item) => (
                        <Management
                          key={ item.id }
                          date={ item.predictedDate }
                          managementType={ item?.operation?.name }
                          done={ item.realizationDate }
                          cropManagement={ item }
                          onClick={ currentCropManagement }
                          active={
                            item.id === cropManagementData?.id &&
                            showCropManagement
                          }
                          disabled={ isFromHistory }
                        />
                      ))
                    ) : (
                      <div
                        style={ {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: '10px 0 ',
                          flex: 1,
                          height: '100%'
                        } }
                      >
                        <EmptyState
                          icon={ emptyManagement }
                          text={ t('no management registered') }
                          action={ !userWrite ? undefined : {
                            text: t('register management'),
                            onClick: handleManagementRegister
                          } }
                          buttonProps={ {
                            disabled: !userWrite || isFromHistory
                          } }
                        />
                      </div>
                    )}
                  </ContainerListManagements>
                </>
              )}
            </Container>
          </RightContainer>
        </PartialLoading>)
      }
      rightChildren={
        <>
          {(loadingField || loading) ? <Loader /> : (!isEmpty(fieldWithGrowingSeason) || !isEmpty(fieldHistory)) && (
            <MapContainer>
              <Maps
                zoom={ mapZoom }
                region={ mapCenterPosition }
                ref={ mapRef }
                layer="google"
              >
                <Control position="topcenter">
                  <div
                    style={ {
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      padding: '0 10px',
                      paddingRight: '70px'
                    } }
                  >
                    <div
                      style={ {
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                      } }
                    >

                      <div style={ {} }>
                        <Button
                          id="toggle-Layout"
                          color={ colors.white }
                          style={ {
                            padding: '9px 10px',
                            fontSize: '0.875rem',
                            marginRight: 10
                          } }
                          onClick={ toggleLayout }
                        >
                          <Icon
                            icon={ iconChange }
                            size={ 17 }
                          />
                        </Button>

                        <Button
                          id="field-details"
                          color={ colors.shadow }
                          style={ {
                            padding: '5.5px 10px',
                            fontColor: colors.white,
                            fontSize: '0.875rem',
                            marginRight: 10
                          } }
                        >
                          <>
                            <Icon
                              icon={ fieldIcon }
                              size={ 17 }
                              style={ { marginRight: 5 } }
                              color={ colors.white }
                            />
                            {!isEmpty(fieldWithGrowingSeason?.productivityChallenge) && (
                              <Tooltip title={ <div>{map(fieldWithGrowingSeason?.productivityChallenge, challenge => <p>{challenge?.project?.projectName}</p>)}</div> }>
                                <Icon icon={ productivityChallange } size={ 14 } style={ { marginRight: 3 } }/>
                              </Tooltip>
                            )}
                            {fieldWithGrowingSeason?.fieldName || fieldHistory?.fieldName}
                            { !isEmpty(currentPolygon) ? ` - ${ currentPolygon?.name }` : null}
                            <div style={ { textTransform: 'lowercase' } }>
                              { !isEmpty(currentPolygon) ? ` | ${ round(currentPolygon?.area, 2) } ha` : ` | ${ round(growingSeason?.fieldData?.area || field?.area, 2) } ha`}
                            </div>
                          </>
                        </Button>
                        <Button
                          id="field-export"
                          color={ colors.shadow }
                          style={ {
                            padding: '5.5px 10px',
                            fontColor: colors.white,
                            fontSize: '0.875rem',
                            marginRight: 10
                          } }
                          onClick={ () => generateKML(fieldWithGrowingSeason?.polygonCoordinates, fieldWithGrowingSeason?.fieldName || fieldHistory?.fieldName) }
                        >
                          <>
                            <Icon
                              icon={ download }
                              size={ 17 }
                              style={ { marginRight: 5 } }
                              color={ colors.white }
                            />
                            {t('download kml')}
                          </>
                        </Button>
                      </div>
                      <div>
                        {(technicalModule && !isFromHistory && !isEmpty(fieldWithGrowingSeason?.productivityChallenge) && !hasBeenRemoved) && map(fieldWithGrowingSeason?.productivityChallenge, item => (
                          <Button
                            key={ `remove-productivity-challenge-participation-${ item?.project?.slug }` }
                            id={ `remove-productivity-challenge-participation-${ item?.project?.slug }` }
                            color={ colors.white }
                            style={ {
                              padding: '5.5px 10px',
                              fontSize: '0.875rem',
                              marginRight: 10
                            } }
                            onClick={ () => removeProductivityChallengeParticipation('refuse', item) }
                            disabled={ isFromHistory }
                          >
                            <>
                              <Icon
                                icon={ trash }
                                size={ 17 }
                                style={ { marginRight: 5 } }
                              />
                              <I18n>remove from</I18n> {item?.projectName}
                            </>
                          </Button>
                        ))}

                        {(technicalModule && !isFromHistory) && (
                          <Button
                            id="register-technical-visit"
                            color={ colors.white }
                            style={ {
                              padding: '5.5px 10px',
                              fontSize: '0.875rem',
                              marginRight: 10
                            } }
                            onClick={ openTechnicalVisitRegister }
                            disabled={ isFromHistory }
                          >
                            <>
                              <Icon
                                icon={ plantAndHand }
                                size={ 17 }
                                style={ { marginRight: 5 } }
                              />
                              {t('technical visit')}
                            </>
                          </Button>
                        )}


                      </div>
                    </div>
                  </div>
                </Control>
                {!isPlanned && (
                  <Control position="topright">
                    { !isFromHistory && (
                      <WeatherForecastCard
                        customCoordinates={ mapCenterPosition }
                      />
                    )}
                  </Control>
                )}
                {!isPlanned && (
                  <Control position="bottomcenter">
                    <FieldIndexesNavigator
                      field={ fieldWithGrowingSeason }
                      isFromHistory={ isFromHistory }
                      onChangeImage={ setIndicatorImage }
                      fieldHistory={ fieldHistory }
                    />
                  </Control>
                )}
                {

                  map(orderBy(plagueReports, 'occurenceDate', 'asc'), (pestReport, key) => (
                    <PinMarker
                      key={ pestReport?.id }
                      coordinate={ pestReport?.pinLocation }
                      customIcon={ bugMarkerRoundIcon }
                      iconProps={ { count: key + 1 } }
                      size={ 34 }
                      iconAnchor={ {
                        x: 13,
                        y: 40
                      } }
                      tooltip={ <TooltipMap direction="top" sticky>
                        { pestReport?.reportTypes?.name || pestReport?.reportType?.name || pestReport?.disease?.name || '' } - {moment(pestReport.occurenceDate, momentBackDateFormat).format(momentFriendlyDateFormat)}
                      </TooltipMap> }
                    >
                      <PestReportDetailsFragment
                        pestReport={ { ...pestReport } }
                        onDelete={ onPestReportDelete }
                        onEdit={ onPestReportEdit }
                        isFromHistory={ isFromHistory }
                      />
                    </PinMarker>
                  ))
                }
                {indicatorImage && (
                  <ImageOverlay
                    url={ indicatorImage }
                    bounds={ isFromHistory ? fieldHistory?.fieldData?.polygonCoordinates : fieldWithGrowingSeason?.polygonCoordinates }
                    className={ classes.mapImage }
                  />
                )}
                { isFromHistory ? (
                  <Polygon
                    points={ !isEmpty(historyGrowingSeason?.fieldData?.childrenPolygons) ? findPolygonByGrowingSeason(historyGrowingSeason?.fieldData?.childrenPolygons) :  historyGrowingSeason?.fieldData?.polygonCoordinates }
                    color={  GROWING_SEASON_COLOR[fieldHistory?.crop?.slug] }
                    fillOpacity={ indicatorImage ? 0 : 0.2 }
                  />
                ) : !isEmpty(fieldWithGrowingSeason?.childrenPolygons) ? map(fieldWithGrowingSeason?.childrenPolygons, item => {
                  const currentGrowingSeason = find(growingSeasons, crop => crop?.childrenPolygonId === item?.id)

                  return (
                    <Polygon
                      key={ `polygon-${ item.id }` }
                      points={ item?.coordinates }
                      color={ findPolygonColorById(item.id) }
                      onClick={ () => setSelectedPolygon(item.id) }
                      fillOpacity={ indicatorImage ? 0 : 0.2 }
                      strokeWidth={ item.id === selectedPolygon ? 7 : 2 }
                    >
                      <Popup>
                        <LeftContent>
                          <TextFieldInfo>
                            {item.name}
                          </TextFieldInfo>
                          {!isEmpty(currentGrowingSeason) && (
                            <TextFieldInfo>
                              {currentGrowingSeason?.crop?.description} - {currentGrowingSeason?.sowingYear}
                            </TextFieldInfo>
                          )}
                          <TextFieldInfo>
                            {parseFloat(item?.area).toFixed(2)} ha
                          </TextFieldInfo>
                        </LeftContent>
                      </Popup>
                    </Polygon>
                  )
                }) :
                  <Polygon
                    points={ fieldWithGrowingSeason.polygonCoordinates }
                    color={  !isEmpty(growingSeasons) ? GROWING_SEASON_COLOR[currentPolygonGrowingSeason?.crop?.slug] : '#fff' }
                    fillOpacity={ indicatorImage ? 0 : 0.2 }
                  />
                }
              </Maps>
              {showCropManagement ? (
                <CropManagementDetailFragment
                  closeCropManagement={ () => {
                    setShowCropManagement(false)
                  } }
                  growingSeasonId={ growingSeason.id }
                  cropManagementData={ cropManagementData }
                  canConcludeGrowingSeason={ hasAllCropsManagementClosedButOne }
                  isFromHistory={ isFromHistory }
                  growingSeason={ growingSeason }
                  isPlanned={ isPlanned }
                  field={ field }
                  childrenPolygon={ find(fieldWithGrowingSeason?.childrenPolygons, item => item.id === growingSeason?.childrenPolygonId) }
                  fieldId={ fieldId }
                />
              ) : (
                <div>
                  <AccordionsGroup
                    items={ accordionItems }
                    toggleOnClick
                    invertArrow
                  />
                </div>
              )}
            </MapContainer>
          )}
        </>
      }
    />
  )
}

export default FieldDetailsScreen
