import api from '../api'

export const getAnimalObservations = (params, { animalId }) =>
  api.get(`/v1/herds-management/animals-observations/animal/${ animalId }`, { params })

export const createAnimalObservations = async (params) => {
  const { data } = await api.post('/v1/herds-management/animals-observations', params)
  return data
}

export const updateAnimalObservations = async (params, { observationId }) => {
  const { data } = await api.patch(`/v1/herds-management/animals-observations/${ observationId }`, params)
  return data
}

export const deleteAnimalObservations = async ({ observationId }) => {
  const { data } = await api.delete(`/v1/herds-management/animals-observations/${ observationId }`)
  return data
}
