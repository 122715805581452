import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import hayProductionCropManagementSchema from '@smartcoop/forms/schemas/management/hayProductionCropManagement.schema'
import { useT } from '@smartcoop/i18n'
import Form from '@smartcoop/web-components/Form'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputUnit from '@smartcoop/web-components/InputUnit'

const HayProductionForm = forwardRef(({ defaultValues, handleSubmit, readOnly }, formRef) => {
  const t = useT()

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ hayProductionCropManagementSchema }
      containerStyle={ { height: 'auto' } }
      onSubmit={ handleSubmit }
    >
      <InputNumber
        name="balesRollsPerHa"
        label={ t('number of bales/rolls per ha') }
        maxLength={ 3 }
        type="integer"
        fullWidth
        defaultValue={ defaultValues?.balesRollsPerHa }
        disabled={ readOnly }
      />

      <InputUnit
        name="balesWeightRollsPerHa"
        label={ t('Bale / hay roll weight') }
        maxLength={ 3 }
        unit="kg/fardo"
        type="integer"
        fullWidth
        defaultValue={ defaultValues?.balesWeightRollsPerHa }
        disabled={ readOnly }
      />
    </Form>
  )
})


HayProductionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  defaultValues: PropTypes.object
}

HayProductionForm.defaultProps = {
  readOnly: false,
  defaultValues: {}
}

export default HayProductionForm
