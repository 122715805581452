import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const technicalGroups = ({ t }) => Yup.object().shape({

  name: flow(required({ t }))(Yup.string()),

  usersIds: Yup.string().nullable()
})

export default technicalGroups
