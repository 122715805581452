import React, { useMemo } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import upperFirst from 'lodash/upperFirst'

import colors from '@smartcoop/styles/colors'
import { momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import { iconByModule } from '@smartcoop/utils/modules'
import Icon from '@smartcoop/web-components/Icon'

import {
  Container,
  HeaderContainer,
  Date,
  UnreadSignal,
  TitleContainer,
  ModuleIconContainer,
  BodyContainer,
  Body,
  Title
} from './styles'

const Notification = (props) => {
  const {
    notification,
    onClick
  } = props

  const {
    id,
    read,
    createdAt,
    message = {},
    data = {}
  } = notification

  const  {
    title,
    body
  } = message

  const notificationMoment = useMemo(
    () => moment(createdAt, 'YYYY-MM-DD HH:mm:ssZ'),
    [createdAt]
  )

  const recent24 = useMemo(
    () => moment().diff(notificationMoment, 'hours') <= 20,
    [notificationMoment]
  )

  const recent48 = useMemo(
    () => moment().diff(notificationMoment, 'hours') <= 47,
    [notificationMoment]
  )

  const fromNow = useMemo(
    () => {
      if (recent24) {
        return notificationMoment.fromNow()
      }

      if (recent48) {
        const startOfDay = moment(notificationMoment)
        return startOfDay.startOf('day').fromNow()
      }

      return notificationMoment.format(momentFriendlyDateTimeFormat)
    },
    [notificationMoment, recent24, recent48]
  )

  return (
    <Container
      id={ `notification-${ id }` }
      button
      onClick={ () => onClick(notification) }
    >
      <HeaderContainer>
        <Date recent={ recent24 }>
          {upperFirst(fromNow)}
        </Date>
        {!read && (
          <UnreadSignal />
        )}
      </HeaderContainer>

      <TitleContainer>
        <ModuleIconContainer>
          <Icon icon={ iconByModule(data.module) } color={ colors.white } size={ 14 } />
        </ModuleIconContainer>
        <Title read={ read }>
          {title}
        </Title>
      </TitleContainer>

      <BodyContainer>
        <Body read={ read }>
          {body}
        </Body>
      </BodyContainer>

    </Container>
  )
}

Notification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    read: PropTypes.bool.isRequired,
    createdAt: PropTypes.string.isRequired,
    message: PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired
    }).isRequired,
    data: PropTypes.object
  }).isRequired,
  onClick: PropTypes.func
}

Notification.defaultProps = {
  onClick: () => {}
}

export default Notification
