import React from 'react'

import PropTypes from 'prop-types'

import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import {
  Container,
  LeftSide,
  RightSide,
  Line,
  Unit,
  Title,
  Big,
  Small,
  Row
} from './styles'

const MilkDataCard = props => {
  const {
    title,
    measureUnit,
    color,
    icon,
    iconSize,
    children,
    style,
    lowerText
  } = props
  return (
    <Container style={ style }>
      <LeftSide backgroundColor={ color }>
        <Icon
          icon={ icon }
          color={ color }
          size={ iconSize }
        />
      </LeftSide>
      <RightSide>
        <Line>
          <Title>{title}</Title>
          <Unit>{measureUnit}</Unit>
        </Line>
        <Row>
          <Big>
            {children}
          </Big>
          {lowerText ? (
            <Small>
              {lowerText}
            </Small>
          ) : null}
        </Row>
      </RightSide>
    </Container>
  )
}

MilkDataCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  lowerText: PropTypes.string,
  icon: PropTypes.any,
  children: PropTypes.any,
  iconSize: PropTypes.number,
  measureUnit: PropTypes.string,
  style: PropTypes.object
}

MilkDataCard.defaultProps = {
  color: colors.blue,
  children: null,
  icon: null,
  lowerText: null,
  iconSize: 38,
  title: '-',
  measureUnit: '-',
  style: {}
}

export default MilkDataCard
