import React, { useCallback, useMemo, useEffect, forwardRef } from 'react'

import PropTypes from 'prop-types'

import countBy from 'lodash/countBy'
import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import flow from 'lodash/fp/flow'
import orderByFP from 'lodash/fp/orderBy'
import uniqByFP from 'lodash/fp/uniqBy'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import reverse from 'lodash/reverse'
import size from 'lodash/size'
import toNumber from 'lodash/toNumber'

import { ThemeProvider } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'

import { useT } from '@smartcoop/i18n'
import { warning } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import CheckboxTreeItem from './CheckboxTreeItem'
import { useStylesTreeView, Label, ErrorLabel, Row } from './styles'

const getCheckedNodeStatusByOptions = (options) => {
  const enabledOptions = filter(options, (option) => !option.disabled)
  const childrenChecked = countBy(enabledOptions, 'checked')
  const optionsSize = size(enabledOptions)

  if (childrenChecked.true === optionsSize) {
    /* todos os filhos diretos estão marcados */
    return true
  }
  if (isEmpty(childrenChecked) || childrenChecked.false === optionsSize) {
    /* todos os filhos diretos estão desmarcados */
    return false
  }
  return null
}

// eslint-disable-next-line no-unused-vars
const CheckboxTreeViewStyled = forwardRef((props, ref) => {
  const {
    id,
    options,
    defaultExpanded,
    onChangeOptions,
    onChange,
    required,
    label,
    error
  } = props

  const classes = useStylesTreeView()
  const t = useT()

  const internalOptions = useMemo(
    () => {
      const checkLevel = (opts = options) => map(opts, (option) => {
        if (isEmpty(option.options)) {
          return option
        }

        if (find(option.options, (opt) => !isEmpty(opt.options))) {
          const newOptions = checkLevel(option.options)
          return {
            ...option,
            checked: getCheckedNodeStatusByOptions(newOptions),
            options: newOptions
          }
        }

        return {
          ...option,
          checked: getCheckedNodeStatusByOptions(option.options)
        }
      })
      return checkLevel()
    },
    [options]
  )

  const getCheckeds = useCallback((opts) => {
    let checkeds = {}
    forEach(opts, (option) => {
      if (!isEmpty(option.options)) {
        const checkedsOptions = getCheckeds(option.options)
        if (!isEmpty(checkedsOptions)) {
          checkeds = {
            ...checkeds,
            [option.id]: checkedsOptions
          }
        }
      } else if (option.checked) {
        checkeds = {
          ...checkeds,
          [option.id]: true
        }
      }
    })
    return checkeds
  }, [])

  const refreshValue = useCallback(
    (opts) => {
      const checkeds = getCheckeds(opts)
      onChange(checkeds)
    },
    [getCheckeds, onChange]
  )

  const onChangeChecked = useCallback(
    (nodeId, checked) => {

      let nodeOption
      let nodeParents
      let nodeMain
      let newChecked = checked

      const getNode = (opts = internalOptions, parents = []) => {
        /*
            busca o node (option) que foi ativo/inativo
            e tambem o node de cada pai
          */
        forEach(opts, (option) => {
          if (option.id === nodeId) {
            nodeOption = Object.assign(option)
            nodeParents = parents
          } else if (!isEmpty(option.options)) {
            const { options: childOptions } = option
            getNode(childOptions, [...parents, option])
          }
        })
      }
      getNode()

      if (isEmpty(nodeOption)) {
        // eslint-disable-next-line no-console
        console.error(`Node ID not found: ${ nodeId }`)
        return
      }

      if (isEmpty(nodeOption.options)) {
        nodeOption.checked = newChecked
      } else {
        /*
          quando o item clicado é pai de outros itens
          ajustamos todos os filhos para receberem o mesmo estado que o pai (true || false)
        */
        const oldCheckedStatusComputed = getCheckedNodeStatusByOptions(nodeOption.options)
        const childrenDisabled = countBy(nodeOption.options, 'disabled')
        const childrenChecked = countBy(nodeOption.options, 'checked')

        if (
          oldCheckedStatusComputed === null
          && childrenDisabled.true + childrenChecked.true === size(nodeOption.options)
        ) {
          /*
            se o estado anterior era indeterminado (null)
            e todos os itens possiveis ja estavam selecionados,
            desmarcamos o item e seus filhos
          */
          newChecked = false
        }

        const changeAllChildrenOptions = (
          opts = nodeOption.options,
          disabled = false
        ) => map(opts, (option) => ({
          ...option,
          checked: !disabled && !option.disabled && newChecked,
          options: isEmpty(option.options)
            ? undefined
            : changeAllChildrenOptions(option.options, disabled || option.disabled)
        }))

        nodeOption.options = changeAllChildrenOptions()
        nodeOption.checked = getCheckedNodeStatusByOptions(nodeOption.options)
      }

      if (isEmpty(nodeParents)) {
        nodeMain = nodeOption
      } else {
        /*
          caso o item tenha ao menos um pai
          reconstruimos cada pai, na ordem inversa, recursivamente,
          reconstruindo assim o node principal da arvore
        */
        reverse(nodeParents)
        const changeAllParentsOptions = () => reduce(
          nodeParents,
          (childOption, parent) => {
            let { options: parentOptions } = parent

            parentOptions = map(parentOptions, (option) => {
              if (option.id === childOption.id) {
                /* opcao verificada é a mesma que ja foi tratada anteriormente */
                return childOption
              }
              return option
            })

            const parentChecked = getCheckedNodeStatusByOptions(parentOptions)

            return {
              ...parent,
              checked: parentChecked,
              options: parentOptions
            }
          },
          nodeOption
        )

        nodeMain = changeAllParentsOptions()
      }

      const getNewOptions = (opts = internalOptions) => map(opts, (option) => {
        /*
            recria as opções após o processamento
            substituindo o node principal do item clicado
          */
        if (option.id === nodeMain.id) {
          return nodeMain
        }
        return option
      })

      const newOptions = getNewOptions()
      refreshValue(newOptions)
      onChangeOptions(newOptions)
    },
    [internalOptions, onChangeOptions, refreshValue]
  )

  const handleCreateOption = useCallback(
    (parentPath, creatableValue) => {
      const createOptions = (opts = options, parentId = '') => map(opts, (option) => {

        const currentId = parentId ? `${ parentId }.${ option.id }` : option.id
        let response = { ...option }

        if (parentPath === currentId) {
          const optOptions = get(response, 'options', [])

          response = {
            ...response,
            options: flow(
              uniqByFP('id'),
              orderByFP([(opt) => toNumber(opt.id)], ['asc'])
            )([
              {
                id: creatableValue.toString(),
                checked: true,
                label: `${ creatableValue.toString()  } ${ t('day', { howMany: creatableValue }) }`
              },
              ...optOptions
            ])
          }
          // natural sort nas labels
          response.options.sort((a,b) => a.label.localeCompare(b.label, undefined, { numeric: true }))
        } else if (option.options) {
          response = {
            ...response,
            options: createOptions(option.options, id)
          }
        }

        return response

      })
      const newOptions = createOptions()
      refreshValue(newOptions)
      onChangeOptions(newOptions)
    },
    [id, onChangeOptions, options, refreshValue, t]
  )

  useEffect(() => {
    refreshValue(options)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider
      theme={
        (theme) => ({
          ...theme,
          overrides: {
            ...theme.overrides,
            MuiTreeItem: {
              root: {
                '&:last-child': {
                  '& > *': {
                    marginBottom: 20
                  }
                }
              },
              label: {
                width: 'auto',
                paddingLeft: 0
              }
            },
            MuiFormHelperText: {
              marginDense: {
                marginTop: 0
              }
            },
            MuiCollapse: {
              wrapper: {
                marginTop: 8
              },
              wrapperInner: {
                marginLeft: '15px',
                borderLeft: '2px solid #1D1D1B'
              }
            }
          }
        })
      }
    >
      <>
        {label && (
          <Label color={ error ? 'error' : undefined } >
            { label }{required && ' *'}
          </Label>
        )}
        {!!error && (
          <Row>
            <Icon
              icon={ warning }
              color={ colors.error }
              size={ 12 }
            />
            <ErrorLabel>{ error }</ErrorLabel>
          </Row>
        )}
        <TreeView
          id={ id }
          className={ classes.root }
          defaultExpanded={ defaultExpanded }
        // defaultCollapseIcon={ <ExpandMoreIconButton size="small" /> }
        // defaultExpandIcon={ <ChevronRightIconButton size="small" /> }
        >
          {map(internalOptions, (option) => (
            <CheckboxTreeItem
              key={ option.id }
              { ...option }
              onCreateOption={ handleCreateOption }
              onChangeChecked={ onChangeChecked }
            />
          ))}
        </TreeView>
      </>
    </ThemeProvider>
  )
})

CheckboxTreeViewStyled.propTypes = {
  /** Id da lista */
  id: PropTypes.string.isRequired,
  onChangeOptions: PropTypes.func,
  /** options a serem renderizados na listagem */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.element
      ]),
      options: PropTypes.array,
      color: PropTypes.string
    })
  ),
  /** Lista de Ids dos options que nascem abertos */
  defaultExpanded: PropTypes.array,
  /** funcao callback que entrega o id dos itens slecionados */
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool
}

CheckboxTreeViewStyled.defaultProps = {
  options: [],
  defaultExpanded: [],
  onChange: () => {},
  onChangeOptions: () => {},
  label: '',
  error: null,
  required: false
}

export default CheckboxTreeViewStyled
