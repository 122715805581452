import moment from 'moment/moment'

export default ({ t }) => YupInstance => YupInstance
  .test('date', t('date must be a weekday'), (date) => {
    const format = 'YYYY-MM-DD'

    const day = moment(date, format).day()

    return !(day === 6 || day === 0)
  })
