import { TextEncoder, TextDecoder } from 'text-encoding'

import { arrayBufferToBase64, base64ToArrayBuffer } from './base64'

const encoder = new TextEncoder()
const decoder = new TextDecoder()

const rsaAlgorithm = {
  name: 'RSA-OAEP',
  modulusLength: 2048,
  publicExponent: new Uint8Array([1, 0, 1]),
  hash: 'SHA-256'
}

export async function encrypt(message, rawPublicKey) {
  // eslint-disable-next-line no-param-reassign
  rawPublicKey = rawPublicKey.replace(/(\n|\r|\r\n)/gm, '').replace(/-----BEGIN PUBLIC KEY-----/, '').replace(/-----END PUBLIC KEY-----/, '')
  const key = await crypto.subtle.generateKey(
    { name: 'AES-CBC', length: 256 },
    true,
    ['encrypt', 'decrypt']
  )

  const iv = crypto.getRandomValues(new Uint8Array(16))
  const encryptedData = await crypto.subtle.encrypt(
    { name: 'AES-CBC', iv },
    key,
    encoder.encode(message)
  )

  const publicKey = await crypto.subtle.importKey('spki', base64ToArrayBuffer(rawPublicKey), { name: 'RSA-OAEP', hash: 'SHA-1' }, true, ['encrypt'])
  const encryptedKey = await crypto.subtle.encrypt(rsaAlgorithm, publicKey, await crypto.subtle.exportKey('raw', key))

  return `${ arrayBufferToBase64(iv) }.${ arrayBufferToBase64(encryptedKey) }.${ arrayBufferToBase64(encryptedData) }`
}

export async function decrypt(message, rawPrivateKey) {
  try {
    // eslint-disable-next-line no-param-reassign
    rawPrivateKey = rawPrivateKey.replace(/(\n|\r|\r\n)/gm, '').replace(/-----BEGIN PRIVATE KEY-----/, '').replace(/-----END PRIVATE KEY-----/, '')

    const [iv, encryptedKey, encryptedData] = message.split('.').map(x => base64ToArrayBuffer(x))

    const privateKey = await crypto.subtle.importKey('pkcs8', base64ToArrayBuffer(rawPrivateKey), { name: 'RSA-OAEP', hash: 'SHA-1' }, true, ['decrypt'])
    const decryptedKey = await crypto.subtle.decrypt(rsaAlgorithm, privateKey, encryptedKey)

    const key = await crypto.subtle.importKey('raw', decryptedKey, { name: 'AES-CBC' }, true, ['encrypt', 'decrypt'])
    const decryptedData = await crypto.subtle.decrypt( { name: 'AES-CBC', iv }, key, encryptedData )

    return decoder.decode(decryptedData)
  } catch(error) {
    // console.log('[Decrypt] Error: ', error)
    return message
  }
}

export async function generateKey() {
  const key = await crypto.subtle.generateKey(
    { name: 'AES-CBC', length: 256 },
    true,
    ['encrypt', 'decrypt']
  )

  const iv = crypto.getRandomValues(new Uint8Array(16))
  const rawKey = await crypto.subtle.exportKey('raw', key)

  return `${ arrayBufferToBase64(rawKey) }.${  arrayBufferToBase64(iv) }`
}

export async function encryptToGroup(message, publicKey) {
  const [rawKey, iv] = publicKey.split('.').map(x => base64ToArrayBuffer(x))
  const key = await crypto.subtle.importKey('raw', rawKey, { name: 'AES-CBC', length: 256 }, true, ['encrypt', 'decrypt'])

  const encryptedData = await crypto.subtle.encrypt(
    { name: 'AES-CBC', iv },
    key,
    encoder.encode(message)
  )

  return arrayBufferToBase64(encryptedData)
}

export async function decryptToGroup(message, publicKey) {
  try {
    const [rawKey, iv] = publicKey.split('.').map(x => base64ToArrayBuffer(x))
    const encryptedMessage = base64ToArrayBuffer(message)
    const key = await crypto.subtle.importKey('raw', rawKey, { name: 'AES-CBC', length: 256 }, true, ['encrypt', 'decrypt'])

    const decryptedData = await crypto.subtle.decrypt( { name: 'AES-CBC', iv }, key, encryptedMessage )

    return decoder.decode(decryptedData)
  } catch(error) {
    return message
  }
}
