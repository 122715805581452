import * as cryptoMobile from './cryptography-mobile'
import * as cryptoWeb from './cryptography-web'

// eslint-disable-next-line no-undef
const isMobile = !globalThis.crypto

export async function encrypt(message, publicKey) {
  if (isMobile) {
    return cryptoMobile.encrypt(message, publicKey)
  }

  return cryptoWeb.encrypt(message, publicKey)
}

export async function decrypt(message, privateKey) {
  if (isMobile) {
    return cryptoMobile.decrypt(message, privateKey)
  }

  return cryptoWeb.decrypt(message, privateKey)
}

export async function generateKey() {
  if (isMobile) {
    return cryptoMobile.generateKey()
  }
  return cryptoWeb.generateKey()
}

export async function encryptToGroup(message, publicKey) {
  if (isMobile) {
    return cryptoMobile.encryptToGroup(message, publicKey)
  }

  return cryptoWeb.encryptToGroup(message, publicKey)
}

export async function decryptToGroup(message, publicKey) {
  if (isMobile) {
    return cryptoMobile.decryptToGroup(message, publicKey)
  }

  return cryptoWeb.decryptToGroup(message, publicKey)
}
