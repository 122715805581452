/* eslint-disable no-console */
import React, {
  useCallback,
  forwardRef,
  useMemo,
  useState,
  useEffect
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import trimMask from '@meta-awesome/functions/src/trimMask'
import PropTypes from 'prop-types'

import { kebabCase, map, isEmpty } from 'lodash'


import ncmMask from '@smartcoop/forms/masks/ncm.mask'
import registerProductSchema from '@smartcoop/forms/schemas/shoppingPlatform/product/registerProduct.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { plus } from '@smartcoop/icons'
import { getSuppliers as getSuppliersService } from '@smartcoop/services/apis/smartcoopApi/resources/organization'
import { getProductGroups as getProductGroupsService } from '@smartcoop/services/apis/smartcoopApi/resources/productGroup'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectUserProfileIsSmartcoop } from '@smartcoop/stores/authentication/selectorAuthentication'
import { ProductsActions } from '@smartcoop/stores/products'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import {
  Item,
  Title
} from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'

import {
  Row,
  ButtonContainer,
  Header,
  Container,
  ButtonLabel,
  CustomIconButton,
  TableContainer,
  FlexDiv
} from './styles'

const EditProductForm = forwardRef((props, formRef) => {
  const { onSuccess, onCancel, loadingData, productData, suppliersProduct } = props

  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()

  const [loading, setLoading] = useState(loadingData)
  const [tableData, setTableData] = useState([])
  const [attribute, setAttribute] = useState('')
  const [value, setValue] = useState('')
  const [eanOptions, setEanOptions] = useState([])
  const [suppliers, setSuppliers] = useState([])

  const userProfileIsSmartcoop = useSelector(selectUserProfileIsSmartcoop)

  useEffect(() => {
    setLoading(loadingData)
  }, [loadingData])

  useEffect(() => {
    setSuppliers(suppliersProduct)
  },[suppliersProduct])

  const options = useMemo(
    () => [
      { value: true, label: t('yes') },
      { value: false, label: t('no') }
    ],
    [t]
  )

  const purchaseOptions = useMemo(
    () => [
      { value: 'scheduled', label: t('scheduled') },
      { value: 'continuous', label: t('continuous') }
    ],[t]
  )

  const columns = useMemo(
    () => [
      {
        title: 'Atributo',
        field: 'attribute'
      },
      {
        title: 'Valor do Atributo',
        field: 'value'
      }
    ],
    []
  )

  const onDeleteClick = useCallback(
    (event, data) => {
      const array = tableData
      array.splice(data.tableData.id, 1)
      setTableData([...array])
    },
    [tableData]
  )

  const handleSubmit = useCallback(
    (formData) => {
      setLoading(true)
      const data = {
        ...formData,
        ean: formData.ean,
        ncm: isEmpty(formData?.ncm) ? null : trimMask(formData?.ncm),
        slug: formData.shortDescription.replace(/\s/g, '-'),
        id: productData.id,
        extraAttributes: [...tableData],
        scheduled: formData.purchaseType === 'scheduled',
        continuous: formData.purchaseType === 'continuous'
      }

      dispatch(
        ProductsActions.editProduct(
          { data, suppliers },
          () => {
            snackbar.success(
              t('your {this} was edited', {
                howMany: 1,
                this: t('product', { howMany: 1 }),
                gender: 'male'
              })
            )
            setLoading(false)
            onSuccess()
          },
          () => setLoading(false)
        )
      )
    },
    [dispatch, onSuccess, productData.id, snackbar, suppliers, t, tableData]
  )

  useEffect(() => {
    setTableData([...productData.extraAttributes])
    let purchaseType = null

    if(productData.scheduled){
      purchaseType = 'scheduled'
    }else if (productData.continuous){
      purchaseType = 'continuous'
    }

    formRef.current.setFieldValue('description', productData.description)
    formRef.current.setFieldValue(
      'shortDescription',
      productData.shortDescription
    )
    formRef.current.setFieldValue('unitOfMeasures', productData.unitOfMeasures)
    productData.barcodes.length > 0 &&
      formRef.current.setFieldValue('ean', map(productData.barcodes, item => kebabCase(item.ean)))
    setEanOptions(map(productData.barcodes, item => ({ value: kebabCase(item.ean), label: item.ean })))
    formRef.current.setFieldValue('dun14', productData.dun14)
    formRef.current.setFieldValue('ncm', productData.ncm)
    formRef.current.setFieldValue('productGroupId', productData.productGroupId)
    formRef.current.setFieldValue('active', productData.active)
    formRef.current.setFieldValue('digitalization', productData.digitalization)
    formRef.current.setFieldValue('cultivate', productData.cultivate)
    formRef.current.setFieldValue('purchasing', productData.purchasing)
    formRef.current.setFieldValue('purchaseType', purchaseType)
    formRef.current.setFieldValue(
      'commercialization',
      productData.commercialization
    )
  }, [formRef, productData])

  const handleAddAttribute = useCallback(() => {
    if (!attribute || !value) {
      snackbar.error(t('insert attribute and value'))
      return
    }
    const obj = {
      attribute,
      value
    }

    setAttribute('')
    setValue('')
    setTableData((state) => [...state, obj])
  }, [attribute, snackbar, t, value])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddAttribute()
    }
  }

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ registerProductSchema }
      onSubmit={ handleSubmit }
      style={ { flex: 1 } }
      loading={ loading }
    >
      <SplitedScreenLayout
        title={ { name: t('products list') } }
        divRightStyle={ { paddingTop: 67 } }
        leftChildren={
          <Container>
            <Header style={ { paddingLeft: 35 } }>
              <Title style={ { fontSize: 16, fontWeight: 600 } }>
                <I18n>pre-registration of products</I18n>
              </Title>
            </Header>

            <Item style={ { padding: '0 35px 0 35px' } }>
              <InputText
                label={ t('productCode') }
                name="productCode"
                detached
                disabled
                value={ productData?.productCode }
              />
              <InputText
                label={ t('description') }
                name="description"
                defaultValue={ productData.description }
              />
              <InputText
                label={ t('shortDescription') }
                name="shortDescription"
                defaultValue={ productData.shortDescription }
              />
              <InputText label={ t('ncm') } name="ncm" setMask={ ncmMask } />
              <InputText
                label={ t('unitOfMeasure', { howMany: 1 }) }
                name="unitOfMeasures"
                defaultValue={ productData.unitOfMeasures }
              />
              <InputSelect
                creatable
                multiple
                label={ t('ean') }
                name="ean"
                options={ eanOptions }
                defaultValue={ productData.ean }
              />
              <InputNumber
                label={ t('dun14') }
                name="dun14"
                maxLength={ 14 }
                defaultValue={ productData.dun14 }
              />

              <InputSelect
                label={ t('product group') }
                name="productGroupId"
                options={ getProductGroupsService }
                defaultValue={ productData.productGroupId }
              />

              <InputSelect
                label={ t('purchase type') }
                name="purchaseType"
                options={ purchaseOptions }
              />
            </Item>
          </Container>
        }
        rightChildren={
          <Container style={ { flex: 1 } }>
            <Row>
              <InputSelect
                detached
                multiple
                label={ t('supplier', { howMany: 2 }) }
                options={ getSuppliersService }
                asyncOptionLabelField="tradeName"
                value={ suppliers }
                onChange={ setSuppliers }
              />
            </Row>
            <Row>
              <RadioGroup
                label="Ativo?"
                name="active"
                options={ options }
                defaultValue={ productData.active }
                variant="row"
              />

              {userProfileIsSmartcoop && (
                <RadioGroup
                  label="Digitalização"
                  name="digitalization"
                  options={ options }
                  defaultValue={ productData.digitalization }
                  variant="row"
                />
              )}
              { userProfileIsSmartcoop && (
                <RadioGroup
                  label="Cultivar"
                  name="cultivate"
                  options={ options }
                  defaultValue={ productData.cultivate }
                  variant="row"
                />
              )}
            </Row>
            { userProfileIsSmartcoop && (
              <Row>
                <RadioGroup
                  label="Compras"
                  name="purchasing"
                  options={ options }
                  defaultValue={ productData.purchasing }
                  variant="row"
                />

                <RadioGroup
                  label="Comercialização"
                  name="commercialization"
                  options={ options }
                  defaultValue={ productData.commercialization }
                  variant="row"
                />
              </Row>
            )}

            <TableContainer>
              <div
                style={ {
                  marginTop: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                } }
              >
                <FlexDiv style={ { marginRight: '20px' } }>
                  <InputText
                    detached
                    value={ attribute }
                    onChange={ ({ target }) => setAttribute(target.value) }
                    label={ t('attribute') }
                    name="attribute"
                    style={ { marginRight: 10, marginBottom: 0 } }
                  />
                  <InputText
                    detached
                    value={ value }
                    onChange={ ({ target }) => setValue(target.value) }
                    label={ t('attribute value') }
                    name="value"
                    style={ { marginBottom: 0 } }
                    onKeyPress={ handleKeyPress }
                  />
                </FlexDiv>
                <FlexDiv>
                  <CustomIconButton onClick={ handleAddAttribute }>
                    <Icon icon={ plus } size={ 12 } />
                    <ButtonLabel>{t('create attribute')}</ButtonLabel>
                  </CustomIconButton>
                </FlexDiv>
              </div>

              <DataTable
                columns={ columns }
                data={ tableData }
                onDeleteClick={ onDeleteClick }
                id="table-edit-product-form"
              />
            </TableContainer>
            <Row style={ { justifyContent: 'flex-end' } }>
              <ButtonContainer>
                <Button
                  id="cancel-order-edit-form"
                  onClick={ onCancel }
                  style={ { flex: 1 } }
                  variant="outlined"
                >
                  <I18n>cancel</I18n>
                </Button>
              </ButtonContainer>

              <ButtonContainer>
                <Button
                  id="submit-order-edit-form"
                  onClick={ () => formRef.current.submit() }
                  style={ { flex: 1 } }
                >
                  <I18n>save</I18n>
                </Button>
              </ButtonContainer>
            </Row>
          </Container>
        }
      />
    </Form>
  )
})

EditProductForm.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  loadingData: PropTypes.bool,
  suppliersProduct: PropTypes.array,
  productData: PropTypes.shape({
    id: PropTypes.string,
    productGroupId: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
    shortDescription: PropTypes.string,
    ncm: PropTypes.string,
    unitOfMeasures: PropTypes.string,
    barcodes: [
      {
        id: PropTypes.string,
        productId: PropTypes.string,
        ean: PropTypes.string
      }
    ],
    ean: PropTypes.array,
    dun14: PropTypes.string,
    productCode: PropTypes.string,
    cultivate: PropTypes.bool,
    active: PropTypes.bool,
    purchasing: PropTypes.bool,
    commercialization: PropTypes.bool,
    digitalization: PropTypes.bool,
    extraAttributes: [
      {
        attribute: PropTypes.string,
        value: PropTypes.string
      }
    ],
    continuous: PropTypes.bool,
    scheduled: PropTypes.bool,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    minReceiptDate: PropTypes.string
  })
}

EditProductForm.defaultProps = {
  onSuccess: () => {},
  onCancel: () => {},
  suppliersProduct: [],
  loadingData: true,
  productData: {
    id: '',
    productGroupId: '',
    description: '',
    slug: '',
    shortDescription: '',
    ncm: '',
    unitOfMeasures: '',
    barcodes: [
      {
        id: '',
        productId: '',
        ean: ''
      }
    ],
    dun14: '',
    productCode: '',
    cultivate: false,
    active: false,
    purchasing: false,
    commercialization: false,
    digitalization: false,
    extraAttributes: [
      {
        attribute: '',
        value: ''
      }
    ],
    continuous: false,
    scheduled: false,
    createdAt: '',
    updatedAt: '',
    minReceiptDate: ''
  }
}

export default EditProductForm
