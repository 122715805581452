import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import { Item } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`

export const Divider = styled.hr`
  border: none;
  border-bottom: 0.935252px solid #E0E0E0;
  margin: 0;
  width: 100%;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`
export const OrderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.5px;
`
export const OrderSubTitleItem = styled(Item)`
  font-size: 16px;
  font-weight: 600;
  padding-top: 0;
  padding-bottom: 0;
`

export const OrderItem = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  padding-bottom: 13px;
`

export const OrderItemText = styled(Item)`
  padding-top: 0;
  padding-bottom: 0;
`
export const OrganizationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
`

export const OrganizationItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
`

export const AccordionItems = styled.div`
  padding-left: 25px;
  padding-bottom: 20px;
`
export const AccordionItem = styled.div`
  padding-top: 10px;
`

export const AccordionTitle = styled.span`
  font-weight: bold;
`
