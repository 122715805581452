export default () => ({
  '@global': {
    '.leaflet-center': {
      position: 'relative !important',
      left: 0,
      right: 0,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      zIndex: 1002
    },

    '.leaflet-center .leaflet-control': {
      bottom: 0,
      width: '90%',
      marginTop: 12,
      alignItems: 'center'
    },

    '.leaflet-top.leaflet-right': {
      zIndex: 1001
    },

    '.leaflet-top.leaflet-left': {
      zIndex: 1001,
      display: 'flex'
    },

    '.leaflet-control-container .leaflet-control-bottomcenter': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0
    }
  }
})
