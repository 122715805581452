import React, { useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'

import filterTechnicalPortfolio from '@smartcoop/forms/schemas/property/filterTechnicalPortfolio.schema'
import { useT } from '@smartcoop/i18n'
import Form from '@smartcoop/web-components/Form'
import InputCpfCnpj from '@smartcoop/web-components/InputCpfCnpj'
import InputEmail from '@smartcoop/web-components/InputEmail'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputPhone from '@smartcoop/web-components/InputPhone'
import InputText from '@smartcoop/web-components/InputText'
import Loader from '@smartcoop/web-components/Loader'

import { Container } from './styles'

const FilterTechnicalPortfolioForm = forwardRef((props, formRef) => {
  const { loading, onSubmit } = props
  const t = useT()

  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  return (
    <Container>
      {
        loading ? <Loader /> : (
          <Form
            style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
            ref={ formRef }
            schemaConstructor={ filterTechnicalPortfolio }
            onSubmit={ handleSubmit }
          >
            <InputText
              name="name"
              label={ t('name') }
              fullWidth
            />
            <InputNumber
              name="registry"
              label={ t('register number') }
              fullWidth
            />
            <InputPhone
              name="cellPhone"
              label={ t('phone') }
              fullWidth
            />
            <InputEmail
              name="email"
              label={ t('email') }
              fullWidth
            />
            <InputCpfCnpj
              name="document"
              label="CPF"
              onlyCpf
              fullWidth
            />
          </Form>
        )
      }
    </Container>
  )
})

FilterTechnicalPortfolioForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func
}

FilterTechnicalPortfolioForm.defaultProps = {
  loading: false,
  onSubmit: () => {}
}

export default FilterTechnicalPortfolioForm
