import React, { useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import Popover from '@material-ui/core/Popover'

import I18n, { useT } from '@smartcoop/i18n'
import { arrowDown, forward, info, trash } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import CheckboxCard from '@smartcoop/web-components/FilteredCheckboxGroup/CheckboxCard'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import CircularLoader from '@smartcoop/web-components/Loader/CircularLoader'

import MessageStatusIcon from '../../../components/MessageStatusIcon/MessageStatusIcon'
import { useLocalFile } from '../../../hooks/useLocalFile'
import { formatChatDate } from '../../../utils/chatDateFormat'
import ChatDocument from '../ChatDocument'
import ChatMapLocation from '../ChatMapLocation'
import {
  ActionButton,
  AudioPlayer,
  ButtonContainer,
  Card,
  CardDetail,
  DateSpan,
  DeletedSpan,
  ForwardSpan,
  ImageLoading,
  ImagePreview,
  Line,
  LineWrapper,
  NameSpan,
  PopoverButton,
  VideoPlayer,
  MessageText
} from './styles'

const ChatMessageItem = ({
  message,
  localUser,
  showCheckbox,
  selected,
  handleChangeSelected,
  onOpenImage,
  trySendAgain,
  conversationFile,
  isGroup
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const t = useT()
  const { inProggress } = useLocalFile(message, conversationFile)

  const receiver = useMemo(
    () => message.whoSend !== localUser?.userId,
    [message, localUser]
  )

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickDelete = () => {
    handleChangeSelected(message)
    handleClose()
  }

  const handleClickForward = () => {
    handleChangeSelected(message)
    handleClose()
  }

  const deletedMessage = useMemo(() => !!message?.delAt, [message.delAt])

  const convertToJSON = value => {
    try {
      return JSON.parse(value)
    } catch(error) {
      return false
    }
  }

  const messageContent = useMemo(() => {
    if(message?.isGeolocation && message?.message) {
      const coordinates = convertToJSON(message?.message)

      if (!coordinates) {
        return <MessageText>{message.message?.trim()}</MessageText>
      }

      return (
        <ChatMapLocation coordinates={ coordinates } />
      )
    }

    if (message.isDocument && message.documentFormat === 'audio') {
      return (
        <AudioPlayer receiver={ receiver } src={ conversationFile?.source } preload="auto" controls>
          {t('datagrid fetch load error')}
        </AudioPlayer>
      )
    }

    if (message.isDocument && message.documentFormat === 'video') {
      return (
        <VideoPlayer src={ conversationFile?.source } controls>
          {t('datagrid fetch load error')}
        </VideoPlayer>
      )
    }

    if (message.isDocument && message.documentFormat === 'image') {
      if (inProggress && !conversationFile) {
        return (
          <ImageLoading>
            <CircularLoader />
          </ImageLoading>
        )
      }

      return (
        <ImagePreview
          src={ conversationFile?.source }
          onClick={ () => onOpenImage(conversationFile?.source, conversationFile?.documentName) }
          alt={ conversationFile?.documentName }
        />
      )
    }

    if (message.isDocument && message.documentFormat === 'document') {
      return (
        <ChatDocument
          conversationItem={ conversationFile }
          receiver={ receiver }
        />
      )
    }

    return  <MessageText>{message.message?.trim()}</MessageText>
  }, [
    conversationFile,
    inProggress,
    message,
    onOpenImage,
    receiver,
    t
  ])

  const systemMessage = useMemo(() => !message.whoSend, [message.whoSend])

  return (
    <LineWrapper showCheckbox={ showCheckbox } >
      { (showCheckbox && !deletedMessage && message.whoSend) && (
        <CheckboxCard
          style={ { backgroundColor: 'transparent', margin: 0, paddingLeft: 4 } }
          onChange={ () => handleChangeSelected(message) }
          onClick={ event => event.stopPropagation() }
          checked={ selected }
          value={ selected }
        />
      ) }

      <Line receiver={ receiver } id={ message.id } system={ systemMessage }>
        <Card receiver={ receiver } system={ systemMessage }>
          { (!deletedMessage && !systemMessage) && (
            <ActionButton id="action">
              <Icon
                onClick={ handleClick }
                icon={ arrowDown }
                color={ colors.text }
                className="delete-message"
              />
            </ActionButton>
          ) }
          <Popover
            id={ message?.id }
            open={ open }
            anchorEl={ anchorEl }
            onClose={ handleClose }
            anchorOrigin={ {
              vertical: 'bottom',
              horizontal: 'center'
            } }
          >
            <ButtonContainer>
              <PopoverButton
                color={ colors.white }
                onClick={ handleClickDelete }
              >
                <Icon icon={ trash } size={ 20 } />
                <I18n>delete message</I18n>
              </PopoverButton>
              <PopoverButton
                color={ colors.white }
                onClick={ handleClickForward }
              >
                <Icon icon={ forward } size={ 20 } />
                <I18n>forward message</I18n>
              </PopoverButton>
            </ButtonContainer>
          </Popover>

          { !systemMessage && (
            <CardDetail
              forward={ (message.forward || message.fileAction === 'forward') }
              group={ isGroup && !systemMessage && receiver }
            >
              {(isGroup && !systemMessage && receiver) && (
                <NameSpan>{message.nickname}</NameSpan>
              )}
              {(message.forward || message.fileAction === 'forward') && (
                <ForwardSpan>
                  <Icon
                    icon={ forward }
                    size={ 10 }
                    color={ colors.mediumGrey }
                  />
                  {t('forwarded')}
                </ForwardSpan>
              )}
              <DateSpan>{formatChatDate(message.delAt || message.sendAt, t)}</DateSpan>
              {(!receiver && !deletedMessage) && (
                <MessageStatusIcon id="status" status={ (message?.status || '').toUpperCase() } size={ 15 } />
              )}
            </CardDetail>
          )}
          {deletedMessage ?
            (<DeletedSpan>{t('message deleted')}</DeletedSpan>) :
            (messageContent)
          }
        </Card>
        {Boolean(message.syncId) && (
          <IconButton size="small" onClick={ () => trySendAgain(message.syncId, message.isDocument) }>
            <Icon icon={ info } color={ colors.error } size={ 15 } />
          </IconButton>
        )}
      </Line>
    </LineWrapper>
  )
}

ChatMessageItem.propTypes = {
  message: PropTypes.object.isRequired,
  localUser: PropTypes.object,
  showCheckbox:PropTypes.bool.isRequired,
  selected:PropTypes.bool.isRequired,
  handleChangeSelected:PropTypes.func.isRequired,
  trySendAgain:PropTypes.func.isRequired,
  onOpenImage:PropTypes.func.isRequired,
  conversationFile: PropTypes.object,
  isGroup: PropTypes.bool
}

ChatMessageItem.defaultProps = {
  localUser: undefined,
  conversationFile: null,
  isGroup: false
}

export default ChatMessageItem
