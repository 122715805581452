// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
      <g>
        <path fill="#D3D3D3" d="M106.8,88c-0.3,0.1-0.5,0.3-0.8,0.4C106.2,88.2,106.5,88.1,106.8,88z"/>
        <path fill="#D3D3D3" d="M104.1,89.2c-0.3,0.1-0.5,0.2-0.8,0.3C103.5,89.5,103.8,89.3,104.1,89.2z"/>
        <path fill="#D3D3D3" d="M100.9,90.4c-0.1,0-0.2,0.1-0.3,0.1C100.7,90.5,100.8,90.5,100.9,90.4z"/>
        <path fill="#D3D3D3" d="M97.9,91.4c-0.3,0.1-0.5,0.1-0.8,0.2C97.4,91.5,97.7,91.4,97.9,91.4z"/>
        <path fill="#D3D3D3" d="M114.2,80.9c0.5-0.1,0.9-0.1,1.4-0.2C115.1,80.8,114.6,80.8,114.2,80.9z"/>
        <path fill="#D3D3D3" d="M116.1,80.6c0.6-0.1,1.3-0.3,1.9-0.4C117.4,80.3,116.8,80.5,116.1,80.6z"/>
        <path fill="#D3D3D3" d="M118,80.2L118,80.2c-1.3,1.2-2.7,2.4-4.1,3.4C115.3,82.5,116.7,81.4,118,80.2z"/>
        <path fill="#D3D3D3" d="M92.1,92.5c-0.2,0-0.3,0.1-0.5,0.1C91.7,92.6,91.9,92.6,92.1,92.5z"/>
        <path fill="#D3D3D3" d="M79.7,92.6c-0.2,0-0.5-0.1-0.7-0.1C79.2,92.6,79.4,92.6,79.7,92.6z"/>
        <path fill="#D3D3D3" d="M82.9,92.9c-0.2,0-0.4,0-0.5,0C82.6,92.9,82.8,92.9,82.9,92.9z"/>
        <path fill="#D3D3D3" d="M76.9,92.2c-0.3-0.1-0.5-0.1-0.8-0.2C76.4,92.1,76.6,92.1,76.9,92.2z"/>
        <path fill="#D3D3D3" d="M112.1,81.1c0.5,0,0.9-0.1,1.4-0.1C113.1,81,112.6,81.1,112.1,81.1z"/>
        <path fill="#D3D3D3" d="M95.2,92c-0.3,0.1-0.6,0.1-0.9,0.2C94.6,92.1,94.9,92.1,95.2,92z"/>
        <path fill="#D3D3D3" d="M85.6,93c0.2,0,0.3,0,0.5,0c-0.3,0-0.6,0-0.9,0C85.3,93,85.5,93,85.6,93z"/>
        <path fill="#D3D3D3" d="M88.8,92.9c-0.2,0-0.5,0-0.7,0C88.3,92.9,88.6,92.9,88.8,92.9z"/>
        <path fill="#D3D3D3" d="M72.8,61.3c0.6,0.8,1.2,1.6,1.9,2.4C74.1,62.9,73.4,62.1,72.8,61.3z"/>
        <path fill="#D3D3D3" d="M79.6,68.7c0.7,0.6,1.4,1.3,2.2,1.9C81,70,80.3,69.3,79.6,68.7z"/>
        <path fill="#D3D3D3" d="M84.5,72.7c0.8,0.5,1.5,1,2.3,1.5C86,73.7,85.3,73.2,84.5,72.7z"/>
        <path fill="#D3D3D3" d="M74.8,63.8c0.6,0.7,1.3,1.5,2,2.2C76.1,65.2,75.5,64.5,74.8,63.8z"/>
        <path fill="#D3D3D3" d="M82,70.7c0.7,0.6,1.4,1.1,2.2,1.7C83.4,71.9,82.7,71.3,82,70.7z"/>
        <path fill="#D3D3D3" d="M68.8,55.2c0.5,0.9,1.1,1.8,1.7,2.7C69.9,57,69.3,56.1,68.8,55.2z"/>
        <path fill="#D3D3D3" d="M70.7,58.3c0.6,0.8,1.1,1.7,1.7,2.5C71.9,60,71.3,59.2,70.7,58.3z"/>
        <path fill="#D3D3D3" d="M73.8,91.5c-0.1,0-0.3-0.1-0.4-0.1C73.5,91.4,73.7,91.5,73.8,91.5z"/>
        <path fill="#D3D3D3" d="M95.5,78.6c0.8,0.3,1.6,0.6,2.5,0.8C97.2,79.2,96.3,78.9,95.5,78.6z"/>
        <path fill="#D3D3D3" d="M98.4,79.5c0.8,0.2,1.6,0.5,2.4,0.6C100,80,99.2,79.8,98.4,79.5z"/>
        <path fill="#D3D3D3" d="M101,80.2c0.8,0.2,1.7,0.3,2.5,0.5C102.7,80.6,101.9,80.4,101,80.2z"/>
        <path fill="#D3D3D3" d="M87.3,74.5c0.8,0.5,1.6,0.9,2.4,1.4C88.8,75.4,88,75,87.3,74.5z"/>
        <path fill="#D3D3D3" d="M104.1,80.8c0.8,0.1,1.7,0.2,2.5,0.3C105.7,81,104.9,80.9,104.1,80.8z"/>
        <path fill="#D3D3D3" d="M89.9,76c0.8,0.4,1.5,0.8,2.3,1.2C91.5,76.8,90.7,76.4,89.9,76z"/>
        <path fill="#D3D3D3" d="M107,81.1c0.8,0.1,1.6,0.1,2.5,0.1C108.6,81.2,107.8,81.2,107,81.1z"/>
        <path fill="#D3D3D3" d="M92.6,77.3c0.8,0.4,1.6,0.7,2.4,1C94.2,78,93.4,77.7,92.6,77.3z"/>
        <path fill="#D3D3D3" d="M65.1,88.4c-0.1,0-0.2-0.1-0.3-0.1C64.9,88.3,65,88.3,65.1,88.4z"/>
        <path fill="#D3D3D3" d="M50.1,77.2c0,0-0.1-0.1-0.1-0.1C50.1,77.1,50.1,77.1,50.1,77.2z"/>
        <path fill="#D3D3D3" d="M52.6,79.7c-0.2-0.2-0.4-0.4-0.6-0.6C52.2,79.3,52.4,79.5,52.6,79.7z"/>
        <path fill="#D3D3D3" d="M62.4,86.9c-0.2-0.1-0.4-0.2-0.6-0.4C61.9,86.7,62.1,86.8,62.4,86.9z"/>
        <path fill="#D3D3D3" d="M48.2,74.9c-0.2-0.2-0.3-0.4-0.5-0.7C47.9,74.4,48,74.6,48.2,74.9z"/>
        <path fill="#D3D3D3" d="M54.8,81.7c-0.2-0.2-0.4-0.3-0.6-0.5C54.4,81.3,54.6,81.5,54.8,81.7z"/>
        <path fill="#D3D3D3" d="M59.9,85.5c-0.2-0.1-0.5-0.3-0.7-0.4C59.5,85.2,59.7,85.3,59.9,85.5z"/>
        <path fill="#D3D3D3" d="M57.1,83.6c-0.1,0-0.1-0.1-0.2-0.1C57,83.5,57.1,83.5,57.1,83.6z"/>
        <path fill="#D3D3D3" d="M186,72.2c-4.1-15.4-19.9-24.6-35.3-20.6c0,0-1.1,0.3-3.1,0.8c-4.9-18.3-21.6-31.8-41.5-31.8
          c-15.6,0-29.3,8.3-36.8,20.8c1.1,3.7,2.6,7.3,4.6,10.8c8.1,14,22.1,23,35.6,23c2.4,0,4.7-0.3,7-0.9c0.5-0.1,1-0.2,1.5-0.2
          c2.2,0,4.2,1.2,5.3,3.1c1.3,2.4,0.8,5.4-1.2,7.3c-3,2.8-6.3,5.2-9.8,7.3c-8.1,4.7-17.4,7.2-26.7,7.2c-19.1,0-36.9-10.3-46.4-26.8
          c-1.1-1.9-2-3.8-2.9-5.8c-1.6,2.3-2.9,4.9-3.7,7.8c-0.8,3.1-1,6.2-0.7,9.1c-6.5,1.8-10.5,2.8-10.5,2.8c-6.1,1.6-9.8,7.9-8.1,14
          c1.4,5.4,6.5,8.8,11.8,8.5h132.1c0,0,5.5-0.3,8.2-1C181,103.6,190.2,87.7,186,72.2z"/>
        <path fill="#D3D3D3" d="M79.2,68.3c-0.7-0.6-1.4-1.3-2-2C77.8,67,78.5,67.7,79.2,68.3z"/>
        <path fill="#D3D3D3" d="M46.4,72.4c-0.2-0.2-0.3-0.5-0.5-0.7C46.1,72,46.3,72.2,46.4,72.4z"/>
        <path fill="#D3D3D3" d="M70.7,90.6c-0.2-0.1-0.4-0.2-0.7-0.2C70.3,90.5,70.5,90.5,70.7,90.6z"/>
        <path fill="#D3D3D3" d="M68.1,89.7c-0.3-0.1-0.5-0.2-0.8-0.3C67.6,89.5,67.8,89.6,68.1,89.7z"/>
        <path fill="#D3D3D3" d="M111.6,81.1c-0.6,0-1.3,0.1-1.9,0.1C110.3,81.2,110.9,81.2,111.6,81.1z"/>
        <path fill="#6EC4E9" d="M124.7,121.1c-1-0.2-2,0.2-2.5,1l0,0l-34.8,53.7c-0.6,0.9-1.1,1.8-1.4,2.9c-1.6,5.4,2,11,8.1,12.4
          c6,1.4,12.2-1.7,13.9-7.1h0l8.4-27.9c0,0,0-0.1,0-0.1l0,0l9.8-32.5l0-0.2C126.5,122.5,125.8,121.4,124.7,121.1z"/>
        <path fill="#6EC4E9" d="M86.4,152.4l11.5-28.9l0.1-0.1c0.4-0.9-0.2-1.9-1.2-2.2c-0.9-0.3-1.9,0-2.3,0.7h0l-19.4,24.8
          c-0.4,0.5-0.7,1-1,1.6c-1.2,3,0.6,6.4,4,7.5C81.5,156.8,85.1,155.3,86.4,152.4L86.4,152.4z"/>
        <path fill="#DEDDDD" d="M118,80.2c-0.6,0.2-1.3,0.3-1.9,0.4c-0.2,0-0.4,0.1-0.6,0.1c-0.4,0.1-0.9,0.1-1.4,0.2
          c-0.2,0-0.4,0.1-0.7,0.1c-0.5,0.1-0.9,0.1-1.4,0.1c-0.2,0-0.4,0-0.5,0c-0.6,0-1.3,0.1-1.9,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0
          c-0.8,0-1.6,0-2.5-0.1c-0.1,0-0.3,0-0.4,0c-0.8-0.1-1.7-0.2-2.5-0.3c-0.2,0-0.3-0.1-0.5-0.1c-0.8-0.1-1.7-0.3-2.5-0.5
          c-0.1,0-0.2,0-0.3-0.1c-0.8-0.2-1.6-0.4-2.4-0.6c-0.1,0-0.3-0.1-0.4-0.1c-0.8-0.3-1.6-0.5-2.5-0.8c-0.2-0.1-0.3-0.1-0.5-0.2
          c-0.8-0.3-1.6-0.7-2.4-1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.8-0.4-1.5-0.7-2.3-1.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.8-0.4-1.6-0.9-2.4-1.4
          c-0.2-0.1-0.3-0.2-0.5-0.3c-0.8-0.5-1.5-1-2.3-1.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.7-0.5-1.4-1.1-2.2-1.7c-0.1-0.1-0.1-0.1-0.2-0.2
          c-0.7-0.6-1.5-1.2-2.2-1.9c-0.1-0.1-0.3-0.3-0.4-0.4c-0.7-0.6-1.4-1.3-2-2c-0.1-0.1-0.2-0.2-0.3-0.4c-0.7-0.7-1.3-1.4-2-2.2
          c0,0-0.1-0.1-0.1-0.1c-0.7-0.8-1.3-1.6-1.9-2.4c-0.1-0.2-0.2-0.3-0.4-0.5c-0.6-0.8-1.2-1.6-1.7-2.5c-0.1-0.2-0.2-0.3-0.3-0.5
          c-0.6-0.9-1.1-1.8-1.7-2.7c-1.2-2-2.2-4.1-3.1-6.1c-7.7-18-5.2-37.5,6-49c-3.4,1-6.7,2.5-9.9,4.3C41.5,16,33.2,40.4,40.9,61.6
          c0.9,2.6,2.1,5.1,3.5,7.6c0.5,0.9,1,1.7,1.6,2.5c0.2,0.2,0.3,0.5,0.5,0.7c0.4,0.6,0.8,1.2,1.3,1.8c0.2,0.2,0.3,0.4,0.5,0.7
          c0.6,0.8,1.2,1.5,1.8,2.2c0,0,0.1,0.1,0.1,0.1c0.6,0.7,1.2,1.3,1.9,2c0.2,0.2,0.4,0.4,0.6,0.6c0.5,0.5,1,1,1.6,1.4
          c0.2,0.2,0.4,0.3,0.6,0.5c0.7,0.6,1.4,1.2,2.2,1.8c0.1,0,0.1,0.1,0.2,0.1c0.7,0.5,1.4,1,2.1,1.5c0.2,0.1,0.5,0.3,0.7,0.4
          c0.6,0.4,1.2,0.7,1.8,1.1c0.2,0.1,0.4,0.2,0.6,0.4c0.8,0.5,1.6,0.9,2.5,1.3c0.1,0.1,0.2,0.1,0.3,0.1c0.7,0.3,1.5,0.7,2.2,1
          c0.3,0.1,0.5,0.2,0.8,0.3c0.7,0.3,1.3,0.5,2,0.7c0.2,0.1,0.4,0.2,0.7,0.2c0.9,0.3,1.8,0.5,2.6,0.8c0.1,0,0.3,0.1,0.4,0.1
          c0.8,0.2,1.5,0.4,2.3,0.5c0.3,0.1,0.5,0.1,0.8,0.2c0.7,0.1,1.4,0.2,2.1,0.3c0.2,0,0.5,0.1,0.7,0.1c0.9,0.1,1.8,0.2,2.7,0.3
          c0.2,0,0.4,0,0.5,0c0.7,0,1.5,0.1,2.3,0.1c0.3,0,0.6,0,0.9,0c0.7,0,1.4,0,2-0.1c0.2,0,0.5,0,0.7,0c0.9-0.1,1.8-0.1,2.7-0.3
          c0.2,0,0.3-0.1,0.5-0.1c0.7-0.1,1.5-0.2,2.2-0.4c0.3-0.1,0.6-0.1,0.9-0.2c0.7-0.1,1.3-0.3,2-0.5c0.3-0.1,0.5-0.1,0.8-0.2
          c0.9-0.2,1.8-0.5,2.7-0.8c0.1,0,0.2-0.1,0.3-0.1c0.8-0.3,1.6-0.6,2.3-0.9c0.3-0.1,0.5-0.2,0.8-0.3c0.6-0.3,1.3-0.6,1.9-0.9
          c0.3-0.1,0.5-0.2,0.8-0.4c0.9-0.4,1.7-0.9,2.6-1.4c1.6-0.9,3.1-1.9,4.6-3C115.3,82.5,116.7,81.4,118,80.2
          C118,80.2,118,80.2,118,80.2z"/>
        <path fill="#FFFFFF" d="M70.1,90.4c-0.7-0.2-1.3-0.5-2-0.7C68.8,89.9,69.4,90.2,70.1,90.4z"/>
        <path fill="#FFFFFF" d="M86.8,74.2c0.2,0.1,0.3,0.2,0.5,0.3C87.1,74.4,86.9,74.3,86.8,74.2z"/>
        <path fill="#FFFFFF" d="M109.6,81.2c-0.1,0-0.1,0-0.2,0C109.5,81.2,109.5,81.2,109.6,81.2C109.6,81.2,109.6,81.2,109.6,81.2z"/>
        <path fill="#FFFFFF" d="M89.6,75.9c0.1,0.1,0.2,0.1,0.3,0.2C89.8,76,89.7,75.9,89.6,75.9z"/>
        <path fill="#FFFFFF" d="M98,79.4c0.1,0,0.3,0.1,0.4,0.1C98.2,79.5,98.1,79.4,98,79.4z"/>
        <path fill="#FFFFFF" d="M95,78.4c0.2,0.1,0.3,0.1,0.5,0.2C95.3,78.5,95.2,78.4,95,78.4z"/>
        <path fill="#FFFFFF" d="M61.7,86.6c-0.6-0.4-1.2-0.7-1.8-1.1C60.5,85.9,61.1,86.2,61.7,86.6z"/>
        <path fill="#FFFFFF" d="M79,92.5c-0.7-0.1-1.4-0.2-2.1-0.3C77.6,92.3,78.3,92.4,79,92.5z"/>
        <path fill="#FFFFFF" d="M92.2,77.2c0.1,0.1,0.2,0.1,0.3,0.2C92.5,77.3,92.3,77.2,92.2,77.2z"/>
        <path fill="#FFFFFF" d="M82.4,92.9c-0.9-0.1-1.8-0.2-2.7-0.3C80.6,92.7,81.5,92.8,82.4,92.9z"/>
        <path fill="#FFFFFF" d="M72.5,60.8c0.1,0.2,0.2,0.3,0.4,0.5C72.7,61.1,72.6,61,72.5,60.8z"/>
        <path fill="#FFFFFF" d="M70.4,57.9c0.1,0.2,0.2,0.3,0.3,0.5C70.6,58.2,70.5,58,70.4,57.9z"/>
        <path fill="#FFFFFF" d="M76.1,92c-0.8-0.2-1.5-0.3-2.3-0.5C74.6,91.7,75.3,91.9,76.1,92z"/>
        <path fill="#FFFFFF" d="M73.4,91.4c-0.9-0.2-1.8-0.5-2.6-0.8C71.6,90.9,72.5,91.2,73.4,91.4z"/>
        <path fill="#FFFFFF" d="M84.1,72.4c0.1,0.1,0.2,0.2,0.4,0.3C84.4,72.6,84.3,72.5,84.1,72.4z"/>
        <path fill="#FFFFFF" d="M81.8,70.6c0.1,0.1,0.1,0.1,0.2,0.2C81.9,70.7,81.8,70.6,81.8,70.6z"/>
        <path fill="#FFFFFF" d="M74.7,63.6c0,0,0.1,0.1,0.1,0.1C74.8,63.7,74.8,63.7,74.7,63.6z"/>
        <path fill="#FFFFFF" d="M77.1,66.3c-0.1-0.1-0.2-0.2-0.3-0.4C76.9,66,77,66.2,77.1,66.3z"/>
        <path fill="#FFFFFF" d="M79.2,68.3c0.1,0.1,0.3,0.3,0.4,0.4C79.5,68.6,79.3,68.4,79.2,68.3z"/>
        <path fill="#FFFFFF" d="M46,71.7c-0.5-0.8-1.1-1.6-1.6-2.5C44.9,70.1,45.4,70.9,46,71.7z"/>
        <path fill="#FFFFFF" d="M56.9,83.4c-0.7-0.6-1.5-1.2-2.2-1.8C55.5,82.3,56.2,82.9,56.9,83.4z"/>
        <path fill="#FFFFFF" d="M91.6,92.6c-0.9,0.1-1.8,0.2-2.7,0.3C89.7,92.8,90.6,92.7,91.6,92.6z"/>
        <path fill="#FFFFFF" d="M109.3,86.6c-0.8,0.5-1.7,0.9-2.6,1.4C107.6,87.6,108.5,87.1,109.3,86.6z"/>
        <path fill="#FFFFFF" d="M106,88.4c-0.6,0.3-1.3,0.6-1.9,0.9C104.7,89,105.3,88.7,106,88.4z"/>
        <path fill="#FFFFFF" d="M54.2,81.2c-0.5-0.5-1.1-0.9-1.6-1.4C53.1,80.2,53.6,80.7,54.2,81.2z"/>
        <path fill="#FFFFFF" d="M64.8,88.2c-0.8-0.4-1.6-0.8-2.5-1.3C63.2,87.4,64,87.8,64.8,88.2z"/>
        <polygon fill="#FFFFFF" points="118,80.2 118,80.2 118,80.2 		"/>
        <path fill="#FFFFFF" d="M103.3,89.6c-0.8,0.3-1.6,0.6-2.3,0.9C101.7,90.2,102.5,89.9,103.3,89.6z"/>
        <path fill="#FFFFFF" d="M97.1,91.6c-0.7,0.2-1.3,0.3-2,0.5C95.8,91.9,96.5,91.7,97.1,91.6z"/>
        <path fill="#FFFFFF" d="M94.3,92.2c-0.7,0.1-1.5,0.3-2.2,0.4C92.8,92.4,93.6,92.3,94.3,92.2z"/>
        <path fill="#FFFFFF" d="M59.2,85c-0.7-0.5-1.4-1-2.1-1.5C57.8,84.1,58.5,84.6,59.2,85z"/>
        <path fill="#FFFFFF" d="M100.6,90.6c-0.9,0.3-1.8,0.6-2.7,0.8C98.8,91.1,99.7,90.9,100.6,90.6z"/>
        <path fill="#FFFFFF" d="M47.7,74.2c-0.4-0.6-0.9-1.2-1.3-1.8C46.9,73,47.3,73.6,47.7,74.2z"/>
        <path fill="#FFFFFF" d="M50,77.1c-0.6-0.7-1.2-1.5-1.8-2.2C48.8,75.6,49.4,76.4,50,77.1z"/>
        <path fill="#FFFFFF" d="M103.6,80.7c0.2,0,0.3,0.1,0.5,0.1C103.9,80.8,103.7,80.7,103.6,80.7z"/>
        <path fill="#FFFFFF" d="M100.8,80.2c0.1,0,0.2,0,0.3,0.1C100.9,80.2,100.9,80.2,100.8,80.2z"/>
        <path fill="#FFFFFF" d="M67.3,89.4c-0.7-0.3-1.5-0.6-2.2-1C65.9,88.7,66.6,89,67.3,89.4z"/>
        <path fill="#FFFFFF" d="M106.6,81.1c0.1,0,0.3,0,0.4,0C106.8,81.1,106.7,81.1,106.6,81.1z"/>
        <path fill="#FFFFFF" d="M52,79.1c-0.6-0.6-1.3-1.3-1.9-2C50.8,77.9,51.4,78.5,52,79.1z"/>
        <path fill="#FFFFFF" d="M113.5,81c0.2,0,0.4-0.1,0.7-0.1C114,80.9,113.7,81,113.5,81z"/>
        <path fill="#FFFFFF" d="M88.1,92.9c-0.7,0-1.4,0.1-2,0.1C86.7,93,87.4,93,88.1,92.9z"/>
        <path fill="#FFFFFF" d="M85.2,93c-0.8,0-1.5,0-2.3-0.1C83.7,93,84.4,93,85.2,93z"/>
        <path fill="#FFFFFF" d="M111.6,81.1c0.2,0,0.4,0,0.5,0C111.9,81.1,111.7,81.1,111.6,81.1z"/>
        <path fill="#FFFFFF" d="M115.5,80.7c0.2,0,0.4-0.1,0.6-0.1C115.9,80.6,115.7,80.7,115.5,80.7z"/>
      </g>
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
