import React, { useMemo, useState, useCallback, useEffect } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import { find, isEmpty } from 'lodash'
import map from 'lodash/map'
import size from 'lodash/size'

import { arrowRightSimple } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import { generateArrayOfDate } from '@smartcoop/utils/dates'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'

import { Container, Tab } from './styles'

const DateTabs = props => {
  const {
    endDate,
    initialDate,
    mode,
    format,
    currentYearFormat,
    onChange,
    tabsQuantityToBeShown,
    navigateQuantity,
    viewLastFirst,
    defaultValue,
    ...rest
  } = props

  const [selectedItem, setSelectedItem] = useState(defaultValue)
  const [mounted, setMounted] = useState(false)

  const arrayOfDates = useMemo(
    () => generateArrayOfDate(initialDate, endDate, mode),
    [endDate, initialDate, mode]
  )

  const arraySize = useMemo(
    () => size(arrayOfDates),
    [arrayOfDates]
  )

  const generateNewIndex = useCallback(
    (oldIndex, displacement) => {
      let newIndex = oldIndex + displacement
      newIndex = newIndex > arraySize-tabsQuantityToBeShown ? arraySize-tabsQuantityToBeShown : newIndex
      return newIndex <= 0 ? 0 : newIndex
    },
    [arraySize, tabsQuantityToBeShown]
  )

  const [index, setIndex] = useState(0)

  const dates = useMemo(
    () => arrayOfDates.slice(index, index + tabsQuantityToBeShown > arraySize ? arraySize : index + tabsQuantityToBeShown),
    [arrayOfDates, arraySize, index, tabsQuantityToBeShown]
  )

  const disabledLeftButton = useMemo(
    () => index <= 0,
    [index]
  )

  const disabledRightButton = useMemo(
    () => index + tabsQuantityToBeShown >= arraySize,
    [arraySize, index, tabsQuantityToBeShown]
  )

  const handleIndexChange = useCallback(
    (displacement) => {
      setIndex(generateNewIndex(index, displacement))
    },
    [generateNewIndex, index]
  )

  useEffect(() => {
    if(!isEmpty(dates) && moment(selectedItem).format('MM-YYYY') === moment(defaultValue).format('MM-YYYY')) {
      setMounted(true)
    }
    else {
      const hasToReset = !find(arrayOfDates, item => moment(item).isSameOrAfter(moment(selectedItem)))

      if(hasToReset) {
        setSelectedItem(defaultValue)
        map(arrayOfDates, (item, itemIndex) => {
          if(moment(item).format('MM-YYYY') === moment(defaultValue).format('MM-YYYY')) {
            setIndex(generateNewIndex(0, arraySize-itemIndex-tabsQuantityToBeShown <= 0 ? arraySize-tabsQuantityToBeShown : arraySize-itemIndex-tabsQuantityToBeShown ))
          }
        })
      }
    }
    if(!mounted) {
      if(moment(selectedItem).format('MM-YYYY') === moment(defaultValue).format('MM-YYYY')) {
        map(arrayOfDates, (item, itemIndex) => {
          if(moment(item).format('MM-YYYY') === moment(defaultValue).format('MM-YYYY')) {
            setIndex(generateNewIndex(0, arraySize-itemIndex-tabsQuantityToBeShown <= 0 ? arraySize-tabsQuantityToBeShown : arraySize-itemIndex-tabsQuantityToBeShown ))
          }
        })

        if(!find(arrayOfDates, item => moment(item).format('MM-YYYY') === moment(defaultValue).format('MM-YYYY'))) {
          setIndex(viewLastFirst ? generateNewIndex(0, arraySize-tabsQuantityToBeShown) : 0)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[defaultValue])

  return (
    <Container { ...rest }>
      <IconButton
        onClick={ () => handleIndexChange(-navigateQuantity) }
        disabled={ disabledLeftButton }
      >
        <Icon size={ 16 } style={ { transform: 'rotate(180deg)' } } icon={ arrowRightSimple } color={ disabledLeftButton ? colors.grey : colors.black } />
      </IconButton>      {
        map(dates, (date) => (
          <div key={ date }>
            <Tab
              onClick={ () => {
                setSelectedItem(date)
                onChange(date)
              } }
              active={ date.isSame(selectedItem, mode) }
              color={ date.isSame(selectedItem, mode) ? colors.blackLight : colors.backgroundHtml }
            >
              {/* Check if the date is in the actual year and change the exibition */}
              { moment().isSame(date, 'year') ? date.format(currentYearFormat || format) : date.format(format) }
            </Tab>
          </div>
        ))
      }
      <IconButton
        onClick={ () => handleIndexChange(navigateQuantity) }
        disabled={ disabledRightButton }
      >
        <Icon size={ 16 } icon={ arrowRightSimple } color={ disabledRightButton ? colors.grey : colors.black } />
      </IconButton>
    </Container>
  )
}

DateTabs.propTypes = {
  initialDate: PropTypes.object.isRequired,
  endDate: PropTypes.object,
  mode: PropTypes.oneOf(['day', 'month', 'year']),
  format: PropTypes.string,
  currentYearFormat: PropTypes.string,
  tabsQuantityToBeShown: PropTypes.number,
  navigateQuantity: PropTypes.number,
  viewLastFirst: PropTypes.bool,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func
}

DateTabs.defaultProps = {
  endDate: moment(),
  mode: 'month',
  format: 'MMM/YY',
  currentYearFormat: null,
  tabsQuantityToBeShown: 10,
  navigateQuantity: 3,
  viewLastFirst: false,
  defaultValue: null,
  onChange: () => {}
}

export default DateTabs
