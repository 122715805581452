import React, { createContext, useState } from 'react'

import PropTypes from 'prop-types'

import { conversationService } from '@smartcoop/database/services/conversationService'
import { generateUuid } from '@smartcoop/database/utils/uuidGenerator'
import { database } from '@smartcoop/database/web-database'

import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser'

export const ChatContext = createContext({})

export const SCREENS = {
  CONVERSATION: 'conversation',
  CONTACTS: 'contacts'
}

export const ChatProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useState()
  const [screen, setScreen] = useState(SCREENS.CONVERSATION)
  const [openedChat, setOpenedChat] = useState({
    relationalId: null,
    conversationId: null,
    localMessageId: null,
    userId: null
  })

  const localUser = useAuthenticatedUser()

  const openChat = value => setOpenedChat(value)

  const closeChat = () => setOpenedChat({
    relationalId: null,
    conversationId: null,
    localMessageId: null,
    userId: null
  })

  const navigateToConversation = () => setScreen(SCREENS.CONVERSATION)

  const navigateToContacts = () => setScreen(SCREENS.CONTACTS)

  const generateContactUuid = contactId => generateUuid(contactId, localUser?.userId)

  const startConversation = async (contact) => {
    const relationalId = generateUuid(contact?.contactId, localUser?.userId)

    const conversation = await conversationService(database).findByRelationalId(relationalId)

    if (conversation) {
      openChat({
        relationalId,
        userId: contact?.contactId,
        conversationId: conversation.conversationId
      })
      return navigateToConversation()
    }

    await conversationService(database).startConversation(relationalId, contact)
    openChat({ relationalId })

    return navigateToConversation()
  }

  return (
    <ChatContext.Provider
      value={ {
        screen,
        searchParams,
        setSearchParams,
        navigateToContacts,
        navigateToConversation,
        startConversation,
        openedChat,
        openChat,
        generateContactUuid,
        closeChat
      } }
    >
      {children}
    </ChatContext.Provider>
  )
}

ChatProvider.propTypes = {
  children: PropTypes.element.isRequired
}
