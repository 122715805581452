import React from 'react'

import PropTypes from 'prop-types'

import Icon from '@smartcoop/web-components/Icon'

import { CircularButton } from './styles'


const IconButton = ({ icon, onClick }) => (
  <CircularButton onClick={ onClick }>
    <Icon icon={ icon } size={ 22 } />
  </CircularButton>
)

IconButton.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element
  ]).isRequired,
  onClick: PropTypes.func
}

IconButton.defaultProps = {
  onClick: () => {}
}

export default IconButton
