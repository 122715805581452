import React, { useCallback, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { map } from 'lodash'
import filter from 'lodash/filter'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { selectPermissionsResponse } from '@smartcoop/stores/authentication/selectorAuthentication'
import { OrganizationActions } from '@smartcoop/stores/organization'
import { UserActions } from '@smartcoop/stores/user'
import Button from '@smartcoop/web-components/Button'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'

import useStyles, { ButtonContainer, Container, Divisor } from './styles'

const RemoveAccessModal = (props) => {
  const {
    id,
    open,
    handleClose,
    userOrganization,
    organizations
  } = props

  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const { createDialog } = useDialog()
  const t = useT()

  const permissionsResponse = useSelector(selectPermissionsResponse)

  const [isLoading, setIsLoading] = useState(false)
  const [registry, setRegistry] = useState('')
  const [revocationReason, setRevocationReason] = useState('')
  const [selectedModule, setSelectedModule] = useState([])

  const modulesByOrg = useMemo(() => flatten(map(filter(permissionsResponse, item => item.organizationsUser.registry === registry && item.organizationsUser.organizationId === userOrganization?.id), item => item.modules)), [permissionsResponse, registry, userOrganization.id])

  const hasOrganization = useMemo(() => !isEmpty(userOrganization), [userOrganization])
  const filteredOrganizations = useMemo(() => filter(organizations, item => item.id === userOrganization?.id ), [organizations, userOrganization])
  const profileOptions = useMemo(() => hasOrganization ? map(filteredOrganizations, item => ({ label: item?.profileName, value: item?.registry })) : [], [filteredOrganizations, hasOrganization])
  const moduleOptions = useMemo(() => hasOrganization ? map(modulesByOrg, item => ({ label: item?.name, value: item?.id })) : [], [hasOrganization, modulesByOrg])

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      dispatch(UserActions.loadUser())
      dispatch(AuthenticationActions.loadPermissions())
      dispatch(OrganizationActions.loadUserOrganizations())
      handleClose()
    }, [dispatch, handleClose]
  )

  const removeAccessSuccess = useCallback(
    () => {
      snackbar.success(
        t('your access was removed')
      )
      closeModal()

    }, [closeModal, snackbar, t]
  )

  const submitForms = useCallback(
    () => {
      setIsLoading(true)
      if(hasOrganization) {
        dispatch(UserActions.deleteUserOrganization(
          {
            registry,
            revocationReason,
            organizationId: userOrganization?.id,
            modules: selectedModule
          },
          removeAccessSuccess,
          closeModal
        ))
      } else {
        dispatch(UserActions.revokeUserAccess(
          {
            revocationReason
          },
          () => {
            removeAccessSuccess()
            dispatch(AuthenticationActions.logout())
          },
          closeModal
        ))
      }
    }, [closeModal, dispatch, hasOrganization, registry, removeAccessSuccess, revocationReason, selectedModule, userOrganization.id]
  )

  const handleConfirm = useCallback(
    () => {
      createDialog({
        id: 'confirm-submit',
        Component: ConfirmModal,
        props: {
          onConfirm: submitForms,
          textWarning: t('when confirming the removal of the access you will have your access polices updated, do you want to continue?')
        }
      })
    },
    [createDialog, submitForms, t]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n>remove access</I18n>
        </>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      {
        isLoading ?
          <Loader width={ 100 } /> :
          (
            <Container>
              { hasOrganization ? t('please to follow with your removel of the access on {this}, fill the form below', { this: userOrganization?.tradeName }) : t('are you sure that you want to remove your access to SMARTCOOP')}
              <Divisor />
              { hasOrganization && (
                <InputSelect
                  detached
                  label={ t('profile/registration') }
                  options={ profileOptions }
                  onChange={ (value) => {
                    setSelectedModule([])
                    setRegistry(value)
                  } }
                  fullWidth
                  required
                  value={ registry }
                />
              )}
              { hasOrganization && !isEmpty(registry) && (
                <InputSelect
                  detached
                  multiple
                  label={ t('module') }
                  options={ moduleOptions || [] }
                  onChange={ setSelectedModule }
                  fullWidth
                  required
                  value={ selectedModule }
                />
              )}
              <InputText
                detached
                label={ t('reason ') }
                fullWidth
                required
                value={ revocationReason }
                onChange={ (e) => setRevocationReason(e.target.value) }
              />
              <ButtonContainer>
                <div>
                  <Button
                    id="cancel-remove-access"
                    variant="outlined"
                    style={ { marginTop: 30, marginRight: 10 } }
                    onClick={ closeModal }
                  >
                    <I18n>cancel</I18n>
                  </Button>
                </div>
                <div>
                  <Button
                    id="confirm-remove-access"
                    style={ { marginTop: 30 } }
                    color="secondary"
                    onClick={ handleConfirm }
                    disabled={ hasOrganization ? (isEmpty(registry) || isEmpty(revocationReason) || isEmpty(selectedModule)) : isEmpty(revocationReason) }
                  >
                    <I18n>save</I18n>
                  </Button>
                </div>
              </ButtonContainer>
            </Container>
          )
      }
    </Modal>
  )}

RemoveAccessModal.propTypes = {
  id: PropTypes.string.isRequired,
  userOrganization: PropTypes.object,
  organizations: PropTypes.array,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
}

RemoveAccessModal.defaultProps = {
  handleClose: () => {},
  userOrganization: {},
  organizations: []
}

export default RemoveAccessModal
