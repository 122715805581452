/* eslint-disable no-param-reassign */
import { Database, Q } from '@nozbe/watermelondb'
import { blobToBase64 } from 'base64-blob'

import first from 'lodash/first'
import last from 'lodash/last'

import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { getFile } from '@smartcoop/services/apis/smartcoopApi/resources/file'
import { downloadFromBase64 } from '@smartcoop/utils/files'

import { ROUTES } from './routes'
import { syncFileService } from './syncFileService'

export function profilePictureService(database: Database) {
  async function findFile(sourceUrl, localUser = false) {
    if (localUser) {
      const { data: { file: fileData } } = await getBucketFile({
        bucketId: process.env.REACT_APP_BUCKET_PHOTO_USER_ID,
        fileKey: sourceUrl
      })

      const base64file = downloadFromBase64(fileData)

      return base64file
    }

    const { data: fileData } = await getFile({ file: sourceUrl })

    const base64File = await blobToBase64(fileData)

    return base64File
  }

  async function getContactPhoto(contact) {
    if (contact.group || contact.broadcast) {
      const register = await database.get('profile_picture')
        .query(
          Q.where('conversationId', contact.id || null),
          Q.where('group', Q.eq(true))
        )
        .fetch()

      return first(register)
    }

    const register = await database.get('profile_picture')
      .query(Q.where('userId', contact.userId || null))
      .fetch()

    return first(register)
  }

  async function createOrUpdate(contacts = []) {
    await contacts.forEach(async contact => {
      const register = await getContactPhoto(contact)

      if (!contact?.photo) {
        return
      }

      if (register) {
        if (register.sourceUrl !== contact?.photo) {
          const source = await findFile(contact?.photo, contact?.localUser)

          await database.write(async () => {
            register.update(item => {
              item.sourceUrl = contact?.photo
              item.source = source
            })
          })
        }
        return
      }

      const source = await findFile(contact?.photo, contact?.localUser)

      await database.write(async () => {
        await database.get('profile_picture').create(item => {
          if (contact.group || contact.broadcast) {
            item.group = true
            item.conversationId = contact.id
          } else {
            item.userId = contact.userId
          }

          item.sourceUrl = contact?.photo
          item.source = source
        })
      })
    })
  }

  async function setConversationProfilePicture(
    conversationId,
    fileName,
    source,
    { uri = null } = {}
  ) {
    const registers = await database.get('profile_picture')
      .query(
        Q.where('conversationId', conversationId),
        Q.where('group', Q.eq(true))
      ).fetch()

    await database.write(async () => {
      if (registers && registers[0]) {
        await registers[0].update(item => {
          item.source = source
        })
      } else {
        await database.collections.get('profile_picture').create(data => {
          data.userId = null
          data.sourceUrl = null
          data.source = source
          data.group = true
          data.conversationId = conversationId
        })
      }

      const sourceExtension = last(fileName.split('.'))

      await syncFileService(database).create({
        uri,
        type: 'POST',
        source,
        sourceExtension,
        params: {},
        documentType: 'image',
        url: `${ ROUTES.UPDATE_GROUP_IMAGE }/${ conversationId }`
      })
    })
  }

  async function findProfileImageByUserId(userId) {
    const registers = await database.collections.get('profile_picture')
      .query(Q.where('userId', userId))
      .fetch()

    return registers[0]
  }

  function observeProfilePictureByUserId(userId, group = false) {
    const profilePicture = database.collections.get('profile_picture')

    if (group) {
      return profilePicture
        .query(
          Q.where('conversationId', userId || null),
          Q.where('group', Q.eq(true))
        )
        .observeWithColumns(['sourceUrl', 'source'])
    }

    return profilePicture
      .query(
        Q.where('userId', userId || null),
        Q.where('userId', Q.notEq(null)),
        Q.where('userId', Q.notEq(''))
      )
      .observeWithColumns(['sourceUrl', 'source'])
  }

  return {
    createOrUpdate,
    findProfileImageByUserId,
    setConversationProfilePicture,
    observeProfilePictureByUserId
  }
}
