import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterObservationsForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterObservationsForm'

import useStyles from './styles'

const RegisterObservationsModal = (props) => {
  const {
    id,
    open,
    onSubmit,
    handleClose,
    animalId,
    observation
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()
  const currentProperty = useSelector(selectCurrentProperty)

  const [isLoading, setIsLoading] = useState(false)

  const isEditing = useMemo(
    () => (!isEmpty(observation?.id)),[observation.id]
  )
  const defaultValues = useMemo(
    () => (
      {
        code: '',
        name: '',
        property: {
          id: currentProperty?.id
        },
        animal: {
          id: animalId
        },
        ...observation
      }
    ), [animalId, currentProperty.id, observation]
  )

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      handleClose()
    }, [handleClose]
  )

  const onSuccess = useCallback(
    () => {
      dispatch(AnimalActions.loadCurrentAnimal())
      snackbar.success(
        t(`your {this} was ${  isEditing ? 'edited' :'registered' }`, {
          howMany: 1,
          gender: 'female',
          this: t('observation', { howMany: 1 })
        })
      )
      onSubmit()
      closeModal()

    }, [closeModal, dispatch, isEditing, onSubmit, snackbar, t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(AnimalActions.saveOfflineAnimalObservation(
        {
          observationDate: observation?.observationDate || moment().format(momentBackDateFormat),
          ...observation,
          ...data
        },
        onSuccess,
        () => setIsLoading(false),
        true
      ))
    },
    [dispatch, observation, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n params={ { this: t('observation', { howMany: 1 }) } }>{'register {this}'}</I18n>
        </>
      }
      disableEsc
      classes={ { paper: classes.modalBackground } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      fullWidth
      maxWidth="sm"
    >
      <>
        {
          isLoading ? <Loader width={ 100 } /> :
            <RegisterObservationsForm
              formRef={ formRef }
              defaultValues={ defaultValues }
              onSubmit={ handleSubmit }
              onCancel={ closeModal }
              propertyId={ currentProperty?.id }
            />
        }
      </>
    </Modal>
  )}

RegisterObservationsModal.propTypes = {
  id: PropTypes.string.isRequired,
  animalId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  observation: PropTypes.object
}

RegisterObservationsModal.defaultProps = {
  handleClose: () => {},
  onSubmit: () => {},
  animalId: null,
  observation: {}
}

export default RegisterObservationsModal
