import React, { useCallback, forwardRef, useMemo } from 'react'

import PropTypes from 'prop-types'

import { find } from 'lodash'
import map from 'lodash/map'

import acceptProposalSchema from '@smartcoop/forms/schemas/shoppingPlatform/order/acceptProposal.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { hammer, warning, checked, blocked } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import { formatCpfCnpj, formatCurrency } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import ObservationBox from '@smartcoop/web-components/ObservationBox'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import {
  Text
} from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import {
  Container,
  ButtonContainer,
  WhiteContainer,
  ProposalCard,
  Label,
  LabelItem,
  FormTitle,
  PaymentType,
  Payment
} from './styles'

const AcceptBestProposalForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    proposal,
    deliveryPercentage,
    disabled,
    onAcceptProposal,
    onRejectProposal,
    changeDecision,
    proposalAccepted,
    defaultValue,
    waitingDelivery,
    currentOrganization
  } = props
  const t = useT()

  const renderLabel = useCallback(
    (deferredPayment, value, daysAmount) => (
      <Payment>
        <PaymentType>
          {!deferredPayment
            ? t('in cash')
            : `${ t('deferred') } ${ daysAmount } ${ t('day', {
              howMany: daysAmount
            }) }:`}
        </PaymentType>
        <Label>{formatCurrency(value)}</Label>
      </Payment>
    ),
    [t]
  )

  const onlyCash = useMemo(() => {
    if (proposal?.mainPurchase?.cashOnly) {
      const cashOnlyMapped = proposal?.mainPurchase?.cashOnly

      return find(
        cashOnlyMapped,
        (item) =>
          item?.organizationId === currentOrganization?.id &&
          item?.supplierId === proposal?.supplierId
      )
    }
    return false
  }, [currentOrganization, proposal])

  const options = useMemo(() => {
    const array = onlyCash
      ? proposal?.proposalPaymentForms.filter(
        (element) => element?.paymentForm?.deferredPayment === false
      )
      : proposal?.proposalPaymentForms

    return map(array, (item) => {
      const { value, paymentForm, id } = item
      return {
        value: id,
        label: renderLabel(
          paymentForm?.deferredPayment,
          value,
          paymentForm?.daysAmount
        )
      }
    })
  }, [onlyCash, proposal, renderLabel])

  const rejectProposal = useCallback(() => {
    onRejectProposal()
  }, [onRejectProposal])

  const onSubmit = useCallback(
    (data) => {
      onAcceptProposal({ ...data })
    },
    [onAcceptProposal]
  )

  const handleAccept = useCallback(() => {
    formRef.current.submit()
  }, [formRef])

  const observationBoxProps = useMemo(() => {
    if (proposalAccepted === true) {
      return {
        icon: checked,
        color: colors.green,
        message: t('You already {this} this demand', { this: t('accepted') })
      }
    }
    if (proposalAccepted === false) {
      return {
        icon: blocked,
        color: colors.red,
        message: t('You already {this} this demand', { this: t('declined') })
      }
    }
    return {
      icon: null,
      color: '',
      message: ''
    }
  }, [proposalAccepted, t])

  return (
    <Container>
      <FormTitle>
        <Icon icon={ hammer } size={ 26 } style={ { paddingRight: 5 } } />
        {t('proposal in relation to total demand')}
      </FormTitle>
      {proposal?.mainPurchase?.freightType === 'CFA' && (
        <ObservationBox
          icon={ warning }
          title={ t('freight observation') }
          style={ { marginTop: 10, background: 'white' } }
        >
          <Text style={ { marginBottom: 10, fontSize: 14 } }>
           Na modalidade de frete CFA - Custo de Frete Adiciona, o valor do frete <b>NÃO ESTÁ</b> incluso no preço do produto e <b>DEVE</b> ser avaliado individualmente para cada local de entrega.
          </Text>
        </ObservationBox>
      )}
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ acceptProposalSchema }
        onSubmit={ onSubmit }
      >
        <ProposalCard>
          <RadioGroup
            label={
              <>
                <Label>
                  <span>{proposal?.supplier?.companyName}</span>
                  <LabelItem>
                    {`CNPJ ${ formatCpfCnpj(
                      proposal?.supplier?.companyDocument
                    ) }`}
                  </LabelItem>
                </Label>
                {onlyCash && (
                  <ObservationBox
                    icon={ warning }
                    iconProps={ { size: 16, color: colors.yellow } }
                    title={ t('only cash') }
                    titleStyle={ { fontWeight: '600', fontSize: 12 } }
                    style={ { marginRight: '10px' } }
                  />
                )}
                <ObservationBox
                  icon={ warning }
                  iconProps={ { size: 16, color: colors.darkGrey } }
                  title={ t('comply with {this} of the demand', {
                    this: deliveryPercentage
                  }) }
                  titleStyle={ { fontWeight: '600' } }
                />
              </>
            }
            name="proposalPaymentFormId"
            options={ options }
            itemStyle={ { marginBottom: 0 } }
            variant="lined"
            defaultValue={ defaultValue }
            disabled={ disabled }
            clearable
          />
          {!withoutSubmitButton && (
            <WhiteContainer>
              <ObservationBox
                icon={ observationBoxProps.icon }
                iconProps={ { size: 16, color: observationBoxProps.color } }
                title={ observationBoxProps.message }
                titleStyle={ {
                  fontWeight: '600',
                  color: observationBoxProps.color
                } }
              />
              <ButtonContainer disabled={ waitingDelivery }>
                {disabled && (
                  <Button
                    id="web-order-change-form-button"
                    style={ {
                      width: '48%',
                      marginRight: 14
                      // display: !disabled ? 'none' : 'block'
                    } }
                    color="secondary"
                    onClick={ changeDecision }
                    disabled={ waitingDelivery }
                  >
                    <I18n>change decision</I18n>
                  </Button>
                )}
                {!disabled && (
                  <Button
                    id="web-order-deny-form-button"
                    style={ {
                      width: '48%',
                      marginRight: 14
                      // display: disabled ? 'none' : 'block'
                    } }
                    variant="outlined"
                    onClick={ rejectProposal }
                  >
                    <I18n>deny</I18n>
                  </Button>
                )}
                {!disabled && (
                  <Button
                    id="web-order-accept-form-button"
                    style={ {
                      width: '48%'
                      // display: disabled ? 'none' : 'block'
                    } }
                    onClick={ handleAccept }
                  >
                    <I18n>accept</I18n>
                  </Button>
                )}
              </ButtonContainer>
            </WhiteContainer>
          )}
        </ProposalCard>
      </Form>
    </Container>
  )
})

AcceptBestProposalForm.propTypes = {
  onAcceptProposal: PropTypes.func,
  onRejectProposal: PropTypes.func,
  changeDecision: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  proposal: PropTypes.object.isRequired,
  deliveryPercentage: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  proposalAccepted: PropTypes.bool,
  waitingDelivery: PropTypes.bool,
  currentOrganization: PropTypes.object.isRequired
}

AcceptBestProposalForm.defaultProps = {
  onAcceptProposal: () => {},
  onRejectProposal: () => {},
  changeDecision: () => {},
  withoutSubmitButton: false,
  disabled: false,
  defaultValue: '',
  proposalAccepted: undefined,
  waitingDelivery: false
}

export default AcceptBestProposalForm
