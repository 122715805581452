import React, { useCallback, forwardRef, useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import round from 'lodash/round'
import sumBy from 'lodash/sumBy'
import toNumber from 'lodash/toNumber'
import uniq from 'lodash/uniq'


import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { plus } from '@smartcoop/icons'
import { getActivities as getActivitiesService } from '@smartcoop/services/apis/smartcoopApi/resources/activity'
import { getItems, getCostsByProperty } from '@smartcoop/services/apis/smartcoopApi/resources/costs'
import { useSnackbar } from '@smartcoop/snackbar'
import { CostsActions } from '@smartcoop/stores/costs/duckCosts'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import { LOT_SLUG_OPTIONS_OBJECT } from '@smartcoop/utils/constants'
import { momentBackDateTimeFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import { formatCurrencyIntl } from '@smartcoop/utils/formatters'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import NewCostModal from '@smartcoop/web-containers/modals/NewCostModal'


import { Container } from './styles'

const ActivityCostsForm = forwardRef(({ year, cropsOptions }) => {
  const t = useT()
  const { createDialog } = useDialog()
  const tableRef = useRef(null)
  const dispatch = useDispatch()

  const property = useSelector(selectCurrentProperty)
  const snackbar = useSnackbar()

  const [costsItems, setCostsItems] = useState([])
  const [costs, setCosts] = useState([])
  const [activities, setActivities] = useState([])

  const createdCosts = useMemo(() => filter(costs, item => !item?.growingSeasonId), [costs])
  const activityCosts = useMemo(() => map(groupBy(createdCosts, item => `${ item?.bulkId }${ item?.monthlyApportionment || 'date' }`), item => item), [createdCosts])

  const getActivities = useCallback(async () => {
    const { data } = await getActivitiesService({ limit: 9999 })
    setActivities(map(data, item => ({ label: item?.name, value: item?.id, slug: item?.slug })))
  }, [])

  const getCostsProperty = useCallback(async () => {
    const { data } = await getCostsByProperty({ propertyId: property?.id, year })
    setCosts(data)
  }, [property, year])

  useEffect(() => {
    const getCostsItems = async () => {
      const { data } = await getItems({ propertyId: property?.id })
      setCostsItems(data)
    }

    getActivities()
    getCostsItems()
    getCostsProperty()
  },[getActivities, getCostsProperty, property])

  const openAddCostModal = useCallback((_, defaultValues = {}) => {

    const updatedDefaultValues = !isEmpty(defaultValues) ? map(defaultValues, defaultValue => {
      const realizationMonths = defaultValue.monthlyApportionment ? uniq(map(filter(createdCosts, item => defaultValue?.bulkId === item?.bulkId), item => moment(item?.realizationDate, momentBackDateTimeFormat).format('YYYY-MM'))) : null

      return {
        ...defaultValue,
        realizationMonths,
        value: realizationMonths ? defaultValue?.value * realizationMonths.length : defaultValue?.value
      }
    }) : {}
    createDialog({
      id: 'organization-onboarding',
      Component: NewCostModal,
      props: {
        isActivity: true,
        defaultValues:  updatedDefaultValues,
        year,
        managementOptions: activities,
        costsOptions: map(costsItems, item => ({ value: item?.id, label: item?.description, ...item })),
        cropsOptions,
        onSuccess: getCostsProperty
      }
    })
  },[activities, costsItems, createDialog, createdCosts, cropsOptions, getCostsProperty, year])

  const columns = useMemo(
    () => [
      {
        title: t('rubric'),
        field: 'name',
        render: (row) => {
          const costItem = find(costsItems, item => item.id === row[0]?.rubricItemId)
          return costItem?.description
        },
        cellStyle: { width: '10%' }
      },
      {
        title: t('date', { howMany: 1 }),
        field: 'realizationDate',
        render: (row) => moment(row[0]?.realizationDate, momentBackDateTimeFormat).format(momentFriendlyDateFormat),
        cellStyle: { width: '10%' }
      },
      {
        title: t('activity', { howMany: 2 }),
        field: 'activities',
        render: (row) => {
          if(isEmpty(row[0]?.activity)) {
            return '-'
          }
          const activitiesIds = uniq(map(row, (item) => item?.activity?.id))
          return map(activitiesIds, (id) => {
            const foundArray = filter(row, (item) => item?.activity?.id === id)
            const isAnimal = foundArray?.[0]?.activity?.slug === 'pecuaria-de-leite' || foundArray?.[0]?.activity?.slug === 'pecuaria-de-corte'
            return isAnimal ? `${ foundArray?.[0]?.activity?.name }${  find(foundArray, found => found?.lotType) ? ` (${ map(foundArray, (found) => LOT_SLUG_OPTIONS_OBJECT[found?.lotType]).join(', ') })` : '' }` : `${ foundArray?.[0]?.activity?.name } ${ find(foundArray, found => found?.crop?.description) ? ` (${ map(foundArray, (found) => `${ found?.crop?.description  }/${  found?.sowingYear }`).join(', ') })` : '' }`
          }).join(' | ')
        }
      },
      {
        title: t('sharing'),
        field: 'sharing',
        render: (row) => {
          if(isEmpty(row[0]?.activity)) {
            return '-'
          }
          const activitiesIds = uniq(map(row, (item) => item?.activity?.id))
          return map(activitiesIds, (id) => {
            const foundArray = filter(row, (item) => item?.activity?.id === id)
            const total = round(sumBy(foundArray, (item) => item?.registryPercentage !== 'NaN' && !isEmpty(item?.registryPercentage) ? toNumber(item?.registryPercentage) : toNumber(item?.percentage)), 2)
            return foundArray.length > 1 ? `${ total }% (${ map(foundArray, (found) => `${ found?.registryPercentage !== 'NaN' && !isEmpty(found?.registryPercentage) ? found?.registryPercentage : found?.percentage }%`).join(', ') })` : `${ total }%`
          }).join(' | ')
        },
        cellStyle: { width: '10%' }
      },
      {
        title: `${ t('description') } | ${ t('product') }`,
        field: 'description',
        render: row => row[0]?.description
      },
      {
        title: t('total value'),
        field: 'value',
        render: (row) => row[0]?.value ? <div style={ { display: 'flex', flexDirection: 'row', color:  row?.[0]?.rubricItem?.rubricGroup?.operationType === 'debit' ? 'red' : 'black' } }>{formatCurrencyIntl(row[0]?.rubricItem?.rubricGroup?.operationType === 'debit' ? -row[0]?.value : row[0]?.value)}</div> : '-',
        cellStyle: { width: '10%' }
      },
      {
        title: t('proportional value'),
        field: 'value',
        render: (row) => {
          if(isEmpty(row[0]?.activity)) {
            return '-'
          }
          const activitiesIds = uniq(map(row, (item) => item?.activity?.id))
          return <div style={ { display: 'flex', flexDirection: 'row', color:  row[0]?.rubricItem?.rubricGroup?.operationType === 'debit' ? 'red' : 'black' } }>
            {map(activitiesIds, (id) => {
              const foundArray = filter(row, (item) => item?.activity?.id === id)
              const total = round(sumBy(foundArray, (item) => item?.registryPercentage !== 'NaN' && !isEmpty(item?.registryPercentage) ? ((toNumber(item?.registryPercentage)/100)*item?.value) : (toNumber(item?.percentage)/100)*item?.value), 2)
              return formatCurrencyIntl(foundArray?.[0]?.rubricItem?.rubricGroup?.operationType === 'debit' ? total*-1 : total)
            }).join(' | ')}
          </div>
        },
        cellStyle: { width: '15%' }
      }
    ],
    [costsItems, t]
  )

  const removeCost = useCallback((_, row) => {
    createDialog({
      id: 'confirm-delete',
      Component: ConfirmModal,
      props: {
        onConfirm: () => {
          dispatch(CostsActions.deleteCosts(
            row?.bulkId || row?.[0]?.bulkId,
            () => {
              snackbar.success(t('cost deleted sucessfully'))
              getCostsProperty()
            }
          ))
        },
        textWarning: t('are you sure you want to delete the {this}?', {
          howMany: 1,
          gender: 'male',
          this: t('cost')
        })
      }
    })
  },[createDialog, dispatch, getCostsProperty, snackbar, t])


  return (
    <Container>
      <div style={ { display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end', marginBottom: 10, marginTop: 20 } }>
        <IconButton
          onClick={ openAddCostModal }
          style={ { backgroundColor: colors.secondary } }
        >
          <Icon size={ 16 } icon={ plus } color={ colors.black } />
        </IconButton>
      </div>
      <DataTable
        tableRef={ tableRef }
        columns={ columns }
        data={ orderBy(activityCosts, item => item?.[0]?.realizationDate, 'desc') }
        id="activity-costs-table"
        emptyMessage={ t('there is no costs registered') }
        hidePagination
        hasPagination={ false }
        onDeleteClick={ removeCost }
        onEditClick={ openAddCostModal }
        disabledEdit={ row => !row?.[0]?.manual }
        conditionToDelete={ row => row?.[0]?.manual }
      />
    </Container>
  )
})

ActivityCostsForm.propTypes = {
  year: PropTypes.string.isRequired,
  cropsOptions: PropTypes.array.isRequired
}

export default ActivityCostsForm
