import { call, put, takeLatest } from 'redux-saga/effects'

import { createPost, getPosts, getPostComments, createPostComment, deletePostComment, editPostComment } from '@smartcoop/services/apis/smartcoopApi/resources/productWall'

import { ProductWallActions, ProductWallTypes } from './duckProductWall'

function* loadProductWallPosts({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: { data, ...pagination } } = yield call(getPosts, {
      limit: 10,
      ...params
    })

    yield put(ProductWallActions.loadProductWallPostsSuccess(
      data,
      pagination.page,
      () => onSuccess(pagination)
    ))
  } catch (err) {
    const error = err.message
    yield put(ProductWallActions.productWallError(error))
    yield call(onError, error)
  }
}

function* loadProductWallPostsSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* saveOfflineProductWallPost({ post, onSuccess = () => {}, onError = () => {} }) {
  yield put(ProductWallActions.saveProductWallPost(post, onSuccess, onError))
}

function* saveProductWallPost({ post, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(createPost, post)
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(ProductWallActions.productWallError(error))
    yield call(onError)
  }
}

function* loadProductWallComments({ params = {}, onSuccess = () => {}, onError = () => {} }){
  try{
    const { postId } = params
    const { data } = yield call(getPostComments, {}, postId)
    yield call(onSuccess, data)
  }
  catch(err){
    yield call(onError)
  }
}

function* saveProductWallComment({ params = {}, onSuccess = () => {}, onError = () => {} }){
  try{
    const { postId, comment } = params
    const { data } = yield call(createPostComment, comment, postId)
    yield call(onSuccess, data)
  }
  catch(err){
    yield call(onError)
  }
}

function* editProductWallComment({ params = {}, onSuccess = () => {}, onError = () => {} }){
  try{
    const { commentId, text } = params
    yield call(editPostComment, { text }, commentId)
    yield call(onSuccess)
  }
  catch(err){
    yield call(onError)
  }
}

function* deleteProductWallComment({ params = {}, onSuccess = () => {}, onError = () => {} }){
  try{
    const { commentId } = params
    yield(call(deletePostComment, commentId))
    yield call(onSuccess)
  }
  catch(err){
    yield call(onError)
  }
}

export default [
  takeLatest(ProductWallTypes.LOAD_PRODUCT_WALL_POSTS, loadProductWallPosts),
  takeLatest(ProductWallTypes.LOAD_PRODUCT_WALL_POSTS_SUCCESS, loadProductWallPostsSuccess),

  takeLatest(ProductWallTypes.SAVE_PRODUCT_WALL_POST, saveProductWallPost),
  takeLatest(ProductWallTypes.SAVE_OFFLINE_PRODUCT_WALL_POST, saveOfflineProductWallPost),

  takeLatest(ProductWallTypes.LOAD_PRODUCT_WALL_COMMENTS, loadProductWallComments),
  takeLatest(ProductWallTypes.SAVE_PRODUCT_WALL_COMMENT, saveProductWallComment),
  takeLatest(ProductWallTypes.EDIT_PRODUCT_WALL_COMMENT, editProductWallComment),
  takeLatest(ProductWallTypes.DELETE_PRODUCT_WALL_COMMENT, deleteProductWallComment)

]
