// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
      <g>
        <path fill="#F9D915" d="M161.1,124.3c0-0.5-0.5-0.8-1-0.8l0,0l0,0h-19.3c-0.5,0-0.8,0.3-1,0.5l-7.6,21.6c0,0.2,0,0.2,0,0.3
          c0,0.5,0.5,0.8,1,0.8l0,0h7.3l-6.8,19.4l0,0c0,0.2,0,0.3,0,0.3c0,0.8,0.8,1.5,2,1.5c0.7,0,1.4-0.3,1.7-0.8l0,0l22.3-29.6l0,0
          c0-0.2,0-0.2,0-0.3c0-0.5-0.5-0.8-1-0.8l0,0l0,0H152l9.1-12L161.1,124.3z"/>
        <path fill="#6EC4E9" d="M176.9,149.7c0.4-0.9-0.2-1.9-1.2-2.2c-0.9-0.3-1.9,0-2.3,0.7h0L154,173c-0.4,0.5-0.7,1-1,1.6
          c-1.2,3,0.6,6.4,4,7.5c3.4,1.1,7-0.4,8.3-3.3h0l11.5-28.9L176.9,149.7L176.9,149.7z"/>
      </g>
      <g>
        <g>
          <path fill="none" d="M175,78.8c-0.2,0-0.4,0-0.6,0c0.4,0,0.8,0,1.2,0C175.4,78.8,175.2,78.8,175,78.8z"/>
          <path fill="#F9D915" d="M99.5,117.8c-0.3,0-0.6,0-0.9,0l-0.1,0l0,0c-0.2,0-0.5,0-0.9-0.1c-0.5-0.1-0.9-0.2-1.1-0.2l-0.1,0
            c-0.2,0-0.5-0.1-0.8-0.2c-0.4-0.1-0.8-0.3-1.1-0.4l-0.1,0c-0.2-0.1-0.5-0.2-0.8-0.4c-0.4-0.2-0.7-0.4-0.9-0.5l0,0l0,0
            c-0.2-0.1-0.4-0.3-0.7-0.5c-0.4-0.3-0.7-0.5-0.8-0.7l-0.1-0.1c-0.1-0.1-0.4-0.3-0.6-0.6c-0.3-0.4-0.6-0.7-0.8-0.9l-0.1-0.1l0,0
            c-0.1-0.1-0.3-0.4-0.5-0.7c-0.3-0.4-0.5-0.8-0.7-1.1c-0.1-0.1-0.2-0.4-0.4-0.7c-0.3-0.7-0.6-1.3-0.7-2c-0.9-3.2-0.4-6.6,1.3-9.5
            c1.7-2.9,4.4-5,7.6-5.8l0,0c0.3-0.1,0.9-0.2,1.7-0.5c0-0.1,0-0.1,0-0.2c0.1-0.6,0.2-1.3,0.4-1.9c0.2-0.6,0.3-1.2,0.6-1.8
            c0.1-0.2,0.1-0.3,0.2-0.5l0-0.1c0.2-0.4,0.3-0.8,0.5-1.2c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.4,0.4-0.7,0.6-1c0.1-0.2,0.2-0.4,0.4-0.6
            c0.2-0.3,0.4-0.6,0.7-1l0.1-0.1c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.3,0.6-0.7,0.9-1.1c0.1-0.1,0.2-0.2,0.3-0.3
            c0.4-0.4,0.8-0.8,1.3-1.2c0.1-0.1,0.2-0.2,0.4-0.3c0.4-0.3,0.7-0.6,1-0.8c0.1-0.1,0.3-0.2,0.4-0.3l0.1,0c0.3-0.2,0.7-0.4,1-0.6
            c0.2-0.1,0.4-0.2,0.6-0.3c0.3-0.2,0.7-0.4,1-0.5l0.1,0c0.2-0.1,0.3-0.2,0.5-0.2c0.4-0.2,0.8-0.3,1.2-0.5c0.1-0.1,0.3-0.1,0.4-0.2
            c0.6-0.2,1.1-0.4,1.7-0.5c0.1,0,0.3-0.1,0.4-0.1c0.5-0.1,0.9-0.2,1.4-0.3c0.2,0,0.4,0,0.5-0.1l0.1,0c0.4-0.1,0.8-0.1,1.2-0.1
            c0.2,0,0.5,0,0.7,0c0.1,0,0.1,0,0.2,0c0.1-0.3,0.2-0.6,0.4-1c0.6-1.6,1.4-3.1,2.3-4.5c-7-6.2-16.2-10-26.3-10
            c-21.9,0-39.6,17.7-39.6,39.6c0,21.9,17.7,39.6,39.6,39.6c15.4,0,28.8-8.9,35.3-21.8H99.8C99.7,117.8,99.6,117.8,99.5,117.8z"/>
          <path fill="#DDC72D" d="M99.5,111.8c0.1,0,0.1,0,0.2,0c-0.3,0-0.5,0-0.8,0C99.1,111.8,99.3,111.8,99.5,111.8z"/>
          <path fill="#DDC72D" d="M117.5,81.9c-0.2,0-0.3,0-0.5,0c0.3,0,0.6,0,1,0C117.8,81.9,117.7,81.9,117.5,81.9z"/>
          <path fill="#F9D915" d="M49.8,30.1l13.4,32.7c3.9-3.2,8.4-5.8,13.2-7.6L55,27.1c0,0,0,0-0.1-0.1c-1-1.2-2.9-1.5-4.2-0.5
            c-1,0.8-1.4,2.1-1,3.4C49.7,29.9,49.7,30,49.8,30.1z"/>
          <path fill="#F9D915" d="M101.1,52.6l-4.5-35c0,0,0-0.1,0-0.1c-0.2-1.6-1.7-2.8-3.4-2.6c-1.3,0.2-2.3,1.1-2.6,2.4
            c0,0.1,0,0.1-0.1,0.2l-4.7,35c2.5-0.4,5-0.6,7.5-0.6C96,52,98.6,52.2,101.1,52.6z"/>
          <path fill="#F9D915" d="M45.4,100c0-2.6,0.2-5.2,0.6-7.7l-35,4.5c0,0-0.1,0-0.1,0c-1.6,0.3-2.8,1.8-2.5,3.4
            c0.2,1.3,1.1,2.3,2.4,2.6c0.1,0,0.1,0,0.2,0.1l35,4.7C45.6,105.1,45.4,102.6,45.4,100z"/>
          <path fill="#F9D915" d="M137.4,30.3C137.4,30.3,137.4,30.2,137.4,30.3c0.3-0.8,0.3-1.6,0-2.3c-0.3-0.8-0.9-1.3-1.6-1.7
            c-1.2-0.5-2.6-0.2-3.4,0.8c-0.1,0-0.1,0.1-0.2,0.2l-21.6,27.9c4.8,1.9,9.3,4.5,13.2,7.7L137.4,30.3z"/>
          <path fill="#F9D915" d="M20.6,61.3l28,21.6c1.9-4.8,4.5-9.3,7.7-13.2L23.7,56c0,0-0.1,0-0.1,0c-1.5-0.6-3.3,0.2-3.9,1.7
            c-0.5,1.2-0.2,2.6,0.8,3.4C20.5,61.2,20.6,61.2,20.6,61.3z"/>
          <path fill="#F9D915" d="M48.5,117l-28.1,21.4c0,0-0.1,0-0.1,0.1c-0.6,0.5-1,1.2-1.1,1.9c-0.1,0.8,0.1,1.6,0.6,2.3
            c0.8,1,2.2,1.4,3.4,1c0.1,0,0.1,0,0.2-0.1l32.7-13.4C53,126.3,50.4,121.8,48.5,117z"/>
          <path fill="#F9D915" d="M85.7,147.3l4.5,35c0,0,0,0.1,0,0.1c0.2,1.5,1.5,2.6,3,2.6c0.1,0,0.3,0,0.4,0c1.3-0.2,2.3-1.1,2.6-2.4
            c0-0.1,0-0.1,0.1-0.2l4.7-35c-2.5,0.4-5,0.6-7.5,0.6C90.8,148,88.2,147.8,85.7,147.3z"/>
          <path fill="#F9D915" d="M49.4,169.7C49.4,169.7,49.4,169.8,49.4,169.7c-0.3,0.8-0.3,1.6,0,2.3c0.3,0.8,0.9,1.3,1.6,1.7
            c1.2,0.5,2.6,0.2,3.4-0.8c0.1,0,0.1-0.1,0.1-0.1l21.6-28c-4.8-1.9-9.3-4.5-13.2-7.7L49.4,169.7z"/>
          <path fill="#D3D3D3" d="M191,91c-0.1-0.5-0.3-1-0.5-1.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.3-0.3-0.6-0.4-1
            c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.3-0.6-0.5-0.8c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.5-0.6-0.8
            c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.3-0.5-0.6-0.8-0.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.4-0.7-0.7-1.1-1c-0.1-0.1-0.2-0.2-0.3-0.3
            c-0.3-0.2-0.5-0.4-0.8-0.7c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3-0.2-0.5-0.3-0.8-0.5c-0.2-0.1-0.3-0.2-0.5-0.3
            c-0.3-0.2-0.6-0.3-0.8-0.4c-0.2-0.1-0.3-0.2-0.5-0.2c-0.3-0.1-0.7-0.3-1-0.4c-0.1,0-0.2-0.1-0.4-0.1c-0.5-0.2-0.9-0.3-1.4-0.4
            c-0.1,0-0.2,0-0.3-0.1c-0.4-0.1-0.8-0.2-1.2-0.2c-0.2,0-0.3,0-0.5-0.1c-0.3,0-0.6-0.1-1-0.1c-0.2,0-0.4,0-0.6,0
            c-0.4,0-0.8,0-1.2,0c-0.1,0-0.1,0-0.2,0c-0.5,0-1,0.1-1.4,0.1c-0.1,0-0.2,0-0.3,0.1c-0.5,0.1-1,0.2-1.5,0.3c0,0-0.6,0.2-1.8,0.5
            c-1.5-5.4-4.8-10.1-9.2-13.4c0,0,0,0,0,0c-1.9-1.4-4-2.5-6.3-3.3c-1.3-0.5-2.8-0.8-4.2-1c-1.2-0.2-2.5-0.3-3.8-0.3
            c-9.4,0-17.6,5.4-21.6,13.2c-0.4,0.7-0.7,1.5-1,2.2c-0.7,1.7-1.2,3.5-1.5,5.4c-0.4-0.1-0.6-0.2-0.6-0.2c-0.4-0.1-0.8-0.2-1.2-0.3
            c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.8-0.1-1.2-0.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-1,0c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6,0-0.8,0.1
            c-0.1,0-0.3,0-0.4,0.1c-0.3,0.1-0.7,0.1-1,0.2c-0.1,0-0.1,0-0.2,0c-0.4,0.1-0.8,0.2-1.2,0.4c-0.1,0-0.2,0.1-0.3,0.1
            c-0.3,0.1-0.6,0.2-0.8,0.3c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.5,0.2-0.7,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
            c-0.2,0.1-0.5,0.3-0.7,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1,0.1-0.2,0.1-0.3,0.2
            c-0.3,0.3-0.6,0.6-0.9,0.9c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.2-0.5,0.5-0.7,0.8c-0.1,0.1-0.2,0.2-0.3,0.3
            c-0.2,0.2-0.3,0.5-0.5,0.7c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.5-0.4,0.7c-0.1,0.1-0.1,0.3-0.2,0.4
            c-0.1,0.3-0.2,0.5-0.4,0.8c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.4-0.3,0.8-0.4,1.3c-0.1,0.4-0.2,0.9-0.3,1.3
            c-0.1,0.9-0.2,1.7-0.2,2.6c0,0.4,0,0.9,0.1,1.3c-3.7,1-6,1.6-6,1.6c-3.5,0.9-5.5,4.5-4.6,8c0.1,0.4,0.2,0.7,0.4,1.1
            c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.2,0.2,0.3,0.3
            c0.2,0.1,0.3,0.3,0.5,0.4c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.2,0.6,0.2
            c0.2,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.3,0,0.5,0c0.3,0,0.5,0,0.8,0h29.5h2h43.5c0,0,3.1-0.1,4.7-0.6
            C188.2,108.9,193.4,99.8,191,91z"/>
        </g>
      </g>
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
