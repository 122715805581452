import React, { Suspense } from 'react'
import { withRouter } from 'react-router-dom'

import CircularLoader from '@smartcoop/web-components/Loader/CircularLoader'

import AuthenticatedLayout from '../AuthenticatedLayout'
import GuestLayout from '../GuestLayout'

/**
 * High Order Component that provides Layout to Components
 *
 * @param nextLayout string
 * @returns {function(*): *}
 */
const withLayout = (nextLayout) => (ScreenRouter) => {
  let LayoutComponent

  switch (nextLayout) {
    case 'guest':
      LayoutComponent = GuestLayout
      break
    case 'authenticated':
      LayoutComponent = AuthenticatedLayout
      break
    default:
      // eslint-disable-next-line react/prop-types
      LayoutComponent = ({ children }) => (<div style={ { width: '100%', height: '100%' } }>{children}</div>)
      break
  }

  const wrapped = (props) => (
    <Suspense fallback={ <CircularLoader /> }>
      <LayoutComponent { ...props }>
        <ScreenRouter { ...props } />
      </LayoutComponent>
    </Suspense>
  )

  return withRouter(wrapped)
}

export default withLayout
