import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  flex: 1;
  justify-content: space-between;
`
export const HourWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: 10px;
`

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 3;
`

export const TotalQuantityRow = styled(Row)`
  margin-top: 20;
  background-color: #fff;
  padding: 12px 15px 12px 40px;
  justify-content: space-between;
  align-items: center;
`

export const TotalQuantity = styled(Typography)`
  font-weight: 600;
  font-size: 16;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
