import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import notInclude from '@smartcoop/forms/validators/notInclude.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerAnimal = ({ t, props }) => Yup.object().shape({
  name:  flow(
    required({ t })
  )(Yup.string()),

  earringCode: flow(
    required({ t }),
    notInclude({ t, text: 'invalid earring', compareWith: props.unavailableEarrings })
  )(Yup.string()),


  category:  flow(
    required({ t })
  )(Yup.string()),

  birthDate: flow(
    required({ t }),
    date({ t })
  )(Yup.string()),

  lastBirth: Yup.string(),

  lactationsNumber: Yup.string(),

  lotId: flow(
    required({ t })
  )(Yup.string()),

  slaughterReasonId: Yup.string().when('slaughterDate', {
    is: val => val !== '',
    then: flow(
      required({ t })
    )(Yup.string())
  }),

  predominantBreedId: flow(
    required({ t })
  )(Yup.string()),

  statusId: flow(
    required({ t })
  )(Yup.string()),

  motivation: Yup.string().when('slaughterDate', {
    is: val => val !== '',
    then: flow(
      required({ t })
    )(Yup.string())
  }),

  saleValue: Yup.string().nullable(),

  slaughterDate: Yup.string(),

  fatherCode: Yup.string(),

  motherEarringId: Yup.string(),

  maternalGrandfatherCode: Yup.string(),

  maternalGreatGrandfatherCode: Yup.string()
})

export default registerAnimal
