import React from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'

import { Container, HeaderText , ButtonContainer } from './styles'


const DeleteMessageModal = ({ id, open, handleClose, handleDelete, deleteMe, deleteAll }) => (
  <Modal
    id={ id }
    open={ open }
    escape={ false }
    hideHeader
  >
    <Container>
      <HeaderText>
        <I18n>delete messages</I18n>
      </HeaderText>

      <ButtonContainer>
        { deleteMe && (
          <Button
            id="delete-for-me"
            color='secondary'
            onClick={ () => {
              handleDelete(false)
              handleClose()
            } }
            style={ { minWidth: '100%' } }
          >
            <I18n>delete for me</I18n>
          </Button>
        ) }

        { deleteAll && (
          <Button
            id="delete-for-all"
            color='secondary'
            onClick={ () => {
              handleDelete(true)
              handleClose()
            } }
            style={ { minWidth: '100%' } }
          >
            <I18n>delete for all</I18n>
          </Button>
        ) }

        <Button
          id="cancel"
          onClick={ handleClose }
          style={ { minWidth: '100%' } }
        >
          <I18n>cancel</I18n>
        </Button>
      </ButtonContainer>
    </Container>
  </Modal>
)

DeleteMessageModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  deleteMe: PropTypes.bool,
  deleteAll: PropTypes.bool
}

DeleteMessageModal.defaultProps = {
  deleteMe: false,
  deleteAll: false
}

export default DeleteMessageModal
