import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'

import {
  Badge
} from './styles'

const NonComplianceStatus = props => {
  const {
    status,
    style
  } = props

  const options = useMemo(() => {
    switch (status) {

      case 1:
        return {
          name: <I18n params={ { gender: 'female' } }>opened</I18n>,
          background: '(25, 156, 216, 0.1)',
          color: '(25, 156, 216, 1)'
        }
      case 2:
        return {
          name: <I18n params={ { gender: 'female' } }>finished</I18n>,
          background: '(29, 29, 27, 0.1)',
          color: '(29, 29, 27, 1)'
        }
      default:
        return {
          name: '',
          background: '(40, 159, 48, 0.1)',
          color: '(40, 159, 48, 1)'
        }
    }
  }, [status])

  return (
    <Badge
      backgroundColorBadge={ options.background }
      colorBadge={ options.color }
      style={ style }
    >
      { options.name }
    </Badge>
  )
}


NonComplianceStatus.propTypes = {
  status: PropTypes.oneOf([
    1,2
  ]).isRequired,
  style: PropTypes.object
}

NonComplianceStatus.defaultProps = {
  style: {}
}

export default NonComplianceStatus
