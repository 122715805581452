import * as Yup from 'yup'


import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const nfeIntegrator = ({ t }) => Yup.object().shape({
  CpfCnpj: flow(
    required({ t })
  )(Yup.string()),

  Nome: flow(
    required({ t })
  )(Yup.string()),

  Fantasia: flow(
    required({ t })
  )(Yup.string()),

  Logradouro: flow(
    required({ t })
  )(Yup.string()),

  NroEnd: flow(
    required({ t })
  )(Yup.string()),

  Bairro: flow(
    required({ t })
  )(Yup.string()),

  Complemento: flow(
  )(Yup.string()),

  CEP: flow(
    required({ t })
  )(Yup.string()),

  Cidade: flow(
    required({ t })
  )(Yup.string()),

  Uf: flow(
    required({ t })
  )(Yup.string()),

  Email: flow(
    required({ t })
  )(Yup.string()),

  Fone: flow(
  )(Yup.string())
})

export default nfeIntegrator
