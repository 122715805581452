import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { colors } from '@smartcoop/styles'
import { formatCep, formatCpfCnpj } from '@smartcoop/utils/formatters'

import NonComplianceStatus from '../NonComplianceStatus/NonComplianceStatus'
import {
  Container
} from './styles'

const NonComplianceCard = (props) => {
  const { nonCompliance, handleClick, selectedNonCompliance } = props
  const { deliveryLocation, organization } = nonCompliance

  const selected = useMemo(() => {
    if(selectedNonCompliance === null) {
      return false
    }
    return nonCompliance.id === selectedNonCompliance.id},
  [nonCompliance, selectedNonCompliance])

  return (
    <Container onClick={ () => handleClick(nonCompliance.id) } style={ selected ? { boxShadow: `0px 0px 6px ${ colors.yellow }` } : {} }>
      <div style={ { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' } }>
        <div style={ { fontWeight: 'bold' } }>
          {organization.companyName}
        </div>
        <div style={ { fontWeight: 'bold' } }>
          {`CNPJ ${ formatCpfCnpj(deliveryLocation.organization.companyDocument) }`}
        </div>
        <div style={ { fontWeight: 'bold' } }>
          {deliveryLocation.organization.tradeName}
        </div>
      </div>
      <div style={ { display: 'flex', flexDirection: 'column' } }>
        <div>
          {`CEP ${ formatCep(deliveryLocation.organization.postalCode) }`}
        </div>
        <div>
          {`${ deliveryLocation.organization.city }/${ deliveryLocation.organization.state }`}
        </div>
        <div>
          <NonComplianceStatus status={ nonCompliance.statusId } />
        </div>
      </div>
    </Container>)
}

NonComplianceCard.propTypes = {
  nonCompliance: PropTypes.shape({
    id: PropTypes.string,
    statusId: PropTypes.string,
    organization: PropTypes.shape({
      companyDocument: PropTypes.string,
      companyName: PropTypes.string,
      tradeName: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      city: PropTypes.string
    }),
    deliveryLocation: PropTypes.shape({
      organization: PropTypes.shape({
        companyDocument: PropTypes.string,
        companyName: PropTypes.string,
        tradeName: PropTypes.string,
        postalCode: PropTypes.string,
        state: PropTypes.string,
        city: PropTypes.string
      }),
      organizationPurchase: PropTypes.shape({
      })
    })
  }),
  handleClick: PropTypes.func.isRequired,
  selectedNonCompliance: PropTypes.string.isRequired
}

NonComplianceCard.defaultProps = {
  nonCompliance: {}
}

export default NonComplianceCard
