// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
      <g>
        <g>
          <path fill="#6EC4E9" d="M129.1,129.6c-1-0.2-2,0.2-2.5,1l0,0l-34.8,53.7c-0.6,0.9-1.1,1.8-1.4,2.9c-1.6,5.4,2,11,8.1,12.4
            c6,1.4,12.2-1.7,13.9-7.1h0l8.4-27.9c0,0,0-0.1,0-0.1l0,0l9.8-32.5l0-0.2l0,0C130.9,130.9,130.2,129.9,129.1,129.6z"/>
        </g>
        <g>
          <g>
            <path fill="#D3D3D3" d="M82.8,158.3c-0.3,0-0.5,0-0.8-0.2c-1.1-0.4-1.6-1.7-1.1-2.7L96.3,119c0.4-1.1,1.7-1.6,2.7-1.1
              c1.1,0.4,1.6,1.7,1.2,2.7L84.7,157C84.4,157.8,83.6,158.3,82.8,158.3z"/>
            <path fill="#D3D3D3" d="M76.1,143.9c-0.3,0-0.5,0-0.8-0.2c-1.1-0.4-1.6-1.7-1.1-2.7l7.6-18.3c0.4-1.1,1.7-1.6,2.7-1.1
              c1.1,0.4,1.6,1.7,1.2,2.7L78,142.6C77.7,143.4,76.9,143.9,76.1,143.9z"/>
            <path fill="#D3D3D3" d="M79.9,172.2c0,5.7-4.6,10.3-10.3,10.3s-10.3-4.6-10.3-10.3c0-5.7,4.6-10.3,10.3-10.3
              S79.9,166.5,79.9,172.2z"/>
          </g>
        </g>
      </g>
      <g>
        <path fill="#D3D3D3" d="M197.9,67.2c-4.7-17.5-22.7-28-40.2-23.4c0,0-1.3,0.3-3.5,0.9c-5.6-20.8-24.6-36.2-47.2-36.2
          c-24.5,0-44.8,18.1-48.3,41.6c-0.8-0.2-1.3-0.3-1.3-0.3c-14.8-3.8-30,5-33.9,19.8c-0.9,3.5-1.1,7-0.8,10.4c-7.4,2-12,3.2-12,3.2
          c-7,1.9-11.1,9-9.2,16c1.6,6.1,7.3,10,13.4,9.7h150.3c0,0,6.2-0.3,9.4-1.1C192.1,102.9,202.6,84.8,197.9,67.2z"/>
      </g>
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
