export const inputSearchTheme = (theme) => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiFormControl: {
      ...theme.overrides?.MuiFormControl,
      root: {
        ...theme.overrides?.MuiFormControl?.root,
        marginBottom: '0 !important'
      }
    },
    MuiFormHelperText: {
      ...theme.overrides?.MuiFormHelperText,
      root: {
        ...theme.overrides?.MuiFormHelperText?.root,
        marginTop: '0 !important'
      }
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff'
      }
    }
  }
})
