import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import Modal from '@smartcoop/web-components/Modal'

import PropertiesAssetsForm from '../../forms/digitalProperty/propertiesAssets/PropertiesAssetsForm/PropertiesAssets'
import { Content } from './styles'

const PropertiesAssetsModal = (props) => {
  const { id, open, disabled, onSuccess, handleClose, readOnly, propertiesAssets, defaultValues, cropsOptions, isEditable } = props
  const propertiesAssetsRegisterFormRef = useRef(null)

  return (
    <Modal
      id={ id }
      open={ open }
      disableFullScreen
      maxWidth='md'
      fullWidth
      disableEsc
      headerProps={ {
        disableTypography: true
      } }
      title="Cadastro de Bens e Ativos"
      contentContainerStyle={ { padding: 0, minWidth: 700 } }
    >
      <>
        <Content>

          <PropertiesAssetsForm
            handleClose={ handleClose }
            disabled={ disabled }
            onSuccess={ onSuccess }
            ref={ propertiesAssetsRegisterFormRef }
            readOnly={ readOnly }
            isEditable={ isEditable }
            propertiesAssetsData={ propertiesAssets }
            defaultValues={ defaultValues }
            cropsOptions={ cropsOptions }
          />
        </Content>
      </>
    </Modal>
  )
}

PropertiesAssetsModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSuccess: PropTypes.func,
  propertiesAssets: PropTypes.array.isRequired,
  defaultValues: PropTypes.object,
  cropsOptions: PropTypes.array.isRequired,
  isEditable: PropTypes.bool.isRequired
}

PropertiesAssetsModal.defaultProps = {
  disabled: false,
  readOnly: false,
  onSuccess: () => {},
  defaultValues: {}
}

export default PropertiesAssetsModal
