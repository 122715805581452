import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { select, call, all, put, takeLatest } from 'redux-saga/effects'

import { v4 as uuidv4 } from 'uuid'

import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'

import {
  getAllAnimalDrying as getAllAnimalDryingService,
  createAnimalDrying as createAnimalDryingService,
  editAnimalDrying as editAnimalDryingService,
  deleteAnimalDrying as deleteAnimalDryingService
} from '@smartcoop/services/apis/smartcoopApi/resources/animalPregnancyActions'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { OfflineAnimalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineAnimalUserData/duckOfflineAnimalUserData'
import { selectOfflineAnimals } from '@smartcoop/stores/offlineData/userData/offlineAnimalUserData/selectorOfflineAnimalUserData'
import { OfflineTechnicalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData'
import { selectOfflineTechnicalAnimals } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData/selectorOfflineTechnicalUserData'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'

import { selectModuleIsTechnical } from '../module/selectorModule'
import { selectIsConnected } from '../network/selectorNetwork'
import { AnimalPregnancyActionsActions, AnimalPregnancyActionsTypes } from './duckAnimalPregnancyActions'

function* loadDryings({ params = {}, onSuccess= () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const currentAnimal = yield select(selectCurrentAnimal)

    const { data: { data, ...pagination } } = yield call(
      getAllAnimalDryingService,
      {
        limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
        ...params
      },
      { propertyId: currentProperty?.id, animalId: currentAnimal?.id }
    )

    yield put(AnimalPregnancyActionsActions.loadDryingsSuccess(
      data,
      pagination.page,
      () => onSuccess(data)
    ))
  } catch (err) {
    const error = err.message
    yield put(AnimalPregnancyActionsActions.dryingError(error))
    yield call(onError, error)
  }
}

function* loadDryingsSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* saveDrying({ params, onSuccess = () => {}, onError = () => {}, isEdit = false, isConnected = true }) {
  try {
    yield put(AnimalPregnancyActionsActions.resetBlockDrying())

    const { data } = yield call(
      !isEdit ? createAnimalDryingService : editAnimalDryingService,
      {
        ...params
      },
      { propertyId: params?.propertyId ?? null, dryingId: isEdit ? params?.id : null }

    )

    if(isConnected) {
      yield call(onSuccess, data)
    }
  } catch (err) {
    const error = err.message
    yield put(AnimalPregnancyActionsActions.dryingError(error))
    yield call(onError, error)
  }
}

function* saveOfflineDrying({ params, onSuccess = () => {}, onError = () => {}, web = false }) {
  try {
    const currentAnimal = yield select(selectCurrentAnimal)
    const currentProperty = yield select(selectCurrentProperty)
    const isTechnical = yield select(selectModuleIsTechnical)
    const isConnected = web ? true : yield select(selectIsConnected)
    // eslint-disable-next-line no-nested-ternary
    const animals = web ? null : (isTechnical ? yield select(selectOfflineTechnicalAnimals) : yield select(selectOfflineAnimals))

    yield put(AnimalPregnancyActionsActions.addBlockDrying(currentAnimal?.id))

    if(!web) {
      const newDrying = {
        ...params,
        id: params?.id,
        // eslint-disable-next-line no-unused-vars
        ids: params?.id ? [params?.id] : map(params?.animalsId, _ => uuidv4()),
        propertyId: currentProperty?.id
      }
      const updatedAnimals = map(params?.animalsId, id => {
        const foundAnimal = find(animals, each => each.id === id)
        return {
          ...currentAnimal,
          drying: [
            ...filter(foundAnimal?.drying, item => item.id !== params.id),
            newDrying
          ],
          alreadyDrying: !isConnected ? true : null
        }
      })

      if (isTechnical) {
        yield all(updatedAnimals.map(animal => put(OfflineTechnicalUserDataActions.updateOfflineAnimal(animal))))
      } else {
        yield all(updatedAnimals.map(animal => put(OfflineAnimalUserDataActions.updateOfflineAnimal(animal))))
      }
      yield put(AnimalPregnancyActionsActions.saveDrying(newDrying, onSuccess, onError, params?.id, isConnected))

    }

    yield put(AnimalPregnancyActionsActions.saveDrying({ ...params, propertyId: currentProperty?.id }, onSuccess, onError, !!params?.id, isConnected))

  } catch (err) {
    const error = err.message
    yield put(AnimalPregnancyActionsActions.dryingError(error))
    yield call(onError, error)
  }
}

function* deleteDrying({ id, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)

    yield call(deleteAnimalDryingService, { propertyId: currentProperty?.id, dryingId: id })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(AnimalPregnancyActionsActions.dryingError(error))
    yield call(onError, error)
  }
}

function* deleteOfflineDrying({ id, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(OfflineAnimalUserDataActions.removeOfflineDrying(id))

    yield put(AnimalPregnancyActionsActions.deleteDrying(id, onSuccess, onError))

  } catch (err) {
    const error = err.message
    yield put(AnimalPregnancyActionsActions.dryingError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(AnimalPregnancyActionsTypes.LOAD_DRYINGS, loadDryings),
  takeLatest(AnimalPregnancyActionsTypes.LOAD_DRYINGS_SUCCESS, loadDryingsSuccess),

  takeLatest(AnimalPregnancyActionsTypes.SAVE_DRYING, saveDrying),
  takeLatest(AnimalPregnancyActionsTypes.SAVE_OFFLINE_DRYING, saveOfflineDrying),

  takeLatest(AnimalPregnancyActionsTypes.DELETE_DRYING, deleteDrying),
  takeLatest(AnimalPregnancyActionsTypes.DELETE_OFFLINE_DRYING, deleteOfflineDrying)

]
