import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'

import debounce from 'lodash/debounce'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import take from 'lodash/take'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { emptyFilter } from '@smartcoop/icons'
import { InformativesActions } from '@smartcoop/stores/informatives'
import { selectWebinars } from '@smartcoop/stores/informatives/selectorInformatives'
import { OrganizationActions } from '@smartcoop/stores/organization'
import { selectIsOrganizationAdmin, selectIsProfileRTC, selectUserOrganizationIsSmartcoop } from '@smartcoop/stores/organization/selectorOrganization'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Carousel from '@smartcoop/web-components/Carousel'
import EmptyState from '@smartcoop/web-components/EmptyState'
import InputSearch from '@smartcoop/web-components/InputSearch'
import InputSelect from '@smartcoop/web-components/InputSelect'
import LoadingModal from '@smartcoop/web-containers/modals/LoadingModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { Container, Row, InputContainer, ContainerListFields, TabItem, TabsGroup, Title } from './style'

const PropertyInformativeScreen = () => {
  const dispatch = useDispatch()
  const [filterText, setFilterText] = useState('')
  const [debouncedFilterText, setDebouncedFilterText] = useState('')
  const [activeTab, setActiveTab] = useState('')
  const [userOrganizations, setUserOrganizations] = useState([])
  const t = useT()
  const isRtc = useSelector(selectIsProfileRTC)
  const isOrganizationAdmin = useSelector(selectIsOrganizationAdmin)
  const isSmartcoop = useSelector(selectUserOrganizationIsSmartcoop)
  const [organizations, setOrganizations] = useState('')
  const webinar = useSelector(selectWebinars)
  const { createDialog, removeDialog } = useDialog()
  const history = useHistory()
  const { routes } = useRoutes()

  const webinarByKeyword = useMemo(
    () => groupBy(webinar, 'fileType'),
    [webinar]
  )

  useEffect(() => {
    let newTab = ''
    let selectedTab = null
    switch (activeTab) {
      case 0:
        selectedTab = 'webinar'
        break

      case 1:
        selectedTab = 'technicalVideos'
        break

      case 2:
        selectedTab = 'boletim-tecnico'
        break

      case 3:
        selectedTab = 'searchResults'
        break

      case 4:
        selectedTab = 'news'
        break

      case 5:
        selectedTab = 'informatives'
        break
      default:
        break
    }
    if(!includes(Object.keys(webinarByKeyword), selectedTab) || isNull(selectedTab)){
      if(webinarByKeyword?.webinar) {
        newTab = 0
      } else if(webinarByKeyword?.technicalVideos) {
        newTab = 1
      }else if(webinarByKeyword['boletim-tecnico']) {
        newTab = 2
      }else if(webinarByKeyword?.searchResults) {
        newTab = 3
      }else if(webinarByKeyword?.news) {
        newTab = 4
      }else if(webinarByKeyword?.informatives) {
        newTab = 5
      }
      setActiveTab(
        newTab
      )
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[webinarByKeyword])

  const handleParams = useCallback(
    (values) =>
      Object.keys(values)
        .filter((key) => typeof values[key] === 'boolean' || values[key])
        .reduce((prev, curr) => ({ ...prev, [curr]: values[curr] }), {}),
    []
  )

  const params = useMemo(() => {
    const filterParams = {
      q: debouncedFilterText,
      organizationId: organizations,
      limit: 99999
    }

    return handleParams(filterParams)
  }, [debouncedFilterText, handleParams, organizations])

  const loadWebinars = useCallback(
    debounce(() => {
      dispatch(
        InformativesActions.loadWebinars(
          params
        )
      )
    }, 300),
    [dispatch, params]
  )

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )

  const breakPoints = useMemo(
    () => [
      { width: 100, itemsToShow: 2 },
      { width: 550, itemsToShow: 3 },
      { width: 768, itemsToShow: 4 },
      { width: 1200, itemsToShow: [2, 3, 4, 5].includes(activeTab) ? 6 : 5 }
    ],
    [activeTab]
  )

  useEffect(() => {
    if(!isEmpty(organizations)) {
      loadWebinars()
    }
  }, [params, dispatch, loadWebinars, organizations])

  useEffect(() => {
    dispatch(OrganizationActions.loadUserOrganizations(true, (orgs) => {
      if(!isEmpty(orgs)) {
        setUserOrganizations(orgs)
        const rtc = find(orgs, item => item.tradeName === 'RTC')
        if(rtc) {
          setOrganizations(rtc?.id)
        } else {
          setOrganizations(orgs[0]?.id)
        }
      }
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onAccessWebinar = useCallback((accessParams) => {
    dispatch(InformativesActions.accessWebinar(accessParams, loadWebinars))
  }, [dispatch, loadWebinars])

  const renderTabData = useCallback((tabData) => {

    const dataWithYear = map(tabData, item => ({ ...item, year: moment(item.publicationDate, 'YYYY-MM-DD hh:mm:ss').format('YYYY') }))
    let groupedData = groupBy(dataWithYear, 'year')

    groupedData = map(groupedData, item => orderBy(item, 'publicationDate', 'desc'))

    let viewType = 'landscape'
    if ([2, 3, 4, 5].includes(activeTab)) {
      viewType = 'portrait'
    }

    return (
      <>
        <>
          <Title>{t('trending')}</Title>
          <Carousel
            viewType={ viewType }
            breakPoints={ breakPoints }
            items={ take(orderBy(tabData, ['accessNumber', 'publicationDate'], ['desc', 'desc']), 10) }
            onClickAction={ onAccessWebinar }
          />
        </>
        {map(orderBy(groupedData, '[0].year', 'desc'), items => <>
          <Title>{items[0]?.year}</Title>
          <Carousel
            viewType={ viewType }
            breakPoints={ breakPoints }
            items={ items }
            onClickAction={ onAccessWebinar }
          />
        </>)}
      </>
    )

  }, [activeTab, breakPoints, onAccessWebinar, t])

  const renderList = useMemo(() => {
    let tabData = []
    switch (activeTab) {
      case 0:
        tabData = webinarByKeyword.webinar
        break

      case 1:
        tabData = webinarByKeyword.technicalVideos
        break

      case 2:
        tabData = webinarByKeyword['boletim-tecnico']
        break

      case 3:
        tabData = webinarByKeyword.searchResults
        break

      case 4:
        tabData = webinarByKeyword.news
        break

      case 5:
        tabData = webinarByKeyword.informatives
        break
      default:
        return null
    }
    return (
      <>
        {renderTabData(tabData)}
      </>
    )
  }, [activeTab, renderTabData, webinarByKeyword])

  const organizationOptions = useMemo(
    () =>
      map(userOrganizations, ({ tradeName, id }) => ({
        label: tradeName,
        value: id
      })),
    [userOrganizations]
  )

  const onChangeTab = useCallback((tab) => {
    createDialog({
      id: 'loading',
      Component: LoadingModal
    })
    setActiveTab(tab)
    setTimeout(() => removeDialog({ id: 'loading' }), 150)
  }, [createDialog, removeDialog])

  return (
    <Container>
      <Row>
        <InputContainer>
          <InputSearch
            adornmentStyle={ { marginRight: 15 } }
            detached
            onChange={ onChangeSearchFilter }
            value={ filterText }
            placeholder={ t('search') }
            style={ { width: '80vw', marginRight: 20 } }
          />
          <InputSelect
            detached
            disableClearable
            label={ t('select one organization') }
            name="organization"
            options={ organizationOptions }
            value={ organizations }
            onChange={ setOrganizations }
          />
          {(isSmartcoop || isRtc || isOrganizationAdmin) && (
            <div style={ { width: '60vw', marginLeft: 20 } }>
              <Button
                variant="contained"
                color={ colors.secondary }
                onClick={ () => history.push(routes?.informativeRegister?.path) }
                fullWidth
              >
                {t('registered material')}
              </Button>
            </div>
          )}
        </InputContainer>
        <TabsGroup value={ activeTab }>
          <TabItem
            disabled={ isEmpty(webinarByKeyword.webinar) }
            onClick={ () => onChangeTab(0) }
            label={ t('webinars') }
            style={ isEmpty(webinarByKeyword.webinar) ? { opacity: 0.2 } : {} }
          />
          <TabItem
            disabled={ isEmpty(webinarByKeyword.technicalVideos) }
            onClick={ () => onChangeTab(1) }
            label={ t('technical videos') }
            style={ isEmpty(webinarByKeyword.technicalVideos) ? { opacity: 0.2 } : {} }
          />
          <TabItem
            disabled={ isEmpty(webinarByKeyword['boletim-tecnico']) }
            onClick={ () => onChangeTab(2) }
            label={ t('technical bulletins') }
            style={ isEmpty(webinarByKeyword['boletim-tecnico']) ? { opacity: 0.2 } : {} }
          />
          <TabItem
            disabled={ isEmpty(webinarByKeyword.searchResults) }
            onClick={ () => onChangeTab(3) }
            label={ t('search results') }
            style={ isEmpty(webinarByKeyword.searchResults)? { opacity: 0.2 } : {} }
          />
          <TabItem
            disabled={ isEmpty(webinarByKeyword.news) }
            onClick={ () => onChangeTab(4) }
            label={ t('news') }
            style={ isEmpty(webinarByKeyword.news) ? { opacity: 0.2 } : {} }
          />
          <TabItem
            disabled={ isEmpty(webinarByKeyword.informatives) }
            onClick={ () => onChangeTab(5) }
            label={ t('informative') }
            style={ isEmpty(webinarByKeyword.informatives) ? { opacity: 0.2 } : {} }
          />
        </TabsGroup>
      </Row>
      {isEmpty(webinarByKeyword) ? (
        <ContainerListFields isCenter>
          <EmptyState text={ t('no materials found') } icon={ emptyFilter } />
        </ContainerListFields>
      ) : renderList }

    </Container>
  )
}

export default PropertyInformativeScreen
