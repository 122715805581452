import * as Yup from 'yup'


import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import required from '@smartcoop/forms/validators/required.validator'

const technicalVisitSchedule = ({ t }) => Yup.object().shape({
  predictedDate: flow(
    date({ t }),
    required({ t })
  )(Yup.string()),

  scheduleNote: Yup.string(),

  hour: flow(
    required({ t })
  )(Yup.string().matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, t('invalid hour'))),

  visitMode: flow(
    required({ t })
  )(Yup.string()),

  visitGoalId: flow(
    required({ t })
  )(Yup.string())
})

export default technicalVisitSchedule
