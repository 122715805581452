import React, { useMemo } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import join from 'lodash/join'
import map from 'lodash/map'

import ListItem from '@material-ui/core/ListItem'

import { checked, calendar } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import { PHYTOSANITARY_APPLICATION_PRODUCT_GROUPS_OBJECT, SEEDTREATMENT_PRODUCT_GROUPS_OBJECT } from '@smartcoop/utils/constants'
import { momentFriendlyDateFormat, momentBackDateFormat } from '@smartcoop/utils/dates'
import Icon from '@smartcoop/web-components/Icon'

import {
  Container,
  Date,
  ManagementName,
  DateContent,
  TextContent,
  FieldName,
  Header
} from './styles'

const Management = ({
  date,
  managementType,
  done,
  onClick,
  cropManagement,
  fieldName,
  active
}) => {

  const colorChecked = useMemo(
    () => !done ? colors.lightGrey : colors.green
    ,
    [done]
  )

  const colorDate = useMemo(
    () => {
      if(done) {
        return colors.grey
      }
      if(moment().format('YYYY-MM-DD') > date){
        return colors.red
      }
      return colors.green

    },
    [date, done]
  )

  const dateManagement = useMemo(
    () => done ? moment(done, momentBackDateFormat).format(momentFriendlyDateFormat) : moment(date, momentBackDateFormat).format(momentFriendlyDateFormat)
    ,
    [date, done]
  )

  const phytosanitaryProductGroups = useMemo(() => {
    // eslint-disable-next-line no-nested-ternary
    const cropManagementItem = !isEmpty(cropManagement?.cropManagementItem?.items) ? (isString(cropManagement?.cropManagementItem?.items) ? JSON.parse(cropManagement?.cropManagementItem?.items) : cropManagement?.cropManagementItem?.items) : []

    const itemProductGroups = map(cropManagementItem?.formFields, item => cropManagement?.operation?.slug === 'aplicacao-fitossanitaria' ? PHYTOSANITARY_APPLICATION_PRODUCT_GROUPS_OBJECT[item?.productGroup] : SEEDTREATMENT_PRODUCT_GROUPS_OBJECT[item?.productGroup])

    return join(itemProductGroups, ' | ')
  },[cropManagement])

  const soilPrepareItems = useMemo(() => {
    // eslint-disable-next-line no-nested-ternary
    const cropManagementItem = !isEmpty(cropManagement?.cropManagementItem?.items) ? (isString(cropManagement?.cropManagementItem?.items) ? JSON.parse(cropManagement?.cropManagementItem?.items) : cropManagement?.cropManagementItem?.items) : []

    return join(cropManagementItem?.type, ' | ')
  }, [cropManagement])

  return (
    <ListItem
      button={ !!onClick }
      onClick={ () => onClick && onClick(cropManagement) }
      divider
      style={ active ? { border: '2px solid #FFC80A' } : undefined }
    >
      <Container>
        <Header>
          <TextContent>
            <Icon
              size={ 18 }
              icon={ checked }
              color={ colorChecked }
              style={ { marginRight: 10 } }
            />
            <ManagementName>{managementType}</ManagementName>
          </TextContent>
          {fieldName && (
            <FieldName>
              {fieldName}
            </FieldName>
          )}
        </Header>
        { cropManagement?.operation?.slug === 'aplicacao-fitossanitaria' && !isEmpty(phytosanitaryProductGroups) && (
          <DateContent>
            <Date>
              {phytosanitaryProductGroups}
            </Date>
          </DateContent>
        )}
        { cropManagement?.operation?.slug === 'preparo-de-solo' && (
          <DateContent>
            <Date>
              {soilPrepareItems}
            </Date>
          </DateContent>
        )}
        <DateContent>
          <Icon style={ { marginRight: 5 } } size={ 10 } icon={ calendar } color={ colorDate }/>
          <Date style={ { color: colorDate } }>{dateManagement}</Date>
        </DateContent>
      </Container>
    </ListItem>
  )
}

Management.propTypes = {
  date: PropTypes.string,
  managementType: PropTypes.string,
  done: PropTypes.string,
  onClick: PropTypes.func,
  cropManagement: PropTypes.object,
  fieldName: PropTypes.string,
  active: PropTypes.bool
}

Management.defaultProps = {
  date: null,
  managementType: null,
  done: null,
  cropManagement: {},
  active: false,
  onClick: undefined,
  fieldName: null
}

export default Management
