import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`
