import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'

import { CloseIconButton, FullScreenIconButton, ExitFullScreenIconButton } from '@smartcoop/web-components/IconButton'

import useStyles, { Divisor } from './styles'

const HeaderTitleClose = (props) => {
  const {
    title,
    disableTypography,
    titleClass,
    onClose,
    escape,
    containerClass,
    fullScreenButton,
    fullScreen,
    closeIconColor,
    onChangeFullScreen
  } = props

  const classes = useStyles()

  const fullScreenIconButton = useMemo(() => {
    if (fullScreenButton) {
      let CustomButton = FullScreenIconButton
      if (fullScreen) {
        CustomButton = ExitFullScreenIconButton
      }
      return <CustomButton size="small" marginHorizontal marginVertical onClick={ onChangeFullScreen } />
    }
    return null
  }, [fullScreenButton, fullScreen, onChangeFullScreen])

  return (
    <>
      <div className={ [classes.header, containerClass].join(' ') }>
        <DialogTitle
          className={ [classes.title, titleClass].join(' ') }
          disableTypography={ disableTypography }
        >
          {title}
        </DialogTitle>
        <div
          style={ {
            position: 'absolute',
            width: '98%',
            display: 'flex',
            justifyContent: 'flex-end'
          } }
        >
          {fullScreenIconButton}
          {escape && (
            <CloseIconButton iconColor={ closeIconColor } size="small" marginHorizontal marginVertical onClick={ onClose } />
          )}
        </div>
      </div>
      <Divisor />
    </>
  )
}

HeaderTitleClose.propTypes = {
  escape: PropTypes.bool.isRequired,
  disableTypography: PropTypes.bool,
  titleClass: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClose: PropTypes.func,
  containerClass: PropTypes.string,
  closeIconColor: PropTypes.string,
  fullScreenButton: PropTypes.bool,
  fullScreen: PropTypes.bool.isRequired,
  onChangeFullScreen: PropTypes.func.isRequired
}

HeaderTitleClose.defaultProps = {
  disableTypography: true,
  title: null,
  titleClass: null,
  closeIconColor: null,
  onClose: () => {},
  containerClass: null,
  fullScreenButton: false
}

export default HeaderTitleClose
