// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
      <g>
        <path fill="#DEDDDD" d="M35.9,129.9c-6.5,0-12.3-4.4-14-10.7c-1-3.7-0.5-7.6,1.4-11c1.9-3.3,5.1-5.7,8.8-6.7l0,0
          c0.6-0.2,2.4-0.7,8.1-2.2c-0.1-2.6,0.3-5.1,0.9-7.6c0.5-1.8,1.2-3.5,2-5.1c-4.6-23.2,0.8-46,15.2-60.7c-5.4,1.7-10.8,3.9-15.9,6.9
          C6.2,53.6-6.3,100,14.7,136.3c21,36.3,67.4,48.8,103.7,27.8c5.1-3,9.8-6.4,13.9-10.3c-22.5,5.7-48.4-3.6-67-24H36.7
          C36.5,129.8,36.2,129.9,35.9,129.9z"/>
        <path fill="#D3D3D3" d="M194.6,91c-3.1-11.4-13.4-19.3-25.1-19.3c-2.2,0-4.4,0.3-6.5,0.8l-0.2,0c-0.4,0.1-1.4,0.4-2.9,0.8
          l-2.9,0.8l-0.8-2.9c-4.7-17.4-20.5-29.6-38.6-29.6c-19.6,0-36.6,14.6-39.5,34L77.6,79l-4.4-1.2c-1.8-0.5-3.6-0.7-5.4-0.7
          c-9.7,0-18.3,6.6-20.8,16c-0.4,1.6-0.6,3.2-0.7,4.8c0,1.1,0,2.1,0.1,3.2l0.3,2.6l-2.5,0.7c-5.7,1.5-9.4,2.5-10.3,2.8l-0.2,0.1
          c-2.2,0.6-4,2-5.1,3.9c-1.1,2-1.4,4.2-0.8,6.4c1,3.9,4.7,6.5,8.7,6.3l0.2,0h23.7h108.4c1.5-0.1,5.6-0.4,7.5-0.9
          C190.1,119.2,198.3,104.9,194.6,91z"/>
      </g>
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
