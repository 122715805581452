import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export const Label = styled(Typography)`
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const ErrorLabel = styled(Label)`
  font-weight: normal;
  margin-left: 8px;
  font-size: 13px;
  margin-bottom: 10px;
  color: ${ colors.error };
`

export const NoDataFoundRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  margin-bottom: 15px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
`

export const inputSearchTheme = (theme) => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff'
      }
    }
  }
})

export const inputQuantityTheme = (theme) => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiTextField: {
      ...(theme.overrides.MuiTextField || {}),
      root: {
        marginBottom: '0 !important'
      }
    }
  }
})


