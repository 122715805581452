import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  medicationControls: [],
  currentMedicationControl: null,
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadMedicationControls: ['params', 'onSuccess', 'onError'],
  loadMedicationControlsSuccess: ['params', 'page', 'onSuccess'],

  saveMedicationControl: ['params', 'onSuccess', 'onError', 'isEdit'],
  saveOfflineMedicationControl: ['params', 'onSuccess', 'onError'],

  deleteMedicationControl: ['params', 'registerCode', 'onSuccess', 'onError'],
  deleteOfflineMedicationControl: ['params', 'registerCode', 'onSuccess', 'onError'],

  medicationControlError: ['error']
})

markActionsOffline(Creators, [
  'saveMedicationControl',
  'deleteMedicationControl'
])

/**
 * Reducers functions
 */
const loadMedicationControlsSuccess = (state = INITIAL_STATE, { params, page }) => (
  {
    ...state,
    error: INITIAL_STATE.error,
    medicationControls: page === 1 ? params : [
      ...state.medicationControls,
      ...params
    ]
  }
)

const medicationControlError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_MEDICATION_CONTROLS_SUCCESS]: loadMedicationControlsSuccess,

  [Types.MEDICATION_CONTROL_ERROR]: medicationControlError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as MedicationControlTypes,
  Creators as MedicationControlActions
}
