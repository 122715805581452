/* eslint-disable no-nested-ternary */
import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { find } from 'lodash'
import filter from 'lodash/filter'
import flatten from 'lodash/flatten'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import map from 'lodash/map'

import I18n, { useT } from '@smartcoop/i18n'
import { smallPlant } from '@smartcoop/icons'
import { getFieldsByPropertyId } from '@smartcoop/services/apis/smartcoopApi/resources/field'
import { useSnackbar } from '@smartcoop/snackbar'
import { FieldActions } from '@smartcoop/stores/field'
import { selectCurrentField } from '@smartcoop/stores/field/selectorField'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import colors from '@smartcoop/styles/colors'
import { getPolygonCenter, findMapZoom } from '@smartcoop/utils/maps'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import Maps from '@smartcoop/web-components/Maps'
import Polygon from '@smartcoop/web-components/Maps/components/Polygon'
import PartialLoading from '@smartcoop/web-components/PartialLoading'
import GrowingSeasonForm from '@smartcoop/web-containers/forms/digitalProperty/growingSeason/GrowingSeasonForm'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { Container, Header, Title, IconContainer } from './styles'

const GrowingSeasonRegisterScreen = () => {
  const [loading, setLoading] = useState(false)
  const growingSeasonFormRef = useRef(null)
  const { routes } = useRoutes()
  const history = useHistory()
  const dispatch = useDispatch()
  const t = useT()
  const snackbar = useSnackbar()

  const [plannedFields, setPlannedFields] = useState([])
  const [fieldOptions, setFieldOptions] = useState([])
  const [selectedPolygons, setSelectedPolygons] = useState([])

  const { polygonCoordinates, childrenPolygons } = useSelector(selectCurrentField)
  const propertyId = useSelector(selectCurrentPropertyId)

  const childrenPolygonId = history?.location?.state?.childrenPolygonId
  const currentGrowingSeason = history?.location?.state?.currentGrowingSeason || null
  const isPlanned = history?.location?.state?.isPlanned || null

  const handleClose = useCallback(
    ({ fieldId, growingSeasonId }) => {
      if(isPlanned) {
        history.push(routes.fieldList.path, { isPlanned })
      } else {
        dispatch(FieldActions.setFieldNavigationData({ fieldId, growingSeasonId, childrenPolygonId, isPlanned: currentGrowingSeason?.isPlanned }))
        history.push(routes.fieldDetails.path.replace(':fieldId', fieldId))
      }
    },
    [childrenPolygonId, currentGrowingSeason, dispatch, history, isPlanned, routes]
  )

  const plannedOptions = useMemo(() => filter(fieldOptions, item => includes(plannedFields, item.id)), [fieldOptions, plannedFields])

  const optionsByFields = useMemo(() => flatten(map(plannedOptions, item =>
    !isEmpty(item?.childrenPolygons)
      ? map(item?.childrenPolygons, polygon => ({ label: `${ item.fieldName } - ${ polygon.name }`, value: polygon.id }))
      : ({ label: item?.fieldName, value: item?.id })
  )), [plannedOptions])

  const loadFieldOptions = useCallback(
    async () => {
      const result = await getFieldsByPropertyId({
        page: 1,
        limit: 99999
      }, { propertyId })

      setFieldOptions(result)
    },
    [propertyId]
  )

  useEffect(() => {
    loadFieldOptions()
  },[loadFieldOptions])

  const onError = useCallback(
    () => setLoading(false),
    []
  )

  const onSuccess = useCallback(
    ({ fieldId, growingSeasonId }) => {
      snackbar.success(
        t(currentGrowingSeason?.id ? 'your {this} was edited' : 'your {this} was registered', {
          howMany: 1,
          gender: 'male',
          this: t('crops', { howMany: 1 })
        })
      )
      handleClose({ fieldId, growingSeasonId })
    },
    [currentGrowingSeason, handleClose, snackbar, t]
  )

  const submitForms = useCallback(
    () => {
      growingSeasonFormRef.current.submit()
    },
    []
  )

  const mapCenter = useMemo(
    () => {
      const lastPlannedOptions = last(plannedOptions)
      return isPlanned
        ? (!isEmpty(lastPlannedOptions?.polygonCoordinates) ? getPolygonCenter(lastPlannedOptions?.polygonCoordinates) : undefined)
        : (!isEmpty(polygonCoordinates) ? getPolygonCenter(polygonCoordinates) : undefined)
    },
    [isPlanned, plannedOptions, polygonCoordinates]
  )
  const mapZoom = useMemo(
    () => {
      const lastPlannedOptions = last(plannedOptions)
      return isPlanned
        ? (!isEmpty(lastPlannedOptions?.polygonCoordinates) ? findMapZoom(lastPlannedOptions?.polygonCoordinates) : undefined)
        : (!isEmpty(polygonCoordinates) ? findMapZoom(polygonCoordinates) : undefined)
    },
    [isPlanned, plannedOptions, polygonCoordinates]
  )

  useEffect(() => {
    if(isPlanned) {
      const foundFields = map(filter(optionsByFields, ({ value }) => includes(plannedFields, value)), item => item.value)
      if(!isEmpty(foundFields)) {
        setSelectedPolygons(foundFields)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[plannedFields])

  return (
    <SplitedScreenLayout
      title={ { name: t('field', { howMany: 2 }) } }
      divRightStyle={ { padding: 0 } }
      leftChildren={ (
        <Container>
          <Header>
            <IconContainer>
              <Icon icon={ smallPlant } size={ 18 } />
            </IconContainer>

            <Title style={ { fontSize: 18, fontWeight: 600 } }>
              <I18n>crops register</I18n>
            </Title>

          </Header>

          <PartialLoading open={ loading } >
            <GrowingSeasonForm
              ref={ growingSeasonFormRef }
              onSuccess={ onSuccess }
              onSubmit={ () => setLoading(true) }
              onError={ onError }
              withoutSubmitButton
              childrenPolygonId={ childrenPolygonId }
              currentGrowingSeason={ currentGrowingSeason }
              isPlanned={ isPlanned }
              setPlannedFields={ setPlannedFields }
              plannedFields={ plannedFields }
              fieldOptions={ map(fieldOptions, item => ({ label: item?.fieldName, value: item?.id })) }
              optionsByFields={ optionsByFields }
              setSelectedPolygons={ setSelectedPolygons }
              selectedPolygons={ selectedPolygons }
              plannedOptions={ plannedOptions }
            />

            <ButtonsContainer style={ { paddingTop: 10 } }>
              <Button
                id="cancel-crops-form"
                onClick={ handleClose }
                style={ { flex: 1 } }
                variant="outlined"
              >
                <I18n>cancel</I18n>
              </Button>

              <div style={ { width: '10%' } } />

              <Button
                id="submit-crops-form"
                onClick={ submitForms }
                style={ { flex: 1 } }
              >
                <I18n>save</I18n>
              </Button>
            </ButtonsContainer>
          </PartialLoading>
        </Container>
      ) }
      rightChildren={ (
        <Maps
          zoom={ mapZoom }
          style={ { minHeight: 500 } }
          region={ mapCenter }
        >
          {isPlanned ? (
            map(plannedOptions, fieldItem => !isEmpty(fieldItem?.childrenPolygons) ? map(fieldItem?.childrenPolygons, item => (
              <Polygon
                onClick={ () => {
                  if(find(selectedPolygons, each => each === item.id)){
                    const currentPolygons = [...selectedPolygons]
                    setSelectedPolygons(filter(currentPolygons, each => each !== item.id))
                  } else {
                    const currentPolygons = [...selectedPolygons]
                    setSelectedPolygons([...currentPolygons, item.id])
                  }
                } }
                key={ `polygon-${ item.id }` }
                points={ item?.coordinates }
                color={ includes(selectedPolygons, item.id) ? '#fcdf03': '#fff' }
                strokeWidth={ includes(selectedPolygons, item.id) ? 7 : 2 }
              />
            )) : (
              <Polygon
                onClick={ () => {
                  if(find(selectedPolygons, each => each === fieldItem.id)){
                    const currentPolygons = [...selectedPolygons]
                    setSelectedPolygons(filter(currentPolygons, each => each !== fieldItem.id))
                  } else {
                    const currentPolygons = [...selectedPolygons]
                    setSelectedPolygons([...currentPolygons, fieldItem.id])
                  }
                } }
                key={ `polygon-${ fieldItem.id }` }
                points={ fieldItem?.polygonCoordinates }
                color={ includes(selectedPolygons, fieldItem.id) ? '#fcdf03': '#fff' }
                strokeWidth={ includes(selectedPolygons, fieldItem.id) ? 7 : 2 }
              />
            ))
          ):
            !isEmpty(childrenPolygons) ? map(childrenPolygons, item => (
              <Polygon
                key={ `polygon-${ item.id }` }
                points={ item?.coordinates }
                color={ childrenPolygonId === item.id ? '#fcdf03' : '#fff' }
              />
            )) : (
              <Polygon
                points={ polygonCoordinates }
                color={ colors.secondary }
              />
            )
          }
        </Maps>
      ) }
    />
  )}

export default GrowingSeasonRegisterScreen
