import React, {  useMemo, useCallback  } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import map from 'lodash/map'

import Typography from '@material-ui/core/Typography'


import I18n, { useT } from '@smartcoop/i18n'
import { download, organizationRounded } from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { getBankSlips } from '@smartcoop/services/apis/smartcoopApi/resources/order'
import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import { momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import { formatCpfCnpj } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import Modal from '@smartcoop/web-components/Modal'

import useStyles, { Content, Identifier, Text, CNPJ, GroupLocation } from './styles'

const BankSlipTable = ({ queryParams }) => {
  const t = useT()

  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_PURCHASE_DEMANDS_ID, fileKey: file?.fileKey })

    return downloadFromBase64(data)
  }, [])

  const columns = useMemo(() => [
    {
      title: t('archive', { howMany: 1 }),
      field: 'fileName'
    },
    {
      title: t('update / shipping'),
      field: 'updatedAt',
      render: (row) => (moment(row.updatedAt).format(momentFriendlyDateTimeFormat))
    },
    {
      title: '',
      field: 'download',
      render: (row) => {
        const file = renderFile(row)

        return (
          <div style={ { display: 'flex' } }>
            <a href={ file } download>
              <Button
                id="download-bank-splip"
                variant="outlined"
              >
                <>
                  <Icon style={ { marginRight: 6 } } size={ 16 } icon={ download }/>
                  <I18n>download</I18n>
                </>
              </Button>
            </a>
          </div>
        )
      }
    }
  ], [renderFile, t])
  return (
    <DataTable
      data={ getBankSlips }
      columns={ columns }
      queryParams ={ queryParams }
      urlParams={ queryParams }
      options={ {
        paging: false
      } }
      id="view-bank-slip-table"
    />
  )
}

BankSlipTable.propTypes = {
  queryParams: PropTypes.object.isRequired
}

const ViewBankSlipModal = ({ id, open, purchase: { organization, deliveryLocations } }) => {
  const classes = useStyles()

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n>view documents</I18n>
          <Identifier>
            <Icon icon={ organizationRounded } color={ colors.green } size={ 25 } />
            <Typography
              variant="body1"
              component="h2"
              align="left"
              style={ {
                paddingLeft: 10,
                paddingRight: 30,
                fontWeight: fonts.fontWeight.bold
              } }
            >
              { organization.companyName }
            </Typography>
            <Text><I18n>company document</I18n> {formatCpfCnpj(organization.companyDocument)}</Text>
          </Identifier>
        </>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        <Content>
          {map(deliveryLocations, location => (
            <GroupLocation key={ location.id }>
              <CNPJ><I18n>delivery location</I18n>: {formatCpfCnpj(location.organization.companyDocument)}</CNPJ>
              <BankSlipTable
                queryParams={
                  {
                    deliveryLocationId: location.id,
                    organizationPurchaseId: location.organizationPurchaseId
                  }
                }
              />
            </GroupLocation>
          ))}
        </Content>
      </>
    </Modal>
  )}

ViewBankSlipModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  purchase: PropTypes.object.isRequired
}

export default ViewBankSlipModal
