import { AVAILABLE_MODULES } from './duckModule'

export const selectCurrentModule = (state) => state.module.currentModule

export const selectOldModuleData = (state) => state.module.oldModuleData

export const selectModuleIsDigitalProperty = (state) => selectCurrentModule(state) === AVAILABLE_MODULES.digitalProperty

export const selectModuleChatList = (state) => selectCurrentModule(state) === AVAILABLE_MODULES.chatList

export const selectModuleIsCommercialization = (state) => selectCurrentModule(state) === AVAILABLE_MODULES.commercialization

export const selectModuleIsTechnical = (state) => selectCurrentModule(state) === AVAILABLE_MODULES.technical

export const selectModuleIsAdmin = (state) => selectCurrentModule(state) === AVAILABLE_MODULES.administration
