/* eslint-disable func-names */
/* eslint-disable react/prop-types */
import React, { useMemo, useRef, useState, useCallback } from 'react'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { getBulls } from '@smartcoop/services/apis/smartcoopApi/resources/herdsManagement'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import InputSearchDebounced from '@smartcoop/web-components/InputSearchDebounced'
import Modal from '@smartcoop/web-components/Modal'
import RegisterBullModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterBullModal'

const BullListModal = ({ id, open }) => {


  const tableRef = useRef()
  const t = useT()
  const { createDialog } = useDialog()

  const [debouncedFilterText, setDebouncedFilterText] = useState('')

  const onChangeSearchFilter = useCallback((value) => {
    setDebouncedFilterText(value)
  }, [])

  const reloadDataTable = useCallback(() => {
    tableRef.current.onQueryChange()
  }, [])

  const columns = useMemo(
    () => [
      {
        title: t('name'),
        field: 'name',
        sorting: true,
        defaultSort: 'asc'
      },
      {
        title: t('code'),
        sorting: true,
        field: 'code'
      },
      {
        title: t('breed'),
        sorting: true,
        field: 'breed.name'
      }
    ],
    [t]
  )

  // const handleDeleteClick = useCallback(
  //   (row) =>{
  //     createDialog({
  //       id: 'confirm-delete',
  //       Component: ConfirmModal,
  //       props: {
  //         onConfirm: () => {
  //           dispatch(AnimalActions.deleteBull(
  //             row?.id,
  //             () => {
  //               snackbar.success(
  //                 t('your {this} was deleted', {
  //                   howMany: 1,
  //                   gender: 'male',
  //                   this: t('medicine')
  //                 })
  //               )
  //               reloadDataTable()
  //             },
  //             reloadDataTable
  //           ))
  //         },
  //         textWarning: t('are you sure you want to delete the {this}?', {
  //           howMany: 1,
  //           gender: 'male',
  //           this: t('bull')
  //         })
  //       }
  //     })
  //   },
  //   [createDialog, dispatch, reloadDataTable, snackbar, t]
  // )

  const handleEditClick = useCallback(
    (_, row = null) => {
      createDialog({
        id: 'confirm-delete',
        Component: RegisterBullModal,
        props: {
          onSubmit: reloadDataTable,
          defaultValues: row
        }
      })
    },
    [createDialog, reloadDataTable]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      maxWidth="xl"
      fullWidth
      disableEsc
      title={
        <div style={ { display: 'flex', flexDirection: 'row' } }>
          <I18n>bulls</I18n>
        </div>
      }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
    >
      <div style={ { display: 'flex', flex: 1, flexDirection: 'column', flexWrap: 'wrap', padding: '30px' } }>
        <div style={ { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 } }>
          <div style={ { width: '50%' } }>
            <InputSearchDebounced onChange={ onChangeSearchFilter } />
          </div>
          <Button
            id="save"
            color="secondary"
            onClick={ handleEditClick }
            style={ { maxWidth: 100 } }
          >
            {t('register')}
          </Button>
        </div>
        <DataTable
          columns={ columns }
          data={ getBulls }
          tableRef={ tableRef }
          queryParams={ { q: debouncedFilterText } }
          deps={ { debouncedFilterText } }
          onEditClick={ handleEditClick }
          // onDeleteClick={ handleDeleteClick }
        />
      </div>
    </Modal>
  )}

BullListModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
}

BullListModal.defaultProps = {
}

export default BullListModal
