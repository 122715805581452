import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'


export class SyncHistoricModel extends Model {
  static table = 'sync_historic'

  @field('table')
  table: string

  @field('date')
  date: number

  @field('percentage')
  percentage: number
}
