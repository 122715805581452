import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  getProductsQuotationByOrg
} from '@smartcoop/services/apis/smartcoopApi/resources/productsQuotation'

import { selectCurrentOrganization } from '../organization/selectorOrganization'
import { ProductsQuotationActions, ProductsQuotationTypes } from './duckProductsQuotation'

function* loadProductsQuotation({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)

    const { data: { data: quotation, ...pagination } } = yield call(getProductsQuotationByOrg, {
      limit: 10,
      ...params
    }, { organizationId: currentOrganization.id })

    yield put(ProductsQuotationActions.loadProductsQuotationSuccess(
      quotation,
      pagination.page,
      () => onSuccess(pagination)
    ))
  } catch (error) {
    yield put(ProductsQuotationActions.productsQuotationError(error))
    yield call(onError, error)
  }
}

function* loadProductsQuotationSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}


export default [
  takeLatest(ProductsQuotationTypes.LOAD_PRODUCTS_QUOTATION, loadProductsQuotation),
  takeLatest(ProductsQuotationTypes.LOAD_PRODUCTS_QUOTATION_SUCCESS, loadProductsQuotationSuccess)
]
