import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'

import {
  chat
} from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'


const ChatFabButton = ({ onNavigate }) => {
  const { routes } = useRoutes()
  const history = useHistory()

  const goToChat = useCallback(
    () => {
      history.push(routes.chatList.path)
      onNavigate()
    },
    [history, onNavigate, routes]
  )

  return (
    <IconButton
      color="inherit"
      edge="start"
      onClick={ goToChat }
    >
      <Icon
        icon={ chat }
        color={ colors.white }
        size={ 22 }
      />
    </IconButton>
  )
}

ChatFabButton.propTypes = {
  onNavigate: PropTypes.func.isRequired
}

export default ChatFabButton
