import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import map from 'lodash/map'

import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { ChatActions } from '@smartcoop/stores/chat'
import { AllowedAudioTypesExtensions, AllowedDocumentTypesExtensions, AllowedImageTypesExtensions, AllowedVideoTypesExtensions } from '@smartcoop/utils/constants'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'
import AdvertisingForm from '@smartcoop/web-containers/forms/administration/AdvertisingForm'

import useStyles, { Row, Content, ButtonsContainer  } from './styles'

const AdvertisingRegisterModal = (props) => {
  const classes = useStyles()
  const fiscalRegisterFormRef = useRef(null)
  const dispatch = useDispatch()
  const t = useT()

  const { id, open, handleClose, name, defaultValues, reloadDataTable, readOnly } = props

  const snackbar = useSnackbar()

  const [disabledSubmit, setDisabledSubmit] = useState(readOnly)
  const [loading, setLoading] = useState(false)

  const handleSubmit = (data, advertisingFiles, defaultValuesFiles = []) => {
    setLoading(true)
    const defaultVideo = []
    const defaultAudio = []
    const defaultImage = []
    const defaultDocument = []

    map(defaultValuesFiles, item => {
      const extension = `.${ last(item?.url.split('.')) }`

      if(includes(AllowedVideoTypesExtensions, extension)) {
        defaultVideo.push({ url: item?.url })
      }
      if(includes(AllowedAudioTypesExtensions, extension)) {
        defaultAudio.push({ url: item?.url })
      }
      if(includes(AllowedImageTypesExtensions, extension)) {
        defaultImage.push({ url: item?.url })
      }
      if(includes(AllowedDocumentTypesExtensions, extension)) {
        defaultDocument.push({ url: item?.url })
      }
    })

    if(!isEmpty(advertisingFiles)) {
      const formData = new FormData()
      map(advertisingFiles, item => {
        formData.append('uploads', item)
      })

      dispatch(ChatActions.createAdvertisingFiles(formData, (response) => {
        const video = []
        const audio = []
        const image = []
        const document = []
        map(response, item => {
          const extension = `.${ last(item?.fileName.split('.')) }`

          if(includes(AllowedVideoTypesExtensions, extension)) {
            video.push({ url: item?.fileUrl })
          }
          if(includes(AllowedAudioTypesExtensions, extension)) {
            audio.push({ url: item?.fileUrl })
          }
          if(includes(AllowedImageTypesExtensions, extension)) {
            image.push({ url: item?.fileUrl })
          }
          if(includes(AllowedDocumentTypesExtensions, extension)) {
            document.push({ url: item?.fileUrl })
          }
        })

        const content = {
          message: data?.content?.message,
          audio: [...defaultAudio, ...audio],
          video: [...defaultVideo, ...video],
          image: [...defaultImage, ...image],
          document: [...defaultDocument, ...document]
        }

        dispatch(ChatActions.createAdvertising({ ...data, content }, () => {
          setLoading(false)
          snackbar.success(defaultValues?.id ?
            t('your {this} was edited',
              {
                howMany: 1,
                gender: 'female',
                this: t('advertising')
              }
            )
            : t('your {this} was registered', {
              howMany: 1,
              gender: 'female',
              this: t('advertising')
            })
          )
          reloadDataTable()
          handleClose()
        }, () => {
          setLoading(false)
        }))
      }))
    } else {
      const content = {
        message: data?.content?.message,
        audio: [...defaultAudio],
        video: [...defaultVideo],
        image: [...defaultImage],
        document: [...defaultDocument]
      }

      dispatch(ChatActions.createAdvertising({ ...data, content }, () => {
        setLoading(false)
        snackbar.success(defaultValues?.id ?
          t('your {this} was edited',
            {
              howMany: 1,
              gender: 'female',
              this: t('advertising')
            }
          )
          : t('your {this} was registered', {
            howMany: 1,
            gender: 'female',
            this: t('advertising')
          })
        )
        reloadDataTable()
        handleClose()
      }, () => {
        setLoading(false)
      }))
    }
  }

  return (
    <Modal
      id={ id }
      open={ open }
      onClose={ handleClose }
      title={
        <Row>
          <I18n>{name}</I18n>
        </Row>
      }
      disableFullScreen
      disableEsc
      maxWidth='md'
      fullWidth
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      loading={ loading }
    >
      <Content>
        <AdvertisingForm
          ref={ fiscalRegisterFormRef }
          onSubmit={ handleSubmit }
          defaultValues={ defaultValues }
          setDisabledSubmit={ setDisabledSubmit }
          readOnly={ readOnly }
        />
        <ButtonsContainer>
          <Button
            id="submit-fiscal-register-form"
            onClick={ () => fiscalRegisterFormRef.current.submit() }
            style={ { flex: 1 } }
            disabled={ disabledSubmit }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Content>
    </Modal>
  )
}

AdvertisingRegisterModal.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool,
  readOnly: PropTypes.bool,
  defaultValues: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  reloadDataTable: PropTypes.func.isRequired
}

AdvertisingRegisterModal.defaultProps = {
  open: false,
  readOnly: false,
  defaultValues: null
}

export default AdvertisingRegisterModal
