import React, { useState, useRef, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'

import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { filter, arrowLeft } from '@smartcoop/icons'
import { getPriceData } from '@smartcoop/services/apis/smartcoopApi/resources/dairyFarm'
import { useSnackbar } from '@smartcoop/snackbar'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite, selectUserHasCCGL } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import { momentBackDateFormat, momentFriendlyMonthYearFormat } from '@smartcoop/utils/dates'
import { formatCurrency } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import Loader from '@smartcoop/web-components/Loader'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import CompositionModal from '@smartcoop/web-containers/modals/dairyFarm/CompositionModal'
import FilterPriceDataModal from '@smartcoop/web-containers/modals/dairyFarm/FilterPriceDataModal'
import RegisterPriceDataModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterPriceDataModal'

import { Top, Container, Row, Title, WarningDiv } from './styles'

const ListPriceDataScreen = () => {
  const dispatch = useCallback(useDispatch(), [])
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()
  const tableRef = useRef(null)
  const history = useHistory()
  const t = useT()

  const currentProperty = useSelector(selectCurrentProperty)
  const userWrite = useSelector(selectUserCanWrite)
  const userHasCCGL = useSelector(selectUserHasCCGL)
  const moduleIsTechnical = useSelector(selectModuleIsTechnical)

  const [filters, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const dairyFarm = useMemo(
    () => currentProperty?.dairyFarm[0],
    [currentProperty]
  )

  const urlParams = useMemo(
    () => ({ dairyFarmId: dairyFarm?.id }),
    [dairyFarm]
  )

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )

  const handleEditClick = useCallback(
    (_, data) => {
      createDialog({
        id: 'edit-price-data',
        Component: RegisterPriceDataModal,
        props: {
          priceData: data,
          isEditing: true,
          reloadDataTable
        }
      })
    },
    [createDialog, reloadDataTable]
  )

  const handleDeleteClick = useCallback(
    (_, data) =>{
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            setIsLoading(true)
            dispatch(DairyFarmActions.deletePriceData(
              data.id,
              () => {
                setIsLoading(false)
                reloadDataTable()
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 2,
                    gender: 'male',
                    this: t('price data')
                  })
                )
              },
              () => {
                setIsLoading(false)
                reloadDataTable()
              }
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 2,
            gender: 'male',
            this: t('price data')
          })
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, snackbar, t]
  )

  const isCooperative = useCallback(
    (row) => !isEmpty(row.organizationId),
    []
  )

  const openCompositionData = useCallback(
    (stateRegistration, anoMes) => {
      createDialog({
        id: 'composition-data',
        Component: CompositionModal,
        props: {
          stateRegistration,
          anoMes
        }
      })
    },
    [createDialog]
  )

  const columns = useMemo(
    () => [
      {
        title: `${ t('month', { howMany: 1 }) }/${ t('year', { howMany: 1 }) }`,
        field: 'priceDate',
        sorting: true,
        defaultSort: 'desc',
        render: (row) => moment(row.priceDate, momentBackDateFormat).format(momentFriendlyMonthYearFormat)
      },
      {
        title: t('price', { howMany: 1 }),
        field: 'price',
        sorting: true,
        render: (row) => formatCurrency(row.price, 4)
      },
      {
        title: t('delivery', { howMany: 1 }),
        field: 'organizationId',
        render: (row) => isCooperative(row) ? row.organization.tradeName : row.companyName
      },
      {
        title: t('state registration'),
        field: 'userStateRegistration.stateRegistration',
        render: (row) => row?.userStateRegistration?.stateRegistration ?? '-'
      },
      {
        title: '',
        field: '',
        render: (row) => userHasCCGL && !moduleIsTechnical ?
          (
            <Button
              id="new-price-data"
              color="white"
              style={ { flex: 'none', whiteSpace: 'nowrap' } }
              onClick={ () => openCompositionData(row?.userStateRegistration?.stateRegistration, moment(row.priceDate, momentBackDateFormat).format('YYYYMM')) }
            >
              <I18n>demonstrative</I18n>
            </Button>
          ) : null
      }
    ], [isCooperative, moduleIsTechnical, openCompositionData, t, userHasCCGL]
  )

  const handleFilter = useCallback(
    (values) => setFilters(values),
    []
  )

  const filterData = useCallback(
    () => {
      createDialog({
        id: 'filter-price-data',
        Component: FilterPriceDataModal,
        props: {
          onSubmit: handleFilter,
          filters
        }
      })
    },
    [createDialog, filters, handleFilter]
  )

  const registerPriceData = useCallback(
    () => {
      createDialog({
        id: 'register-price-data',
        Component: RegisterPriceDataModal,
        props: {
          reloadDataTable,
          dairyFarm
        }
      })
    },
    [createDialog, dairyFarm, reloadDataTable]
  )



  return (
    isLoading ? <Loader width={ 100 } /> :
      <Container>
        <Top>
          <IconButton
            tooltip={ t('go back') }
            onClick={ () => history.goBack() }
          >
            <Icon icon={ arrowLeft } size={ 16 } />
          </IconButton>
          <Title>
            <I18n>price data</I18n>
          </Title>
        </Top>
        <Row>
          { (isEmpty(dairyFarm) || isCooperative(dairyFarm)) && <WarningDiv>
            <I18n>data inclusion will be done by the organization</I18n>
          </WarningDiv>}
          <Button
            id="filter"
            color="white"
            style={ {
              flex: 'none',
              whiteSpace: 'nowrap',
              backgroundColor: isEmpty(filters) ? colors.white : colors.secondary
            } }
            onClick={ filterData }
            disabled={ isEmpty(dairyFarm) }
          >
            <>
              <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 }/>
              <I18n>filtrate</I18n>
            </>
          </Button>
          <Button
            id="new-price-data"
            color="secondary"
            style={ { flex: 'none', whiteSpace: 'nowrap', marginLeft: 16 } }
            onClick={ registerPriceData }
            disabled={ isEmpty(dairyFarm) || isCooperative(dairyFarm) || !userWrite }
          >
            <I18n>register</I18n>
          </Button>
        </Row>

        {!isEmpty(dairyFarm) &&
          <DataTable
            tableRef={ tableRef }
            data={ getPriceData }
            queryParams={ {
              ...filters,
              from: filters.from && filters.from[0],
              to: filters.to && filters.to[0]
            } }
            urlParams={  urlParams }
            columns={ columns }
            onEditClick={ handleEditClick }
            onDeleteClick={ handleDeleteClick }
            disabledEdit={ (row) => isCooperative(row) || !userWrite }
            conditionToDelete={ (row) => !isCooperative(row) && userWrite }
            id="list-price-data-table"
          />
        }
      </Container>
  )
}

export default ListPriceDataScreen
