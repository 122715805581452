import React, { useCallback, forwardRef, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { getTechnicianGroups } from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import { useSnackbar } from '@smartcoop/snackbar'
import { TechnicalActions } from '@smartcoop/stores/technical'
import DataTable from '@smartcoop/web-components/DataTable'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import TechnicianGroupsModal from '@smartcoop/web-containers/modals/technical/TechnicianGroupsModal'

import { Container } from './styles'

const TechnicianGroupsList = forwardRef((props, tableRef) => {
  const t = useT()
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()
  const dispatch = useDispatch()

  const columns = useMemo(
    () => [
      {
        title: t('name'),
        field: 'name',
        sorting: true
      },
      {
        title: t('user', { howMany: 2 }),
        field: 'producersGroups',
        sorting: true,
        render: row => row?.producersGroups.length
      }
    ], [t]
  )

  const deleteGroup = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete-group',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(
              TechnicalActions.deleteTechnicianGroups(
                row?.id,
                () => {
                  snackbar.success(
                    t('your group was deleted')
                  )
                  tableRef.current.onQueryChange()
                }
              )
            )
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('group')
          })
        }
      })
    },
    [createDialog, dispatch, snackbar, t, tableRef]
  )

  const openGroupModal = useCallback(
    (_, row) => {
      createDialog({
        id: 'edit-group',
        Component: TechnicianGroupsModal,
        props: {
          defaultValues: { ...row, usersIds: map(row?.producersGroups, item => item?.proprietaryId) },
          onClose: () => tableRef.current.onQueryChange()
        }
      })
    },
    [createDialog, tableRef]
  )

  return (
    <Container>
      <DataTable
        tableRef={ tableRef }
        columns={ columns }
        data={ getTechnicianGroups }
        id="groups-list-table"
        onDeleteClick={ deleteGroup }
        conditionToDelete={ (row) => isEmpty(row?.producersGroups) }
        onRowClick={ (_, row) =>  openGroupModal(_, row) }
      />
    </Container>
  )
})

export default TechnicianGroupsList
