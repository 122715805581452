import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  animalBirths: [],
  currentAnimalBirth: {},
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadAnimalBirths: ['params', 'onSuccess', 'onError'],
  loadAnimalBirthsSuccess: ['params', 'page', 'onSuccess'],

  saveAnimalBirth: ['params', 'onSuccess', 'onError', 'isEdit', 'isConnected'],
  saveOfflineAnimalBirth: ['params', 'onSuccess', 'onError', 'isConnected', 'web'],
  deleteAnimalBirth: ['animalBirthId', 'onSuccess', 'onError'],
  deleteOfflineAnimalBirth: ['animalBirthId', 'onSuccess', 'onError'],


  setCurrentAnimalBirth: ['currentAnimalBirth'],
  resetCurrentAnimalBirth: [],

  animalBirthError: ['error']
})

markActionsOffline(Creators, [
  'saveAnimalBirth',
  'deleteAnimalBirth'
])

/**
 * Reducers functions
 */

const loadAnimalBirthsSuccess = (state = INITIAL_STATE, { params, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  animalBirths: page === 1 ? params : [
    ...state.animalBirths,
    ...params
  ]
})

const animalBirthError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})


const setCurrentAnimalBirth = (state = INITIAL_STATE, { currentAnimalBirth }) => ({
  ...state,
  currentAnimalBirth
})

const resetcurrentAnimalBirth = (state = INITIAL_STATE) => ({
  ...state,
  currentAnimalBirth: INITIAL_STATE.currentAnimalBirth
})


const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */

export default createReducer(INITIAL_STATE, {
  [Types.LOAD_ANIMAL_BIRTHS_SUCCESS]: loadAnimalBirthsSuccess,

  [Types.ANIMAL_BIRTH_ERROR]: animalBirthError,

  [Types.SET_CURRENT_ANIMAL_BIRTH]: setCurrentAnimalBirth,
  [Types.RESET_CURRENT_ANIMAL_BIRTH]: resetcurrentAnimalBirth,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as AnimalBirthTypes,
  Creators as AnimalBirthActions
}
