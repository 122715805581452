import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

const filterTechnicalPortfolio = () => Yup.object().shape({

  name: flow(
  )(Yup.string()),

  registry: flow(
  )(Yup.string()),

  cellPhone: flow(
  )(Yup.string()),

  email: flow(
  )(Yup.string()),

  document: flow(
  )(Yup.string())


})

export default filterTechnicalPortfolio
