import axios from 'axios'

import split from 'lodash/split'
import startsWith from 'lodash/startsWith'

import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { selectAccessToken, selectAuthenticated, selectRefreshToken } from '@smartcoop/stores/authentication/selectorAuthentication'

import smartcoopApi from './api'
import { refreshToken as refreshTokenService } from './resources/authentication'

export const createAccessTokenSubscriber = store => {
  /*
    Add header Authorization when store contains accessToken
    or remove when it not contains accessToken
  */
  store.subscribe(() => {
    const accessToken = selectAccessToken(store.getState())
    if (!accessToken) {
      if (smartcoopApi.defaults.headers.common.Authorization) {
        const { Authorization, ...common } = smartcoopApi.defaults.headers.common || {}
        smartcoopApi.defaults.headers.common = common
      }
    } else if (accessToken !== smartcoopApi.defaults.headers.common.Authorization) {
      smartcoopApi.defaults.headers.common.Authorization = accessToken
    }
  })
}

export const createRefreshTokenInterceptor = store => {
  let isAlreadyFetchingAccessToken = false
  let subscribers = []

  smartcoopApi.interceptors.response.use(
    response => response,
    async (error) => {
      const status = error?.response?.status
      const config = error?.response?.config
      // if can do refresh token
      if (
        selectAuthenticated(store.getState())
        && status === 401
        && !startsWith(config.url, '/v1/auth/refresh-token')
      ) {
        try {
          const oldRefreshToken = await  selectRefreshToken(store.getState())
          if (!oldRefreshToken) {
            // We can't refresh, throw the error anyway
            throw error
          }

          /* Proceed to the token refresh procedure
          We create a new Promise that will retry the request,
          clone all the request configuration from the failed
          request in the error object. */
          const retryOriginalRequest = new Promise(resolve => {
          /* We need to add the request retry to the queue
          since there another request that already attempt to
          refresh the token */
            subscribers.push(accessToken => {
              config.headers.Authorization = accessToken
              resolve(axios(config))
            })
          })

          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true

            const oldAccessToken = await  selectAccessToken(store.getState())
            const refreshTokenResponse = await refreshTokenService({
              accessToken: split(oldAccessToken, ' ')[1],
              refreshToken: oldRefreshToken
            })

            if (!refreshTokenResponse) {
              throw error
            }

            const { accessToken, refreshToken, tokenType, iaToken } = refreshTokenResponse
            const newAccessToken = `${ tokenType } ${ accessToken }`
            store.dispatch(
              AuthenticationActions.refreshTokenSuccess(
                newAccessToken,
                refreshToken,
                iaToken
              )
            )

            isAlreadyFetchingAccessToken = false
            // When the refresh is successful, we start retrying the requests one by one and empty the queue
            subscribers.forEach(callback => callback(newAccessToken))
            subscribers = []
          }
          return retryOriginalRequest
        } catch (err) {
          subscribers = []
          store.dispatch(AuthenticationActions.logout())
          return Promise.reject(new Error('Access denied! Please try to login again!'))
        }
      }

      // If the error is due to other reasons, we just throw it back to axios
      return Promise.reject(error)
    }
  )
}
