import * as Yup from 'yup'

const filterInsemination = () => Yup.object().shape({
  date: Yup.string(),
  inseminator: Yup.string(),
  bullName: Yup.string(),
  bullCode: Yup.string()
})

export default filterInsemination
