import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'


import I18n from '@smartcoop/i18n'
import { TechnicalActions } from '@smartcoop/stores/technical'
import { selectCurrentForm } from '@smartcoop/stores/technical/selectorTechnical'
import Modal from '@smartcoop/web-components/Modal'

import TechnicalEvaluationFormScreen from '../../../screens/authenticated/technical/TechnicalEvaluationFormScreen/TechnicalEvaluationFormScreen'
import TechnicalFormsListScreen from '../../../screens/authenticated/technical/TechnicalFormsListScreen/TechnicalFormsListScreen'
import useStyles, { Row, Content } from './styles'

const RegisterTechnicalEvaluationModal = ({ id, open, handleClose, onSubmit, defaultForm, defaultProducer, defaultValues }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const currentForm = useSelector(selectCurrentForm)

  const [producer, setProducer] = useState(defaultProducer)

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row>
          <I18n>{defaultValues?.id ? 'update evaluation' : 'new evaluation'}</I18n>
        </Row>
      }
      disableEsc
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 350 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      disableFullScreen
      maxWidth="md"
      fullWidth
      onClose={ () => {
        dispatch(TechnicalActions.resetCurrentTechnicalForm())
        dispatch(TechnicalActions.loadTechnicalEvaluationAnswers())
      } }
    >
      <>
        <Content>
          <TechnicalFormsListScreen
            setProducer={ setProducer }
            producer={ producer }
            currentForm={ currentForm }
            defaultForm={ defaultForm }
            defaultProducer={ defaultProducer }
          />
          {(currentForm?.proprietaryRequired ? !!producer : true) && currentForm ?
            <TechnicalEvaluationFormScreen
              setProducer={ setProducer }
              producer={ producer }
              currentForm={ currentForm }
              modalHandleClose={ handleClose }
              onSubmit={ onSubmit }
              defaultValues={ defaultValues }
            />
            : null}
        </Content>
      </>
    </Modal>
  )}

RegisterTechnicalEvaluationModal.propTypes = {
  id: PropTypes.string.isRequired,
  defaultForm: PropTypes.string,
  defaultProducer: PropTypes.object,
  defaultValues: PropTypes.object,
  handleClose: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired
}

RegisterTechnicalEvaluationModal.defaultProps = {
  onSubmit: null,
  defaultForm: null,
  defaultValues: null,
  defaultProducer: null
}

export default RegisterTechnicalEvaluationModal
