import React, { useCallback, useMemo, useState }from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import Backdrop from '@material-ui/core/Backdrop'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { edit, organizationRounded, trash } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { ProductWallActions } from '@smartcoop/stores/productWall'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import { momentBackDateTimeFormat, momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import Avatar from '@smartcoop/web-components/Avatar'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import VerticalDotsIconButton from '@smartcoop/web-components/IconButton/VerticalDotsIconButton'
import { CircularLoader } from '@smartcoop/web-components/Loader'
import Popover from '@smartcoop/web-components/Popover'
import ReadMore from '@smartcoop/web-components/ReadMore'
import EditCommentModal from '@smartcoop/web-containers/modals/shoppingPlatform/ProductWall/EditCommentModal'

import useStyles, {
  Body,
  Col,
  Container,
  Header,
  Identifier,
  Name,
  Text,
  Timer,
  FlexDiv,
  ButtonContainer,
  Privacy,
  Type } from './styles'

const ProductWallComment = (props) =>  {
  const { style, comment, length, getComments } = props
  const { createdAt, text, user, organization } = comment

  const t = useT()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])

  const loggedUser = useSelector(selectUser)

  const [loading, setLoading] = useState(false)

  const editDeletePermission = useMemo( () => {
    const test = user?.id === loggedUser?.id
    return !!test
  }, [loggedUser.id, user.id])

  const handleEdit = useCallback(() => {
    createDialog({
      id: 'edit-comment',
      Component: EditCommentModal,
      props: {
        comment,
        getComments
      }
    })
  }, [comment, createDialog, getComments])

  const deleteCommentSuccess = useCallback(() => {
    setLoading(false)
    snackbar.success(t('social comment delete success'))
    getComments()
  },[getComments, snackbar, t])

  const handleDelete = useCallback(() => {
    setLoading(true)
    dispatch(ProductWallActions.deleteProductWallComment({ commentId: comment.id }, deleteCommentSuccess, () => {}))
  },[comment.id, deleteCommentSuccess, dispatch])

  const time = useMemo( () => moment(createdAt, momentBackDateTimeFormat).format(momentFriendlyDateTimeFormat), [createdAt])
  return (
    <Container style={ style } >
      <Header>
        <Identifier>
          <Avatar alt={ user.name } src={ user.image } />
          <Col>
            <Name>{user.name}</Name>
            <Privacy>
              <Icon icon={ organizationRounded } />
              <Type>{organization.tradeName}</Type>
            </Privacy>
          </Col>
        </Identifier>
        <FlexDiv>
          <Timer>{time}</Timer>
          { editDeletePermission &&
          <Popover
            popoverId="comment-actions"
            Component={ VerticalDotsIconButton }
            popoverStyle={ { zIndex: 1200 } }
            ComponentProps={ {
              color: colors.black,
              edge: 'end',
              iconColor: colors.black,
              style: { padding: '0 5px' }
            } }
          >
            <ButtonContainer>
              <Button onClick={ handleEdit } color={ colors.white } id="edit-property">
                <>
                  <Icon style={ { marginRight: 10 } } icon={ edit } size={ 15 } color={ colors.primary } />
                  <I18n>social comment edit</I18n>
                </>
              </Button>
              <Button onClick={ handleDelete } color={ colors.white } id="delete-property">
                <>
                  <Icon style={ { marginRight: 10 } } icon={ trash } size={ 15 } color={ colors.red } />
                  <I18n style={ { color: colors.red } }>social comment delete</I18n>
                </>
              </Button>
            </ButtonContainer>
          </Popover>}
        </FlexDiv>
      </Header>
      <Body>
        <Text>
          <ReadMore length={ length }>
            { text }
          </ReadMore>
        </Text>
      </Body>
      <Backdrop open={ loading } className={ classes.backdrop }>
        <CircularLoader />
      </Backdrop>
    </Container>
  )
}

ProductWallComment.propTypes = {
  style: PropTypes.object,
  comment: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    userId: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      image: PropTypes.string
    }),
    organization: PropTypes.shape({
      companyName: PropTypes.string,
      id: PropTypes.string,
      tradeName: PropTypes.string
    })
  }).isRequired,
  originalPost: PropTypes.shape({
    interestAreas: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      categoryId: PropTypes.string,
      category: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      }),
      color: PropTypes.string
    })),
    id: PropTypes.string,
    text: PropTypes.string,
    userId: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      image: PropTypes.string
    })
  }).isRequired,
  length: PropTypes.number,
  getComments: PropTypes.func.isRequired
}

ProductWallComment.defaultProps = {
  style: {},
  length: 200
}

export default ProductWallComment
