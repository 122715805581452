import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import required from '@smartcoop/forms/validators/required.validator'


const managementSchemaRegister = ({ t, props }) => Yup.object().shape({
  predictedDate: flow(
    date({ t }),
    required({ t })
  )(Yup.string()),

  realizationDate: props.closed
    ? flow(
      date({ t }),
      required({ t })
    )(Yup.string())
    : Yup.string(),

  operationId: flow(
    required({ t })
  )(Yup.string()),

  observations: Yup.string()
})

export default managementSchemaRegister
