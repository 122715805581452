import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import InputAdornmentMui from '@material-ui/core/InputAdornment'

import colors from '@smartcoop/styles/colors'
import InputFloat from '@smartcoop/web-components/InputFloat'
import InputNumber from '@smartcoop/web-components/InputNumber'

const InputUnit = (props) => {
  const { type, unit, ...rest } = props

  const InputType = useMemo(
    () => type === 'integer' ? InputNumber : InputFloat,
    [type]
  )

  return (
    <InputType
      { ...rest }
      InputProps={ unit && {
        endAdornment:
          <InputAdornmentMui
            style={ {
              backgroundColor: colors.backgroundHtml,
              height: 40,
              maxHeight: 'none',
              borderRadius: '0 4px 4px 0',
              padding: '0 10px'
            } }
            position="end"
          >
            { unit }
          </InputAdornmentMui>
      } }
    />
  )
}

InputUnit.propTypes = {
  unit: PropTypes.string,
  type: PropTypes.string
}

InputUnit.defaultProps = {
  unit: null,
  type: 'float'
}

export default InputUnit
