import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 25px;
  align-items: center;
  background-color: ${ colors.lightGrey };
  padding: 5px 15px;
  height: 52px;
`

export const HeaderTitle = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${ colors.text };
`
