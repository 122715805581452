// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
      <g>
        <path fill="#D3D3D3" d="M175.4,2.8c-2.1-2.1-5.4-2.3-7.8-0.7l0,0L77.7,53.9c-1.3,0.6-2.5,1.3-3.8,2c-24.3,14.4-32.4,45.8-18,70.1
          c3.9,6.6,9.1,12,15,16.1l-46,46l-0.4,0.4h0c-2.4,2.4-2.4,6.3,0,8.7c2.1,2.1,5.4,2.3,7.8,0.7l0,0l89.9-51.9c1.3-0.6,2.5-1.3,3.8-2
          c24.3-14.4,32.4-45.8,18-70.1c-3.9-6.6-9.1-12-15-16.1l46-46l0.4-0.4h0C177.8,9.1,177.8,5.2,175.4,2.8z M109.1,115.5
          c-8.5,5-19.5,2.2-24.6-6.3c-5-8.5-2.2-19.5,6.3-24.6c8.5-5.1,19.5-2.2,24.6,6.3C120.5,99.4,117.7,110.4,109.1,115.5z"/>
      </g>
    </g>
  </svg>
`
