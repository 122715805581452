import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  lots: [],
  lotsOptions: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadLots: ['params', 'onSuccess', 'onError'],
  loadLotsSuccess: [
    'params',
    'page',
    'lotsOptions',
    'onSuccess'
  ],

  saveLot: ['params', 'onSuccess', 'onError'],
  deleteLot: ['lotId', 'onSuccess', 'onError'],

  lotError: ['error']
})

markActionsOffline(Creators, ['saveLots', 'deleteLots'])

/**
 * Reducers functions
 */
const loadLotsSuccess = (
  state = INITIAL_STATE,
  { params, page, lotsOptions }
) => ({
  ...state,
  error: INITIAL_STATE.error,
  lots: page === 1 ? params : [...state.lots, ...params],
  lotsOptions
})
const lotError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_LOTS_SUCCESS]: loadLotsSuccess,

  [Types.LOT_ERROR]: lotError,

  [AuthenticationTypes.LOGOUT]: logout
})

export { Types as LotTypes, Creators as LotActions }
