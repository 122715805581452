import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import maxNumberValue from '@smartcoop/forms/validators/maxNumberValue.validator'
import minNumberValue from '@smartcoop/forms/validators/minNumberValue.validator'
import required from '@smartcoop/forms/validators/required.validator'

const field = ({ t }) => Yup.object().shape({
  fieldName: flow(
    required({ t })
  )(Yup.string()),

  area: flow(
    maxNumberValue({ t, name: t('area'), customSizeMessage: '5.000 ha', max: '5000.00' }),
    minNumberValue({ t, field: t('area') }),
    required({ t })
  )(Yup.string()),

  fieldMode: flow(
    required({ t, isNotText: true })
  )(Yup.string().nullable()),

  irrigated: flow(
    required({ t, isNotText: true })
  )(Yup.string().nullable())
})

export default field
