import React, { useRef, useEffect, useCallback, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { omit } from 'lodash'
import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { UserActions } from '@smartcoop/stores/user'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Button from '@smartcoop/web-components/Button'
import ResetCodeForm from '@smartcoop/web-containers/forms/auth/ResetCodeForm'
import { withLayout } from '@smartcoop/web-containers/layouts'
import { Item, Header, Title, Text, ButtonsContainer, TextUnderlined } from '@smartcoop/web-containers/layouts/GuestLayout/theme'
import LoadingModal from '@smartcoop/web-containers/modals/LoadingModal'
import { useRoutes } from '@smartcoop/web-containers/routes/guest'

const ResetCodeScreen = () => {
  const formRef = useRef(null)
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()

  const user = useSelector(selectUser)
  const t = useT()
  const history = useHistory()
  const { routes } = useRoutes()
  const { state: { mode } } = useLocation()

  const { createDialog, removeDialog } = useDialog()

  const handleSubmit = useCallback(
    () => {
      if (mode === 'signUp') {
        createDialog({
          id: 'loading',
          Component: LoadingModal
        })

        const removeLoader = () => {
          setTimeout(() => removeDialog({ id: 'loading' }), 150)
        }

        const onSuccess = () => {
          removeLoader()
          history.push(routes.createPassword.path, { mode })
        }

        dispatch(UserActions.saveInitialUser(
          omit(user, ['email', 'cellPhone']),
          onSuccess,
          removeLoader
        ))
      } else {
        history.push(routes.createPassword.path, { mode })
      }
    },
    [createDialog, dispatch, history, mode, removeDialog, routes.createPassword.path, user]
  )

  const sentCodeByEmail = useCallback(
    () => {
      dispatch(AuthenticationActions.requestVerificationCode('email',
        () => {snackbar.success(t('the code was sent to your email'))},
        () => {snackbar.error(t('could not send the code to your email'))}
      ))
    },
    [dispatch, snackbar, t]
  )

  useEffect(() => {
    dispatch(AuthenticationActions.requestVerificationCode('sms'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(
    () => {
      if (isEmpty(user)) {
        history.replace(routes.login.path)
      }
    },
    [history, routes.login.path, user]
  )

  return (
    <>
      <Item>
        <Header>
          <Title style={ { alignSelf: 'flex-end' } }>
            <I18n>all right</I18n>
          </Title>
        </Header>

        <Text>
          <I18n>we sent a password reset code via SMS to the final cell phone</I18n>
          <strong> { user?.cellPhone?.substr(-4)}</strong>.
        </Text>

        <Text>
          <I18n>enter it below</I18n>
        </Text>

        <ResetCodeForm
          ref={ formRef }
          onSubmit={ handleSubmit }
          withoutSubmitButton
        />
      </Item>

      <Item>
        {
          !isEmpty(user?.email) &&
          <TextUnderlined
            onClick={ sentCodeByEmail }
            style={ { marginBottom: 10 } }
          >
            <I18n>send code by mail</I18n>
          </TextUnderlined>
        }

        <ButtonsContainer>
          <Button
            id="go-back"
            onClick={ () => history.goBack() }
            style={ { width: '48%', flex: '0 0 auto' } }
            variant="outlined"
          >
            <I18n>go back</I18n>
          </Button>
          <Button
            id="reset-code-submit"
            onClick={ () => formRef.current.submit() }
            style={ { width: '48%', flex: '0 0 auto' } }
          >
            <I18n>next</I18n>
          </Button>
        </ButtonsContainer>
      </Item>
    </>
  )
}

export default memo(withLayout('guest')(ResetCodeScreen))
