import { call, put, takeLatest } from 'redux-saga/effects'

import {
  saveCosts as postCosts,
  editCosts as patchCosts,
  deleteCosts as removeCosts
} from '@smartcoop/services/apis/smartcoopApi/resources/costs'

import { CostsActions, CostsTypes } from './duckCosts'


function* saveCosts({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    if(params?.bulkId) {
      yield call(patchCosts, params, { costId: params?.bulkId })
    } else {
      yield call(postCosts, params)
    }

    yield call(onSuccess)
  } catch (error) {
    yield put(CostsActions.costsError(error))
    yield call(onError, error)
  }
}

function* deleteCosts({ costId = null, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(removeCosts, costId)

    yield call(onSuccess)
  } catch (error) {
    yield put(CostsActions.costsError(error))
    yield call(onError, error)
  }
}


export default [
  takeLatest(CostsTypes.SAVE_COSTS, saveCosts),
  takeLatest(CostsTypes.DELETE_COSTS, deleteCosts)
]
