import React from 'react'

import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import withWidth from '@material-ui/core/withWidth'

import useStyles, {
  BackgroundContainer,
  ImageBackground
} from './styles'

const GuestLayout = (props) => {
  const { children } = props

  const classes = useStyles()

  return (
    <div className={ classes.container }>
      <div className={ classes.contentScroll } style={ { height:'100%', width:'100%' } }>
        <Grid
          container
          direction="row"
          style={ { height: '100%' } }
        >
          <Grid item xs={ false } sm={ 6 } md={ 7 } lg={ 7 } style={ { flex: 1 } }>
            <Hidden xsDown>
              <BackgroundContainer>
                <ImageBackground />
              </BackgroundContainer>
            </Hidden>
          </Grid>
          <Grid item xs={ 12 } sm={ 6 } md={ 5 } lg={ 5 } className={ classes.gridLogin }>
            <div className={ classes.cardContainer }>
              {children}
            </div>
          </Grid>
        </Grid>

      </div>

    </div>
  )
}

GuestLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default withWidth()(GuestLayout)
