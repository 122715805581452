import React, { useEffect, useRef, useMemo } from 'react'
import { ImageOverlay } from 'react-leaflet'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import map from 'lodash/map'

import { WeatherForecastActions } from '@smartcoop/stores/weatherForecast'
import { selectAggregatedRain } from '@smartcoop/stores/weatherForecast/selectorWeatherForecast'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import Maps from '@smartcoop/web-components/Maps'

import * as images from './images'

const RainMapFragment = ({ property, style }) => {
  const mapRef = useRef(null)
  const dispatch = useDispatch()

  const rain = useSelector(selectAggregatedRain)

  useEffect(() => {
    dispatch(WeatherForecastActions.searchAggregatedRain(
      moment().subtract(30, 'days').format(momentBackDateFormat),
      moment().format(momentBackDateFormat)
    ))
  }, [dispatch])

  const rainArea = useMemo(
    () => filter(rain, item => item.aggregatedPrecipitation > 0),
    [rain]
  )

  useEffect(()=> {
    mapRef.current && mapRef.current.leafletElement._onResize()
  }, [mapRef])

  const getImage = rainValue => {
    if (rainValue < 26) return images.red
    if (rainValue >= 26 && rainValue <= 57) return images.orange
    if (rainValue >= 58 && rainValue <= 96) return images.yellow
    if (rainValue >= 97 && rainValue <= 145) return images.green
    if (rainValue >= 146 && rainValue <= 203) return images.blueSky
    if (rainValue >= 204 && rainValue <= 271) return images.blue
    if (rainValue >= 272 && rainValue <= 352) return images.blueOcean
    return images.purple
  }

  const getBounds = (coordinates) => {
    const coord = JSON.parse(coordinates)
    const first = coord[0]
    const second = [first[0] - 0.15, first[1] + 0.17]

    return [first, second]
  }

  return (
    property?.geolocalization ? (
      <Maps
        zoom={ 8 }
        maxZoom={ 9 }
        region={ property?.geolocalization }
        ref={ mapRef }
        layer="streets"
        style={ { zIndex: 1, ...style } }
      >
        { map(rainArea, item => (
          <ImageOverlay
            key={ item.id }
            url={ getImage(item.aggregatedPrecipitation) }
            bounds={ getBounds(item.polygonCoordinates) }
          />
        )) }
      </Maps>
    ) : (
      <div>Carregando</div>
    )

  )
}

RainMapFragment.propTypes = {
  property: PropTypes.object.isRequired,
  style: PropTypes.object
}

RainMapFragment.defaultProps = {
  style: {}
}

export default RainMapFragment
