import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'

import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n from '@smartcoop/i18n'
import { filter } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import AccreditmentList from '@smartcoop/web-containers/fragments/technical/AccreditmentList/AccreditmentList'
import OperationList from '@smartcoop/web-containers/fragments/technical/OperationList'
import { Title } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import FilterAccreditmentsModal from '@smartcoop/web-containers/modals/operations/FilterAccreditmentsModal/FilterAccreditmentsModal'
import FilterOperationsModal from '@smartcoop/web-containers/modals/operations/FilterOperationsModal'

import {
  Container,
  Row,
  ButtonContainer,
  TabButton
} from './styles'

const OperationsScreen = () => {
  const { createDialog } = useDialog()

  const [listType, setListType] = useState('accreditments')
  const [filters, setFilters] = useState({})

  const accreditmentsTableRef = useRef()

  const operationsTableRef = useRef()

  const handleFilter = useCallback(
    (values) => setFilters(values),
    []
  )

  const filterData = useCallback(
    () => {
      createDialog({
        id: 'filter-operations',
        Component: listType === 'accreditments' ? FilterAccreditmentsModal : FilterOperationsModal,
        props: {
          onSubmit: handleFilter,
          filters
        }
      })
    },
    [createDialog, filters, handleFilter, listType]
  )


  const dataTableList = useMemo(
    () => ({
      'accreditments': <AccreditmentList filters={ filters } ref={ accreditmentsTableRef }/>,
      'operation': <OperationList filters={ filters } ref={ operationsTableRef }/>
    }[listType]),
    [filters, listType]
  )

  useEffect(() => {
    setFilters({})
  }, [listType])

  return (
    <Container>
      <Title>
        <I18n>operation 365</I18n>
      </Title>
      <Row>
        <ButtonContainer>
          <div>
            <TabButton
              id="accreditments"
              onClick={ () => setListType('accreditments') }
              color={ listType === 'accreditments' ? colors.black : colors.white }
            >
              <I18n>accreditments</I18n>
            </TabButton>
            <TabButton
              id="operation"
              onClick={ () => setListType('operation') }
              color={ listType === 'operation' ? colors.black : colors.white }
            >
              <I18n>fields</I18n>
            </TabButton>
          </div>

          <div>
            <Button
              id="filter"
              onClick={ filterData }
              color={ isEmpty(filters) ? 'white' : 'secondary' }
              style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 12 } }
            >
              <>
                <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 }/>
                <I18n>filtrate</I18n>
              </>
            </Button>
          </div>
        </ButtonContainer>
      </Row>
      <div>
        {dataTableList}
      </div>
    </Container>
  )
}

export default OperationsScreen
