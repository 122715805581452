import React, { useCallback, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import { reloadSchema } from '@smartcoop/forms'
import createPasswordSchema from '@smartcoop/forms/schemas/auth/createPassword.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputPassword from '@smartcoop/web-components/InputPassword'
import LoadingModal from '@smartcoop/web-containers/modals/LoadingModal'

import { Container, ButtonContainer } from './styles'

const CreatePasswordForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit } = props

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        formRef.current.submit()
      }
    },
    [formRef]
  )

  const dispatch = useCallback(useDispatch(), [])

  const user = useSelector(selectUser)

  const t = useT()
  const { createDialog, removeDialog } = useDialog()

  const handleSubmit = useCallback(
    ({ newPassword }) => {
      createDialog({
        id: 'loading',
        Component: LoadingModal
      })

      const removeLoader = () => {
        setTimeout(() => removeDialog({ id: 'loading' }), 150)
      }

      dispatch(AuthenticationActions.changePassword(
        newPassword,
        () => {
          onSubmit(newPassword)
          removeLoader()
        },
        removeLoader
      ))
    },
    [createDialog, dispatch, onSubmit, removeDialog]
  )

  const validateSamePassword = useCallback(
    () => reloadSchema(formRef, true),
    [formRef]
  )

  const schemaConstructor = useCallback(
    (opts) => createPasswordSchema({ ...opts, user }),
    [user]
  )

  return (
    <Container>
      <Form
        ref={ formRef }
        schemaConstructor={ schemaConstructor }
        onSubmit={ handleSubmit }
      >
        <InputPassword
          name="newPassword"
          label={ t('new password') }
          onChange={ validateSamePassword }
          disabled={ loading }
          fullWidth
          onKeyDown={ handleKeyDown }
        />

        <InputPassword
          name="confirmPassword"
          label={ t('confirm password') }
          disabled={ loading }
          fullWidth
          onKeyDown={ handleKeyDown }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="create-password-submit"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>next</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

CreatePasswordForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

CreatePasswordForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default CreatePasswordForm
