import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import SliderMui from '@material-ui/core/Slider'

import useStyles,
{
  Label,
  Container
} from './styles'

const Slider = (props) => {
  const {
    value,
    label: externalLabel,
    required,
    onChange,
    style,
    className,
    sliderProps
  } = props

  const classes = useStyles()
  const label = useMemo(
    () => `${ externalLabel }${ required ? ' *' : '' }`,
    [externalLabel, required]
  )
  return (
    <Container>
      <Label>{label}</Label>
      <SliderMui
        classes={ classes }
        className={ className }
        style={ style }
        valueLabelDisplay="auto"
        aria-label="slider"
        value={ value }
        valueLabelFormat={ () => `${ value }%`  }
        onChange={ (e, v) => onChange(e, v) }
        { ...sliderProps }
      />
    </Container>
  )
}

Slider.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  sliderProps: PropTypes.object,
  required: PropTypes.bool
}

Slider.defaultProps = {
  value: null,
  label: '',
  onChange: () => {},
  style: {},
  className: '',
  required: false,
  sliderProps: {}
}

export default Slider
