import bytes from 'bytes'

import forEach from 'lodash/forEach'
import sumBy from 'lodash/sumBy'

export const isValidSize = (file, maxSize) => (file?.size || file?.fileSize || sumBy(file, 'fileSize')) < maxSize

export const convertFileSize = size => bytes(size)

export const convertToCSV = (objArray) => {
  const lineArray = []
  objArray.forEach((infoArray) => {
    const line = infoArray.join(',')
    lineArray.push(line)
  })
  const csvContent = lineArray.join('\n')

  return csvContent
}

export const createFormData = (files, fileKey = 'upload', body = {}) => {
  const data = new FormData()

  forEach(files, (file) => {
    data.append(fileKey, file)
  })

  Object.keys(body).forEach((key) => {
    data.append(key, body[key])
  })

  return data
}

const signatures = {
  'JVBERi0': 'application/pdf',
  'iVBORw0KGgo': 'image/png',
  '/9j/': 'image/jpg'
}

export const detectMimeType = (b64) => {
  for (const s in signatures) {
    if (b64.indexOf(s) === 0) {
      return signatures[s]
    }
  }
  return null
}

export const downloadFromBase64 =  (b64Data) => {
  const mimeType = detectMimeType(b64Data)

  const linkSrc = `data:${ mimeType };base64,${ b64Data }`
  return mimeType ? linkSrc : b64Data
}

export const generateKML = (polygonCoordinates, name) => {

  let kml = `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2">
    <Document>
      <name>Converted Polygon</name>
      <Placemark>
        <name>Polygon</name>
        <Polygon>
          <outerBoundaryIs>
            <LinearRing>
              <coordinates>
  `

  polygonCoordinates.forEach(coord => {
    const [lat, lng] = coord
    kml += `${ lng },${ lat },0\n`
  })

  kml += `
              </coordinates>
            </LinearRing>
          </outerBoundaryIs>
        </Polygon>
      </Placemark>
    </Document>
  </kml>
  `

  const kmlBlob = new Blob([kml], { type: 'application/xml' })
  const url = URL.createObjectURL(kmlBlob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${ name }.kml`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
