import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import IconButton from '@smartcoop/web-components/IconButton'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 70%;
  @media (max-width: 1600px) {
    width: 100%;
  }
`

export const TotalQuantityRow = styled(Row)`
  margin-top: 20;
  background-color: #fff;
  padding: 12px 15px 12px 35px;
  justify-content: space-between;
  align-items: center;
`

export const TotalQuantity = styled(Typography)`
  font-weight: 600;
  font-size: 16;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
`

export const ButtonContainer = styled.div`
  margin-left: 10px;
`
export const ButtonLabel = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1d1d1b;
  margin-left: 5px;
`

export const CustomIconButton = styled(IconButton)`
  padding: 0;
  border-radius: 0;
`
