import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectUserProfileIsAdmin, selectUserProfileIsSmartcoop } from '@smartcoop/stores/authentication/selectorAuthentication'
import { OrderActions } from '@smartcoop/stores/order'
import {
  selectCurrentOrder,
  selectCurrentOrderProduct
} from '@smartcoop/stores/order/selectorOrder'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Loader from '@smartcoop/web-components/Loader'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import AcceptProposalForm from '@smartcoop/web-containers/forms/shoppingPlatform/order/AcceptProposalForm'
import OrderDetailsFragment from '@smartcoop/web-containers/fragments/OrderDetailsFragment'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import ViewListAmountPaidModal from '@smartcoop/web-containers/modals/AmountPaid/ViewListAmountPaidModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

const AcceptProposalScreen = () => {
  const history = useHistory()
  const { orderId } = useParams()
  const { routes } = useRoutes()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedProposal, setSelectedProposal] = useState(null)
  const t = useT()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const userProfileIsAdmin = useSelector(selectUserProfileIsAdmin)
  const userProfileIsSmartcoop = useSelector(selectUserProfileIsSmartcoop)
  const { createDialog } = useDialog()

  const acceptProposalRef = useRef(null)
  const currentOrder = useSelector(selectCurrentOrder)
  const currentOrganization = useSelector(selectCurrentOrganization)
  const product = useSelector(selectCurrentOrderProduct)

  const isFormDisabled = useMemo(
    () => currentOrder.status?.slug !== 'aguardando_resposta' || formSubmitted,
    [currentOrder, formSubmitted]
  )

  // TODO: Redirecionar pra página de detalhes caso o slug não seja 'aguardando_decisao'

  const onSuccess = useCallback(() => {
    dispatch(OrderActions.resetCurrentOrder())
    history.replace(
      routes.orderDetails.path.replace(':orderId', currentOrder.id)
    )
  }, [currentOrder.id, dispatch, history, routes.orderDetails.path])

  const rejectProposals = useCallback(() => {
    createDialog({
      id: 'confirm-reject',
      Component: ConfirmModal,
      props: {
        onConfirm: () => {
          dispatch(
            OrderActions.rejectOfflineProposals(currentOrder.id, () =>
              snackbar.success(t('all proposals were rejected'))
            )
          )
          setFormSubmitted(true)
        },
        textWarning: t('are you sure you want to reject all proposals?')
      }
    })
  }, [createDialog, currentOrder.id, dispatch, snackbar, t])

  const acceptProposal = useCallback(
    ({ id, supplier: { companyName } }) => {
      createDialog({
        id: 'confirm-accept',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(
              OrderActions.acceptOfflineProposal(id, () =>
                snackbar.success(
                  t('the proposal from {this} was accepted', {
                    this: companyName
                  })
                )
              )
            )
            setFormSubmitted(true)
          },
          textWarning: t(
            'are you sure you want to accept the supplier proposal {this}?',
            { this: companyName }
          )
        }
      })
    },
    [createDialog, dispatch, snackbar, t]
  )

  const ListAmountPaid = useCallback(() => {
    createDialog({
      id: 'complete-crop-management',
      Component: ViewListAmountPaidModal,
      props: {
        productId: product.id
      }
    })
  }, [createDialog, product.id])

  useEffect(
    () => {
      setLoading(true)
      dispatch(
        OrderActions.loadCurrentOrder(
          orderId,
          () => setLoading(false),
          () => history.replace(routes.orderList)
        )
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, orderId, currentOrganization]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(OrderActions.resetCurrentOrder()), [])

  useEffect(() => {
    if (!(userProfileIsAdmin || userProfileIsSmartcoop)) {
      history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileIsAdmin, userProfileIsSmartcoop, routes])

  return loading ? (
    <Loader width={ 100 } />
  ) : (
    <SplitedScreenLayout
      title={ { name: t('order', { howMany: 2 }) } }
      divRightStyle={ { paddingTop: 67, width: '100%' } }
      leftChildren={ <OrderDetailsFragment selectedProposal={ selectedProposal } organizationsVariant="accordion" /> }
      rightChildren={
        <div style={ { display: 'flex', flexDirection: 'column', width: '100%' } }>
          <div style={ { alignSelf: 'flex-end' } }>
            <Button
              id="open-register-purchase"
              onClick={ ListAmountPaid }
              style={ { width: 'auto', fontWeight: 600, marginRight: 10 } }
              color={ colors.white }
            >
              <I18n>see paid price history</I18n>
            </Button>
          </div>
          {!isEmpty(currentOrder.purchaseProposals) && (
            <AcceptProposalForm
              ref={ acceptProposalRef }
              purchaseProposals={ currentOrder.purchaseProposals }
              deliveryPercentage={ currentOrder.deliveryPercentage }
              onSuccess={ onSuccess }
              onAcceptProposal={ (proposalId) => acceptProposal(proposalId) }
              onRejectProposals={ () => rejectProposals() }
              disabled={ isFormDisabled }
              onChangeSelected={ setSelectedProposal }
            />
          )}
        </div>
      }
    />
  )
}

export default AcceptProposalScreen
