import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import float from '@smartcoop/forms/validators/float.validator'
import minNumberValue from '@smartcoop/forms/validators/minNumberValue.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerAmountPaid = ({ t }) => Yup.object().shape({
  payDay: flow(
    date({ t }),
    required({ t })
  )(Yup.string()),

  unitPrice: flow(
    float({ t }),
    minNumberValue({ t, field: t('unit price') }),
    required({ t })
  )(Yup.string())
})

export default registerAmountPaid
