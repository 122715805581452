import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

export const TitleContainer = styled.div`
  padding: 0px 20px; 
`

export const FieldsContainer = styled(Grid).attrs({
  container: true,
  spacing: 3
})`
  padding: 10px 20px;
`

export const Item = styled(Grid).attrs({
  item: true,
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
  xl: 6
})``

export const Row = styled(Grid).attrs({
  item: true,
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12
})``