import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  display: inline-flex;
  border-radius: 5px;
  overflow: hidden;
  line-height: 0;
  width: 98px;
  border: 0.7px solid ${ colors.lightGrey };
`
export const Button = styled.button`
  border: 0;
  margin: 0;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: ${ ({ disabled }) => disabled ? colors.muted : colors.transparent };
  cursor: pointer;
`

export const Input = styled.input`
  width: 40%;
  height: auto;
  display: flex;
  border: 0;
  text-align: center;
  font-size: 18px;
  line-height: 0;
  border-left: 0.7px solid ${ colors.lightGrey };
  border-right: 0.7px solid ${ colors.lightGrey };
  background-color: ${ ({ disabled }) => disabled ? colors.muted : colors.white };
`
