/* eslint-disable react/no-this-in-sfc */
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { selectReloadData } from '@smartcoop/stores/property/selectorProperty'
import { createPropertyActivitiesChartOptions } from '@smartcoop/utils/charts'
import Chart from '@smartcoop/web-components/Chart'
import Loader from '@smartcoop/web-components/Loader'

const PropertyActivitiesChart = ({ property }) => {
  const t = useT()

  const mounted = useRef(false)

  const [chartOptions, setChartOptions] = useState({})
  const [loading, setLoading] = useState(false)

  const reloadData = useSelector(selectReloadData)

  const getChartOptions = useCallback(async () => {
    try {
      setLoading(true)
      const options = await createPropertyActivitiesChartOptions({
        propertyId: property?.id,
        t
      })
      if (mounted.current) {
        setChartOptions(options)
        setLoading(false)
      }
    } catch (e) {
      if (mounted.current) {
        setLoading(false)
      }
    }
  }, [property?.id, t])

  useEffect(() => {
    if (property?.id || reloadData) {
      getChartOptions()
    }
  }, [getChartOptions, property?.id, reloadData])

  useEffect(() => {
    mounted.current = true
  }, [])

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  return (
    <div style={ { height: 300, width: '100%' } }>
      {loading ? (
        <Loader width={ 100 } />
      ) : (
        <Chart
          options={ chartOptions }
          containerProps={ { style: { height: 300 } } }
        />
      )}
    </div>
  )
}

PropertyActivitiesChart.propTypes = {
  property: PropTypes.object.isRequired
}

export default PropertyActivitiesChart
