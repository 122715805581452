import React, { useCallback, useMemo, forwardRef } from 'react'
import {
  Map as LeaftletMap,
  TileLayer,
  LayersControl
} from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'

import L from 'leaflet'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { DEFAULT_COORDINATES } from '../../../core/utils/maps'
import useStyles from './styles'

if (typeof window !== 'undefined') {
  delete L.Icon.Default.prototype._getIconUrl

  L.Icon.Default.mergeOptions({
    iconRetinaUrl,
    iconUrl,
    shadowUrl
  })
}

const Maps = forwardRef( (props, mapRef) => {
  const {
    children,
    region,
    zoom,
    onClick,
    cursor,
    layer,
    maxZoom,
    streetOnly,
    onZoomEnd,
    style: propStyle
  } = props

  useStyles()

  const style = useMemo(
    () => {
      if (cursor) {
        return {
          ...propStyle,
          cursor
        }
      }
      return propStyle
    },
    [cursor, propStyle]
  )

  const center = useMemo(() => {
    if (!isEmpty(region) && region?.latitude && region?.longitude) {
      return [region?.latitude, region?.longitude]
    }

    return map(DEFAULT_COORDINATES, item => item)
  }, [region])

  const handlePointClick = useCallback(
    ({ latlng }) => {
      onClick({
        latitude: latlng.lat,
        longitude: latlng.lng
      })
    },
    [onClick]
  )

  return !isEmpty(center) && (
    <LeaftletMap
      { ...props }
      ref={ mapRef }
      maxZoom={ maxZoom }
      center={ center }
      zoom={ zoom }
      onClick={ handlePointClick }
      style={ style }
      attributionControl={ false }
      onzoomend={ onZoomEnd }
      layer={ false }
    >
      {streetOnly && (
        <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
      )}
      {!streetOnly && (
        <LayersControl position="topright">
          <LayersControl.BaseLayer name="Satélite" checked={ layer === 'satellite' }>
            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png" />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Satélite Google" checked={ layer === 'google' }>
            <ReactLeafletGoogleLayer googleMapsLoaderConf={ { KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY } } type="hybrid" />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Vias" checked={ layer === 'streets' }>
            <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer>
        </LayersControl>
      )}
      {children}
    </LeaftletMap>
  )
})

Maps.propTypes = {
  children: PropTypes.any,
  region: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  zoom: PropTypes.number,
  streetOnly: PropTypes.bool,
  onClick: PropTypes.func,
  cursor: PropTypes.string,
  style: PropTypes.object,
  maxZoom: PropTypes.number,
  onZoomEnd: PropTypes.func,
  layer: PropTypes.oneOf(['satellite', 'google', 'streets'])
}

Maps.defaultProps = {
  layer: 'google',
  children: null,
  streetOnly: false,
  region: {},
  zoom: 16,
  onClick: () => {},
  cursor: undefined,
  maxZoom: 18,
  onZoomEnd: () => {},
  style: {}
}

export default Maps
