import React, { useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { size } from 'lodash'
import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n from '@smartcoop/i18n'
import { barn } from '@smartcoop/icons'
import { selectAllModules } from '@smartcoop/stores/authentication/selectorAuthentication'
import { ModuleActions } from '@smartcoop/stores/module'
import { selectCurrentProperty, selectProperties } from '@smartcoop/stores/property/selectorProperty'
import Button from '@smartcoop/web-components/Button'
import Card from '@smartcoop/web-components/Card'
import Icon from '@smartcoop/web-components/Icon'
import PropertyIdentificationForm from '@smartcoop/web-containers/forms/digitalProperty/property/PropertyIdentificationForm'
import { Item, Title, ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import ChangePropertyModal from '../../../../../modals/ChangePropertyModal/ChangePropertyModal'
import { IconContainer } from './styles'

const PropertyIdentificationScreen = (props) => {
  const { onConfirm, onCancel, isEdit } = props
  const formRef = useRef(null)
  const allModules = useSelector(selectAllModules)
  const currentProperty = useSelector(selectCurrentProperty)
  const dispatch = useCallback(useDispatch(), [])
  const { createDialog } = useDialog()
  const properties = useSelector(selectProperties)

  const exitModule = useCallback(
    () => dispatch(ModuleActions.exitCurrentModule()),
    [dispatch]
  )

  const handleCancel = useCallback(() => {

    onCancel()
    const { length: allModulesLength } = allModules

    if(allModulesLength > 1 ) {
      !isEdit && isEmpty(currentProperty) && exitModule()
    }
    if ((size(properties) >= 1 && isEmpty(currentProperty)) ||
         size(properties) === 0) {
      createDialog({
        id: 'change-property',
        Component: ChangePropertyModal
      })
    }
  }, [allModules, createDialog, currentProperty, exitModule, isEdit, onCancel, properties])

  return (
    <Card style={ { marginTop: 0 } } cardStyle={ { minWidth: 450 } }>
      <Item>
        <IconContainer>
          <Icon icon={ barn } size={ 25 } />
        </IconContainer>

        <Title>
          <I18n>{isEdit ? 'property edition' : 'property registration'}</I18n>
        </Title>

        <PropertyIdentificationForm
          style={ { display: 'flex', flexDirection: 'column' } }
          ref={ formRef }
          onSubmit={ onConfirm }
          withoutSubmitButton
        />

        <ButtonsContainer>
          <Button
            id="cancel-property-form"
            onClick={ handleCancel }
            style={ { width: '40%', flex: '0 0 auto' } }
            variant="outlined"
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="submit-property-form"
            onClick={ () => formRef.current.submit() }
            style={ { width: '40%', flex: '0 0 auto' } }
          >
            <I18n>next</I18n>
          </Button>
        </ButtonsContainer>
      </Item>
    </Card>
  )
}

PropertyIdentificationScreen.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isEdit: PropTypes.bool
}

PropertyIdentificationScreen.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  isEdit: false
}

export default PropertyIdentificationScreen
