import orderBy from 'lodash/orderBy'

export const selectMilkDeliveries = state => orderBy(state.dairyFarm.milkDeliveries, 'volumeDate', 'desc')

export const selectMilkQualities = state => state.dairyFarm.milkQualities
export const selectDashboardData = state => state.dairyFarm.dashboardData

export const selectCurrentMilkDelivery = state => state.dairyFarm.currentMilkDelivery

export const selectDairyFarmMonth = state => state.dairyFarm.dairyFarmMonth

export const selectPriceData = state => state.dairyFarm.priceData
export const selectMonthlyDataHistory = state => state.dairyFarm.monthlyDataHistory

export const selectCurrentPriceData = state => state.dairyFarm.currentPriceData

export const selectCurrentSection = state => state.dairyFarm.currentSection
