import styled from 'styled-components'

import { Typography } from '@material-ui/core'

import { colors } from '@smartcoop/styles'


export const Card = styled.div`
  display: grid;
  grid-template-columns: ${ ({ openSelect }) => openSelect ? 'auto auto 1fr' : 'auto 1fr' };
  column-gap: 10px;
  padding: 15px;
  background-color: ${ colors.white };
  cursor: pointer;
  filter: ${ ({ selected }) => selected ? 'brightness(0.93)' : 'none' };

  :hover {
    filter: brightness(0.95);
  }
`

export const ChatDefailtWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`

export const CardRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`

export const NameText = styled(Typography)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${ colors.text };
`

export const SmallText = styled(Typography)`
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
`

export const MessageText = styled(Typography)`
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 300px;
  column-gap: 5px;
`

export const MessageWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 5px;
`

export const DeletedSpan = styled.span`
  color: ${ colors.mediumGrey };
  font-style: italic;
  font-size: 12px;
  display: flex;
  column-gap: 5px;
`

export const NumberInfo = styled.div`
  border-radius: 7.5px;
  height: 15px;
  width: 15px;
  background-color: ${ colors.warning };
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const InfoChat = styled.p`
  text-align: center;

  font-size: 10px;
  font-weight: bold;
  color: ${ colors.blackLight };
`
