import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  pregnancyActions: [],
  error: null,
  blockDrying: []
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadDryings: ['params', 'onSuccess', 'onError'],
  loadDryingsSuccess: ['params', 'page', 'onSuccess'],

  saveDrying: ['params', 'onSuccess', 'onError', 'isEdit', 'isConnected'],
  saveOfflineDrying: ['params', 'onSuccess', 'onError', 'web'],
  deleteDrying: ['id', 'onSuccess', 'onError'],
  deleteOfflineDrying: ['id', 'onSuccess', 'onError'],

  dryingError: ['error'],
  addBlockDrying: ['animalId'],
  resetBlockDrying: []
})

markActionsOffline(Creators, [
  'saveDrying',
  'deleteDrying'
])

/**
 * Reducers functions
 */

const loadDryingsSuccess = (state = INITIAL_STATE, { params, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  pregnancyActions: page === 1 ? params : [
    ...state.pregnancyActions,
    ...params
  ]
})

const addBlockDrying = (state = INITIAL_STATE, { animalId }) => ({
  ...state,
  blockDrying: [...state.blockDrying, animalId]
})

const resetBlockDrying = (state = INITIAL_STATE) => ({
  ...state,
  blockDrying: INITIAL_STATE.blockDrying
})

const dryingError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */

export default createReducer(INITIAL_STATE, {
  [Types.LOAD_DRYINGS_SUCCESS]: loadDryingsSuccess,

  [Types.DRYING_ERROR]: dryingError,

  [Types.ADD_BLOCK_DRYING]: addBlockDrying,
  [Types.RESET_BLOCK_DRYING]: resetBlockDrying,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as AnimalPregnancyActionsTypes,
  Creators as AnimalPregnancyActionsActions
}
