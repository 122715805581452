import api from '../api'

export const getSalesOrdersByOrg = (params, { organizationId }) => api.get(`/v1/sales-orders/organization/${ organizationId }`, { params })

export const getSettlementDateByOrg = async (params, { organizationId }) => {
  const response = await api.get(`/v1/sales-orders/settlement-date/${ organizationId }`, { params })
  return { data: { data: response.data.settlementDates } }
}

export const getProductBatchByOrg = async (params, { organizationId }) => {
  const response = await api.get(`/v1/sales-orders/product-batch/organization/${ organizationId }`, { params })
  return { data: { data: response.data.productBatchs } }
}

export const createSalesOrder = async (params, { organizationId }) => api.post(`/v1/sales-orders/cash-sale/${ organizationId }`, params)
export const createFutureOrder = async (params, { organizationId }) => api.post(`/v1/sales-orders/future-sale/${ organizationId }`, params)
export const createScheduledOrder = async (params, { organizationId }) => api.post(`/v1/sales-orders/scheduled-sale/${ organizationId }`, params)

export const deleteSaleOrder = async (params, { saleOrderId, organizationId }) => api.delete(`/v1/sales-orders/${ saleOrderId }/organization/${ organizationId }`, params)

export const validationSignatureEletronic = async (params, { organizationId }) => api.post(`/v1/eletronic-signature/organization/${ organizationId }/validate`, params)

export const getAllSalesOrdersByUser = (params) => api.get('/v1/sales-orders/organizations/user', { params })
