import * as Yup from 'yup'

import date from '@smartcoop/forms/validators/date.validator'

const filterSupplier = ({ t }) => Yup.object().shape({
  proposalDeadline: date({ t })(Yup.string()),

  receiptDeadline: date({ t })(Yup.string()),

  productId: Yup.string(),

  statusId: Yup.string()
})

export default filterSupplier
