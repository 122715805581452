import styled from 'styled-components'

import { colors } from '@smartcoop/styles'


export const Step = styled.div`
  width: 100%;
  min-height: 10px;
  margin: 10px;
  background: ${ props => props.active ? colors.yellow : colors.lightYellow };
  flex: 1;
  border-radius: 5px;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
