import find from 'lodash/find'
import reduce from 'lodash/reduce'

import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'


export const selectOrganizationOrders = state => state.order.organizationOrders

export const selectOrderFilters = state => state.order.orderFilters

export const selectCurrentOrder = state => state.order.currentOrder

export const selectCurrentOrderProduct = state => {
  const currentOrder = selectCurrentOrder(state)
  return currentOrder?.product || {}
}

export const selectCurrentOrderPurchasesOfCurrentOrganization = state => {
  const currentOrder = selectCurrentOrder(state)
  const currentOrganization = selectCurrentOrganization(state)

  return find(currentOrder.organizationPurchases, ({ organizationId }) => organizationId === currentOrganization.id )
}

export const selectCurrentOrderAddressesOfCurrentOrganization = state => {
  const orderPurchases = selectCurrentOrderPurchasesOfCurrentOrganization(state)

  return reduce(orderPurchases.deliveryLocations, (acc, location) => [...acc, ...location], [])
}

export const selectBestProposal = state => state.order.bestProposal
