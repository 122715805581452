import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import Form from '@smartcoop/web-components/Form'

export const Container = styled(Form).attrs({
  containerStyle: { height: 'auto' }
})`
  background: ${ colors.white };
  box-shadow: 0px 0px 3px ${ colors.lightGrey };
  border-radius: 10px;
`

export const Header = styled.div`
  padding-top: 14px;
  padding-left: 18px;
  padding-right: 18px;
`

export const Identifier = styled.div`
  display: flex;
  align-items: center;
`

export const Col = styled.div`
  padding-left: 12px;
`

export const Name = styled(Typography)`
  font-size: 18px;
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: 600;
  color: ${ colors.black };
`

export const Privacy = styled.div`
  display: flex;
  align-items: center;
`

export const Type = styled(Typography)`
  margin-left: 4px;
  font-size: 14px;
  font-family: ${ fonts.fontFamilySans };
  font-weight: 600;
  /* color: ${ colors.green }; */
`

export const Body = styled.div`
  padding-left: 18px;
  padding-right: 18px;
`

export const Footer = styled.div`
  padding: 10px 18px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const ColFooter1 = styled.div`
  flex: 1;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 0px;
    margin-right: 10px;
  }
`

export const ColFooter2 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const textFieldTheme = (theme) => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiOutlinedInput: {
      notchedOutline: {
        border: 'none !important'
      }
    }
  }
})
