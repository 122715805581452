import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import Modal from '@smartcoop/web-components/Modal'
import PropertyTechnicalVisitScheduleForm from '@smartcoop/web-containers/forms/digitalProperty/technicalVisit/PropertyTechnicalVisitScheduleForm'

import useStyles, { Row, Content } from './styles'

const PropertyTechnicalVisitScheduleModal = (props) => {
  const { id, open, technicalVisit, disabled, onSuccess, handleClose, readOnly, onVisit, fromTechnicalArea } = props
  const technicalVisitRegisterFormRef = useRef(null)

  const classes = useStyles()

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row style={ { marginBottom: '10px' } }>
          <I18n>schedule</I18n>
        </Row>
      }
      disableFullScreen
      maxWidth='md'
      fullWidth
      disableEsc
      classes={ { paper: classes.modalBackground } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      contentContainerStyle={ { padding: 0, minWidth: 700 } }
    >
      <>
        <Content>
          <PropertyTechnicalVisitScheduleForm
            ref={ technicalVisitRegisterFormRef }
            handleClose={ handleClose }
            technicalVisit={ technicalVisit }
            disabled={ disabled }
            onSuccess={ onSuccess }
            readOnly={ readOnly }
            onVisit={ onVisit }
            fromTechnicalArea={ fromTechnicalArea }
          />
        </Content>
      </>
    </Modal>
  )
}

PropertyTechnicalVisitScheduleModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  technicalVisit: PropTypes.object,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSuccess: PropTypes.func,
  fromTechnicalArea: PropTypes.bool,
  onVisit: PropTypes.func
}

PropertyTechnicalVisitScheduleModal.defaultProps = {
  technicalVisit: {},
  disabled: false,
  fromTechnicalArea: false,
  readOnly: false,
  onSuccess: () => {},
  onVisit: () => {}
}

export default PropertyTechnicalVisitScheduleModal
