/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useState,
  useEffect,
  forwardRef,
  useMemo,
  useRef,
  useLayoutEffect
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import moment from 'moment/moment'

import capitalize from 'lodash/capitalize'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { edit, logout, organizationRounded, doc } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { OrderActions } from '@smartcoop/stores/order'
import {
  selectCurrentOrder,
  selectCurrentOrderProduct,
  selectBestProposal
} from '@smartcoop/stores/order/selectorOrder'
import { selectCurrentOrganization, selectCurrentOrganizationAddresses } from '@smartcoop/stores/organization/selectorOrganization'
import colors from '@smartcoop/styles/colors'
import {
  momentFriendlyDateFormat,
  momentBackDateTimeZoneFormat
} from '@smartcoop/utils/dates'
import { formatCurrency } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import DemandStatus from '@smartcoop/web-components/DemandStatus'
import Icon from '@smartcoop/web-components/Icon'
import Loader from '@smartcoop/web-components/Loader'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import AcceptBestProposalForm from '@smartcoop/web-containers/forms/shoppingPlatform/order/AcceptBestProposalForm'
import AddressFragment from '@smartcoop/web-containers/fragments/AddressFragment'
import OrderDetailsFragment from '@smartcoop/web-containers/fragments/OrderDetailsFragment'
import DemandStatusFragment from '@smartcoop/web-containers/fragments/shoppingPlatform/DemandStatusFragment/DemandStatusFragment'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import ViewListAmountPaidModal from '@smartcoop/web-containers/modals/AmountPaid/ViewListAmountPaidModal'
import RejectProposalModal from '@smartcoop/web-containers/modals/RejectProposalModal'
import ViewBankSlipModal from '@smartcoop/web-containers/modals/shoppingPlatform/ViewBankSlipModal'
import ViewListSuppliersByProducts from '@smartcoop/web-containers/modals/ViewListSuppliersByProducts'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  Container,
  Row,
  Title,
  ButtonContainer,
  InformationContainer,
  InformationContent,
  RowLocations,
  IconContainer,
  Header,
  HeaderDiv
} from './styles'

const OrderDetailsScreen = forwardRef(() => {
  const t = useT()
  const params = useParams()
  const history = useHistory()
  const snackbar = useSnackbar()
  const { routes } = useRoutes()
  const { createDialog } = useDialog()
  const acceptProposalRef = useRef(null)
  const dispatch = useCallback(useDispatch(), [])
  const location = useLocation()
  const { orderId } = params
  const currentOrder = useSelector(selectCurrentOrder)
  const bestProposal = useSelector(selectBestProposal)
  const product = useSelector(selectCurrentOrderProduct)
  const currentOrganization = useSelector(selectCurrentOrganization)
  const currentOrganizationAdresses = useSelector(selectCurrentOrganizationAddresses)
  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [suppliersProduct, setSuppliersProduct] = useState(null)

  const accepted = useMemo(() => location.state?.accepted, [
    location.state
  ])

  const acceptedProposal = useMemo(() => find(currentOrder?.purchaseProposals, item => item?.status?.slug === 'aprovado'), [currentOrder])

  const [verifiedCurrentOrder, setVerifiedCurrentOrder] = useState(false)

  const productUnit = useMemo(() => product.unitOfMeasures || 'kg', [product])

  const slug = useMemo(() => currentOrder?.status?.slug || '', [currentOrder])

  const currentOrganizationPurchase = useMemo(
    () =>
      find(
        currentOrder.organizationPurchases,
        ({ organizationId }) => organizationId === currentOrganization.id
      ),
    [currentOrder, currentOrganization]
  )
  const showEdit = useMemo(
    () => currentOrder?.status?.slug !== 'aguardando_decisao' && currentOrder?.status?.slug !== 'aguardando_entrega' && currentOrder?.status?.slug !== 'finalizado',
    [currentOrder]
  )

  const disabledForm = useMemo(
    () =>
      !(
        isUndefined(currentOrganizationPurchase) ||
        isUndefined(currentOrganizationPurchase.proposalAccepted)
      ),
    [currentOrganizationPurchase]
  )

  const waitingDelivery = useMemo(
    // eslint-disable-next-line arrow-body-style
    () => {
      return currentOrder?.status?.slug === 'aguardando_entrega'
    },
    [currentOrder]
  )

  useEffect(() => {
    setIsFormDisabled(disabledForm)
  }, [disabledForm])

  const willShowBestProposalForm = useMemo(() => {
    const slugsToShow = [
      'aguardando_decisao',
      'proposta_aceita',
      'aguardando_entrega',
      'recusado'
    ]
    return (
      slugsToShow.includes(currentOrder?.status?.slug) && !isEmpty(bestProposal)
    )
  }, [bestProposal, currentOrder])

  const willShowProposalLabel = useMemo(() => {
    const slugsToShow = [
      'aguardando_entrega',
      'aguardando_decisao',
      'atrasada',
      'entregue',
      'recusado',
      'finalizado'
    ]
    return slugsToShow.includes(currentOrder?.status?.slug)
  }, [currentOrder])

  const orderDetails = useMemo(
    () => [
      {
        subtitle: 'began to participate in',
        value:
          currentOrganizationPurchase &&
          moment(
            currentOrganizationPurchase.createdAt,
            momentBackDateTimeZoneFormat
          ).format(momentFriendlyDateFormat)
      },
      {
        subtitle: 'volume',
        value: currentOrganizationPurchase
          ? `${ Number(currentOrganizationPurchase.totalAmount).toLocaleString(
            'pt-BR'
          ) } ${ productUnit }` || ''
          : ''
      }
    ],
    [currentOrganizationPurchase, productUnit]
  )

  const getRefusedProposalValue = useCallback(() => {
    const proposal = currentOrder?.purchaseProposals ? find(currentOrder?.purchaseProposals, item => item?.bestProposal) : {}
    const onCash = find(proposal?.proposalPaymentForms, item => !item.paymentForm.deferredPayment)

    return `${ capitalize(t('in cash')) }: ${ formatCurrency(onCash?.value) }`
  },[currentOrder, t])

  const orderDetailsWithProposal = useMemo(
    () => [
      {
        subtitle: 'began to participate in',
        value:
          currentOrganizationPurchase &&
          moment(
            currentOrganizationPurchase.createdAt,
            momentBackDateTimeZoneFormat
          ).format(momentFriendlyDateFormat)
      },
      {
        subtitle: 'volume',
        value: currentOrganizationPurchase
          ? `${ Number(currentOrganizationPurchase.totalAmount).toLocaleString(
            'pt-BR'
          ) } ${ productUnit }` || ''
          : ''
      },
      {
        subtitle: 'proposal',
        value: willShowBestProposalForm ? null : (!currentOrganizationPurchase?.proposalAccepted ? getRefusedProposalValue() : (!currentOrganizationPurchase?.proposalPaymentForm?.paymentForm
          ?.deferredPayment
          ? `${ capitalize(t('in cash')) }: ${ formatCurrency(
            currentOrganizationPurchase?.proposalPaymentForm?.value
          ) }`
          : `${ capitalize(t('deferred')) } ${
            currentOrganizationPurchase?.proposalPaymentForm?.paymentForm
              ?.daysAmount
          }` +
            ` ${ t('day', {
              howMany:
                currentOrganizationPurchase?.proposalPaymentForm?.paymentForm
                  ?.daysAmount
            }) }:` +
            ` ${ formatCurrency(
              currentOrganizationPurchase?.proposalPaymentForm?.value
            ) }`))
      },
      {
        subtitle: 'supplier',
        value: currentOrder?.purchaseProposals && !willShowBestProposalForm ? find(currentOrder?.purchaseProposals, item => item?.bestProposal)?.supplier?.tradeName : null
      },
      {
        subtitle: 'refuse reason',
        value: currentOrganizationPurchase?.deniedProposalReason
      },
      {
        subtitle: currentOrder?.freightType === 'CFA' && !isEmpty(acceptedProposal || bestProposal) ? 'total cfa freight cost' : null,
        value: currentOrder?.freightType === 'CFA' && !isEmpty(acceptedProposal || bestProposal) ? formatCurrency(sumBy(acceptedProposal?.deliveryFreightValues || bestProposal?.deliveryFreightValues, 'value')) : null
      }
    ],
    [acceptedProposal, bestProposal, currentOrder, currentOrganizationPurchase, getRefusedProposalValue, productUnit, t, willShowBestProposalForm]
  )

  const disableActions = useMemo(() => {
    if (!isEmpty(currentOrder)) {
      return (
        currentOrder.status?.slug !== 'aberta' ||
        moment().isAfter(moment(currentOrder.participationDeadline))
      )
    }
    return true
  }, [currentOrder])

  const disableLeaveOrder = useMemo(() => {
    if (!isEmpty(currentOrder)) {
      return (
        currentOrder.status?.slug !== 'aberta' ||
        moment().isAfter(moment(currentOrder.participationDeadline))
      )
    }
    return true
  }, [currentOrder])

  const locations = useMemo(
    () =>
      map(
        currentOrganizationPurchase
          ? currentOrganizationPurchase.deliveryLocations
          : [],
        (org) => ({
          cnpj: org.organization.companyDocument,
          amount: org.amount,
          billingOrganizationId: org.billingOrganizationId,
          note: org.note,
          ...org.organization,
          cep: org.organization.postalCode,
          neighborhood: org.organization.district,
          deliveryId: org.id,
          received: org.received
        })
      ),
    [currentOrganizationPurchase]
  )

  const defaultPaymentForm = useMemo(
    () =>
      find(
        currentOrder?.organizationPurchases,
        ({ organizationId }) => currentOrganization.id === organizationId
      )?.proposalPaymentForm?.id || '',
    [currentOrder.organizationPurchases, currentOrganization]
  )

  const handleViewClick = useCallback(() => {
    createDialog({
      id: 'view-docs',
      Component: ViewBankSlipModal,
      props: {
        purchase: currentOrganizationPurchase
      }
    })
  }, [createDialog, currentOrganizationPurchase])

  const handleNonCompliance = useCallback(
    (deliveryId) => {
      history.push(
        `/shopping-platform/order/${ orderId }/non-compliance/${ deliveryId }`
      )
    },
    [history, orderId]
  )

  const onEdit = useCallback(() => {
    history.replace(
      routes.joinOrder.path.replace(':orderId', currentOrder.id),
      { isEditing: true }
    )
  }, [currentOrder.id, history, routes.joinOrder.path])

  const onExit = useCallback(() => {
    dispatch(
      OrderActions.exitOfflineOrder(() => history.push(routes.orderList.path))
    )
  }, [dispatch, history, routes.orderList.path])

  const rejectProposal = useCallback(() => {
    createDialog({
      id: 'confirm-delete',
      Component: RejectProposalModal,
      props: {
        onConfirm: (reason) => {
          dispatch(OrderActions.refuseOfflineBestProposal(
            bestProposal.id,
            reason,
            () => {
              snackbar.success(t('all proposals were rejected'))
              history.replace(routes.orderList.path)
              setIsFormDisabled(true)
              setLoading(false)
            },
            () => {
              setLoading(false)
            }
          ))

        },
        textWarning: t('are you sure you want to reject the best proposal')
      }
    })
  }, [
    bestProposal.id,
    createDialog,
    dispatch,
    history,
    routes.orderList.path,
    snackbar,
    t
  ])

  const acceptProposal = useCallback(
    ({ proposalPaymentFormId }) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            setLoading(true)
            dispatch(
              OrderActions.acceptOfflineBestProposal(
                bestProposal.id,
                proposalPaymentFormId,
                () => {
                  snackbar.success(t('the proposal was accepted'))
                  history.replace(routes.orderList.path)
                  setIsFormDisabled(true)
                  setLoading(false)
                },
                () => {
                  setLoading(false)
                }
              )
            )

          },
          textWarning: t('are you sure you want to choose this form of payment')
        }
      })
    },
    [
      bestProposal.id,
      createDialog,
      dispatch,
      history,
      routes.orderList.path,
      snackbar,
      t
    ]
  )

  const ListAmountPaid = useCallback(() => {
    createDialog({
      id: 'complete-crop-management',
      Component: ViewListAmountPaidModal,
      props: {
        productId: product.id
      }
    })
  }, [createDialog, product])

  const ListSuppliersByProducts = useCallback(() => {
    createDialog({
      id: 'list-suppliers-by-products',
      Component: ViewListSuppliersByProducts,
      props: {
        data: suppliersProduct,
        proposals: currentOrder?.purchaseProposals
      }
    })
  }, [createDialog, currentOrder, suppliersProduct])

  useLayoutEffect(() => {
    setLoading(true)
    dispatch(
      OrderActions.loadCurrentOrder(
        orderId,
        (data) => {
          setLoading(false)
          if (!isEmpty(data) && !verifiedCurrentOrder) {
            const isParticipating = find(
              data.organizationPurchases,
              ({ organizationId }) => organizationId === currentOrganization.id
            )
            const path = routes.joinOrder.path.replace(':orderId', orderId)
            if (!isParticipating) {
              history.replace(path, { accepted })
            } else {
              setVerifiedCurrentOrder(true)
            }
          }
        },
        () => history.replace(routes.orderList.path),
        accepted
      )
    )
    dispatch(OrderActions.loadBestProposal(orderId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => () => {
      dispatch(OrderActions.resetCurrentOrder())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  )

  useEffect(() => {
    if (orderId) {
      setVerifiedCurrentOrder(false)
    }
  }, [orderId, currentOrganization])

  useEffect(() => {
    if(product?.id) {
      dispatch(OrderActions.loadSuppliersByProduct(
        product?.id,
        (data) => {
          if(isEmpty(data)) {
            snackbar.warning('Não existe fornecedor cadastrado para este produto.')
          } else {
            setSuppliersProduct(data)
          }
        },
        () => {
          snackbar.warning('Não existe fornecedor cadastrado para este produto.')
        }
      ))
    }
  }, [product, dispatch, snackbar])

  const showNonComplianceButton = useMemo(() => {
    if (!currentOrder?.status) {
      return false
    }
    return find(
      ['aguardando_entrega', 'atrasada', 'entregue', 'finalizado'],
      (item) => item === currentOrder?.status?.slug
    )
  }, [currentOrder])

  const showListAmountPaidButton = useMemo(
    () =>
      find(
        [
          'aguardando_decisao',
          'proposta_aceita',
          'aguardando_entrega',
          'atrasada',
          'entregue',
          'finalizado'
        ],
        (item) => item === currentOrder?.status?.slug
      ),
    [currentOrder]
  )

  const demandStatusFragmentStatus = useMemo(
    () => {
      if (currentOrder?.status?.slug === 'aguardando_decisao') {
        return 'aguardando_cooperativas'
      }
      if(currentOrder?.status?.slug === 'aguardando_entrega' && !!find(currentOrder?.organizationPurchases, item => item.proposalAccepted === false && item.organizationId === currentOrganization.id)) {
        return 'recusado'
      }

      return currentOrder?.status?.slug
    },
    [currentOrder, currentOrganization]
  )

  return loading ? (
    <Loader width={ 100 } />
  ) : (
    verifiedCurrentOrder && (
      <SplitedScreenLayout
        title={ { name: t('order', { howMany: 2 }) } }
        leftChildren={ <OrderDetailsFragment /> }
        rightHeaderChildren={
          <div style={ { marginTop: 60 } }>
            <DemandStatusFragment
              openingDate={ currentOrder?.createdAt }
              closingDate={ currentOrder?.participationDeadline }
              proposalDeadlineDate={ currentOrder?.proposalDeadline }
              organizationDecisionLimitDate={ currentOrder?.decisionDeadline }
              deliveryDate={ currentOrder?.receiptDeadline }
              status={ demandStatusFragmentStatus }
            />
            <ButtonContainer style={ { flex: '0 0 auto' } }>
              {product?.id && (
                <Button
                  id="open-suppliers-by-products"
                  onClick={ ListSuppliersByProducts }
                  disabled={ isEmpty(suppliersProduct) }
                  style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 20 } }
                  color={ colors.white }
                >
                  <I18n>open suppliers by products</I18n>
                </Button>
              )}
              {showListAmountPaidButton && (
                <Button
                  id="open-register-purchase"
                  onClick={ ListAmountPaid }
                  style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 20 } }
                  color={ colors.white }
                >
                  <I18n>see paid price history</I18n>
                </Button>
              )}
              <Button
                id="my-docs"
                color="white"
                onClick={ handleViewClick }
                style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 20 } }
                disabled={ !showNonComplianceButton }
              >
                <>
                  <Icon icon={ doc } size={ 20 } style={ { paddingRight: 6 } } />
                  <I18n>view documents</I18n>
                </>
              </Button>
              { showEdit && (
                <Button
                  id="leave-order"
                  color="white"
                  onClick={ onExit }
                  style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 20 } }
                  disabled={ disableLeaveOrder }
                >
                  <>
                    <Icon style={ { paddingRight: 6 } } icon={ logout } size={ 20 } />
                    <I18n>leave order</I18n>
                  </>
                </Button>
              ) }

              {showEdit && (
                <Button
                  id="filter"
                  onClick={ onEdit }
                  color="white"
                  style={ { flex: 'none', whiteSpace: 'nowrap' } }
                  disabled={ disableActions }
                >
                  <>
                    <Icon style={ { paddingRight: 6 } } icon={ edit } size={ 14 } />
                    <I18n>edit</I18n>
                  </>
                </Button>
              )}

            </ButtonContainer>
          </div>
        }
        rightChildren={
          <Container style={ { flex: 1 } }>
            {willShowBestProposalForm && (
              <AcceptBestProposalForm
                ref={ acceptProposalRef }
                proposal={ bestProposal }
                deliveryPercentage={ currentOrder?.deliveryPercentage || 100 }
                onAcceptProposal={ acceptProposal }
                onRejectProposal={ rejectProposal }
                changeDecision={ () => setIsFormDisabled(!isFormDisabled) }
                defaultValue={ defaultPaymentForm }
                disabled={ isFormDisabled }
                proposalAccepted={ currentOrganizationPurchase?.proposalAccepted }
                waitingDelivery={ waitingDelivery }
                currentOrganization={ currentOrganization }
              />
            )}
            <Header>
              <IconContainer>
                <Icon
                  icon={ organizationRounded }
                  color={ colors.secondary }
                  size={ 26 }
                />
              </IconContainer>
              <Title style={ { fontSize: 16, fontWeight: 600, margin: 0 } }>
                <I18n>your purchase in this order</I18n>
              </Title>
            </Header>
            <Row>
              {map(
                willShowProposalLabel ? orderDetailsWithProposal : orderDetails,
                ({ subtitle, value }, index) => value ? (
                  <InformationContainer key={ index }>
                    <InformationContent>
                      <Title style={ { fontSize: 16, fontWeight: 600, display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
                        <I18n params={ { howMany: 1 } }>{subtitle}</I18n>
                        {subtitle === 'proposal' && (
                          <DemandStatus style={ { marginLeft: '5px' } } slug={ currentOrganizationPurchase?.proposalAccepted ? 'proposta_aceita' : 'recusado' } small/>
                        )}
                      </Title>
                      {value}
                    </InformationContent>
                  </InformationContainer>
                ) : null
              ) }
            </Row>
            <RowLocations>
              <Header>
                <HeaderDiv>
                  <Title style={ { margin: 0, fontSize: 16, fontWeight: 600 } }>
                    <I18n>delivery locations</I18n>
                  </Title>
                </HeaderDiv>
              </Header>
              {map(locations, (loc, index) => (
                <AddressFragment
                  location={ loc }
                  freightValue={ currentOrder?.freightType === 'CFA' ? find(acceptedProposal?.deliveryFreightValues || bestProposal?.deliveryFreightValues, item => item?.deliveryLocationId === loc?.deliveryId)?.value : null }
                  deliveryLocations={ currentOrganizationAdresses.filter((item) => item.active) }
                  productUnit={ productUnit }
                  slug={ slug }
                  key={ index }
                  handleNonCompliance={ handleNonCompliance }
                  showNonComplianceButton={ showNonComplianceButton }
                  resetOrder={ () => {
                    dispatch(
                      OrderActions.loadCurrentOrder(
                        orderId,
                        (data) => {
                          setLoading(false)
                          if (!isEmpty(data) && !verifiedCurrentOrder) {
                            const isParticipating = find(
                              data.organizationPurchases,
                              ({ organizationId }) => organizationId === currentOrganization.id
                            )
                            const path = routes.joinOrder.path.replace(':orderId', orderId)
                            if (!isParticipating) {
                              history.replace(path)
                            } else {
                              setVerifiedCurrentOrder(true)
                            }
                          }
                        },
                        () => history.replace(routes.orderList.path),
                        accepted
                      )
                    )
                  } }
                />
              ))}
            </RowLocations>
          </Container>
        }
      />
    )
  )
})

export default OrderDetailsScreen
