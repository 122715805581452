import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import { colors } from '@smartcoop/styles'
import background from '@smartcoop/styles/assets/images/homeCardBackground.svg'
import fonts from '@smartcoop/styles/fonts'
import Button from '@smartcoop/web-components/Button'
import Card from '@smartcoop/web-components/Card'

export const Container = styled.div`
  flex: 1;
  width: calc(100% - 8px);
  margin-top: 10px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const CardItemTitle = styled(Card).attrs(props => ({
  cardStyle: {
    width: '100%',
    padding: 0,
    ...(props.cardStyle || {})
  },
  headerTitleProps: {
    align: 'left',
    style: {
      fontWeight: fonts.fontWeight.bold,
      padding: '10px 15px',
      borderBottom: `2px solid ${ colors.lightGrey }`
    }
  },
  childrenStyle: {
    padding: '5px 20px 17px',
    ...(props.childrenStyle || {})
  }
}))`
  margin-bottom: 0;
  margin-top: 7.5px;
  width: 100%;
`

export const CardItemBackground = styled(Card).attrs({
  cardStyle: {
    width: '100%',
    backgroundImage: `url('${ background }')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center right',
    padding: 20
  },
  headerStyle: {
    marginBottom: 0
  }
})`
  margin-bottom: 15px;
  margin-top: 0;
  width: 100%;
`

export const HeaderTitle = styled(Typography).attrs({
  variant: 'h5'
})`
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const HeaderTitleBold = styled(HeaderTitle)`
  font-weight: ${ fonts.fontWeight.bold };
`

export const CardButton = styled(Button).attrs({
  color: 'white'
})`
  width: 100%;
  margin-bottom: 15px;
`
