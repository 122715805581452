import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import Card from '@smartcoop/web-components/Card'

export default makeStyles({
  invertArrow: {
    transform: 'rotate(180deg)'
  }
})

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const ItemContainer = styled(Card).attrs({
  headerStyle: { padding: '8px 16px 3px' },
  cardContentProps: { style: { paddingTop: 0 } }
})`
  width: 100%;
  margin: 0;
`
