import api from '../api'

export const getOrders = (params) => api.get('/v1/purchase-demands', { params })

export const getPurchaseDemandsParams = () => api.get('v1/purchase-demands/params')

export const getOrdersByOrganization = (params, { organizationId }) =>
  api.get(`/v1/purchase-demands/orgs/${ organizationId }`, { params })

export const getMyOrdersByOrganization = (params, { organizationId }) =>
  api.get(`/v1/purchase-demands/orgs/${ organizationId }/my-demands`, { params })


export const getOrdersBySupplier = (params, { supplierId }) =>
  api.get(`/v1/purchase-demands/supplier/${ supplierId }`, { params })

export const getOrderById = ({ orderId }, params) => api.get(`/v1/purchase-demands/${ orderId }`, { params })

export const createOrder = async (params) => api.post('/v1/purchase-demands', params)

export const joinOrder = (params, { orderId }) => api.post(`/v1/purchase-demands/${ orderId }/orgs`, params)

export const exitOrder = ({ organizationPurchaseId, orderId }) => api.delete(`/v1/purchase-demands/${ orderId }/orgs/${ organizationPurchaseId }`)

export const editJoinOrder = (params, { organizationPurchaseId, orderId }) => api.patch(`/v1/purchase-demands/${ orderId }/orgs/${ organizationPurchaseId }`, params)

export const acceptProposal = async ({ proposalId }) => api.patch(`/v1/purchase-demands/proposal/${ proposalId }/accept`)

export const rejectProposals = async (params, { mainPurchaseId }) => api.patch(`/v1/purchase-demands/proposal/${ mainPurchaseId }/reject-all`, params)

export const receiveProduct = async (params, { deliveryLocationId }) => api.post(`/v1/purchase-demands/delivery-locations/${ deliveryLocationId }/receipt`, params)

export const attachBankSlip = (params, { deliveryLocationId, supplierId }) =>
  api.post(`/v1/purchase-demands/delivery-locations/${ deliveryLocationId }/bank-slip/${ supplierId }`, params, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

export const getBankSlips = async (params) => api.get('/v1/purchase-demands/orgs/bank-slips', { params })

export const getPurchaseDemandsStatus = async (params) => api.get('/v1/purchase-demands/status', { params })

export const deleteBankSlip = async ({ bankSlipId }) =>
  api.delete(`/v1/purchase-demands/bank-slip/${ bankSlipId }`)
