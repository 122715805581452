import React, { useCallback, useRef,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import toNumber from 'lodash/toNumber'

import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { FieldsMonitorationActions } from '@smartcoop/stores/fieldsMonitoration'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import Modal from '@smartcoop/web-components/Modal'
import RainRecordForm from '@smartcoop/web-containers/forms/digitalProperty/field/RainRecordForm'

import useStyles, { Content, Row } from './styles'

const RainRecord = ({
  id,
  open,
  propertyId,
  data,
  onSuccess,
  handleClose
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const t = useT()
  const RainRecordFormRef = useRef(null)
  const dispatch = useCallback(useDispatch(), [])

  const currentProperty = useSelector(selectCurrentProperty)

  const [loading, setLoading] = useState(false)

  const snackbarSuccess = useCallback(
    (precipitationId) => (
      precipitationId ? (
        snackbar.success(
          t('your {this} was edited', {
            howMany: 1,
            gender: 'male',
            this: t('rain record')
          })
        )
      ) : (
        snackbar.success(
          t('your {this} was registered', {
            howMany: 1,
            gender: 'male',
            this: t('rain record')
          })
        )
      )
    ),
    [snackbar, t]
  )

  const handleSubmit = useCallback(
    (formValues) => {
      setLoading(true)
      dispatch(FieldsMonitorationActions.saveFieldsMonitoration(
        {
          ...data,
          occurrenceDate: formValues.occurrenceDate,
          fieldsId: formValues.fieldsId,
          rainMm: toNumber(formValues.rainMm),
          frost: formValues.events.includes('frost'),
          hail: formValues.events.includes('hail')
        },
        () => {
          onSuccess()
          snackbarSuccess(data.id)
          handleClose()
        },
        () => {
          setLoading(false)
        },
        currentProperty?.id,
        !!data?.id
      ))

    },
    [currentProperty, data, dispatch, handleClose, onSuccess, snackbarSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row style={ { marginBottom: '10px' } }>
          <I18n>rain record</I18n>
        </Row>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 700 } }
      maxWidth='md'
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      loading={ loading }
    >
      <>
        <Content>
          <RainRecordForm
            ref={ RainRecordFormRef }
            onSubmit={ handleSubmit }
            handleClose={ handleClose }
            propertyId={ propertyId }
            data={ data }
          />
        </Content>
      </>
    </Modal>
  )
}

RainRecord.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  propertyId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  data: PropTypes.object
}

RainRecord.defaultProps = {
  onSuccess: () => {},
  data: {}
}

export default RainRecord
