import styled from 'styled-components/native'

export const Container = styled.View`
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const ButtonContainer = styled.View`
  flex: 1 1 auto;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
`
