import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { select, call, all, put, takeLatest } from 'redux-saga/effects'

import { v4 as uuidv4 } from 'uuid'

import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'

import {
  createAnimalProtocol,
  editAnimalProtocol,
  deleteAnimalProtocol,
  getAnimalProtocols
} from '@smartcoop/services/apis/smartcoopApi/resources/protocols'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { OfflineAnimalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineAnimalUserData/duckOfflineAnimalUserData'
import { selectOfflineAnimals } from '@smartcoop/stores/offlineData/userData/offlineAnimalUserData/selectorOfflineAnimalUserData'
import { OfflineTechnicalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData'
import { selectOfflineTechnicalAnimals } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData/selectorOfflineTechnicalUserData'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'

import { selectModuleIsTechnical } from '../module/selectorModule'
import { selectIsConnected } from '../network/selectorNetwork'
import { AnimalProtocolsActions, AnimalProtocolsTypes } from './duckAnimalProtocol'

function* loadAnimalProtocols({ params = {}, onSuccess= () => {}, onError = () => {} }) {
  try {
    const currentAnimal = yield select(selectCurrentAnimal)

    const { data: { data, ...pagination } } = yield call(
      getAnimalProtocols,
      {
        limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
        ...params
      },
      { animalId: currentAnimal?.id }
    )

    yield call(onSuccess)
    yield put(AnimalProtocolsActions.loadAnimalProtocolsSuccess(
      data,
      pagination.page
    ))
  } catch (err) {
    const error = err.message
    yield put(AnimalProtocolsActions.animalProtocolsError(error))
    yield call(onError, error)
  }
}


function* saveAnimalProtocols({ params, onSuccess = () => {}, onError = () => {}, isEdit = false, isConnected }) {
  try {
    const { data } = yield call(
      !isEdit ? createAnimalProtocol : editAnimalProtocol,
      {
        ...params,
        numberOfDays: toNumber(params?.numberOfDays)
      },
      { propertyId: params?.propertyId ?? null, dryingId: isEdit ? params?.id : null }

    )

    if(isConnected) {
      yield call(onSuccess, data)
    }
  } catch (err) {
    const error = err.message
    yield put(AnimalProtocolsActions.animalProtocolsError(error))
    yield call(onError, error)
  }
}

function* saveOfflineAnimalProtocols({ params, onSuccess = () => {}, onError = () => {}, web = false }) {
  try {
    const currentAnimal = yield select(selectCurrentAnimal)
    const currentProperty = yield select(selectCurrentProperty)
    const isTechnical = yield select(selectModuleIsTechnical)
    const isConnected = web ? true : yield select(selectIsConnected)
    // eslint-disable-next-line no-nested-ternary
    const animals = web ? null : (isTechnical ? yield select(selectOfflineTechnicalAnimals) : yield select(selectOfflineAnimals))

    if(!web) {
      const newProtocol = {
        ...params,
        id: params?.id,
        // eslint-disable-next-line no-unused-vars
        ids: params?.id ? [params?.id] : map(params?.animalsId, _ => uuidv4()),
        propertyId: currentProperty?.id
      }
      const updatedAnimals = map(params?.animalsId, id => {
        const foundAnimal = find(animals, each => each.id === id)
        return {
          ...currentAnimal,
          protocols: [
            ...filter(foundAnimal?.protocols, item => item.id !== params.id),
            newProtocol
          ]
        }
      })

      if (isTechnical) {
        yield all(updatedAnimals.map(animal => put(OfflineTechnicalUserDataActions.updateOfflineAnimal(animal))))
      } else {
        yield all(updatedAnimals.map(animal => put(OfflineAnimalUserDataActions.updateOfflineAnimal(animal))))
      }
      yield put(AnimalProtocolsActions.saveAnimalProtocols(newProtocol, onSuccess, onError, params?.id, isConnected))

    }

    yield put(AnimalProtocolsActions.saveAnimalProtocols({ ...params, propertyId: currentProperty?.id }, onSuccess, onError, !!params?.id, isConnected))

  } catch (err) {
    const error = err.message
    yield put(AnimalProtocolsActions.animalProtocolsError(error))
    yield call(onError, error)
  }
}

function* deleteAnimalProtocols({ id, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteAnimalProtocol, { protocolId: id })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(AnimalProtocolsActions.animalProtocolsError(error))
    yield call(onError, error)
  }
}

function* deleteOfflineAnimalProtocols({ id, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(OfflineAnimalUserDataActions.removeOfflineProtocol(id))

    yield put(AnimalProtocolsActions.deleteAnimalProtocols(id, onSuccess, onError))

  } catch (err) {
    const error = err.message
    yield put(AnimalProtocolsActions.animalProtocolsError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(AnimalProtocolsTypes.LOAD_ANIMAL_PROTOCOLS, loadAnimalProtocols),
  takeLatest(AnimalProtocolsTypes.SAVE_ANIMAL_PROTOCOLS, saveAnimalProtocols),
  takeLatest(AnimalProtocolsTypes.SAVE_OFFLINE_ANIMAL_PROTOCOLS, saveOfflineAnimalProtocols),

  takeLatest(AnimalProtocolsTypes.DELETE_ANIMAL_PROTOCOLS, deleteAnimalProtocols),
  takeLatest(AnimalProtocolsTypes.DELETE_OFFLINE_ANIMAL_PROTOCOLS, deleteOfflineAnimalProtocols)

]
