import { createActions, createReducer } from 'reduxsauce'

import filter from 'lodash/filter'
import map from 'lodash/map'
import remove from 'lodash/remove'

import { AuthenticationTypes } from '../../../authentication'

// Initial state
const INITIAL_STATE = {
  producers: [],
  animals:[],
  offlineCurrentOwner: null,
  technicalOrganization: null,
  productivityChallengeFormData: [],
  technicalForms: [],
  evaluationProducers: [],
  error: null,
  technicalGoals: []
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadData: ['onFinish'],

  loadAllProducers: ['onFinish'],
  loadAllProducersSuccess: ['producers'],

  loadOfflineTechnicalEvaluationData: ['onFinish'],
  loadOfflineTechnicalEvaluationDataSuccess: ['technicalForms', 'evaluationProducers'],

  loadTechnicalOrganization: ['onFinish'],
  loadTechnicalOrganizationSuccess: ['technicalOrganization'],

  loadAllAnimals: ['onFinish'],
  loadAllAnimalsSuccess: ['animals'],

  loadOfflineTechnicalGoals: ['onFinish'],
  loadOfflineTechnicalGoalsSuccess: ['technicalGoals'],

  setOfflineCurrentOwner: ['offlineCurrentOwner'],

  updateOfflineTechnicalCurrentField: ['field', 'propertyId', 'cropManagement'],
  deleteOfflineTechnicalCurrentField: ['fieldId', 'propertyId'],

  loadOfflineProductivityChallengeData: ['onFinish'],
  loadOfflineProductivityChallengeDataSuccess: ['productivityChallengeFormData' ],

  updateOfflineTechnicalPrecipitations: ['precipitation', 'propertyId'],
  deleteOfflineTechnicalPrecipitation: ['precipitationId', 'propertyId'],

  updateOfflineTechnicalMilkControl: ['milkControl', 'propertyId'],
  deleteOfflineTechnicalMilkControl: ['milkControlId', 'propertyId'],

  updateOfflineAnimal: ['animal'],

  offlineTechnicalUserDataError: ['error']
}, { prefix: 'OFFLINE_TECHNICAL_USER_DATA' })

/**
 * Reducers functions
 */

const loadOfflineProductivityChallengeDataSuccess = (state = INITIAL_STATE, { productivityChallengeFormData }) => ({
  ...state,
  productivityChallengeFormData
})

const loadAllProducersSuccess = (state = INITIAL_STATE, { producers }) => ({
  ...state,
  error: INITIAL_STATE.error,
  producers
})

const loadTechnicalOrganizationSuccess = (state = INITIAL_STATE, { technicalOrganization }) => ({
  ...state,
  error: INITIAL_STATE.error,
  technicalOrganization
})

const loadAllAnimalsSuccess = (state = INITIAL_STATE, { animals }) => ({
  ...state,
  error: INITIAL_STATE.error,
  animals
})

const loadOfflineTechnicalGoalsSuccess = (state = INITIAL_STATE, { technicalGoals }) => ({
  ...state,
  error: INITIAL_STATE.error,
  technicalGoals
})

const setOfflineCurrentOwner = (state = INITIAL_STATE, { offlineCurrentOwner }) => ({
  ...state,
  error: INITIAL_STATE.error,
  offlineCurrentOwner
})


const offlineTechnicalUserDataError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const updateOfflineTechnicalCurrentField = (state = INITIAL_STATE, { field, propertyId, cropManagement }) => ({
  ...state,
  producers: map(state?.producers, producer => {
    if(producer?.id === state?.offlineCurrentOwner?.id) {
      return {
        ...producer,
        userProperties: map(producer?.userProperties, prop => {
          if(prop?.propertyId === propertyId){
            const fields = prop?.property?.fields || []

            if(cropManagement) {
              map(field, (item) => {
                remove(fields, (removeField) => removeField?.id === item?.id)
              })
            }
            return {
              ...prop,
              property: {
                ...prop?.property,
                fields: cropManagement ? [...field, ...fields] : [field , ...filter(prop?.property?.fields, (item) => item?.id !== field?.id)]
              }
            }
          }
          return prop
        })
      }
    }
    return producer
  }),
  offlineCurrentOwner: {

    ...state?.offlineCurrentOwner,
    userProperties: map(state?.offlineCurrentOwner?.userProperties, prop => {

      if(prop?.propertyId === propertyId){
        const fields = prop?.property?.fields || []
        if(cropManagement) {
          map(field, (item) => {
            remove(fields, (removeField) => removeField?.id === item?.id)
          })
        }
        return {
          ...prop,
          property: {
            ...prop?.property,
            fields: cropManagement ? [...field, ...fields] : [field, ...filter(prop?.property?.fields, (item) => item?.id !== field?.id)]
          }
        }
      }
      return prop
    })
  }
})

const deleteOfflineTechnicalCurrentField = (state = INITIAL_STATE, { fieldId, propertyId }) => ({
  ...state,
  producers: map(state.producers, producer => {
    if(producer.id === state?.offlineCurrentOwner?.id) {
      return {
        ...producer,
        userProperties: map(producer.userProperties, prop => {
          if(prop.propertyId === propertyId){
            return {
              ...prop,
              property: {
                ...prop.property,
                fields: [...filter(prop.property.fields, (item) => item.id !== fieldId)]
              }
            }
          }
          return prop
        })
      }
    }
    return producer
  }),
  offlineCurrentOwner: {
    ...state?.offlineCurrentOwner,
    userProperties: map(state?.offlineCurrentOwner.userProperties, prop => {
      if(prop.propertyId === propertyId){
        return {
          ...prop,
          property: {
            ...prop.property,
            fields: [...filter(prop.property.fields, (item) => item.id !== fieldId)]
          }
        }
      }
      return prop
    })
  }
})

const deleteOfflineTechnicalPrecipitation = (state = INITIAL_STATE, { precipitationId, propertyId }) => ({
  ...state,
  producers: map(state.producers, producer => {
    if(producer.id === state?.offlineCurrentOwner?.id) {
      return {
        ...producer,
        userProperties: map(producer.userProperties, prop => {
          if(prop.propertyId === propertyId){
            return {
              ...prop,
              property: {
                ...prop.property,
                precipitations: filter(prop.property.precipitations, prep => precipitationId !== prep?.id)
              }
            }
          }
          return prop
        })
      }
    }
    return producer
  }),
  offlineCurrentOwner: {
    ...state?.offlineCurrentOwner,
    userProperties: map(state?.offlineCurrentOwner?.userProperties, prop => {
      if(prop.propertyId === propertyId){
        return {
          ...prop,
          property: {
            ...prop.property,
            precipitations: filter(prop.property.precipitations, prep => precipitationId !== prep?.id)
          }
        }
      }
      return prop
    })
  }
})

const deleteOfflineTechnicalMilkControl = (state = INITIAL_STATE, { milkControlId, propertyId }) => ({
  ...state,
  producers: map(state.producers, producer => {
    if(producer.id === state?.offlineCurrentOwner?.id) {
      return {
        ...producer,
        userProperties: map(producer.userProperties, prop => {
          if(prop.propertyId === propertyId){
            return {
              ...prop,
              property: {
                ...prop.property,
                milkControls: filter(prop.property.milkControls, milkControl => milkControlId !== milkControl?.id)
              }
            }
          }
          return prop
        })
      }
    }
    return producer
  }),
  offlineCurrentOwner: {
    ...state?.offlineCurrentOwner,
    userProperties: map(state?.offlineCurrentOwner?.userProperties, prop => {
      if(prop.propertyId === propertyId){
        return {
          ...prop,
          property: {
            ...prop.property,
            milkControls: filter(prop.property.milkControls, milkControl => milkControlId !== milkControl?.id)
          }
        }
      }
      return prop
    })
  }
})

const updateOfflineTechnicalMilkControl = (state = INITIAL_STATE, { milkControl, propertyId }) => ({
  ...state,
  producers: map(state.producers, producer => {
    if(producer.id === state?.offlineCurrentOwner?.id) {
      return {
        ...producer,
        userProperties: map(producer.userProperties, prop => {
          if(prop.propertyId === propertyId){
            return {
              ...prop,
              property: {
                ...prop.property,
                milkControls: [milkControl, ...filter(prop.property.milkControls, mc => mc?.id !== milkControl?.id)]
              }
            }
          }
          return prop
        })
      }
    }
    return producer
  }),
  offlineCurrentOwner: {
    ...state?.offlineCurrentOwner,
    userProperties: map(state?.offlineCurrentOwner?.userProperties, prop => {
      if(prop.propertyId === propertyId){
        return {
          ...prop,
          property: {
            ...prop.property,
            milkControls: [milkControl, ...filter(prop.property.milkControls, mc => mc?.id !== milkControl?.id)]
          }
        }
      }
      return prop
    })
  }
})

const updateOfflineTechnicalPrecipitations = (state = INITIAL_STATE, { precipitation, propertyId }) => ({
  ...state,
  producers: map(state.producers, producer => {
    if(producer.id === state?.offlineCurrentOwner?.id) {
      return {
        ...producer,
        userProperties: map(producer.userProperties, prop => {
          if(prop.propertyId === propertyId){
            return {
              ...prop,
              property: {
                ...prop.property,
                precipitations: [precipitation, ...filter(prop.property.precipitations, prep => precipitation?.id !== prep?.id)]
              }
            }
          }
          return prop
        })
      }
    }
    return producer
  }),
  offlineCurrentOwner: {
    ...state?.offlineCurrentOwner,
    userProperties: map(state?.offlineCurrentOwner?.userProperties, prop => {
      if(prop.propertyId === propertyId){
        return {
          ...prop,
          property: {
            ...prop.property,
            precipitations: [precipitation, ...filter(prop.property.precipitations, prep => precipitation.id !== prep.id)]
          }
        }
      }
      return prop
    })
  }
})

const updateOfflineAnimal = (state = INITIAL_STATE, { animal }) => ({
  ...state,
  animals: [animal, ...filter(state.animals, (item) => item.id !== animal.id)]
})

const loadOfflineTechnicalEvaluationDataSuccess = (state = INITIAL_STATE, { technicalForms, evaluationProducers }) => ({
  ...state,
  technicalForms,
  evaluationProducers
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_ALL_PRODUCERS_SUCCESS]: loadAllProducersSuccess,

  [Types.LOAD_ALL_ANIMALS_SUCCESS]: loadAllAnimalsSuccess,
  [Types.LOAD_OFFLINE_TECHNICAL_GOALS_SUCCESS]: loadOfflineTechnicalGoalsSuccess,
  [Types.LOAD_TECHNICAL_ORGANIZATION_SUCCESS]: loadTechnicalOrganizationSuccess,

  [Types.LOAD_OFFLINE_TECHNICAL_EVALUATION_DATA_SUCCESS]: loadOfflineTechnicalEvaluationDataSuccess,

  [Types.LOAD_OFFLINE_PRODUCTIVITY_CHALLENGE_DATA_SUCCESS]: loadOfflineProductivityChallengeDataSuccess,

  [Types.SET_OFFLINE_CURRENT_OWNER]: setOfflineCurrentOwner,

  [Types.UPDATE_OFFLINE_TECHNICAL_CURRENT_FIELD]: updateOfflineTechnicalCurrentField,
  [Types.DELETE_OFFLINE_TECHNICAL_CURRENT_FIELD]: deleteOfflineTechnicalCurrentField,

  [Types.UPDATE_OFFLINE_TECHNICAL_PRECIPITATIONS]: updateOfflineTechnicalPrecipitations,
  [Types.DELETE_OFFLINE_TECHNICAL_PRECIPITATION]: deleteOfflineTechnicalPrecipitation,

  [Types.UPDATE_OFFLINE_TECHNICAL_MILK_CONTROL]: updateOfflineTechnicalMilkControl,
  [Types.DELETE_OFFLINE_TECHNICAL_MILK_CONTROL]: deleteOfflineTechnicalMilkControl,

  [Types.UPDATE_OFFLINE_ANIMAL]: updateOfflineAnimal,

  [Types.OFFLINE_TECHNICAL_USER_DATA_ERROR]: offlineTechnicalUserDataError,
  [AuthenticationTypes.LOGOUT]: logout
})

export { Types as OfflineTechnicalUserDataTypes, Creators as OfflineTechnicalUserDataActions }
