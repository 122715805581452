import api from '../api'

export const getOperations = (params) => api.get('/v1/operation-365',  { params })
export const createOperation = (params) => api.post('/v1/operation-365', params)
export const createOperationFile = ({ operationId }, params) => api.post(`/v1/operation-365/${ operationId }/documents`, params)
export const updateOperation = (params) => api.patch(`/v1/operation-365/${ params?.id }`, params)
export const deleteOperation = ({ operationId }) => api.delete(`/v1/operation-365/${ operationId }`)
export const getAccreditations = (params) => api.get('/v1/operation-365/accreditation', { params })
export const requestAccreditment = (params) => api.post('/v1/operation-365/accreditation', params)
export const deleteAccreditment = ({ accreditmentId }, params) => api.delete(`/v1/operation-365/accreditation/${ accreditmentId }`, params)
export const editAccreditment = ( accreditmentId ,params) => api.patch(`/v1/operation-365/accreditation/${ accreditmentId }`,  params)

