import React from 'react'

import PropTypes from 'prop-types'

import { Top, LabelContainer, Label } from './styles'

const Header = ({ label, codeProposal, component }) => (
  <Top>
    <LabelContainer>
      <Label style={ { marginRight: '15px' } }>{label}</Label>
      <Label>{codeProposal}</Label>
      <Label>{component}</Label>
    </LabelContainer>
  </Top>
)

Header.propTypes = {
  label: PropTypes.string.isRequired,
  codeProposal: PropTypes.string.isRequired,
  component: PropTypes.any.isRequired
}

export default Header
