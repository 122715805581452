import React from 'react'

import PropTypes from 'prop-types'

import anaIa from '@smartcoop/styles/assets/images/ana-ia.jpg'
import Avatar from '@smartcoop/web-components/Avatar'


import { HeaderContainer, InfoWrapper, NameText, RightContainer } from './styles'

const IAHeader = ({ rightContainer }) => (
  <HeaderContainer>
    <>
      <InfoWrapper>
        <Avatar src={ anaIa } />
        <NameText>Ana - Smartcoop</NameText>
      </InfoWrapper>
      <RightContainer>
        {rightContainer}
      </RightContainer>
    </>
  </HeaderContainer>
)

IAHeader.propTypes = {
  rightContainer: PropTypes.element
}

IAHeader.defaultProps = {
  rightContainer: null
}

export default IAHeader
