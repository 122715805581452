// eslint-disable-next-line spaced-comment
export default ({ size, color = '#000' } = {}) => /*html*/`
<svg width="${ size }" height="${ size }" version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1080 1080" style="enable-background:new 0 0 1080 1080;" xml:space="preserve">
<path fill="${ color }" d="M957.58,819.18L738.24,340.83V151.77c27.23-8.65,47.26-32.64,48.78-61.61c0.98-19.08-5.68-37.19-18.79-51
	c-12.94-13.63-31.12-21.44-49.88-21.44H504.52c-38.06,0-69.33,28.6-71.25,65.08c-0.98,19.08,5.68,37.19,18.79,51
	c8.06,8.49,18.15,14.72,30,18.22v188.82L262.72,819.18c-23.86,52.05-19.66,111.88,11.25,160.07
	c30.93,48.19,83.55,76.96,140.81,76.96h390.73c57.25,0,109.87-28.76,140.81-76.96C977.23,931.06,981.43,871.23,957.58,819.18z
	 M906.97,954.01c-22.28,34.72-60.2,55.45-101.45,55.45H414.78c-41.25,0-79.17-20.73-101.45-55.45
	c-22.28-34.72-25.29-77.82-8.1-115.33l221.46-482.98c1.39-3.06,2.12-6.39,2.12-9.75V131.86c0-12.92-13.95-23.38-26.87-23.38
	c-6.1,0-11.76-2.44-15.96-6.87c-4.2-4.41-6.32-10.23-6-16.37c0.59-11.46,11.6-20.77,24.54-20.77h213.84c6.1,0,11.76,2.44,15.96,6.87
	c4.2,4.41,6.32,10.23,6,16.37c-0.59,11.46-11.6,20.77-25.45,20.77h-82.84c-12.92,0-23.38,10.46-23.38,23.38s10.46,23.38,23.38,23.38
	h59.47v190.71c0,3.36,0.73,6.69,2.12,9.75l221.46,482.98C932.26,876.18,929.25,919.28,906.97,954.01z"/>
<path fill="${ color }" class="st1" d="M868.31,860.13L743.62,587c-17.82,20-50.69,27.15-75.01,16.65c-21.48-9.27-36.47-27.02-59.59-33.37
	c-21.95-6.02-46.01-4.01-66.65,5.58c-13.04,6.06-42.48,28.26-71.97,28.26c-0.41,0.88-118.45,256-118.45,256
	c-14.2,30.98-1.46,56.55,4.65,66.09c6.15,9.56,24.09,31.8,58.17,31.8h390.73c34.09,0,52.03-22.24,58.17-31.78
	C869.79,916.67,882.53,891.11,868.31,860.13z M582.19,771.26c-30.81,0-55.78-24.98-55.78-55.78c0-30.81,24.97-55.78,55.78-55.78
	c30.81,0,55.78,24.97,55.78,55.78C637.97,746.28,612.99,771.26,582.19,771.26z M719.75,881.58c-18.71,0-33.87-15.17-33.87-33.87
	c0-18.71,15.16-33.87,33.87-33.87c18.7,0,33.87,15.17,33.87,33.87C753.61,866.42,738.45,881.58,719.75,881.58z"/>
</svg>
`
