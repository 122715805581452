import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getPowerBiToken } from '@smartcoop/services/apis/smartcoopApi/resources/bi'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import Loader from '@smartcoop/web-components/Loader'
import DirectionReportFragment from '@smartcoop/web-containers/fragments/reports/crm/DirectionReportFragment'

const DirectionReportScreen = () => {
  // const [reportType, setReportType] = useState('dashboard')
  const curentOrganization = useSelector(selectCurrentOrganization)

  const [token, setToken] = useState(null)
  const [isValidating, setIsValidating] = useState(true)

  const getMicrosoftToken = useCallback(async () => {
    const { data, isValidating: validating } = await getPowerBiToken()
    setToken(data)
    setIsValidating(validating)
    return data
  }, [])

  useEffect(() => {
    getMicrosoftToken()
  },[getMicrosoftToken])

  const accessToken = useMemo(
    () => token?.accessToken,
    [token]
  )

  const organizationId = useMemo(() => curentOrganization?.id, [curentOrganization])

  const loading = useMemo(
    () => (!accessToken && isValidating) || !organizationId || !accessToken,
    [accessToken, isValidating, organizationId]
  )

  return loading ? (
    <Loader width={ 100 } />
  ) : (
    <div style={ { display: 'flex', flex: 1, flexDirection: 'column' } }>
      <div style={ { display: 'flex', flex: 1 } }>
        <DirectionReportFragment getMicrosoftToken={ getMicrosoftToken } token={ accessToken } organizationId={ organizationId }/>
      </div>
    </div>
  )
}

export default DirectionReportScreen
