import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  height: auto;
  width: auto;

  display: flex;
  flex-direction: column;
`

export const RadioGroupContainer = styled.div`
  height: auto;
  width: auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`

export const Label = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`

export const LabelError = styled(Label)`
  color: ${ colors.error };
  font-weight: normal;
  padding-left: 0px;
  margin-bottom: 2px;
  font-size: 13px;
`
