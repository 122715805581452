import styled from 'styled-components'

import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'

export const Header = styled.div`
  flex-direction: row;
  margin: 10px 10px 10px 0px;
  align-items: center;
`
export const Badge = styled.div`
  background-color: rgba${ props => props.backgroundColorBadge };
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open Sans";
  color: rgb${ props => props.colorBadge };
`

export const Container = styled.div`
  flex-direction: column;
  flex: 1;
  text-align: center;
`

export const FormSelectionContainer = styled(Button)`
  border-top-color: #babaca;
  border-top-width: 1px;
  padding: 10px 20px;
`

export const Name = styled.span`
  font-weight: bold;
  color: ${ colors?.text };
`
