import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  container: {
    padding: `${ 0 } ${ 20 }px`,
    width: 200
  },
  listItemIcon: {
    minWidth: 40
  }
})
