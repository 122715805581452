import { createActions, createReducer } from 'reduxsauce'

/* Initial State */
const INITIAL_STATE = {
  data: {},
  error: null,
  familyGroupAccessList: []
}

/**
 * Creating actions and types with reduxsauce.
 */
export const { Types, Creators } = createActions({
  createFamilyGroupAccess: ['data', 'onSuccess', 'onError'],
  editFamilyGroupUser: ['data', 'onSuccess', 'onError'],
  editFamilyGroupUserAccess: ['data', 'onSuccess', 'onError'],
  searchUserByDocument: ['document', 'onSuccess'],
  loadFamilyGroupAccessList: ['params', 'onSuccess', 'onError'],
  loadFamilyGroupAccessListSuccess: ['familyGroupAccessList', 'page', 'onSuccess']
})


/**
 * Reducers functions
 */

const loadFamilyGroupAccessListSuccess = (
  state = INITIAL_STATE,
  { familyGroupAccessList, page }
) => ({
  ...state,
  error: INITIAL_STATE.error,
  familyGroupAccessList:
    page === 1
      ? familyGroupAccessList
      : [...state.requestAccessList, ...familyGroupAccessList]
})


/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_FAMILY_GROUP_ACCESS_LIST_SUCCESS]: loadFamilyGroupAccessListSuccess
})

export {
  Types as FamilyGroupTypes,
  Creators as FamilyGroupActions
}
