import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  width: 100%;
`

export const CardContainer = styled.div`
  background-color: ${ colors.white };
  padding: 20px;
  border-radius: 5px;
  margin-top: 10px;
  width: 100%;
`

export const Label = styled.span`
  font-weight: 700;
  font-size: 17px;
`

export const Table = styled.table`
  width: 100%;
  tr {
    text-align: left;
    width: 100%;
  }

  td {
    width: 33.3%;
  }
`
