import React, { useEffect, useCallback,useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import Backdrop from '@material-ui/core/Backdrop'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { SocialActions } from '@smartcoop/stores/social'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Feed from '@smartcoop/web-components/Feed'
import { CircularLoader } from '@smartcoop/web-components/Loader'
import Comment from '@smartcoop/web-containers/fragments/Comment'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import CommentModal from '@smartcoop/web-containers/modals/Social/commentModal'

import useStyles, { CommentDiv, LabelComments, FlexDiv } from './styles'


const INITIAL_FEED = {
  interestAreas: [{
    id: '',
    name: '',
    categoryId: '',
    category: {
      id: '',
      name: ''
    },
    color: ''
  }],
  id: '',
  text: '',
  userId: '',
  createdAt: '',
  updatedAt: '',
  totalComments: 0,
  user: {
    id: '',
    name: '',
    email: '',
    city: '',
    state: '',
    image: ''
  }
}

const Comments = () => {
  const t = useT()
  const params = useParams()
  const classes = useStyles()
  const location = useLocation()
  const { createDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])

  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(true)
  const [originalPost, setOriginalPost] = useState(INITIAL_FEED)

  const { state: { feed } } = location
  const { postId } = params

  useEffect( () => {
    setOriginalPost(feed)
  }, [feed])

  useEffect(() => {
    window.scrollTo(0,0) }, [])

  const dispatchError = () => {
    setLoading(false)
  }

  const loadCommentSuccess = (result) => {
    const { data } = result
    setComments([...data].reverse())
    setOriginalPost(state => ({ ...state, totalComments: result.totalRecords }))
    setLoading(false)
  }

  const getComments = useCallback( () => {
    setLoading(true)
    dispatch(SocialActions.loadComments({ postId }, loadCommentSuccess, dispatchError))
  }, [postId, dispatch])

  useEffect(() => {
    getComments()
  },[getComments])

  const createComment = useCallback(() => {
    createDialog({
      id: 'create-comment',
      Component: CommentModal,
      props: {
        originalPost: feed,
        getComments
      }
    })
  }, [createDialog, feed, getComments])

  return (
    <SplitedScreenLayout
      withGoBackRight
      withoutDivider
      title={ { name: 'Feed' } }
      rightGridStyle={ {
        backgroundColor: colors.backgroundHtml,
        maxWidth: '100%',
        flexBasis: '100%'
      } }
      divRightStyle={ {
        backgroundColor: colors.backgroundHtml,
        width: '100%',
        height: 'auto',
        padding: 0
      } }
      rightChildrenStyle={ { justifyContent: 'center' } }
      rightChildren={ (
        <CommentDiv>
          <Feed feed={ originalPost }/>
          <FlexDiv>
            <div><LabelComments>{t('social comments')}</LabelComments></div>
            <div><Button id='button' color='secondary' onClick={ createComment }>{t('social comment')}</Button></div>
          </FlexDiv>
          {comments.map((item)=> <Comment key={ `comment-${ item.id }` } comment={ item } originalPost={ feed } getComments={ getComments } />)}
          <Backdrop open={ loading } className={ classes.backdrop }>
            <CircularLoader />
          </Backdrop>
        </CommentDiv>
      ) }
    />
  )}

export default Comments
