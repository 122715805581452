import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'


import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterWeightControlForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterWeightControlForm'

import useStyles from './styles'

const WeightControlModal = (props) => {
  const {
    id,
    open,
    onSubmit,
    handleClose,
    animalId,
    weightControl
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()
  const propertyId = useSelector(selectCurrentPropertyId)

  const [isLoading, setIsLoading] = useState(false)

  const isEditing = useMemo(
    () => (!isEmpty(weightControl?.id)),[weightControl]
  )

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      handleClose()
    }, [handleClose]
  )

  const onSuccess = useCallback(
    () => {
      snackbar.success(
        t(`your {this} was ${  isEditing ? 'edited' :'registered' }`, {
          howMany: 1,
          gender: 'male',
          this: t('weight control')
        })
      )
      onSubmit()
      closeModal()

    }, [closeModal, isEditing, onSubmit, snackbar, t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(AnimalActions.saveAnimalWeight(
        {
          ...weightControl,
          ...data
        },
        onSuccess,
        () => setIsLoading(false),
        weightControl?.id
      ))
    },
    [dispatch, weightControl, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n params={ { this: t('weight control') } }>{'register {this}'}</I18n>
        </>
      }
      disableEsc
      classes={ { paper: classes.modalBackground } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      fullWidth
      maxWidth="sm"
    >
      <>
        {
          isLoading ? <Loader width={ 100 } /> :
            <RegisterWeightControlForm
              formRef={ formRef }
              defaultValues={ weightControl }
              onSubmit={ handleSubmit }
              onCancel={ closeModal }
              animalId={ animalId }
              propertyId={ propertyId }
            />
        }
      </>
    </Modal>
  )}

WeightControlModal.propTypes = {
  id: PropTypes.string.isRequired,
  animalId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  weightControl: PropTypes.object
}

WeightControlModal.defaultProps = {
  handleClose: () => {},
  onSubmit: () => {},
  animalId: null,
  weightControl: {}
}

export default WeightControlModal
