import styled from 'styled-components'

import I18n from '@smartcoop/i18n'

export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid #E0E0E0;
  margin: 10 0;
`
export const IconText = styled(I18n)`
  font-size: 18px;
  font-family: OpenSans;
  padding-left: 5px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0  10px 10px 10px;
`

export const IconLike = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: bold;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  column-gap: 10px;
`

