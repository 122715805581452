import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 25px 5px;
`

export const HeaderText = styled(Typography)`
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 22px;
  color: ${ colors.text };
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
`

export const ListWrapper = styled.div`
  overflow-y: auto;
  max-height: calc(70vh - 160px);
  margin-bottom: 20px;
  width: 100%;
  row-gap: 5px;
  display: flex;
  flex-direction: column;
`
