import React, { useCallback, useState, useRef,useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import toNumber from 'lodash/toNumber'

import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectCurrentProperty, selectPropertyParameters } from '@smartcoop/stores/property/selectorProperty'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import ParametersRegistrationForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/ParametersRegistrationForm/ParametersRegistrationForm'

import useStyles from './styles'

const ParametersRegistrationModal = (props) => {
  const {
    id,
    open,
    onSubmit,
    handleClose,
    onClose
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()
  const currentProperty = useSelector(selectCurrentProperty)
  const currentPropertyParameters = useSelector(selectPropertyParameters)

  const [isLoading, setIsLoading] = useState(false)

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      handleClose()
      onClose()
    }, [handleClose, onClose]
  )

  const onSuccess = useCallback(
    () => {
      snackbar.success(
        t('your {this} was edited', {
          howMany: 2,
          gender: 'male',
          this: t('parameter', { howMany: 2 })
        })
      )
      onSubmit()
      closeModal()

    }, [closeModal, onSubmit, snackbar, t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(PropertyActions.updatePropertyParameters(
        {
          ...data,
          heiferAge: toNumber(data?.heiferAge),
          animalGestation: toNumber(data?.animalGestation),
          id: currentPropertyParameters.id,
          propertyId: currentProperty?.id
        },
        onSuccess,
        () => setIsLoading(false)
      ))
    },
    [currentProperty, currentPropertyParameters, dispatch, onSuccess]
  )

  useEffect(() => {
    setIsLoading(true)
    dispatch(PropertyActions.loadParameters(
      () => setIsLoading(false),
      () => setIsLoading(false)
    ))
  }, [dispatch])

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n>parameters</I18n>
        </>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        {
          isLoading ? <Loader width={ 100 } /> :
            <ParametersRegistrationForm
              ref={ formRef }
              defaultValues={ currentPropertyParameters }
              onSubmit={ handleSubmit }
              onCancel={ closeModal }
            />
        }
      </>
    </Modal>
  )}

ParametersRegistrationModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
}

ParametersRegistrationModal.defaultProps = {
  handleClose: () => {},
  onSubmit: () => {},
  onClose: () => {}
}

export default ParametersRegistrationModal
