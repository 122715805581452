import React from 'react'

import PropTypes from 'prop-types'

import  I18n from '@smartcoop/i18n'
import Modal from '@smartcoop/web-components/Modal'
import LotList from '@smartcoop/web-containers/screens/authenticated/digitalProperty/dairyFarm/DairyFarmDashboardScreen/LotList'

import useStyles from './styles'

const LotListModal = (props) => {
  const {
    id,
    open
  } = props


  const classes = useStyles()

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n params={ { howMany: 2 } }>lot</I18n>
        </>
      }
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      maxWidth="md"
      fullWidth
    >
      <>
        <div style={ { padding: 10 } }>
          <LotList />
        </div>
      </>
    </Modal>
  )}

LotListModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
}

export default LotListModal
