import { tableSchema } from '@nozbe/watermelondb'


export const groupMemberSchema = tableSchema({
  name: 'group_member',
  columns: [
    { name: 'groupId', type: 'string' },
    { name: 'userId', type: 'string' },
    { name: 'userName', type: 'string' },
    { name: 'userCode', type: 'string' },
    { name: 'photo', type: 'string' },
    { name: 'profiles', type: 'string' },
    { name: 'isAdmin', type: 'boolean' },
    { name: 'isLeft', type: 'boolean' },
    { name: 'publicKey', type: 'string' }
  ]
})
