import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'

import {
  Badge
} from './styles'

const VisitModeStatus = props => {
  const {
    mode,
    style
  } = props

  const options = useMemo(() => {
    switch (mode) {
      case 'virtual':
        return {
          name: 'Virtual',
          background: '(22, 67, 183, 0.1)',
          color: '#1643b7'
        }
      case 'presential':
        return {
          name: 'Presencial',
          background: '(40, 159, 48, 0.1)',
          color: '#289f30'
        }
      default:
        return {
          name: <I18n>none</I18n>,
          background: '(29, 29, 27, 0.1)',
          color: '(29, 29, 27, 1)'
        }
    }
  }, [mode])

  return (
    <Badge
      backgroundColorBadge={ options.background }
      colorBadge={ options.color }
      style={ style }
    >
      { options.name }
    </Badge>
  )
}


VisitModeStatus.propTypes = {
  mode: PropTypes.oneOf([
    'presential',
    'virtual'
  ]),
  style: PropTypes.object
}

VisitModeStatus.defaultProps = {
  style: {},
  mode: 'presential'
}

export default VisitModeStatus
