import React, { useMemo, useState, useCallback, useRef } from 'react'
import { useSelector , useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'


import moment from 'moment/moment'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { filter, arrowLeft, frost, hail } from '@smartcoop/icons'
import { getPropertyPrecipitations as getPropertyPrecipitationsService } from '@smartcoop/services/apis/smartcoopApi/resources/fieldsMonitoration'
import { useSnackbar } from '@smartcoop/snackbar'
import { FieldsMonitorationActions } from '@smartcoop/stores/fieldsMonitoration'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import ReadMore from '@smartcoop/web-components/ReadMore'
import FilterRainRecordModal from '@smartcoop/web-containers/modals/DigitalProperty/FilterRainRecordModal'
import RainRecord from '@smartcoop/web-containers/modals/DigitalProperty/RainRecord/RainRecord'

import {
  Container,
  Row,
  ButtonContainer,
  BackButton
} from './styles'

const RainRecordList = () => {
  const { createDialog } = useDialog()
  const history = useHistory()
  const t = useT()
  const tableRef = useRef(null)
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const [filters, setFilters] = useState({})

  const currentProperty = useSelector(selectCurrentProperty)
  const userWrite = useSelector(selectUserCanWrite)

  const handleFilter = useCallback(
    (values) => setFilters(values),
    []
  )

  const filterData = useCallback(
    () => {
      createDialog({
        id: 'filter-record-list',
        Component: FilterRainRecordModal,
        props: {
          onSubmit: handleFilter,
          propertyId: currentProperty?.id,
          filters
        }
      })
    },
    [createDialog, currentProperty.id, filters, handleFilter]
  )

  const showIconRegister = useCallback(
    (row) => {
      if(row?.frost && row?.hail) {
        return (
          'Geada e Granizo'
        )
      }

      if(row?.frost && !row?.hail) {
        return (
          <div style={ { display: 'flex', justifyContent: 'center' } }>
            <div style={ { display: 'flex' } }>
              <Icon size={ 16 } icon={ frost } color={ colors.red } style={ { marginRight: 5 } }/>
              <p>Geada</p>
            </div>
          </div>
        )
      }

      if(!row?.frost && row?.hail){
        return (
          <div style={ { display: 'flex', justifyContent: 'center' } }>
            <div style={ { display: 'flex' } }>
              <Icon size={ 16 } icon={ hail } color={ colors.red } style={ { marginRight: 5 } }/>
              <p>Granizo</p>
            </div>
          </div>
        )
      }

      return '-'

    },
    []
  )

  const columns = useMemo(
    () => [
      {
        title: 'Data de Registro',
        field: 'occurrenceDate',
        render: (row) => moment(row.occurrenceDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
      },
      {
        title: 'Precipitação (mm)',
        field: 'rainMm',
        align: 'center'
      },
      {
        title: 'Geada/Granizo',
        field: 'name',
        align: 'center',
        render: (row) => showIconRegister(row)
      },
      {
        title: 'Aplicado aos talhões',
        field: 'name',
        align: 'center',
        render: (row) => (
          <ReadMore length={ 40 }>
            { map(row.fields, field => (field.fieldName)).join(', ') }
          </ReadMore>
        )
      }
    ], [showIconRegister]
  )

  const refreshTable = useCallback(
    () => {
      const { query } = tableRef.current.state
      tableRef.current.onQueryChange({
        ...query,
        page: 0
      })
    },
    []
  )

  const handleDelete = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(FieldsMonitorationActions.deleteOfflineFieldsMonitoration(
              row.id,
              () => {
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'male',
                    this: t('rain record')
                  })
                )
                refreshTable()
              }
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('rain record')
          })
        }
      })
    },
    [createDialog, dispatch, refreshTable, snackbar, t]
  )

  const openRainRecord = useCallback(
    (data = {}) => {
      createDialog({
        id: 'rain-record',
        Component: RainRecord,
        props: {
          propertyId: currentProperty?.id,
          onSuccess: refreshTable,
          data
        }
      })
    }, [createDialog, currentProperty.id, refreshTable])

  const onEditClick = useCallback(
    (event, data) => openRainRecord(data),
    [openRainRecord]
  )


  return (
    <Container>
      <Row>
        <ButtonContainer>
          <BackButton
            onClick={ () => history.goBack() }
          >
            <Icon icon={ arrowLeft } size={ 16 } />
            <I18n style={ { fontSize: 18, fontWeight: 700, paddingLeft: 5 } }>rain records</I18n>
          </BackButton>
          <div>
            <Button
              id="filter"
              onClick={ filterData }
              color={ isEmpty(filters) ? 'white' : 'secondary' }
              style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 10 } }
            >
              <>
                <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 }/>
                <I18n>filtrate</I18n>
              </>
            </Button>
            <Button
              id="register-rain-record"
              onClick={ (e) => {
                e.preventDefault()
                openRainRecord()
              } }
              color="secondary"
              style={ { flex: 'none', whiteSpace: 'nowrap' } }
              disabled={ !userWrite }
            >
              <I18n>register rain</I18n>
            </Button>
          </div>
        </ButtonContainer>
      </Row>
      <div>
        <DataTable
          tableRef={ tableRef }
          columns={ columns }
          data={ getPropertyPrecipitationsService }
          onEditClick={ onEditClick }
          queryParams={ filters }
          pageSize={ 50 }
          urlParams={ { propertyId: currentProperty?.id } }
          onDeleteClick={ handleDelete }
          disabled={ !userWrite }
          id="rain-record-list-table"
        />
      </div>
    </Container>
  )
}

export default RainRecordList
