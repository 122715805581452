/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'


import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { filter } from '@smartcoop/icons'
import { getProducts } from '@smartcoop/services/apis/smartcoopApi/resources/product'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import colors from '@smartcoop/styles/colors'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import InputSearchDebounced from '@smartcoop/web-components/InputSearchDebounced'
import FilterProductModal from '@smartcoop/web-containers/modals/shoppingPlatform/FilterProductModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  Container,
  Row,
  Title,
  ButtonContainer,
  SingleButtonContainer
} from './styles'

const ProductsListScreen = () => {
  const t = useT()
  const history = useHistory()
  const { routes } = useRoutes()
  const { createDialog } = useDialog()

  const currentOrganization = useSelector(selectCurrentOrganization)

  const [listAll, setListAll] = useState(true)
  const [filters, setFilters] = useState({})
  const [debouncedFilterText, setDebouncedFilterText] = useState('')

  const tableFilters = useMemo(
    () =>
      debouncedFilterText
        ? { ...filters, q: debouncedFilterText }
        : { ...filters },
    [debouncedFilterText, filters]
  )

  const columns = useMemo(
    () => [
      {
        title: t('productCode'),
        field: 'productCode'
      },
      {
        title: t('description'),
        field: 'description'
      },
      {
        title: t('unitOfMeasure', { howMany: 1 }),
        field: 'unitOfMeasures'
      },
      {
        title: t('product group'),
        field: 'productGroup.name'
      },
      {
        field: 'createdAt',
        sorting: true,
        defaultSort: 'desc',
        hidden: true
      }
    ],
    [t]
  )

  const handleFilter = useCallback((values) => setFilters({ ...values }), [])

  const filterData = useCallback(() => {
    createDialog({
      id: 'filter-order',
      Component: FilterProductModal,
      props: {
        onSubmit: handleFilter,
        filters,
        setFilters
      }
    })
  }, [createDialog, filters, handleFilter])

  const onChangeSearchFilter = useCallback((value) => {
    setDebouncedFilterText(value)
  }, [])

  const redirectCreateProduct = useCallback(() => {
    history.push(routes.registerProduct.path)
  }, [history, routes.registerProduct])

  const onEditClick = useCallback(
    (event, data) =>
      history.push(routes.editProduct.path, { productId: data.id }),
    [history, routes.editProduct.path]
  )

  return (
    <Container>
      <div style={ { display: 'flex', flexDirection: 'row', marginBottom: 15, marginTop: 5 } }>
        {currentOrganization?.logoData && (
          <div style={ { display: 'flex', flexDirection: 'row' } }>
            <img alt={ currentOrganization?.tradeName } src={ downloadFromBase64(currentOrganization?.logoData) } style={ { height: 60, width: 120, objectFit: 'contain', padding: 4 } } />
            <div style={ { marginRight: 10, marginLeft: 10, height: '100%', width: 8, backgroundColor: colors?.secondary } }/>
          </div>
        )}
        <Title>
          <I18n>products list</I18n>
        </Title>
      </div>

      <Row>
        <ButtonContainer>
          <Button
            id="all-products"
            onClick={ () => setListAll(true) }
            color={ listAll ? colors.black : colors.white }
            style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 12 } }
          >
            <I18n params={ { gender: 'male' } }>all</I18n>
          </Button>
        </ButtonContainer>

        <SingleButtonContainer>
          <InputSearchDebounced onChange={ onChangeSearchFilter } />
          <Button
            id="filter"
            onClick={ filterData }
            color={ isEmpty(filters) ? 'white' : 'secondary' }
            style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 20 } }
          >
            <>
              <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 } />
              <I18n>filtrate</I18n>
            </>
          </Button>
          <Button
            id="new-product"
            onClick={ redirectCreateProduct }
            color="secondary"
            style={ { flex: 'none', whiteSpace: 'nowrap' } }
          >
            <I18n>signup</I18n>
          </Button>
        </SingleButtonContainer>
      </Row>

      <DataTable
        data={ getProducts }
        columns={ columns }
        onEditClick={ onEditClick }
        queryParams={ tableFilters }
        id="product-list-table"
      />
    </Container>
  )
}

export default ProductsListScreen
