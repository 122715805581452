import React from 'react'

import PropTypes from 'prop-types'

import  AvatarGroupMui from '@material-ui/lab/AvatarGroup'


const AvatarGroup = (props) => {
  const {
    max,
    style,
    className,
    children,
    ...otherProps
  } = props


  return (
    <AvatarGroupMui max={ max } className={ className } style={ style } { ...otherProps }>
      {children}
    </AvatarGroupMui>
  )
}

AvatarGroup.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
  max: PropTypes.number
}

AvatarGroup.defaultProps = {
  style: {},
  className: '',
  max: 5,
  children: null
}

export default AvatarGroup
