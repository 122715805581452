import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment/moment'

import { isEmpty } from 'lodash'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { filter } from '@smartcoop/icons'
import { getAnimalPevList } from '@smartcoop/services/apis/smartcoopApi/resources/herdsManagement'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import {
  momentBackDateFormat,
  momentFriendlyDateFormat
} from '@smartcoop/utils/dates'
import Badge from '@smartcoop/web-components/Badge'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import FilterPevModal from '@smartcoop/web-containers/modals/dairyFarm/FilterPevModal'
import RegisterPevModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterPevModal'

import { Container, Row, RightContainer } from './styles'

const ListPevFragment = () => {
  const [filters, setFilters] = useState({})
  const tableRef = useRef(null)
  const { createDialog } = useDialog()
  const t = useT()
  const currentProperty = useSelector(selectCurrentProperty)
  const currentAnimal = useSelector(selectCurrentAnimal)
  const userWrite = useSelector(selectUserCanWrite)

  const dispatch = useCallback(useDispatch(), [])

  const dairyFarm = useMemo(() => currentProperty?.dairyFarm[0], [currentProperty])
  const urlParams = useMemo(() => ({ animalId: currentAnimal?.id }), [
    currentAnimal
  ])

  const reloadDataTable = useCallback(() => {
    tableRef.current.onQueryChange()
  }, [])

  const registerDisabled = useMemo(
    () =>
      currentAnimal?.animalStatus?.name!== 'PEV'
      || !userWrite,
    [currentAnimal, userWrite]
  )

  const handleEditClick = useCallback(
    (_, data) => {
      createDialog({
        id: 'edit-pev',
        Component: RegisterPevModal,
        props: {
          pev: data,
          reloadDataTable
        }
      })
    },
    [createDialog, reloadDataTable]
  )

  const columns = useMemo(
    () => [
      {
        title: t('insemination date'),
        field: 'inseminationDate',
        render: (row) =>
          moment(
            row?.insemination?.inseminationDate,
            momentBackDateFormat
          ).format(momentFriendlyDateFormat),
        sorting: true
      },
      {
        title: t('registry date'),
        field: 'registryDate',
        sorting: true,
        defaultSort: 'desc',
        render: (row) =>
          moment(row.registryDate, momentBackDateFormat).format(
            momentFriendlyDateFormat
          )
      },
      {
        title: t('reason'),
        field: 'reason',
        sorting: true
      },
      {
        title: t('able'),
        field: 'able',
        sorting: true,
        render: (row) => (
          <div style={ { display: 'flex' } }>
            <Badge
              backgroundColorBadge={ row.able ? '#289F30' : '#E41D1B' }
              colorBadge={ row.able ? 'rgb(40,159,48)' : 'rgb(228,29,27)' }
            >
              {row.able ? t('yes') : t('no')}
            </Badge>
          </div>
        )
      }
    ],
    [t]
  )

  const handleFilter = useCallback((values) => setFilters(values), [])

  const filterData = useCallback(() => {
    createDialog({
      id: 'filter-pev',
      Component: FilterPevModal,
      props: {
        onSubmit: handleFilter,
        filters
      }
    })
  }, [createDialog, filters, handleFilter])

  const registerPev = useCallback(() => {
    createDialog({
      id: 'register-pev',
      Component: RegisterPevModal,
      props: {
        reloadDataTable
      }
    })
  }, [createDialog, reloadDataTable])

  useEffect(
    () => {
      dispatch(AnimalActions.loadCurrentAnimal())
    },[dispatch]
  )

  return (
    <Container>
      <Row>
        <RightContainer>
          <Button
            id="filter"
            color="white"
            style={ {
              flex: 'none',
              whiteSpace: 'nowrap',
              backgroundColor: isEmpty(filters)
                ? colors.white
                : colors.secondary
            } }
            onClick={ filterData }
            disabled={ isEmpty(dairyFarm) }
          >
            <>
              <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 } />
              <I18n>filtrate</I18n>
            </>
          </Button>
          <Button
            id="new-pev"
            color="secondary"
            style={ { flex: 'none', whiteSpace: 'nowrap', marginLeft: 16 } }
            onClick={ registerPev }
            disabled={ registerDisabled }
          >
            <I18n>diagnostic pev</I18n>
          </Button>
        </RightContainer>
      </Row>

      <div style={ { marginTop: 20, width: 'calc(100% - 30px)' } }>
        {!isEmpty(dairyFarm) && (
          <DataTable
            tableRef={ tableRef }
            data={ getAnimalPevList }
            queryParams={ {
              ...filters,
              from: filters.from && filters.from[0],
              to: filters.to && filters.to[0]
            } }
            urlParams={ urlParams }
            columns={ columns }
            onEditClick={ handleEditClick }
            disabled={ !userWrite }
            id="list-pev-table"
          />
        )}
      </div>

    </Container>
  )
}

export default ListPevFragment
