import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { call, put, select, takeLatest } from 'redux-saga/effects'

import { v4 as uuidv4 } from 'uuid'

import {
  createFieldPrecipations,
  editFieldPrecipations,
  getPropertyPrecipitations,
  deleteFieldPrecipitation
} from '@smartcoop/services/apis/smartcoopApi/resources/fieldsMonitoration'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { OfflinePrecipitationUserDataActions } from '@smartcoop/stores/offlineData/userData/offlinePrecipitationUserData/duckOfflinePrecipitationUserData'
import { OfflineTechnicalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData/duckOfflineTechnicalUserData'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'

import { FieldsMonitorationActions, FieldsMonitorationTypes } from './duckFieldsMonitoration'

function* saveFieldsMonitoration( { fieldMonitoration, onSuccess = () => {}, onError = () => {}, propertyId, isEdit } ) {
  try {
    const currentProperty = yield select(selectCurrentProperty)

    yield call(
      isEdit ? editFieldPrecipations : createFieldPrecipations,
      fieldMonitoration,
      { propertyId: propertyId || currentProperty.id, precipitationId: fieldMonitoration?.id }
    )

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(FieldsMonitorationActions.fieldsMonitorationError(error))
    yield call(onError, error)
  }
}

function* saveOfflineFieldsMonitoration( { fieldMonitoration, onSuccess = () => {}, onError = () => {} } ) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const moduleIsTechnical = yield select(selectModuleIsTechnical)

    const newFieldMonitoration = {
      ...fieldMonitoration,
      id: fieldMonitoration?.id || uuidv4()
    }
    // eslint-disable-next-line no-unused-expressions
    if(moduleIsTechnical) {
      yield put(OfflineTechnicalUserDataActions.updateOfflineTechnicalPrecipitations(newFieldMonitoration, currentProperty?.id))
    } else {
      yield put(OfflinePrecipitationUserDataActions.updateOfflinePrecipitationUserData(newFieldMonitoration))
    }

    yield put(FieldsMonitorationActions.saveFieldsMonitoration(fieldMonitoration, onSuccess, onError, currentProperty?.id, !!fieldMonitoration?.id ))
  } catch (err) {
    const error = err.message
    yield put(FieldsMonitorationActions.fieldsMonitorationError(error))
    yield call(onError, error)
  }
}

function* loadFieldsMonitoration({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const { data: { data, ...pagination } } = yield call(getPropertyPrecipitations, {
      limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
      ...params
    },
    { propertyId: currentProperty?.id }
    )
    yield put(FieldsMonitorationActions.loadFieldsMonitorationSuccess(
      data,
      pagination.page,
      () => onSuccess(data)
    ))

  } catch (err) {
    const error = err.message
    yield put(FieldsMonitorationActions.fieldsMonitorationError(error))
    yield call(onError, error)
  }
}

function* loadFieldsMonitorationSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* deleteFieldsMonitoration({ precipitationId, onSuccess = () => {}, onError = () => {}, propertyId = null }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)

    yield call(deleteFieldPrecipitation, { propertyId: propertyId || currentProperty.id, precipitationId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(FieldsMonitorationActions.fieldsMonitorationError(error))
    yield call(onError, error)
  }
}

function* deleteOfflineFieldsMonitoration({ precipitationId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const moduleIsTechnical = yield select(selectModuleIsTechnical)

    if(moduleIsTechnical) {
      yield put(OfflineTechnicalUserDataActions.deleteOfflineTechnicalPrecipitation(precipitationId, currentProperty?.id))
    } else {
      yield put(OfflinePrecipitationUserDataActions.removeOfflinePrecipitation(precipitationId))
    }

    yield put(FieldsMonitorationActions.deleteFieldsMonitoration(precipitationId, onSuccess, onError, currentProperty.id))
  } catch (err) {
    const error = err.message
    yield put(FieldsMonitorationActions.fieldsMonitorationError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(FieldsMonitorationTypes.SAVE_FIELDS_MONITORATION, saveFieldsMonitoration),
  takeLatest(FieldsMonitorationTypes.SAVE_OFFLINE_FIELDS_MONITORATION, saveOfflineFieldsMonitoration),
  takeLatest(FieldsMonitorationTypes.LOAD_FIELDS_MONITORATION, loadFieldsMonitoration),
  takeLatest(FieldsMonitorationTypes.LOAD_FIELDS_MONITORATION_SUCCESS, loadFieldsMonitorationSuccess),
  takeLatest(FieldsMonitorationTypes.DELETE_FIELDS_MONITORATION, deleteFieldsMonitoration),
  takeLatest(FieldsMonitorationTypes.DELETE_OFFLINE_FIELDS_MONITORATION, deleteOfflineFieldsMonitoration)
]
