import React from 'react'

import PropTypes from 'prop-types'

import {
  Container,
  Step
} from './styles'

const Stepper = props => {
  const {
    step,
    steps,
    style
  } = props

  return (
    <Container style={ { ...style } }>
      {
        Array.from({ length: steps }, (_, index) => <Step key={ index } active={ (step - 1) >= index }/>)
      }
    </Container>
  )
}

Stepper.propTypes = {
  step: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired,
  style: PropTypes.object
}

Stepper.defaultProps = {
  style: {}
}

export default Stepper
