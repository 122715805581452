import React, { useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'

import { checkboxChecked as checkedIcon, checkboxUnchecked as uncheckedIcon } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import Icon from '@smartcoop/web-components/Icon'

import {
  CheckboxTouchableContainer,
  CheckboxContainer,
  ContentContainer,
  IconContainer
} from './styles'

const CheckboxCard = (props) => {
  const { checked, style, onChange, value, children, disabled, ...rest } = props

  const isChecked = useMemo(
    () => (
      checked ? checkedIcon : uncheckedIcon
    ),
    [checked]
  )
  const toggle = useCallback(
    () => {
      !disabled && onChange(value, !checked)
    },
    [disabled, onChange, value, checked]
  )

  const color = useMemo(() => {
    if(checked){
      return colors.yellow
    }
    return disabled ? colors.lightGrey : colors.black

  },[checked, disabled])

  return (
    <CheckboxContainer { ...rest } checked={ checked } style={ { ...style } }>
      <CheckboxTouchableContainer>
        <IconContainer onClick={ toggle }>
          <Icon color={ color } icon={ isChecked } size={ 16 } />
        </IconContainer>
        <ContentContainer>
          {children}
        </ContentContainer>
      </CheckboxTouchableContainer>
    </CheckboxContainer>

  )
}

CheckboxCard.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool]),
  value: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool
}

CheckboxCard.defaultProps = {
  style: {},
  checked: false,
  disabled: false
}

export default CheckboxCard
