import React, { useCallback } from 'react'

import PropTypes from 'prop-types'

import { map } from 'lodash'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { colors } from '@smartcoop/styles'

import useStyles from './styles'

const MenuPopUp = (props) => {
  const { onClosePopup, options } = props

  const classes = useStyles()

  const handleClick = useCallback(
    (onClick, disabled) => {
      if (!disabled) {
        onClick()
        onClosePopup()
      }
    },
    [onClosePopup]
  )

  const createOption = useCallback(
    ({ icon, text, onClick, disabled, index }) => {
      const style = disabled
        ? { backgroundColor: colors.lightGrey, cursor: 'default' }
        : {}
      return (
        <ListItem
          button
          onClick={ () => handleClick(onClick, disabled) }
          key={ index }
          style={ style }
          disableRipple={ disabled }
        >
          {icon && (
            <ListItemIcon classes={ { root: classes.listItemIcon } }>
              {icon}
            </ListItemIcon>
          )}
          <ListItemText primary={ text } />
        </ListItem>
      )
    },
    [handleClick, classes]
  )

  return (
    <List>
      {map(options, ({ icon, text, onClick, disabled }, index) =>
        createOption({
          icon,
          text,
          onClick,
          disabled,
          index
        })
      )}
    </List>
  )
}

MenuPopUp.propTypes = {
  onClosePopup: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any,
      text: PropTypes.any,
      onClick: PropTypes.func,
      disabled: PropTypes.bool
    })
  )
}

MenuPopUp.defaultProps = {
  onClosePopup: () => {},
  options: []
}

export default MenuPopUp
