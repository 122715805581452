import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

/* Initial State */
const INITIAL_STATE = {
  weatherStations: [],
  currentWeatherStation: {},
  error: null,
  biWeatherStationId: null
}

/**
 * Creating actions and types with reduxsauce.
 */

const { Types, Creators } = createActions({
  loadWeatherStations: ['params', 'onSuccess', 'onError'],
  loadWeatherStationsSuccess: ['weatherStations', 'page', 'onSuccess'],

  setCurrentWeatherStation: ['currentWeatherStation'],
  setBiWeatherStationId: ['biWeatherStationId'],
  resetCurrentWeatherStation: [],

  loadCurrentWeatherStation: ['weatherStationId', 'onSuccess', 'onError'],

  weatherStationsError: ['error']
})

/**
 * Reducers functions
 */

const loadWeatherStationsSuccess = (state = INITIAL_STATE, { weatherStations }) => ({
  ...state,
  error: INITIAL_STATE.error,
  weatherStations
})


const setCurrentWeatherStation = (state = INITIAL_STATE, { currentWeatherStation }) => ({
  ...state,
  currentWeatherStation
})

const setBiWeatherStationId = (state = INITIAL_STATE, { biWeatherStationId }) => ({
  ...state,
  biWeatherStationId
})

const resetCurrentWeatherStation = (state = INITIAL_STATE) => ({
  ...state,
  currentWeatherStation: INITIAL_STATE.currentWeatherStation
})

const weatherStationsError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */

export default createReducer(INITIAL_STATE, {
  [Types.LOAD_WEATHER_STATIONS_SUCCESS]: loadWeatherStationsSuccess,

  [Types.SET_CURRENT_WEATHER_STATION]: setCurrentWeatherStation,
  [Types.SET_BI_WEATHER_STATION_ID]: setBiWeatherStationId,
  [Types.RESET_CURRENT_WEATHER_STATION]: resetCurrentWeatherStation,

  [Types.WEATHER_STATIONS_ERROR]: weatherStationsError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as WeatherStationsTypes,
  Creators as WeatherStationsActions
}
