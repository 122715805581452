import React from 'react'

import Loader from '@smartcoop/web-components/Loader'

import { Container } from './styles'

const FullScreenLoading = () => (
  <Container>
    <Loader width={ 100 } height={ 100 } />
  </Container>
)

export default FullScreenLoading
