import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import { colors } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`

export const ButtonContainer = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
`

export const RowTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const Gallery = styled.div`
  display: flex;
  justify-content: ${ ({ isCenter }) => isCenter ? 'center' : 'flex-start' };
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${ ({ isCenter }) => isCenter ? '0' : '20px' };
  margin-bottom: 20px;
`

export const CheckboxContainer = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
`
