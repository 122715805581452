import React, {
  forwardRef,
  useRef,
  useCallback,
  useEffect,
  useMemo,
  Fragment,
  useState
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import moment from 'moment/moment'

import filter from 'lodash/filter'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import some from 'lodash/some'
import split from 'lodash/split'
import sum from 'lodash/sum'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import { Divider, Typography } from '@material-ui/core'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import {
  organizationRounded,
  hammer,
  edit,
  trash,
  warning,
  doc,
  nonCompliance
} from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { SupplierQuotationActions } from '@smartcoop/stores/supplierQuotation'
import {
  selectCurrentSupplierQuotation,
  selectCurrentSupplierQuotationProposal
} from '@smartcoop/stores/supplierQuotation/selectorSupplierQuotation'
import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import {
  DemandStatusCode,
  ProposalStatusCode
} from '@smartcoop/utils/constants'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import {
  formatCpfCnpj,
  formatNumber,
  formatCep
} from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import CardAccordion from '@smartcoop/web-components/CardAccordion'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Icon from '@smartcoop/web-components/Icon'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'
import Loader from '@smartcoop/web-components/Loader'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import ObservationBox from '@smartcoop/web-components/ObservationBox'
import PartialLoading from '@smartcoop/web-components/PartialLoading'
import CreateSupplierQuotationProposalForm from '@smartcoop/web-containers/forms/shoppingPlatform/quotation/CreateSupplierQuotationProposalForm'
import { OrderItemText } from '@smartcoop/web-containers/fragments/OrderDetailsFragment/styles'
import DemandStatusFragment from '@smartcoop/web-containers/fragments/shoppingPlatform/DemandStatusFragment/DemandStatusFragment'
import SupplierQuotationDetailsFragment from '@smartcoop/web-containers/fragments/SupplierQuotationDetailsFragment'
import {
  ButtonsContainer,
  Text
} from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import AttachBankSlipModal from '@smartcoop/web-containers/modals/shoppingPlatform/AttachBankSlipModal'
import BillingDataModal from '@smartcoop/web-containers/modals/shoppingPlatform/BillingDataModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  RightContainer,
  AddressesContainer,
  Row,
  Cell,
  ProposalFormContainer,
  Header,
  IconContainer,
  Title,
  LinkCustom,
  NonComplianceButtonContainer
} from './styles'

const SupplierQuotationDetailsScreen = forwardRef(() => {
  const t = useT()
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()
  const history = useHistory()
  const { routes } = useRoutes()
  const { supplierQuotationId } = useParams()
  const location = useLocation()

  const mounted = useRef(false)
  const createSupplierQuotationProposalFormRef = useRef(null)

  const [isChecked, setIsChecked] = useState({})
  const [cooperatives, setCooperatives] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [isLoadingOrder, setIsLoadingOrder] = useState(true)
  const [isLoadingProposal, setIsLoadingProposal] = useState(true)
  const [supplierFiles, setSupplierFiles] = useState({})
  const [locationFreight, setLocationFreight] = useState({})

  const currentOrganization = useSelector(selectCurrentOrganization)
  const currentSupplierQuotation = useSelector(selectCurrentSupplierQuotation)
  const currentProposal = useSelector(selectCurrentSupplierQuotationProposal)
  const currentSupplierQuotationProposal = useSelector(
    selectCurrentSupplierQuotationProposal
  )
  const order = useMemo(() => location?.state?.order ?? {}, [location])
  const accepted = useMemo(() => location?.state?.accepted ?? {}, [location])

  useEffect(() => {
    const currentLocationFreight = {}
    map(currentProposal?.deliveryFreightValues, item => {
      currentLocationFreight[item?.deliveryLocationId] = item?.value
    })
    setLocationFreight(currentLocationFreight)
  },[currentProposal])

  const isDisabled = useMemo(
    () =>
      order?.status?.slug !== 'aguardando_resposta' &&
      moment(order?.proposalDeadline).isBefore(moment()),
    [order]
  )

  const dispatch = useCallback(useDispatch(), [])

  const loading = useMemo(() => isLoadingOrder || isLoadingProposal, [
    isLoadingOrder,
    isLoadingProposal
  ])

  const paymentForms = useMemo(
    () => currentSupplierQuotation?.paymentForms || [],
    [currentSupplierQuotation]
  )

  const productUnit = useMemo(() => '%', [])

  const proposalPaymentsForms = useMemo(
    () => currentSupplierQuotationProposal?.proposalPaymentForms || [],
    [currentSupplierQuotationProposal]
  )

  const defaultValuePaymentForms = useMemo(
    () =>
      reduce(
        proposalPaymentsForms,
        (acc, { paymentFormId, value }) => ({
          ...acc,
          [paymentFormId]: toString(value)
        }),
        {}
      ),
    [proposalPaymentsForms]
  )

  const deliveryPercentage = useMemo(
    () => currentSupplierQuotation?.deliveryPercentage || 100,
    [currentSupplierQuotation]
  )

  // const defaultAmount = useMemo(
  //   () =>
  //     !isEmpty(currentSupplierQuotationProposal)
  //       ? currentSupplierQuotationProposal?.amount || 0
  //       : (deliveryPercentage * currentSupplierQuotation.aggregatedVolume) /
  //       100,
  //   [
  //     currentSupplierQuotation.aggregatedVolume,
  //     currentSupplierQuotationProposal,
  //     deliveryPercentage
  //   ]
  // )

  // TODO change to slug
  const proposalStatusId = useMemo(() => {
    if (isEmpty(currentSupplierQuotationProposal)) return 0
    return currentSupplierQuotationProposal?.statusId
  }, [currentSupplierQuotationProposal])

  // TODO: Verificar com a storage limpa
  const defaultValue = useMemo(
    () => ({
      amount: toString(currentSupplierQuotation?.deliveryPercentage),
      paymentForms: defaultValuePaymentForms
    }),
    [currentSupplierQuotation, defaultValuePaymentForms]
  )

  const showForm = useMemo(() => {
    if (isLoadingProposal) return false

    if (
      !isEmpty(currentSupplierQuotationProposal) &&
      isEmpty(defaultValue?.paymentForms)
    ) {
      return false
    }

    return true
  }, [currentSupplierQuotationProposal, defaultValue, isLoadingProposal])

  const isProposalDeadlineValid = useMemo(
    () => moment(currentSupplierQuotation?.proposalDeadline).isBefore(moment()),
    [currentSupplierQuotation]
  )

  const currentProposalStatus = useMemo(() => {
    if (!isEmpty(currentSupplierQuotation)) {
      const actualPurchase = currentSupplierQuotation?.purchaseProposals.find(
        (item) => item?.supplierId === currentOrganization?.id
      )

      return actualPurchase
        ? actualPurchase?.status
        : { slug: 'aguardando_proposta', statusName: 'Aguardando proposta' }
    }
    return { slug: '', statusName: '' }
  }, [currentSupplierQuotation, currentOrganization])

  const handleLoad = useCallback(() => {
    setIsLoadingOrder(true)
    dispatch(
      SupplierQuotationActions.loadCurrentSupplierQuotation(
        supplierQuotationId,
        () => mounted.current && setIsLoadingOrder(false),
        () => history.replace(routes.supplierQuotationList.path),
        accepted || null
      )
    )
  }, [accepted, dispatch, history, routes, supplierQuotationId])

  const handleSubmit = useCallback(
    (proposal, check = false) => {
      setIsLoadingProposal(true)
      const organizationsCashOnly = []
      if (proposalStatusId === 0) {
        const checked = Object.values(isChecked)
        checked.forEach(
          (item, index) =>
            item === true && organizationsCashOnly.push(cooperatives[index])
        )
      }
      dispatch(
        SupplierQuotationActions.saveOfflineSupplierQuotationProposal(
          proposalStatusId === 0
            ? { ...proposal, organizationsCashOnly, deliveryFreightValues: map(locationFreight, (item, key) => ({ deliveryLocationId: key, value: item })) }
            : proposal,
          () => {
            dispatch(
              SupplierQuotationActions.loadCurrentSupplierQuotationProposal(
                supplierQuotationId,
                () => {
                  setIsLoadingProposal(false)
                  handleLoad()
                },
                () => setIsLoadingProposal(false),
                accepted || null
              )
            )
          }
        )
      )
      snackbar.success(
        t(`your {this} was ${ isEditing || check ? 'edited' : 'registered' }`, {
          howMany: 1,
          this: t('proposal', { howMany: 1 }),
          gender: 'female'
        })
      )
      setIsEditing(false)
    },
    [proposalStatusId, dispatch, locationFreight, snackbar, t, isEditing, isChecked, cooperatives, supplierQuotationId, accepted, handleLoad]
  )

  const handleSaveClick = useCallback(() => {
    if(order?.freightType === 'CFA') {
      const notFoundEmptyCFA = []
      map(currentSupplierQuotation?.organizationPurchases, purchase => map(purchase.deliveryLocations, deliveryLocation => {
        if(!locationFreight?.[deliveryLocation?.id]) {
          notFoundEmptyCFA.push(deliveryLocation?.id)
        }
      }))

      if(notFoundEmptyCFA.length) {
        createDialog({
          id: 'not-found-cfa',
          Component: ConfirmModal,
          props: {
            onConfirm: () => {
              createSupplierQuotationProposalFormRef.current.submit()
            },
            textWarning: t(
              'there is {items} with no cfa value registered, they will be saved as 0, are you sure to continue?',
              {
                items: notFoundEmptyCFA.length
              }
            )
          }
        })
      } else {
        createSupplierQuotationProposalFormRef.current.submit()
      }
    } else {
      createSupplierQuotationProposalFormRef.current.submit()
    }
  }, [createDialog, currentSupplierQuotation, locationFreight, order, t])

  const rejectProposal = useCallback(() => {
    dispatch(SupplierQuotationActions.refuseOfflineSupplierQuotationProposal())
    snackbar.success(
      t('{this} refused', {
        gender: 'female',
        this: t('proposal', { howMany: 1 })
      })
    )
    history.push(routes.supplierQuotationList.path)
  }, [dispatch, history, routes, snackbar, t])

  const handleRejectClick = useCallback(() => {
    createDialog({
      id: 'reject-proposal',
      Component: ConfirmModal,
      props: {
        onConfirm: () => {
          rejectProposal()
        },
        textWarning: t(
          'are you sure you want to refuse the order? You cannot join it anymore'
        )
      }
    })
  }, [createDialog, rejectProposal, t])

  const handleRemoveClick = useCallback(() => {
    setIsLoadingProposal(true)
    dispatch(
      SupplierQuotationActions.removeOfflineSupplierQuotationProposal(() => {
        setIsLoadingProposal(false)
        handleLoad()
      })
    )
    dispatch(SupplierQuotationActions.resetCurrentSupplierQuotationProposal())
    snackbar.success(
      t('your {this} was removed', {
        gender: 'female',
        this: t('proposal', { howMany: 1 })
      })
    )
    setIsEditing(false)
  }, [dispatch, handleLoad, snackbar, t])

  const handleAttachClick = useCallback(
    (purchase) => {
      createDialog({
        id: 'attach-docs',
        Component: AttachBankSlipModal,
        props: {
          purchase,
          handleLoad
        }
      })
    },
    [createDialog, handleLoad]
  )

  useEffect(() => {
    handleLoad()
    setIsLoadingProposal(true)
    dispatch(
      SupplierQuotationActions.loadCurrentSupplierQuotationProposal(
        supplierQuotationId,
        () => mounted.current && setIsLoadingProposal(false),
        () => mounted.current && setIsLoadingProposal(false),
        accepted || null
      )
    )
    return () => {
      dispatch(SupplierQuotationActions.resetCurrentSupplierQuotation())
      dispatch(SupplierQuotationActions.resetCurrentSupplierQuotationProposal())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization])

  // TODO: Verificar melhor isso depois
  useEffect(() => {
    if (!isLoadingProposal && !proposalStatusId) {
      // history.replace(routes.supplierQuotationList.path)
    }
  }, [
    currentSupplierQuotationProposal,
    history,
    isLoadingProposal,
    proposalStatusId,
    routes.supplierQuotationList.path
  ])

  const renderFile = useCallback(async (file) => {
    const fileUrl = split(file?.fileUrl, '/')
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_ORGANIZATION_FILES_ID, fileKey: last(fileUrl) })

    return downloadFromBase64(data)
  }, [])

  useEffect(() => {
    const renderFiles = async () => {
      const allFiles = filter(flatten(map(currentSupplierQuotation?.organizationPurchases, purchase => purchase?.organization?.organizationFiles.concat(purchase?.organization?.headquarter?.organizationFiles))), item => !isEmpty(item))

      const responses = await Promise.all(map(allFiles, async (file) => {
        const fileSrc = await renderFile(file)
        return {
          fileId: file?.id,
          fileSrc,
          fileName: file?.fileName,
          organizationId: file?.organizationId
        }
      }))
      setSupplierFiles(responses)
    }

    renderFiles()
  }, [currentSupplierQuotation, renderFile])

  const handlePaymentForm = useCallback(
    (paymentForm) => {
      if (!paymentForm) {
        return t('payment form not selected')
      }

      const { deferredPayment, daysAmount } = paymentForm

      return `${
        !deferredPayment
          ? t('in cash')
          : `${ t('deferred') } ${ daysAmount } ${ t('day', { howMany: 2 }) }`
      }`
    },
    [t]
  )

  const handleNonCompliance = () => {
    history.push(
      routes.nonComplianceSupplierDetails.path.replace(
        ':orderId',
        supplierQuotationId
      )
    )
  }

  const showNonComplianceButton = useMemo(() => {
    if (!currentSupplierQuotation?.status) {
      return false
    }
    return (
      find(
        ['aguardando_entrega', 'atrasada', 'entregue', 'finalizado'],
        (item) => item === currentSupplierQuotation?.status?.slug
      ) && currentProposalStatus?.slug !== 'recusado'
    )
  }, [currentProposalStatus, currentSupplierQuotation])

  const handleInCash = useCallback(
    (value, checked) => {
      if (proposalStatusId === 0) {
        const { index } = value

        setIsChecked((state) => ({ ...state, [`item-${ index }`]: checked }))
      } else {
        const cashOnlyArray = currentSupplierQuotationProposal?.mainPurchase
          ? currentSupplierQuotationProposal?.mainPurchase?.cashOnly.map(
            (item) => item.organizationId
          )
          : []
        if (checked) {
          handleSubmit(
            {
              amount: currentSupplierQuotationProposal?.amount,
              organizationsCashOnly: [value?.organization, ...cashOnlyArray],
              proposalPaymentForms: currentSupplierQuotationProposal?.proposalPaymentForms.map(
                (item) => ({
                  paymentFormId: item?.paymentFormId,
                  value: item?.value
                })
              )
            },
            true
          )
        } else {
          handleSubmit(
            {
              amount: currentSupplierQuotationProposal?.amount,
              organizationsCashOnly: [
                ...cashOnlyArray.filter((item) => item !== value?.organization)
              ],
              proposalPaymentForms: currentSupplierQuotationProposal?.proposalPaymentForms.map(
                (item) => ({
                  paymentFormId: item?.paymentFormId,
                  value: item?.value
                })
              )
            },
            true
          )
        }
      }
    },
    [
      currentSupplierQuotationProposal,
      handleSubmit,
      proposalStatusId
    ]
  )

  useEffect(() => {
    const checkboxObject = {}
    const organizationArray = []

    const purchases = currentSupplierQuotation?.organizationPurchases

    purchases &&
      purchases.forEach((item, index) => {
        organizationArray.push(item?.organizationId)
        if (proposalStatusId === 0) {
          checkboxObject[`item-${ index }`] = false
        } else {
          const found = some(
            currentSupplierQuotation?.cashOnly,
            (value) =>
              value?.organizationId === item?.organizationId &&
              value?.supplierId === currentOrganization?.id
          )
          checkboxObject[`item-${ index }`] = found
        }
      })

    setIsChecked(checkboxObject)
    setCooperatives(organizationArray)
  }, [
    currentOrganization,
    currentSupplierQuotation,
    dispatch,
    proposalStatusId
  ])

  const showCreditDenialCheckbox = useMemo(() => {
    const statusArray = [
      ProposalStatusCode.AGUARDANDO_PROPOSTA,
      ProposalStatusCode.PARTICIPANDO,
      ProposalStatusCode.AGUARDANDO_COOPERATIVAS,
      ProposalStatusCode.APROVADO,
      ProposalStatusCode.ENTREGUE,
      ProposalStatusCode.ATRASADO
    ]
    const result = statusArray.some((item) => item === proposalStatusId)

    return result
  }, [proposalStatusId])

  const enableAttachButton = useMemo(() => {
    if (
      currentSupplierQuotation?.statusId === DemandStatusCode.AGUARDANDO_ENTREGA
    ) {
      const organizationPurchases = currentSupplierQuotation.organizationPurchases.map(
        (item) => ({
          proposalAccepted: item?.proposalAccepted,
          received: item?.received,
          organizationId: item?.organizationId
        })
      )
      const organizationsCashOnly = []
      const checked = Object.values(isChecked)
      checked.forEach(
        (item, index) =>
          item === true && organizationsCashOnly.push(cooperatives[index])
      )

      const organizationPurchasesFiltered = organizationPurchases.filter(
        (item) =>
          !organizationsCashOnly.some(
            (element) => element === item.organizationId
          )
      )

      return organizationPurchasesFiltered.every(
        (item) => item.received === true
      )
    }
    return false
  }, [
    cooperatives,
    currentSupplierQuotation,
    isChecked
  ])

  useEffect(() => {
    mounted.current = true
  }, [])

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  const demandStatusFragmentStatus = useMemo(
    () => {
      if (proposalStatusId === 5) {
        return 'recusada'
      }

      if (proposalStatusId === 10 && currentSupplierQuotation?.status?.slug === 'aguardando_decisao') {
        return 'aguardando_cooperativas'
      }

      return currentSupplierQuotation?.status?.slug
    },
    [currentSupplierQuotation, proposalStatusId]
  )

  return loading && isEmpty(currentSupplierQuotation) ? (
    <Loader width={ 100 } />
  ) : (
    <SplitedScreenLayout
      withGoBackRight
      divRightStyle={ { paddingRight: '3%', paddingLeft: '3%',  width: '100%' } }
      title={ { name: t('quotation', { howMany: 2 }) } }
      rightChildren={
        <PartialLoading open={ isLoadingOrder } width={ 100 }>
          <RightContainer>
            {!isEmpty(currentSupplierQuotation) && (
              <SupplierQuotationDetailsFragment
                currentDemand={ currentSupplierQuotation }
                currentOrganization={ currentOrganization }
                currentProposal={ currentProposal }
              />
            )}

            <div style={ { marginTop: 25 } }>
              <DemandStatusFragment
                openingDate={ currentSupplierQuotation?.createdAt }
                closingDate={ currentSupplierQuotation?.participationDeadline }
                proposalDeadlineDate={ currentSupplierQuotation?.proposalDeadline }
                organizationDecisionLimitDate={ currentSupplierQuotation?.decisionDeadline }
                deliveryDate={ currentSupplierQuotation?.receiptDeadline }
                status={ demandStatusFragmentStatus }
              />
            </div>

            <AddressesContainer>
              {showNonComplianceButton && (
                <NonComplianceButtonContainer>
                  <Button
                    id="web-attached-supplier-quotation-proposal"
                    style={ { flex: '0 0 auto' } }
                    onClick={ handleNonCompliance }
                    color="black"
                  >
                    <>
                      <Icon
                        icon={ nonCompliance }
                        inColor="black"
                        outColor="white"
                        size={ 20 }
                        style={ { marginRight: 5 } }
                      />
                      <I18n>non compliance</I18n>
                    </>
                  </Button>
                </NonComplianceButtonContainer>
              )}
              {map(
                currentSupplierQuotation?.organizationPurchases,
                (purchase, index) => (
                  <CardAccordion
                    defaultOpened
                    key={ purchase?.id }
                    title={ purchase?.organization?.companyName }
                    headerTitleProps={ {
                      style: { fontWeight: fonts?.fontWeight?.bold }
                    } }
                    headerLeft={
                      <Icon
                        icon={ organizationRounded }
                        color={ colors.green }
                        size={ 25 }
                      />
                    }
                    headerAction={
                      <>
                        {showCreditDenialCheckbox && (
                          <CheckboxButton
                            style={ {
                              backgroundColor: 'transparent',
                              padding: '5px 15px',
                              marginBottom: 0
                            } }
                            label={ t('credit denied') }
                            onChange={ handleInCash }
                            value={ {
                              mainPurchase: purchase?.mainPurchaseId,
                              organization: purchase?.organizationId,
                              index
                            } }
                            checked={ isChecked[`item-${ index }`] }
                            disabled={
                              !(
                                proposalStatusId === 0 || proposalStatusId === 1
                              )
                            }
                            tooltipTitle={ t('allow purchase only in cash') }
                          />
                        )}
                        <Button
                          id="web-attached-supplier-quotation-proposal"
                          style={ { flex: '0 0 auto', marginRight: 15 } }
                          variant="outlined"
                          onClick={ () => handleAttachClick(purchase) }
                          disabled={
                            loading ||
                            proposalStatusId === ProposalStatusCode.RECUSADO ||
                            (!(
                              currentSupplierQuotation?.statusId ===
                                DemandStatusCode.AGUARDANDO_ENTREGA &&
                              purchase.proposalAccepted &&
                              purchase.proposalPaymentForm?.paymentForm
                                .deferredPayment === false &&
                                purchase.cashOnly[0]?.organizationId === purchase.organizationId
                            ) &&
                              !(
                                currentSupplierQuotation?.statusId ===
                                  DemandStatusCode.AGUARDANDO_ENTREGA &&
                                purchase?.proposalAccepted &&
                                purchase?.received &&
                                enableAttachButton
                              ) && !(
                              currentSupplierQuotation?.statusId ===
                                DemandStatusCode.FINALIZADO &&
                                purchase?.proposalAccepted
                                &&
                                currentSupplierQuotation?.freightType === 'fob'
                            ) &&
                              currentSupplierQuotation?.statusId !==
                                DemandStatusCode.ENTREGUE &&
                              currentSupplierQuotation?.statusId !==
                                DemandStatusCode.FINALIZADO)
                          }
                        >
                          <>
                            <Icon
                              icon={ doc }
                              size={ 20 }
                              style={ { marginRight: 5 } }
                            />
                            <I18n>attach documents</I18n>
                          </>
                        </Button>
                      </>
                    }
                    style={ { marginBottom: 15 } }
                  >
                    <div style={ { padding: '5px 0 10px' } }>
                      <Row style={ { justifyContent: 'space-between' } }>
                        <Cell>
                          <I18n>total quantity</I18n>:{' '}
                          {formatNumber(purchase?.totalAmount)}{' '}
                          {currentSupplierQuotation?.product?.unitOfMeasures}
                        </Cell>
                        {// proposalStatusId 4 = aprovado
                        }
                        {((order?.status?.slug === 'aguardando_entrega' && proposalStatusId === 4) || (order?.status?.slug === 'finalizado' && order?.freightType === 'FOB' && proposalStatusId === 4)) && (
                          <Cell>
                            <Button
                              id="billing-data-modal-button"
                              style={ {
                                flex: '0 0 auto',
                                whiteSpace: 'no-wrap',
                                fontWeight: 600
                              } }
                              color={ colors.primary }
                              variant="outlined"
                              onClick={ () => createDialog({
                                id: 'billing-data',
                                Component: BillingDataModal,
                                props: {
                                  data: purchase,
                                  unit: currentSupplierQuotation?.product?.unitOfMeasures
                                }
                              }) }
                            >
                              <I18n>billing data</I18n>
                            </Button>
                          </Cell>
                        )}
                      </Row>
                      <Row style={ { marginTop: '10px' } }>
                        <I18n style={ { fontWeight: 900 } }>billing mode</I18n>
                      </Row>
                      <Row>
                        <OrderItemText
                          style={ { padding: '10px 30px 10px 0px' } }
                        >
                          {handlePaymentForm(
                            purchase?.proposalPaymentForm?.paymentForm
                          )}
                        </OrderItemText>
                      </Row>
                      <Row style={ { marginTop: 15, marginBottom: 0 } }>
                        <Typography
                          variant="body1"
                          style={ { fontWeight: fonts.fontWeight.bold } }
                        >
                          {t('doc', { howMany: 2 })}
                        </Typography>
                      </Row>
                      <Row>
                        {isEmpty(purchase?.organization?.organizationFiles) &&
                        isEmpty(
                          purchase?.organization?.headquarter?.organizationFiles
                        ) ? (
                            <EmptyState text={ t('no file') } />
                          ) : !isEmpty(supplierFiles) && supplierFiles.filter(item => item?.organizationId === purchase?.organizationId).map(item => (
                            <Cell key={ item.fileId }>
                              <LinkCustom href={ item.fileSrc } download={ item.fileName }>
                                {item.fileName}
                              </LinkCustom>
                            </Cell>
                          ))
                        }
                      </Row>
                      <Row style={ { marginTop: 15, marginBottom: 0, justifyContent: 'space-between' } }>
                        <I18n
                          as={ Typography }
                          variant="body1"
                          style={ { fontWeight: fonts.fontWeight.bold } }
                        >
                          delivery locations
                        </I18n>
                        {order?.freightType === 'CFA' && (
                          <InputUnit
                            detached
                            disabled
                            type="float"
                            unit="R$"
                            label={ t('total cfa freight cost') }
                            value={ sum(map(purchase.deliveryLocations, deliveryLocation => locationFreight?.[deliveryLocation?.id] ? toNumber(locationFreight[deliveryLocation?.id]) : 0 )) }
                          />
                        )}
                      </Row>
                      {map(purchase.deliveryLocations, (deliveryLocation) => (
                        <Fragment key={ deliveryLocation.id }>
                          <Divider />
                          <Row style={ { justifyContent: 'space-between' } }>
                            <Cell>
                              {deliveryLocation?.organization?.street}
                              {'\n'}
                              {deliveryLocation?.organization?.district}
                            </Cell>
                            <Cell>
                              CEP{' '}
                              {formatCep(
                                deliveryLocation?.organization?.stateRegistration
                              )}
                              {'\n'}
                              {deliveryLocation?.organization?.city}/
                              {deliveryLocation?.organization?.state} -{' '}
                              {deliveryLocation?.organization?.country}
                            </Cell>
                            <Cell>
                              CNPJ{' '}
                              {formatCpfCnpj(
                                deliveryLocation?.organization?.companyDocument
                              )}
                              {'\n'}
                              <b>
                                <I18n>quantity</I18n>:{' '}
                                {formatNumber(deliveryLocation?.amount)}{' '}
                                {
                                  currentSupplierQuotation?.product
                                    ?.unitOfMeasures
                                }
                              </b>
                            </Cell>
                          </Row>
                          <Row>
                            <Cell>
                              <I18n>billing cnpj</I18n>{': '}
                              {formatCpfCnpj(
                                deliveryLocation?.billingOrganization?.companyDocument
                              )}
                            </Cell>
                          </Row>
                          {((order?.status?.slug === 'aguardando_entrega' && proposalStatusId === 4) || (order?.status?.slug === 'finalizado' && order?.freightType === 'FOB' && proposalStatusId === 4))  && (
                            <Row>
                              <InputText detached fullWidth disabled value={ deliveryLocation?.note || 'Nada informado' }/>
                            </Row>
                          )}
                          {order?.freightType === 'CFA' && (
                            <Row style={ { justifyContent: 'space-between' } }>
                              <Cell />
                              <Cell />
                              <InputUnit
                                disabled={ (!isEmpty(currentSupplierQuotationProposal) ||
                                  isProposalDeadlineValid) &&
                                !isEditing }
                                detached
                                type="float"
                                unit="R$"
                                label={ t('cfa freight cost') }
                                value={ locationFreight[deliveryLocation?.id] }
                                onChange={ ({ target: { value } }) => {
                                  const currentLocationFreight = { ...locationFreight }
                                  currentLocationFreight[deliveryLocation?.id] = value
                                  setLocationFreight(currentLocationFreight)
                                } }
                              />
                            </Row>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </CardAccordion>
                )
              )}
            </AddressesContainer>
          </RightContainer>
        </PartialLoading>
      }
      appendColumns={
        proposalStatusId !== 2 && {
          xl: 3,
          lg: 4,
          md: 5
        }
      }
      appendChildren={
        proposalStatusId !== 2 && (
          <>
            <ButtonsContainer
              style={ {
                justifyContent: 'flex-end',
                paddingRight: 30,
                paddingTop: 30,
                paddingBottom: 5
              } }
            >
              <Button
                id="web-create-supplier-quotation-proposal-form-button"
                style={ {
                  flex: '0 0 auto',
                  whiteSpace: 'no-wrap',
                  fontWeight: 600
                } }
                color={ colors.primary }
                variant="outlined"
                onClick={ () => handleRejectClick() }
                disabled={
                  !isEmpty(currentSupplierQuotationProposal) || isEditing
                }
              >
                <I18n>refuse</I18n>
              </Button>
            </ButtonsContainer>
            <Header
              style={ { paddingLeft: 30, paddingTop: 10, alignItems: 'center' } }
            >
              <IconContainer>
                <Icon icon={ hammer } size={ 18 } />
              </IconContainer>

              <Title style={ { fontWeight: 600, margin: 0 } }>
                <I18n>register proposal</I18n>
              </Title>
            </Header>
            <Divider />
            <ProposalFormContainer>
              {!isEmpty(paymentForms) && (
                <>
                  {showForm ? (
                    <>
                      <CreateSupplierQuotationProposalForm
                        paymentForms={ paymentForms }
                        productUnit={ productUnit }
                        defaultValue={ defaultValue }
                        ref={ createSupplierQuotationProposalFormRef }
                        deliveryFreightValues={ currentProposal?.deliveryFreightValues ? map(currentProposal?.deliveryFreightValues, item => ({
                          ...item,
                          value: toNumber(locationFreight[item?.deliveryLocationId])
                        })) :  map(locationFreight, (item, key) => ({ deliveryLocationId: key, value: toNumber(item) })) }
                        onSuccess={ handleSubmit }
                        disabled={
                          (!isEmpty(currentSupplierQuotationProposal) ||
                            isProposalDeadlineValid) &&
                          !isEditing
                        }
                        withoutSubmitButton
                      />
                      {order?.freightType === 'CFA' && (
                        <ObservationBox
                          icon={ warning }
                          title={ t('freight observation') }
                          style={ { marginBottom: 18 } }
                        >
                          <Text style={ { marginBottom: 10, fontSize: 14 } }>
                            Na modalidade de frete CFA - Custo de Frete Adiciona, o valor do frete <b>NÃO DEVE</b> estar incluso no preço do produto e <b>DEVE</b> ser informado individualmente para cada local de entrega.
                          </Text>
                        </ObservationBox>
                      )}
                      <ObservationBox
                        icon={ warning }
                        title={ t('observation', { howMany: 1 }) }
                        style={ { marginBottom: 18 } }
                      >
                        <Text style={ { marginBottom: 10, fontSize: 14 } }>
                          <I18n params={ { this: `${ deliveryPercentage }%` } }>
                            {'must cover {this} of the demand'}
                          </I18n>
                        </Text>
                        <Text style={ { marginBottom: 10, fontSize: 14 } }>
                          <I18n>
                          bills will be generated only after a success delivery on modality CIF, except when the credit analysis is refused, or after all cooperatives agreement when FOB
                          </I18n>
                        </Text>
                        <Text style={ { marginBottom: 10, fontSize: 14 } }>
                          <I18n>minimum purchase guaranteed</I18n>
                        </Text>
                      </ObservationBox>
                      <ButtonsContainer
                        style={ { justifyContent: 'flex-start' } }
                      >
                        {isEmpty(currentSupplierQuotationProposal) ||
                        isEditing ? (
                            <Button
                              id="web-create-supplier-quotation-proposal-form-button"
                              style={ {
                                flex: '0 0 auto',
                                whiteSpace: 'no-wrap',
                                fontWeight: 600
                              } }
                              color={ colors.secondary }
                              onClick={ () => handleSaveClick() }
                              disabled={ isDisabled }
                            >
                              <I18n>{isEditing ? 'save' : 'send proposal'}</I18n>
                            </Button>
                          ) : (
                            <>
                              <Button
                                id="web-edited-supplier-quotation-proposal"
                                style={ { flex: '0 0 auto', marginRight: 15 } }
                                variant="outlined"
                                onClick={ () => {
                                  setIsEditing(true)
                                } }
                                disabled={ isDisabled }
                              >
                                <>
                                  <Icon
                                    icon={ edit }
                                    size={ 14 }
                                    style={ { marginRight: 5 } }
                                  />
                                  <I18n>edit</I18n>
                                </>
                              </Button>
                              <Button
                                id="web-edited-supplier-quotation-proposal"
                                style={ { flex: '0 0 auto' } }
                                variant="outlined"
                                onClick={ handleRemoveClick }
                                disabled={ isDisabled }
                              >
                                <>
                                  <Icon
                                    color={ colors.error }
                                    icon={ trash }
                                    size={ 14 }
                                    style={ { marginRight: 5 } }
                                  />
                                  <I18n>exclude</I18n>
                                </>
                              </Button>
                            </>
                          )}
                      </ButtonsContainer>
                    </>
                  ) : (
                    <Loader width={ 75 } />
                  )}
                </>
              )}
            </ProposalFormContainer>
          </>
        )
      }
    />
  )
})

export default SupplierQuotationDetailsScreen
