import React, { useRef, useCallback } from 'react'

import PropTypes from 'prop-types'

import { ThemeProvider } from '@material-ui/core'

import commentSchema from '@smartcoop/forms/schemas/social/comment.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { organizationRounded } from '@smartcoop/icons'
import Avatar from '@smartcoop/web-components/Avatar'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import InputText from '@smartcoop/web-components/InputText'

import { Container, Name, Header, Identifier, Body, Footer, textFieldTheme, Col, ColFooter1, ColFooter2, Privacy, Type } from './styles'

const PostComment = ({ style, user, onSubmit, textFieldProps, buttonProps , comment, productWall, currentOrganization }) =>  {
  const t = useT()
  const formRef = useRef(null)

  const handleSubmit = useCallback(
    (data) => {
      onSubmit(data)
    },
    [onSubmit]
  )

  return (
    <ThemeProvider theme={ textFieldTheme }>
      <Container
        style={ style }
        ref={ formRef }
        schemaConstructor={ commentSchema }
        onSubmit={ handleSubmit }
      >
        <Header>
          <Identifier>
            <Avatar alt={ user.name } src={ user.image } />
            <Col>
              <Name>{user.name}</Name>
              { productWall && <Privacy>
                <Icon icon={ organizationRounded } />
                <Type>{currentOrganization.tradeName}</Type>
              </Privacy>}
            </Col>
          </Identifier>
        </Header>
        <Body>

          <InputText
            placeholder={ t('social insert comment').replace('\n', ' ') }
            name="text"
            fullWidth
            multiline
            rows={ 4 }
            defaultValue={ comment.text }
            style={ { marginBottom: 0 } }
            { ...textFieldProps }
          />
        </Body>
        <Footer>
          <ColFooter1/>
          <ColFooter2>
            <Button
              id="button"
              color="secondary"
              onClick={ () => formRef.current.submit() }
              { ...buttonProps }
            >
              <I18n>{comment.text ? 'save' : 'social comment'}</I18n>
            </Button>
          </ColFooter2>
        </Footer>
      </Container>

    </ThemeProvider>
  )}

PostComment.propTypes = {
  style: PropTypes.object,
  user: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string }),
  onSubmit: PropTypes.func,
  textFieldProps: PropTypes.object,
  buttonProps: PropTypes.object,
  comment: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    userId: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      image: PropTypes.string
    }) }),
  productWall: PropTypes.bool,
  currentOrganization: PropTypes.object
}

PostComment.defaultProps = {
  style: {},
  user: {
    name: '',
    image: ''
  },
  onSubmit: () => {},
  textFieldProps: {},
  buttonProps: {},
  comment: {
    text: ''
  },
  productWall: false,
  currentOrganization: {}
}

export default PostComment
