import React, { useRef, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import round from 'lodash/round'

import I18n, { useT } from '@smartcoop/i18n'
import {
  field as fieldIcon
  , calendar } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { FieldActions } from '@smartcoop/stores/field'
import { selectCurrentField } from '@smartcoop/stores/field/selectorField'
import colors from '@smartcoop/styles/colors'
import { getPolygonCenter, findMapZoom } from '@smartcoop/utils/maps'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import Maps from '@smartcoop/web-components/Maps'
import Control from '@smartcoop/web-components/Maps/components/Control'
import Polygon from '@smartcoop/web-components/Maps/components/Polygon'
import ManagementForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { Container, Header, Title, IconContainer } from './styles'

const Management = () => {
  const managementFormRef = useRef(null)
  const history = useHistory()
  const { routes } = useRoutes()
  const t = useT()
  const snackbar = useSnackbar()
  const location = useLocation()
  const dispatch = useDispatch()

  const { polygonCoordinates } = useSelector(selectCurrentField)

  const childrenPolygon = useMemo(
    () => location?.state?.childrenPolygon ?? {}, [location]
  )

  const growingSeason = useMemo(
    () => location?.state?.growingSeason ?? {}, [location]
  )

  const area = useMemo(() => {
    if(growingSeason?.childrenPolygonId) {
      return round(find(growingSeason?.fieldData?.childrenPolygons, item => item.id === growingSeason?.childrenPolygonId)?.area, 2)
    }
    return round(growingSeason?.fieldData?.area, 2)
  }, [growingSeason])


  const field = useMemo(
    () => location?.state?.field ?? {}, [location]
  )

  const isPlanned = useMemo(
    () => location?.state?.isPlanned ?? false, [location]
  )


  const handleClose = useCallback(
    () => {
      history.goBack()
    },
    [history]
  )

  const onSuccess = useCallback(
    () => {
      snackbar.success(
        t('your {this} was registered', {
          howMany: 1,
          gender: 'male',
          this: t('cropManagement', { howMany: 1 })
        })
      )
      dispatch(FieldActions.setFieldNavigationData({ fieldId: field.id, childrenPolygonId: growingSeason?.childrenPolygonId, growingSeasonId: growingSeason?.id, isPlanned: growingSeason?.isPlanned }))
      history.push(routes.fieldDetails.path.replace(':fieldId', field.id))
    },
    [dispatch, field, growingSeason, history, routes, snackbar, t]
  )

  const mapCenter = useMemo(
    () => !isEmpty(polygonCoordinates) ? getPolygonCenter(childrenPolygon?.coordinates || polygonCoordinates) : undefined,
    [childrenPolygon.coordinates, polygonCoordinates]
  )
  const mapZoom = useMemo(
    () => !isEmpty(polygonCoordinates) ? findMapZoom(childrenPolygon?.coordinates || polygonCoordinates) : undefined,
    [childrenPolygon.coordinates, polygonCoordinates]
  )

  return (
    <SplitedScreenLayout
      title={ { name: t('field', { howMany: 1 }) } }
      divRightStyle={ { padding: 0 } }
      leftChildren={ (
        <Container>
          <Header>
            <IconContainer>
              <Icon icon={ calendar } size={ 18 } />
            </IconContainer>

            <Title style={ { fontSize: 18, fontWeight: 600 } }>
              <I18n>management register</I18n>
            </Title>

          </Header>

          <ManagementForm
            ref={ managementFormRef }
            withoutSubmitButton
            growingSeason={ growingSeason }
            onSuccess={ onSuccess }
            handleClose={ handleClose }
            isPlanned={ isPlanned }
            area={ area }
          />
        </Container>
      ) }
      rightChildren={ (
        <Maps
          zoom={ mapZoom }
          style={ { minHeight: 500 } }
          region={ mapCenter }
          layer="google"
        >
          <Control position="topcenter">
            <Button
              id="field-details"
              color={ colors.shadow }
              style={ {
                padding: '5.5px 10px',
                fontColor: colors.white,
                fontSize: '0.875rem',
                marginRight: 10
              } }
            >
              <>
                <Icon
                  icon={ fieldIcon }
                  size={ 17 }
                  style={ { marginRight: 5 } }
                  color={ colors.white }
                />
                {growingSeason?.fieldData?.fieldName}
                { !isEmpty(childrenPolygon) ? ` - ${ childrenPolygon?.name }` : null}
                <div style={ { textTransform: 'lowercase' } }>
                  { ` | ${ round(area, 2) } ha`}
                </div>
              </>
            </Button>
          </Control>
          <Polygon
            points={ childrenPolygon?.coordinates || polygonCoordinates }
            color={ colors.secondary }
          />
        </Maps>
      ) }
    />
  )}

export default Management
