import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

import Button from '@smartcoop/web-components/Button'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  min-width: 500px;
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const HalfGroup = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

export const FormContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 20px;
    flex-direction: column;
`

export const Item = styled(Grid).attrs({
  item: true,
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12
})``

export const HalfItem = styled(Grid).attrs({
  item: true,
  xs: 6,
  sm: 6,
  md: 6,
  lg: 6,
  xl: 6
})``

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 20px;
`

export const ButtonContainer = styled.div`
  width: 80px;
`
export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const AddNew = styled(Button).attrs({
  color: '#ffffff',
  style: {
    border: 0,
    boxShadow: 'none',
    background: 'transparent'
  }
})`
  display: flex;
  flex-direction: row;
  font-weight: 600;
`
