import React, { useRef, useCallback, useMemo, useState } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import size from 'lodash/size'

import InputAdornment from '@material-ui/core/InputAdornment'

import { calendar } from '@smartcoop/icons'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import CalendarPicker from '@smartcoop/web-components/CalendarPicker'
import Icon from '@smartcoop/web-components/Icon'
import TextField from '@smartcoop/web-components/TextField'

const InputDateRange = (props) => {
  const {
    value,
    onChange,
    onClick,
    adornmentStyle,
    pickerProps,
    onBlur,
    defaultValue,
    ...rest
  } = props

  const { detached, disabled } = rest

  const [calendarValue, setCalendarValue] = useState(value)

  const inputRef = useRef()

  const dates = useMemo(
    () => filter(detached ? value : calendarValue, (date) => !isEmpty(date)),
    [calendarValue, detached, value]
  )

  const datesObj = useMemo(
    () => {
      const [from = '', to = ''] = dates || []
      return { from, to }
    },
    [dates]
  )

  const transformRender = useCallback(
    (newDates) => {
      const newMomentFrom = moment(newDates.from, momentBackDateFormat, true)
      const fromStr = newMomentFrom.isValid() ? newMomentFrom.format(momentFriendlyDateFormat) : newMomentFrom._i

      const newMomentTo = moment(newDates.to, momentBackDateFormat, true)
      const toStr = newMomentTo.isValid() ? newMomentTo.format(momentFriendlyDateFormat) : newMomentTo._i

      if (!newDates.to || fromStr === toStr) {
        return fromStr
      }
      return `${ fromStr } - ${ toStr }`
    },
    []
  )

  // only use it outside a form
  const handleInputChange = useCallback(
    () => {},
    []
  )

  const handleChangeDates = useCallback(
    (newDates) => {
      if (!detached && inputRef.current.setValue) {
        inputRef.current.setValue(newDates)
      }
      onChange(newDates)
      setCalendarValue(newDates)
    },
    [detached, onChange]
  )

  // only use it inside a form
  const handleFieldChange = useCallback(
    (newDates) => {
      if (!detached) {
        if (!isEqual(newDates, datesObj)) {
          handleChangeDates(newDates)
        } else {
          handleChangeDates({ from: '', to: '' })
        }
      }
    },
    [datesObj, detached, handleChangeDates]
  )

  const handleCalendarChange = useCallback(
    (newDates) => {
      const [from = '', to = ''] = newDates || []

      handleChangeDates({ from, to })

      if (size(newDates) === 2) {
        onBlur()
      }
    },
    [handleChangeDates, onBlur]
  )

  const onCloseCalendar = useCallback(
    (values) => {
      if (size(values) < 2) {
        handleChangeDates({ from: '', to: '' })
      }
    },
    [handleChangeDates]
  )


  const calendarOptions = useMemo(
    () => ({
      mode: 'range',
      onClose: onCloseCalendar
    }),
    [onCloseCalendar]
  )

  if (detached) {
    rest.value = datesObj
    rest.onChange = handleInputChange
  } else {
    rest.defaultValue = defaultValue
    rest.onFieldValueChange = handleFieldChange
  }

  const input = (
    <TextField
      data-input
      ref={ inputRef }
      { ...rest }
      notEditable
      type="tel"
      autoComplete="off"
      transformRender={ transformRender }
      validateOnBlur={ false }
      style={ { width: '100%' } }
      InputProps={ {
        endAdornment: (
          <InputAdornment style={ { paddingRight: 18, ...adornmentStyle } } position="end">
            <Icon
              icon={ calendar }
              size={ 18 }
              color="#151818"
              onClick={ onClick }
              data-toggle
            />
          </InputAdornment>
        )
      } }
    />
  )

  return (
    disabled
      ? input
      : (
        <CalendarPicker
          value={ dates }
          onChange={ handleCalendarChange }
          options={ calendarOptions }
          { ...pickerProps }
        >
          {input}
        </CalendarPicker>
      )
  )
}

InputDateRange.propTypes = {
  value: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string
  }),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  adornmentStyle: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  pickerProps: PropTypes.object,
  minWidth: PropTypes.number,
  defaultValue: PropTypes.shape({
    to: PropTypes.string,
    from: PropTypes.string
  })
}

InputDateRange.defaultProps = {
  value: {
    from: '',
    to: ''
  },
  defaultValue: {
    from: '',
    to: ''
  },
  onBlur: () => {},
  onChange: () => {},
  adornmentStyle: {},
  onClick: () => {},
  disabled: false,
  pickerProps: {},
  minWidth: 190
}

export default InputDateRange
