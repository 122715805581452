/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import size from 'lodash/size'
import toString from 'lodash/toString'

import I18n, { useT } from '@smartcoop/i18n'
import { arrowUpField, arrowDownField, productivityChallange, pencil, download } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import { generateKML } from '@smartcoop/utils/files'
import { GROWING_SEASON_COLOR, getPolygonCenter } from '@smartcoop/utils/maps'
import Button from '@smartcoop/web-components/Button'
import Card from '@smartcoop/web-components/Card'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton/IconButton'
import PolygonToSvg from '@smartcoop/web-components/PolygonToSvg'
import Tooltip from '@smartcoop/web-components/Tooltip'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  ItemBorderLeft,
  Line,
  ContainerField,
  TextGroup,
  TextLeft,
  TextRight,
  TextBold,
  Text,
  CropTextGroup,
  CropText,
  TemperatureText,
  Row
} from './styles'

const FieldItem = (props) => {
  const {
    field,
    onClick,
    editFields
  } = props
  const history = useHistory()
  const { routes } = useRoutes()
  const t = useT()

  const { growingSeasons, currentWeather, childrenPolygons } = field

  const findGrowingSeasonById = useCallback((id) => {
    const foundGrowingSeason = find(growingSeasons, item => item?.childrenPolygonId === id)
    return foundGrowingSeason
  },[growingSeasons])

  const getFieldName = useCallback(() => {
    const response = filter(childrenPolygons, item => findGrowingSeasonById(item?.id))
    const updatedGrowingSeason = findGrowingSeasonById(response[0]?.id)
    return updatedGrowingSeason?.fieldData?.fieldName
  },[childrenPolygons, findGrowingSeasonById])

  return !isEmpty(childrenPolygons)
    ? (
      <>
        <ContainerField style={ { padding: '14px', width: '100%', marginTop: 20, marginBottom: 10 } }>
          <PolygonToSvg
            shape={ map(field.polygonCoordinates, ([ lat, lng ]) => ({ lat, lng })) }
            color={ colors.orange }
            width={ 40 }
          />
          <TextGroup>
            <TextLeft>

              <TextBold>{map(field?.productivityChallenge, challenge => (
                <Tooltip key={ challenge?.project?.slug } title={ `${ t('field participating in') } ${ challenge?.project?.projectName }` }>
                  <Icon icon={ productivityChallange } size={ 14 } style={ { marginRight: 3 } }/>
                </Tooltip>
              ))}{getFieldName() || field.fieldName}</TextBold>
              <Text>{parseFloat(field.area).toFixed(2)} ha</Text>
            </TextLeft>
            {editFields ? (
              <Row>
                <Button
                  id="edit-field"
                  color={ colors.white }
                  style={ {
                    padding: '5.5px 10px',
                    fontSize: '0.875rem',
                    marginRight: 5
                  } }
                  onClick={ () =>
                    history.push({
                      pathname: routes.fieldEdit.path,
                      state: { field, mapCenterPosition: getPolygonCenter(field.polygonCoordinates), editFields }
                    })
                  }
                >
                  <>
                    <Icon
                      icon={ pencil }
                      size={ 17 }
                      style={ { marginRight: 5 } }
                    />
                    <I18n>edit</I18n>
                  </>
                </Button>
                <IconButton
                  id="download-field"
                  color={ colors.white }
                  onClick={ () => generateKML(field?.polygonCoordinates, field?.fieldName) }
                  tooltip={ t('download kml') }
                >
                  <Icon
                    icon={ download }
                    size={ 17 }
                  />
                </IconButton>
              </Row>
            ) : !isEmpty(currentWeather) ? (
              <TextRight>
                <TemperatureText>
                  <Icon style={ { marginRight: 5 } } size={ 14 } icon={ arrowUpField } color={ colors.orange } />
                  <Text>{currentWeather?.high}°C</Text>
                </TemperatureText>
                <TemperatureText >
                  <Icon style={ { marginRight: 5 } } size={ 14 } icon={ arrowDownField } color={ colors.blue } />
                  <Text style={ size(toString(currentWeather?.low)) < 2 ? { marginLeft: 8 } : {} }>{currentWeather?.low}°C</Text>
                </TemperatureText>
                <div>
                  <Text style={ { margin: 0, fontWeight: 600 } }>{currentWeather?.precipitation} mm</Text>
                </div>
              </TextRight>
            ) : null }

          </TextGroup>
        </ContainerField>
        {map(childrenPolygons, (item, index) => {
          const itemGrowingSeason = findGrowingSeasonById(item.id)

          return (
            <Card
              style={ { marginBottom:  editFields ? 0 : 10, marginTop: editFields ? 0 : 20, cursor: editFields ? 'auto' : 'pointer', overflow: 'visible' } }
              cardStyle={ { width: '100%', marginBottom: 0, boxShadow: '', overflow: 'visible' } }
              onClick={ () => editFields ? {} : onClick({ field, childrenPolygonId: item.id, itemGrowingSeason }) }
              noBoxShadow
            >
              <ContainerField style={ { marginLeft: 30 } }>
                <ItemBorderLeft isLast={ childrenPolygons?.length === index + 1 } />
                <Line />
                <PolygonToSvg
                  color={ !editFields ? GROWING_SEASON_COLOR[itemGrowingSeason?.crop?.slug] || colors.orange : colors.orange }
                  shape={ map(item?.coordinates, ([ lat, lng ]) => ({ lat, lng })) }
                  width={ 40 }
                />
                <TextGroup>
                  <TextLeft>
                    <Row>
                      <TextBold>{item?.name}</TextBold>
                    </Row>
                    {!editFields && (
                      !isEmpty(itemGrowingSeason) ? (
                        <CropTextGroup>
                          <CropText style={ { marginRight: 3 } }>
                            {itemGrowingSeason?.crop?.description}
                          </CropText>
                          <CropText>
                            {itemGrowingSeason.sowingYear}
                          </CropText>
                        </CropTextGroup>
                      )
                        :
                        <Text style={ { fontStyle: 'oblique' } }>
                          <I18n>no crop</I18n>
                        </Text>

                    )}

                    <Text>{parseFloat(item.area).toFixed(2)} ha</Text>
                  </TextLeft>
                </TextGroup>
              </ContainerField>
            </Card>
          )
        })}
      </>
    ) : (
      <ContainerField
        style={ { padding: '14px', width: '100%', marginTop: 20, marginBottom: 10, cursor: editFields ? 'auto' : 'pointer' } }
        cardStyle={ { padding: '14px', width: '100%', marginBottom: 0 } }
        onClick={ () => editFields ? {} : onClick({ field, childrenPolygonId: null, itemGrowingSeason: growingSeasons[0] }) }
      >
        <PolygonToSvg
          shape={ map(field.polygonCoordinates, ([ lat, lng ]) => ({ lat, lng })) }
          color={ !isEmpty(growingSeasons) && !editFields ? GROWING_SEASON_COLOR[growingSeasons[0]?.crop?.slug] : colors.orange }
          width={ 40 }
        />
        <TextGroup>
          <TextLeft>
            <Row>
              {!isEmpty(field?.productivityChallenge) && (
                <Tooltip title={ <div>{map(field?.productivityChallenge, challenge => <p>{challenge?.project?.projectName}</p>)}</div> }>
                  <Icon icon={ productivityChallange } size={ 14 } style={ { marginRight: 3 } }/>
                </Tooltip>
              )}
              <TextBold>{field.fieldName}</TextBold>
            </Row>
            {!editFields && (
              !isEmpty(growingSeasons) ? (
                <CropTextGroup>
                  <CropText style={ { marginRight: 3 } }>
                    {growingSeasons[0]?.crop?.description}
                  </CropText>
                  <CropText>
                    {growingSeasons[0]?.sowingYear}
                  </CropText>
                </CropTextGroup>
              )
                :
                <Text style={ { fontStyle: 'oblique' } }>
                  <I18n>no crop</I18n>
                </Text>
            )}
            <Text>{parseFloat(field.area).toFixed(2)} ha</Text>
          </TextLeft>
          {editFields ? (
            <Row>
              <Button
                id="edit-field"
                color={ colors.white }
                style={ {
                  padding: '5.5px 10px',
                  fontSize: '0.875rem',
                  marginRight: 10
                } }
                onClick={ () =>
                  history.push({
                    pathname: routes.fieldEdit.path,
                    state: { field, mapCenterPosition: getPolygonCenter(field.polygonCoordinates), editFields }
                  })
                }
              >
                <>
                  <Icon
                    icon={ pencil }
                    size={ 17 }
                    style={ { marginRight: 5 } }
                  />
                  <I18n>edit</I18n>
                </>
              </Button>
              <IconButton
                id="download-field"
                color={ colors.white }
                onClick={ () => generateKML(field?.polygonCoordinates, field?.fieldName) }
                tooltip={ t('download kml') }
              >
                <Icon
                  icon={ download }
                  size={ 17 }
                />
              </IconButton>
            </Row>
          ) : !isEmpty(currentWeather) ? (
            <TextRight>
              <TemperatureText>
                <Icon style={ { marginRight: 5 } } size={ 14 } icon={ arrowUpField } color={ colors.orange } />
                <Text>{currentWeather?.high}°C</Text>
              </TemperatureText>
              <TemperatureText >
                <Icon style={ { marginRight: 5 } } size={ 14 } icon={ arrowDownField } color={ colors.blue } />
                <Text style={ size(toString(currentWeather?.low)) < 2 ? { marginLeft: 8 } : {} }>{currentWeather?.low}°C</Text>
              </TemperatureText>
              <div>
                <Text style={ { margin: 0, fontWeight: 600 } }>{currentWeather?.precipitation} mm</Text>
              </div>
            </TextRight>
          ) : null}
        </TextGroup>
      </ContainerField>
    )
}

FieldItem.propTypes = {
  field: PropTypes.shape({
    currentWeather: PropTypes.object,
    precipitation: PropTypes.number,
    fieldName: PropTypes.string,
    growingSeasons: PropTypes.array,
    area: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    polygonCoordinates: PropTypes.array,
    plantation: PropTypes.string,
    childrenPolygons: PropTypes.array,
    productivityChallenge: PropTypes.array
  }),
  editFields: PropTypes.bool,
  onClick: PropTypes.func
}

FieldItem.defaultProps = {
  field: {
    fieldName: null,
    growingSeasons: [],
    area: 0,
    polygonCoordinates: [],
    childrenPolygons: [],
    plantation: null,
    currentWeather: {},
    productivityChallenge: []
  },
  onClick: () => {},
  editFields: false
}

export default FieldItem
