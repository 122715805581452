import { call, takeLatest, put } from 'redux-saga/effects'


import isEmpty from 'lodash/isEmpty'

import {
  getUserOnboarding
} from '@smartcoop/services/apis/smartcoopApi/resources/authentication'
import {
  createFamilyGroupAccess as postCreateFamilyGroupAccess,
  patchFamilyGroupAccessUser,
  putFamilyGroupAccessUser,
  getFamilyGroupAccess
} from '@smartcoop/services/apis/smartcoopApi/resources/familyGroup'

import { FamilyGroupTypes, FamilyGroupActions } from './duckFamilyGroup'

function* createFamilyGroupAccess({ data, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(postCreateFamilyGroupAccess, data)

    onSuccess(data.name)
  } catch (error) {
    yield call(onError, error)
  }
}

function* editFamilyGroupUser({ data, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { email, ...rest } = data
    let response = rest

    if (!isEmpty(email)) {
      response = { ...response, email }
    }

    yield call(patchFamilyGroupAccessUser, response, { userId: data.id })

    onSuccess(data.name)
  } catch (error) {
    yield call(onError, error)
  }
}

function* editFamilyGroupUserAccess({ data, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(putFamilyGroupAccessUser, data.properties, { userId: data.userId })

    onSuccess()
  } catch (error) {
    yield call(onError, error)
  }
}

function* searchUserByDocument({ document, onSuccess = () => {} }) {
  try {
    const data = yield call(getUserOnboarding, {}, { document })

    onSuccess(data)
  } catch (error) {
    console.log(error)
  }
}

function* loadFamilyGroupAccessList({
  params = {},
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const {
      data: { data, ...pagination }
    } = yield call(getFamilyGroupAccess, params)

    yield put(
      FamilyGroupActions.loadFamilyGroupAccessListSuccess(data, pagination.page, () =>
        onSuccess(pagination)
      )
    )
  } catch (err) {
    const error = err.message
    yield call(onError, error)
  }
}

export default [
  takeLatest(FamilyGroupTypes.CREATE_FAMILY_GROUP_ACCESS, createFamilyGroupAccess),
  takeLatest(FamilyGroupTypes.EDIT_FAMILY_GROUP_USER, editFamilyGroupUser),
  takeLatest(FamilyGroupTypes.EDIT_FAMILY_GROUP_USER_ACCESS, editFamilyGroupUserAccess),
  takeLatest(FamilyGroupTypes.SEARCH_USER_BY_DOCUMENT, searchUserByDocument),
  takeLatest(FamilyGroupTypes.LOAD_FAMILY_GROUP_ACCESS_LIST, loadFamilyGroupAccessList)
]
