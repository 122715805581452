import React, { useMemo } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import ceil from 'lodash/ceil'
import map from 'lodash/map'


import { useT } from '@smartcoop/i18n'
import {
  getUserProductsBalance as getUserProductsBalanceService
} from '@smartcoop/services/apis/smartcoopApi/resources/productBalance'
import { momentBackDateTimeFormat, momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import { formatNumber } from '@smartcoop/utils/formatters'
import DataTable from '@smartcoop/web-components/DataTable'

const ProductBalanceListFragment = ({
  filters,
  urlParams,
  setLastUpdatedAt
}) => {
  const t = useT()

  const columns = useMemo(
    () => [
      {
        title: t('product'),
        field: 'organizationProduct.productName',
        cellStyle: { fontWeight: 'bold' },
        render: (row) => row.organizationProduct?.productName
      },
      {
        title: t('primary unit balance'),
        field: 'currentBalance',
        render: (row) => `${ formatNumber(ceil(row.currentBalance, 2)) } ${ row.organizationProduct?.measureUnit }`
      },
      {
        title: t('secondary unit balance'),
        field: '',
        render: (row) =>
          // eslint-disable-next-line eqeqeq
          !row.organizationProduct?.conversionFactor || row.organizationProduct?.conversionFactor == 0
            ? t('does not apply - short version')
            : `${ formatNumber((Math.floor(Number(row.currentBalance)/Number(row.organizationProduct?.conversionFactor)))) } ${ t('{this} of', { this: row.organizationProduct?.unitOfMeasuresForConversion }) } ${ formatNumber(ceil(row.organizationProduct?.conversionFactor, 2)) } ${ row.organizationProduct?.measureUnit }`
      }
    ], [t]
  )

  return(
    <DataTable
      onDataLoad={ data => {
        setLastUpdatedAt(moment.max(map(data, item => moment(item.lastUpdate, momentBackDateTimeFormat))).format(momentFriendlyDateTimeFormat))
      } }
      data={ getUserProductsBalanceService }
      queryParams ={ filters }
      columns={ columns }
      urlParams={ urlParams }
      id="product-balance-list-table"
    />
  )
}

ProductBalanceListFragment.propTypes = {
  filters: PropTypes.object,
  setLastUpdatedAt: PropTypes.func.isRequired,
  urlParams: PropTypes.object
}

ProductBalanceListFragment.defaultProps = {
  filters: {},
  urlParams: {}

}

export default ProductBalanceListFragment
