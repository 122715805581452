import React, { useCallback, useRef, useEffect, useMemo, useState } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import first from 'lodash/first'

import { contactService } from '@smartcoop/database/services/contactService'
import { conversationFileService } from '@smartcoop/database/services/conversationFileService'
import { conversationService } from '@smartcoop/database/services/conversationService'
import { groupMemberService } from '@smartcoop/database/services/groupMemberService'
import { messageService } from '@smartcoop/database/services/messageService'
import { database } from '@smartcoop/database/web-database'
import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'

import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser'
import { useForwardMessages } from '../../../hooks/useForwardMessages'
import { useSendFile } from '../../../hooks/useSendFile'
import { useSendMessage } from '../../../hooks/useSendMessage'
import ChangeCodeModal from '../../../modals/ChangeCodeModal/ChangeCodeModal'
import DeleteMessageModal from '../../../modals/DeleteMessageModal'
import SelectConversationsModal from '../../../modals/SelectConversationsModal'
import ChatFeed from '../ChatFeed'
import ChatFooter from '../ChatFooter'
import ChatHeader from '../ChatHeader'
import { Container } from './styles'

const ChatMessages = ({
  conversations,
  messages,
  relationalId,
  handleShowSearch,
  handleShowDetails,
  conversationFiles,
  groupMember
}) => {
  const scrollElementRef = useRef()
  const [isBroadcastAdmin, setIsBroadcastAdmin] = useState(false)
  const [selectedsMessages, setSelectedsMessages] = useState([])

  const t = useT()
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()
  const localUser = useAuthenticatedUser()
  const conversation = first(conversations)
  const isGroupMember = Boolean(groupMember)
  const { sendMessage } = useSendMessage(conversation, localUser)
  const { sendAudio, sendFile } = useSendFile(conversation, localUser)
  const { forwardMessages } = useForwardMessages(conversation, localUser)

  const handleChangeSelected = (message) => {
    if (selectedsMessages.includes(message)) {
      const index = selectedsMessages.indexOf(message)
      const tmpArray = [...selectedsMessages]

      tmpArray.splice(index, 1)

      return setSelectedsMessages(tmpArray)
    }

    return setSelectedsMessages([...selectedsMessages, message])
  }

  const selectedsIds = useMemo(() => selectedsMessages.map(item => item.id), [selectedsMessages])

  const onSendMessage = useCallback(
    (message) => sendMessage(
      message,
      () => scrollElementRef?.current?.scrollIntoView({ behavior: 'smooth' })
    ), [sendMessage]
  )

  const handleCancelSelecion = () => setSelectedsMessages([])

  // Scroll to the end when open screen
  useEffect(() => {
    if (scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView()
    }
  }, [messages, scrollElementRef])

  const messagesLength = useMemo(() => messages?.length || 0, [messages])

  useEffect(() => {
    if (conversation && conversation.relationalId && messagesLength) {
      messageService(database)
        .updateReadMessages(
          conversation.conversationId
        )
    }
  }, [conversation, messagesLength])

  useEffect(() => {
    setSelectedsMessages([])
  }, [conversation])

  const handleDeleteMessages = useCallback(() => {
    const showForAll = selectedsMessages.every(item => item.whoSend === localUser?.userId)
    const showForMe = selectedsMessages.some(item => item.whoSend !== localUser?.userId)

    createDialog({
      id: 'confirm-delete-messages',
      Component: DeleteMessageModal,
      props: {
        deleteAll: showForAll,
        deleteMe: (showForMe || showForAll),
        handleDelete: async (deleteForAll) => {
          await messageService(database).deleteMessages(selectedsMessages, deleteForAll)
          setSelectedsMessages([])
        }
      }
    })
  }, [createDialog, localUser, selectedsMessages])

  const handleForwardMessages = useCallback(() => {
    createDialog({
      id: 'confirm-forward-messages',
      Component: SelectConversationsModal,
      props: {
        contactId: conversation?.contactId || null,
        handleSubmit: async (selectedContacts) => {
          await forwardMessages(selectedContacts, selectedsMessages)
          setSelectedsMessages([])
        }
      }
    })
  }, [conversation, createDialog, forwardMessages, selectedsMessages])

  const openChangeCodeModal = useCallback(() => {
    createDialog({
      id: 'confirm-delete-messages',
      Component: ChangeCodeModal,
      props: {
        name: conversation?.name,
        contactId: conversation?.contactId,
        handleSubmit: async (contactId, userCode) => {
          await conversationService(database).updateCodeByContactId(contactId, userCode)
          await contactService(database).updateCodeByContactId(contactId, userCode)
        }
      }
    })
  }, [conversation, createDialog])

  useEffect(() => {
    if (conversation?.invalidCode) {
      openChangeCodeModal()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation?.invalidCode, openChangeCodeModal])

  useEffect(() => {
    if (conversation?.activeUser === false) {
      snackbar.warning(t('inactive recipient'))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation?.activeUser])

  const searchIsAdmin = useCallback(async () => {
    const isAdminResponse = await groupMemberService(database).checkUserIsAdmin(
      localUser?.userId,
      conversation?.conversationId
    )

    setIsBroadcastAdmin(isAdminResponse)
  }, [conversation, localUser])

  useEffect(() => {
    if (conversation?.broadcast) {
      searchIsAdmin()
    }
  }, [conversation, searchIsAdmin])

  return (
    <Container>
      <ChatHeader
        conversation={ conversation }
        userId={ (conversation?.group || conversation?.broadcast) ? conversation?.conversationId : conversation?.contactId }
        isGroup={ conversation?.group || conversation?.broadcast }
        isBroadcast={ conversation?.broadcast && !isBroadcastAdmin }
        conversationId={ conversation?.conversationId || null }
        showOptions={ !!selectedsMessages?.length }
        handleDeleteMessages={ handleDeleteMessages }
        handleForwardMessages={ handleForwardMessages }
        handleShowSearch={ handleShowSearch }
        handleShowDetails={ handleShowDetails }
        handleCancelSelecion={ handleCancelSelecion }
      />
      { conversation && (
        <>
          <ChatFeed
            messages={ messages }
            ref={ scrollElementRef }
            selecteds={ selectedsIds }
            conversationFiles={ conversationFiles }
            handleChangeSelected={ handleChangeSelected }
            isGroup={ conversation?.group }
            isBroadcast={ conversation.broadcast }
            hiddenFooter={ (conversation?.broadcast && !isBroadcastAdmin) }
          />
          {!(conversation?.broadcast && !isBroadcastAdmin) && (
            <ChatFooter
              onSendMessage={ onSendMessage }
              onSendAudio={ sendAudio }
              onUploadFile={ sendFile }
              relationalId={ relationalId }
              blockFields={ (
                (!conversation?.activeUser && !conversation.group && !conversation.broadcast) ||
                (conversation?.group && !isGroupMember) ||
                (conversation?.broadcast && !isBroadcastAdmin)
              ) }
            />
          )}
        </>
      ) }
    </Container>
  )
}

ChatMessages.propTypes = {
  conversations: PropTypes.array,
  messages: PropTypes.array,
  relationalId: PropTypes.string.isRequired,
  handleShowSearch: PropTypes.func.isRequired,
  handleShowDetails: PropTypes.func.isRequired,
  conversationFiles: PropTypes.array,
  groupMember: PropTypes.number
}

ChatMessages.defaultProps = {
  conversations: [],
  messages: [],
  conversationFiles: [],
  groupMember: 0
}

const enhance = withObservables(['conversationId', 'relationalId', 'userId'], ({ conversationId, relationalId, userId }) => ({
  conversations: conversationService(database).observeConversationById(conversationId, relationalId),
  messages: messageService(database).observeMessagesByConversationId(conversationId, relationalId),
  messagesCount: messageService(database).observeMessagesCountByRelationalId(relationalId, userId),
  conversationFiles: conversationFileService(database).observeConversationFileByConversationId(conversationId || relationalId),
  groupMember: groupMemberService(database).observeLocalUserIsMember(conversationId, userId)
}))

const EnhancedChatMessages = enhance(ChatMessages)

export default EnhancedChatMessages
