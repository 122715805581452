import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import number from '@smartcoop/forms/validators/number.validator'


const hayProductionCropManagementSchema = ({ t }) => Yup.object().shape({
  balesRollsPerHa: flow(
    number({ t })
  )(Yup.string()),

  balesWeightRollsPerHa: flow(
    number({ t })
  )(Yup.string())
})

export default hayProductionCropManagementSchema
