import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${ ({ viewType }) => viewType === 'portrait' ? '17vw' : '12vw' };
  width:${ ({ viewType }) => viewType === 'portrait' ? '12vw' : '14vw' };
  overflow: visible;

  img{
    height: ${ ({ viewType }) => viewType === 'portrait' ? '17vw' : '12vw' };
    width:${ ({ viewType }) => viewType === 'portrait' ? '12vw' : '14vw' };
    border-radius: 20px 20px;
    box-shadow: 4px 3px 5px rgba(50, 50, 50, 0.77);
    overflow: visible;
  }


  :hover {
    transform: scale(1.5);
    transition: all 0.5s;
    box-shadow: none;
    overflow: visible;
    cursor: pointer;
    z-index: 1000;
  }

`

export const Container = styled.div`
  .rec.rec-arrow {
    background-color: white;
    z-index: 10000;
  }
  .rec.rec-arrow {
    color: #000 !important;
  }
  .rec.rec-dot_active {
    box-shadow: none;
  }

  .rec.rec-dot:hover {
    box-shadow: 0 0 1px 2px ${ colors.secondary };
  }
  .rec-slider-container {
    overflow: visible;
  }
  .rec-slider {
    overflow: visible;
  }
  .rec-item-wrapper {
    overflow: visible;
  }
`
