import React, { useCallback, forwardRef } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import signUp from '@smartcoop/forms/schemas/auth/signup.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputCpfCnpj from '@smartcoop/web-components/InputCpfCnpj'
import ErrorModal from '@smartcoop/web-containers/modals/ErrorModal'
import LoadingModal from '@smartcoop/web-containers/modals/LoadingModal'

import { Container, ButtonContainer } from './styles'

const SignUpForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, onSubmit } = props

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        formRef.current.submit()
      }
    },
    [formRef]
  )

  const dispatch = useCallback(useDispatch(), [])
  const { createDialog, removeDialog } = useDialog()
  const t = useT()

  const handleSubmit = useCallback(
    ({ document: doc }) => {
      createDialog({
        id: 'loading',
        Component: LoadingModal
      })

      const removeLoader = () => {
        setTimeout(() => removeDialog({ id: 'loading' }), 150)
      }

      dispatch(AuthenticationActions.validateSignUp(
        doc,
        () => {
          onSubmit()
          removeLoader()
        },
        (error) => {
          createDialog({
            id: 'user-not-found',
            Component: ErrorModal,
            props: {
              message: t(error)
            }
          })
          removeLoader()
        }
      ))
    },
    [createDialog, dispatch, onSubmit, removeDialog, t]
  )

  return (
    <Container>
      <Form
        ref={ formRef }
        schemaConstructor={ signUp }
        onSubmit={ handleSubmit }
      >
        <InputCpfCnpj
          name="document"
          label="CPF/CNPJ"
          fullWidth
          onKeyDown={ handleKeyDown }
        />

        {/* <InputDate
          name="dateOfBirth"
          label={ t('date of birth') }
          fullWidth
          onKeyDown={ handleKeyDown }
        /> */}

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="signup-submit"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
            >
              <I18n>signup</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

SignUpForm.propTypes = {
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

SignUpForm.defaultProps = {
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default SignUpForm
