import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'

import {
  Badge
} from './styles'

const FieldAnalysisStatus = props => {
  const {
    status,
    style
  } = props

  const options = useMemo(() => {
    switch (status) {
      case 'Concluída':
        return {
          name: 'Concluída',
          background: '(22, 67, 183, 0.1)',
          color: '(22, 67, 183, 1)'
        }
      case 'Amostrada':
        return {
          name: 'Amostrada',
          background: '(40, 159, 48, 0.1)',
          color: '(40, 159, 48, 1)'
        }
      default:
        return {
          name: <I18n>none</I18n>,
          background: '(29, 29, 27, 0.1)',
          color: '(29, 29, 27, 1)'
        }
    }
  }, [status])

  return (
    <Badge
      backgroundColorBadge={ options.background }
      colorBadge={ options.color }
      style={ style }
    >
      { options.name }
    </Badge>
  )
}


FieldAnalysisStatus.propTypes = {
  status: PropTypes.oneOf([
    'Concluída',
    'Amostrada'
  ]),
  style: PropTypes.object
}

FieldAnalysisStatus.defaultProps = {
  style: {},
  status: 'Amostrada'
}

export default FieldAnalysisStatus
