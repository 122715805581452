
import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export default () => ({
  '@global': {
    '@font-face': [
      ...fonts.fontFaceSans,
      ...fonts.fontFaceMonospace,
      ...fonts.fontFaceMontserrat
    ],
    'html, body, #root': {
      fontFamily: fonts.fontFamilySans,
      fontWeight: fonts.regular,
      backgroundColor: colors.backgroundHtml,
      width: '100%',
      height: '100%',
      padding: 0,
      margin: 0,
      flex: 1
    },
    'html': {
      overflow: 'hidden'
    },
    'html, body': {
      // display: 'block'
    },
    '#root': {
      display: 'flex',
      overflow: 'auto',
      whiteSpace: 'pre-line'
      // position: 'absolute'
    },
    'code, pre, em': {
      fontFamily: `${ fonts.fontFamilyMonospace } !important`,
      '& *': {
        fontFamily: `${ fonts.fontFamilyMonospace } !important`
      }
    },
    a: {
      textDecoration: 'none'
    },
    form: {
      width: '100%'
    },
    // docz fira code fix
    'div[data-testid="live-editor"]': {
      fontSize: 13,
      '& textarea': {
        letterSpacing: '0.115em !important'
      }
    },
    // docz dark theme fix
    'div[data-testid="live-preview"]': {
      color: colors.text,
      display: 'flex'
    }
  }
})
