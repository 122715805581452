const NDVI = [
  '#800026',
  '#bd0026',
  '#e31a1c',
  '#fc4e2a',
  '#fc6329',
  '#dcae2c',
  '#fed337',
  '#fbf40c',
  '#fcff2b',
  '#fdffce',
  '#e4f9bc',
  '#bde99e',
  '#9fd877',
  '#8cc762',
  '#7cc065',
  '#32bb2e',
  '#08a355',
  '#0d794c',
  '#055a5b',
  '#064e62',
  '#164166',
  '#08055b'
]

const NDRE = [
  '#800026',
  '#bd0026',
  '#e31a1c',
  '#fc4e2a',
  '#fc6329',
  '#dcae2c',
  '#fed337',
  '#fbf40c',
  '#fcff2b',
  '#fdffce',
  '#e4f9bc',
  '#bde99e',
  '#9fd877',
  '#8cc762',
  '#7cc065',
  '#32bb2e',
  '#08a355',
  '#0d794c',
  '#055a5b',
  '#064e62',
  '#164166',
  '#08055b'
]

const STDEV = [
  '#fc4e2a',
  '#fcff2b',
  '#e4f9bc',
  '#32bb2e',
  '#08a355'
]

export {
  NDVI,
  NDRE,
  STDEV
}
