import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'


export const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 30px;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 20px;
  margin: 10px;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const Subtitle = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const Text = styled(Typography)`
  color: ${ colors.text };
  font-size: 16px;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const FormLabel = styled(Typography)`
  color: ${ colors.text };
  font-size: 16px;
  padding-bottom: 12px;
  font-weight: 700;
`

export const FormLabelSubtitle = styled(Typography)`
  color: ${ colors.text };
  font-size: 12px;
  font-weight: 500;
  margin-top: -14px;
  padding-bottom: 12px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
`
