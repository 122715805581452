import React, { useRef, useCallback } from 'react'

import PropTypes from 'prop-types'

import { ThemeProvider } from '@material-ui/core'

import nonComplianceCommentSchema from '@smartcoop/forms/schemas/shoppingPlatform/nonCompliance/nonComplianceComment.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { organizationRounded } from '@smartcoop/icons'
import Avatar from '@smartcoop/web-components/Avatar'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import InputText from '@smartcoop/web-components/InputText'

import {
  Container,
  Name,
  Header,
  Identifier,
  Body,
  Footer,
  textFieldTheme,
  Col,
  ColFooter1,
  ColFooter2,
  Privacy,
  Type
} from './styles'

const PostNonComplianceComment = ({ style, organization, onSubmit, textFieldProps, buttonProps , answer, user }) =>  {
  const t = useT()
  const formRef = useRef(null)

  const handleSubmit = useCallback(
    (data) => {
      onSubmit(data)
    },
    [onSubmit]
  )

  return (
    <ThemeProvider theme={ textFieldTheme }>
      <Container
        style={ style }
        ref={ formRef }
        schemaConstructor={ nonComplianceCommentSchema }
        onSubmit={ handleSubmit }
      >
        <Header>
          <Identifier>
            <Avatar alt={ user?.name  } src={ user?.image || user?.name } />
            <Col>
              <Name>{user?.name }</Name>
              <Privacy>
                <Icon icon={ organizationRounded } />
                <Type>{organization?.tradeName || 'Organização'}</Type>
              </Privacy>
            </Col>
          </Identifier>
        </Header>
        <Body>

          <InputText
            placeholder={ t(!answer ? 'insert non compliance' : 'insert non compliance answer').replace('\n', ' ') }
            name="comment"
            fullWidth
            multiline
            rows={ 4 }
            style={ { marginBottom: 0 } }
            { ...textFieldProps }
          />
        </Body>
        <Footer>
          <ColFooter1/>
          <ColFooter2>
            <Button
              id="button"
              color="secondary"
              onClick={ () => formRef.current.submit() }
              { ...buttonProps }
            >
              <I18n>{!answer ? 'send non compliance' : 'non compliance answer'}</I18n>
            </Button>
          </ColFooter2>
        </Footer>
      </Container>

    </ThemeProvider>
  )}

PostNonComplianceComment.propTypes = {
  style: PropTypes.object,
  organization: PropTypes.shape({
    companyName: PropTypes.string,
    tradeName: PropTypes.string,
    companyImage: PropTypes.string }),
  user: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string
  }),
  onSubmit: PropTypes.func,
  textFieldProps: PropTypes.object,
  buttonProps: PropTypes.object,
  comment: PropTypes.shape({
    observation: PropTypes.string
  }),
  answer: PropTypes.bool
}

PostNonComplianceComment.defaultProps = {
  style: {},
  organization: {
    companyName: '',
    tradeName: ''
  },
  user: {
    name: '',
    image: ''
  },
  onSubmit: () => {},
  textFieldProps: {},
  buttonProps: {},
  comment: {
    observation: ''
  },
  answer: false
}

export default PostNonComplianceComment
