import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { select, call, put, takeLatest } from 'redux-saga/effects'

import { v4 as uuidv4 } from 'uuid'

import find from 'lodash/find'

import {
  createAnimalBirth as createAnimalBirthService,
  editAnimalBirth as editAnimalBirthService,
  getAnimalBirthsById as getAnimalBirthsByIdService,
  deleteAnimalBirth as deleteAnimalBirthService
} from '@smartcoop/services/apis/smartcoopApi/resources/animalBirth'
import { AnimalActions } from '@smartcoop/stores/animal'
import { OfflineAnimalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineAnimalUserData/duckOfflineAnimalUserData'
import { selectOfflineAnimalStatus, selectOfflineBirths } from '@smartcoop/stores/offlineData/userData/offlineAnimalUserData/selectorOfflineAnimalUserData'
import { OfflineTechnicalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData'
import { selectOfflineTechnicalBirths } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData/selectorOfflineTechnicalUserData'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'

import { selectCurrentAnimal } from '../animal/selectorAnimal'
import { selectModuleIsTechnical } from '../module/selectorModule'
import { AnimalBirthActions, AnimalBirthTypes } from './duckAnimalBirth'
import { selectAnimalBirths } from './selectorAnimalBirth'

function* loadAnimalBirths({ params = {}, onSuccess= () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const currentAnimal = yield select(selectCurrentAnimal)
    const propertyId = currentProperty?.id
    const animalId = currentAnimal?.id

    const { data: { data, ...pagination } } = yield call(
      getAnimalBirthsByIdService,
      {
        limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
        ...params
      },
      { propertyId, animalId }
    )

    yield put(AnimalBirthActions.loadAnimalBirthsSuccess(
      data,
      pagination.page,
      () => onSuccess(data)
    ))
  } catch (err) {
    const error = err.message
    yield put(AnimalBirthActions.animalBirthError(error))
    yield call(onError, error)
  }
}


function* loadAnimalBirthsSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* saveAnimalBirth({ params, onSuccess = () => {}, onError = () => {}, isEdit = false, isConnected }) {
  try {
    const { data } = yield call(
      !isEdit ? createAnimalBirthService : editAnimalBirthService,
      params,
      {
        animalBirthId: isEdit ? params?.id : null,
        propertyId: params?.propertyId
      }
    )
    if(isConnected) {
      yield call(onSuccess, data)
    }
  } catch (err) {
    const error = err.message
    yield put(AnimalBirthActions.animalBirthError(error))
    yield call(onError, error)
  }
}

function* saveOfflineAnimalBirth({ params, onSuccess = () => {}, onError = () => {}, isConnected, web = false }) {
  try {
    const currentAnimal = yield select(selectCurrentAnimal)
    const currentProperty = yield select(selectCurrentProperty)
    const currentAnimalBirths = web ? null : yield select(selectAnimalBirths)
    const isTechnical = yield select(selectModuleIsTechnical)
    const animalBirthsTechnical = web ? null : yield select(selectOfflineTechnicalBirths)
    const animalBirths = web ? null : yield select(selectOfflineBirths)
    const status = web ? null : yield select(selectOfflineAnimalStatus)

    const newBirth = {
      ...params,
      id: params?.id || uuidv4(),
      propertyId: currentProperty?.id,
      lot: currentAnimal.lot
    }

    if(!web) {
      const newAnimal = isConnected ? {
        ...currentAnimal,
        births: [
          newBirth,
          ...currentAnimalBirths
        ]
      } : {
        ...currentAnimal,
        births: [
          newBirth,
          ...(isTechnical ? animalBirthsTechnical : animalBirths)
        ],
        animalStatus: find(status, item => item.id === 5),
        statusId: find(status, item => item.id === 5).id
      }

      if (isTechnical) {
        yield put(OfflineTechnicalUserDataActions.updateOfflineAnimal(newAnimal))
      } else {
        yield put(OfflineAnimalUserDataActions.updateOfflineAnimal(newAnimal))
      }

      yield put(AnimalActions.setCurrentAnimal(newAnimal))

      if(!isConnected){
        yield call(onSuccess, {
          ...newBirth
        })
      }
    }

    yield put(AnimalBirthActions.saveAnimalBirth(newBirth, isConnected ? onSuccess : () => {}, onError, !!params.id, isConnected))
  } catch (err) {
    const error = err.message
    yield put(AnimalBirthActions.animalBirthError(error))
    yield call(onError, error)
  }
}

function* deleteAnimalBirth({ animalBirthId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const propertyId = currentProperty?.id

    yield call(deleteAnimalBirthService, { propertyId, animalBirthId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(AnimalBirthActions.animalBirthError(error))
    yield call(onError, error)
  }
}

function* deleteOfflineAnimalBirth({ animalBirthId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(AnimalBirthActions.deleteAnimalBirth(animalBirthId, onSuccess, onError))

  } catch (err) {
    const error = err.message
    yield put(AnimalBirthActions.animalBirthError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(AnimalBirthTypes.LOAD_ANIMAL_BIRTHS, loadAnimalBirths),
  takeLatest(AnimalBirthTypes.LOAD_ANIMAL_BIRTHS_SUCCESS, loadAnimalBirthsSuccess),

  takeLatest(AnimalBirthTypes.SAVE_ANIMAL_BIRTH, saveAnimalBirth),
  takeLatest(AnimalBirthTypes.SAVE_OFFLINE_ANIMAL_BIRTH, saveOfflineAnimalBirth),

  takeLatest(AnimalBirthTypes.DELETE_ANIMAL_BIRTH, deleteAnimalBirth),
  takeLatest(AnimalBirthTypes.DELETE_OFFLINE_ANIMAL_BIRTH, deleteOfflineAnimalBirth)

]
