import styled from 'styled-components'

export const FiltersContainer = styled.div`
  form {
    display: flex;
    column-gap: 10px;

    button {
      height: 40px;
    }
  }
`

export const BodyContainer = styled.div`
  width: 70vw;
  height: calc(80vh - 150px);
`
