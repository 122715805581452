import { useEffect, useCallback, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { camelCase, isEmpty } from 'lodash'
import find from 'lodash/find'

import { useDialog } from '@smartcoop/dialog'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import {
  selectTerms,
  selectUserIsSupplier
} from '@smartcoop/stores/authentication/selectorAuthentication'
import AcceptTermModal from '@smartcoop/web-containers/modals/AcceptTermModal'

const useTerm = (
  slug = null,
  startOpened = true,
  onCancel = () => {},
  onError = () => {},
  forceShow = null
) => {
  const { createDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])

  const mounted = useRef(false)

  const terms = useSelector(selectTerms)
  const isSupplier = useSelector(selectUserIsSupplier)

  const selectedTerm = useMemo(
    () => find(terms, { termType: { slug } }) ?? {},
    [slug, terms]
  )
  const accept = useMemo(
    () => !isEmpty(selectedTerm?.userTerm),
    [selectedTerm]
  )

  const showModal = useMemo(
    () =>
      ({
        privacyTerm: !accept,
        socialNetworkTerm: !accept,
        commercializationTerm: !accept,
        supplierTerm: !accept && isSupplier,
        organizationTerm: !accept && !isSupplier,
        useTerm: !accept && !isSupplier
      }[camelCase(slug)]),
    [accept, isSupplier, slug]
  )

  const modalTerm = useCallback(
    (onWillClose = () => {}, viewOnly = false) => {
      if ((!accept || viewOnly || forceShow) && !isEmpty(selectedTerm)) {
        createDialog({
          id: `term-modal-${ slug }`,
          Component: AcceptTermModal,
          props: {
            viewOnly,
            slug,
            onWillClose,
            onCancel,
            forceShow
          }
        })
      }
    },
    [accept, createDialog, forceShow, onCancel, selectedTerm, slug]
  )

  const openModal = useCallback(() => {
    if (startOpened && showModal) modalTerm()
  }, [modalTerm, showModal, startOpened])

  useEffect(() => {
    if (
      (mounted.current && isEmpty(terms)) ||
      (mounted.current && showModal)
    ) {
      dispatch(AuthenticationActions.loadTerms(openModal, onError, forceShow))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, mounted.current])

  useEffect(() => {
    mounted.current = true
  }, [])

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  return [modalTerm, selectedTerm, terms]
}

export default useTerm
