import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  machinery: [],
  currentMachine: {},
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadMachinery: ['params', 'onSuccess', 'onError'],
  loadMachinerySuccess: ['machinery', 'page', 'onSuccess'],

  saveMachine: ['params', 'onSuccess', 'onError'],
  saveOfflineMachine: ['params', 'onSuccess', 'onError'],
  updateMachine: ['params', 'machineId', 'onSuccess', 'onError'],

  addMachineFiles: ['params', 'machineId', 'onSuccess', 'onError'],
  editMachineFiles: ['params', 'machineId', 'onSuccess', 'onError'],
  deleteMachine: ['machineId', 'onSuccess', 'onError'],

  setCurrentMachine: ['currentMachine'],
  resetCurrentMachine: [],

  machineError: ['error']
})

/**
 * Assigning offline actions
 */
markActionsOffline(Creators, [
  'saveMachine',
  'updateMachine',
  'addMachineFiles',
  'editMachineFiles'
])


/**
 * Reducers functions
 */
const loadMachinerySuccess = (state = INITIAL_STATE, { machinery, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  machinery: page === 1 ? machinery : [
    ...state.machinery,
    ...machinery
  ]
})


const machineError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const setCurrentMachine = (state = INITIAL_STATE, { currentMachine }) => ({
  ...state,
  currentMachine
})

const resetCurrentMachine = (state = INITIAL_STATE) => ({
  ...state,
  currentMachine: INITIAL_STATE.currentMachine
})


const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_MACHINERY_SUCCESS]: loadMachinerySuccess,

  [Types.MACHINE_ERROR]: machineError,

  [Types.SET_CURRENT_MACHINE]: setCurrentMachine,
  [Types.RESET_CURRENT_MACHINE]: resetCurrentMachine,


  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as MachineTypes,
  Creators as MachineActions
}
