import * as Yup from 'yup'

const filterMilkDelivery = () => Yup.object().shape({
  date: Yup.object().nullable(),

  earring: Yup.string(),
  lactationNumber: Yup.string(),
  lotId: Yup.string()
})

export default filterMilkDelivery
