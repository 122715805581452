import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'


export class GroupModel extends Model {
  static table = 'group'

  @field('groupId')
  groupId: string

  @field('publicKey')
  publicKey: string

  @field('name')
  name: string

  @field('photo')
  photo: string

  @field('isBroadcast')
  isBroadcast: boolean
}
