import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import { colors } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'

const drawerWidth = 240
const topBarHeight = 64

export const UserMenuContainer = styled.div`
  min-width: 150
`

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    overflowX: 'hidden'
  },
  drawer: {
    zIndex: 99
  },
  appBar: {
    boxShadow: 'none',
    zIndex: 100,
    color: 'white',
    [theme.breakpoints.up('sm')]: {
      width: '100%'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex'
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'auto'
  },
  menuItensContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    fontSize: fonts.fontSize.L,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 15
  },
  brandToolbarContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  brandToolbar: {
    filter: 'grayscale(1) brightness(100)',
    height: '75%'
  },
  toolbarRightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > * ': {
      marginLeft: 10
    }
  },
  toolbarRoot: {
    transition: 'all .2s',
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between'
  },
  menuLink: {
    display: 'flex',
    '& * ': {
      color: `${ colors.grey } !important`,
      fill: `${ colors.grey } !important`,
      whiteSpace: 'nowrap'
    }
  },
  listItemIcon: {
    width: 56,
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  listItem: {
    padding: '11px 0',
    width: '100%'
  },
  menuActive: {
    display: 'flex',
    '& * ': {
      color: `${ colors.primary } !important`,
      fontWeight: '600',
      fill: `${ colors.primary } !important`
    },
    '& > div': {
      backgroundColor: `${ colors.backgroundHtml } !important`
    },
    '& $activeIndicator': {
      opacity: 1,
      backgroundColor: `${ colors.backgroundHtml } !important`,
      borderRight: 'solid 5px black'
    },
    '& $listItem': {
      width: 'calc(100% - 4px)'
    },
    '& $listItemIcon': {
      width: 46,
      marginRight: 5
    }
  },
  activeIndicator: {
    borderRadius: '0px 6px 6px 0px',
    opacity: 0
  },
  drawerFooter: {
    position: 'absolute',
    bottom: 2,
    left: 5,
    width: '93%',
    textAlign: 'right',
    '& > span': {
      color: colors.text
    }
  },
  drawerItems: {
    marginTop: topBarHeight
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1
    }
  },

  changeArea: {
    display: 'flex',
    cursor: 'pointer'
  },

  exitProperty: {
    marginLeft: 20,
    cursor: 'pointer'
  },

  changeProperty: {
    marginLeft: 20,
    fontSize: 16,
    fontWeight: 700,
    cursor: 'pointer'
  },

  leftWrapper: {
    alignItems: 'center',
    display: 'flex'
  },

  leftContainer: {
    display: 'inline-flex'
  }
}))

export default useStyles
