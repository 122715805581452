import React, { useCallback, forwardRef, useMemo } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import registerPevSchema from '@smartcoop/forms/schemas/dairyFarm/registerPev.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputText from '@smartcoop/web-components/InputText'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

import {
  Container,
  FormContainer,
  ButtonsContainer,
  Item
} from './styles'

const RegisterPevForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel,
    isEditing
  } = props

  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      onSubmit(data)
    },
    [onSubmit]
  )

  const ableOptions = useMemo(
    () => ([
      { label: t('yes'), value: true },
      { label: t('no'), value: false }
    ]),
    [t]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerPevSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputDate
                label={ t('registry date') }
                name="registryDate"
                fullWidth
                defaultValue={ defaultValues.registryDate }
                pickerProps={ {
                  maxDate: moment().format()
                } }
              />
            </Item>

            <Item>
              <InputText
                name="reason"
                label={ t('reason') }
                fullWidth
                defaultValue={ defaultValues.reason }
                multiline
                rows={ 4 }
              />
            </Item>

            <Item>
              <RadioGroup
                label={ t('able?') }
                name="able"
                options={ ableOptions }
                variant="row"
                defaultValue={ defaultValues.able }
                disabled={ isEditing }
              />
            </Item>

          </Grid>

        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-cancel-form-button"
              onClick={ onCancel }
              color={ colors.white }
              disabled={ loading }
              fullWidth
              style={ { marginRight: 5 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="web-save-form-button"
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
              fullWidth
              style={ { marginLeft: 5 } }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

RegisterPevForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  isEditing: PropTypes.bool,
  defaultValues: PropTypes.object.isRequired
}

RegisterPevForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {},
  isEditing: false,
  withoutSubmitButton: false
}

export default RegisterPevForm
