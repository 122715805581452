import React, { useState, useCallback } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import map from 'lodash/map'

import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import Fade from '@material-ui/core/Fade'


import ArrowDownIconButton from '@smartcoop/web-components/IconButton/ArrowDownIconButton'
import ArrowUpIconButton from '@smartcoop/web-components/IconButton/ArrowUpIconButton'

import useStyles, { Container, ItemContainer } from './styles'

const AccordionsGroup = (props) => {
  const {
    invertArrow,
    items,
    defaultOpened,
    toggleOnClick
  } = props

  const classes = useStyles()

  const [opened, setOpened] = useState(defaultOpened)

  const handleToggle = useCallback(
    (index) => {
      setOpened(old => {
        const newOpened = old !== index ? index : -1

        if (old > -1 && items[old].onClose) {
          items[old].onClose()
        }

        if (newOpened > -1 && items[newOpened].onOpen) {
          items[newOpened].onOpen()
        }

        return newOpened
      })
    },
    [items]
  )

  const IconButton = useCallback(
    ({ index, ...restItem }) => (
      opened === index
        ? <ArrowUpIconButton { ...restItem } />
        : <ArrowDownIconButton { ...restItem } />
    ),
    [opened]
  )

  return (
    <Container>
      {map(items, ({ children, onOpen, onClose, ...item }, index) => (
        <ItemContainer
          key={ index }
          { ...item }
          cardStyle={ {
            padding: 0,
            width: '100%',
            borderTopLeftRadius: index !== 0 ? 0 : undefined,
            borderTopRightRadius: index !== 0 ? 0 : undefined,
            borderBottomLeftRadius: index !== (items.length - 1) ? 0 : undefined,
            borderBottomRightRadius: index !== (items.length - 1) ? 0 : undefined
          } }
          onHeaderClick={ toggleOnClick ? () => handleToggle(index) : undefined }
          headerRight={ (
            <IconButton
              index={ index }
              size="small"
              onClick={ () => !toggleOnClick && handleToggle(index) }
              className={ clsx({ [classes.invertArrow]: invertArrow }) }
            />
          ) }
        >
          <Collapse in={ opened === index }>
            <Fade in={ opened === index }>
              <CardContent style={ { padding: 0 } }>
                {children}
              </CardContent>
            </Fade>
          </Collapse>
        </ItemContainer>
      ))}
    </Container>
  )
}

AccordionsGroup.propTypes = {
  invertArrow: PropTypes.bool,
  defaultOpened: PropTypes.number,
  toggleOnClick: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.any,
    children: PropTypes.any,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
  }))
}

AccordionsGroup.defaultProps = {
  invertArrow: false,
  toggleOnClick: false,
  defaultOpened: -1,
  items: []
}

export default AccordionsGroup
