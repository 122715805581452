import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import trimMask from '@meta-awesome/functions/src/trimMask'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'


import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { getUserByCpf } from '@smartcoop/services/apis/smartcoopApi/resources/user'
import { useSnackbar } from '@smartcoop/snackbar'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import Button from '@smartcoop/web-components/Button'
import InputCpfCnpj from '@smartcoop/web-components/InputCpfCnpj'
import InputEmail from '@smartcoop/web-components/InputEmail'
import InputText from '@smartcoop/web-components/InputText'
import Modal from '@smartcoop/web-components/Modal'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import useStyles, { Container } from './styles'

const TransferPropertyModal = ({ id, open, handleClose }) => {
  const classes = useStyles()
  const t = useT()
  const dispatch = useDispatch()
  const snackbar = useSnackbar()

  const [userFound, setUserFound] = useState(null)
  const [document, setDocument] = useState('')

  const { createDialog } = useDialog()

  const currentProperty = useSelector(selectCurrentProperty)
  const searchUser = useCallback(
    async () => {
      if(!isEmpty(document)) {
        const data = await getUserByCpf({}, { document: trimMask(document) })
        setUserFound(data)
      }
    },
    [document]
  )
  const onSubmit = useCallback(() => {
    createDialog({
      id: 'confirm-delete',
      Component: ConfirmModal,
      props: {
        onConfirm: () => dispatch(
          PropertyActions.transferProperty(
            { userId: userFound?.id, propertyId: currentProperty?.id },
            () => {
              dispatch(PropertyActions.loadProperties())
              snackbar.success(
                t('your property was transfered')
              )
              dispatch(PropertyActions.resetCurrentProperty())
              handleClose()
            }
          )
        ),
        textWarning: t('are you sure you want to transfer the property {property} to the user {this}?', {
          howMany: 1,
          gender: 'female',
          this: `${ userFound?.document } - ${ userFound?.name }`,
          property: currentProperty?.name
        })
      }
    })

  }, [createDialog, currentProperty, dispatch, handleClose, snackbar, t, userFound])

  return (
    <Modal
      id={ id }
      open={ open }
      escape={ false }
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0 } }
      title={ t('transfer property') }
      FooterComponent={
        () => (
          <ButtonsContainer
            style={ {
              flex: 1,
              padding: '10px 20px'
            } }
          >
            <Button
              id="cancel-property-activity-details-form"
              onClick={ handleClose }
              variant="outlined"
              style={ { flex: '0 0 auto', minWidth: 150, marginRight: 10 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="next-page-map"
              onClick={ onSubmit }
              style={ { flex: '0 0 auto', minWidth: 150 } }
              disabled={ !userFound }
            >
              <I18n>confirm</I18n>
            </Button>
          </ButtonsContainer>
        )
      }
    >
      <Container>
        <InputCpfCnpj
          detached
          onChange={ ({ target : { value } }) => {
            setUserFound(null)
            setDocument(value)
          } }
          name="document"
          label="CPF"
          fullWidth
          value={ document }
          onBlur={ searchUser }
        />
        {userFound ? (
          <>
            <InputText
              detached
              value={ userFound?.name }
              label={ t('name') }
              style={ { width: '100%' } }
              disabled
            />

            <InputEmail
              detached
              label={ t('email') }
              style={ { width: '100%' } }
              disabled
              value={ userFound?.email }
            />

            <InputText
              label={ t('phone') }
              detached
              style={ { width: '100%' } }
              disabled
              type="text"
              value={ userFound?.cellPhone }
            />
          </>
        ) : null}
      </Container>
    </Modal>
  )}
TransferPropertyModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}
export default TransferPropertyModal
