import React, { useCallback } from 'react'

import PropTypes from 'prop-types'

import { marker as markerIcon } from '@smartcoop/icons'
import Maps from '@smartcoop/web-components/Maps'
import PinMarker from '@smartcoop/web-components/Maps/markers/PinMarker'

import { MapItem } from './styles'


const ChatMapLocation = ({ coordinates }) => {
  const onOpenLocation = useCallback(({ latitude, longitude }) => {
    const locationUrl = `https://maps.google.com/?q=${ latitude },${ longitude }`

    window.open(locationUrl, '_blank')
  }, [])

  return (
    <MapItem onClick={ () => onOpenLocation(coordinates) }>
      <Maps
        region={ coordinates }
        layer="streets"
        style={ { flex: 1 } }
        zoom={ 13 }
        dragging={ false }
        zoomControl={ false }
        attributionControl={ false }
        streetOnly
      >
        <PinMarker
          coordinate={ coordinates }
          customIcon={ markerIcon }
        />
      </Maps>
    </MapItem>
  )
}

ChatMapLocation.propTypes = {
  coordinates: PropTypes.object.isRequired
}

export default ChatMapLocation
