import React from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  Row,
  Text,
  PaletteItem
} from './styles'

const ColorPalette = props => {
  const {
    colors,
    startLabel,
    endLabel,
    ...rest
  } = props

  return (
    <>
      {!isEmpty(colors) && (
        <div { ...rest }>
          <Row>
            <Text style={ { paddingRight: 5 } }>{startLabel}</Text>
            {
              map(colors, color => (
                <PaletteItem
                  color={ color }
                  key={ color }
                />
              ))
            }
            <Text style={ { paddingLeft: 5 } }>{endLabel}</Text>
          </Row>
        </div>
      )}
    </>
  )
}


ColorPalette.propTypes = {
  colors: PropTypes.array.isRequired,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string
}

ColorPalette.defaultProps = {
  startLabel: '',
  endLabel: ''
}

export default ColorPalette
