/* eslint-disable no-param-reassign */
import { Database, Q } from '@nozbe/watermelondb'

import api from '@smartcoop/services/apis/smartcoopApi'

const SYNC_PENDING_FIELDS = [
  'type',
  'body',
  'params',
  'url',
  'localMessageId',
  'registerType'
]

export function syncPendingService(database: Database) {
  async function create(data) {
    await database.get('sync_pending').create(item => {
      SYNC_PENDING_FIELDS.forEach(field => { item[field] = data[field] })
      item.sync = false
    })
  }

  async function update(register, error = '') {
    await database.write(async () => {
      await register.update(item => {
        item.sync = true
        item.error = error
      })
    })
  }

  async function updateTryAgain(id) {
    const register = await database.get('sync_pending').find(id)

    await database.write(async () => {
      await register.update(item => {
        item.sync = false
        item.error = null
      })
    })
  }

  async function getUnsyncChanges(registerType) {
    if (registerType) {
      return database
        .get('sync_pending')
        .query(
          Q.where('sync', false),
          Q.where('error', ''),
          Q.where('registerType', registerType)
        )
        .fetch()
    }

    return database
      .get('sync_pending')
      .query(
        Q.where('sync', false),
        Q.where('error', ''),
        Q.where('registerType', Q.notEq('message'))
      )
      .fetch()
  }

  async function sync(registerType, callback = () => {}, callbackError = () => {}) {
    const registers = await getUnsyncChanges(registerType)

    if (registers.length) {
      const promises = registers.map(register => async () => {
        try {
          const response = await api.request({
            url: register.url,
            method: register.type,
            params: register.params ? JSON.parse(register.params) : register.params,
            data: register.body ? JSON.parse(register.body) : register.body
          })

          await update(register)
          await callback(register._raw, response)
        } catch(error) {
          await update(register, error.message)
          if (register.localMessageId && register.id) {
            await callbackError(register.localMessageId, register.id)
          }
        }
      }).reverse()

      await Promise.all(promises.map(fn => fn()))
    }
  }

  function observeUnsyncChanges(registerType) {
    if (registerType) {
      return database
        .collections.get('sync_pending')
        .query(
          Q.where('sync', false),
          Q.where('error', ''),
          Q.where('registerType', registerType)
        )
        .observe()
    }

    return database
      .collections.get('sync_pending')
      .query(
        Q.where('sync', false),
        Q.where('error', ''),
        Q.where('registerType', Q.notEq('message'))
      )
      .observe()
  }

  return {
    sync,
    create,
    update,
    updateTryAgain,
    observeUnsyncChanges
  }
}
