import React, { useMemo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import debounce from 'lodash/debounce'
import includes from 'lodash/includes'
import isNull from 'lodash/isNull'

import { ThemeProvider } from '@material-ui/core'

import I18n, { useT } from '@smartcoop/i18n'
import { selectAllSlugs } from '@smartcoop/stores/authentication/selectorAuthentication'
import { selectCurrentModule } from '@smartcoop/stores/module/selectorModule'
import { colors } from '@smartcoop/styles'
import { PROFILES } from '@smartcoop/utils/constants'
import GoBackIconButton from '@smartcoop/web-components/IconButton/GoBackIconButton'
import InputSearch from '@smartcoop/web-components/InputSearch'
import FamilyGroupAccessFragment from '@smartcoop/web-containers/fragments/profile/FamilyGroupAccessFragment'
import UserAccessFragment from '@smartcoop/web-containers/fragments/profile/UserAccessFragment'
import { Title } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import {
  Container,
  Row,
  ButtonContainer,
  TabButton,
  inputSearchTheme,
  UpperRowContainer
} from './styles'

const UserAccessScreen = () => {

  const currentModule = useSelector(selectCurrentModule)
  const allSlugs = useSelector(selectAllSlugs)
  const history = useHistory()

  const [listType, setListType] = useState('technicalList')
  const [filterText, setFilterText] = useState('')
  const [debouncedFilterText, setDebouncedFilterText] = useState('')

  const t = useT()

  const DataTableList = useMemo(
    () => ({
      'technicalList': UserAccessFragment,
      'familyGroupList': FamilyGroupAccessFragment
    }[listType]),
    [listType]
  )

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )

  return (
    <Container>
      {isNull(currentModule) && (
        <UpperRowContainer>
          <GoBackIconButton onClick={ () => history.goBack() } iconColor={ colors.primary } />
          <Title
            style={ {
              lineHeight: 1,
              fontSize: 18
            } }
          >
            <I18n>go back</I18n>

          </Title>
        </UpperRowContainer>
      )}
      <Row>
        <ButtonContainer>
          <TabButton
            id="technical-list"
            onClick={ () => setListType('technicalList') }
            color={ listType === 'technicalList' ? colors.black : colors.white }
          >
            <I18n>technical</I18n>
          </TabButton>
          {includes(allSlugs, PROFILES.associatedRuralProducer) && (
            <TabButton
              id="family-group-list"
              onClick={ () => setListType('familyGroupList') }
              color={ listType === 'familyGroupList' ? colors.black : colors.white }
            >
              <I18n>family group</I18n>
            </TabButton>
          )}
        </ButtonContainer>
        <div>
          <ThemeProvider theme={ inputSearchTheme }>
            <InputSearch
              detached
              onChange={ onChangeSearchFilter }
              value={ filterText }
              placeholder={ t('search') }
              fullWidth
              style={ { marginRight: 12 } }
            />
          </ThemeProvider>
        </div>
      </Row>
      <div>
        <DataTableList filterText={ { q: debouncedFilterText } } />
      </div>
    </Container>
  )
}

export default UserAccessScreen
