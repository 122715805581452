import styled from 'styled-components'

export const Badge = styled.div`
  background-color: rgba${ props => props.backgroundColorBadge };
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open Sans";
  color: ${ props => props.colorBadge };
  width: 130px;
`
