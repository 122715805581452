// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
      <g>
        <path fill="#D3D3D3" d="M197.9,67.2c-4.7-17.5-22.7-28-40.2-23.4c0,0-1.3,0.3-3.5,0.9c-5.6-20.8-24.6-36.2-47.2-36.2
          c-24.5,0-44.8,18.1-48.3,41.6c-0.8-0.2-1.3-0.3-1.3-0.3c-14.8-3.8-30,5-33.9,19.8c-0.9,3.5-1.1,7-0.8,10.4c-7.4,2-12,3.2-12,3.2
          c-7,1.9-11.1,9-9.2,16c1.6,6.1,7.3,10,13.4,9.7h150.3c0,0,6.2-0.3,9.4-1.1C192.1,102.9,202.6,84.8,197.9,67.2z"/>
      </g>
      <g>
        <g>
          <path fill="#D3D3D3" d="M98.1,174.8c-0.3,0-0.5,0-0.8-0.2c-1.1-0.4-1.6-1.7-1.1-2.7l22.8-54.1c0.4-1.1,1.7-1.6,2.7-1.1
            c1.1,0.4,1.6,1.7,1.2,2.7L100,173.4C99.7,174.3,98.9,174.8,98.1,174.8z"/>
          <path fill="#D3D3D3" d="M94.8,189.7c0,5.7-4.6,10.3-10.3,10.3c-5.7,0-10.3-4.6-10.3-10.3s4.6-10.3,10.3-10.3
            C90.2,179.4,94.8,184,94.8,189.7z"/>
        </g>
        <g>
          <path fill="#D3D3D3" d="M60,164.4c0,3.6-2.9,6.4-6.4,6.4c-3.6,0-6.4-2.9-6.4-6.4c0-3.6,2.9-6.4,6.4-6.4
            C57.1,158,60,160.9,60,164.4z"/>
          <path fill="#D3D3D3" d="M63.7,141.9c-0.3,0-0.5,0-0.8-0.2c-1.1-0.4-1.6-1.7-1.1-2.7l8.8-21.3c0.4-1.1,1.7-1.6,2.7-1.1
            c1.1,0.4,1.6,1.7,1.2,2.7l-8.8,21.3C65.3,141.5,64.5,141.9,63.7,141.9z"/>
        </g>
        <g>
          <path fill="#D3D3D3" d="M142,170.4c0,3.6-2.9,6.4-6.4,6.4c-3.6,0-6.4-2.9-6.4-6.4c0-3.6,2.9-6.4,6.4-6.4
            C139.1,164,142,166.9,142,170.4z"/>
          <path fill="#D3D3D3" d="M142,158c-0.3,0-0.5,0-0.8-0.2c-1.1-0.4-1.6-1.7-1.1-2.7l8.8-21.3c0.4-1.1,1.7-1.6,2.7-1.1
            c1.1,0.4,1.6,1.7,1.2,2.7l-8.8,21.3C143.6,157.5,142.8,158,142,158z"/>
        </g>
        <path fill="#D3D3D3" d="M91.8,159.5c-0.3,0-0.5,0-0.8-0.2c-1.1-0.4-1.6-1.7-1.1-2.7l11.3-27.3c0.4-1.1,1.7-1.6,2.7-1.1
          c1.1,0.4,1.6,1.7,1.2,2.7l-11.3,27.3C93.4,159,92.7,159.5,91.8,159.5z"/>
      </g>
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
