
import styled from 'styled-components'

export const InputTextArea = styled.textarea`
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border: none;
  border-radius: 8px;
  font-family: 'Open Sans';
  resize: none;
  height: 50px;
  overflow-y: hidden;
`
