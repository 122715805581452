import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'



export const Container = styled.div`
  background: ${ colors.white };
  border-radius: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
`

export const Header = styled.div`
  padding: 14px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

export const Identifier = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Name = styled(Typography)`
  font-size: 18px;
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: 600;
  color: ${ colors.black };
`

export const Col = styled.div`
  padding-left: 12px;
`

export const Privacy = styled.div`
  display: flex;
  align-items: center;
`

export const Type = styled(Typography)`
  margin-left: 4px;
  font-size: 14px;
  font-family: ${ fonts.fontFamilySans };
  font-weight: 600;
  color: ${ colors.darkGrey };
`

export const Timer = styled(Typography)`
  font-size: 14px;
  font-family: ${ fonts.fontFamilySans };
  font-weight: 600;
  color: ${ colors.darkGrey };
`

export const Body = styled.div`
  padding: 0px 18px 14px 18px;
`

export const Text = styled(Typography)`
  font-size: 16px;
  font-family: ${ fonts.fontFamilySans };
  color: ${ colors.darkGrey };
`

export const Footer = styled.div`
  padding: 0 18px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #E0E0E0;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const ColFooter1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;

  @media (min-width: 768px) {
    margin-bottom: 0px;
    margin-right: 10px;
  }
`
