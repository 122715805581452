import api from '../api'

export const getSupplierQuotationProposal = async (params, { supplierQuotationId, supplierId }) => api.get(`/v1/purchase-demands/${ supplierQuotationId }/proposal/supplier/${ supplierId }`, { params })

export const saveSupplierQuotationProposal = async (params, { supplierQuotationId, supplierId }) => api.post(`/v1/purchase-demands/${ supplierQuotationId }/proposal/${ supplierId }`, params)

export const editSupplierQuotationProposal = async (params, { supplierQuotationId, supplierQuotationProposalId }) => api.patch(`/v1/purchase-demands/${ supplierQuotationId }/proposal/${ supplierQuotationProposalId }`, params)

export const removeSupplierQuotationProposal = async (params, { proposalId }) => api.delete(`/v1/purchase-demands/proposal/${ proposalId }`, params)

export const refuseSupplierQuotationProposal = async ({ supplierQuotationId, supplierId }) => api.post(`/v1/purchase-demands/${ supplierQuotationId }/proposal/${ supplierId }`, { statusId: 2 })
