import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 80vw;
  gap: 3em;
`

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const Item = styled.div`
  box-sizing: border-box;
`

export const AvatarNameContainer = styled.div`
`

export const ButtonContainer = styled.div`
`

export const FirstRowContainer = styled(RowContainer)`
  justify-content: space-between;
`

export const Text = styled.span`
  font-size: 16px;
`

export const Name = styled(Text)`
  font-weight: 700;
  font-size: 17px;
`
