import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  current: undefined,
  forecast: undefined,
  aggregatedRain: undefined,
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  searchCurrentWeather: ['latitude', 'longitude', 'onSuccess', 'onError'],
  searchCurrentWeatherSuccess: ['current'],

  searchWeatherForecast: ['latitude', 'longitude', 'onSuccess', 'onError'],
  searchWeatherForecastSuccess: ['forecast'],

  searchAggregatedRain: ['initialDate', 'finalDate'],
  searchAggregatedRainSuccess: ['aggregatedRain'],

  weatherForecastError: ['error']
})

/**
 * Reducers functions
 */
const searchCurrentWeatherSuccess = (state = INITIAL_STATE, { current }) => ({
  ...state,
  current
})

const searchWeatherForecastSuccess = (state = INITIAL_STATE, { forecast }) => ({
  ...state,
  forecast
})

const searchAggregatedRainSuccess = (state = INITIAL_STATE, { aggregatedRain }) => ({
  ...state,
  aggregatedRain
})

const weatherForecastError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.SEARCH_CURRENT_WEATHER_SUCCESS]: searchCurrentWeatherSuccess,
  [Types.SEARCH_WEATHER_FORECAST_SUCCESS]: searchWeatherForecastSuccess,
  [Types.SEARCH_AGGREGATED_RAIN_SUCCESS]: searchAggregatedRainSuccess,

  [Types.WEATHER_FORECAST_ERROR]: weatherForecastError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as WeatherForecastTypes,
  Creators as WeatherForecastActions
}
