import trimMask from '@meta-awesome/functions/src/trimMask'
import { cpf, cnpj } from 'cpf-cnpj-validator'

import size from 'lodash/size'

export default ({ t, onlyCnpj }) => YupInstance => YupInstance
  .test('cpf', t('invalid CPF'), (value) => {
    if (!onlyCnpj && size(trimMask(value)) <= 11) {
      return cpf.isValid(value)
    }
    return true
  })
  .test('cnpj', t('invalid CNPJ'), (value) => {
    if (onlyCnpj || size(trimMask(value)) > 11) {
      return cnpj.isValid(value)
    }
    return true
  })
