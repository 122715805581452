import { tableSchema } from '@nozbe/watermelondb'


export const contactSchema = tableSchema({
  name: 'contact',
  columns: [
    { name: 'contactId', type: 'string' },
    { name: 'contactType', type: 'string' },
    { name: 'profiles', type: 'string' },
    { name: 'chatNickname', type: 'string' },
    { name: 'photo', type: 'boolean' },
    { name: 'publicKey', type: 'string' },
    { name: 'userCode', type: 'string' },
    { name: 'company', type: 'string' },
    { name: 'default', type: 'boolean' },
    { name: 'activeUser', type: 'boolean' }
  ]
})
