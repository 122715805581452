import React, { useCallback, forwardRef, useMemo } from 'react'

import PropTypes from 'prop-types'

import filterPevSchema from '@smartcoop/forms/schemas/dairyFarm/filterPev.schema'
import I18n, { useT } from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputText from '@smartcoop/web-components/InputText'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

import { Container, ButtonContainer } from './styles'

const FilterPevForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()

  const ableOptions = useMemo(
    () => ([
      { label: t('yes'), value: true },
      { label: t('no'), value: false }
    ]),
    [t]
  )

  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterPevSchema }
        onSubmit={ handleSubmit }
      >

        <InputDate
          label={ t('registry date') }
          name="registryDate"
          fullWidth
          defaultValue={ filters.registryDate }
        />

        <InputText
          name="reason"
          label={ t('reason') }
          fullWidth
          defaultValue={ filters.reason }
          multiline
          rows={ 4 }
        />

        <RadioGroup
          label={ t('able?') }
          name="able"
          options={ ableOptions }
          variant="row"
          defaultValue={ filters.able }
          clearable
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-order-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterPevForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterPevForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterPevForm
