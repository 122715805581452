import styled from 'styled-components'

import { hexToRgba } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'

const Badge = styled.div`
  display: flex;
  background-color: ${ ({ backgroundColorBadge }) => hexToRgba(backgroundColorBadge, .1) };
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: ${ fonts.fontSize.SS }px;
  font-weight: ${ fonts.fontWeight.semiBold };
  font-family: ${ fonts.fontFamilySans };
  color: ${ ({ colorBadge }) => colorBadge };
`

export default Badge
