import React, { useCallback, forwardRef, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import capitalize from 'lodash/capitalize'

import Grid from '@material-ui/core/Grid'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { SalesOrdersActions } from '@smartcoop/stores/salesOrders'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import SignatureOrderModal from '@smartcoop/web-containers/modals/commercialization/SignatureOrderModal'

import FutureSaleForm from './FutureSaleForm'
import InCashForm from './InCashForm'
import { Container } from './styles'
import TriggerForm from './TriggerForm'

const CreateOrderForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    onSubmit,
    onSucess,
    onCancel,
    onError,
    onChangeFormType
  } = props

  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('in_cash')
  const { createDialog } = useDialog()

  const handleSubmit = useCallback(
    (incomingData) => {
      const {
        exibitionBalance,
        exibitionBalanceInUnit,
        exibitionQuotation,
        totalValueExibition,
        conversionFactor,
        ...data
      } = incomingData
      const quantity = incomingData.quantity * incomingData.conversionFactor
      onSubmit()
      createDialog({
        id: 'signature-order-modal',
        Component: SignatureOrderModal,
        props: {
          onSuccess: () => {
            setLoading(true)
            dispatch(SalesOrdersActions.saveOfflineSalesOrder({ ...data, type, quantity },
              () => {
                snackbar.success(t('your {this} was registered', {
                  howMany: 1,
                  this: t('demand', { howMany: 1 }),
                  gender: 'female'
                }))
                setLoading(false)
                onSucess(data)
              },
              () => {
                setLoading(false)
                onError()
              }
            ))
          },
          onCancel: () => {
            setLoading(false)
            onCancel()
          }
        }
      })
    },
    [createDialog, dispatch, onCancel, onError, onSubmit, onSucess, snackbar, t, type]
  )

  const statusOptions = useMemo(
    () => [
      {
        label: capitalize(t('in cash')),
        value: 'in_cash'
      },
      {
        label: capitalize(t('upcoming', { gender: 'female' })),
        value: 'future_sale'
      },
      {
        label: capitalize(t('trigger')),
        value: 'trigger'
      }
    ],
    [t]
  )

  const OrderForm = useMemo(() => {
    switch (type) {
      case 'future_sale':
        return FutureSaleForm
      case 'trigger':
        return TriggerForm
      case 'in_cash':
      default:
        return InCashForm
    }
  }, [type])

  const handeStatusChange = useCallback(
    ({ value }) => {
      setType(value)
      onChangeFormType(value)
    },
    [onChangeFormType]
  )

  return (
    <Container>
      <Grid container>
        <RadioGroup
          options={ statusOptions }
          variant="row"
          style={ { marginBottom: 20 } }
          value={ type }
          onChange={ handeStatusChange }
          detached
        />
      </Grid>
      <OrderForm
        ref={ formRef }
        onSubmit={ handleSubmit }
        withoutSubmitButton={ withoutSubmitButton }
        loading={ loading }
      />
    </Container>
  )
})

CreateOrderForm.propTypes = {
  onSubmit: PropTypes.func,
  onSucess: PropTypes.func,
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  onChangeFormType: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

CreateOrderForm.defaultProps = {
  onSucess: () => {},
  onSubmit: () => {},
  onCancel: () => {},
  onError: () => {},
  onChangeFormType: () => {},
  withoutSubmitButton: false
}

export default CreateOrderForm
