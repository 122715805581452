import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

const INITIAL_STATE = {
  pregnancyDiagnostics: [],
  currentPregnancyDiagnostic: {},
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadPregnancyDiagnostics: ['params', 'onSuccess', 'onError'],
  loadPregnancyDiagnosticsSuccess: ['params', 'page', 'onSuccess'],

  loadPregnancyDiagnostic: ['params', 'onSuccess', 'onError'],
  loadPregnancyDiagnosticSuccess: ['params', 'onSuccess'],

  savePregnancyDiagnostic: ['params', 'onSuccess', 'onError', 'isEdit'],
  saveOfflinePregnancyDiagnostic: ['params', 'onSuccess', 'onError', 'web'],
  deletePregnancyDiagnostic: ['diagnosisId', 'onSuccess', 'onError'],
  deleteOfflinePregnancyDiagnostic: ['diagnosisId', 'onSuccess', 'onError'],

  pregnancyDiagnosticError: ['error']
})

markActionsOffline(Creators, [
  'loadPregnancyDiagnostics',
  'savePregnancyDiagnostic'
])

/**
 * Reducers functions
 */
const loadPregnancyDiagnosticsSuccess = (state = INITIAL_STATE, { params, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  pregnancyDiagnostics: page === 1 ? params : [
    ...state.pregnancyDiagnostics,
    ...params
  ]
})

const loadPregnancyDiagnosticSuccess = (state = INITIAL_STATE, { params }) => ({
  ...state,
  error: INITIAL_STATE.error,
  currentPregnancyDiagnostic: params
})

const pregnancyDiagnosticError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */

export default createReducer(INITIAL_STATE, {
  [Types.LOAD_PREGNANCY_DIAGNOSTICS_SUCCESS]: loadPregnancyDiagnosticsSuccess,

  [Types.LOAD_PREGNANCY_DIAGNOSTIC_SUCCESS]: loadPregnancyDiagnosticSuccess,

  [Types.PREGNANCY_DIAGNOSTIC_ERROR]: pregnancyDiagnosticError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as AnimalPregnancyDiagnosticsTypes,
  Creators as AnimalPregnancyDiagnosticsActions
}
