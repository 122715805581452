import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import Button from '@smartcoop/web-components/Button'
import Card from '@smartcoop/web-components/Card'

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-family: ${ fonts.fontFamilySans };
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin: 10px 0;
`

export const ComplianceButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  justify-content: space-between;
`

export const ComplianceButton = styled(Button)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  padding: 10px;
  border-radius: 5px;
  flex: 0;
`

export const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 21px;
  font-size: 16px;
  flex: 1;
`

export const AddressContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`

export const Volume = styled.span``

export const CardAddress = styled(Card)`
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  margin: 0;
`

export const QuantityAddress = styled.div`
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.32);
  margin: 0 0 0 14px;
  border-radius: 5px;
  padding: 10px;
  background: #ffffff;
`

export const VolumeContainer = styled.div`
  width: auto;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  margin: 5px 0 10px;
  font-size: 16px;
  flex: 1;
`
