import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import number from '@smartcoop/forms/validators/number.validator'

const registerProduct = ({ t }) =>  Yup.object().shape({

  description: Yup.string(),

  unitOfMeasures: Yup.string(),

  ean: Yup.string(),

  dun14: flow(
    number({ t })
  )(Yup.string()),

  productGroupId: Yup.string().nullable(),

  active: Yup.string().nullable(),

  cultivate: Yup.string().nullable(),

  purchasing: Yup.string().nullable(),

  commercialization: Yup.string().nullable(),

  digitalization: Yup.string().nullable(),

  continuous: Yup.string().nullable(),

  scheduled: Yup.string().nullable()
})

export default registerProduct
