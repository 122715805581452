import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import List from '@material-ui/core/List'

import I18n from '@smartcoop/i18n'
import { PropertyActions } from '@smartcoop/stores/property'
import {
  selectPropertyPendingManagements,
  selectReloadData
} from '@smartcoop/stores/property/selectorProperty'
import Loader from '@smartcoop/web-components/Loader'
import Management from '@smartcoop/web-components/Management'

import { EmptyState } from './styles'

const PropertyPendingManagementsList = (props) => {
  const { property } = props

  const dispatch = useCallback(useDispatch(), [])
  const mounted = useRef(false)

  const [loading, setLoading] = useState(false)

  const propertyPendingManagements = useSelector(
    selectPropertyPendingManagements
  )
  const reloadData = useSelector(selectReloadData)

  useEffect(() => {
    if (property?.id || reloadData) {
      setLoading(true)
      dispatch(
        PropertyActions.loadPropertyPendingManagements(
          property?.id,
          () => mounted.current && setLoading(false),
          () => mounted.current && setLoading(false)
        )
      )
    }
  }, [dispatch, property, reloadData])

  useEffect(() => {
    mounted.current = true
  }, [])

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  return loading && isEmpty(propertyPendingManagements) ? (
    <Loader width={ 100 } />
  ) : (
    <>
      {isEmpty(propertyPendingManagements) ? (
        <I18n as={ EmptyState }>no found scheduled managements</I18n>
      ) : (
        <List style={ { padding: 0 } }>
          {map(propertyPendingManagements, (cropManagement) => (
            <Management
              key={ cropManagement?.id }
              date={ cropManagement?.predictedDate }
              managementType={ cropManagement?.operation?.name }
              done={ cropManagement?.realizationDate }
              cropManagement={ cropManagement }
              fieldName={ cropManagement.growingSeason?.field?.fieldName }
            />
          ))}
        </List>
      )}
    </>
  )
}

PropertyPendingManagementsList.propTypes = {
  property: PropTypes.object
}

PropertyPendingManagementsList.defaultProps = {
  property: {}
}

export default PropertyPendingManagementsList
