import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import I18n from '@smartcoop/i18n'
import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import Button from '@smartcoop/web-components/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 15px;
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 18px;
`

export const IconText = styled(I18n)`
  font-weight: 600;
  font-size: 15px;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
`

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin: 10px 0 3px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 22px;
`
export const TabButton = styled(Button)`
  flex: 1;
  white-space: nowrap;
  margin-right: 12px;
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 10px;
`

export const InputItem = styled.div`
  display: flex;
  padding: 8px;
  flex: 5;
`

export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid ${ colors.lightGrey };
  margin: 0;
  margin-bottom: 5px;
`