

import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Loader = styled.div`
width: 8px;
margin-left: 20px;
aspect-ratio: 1;
border-radius: 50%;
animation: l5 1s infinite linear alternate;
@keyframes l5 {
  0%  {box-shadow: 15px 0 ${ colors.secondary }, -15px 0 #0002;background: ${ colors.secondary } }
  33% {box-shadow: 15px 0 ${ colors.secondary }, -15px 0 #0002;background: #0002}
  66% {box-shadow: 15px 0 #0002,-15px 0 ${ colors.secondary }; background: #0002}
  100%{box-shadow: 15px 0 #0002,-15px 0 ${ colors.secondary }; background: ${ colors.secondary } }
}
`
export const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: 20px;
  column-gap: 10px;
`

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${ colors.backgroundHtml };
  padding: 20px;
  flex: 1;
  overflow-y: 'auto';
`

export const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 15px;

  > button {
    margin: 0 !important;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
  }
`
