import { useCallback, useEffect, useState } from 'react'

import isEmpty from 'lodash/isEmpty'

import { conversationFileService } from '@smartcoop/database/services/conversationFileService'
import { database } from '@smartcoop/database/web-database'

export function useLocalFile(message, conversationFile) {
  const [inProggress, setInProggress] = useState(false)

  const fetchData = useCallback(async () => {
    try {
      await conversationFileService(database).fetchDocument({
        messageId: message.messageId,
        conversationId: message.conversationId,
        documentName: message.documentName,
        registerId: message.id
      })
    } catch(error) {
      console.log('[Sync File] Erro ao buscar arquivo: ', error)
    }
  }, [message])

  useEffect(() => {
    if (message.isDocument && !message.delAt) {
      if (isEmpty(conversationFile) && !inProggress) {
        setInProggress(true)
        fetchData()
      }
    }
  }, [conversationFile, fetchData, inProggress, message])

  return {
    inProggress
  }
}
