import React, { useMemo, useState } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import { database } from '@smartcoop/database'
import { contactService } from '@smartcoop/database/services/contactService'
import { conversationService } from '@smartcoop/database/services/conversationService'
import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'

import ContactCard from './parse/ContactCard'
import ConversationCard from './parse/ConversationCard'
import { Container, HeaderText , ButtonContainer, ListWrapper } from './styles'


const SelectConversationsModal = ({
  id,
  open,
  handleClose,
  handleSubmit,
  conversations,
  contacts
}) => {
  const [selecteds, setSelecteds] = useState([])

  const t = useT()
  const snackbar = useSnackbar()

  const filteredContacts = useMemo(
    () => contacts?.filter(contact => !conversations.some(
      conversation => conversation.contactId === contact.contactId
    )),
    [contacts, conversations]
  )

  const handleChangeSelected = (item) => {
    if (selecteds.includes(item)) {
      const index = selecteds.indexOf(item)
      const tmpArray = [...selecteds]

      tmpArray.splice(index, 1)

      return setSelecteds(tmpArray)
    }

    return setSelecteds([...selecteds, item])
  }

  const onSubmit = () => {
    if (!selecteds.length) {
      return snackbar.warning(t('select at least one contact to forward the message'))
    }

    handleClose()

    return handleSubmit(selecteds)
  }

  return (
    <Modal
      id={ id }
      open={ open }
      escape={ false }
      maxWidth="sm"
      PaperProps={ { style: { width: '100%' } } }
      hideHeader
    >
      <Container>
        <HeaderText>
          <I18n>forward message</I18n>
        </HeaderText>

        <ListWrapper>
          { conversations?.map(conversation => (
            <ConversationCard
              key={ conversation.conversationId }
              conversation={ conversation }
              userId={ (conversation.group || conversation.broadcast) ? conversation.conversationId : conversation?.contactId }
              isGroup={ conversation.group || conversation.broadcast }
              selected={ selecteds.includes(conversation) }
              handleSelect={ () => handleChangeSelected(conversation) }
            />
          )) }
          { filteredContacts?.map(contact => (
            <ContactCard
              key={ contact.id }
              contact={ contact }
              userId={ contact.contactId }
              selected={ selecteds.includes(contact) }
              handleSelect={ () => handleChangeSelected(contact) }
            />
          )) }
        </ListWrapper>

        <ButtonContainer>
          <Button
            id="cancel"
            onClick={ onSubmit }
            style={ { minWidth: '100%' } }
            color="secondary"
          >
            <I18n>forward</I18n>
          </Button>
          <Button
            id="cancel"
            onClick={ handleClose }
            style={ { minWidth: '100%' } }
          >
            <I18n>cancel</I18n>
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  )
}

SelectConversationsModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  conversations: PropTypes.array.isRequired,
  contacts: PropTypes.array.isRequired
}

const enhance = withObservables(['contactId'], ({ contactId }) => ({
  conversations: conversationService(database).observeConversations(contactId),
  contacts: contactService(database).observeContacts(contactId)
}))

const EnhancedSelectConversationsModal = enhance(SelectConversationsModal)

export default EnhancedSelectConversationsModal
