import React from 'react'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import Divider from '@material-ui/core/Divider'

import I18n from '@smartcoop/i18n'
import Modal from '@smartcoop/web-components/Modal'

import MachineDetailsFragment from '../MachineDetailsFragment'
import { TitleScreen } from './styles'

const MachineDetailsModal = (props) => {
  const { id, open, handleClose, machines } = props

  return (
    <Modal
      id={ id }
      open={ open }
      maxWidth="lg"
      fullWidth
    >
      <>
        <TitleScreen>
          <I18n>
            {machines.length > 1 ? 'machines details' : 'machine details'}
          </I18n>
        </TitleScreen>
        <Divider />
        {map(machines, (machine, index) => (
          <div key={ index }>
            {index > 0 ? (<Divider style={ { background: 'black' } }/>) : <></>}
            <MachineDetailsFragment machine={ machine } handleClose={ handleClose } manyMachines />
          </div>
        ))}
      </>
    </Modal>
  )
}

MachineDetailsModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  machines: PropTypes.array.isRequired
}

export default MachineDetailsModal
