export default ({ size } = {}) => /* html */`
<svg width="${ size }" height="${ size }" version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1080 1080" style="enable-background:new 0 0 1080 1080;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFDC04;}
	.st1{fill:#1D1E1C;}
	.st2{clip-path:url(#SVGID_00000042007008282803316260000003395673652528594594_);}
	.st3{fill:#FDEF76;}
	.st4{fill:#FFFFFF;}
	.st5{fill:none;stroke:#FDEA14;stroke-width:0.4278;stroke-miterlimit:10;}
</style>
<g>
	<circle class="st0" cx="813.86" cy="564.6" r="258.12"/>
	<g>
		<path class="st1" d="M22.12,610.36c-9.4-9.4-14.1-20.74-14.1-34.01h9.52c0,10.67,3.78,19.76,11.34,27.26
			c7.5,7.56,16.59,11.34,27.26,11.34c10.61,0,19.7-3.78,27.26-11.34c7.56-7.5,11.34-16.59,11.34-27.26
			c0-10.5-3.86-19.12-11.59-25.87c-3.06-2.65-6.98-5.25-11.77-7.79c-1.9-1.04-4.1-2.13-6.58-3.29l-6.75-3.03
			c-9.06-4.04-15.26-7.36-18.6-9.95c-5.48-4.21-8.22-9.2-8.22-14.97c0-5.83,2.13-10.79,6.4-14.88c4.33-4.1,9.52-6.14,15.58-6.14
			c6.58,0,11.88,1.76,15.92,5.28c1.33,1.15,2.42,2.39,3.29,3.72c0.29,0.46,0.55,0.89,0.78,1.3c0.06,0.17,0.11,0.32,0.17,0.43
			l0.09,0.09v0.09l-8.74,3.64l-0.09-0.09l-0.09-0.17c-0.12-0.17-0.26-0.37-0.43-0.61c-0.46-0.63-1.07-1.21-1.82-1.73
			c-2.25-1.61-5.28-2.42-9.09-2.42c-3.17,0-6,1.07-8.48,3.2c-2.71,2.25-4.06,5.02-4.06,8.31c0,2.89,1.9,5.65,5.71,8.31
			c2.71,1.9,7.91,4.56,15.58,7.96l3.38,1.47l3.55,1.64c2.65,1.27,5.05,2.48,7.18,3.63c5.48,2.94,10.07,6.06,13.76,9.35
			c9.64,8.6,14.45,19.44,14.45,32.54c0,13.27-4.7,24.61-14.11,34.01c-9.4,9.4-20.74,14.11-34.01,14.11S31.52,619.76,22.12,610.36z"
			/>
		<path class="st1" d="M231.29,619.79h-9.52v-90.43l-52.27,93.8l-52.18-93.8v90.43h-9.52V491.37l61.7,113.02l61.79-113.02V619.79z"
			/>
		<path class="st1" d="M366.9,619.62h-10.64l-15.92-30.89h-81.52l-15.92,30.89h-10.64l67.32-130.75L366.9,619.62z M263.67,579.29
			h71.82l-35.91-69.75L263.67,579.29z"/>
		<path class="st1" d="M463.84,539.49c0,9.92-3.03,18.78-9.09,26.57c-6,7.67-13.7,12.78-23.11,15.32l31.76,38.25h-12.29
			l-30.55-36.78h-43.18v36.78h-9.52V496.05h52.61c5.89,0,11.51,1.13,16.87,3.38c5.19,2.19,9.78,5.31,13.76,9.35
			c4.04,3.98,7.15,8.57,9.35,13.76C462.71,527.95,463.84,533.6,463.84,539.49z M420.48,573.41c4.56,0,8.94-0.89,13.15-2.68
			c4.1-1.73,7.7-4.15,10.82-7.27c3.11-3.17,5.54-6.78,7.27-10.82c1.79-4.21,2.68-8.59,2.68-13.15c0-4.61-0.89-9.03-2.68-13.24
			c-1.73-4.04-4.15-7.64-7.27-10.82c-3.11-3.12-6.72-5.54-10.82-7.27c-4.21-1.79-8.6-2.68-13.15-2.68h-43.09v67.93H420.48z"/>
		<path class="st1" d="M559.9,496.05v9.43h-45.43v114.14h-9.43V505.48h-45.43v-9.43H559.9z"/>
	</g>
	<g>
		<defs>
			<circle id="SVGID_1_" cx="813.86" cy="564.6" r="258.12"/>
		</defs>
		<clipPath id="SVGID_00000151505390263453272530000005098175500496353687_">
			<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
		</clipPath>
		<g style="clip-path:url(#SVGID_00000151505390263453272530000005098175500496353687_);">
			<g>
				<circle class="st3" cx="688.98" cy="355.62" r="17.46"/>
			</g>
			<g>
				<circle class="st3" cx="767.55" cy="532.73" r="12.35"/>
			</g>
			<g>
				<circle class="st3" cx="817.03" cy="664.41" r="8.73"/>
			</g>
			<g>
				<circle class="st3" cx="611.86" cy="532.73" r="8.73"/>
			</g>
			<g>
				<circle class="st3" cx="555.84" cy="664.41" r="8.73"/>
			</g>
			<g>
				<circle class="st3" cx="669.59" cy="587.63" r="5.02"/>
			</g>
			<g>
				<circle class="st3" cx="611.86" cy="628.54" r="5.02"/>
			</g>
			<g>
				<circle class="st3" cx="706.44" cy="442.58" r="5.02"/>
			</g>
			<g>
				<circle class="st3" cx="674.61" cy="669.43" r="5.02"/>
			</g>
			<g>
				<circle class="st3" cx="706.44" cy="669.43" r="5.02"/>
			</g>
			<g>
				<circle class="st3" cx="706.44" cy="771.04" r="8.73"/>
			</g>
			<g>
				<circle class="st3" cx="742.58" cy="618.51" r="5.02"/>
			</g>
			<g>
				<circle class="st3" cx="669.59" cy="489.01" r="5.02"/>
			</g>
			<g>
				<circle class="st3" cx="706.44" cy="532.73" r="5.02"/>
			</g>
			<g>
				<circle class="st3" cx="674.61" cy="736.12" r="5.02"/>
			</g>
			<g>
				<ellipse class="st3" cx="941.5" cy="355.62" rx="17" ry="17.46"/>
			</g>
			<g>
				<ellipse class="st3" cx="864.98" cy="532.73" rx="12.02" ry="12.35"/>
			</g>
			<g>
				<ellipse class="st3" cx="1016.61" cy="532.73" rx="8.5" ry="8.73"/>
			</g>
			<g>
				<ellipse class="st3" cx="1071.16" cy="664.41" rx="8.5" ry="8.73"/>
			</g>
			<g>
				<ellipse class="st3" cx="948.77" cy="605.82" rx="4.89" ry="5.02"/>
			</g>
			<g>
				<ellipse class="st3" cx="1016.61" cy="600.8" rx="4.89" ry="5.02"/>
			</g>
			<g>
				<ellipse class="st3" cx="924.5" cy="442.58" rx="4.89" ry="5.02"/>
			</g>
			<g>
				<ellipse class="st3" cx="958.54" cy="669.43" rx="4.89" ry="5.02"/>
			</g>
			<g>
				<ellipse class="st3" cx="924.5" cy="669.43" rx="4.89" ry="5.02"/>
			</g>
			<g>
				<ellipse class="st3" cx="928.43" cy="771.04" rx="8.5" ry="8.73"/>
			</g>
			<g>
				<ellipse class="st3" cx="924.5" cy="499.04" rx="4.89" ry="5.02"/>
			</g>
			<g>
				<ellipse class="st3" cx="888.53" cy="600.8" rx="4.89" ry="5.02"/>
			</g>
			<g>
				<ellipse class="st3" cx="965.27" cy="537.75" rx="6.11" ry="6.28"/>
			</g>
			<g>
				<ellipse class="st3" cx="960.39" cy="736.12" rx="4.89" ry="5.02"/>
			</g>
			<g>
				<polygon class="st3" points="612.08,628.85 611.65,628.24 669,587.58 614.97,535.88 615.48,535.34 670.18,587.67 669.81,587.93
									"/>
			</g>
			<g>
				<line class="st4" x1="669.59" y1="489.01" x2="616.88" y2="532.73"/>
				<polygon class="st3" points="617.12,533.02 616.64,532.44 669.35,488.72 669.83,489.3 				"/>
			</g>
			<g>
				<line class="st4" x1="671.52" y1="491.47" x2="703.86" y2="530.15"/>

					<rect x="686.94" y="485.6" transform="matrix(0.7671 -0.6415 0.6415 0.7671 -167.5335 560.1584)" class="st3" width="1.5" height="50.42"/>
			</g>
			<g>
				<polygon class="st3" points="672.1,487.1 670.94,486.14 705.86,443.7 707.02,444.65 				"/>
			</g>
			<g>
				<polygon class="st3" points="672.91,585.93 671.59,585.2 700.15,533.48 620.59,533.48 620.59,531.98 702.7,531.98 				"/>
			</g>
			<g>
				<path class="st3" d="M707.95,848.08l-34.84-30.66V670.88l-119.48-5.05l0.83-1.99l56.02-134.27l78.51-177.67l79.95,180.22
					L817,660.07l46.56-127.86l77.95-180.43l76.48,180.35l55.39,133.68l-113.29,5.05l1.8,65.21l-0.08,72.05l-12.28,36.9l-21.64-16.25
					l-0.97-57.72l-3.88-100.13h-215.1V848.08z M676.11,816.07l28.82,25.36v-173.5h111.8l-50.59-134.67l-77.16-173.92l-75.74,171.42
					L558.06,663l118.05,4.99V816.07z M930.88,827.26L948,840.11l10.88-32.71v-71.29L957,667.99l111.95-4.99l-53.73-129.7
					l-73.72-173.86l-75.13,173.88l-49.01,134.6h108.6l3.99,103.05L930.88,827.26z"/>
			</g>
			<g>

					<rect x="696.2" y="356.57" transform="matrix(0.9846 -0.1749 0.1749 0.9846 -58.9071 127.933)" class="st3" width="0.75" height="83.4"/>
			</g>
			<g>
				<rect x="611.49" y="539.37" class="st3" width="0.75" height="85.85"/>
			</g>
			<g>
				<polygon class="st3" points="675.42,668.83 615.19,630.53 615.99,629.26 673.79,666.01 670.56,590.7 672.06,590.63 				"/>
			</g>
			<g>
				<polygon class="st3" points="741.22,617.49 740.83,617.33 673.71,590.63 673.99,589.93 740.72,616.48 762.07,541.36
					762.79,541.56 				"/>
			</g>
			<g>
				<rect x="710.54" y="535.23" class="st3" width="49.58" height="0.75"/>
			</g>
			<g>

					<rect x="732.59" y="438.41" transform="matrix(0.8521 -0.5235 0.5235 0.8521 -145.5008 455.6637)" class="st3" width="1.51" height="93.63"/>
			</g>
			<g>
				<line class="st4" x1="706.44" y1="446.67" x2="707.35" y2="527.79"/>

					<rect x="706.52" y="446.67" transform="matrix(0.9999 -0.0112 0.0112 0.9999 -5.4314 7.9757)" class="st3" width="0.75" height="81.13"/>
			</g>
			<g>
				<line class="st4" x1="608.32" y1="630.74" x2="561.78" y2="658.99"/>
				<polygon class="st3" points="561.97,659.31 561.58,658.67 608.12,630.42 608.51,631.06 				"/>
			</g>
			<g>
				<path class="st5" d="M748.24,620.92"/>
			</g>
			<g>
				<polygon class="st3" points="809.77,661.74 743.56,621.24 743.96,620.6 810.16,661.1 				"/>
			</g>
			<g>

					<rect x="695.83" y="643.16" transform="matrix(0.5798 -0.8148 0.8148 0.5798 -220.2682 859.9987)" class="st3" width="55.48" height="0.75"/>
			</g>
			<g>
				<polygon class="st3" points="701.12,767.13 679.21,736.17 679.28,735.99 703.88,670.2 704.58,670.46 680.05,736.06
					701.73,766.69 				"/>
			</g>
			<g>
				<polygon class="st3" points="678.43,671.08 678.37,669.58 702.23,668.72 671.52,590.94 672.92,590.39 704.41,670.15 				"/>
			</g>
			<g>
				<polygon class="st3" points="923.8,442.67 923.07,442.48 941.14,372.98 941.87,373.17 				"/>
			</g>
			<g>
				<polygon class="st3" points="965.43,538.49 965.12,537.01 1007.56,528.28 928.29,445.37 929.38,444.33 1010.53,529.2 				"/>
			</g>
			<g>
				<polygon class="st3" points="962.54,533.76 923.97,495.58 923.97,445.88 925.02,445.88 925.02,495.14 963.28,533.01 				"/>
			</g>
			<g>

					<rect x="855.45" y="549.93" transform="matrix(0.3346 -0.9424 0.9424 0.3346 83.7243 1219.4922)" class="st3" width="99.84" height="1.06"/>
			</g>
			<g>
				<polygon class="st3" points="823.92,662.06 822.64,660.78 884.6,598.63 869.69,539.23 871.45,538.79 886.6,599.18
					886.25,599.55 				"/>
			</g>
			<g>
				<polygon class="st3" points="874.4,533.35 873.53,532.12 919.38,499.8 920.25,501.04 				"/>
			</g>
			<g>
				<polygon class="st3" points="950.93,606.94 950.74,604.69 1013.09,599.27 965.49,542.18 967.23,540.74 1017.6,601.15 				"/>
			</g>
			<g>
				<polygon class="st3" points="946.45,604.86 890.52,601.11 960.13,537.47 960.64,538.03 892.33,600.48 946.5,604.11 				"/>
			</g>
			<g>
				<polygon class="st3" points="955.5,669.81 923.98,669.81 944.17,608.16 944.89,608.39 925.02,669.06 955.5,669.06 				"/>
			</g>
			<g>

					<rect x="1007.82" y="571.3" transform="matrix(0.4128 -0.9108 0.9108 0.4128 14.3049 1290.7242)" class="st3" width="0.76" height="125.94"/>
			</g>
			<g>
				<polygon class="st3" points="929.51,768.54 954.77,671.47 956.23,671.85 932.39,763.45 958.01,737.34 959.08,738.39 				"/>
			</g>
		</g>
	</g>
	<g>
		<path class="st1" d="M632.1,615.03c13.21,0,25.01-4.12,35.39-12.37c10.21-8.08,16.84-18.43,19.9-31.07h9.78
			c-3.17,15.23-10.79,27.84-22.84,37.82c-12.29,10.09-26.36,15.14-42.23,15.14c-18.34,0-34.04-6.52-47.07-19.56
			c-12.98-12.98-19.47-28.64-19.47-46.99c0-18.35,6.49-34.01,19.47-46.99c13.04-13.04,28.73-19.56,47.07-19.56
			c15.86,0,29.94,5.05,42.23,15.14c12.06,9.98,19.67,22.59,22.84,37.82h-9.78c-3.06-12.63-9.69-22.99-19.9-31.07
			c-10.38-8.25-22.18-12.38-35.39-12.38c-15.75,0-29.19,5.57-40.33,16.7c-11.13,11.13-16.7,24.58-16.7,40.33s5.57,29.19,16.7,40.33
			C602.9,609.46,616.35,615.03,632.1,615.03z"/>
	</g>
	<g>
		<g>
			<path class="st1" d="M718.41,605.29c-12.98-12.98-19.47-28.64-19.47-46.99c0-18.35,6.49-34.01,19.47-46.99
				c13.04-13.04,28.73-19.56,47.07-19.56c18.34,0,34.01,6.52,46.99,19.56c13.04,12.98,19.56,28.64,19.56,46.99
				c0,18.34-6.52,34.01-19.56,46.99c-12.98,13.04-28.64,19.56-46.99,19.56C747.14,624.84,731.45,618.33,718.41,605.29z
				 M725.16,517.97c-11.13,11.13-16.7,24.58-16.7,40.33c0,15.75,5.57,29.19,16.7,40.32c11.14,11.14,24.58,16.7,40.33,16.7
				s29.19-5.56,40.32-16.7c11.14-11.13,16.7-24.58,16.7-40.32c0-15.75-5.57-29.19-16.7-40.33c-11.13-11.13-24.57-16.7-40.32-16.7
				S736.29,506.84,725.16,517.97z"/>
		</g>
	</g>
	<g>
		<g>
			<path class="st1" d="M852.46,605.29c-12.98-12.98-19.47-28.64-19.47-46.99c0-18.35,6.49-34.01,19.47-46.99
				c13.04-13.04,28.73-19.56,47.07-19.56s34.01,6.52,46.99,19.56c13.04,12.98,19.56,28.64,19.56,46.99
				c0,18.34-6.52,34.01-19.56,46.99c-12.98,13.04-28.64,19.56-46.99,19.56S865.5,618.33,852.46,605.29z M859.21,517.97
				c-11.13,11.13-16.7,24.58-16.7,40.33c0,15.75,5.57,29.19,16.7,40.32c11.14,11.14,24.58,16.7,40.33,16.7s29.19-5.56,40.32-16.7
				c11.14-11.13,16.7-24.58,16.7-40.32c0-15.75-5.57-29.19-16.7-40.33c-11.13-11.13-24.57-16.7-40.32-16.7
				S870.35,506.84,859.21,517.97z"/>
		</g>
	</g>
	<g>
		<g>
			<path class="st1" d="M1020.27,496.43c12.34,0,22.9,4.39,31.67,13.15c8.77,8.77,13.15,19.33,13.15,31.67
				c0,12.4-4.39,22.99-13.15,31.76c-8.77,8.77-19.33,13.15-31.67,13.15h-41.19V620h-9.52V496.43H1020.27z M1020.27,576.64
				c9.75,0,18.09-3.46,25.01-10.38c6.86-6.92,10.3-15.26,10.3-25.01c0-9.75-3.43-18.09-10.3-25.01
				c-6.92-6.92-15.26-10.38-25.01-10.38h-41.19v70.78H1020.27z"/>
		</g>
	</g>
	<g>
		<path class="st1" d="M770.31,649.52v80.16h-15.23l-39.97-48.67v48.67h-18.32v-80.16h15.35l39.85,48.67v-48.67H770.31z"/>
		<path class="st1" d="M804.43,664.41v21.18h37.1v14.89h-37.1v29.2h-18.55v-80.16h60.58v14.89H804.43z"/>
		<path class="st1" d="M917.68,714.8v14.89h-62.07v-80.16h60.58v14.89h-42.14v17.41h37.22v14.43h-37.22v18.55H917.68z"/>
	</g>
</g>
</svg>
`
