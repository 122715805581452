import React, { useCallback, forwardRef, useMemo } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import filterPriceDataSchema from '@smartcoop/forms/schemas/dairyFarm/filterPriceData.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { selectCurrentPropertyDairyFarmIEs } from '@smartcoop/stores/property/selectorProperty'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputMonthYear from '@smartcoop/web-components/InputMonthYear'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputUnit from '@smartcoop/web-components/InputUnit'

import { Container, ButtonContainer } from './styles'

const FilterPriceDataForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()

  const currentPropertyDairyFarmIEs = useSelector(selectCurrentPropertyDairyFarmIEs)

  const iesOptions = useMemo(
    () => map(currentPropertyDairyFarmIEs, ({ stateRegistration, id }) => ({
      label: stateRegistration,
      value: id
    })),
    [currentPropertyDairyFarmIEs]
  )

  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterPriceDataSchema }
        onSubmit={ handleSubmit }
      >
        <InputSelect
          name="userStateRegistrationId"
          label={ t('state registration') }
          defaultValue={ filters?.userStateRegistrationId }
          options={ iesOptions }
        />
        <InputMonthYear
          label={ t('initial month/year') }
          name="from"
          fullWidth
          defaultValue={ filters.from }
        />

        <InputMonthYear
          label={ t('final month/year') }
          name="to"
          fullWidth
          defaultValue={ filters.to }
        />

        <InputUnit
          name="initialPrice"
          label={ t('initial price') }
          unit="R$"
          type="float"
          fullWidth
          decimalScale={ 4 }
          defaultValue={ filters.initialPrice }
        />

        <InputUnit
          name="finalPrice"
          label={ t('final price') }
          unit="R$"
          type="float"
          fullWidth
          decimalScale={ 4 }
          defaultValue={ filters.finalPrice }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-order-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterPriceDataForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterPriceDataForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterPriceDataForm
