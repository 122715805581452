import React, { useCallback, forwardRef } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import filterAnalysisSchema from '@smartcoop/forms/schemas/property/fields/filterAnalysis.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { momentBackDateTimeFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDateRange from '@smartcoop/web-components/InputDateRange'

import { Container, ButtonContainer } from './styles'

const FilterAnalysisForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        startDate: data?.rangeDate?.from,
        endDate: data?.rangeDate?.to
      })
    },
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterAnalysisSchema }
        onSubmit={ handleSubmit }
      >
        <InputDateRange
          label={ t('period') }
          name="rangeDate"
          defaultValue={
            !isEmpty(filters) ?
              {
                from: moment(filters.startDate, momentBackDateTimeFormat), to: moment(filters.endDate, momentBackDateTimeFormat)
              } : {
                from: '', to: ''
              }
          }
          fullWidth
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-order-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterAnalysisForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterAnalysisForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterAnalysisForm
