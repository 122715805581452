import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

import LargeButton from '../../../components/LargeButton/LargeButton'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  border-left: 1px solid #cbcbcb;
`

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-rows: max-content max-content max-content;
  grid-template-columns: 1fr;

  align-items: center;
  justify-content: center;
  padding: 15px;
  row-gap: 10px;
  padding: 10px;
  overflow-y: scroll;
  max-height: calc(100vh - 116px);
`

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const CodeSpan = styled.span`
  color: ${ colors.darkGrey };
`

export const GroupMembersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
`

export const GroupMembersTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${ colors.text };
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
`

export const ActionButton = styled(LargeButton)`
  font-size: 15px;
  font-weight: 600;

  > div {
    width: 30px;
    height: 30px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`

export const CustomInput = styled.input`
  width: 100%;
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid ${ colors.gray };
  font-family: 'Open Sans';
`
