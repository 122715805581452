import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { SocialActions } from '@smartcoop/stores/social'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Modal from '@smartcoop/web-components/Modal'
import PostComment from '@smartcoop/web-components/PostComment'

import useStyles from './styles'

const EditCommentModal = (props) => {
  const { id, open, comment, handleClose, getComments } = props
  const t = useT()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const user = useSelector(selectUser)
  const dispatch = useCallback(useDispatch(), [])

  const [loading, setLoading] = useState()

  const editCommentSuccess = useCallback(() => {
    snackbar.success(t('social comment edit success'))
    setLoading(false)
    handleClose()
    getComments()
  }, [getComments, handleClose, snackbar, t])

  const handleSubmit = useCallback((data) => {
    dispatch(SocialActions.editComment({ commentId: comment.id, text: data.text }, editCommentSuccess, () => setLoading(false)))
  },[comment.id, dispatch, editCommentSuccess])

  return (
    <Modal
      id={ id }
      open={ open }
      title={ t('social comment edit') }
      disableFullScreen
      escape
      contentContainerStyle={ { padding: 0, minWidth: 500 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      loading={ loading }
    >
      <PostComment user={ user } onSubmit={ handleSubmit } comment={ comment }/>
    </Modal>
  )

}

EditCommentModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  getComments: PropTypes.func.isRequired,
  comment: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    userId: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      image: PropTypes.string
    })
  }).isRequired
}

EditCommentModal.defaultProps = {
}

export default EditCommentModal
