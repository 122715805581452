import * as Yup from 'yup'

import flow from 'lodash/fp/flow'
import toNumber from 'lodash/toNumber'

import maxNumberValue from '@smartcoop/forms/validators/maxNumberValue.validator'
import required from '@smartcoop/forms/validators/required.validator'

const createFutureSaleOrder = ({ t, data }) => Yup.object().shape({
  productBatchId: flow(
    required({ t })
  )(Yup.string()),

  propertyId: flow(
    required({ t })
  )(Yup.string()),

  quantity: (() => {
    let maxNumber = (param) => param

    if (toNumber(data?.productBalance || '') > 0) {
      maxNumber = maxNumberValue({
        t,
        max: (
          toNumber(data?.conversionFactor || '') > 0
            ? (Math.floor(Number(data?.productBalance)/Number(data?.conversionFactor))).toString()
            : data?.productBalance).toString(),
        name: t('quantity'),
        customSizeMessage: t('the {this}', { this: t('balance'), gender: 'male' })
      })
    }

    return flow(
      required({ t }),
      maxNumber
    )(Yup.string())
  })(),

  batchQuotation: flow(
    required({ t })
  )(Yup.string()),

  exibitionQuotation: flow(
    required({ t })
  )(Yup.string()),

  exibitionBalance: flow(
    required({ t })
  )(Yup.string()),

  exibitionBalanceInUnit: flow(
    required({ t })
  )(Yup.string()),

  productBalance: flow(
    required({ t })
  )(Yup.string()),

  settlementDate: flow(
    required({ t })
  )(Yup.string()),

  quotationExpirationDate: flow(
    required({ t })
  )(Yup.string()),

  totalValue: flow(
    required({ t })
  )(Yup.string()),

  conversionFactor: Yup.string(),

  totalValueExibition: flow(
    required({ t })
  )(Yup.string()),

  stateRegistrationId: flow(
    required({ t })
  )(Yup.string())
})

export default createFutureSaleOrder
