import React, { useRef, useMemo, useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'
import mapLodash from 'lodash/map'

import useMediaQuery from '@material-ui/core/useMediaQuery'


import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import {
  bug,
  bugMarker as bugMarkerIcon,
  field as fieldIcon,
  noImage
} from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { useSnackbar } from '@smartcoop/snackbar'
import { FieldActions } from '@smartcoop/stores/field'
import colors from '@smartcoop/styles/colors'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import  { getCenterCoordinates } from '@smartcoop/utils/maps'
import Button from '@smartcoop/web-components/Button'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Icon from '@smartcoop/web-components/Icon'
import InputFile  from '@smartcoop/web-components/InputFile'
import Loader from '@smartcoop/web-components/Loader'
import Maps from '@smartcoop/web-components/Maps'
import Control from '@smartcoop/web-components/Maps/components/Control'
import Polygon from '@smartcoop/web-components/Maps/components/Polygon'
import PinMarker from '@smartcoop/web-components/Maps/markers/PinMarker'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import ThumbnailImage from '@smartcoop/web-components/ThumbnailImage'
import PestReportForm from '@smartcoop/web-containers/forms/digitalProperty/pestReport/PestReportForm/PestReportForm'
import useFile from '@smartcoop/web-containers/hooks/useFile'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
// import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { Container, Header, Title, IconContainer, MapContainer, InfoCard, RowTitle, Gallery } from './styles'

const PestReportRegisterScreen = () => {
  const t = useT()
  const history = useHistory()
  // const { routes } = useRoutes()
  const pestReportFormRef = useRef(null)
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const location = useLocation()

  const {
    selectedFiles,
    receivedFiles,
    isEmpty: isEmptyFiles,
    handleAdd,
    createFormData,
    handleRemove
  } = useFile([], location.state?.pestReport?.reportImages ?? [])
  const [isLoading, setIsLoading] = useState(false)
  const [filesToRender, setFilesToRender] = useState([])
  const { createDialog } = useDialog()

  const map = useMemo(
    () => ({
      ...location?.state?.map
    }), [location]
  )
  const field = useMemo(
    () => ({
      ...location?.state?.field
    }), [location]
  )

  const growingSeason = useMemo(
    () => ({
      ...location?.state?.growingSeason
    }), [location]
  )

  const isEditing = useMemo(() =>  location.pathname.includes('edit'),[location.pathname])


  const statePestReport = useMemo(
    () => location?.state?.pestReport ?? {}, [location]
  )

  const defaultValues = useMemo(
    () => ({
      reportTypes: {
        slug: ''
      },
      ...statePestReport,
      specification: statePestReport[statePestReport?.reportTypes?.slug]?.id ?? '',
      description: statePestReport?.description ?? ''
    }), [statePestReport]
  )

  const [markerCoordinate, setMarkerCoordinate] = useState( defaultValues.pinLocation || getCenterCoordinates(field?.coordinates || field?.polygonCoordinates))


  const handleClose = useCallback(
    () => (
      history.goBack()
    ),
    [history]
  )

  const onDragendMarker = useCallback((event) => {
    setMarkerCoordinate({
      latitude: event.target._latlng.lat,
      longitude: event.target._latlng.lng
    })
  }, [])

  const onSuccess = useCallback(
    () => {
      setIsLoading(false)
      snackbar.success(
        t(isEditing ? 'your report was edited!': 'your report was registered!')
      )
      handleClose()
    },
    [handleClose, isEditing, snackbar, t]
  )

  const submit = useCallback(
    () => {
      pestReportFormRef.current.submit()
    },
    []
  )

  const submitNewFiles = useCallback(
    (pestReport) => {
      if (isEmpty(selectedFiles)) {
        onSuccess()
      } else {
        dispatch(FieldActions.addPestReportFiles(createFormData('upload', {}), pestReport.id,
          onSuccess,
          () => setIsLoading(false)
        ))
      }
    },
    [createFormData, dispatch, onSuccess, selectedFiles]
  )

  const submitOldFiles = useCallback(
    (pestReport) => {
      dispatch(FieldActions.editPestReportFiles(receivedFiles, pestReport.id,
        () => submitNewFiles(pestReport),
        () => setIsLoading(false)
      ))
    },
    [dispatch, receivedFiles, submitNewFiles]
  )

  const submitForms = useCallback(
    (data) => {
      setIsLoading(true)
      if(isEditing) {
        dispatch(FieldActions.updateOfflinePestReport(
          data,
          defaultValues.id,
          (pestReport) => submitOldFiles(pestReport),
          () => setIsLoading(false)
        ))
      } else {
        dispatch(FieldActions.saveOfflinePestReport(
          data,
          growingSeason.id,
          (pestReport) => submitNewFiles(pestReport),
          () => setIsLoading(false),
          true
        ))
      }
    },
    [defaultValues, dispatch, growingSeason, isEditing, submitNewFiles, submitOldFiles]
  )

  const handleClickClose = useCallback(
    (list, removedFile) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => handleRemove(list, removedFile),
          textWarning: t('are you sure you want to delete this image?')
        }
      })
    },
    [createDialog, handleRemove, t]
  )

  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_PLAGUE_REPORT_ID, fileKey: file?.fileKey })

    return downloadFromBase64(data)
  }, [])


  useEffect(() => {
    const getRenderedFiles = async() => {
      const receivedElements = await Promise.all(mapLodash(receivedFiles, async(file) => {
        const fileSrc = await renderFile(file)
        return(
          <ThumbnailImage
            key={ file.id }
            src={ fileSrc }
            size={ 100 }
            onClose={ () => handleClickClose('receivedFiles', file) }
          />
        )}).concat(mapLodash(selectedFiles, (file, index) => (
        <ThumbnailImage
          key={ `${ index }-${ file.name }` }
          src={ URL.createObjectURL(file) }
          size={ 100 }
          onClose={ () => handleClickClose('selectedFiles', file) }
        />
      ))))

      setFilesToRender(receivedElements)
    }

    getRenderedFiles()
  }, [handleClickClose, receivedFiles, renderFile, selectedFiles])



  const matches = useMediaQuery('(max-height:899px)')


  return (
    isLoading ? <Loader width={ 100 } /> :
      <SplitedScreenLayout
        title={ { name: t('field', { howMany: 1 }) } }
        divRightStyle={ { padding: 0 } }
        leftChildren={
          <Container>
            <Header>
              <IconContainer>
                <Icon icon={ bug } size={ 18 } />
              </IconContainer>

              <Title style={ { fontSize: 18, fontWeight: 600 } }>
                <I18n>{isEditing ? 'edit occurrence' : 'report occurrence'}</I18n>
              </Title>

            </Header>
            <PestReportForm
              ref={ pestReportFormRef }
              onSubmit={ submitForms }
              markerCoordinate={ markerCoordinate }
              withoutSubmitButton
              isEditing={ isEditing }
              defaultValues={ defaultValues }
            />

            <RowTitle>
              <Title style={ { fontSize: 18, fontWeight: 600, margin: 0 } }>
                <I18n params={ { howMany: 2 } }>image</I18n>
              </Title>
              {!isEmptyFiles &&
                <InputFile
                  idInput="contained-btn-file"
                  onChange={ handleAdd }
                  inputProps={ {
                    accept: 'image/png,image/jpeg,image/tiff',
                    multiple: true
                  } }
                  buttonProps={ {
                    id: 'attach-docs',
                    color: 'secondary'
                  } }
                  maxNumberFile={ 6 - (receivedFiles.length + selectedFiles.length) }
                  maxNumberFileDisplay={ 6 }
                >
                  <I18n>add images</I18n>
                </InputFile>
              }
            </RowTitle>
            <Gallery isCenter={ isEmptyFiles }>
              {isEmptyFiles ?
                <EmptyState
                  text={ t('no image added') }
                  icon={ noImage }
                  isInputFile
                  maxNumberFile={ 6 - (receivedFiles.length + selectedFiles.length) }
                  maxNumberFileDisplay={ 6 }
                  file={ {
                    idInput: 'contained-button-file',
                    inputProps: {
                      accept: 'image/png,image/jpeg,image/tiff',
                      multiple: true
                    }
                  } }
                  iconProps={
                    matches ? {
                      size: 0
                    } : { size: 100 } }
                  action={ {
                    text: t('add images') ,
                    onClick: handleAdd
                  } }
                  buttonProps={ {
                    id: 'attach-documents',
                    color: 'secondary'
                  } }
                />
                :
                filesToRender
              }
            </Gallery>

            <ButtonsContainer style={ { paddingTop: 30 } }>
              <Button
                id="cancel-field-form"
                onClick={ handleClose }
                style={ { flex: 1 } }
                variant="outlined"
                disabled={ isLoading }
              >
                <I18n>cancel</I18n>
              </Button>

              <div style={ { width: '10%' } } />

              <Button
                id="submit-field-form"
                onClick={ submit }
                style={ { flex: 1 } }
                disabled={ isLoading }
              >
                <I18n>save</I18n>
              </Button>
            </ButtonsContainer>
          </Container>
        }
        rightChildren={
          <>

            <MapContainer>
              <Maps
                zoom={ map.mapZoom }
                region={ map.mapCenterPosition }
                layer="google"
              // onClick={ onClickMap }
              >
                <Control position="topcenter">
                  <div
                    style={ {
                      margin: '12px 20px 10px 10px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    } }
                  >
                    <div style={ {} }>
                      <Button
                        id="edit-field"
                        color={ colors.white }
                        style={ {
                          padding: '5.5px 10px',
                          fontSize: '0.875rem',
                          marginRight: 10
                        } }
                      >
                        <>
                          <Icon
                            icon={ fieldIcon }
                            size={ 17 }
                            style={ { marginRight: 5 } }
                          />
                          {field?.name || field?.fieldName}
                        </>
                      </Button>

                    </div>
                  </div>
                </Control>

                <Control position="topright">
                  <InfoCard>
                    <I18n>click and drag the pin to the desired location</I18n>
                  </InfoCard>
                </Control>
                <PinMarker
                  coordinate={ markerCoordinate }
                  draggable
                  onDragend={ onDragendMarker }
                  customIcon={ bugMarkerIcon }
                  iconAnchor={ [22, 62] }
                  size={ 40 }
                />
                <Polygon
                  points={ field?.coordinates || field?.polygonCoordinates }
                  color={ colors.secondary }
                />
              </Maps>
            </MapContainer>
          </>
        }
      />
  )
}

export default PestReportRegisterScreen
