import api from '../api'

export const nfeIntegration = async (params) =>
  api.post('/v1/nfe', params)

export const getNfeTables = async (params) =>
  api.get('/v1/nfe', params)

export const getProducer = async (params) =>
  api.get('/v1/nfe/producers', params)

export const getCFOP = async (params) => api.get('/v1/products/cfop', params)

export const saveFiscalFigure = async (params) => api.post('/v1/products/fiscal-figure', params)

export const deleteFiscalFigure = async (fiscalFigureId) => api.delete(`/v1/products/fiscal-figure/${ fiscalFigureId }`)

export const integrationControl = async (params) => api.post('/v1/nfe/integration-control', params)

export const editFiscalFigure = async (params, { fiscalFigureId }) => api.patch(`/v1/products/fiscal-figure/${ fiscalFigureId }`, params)

export const getFiscalFigure = async (params) => api.get('/v1/products/fiscal-figure', { params })

export const getNfeOrganizations = async (params) => api.get('/v1/nfe/organizations', { params })
