import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useDialog } from '@smartcoop/dialog'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectSuggestPropertyRegister } from '@smartcoop/stores/property/selectorProperty'
import { withLayout } from '@smartcoop/web-containers/layouts'
import CreatePropertyModal from '@smartcoop/web-containers/modals/CreatePropertyModal'
import ZeroPropertiesModal from '@smartcoop/web-containers/modals/ZeroPropertiesModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import RouterSwitch from '../../RouterSwitch'

const DigitalPropertyScreenRouter = (props) => {
  const suggestPropertyRegister = useSelector(selectSuggestPropertyRegister)
  const technicalModules = useSelector(selectModuleIsTechnical)

  const { createDialog, removeDialog } = useDialog()
  const { routes } = useRoutes()

  useEffect(() => {
    if (suggestPropertyRegister) {
      createDialog({
        id: 'zero-properties',
        Component: ZeroPropertiesModal,
        props: {
          onConfirm: () => createDialog({
            id: 'property-onboarding',
            Component: CreatePropertyModal,
            props: {
              zeroProperties: true
            }
          }),
          onCancel: () => removeDialog({ id: 'change-property' })
        }
      })
    }
  }, [createDialog, removeDialog, suggestPropertyRegister, technicalModules])

  return <RouterSwitch routes={ routes } { ...props } />
}

export default memo(withLayout('authenticated')(DigitalPropertyScreenRouter))
