import React, {
  forwardRef,
  useCallback,
  useMemo,
  useState,
  useEffect
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'


import debounce from 'lodash/debounce'
import find from 'lodash/find'
import findFP from 'lodash/fp/find'
import flow from 'lodash/fp/flow'
import mapFP from 'lodash/fp/map'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'
import toLower from 'lodash/toLower'

// import toString from 'lodash/toString'
import { useDialog } from '@smartcoop/dialog'
import joinOrderSchema from '@smartcoop/forms/schemas/shoppingPlatform/order/joinOrder.schema'
import I18n, { useT } from '@smartcoop/i18n'
import useSmartcoopApi from '@smartcoop/services/hooks/useSmartcoopApi'
import { useSnackbar } from '@smartcoop/snackbar'
import { OrderActions } from '@smartcoop/stores/order'
import {
  selectCurrentOrder,
  selectCurrentOrderProduct
} from '@smartcoop/stores/order/selectorOrder'
import { OrganizationActions } from '@smartcoop/stores/organization'
import {
  selectCurrentOrganization,
  selectCurrentOrganizationAddresses
} from '@smartcoop/stores/organization/selectorOrganization'
import { colors } from '@smartcoop/styles'
import { hourOptions } from '@smartcoop/utils/constants'
import { momentBackDateTimeFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
// import CheckboxTreeView from '@smartcoop/web-components/CheckboxTreeView'
import FilteredCheckboxGroup from '@smartcoop/web-components/FilteredCheckboxGroup'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import AddressWithQuantity from '@smartcoop/web-containers/forms/shoppingPlatform/order/RegisterOrderForm/AddressWithQuantity'
import OrderDetailsFragment from '@smartcoop/web-containers/fragments/OrderDetailsFragment'
import DemandStatusFragment from '@smartcoop/web-containers/fragments/shoppingPlatform/DemandStatusFragment/DemandStatusFragment'
import {
  ButtonsContainer,
  Title
} from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import RegistrationAmountPaidModal from '@smartcoop/web-containers/modals/AmountPaid/RegistrationAmountPaidModal'
import ViewListAmountPaidModal from '@smartcoop/web-containers/modals/AmountPaid/ViewListAmountPaidModal'
import ViewListSuppliersByProducts from '@smartcoop/web-containers/modals/ViewListSuppliersByProducts'

import { Row, TotalQuantityRow, TotalQuantity, Container, DateWrapper, HourWrapper } from './styles'

const JoinOrderForm = forwardRef((props, formRef) => {
  const { onSuccess, isEditing } = props

  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()

  const currentOrganizationAddresses = useSelector(
    selectCurrentOrganizationAddresses
  )
  const { data: dateParams } = useSmartcoopApi('/v1/purchase-demands/params')

  const currentOrganization = useSelector(selectCurrentOrganization)
  const product = useSelector(selectCurrentOrderProduct)

  const currentOrder = useSelector(selectCurrentOrder)

  const [filterText, setFilterText] = useState('')
  const [loading, setLoading] = useState(false)
  const [addressesOptions, setAddressesOptions] = useState([])

  const [participationDeadline, setParticipationDeadline] = useState(moment(currentOrder?.participationDeadline).format('YYYY-MM-DD'))
  const [participationDeadlineHour, setParticipationDeadlineHour] = useState(moment(currentOrder?.participationDeadline).format('HH:mm'))
  const [proposalDeadline, setProposalDeadline] = useState(moment(currentOrder?.proposalDeadline).format('YYYY-MM-DD'))
  const [proposalDeadlineHour, setProposalDeadlineHour] = useState(moment(currentOrder?.proposalDeadline).format('HH:mm'))
  const [decisionDeadline, setDecisionDeadline] = useState(moment(currentOrder?.decisionDeadline).format('YYYY-MM-DD'))
  const [decisionDeadlineHour, setDecisionDeadlineHour] = useState(moment(currentOrder?.decisionDeadline).format('HH:mm'))
  const [receiptDeadline, setReceiptDeadline] = useState(moment(currentOrder?.receiptDeadline).format('YYYY-MM-DD'))
  const [receiptDeadlineHour, setReceiptDeadlineHour] = useState(moment(currentOrder?.receiptDeadline).format('HH:mm'))
  const [suppliersProduct, setSuppliersProduct] = useState(null)

  // const [paymentOptions, setPaymentOptions] = useState([
  //   {
  //     id: 'vista',
  //     checked: true,
  //     disabled: true,
  //     label: 'À Vista'
  //   },
  //   {
  //     id: 'prazo',
  //     label: 'À Prazo',
  //     creatableInput: {
  //       type: 'inputNumber',
  //       label: t('quantity of days'),
  //       name: 'creatableValue',
  //       maxLength: 3
  //     },
  //     options: map(filter(currentOrder?.paymentForms, item => item?.daysAmount), item => ({
  //       id: toString(item.daysAmount),
  //       checked: true,
  //       label: `${ item.daysAmount } ${ t('day', { howMany: item.daysAmount }) }`
  //     }))
  //   }
  // ])
  // const defaultPaymentExpanded = useMemo(() => ['prazo'], [])

  const freightTypeOptions = [
    {
      label: 'CIF',
      value: 'cif'
    },
    {
      label: 'FOB',
      value: 'fob'
    },
    {
      label: 'CFA',
      value: 'cfa'
    }
  ]

  const productUnit = useMemo(() => product.unitOfMeasures || 'kg', [product])
  const orgIsOwner = useMemo(() => currentOrganization?.id === currentOrder?.organizationId, [currentOrder.organizationId, currentOrganization])

  const totalQuantity = useMemo(
    () => sumBy(addressesOptions, (item) => Number(item.quantity) || 0),
    [addressesOptions]
  )

  const disableActions = useMemo(() => {
    if (!isEmpty(currentOrder)) {
      return (
        currentOrder.status?.slug !== 'aberta' &&
        moment().isAfter(moment(currentOrder.participationDeadline))
      )
    }
    return true
  }, [currentOrder])

  const activeOrganizationPurchase = useMemo(
    () =>
      find(
        currentOrder.organizationPurchases,
        ({ organizationId }) => organizationId === currentOrganization?.id
      ),
    [currentOrder, currentOrganization]
  )

  const isOrganizationTheOwnerOfTheCurrentOrder = useMemo(
    () => (
      currentOrganization?.id === currentOrder?.organizationId
    ),
    [currentOrder, currentOrganization]
  )

  const demandStatusFragmentStatus = useMemo(
    () => {
      if (currentOrder?.status?.slug === 'aguardando_decisao') {
        return 'aguardando_cooperativas'
      }

      return currentOrder?.status?.slug
    },
    [currentOrder]
  )

  const schemaProps = useMemo(
    () => ({
      ...dateParams,
      createdAt: currentOrder?.createdAt,
      participationDeadline,
      proposalDeadline,
      decisionDeadline,
      isEditing,
      orgIsOwner,
      receiptDeadline,
      participationDeadlineHour,
      proposalDeadlineHour,
      decisionDeadlineHour,
      receiptDeadlineHour,
      isOrganizationTheOwnerOfTheCurrentOrder
    }),
    [currentOrder, dateParams, decisionDeadline, decisionDeadlineHour, isEditing, isOrganizationTheOwnerOfTheCurrentOrder, orgIsOwner, participationDeadline, participationDeadlineHour, proposalDeadline, proposalDeadlineHour, receiptDeadline, receiptDeadlineHour]
  )

  const handleAddressQuantityChange = useCallback(
    debounce((id, newOpt) => {
      setAddressesOptions((old) =>
        old.map((opt) => {
          if (opt.value === id) {
            return { ...opt, ...newOpt }
          }
          return opt
        })
      )
    }, 300),
    []
  )

  useEffect(() => {
    setLoading(true)
    dispatch(
      OrganizationActions.loadCurrentOrganizationAddresses(
        { q: filterText },
        (data) => {
          setAddressesOptions((old) =>
            map(data, (address) => ({
              value: address.id,
              quantity: find(old, { value: address.id })?.quantity || '',
              billingOrganizationId: find(old, { value: address.id })?.billingOrganizationId || address.id,
              note: find(old, { value: address.id })?.note || '',
              // eslint-disable-next-line react/prop-types
              render: ({ option }) => (
                <AddressWithQuantity
                  // eslint-disable-next-line react/prop-types
                  id={ option.value }
                  // eslint-disable-next-line react/prop-types
                  checked={ option.checked }
                  address={ address }
                  onChange={ handleAddressQuantityChange }
                  // eslint-disable-next-line react/prop-types
                  quantity={ option.quantity }
                  // eslint-disable-next-line react/prop-types
                  billingOrganizationId={ option.billingOrganizationId }
                  // eslint-disable-next-line react/prop-types
                  note={ option.note }
                  // eslint-disable-next-line react/prop-types
                  error={ option.error }
                  unit={ productUnit }
                  allAdresses={ data }
                />
              )
            }))
          )
          setLoading(false)
        },
        () => setLoading(false)
      )
    )

  },[dispatch, filterText, handleAddressQuantityChange, productUnit])

  const handleSubmit = useCallback(
    ({ deliveryLocations,  ...rest }) => {
      const addressWithQuantityZero = flow(
        mapFP((opt) => find(addressesOptions, { value: opt })),
        findFP((opt) => !!opt.error)
      )(deliveryLocations)
      if (!addressWithQuantityZero) {
        setLoading(true)
        const data = orgIsOwner ? {
          ...rest,
          participationDeadline: moment(`${ participationDeadline } ${ participationDeadlineHour }`, 'YYYY-MM-DD HH:mm').format(momentBackDateTimeFormat),
          proposalDeadline: moment(`${ proposalDeadline } ${ proposalDeadlineHour }`, 'YYYY-MM-DD HH:mm').format(momentBackDateTimeFormat),
          decisionDeadline: moment(`${ decisionDeadline } ${ decisionDeadlineHour }`, 'YYYY-MM-DD HH:mm').format(momentBackDateTimeFormat),
          receiptDeadline: moment(`${ receiptDeadline } ${ receiptDeadlineHour }`, 'YYYY-MM-DD HH:mm').format(momentBackDateTimeFormat),
          totalAmount: Number(totalQuantity),
          organizationId: currentOrganization.id,
          deliveryLocations: map(deliveryLocations, (address) => ({
            organizationId: address,
            amount: Number(find(addressesOptions, { value: address })?.quantity),
            billingOrganizationId: find(addressesOptions, { value: address })?.billingOrganizationId,
            note: find(addressesOptions, { value: address })?.note
          }))
        } : {
          ...rest,
          totalAmount: Number(totalQuantity),
          organizationId: currentOrganization.id,
          deliveryLocations: map(deliveryLocations, (address) => ({
            organizationId: address,
            amount: Number(find(addressesOptions, { value: address })?.quantity),
            billingOrganizationId: find(addressesOptions, { value: address })?.billingOrganizationId,
            note: find(addressesOptions, { value: address })?.note
          }))
        }

        dispatch(
          OrderActions.joinOfflineOrder(
            data,
            isEditing,
            activeOrganizationPurchase?.id,
            () => {
              setLoading(false)
              onSuccess()
            },
            () => {
              setLoading(false)
            }
          )
        )
      }
    },
    [activeOrganizationPurchase, addressesOptions, currentOrganization, decisionDeadline, decisionDeadlineHour, dispatch, isEditing, onSuccess, orgIsOwner, participationDeadline, participationDeadlineHour, proposalDeadline, proposalDeadlineHour, receiptDeadline, receiptDeadlineHour, totalQuantity]
  )

  const RegisterAmountPaid = useCallback(() => {
    createDialog({
      id: 'register-history-price',
      Component: RegistrationAmountPaidModal,
      props: {
        productId: product.id,
        productUnit
      }
    })
  }, [createDialog, product, productUnit])

  const ListAmountPaid = useCallback(() => {
    createDialog({
      id: 'complete-crop-management',
      Component: ViewListAmountPaidModal,
      props: {
        productId: product.id,
        organizationId: currentOrganization.id
      }
    })
  }, [createDialog, currentOrganization, product])

  useEffect(() => {
    if(product?.id) {
      dispatch(OrderActions.loadSuppliersByProduct(
        product?.id,
        (data) => {
          if(isEmpty(data)) {
            snackbar.warning('Não existe fornecedor cadastrado para este produto.')
          } else {
            setSuppliersProduct(data)
          }
        },
        () => {
          snackbar.warning('Não existe fornecedor cadastrado para este produto.')
        }
      ))
    }
  }, [product, dispatch, snackbar])

  const ListSuppliersByProducts = useCallback(() => {
    createDialog({
      id: 'list-suppliers-by-products',
      Component: ViewListSuppliersByProducts,
      props: {
        data: suppliersProduct,
        proposals: currentOrder?.purchaseProposals
      }
    })
  }, [createDialog, currentOrder, suppliersProduct])

  useEffect(() => {
    const array = currentOrganizationAddresses.filter((item) => item.active)
    setAddressesOptions((old) =>
      map(array, (address) => ({
        value: address.id.toString(),
        quantity: (find(old, { value: address.id })?.quantity || 0).toString(),
        billingOrganizationId: (find(old, { value: address.id })?.billingOrganizationId || address.id),
        note: (find(old, { value: address.id })?.note || ''),
        // eslint-disable-next-line react/prop-types
        render: ({ option }) => (
          <AddressWithQuantity
            // eslint-disable-next-line react/prop-types
            id={ option.value }
            // eslint-disable-next-line react/prop-types
            checked={ option.checked }
            address={ address }
            onChange={ handleAddressQuantityChange }
            // eslint-disable-next-line react/prop-types
            quantity={ option.quantity }
            // eslint-disable-next-line react/prop-types
            billingOrganizationId={ option.billingOrganizationId }
            // eslint-disable-next-line react/prop-types
            note={ option.note }
            // eslint-disable-next-line react/prop-types
            error={ option.error }
            unit={ productUnit }
            disabled={ disableActions }
            allAdresses={ array }
          />
        )
      }))
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganizationAddresses, disableActions, handleAddressQuantityChange, productUnit])

  useEffect(() => {
    setAddressesOptions((old) =>
      isEmpty(old)
        ? old
        : map(old, (item) => {
          const deliveryLocation = find(
            activeOrganizationPurchase?.deliveryLocations,
            ({ organizationId }) => organizationId === item.value
          )

          return {
            ...item,
            quantity: (
              deliveryLocation?.amount ||
                find(old, { value: item.value })?.quantity ||
                0
            ).toString(),
            billingOrganizationId: (
              deliveryLocation?.billingOrganizationId ||
                find(old, { value: item.value })?.billingOrganizationId ||
                item.id
            ),
            note: (
              deliveryLocation?.note ||
                find(old, { value: item.value })?.note ||
                ''
            )
          }
        })
    )

    const items = map(
      activeOrganizationPurchase?.deliveryLocations,
      ({ organizationId }) => organizationId
    )

    formRef.current.setFieldValue('deliveryLocations', items)
  }, [activeOrganizationPurchase, currentOrganizationAddresses, formRef])

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ joinOrderSchema }
      schemaProps={ schemaProps }
      onSubmit={ handleSubmit }
      style={ { flex: 1 } }
      loading={ loading }
    >
      <SplitedScreenLayout
        title={ { name: t('order', { howMany: 2 }) } }
        divRightStyle={ { paddingTop: 67 } }
        leftChildren={
          <OrderDetailsFragment isEdit={ isOrganizationTheOwnerOfTheCurrentOrder }>
            {isOrganizationTheOwnerOfTheCurrentOrder && (
              <>
                {/* <CheckboxTreeView
                  id="checkbox-tree-view"
                  name="paymentForms"
                  label={ t('payment methods') }
                  options={ paymentOptions }
                  onChangeOptions={ setPaymentOptions }
                  defaultExpanded={ defaultPaymentExpanded }
                /> */}

                <InputSelect
                  label={ t('freight type') }
                  name="freightType"
                  options={ freightTypeOptions }
                  defaultValue={ toLower(currentOrder?.freightType) }
                  style={ { marginTop: '20px' } }
                />
                <Title style={ { fontSize: 16, fontWeight: 600 } }>
                  <I18n>demand parameterization dates</I18n>:
                </Title>
                <Row>
                  <DateWrapper>
                    <InputDate
                      pickerProps={ {
                        minDate: moment().format()
                      } }
                      onChange={ setParticipationDeadline }
                      label={ t('demand closing') }
                      name="participationDeadline"
                      defaultValue={ participationDeadline }
                      style={ { display: 'flex', flex: 1, width: '100%' } }
                    />
                  </DateWrapper>
                  <HourWrapper>
                    <InputSelect
                      label={ t('hour') }
                      name="participationDeadlineHour"
                      options={ hourOptions }
                      onChange={ (e) => {
                        setParticipationDeadlineHour(e.target.value)
                      } }
                      defaultValue={ participationDeadlineHour }
                    />
                  </HourWrapper>
                </Row>

                <Row>
                  <DateWrapper>
                    <InputDate
                      pickerProps={ {
                        minDate: moment().format()
                      } }
                      onChange={ setProposalDeadline }
                      label={ t('proposal limit') }
                      name="proposalDeadline"
                      defaultValue={ proposalDeadline }
                      style={ { display: 'flex', flex: 1, width: '100%' } }
                    />
                  </DateWrapper>
                  <HourWrapper>
                    <InputSelect
                      label={ t('hour') }
                      name="proposalDeadlineHour"
                      options={ hourOptions }
                      onChange={ (e) => {
                        setProposalDeadlineHour(e.target.value)
                      } }
                      defaultValue={ proposalDeadlineHour }
                    />
                  </HourWrapper>
                </Row>

                <Row>
                  <DateWrapper>
                    <InputDate
                      pickerProps={ {
                        minDate: moment().format()
                      } }
                      onChange={ setDecisionDeadline }
                      label={ t('organization decision limit') }
                      name="decisionDeadline"
                      defaultValue={ decisionDeadline }
                      style={ { display: 'flex', flex: 1, width: '100%' } }
                    />
                  </DateWrapper>
                  <HourWrapper>
                    <InputSelect
                      label={ t('hour') }
                      name="decisionDeadlineHour"
                      options={ hourOptions }
                      onChange={ (e) => {
                        setDecisionDeadlineHour(e.target.value)
                      } }
                      defaultValue={ decisionDeadlineHour }
                    />
                  </HourWrapper>
                </Row>
                <Row>
                  <DateWrapper>
                    <InputDate
                      pickerProps={ {
                        minDate: moment().format()
                      } }
                      label={ t('receipt limit') }
                      name="receiptDeadline"
                      defaultValue={ receiptDeadline }
                      onChange={ setReceiptDeadline }
                      style={ { display: 'flex', flex: 1, width: '100%' } }
                    />
                  </DateWrapper>
                  <HourWrapper>
                    <InputSelect
                      label={ t('hour') }
                      name="receiptDeadlineHour"
                      options={ hourOptions }
                      onChange={ (e) => {
                        setReceiptDeadlineHour(e.target.value)
                      } }
                      defaultValue={ receiptDeadlineHour }
                    />
                  </HourWrapper>
                </Row>
              </>
            )}
          </OrderDetailsFragment>
        }
        rightChildren={
          <Container>
            <DemandStatusFragment
              openingDate={ currentOrder?.createdAt }
              closingDate={ currentOrder?.participationDeadline }
              proposalDeadlineDate={ currentOrder?.proposalDeadline }
              organizationDecisionLimitDate={ currentOrder?.decisionDeadline }
              deliveryDate={ currentOrder?.receiptDeadline }
              status={ demandStatusFragmentStatus }
            />
            <div style={ { alignSelf: 'flex-end' } }>
              {product?.id && (
                <Button
                  id="open-suppliers-by-products"
                  onClick={ ListSuppliersByProducts }
                  disabled={ isEmpty(suppliersProduct) }
                  style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 20 } }
                  color={ colors.white }
                >
                  <I18n>open suppliers by products</I18n>
                </Button>
              )}
              <Button
                id="open-register-purchase"
                onClick={ ListAmountPaid }
                style={ { width: 'auto', fontWeight: 600, marginRight: 10 } }
                color={ colors.white }
              >
                <I18n>see paid price history</I18n>
              </Button>
              <Button
                id="open-register-purchase"
                onClick={ RegisterAmountPaid }
                style={ { width: 'auto', fontWeight: 600 } }
                color={ colors.white }
              >
                <I18n>inform price paid</I18n>
              </Button>
            </div>

            <FilteredCheckboxGroup
              name="deliveryLocations"
              label={ t('participate in the purchase') }
              options={ addressesOptions }
              setFilterText={ setFilterText }
              filterText={ filterText }
              inputSearchStyle={ { backgroundColor: colors.white } }
              inputLabel={ t('search delivery location by cnpj') }
              disabled={ disableActions || loading }
            />

            <TotalQuantityRow>
              <Row style={ { flex: 2 } }>
                <TotalQuantity style={ { marginRight: 8 } }>
                  <I18n>total quantity</I18n>:
                </TotalQuantity>
                <TotalQuantity style={ { fontWeight: 'normal' } }>
                  {totalQuantity} {productUnit}
                </TotalQuantity>
              </Row>

              <ButtonsContainer style={ { flex: 1 } }>
                <Button
                  id="submit-order-register-form"
                  color={ colors.secondary }
                  onClick={ () => {
                    formRef.current.submit()
                  } }
                  style={ { flex: 1, marginLeft: '20px' } }
                  disabled={ disableActions || loading }
                >
                  <I18n>{isEditing ? 'save' : 'participate'}</I18n>
                </Button>
              </ButtonsContainer>
            </TotalQuantityRow>
          </Container>
        }
      />
    </Form>
  )
})

JoinOrderForm.propTypes = {
  onSuccess: PropTypes.func,
  isEditing: PropTypes.bool
}

JoinOrderForm.defaultProps = {
  onSuccess: () => {},
  isEditing: false
}

export default JoinOrderForm
