import { call, put, takeLatest } from 'redux-saga/effects'

import {
  getOperations,
  createOperation,
  updateOperation as updateOperationService,
  deleteOperation as deleteOperationService,
  deleteAccreditment as deleteAccreditmentService,
  requestAccreditment as requestAccreditmentService,
  editAccreditment as editAccreditmentService,
  getAccreditations,
  createOperationFile
} from '@smartcoop/services/apis/smartcoopApi/resources/operations'

import { OperationsActions, OperationsTypes } from './duckOperations'

function* loadOperations({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(getOperations, params)

    yield put(OperationsActions.loadOperationsSuccess(
      data
    ))
    onSuccess()
  } catch (error) {
    yield put(OperationsActions.operationsError(error))
    yield call(onError, error)
  }
}

function* loadAccrediteds({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(getAccreditations, params)

    yield put(OperationsActions.loadAccreditedsSuccess(
      data
    ))
    onSuccess()
  } catch (error) {
    yield put(OperationsActions.operationsError(error))
    yield call(onError, error)
  }
}

function* checkAccredited({ onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(getAccreditations)

    yield put(OperationsActions.checkAccreditedSuccess(
      data
    ))
    onSuccess()
  } catch (error) {
    yield put(OperationsActions.operationsError(error))
    yield call(onError, error)
  }
}

function* requestAccreditment({ onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(requestAccreditmentService)
    onSuccess()
  } catch (error) {
    yield put(OperationsActions.operationsError(error))
    yield call(onError, error)
  }
}

function* saveOfflineOperation({ formData, operation, onSuccess = () => {}, onError = () => {} }) {
  yield put(OperationsActions.saveOperation(formData, operation, onSuccess, onError))
}

function* saveOperation({ formData = null, operation, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(
      createOperation,
      { ...operation }
    )

    if(formData) {
      yield call(createOperationFile, { operationId: data?.id }, formData)
    }

    onSuccess()
  } catch (err) {
    const error = err.message
    yield put(OperationsActions.operationsError(error))
    yield call(onError, error)
  }
}

function* updateOfflineOperation({ operation, onSuccess = () => {}, onError = () => {} }) {
  yield put(OperationsActions.updateOperation(operation, onSuccess, onError))
}

function* updateOperation({ formData = null, operation, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(
      updateOperationService,
      { ...operation }
    )

    if(formData) {
      yield call(createOperationFile, { operationId: operation?.id }, formData)
    }

    onSuccess()
  } catch (err) {
    const error = err.message
    yield put(OperationsActions.operationsError(error))
    yield call(onError, error)
  }
}

function* editAccreditment({ accreditmentId, params, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(
      editAccreditmentService,
      accreditmentId,
      { ...params }
    )

    onSuccess()
  } catch (err) {
    const error = err.message
    yield put(OperationsActions.operationsError(error))
    yield call(onError, error)
  }
}

function* deleteOfflineOperation({ params, onSuccess = () => {}, onError = () => {} }) {
  yield put(OperationsActions.deleteOperation(params, onSuccess, onError))
}

function* deleteOperation({ params, onSuccess = () => {}, onError = () => {} }) {
  try {

    yield call(deleteOperationService, {}, params)

    yield call(onSuccess)
  } catch (error) {
    yield put(OperationsActions.operationsError(error))
    yield call(onError, error)
  }
}

function* deleteAccreditment({ accreditmentId, reason, onSuccess = () => {}, onError = () => {} }) {
  try {

    yield call(deleteAccreditmentService, { accreditmentId }, { reason })

    yield call(onSuccess)
  } catch (error) {
    yield put(OperationsActions.operationsError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(OperationsTypes.LOAD_OPERATIONS, loadOperations),
  takeLatest(OperationsTypes.LOAD_ACCREDITEDS, loadAccrediteds),
  takeLatest(OperationsTypes.CHECK_ACCREDITED, checkAccredited),
  takeLatest(OperationsTypes.EDIT_ACCREDITMENT, editAccreditment),
  takeLatest(OperationsTypes.SAVE_OFFLINE_OPERATION, saveOfflineOperation),
  takeLatest(OperationsTypes.SAVE_OPERATION, saveOperation),
  takeLatest(OperationsTypes.UPDATE_OFFLINE_OPERATION, updateOfflineOperation),
  takeLatest(OperationsTypes.UPDATE_OPERATION, updateOperation),
  takeLatest(OperationsTypes.DELETE_OFFLINE_OPERATION, deleteOfflineOperation),
  takeLatest(OperationsTypes.DELETE_OPERATION, deleteOperation),
  takeLatest(OperationsTypes.DELETE_ACCREDITMENT, deleteAccreditment),
  takeLatest(OperationsTypes.REQUEST_ACCREDITMENT, requestAccreditment)
]
