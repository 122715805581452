import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { mapValues, pickBy, identity } from 'lodash'

import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterMilkQualityForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterMilkQualityForm'

import useStyles from './styles'

const RegisterMilkQualityModal = (props) => {
  const {
    id,
    open,
    data,
    handleClose,
    reloadDataTable
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const [isLoading, setIsLoading] = useState(false)

  const defaultValues = useMemo(
    () => ({
      ...data,
      id: data?.id ?? null,
      date:  data.date,
      protein: data?.protein,
      fat: data?.fat,
      ccs: data?.ccs,
      ctb: data?.ctb,
      nul: data?.nul
    }), [data]
  )

  const isEditing = useMemo(
    () => defaultValues.id, [defaultValues]
  )

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      reloadDataTable()
      handleClose()
    }, [handleClose, reloadDataTable]
  )

  const onSuccess = useCallback(
    () => {
      setIsLoading(false)
      snackbar.success(
        t(`your {this} was ${  isEditing ? 'edited' :'registered' }`, {
          howMany: 1,
          gender: 'female',
          this: t('analysis', { howMany: 1 })
        })
      )
      closeModal()
    }, [closeModal, isEditing, snackbar, t]
  )

  const submitForms = useCallback(
    (formData) => {
      setIsLoading(true)

      dispatch(DairyFarmActions.saveMilkQuality(
        {
          id: data?.id ?? null,
          ...pickBy(mapValues(formData, (item) => item.replace(',', '.')), identity)
        },
        onSuccess,
        () => setIsLoading(false)
      ))
    }, [data, dispatch, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n>register quality</I18n>
        </>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      {
        isLoading ?
          <Loader width={ 100 } /> :
          (
            <RegisterMilkQualityForm
              ref={ formRef }
              defaultValues={ defaultValues }
              onSubmit={ submitForms }
              onCancel={ closeModal }
            />
          )
      }
    </Modal>
  )}

RegisterMilkQualityModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleClose: PropTypes.func,
  reloadDataTable: PropTypes.func
}

RegisterMilkQualityModal.defaultProps = {
  handleClose: () => {},
  reloadDataTable: () => {},
  data: {}
}

export default RegisterMilkQualityModal
