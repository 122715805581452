import React, { useCallback, useEffect, useState, useMemo } from 'react'

import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { map } from 'lodash'

import validateDataBySchema from '@smartcoop/forms/src/utils/validateDataBySchema'
import minNumberValue from '@smartcoop/forms/validators/minNumberValue.validator'
import { useT } from '@smartcoop/i18n'
import { formatCpfCnpj } from '@smartcoop/utils/formatters'
import Address from '@smartcoop/web-components/Address'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'

import { Container, AddressContainer, QuantityContainer, CNPJContainer, WrapperContainer, WrapperCNPJContainer } from './styles'

const AddressWithQuantity = (props) => {
  const {
    id,
    address,
    onChange,
    checked,
    quantity: externalQuantity,
    billingOrganizationId: externalBillingOrganizationId,
    note: externalNote,
    error,
    unit,
    disabled,
    allAdresses
  } = props
  const t = useT()

  const [quantity, setQuantity] = useState(externalQuantity)
  const [billingOrganizationId, setBillingOrganizationId] = useState(externalBillingOrganizationId || address.id)
  const [note, setNote] = useState(externalNote)

  const billingOptions = useMemo(() => map(allAdresses, item => ({
    label: `${ formatCpfCnpj(item?.companyDocument) } - ${ item?.tradeName }`,
    value: item.id
  })), [allAdresses])

  const handleQuantityChange = useCallback(
    ({ target }) => {
      setQuantity(target.value)
    },
    []
  )

  useEffect(() => {
    async function validate() {
      let newError
      try {
        await validateDataBySchema({
          data: { quantity },
          schema: Yup.object().shape({
            quantity: minNumberValue({ t, field: t('quantity') })(Yup.string())
          })
        })
      } catch (err) {
        if (err.formError) {
          newError = err.messages.quantity
        }
      } finally {
        onChange(id, { quantity, billingOrganizationId, note, error: newError })
      }
    }

    if (checked) {
      validate()
    }
  }, [billingOrganizationId, checked, id, note, onChange, quantity, t])

  useEffect(() => {
    if (!checked) {
      onChange(id, { quantity: '0', error: undefined })
    }
  }, [checked, id, onChange])

  useEffect(() => {
    if (externalQuantity !== quantity) {
      setQuantity(externalQuantity)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalQuantity])

  return (
    <WrapperContainer>
      <Container>
        <AddressContainer>
          <Address fields={ address } />
        </AddressContainer>
        <QuantityContainer>
          <InputUnit
            unit={ unit }
            label={ t('quantity') }
            onChange={ handleQuantityChange }
            disabled={ disabled || !checked }
            value={ quantity }
            error={ error }
            style={ { width: '100%' } }
            detached
          />
        </QuantityContainer>
      </Container>
      <WrapperCNPJContainer>
        <CNPJContainer>
          <InputSelect
            name="billingOrganizationId"
            label={ t('billing cnpj') }
            options={ billingOptions }
            onChange={ setBillingOrganizationId }
            disabled={ disabled || !checked }
            value={ billingOrganizationId }
            detached
          />
        </CNPJContainer>
      </WrapperCNPJContainer>
      <InputText
        name="note"
        label={ t('delivery observations') }
        fullWidth
        onChange={ ({ target: { value } }) => setNote(value) }
        value={ note }
        disabled={ disabled || !checked }
        detached
      />
    </WrapperContainer>
  )
}

AddressWithQuantity.propTypes = {
  id: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  allAdresses: PropTypes.array.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  quantity: PropTypes.string,
  billingOrganizationId: PropTypes.string,
  note: PropTypes.string,
  error: PropTypes.string,
  unit: PropTypes.string
}

AddressWithQuantity.defaultProps = {
  onChange: () => {},
  checked: false,
  quantity: '0',
  billingOrganizationId: null,
  note: '',
  error: undefined,
  unit: null,
  disabled: false
}


export default AddressWithQuantity
