import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

const filterOperations = () => Yup.object().shape({
  proprietaryId: flow(
  )(Yup.string()),

  propertyId: flow(
  )(Yup.string()),

  fieldId: flow(
  )(Yup.string()),

  requestStatus: flow(
  )(Yup.string()),

  accreditmentDate: Yup.string().nullable(),

  requestedDate: Yup.string().nullable(),

  stamp: flow(
  )(Yup.string())
})

export default filterOperations
