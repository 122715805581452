/* eslint-disable consistent-return */
import axios from 'axios'

const api = axios.create({
  baseURL: 'https://quicksign.signstamp.com.br/assinar'
})

// eslint-disable-next-line consistent-return
const sign = async (params) => {
  try {
    const { data } = await api.post('/', params, {
      headers: {
        'Authorization': 'Basic c21hcnRjb29wOmlZOTBTbUBydGMwMFAq',
        'Content-Type': 'application/pdf'
      },
      responseType:'blob'
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export { sign }

export default api
