import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import { Switch } from '@material-ui/core'

import { edit } from '@smartcoop/icons'
import { AbsenceActions } from '@smartcoop/stores/absence'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'

import {
  ChatAbsenceItemContainer,
  ChatAbsenceMainTitle,
  ChatAbsenceMessageContent,
  ChatAbsenceMessageTitle,
  ChatAbsencePeriodContent,
  ChatAbsencePeriodTitle,
  ChatAbsenceSwitcherContainer
} from './styles'

export default function ChatAbsenceItemFragment({
  absence,
  handleOpenEditModal
}) {
  const dispatch = useDispatch()

  const formatTime = (time) => moment(new Date(time)).format('DD/MM/YYYY HH:mm')

  const onClickEdit = () => {
    handleOpenEditModal(absence)
  }

  const switchAbsenceStatus = useCallback(() => {
    const newData = {
      ...absence,
      status: !absence.status
    }
    dispatch(AbsenceActions.editAbsence(absence.id, newData))
  }, [absence, dispatch])

  return (
    <ChatAbsenceItemContainer>
      <ChatAbsenceMainTitle>
        <span>{ absence.name }</span>
        <IconButton onClick={ onClickEdit }>
          <Icon icon={ edit } size={ 14 } />
        </IconButton>
      </ChatAbsenceMainTitle>
      <ChatAbsenceSwitcherContainer>
        <Switch
          checked={ absence.status }
          onChange={ switchAbsenceStatus }
        />
      </ChatAbsenceSwitcherContainer>
      <ChatAbsencePeriodTitle>
        Período:
      </ChatAbsencePeriodTitle>
      <ChatAbsencePeriodContent>
        { formatTime(absence.startTime) } <br />
        { formatTime(absence.endTime) }
      </ChatAbsencePeriodContent>
      <ChatAbsenceMessageTitle>
        Mensagem:
      </ChatAbsenceMessageTitle>
      <ChatAbsenceMessageContent>
        { absence.message }
      </ChatAbsenceMessageContent>
    </ChatAbsenceItemContainer>
  )
}

ChatAbsenceItemFragment.propTypes = {
  absence: PropTypes.object.isRequired,
  handleOpenEditModal: PropTypes.func.isRequired
}
