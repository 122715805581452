import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import float from '@smartcoop/forms/validators/float.validator'
import required from '@smartcoop/forms/validators/required.validator'



const phytosanitaryCropManagementSchema = ({ t }) =>
  Yup.object().shape({
    hour: Yup.lazy(value => !(value === '0' || value === null || value === '')
      ? Yup.string().matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, t('invalid hour'))
      : Yup.string()
    ),
    formFields: Yup.array()
      .of(
        Yup.object().shape({
          productGroup: flow(
            required({ t })
          )(Yup.string()),

          products: flow(required({ t }))(Yup.string()),

          value: Yup.string().nullable(),
          valueHA: Yup.string().nullable(),
          valueDose: Yup.string().nullable(),

          dosePhyntosanitary: flow(
            float({ t })
          )(Yup.string()),

          unit: Yup.string().when('dosePhyntosanitary', {
            is: value => !(value === '0' || value === '0.00'),
            then: flow(required({ t }))(Yup.string()),
            otherwise: Yup.string()
          })
        })
      )
  })

export default phytosanitaryCropManagementSchema
