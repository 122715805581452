import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import number from '@smartcoop/forms/validators/number.validator'


const grazingCropManagementSchema = ({ t }) => Yup.object().shape({
  numberOfAnimals: flow(
    number({ t })
  )(Yup.string()),

  grazingTimePeriod: flow(
    number({ t })
  )(Yup.string())
})

export default grazingCropManagementSchema
