import React from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  emptyFilter,
  filter
} from '@smartcoop/icons'
import EmptyState from '@smartcoop/web-components/EmptyState'
import FieldAnalysisItem from '@smartcoop/web-components/FieldAnalysisItem'
import Icon from '@smartcoop/web-components/Icon'
import InputSearch from '@smartcoop/web-components/InputSearch'
import Loader from '@smartcoop/web-components/Loader'

import {
  ContainerListFields,
  SearchContainer,
  ButtonFilter,
  ButtonRegisterAnalysis
} from './styles'

const FieldListAnalysis = (props) => {

  const {
    history,
    selectedAnalysis,
    routes,
    onChangeSearchFilter,
    handleAnalysisClick,
    filterText,
    filters,
    filterAnalysis,
    loading,
    analysis,
    t
  } = props

  const hasAnalysis = !isEmpty(analysis)

  return (
    <>
      <SearchContainer>
        <ButtonRegisterAnalysis
          id="register-analysis"
          color="secondary"
          onClick={ () => history.push(routes.fieldAnalysisRegister.path) }
        >
          {t('sample point')}
        </ButtonRegisterAnalysis>
      </SearchContainer>
      <SearchContainer>
        <InputSearch
          style={ { marginRight: 8, marginBottom: 0, flex: 5 } }
          adornmentStyle={ { marginRight: 15 } }
          detached
          onChange={ onChangeSearchFilter }
          value={ filterText }
          placeholder={ t('search') }
        />
        <ButtonFilter
          id="filter-field"
          color={ isEmpty(filters) ? 'white' : 'secondary' }
          onClick={ filterAnalysis }
          disabled={ loading }
        >
          <>
            <Icon
              style={ { marginRight: '8px' } }
              size={ 14 }
              icon={ filter }
            />
            {t('filtrate')}
          </>
        </ButtonFilter>
      </SearchContainer>

      <ContainerListFields isCenter={ isEmpty(analysis) }>
        {loading ? (
          <Loader width={ 100 } />
        ) : (
          map(analysis, (item) => (
            <FieldAnalysisItem selected={ selectedAnalysis?.id === item?.id } t={ t } analysis={ item } onClick={ () => handleAnalysisClick(item) }/>
          ))
        )}

        {!loading && !hasAnalysis && (
          <EmptyState text={ t('no results found') } icon={ emptyFilter } />
        )}
      </ContainerListFields>
    </>
  )
}

FieldListAnalysis.propTypes = {
  routes: PropTypes.object.isRequired,
  selectedAnalysis: PropTypes.object,
  history: PropTypes.func.isRequired,
  handleAnalysisClick: PropTypes.func.isRequired,
  onChangeSearchFilter: PropTypes.func.isRequired,
  filterText: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  filterAnalysis: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  analysis: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
}

FieldListAnalysis.defaultProps = {
  selectedAnalysis: {}
}

export default FieldListAnalysis
