/* eslint-disable no-unused-expressions */
import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useCallback,
  useState,
  useMemo,
  useEffect
} from 'react'

import PropTypes from 'prop-types'

import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'
import set from 'lodash/set'

import { useT } from '@smartcoop/i18n'

import { FormContext } from '../hooks/useForm'
import validateDataBySchema from '../utils/validateDataBySchema'

const FormProvider = forwardRef((props, formRef) => {
  const {
    UnformComponent,
    schemaConstructor,
    schemaProps,
    resetOnSubmit,
    onSubmit,
    dynamic,
    children,
    ...otherProps
  } = props
  const unformRef = useRef(null)
  const fields = useRef([])
  const fieldsOrderCounter = useRef(0)
  const fieldsZIndexCounter = useRef(100)
  const t = useT()
  const [schema, setSchema] = useState({})
  const needSchema = useMemo(
    () => !!schemaConstructor,
    [schemaConstructor]
  )
  // intercepting unform setFieldValue
  const setFieldValue = useCallback(
    (fieldName, value, ...other) => {
      let changed = false
      const fieldRef = formRef.current.getFieldRef(fieldName)
      if (
        fieldRef
        && fieldRef.setValue
        && !isEqual(fieldRef.value, value)
      ) {
        fieldRef.setValue(value)
        changed = true
      }
      // force unform field update
      const unformRefValue = unformRef.current.getFieldValue(fieldName)
      if (!isEqual(value, unformRefValue)) {
        unformRef?.current?.setFieldValue(fieldName, value)
      }
      if (changed && fieldRef.externalOnChange) {
        fieldRef?.externalOnChange({ target: { value } }, ...other)
      }
    },
    [formRef]
  )
  // intercepting and changing unform clearField
  const clearField = useCallback(
    (fieldName) => {
      const fieldRef = formRef.current.getFieldRef(fieldName)
      if (
        fieldRef
        && fieldRef.setValue
        && !isEqual(fieldRef.value, fieldRef.defaultValue)
      ) {
        fieldRef.setValue(fieldRef.defaultValue)
      }
      // force unform field update
      const unformRefValue = unformRef.current.getFieldValue(fieldName)
      if (!isEqual(fieldRef.defaultValue, unformRefValue)) {
        unformRef.current.setFieldValue(fieldName, fieldRef.defaultValue)
      }
    },
    [formRef]
  )
  // intercepting and changing unform reset
  const reset = useCallback(
    () => {
      forEach(fields.current, (fieldName) => {
        const fieldRef = formRef.current.getFieldRef(fieldName)
        if (fieldRef.resetField) {
          fieldRef.resetField()
        }
      })
    },
    [formRef]
  )
  const getFieldOrder = useCallback(
    () => {
      fieldsOrderCounter.current += 1
      const order = fieldsOrderCounter.current
      return order
    },
    []
  )
  const getFieldZIndex = useCallback(
    () => {
      const zIndex = fieldsZIndexCounter.current
      fieldsZIndexCounter.current -= 1
      return zIndex
    },
    []
  )
  const handleSubmit = useCallback(
    async (data, funcs = {}) => {
      try {
        if (!isEmpty(schema)) {
          await validateDataBySchema({ data, schema })
        }
        onSubmit(data, { ...funcs, reset })
        if (resetOnSubmit) {
          reset()
        }
      } catch (err) {
        if (err.formError) {
          formRef.current.setErrors(err.messages)
        }
      }
    },
    [formRef, onSubmit, reset, resetOnSubmit, schema]
  )
  // intercepting and changing unform setData
  const getData = useCallback(
    () => {
      const data = {}
      forEach(fields.current, (fieldName) => {
        set(data, fieldName, formRef.current.getFieldValue(fieldName))
      })
      return data
    },
    [formRef]
  )
  // intercepting and changing unform setData
  const setData = useCallback(
    (values) => {
      forEach(fields.current, (fieldName) => {
        const newFieldValue = get(values, fieldName)
        if (newFieldValue) {
          if (newFieldValue !== undefined) {
            const fieldRef = formRef.current.getFieldRef(fieldName)
            if (fieldRef.setValue) {
              fieldRef.setValue(newFieldValue)
            }
          }
        }
      })
      formRef.current.setErrors({})
    },
    [formRef]
  )
  const submit = useCallback(
    () => {
      const data = getData()
      handleSubmit(isFunction(dynamic) ? dynamic(data) : data)
    },
    [dynamic, getData, handleSubmit]
  )
  const registerFieldName = useCallback(
    fieldName => fields.current.push(fieldName),
    []
  )
  const unregisterFieldName = useCallback(
    fieldName => {
      fields.current = fields.current.filter(field => field !== fieldName)
    },
    []
  )
  const createSchema = useCallback(
    () => {
      if (schemaConstructor && formRef && formRef.current && formRef.current.getData) {
        const data = formRef.current.getData()
        const newSchema = schemaConstructor({ t, data, props: schemaProps })
        setSchema(newSchema)
      }
    },
    [formRef, schemaConstructor, schemaProps, t]
  )
  const validateField = useCallback(
    fieldName => {
      const fieldRef = formRef.current.getFieldRef(fieldName)
      if (fieldRef.validateField) {
        fieldRef.validateField()
      }
    },
    [formRef]
  )
  useEffect(() => {
    createSchema()
  }, [createSchema, schemaProps])
  useImperativeHandle(formRef, () => ({
    ...unformRef.current,
    createSchema,
    setFieldValue,
    submit,
    registerFieldName,
    unregisterFieldName,
    getData,
    setData,
    clearField,
    reset,
    validateField
  }))
  const state = {
    formRef,
    schema,
    needSchema,
    registerFieldName,
    unregisterFieldName,
    getFieldZIndex,
    getFieldOrder
  }
  return (
    <UnformComponent
      ref={ unformRef }
      onSubmit={ handleSubmit }
      { ...otherProps }
    >
      <FormContext.Provider value={ state }>
        {(!isEmpty(schema) || !schemaConstructor) && children}
      </FormContext.Provider>
    </UnformComponent>
  )
})
FormProvider.propTypes = {
  /** function that return the schema to control the form validations */
  schemaConstructor: PropTypes.func,
  /** bind dynamically options to schema constructor */
  schemaProps: PropTypes.object,
  /** function called when form pass the validations and it is submitted */
  onSubmit: PropTypes.func.isRequired,
  /** specific Form component from @unform/mobile or @unform/web */
  UnformComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]).isRequired,
  children: PropTypes.any,
  resetOnSubmit: PropTypes.bool,
  dynamic: PropTypes.func
}
FormProvider.defaultProps = {
  children: null,
  schemaConstructor: undefined,
  dynamic: undefined,
  schemaProps: {},
  resetOnSubmit: false
}
export default FormProvider
