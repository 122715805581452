import React, {
  useCallback,
  forwardRef,
  useMemo,
  useState,
  useEffect
} from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { map } from 'lodash'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'

import registerSupplierSchema from '@smartcoop/forms/schemas/shoppingPlatform/supplier/registerSupplier.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { searchCep } from '@smartcoop/services/apis/brasilApi'
import { searchStates, searchCities } from '@smartcoop/services/apis/ibgeApi'
import { getProducts } from '@smartcoop/services/apis/smartcoopApi/resources/product'
import { useSnackbar } from '@smartcoop/snackbar'
import { OrganizationActions } from '@smartcoop/stores/organization'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputCep from '@smartcoop/web-components/InputCep'
import InputCpfCnpj from '@smartcoop/web-components/InputCpfCnpj'
import InputEmail from '@smartcoop/web-components/InputEmail'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputPhone from '@smartcoop/web-components/InputPhone'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import { Title } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'

import {
  TitleContainer,
  FieldsContainer,
  Row,
  Container,
  Header,
  ButtonContainer,
  ItemLeft,
  Item
} from './styles'

const EditSupplierForm = forwardRef((props, formRef) => {
  const { onCancel, style, onSuccess, supplier } = props
  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()

  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const options = useMemo(
    () => [
      {
        label: t('yes'),
        value: true
      },
      {
        label: t('no'),
        value: false
      }
    ],
    [t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setLoading(true)

      dispatch(
        OrganizationActions.updateOrganization(
          {
            ...supplier,
            ...data,
            type: 2,
            companyDocument: data.companyDocument.replace(/\D/g, ''),
            organizationEmails: [{ email: data.email }],
            organizationPhones: [{ phone: data.phone }],
            postalCode: data.cep.replace(/\D/g, ''),
            district: data.neighborhood
          },
          () => {
            snackbar.success(
              t('your {this} was edited', {
                gender: 'male',
                howMany: 1,
                this: t('supplier', { howMany: 1 })
              })
            )
            setLoading(false)
            onSuccess()
          },
          (err) => {
            snackbar.error(t(err.message))
            setLoading(false)
          }
        )
      )
    },
    [dispatch, onSuccess, snackbar, supplier, t]
  )

  const handleStateChange = useCallback(
    async (value) => {
      if (value) {
        let data = await searchCities(value)
        data = map(data, ({ nome }) => ({ label: nome, value: nome }))
        setCityOptions(data)
      } else {
        setCityOptions([])
        formRef.current.clearField('city')
      }
    },
    [formRef]
  )

  const receiveAddress = useCallback(
    ({ ...address }) => {
      formRef.current.setData({
        ...address
      })
    },
    [formRef]
  )

  useEffect(() => {
    async function findStates() {
      let data = await searchStates()
      data = map(data, ({ sigla }) => ({ label: sigla, value: sigla }))
      setStateOptions(data)
    }
    findStates()
  }, [])

  const handleEdit = useCallback(
    async (value) => {
      await handleStateChange(value.state)
      const address = await searchCep(value.postalCode)
      formRef.current.setData({
        ...{ city: address.city }
      })
      setLoading(false)
    },
    [formRef, handleStateChange]
  )

  useEffect(() => {
    if (supplier.state) {
      setLoading(true)
      handleEdit(supplier)
    } else {
      setCityOptions([])
      formRef.current.clearField('city')
    }
  }, [formRef, handleEdit, handleStateChange, supplier])

  return (
    <Form
      ref={ formRef }
      style={ { ...style } }
      schemaConstructor={ registerSupplierSchema }
      onSubmit={ handleSubmit }
      loading={ loading }
    >
      <SplitedScreenLayout
        title={ { name: t('supplier list') } }
        divRightStyle={ { paddingTop: 67 } }
        leftChildren={
          <Container>
            <Header style={ { paddingLeft: 35 } }>
              <Title style={ { fontSize: 16, fontWeight: 600 } }>
                <I18n>edit supplier</I18n>
              </Title>
            </Header>

            <ItemLeft>
              <RadioGroup
                label={ t('active register?') }
                name="active"
                options={ options }
                variant="row"
                style={ { marginBottom: '15px' } }
                defaultValue={ supplier.active }
              />
              <InputCpfCnpj
                name="companyDocument"
                label={ t('company document') }
                onlyCnpj
                fullWidth
                defaultValue={ supplier.companyDocument }
              />
              <InputText
                name="companyName"
                label={ t('company name') }
                fullWidth
                defaultValue={ supplier.companyName }
              />
              <InputText
                name="tradeName"
                label={ t('trade name') }
                fullWidth
                defaultValue={ supplier.tradeName }
              />
              <InputPhone
                label={ t('phone') }
                name="phone"
                fullWidth
                defaultValue={ supplier.phone }
              />
              <InputEmail
                name="email"
                label={ t('email') }
                fullWidth
                defaultValue={ supplier.email }
              />
              <RadioGroup
                label={ t('exempt state registration') }
                name="exemptStateRegistration"
                options={ options }
                variant="row"
                style={ { marginBottom: '10px' } }
                defaultValue={ supplier.exemptStateRegistration }
              />

              <InputNumber
                label={ t('state registration') }
                name="stateRegistration"
                fullWidth
                maxLength={ 20 }
                defaultValue={ supplier.stateRegistration }
              />
            </ItemLeft>
          </Container>
        }
        rightChildren={
          <Container>
            <Row>
              <TitleContainer>
                <I18n style={ { fontSize: 16, fontWeight: 600 } }>
                  select products
                </I18n>
              </TitleContainer>
              <Divider style={ { marginTop: 8, marginBottom: 15 } } />
            </Row>

            <FieldsContainer style={ { paddingBottom: 0 } }>
              <Row>
                <InputSelect
                  multiple
                  label={ t('product') }
                  name="products"
                  options={ getProducts }
                  asyncOptionLabelField="description"
                  defaultValue={ supplier.products }
                  queryParams={ { purchasing: true } }
                />
              </Row>
            </FieldsContainer>

            <Row>
              <TitleContainer style={ { paddingTop: 20 } }>
                <I18n style={ { fontSize: 16, fontWeight: 600 } }>
                  address and complements
                </I18n>
              </TitleContainer>
              <Divider style={ { marginTop: 8, marginBottom: 15 } } />
            </Row>

            <FieldsContainer style={ { paddingBottom: 0 } }>
              <Item>
                <Grid container style={ { justifyContent: 'space-between' } }>
                  <Grid item xs={ 2 }>
                    <InputSelect
                      label={ t('uf') }
                      name="state"
                      onChange={ (e) => handleStateChange(e.target.value) }
                      options={ stateOptions }
                      fullWidth
                      defaultValue={ supplier.state }
                    />
                  </Grid>
                  <Grid item xs={ 9 }>
                    <InputSelect
                      label={ t('city', { howMany: 1 }) }
                      name="city"
                      options={ cityOptions }
                      fullWidth
                      defaultValue={ supplier.city }
                    />
                  </Grid>
                </Grid>
              </Item>

              <Item>
                <InputCep
                  name="cep"
                  label={ t('cep') }
                  fullWidth
                  onAddressChange={ receiveAddress }
                  defaultValue={ supplier.cep }
                />
              </Item>

              <Item>
                <InputText
                  name="street"
                  label={ t('street') }
                  fullWidth
                  defaultValue={ supplier.street }
                />
              </Item>

              <Item>
                <InputText
                  maxLength={ 6 }
                  label={ t('number') }
                  name="number"
                  fullWidth
                  defaultValue={ supplier.number }
                />
              </Item>

              <Item>
                <InputText
                  name="neighborhood"
                  label={ t('neighborhood') }
                  fullWidth
                  defaultValue={ supplier.neighborhood }
                />
              </Item>

              <Item>
                <InputText
                  name="addressComplement"
                  label={ t('complement') }
                  fullWidth
                  defaultValue={ supplier.addressComplement }
                />
              </Item>

              <Row style={ { justifyContent: 'flex-end', display: 'flex' } }>
                <ButtonContainer>
                  <Button
                    id="cancel-product-register-form"
                    onClick={ onCancel }
                    style={ { flex: 1 } }
                    variant="outlined"
                  >
                    <I18n>cancel</I18n>
                  </Button>
                </ButtonContainer>
                <ButtonContainer>
                  <Button
                    id="submit-supplier-register-form"
                    onClick={ () => formRef.current.submit() }
                    style={ { flex: 1 } }
                  >
                    <I18n>save</I18n>
                  </Button>
                </ButtonContainer>
              </Row>
            </FieldsContainer>
          </Container>
        }
      />
    </Form>
  )
})

EditSupplierForm.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  style: PropTypes.object,
  supplier: PropTypes.object
}

EditSupplierForm.defaultProps = {
  onCancel: () => {},
  onSuccess: () => {},
  style: {
    height: 'inherit'
  },
  supplier: {}
}

export default EditSupplierForm
