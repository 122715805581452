import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  display: flex;
  height: calc(100vh - 65px);
  flex-direction: column;
`

export const UpperContainer = styled.div`
  display: flex;
  background: ${ colors.white };
  flex: 1;
`

export const UnderContainer = styled.div`
  display: flex;
  flex: 3;
`
