import React from 'react'

import PropTypes from 'prop-types'

import { search } from '@smartcoop/icons'
import InputIcon from '@smartcoop/web-components/InputIcon'

const InputSearch = (props) => {
  const { label, ...rest } = props

  return (
    <InputIcon
      { ...rest }
      icon={ search }
      label={ label }
    />
  )
}

InputSearch.propTypes = {
  label: PropTypes.string
}

InputSearch.defaultProps = {
  label: ''
}

export default InputSearch
