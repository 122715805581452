import React, { useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'

import filterRainRecord from '@smartcoop/forms/schemas/property/filterRainRecord.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getFieldsByPropertyId as getFieldsByPropertyIdService } from '@smartcoop/services/apis/smartcoopApi/resources/field'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDateRange from '@smartcoop/web-components/InputDateRange'
import InputSelect from '@smartcoop/web-components/InputSelect'

import { Container, ButtonContainer } from './styles'

const FilterRainRecordForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters, propertyId } = props

  const t = useT()

  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  const loadFieldOptions = useCallback(
    async (...params) => {
      const result = await getFieldsByPropertyIdService(...params)
      return {
        data: {
          data: result
        }
      }
    },
    []
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterRainRecord }
        onSubmit={ handleSubmit }
      >

        <InputSelect
          label={ t('field', { howMany: 1 }) }
          name="fieldId"
          options={ loadFieldOptions }
          urlParams={ { propertyId } }
          asyncOptionLabelField="fieldName"
          defaultValue={ filters.fieldId }
        />

        <InputDateRange
          label={ t('period') }
          name="rangeDate"
          defaultValue={ { from: filters.startDate, to: filters.endDate } || { from: '', to: '' } }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-order-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterRainRecordForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  propertyId: PropTypes.string
}

FilterRainRecordForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false,
  propertyId: ''
}

export default FilterRainRecordForm
