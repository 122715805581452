import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import { colors } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 30px 23px;
  background-color: ${ colors.backgroundHtml };
  justify-content: center;
`

export const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 70%;
  align-self: flex-end;
`

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Title = styled(Typography)`
  font-size: 18px;
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: 700;
  color: ${ colors.black };
  display: flex;
  flex-direction: row;
`


