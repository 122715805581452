import api from '../api'

export const createUserAbsence = (newAbsence) =>
  api.post('/v1/absence/status', newAbsence)

export const getUserAbsences = () =>
  api.get('/v1/absence/status')

export const patchUserAbsence = (id, params) =>
  api.patch(`/v1/absence/status/${ id }`, params)

export const deleteUserAbsence = (id) =>
  api.delete(`/v1/absence/status/${ id }`)