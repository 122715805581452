import api from '../api'

export const getGrowingSeasons = async (params) => {
  const { data } = await api.get('/v1/growing-seasons', { params })
  return data
}

export const getGrowingSeasonHistory = async ({ growingSeasonId }) => {
  const { data } = await api.get(
    `v1/growing-seasons/${ growingSeasonId }/history`
  )
  return data
}

export const createGrowingSeason = (params) =>
  api.post('/v1/growing-seasons', params)

export const createGrowingSeasonReport = (params, accessToken) =>
  api.post('/v1/growing-seasons/report', params, accessToken ? {
    headers: {
      'authorization': accessToken
    }
  } : {})

export const createGrowingSeasons = (params) =>
  api.post('/v2/growing-seasons', params)

export const editGrowingSeasons = (params, { growingSeasonId }) =>
  api.patch(`/v1/growing-seasons/${ growingSeasonId }`, params)

export const deleteGrowingSeasons = ({ growingSeasonId }) =>
  api.delete(`/v1/growing-seasons/${ growingSeasonId }`)

export const getOpenGrowingSeasonsByProperty = (params) =>
  api.get('v1/growing-seasons/open/fields', { params })

export const createGrowingSeasonsReport = (params) =>
  api.post('/v1/growing-seasons/report', params, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
