/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, memo } from 'react'
import { useParams , useHistory } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'

import I18n from '@smartcoop/i18n'
import { colors } from '@smartcoop/styles'
import SeloBronzePdf from '@smartcoop/styles/assets/images/selos/selo-bronze-de-inovacao.pdf'
import SeloBronzePng from '@smartcoop/styles/assets/images/selos/selo-bronze-de-inovacao.png'
import SeloDiamantePdf from '@smartcoop/styles/assets/images/selos/selo-diamante-de-inovacao.pdf'
import SeloDiamantePng from '@smartcoop/styles/assets/images/selos/selo-diamante-de-inovacao.png'
import SeloOuroPdf from '@smartcoop/styles/assets/images/selos/selo-ouro-de-inovacao.pdf'
import SeloOuroPng from '@smartcoop/styles/assets/images/selos/selo-ouro-de-inovacao.png'
import SeloPrataPdf from '@smartcoop/styles/assets/images/selos/selo-prata-de-inovacao.pdf'
import SeloPrataPng from '@smartcoop/styles/assets/images/selos/selo-prata-de-inovacao.png'
import Button from '@smartcoop/web-components/Button'
import GoBackIconButton from '@smartcoop/web-components/IconButton/GoBackIconButton'
import { withLayout } from '@smartcoop/web-containers/layouts'
import { Item, Title, Row, Text, SimpleItem } from '@smartcoop/web-containers/layouts/GuestLayout/theme'

const StampScreen = () => {
  const { hash } = useParams()
  const history = useHistory()

  const stamp = useMemo(
    () => (
      {
        '3376ca71-7160-4eea-8cfd-8d6e26230ecc': {
          hash: '3376ca71-7160-4eea-8cfd-8d6e26230ecc',
          stampName: 'Bronze',
          fileName: 'Selo Bronze Smartcoop.',
          png: SeloBronzePng,
          pdf: SeloBronzePdf
        },
        '65641262-9963-4a51-812b-1aebb8ece0a9': {
          hash: '65641262-9963-4a51-812b-1aebb8ece0a9',
          stampName: 'Prata',
          fileName: 'Selo Prata Smartcoop.',
          png: SeloPrataPng,
          pdf: SeloPrataPdf
        },
        '1b0a1537-6f65-4f55-9d19-3db5dc2d1bb6': {
          hash: '1b0a1537-6f65-4f55-9d19-3db5dc2d1bb6',
          stampName: 'Ouro',
          fileName: 'Selo Ouro Smartcoop.',
          png: SeloOuroPng,
          pdf: SeloOuroPdf
        },
        'db1a390c-4ab7-4c9b-b75e-7337c103d6e0': {
          hash: 'db1a390c-4ab7-4c9b-b75e-7337c103d6e0',
          stampName: 'Diamante',
          fileName: 'Selo Diamante Smartcoop.',
          png: SeloDiamantePng,
          pdf: SeloDiamantePdf
        }
      }[hash]
    ),
    [hash]
  )

  useEffect(
    () => {
      if (isEmpty(stamp)) {
        history.push('/')
      }
    },
    [hash, history, stamp]
  )

  return (
    <>
      <Item style={ { width: '100%' } }>
        <I18n as={ Title }>welcome to smartcoop (without break)</I18n>

        <Row style={ { marginTop: 50 } }>
          <SimpleItem style={ { flexDirection: 'column', flex: 1, justifyContent: 'space-around' } }>
            <I18n as={ Text } params={ { this: stamp?.stampName } }>{'download your stamp {this}'}</I18n>

            <SimpleItem style={ { flexDirection: 'column' } }>
              <Button
                style={ { flex: '0 0 auto', maxWidth: 300, padding: 0, marginBottom: 5 } }
                variant="outlined"
                id="download-pdf"
                fullWidth
              >
                <a href={ stamp?.pdf } download={ `${ stamp?.fileName }pdf` } style={ { color: colors.black, width: '100%', padding: '5px 15px' } }>
                  <I18n>pdf</I18n>
                </a>
              </Button>

              <Button
                style={ { flex: '0 0 auto', maxWidth: 300, padding: 0 } }
                variant="outlined"
                id="download-png"
                fullWidth
              >
                <a href={ stamp?.png } download={ `${ stamp?.fileName }png` } style={ { color: colors.black, width: '100%', padding: '5px 15px' } }>
                  <I18n>png</I18n>
                </a>
              </Button>
            </SimpleItem>

          </SimpleItem>

          <SimpleItem>
            <img src={ stamp?.png } width={ 250 } alt="Stamp to Download" />
          </SimpleItem>
        </Row>

        <SimpleItem style={ { alignItems: 'center', marginLeft: -15 } }>
          <GoBackIconButton onClick={ () => history.push('/') } iconColor={ colors.primary } />
          <Title
            style={ {
              lineHeight: 1,
              fontSize: 18
            } }
          >
            <I18n>go back</I18n>

          </Title>
        </SimpleItem>
      </Item>
    </>
  )
}

export default memo(withLayout('guest')(StampScreen))
