import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerMilkControl = ({ t }) => Yup.object().shape({
  animalId: flow(
    required({ t })
  )(Yup.string()),

  date: flow(
    date({ t }),
    required({ t })
  )(Yup.string()),

  volume: Yup.string().nullable(),

  del: flow(
    required({ t })
  )(Yup.string()),

  ccs: Yup.string().nullable(),

  fat: Yup.string().nullable(),

  protein: Yup.string().nullable(),

  lactose: Yup.string().nullable(),

  totalSolids: Yup.string().nullable(),

  nul: Yup.string().nullable(),

  esd: Yup.string().nullable(),

  casein: Yup.string().nullable(),

  lotId: flow(
    required({ t })
  )(Yup.string())
})

export default registerMilkControl
