import React from 'react'

import PropTypes from 'prop-types'

import Backdrop from '@material-ui/core/Backdrop'
import { makeStyles } from '@material-ui/core/styles'

import AnimatedLoader from '@smartcoop/web-components/Loader'

const PartialLoading = ({ children, open, loadingLabel, width, opacity, displayContent }) => {
  const useStyles = makeStyles({
    parent: {
      position: 'relative',
      display: displayContent ? 'contents' : null,
      zIndex: 0,
      width: '100%'
    },
    backdrop: {
      position: 'absolute',
      zIndex: 999,
      backgroundColor: `rgba(255,255,255, ${ opacity })`
    }
  })

  const classes = useStyles()

  return (
    <div className={ classes.parent }>
      <Backdrop className={ classes.backdrop } open={ open }>
        <AnimatedLoader width={ width } message={ loadingLabel } />
      </Backdrop>
      {children}
    </div>
  )
}

PartialLoading.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  loadingLabel: PropTypes.string,
  width: PropTypes.number,
  opacity: PropTypes.number,
  displayContent: PropTypes.bool
}

PartialLoading.defaultProps = {
  children: null,
  open: false,
  loadingLabel: null,
  width: 200,
  opacity: 0.8,
  displayContent: false
}
export default PartialLoading
