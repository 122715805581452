// eslint-disable-next-line spaced-comment
export default ({ size } = {}) => /*html*/`
<svg width="${ size }" height="${ size }" viewBox="0 0 109 125" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M108.009 36.0006C108.009 55.8821 91.8881 72 72.0042 72C52.1191 72 36 55.8821 36 36.0006C36 16.1179 52.1191 0 72.0042 0C91.8881 0 108.009 16.1179 108.009 36.0006Z" fill="#289F30"/>
  <path d="M75.4669 54.0107H68.9114C60.5379 54.0107 53.9788 47.5596 53.9788 39.3233C53.9788 38.3166 54.7949 37.5017 55.8004 37.5017C56.8072 37.5017 57.6221 38.3166 57.6221 39.3233C57.6221 45.5169 62.5806 50.3686 68.9114 50.3686H75.4669C81.7977 50.3686 86.7563 45.6238 86.7563 39.5674C86.7563 38.5607 87.5711 37.7458 88.5779 37.7458C89.5847 37.7458 90.3996 38.5607 90.3996 39.5674C90.3996 47.6677 83.8404 54.0107 75.4669 54.0107Z" fill="#1D1D1B"/>
  <path d="M63.6361 23.7075C63.171 23.7075 62.7047 23.5302 62.3488 23.1744C60.6219 21.4487 57.7656 21.3867 56.1127 23.0408C55.4011 23.7524 54.2474 23.7524 53.5357 23.0408C52.824 22.3303 52.824 21.1766 53.5357 20.4662C56.5876 17.4143 61.8023 17.4763 64.9246 20.5986C65.6363 21.309 65.6363 22.4627 64.9246 23.1744C64.5688 23.5302 64.1025 23.7075 63.6361 23.7075Z" fill="#1D1D1B"/>
  <path d="M88.4166 23.7077C87.9503 23.7077 87.484 23.5304 87.1281 23.1746C85.4036 21.4489 82.5461 21.3869 80.892 23.041C80.1804 23.7526 79.0279 23.7526 78.3162 23.041C77.6046 22.3305 77.6046 21.1768 78.3162 20.4664C81.3669 17.4145 86.5816 17.4764 89.7039 20.5987C90.4156 21.3092 90.4156 22.4629 89.7039 23.1746C89.3481 23.5304 88.8818 23.7077 88.4166 23.7077Z" fill="#1D1D1B"/>
  <path d="M2.30899 32C2.30899 32 8.58574 46.6443 30.8128 46.6443C30.8128 46.6443 46.9935 47.1425 52.3954 67.8121C52.6444 68.7587 53.0461 69.6586 53.5847 70.4776C55.9665 74.0927 62.7508 87.641 61.6704 123.534C61.6704 123.534 60.232 125.234 59.7089 124.973C59.7089 124.973 62.5359 89.0017 52.3985 72.3241C52.2459 72.0718 51.9314 71.9722 51.6575 72.0843C49.8299 72.8347 43.055 75.2511 38.7895 71.8819C35.1997 69.0483 32.1578 69.0795 30.7443 69.3161C29.5861 69.5092 28.4154 69.6119 27.2416 69.5714C20.5446 69.3379 3.28662 66.4638 0.347502 43.3748C0.347502 43.3779 -1.22169 38.1467 2.30899 32Z" fill="#1D1D1B"/>
  <path d="M60.8665 100.909C60.8665 100.909 57.3857 100.028 54.2784 101.498C53.5032 101.865 52.6003 101.797 51.8655 101.351C49.7608 100.071 45.1404 98.3215 39.6918 102.861C35.6443 106.234 30.1366 107.05 25.9365 107.09C22.8853 107.118 19.8653 106.495 17.032 105.362C15.0456 104.568 12.3244 103.699 10.0952 103.78C9.93953 103.786 9.83367 103.939 9.87415 104.088C10.2291 105.387 12.1345 110.571 19.7688 110.026C28.4865 109.404 25.9957 112.206 30.3545 112.829C34.7134 113.452 41.2517 115.32 53.3942 104.11C53.3942 104.11 56.819 100.996 60.8665 102.242V100.909Z" fill="#1D1D1B"/>
  <path d="M9.13013 89.6027C9.13013 89.6027 23.723 76.8827 29.9779 76.5152C34.9252 76.2225 43.3721 79.1246 46.691 80.3546C47.5597 80.6753 48.4906 80.7563 49.4028 80.6037C51.4671 80.2643 55.4336 80.0494 57.4076 82.8456L56.5887 83.1726C56.5887 83.1726 54.3564 80.9991 50.5891 81.2825C49.3997 81.3728 48.2851 81.9084 47.4756 82.7896C45.8317 84.58 42.1983 88.0208 38.02 88.6872C36.2889 88.9643 34.6388 89.5871 33.191 90.5742C28.6703 93.6537 18.4519 98.6763 9.13013 89.6027Z" fill="#1D1D1B"/>
  <path d="M84 90.5019C84 95.1954 79.9716 99 74.998 99C70.0324 99 66 95.1954 66 90.5019C66 85.8083 70.0324 82 75.002 82C79.9716 82 84 85.8083 84 90.5019Z" fill="#1D1D1B"/>
</svg>
`
