import React, { useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import { deleteTechnicalEvaluationAnswer } from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import { selectIsProfileRTC } from '@smartcoop/stores/organization/selectorOrganization'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'
import CreateOperationForm from '@smartcoop/web-containers/forms/operation/CreateOperationForm'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import useStyles, { Row, Content } from './styles'

const CreateOperationModal = ({ id, open, handleClose, onSubmit, defaultValues, readOnly }) => {
  const classes = useStyles()
  const createOperationModalFormRef = useRef(null)

  const isRTC = useSelector(selectIsProfileRTC)
  const [loading, setLoading] = useState(false)
  const [technicalEvaluationAnswerId, setTechnicalEvaluationAnswerId] = useState(defaultValues?.quizAnswerId || null)

  const clearForm = useCallback(
    async () => {
      if(technicalEvaluationAnswerId && !defaultValues?.quizAnswerId) {
        await deleteTechnicalEvaluationAnswer({ quizAnswerId: technicalEvaluationAnswerId })
      }
      handleClose()
    },
    [defaultValues.quizAnswerId, handleClose, technicalEvaluationAnswerId]
  )

  const onSuccess = useCallback(
    () => {
      setLoading(false)
      onSubmit()
      handleClose()
    },
    [handleClose, onSubmit]
  )

  const onError = useCallback(
    () => {
      setLoading(false)
    },
    []
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row>
          <I18n>operation 365 - evaluation form</I18n>
        </Row>
      }
      escape
      loading={ loading }
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 800 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      maxWidth="md"
      disableFullScreen
      disableEsc
    >
      <Content>
        <CreateOperationForm
          ref={ createOperationModalFormRef }
          onSuccess={ onSuccess }
          onError={ onError }
          isRTC={ isRTC }
          defaultValues={ defaultValues }
          loading={ loading }
          setLoading={ setLoading }
          readOnly={ readOnly }
          setTechnicalEvaluationAnswerId={ setTechnicalEvaluationAnswerId }
          technicalEvaluationAnswerId={ technicalEvaluationAnswerId }
        />
        { readOnly ? null : (
          <ButtonsContainer style={ { marginTop: 10 } }>
            <Button
              id="cancel"
              onClick={ () => clearForm() }
              variant="outlined"
              style={ { marginRight: 7 } }
              disabled={ loading }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="save"
              onClick={ () => createOperationModalFormRef.current.submit() }
              color="black"
              style={ { marginLeft: 7 } }
              disabled={ loading }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Content>
    </Modal>
  )}

CreateOperationModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.object
}

CreateOperationModal.defaultProps = {
  onSubmit: () => {},
  readOnly: false,
  defaultValues: {}
}

export default CreateOperationModal
