import React, { useCallback, forwardRef, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'

import filterTechnicalVisitProducerSchema from '@smartcoop/forms/schemas/property/filterTechnicalVisitProducer.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { selectTechnicalVisits } from '@smartcoop/stores/technical/selectorTechnical'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDateRange from '@smartcoop/web-components/InputDateRange'
import InputSelect from '@smartcoop/web-components/InputSelect'

import { Container, ButtonContainer } from './styles'

const FilterTechnicalVisitProducerForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props
  const [organizationId, setOrganizationId] = useState(filters?.organizationId)
  const [technicianId, setTechnicianId] = useState(filters?.technicianId)
  const [rangeDate, setRangeDate] = useState({ from: filters?.startDate, to: filters?.endDate })
  const t = useT()

  const technicalVisits = useSelector(selectTechnicalVisits)

  const organizationData = useMemo(() => uniqBy(map(filter(technicalVisits, item => !isEmpty(item.technicalCooperative)), item => ({ label: item?.technicalCooperative[0]?.organization?.tradeName, value: item?.technicalCooperative[0]?.organizationId })), 'value'),[technicalVisits])

  const technicianData = useMemo(() => {
    const filteredOrganization = filter(technicalVisits, item => organizationId === item?.technicalCooperative[0]?.organizationId)
    return uniqBy(map(organizationId ? filteredOrganization : technicalVisits, item => ({ label: item?.technician?.name, value: item?.technician?.id })), 'value')
  },[organizationId, technicalVisits])

  const fieldData = useMemo(() => {
    const filteredFields = filter(technicalVisits, item => {
      if(organizationId && technicianId) {
        return item?.technicalCooperative[0]?.organizationId === organizationId && item?.technician?.id === technicianId
      }
      if(organizationId) {
        return item?.technicalCooperative[0]?.organizationId === organizationId
      }
      if(technicianId) {
        return item?.technician?.id === technicianId
      }
      return item
    })
    const hasField = filter(filteredFields, item => item?.field)
    return uniqBy(map(hasField, item => ({ label: item?.field?.fieldName, value: item?.field?.id })), 'value')
  },[organizationId, technicalVisits, technicianId])

  const handleSubmit = useCallback(
    (data) => onSubmit({ ...data, rangeDate }),
    [onSubmit, rangeDate]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterTechnicalVisitProducerSchema }
        onSubmit={ handleSubmit }
      >
        <InputSelect
          label={ t('organization', { gender: 'male', howMany: 1 }) }
          name="organizationId"
          options={ organizationData }
          onChange={ (e) => {
            formRef.current.setFieldValue('fieldId', null)
            formRef.current.setFieldValue('technicianId', null)
            setOrganizationId(e.target.value)
          } }
          defaultValue={ filters.organizationId || null }
        />
        <InputSelect
          label={ t('technical', { howMany: 1 }) }
          name="technicianId"
          options={ technicianData }
          onChange={ (e) => {
            formRef.current.setFieldValue('fieldId', null)
            setTechnicianId(e.target.value)
          } }
          defaultValue={ filters.technicianId || null }
        />
        <InputSelect
          label={ t('field', { howMany: 1 }) }
          name="fieldId"
          options={ fieldData }
          defaultValue={ filters.fieldId || null }
        />
        <div style={ { paddingBottom: '20px' } }>
          <InputDateRange
            label={ t('period') }
            name="rangeDate"
            value={ rangeDate }
            onChange={ setRangeDate }
            fullWidth
          />
        </div>

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-order-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterTechnicalVisitProducerForm.propTypes = {
  filters: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterTechnicalVisitProducerForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterTechnicalVisitProducerForm
