import React, { useState, useEffect } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import { useDialog } from '@smartcoop/dialog'

import PartialLoading from '../PartialLoading/PartialLoading'
import HeaderTitleClose from './HeaderTitleClose'
import useStyles from './styles'

const Modal = (props) => {
  const {
    contentText,
    children,
    id,
    open,
    escape,
    loading,
    title,
    onClose,
    onExit,
    FooterComponent,
    position,
    headerProps,
    fullScreen,
    disableFullScreen,
    maxWidth,
    breakFullScreen,
    hideHeader,
    contentContainerStyle,
    escapeWhenLoading,
    footerContainerStyle,
    disableEsc,
    ...otherProps
  } = props

  const classes = useStyles()

  const [fullScreenState, setFullScreenState] = useState(fullScreen)

  useEffect(() => {
    setFullScreenState(fullScreen)
  }, [fullScreen])

  const { removeDialog } = useDialog()

  const close = () => {
    onClose()
    removeDialog({ id })
  }

  const componentsProps = {
    onClose: close,
    title,
    escape: escape && (escapeWhenLoading || !loading)
  }

  return (
    <Dialog
      id={ id }
      open={ open }
      onClose={ close }
      onExit={ onExit }
      disableEscapeKeyDown={ !componentsProps.escape || disableEsc }
      disableBackdropClick={ !componentsProps.escape || disableEsc }
      disableRestoreFocus
      disableEnforceFocus
      maxWidth={ maxWidth }
      fullScreen={ fullScreenState }
      classes={ {
        scrollPaper: position === 'top' ? classes.dialogTop : undefined,
        ...(otherProps.classes || {})
      } }
      { ...otherProps }
    >
      <PartialLoading displayContent open={ loading }>
        {!hideHeader && (
          <HeaderTitleClose
            fullScreen={ fullScreenState }
            onChangeFullScreen={ () => setFullScreenState((old) => !old) }
            fullScreenButton={ !disableFullScreen }
            { ...componentsProps }
            { ...headerProps }
          />
        )}

        <DialogContent
          classes={ {
            root: clsx({ [classes.withoutFooter]: !FooterComponent })
          } }
          style={ contentContainerStyle }
        >
          <div className={ classes.contentText }>{contentText}</div>
          {children}
        </DialogContent>

        {FooterComponent && (
          <DialogActions
            style={ footerContainerStyle }
            classes={ { root: classes.footerContainer } }
          >
            <FooterComponent { ...componentsProps } />
          </DialogActions>
        )}
      </PartialLoading>
    </Dialog>
  )
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onExit: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  FooterComponent: PropTypes.func,
  contentText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.element,
  escape: PropTypes.bool,
  loading: PropTypes.bool,
  escapeWhenLoading: PropTypes.bool,
  fullScreen: PropTypes.bool,
  position: PropTypes.oneOf(['middle', 'top']),
  headerProps: PropTypes.object,
  breakFullScreen: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  disableFullScreen: PropTypes.bool,
  hideHeader: PropTypes.bool,
  contentContainerStyle: PropTypes.object,
  footerContainerStyle: PropTypes.object,
  disableEsc: PropTypes.bool
}

Modal.defaultProps = {
  title: null,
  FooterComponent: null,
  contentText: null,
  children: null,
  escape: true,
  loading: undefined,
  fullScreen: false,
  escapeWhenLoading: false,
  disableEsc: false,
  onClose: () => {},
  onExit: () => {},
  position: 'middle',
  headerProps: {},
  maxWidth: 'sm',
  breakFullScreen: 'xs',
  disableFullScreen: false,
  hideHeader: false,
  contentContainerStyle: {},
  footerContainerStyle: {}
}

export default Modal
