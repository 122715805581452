import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import colors from '@smartcoop/styles/colors'

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.backgroundHtml
  }
})

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0%;
  padding: 25px 30px;
`
