import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import useGeolocation from 'react-hook-geolocation'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import I18n from '@smartcoop/i18n'
import { marker as markerIcon , markerClosed as markerClosedIcon, compass } from '@smartcoop/icons'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectOfflineProperty } from '@smartcoop/stores/property/selectorProperty'
import { DEFAULT_COORDINATES } from '@smartcoop/utils/maps'
import Card from '@smartcoop/web-components/Card'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import Maps from '@smartcoop/web-components/Maps'
import Control from '@smartcoop/web-components/Maps/components/Control'
import PinMarker from '@smartcoop/web-components/Maps/markers/PinMarker'
import { Title } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container, MapContainer } from './styles'

const PropertyLocalizationScreen = ({ onConfirm, confirmedMap }) => {
  const geolocation = useGeolocation()
  const dispatch = useCallback(useDispatch(), [])
  const [center, setCenter] = useState([])

  const property = useSelector(selectOfflineProperty)

  const [markerCoordinate, setMarkerCoordinate] = useState(
    property?.data.geolocalization.latitude
      ? property?.data.geolocalization
      : undefined
  )

  const selectingGeo = useMemo(
    () => !confirmedMap,
    [confirmedMap]
  )

  const defaultZoom = useMemo(() => (markerCoordinate ? 17 : 6), [markerCoordinate])

  const mounted = useRef(true)

  const disabled = useMemo(
    () => isEmpty(markerCoordinate),
    [markerCoordinate]
  )

  const onDragendMarker = useCallback((event) => {
    if (selectingGeo) {
      setMarkerCoordinate({
        latitude: event.target._latlng.lat,
        longitude: event.target._latlng.lng
      })
    }
  }, [selectingGeo])

  const savePropertyLocation = useCallback(
    () => {
      if (!disabled) {

        dispatch(PropertyActions.updateOfflineProperty({
          data: {
            geolocalization: markerCoordinate
          }
        }))

        onConfirm()
      }
    },
    [disabled, dispatch, markerCoordinate, onConfirm]
  )

  useEffect(() => {
    if (!isEmpty(markerCoordinate)) {
      setCenter(markerCoordinate)
    }

    return setCenter(map(DEFAULT_COORDINATES, item => item))
  },[markerCoordinate])

  useEffect(
    () => {
      if (!mounted.current) {
        setMarkerCoordinate(center)
      }
    },
    [center, mounted]
  )
  useEffect(() => {
    if(markerCoordinate) {
      onConfirm()
      savePropertyLocation()
    }
  },[markerCoordinate, onConfirm, savePropertyLocation])

  useEffect(() => () => {
    mounted.current = false
  }, [mounted])

  const handleRecenter = useCallback(() => {
    if (
      !geolocation.error
    ) {
      setCenter({ latitude: geolocation.latitude, longitude: geolocation.longitude })
      setMarkerCoordinate({ latitude: geolocation.latitude, longitude: geolocation.longitude })
    }
    setCenter(DEFAULT_COORDINATES)
  },[geolocation])

  return (
    <Container>
      <MapContainer>

        <Maps
          region={ markerCoordinate || {} }
          zoom={ defaultZoom }
          layer="google"
          center={ center }
          onClick={ setMarkerCoordinate }
          cursor="crosshair"
        >
          <Control position="topleft">
            <IconButton style={ { zIndex: 999, backgroundColor: '#000', padding: 1.5, margin: 0 } } onClick={ handleRecenter }>
              <Icon icon={ compass } color="#fff" />
            </IconButton>
          </Control>
          <Control position="topcenter">
            <Card style={ { marginBottom: 0, marginTop: 0, marginRight: 70 } }>
              <Title align="center" style={ { padding: '0 40px', marginTop: 0, fontSize: 16 } }>
                <I18n>mark your property on the map</I18n>
              </Title>
            </Card>
          </Control>
          <PinMarker
            coordinate={ markerCoordinate }
            draggable={ selectingGeo }
            onDragend={ onDragendMarker }
            customIcon={ selectingGeo ? markerIcon : markerClosedIcon }
          />
        </Maps>
      </MapContainer>
    </Container>
  )
}

PropertyLocalizationScreen.propTypes = {
  onConfirm: PropTypes.func,
  confirmedMap: PropTypes.bool
}

PropertyLocalizationScreen.defaultProps = {
  onConfirm: () => {},
  confirmedMap: false
}

export default PropertyLocalizationScreen
