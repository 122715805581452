import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

/* Initial State */
const INITIAL_STATE = {
  challenges: [],
  productivityChallengeFormData: [],
  error: null,
  projectTypes: []
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  updateOfflineChallenge: ['challenge', 'onSuccess', 'onError'],
  saveOfflineChallenge: ['challenge', 'onSuccess', 'onError'],
  saveProductivityChallenge: ['challenge', 'onSuccess', 'onError'],
  saveProductivityChallengeSuccess: ['onSuccess', 'onError'],

  loadProjectTypesSuccess: ['projectTypes', 'page'],
  loadProjectTypes: ['params', 'onSuccess', 'onError'],

  loadChallengesSuccess: ['challenges', 'page'],
  loadChallenges: ['params', 'onSuccess', 'onError'],
  loadChallengeTerm: ['fieldId', 'projectSlug', 'onSuccess', 'onError'],
  loadChallengeTermByField: ['fieldId', 'onSuccess', 'onError'],

  loadProductivityChallengeDataSuccess: ['formData', 'onSuccess', 'onError'],
  loadProductivityChallengeData: ['params', 'page', 'onSuccess'],

  deleteOfflineChallenge: ['params', 'onSuccess', 'onError'],
  deleteProductivityChallenge: ['params', 'onSuccess', 'onError'],

  acceptProductivityTerm: ['productivityChallengeId', 'termId', 'onSuccess', 'onError'],
  acceptOfflineProductivityTerm: ['productivityChallengeId', 'termId', 'onSuccess', 'onError'],

  challengesError: ['error']
})

markActionsOffline(Creators, [
  'saveProductivityChallenge',
  'acceptProductivityTerm',
  'deleteProductivityChallenge'
])

const loadChallengesSuccess = (state = INITIAL_STATE, { challenges, page }) => ({
  ...state,
  challenges: page === 1 ? challenges : [
    ...state.challenges,
    ...challenges
  ]
})

const loadProjectTypesSuccess = (state = INITIAL_STATE, { projectTypes }) => ({
  ...state,
  projectTypes
})

const loadProductivityChallengeData = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error
})

const loadProductivityChallengeDataSuccess = (state = INITIAL_STATE, { formData }) => ({
  ...state,
  productivityChallengeFormData: formData
})

const challengesError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })
const resetAuthentication = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_CHALLENGES_SUCCESS]: loadChallengesSuccess,

  [Types.LOAD_PRODUCTIVITY_CHALLENGE_DATA]: loadProductivityChallengeData,
  [Types.LOAD_PRODUCTIVITY_CHALLENGE_DATA_SUCCESS]: loadProductivityChallengeDataSuccess,

  [Types.LOAD_PROJECT_TYPES_SUCCESS]: loadProjectTypesSuccess,

  [Types.CHALLENGES_ERROR]: challengesError,

  [AuthenticationTypes.LOGOUT]: logout,
  [AuthenticationTypes.RESET_AUTHENTICATION]: resetAuthentication
})

export {
  Types as ChallengesTypes,
  Creators as ChallengesActions
}
