import React, { useCallback, forwardRef } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import filterMilkControlSchema from '@smartcoop/forms/schemas/dairyFarm/filterMilkControl.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getLots as getLotsService } from '@smartcoop/services/apis/smartcoopApi/resources/lot'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDateRange from '@smartcoop/web-components/InputDateRange'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import { Container, ButtonContainer } from './styles'

const FilterMilkControlForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()
  const currentProperty = useSelector(selectCurrentProperty)

  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterMilkControlSchema }
        onSubmit={ handleSubmit }
      >
        <InputDateRange
          label={ t('date', { howMany: 1 }) }
          name="date"
          fullWidth
          defaultValue={ filters.date }
        />
        <InputText
          label={ t('earring') }
          name="earring"
          defaultValue={ filters?.earring }
        />
        <InputText
          label={ t('number of lactations') }
          name="lactationNumber"
          defaultValue={ filters?.lactationNumber }
        />
        <InputSelect
          label={ t('lot', { howMany: 1 }) }
          name="lotId"
          options={ getLotsService }
          urlParams={ { propertyId: currentProperty?.id } }
          style={ { paddingBottom: 10 } }
          defaultValue={ filters?.lotId }
          clearable="true"
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-order-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterMilkControlForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterMilkControlForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterMilkControlForm
