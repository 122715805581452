import api from '../api'

export const getAllAnimalDrying = (params, { propertyId, animalId }) =>
  api.get(`/v1/herds-management/property/${ propertyId }/drying/animal/${ animalId }`, { params })

export const createAnimalDrying = (params) =>
  api.post('/v1/herds-management/drying', params)

export const editAnimalDrying = (params, { dryingId, propertyId }) =>
  api.patch(`/v1/herds-management/property/${ propertyId }/drying/${ dryingId }`, params)

export const deleteAnimalDrying = ({ dryingId, propertyId }) =>
  api.delete(`/v1/herds-management/property/${ propertyId }/drying/${ dryingId }`)
