import React, { useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'

import { checked as checkedIcon, unchecked as uncheckedIcon } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import Icon from '@smartcoop/web-components/Icon'


import {
  RadioTouchableContainer,
  Container,
  IconContainer,
  LabelContainer,
  Label
} from './styles'

const RadioButton = (props) => {
  const { checked, label, value, onChange, style } = props

  const onToggle = useCallback(
    () => {
      onChange({ value })
    },
    [onChange, value]
  )

  const selectIcon = useMemo(
    () => (
      checked ? checkedIcon : uncheckedIcon
    ),
    [checked]
  )

  const checkedStyle = useMemo(
    () => (
      checked ? { backgroundColor: colors.secondary } : { backgroundColor: colors.backgroundHtml }
    ),
    [checked]
  )

  return (
    <Container style={ { ...checkedStyle, ...style } }>
      <RadioTouchableContainer onClick={ onToggle }>
        <IconContainer>
          <Icon icon={ selectIcon } size={ 12 }/>
        </IconContainer>

        <LabelContainer>
          <Label>{ label }</Label>
        </LabelContainer>
      </RadioTouchableContainer>
    </Container>
  )
}

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string
  ]).isRequired,
  onChange: PropTypes.func,
  style: PropTypes.object
}

RadioButton.defaultProps = {
  style: {},
  onChange: () => {}
}
export default RadioButton
