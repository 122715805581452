import React, { useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import Icon from '@smartcoop/web-components/Icon'

import { Container, Row, ItemRow, Divisor, Label, Description, ItemColumn } from './styles'

const ItemSelection = (props) => {
  const { selectedIconColor, unselectedIconColor, options, title, unselectedIcon, selectedIcon, onChange, selected, filterFunction, showSelected } = props

  const items = useMemo(
    () => {
      if (isEmpty(selected)) return options

      return filter(options, (option) => (filterFunction(option, selected)))
    },
    [filterFunction, options, selected]
  )

  const handleItemChange = useCallback(
    (item) => {
      onChange(item)
    },
    [onChange]
  )
  return (
    <Container>
      {(!isEmpty(selected) && showSelected) && (
        <>
          <Row>
            {selectedIcon &&
              <Icon style={ { paddingRight: 5 } } icon={ selectedIcon } size={ 25 } color={ selectedIconColor } />
            }
            <Label>{selected.label}</Label>
          </Row>
          {selected?.description && (
            <Description>{selected?.description}</Description>
          )}

          <Divisor/>
        </>
      )}

      {title && (
        <Row style={ { paddingBottom: 7 } }>
          <Label>{ title }</Label>
        </Row>
      )}
      {
        map(items, (item) => item?.description ? (
          <ItemColumn key={ `${ item?.value }-${ item?.label }-column` }>
            <ItemRow key={ `${ item?.value }-${ item?.label }-row` } onClick={ () => handleItemChange(item) }>
              {unselectedIcon &&
              <Icon style={ { paddingRight: 5 } } icon={ unselectedIcon } size={ 25 } color={ unselectedIconColor } />
              }
              <Label>{item?.label}</Label>

            </ItemRow>
            {item?.description && (
              <Description>{item?.description}</Description>
            )}
          </ItemColumn>
        ) : (
          <ItemRow margin key={ `${ item?.value }-${ item?.label }-row` } onClick={ () => handleItemChange(item) }>
            {unselectedIcon &&
              <Icon style={ { paddingRight: 5 } } icon={ unselectedIcon } size={ 25 } color={ unselectedIconColor } />
            }
            <Label>{item?.label}</Label>

          </ItemRow>
        ))
      }
    </Container>
  )
}

ItemSelection.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  title: PropTypes.string,
  filterFunction: PropTypes.func,
  unselectedIcon: PropTypes.func,
  selectedIcon: PropTypes.func,
  selected: PropTypes.object,
  selectedIconColor: PropTypes.string,
  unselectedIconColor: PropTypes.string,
  showSelected: PropTypes.bool
}

ItemSelection.defaultProps = {
  onChange: (param) => (param),
  title: null,
  unselectedIcon: null,
  filterFunction: (option, selected) => option.value !== selected.value,
  selectedIcon: null,
  selected: {},
  selectedIconColor: undefined,
  unselectedIconColor: undefined,
  showSelected: true
}

export default ItemSelection
