import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { comment, organizationRounded } from '@smartcoop/icons'
import { momentBackDateTimeFormat, momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import Avatar from '@smartcoop/web-components/Avatar'
import Icon from '@smartcoop/web-components/Icon'
import ReadMore from '@smartcoop/web-components/ReadMore'

import {
  Body,
  Col,
  ColFooter1,
  ColFooter2,
  Container,
  FeedButtonCounters,
  FlexDiv,
  Footer,
  Header,
  Identifier,
  Name,
  Privacy,
  Text,
  Timer,
  Type,
  CustomIconButton } from './styles'

const ProductWallFeed = (props) =>  {
  const { style, feed: { createdAt, text, user, id, totalComments, organization }, length, commentClick } = props
  const t = useT()
  const history = useHistory()

  const time = useMemo( () => moment(createdAt, momentBackDateTimeFormat).format(momentFriendlyDateTimeFormat), [createdAt])

  return (
    <Container style={ style } >
      <Header>
        <Identifier>
          <Avatar alt={ user.name } src={ user.image } />
          <Col>
            <Name>{user.name}</Name>
            <Privacy>
              <Icon icon={ organizationRounded } />
              <Type>{organization.tradeName}</Type>
            </Privacy>
          </Col>
        </Identifier>
        <Timer>{time}</Timer>
      </Header>
      <Body>
        <Text>
          <ReadMore length={ length }>
            { text }
          </ReadMore>
        </Text>
      </Body>
      <Footer>
        <ColFooter1 style={ { marginRight: '0px' } } />
        <ColFooter2>
          <div style={ { display: 'flex' } }>
            <CustomIconButton tooltip={ t('social comments') } onClick={ () => {
              commentClick && history.push(`/product-wall/comments/${ id }`, { feed: props.feed } )} } marginHorizontal={ false }
            >
              <FlexDiv>
                <Icon icon={ comment } />
                <FeedButtonCounters>{totalComments}</FeedButtonCounters>
              </FlexDiv>
            </CustomIconButton>
          </div>
        </ColFooter2>
      </Footer>
    </Container>
  )
}

ProductWallFeed.propTypes = {
  style: PropTypes.object,
  feed: PropTypes.shape({
    organization: PropTypes.arrayOf(PropTypes.shape({
      companyName: PropTypes.string,
      id: PropTypes.string,
      tradeName: PropTypes.string
    })),
    id: PropTypes.string,
    text: PropTypes.string,
    userId: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    totalComments: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      image: PropTypes.string
    })
  }).isRequired,
  length: PropTypes.number,
  commentClick: PropTypes.bool
}

ProductWallFeed.defaultProps = {
  style: {},
  length: 200,
  commentClick: false
}

export default ProductWallFeed
