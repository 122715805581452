import api from '../api'

export const getCostsManagementGroups = async (params) =>
  api.get('/v1/costs-management/groups', { params })


export const getCostsManagementItems = async (params) =>
  api.get('/v1/costs-management/items', { params })


export const createCostsManagementItem = async (params) =>
  api.post('/v1/costs-management/items', params)

export const editCostsManagementItem = async (params, { rubricId }) =>
  api.patch(`/v1/costs-management/items/${ rubricId }`, params )

export const deleteCostsManagementItem = async (params, { rubricId }) =>
  api.delete(`/v1/costs-management/items/${ rubricId }`, params )
