import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'

import isEmpty from 'lodash/isEmpty'

import { AVAILABLE_MODULES } from '@smartcoop/stores/module'
import { selectCurrentModule } from '@smartcoop/stores/module/selectorModule'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import AdministrationScreenRouter from './administration/AdministrationScreenRouter'
import CommercializationScreenRouter from './commercialization/CommercializationScreenRouter'
import DigitalPropertyScreenRouter from './digitalProperty/DigitalPropertyScreenRouter'
import ModuleScreenRouter from './moduleScreen/ModuleScreenRouter'
import ShoppingPlatformScreenRouter from './shoppingPlatform/ShoppingPlatformScreenRouter'
import TechnicalScreenRouter from './technical/TechnicalScreenRouter'

const AuthenticatedScreenRouter = (props) => {
  const { routes } = useRoutes()
  const currentModule = useSelector(selectCurrentModule)

  const Router = useMemo(
    () => {
      switch (currentModule) {
        case AVAILABLE_MODULES.digitalProperty:
          return DigitalPropertyScreenRouter
        case AVAILABLE_MODULES.commercialization:
          return CommercializationScreenRouter
        case AVAILABLE_MODULES.shoppingPlatform:
          return ShoppingPlatformScreenRouter
        case AVAILABLE_MODULES.administration:
          return AdministrationScreenRouter
        case AVAILABLE_MODULES.technical:
          return TechnicalScreenRouter
        default:
          return ModuleScreenRouter
      }
    },
    [currentModule]
  )

  return !isEmpty(routes) && <Router { ...props } />
}

export default memo(AuthenticatedScreenRouter)
