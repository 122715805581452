import React, { useCallback, forwardRef, useEffect, useState } from 'react'

import trimMask from '@meta-awesome/functions/src/trimMask'
import PropTypes from 'prop-types'

import { map } from 'lodash'

import Grid from '@material-ui/core/Grid'

import addressSchema from '@smartcoop/forms/schemas/profile/addressEdit.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { searchCep } from '@smartcoop/services/apis/brasilApi'
import { searchStates, searchCities } from '@smartcoop/services/apis/ibgeApi'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputCep from '@smartcoop/web-components/InputCep'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import { Container, FormContainer, ButtonsContainer, ButtonContainer, Item } from './styles'

const EditAddressForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel
  } = props

  const t = useT()

  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({ ...data, district: data.neighborhood, postalCode: trimMask(data?.postalCode) })
    },
    [onSubmit]
  )

  const receiveAddress = useCallback(({ ...address }) => {
    formRef.current.setData({
      ...address
    })
  }, [formRef])

  const handleStateChange = useCallback(
    async (value) => {
      if (value) {
        let data = await searchCities(value)
        data = map(data, ({ nome }) => ({ label: nome, value: nome }))
        setCityOptions(data)

      } else {
        setCityOptions([])
        formRef.current.clearField('city')
      }
    },
    [formRef]
  )

  const handleEdit = useCallback(
    async (value) => {
      await handleStateChange(value.state)
      const address = await searchCep(value.postalCode)
      formRef.current && formRef.current.setData({
        ...{ city: address.city }
      })
    }, [formRef, handleStateChange]
  )


  useEffect(() => {
    async function findStates() {
      let data = await searchStates()
      data = map(data, ({ sigla }) => ({ label: sigla, value: sigla }))
      setStateOptions(data)
    }
    findStates()
  }, [])


  useEffect(() => {
    if (defaultValues.state) {
      handleEdit(defaultValues)

    } else {
      setCityOptions([])
      formRef.current.clearField('city')
    }
  }, [defaultValues, formRef, handleEdit])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ addressSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>

          <InputCep
            name="postalCode"
            label={ t('cep') }
            fullWidth
            onAddressChange={ receiveAddress }
            defaultValue={ defaultValues.postalCode }
          />

          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputSelect
                label={ t('uf') }
                name="state"
                onChange={ (e) => handleStateChange(e.target.value) }
                options={ stateOptions }
                fullWidth
                defaultValue={ defaultValues.state }
                style={
                  {
                    width: '100%'
                  }
                }
              />
            </Item>
            <Item>
              <InputSelect
                label={ t('city', { howMany: 1 }) }
                name="city"
                options={ cityOptions }
                fullWidth
                defaultValue={ defaultValues.city }
              />
            </Item>
          </Grid>

          <InputText
            name="neighborhood"
            label={ t('neighborhood') }
            fullWidth
            defaultValue={ defaultValues.district }
          />

          <InputText
            name="street"
            label={ t('street') }
            fullWidth
            defaultValue={ defaultValues.street }
          />

          <InputText
            name="number"
            label={ t('number') }
            fullWidth
            defaultValue={ defaultValues.number }
          />

        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <ButtonContainer>
              <Button
                id="web-cancel-form-button"
                onClick={ onCancel }
                color={ colors.white }
                disabled={ loading }
              >
                <I18n>cancel</I18n>
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button
                id="web-save-form-button"
                onClick={ () => formRef.current.submit() }
                disabled={ loading }
              >
                <I18n>save</I18n>
              </Button>
            </ButtonContainer>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

EditAddressForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  defaultValues: PropTypes.object.isRequired
}

EditAddressForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false
}

export default EditAddressForm
