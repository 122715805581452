import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`
export const Image = styled.img`
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
`

export const IconButtonContainer = styled.div`
  position: absolute;
  right: -12px;
  bottom: -5px;
  border-radius: 50%;
  background: ${ colors.yellow };
`
