import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Text = styled.span`
  color: ${ colors.white };
`

export const PaletteItem = styled.div`
  background-color: ${ ({ color }) => color };
  flex: 1;
  height: 10px;
`