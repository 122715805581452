
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { registerProductPriceHistory, getProductsPriceHistoryByOrganization, getProductsPriceHistory, deleteProductsPriceHistory } from '@smartcoop/services/apis/smartcoopApi/resources/productPriceHistory'
import { selectCurrentOrderProduct } from '@smartcoop/stores/order/selectorOrder'


import { selectCurrentOrganization } from '../organization/selectorOrganization'
import { ProductsPriceHistoryActions, ProductsPriceHistoryTypes } from './duckProductsPriceHistory'


function* saveProductsPriceHistory( { productsPriceHistory } ) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)
    const product = yield select(selectCurrentOrderProduct)
    yield call(
      registerProductPriceHistory,
      productsPriceHistory.productsPriceHistory,
      { productId: productsPriceHistory.productId || product.id, organizationId: currentOrganization.id }
    )

  } catch (err) {
    const error = err.message
    yield put(ProductsPriceHistoryActions.productsPriceHistoryError(error))
  }
}

function* loadProductsPriceHistoryByOrganization({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)
    const product = yield select(selectCurrentOrderProduct)

    const data = yield call(getProductsPriceHistoryByOrganization, params, { productId: product.id, organizationId: currentOrganization.id })

    yield put(ProductsPriceHistoryActions.loadProductsPriceHistoryByOrganizationSuccess(
      data,
      onSuccess
    ))
  } catch (err) {
    const error = err.message
    yield put(ProductsPriceHistoryActions.productsPriceHistoryError(error))
    yield call(onError, error)
  }
}

function* loadProductsPriceHistory({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const product = yield select(selectCurrentOrderProduct)

    const data = yield call(getProductsPriceHistory, params, { productId: product.id })

    yield put(ProductsPriceHistoryActions.loadProductsPriceHistorySuccess(
      data,
      onSuccess
    ))
  } catch (err) {
    const error = err.message
    yield put(ProductsPriceHistoryActions.productsPriceHistoryError(error))
    yield call(onError, error)
  }
}

function* deleteProductPriceHistory({ historyId, productId, organizationId, onSuccess = () => {}, onError = () => {} }) {
  try {

    yield call(deleteProductsPriceHistory, { historyId, productId, organizationId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(ProductsPriceHistoryActions.productsPriceHistoryError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(ProductsPriceHistoryTypes.SAVE_PRODUCTS_PRICE_HISTORY, saveProductsPriceHistory),
  takeLatest(ProductsPriceHistoryTypes.LOAD_PRODUCTS_PRICE_HISTORY_BY_ORGANIZATION, loadProductsPriceHistoryByOrganization),
  takeLatest(ProductsPriceHistoryTypes.LOAD_PRODUCTS_PRICE_HISTORY, loadProductsPriceHistory),
  takeLatest(ProductsPriceHistoryTypes.DELETE_PRODUCT_PRICE_HISTORY, deleteProductPriceHistory)
]
