// eslint-disable-next-line spaced-comment
export default ({ size } = {}) => /*html*/`
<svg version="1.1" id="Camada_1" width="${ size }" height="${ size }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1080 1080" style="enable-background:new 0 0 1080 1080;" xml:space="preserve" fill="#000">
<g>
	<path d="M539.21,811.91c-32.31,0-64.63,0.66-96.9-0.51c-7.11-0.26-16.32-5.24-20.61-10.99c-32.84-44.04-64.44-89-96.97-133.28
		c-22.58-30.73-48.26-59.41-68.71-91.46c-71.21-111.58-74.27-228-10.06-342.14C311.02,117.89,414.25,58.99,547.4,61.7
		c130.42,2.65,229.72,64.26,290.63,178.98c60.12,113.23,56.05,227.99-15.15,336.22c-29.34,44.6-65.81,84.45-97.83,127.36
		c-22.78,30.52-43,62.95-65.64,93.59c-4.98,6.74-15.21,13.04-23.32,13.42C603.86,812.8,571.52,811.91,539.21,811.91z M561.42,605.38
		c-10.92,32.74-20.18,59.55-28.77,86.58c-5.58,17.56-0.91,32.48,15.73,41.28c17.45,9.23,31.35,1.81,41.76-12.86
		c4.6-6.49,9.6-13.97,10.63-21.51c4.8-35.31,13.28-71.1,11.16-106.16c-4.02-66.53-32.9-125.77-67.43-181.92
		c-12.46-20.26-26.16-39.76-39.29-59.6c77.94,43.7,120.16,116.89,163.86,192.67c8.96-20.64,18.28-41.52,20.34-63.09
		c5.86-61.21-21.12-109.93-65.71-149.22c-53.16-46.83-117.09-70.05-187.22-82.99c-7.99,24.51-15.89,46.54-22.37,68.99
		c-19.05,66.04-32.17,132.69-19.79,201.95C408.39,598.13,467.62,651.11,561.42,605.38z"/>
	<path d="M649.33,887.73c12.32,60.77-6.99,94.23-61.59,112.15c-4.99,1.64-10.04,7.31-12.27,12.35
		c-6.95,15.69-17.95,24.88-35.24,24.96c-17.28,0.09-27.89-9.3-35.8-24.42c-3.3-6.31-11.24-12.2-18.24-14.23
		c-43.83-12.66-70.49-57.58-58.2-101.54c1.14-4.08,9.15-8.85,14-8.89C510.78,887.55,579.57,887.73,649.33,887.73z"/>
	<path d="M257.84,718.5c-0.42,21.01-17.62,36.94-38.91,36.04c-19.95-0.85-35.78-17.68-35.33-37.55
		c0.45-19.76,17.19-36.34,36.87-36.5C241.16,680.32,258.25,697.71,257.84,718.5z"/>
	<path d="M896.28,720.06c-1.38,20.7-19.56,36.12-40.6,34.41c-20.06-1.63-34.7-18.67-33.52-39.01c1.15-19.75,18.52-35.67,38.14-34.95
		C880.85,681.27,897.63,699.71,896.28,720.06z"/>
	<path d="M221.23,42.8c21.19,0.24,37.46,17.23,36.66,38.26c-0.77,20.17-17.04,35.6-37.33,35.41c-20.95-0.2-37.7-17.6-36.83-38.28
		C184.56,58.25,201.17,42.57,221.23,42.8z"/>
	<path d="M88.7,361.67c19.87-0.08,36.52,16.05,37.09,35.94c0.59,20.52-16.8,38.55-37.11,38.47c-19.53-0.08-36.62-16.7-37.27-36.27
		C50.72,379.35,67.92,361.76,88.7,361.67z"/>
	<path d="M1028.6,397.82c0.49,20.32-17.17,38.34-37.5,38.26c-19.54-0.08-36.38-16.74-36.87-36.48c-0.49-20,15.14-36.89,35.05-37.89
		C1009.96,360.68,1028.1,377.35,1028.6,397.82z"/>
	<path d="M896.3,78.63c0.57,19.89-15,36.64-35.11,37.75c-21.15,1.18-38.5-14.71-39.07-35.75c-0.55-20.31,14.52-36.6,34.88-37.7
		C878.05,41.79,895.7,57.82,896.3,78.63z"/>
</g>
</svg>`
