// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
      <g>
        <path fill="none" d="M7.1,135.1c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.4,0.2c-0.1-0.1-0.3-0.1-0.4-0.2
          C7.3,135.3,7.2,135.2,7.1,135.1z"/>
        <path fill="none" d="M5.6,133.9c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1-0.1-0.2-0.2-0.3-0.3
          C5.8,134.2,5.7,134.1,5.6,133.9z"/>
        <path fill="none" d="M6.3,134.6c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.4,0.3c-0.1-0.1-0.2-0.2-0.4-0.3
          C6.6,134.8,6.4,134.7,6.3,134.6z"/>
        <path fill="none" d="M11.4,136.9c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.3-0.1-0.4-0.1c0.1,0,0.3,0.1,0.4,0.1
          c0.2,0,0.3,0.1,0.5,0.1C11.1,136.8,11.3,136.9,11.4,136.9c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0.1c-0.1,0-0.3,0-0.4-0.1
          C11.8,137,11.6,136.9,11.4,136.9z"/>
        <path fill="none" d="M147,79C147,79,147,79,147,79c0,0,0.1,0,0.1,0C147.1,79,147,79,147,79z"/>
        <path fill="none" d="M12.4,137c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0c-0.1,0-0.3,0-0.4,0C12.7,137.1,12.5,137.1,12.4,137z"/>
        <path fill="none" d="M147,79C147,79,147,79,147,79C147,79,147,79,147,79L147,79z"/>
        <path fill="none" d="M13.6,137.1c0.2,0,0.4,0,0.6,0c-0.3,0-0.6,0-0.9,0C13.4,137.1,13.5,137.1,13.6,137.1z"/>
        <path fill="#DEDDDD" d="M45.4,84.4C45.4,84.4,45.3,84.4,45.4,84.4C45.3,84.4,45.4,84.4,45.4,84.4C45.4,84.4,45.4,84.4,45.4,84.4z"
          />
        <path fill="#DEDDDD" d="M126.9,164.1c5.1-3,9.8-6.4,13.9-10.3c-16.8,4.3-35.5,0.1-51.7-10.7H27.6
          C50.1,174.1,92.9,183.7,126.9,164.1z"/>
        <path fill="#DEDDDD" d="M14.9,106.8c0.1-1,0.2-1.9,0.3-2.9c0.2-1,0.4-1.9,0.6-2.9c0.2-0.7,0.4-1.4,0.7-2.3l0-0.1
          c0-0.1,0.1-0.2,0.1-0.3c0.3-0.7,0.5-1.4,0.8-2.1c0-0.1,0.1-0.2,0.2-0.3l0-0.1c0.3-0.7,0.7-1.4,1-2c0-0.1,0.1-0.1,0.1-0.2
          c0.3-0.6,0.7-1.2,1.1-1.8l0-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.4-0.6,0.8-1.2,1.3-1.7c0.1-0.1,0.2-0.2,0.3-0.3c0.5-0.6,1-1.1,1.4-1.6
          c0.1-0.1,0.1-0.1,0.2-0.2c0.5-0.5,0.9-0.9,1.5-1.4c0.1-0.1,0.1-0.1,0.2-0.2c0.5-0.5,1.1-0.9,1.6-1.3c0.1-0.1,0.2-0.2,0.4-0.3
          c0.6-0.5,1.2-0.8,1.7-1.2c0.1-0.1,0.2-0.1,0.3-0.2c0.6-0.4,1.2-0.7,1.8-1c0.1,0,0.2-0.1,0.3-0.1c0.6-0.3,1.2-0.6,1.9-0.9
          c0.1-0.1,0.3-0.1,0.4-0.2c0.7-0.3,1.3-0.5,2-0.8c0.1,0,0.2-0.1,0.3-0.1c0.7-0.2,1.3-0.4,1.9-0.6c0.1,0,0.2,0,0.3-0.1
          c0.7-0.2,1.4-0.3,2.2-0.4l0.1,0c0.1,0,0.2,0,0.4-0.1c0.7-0.1,1.4-0.2,2.1-0.3c0.1,0,0.2,0,0.3,0l0.1,0c0.7-0.1,1.4-0.1,2.1-0.1
          c0.1,0,0.1,0,0.2,0c0.6,0,1.1,0,1.7,0.1c0.1,0,0.3,0,0.4,0l0.1,0c0.1,0,0.3,0,0.4,0c0.6-2,1.3-3.9,2.1-5.8
          c-0.5-18.2,5.3-35.2,16.7-47c-5.4,1.7-10.8,3.9-15.9,6.9c-27.2,15.7-41,45.6-37.4,74.9c0.4-0.1,0.9-0.2,1.4-0.4
          C14.9,107,14.9,106.9,14.9,106.8z"/>
        <path fill="#D3D3D3" d="M175.9,104.9c-0.2-1.5-0.4-2.9-0.8-4.4c-0.2-0.7-0.4-1.4-0.7-2.2c0-0.1-0.1-0.3-0.1-0.4
          c-0.2-0.7-0.5-1.3-0.8-2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.3-0.7-0.6-1.3-1-1.9c0,0,0-0.1-0.1-0.1c-0.3-0.6-0.7-1.2-1.1-1.8
          c-0.1-0.1-0.1-0.2-0.2-0.3c-0.4-0.6-0.8-1.1-1.2-1.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.5-0.9-1.1-1.4-1.6c0,0-0.1-0.1-0.1-0.1
          c-0.5-0.5-0.9-0.9-1.4-1.4c-0.1-0.1-0.2-0.1-0.2-0.2c-0.5-0.5-1-0.9-1.6-1.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.5-0.4-1.1-0.8-1.7-1.2
          c-0.1,0-0.1-0.1-0.2-0.1c-0.6-0.4-1.1-0.7-1.7-1c-0.1,0-0.2-0.1-0.2-0.1c-0.6-0.3-1.2-0.6-1.8-0.9c-0.1-0.1-0.2-0.1-0.4-0.2
          c-0.6-0.3-1.3-0.5-1.9-0.7c-0.1,0-0.2-0.1-0.3-0.1c-0.6-0.2-1.3-0.4-1.9-0.6c-0.1,0-0.1,0-0.2-0.1c-0.7-0.2-1.3-0.3-2-0.4
          c-0.1,0-0.3,0-0.4-0.1c-0.7-0.1-1.4-0.2-2-0.3c-0.1,0-0.2,0-0.3,0c-0.7-0.1-1.4-0.1-2.1-0.1c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
          c-0.5,0-1.1,0-1.6,0.1c-0.2,0-0.3,0-0.5,0c-0.4,0-0.8,0.1-1.2,0.1c-0.2,0-0.4,0-0.6,0.1c-0.4,0.1-0.8,0.1-1.2,0.2
          c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.1-1.1,0.2-1.7,0.4c0,0-1.1,0.3-3.1,0.8c-4.9-18.3-21.6-31.8-41.5-31.8c-2.6,0-5.2,0.2-7.6,0.7
          c-1.4,0.3-2.8,0.6-4.2,1c-1.8,0.5-3.5,1.1-5.1,1.8c-13.4,5.8-23.3,18.2-25.5,33.1c-0.2,0-0.3-0.1-0.5-0.1s-0.3-0.1-0.4-0.1
          c-0.1,0-0.2,0-0.2-0.1c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.5-0.1-0.9-0.2-1.4-0.3c-0.1,0-0.3-0.1-0.4-0.1c-0.3-0.1-0.7-0.1-1-0.2
          c-0.2,0-0.3,0-0.5-0.1c-0.3,0-0.7-0.1-1-0.1c-0.1,0-0.3,0-0.4,0c-0.5,0-0.9,0-1.4,0c0,0-0.1,0-0.1,0c-0.6,0-1.2,0-1.8,0.1
          c-0.1,0-0.2,0-0.3,0c-0.6,0.1-1.2,0.1-1.7,0.2c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0c-0.6,0.1-1.1,0.2-1.7,0.4c-0.1,0-0.1,0-0.2,0
          c-0.5,0.1-1.1,0.3-1.6,0.5c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.2-1.1,0.4-1.6,0.6c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.2-1,0.5-1.5,0.7
          c-0.1,0-0.1,0.1-0.2,0.1c-0.5,0.3-0.9,0.5-1.4,0.8c-0.1,0-0.1,0.1-0.2,0.1c-0.5,0.3-0.9,0.6-1.4,1c-0.1,0.1-0.2,0.1-0.3,0.2
          c-0.5,0.3-0.9,0.7-1.3,1.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4,0.4-0.8,0.8-1.2,1.2c0,0-0.1,0.1-0.1,0.1c-0.4,0.4-0.8,0.9-1.2,1.3
          c-0.1,0.1-0.2,0.2-0.2,0.3c-0.4,0.4-0.7,0.9-1,1.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.5-0.6,1-0.9,1.5c0,0,0,0.1-0.1,0.1
          c-0.3,0.5-0.6,1.1-0.8,1.6c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.5-0.5,1.1-0.7,1.7c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.6-0.4,1.2-0.6,1.8
          c-0.2,0.8-0.4,1.5-0.5,2.3s-0.2,1.5-0.3,2.3c-0.1,1.5-0.1,3,0.1,4.5c-2.6,0.7-4.8,1.3-6.5,1.7c-2.6,0.7-4,1.1-4,1.1
          c-6.1,1.6-9.8,7.9-8.1,14c0.1,0.3,0.2,0.6,0.3,1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.1,0.2,0.2,0.4
          c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.3,0.3,0.4
          c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3
          c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.2,0.4,0.2
          c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0,0.3,0.1,0.4,0.1
          c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0.1c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0
          c0.3,0,0.6,0,0.9,0h9.5h57.6h65c0,0,1.7-0.1,3.7-0.3c0.7-0.1,1.4-0.1,2-0.2c0.4-0.1,0.9-0.1,1.3-0.2c0.4-0.1,0.8-0.2,1.1-0.3
          c10.2-2.7,17.6-10.5,20.4-19.9c0.3-0.9,0.5-1.8,0.7-2.7c0.1-0.5,0.2-0.9,0.2-1.4c0.2-1.4,0.3-2.8,0.3-4.3
          C176.1,106.8,176,105.8,175.9,104.9z"/>
        <path fill="#FFFFFF" d="M175.9,104.9c0.1,1,0.2,1.9,0.2,2.9C176.1,106.8,176,105.8,175.9,104.9z"/>
        <path fill="#FFFFFF" d="M151.6,79.3c-0.7-0.1-1.4-0.2-2-0.3c-0.1,0-0.2,0-0.3,0c0.1,0,0.2,0,0.3,0
          C150.2,79.1,150.9,79.2,151.6,79.3z"/>
        <path fill="#FFFFFF" d="M154.5,136.1c-0.3,0.1-0.7,0.2-1.1,0.3C153.8,136.3,154.2,136.2,154.5,136.1z"/>
        <path fill="#FFFFFF" d="M152.1,136.5c-0.7,0.1-1.4,0.2-2,0.2C150.7,136.7,151.4,136.6,152.1,136.5z"/>
        <path fill="#FFFFFF" d="M146.3,137.1c0,0,1.7-0.1,3.7-0.3C148,137,146.3,137.1,146.3,137.1L146.3,137.1z"/>
        <path fill="#FFFFFF" d="M176.1,107.7c0,1.4-0.1,2.9-0.3,4.3C176,110.6,176.1,109.2,176.1,107.7z"/>
        <path fill="#FFFFFF" d="M175.6,113.4c-0.2,0.9-0.4,1.8-0.7,2.7C175.2,115.2,175.4,114.3,175.6,113.4z"/>
        <path fill="#FFFFFF" d="M164.7,85c0.5,0.4,1.1,0.8,1.6,1.3C165.8,85.8,165.2,85.4,164.7,85c-0.1-0.1-0.2-0.2-0.3-0.2
          c-0.5-0.4-1.1-0.8-1.7-1.2c0.6,0.4,1.1,0.8,1.7,1.2C164.5,84.8,164.6,84.9,164.7,85z"/>
        <path fill="#FFFFFF" d="M167.9,87.9c0,0,0.1,0.1,0.1,0.1c0.5,0.5,0.9,1,1.4,1.6c0.1,0.1,0.2,0.2,0.3,0.3c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.4-0.5-0.9-1.1-1.4-1.6C168,88,168,87.9,167.9,87.9c-0.5-0.5-0.9-0.9-1.4-1.4c-0.1-0.1-0.2-0.1-0.2-0.2
          c0.1,0.1,0.2,0.1,0.2,0.2C167,86.9,167.5,87.4,167.9,87.9z"/>
        <path fill="#FFFFFF" d="M171.1,91.8c-0.1-0.1-0.1-0.2-0.2-0.3c-0.4-0.6-0.8-1.1-1.2-1.7c0.4,0.5,0.8,1.1,1.2,1.7
          C171,91.6,171.1,91.7,171.1,91.8z"/>
        <path fill="#FFFFFF" d="M154,79.8c0.1,0,0.1,0,0.2,0.1C154.2,79.9,154.1,79.8,154,79.8c-0.7-0.2-1.3-0.3-2-0.4
          c-0.1,0-0.3,0-0.4-0.1c0.1,0,0.3,0,0.4,0.1C152.7,79.5,153.4,79.6,154,79.8z"/>
        <path fill="#FFFFFF" d="M162.7,83.6c-0.1,0-0.1-0.1-0.2-0.1c-0.6-0.4-1.1-0.7-1.7-1c0.6,0.3,1.1,0.6,1.7,1
          C162.6,83.5,162.6,83.5,162.7,83.6z"/>
        <path fill="#FFFFFF" d="M172.3,93.7c0.4,0.6,0.7,1.3,1,1.9C173,95,172.6,94.3,172.3,93.7C172.3,93.7,172.3,93.6,172.3,93.7
          c-0.4-0.7-0.8-1.3-1.2-1.9C171.5,92.4,171.9,93,172.3,93.7C172.3,93.6,172.3,93.7,172.3,93.7z"/>
        <path fill="#FFFFFF" d="M156.4,80.5c0.6,0.2,1.3,0.5,1.9,0.7c0.1,0.1,0.3,0.1,0.4,0.2c0.6,0.3,1.2,0.6,1.8,0.9
          c0.1,0,0.2,0.1,0.2,0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.6-0.3-1.2-0.6-1.8-0.9c-0.1-0.1-0.2-0.1-0.4-0.2
          C157.7,81,157.1,80.8,156.4,80.5c-0.1,0-0.2-0.1-0.3-0.1c-0.6-0.2-1.3-0.4-1.9-0.6c0.6,0.2,1.3,0.4,1.9,0.6
          C156.2,80.5,156.3,80.5,156.4,80.5z"/>
        <path fill="#FFFFFF" d="M52.2,85.4c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.2-0.1c0,0,0.1,0,0.2,0.1C51.9,85.3,52,85.3,52.2,85.4z"
          />
        <path fill="#FFFFFF" d="M174.3,98c0,0.1,0.1,0.2,0.1,0.4c0.2,0.7,0.5,1.4,0.7,2.2c0.4,1.5,0.7,2.9,0.8,4.4
          c-0.2-1.5-0.4-2.9-0.8-4.4c-0.2-0.7-0.4-1.4-0.7-2.2C174.3,98.2,174.3,98.1,174.3,98c-0.2-0.7-0.5-1.3-0.8-2
          c-0.1-0.1-0.1-0.2-0.2-0.4c0.1,0.1,0.1,0.2,0.2,0.4C173.7,96.6,174,97.3,174.3,98z"/>
        <path fill="#FFFFFF" d="M146.9,79c-0.5,0-1.1,0-1.6,0.1c-0.2,0-0.3,0-0.5,0c0.2,0,0.3,0,0.5,0C145.9,79,146.4,79,146.9,79z"/>
        <path fill="#FFFFFF" d="M27.6,92.1c-0.4,0.4-0.8,0.9-1.2,1.3c-0.1,0.1-0.2,0.2-0.2,0.3c0.1-0.1,0.2-0.2,0.2-0.3
          C26.8,92.9,27.2,92.5,27.6,92.1z"/>
        <path fill="#FFFFFF" d="M49.7,84.8c0.1,0,0.3,0,0.4,0.1C50,84.8,49.8,84.8,49.7,84.8c-0.3-0.1-0.7-0.1-1-0.2c-0.2,0-0.3,0-0.5-0.1
          c0.2,0,0.3,0,0.5,0.1C49,84.7,49.4,84.7,49.7,84.8z"/>
        <path fill="#FFFFFF" d="M23.1,98.8c-0.2,0.5-0.5,1.1-0.7,1.7c0,0.1-0.1,0.2-0.1,0.3c0-0.1,0.1-0.2,0.1-0.3
          C22.6,99.9,22.8,99.3,23.1,98.8z"/>
        <path fill="#FFFFFF" d="M21.2,104.9c0.1-0.8,0.3-1.5,0.5-2.3C21.5,103.3,21.3,104.1,21.2,104.9z"/>
        <path fill="#D3D3D3" d="M197.4,81.1c-2.4-8.8-11.3-14-20.1-11.7c0,0-0.6,0.2-1.8,0.5c-2.8-10.4-12.3-18.1-23.6-18.1
          c-7.5,0-14.1,3.4-18.6,8.7c3.2,3.7,5.8,7.9,7.9,12.4c1.6-0.2,3.3-0.3,5-0.3c16.9,0,31.8,11.4,36.2,27.8c0.1,0.5,0.2,1,0.3,1.5
          c1-0.1,2.2-0.2,3-0.4C194.6,99,199.8,89.9,197.4,81.1z"/>
      </g>
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
