import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { PropertyActions } from '@smartcoop/stores/property'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'
import Stepper from '@smartcoop/web-components/Stepper'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SuccessModal from '@smartcoop/web-containers/modals/SuccessModal'
import PropertyActivityDetailsScreen from '@smartcoop/web-containers/screens/authenticated/digitalProperty/property/PropertyActivityDetailsScreen'
import PropertyActivityScreen from '@smartcoop/web-containers/screens/authenticated/digitalProperty/property/PropertyActivityScreen'
import PropertyIdentificationScreen from '@smartcoop/web-containers/screens/authenticated/digitalProperty/property/PropertyIdentificationScreen'
import PropertyLocalizationScreen from '@smartcoop/web-containers/screens/authenticated/digitalProperty/property/PropertyLocalizationScreen'

import useStyles, { Container } from './styles'

const CreatePropertyModal = ({
  id,
  open,
  handleClose,
  isEditing,
  zeroProperties
}) => {
  const classes = useStyles()
  const [step, setStep] = useState(1)
  const [confirmedMap, setConfirmedMap] = useState(false)

  const mapMode = useMemo(() => step === 2, [step])

  const dispatch = useCallback(useDispatch(), [])
  const { createDialog } = useDialog()
  const t = useT()

  const ActualScreen = useMemo(() => {
    switch (step) {
      case 1:
        return PropertyIdentificationScreen
      case 3:
        return PropertyActivityScreen
      case 4:
        return PropertyActivityDetailsScreen
      default:
        // map
        return null
    }
  }, [step])

  const onConfirmMap = useCallback(() => setConfirmedMap(true), [])

  const nextStep = useCallback(() => {
    if (step === 4) {
      handleClose()
      dispatch(PropertyActions.saveOfflineProperty())
      createDialog({
        id: 'create-property-success',
        Component: SuccessModal,
        props: {
          message: t('your property was registered'),
          buttonText: t('go to start'),
          underlinedText: t('register another property'),
          onUnderlinedClick: () =>
            createDialog({
              id: 'property-onboarding',
              Component: CreatePropertyModal
            })
        }
      })
    }
    setStep((old) => (old < 4 ? old + 1 : old))
  }, [createDialog, dispatch, handleClose, step, t])

  const previousStep = useCallback(() => {
    if (step - 1 === 0) {
      if (zeroProperties) window.location.reload()
      else handleClose()
    } else {
      setStep((old) => (old > 1 ? old - 1 : old))
    }
  }, [handleClose, step, zeroProperties])

  useEffect(() => {
    if (!isEditing) {
      dispatch(PropertyActions.resetOfflineProperty())
    }
  }, [dispatch, isEditing])

  return (
    <Modal
      id={ id }
      open={ open }
      escape={ false }
      hideHeader
      fullScreen
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0 } }
      FooterComponent={
        mapMode
          ? () => (
            <ButtonsContainer
              style={ {
                flex: 1,
                padding: '10px 20px'
              } }
            >
              <Button
                id="cancel-property-activity-details-form"
                onClick={ previousStep }
                variant="outlined"
                style={ { flex: '0 0 auto', minWidth: 150 } }
              >
                <I18n>go back</I18n>
              </Button>
              <div>
                <Button
                  id="next-page-map"
                  onClick={ nextStep }
                  style={ { flex: '0 0 auto', minWidth: 150, marginRight: 20 } }
                  disabled={ confirmedMap }
                >
                  <I18n>skip</I18n>
                </Button>
                <Button
                  id="next-page-map"
                  onClick={ nextStep }
                  style={ { flex: '0 0 auto', minWidth: 150 } }
                  disabled={ !confirmedMap }
                >
                  <I18n>confirm</I18n>
                </Button>
              </div>
            </ButtonsContainer>
          )
          : undefined
      }
    >
      {mapMode ? (
        <PropertyLocalizationScreen
          confirmedMap={ confirmedMap }
          onConfirm={ onConfirmMap }
          onCancel={ previousStep }
        />
      ) : (
        <Container>
          <Stepper style={ { width: 450 } } step={ step } steps={ 4 } />
          <ActualScreen isCreate onConfirm={ nextStep } onCancel={ previousStep } />
        </Container>
      )}
    </Modal>
  )
}

CreatePropertyModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  zeroProperties: PropTypes.bool
}

CreatePropertyModal.defaultProps = {
  isEditing: false,
  zeroProperties: false
}

export default CreatePropertyModal
