/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import lineIntersect from '@turf/line-intersect'
import { getAreaOfPolygon, convertArea, getCenterOfBounds, isPointInPolygon } from 'geolib'

import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'

export const DEFAULT_COORDINATES = [
  -29.749222,
  -53.296165
]

export const getPolygonCenter = (points) => {
  if (isEmpty(points)) {
    return {}
  }
  const coordinates = map(points, ([latitude, longitude]) => ({ latitude, longitude }))
  return getCenterOfBounds(coordinates)
}

export const getPolygonArea = (points, unit = 'ha') => {
  const formattedPoints = map(points, ([lat, long]) => [long, lat])

  return convertArea(
    getAreaOfPolygon(formattedPoints),
    unit
  )
}

export const isPointInside = (currentLocation, points) => {
  if (isUndefined(currentLocation.longitude) || isUndefined(currentLocation.latitude)) {
    return false
  }

  const coordinates = map(points, ([latitude, longitude]) => ({ latitude, longitude }))

  return isPointInPolygon(currentLocation, coordinates)

}


export const sortPolygonPoints = (points) => {
  const uniqPoints = uniqBy(points, (point) => point.join('_'))
  const coordinates = map(uniqPoints, ([latitude, longitude]) => ({ latitude, longitude }))

  coordinates.sort((a, b) => a.longitude - b.longitude)

  // Get center y
  const cy = (coordinates[0].longitude + coordinates[coordinates.length -1].longitude) / 2

  // Sort from right to left
  coordinates.sort((a,b) => b.latitude - a.latitude)

  // Get center x
  const cx = (coordinates[0].latitude + coordinates[coordinates.length -1].latitude) / 2

  // Center point
  const center = { latitude: cx, longitude: cy }

  // Pre calculate the angles as it will be slow in the sortnumround
  // As the coordinates are sorted from right to left the first point
  // is the rightmost

  // Starting angle used to reference other angles
  let startAng
  coordinates.forEach(point => {
    let ang = Math.atan2(
      point.longitude - center.longitude,
      point.latitude - center.latitude
    )
    if (!startAng) {
      startAng = ang
    } else if (ang < startAng) {  // ensure that all coordinates are clockwise of the start point
      ang += Math.PI * 2
    }
    // eslint-disable-next-line no-param-reassign
    point.angle = ang // add the angle to the point
  })

  // Sort;
  const response = coordinates.sort((a, b) => b.angle - a.angle)

  return map(
    response,
    ({ latitude, longitude }) => [latitude, longitude]
  )
}

export const polygonToSvg = (latLngs = []) => {
  const [firstPoint, ...polyline] = uniqBy(latLngs, ({ lat, lng }) => `${ lat }_${ lng }`)

  const latLng2point = (lat, lng, multiplier) => {
    multiplier = multiplier || 0
    return {
      x: (lng + 180) * (256 / 360) * multiplier,
      y: (256 / 2) - ((256 * Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 180 / 2))) / (2 * Math.PI)) * multiplier
    }
  }

  // set init vars
  const path = []
  const points = []
  const sections = []
  let minX = 99999999
  let minY = 99999999
  let maxY = 0
  let maxX = 0

  const allPoints = [firstPoint, ...polyline, firstPoint]

  let firstX = 0
  let firstY = 0

  // loop points
  allPoints.forEach((point, i) => {
    // convert lat lng to point
    point = latLng2point(point?.lat, point?.lng, 10000)

    points.push(point)

    // if first point move to point
    if (i === 0) {
      // path.push(`M${ point.x } ${ point.y }`)
      path.push(`M${ 0 } ${ 0 }`)
      firstX = point?.x
      firstY = point?.y
    }

    point.x -= firstX
    point.y -= firstY

    // line to point
    path.push(`L${ point?.x } ${ point?.y }`)

    // calculate max and min points
    if (point.x < minX) {
      minX = point.x
    }
    if (point.y < minY) {
      minY = point.y
    }
    if (point.x > maxX) {
      maxX = point.x
    }
    if (point.y > maxY) {
      maxY = point.y
    }
  })

  // calculate width and height of svg
  const width = maxX - minX
  const height = maxY - minY

  // send out svg details
  return {
    points,
    path: path.join(' '),
    sections,
    width,
    height,
    viewbox: `${ minX } ${ minY } ${ width } ${ height }`,
    minX,
    minY,
    maxX,
    maxY
  }
}

export function getCenterCoordinates(points) {
  const uniqPoints = uniqBy(points, (point) => point.join('_'))
  const coordinates = map(uniqPoints, ([latitude, longitude]) => ({ latitude, longitude }))

  let minX; let maxX; let minY; let maxY;

  // init first point
  ((point) => {
    minX = point.latitude
    maxX = point.latitude
    minY = point.longitude
    maxY = point.longitude
  })(coordinates[0])

  // calculate rect
  map(coordinates, (point) => {
    minX = Math.min(minX, point.latitude)
    maxX = Math.max(maxX, point.latitude)
    minY = Math.min(minY, point.longitude)
    maxY = Math.max(maxY, point.longitude)
  })

  const midX = (minX + maxX) / 2
  const midY = (minY + maxY) / 2
  const deltaX = (maxX - minX)
  const deltaY = (maxY - minY)

  return {
    latitude: midX,
    longitude: midY,
    latitudeDelta: deltaX,
    longitudeDelta: deltaY
  }
}

export const calcZoom = (longitudeDelta) => Math.round(Math.log(360 / longitudeDelta) / Math.LN2)

export const findMapZoom = (points) => {
  const coordinates = getCenterCoordinates(points)

  return calcZoom(coordinates.longitudeDelta)
}

/**
 * Dado um angulo de "0-360" retorna a posição cardeal mais próxima "N/NE/L/SE/S/SO/O/NO"
 */
export function getCardinal(angle) {

  const degreePerDirection = 360 / 8

  /**
   * Offset do angulo pela metade dos graus por direção
   * Exemplo: em 4 direções o Norte (320-45) vira (0-90)
   */
  const offsetAngle = angle + degreePerDirection / 2

  return (offsetAngle >= 0 * degreePerDirection && offsetAngle < 1 * degreePerDirection) ? 'North'
    : (offsetAngle >= 1 * degreePerDirection && offsetAngle < 2 * degreePerDirection) ? 'Northeast'
      : (offsetAngle >= 2 * degreePerDirection && offsetAngle < 3 * degreePerDirection) ? 'East'
        : (offsetAngle >= 3 * degreePerDirection && offsetAngle < 4 * degreePerDirection) ? 'Southeast'
          : (offsetAngle >= 4 * degreePerDirection && offsetAngle < 5 * degreePerDirection) ? 'South'
            : (offsetAngle >= 5 * degreePerDirection && offsetAngle < 6 * degreePerDirection) ? 'Southwest'
              : (offsetAngle >= 6 * degreePerDirection && offsetAngle < 7 * degreePerDirection) ? 'West'
                : 'Northwest'
}

export const GROWING_SEASON_COLOR = {
  'laranja':'#fd8d3c',
  'bergamota':'#e362b8ff',
  'feijao':'#99d09cff',
  'linhaca':'#b6a4d9ff',
  'aveia-ucraniana':'#52f6ebff',
  'pessego':'#ce50b3ff',
  'videira':'#c784e9ff',
  'morango':'#f563a5ff',
  'maca':'#f83969ff',
  'goiaba':'#99f643ff',
  'figo':'#5371e7ff',
  'batata-doce':'#8c510a',
  'abobora':'#43b9e4ff',
  'ameixa':'#d790e9ff',
  'azevem':'#59d843ff',
  'trevo':'#958dcdff',
  'capim-quicuio':'#d55463ff',
  'tifton':'#60e578ff',
  'arroz':'#69b4f5ff',
  'outros':'#a16fd9ff',
  'mix-de-coberturas':'#78ca98ff',
  'sorgo':'#7ef4faff',
  'capim-sudao':'#1f78b4',
  'milheto':'#ff7f00',
  'nabo-forrageiro':'#f9b3abff',
  'triticale':'#90f55aff',
  'centeio':'#ffff99',
  'canola':'#df3ddfff',
  'cevada':'#d6f98bff',
  'aveia-preta':'#4187f0ff',
  'aveia-branca':'#f0ba91ff',
  'trigo-duplo-proposito':'#ceb382ff',
  'trigo':'#f5db85ff',
  'milho':'#FFFF00',
  'soja':'#72cc70ff'
}

export const validateIntersectLine = (lineString, polygon) => {
  let firstLineIntersectIndex = 0
  // eslint-disable-next-line no-plusplus
  for(let i = 0; i < lineString.length - 1; i++) {
    const polygonToValidate = {
      'type': 'Polygon',
      'coordinates': [polygon]
    }

    const lineToValidate = {
      'type': 'LineString',
      'coordinates': [lineString[i], lineString[i + 1]]
    }

    const intersected = lineIntersect(lineToValidate, polygonToValidate)

    if(!isEmpty(intersected?.features)) {
      firstLineIntersectIndex = i
      break
    }
  }

  return firstLineIntersectIndex
}

