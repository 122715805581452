import React, { useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'

import { checked as checkedIcon, unchecked as uncheckedIcon } from '@smartcoop/icons'
import colors, { secondary as secondaryColor } from '@smartcoop/styles/colors'
import Icon from '@smartcoop/web-components/Icon'

import {
  RadioTouchableContainer,
  Container,
  IconContainer,
  Divider,
  Children,
  GreenText
} from './styles'

const RadioCard = (props) => {
  const {
    checked,
    label,
    value,
    onChange,
    style,
    children,
    activeItem,
    activeMessage,
    ...rest
  } = props

  const onToggle = useCallback(
    () => {
      onChange({ value })
    },
    [onChange, value]
  )

  const selectIcon = useMemo(
    () => (
      checked ? checkedIcon : uncheckedIcon
    ),
    [checked]
  )

  const checkedStyle = useMemo(
    () => (
      checked ? secondaryColor : colors.darkGrey
    ),
    [checked]
  )

  return (
    <>
      { activeItem && activeMessage && (
        <GreenText>{activeMessage}</GreenText>
      ) }
      <Container
        { ...rest }
        headerLeft={
          <RadioTouchableContainer onClick={ onToggle }>
            <IconContainer>
              <Icon icon={ selectIcon } size={ 21 } color={ checkedStyle }/>
            </IconContainer>
          </RadioTouchableContainer>
        }
        title={ label }
        cardStyle={ {
          padding: 0,
          width: '100%',
          marginTop: 16,
          border: activeItem ? `3px solid ${ colors.green }` : '3px solid transparent'
        } }
      >
        {children && <>
          <Divider/>
          <Children>
            {children}
          </Children>
        </>}
      </Container>
    </>
  )
}

RadioCard.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string
  ]).isRequired,
  onChange: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.any,
  activeItem: PropTypes.bool,
  activeMessage: PropTypes.string
}

RadioCard.defaultProps = {
  style: {},
  children: null,
  activeItem: null,
  activeMessage: null,
  onChange: () => {}
}
export default RadioCard
