import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'


export class ContactModel extends Model {
  static table = 'contact'

  @field('contactId')
  contactId: string

  @field('contactType')
  contactType: string

  @field('profiles')
  profiles: string

  @field('chatNickname')
  chatNickname: string

  @field('photo')
  photo: string

  @field('publicKey')
  publicKey: string

  @field('userCode')
  userCode: string

  @field('company')
  company: string

  @field('default')
  default: boolean

  @field('activeUser')
  activeUser: boolean
}
