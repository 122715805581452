/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import { filter } from 'lodash'
import camelCase from 'lodash/camelCase'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import I18n, { useT } from '@smartcoop/i18n'
import {
  rain,
  windmill,
  thermometer,
  sun,
  compass,
  dropPercentage,
  wind,
  battery
} from '@smartcoop/icons'
import { WeatherStationsActions } from '@smartcoop/stores/weatherStations/'
import { selectCurrentWeatherStation } from '@smartcoop/stores/weatherStations/selectorWeatherStations'
import colors from '@smartcoop/styles/colors'
import { WEATHER_STATIONS_DESCRIPTIONS } from '@smartcoop/utils/constants'
import { getCardinal } from '@smartcoop/utils/maps'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import Loader from '@smartcoop/web-components/Loader'

import {
  DetailsContainer,
  ItemData,
  LeftData,
  RightData,
  Item,
  TextUpdate,
  BlackSubtitle,
  DividerMui,
  Text
} from './styles'


const WeatherStationResumeFragment = (props) => {
  const { stationName, stationId } = props

  const t = useT()
  const history = useHistory()
  const dispatch = useDispatch()
  const currentWeatherStation = useSelector(selectCurrentWeatherStation)

  const currentItem = useMemo(
    () => ({
      temperaturaDoAr: {
        icon: thermometer,
        color: colors.orange
      },
      direcaoDoVento: {
        icon: compass,
        color: colors.red
      },
      umidadeDoAr: {
        icon: dropPercentage,
        color: colors.blue
      },
      velocidadeDoVento: {
        icon: windmill,
        color: colors.darkGrey
      },
      radiacaoSolarMedia: {
        icon: sun,
        color: colors.yellow
      },
      rajadaDeVento: {
        icon: wind,
        color: colors.blue
      },
      temperaturaDoSolo: {
        icon: thermometer,
        color: colors.orange
      },
      tensaoDaBateria: {
        icon: battery,
        color: colors.green
      },
      precipitacao: {
        icon: rain,
        color: colors.blue
      },
      precipitacaoAcumulada: {
        icon: rain,
        color: colors.darkGrey
      }
    }),
    []
  )

  const arrayItemDescriptions = useMemo(
    () => filter(
      currentWeatherStation.descriptions,
      (item) => {
        // Transforma os graus da direção do vento no ponto cardeal (8 pontas) mais próximo.
        if(item.name === WEATHER_STATIONS_DESCRIPTIONS.DIRECAO_DO_VENTO) {
          item.value = t(getCardinal(Number(item.value)))
          item.unit = null

        // Traduz a label de tensão da bateria.
        } else if(item.name === WEATHER_STATIONS_DESCRIPTIONS.TENSAO_DA_BATERIA) {
          item.value = includes(t(`${ item.value  }_battery`), '_battery') ? item.value : t(`${ item.value  }_battery`)
          item.unit = null
        }

        return item.name !== WEATHER_STATIONS_DESCRIPTIONS.TEMPERATURA_DO_AR_2
          && item.name !== WEATHER_STATIONS_DESCRIPTIONS.TEMPERATURA_INTERIOR_DOSSEL
          && item.name !== WEATHER_STATIONS_DESCRIPTIONS.UMIDADE_DO_AR_DOSSEL
          && item.name !== WEATHER_STATIONS_DESCRIPTIONS.TEMPERATURA_DO_SOLO_2
          && item.name !== WEATHER_STATIONS_DESCRIPTIONS.ETO
          && item.name !== WEATHER_STATIONS_DESCRIPTIONS.UMIDADE_VOLUMETRICA
          && item.name !== WEATHER_STATIONS_DESCRIPTIONS.PONTO_DE_ORVALHO
      }
    ),
    [currentWeatherStation.descriptions, t]
  )

  return (
    <DetailsContainer>
      <Item>
        <BlackSubtitle>{stationName}</BlackSubtitle>
      </Item>
      <DividerMui />
      {isEmpty(currentWeatherStation) && <Loader />}
      {!isEmpty(currentWeatherStation) && (
        <div style={ { maxHeight: '60vh', overflow: 'auto' } }>
          <Item>
            <div style={ { paddingBottom: 8 } }>
              <I18n as={ BlackSubtitle } params={ { howMany: 1 } }>
                weather station
              </I18n>
            </div>
            {!isEmpty(arrayItemDescriptions) &&
              map(arrayItemDescriptions, (itemData) => (
                <div key={ itemData?.index }>
                  <ItemData>
                    <LeftData>
                      <Icon
                        icon={
                          currentItem[camelCase(itemData?.description)]?.icon || null
                        }
                        size={ 18 }
                        style={ { marginRight: 5 } }
                        color={
                          currentItem[camelCase(itemData?.description)]?.color || null
                        }
                      />
                      <Text>{itemData?.description} {itemData?.legend && ` - ${ itemData?.legend }` }</Text>
                    </LeftData>
                    <RightData>
                      <Text>
                        {itemData?.value} {itemData?.unit}
                      </Text>
                    </RightData>
                  </ItemData>
                </div>
              ))}
          </Item>

          <DividerMui />

          <Item>
            <TextUpdate>
              {moment(
                currentWeatherStation?.data?.measuredAt,
                'YYYY-MM-DDTHH:mm:ss.SSSSZ'
              ).format('DD/MM/YYYY [às] HH:mm:ss')}
            </TextUpdate>
          </Item>
        </div>
      )}
      <Item>
        <Button
          id="temetry-station"
          color="secondary"
          style={ { marginTop: 20, width: '100%' } }
          onClick={ () => {
            dispatch(WeatherStationsActions.setBiWeatherStationId(stationId))
            history.push('/weather-stations/reports/dashboard')
          } }
        >
          <I18n>data</I18n>
        </Button>
      </Item>
    </DetailsContainer>
  )
}

WeatherStationResumeFragment.propTypes = {
  stationName: PropTypes.string,
  stationId: PropTypes.string
}

WeatherStationResumeFragment.defaultProps = {
  stationName: '',
  stationId: ''
}

export default WeatherStationResumeFragment
