import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

/* Initial State */
const INITIAL_STATE = {
  securitiesMovements: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadSecuritiesMovement: ['params', 'onSuccess', 'onError'],
  loadSecuritiesMovementSuccess: ['securitiesMovement', 'onSuccess'],

  loadSecuritieMovementInitialDate: ['params', 'onSuccess', 'onError'],

  resetSecuritiesMovement: [],

  securitiesMovementError: ['error']
})


const loadSecuritiesMovement = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error
})

const loadSecuritiesMovementSuccess = (state = INITIAL_STATE, { securitiesMovement }) => ({
  ...state,
  securitiesMovement
})

const securitiesMovementError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const resetSecuritiesMovement = (state = INITIAL_STATE) => ({
  ...state,
  securitiesMovement: INITIAL_STATE.securitiesMovement
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_SECURITIES_MOVEMENT]: loadSecuritiesMovement,
  [Types.LOAD_SECURITIES_MOVEMENT_SUCCESS]: loadSecuritiesMovementSuccess,

  [Types.RESET_SECURITIES_MOVEMENT]: resetSecuritiesMovement,

  [Types.SECURITIES_MOVEMENT_ERROR]: securitiesMovementError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as SecuritiesMovementTypes,
  Creators as SecuritiesMovementActions
}
