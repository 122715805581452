import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import required from '@smartcoop/forms/validators/required.validator'

const rainRecordPropertyFields = ({ t }) => Yup.object().shape({
  occurrenceDate: flow(
    date({ t }),
    required({ t })
  )(Yup.string()),

  events: Yup.string(),

  rainMm: Yup.string(),

  fieldsId: Yup.array().required(t('required field'))
})

export default rainRecordPropertyFields
