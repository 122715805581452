import React, { forwardRef, useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import irrigationCropManagementSchema from '@smartcoop/forms/schemas/management/irrigationCropManagement.schema'
import { useT } from '@smartcoop/i18n'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputUnit from '@smartcoop/web-components/InputUnit'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

const IrrigationForm = forwardRef(({ defaultValues, handleSubmit, readOnly }, formRef) => {
  const t = useT()

  const [typeOfIrrigation, setTypeOfIrrigation] = useState(defaultValues?.typeOfIrrigation || null)

  const typesOfIrrigation = useMemo(
    () => (
      [
        {
          label: t('superficial'),
          value: t('superficial')
        },
        {
          label: t('sprinkler'),
          value: t('sprinkler')
        },
        {
          label: t('center pivot'),
          value: t('center pivot')
        },
        {
          label: t('inundation'),
          value: t('inundation')
        }
      ]
    ),
    [t]
  )

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ irrigationCropManagementSchema }
      containerStyle={ { height: 'auto' } }
      onSubmit={ handleSubmit }
    >
      <RadioGroup
        style={ { marginBottom: 10 } }
        label={ t('type') }
        name="typeOfIrrigation"
        options={ typesOfIrrigation }
        variant="row"
        defaultValue={ defaultValues?.typeOfIrrigation }
        onChange={ ({ target: { value } }) => setTypeOfIrrigation(value) }
        disabled={ readOnly }
      />

      {typeOfIrrigation !== t('inundation') && (
        <InputUnit
          name="irrigationPlates"
          label={ t('irrigation plates') }
          maxLength={ 3 }
          unit="mm"
          type="integer"
          fullWidth
          defaultValue={ defaultValues?.irrigationPlates }
          disabled={ readOnly }
        />
      )}
      {typeOfIrrigation === t('inundation') && (
        <InputUnit
          name="flowRate"
          label={ t('flow rate') }
          unit="m³/s"
          type="float"
          fullWidth
          defaultValue={ defaultValues?.flowRate }
          disabled={ readOnly }
        />
      )}
      {typeOfIrrigation === t('inundation') && (
        <InputDate
          name="irrigationFinalDate"
          label={ t('irrigation final date') }
          fullWidth
          defaultValue={ defaultValues?.irrigationFinalDate || '' }
          disabled={ readOnly }
        />
      )}
    </Form>
  )
})

IrrigationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  defaultValues: PropTypes.object
}

IrrigationForm.defaultProps = {
  readOnly: false,
  defaultValues: {}
}

export default IrrigationForm
