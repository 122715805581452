import * as Yup from 'yup'

const milkDeliverySchema = () => Yup.object().shape({

  tamboType: Yup.string(),

  organizationId: Yup.string(),

  companyName: Yup.string(),

  stateRegistrationId: Yup.string()
})

export default milkDeliverySchema
