import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import minNumberFloatValue from '@smartcoop/forms/validators/minNumberFloatValue.validator'
import minNumberValue from '@smartcoop/forms/validators/minNumberValue.validator'
import number from '@smartcoop/forms/validators/number.validator'
import required from '@smartcoop/forms/validators/required.validator'


const harvestCropManagementSchema = ({ t }) => Yup.object().shape({
  unitProductivity: Yup.string(),

  productivityKg:  Yup.string().when('unitProductivity', {
    is: 'kg/ha',
    then: flow(
      required({ t }),
      number({ t }),
      minNumberFloatValue({ t, field: t('productivity in this unit'), limit: 100 })
    )(Yup.string()),
    otherwise: flow(number({ t }))(Yup.string())
  }),

  productivityBags:  Yup.string().when('unitProductivity', {
    is: 'sacas/ha',
    then: flow(
      required({ t }),
      minNumberValue({ t, field: t('productivity in this unit') })
    )(Yup.string()),
    otherwise: Yup.string()
  }),

  ph: flow(
    number({ t })
  )(Yup.string()),

  wholeGrain: flow(
    number({ t })
  )(Yup.string()),

  brokenGrain: flow(
    number({ t })
  )(Yup.string()),

  impurity: flow(
    number({ t })
  )(Yup.string()),

  humidity: flow(
    number({ t })
  )(Yup.string()),

  germinationProductivity: flow(
    number({ t })
  )(Yup.string()),

  shellAndBran: flow(
    number({ t })
  )(Yup.string())
})

export default harvestCropManagementSchema
