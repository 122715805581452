import { tableSchema } from '@nozbe/watermelondb'

export const animalSchema = tableSchema({
  name: 'animal',
  columns: [
    { name: 'animalId', type: 'string' },
    { name: 'inseminationDate', type: 'string' },
    { name: 'nextActionDate', type: 'string' },
    { name: 'deadlineColor', type: 'string' },
    { name: 'birthDate', type: 'string' },
    { name: 'lastBirth', type: 'string' },
    { name: 'slaughterDate', type: 'string' },
    { name: 'expectedBirthDate', type: 'string' },
    { name: 'calendarDate', type: 'string' },
    { name: 'diagnosisDate', type: 'string' },
    { name: 'carencyDate', type: 'string' },
    { name: 'observationDate', type: 'string' },
    { name: 'propertyId', type: 'string' },
    { name: 'earringCode', type: 'string' },
    { name: 'earringId', type: 'string' },
    { name: 'nameAndCode', type: 'string' },
    { name: 'category', type: 'string' },
    { name: 'lotId', type: 'string' },
    { name: 'lotName', type: 'string' },
    { name: 'lotSlug', type: 'string' },
    { name: 'breedId', type: 'number' },
    { name: 'breedName', type: 'string' },
    { name: 'statusId', type: 'number' },
    { name: 'statusName', type: 'string' },
    { name: 'statusSlug', type: 'string' },
    { name: 'inseminationId', type: 'string' },
    { name: 'nextAction', type: 'string' },
    { name: 'actionName', type: 'string' },
    { name: 'color', type: 'string' },
    { name: 'observation', type: 'string' },
    { name: 'lastObservation', type: 'string' },
    { name: 'daysUntil', type: 'number' },
    { name: 'nextActionOrderAlert', type: 'number' },
    { name: 'nextActionAlert', type: 'string' },
    { name: 'pregnancyStatus', type: 'string' },
    { name: 'inseminationActive', type: 'boolean' },
    { name: 'aborted', type: 'boolean' },
    { name: 'servicesAfterLastBirth', type: 'string' },
    { name: 'services', type: 'string' },
    { name: 'daysLastInsemination', type: 'number' },
    { name: 'daysLastAnimalBirth', type: 'number' },
    { name: 'del', type: 'number' },
    { name: 'ageInMonths', type: 'number' },
    { name: 'conceptionRate', type: 'string' },
    { name: 'abortionRate', type: 'string' },
    { name: 'motivation', type: 'string' },
    { name: 'motivationReason', type: 'string' },
    { name: 'active', type: 'boolean' },
    { name: 'deleted', type: 'boolean' }
  ]
})