import { call, put, takeLatest } from 'redux-saga/effects'

import { createPost, getPosts, getPostComments, createPostComment, deletePostComment, editPostComment } from '@smartcoop/services/apis/smartcoopApi/resources/socialNetwork​'

import { SocialActions, SocialTypes } from './duckSocial'

function* loadPosts({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: { data, ...pagination } } = yield call(getPosts, {
      limit: 10,
      ...params
    })

    yield put(SocialActions.loadPostsSuccess(
      data,
      pagination.page,
      () => onSuccess(pagination)
    ))
  } catch (err) {
    const error = err.message
    yield put(SocialActions.socialError(error))
    yield call(onError, error)
  }
}

function* loadPostsSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* saveOfflinePost({ post, onSuccess = () => {}, onError = () => {} }) {
  yield put(SocialActions.savePost(post, onSuccess, onError))
}

function* savePost({ post, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(createPost, post)
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(SocialActions.socialError(error))
    yield call(onError)
  }
}

function* loadComments({ params = {}, onSuccess = () => {}, onError = () => {} }){
  try{
    const { postId } = params
    const { data } = yield call(getPostComments, {}, postId)
    yield call(onSuccess, data)
  }
  catch(err){
    yield call(onError)
  }
}

function* saveComment({ params = {}, onSuccess = () => {}, onError = () => {} }){
  try{
    const { postId, comment } = params
    const { data } = yield call(createPostComment, comment, postId)
    yield call(onSuccess, data)
  }
  catch(err){
    yield call(onError)
  }
}

function* editComment({ params = {}, onSuccess = () => {}, onError = () => {} }){
  try{
    const { commentId, text } = params
    yield call(editPostComment, { text }, commentId)
    yield call(onSuccess)
  }
  catch(err){
    yield call(onError)
  }
}

function* deleteComment({ params = {}, onSuccess = () => {}, onError = () => {} }){
  try{
    const { commentId } = params
    yield(call(deletePostComment, commentId))
    yield call(onSuccess)
  }
  catch(err){
    yield call(onError)
  }
}

export default [
  takeLatest(SocialTypes.LOAD_POSTS, loadPosts),
  takeLatest(SocialTypes.LOAD_POSTS_SUCCESS, loadPostsSuccess),

  takeLatest(SocialTypes.SAVE_POST, savePost),
  takeLatest(SocialTypes.SAVE_OFFLINE_POST, saveOfflinePost),

  takeLatest(SocialTypes.LOAD_COMMENTS, loadComments),
  takeLatest(SocialTypes.SAVE_COMMENT, saveComment),
  takeLatest(SocialTypes.EDIT_COMMENT, editComment),
  takeLatest(SocialTypes.DELETE_COMMENT, deleteComment)

]
