import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-flex-start;
  width: 100%;
  padding: 25px 30px;
`

export const MainText = styled(Typography)`
  text-align: left;
  color: ${ colors.text };
  font-size: 16px;
  margin-bottom: 10px;
`
