export default ({ size, count = 0, color = '#ff5f6e' } = {}) => /* html */`
<svg width="${ size }" height="${ size }" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
<g transform="matrix(5.089875, 0, 0, 5.089875, 81.149498, 31.023909)" style="">
<g transform="matrix(.26458 0 0 .26458 69.661 54.138)">
  <path class="cls-4" d="m-50.068-59.266c0 55.83-101.09 187.73-101.09 187.73s-101.09-131.9-101.09-187.73a101.09 101.09 0 0 1 202.17 0z" fill="${ color }" style="stroke: rgb(255, 255, 255); stroke-width: 11.1385px;"/>
  <circle cx="-55.947" cy="-137.271" r="70.411" style="paint-order: markers; stroke: rgb(255, 255, 255); stroke-width: 3.71284px; fill: rgb(255, 230, 0);"/>
  <g transform="matrix(3.5871 0 0 3.5871 -206.86 -78.05)" fill="#fffff9">
    <path d="m25.639 16.66c-2.2782 0-4.125 1.8468-4.125 4.125 0 2.2781 1.8468 4.125 4.125 4.125 2.2781 0 4.125-1.8469 4.125-4.125 0-2.2782-1.8469-4.125-4.125-4.125zm0 5.25c-0.6202 0-1.125-0.5049-1.125-1.125 0-0.6202 0.5048-1.125 1.125-1.125 0.6201 0 1.125 0.5048 1.125 1.125 0 0.6201-0.5049 1.125-1.125 1.125zm3.75-13.5h-3v-1.8844c0-0.66187 0.2203-1.3012 0.6164-1.8206 0.2072-0.27188 0.1664-0.65907-0.0619-0.9136l-1.0139-1.133c-0.3122-0.34921-0.8587-0.32437-1.1578 0.03657-0.8869 1.0697-1.3828 2.4248-1.3828 3.8306v1.8844h-4.7967l-2.6293-6.1341c-0.1727-0.40554-0.4609-0.7513-0.8287-0.99419-0.3679-0.2429-0.7991-0.37218-1.2399-0.37174h-6.2554c-1.2408 0-2.25 1.0092-2.25 2.25v6.8681c-0.40453-0.31549-0.9825-0.30283-1.3542 0.0689l-1.4583 1.4583c-0.40265 0.4026-0.40265 1.0556 0 1.4583l0.23719 0.2371c-0.23391 0.4341-0.42 0.8822-0.55828 1.3463h-0.33516c-0.56953 0-1.0312 0.4617-1.0312 1.0312v2.0625c0 0.5696 0.46172 1.0313 1.0312 1.0313h0.33469c0.13875 0.4645 0.32437 0.9122 0.55828 1.3467l-0.23719 0.2372c-0.40265 0.4027-0.40265 1.0556 0 1.4583l1.4583 1.4583c0.40266 0.4026 1.0556 0.4026 1.4583 0l0.23718-0.2372c0.43407 0.2339 0.88219 0.42 1.3462 0.5583v0.3351c0 0.5695 0.46172 1.0313 1.0312 1.0313h2.0626c0.5695 0 1.0312-0.4618 1.0312-1.0313v-0.3347c0.4641-0.1383 0.9122-0.3244 1.3463-0.5583l0.2371 0.2372c0.4027 0.4027 1.0557 0.4027 1.4583 0l1.4583-1.4583c0.4027-0.4026 0.4027-1.0556 0-1.4582l-0.2372-0.2372c0.2339-0.4341 0.42-0.8822 0.5583-1.3463h0.3356c0.5696 0 1.0313-0.4617 1.0313-1.0312v-0.2813h3.7753c1.027-1.3589 2.64-2.25 4.4747-2.25 0.8737 0 1.6908 0.2161 2.4281 0.5719l2.3822-2.3822c0.2812-0.2812 0.4392-0.6628 0.4392-1.0608v-2.3793c5e-4 -0.82828-0.6712-1.5-1.4995-1.5zm-20.25 12c-2.0709 0-3.75-1.6791-3.75-3.75 0-2.071 1.6791-3.75 3.75-3.75s3.75 1.679 3.75 3.75c0 2.0709-1.6791 3.75-3.75 3.75zm1.0312-12h-1.7812v-4.5h5.0104l1.9289 4.5z" fill="#fffff9"/>
  </g>
  <text fill="#000000" font-family="sans-serif" font-size="40px" style="line-height:1.25;shape-inside:url(#rect844);white-space:pre"/>
  <text style="fill: rgb(51, 51, 51); font-family: Arial, sans-serif; font-size: 96.5px; font-weight: 700; white-space: pre; text-anchor: middle;" x="-53.801" y="-103.024">${ count }</text>
</g>
</g>
</svg>
`
