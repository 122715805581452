import React, { useRef, useCallback, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import SignaturePad from 'react-signature-canvas'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'

import I18n, { useT } from '@smartcoop/i18n'
import { selectCurrentOwnerFamilyGroup } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Button from '@smartcoop/web-components/Button'
import InputSelect from '@smartcoop/web-components/InputSelect'
import Modal from '@smartcoop/web-components/Modal'


import { Row, Content, ButtonsContainer  } from './styles'

const SignatureModal = (props) => {
  const canvasRef = useRef(null)

  const t = useT()
  const { open, handleClose, onSubmit, signatures } = props

  const currentOwnerFamilyGroup = useSelector(selectCurrentOwnerFamilyGroup)
  const currentUser = useSelector(selectUser)

  const [isEmptySignature, setIsEmptySignature] = useState(true)
  const [userId, setUserId] = useState(null)

  const clear = useCallback(() => {
    canvasRef.current.clear()
    setIsEmptySignature(true)
  }, [])

  const signatureOptions = useMemo(() => {
    const user = { value: currentUser?.id, label: currentUser?.name }
    const familyGroupOptions = map(currentOwnerFamilyGroup, item => ({ value: item.id, label: item?.name }))
    const currentSignaturesIds = map(signatures, item => item.userId)
    return filter([user, ...familyGroupOptions], item => !includes(currentSignaturesIds, item.value))
  }, [currentOwnerFamilyGroup, currentUser, signatures])

  return (
    <Modal
      id
      open={ open }
      onClose={ handleClose }
      title={
        <Row>
          <I18n>draw digital signature</I18n>
        </Row>
      }
      disableFullScreen
      disableEsc
      maxWidth='xs'
      fullWidth
      contentContainerStyle={ { padding: 0 } }
    >
      <Content>
        <InputSelect
          detached
          label={ t('who is signing') }
          options={ signatureOptions || [] }
          onChange={ setUserId }
          fullWidth
          required
          value={ userId }
          withoutConfirmButton
        />
        <SignaturePad
          ref={ canvasRef }
          backgroundColor="#ebebeb"
          canvasProps={ { style: { width: 300, height: 150, margin: 10 } } }
          onEnd={ () => setIsEmptySignature(canvasRef.current.isEmpty()) }
        />
        <ButtonsContainer>
          <Button
            style={ {
              flex: 1
            } }
            color="white"
            onClick={ clear }
          >
            <I18n>clear</I18n>
          </Button>
          <Button
            id="submit-fiscal-register-form"
            onClick={ () => {
              onSubmit({ userId, signature: canvasRef.current.getTrimmedCanvas().toDataURL('image/png') })
              handleClose()
            } }
            style={ { flex: 1 } }
            disabled={ isEmptySignature || !userId }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Content>
    </Modal>
  )
}

SignatureModal.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  signatures: PropTypes.array,
  handleClose: PropTypes.func.isRequired
}

SignatureModal.defaultProps = {
  open: false,
  signatures: [],
  onSubmit: () => {}
}

export default SignatureModal
