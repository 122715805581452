import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  margin-top: 2px;
  font-size: 16px;
  flex: 1;
  align-items: center;

`

export const Label = styled.span`
  font-size: 16px;
  font-weight: 600;
`

export const Top = styled.div`
  background-color: ${ colors.white };
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 30px;
  width: 100%;
  padding: 10px;
`
