import React, { useCallback, forwardRef, useRef } from 'react'
import SignaturePad from 'react-signature-canvas'


import PropTypes from 'prop-types'

import signatureSchema from '@smartcoop/forms/schemas/producer/signature.schema'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputText from '@smartcoop/web-components/InputText'
import { Text } from '@smartcoop/web-containers/layouts/GuestLayout/theme'

import { Container, ButtonContainer } from './styles'

const SignatureForm = forwardRef((props, formRef) => {
  const { onSubmit } = props

  const canvasRef = useRef(null)

  const handleSubmit = useCallback(
    async (data) => {
      onSubmit(data)
    },
    [onSubmit]
  )

  const clear = useCallback(() => {
    canvasRef.current.clear()
    formRef.current.getFieldRef('signature').setValue(null)
  }, [formRef])

  return (
    <Container>
      <Form
        ref={ formRef }
        schemaConstructor={ signatureSchema }
        onSubmit={ handleSubmit }
      >
        <InputText
          name="signature"
          style={ { display: 'none' } }
        />

        <Text>
          Desenhe sua assinatura digital
        </Text>

        <ButtonContainer>
          <Button
            style={ {
              order: 0,
              flex: '0 1 auto',
              alignSelf: 'auto'
            } }
            onClick={ clear }
          >
            Limpar
          </Button>
        </ButtonContainer>

        <SignaturePad
          ref={ canvasRef }
          backgroundColor="#ebebeb"
          canvasProps={ { style: { width: '100%', height: 150 } } }
          onEnd={ () => formRef.current.getFieldRef('signature').setValue(canvasRef.current.getTrimmedCanvas().toDataURL('image/png')) }
        />

      </Form>
    </Container>
  )
})

SignatureForm.propTypes = {
  onSubmit: PropTypes.func
}

SignatureForm.defaultProps = {
  onSubmit: () => {}
}

export default SignatureForm
