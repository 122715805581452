import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getPowerBiToken } from '@smartcoop/services/apis/smartcoopApi/resources/bi'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Loader from '@smartcoop/web-components/Loader'
import MyFieldProductivityReportFragment from '@smartcoop/web-containers/fragments/reports/field/MyFieldProductivityReportFragment'

const FieldsRankingReportScreen = () => {
  const user = useSelector(selectUser)

  const [token, setToken] = useState(null)
  const [isValidating, setIsValidating] = useState(true)

  const getMicrosoftToken = useCallback(async () => {
    const { data, isValidating: validating } = await getPowerBiToken()
    setToken(data)
    setIsValidating(validating)
    return data
  }, [])

  useEffect(() => {
    getMicrosoftToken()
  },[getMicrosoftToken])

  const accessToken = useMemo(
    () => token?.accessToken,
    [token]
  )

  const userId = useMemo(() => user?.id, [user])

  const loading = useMemo(
    () => (!accessToken && isValidating) || !userId || !accessToken,
    [accessToken, isValidating, userId]
  )

  return loading ? (
    <Loader width={ 100 } />
  ) : (
    <div style={ { display: 'flex', flex: 1 } }>
      <MyFieldProductivityReportFragment token={ accessToken } userId={ userId } getMicrosoftToken={ getMicrosoftToken }/>
    </div>
  )
}

export default FieldsRankingReportScreen
