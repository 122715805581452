import React, { useCallback } from 'react'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import I18n from '@smartcoop/i18n'
import { fullScreen, rain as rainIcon } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import Icon from '@smartcoop/web-components/Icon'
import RainMapFragment from '@smartcoop/web-containers/fragments/RainMapFragment'
import RainScaleFragment from '@smartcoop/web-containers/fragments/RainScaleFragment'
import RainMapModal from '@smartcoop/web-containers/modals/RainMapModal'

import {
  CardItem,
  DotsContainer,
  Title,
  MapContainer
} from './styles'

const RainMapFragmentCard = ({ property }) => {
  const { createDialog } = useDialog()

  const openModal = useCallback(
    () => createDialog({
      id: 'user-not-found',
      Component: RainMapModal,
      props: {
        property
      }
    }),
    [createDialog, property]
  )

  return (

    <CardItem
      headerRight={ (
        <DotsContainer onClick={ openModal }>
          <Icon icon={ fullScreen } style={ { cursor: 'pointer' } } />
        </DotsContainer>
      ) }
      title={
        <Title>
          <Icon
            icon={ rainIcon }
            color={ colors.text }
            size={ 20 }
            style={ { marginRight: 10 } }
          />
          <I18n>rain map</I18n>
        </Title>
      }
    >
      <MapContainer id="teste" style={ { width: '100%', height: 10 } }>
        <RainMapFragment property={ property } style={ { borderRadius: '10px 10px 0 0' } } />
      </MapContainer>
      <RainScaleFragment/>
    </CardItem>
  )}

RainMapFragmentCard.propTypes = {
  property: PropTypes.object.isRequired
}

export default RainMapFragmentCard
