import styled from 'styled-components'

import Card from '@smartcoop/web-components/Card'

export const CardItem = styled(Card).attrs({
  cardStyle: {
    width: '100%',
    padding: 0
  },
  childrenStyle: {
    padding: '0 20px 25px',
    marginTop: -15
  }
})`
  margin-top: 0px;
  margin-bottom: 15px;
  width: 100%;
`

export const DotsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 10px;
  padding: 0px 15px 10px 0px;
`

export const MapContainer = styled.div`
  width: 100px;
  min-height: 300px;
`

export const Title = styled.div`
  display: flex;
  padding: 10px 10px 15px 20px;

  span {
    width: max-content;
  }
`
