import { Model } from '@nozbe/watermelondb'
import { date, field, readonly } from '@nozbe/watermelondb/decorators'


export class SyncPendingModel extends Model {
  static table = 'sync_pending'

  @field('type')
  type: string

  @field('body')
  body: string

  @field('params')
  params: string

  @field('url')
  url: string

  @field('sync')
  sync: boolean

  @field('error')
  error: string

  @field('registerType')
  registerType: string

  @field('localMessageId')
  localMessageId: string

  @readonly @date('created_at') createdAt
}
