import React, { useMemo, useCallback, useRef } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import truncate from 'lodash/truncate'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import {
  getPackagesByOrg as getPackagesByOrgService
} from '@smartcoop/services/apis/smartcoopApi/resources/barter'
import { momentBackDateTimeFormat } from '@smartcoop/utils/dates'
import DataTable from '@smartcoop/web-components/DataTable'
import BarterDetailsModal from '@smartcoop/web-containers/modals/commercialization/BarterDetailsModal'

import Footer from './Footer'

const BarterPackageListFragment = ({
  filters,
  urlParams
}) => {
  const tableRef = useRef(null)

  const t = useT()
  const { createDialog } = useDialog()

  const columns = useMemo(
    () => [
      {
        title: t('name'),
        field: 'barterName',
        cellStyle: { fontWeight: 'bold' }
      },
      {
        title: t('code'),
        field: 'internalCode'
      },
      {
        title: t('price', { howMany: 1 }),
        field: 'price',
        render: (row) => (`${ row.price } ${ row.measureUnit }`)
      },
      {
        title: t('launch'),
        field: 'createdAt',
        render: (row) => (moment(row.createdAt, momentBackDateTimeFormat).format('DD/MM/YYYY'))
      },
      {
        title: t('crop', { howMany: 1 }),
        field: 'cropName'
      },
      {
        title: t('products'),
        render: (row) => truncate(map(row.barterProducts, ({ productName }) => productName).join(', '), 32)
      }
    ], [t]
  )

  const onRowClick = useCallback(
    (_, item) => {
      createDialog({
        id: 'baerter-details-modal',
        Component: BarterDetailsModal,
        props: {
          barterOrderNumber: item.id,
          exibitionCode: item.internalCode,
          packageJoinMode: true,
          title: t('package', { howMany: 1 }),
          onClose: () => {
            const { query } = tableRef.current.state
            tableRef.current.onQueryChange({
              ...query,
              page: 0
            })},
          FooterComponent:
            (props) =>
              <Footer
                { ...props }
                barterId={ item.id }
                measureUnit={ item?.measureUnit }
              />
        }
      })
    },
    [createDialog, t]
  )

  return(
    <DataTable
      tableRef={ tableRef }
      onRowClick={ onRowClick }
      data={ getPackagesByOrgService }
      queryParams ={ filters }
      columns={ columns }
      urlParams={ urlParams }
      id="barter-package-list-table"
    />
  )
}

BarterPackageListFragment.propTypes = {
  filters: PropTypes.object,
  urlParams: PropTypes.object
}

BarterPackageListFragment.defaultProps = {
  filters: {},
  urlParams: {}

}

export default BarterPackageListFragment
