import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'

export class ProfilePictureModel extends Model {
  static table = 'profile_picture'

  @field('userId')
  userId: string

  @field('sourceUrl')
  sourceUrl: string

  @field('source')
  source: string

  @field('group')
  group: boolean

  @field('conversationId')
  conversationId: string
}
