import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import cep from '@smartcoop/forms/validators/cep.validator'
import cpfCnpj from '@smartcoop/forms/validators/cpfCnpj.validator'
import email from '@smartcoop/forms/validators/email.validator'
import min from '@smartcoop/forms/validators/min.validator'
import number from '@smartcoop/forms/validators/number.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerOrganization = ({ t }) =>
  Yup.object().shape({
    active: flow(required({ t }))(Yup.string()),
    nfe: flow(required({ t }))(Yup.string()),

    companyDocument: flow(
      required({ t }),
      cpfCnpj({ t, onlyCnpj: true })
    )(Yup.string()),

    stateRegistration: flow(
      required({ t, isNotText: true }),
      number({ t }),
      min({
        t,
        name: t('state registration'),
        min: 9
      })
    )(Yup.string()),

    companyName: flow(required({ t }))(Yup.string()),

    tradeName: flow(required({ t }))(Yup.string()),

    isSubsidiary: flow(required({ t }))(Yup.string()),

    organizationId: Yup.string().when('isSubsidiary', {
      is: 'true',
      then: required({ t })(Yup.string())
    }),

    city: flow(required({ t }))(Yup.string()),

    state: flow(required({ t }))(Yup.string()),

    cep: flow(required({ t }), cep({ t }))(Yup.string()),

    street: flow(required({ t }))(Yup.string()),

    number: flow(required({ t }))(Yup.string()),

    neighborhood: flow(required({ t }))(Yup.string()),

    addressComplement: flow()(Yup.string()),

    email: flow(email({ t }), required({ t }))(Yup.string())
  })

export default registerOrganization
