import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const registerBull = ({ t }) => Yup.object().shape({
  name: flow(
    required({ t })
  )(Yup.string()),

  code: flow(
    required({ t })
  )(Yup.string()),

  breedId: flow(
    required({ t })
  )(Yup.string())

})

export default registerBull
