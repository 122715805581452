import React, { useCallback, forwardRef } from 'react'
import { useDispatch } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'


import acceptAccreditmentSchema from '@smartcoop/forms/schemas/operations/acceptAccreditment.schema'
import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { OperationsActions } from '@smartcoop/stores/operations'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'

import { Container } from './styles'

const AcceptAccreditmentForm = forwardRef((props, formRef) => {
  const {
    onSubmit,
    onSuccess,
    defaultValues,
    onError,
    setLoading,
    accreditmentId,
    requestStatus
  } = props

  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()

  const handleSubmit = useCallback(
    (incomingData) => {
      setLoading(true)
      onSubmit()

      dispatch(OperationsActions.editAccreditment(
        accreditmentId,
        requestStatus === 'APROVADO' ? {
          ...incomingData
        } :{
          ...incomingData,
          requestStatus: 'APROVADO',
          accreditationDate: moment().format(momentBackDateFormat)
        },
        () => {
          snackbar.success(t(requestStatus === 'APROVADO' ? 'accreditment edited': 'accreditment accepted'))
          onSuccess(incomingData)
        },
        () => {
          setLoading(false)
          onError()
        }
      ))
    },
    [accreditmentId, dispatch, onError, onSubmit, onSuccess, requestStatus, setLoading, snackbar, t]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ acceptAccreditmentSchema }
        onSubmit={ (data) => handleSubmit(data) }
      >
        <InputDate
          label="Data de Validade"
          name="expirationDate"
          fullWidth
          pickerProps={ {
            minDate: moment().format('YYYY-MM-DD')
          } }
          defaultValue={ moment(defaultValues?.expirationDate, 'YYYY-MM-DD h:mm:ss').format('YYYY-MM-DD') }
        />
      </Form>
    </Container>
  )
})

AcceptAccreditmentForm.propTypes = {
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  accreditmentId: PropTypes.string.isRequired,
  requestStatus: PropTypes.string.isRequired,
  setLoading: PropTypes.func,
  defaultValues: PropTypes.object
}

AcceptAccreditmentForm.defaultProps = {
  onSuccess: () => {},
  onSubmit: () => {},
  setLoading: () => {},
  onError: () => {},
  defaultValues: {}
}

export default AcceptAccreditmentForm
