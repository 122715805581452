import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { emptyFilter } from '@smartcoop/icons'
import EmptyState from '@smartcoop/web-components/EmptyState'
import { InputSearch } from '@smartcoop/web-components/SearchChat/styles'

import ChatSearchHeader from '../ChatSearchHeader'
import ChatSearchList from '../ChatSearchList'
import { Container, ListWrapper, SearchWrapper } from './styles'

const ChatSearch = ({ relationalId, conversationId, handleCloseSearch }) => {
  const [searchParams, setSearchParams] = useState('')

  const t = useT()

  return (
    <Container>
      <ChatSearchHeader
        handleCloseSearch={ handleCloseSearch }
      />

      <SearchWrapper>
        <InputSearch
          type="text"
          placeholder={ t('search in conversation') }
          value={ searchParams }
          onChange={ e => setSearchParams(e.target.value) }
        />
      </SearchWrapper>

      <ListWrapper>
        {searchParams.length < 3 ? (
          <EmptyState
            text={ t('enter at least 3 characters to start the search') }
            contentStyle={ { textAlign: 'center' } }
            icon={ emptyFilter }
          />
        ) : (
          <ChatSearchList
            searchParams={ searchParams }
            relationalId={ relationalId }
            conversationId={ conversationId }
          />
        )}
      </ListWrapper>
    </Container>
  )
}

ChatSearch.propTypes = {
  handleCloseSearch: PropTypes.func.isRequired,
  relationalId: PropTypes.string,
  conversationId: PropTypes.string
}

ChatSearch.defaultProps = {
  relationalId: null,
  conversationId: null
}

export default ChatSearch
