import React, { useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import includes from 'lodash/includes'
import last from 'lodash/last'
import split from 'lodash/split'

import { useT } from '@smartcoop/i18n'
import { download, fileWithExtension } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'
import { CloseRoundedIconButton } from '@smartcoop/web-components/IconButton'

import { Preview } from './styles'

const ThumbnailImage = ({ src, style, size, onClose, onClick, name }) => {
  const t = useT()

  const isImage = includes(name, '.png') || includes(name, '.jpeg') || includes(name, '.jpg') || includes(name, '.tiff') ||
    includes(src, '/png') || includes(src, '/jpeg') || includes(src, '/jpg') || includes(src, '/tiff')

  const fileExtension = useMemo(() => {
    if(includes(src, '/png')){
      return 'png'
    }if(includes(src, '/jpeg')){
      return 'jpeg'
    }if(includes(src, '/jpg')){
      return 'jpg'
    }if(includes(src, '/tiff')){
      return 'tiff'
    }if(includes(src, '/pdf')){
      return 'pdf'
    }if(includes(src, '/doc')){
      return 'doc'
    }if(includes(src, '/docx')){
      return 'docx'
    }if(includes(src, '/xlsx')){
      return 'xlsx'
    }
    return ''
  },[src])

  const extension = name ? last(split(name, '.')) : fileExtension


  const downloadBase64File = useCallback(() => {
    const downloadLink = document.createElement('a')
    document.body.appendChild(downloadLink)

    downloadLink.href = src
    downloadLink.target = '_self'
    downloadLink.download = name || `technical_visit_file.${ extension }`
    downloadLink.click()
  }, [extension, name, src])

  return (
    <Preview style={ { ...style, cursor: onClose ? 'auto' : 'pointer' } } i sImage={ isImage } src={ isImage ? src : '' } size={ size } onClick={ onClose ? () => {} : onClick }>
      <div style={ { display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingLeft: 5, alignItems: 'center' } }>
        {!name && (
          <Icon
            onClick={ downloadBase64File }
            size={ 15 }
            tooltip={ t('download') }
            color={ colors.darkGrey }
            icon={ download }
            style={ {
              backgroundColor: colors.white,
              borderRadius: 22,
              padding: 4,
              border: '1px solid #babaca',
              marginTop: 2
            } }
          />
        )}
        {onClose &&
        <CloseRoundedIconButton
          size="small"
          marginHorizontal
          marginVertical
          onClick={ onClose }
          iconBorder={ colors.grey }
          iconColor={ colors.darkGrey }
          style={ { height: 0 } }
        />
        }
      </div>
      {!isImage && (
        <Icon
          style={ { alignSelf: 'center', marginTop: 0 } }
          size={ 100 }
          icon={ fileWithExtension }
          color={ colors.primary }
          text={ `.${ extension }` }
        />
      )}
    </Preview>
  )
}

ThumbnailImage.propTypes = {
  src: PropTypes.string.isRequired,
  name: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.number,
  onClose: PropTypes.func,
  onClick: PropTypes.func
}

ThumbnailImage.defaultProps = {
  style: {},
  onClick: () => {},
  size: 150,
  name: null,
  onClose: null
}

export default ThumbnailImage
