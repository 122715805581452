import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { ChallengesActions } from '@smartcoop/stores/challenges'
import { PropertyActions } from '@smartcoop/stores/property'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import AcceptTermModal from '@smartcoop/web-containers/modals/AcceptTermModal'


import useStyles, { Row, Content, Link } from './styles'

const ChallengeTermModal = ({ id, open, handleClose, field, challengeTerm, challenge, confirmModal }) => {
  const { term, productivityChallenge } = challengeTerm

  const classes = useStyles()
  const { createDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const t = useT()

  const [loading, setLoading] = useState(false)

  const onSuccess = useCallback(
    () => {
      setLoading(false)
      dispatch(
        ChallengesActions.acceptOfflineProductivityTerm (
          productivityChallenge.id,
          term.id,
          () => {
            snackbar.success(
              t('participation successfull')
            )
            dispatch(PropertyActions.loadPropertyChallenges())
          }
        )
      )
      handleClose()
    },
    [dispatch, handleClose, productivityChallenge, snackbar, t, term]
  )

  const modalTerm = useCallback(
    (onWillClose = () => {}, viewOnly = false) => {
      if (viewOnly) {
        createDialog({
          id: 'term-modal-challenge-participation',
          Component: AcceptTermModal,
          props: {
            onWillClose,
            onCancel: () => {},
            viewOnly: true,
            term
          }
        })
      }
    },
    [createDialog, term]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row>
          <I18n>participation term</I18n>
        </Row>
      }
      disableFullScreen
      escape
      loading={ loading }
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        <Content>
          <span>
            {isEmpty(field) ? `A sua propriedade ${ challenge?.property?.name } foi cadastrado pelo técnico ${ challenge?.technician?.name } da cooperativa ${ challenge?.technicianOrganization?.organization?.tradeName } para participar do ${ challenge?.project?.projectName }. Aceite o termo de participação disponível no link abaixo para efetivar a sua participação.` : `O seu talhão ${ field?.fieldName } foi cadastrado pelo técnico ${ productivityChallenge?.technician?.name } da cooperativa ${ productivityChallenge?.technicianOrganization?.organization?.tradeName } para participar do ${ challenge?.project?.projectName }. Aceite o termo de participação disponível no link abaixo para efetivar a sua participação.`}
          </span>

          <Link
            onClick={ () => {modalTerm(() => {}, true)} }
          >
            <I18n>view participation term</I18n>
          </Link>

          <ButtonsContainer style={ { marginTop: 18 } }>
            <Button
              id="refuse"
              onClick={ () => {
                setLoading(false)
                confirmModal('refuse')
              } }
              variant="outlined"
              style={ { marginRight: 7 } }
              disabled={ loading }
            >
              <I18n>refuse</I18n>
            </Button>
            <Button
              id="participate"
              onClick={ () => onSuccess() }
              color="black"
              style={ { marginLeft: 7 } }
              disabled={ loading }
            >
              <I18n>participate</I18n>
            </Button>
          </ButtonsContainer>

        </Content>
      </>
    </Modal>
  )}

ChallengeTermModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  field: PropTypes.object,
  challengeTerm: PropTypes.object.isRequired,
  challenge: PropTypes.object.isRequired,
  confirmModal: PropTypes.func.isRequired
}

ChallengeTermModal.defaultProps = {
  field: {}
}

export default ChallengeTermModal
