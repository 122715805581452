import React, { useRef, useCallback, useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import Button from '@smartcoop/web-components/Button'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import TechnicalEvaluationForm from '@smartcoop/web-containers/forms/technical/TechnicalEvaluationForm'

import { Container } from './styles'

const TechnicalEvaluationFormScreen = ({ producer, currentForm, setProducer, modalHandleClose, onSubmit, defaultValues }) => {
  const technicalEvaluationFormRef = useRef(null)
  const t = useT()
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()

  const [blockedSave, setBlockedSave] = useState(false)

  const submitForms = useCallback(
    () => {
      setBlockedSave(true)
      createDialog({
        id: 'confirm-submit',
        Component: ConfirmModal,
        props: {
          onConfirm: () => technicalEvaluationFormRef.current.submit(),
          textWarning: t('are you sure you want to submit this form')
        }
      })
    },
    [createDialog, t]
  )

  const handleClose = useCallback(
    () => {
      setProducer(null)
      modalHandleClose()
    },
    [modalHandleClose, setProducer]
  )

  const textSnackbarSuccess = useMemo(
    () => defaultValues?.id ? (
      t('your {this} was edited', {
        howMany: 1,
        gender: 'female',
        this: t('technical evaluation')
      })
    ) : (
      t('your {this} was registered', {
        howMany: 1,
        gender: 'female',
        this: t('technical evaluation')
      })
    ),
    [defaultValues, t]
  )

  const onSuccess = useCallback(
    (answerId) => {
      snackbar.success(
        textSnackbarSuccess
      )
      onSubmit(answerId)
      handleClose()
    },
    [handleClose, onSubmit, snackbar, textSnackbarSuccess]
  )

  const defaultValuesMapped = useMemo(() => {
    const updatedData = {}
    map(defaultValues?.answers, item => {
      updatedData[item?.questionId] = item?.answer
    })
    return updatedData
  }, [defaultValues])

  return (
    <Container>
      <TechnicalEvaluationForm
        ref={ technicalEvaluationFormRef }
        defaultValues={ defaultValuesMapped }
        currentForm={ currentForm }
        producer={ producer }
        onSuccess={ onSuccess }
        setBlockedSave={ setBlockedSave }
        onSubmit={ onSubmit }
        quizAnswerId={ defaultValues?.answers?.[0]?.quizAnswerId }
      />
      <div style={ { display: 'flex', flexDirection: 'row', width: '100%' } }>
        <Button
          onClick={ handleClose }
          variant="outlined"
          style={ { margin: 10 } }
        >
          {t('cancel')}
        </Button>

        <Button
          onClick={ submitForms }
          disabled={ blockedSave }
          style={ { margin: 10 } }
        >
          {t('save')}
        </Button>
      </div>
    </Container>
  )
}

TechnicalEvaluationFormScreen.propTypes = {
  producer: PropTypes.object,
  currentForm: PropTypes.object,
  defaultValues: PropTypes.object,
  modalHandleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  setProducer: PropTypes.func.isRequired
}

TechnicalEvaluationFormScreen.defaultProps = {
  producer: null,
  currentForm: null,
  defaultValues: null,
  onSubmit: null
}

export default TechnicalEvaluationFormScreen
