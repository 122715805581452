import React, { useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'


import technicalGroupsSchema from '@smartcoop/forms/schemas/property/technicalGroups.schema'
import { useT } from '@smartcoop/i18n'
import { getTechnicalPortfolio as getTechnicalPortfolioService } from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import { Container } from './styles'

const TechnicianGroupsForm = forwardRef((props, formRef) => {
  const { loading, defaultValues, onSubmit } = props

  const t = useT()

  const handleSubmit = useCallback(
    (data) => onSubmit({ ...data }),
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ technicalGroupsSchema }
        onSubmit={ handleSubmit }
      >
        <InputText
          label={ t('name') }
          name="name"
          defaultValue={ defaultValues?.name }
          disabled={ loading }
        />
        <InputSelect
          label={ t('producer', { gender: 'male', howMany: 2 }) }
          name="usersIds"
          options={ getTechnicalPortfolioService }
          asyncOptionLabelField="name"
          asyncOptionValueField="id"
          queryParams={ { limit: 9999 } }
          defaultValue={ defaultValues?.usersIds || [] }
          disabled={ loading }
          multiple
        />
      </Form>
    </Container>
  )
})

TechnicianGroupsForm.propTypes = {
  defaultValues: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func
}

TechnicianGroupsForm.defaultProps = {
  defaultValues: {},
  loading: false,
  onSubmit: () => {}
}

export default TechnicianGroupsForm
