import React, { useCallback, forwardRef, useRef, useState, useEffect } from 'react'
import Webcam from 'react-webcam'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import takePictureSchema from '@smartcoop/forms/schemas/producer/takePicture.schema'
import I18n, { useT } from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputText from '@smartcoop/web-components/InputText'
import { Header, Title, Text } from '@smartcoop/web-containers/layouts/GuestLayout/theme'
import ProducerRegisterTermModal from '@smartcoop/web-containers/modals/ProducerRegisterTermModal/ProducerRegisterTermModal'

import { Container, ButtonContainer } from './styles'

const TakePictureForm = forwardRef((props, formRef) => {
  const { onSubmit, setDisabledNext, termData, onCancelModal, defaultValues, orgs } = props
  const webcamRef = useRef(null)

  const { createDialog } = useDialog()

  const [image, setImage] = useState(defaultValues?.picture)
  const [termAccepted, setTermAccepted] = useState(!!defaultValues?.picture)

  const capture = useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot()
      formRef.current.getFieldRef('picture').setValue(imageSrc)
      setImage(imageSrc)
    },
    [formRef]
  )
  const t = useT()

  const handleSubmit = useCallback(
    async (data) => {
      onSubmit(data)
    },
    [onSubmit]
  )

  const reset = useCallback(
    () => {
      setImage(null)
    },
    []
  )

  const videoConstraints = {
    width: 300,
    height: 300,
    facingMode: 'user'
  }

  useEffect(() => {
    setDisabledNext(!image)
  },[image, setDisabledNext])

  useEffect(() => {
    createDialog({
      id: 'organization-onboarding',
      Component: ProducerRegisterTermModal,
      props: {
        termData,
        onCancel: onCancelModal,
        setTermAccepted,
        orgs
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Header>
        <Title style={ { alignSelf: 'flex-end' } }>
          <I18n>welcome to smartcoop</I18n>
        </Title>
      </Header>
      <Text>
        {t('if your are an associated producer to one of the participants cooperatives of the smartcoop project and you are not registered in the platform, register by following the steps below')}
      </Text>
      <Form
        ref={ formRef }
        schemaConstructor={ takePictureSchema }
        onSubmit={ handleSubmit }
      >
        <InputText
          name="picture"
          style={ { display: 'none' } }
        />

        {image ? (
          <img src={ image } style={ { height: 300, width:300 } }/>
        ) : termAccepted && (
          <Webcam
            mirrored
            audio={ false }
            height={ 300 }
            ref={ webcamRef }
            screenshotFormat="image/jpeg"
            width={ 300 }
            videoConstraints={ videoConstraints }
          />
        )}

        <ButtonContainer>
          <Button color="secondary" onClick={ image ? reset : capture }>{image ? t('reset') : t('capture')}</Button>
        </ButtonContainer>
      </Form>
    </Container>
  )
})

TakePictureForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancelModal: PropTypes.func.isRequired,
  termData: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
  orgs: PropTypes.array,
  setDisabledNext: PropTypes.func
}

TakePictureForm.defaultProps = {
  onSubmit: () => {},
  orgs: [],
  setDisabledNext: () => {}
}

export default TakePictureForm
