import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import{ useT } from '@smartcoop/i18n'
import Modal from '@smartcoop/web-components/Modal'
import FieldCostsForm from '@smartcoop/web-containers/forms/digitalProperty/field/FieldCostsForm'

import { Container } from './styles'

const FieldCostsModal = (props) => {
  const {
    open,
    id,
    growingSeason,
    onExit
  } = props

  const t = useT()
  const fieldCostsFormRef = useRef(null)

  return (
    <Modal id={ id } open={ open } title={ t('field pendencies lists') } maxWidth='lg' fullWidth disableEsc onClose={ onExit }>
      <Container>
        <FieldCostsForm
          growingSeason={ growingSeason }
          ref={ fieldCostsFormRef }
          withoutSubmitButton
        />
      </Container>
    </Modal>
  )
}

FieldCostsModal.propTypes = {
  id: PropTypes.string.isRequired,
  growingSeason: PropTypes.object.isRequired,
  onExit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

FieldCostsModal.defaultProps = {
}

export default FieldCostsModal
