import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import fonts from '@smartcoop/styles/fonts'
import Card from '@smartcoop/web-components/Card'

export const Container = styled(Card).attrs({
  cardContentProps: {
    style: {
      padding: 15
    }
  },
  cardStyle: {
    flex: 1,
    width: '100%'
  }
})``

export const Title = styled(Typography)`
  font-size: ${ fonts.fontSize.M }px;
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: ${ fonts.fontWeight.bold };
  padding-left: 5px;
`

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
  padding: 0 5px;
`

export const ListContainer = styled.div`
  margin-top: 20px;
`
export const UpperRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  max-width: 30px;
  margin-left: 40px;
  margin-top: 20px;
  align-items: center;
`
