import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import { colors } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'


export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 20px 10px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const LeftItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
`

export const RightItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`

export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid ${ colors.lightGrey };
  margin: 0;
  margin-bottom: 5px;
`

export const Text = styled(Typography)`
  color: ${ colors.text };
  font-size: 14px;
  font-family: ${ fonts.fontFamilyMontserrat };
`