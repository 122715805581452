import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import MuiIconButton from '@material-ui/core/IconButton'
import { useTheme, ThemeProvider } from '@material-ui/core/styles'

import { colors } from '@smartcoop/styles'
import CircularLoader from '@smartcoop/web-components/Loader/CircularLoader'
import Tooltip from '@smartcoop/web-components/Tooltip'

import useStyles from './styles'

const IconButton = (props) => {
  const {
    withOpacity,
    children,
    className,
    tooltip,
    marginVertical,
    marginHorizontal,
    shadow,
    loading,
    styleVariant,
    placement,
    onClick,
    color,
    parseColor,
    ...otherProps
  } = props

  const classes = useStyles()

  const classesNames = []

  const mainTheme = useTheme()

  const buttonColor = useMemo(
    () => get(colors, color) || mainTheme.palette.primary.main,
    [color, mainTheme.palette.primary.main]
  )

  if (shadow) {
    classesNames.push(classes.btnShadow)
  }
  if (withOpacity) {
    classesNames.push(classes.withOpacity)
  }
  if (!isEmpty(classes[styleVariant])) {
    classesNames.push(classes[styleVariant])
  }
  if (className) {
    classesNames.push(className)
  }

  let button = (
    <MuiIconButton
      { ...otherProps }
      color={ parseColor ? 'primary' : color }
      className={ classesNames.join(' ') }
      onClick={ loading ? () => {} : onClick }
    >
      {loading ? <CircularLoader color="inherit" size={ 22 } /> : children}
    </MuiIconButton>
  )

  if (!isEmpty(tooltip)) {
    button = (
      <Tooltip title={ tooltip } placement={ placement }>
        {button}
      </Tooltip>
    )
  }
  if (marginVertical || marginHorizontal) {
    button = (
      <div
        className={ [
          marginHorizontal ? classes.iconButtonMarginHorizontal : null,
          marginVertical ? classes.iconButtonMarginVertical : null
        ].join(' ') }
      >
        {button}
      </div>
    )
  }

  return (
    <ThemeProvider
      theme={ theme => !parseColor ? theme : ({
        ...theme,
        palette: {
          ...theme.palette,
          primary: {
            main: buttonColor,
            contrastText: mainTheme.palette.getContrastText(buttonColor)
          }
        }
      }) }
    >
      {button}
    </ThemeProvider>
  )
}

IconButton.propTypes = {
  withOpacity: PropTypes.bool,
  children: PropTypes.any,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  marginVertical: PropTypes.bool,
  marginHorizontal: PropTypes.bool,
  shadow: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  loading: PropTypes.bool,
  parseColor: PropTypes.bool,
  styleVariant: PropTypes.string,
  placement: PropTypes.string,
  color: PropTypes.string,
  /** Used to control the button's purpose,
   This property passes the value to the type attribute of the native button component. */
  type: PropTypes.oneOf(['button', 'submit', 'reset'])
}

IconButton.defaultProps = {
  withOpacity: false,
  children: '',
  onClick: () => {},
  tooltip: null,
  className: null,
  marginVertical: false,
  marginHorizontal: true,
  shadow: false,
  size: 'medium',
  loading: false,
  parseColor: true,
  styleVariant: null,
  placement: 'bottom',
  color: 'primary',
  type: 'button'
}

export default IconButton
