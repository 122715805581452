import React, { useRef, useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import find from 'lodash/find'

import Grid from '@material-ui/core/Grid'

import { useDialog } from '@smartcoop/dialog'
import I18n from '@smartcoop/i18n'
import {
  barn,
  currencyWithoutSpace,
  shoppingPlatform,
  smallPlant,
  dashboard,
  chat
} from '@smartcoop/icons'
import { AuthenticationActions } from '@smartcoop/stores/authentication/duckAuthentication'
import {
  selectUserHaveDigitalProperty,
  selectUserHaveCommercialization,
  selectUserHaveShoppingPlatform,
  selectModulesHaveTechnical,
  selectUserHaveAdministration,
  selectUserIsSupplier,
  // selectUserHaveChat,
  selectTerms
} from '@smartcoop/stores/authentication/selectorAuthentication'
import { AVAILABLE_MODULES } from '@smartcoop/stores/module'
import pointsBottomLeft from '@smartcoop/styles/assets/images/points-bottom-left.svg'
import pointsTopRight from '@smartcoop/styles/assets/images/points-top-right.svg'
import logo from '@smartcoop/styles/assets/images/smartcoop-logo-inverted.svg'
import colors from '@smartcoop/styles/colors'
import Icon from '@smartcoop/web-components/Icon'
import useTerm from '@smartcoop/web-containers/hooks/useTerm'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import useStyles, {
  Content,
  Title,
  ButtonCard,
  ButtonCardContent,
  ButtonCardTitle
} from './styles'

const ChooseModuleScreen = () => {
  const { routes } = useRoutes()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const hasDigitalProperty = useSelector(selectUserHaveDigitalProperty)
  const hasCommercialization = useSelector(selectUserHaveCommercialization)
  const hasShoppingPlatform = useSelector(selectUserHaveShoppingPlatform)
  const hasTechnical = useSelector(selectModulesHaveTechnical)
  const hasAdministration = useSelector(selectUserHaveAdministration)
  // const hasChat = useSelector(selectUserHaveChat)
  const terms = useSelector(selectTerms)
  const userIsSupplier = useSelector(selectUserIsSupplier)
  const { removeDialog } = useDialog()

  const [privacyModal, selectedPrivacyTerm] = useTerm('privacy-term', false)
  const [termModal] = useTerm('use-term', false)
  const mounted = useRef(false)

  const acceptedPrivacyTerm = !!find(terms, term => term.termType.slug === 'privacy-term' && term?.userTerm)
  const acceptedUseTerm = !!find(terms, term => term.termType.slug === 'use-term' && term?.userTerm)

  const wasAcceptedPrivacyAndNotUse = useMemo(() => acceptedPrivacyTerm && !acceptedUseTerm,[acceptedPrivacyTerm, acceptedUseTerm])

  const initModule = useCallback(
    (module) => {
      history.push(routes.chooseOrganization.path, { module })
    },
    [history, routes]
  )

  const goToChat = useCallback(
    () => history.push(routes.chatList.path),
    [history, routes]
  )

  useEffect(() => {
    if (mounted.current) {
      dispatch(AuthenticationActions.loadPermissions())
      if(wasAcceptedPrivacyAndNotUse) {
        termModal()
      } else {
        privacyModal(userIsSupplier ? () => {} : termModal)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrivacyTerm, mounted.current])

  useEffect(() => {
    mounted.current = true
  }, [])

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  useEffect(
    () => {
      removeDialog({ id: 'change-property' })
      removeDialog({ id: 'property-onboarding' })
    }, [removeDialog]
  )

  return (
    <div className={ classes.container }>
      <div className={ classes.contentScroll }>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={ 11 } sm={ 11 } md={ 11 } lg={ 10 }>
            <Title>
              <I18n>choose a module to continue</I18n>
            </Title>

            <Content>
              {hasDigitalProperty && (
                <ButtonCard
                  id={ AVAILABLE_MODULES.digitalProperty }
                  onClick={ () => initModule(AVAILABLE_MODULES.digitalProperty) }
                >
                  <ButtonCardContent>
                    <Icon icon={ barn } color={ colors.secondary } size={ 100 } />
                    <ButtonCardTitle>
                      <I18n>digital property</I18n>
                    </ButtonCardTitle>
                  </ButtonCardContent>
                </ButtonCard>
              )}

              {hasCommercialization && (
                <ButtonCard
                  id={ AVAILABLE_MODULES.commercialization }
                  onClick={ () =>
                    initModule(AVAILABLE_MODULES.commercialization)
                  }
                >
                  <ButtonCardContent>
                    <Icon
                      icon={ currencyWithoutSpace }
                      color={ colors.secondary }
                      size={ 100 }
                    />
                    <ButtonCardTitle>
                      <I18n>commercialization</I18n>
                    </ButtonCardTitle>
                  </ButtonCardContent>
                </ButtonCard>
              )}

              {hasShoppingPlatform && (
                <ButtonCard
                  id={ AVAILABLE_MODULES.shoppingPlatform }
                  onClick={ () => initModule(AVAILABLE_MODULES.shoppingPlatform) }
                >
                  <ButtonCardContent>
                    <Icon
                      icon={ shoppingPlatform }
                      color={ colors.secondary }
                      size={ 100 }
                    />
                    <ButtonCardTitle>
                      <I18n>shopping</I18n>
                    </ButtonCardTitle>
                  </ButtonCardContent>
                </ButtonCard>
              )}

              {hasTechnical && (
                <ButtonCard
                  id={ AVAILABLE_MODULES.technical }
                  onClick={ () => initModule(AVAILABLE_MODULES.technical) }
                >
                  <ButtonCardContent>
                    <Icon
                      icon={ smallPlant }
                      color={ colors.secondary }
                      size={ 100 }
                    />
                    <ButtonCardTitle>
                      <I18n>technical area</I18n>
                    </ButtonCardTitle>
                  </ButtonCardContent>
                </ButtonCard>
              )}

              {hasAdministration && (
                <ButtonCard
                  id={ AVAILABLE_MODULES.administration }
                  onClick={ () => initModule(AVAILABLE_MODULES.administration) }
                >
                  <ButtonCardContent>
                    <Icon
                      icon={ dashboard }
                      color={ colors.secondary }
                      size={ 100 }
                    />
                    <ButtonCardTitle>
                      <I18n>administration</I18n>
                    </ButtonCardTitle>
                  </ButtonCardContent>
                </ButtonCard>
              )}

              <ButtonCard
                id={ AVAILABLE_MODULES.chat }
                onClick={ () => goToChat() }
              >
                <ButtonCardContent>
                  <Icon
                    icon={ chat }
                    color={ colors.secondary }
                    size={ 100 }
                  />
                  <ButtonCardTitle>
                    <I18n>chat</I18n>
                  </ButtonCardTitle>
                </ButtonCardContent>
              </ButtonCard>
            </Content>

            <div className={ classes.logoContainer }>
              <img alt="smartcoop" src={ logo } width={ 150 } />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={ classes.background }>
        <div className={ classes.backgroundImageLeftContainer }>
          <img
            alt="background"
            src={ pointsBottomLeft }
            className={ classes.backgroundImage }
          />
        </div>
        <div className={ classes.backgroundImageRightContainer }>
          <img
            alt="background"
            src={ pointsTopRight }
            className={ classes.backgroundImage }
          />
        </div>
      </div>
    </div>
  )
}

export default ChooseModuleScreen
