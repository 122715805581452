import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  inseminations: [],
  currentInsemination: null,
  inseminationTypes: [],
  error: null,
  blockInsemination: []
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadInseminations: ['params', 'onSuccess', 'onError'],
  loadInseminationsSuccess: ['params', 'page', 'onSuccess'],

  loadCurrentInsemination: ['currentInsemination', 'onSuccess', 'onError'],
  loadCurrentInseminationSuccess: ['currentInsemination', 'onSuccess', 'onError'],

  loadInseminationTypes: [],
  loadInseminationTypesSuccess: ['types'],

  saveInsemination: ['params', 'onSuccess', 'onError', 'isEdit'],
  saveOfflineInsemination: ['params', 'onSuccess', 'onError', 'web'],
  deleteInsemination: ['inseminationId', 'onSuccess', 'onError'],
  deleteOfflineInsemination: ['inseminationId', 'onSuccess', 'onError'],

  inseminationError: ['error'],
  addBlockInsemination: ['animalId'],
  resetBlockInsemination: []
})

markActionsOffline(Creators, [
  'loadInseminations',
  'saveInsemination',
  'deleteInsemination'
])

/**
 * Reducers functions
 */
const loadInseminationsSuccess = (state = INITIAL_STATE, { params, page }) => (
  {
    ...state,
    error: INITIAL_STATE.error,
    inseminations: page === 1 ? params : [
      ...state.inseminations,
      ...params
    ]
  }
)

const loadCurrentInseminationSuccess = (state = INITIAL_STATE, { currentInsemination }) => ({
  ...state,
  currentInsemination
})

const addBlockInsemination = (state = INITIAL_STATE, { animalId }) => ({
  ...state,
  blockInsemination: [...state.blockInsemination, animalId]
})

const resetBlockInsemination = (state = INITIAL_STATE) => ({
  ...state,
  blockInsemination: INITIAL_STATE.blockInsemination
})

const inseminationError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const loadInseminationTypesSuccess = (state = INITIAL_STATE, { types }) => ({
  ...state,
  types
})


const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_INSEMINATIONS_SUCCESS]: loadInseminationsSuccess,

  [Types.LOAD_CURRENT_INSEMINATION_SUCCESS]: loadCurrentInseminationSuccess,

  [Types.LOAD_INSEMINATION_TYPES_SUCCESS]: loadInseminationTypesSuccess,

  [Types.INSEMINATION_ERROR]: inseminationError,

  [Types.ADD_BLOCK_INSEMINATION]: addBlockInsemination,
  [Types.RESET_BLOCK_INSEMINATION]: resetBlockInsemination,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as InseminationTypes,
  Creators as InseminationActions
}
