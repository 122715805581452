import React, { useMemo, useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'


import filterAnimalBirthSchema from '@smartcoop/forms/schemas/dairyFarm/filterAnimaBirth.schema'
import I18n, { useT } from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputUnit from '@smartcoop/web-components/InputUnit'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

import { Container, Item, ButtonContainer } from './styles'

const FilterAnimalBirthForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()
  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  const defaultOptions = useMemo(() => (
    [
      {
        label: 'Assistido',
        value: 'assistido'
      },
      {
        label: 'Normal',
        value: 'normal'
      },
      {
        label: 'Distocico',
        value: 'distocico'
      }
    ]
  ), [])

  const situationOptions = useMemo(
    () => ([
      {
        label: 'Vivo',
        value: true
      },
      {
        label: 'Morto',
        value: false
      }
    ]), []
  )

  const genderOptions = useMemo(
    () => ([
      {
        label: 'Macho',
        value: 'macho'
      },
      {
        label: 'Fêmea',
        value: 'femea'
      }
    ]), []
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterAnimalBirthSchema }
        onSubmit={ handleSubmit }
      >
        <Item>
          <InputSelect
            label={ t('type') }
            placeholder={ t('type') }
            name="birthType"
            options={ defaultOptions }
            fullWidth
            defaultValue={ filters.birthType }
          />
        </Item>
        <Item>

          <InputDate
            label={ t('expected date') }
            placeholder={ t('expected date') }
            name="expectedDate"
            fullWidth
            defaultValue={ filters.expectedDate }
          />
        </Item>
        <Item>

          <InputDate
            label={ t('occurrence date') }
            placeholder={ t('occurrence date') }
            name="occurrenceDate"
            fullWidth
            defaultValue={ filters.occurrenceDate }
          />
        </Item>
        <Item>

          <InputUnit
            label={ t('weight') }
            placeholder={ t('weight') }
            name="weight"
            fullWidth
            defaultValue={ filters.weight }
            unit="Kg"
          />
        </Item>

        <Item>
          <RadioGroup
            label={ t('gender') }
            placeholder={ t('gender') }
            name="gender"
            options={ genderOptions }
            fullWidth
            variant="row"
            defaultValue={ filters.gender }
          />
        </Item>

        <Item>

          <RadioGroup
            label={ t('situation') }
            placeholder={ t('situation') }
            name="isAlive"
            variant="row"
            options={ situationOptions }
            fullWidth
            defaultValue={ filters.isAlive }
          />
        </Item>

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="animalBirth-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterAnimalBirthForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterAnimalBirthForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterAnimalBirthForm
