import React from 'react'

import PropTypes from 'prop-types'

import {
  Container,
  UpperContainer,
  UnderContainer
} from './styles'

const HorizontalSplitScreenLayout = (props) => {
  const {
    upperChildren,
    upperChildrenStyle,
    underChildren,
    underChildrenStyle,
    style
  } = props

  return (
    <Container style={ style }>
      <UpperContainer style={ upperChildrenStyle }>
        {upperChildren}
      </UpperContainer>
      <UnderContainer style={ underChildrenStyle }>
        {underChildren}
      </UnderContainer>
    </Container>
  )
}

HorizontalSplitScreenLayout.propTypes = {
  underChildren: PropTypes.element,
  underChildrenStyle: PropTypes.object,
  upperChildren: PropTypes.element,
  upperChildrenStyle: PropTypes.object,
  style: PropTypes.object
}


HorizontalSplitScreenLayout.defaultProps = {
  underChildren: null,
  underChildrenStyle: {},
  upperChildren: null,
  upperChildrenStyle: {},
  style: {}
}


export default HorizontalSplitScreenLayout
