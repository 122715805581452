export default ({ size, text = '.PDF' } = {}) =>`
<?xml version="1.0" encoding="utf-8"?>
<svg width="${ size }" height="${ size }" viewBox="0 0 56.25 56.25" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <clipPath id="clipPath16">
      <path d="M 0,0 0,45 45,45 45,0 0,0" id="path18"/>
    </clipPath>
  </defs>
  <g id="g12" transform="matrix(1.25, 0, 0, -1.25, 8.881784197001252e-16, 56.250017773360696)">
    <g clip-path="url(#clipPath16)" id="g14">
      <g id="g20">
        <g id="g22">
          <g id="g24">
            <path d="m 9.347,6.027 0,32.946 c 0,0.843 0.684,1.527 1.527,1.527 l 13.349,0 11.43,-11.43 0,-23.043 C 35.653,5.184 34.969,4.5 34.126,4.5 l -23.252,0 c -0.843,0 -1.527,0.684 -1.527,1.527 z" id="path26" style="fill:#2b2b2b;fill-opacity:1;fill-rule:evenodd;stroke:none"/>
          </g>
        </g>
        <g id="g28">
          <g id="g30">
            <path d="m 24.223,40.5 11.43,-11.43 -9.903,0 c -0.843,0 -1.527,0.684 -1.527,1.527 l 0,9.903 z" id="path32" style="fill:#525252;fill-opacity:1;fill-rule:evenodd;stroke:none"/>
          </g>
        </g>
      </g>
    </g>
  </g>
  <text style="fill: rgb(255, 255, 255); font-family: Bahnschrift; font-size: 12px; text-anchor: end; text-transform: uppercase; white-space: pre;" x="44.156" y="47.788" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 8.881784197001252e-16, 0)">${ text }</text>
</svg>
`

