import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import Button from '@smartcoop/web-components/Button'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 25px 30px;
`
export const RowContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`

export const HeaderText = styled(Typography)`
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: ${ colors.text };
`

export const MainText = styled(Typography)`
  text-align: center;
  color: ${ colors.text };
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`

export const MainButton = styled(Button)`
  margin-top: 20px;
  background-color: ${ colors.black };
  color: ${ colors.white };
`

export const TextUnderlined = styled(Typography).attrs({
  component: 'span'
})`
  margin: 22px 0 0;
  font-size: 14px;
  font-weight: 600;
  width: max-content;
  text-decoration: underline;
  cursor: pointer;
`
