/* eslint-disable no-param-reassign */

import { Database } from '@nozbe/watermelondb'

import get from 'lodash/get'

import { getPermissions } from '../../services/apis/smartcoopApi/resources/permissions'
import { profilePictureService } from './profilePictureService'

const USER_FIELDS = [
  // 'name',
  'userId',
  'userCode',
  'profilePhoto',
  'publicKey',
  'privateKey'
]

export function userService(database: Database) {
  async function saveUserData(data) {
    const { data: permissionsResponse } = await getPermissions()
    const permissions = permissionsResponse?.map(item => item.profile.id)

    await database.write(async () => {
      const response = await database.get('user').query().fetch()

      if (response.length) {
        await response.forEach(async user => {
          await user.markAsDeleted()
        })
      }

      await database.get('user').create(user => {
        USER_FIELDS.forEach(field => { user[field] = data[field] })
        user.name = data?.chatNickname || data?.name
        user.profiles = JSON.stringify(permissions)
      })

      if (data?.profilePhoto) {
        const image = get(data?.profilePhoto?.split('.com/'), '[1]', null)

        if (image) {
          await profilePictureService(database).createOrUpdate([
            {
              userId: data?.userId,
              photo: image,
              localUser: true
            }
          ])
        }
      }
    })
  }

  async function getUserData() {
    const registers = await database.get('user').query().fetch()
    // Return first user on database
    return registers[0]
  }

  async function updateProfilePicture(profilePhoto) {
    try {
      await database.write(async () => {
        const localUser = await getUserData()

        await localUser.update(item => {
          item.profilePhoto = profilePhoto
        })

        if (profilePhoto) {
          const image = get(profilePhoto?.split('.com/'), '[1]', null)

          if (image) {
            await profilePictureService(database).createOrUpdate([
              {
                userId: localUser?.userId,
                photo: image,
                localUser: true
              }
            ])
          }
        }
      })
    } catch(error) {
      console.log('Erro ao atualizar foto: ', error)
    }
  }

  async function getPrivateKey() {
    const register = await getUserData()
    return register?.privateKey
  }

  function observeAuthenticatedUser() {
    return database.collections.get('user')
      .query()
      .observe()
  }

  return {
    saveUserData,
    getUserData,
    getPrivateKey,
    updateProfilePicture,
    observeAuthenticatedUser
  }
}
