// eslint-disable-next-line spaced-comment
export default ({ size } = {}) => /*html*/`
<svg width="${ size }" height="${ size }" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.4999 5.15508C15.4788 5.15508 15.4615 5.16233 15.4436 5.16233C15.4821 4.97937 15.4999 4.78915 15.4999 4.59224C15.4999 3.03706 14.2415 1.77752 12.6877 1.77752C11.8228 1.77752 11.0598 2.17524 10.5432 2.79107C9.88949 1.52428 8.58483 0.651855 7.06281 0.651855C4.89042 0.651855 3.12542 2.41454 3.12542 4.5928C3.12542 4.8494 3.15328 5.09595 3.19898 5.3386C1.85252 5.78206 0.875 7.03825 0.875 8.53375C0.875 10.3985 2.38643 11.9119 4.25007 11.9119H15.4999C17.363 11.9119 18.875 10.3991 18.875 8.53375C18.875 6.66787 17.363 5.15508 15.4999 5.15508Z" fill="#C1C1C1"/>
<path d="M5.34702 15.3655C5.97338 15.3655 6.48114 14.8584 6.48114 14.2328C6.48114 13.6072 5.97338 13.1001 5.34702 13.1001C4.72066 13.1001 4.21289 13.6072 4.21289 14.2328C4.21289 14.8584 4.72066 15.3655 5.34702 15.3655Z" fill="#199CD8"/>
<path d="M9.88413 15.3655C10.5105 15.3655 11.0183 14.8584 11.0183 14.2328C11.0183 13.6072 10.5105 13.1001 9.88413 13.1001C9.25777 13.1001 8.75 13.6072 8.75 14.2328C8.75 14.8584 9.25777 15.3655 9.88413 15.3655Z" fill="#199CD8"/>
<path d="M14.4994 15.3655C15.1257 15.3655 15.6335 14.8584 15.6335 14.2328C15.6335 13.6072 15.1257 13.1001 14.4994 13.1001C13.873 13.1001 13.3652 13.6072 13.3652 14.2328C13.3652 14.8584 13.873 15.3655 14.4994 15.3655Z" fill="#199CD8"/>
<path d="M3.07749 18.7634C3.70385 18.7634 4.21161 18.2563 4.21161 17.6307C4.21161 17.0052 3.70385 16.498 3.07749 16.498C2.45112 16.498 1.94336 17.0052 1.94336 17.6307C1.94336 18.2563 2.45112 18.7634 3.07749 18.7634Z" fill="#199CD8"/>
<path d="M7.6146 18.7634C8.24096 18.7634 8.74872 18.2563 8.74872 17.6307C8.74872 17.0052 8.24096 16.498 7.6146 16.498C6.98823 16.498 6.48047 17.0052 6.48047 17.6307C6.48047 18.2563 6.98823 18.7634 7.6146 18.7634Z" fill="#199CD8"/>
<path d="M12.2318 18.7634C12.8581 18.7634 13.3659 18.2563 13.3659 17.6307C13.3659 17.0052 12.8581 16.498 12.2318 16.498C11.6054 16.498 11.0977 17.0052 11.0977 17.6307C11.0977 18.2563 11.6054 18.7634 12.2318 18.7634Z" fill="#199CD8"/>
</svg>
`
