import React, { useCallback, useRef } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import { filter } from '@smartcoop/icons'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import Modal from '@smartcoop/web-components/Modal'
import FilterProductGroupForm from '@smartcoop/web-containers/forms/shoppingPlatform/productGroup/FilterProductGroupForm/FilterProductGroupForm'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import useStyles, { Row, Content } from './styles'

const FilterProductGroupModal = ({ id, open, handleClose, onSubmit, filters }) => {
  const classes = useStyles()
  const filterProductGroupFormRef = useRef(null)

  const handleSubmit = useCallback(
    (value) => {
      onSubmit(value)
      handleClose()
    },
    [handleClose, onSubmit]
  )

  const clearForm = useCallback(
    () => {
      filterProductGroupFormRef.current.reset()
      onSubmit({})
      handleClose()
    },
    [handleClose, onSubmit]
  )

  return (
    // TODO fix custom close button size
    <Modal
      id={ id }
      open={ open }
      title={
        <Row>
          <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 }/>
          <I18n>filter</I18n>
        </Row>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        <Content>
          <FilterProductGroupForm
            ref={ filterProductGroupFormRef }
            onSubmit={ handleSubmit }
            withoutSubmitButton
            filters={ filters }
          />
          <ButtonsContainer>
            <Button
              id="clear"
              onClick={ clearForm }
              variant="outlined"
              style={ { marginRight: 7 } }
            >
              <I18n>clear</I18n>
            </Button>
            <Button
              id="filtrate"
              onClick={ () => filterProductGroupFormRef.current.submit() }
              color="black"
              style={ { marginLeft: 7 } }
            >
              <I18n>filtrate</I18n>
            </Button>
          </ButtonsContainer>
        </Content>
      </>
    </Modal>
  )}

FilterProductGroupModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  filters: PropTypes.object
}

FilterProductGroupModal.defaultProps = {
  onSubmit: () => {},
  filters: {}
}

export default FilterProductGroupModal
