export default ({ size, color = '#000' } = {}) => /* html */`
<?xml version="1.0" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
 "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="${ size }" height="${ size }" viewBox="0 0 260.000000 228.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,228.000000) scale(0.100000,-0.100000)"
fill="${ color }" stroke="none">
<path d="M1120 2225 c-220 -36 -442 -150 -601 -308 -243 -241 -355 -540 -328
-877 40 -499 433 -908 949 -986 122 -18 187 -18 319 2 245 36 463 144 631 313
140 141 236 307 287 496 24 89 27 119 27 265 1 177 -10 245 -60 378 -60 160
-143 284 -279 417 -143 140 -306 231 -510 285 -105 28 -316 35 -435 15z m347
-408 c121 -45 242 -126 243 -164 0 -7 5 -13 12 -13 8 0 6 -6 -4 -17 -16 -15
-20 -14 -55 18 -45 40 -100 66 -113 53 -6 -6 -3 -21 6 -39 32 -59 24 -105 -30
-183 -41 -60 -50 -81 -59 -145 -13 -90 0 -203 32 -287 20 -54 104 -170 127
-177 13 -4 69 122 79 180 9 48 22 47 30 -2 11 -64 -26 -223 -56 -242 -7 -4 -7
-20 1 -50 20 -71 18 -170 -3 -203 -39 -59 -127 -67 -257 -24 -83 28 -156 27
-250 -2 -86 -26 -176 -31 -213 -11 -54 29 -73 134 -42 236 12 42 12 50 0 59
-25 19 -47 98 -52 187 -7 106 8 123 31 35 21 -78 63 -169 75 -164 14 5 80 87
106 132 43 75 57 146 53 269 -4 122 -12 146 -73 231 -39 53 -45 102 -20 146
40 71 1 79 -81 16 -32 -24 -64 -42 -71 -39 -20 8 -15 23 7 23 11 0 20 6 20 13
0 6 19 33 43 58 42 46 106 85 182 109 89 30 247 28 332 -3z m-709 -182 c51
-22 102 -63 102 -83 -1 -41 -15 -44 -74 -12 -107 56 -222 36 -300 -51 -29 -31
-36 -47 -36 -79 0 -33 7 -47 41 -81 77 -77 192 -104 283 -66 47 21 56 16 56
-29 0 -32 -4 -37 -45 -55 -76 -34 -198 -28 -278 13 -194 98 -211 302 -34 414
81 53 199 64 285 29z m1254 14 c161 -34 264 -167 229 -297 -15 -55 -90 -131
-165 -166 -49 -23 -72 -28 -142 -28 -69 0 -91 4 -126 24 -39 22 -43 27 -40 58
2 19 5 36 7 38 1 2 20 -4 41 -13 150 -62 356 54 322 182 -10 39 -79 99 -127
113 -78 21 -165 9 -225 -33 -28 -20 -29 -20 -42 -2 -8 10 -14 25 -14 32 0 28
148 102 207 103 12 0 46 -5 75 -11z m-1036 -307 c16 -11 34 -48 34 -71 0 -29
-35 -27 -65 4 -28 27 -31 45 -13 63 14 14 28 15 44 4z m678 2 c23 -9 20 -50
-5 -73 -25 -23 -57 -33 -65 -19 -9 13 5 60 23 81 18 19 23 20 47 11z m-244
-871 c50 -15 90 -33 90 -39 0 -19 -66 -72 -97 -78 -124 -23 -217 -13 -273 33
-43 34 -49 57 -18 64 13 3 52 14 88 25 87 27 105 27 210 -5z"/>
<path d="M1103 820 c-27 -11 -39 -50 -24 -79 11 -20 29 -27 81 -33 37 -4 35
-31 -3 -48 -40 -19 -46 -44 -14 -65 21 -13 35 -14 91 -5 44 7 86 7 127 1 73
-12 109 0 109 36 0 18 -8 27 -30 34 -42 15 -42 49 1 49 64 0 102 44 74 86 -19
30 -56 31 -125 4 -67 -26 -116 -25 -196 5 -36 14 -67 25 -68 24 0 0 -11 -4
-23 -9z"/>
</g>
</svg>
`
