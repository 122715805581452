import { createActions, createReducer } from 'reduxsauce'

import filter from 'lodash/filter'

import { AuthenticationTypes } from '../../../authentication'

// Initial state
const INITIAL_STATE = {
  precipitations: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadData: ['onFinish'],

  loadAllPrecipitations: ['onFinish'],
  loadAllPrecipitationsSuccess: ['precipitations'],
  offlinePrecipitationUserDataError: ['error'],
  updateOfflinePrecipitationUserData: ['precipitation'],
  removeOfflinePrecipitation: ['precipitationId']
}, { prefix: 'OFFLINE_PRECIPITATION_USER_DATA' })

/**
 * Reducers functions
 */
const loadAllPrecipitationsSuccess = (state = INITIAL_STATE, { precipitations }) => ({
  ...state,
  error: INITIAL_STATE.error,
  precipitations
})

const offlinePrecipitationUserDataError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const updateOfflinePrecipitationUserData = (state = INITIAL_STATE, { precipitation }) => ({
  ...state,
  precipitations: [precipitation, ...filter(state.precipitations, prep => precipitation.id !== prep.id)]
})

const removeOfflinePrecipitation = (state = INITIAL_STATE, { precipitationId }) => ({
  ...state,
  precipitations: filter(state.precipitations, prep => precipitationId !== prep.id)
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_ALL_PRECIPITATIONS_SUCCESS]: loadAllPrecipitationsSuccess,

  [Types.OFFLINE_PRECIPITATION_USER_DATA_ERROR]: offlinePrecipitationUserDataError,
  [Types.UPDATE_OFFLINE_PRECIPITATION_USER_DATA]: updateOfflinePrecipitationUserData,
  [Types.REMOVE_OFFLINE_PRECIPITATION]: removeOfflinePrecipitation,
  [AuthenticationTypes.LOGOUT]: logout
})

export { Types as OfflinePrecipitationUserDataTypes, Creators as OfflinePrecipitationUserDataActions }
