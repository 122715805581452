import React, { useState, useCallback, useMemo } from 'react'

import { Report } from 'powerbi-report-component'
import PropTypes from 'prop-types'


const DirectionReportFragment = ({
  token,
  organizationId,
  getMicrosoftToken
}) => {
  // eslint-disable-next-line no-unused-vars
  const [report, setReport] = useState(null)
  const [showReport, setShowReport] = useState(true)

  const filters = useMemo(() => ({
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'dOrganizationUsers',
      column: 'organization_id'
    },
    operator: 'In',
    values: [organizationId]
  }), [organizationId])

  const handleReportLoad = useCallback(async (data) => {
    let currentFilters = []
    await data.getFilters().then((oldFilters => {
      currentFilters = oldFilters
    }))
    await data.setFilters([...currentFilters, filters])
    if (!showReport) {
      setShowReport(true)
    }

    setReport(data)
  }, [filters, showReport])


  const handleReportRender = useCallback(
    data => setReport(data),
    [setReport]
  )
  return (
    <Report
      tokenType="Aad"
      accessToken={ token }
      embedUrl="https://app.powerbi.com/reportEmbed"
      embedId="1d9e4b52-2bbd-44d6-a2bc-0b1c57efc3e3"
      groupId="049c3fb9-864c-4001-bfc1-1df372818bad"
      pageName="ReportSection"
      reportMode="View"
      permissions="Read"
      onLoad={ organizationId ? handleReportLoad : () => {} }
      onRender={ handleReportRender }
      eventHooks={ { accessTokenProvider: getMicrosoftToken } }
      style={ {
        width: '100%',
        height: '200%'
      } }
      extraSettings={ {
        filterPaneEnabled: !organizationId,
        navContentPaneEnabled: false,
        hideErrors: false
      } }
    />
  )
}

DirectionReportFragment.propTypes = {
  token: PropTypes.string.isRequired,
  getMicrosoftToken: PropTypes.func.isRequired,
  organizationId: PropTypes.string
}

DirectionReportFragment.defaultProps = {
  organizationId: null
}

export default DirectionReportFragment
