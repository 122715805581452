import React, { useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import I18n, { useT } from '@meta-react/i18n'
import moment from 'moment'
import PropTypes from 'prop-types'

import filterRainMapSchema from '@smartcoop/forms/schemas/property/fields/filterRainMap.schema'
import { useSnackbar } from '@smartcoop/snackbar'
import { WeatherForecastActions } from '@smartcoop/stores/weatherForecast'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import Modal from '@smartcoop/web-components/Modal'
import RainMapFragment from '@smartcoop/web-containers/fragments/RainMapFragment'
import RainScaleFragment from '@smartcoop/web-containers/fragments/RainScaleFragment'

import { FiltersContainer, BodyContainer } from './styles'

const RainMapModal = (props) => {
  const dispatch = useDispatch()
  const form = useRef(null)
  const snackbar = useSnackbar()
  const t = useT()

  const { id, open, property } = props

  const onSubmit = useCallback((data) => {
    if (moment(data.finalDate).diff(moment(data.initialDate), 'days') < 0) {
      return snackbar.warning(t('the start date must be less than end'))
    }

    if (
      moment(data.finalDate).diff(moment(), 'days') > 0 ||
      moment(data.initialDate).diff(moment(), 'days') > 0
    ) {
      return snackbar.warning(t('filtered dates must be less than or equal to current'))
    }

    return dispatch(WeatherForecastActions.searchAggregatedRain(
      moment(data.initialDate).format(momentBackDateFormat),
      moment(data.finalDate).format(momentBackDateFormat)
    ))
  }, [dispatch, snackbar, t])

  return (
    <Modal
      id={ id }
      open={ open }
      maxWidth="xl"
      title={ t('rain map') }
      disableFullScreen
      onClose={ () => onSubmit({
        initialDate: moment().subtract(30, 'days').format(momentBackDateFormat),
        finalDate: moment().format(momentBackDateFormat)
      }) }
    >
      <>
        <FiltersContainer>
          <Form
            ref={ form }
            schemaConstructor={ filterRainMapSchema }
            onSubmit={ onSubmit }
          >
            <InputDate
              name="initialDate"
              label={ t('initial date') }
              defaultValue={ moment().subtract(30, 'days').format(momentBackDateFormat) }
              fullWidth
            />

            <InputDate
              name="finalDate"
              label={ t('final date') }
              defaultValue={ moment().format(momentBackDateFormat) }
              fullWidth
            />

            <Button
              id="submit-search"
              onClick={ () => form.current.submit() }
              color="secondary"
              size="small"
              style={ { maxWidth: 227 } }
            >
              <I18n>search</I18n>
            </Button>
          </Form>

        </FiltersContainer>
        <BodyContainer>
          <RainMapFragment property={ property } style={ { borderRadius: '10px 10px 0 0' } } />
        </BodyContainer>
        <RainScaleFragment />
      </>
    </Modal>
  )
}

RainMapModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  property: PropTypes.object.isRequired
}

export default RainMapModal
