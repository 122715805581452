import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'


const addressEdit = ({ t }) => Yup.object().shape({

  postalCode: flow(
    required({ t })
  )(Yup.string()),

  state: flow(
  )(Yup.string()),

  city: flow(

  )(Yup.string()),

  neighborhood: flow(
  )(Yup.string()),

  street: flow(
  )(Yup.string()),

  number: flow(
  )(Yup.string())

})

export default addressEdit
