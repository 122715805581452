/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import round from 'lodash/round'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import Divider from '@material-ui/core/Divider'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { arrowDown, emptyFilter } from '@smartcoop/icons'
import { editCostsByBulk, getFieldCostsByProperty } from '@smartcoop/services/apis/smartcoopApi/resources/costs'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import { formatCurrency } from '@smartcoop/utils/formatters'
import { convertValues, getBaseVolume, getExtenseBaseVolume } from '@smartcoop/utils/functions'
import Button from '@smartcoop/web-components/Button'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import InputUnit from '@smartcoop/web-components/InputUnit'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'

import useStyles, { Container, Table, ButtonContainer } from './styles'

const InputsListModal = (props) => {
  const {
    open,
    id,
    getCosts,
    year
  } = props

  const t = useT()

  const { createDialog } = useDialog()
  const property = useSelector(selectCurrentProperty)
  const classes = useStyles()

  const [costs, setCosts] = useState([])
  const [editedCosts, setEditedCosts] = useState({})
  const [loading, setLoading] = useState(false)

  const fromManagementCosts = useMemo(() => orderBy(filter(flatMap(costs, item => item.costs), item => item?.cropManagementId), 'realizationDate'), [costs])

  const currentCosts = useMemo(() => groupBy(map(fromManagementCosts, item => {
    const foundCropManagement = find(flatMap(costs, cost => cost?.cropManagements), management => management.id === item.cropManagementId)

    const foundGrowingSeason = find(costs, gs => find(gs.costs, gsCost => gsCost.id === item?.id))
    let dose = null
    let detailedProduct = null

    map(foundCropManagement?.cropsManagementItems, managementItem => {
      if(managementItem?.items?.referenceId === item?.id && managementItem?.items?.product === 'formulado') {
        detailedProduct = `Formulado ${ managementItem?.items?.n }-${ managementItem?.items?.p }-${ managementItem?.items?.k }`
      }
      if(managementItem?.items?.referenceId === item?.id && managementItem?.items?.dose) {
        dose = `${ toNumber(managementItem?.items?.dose) } ${ managementItem?.items?.unit }`
      }
      map(managementItem?.items?.formFields, formField => {
        if(formField?.referenceId === item?.id && formField?.dosePhyntosanitary) {
          dose = `${ toNumber(formField?.dosePhyntosanitary) } ${ formField?.unit }`
        }
        if(formField?.referenceId === item?.id && formField?.product === 'formulado') {
          detailedProduct = `Formulado ${ formField?.n }-${ formField?.p }-${ formField?.k }`
        }
      })
    } )
    if(!detailedProduct) {
      detailedProduct = item?.product?.shortDescription
    }
    return {
      ...item,
      cropManagement: foundCropManagement,
      dose,
      detailedProduct,
      growingSeason: foundGrowingSeason
    }
  }), groupedItem => `${  groupedItem?.detailedProduct }|${ groupedItem?.cropManagement?.operationId }`),[costs, fromManagementCosts])

  const getCostsGrowingSeason = useCallback(async () => {
    setLoading(true)
    const { data } = await getFieldCostsByProperty({ propertyId: property?.id, year })
    setCosts(data)
    setLoading(false)
  }, [property, year])

  const saveCosts = useCallback(() => {
    createDialog({
      id: 'confirm-save-rubric',
      Component: ConfirmModal,
      props: {
        onConfirm: async () => {
          await editCostsByBulk(map(editedCosts, (item) => ({ ...item }))).then(
            () => {
              getCostsGrowingSeason()
            }
          )
        },
        textWarning: t('are you sure you want to save these changes')
      }
    })
  }, [createDialog, editedCosts, getCostsGrowingSeason, t])

  useEffect(() => {
    getCostsGrowingSeason()
  },[getCostsGrowingSeason])

  const handleDoseChange = useCallback(
    debounce((valueDose, row, convertedCost, area) => {
      const valueHA = toNumber(valueDose) * convertedCost
      setEditedCosts(old => ({
        ...old,
        [row?.referenceId]: {
          ...old?.[row?.referenceId],
          id: row?.id,
          valueDose: !isEmpty(valueDose) ? toNumber(valueDose) : valueDose,
          valueHA,
          value: valueHA*area
        }
      }))
    }, 800),
    [] // Dependencies should be empty to prevent recreation of the debounced function
  )

  const handleHAChange = useCallback(
    debounce((valueHA, row, convertedCost, disabled, area) => {
      let valueDose
      if (!disabled) {
        valueDose = toNumber(valueHA) / convertedCost
      }

      setEditedCosts(old => ({
        ...old,
        [row?.referenceId]: {
          ...old?.[row?.referenceId],
          id: row?.id,
          valueDose,
          valueHA: !isEmpty(valueHA) ? toNumber(valueHA) : valueHA,
          value: valueHA*area
        }
      }))
    }, 800),
    [] // Dependencies should be empty to prevent recreation of the debounced function
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={ t('list of inputs') }
      classes={ { paper: classes.modalBackground } }
      maxWidth='lg'
      fullWidth
      disableEsc
      contentContainerStyle={ { padding: 0, minWidth: 700 } }
      headerProps={ {
        disableTypography: true
      } }
      onExit={ getCosts }
    >
      {loading ? (
        <Loader width={ 100 } />
      ) : (
        <Container>
          {isEmpty(currentCosts) ? (
            <EmptyState
              text={ t('no data found') }
              icon={ emptyFilter }
              iconProps={ { size: 100 } }
            />
          ) : (
            <Table>
              {map(currentCosts, (grouped) => (
                <>
                  <thead>
                    <tr>
                      <th style={ { textAlign: 'left' } }>
                        {grouped?.[0]?.cropManagement?.operation?.name}
                      </th>
                      <th>
                        {grouped[0]?.product?.productGroup?.name } - {grouped[0]?.detailedProduct}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(grouped, (groupedCost, index) => {
                      const area = groupedCost?.growingSeason?.childrenPolygonId ? find(groupedCost?.growingSeason?.fieldData?.childrenPolygons, item => item?.id === groupedCost?.growingSeason?.childrenPolygonId)?.area : groupedCost?.growingSeason?.fieldData?.area

                      const foundChildrenPolygon = groupedCost?.growingSeason?.childrenPolygonId ? find(groupedCost?.growingSeason?.fieldData?.childrenPolygons, childrenPolygon => childrenPolygon?.id === groupedCost?.growingSeason?.childrenPolygonId) : false

                      const management = find(groupedCost?.growingSeason?.cropManagements || groupedCost?.growingSeason?.cropsManagements, item => item.id === groupedCost?.cropManagementId)

                      const foundItem = find(flatMap(management?.cropsManagementItems, item => item?.items?.formFields), item => item?.referenceId === groupedCost?.referenceId)
                      const convertedCost = convertValues(foundItem?.dosePhyntosanitary || foundItem?.dose, foundItem?.unit, getBaseVolume(foundItem?.unit))

                      const disabled = isEmpty(foundItem?.unit) || (isEmpty(toString(foundItem?.dosePhyntosanitary)) && isEmpty(toString(foundItem?.dose)))

                      return (
                        <tr>
                          <td>
                            <div style={ { display: 'flex', flexDirection: 'column' } }>
                              <div>
                                {groupedCost?.growingSeason?.fieldData?.fieldName}{foundChildrenPolygon?.name ? ` - ${ foundChildrenPolygon?.name }` : null}
                              </div>
                              <div style={ { fontSize: 12, color: colors.mutedText } }>
                                {round(area, 2)}ha
                              </div>
                            </div>
                          </td>
                          <td>
                            <div style={ { display: 'flex', flexDirection: 'column' } }>
                              <div>
                                {groupedCost?.growingSeason?.crop?.description}/{groupedCost?.growingSeason?.sowingYear}
                              </div>
                              <div style={ { fontSize: 12, color: colors.mutedText } }>
                                {foundItem?.dosePhyntosanitary || foundItem?.dose} { foundItem?.unit}
                              </div>
                            </div>
                          </td>
                          <td style={ { width: '10%' } }>
                            {editedCosts?.[groupedCost?.referenceId] ? formatCurrency(editedCosts?.[groupedCost?.referenceId]?.value) : groupedCost?.value ? formatCurrency(groupedCost?.value) : '-'}
                          </td>
                          <td>
                            <InputUnit
                              disabled={ disabled }
                              detached
                              type="float"
                              unit={ `R$/${ foundItem?.unit ? getExtenseBaseVolume(foundItem?.unit) : 'dose' }` }
                              fullWidth
                              decimalScale={ 2 }
                              style={ { marginBottom: '0' } }
                              value={ round(editedCosts?.[groupedCost?.referenceId]?.valueDose || (groupedCost?.value ? (groupedCost?.value/area) / convertedCost : null), 2) }
                              onChange={ ({ target: { value } }) => handleDoseChange(value, groupedCost, convertedCost, area) }
                            />
                          </td>
                          <td>
                            {(groupedCost?.value > 0 || editedCosts?.[groupedCost?.referenceId]?.value > 0) && editedCosts?.[groupedCost?.referenceId]?.value !== 0 && index < grouped.length-1 ? (
                              <IconButton
                                style={ { width: '45px' } }
                                tooltip="Replicar ao grupo"
                                onClick={ () => {
                                  setEditedCosts(old => {
                                    const updated = { ...old }
                                    const valueDose = old?.[groupedCost?.referenceId]?.valueDose || (groupedCost?.value ? (groupedCost?.value/area) / convertedCost : null)
                                    map(grouped, (item, position) => {
                                      if(position > index) {
                                        const managementItem = find(item?.growingSeason?.cropManagements || item?.growingSeason?.cropsManagements, crop => crop.id === item?.cropManagementId)
                                        const foundCostItem = find(flatMap(managementItem?.cropsManagementItems, crop => crop?.items?.formFields), crop => crop?.referenceId === item?.referenceId)

                                        if(foundCostItem?.unit === foundItem?.unit) {
                                          updated[item?.referenceId] = {
                                            ...updated?.[item?.referenceId],
                                            id: item?.id,
                                            valueDose
                                          }
                                        }
                                      }
                                    })
                                    return updated
                                  })
                                } }
                              >
                                <Icon icon={ arrowDown } color="black" />
                              </IconButton>
                            ) : <div style={ { width: '45px' } }/>}
                          </td>
                          <td>
                            <InputUnit
                              detached
                              type="float"
                              unit="R$/ha"
                              fullWidth
                              decimalScale={ 2 }
                              style={ { marginBottom: '0' } }
                              value={ round(editedCosts?.[groupedCost?.referenceId]?.valueHA || (groupedCost?.value ? groupedCost?.value/area : null), 2) }
                              onChange={ ({ target: { value } }) => handleHAChange(value, groupedCost, convertedCost, disabled, area) }
                            />
                          </td>
                          <td>
                            {(groupedCost?.value > 0 || editedCosts?.[groupedCost?.referenceId]?.value > 0) && editedCosts?.[groupedCost?.referenceId]?.value !== 0 && index < grouped.length-1 ? (
                              <IconButton
                                style={ { width: '45px' } }
                                tooltip="Replicar ao grupo"
                                onClick={ () => {
                                  setEditedCosts(old => {
                                    const currentValue = old?.[groupedCost?.referenceId]?.valueHA || groupedCost?.value/area
                                    const updated = { ...old }
                                    map(grouped, (item, position) => {
                                      if(position > index) {
                                        updated[item?.referenceId] = {
                                          ...updated?.[item?.referenceId],
                                          id: item?.id,
                                          valueHA: currentValue
                                        }
                                      }
                                    })
                                    return updated
                                  })
                                } }
                              >
                                <Icon icon={ arrowDown } color="black" />
                              </IconButton>
                            ) : <div style={ { width: '45px' } }/>}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  <td colSpan={ 5 } >
                    <Divider variant="fullWidth" style={ { marginTop: 25 } }/>
                  </td>
                </>
              ))}
            </Table>
          )}

          {!isEmpty(editedCosts) && (
            <ButtonContainer>
              <Button
                id="cancel-costs"
                variant='outlined'
                onClick={ () => {
                  createDialog({
                    id: 'confirm-save-rubric',
                    Component: ConfirmModal,
                    props: {
                      onConfirm: async () => {
                        setEditedCosts([])
                      },
                      textWarning: t('are you sure you want to cancel these changes')
                    }
                  })
                } }
              >
                {t('cancel')}
              </Button>
              <Button
                id="save-costs"
                color="secondary"
                onClick={ saveCosts }
              >
                {t('save')}
              </Button>
            </ButtonContainer>
          )}
        </Container>
      )}
    </Modal>
  )
}

InputsListModal.propTypes = {
  id: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  getCosts: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

InputsListModal.defaultProps = {
}

export default InputsListModal
