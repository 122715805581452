import React, { useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'

import {
  checked as checkedIcon,
  unchecked as uncheckedIcon
} from '@smartcoop/icons'
import colors, { secondary as secondaryColor } from '@smartcoop/styles/colors'
import Icon from '@smartcoop/web-components/Icon'

import {
  RadioTouchableContainer,
  Container,
  IconContainer,
  Divider,
  Children,
  Row
} from './styles'

const RadioItemLined = (props) => {
  const {
    checked,
    label,
    value,
    onChange,
    style,
    children,
    position,
    disabled
  } = props

  const onToggle = useCallback(() => {
    if (!disabled) {
      onChange({ value })
    }
  }, [disabled, onChange, value])

  const selectIcon = useMemo(() => (checked ? checkedIcon : uncheckedIcon), [
    checked
  ])

  const checkedStyle = useMemo(
    () => (checked && !disabled ? secondaryColor : colors.darkGrey),
    [checked, disabled]
  )

  return (
    <Container>
      {position === 0 && <Divider />}

      <Row style={ { ...style } }>
        <RadioTouchableContainer onClick={ onToggle }>
          <IconContainer>
            <Icon icon={ selectIcon } size={ 21 } color={ checkedStyle } />
          </IconContainer>
        </RadioTouchableContainer>

        {label}
      </Row>

      <Divider />

      {children && (
        <>
          <Children>{children}</Children>
        </>
      )}
    </Container>
  )
}

RadioItemLined.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string
  ]).isRequired,
  onChange: PropTypes.func,
  style: PropTypes.object,
  position: PropTypes.number,
  children: PropTypes.any,
  disabled: PropTypes.bool
}

RadioItemLined.defaultProps = {
  style: {},
  position: null,
  children: null,
  disabled: false,
  onChange: () => {}
}
export default RadioItemLined
