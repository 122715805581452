import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { colors } from '@smartcoop/styles'

export default makeStyles({
  flexContainer: {
    height: '100%'
  }
})

export const TabsGroup = styled(Tabs).attrs({
  variant: 'fullWidth',
  indicatorColor: 'primary'
})`
  color: ${ colors.black };
`

export const TabItem = styled(Tab)`
  min-width: 0;
  min-height: 0;
  pointer-events: inherit !important;
  height: 100%;
`

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

