import React, { useCallback } from 'react'

import PropTypes from 'prop-types'

import  { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { convertFileSize, isValidSize } from '@smartcoop/utils/files'
import Button from '@smartcoop/web-components/Button'

import useStyles from './styles'

const InputFile = (props) => {
  const {
    idInput,
    maxFileSize,
    maxNumberFile,
    onChange,
    inputProps,
    buttonProps,
    children,
    maxNumberFileDisplay,
    disabled,
    style
  } = props

  const classes = useStyles()
  const snackbar = useSnackbar()
  const t = useT()

  const handleChange = useCallback(
    (event) => {
      const { files } = event.target

      if (files.length > maxNumberFile) {
        snackbar.error(
          t('the number of files exceeds the limit of {this}', {
            this: maxNumberFileDisplay || maxNumberFile
          })
        )
      } else {
        for (const file of files) {
          if (!isValidSize(file, maxFileSize)) {
            snackbar.error(
              t('the size of one or more files exceeds {this}', {
                this: convertFileSize(maxFileSize)
              })
            )
            return
          }
        }
        onChange(event)
      }
    },
    [maxFileSize, maxNumberFile, maxNumberFileDisplay, onChange, snackbar, t]
  )

  return (
    <label htmlFor={ idInput } style={ { ...style } }>
      <input
        className={ classes.input }
        id={ idInput }
        type="file"
        onChange={ handleChange }
        { ...inputProps }
        disabled={ disabled || inputProps?.disabled }
      />
      <Button
        component="span"
        { ...buttonProps }
        disabled={ disabled }
      >
        {children}
      </Button>
    </label>
  )
}

InputFile.propTypes = {
  idInput: PropTypes.string.isRequired,
  maxNumberFile: PropTypes.number,
  maxFileSize: PropTypes.number,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  buttonProps: PropTypes.object,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  maxNumberFileDisplay: PropTypes.number,
  disabled: PropTypes.bool
}

InputFile.defaultProps = {
  onChange: () => {},
  maxNumberFile: 20,
  maxFileSize: 5242880,
  inputProps: {},
  buttonProps: {},
  style: {},
  children: null,
  maxNumberFileDisplay: 20,
  disabled: false
}

export default InputFile
