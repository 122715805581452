import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment/moment'

import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import round from 'lodash/round'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import Divider from '@material-ui/core/Divider'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { calculate, list, plus } from '@smartcoop/icons'
import { getCostsByProperty, getFieldCostsByProperty } from '@smartcoop/services/apis/smartcoopApi/resources/costs'
import { getPropertiesAssets } from '@smartcoop/services/apis/smartcoopApi/resources/propertiesAssets'
import { useSnackbar } from '@smartcoop/snackbar'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import { formatCurrencyIntl } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import Card from '@smartcoop/web-components/Card'
import DataTable from '@smartcoop/web-components/DataTable'
import FieldCostsItem from '@smartcoop/web-components/FieldCostsItem'
import Icon from '@smartcoop/web-components/Icon'
import GoBackIconButton from '@smartcoop/web-components/IconButton/GoBackIconButton'
import VerticalDotsIconButton from '@smartcoop/web-components/IconButton/VerticalDotsIconButton'
import InputSelect from '@smartcoop/web-components/InputSelect'
import Loader from '@smartcoop/web-components/Loader'
import MenuPopUp from '@smartcoop/web-components/MenuPopUp'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import Popover from '@smartcoop/web-components/Popover'
import PropertyAssetsStatus from '@smartcoop/web-components/PropertyAssetsStatus/PropertyAssetsStatus'
import PropertyCostsReportFragment from '@smartcoop/web-containers/fragments/reports/property/PropertyCostsReportFragment'
import ActivityCostsModal from '@smartcoop/web-containers/modals/ActivityCostsModal'
import DischargeModal from '@smartcoop/web-containers/modals/DischargeModal/DischargeModal'
import InputsListModal from '@smartcoop/web-containers/modals/InputsListModal'
import PropertiesAssetsModal from '@smartcoop/web-containers/modals/PropertiesAssetsModal/PropertiesAssetsModal'
import PropertiesAssetsMovementModal from '@smartcoop/web-containers/modals/PropertiesAssetsMovementModal'
import RubricsListModal from '@smartcoop/web-containers/modals/RubricsListModal'
import SearchAssetModal from '@smartcoop/web-containers/modals/SearchAssetModal/SearchAssetModal'

import { Container, ButtonRow, RowHeader, Title, Table, ContentContainer, ColumnContainer, Row } from './styles'

const CostsScreen = () => {
  const t = useT()
  const { createDialog } = useDialog()
  const property = useSelector(selectCurrentProperty)
  const [costs, setCosts] = useState([])
  const [fieldCosts, setFieldCosts] = useState([])
  const [dashboardView, setDashboardView] = useState(false)
  const dispatch = useCallback(useDispatch(), [])
  const [propertiesAssetsView, setPropertiesAssetsView] = useState(false)
  const [year, setYear] = useState(moment().format('YYYY'))
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)
  const tableRef = useRef(null)

  const viewMovements = useCallback((row) => {
    createDialog({
      id: 'loading',
      Component: PropertiesAssetsMovementModal,
      props: {
        asset: row
      }
    })
  },[createDialog])

  const onDischarge = useCallback((row) => {
    createDialog({
      id: 'loading',
      Component: DischargeModal,
      props: {
        asset: row,
        onClose: tableRef.current.onQueryChange
      }
    })
  },[createDialog])

  const renderMenus = useCallback((row) => (
    <Popover
      popoverId="user-menu"
      popoverStyle={ { width: 250 } }
      Component={ VerticalDotsIconButton }
      key={ row?.id }
    >
      <MenuPopUp
        options={ [
          {
            icon: null,
            text: t('register discharge'),
            onClick: () => {
              onDischarge(row)
            },
            disabled: !(row.status === 'ativo')
          }
        ] }
      />
    </Popover>
  ), [onDischarge, t])


  const columns = useMemo(
    () => [
      {
        title: '',
        disableClick: true,
        render: renderMenus,
        width: '4%'
      },
      {
        title: 'Código',
        field: 'assetCode',
        sorting: true,
        defaultSort: 'asc',
        align: 'left'
      },
      {
        title: 'Descrição',
        field: 'assetDescription',
        sorting: true,
        align: 'left',
        render: (row) => `${ row?.assetDescription } - ${ row?.assetBrand } - ${ row?.assetYear }`
      },
      {
        title: 'Tipo do bem',
        field: 'assetType.name',
        align: 'left'
      },
      {
        title: 'Valor do bem',
        field: 'assetValue',
        align: 'right',
        sorting: true,
        render: (row) => formatCurrencyIntl(row?.assetValue)
      },
      {
        title: 'Depreciação mensal',
        field: 'assetMonthlyDepreciation',
        align: 'right',
        sorting: true,
        render: (row) => formatCurrencyIntl(-row?.assetMonthlyDepreciation)
      },
      {
        title: 'Saldo a depreciar',
        field: 'assetMonthlyDepreciation',
        align: 'right',
        sorting: true,
        // eslint-disable-next-line no-nested-ternary
        render: (row) => row?.totalDepreciatedValue ? formatCurrencyIntl(row.assetValue - row.totalDepreciatedValue) : row.status !== 'baixado' ? formatCurrencyIntl(row?.assetValue) : '-'
      },
      {
        title: 'Status',
        field: 'status',
        render: (rowData) => (
          <PropertyAssetsStatus slug={ rowData?.status } />
        )
      }
    ], [renderMenus]
  )

  const actions = useMemo(
    () => [
      (row) => ({
        position: 'row',
        onClick: () => viewMovements(row),
        tooltip: t('view movements'),
        iconButtonProps: {
          id: 'view-movements',
          variant: 'outlined',
          size: 'small',
          color: 'black',
          style: {
            width: 30,
            marginTop: 5,
            marginRight: 10,
            height: 30,
            borderRadius: 5,
            color: colors.black
          }
        },
        icon: () => <Icon icon={ list } size={ 20 } />

      })
    ],
    [t, viewMovements]
  )

  const dataTableOption = useMemo(
    () => ({
      sorting: true
    }),
    []
  )

  const getCosts = useCallback(async () => {
    setLoading(true)
    const { data } = await getCostsByProperty({ propertyId: property?.id, year })
    const { data: response } = await getFieldCostsByProperty({ propertyId: property?.id, year })

    setCosts(data)
    setFieldCosts(response)
    setLoading(false)
  }, [property, year])

  useEffect(() => {
    getCosts()
  }, [getCosts, year])

  const openRubricModal = useCallback(() => {
    createDialog({
      id: 'rubric-list',
      Component: RubricsListModal
    })
  }, [createDialog])

  const openActivityCosts = useCallback(() => {
    createDialog({
      id: 'activity-costs',
      Component: ActivityCostsModal,
      props: {
        year,
        cropsOptions: map(fieldCosts, item => ({ label: `${ item?.crop?.description } / ${ item?.sowingYear }`, value: `${ item?.cropId }|${ item?.sowingYear }` })),
        onExit: getCosts
      }
    })
  }, [createDialog, fieldCosts, getCosts, year])

  const openAssetsForm = useCallback((_, row) => {
    createDialog({
      id: 'properties-assets',
      Component: PropertiesAssetsModal,
      props: {
        onSuccess: tableRef.current.onQueryChange,
        defaultValues: row,
        propertiesAssets: tableRef?.current?.state?.data,
        disabled: !isEmpty(row),
        cropsOptions: map(fieldCosts, item => ({ label: `${ item?.crop?.description } / ${ item?.sowingYear }`, value: `${ item?.cropId }|${ item?.sowingYear }` })),
        isEditable: !isEmpty(row)
      }
    })
  }, [createDialog, fieldCosts])

  const openInputsList = useCallback(() => {
    createDialog({
      id: 'input-list-costs',
      Component: InputsListModal,
      props: {
        year,
        getCosts
      }
    })
  }, [createDialog, getCosts, year])

  const years = useMemo(() => {
    const current = toNumber(moment().add(2,'years').format('YYYY'))
    const from = toNumber(moment().subtract(5, 'years').format('YYYY'))
    const total = []
    // eslint-disable-next-line no-plusplus
    for (let i = from; i < current; i += 1) {
      total.push(i)
    }
    return total
  }, [])

  const activityCosts = useMemo(() => {
    const response = []
    map(groupBy(filter(costs, item => !isEmpty(item?.activity) && item?.status === 'realizado'), 'bulkId'), cost => {
      map(cost, activityValues => {
        const percentage = activityValues?.percentage ? toNumber(activityValues?.percentage) : 0
        const value = percentage === 0 ? activityValues?.value / cost.length : activityValues?.value*(percentage/100)

        response.push({
          ...activityValues,
          activityId: activityValues?.activity?.id,
          // eslint-disable-next-line no-nested-ternary
          value: activityValues?.value ? (activityValues?.rubricItem?.rubricGroup?.operationType === 'debit' ? value * -1 : value) : 0
        })
      })
    })
    return groupBy(response, 'activityId')
  }, [costs])

  const groupedCosts = useMemo(() => map(groupBy(fieldCosts, 'cropId'), item => {
    const value = sum(flatMap(item, subItem => map(subItem?.costs, cost => cost?.rubricItem?.rubricGroup?.operationType === 'debit' ? (cost?.value || 0) * -1 : (cost?.value || 0))))
    const area = round(sum(map(item, season => {
      if(season?.childrenPolygonId) {
        const currentPolygon = find(season?.fieldData?.childrenPolygons, polygon => polygon.id === season?.childrenPolygonId)
        return currentPolygon?.area
      }
      return season?.fieldData?.area
    })), 2)
    return ({
      name: `${ item[0]?.crop?.description } / ${ item[0]?.sowingYear }`,
      cropId: item[0]?.cropId,
      activityId: item[0]?.activity?.id,
      area,
      totalValue: value,
      valueByHa: round(value/area, 2)
    })
  }), [fieldCosts])

  const handleDelete = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(PropertyActions.deletePropertyAsset(
              row.id,
              () => {
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'male',
                    this: t('asset')
                  })
                )
                tableRef.current.onQueryChange()
              }
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('asset')
          })
        }
      })
    },
    [createDialog, dispatch, snackbar, t]
  )

  return(
    <Container>
      <RowHeader>
        {
          (dashboardView || propertiesAssetsView) ? (
            <div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
              <GoBackIconButton onClick={ () => {
                setDashboardView(false)
                setPropertiesAssetsView(false)
              } } iconColor={ colors.primary }
              />
              <Title style={ { fontSize: 18, marginLeft: 0 } }>
                {t(!propertiesAssetsView ? 'dashboard' : 'properties and assets')}
              </Title>
            </div>
          )
            : (
              <InputSelect
                detached
                label={ t('year', { howMany: 1 }) }
                options={ map(years, item => ({ label: toString(item), value: toString(item) })) }
                style={ { width: '20%', marginRight: '20%', opacity: !dashboardView ? 100 : 0 } }
                disabled={ dashboardView }
                onChange={ setYear }
                value={ year }
                disableClearable
              />
            )
        }
      </RowHeader>
      <RowHeader>
        {(!dashboardView || !propertiesAssetsView) && (
          <ButtonRow>
            <Button
              id="properties-assets"
              color={ propertiesAssetsView ? colors.primary : colors.white }
              style={ {
                padding: '5.5px 10px',
                fontSize: '0.875rem',
                marginRight: 10
              } }
              onClick={ () => setPropertiesAssetsView(true) }
            >
              {t('properties and assets')}
            </Button>
            <Button
              id="dashboard"
              color={ dashboardView ? colors.primary : colors.white }
              style={ {
                padding: '5.5px 10px',
                fontSize: '0.875rem',
                marginRight: 10
              } }
              onClick={ () => setDashboardView(true) }
            >
              {t('dashboard')}
            </Button>
            <Button
              id="cost-factor"
              color={ colors.white }
              style={ {
                padding: '5.5px 10px',
                fontSize: '0.875rem',
                marginRight: 10
              } }
              onClick={ openRubricModal }
            >
              {t('cost factors')}
            </Button>
            <Button
              id="register-activity-costs"
              color={ colors.white }
              style={ {
                padding: '5.5px 10px',
                fontSize: '0.875rem'
              } }
              onClick={ openActivityCosts }
            >
              {t('registers by activity')}
            </Button>
          </ButtonRow>
        )}
      </RowHeader>
      {
        loading ? (
          <Loader width={ 100 } />
        ) : (
          <ContentContainer style={ { display: (dashboardView || propertiesAssetsView) ? 'none' : 'flex' } }>
            <ColumnContainer>
              <Title style={ { fontSize: 18, marginTop: 5, marginLeft: 0, height: 35.5 } }>Resultados por atividade</Title>
              {map(activityCosts, (item, key) => {
                const activityReceipts = sumBy(filter(item, cost => !isNaN(cost?.value) && cost?.rubricItem?.rubricGroup?.operationType === 'credit'), 'value')
                const activityExpenses = sumBy(filter(item, cost => !isNaN(cost?.value) && cost?.rubricItem?.rubricGroup?.operationType === 'debit'), 'value')
                const activityTotal =  activityExpenses + activityReceipts
                const activities = filter(groupedCosts, groupedCost => groupedCost?.activityId === item?.[0]?.activity?.id)

                return (
                  <Card
                    key={ key }
                    title={
                      <Title>{item?.[0]?.activity?.name}</Title>
                    }
                    headerTitleProps={ {
                      style: {
                        textAlign: 'left',
                        backgroundColor: colors.white,
                        paddingTop: 5,
                        paddingBottom: 5
                      }
                    } }
                    style={ { margin: '5px 0 20px 0', width: '95%' } }
                    cardStyle={ { width: '100%', paddingTop: 0, backgroundColor: colors.white } }
                  >
                    <Table>
                      <tr style={ { display: item?.[0]?.activity?.name === 'Grãos' ? 'none' : 'auto' } }>
                        <td style={ { paddingLeft: 50 } }>
                          {t('receipts')}:
                        </td>
                        <td style={ { paddingRight: 50, textAlign: 'right' } }>
                          {formatCurrencyIntl(activityReceipts)}
                        </td>
                      </tr>
                      <tr>
                        <td style={ { paddingLeft: 50 } }>
                          {t('expenses')}:
                        </td>
                        <td style={ { color: 'red', paddingRight: 50, textAlign: 'right' } }>
                          {formatCurrencyIntl(activityExpenses)}
                        </td>
                      </tr>
                      <tr style={ { display: item?.[0]?.activity?.name === 'Grãos' ? 'none' : 'auto' } }>
                        <td style={ { paddingLeft: 50 } }>
                          {t('result')}:
                        </td>
                        <td style={ { color: activityTotal < 0 ? 'red' : 'black', paddingRight: 50, textAlign: 'right' } }>
                          {formatCurrencyIntl(activityTotal)}
                        </td>
                      </tr>
                    </Table>
                    {!isEmpty(activities) ? (
                      <>
                        <Divider />
                        <Title style={ { marginTop: 10, marginBottom: 10 } }>Resultado por cultura</Title>
                        <Table style={ { fontWeight: 'normal' } }>
                          {map(activities, activity => (
                            <tr>
                              <td style={ { paddingLeft: 50 } }>{activity?.name}</td>
                              <td>{activity?.area}ha</td>
                              <td style={ { textAlign: 'right', paddingRight: 50, color: activity?.totalValue >= 0 ? 'black' : 'red' } }>{formatCurrencyIntl(activity?.totalValue)}</td>
                              <td style={ { textAlign: 'right', paddingRight: 50, color: activity?.totalValue >= 0 ? 'black' : 'red' } }>{formatCurrencyIntl(activity?.valueByHa)}/ha</td>
                            </tr>
                          ))}
                        </Table>
                      </>
                    ) : null}
                  </Card>
                )
              })}
            </ColumnContainer>
            <ColumnContainer>
              <Row>
                <Title style={ { fontSize: 18, marginLeft: 0 } }>Resultados por talhão/cultivo</Title>
                <Button
                  id="register-activity-costs"
                  color={ colors.black }
                  style={ {
                    padding: '5.5px 10px',
                    fontSize: '0.875rem',
                    marginLeft: '15%'
                  } }
                  onClick={ openInputsList }
                >
                  {t('list of inputs')}
                </Button>
              </Row>
              { map(fieldCosts, (item, index) => (
                <FieldCostsItem
                  growingSeason={ item }
                  key={ `${ index }` }
                  getCosts={ getCosts }
                />
              ))}
            </ColumnContainer>
          </ContentContainer>
        )
      }

      {
        loading ? (
          <Loader width={ 100 } />
        ) : (
          <ContentContainer style={ { display: (!propertiesAssetsView || dashboardView) ? 'none' : 'flex', flexDirection: 'column' } }>
            <ColumnContainer
              style={ {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              } }
            >
              <Button
                id="register-asset"
                color={ colors.yellow }
                style={ {
                  padding: '5.5px 10px',
                  fontSize: '0.875rem',
                  marginBottom: 10,
                  maxWidth: 150
                } }
                onClick={ openAssetsForm }
              >
                {t('register asset')}
              </Button>
              <Popover
                popoverId="animal-options"
                Component={ VerticalDotsIconButton }
              >
                <MenuPopUp
                  options={ [
                    {
                      icon: <Icon icon={ calculate } size={ 20 } />,
                      text: t('recalculate month'),
                      onClick: () => {
                        createDialog({
                          id: 'realize-calculation',
                          Component: ConfirmModal,
                          props: {
                            onConfirm: () => {
                              dispatch(PropertyActions.postPropertyAssetsMovements(
                                {
                                  propertyId: property?.id
                                },
                                () => {
                                  snackbar.success(
                                    t('calculation successful', {
                                      howMany: 1
                                    })
                                  )
                                  tableRef.current.onQueryChange()
                                }
                              ))
                            },
                            textWarning: t('when the routine is executed, the depreciation transactions for the assets linked to the property in the month of {month}/{year} will be redone, do you want to continue?', {
                              howMany: 1,
                              month: moment().subtract(1, 'month').format('MMMM'),
                              year: moment().subtract(1, 'month').format('YYYY')
                            })
                          }
                        })
                      }
                    },
                    {
                      icon: <Icon icon={ plus } size={ 18 } />,
                      text: t('include machines and animals'),
                      onClick: () => {
                        createDialog({
                          id: 'search-asset',
                          Component: SearchAssetModal,
                          props: {
                            propertyId: property?.id,
                            onClose: () => tableRef.current.onQueryChange()
                          }
                        })
                      }
                    }
                  ] }
                />
              </Popover>
            </ColumnContainer>
            <DataTable
              tableRef={ tableRef }
              columns={ columns }
              data={  getPropertiesAssets }
              queryParams={ {
                propertyId: property?.id
              } }
              urlParams={ {} }
              options={ dataTableOption }
              onDeleteClick={ handleDelete }
              onEditClick={ openAssetsForm }
              actions={ actions }
              id="properties-assets-table"
            />
          </ContentContainer>
        )
      }

      <ContentContainer style={ { display: !dashboardView ? 'none' : 'flex' } }>
        <PropertyCostsReportFragment />
      </ContentContainer>
    </Container>
  )
}

export default CostsScreen
