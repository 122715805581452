import map from 'lodash/map'

import api from '../api'


export const getAllWeatherStations = (params) => api.get('/v1/weather-stations/equipments', { params })

export const getWeatherStation = async (params, { weatherStationId }) => {
  const response = await api.get(`/v1/weather-stations/equipments/${ weatherStationId }/measures/latest`, { params })

  const descriptions = map(response.data.descriptions, itemDescription => {
    const dataValue = response.data.data[itemDescription.name]
    return {
      ...itemDescription,
      value: dataValue
    }
  })

  return {
    ...response,
    data: {
      ...response.data,
      descriptions: [
        {
          name: 'pl1Sum',
          description: 'Precipitação Acumulada',
          unit: 'mm',
          value: response.data.pl1Sum || '0.00'
        },
        ...descriptions
      ]
    }
  }
}
