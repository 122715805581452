import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

/* Initial State */
const INITIAL_STATE = {
  quotation: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadProductsQuotation: ['params', 'onSuccess', 'onError'],
  loadProductsQuotationSuccess: ['quotation', 'page', 'onSuccess'],

  resetProductsQuotation: [],

  productsQuotationError: ['error']
})


const loadProductsQuotation = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error
})

const loadProductsQuotationSuccess = (state = INITIAL_STATE, { quotation, page }) => ({
  ...state,
  quotation: page === 1 ? quotation : [
    ...state.quotation,
    ...quotation
  ]
})

const productsQuotationError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const resetProductsQuotation = (state = INITIAL_STATE) => ({
  ...state,
  quotation: INITIAL_STATE.productsQuotation
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {

  [Types.LOAD_PRODUCTS_QUOTATION]: loadProductsQuotation,
  [Types.LOAD_PRODUCTS_QUOTATION_SUCCESS]: loadProductsQuotationSuccess,

  [Types.RESET_PRODUCTS_QUOTATION]: resetProductsQuotation,

  [Types.PRODUCTS_QUOTATION_ERROR]: productsQuotationError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as ProductsQuotationTypes,
  Creators as ProductsQuotationActions
}
