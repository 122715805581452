import React, { useEffect, useMemo, memo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'

import I18n, { useT } from '@smartcoop/i18n'
import { selectUserOrganizations } from '@smartcoop/stores/organization/selectorOrganization'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import { withLayout } from '@smartcoop/web-containers/layouts'
import { Item, Header, Title, Text, ButtonsContainer } from '@smartcoop/web-containers/layouts/GuestLayout/theme'
import { useRoutes } from '@smartcoop/web-containers/routes/guest'

const OrganizationsScreen = () => {
  const user = useSelector(selectUser)
  const organizations = useSelector(selectUserOrganizations)

  const t = useT()

  const history = useHistory()
  const { routes } = useRoutes()

  useEffect(
    () => {
      if (isEmpty(user)) {
        history.replace(routes.login.path)
      }
    },
    [history, routes.login, user]
  )

  const columns = useMemo(() => [
    {
      title: t('cooperative', { howMany: 1 }),
      field: 'companyName',
      style: { flex: 2 },
      cellStyle: {
        textTransform: 'uppercase'
      }
    },
    {
      title: t('register number'),
      field: 'registry',
      align: 'center',
      cellStyle: {
        textAlign: 'center',
        textTransform: 'uppercase'
      },
      headerStyle: {
        textAlign: 'center'
      }
    }
  ], [t])

  return (
    <>
      <Item>
        <Header>
          <Title style={ { alignSelf: 'flex-end' } }>
            <I18n params={ { howMany: 2 } }>
              cooperative
            </I18n>
          </Title>
        </Header>

        <Text>
          <I18n>by our data, we found the registers</I18n>
        </Text>
      </Item>

      <DataTable
        data={ uniqBy(organizations, 'id') }
        columns={ columns }
        options={ {
          paging: false
        } }
        id="organizations-table"
      />

      <Item>
        <ButtonsContainer style={ { paddingTop: 25 } }>
          <Button
            id="go-back"
            onClick={ () => history.goBack() }
            style={ { width: '48%', flex: '0 0 auto' } }
            variant="outlined"
          >
            <I18n>go back</I18n>
          </Button>
          <Button
            id="next"
            onClick={ () => history.push(routes.resetCode.path, { mode: 'signUp' }) }
            style={ { width: '48%', flex: '0 0 auto' } }
          >
            <I18n>next</I18n>
          </Button>
        </ButtonsContainer>
      </Item>
    </>
  )
}

export default memo(withLayout('guest')(OrganizationsScreen))
