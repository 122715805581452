import React from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import first from 'lodash/first'

import { Avatar } from '@material-ui/core'


import { profilePictureService } from '@smartcoop/database/services/profilePictureService'
import { database } from '@smartcoop/database/web-database'
import { trash } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import { Card, TextName } from './styles'

const ContactListItem = ({ contact, profilePictures, onStartConversation, onRemoveContact }) => {
  const profilePicture = first(profilePictures)

  const onDeleteContact = (event) => {
    event.stopPropagation()
    onRemoveContact(contact)
  }

  return (
    <Card onClick={ () => onStartConversation(contact) }>
      <Avatar src={ profilePicture?.source } size={ 25 } alt={ contact?.chatNickname } />
      <TextName>{contact?.chatNickname}</TextName>

      { contact?.contactType === 'added' && (
        <Icon
          icon={ trash }
          size={ 15 }
          color={ colors.darkGrey }
          title="Excluir"
          onClick={ onDeleteContact }
        />
      ) }
    </Card>
  )
}

ContactListItem.propTypes = {
  onStartConversation: PropTypes.func.isRequired,
  onRemoveContact: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  profilePictures: PropTypes.array
}

ContactListItem.defaultProps = {
  profilePictures: []
}

const enhance = withObservables(['userId'], ({ userId }) => ({
  profilePictures: profilePictureService(database).observeProfilePictureByUserId(userId)
}))

const EnhancedContactListItem = enhance(ContactListItem)

export default EnhancedContactListItem
