import React, { useState, useCallback } from 'react'

import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'

import { ThemeProvider } from '@material-ui/core'

import { useT } from '@smartcoop/i18n'
import InputSearch from '@smartcoop/web-components/InputSearch'

import { inputSearchTheme } from './styles'

const InputSearchDebounced = (props) => {
  const {
    onChange,
    placeholder,
    inputStyle,
    detached,
    inputTheme,
    debounceTime,
    ...rest
  } = props

  const t = useT()

  const [filterText, setFilterText] = useState('')

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      onChange(value)
    }, debounceTime),
    []
  )

  const handleChange = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )

  return (
    <ThemeProvider theme={ inputTheme }>
      <InputSearch
        { ...rest }
        detached={ detached }
        onChange={ handleChange }
        style={ inputStyle }
        value={ filterText }
        placeholder={ t(placeholder) }
        fullWidth
      />
    </ThemeProvider>
  )
}

InputSearchDebounced.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  inputStyle: PropTypes.object,
  detached: PropTypes.bool,
  inputTheme: PropTypes.func,
  debounceTime: PropTypes.number
}

InputSearchDebounced.defaultProps = {
  placeholder: 'search',
  inputStyle: { marginRight: 12 },
  detached: true,
  inputTheme: inputSearchTheme,
  debounceTime: 300
}

export default InputSearchDebounced
