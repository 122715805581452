import { call, put, takeLatest } from 'redux-saga/effects'

import { getAggregatedRain, getCurrent, getForecast } from '@smartcoop/services/apis/smartcoopApi/resources/weatherForecast'

import { WeatherForecastActions, WeatherForecastTypes } from './duckWeatherForecast'

function* searchCurrentWeather({ latitude, longitude, onSuccess = () => {}, onError = () => {} }) {
  try {
    const current = yield call(getCurrent, latitude, longitude)

    yield put(WeatherForecastActions.searchCurrentWeatherSuccess(current))

    yield call(onSuccess)
  } catch (error) {
    yield put(WeatherForecastActions.weatherForecastError(error))
    yield call(onError, error)
  }
}

function* searchWeatherForecast({ latitude, longitude, onSuccess = () => {}, onError = () => {} }) {
  try {
    const forecast = yield call(getForecast, latitude, longitude)

    yield put(WeatherForecastActions.searchWeatherForecastSuccess(forecast))

    yield call(onSuccess)
  } catch (error) {
    yield put(WeatherForecastActions.weatherForecastError(error))
    yield call(onError, error)
  }
}

function* searchAggregatedRain({ initialDate, finalDate, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(getAggregatedRain, initialDate, finalDate)

    yield put(WeatherForecastActions.searchAggregatedRainSuccess(data))

    yield call(onSuccess)
  } catch (error) {
    yield put(WeatherForecastActions.weatherForecastError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(WeatherForecastTypes.SEARCH_CURRENT_WEATHER, searchCurrentWeather),
  takeLatest(WeatherForecastTypes.SEARCH_WEATHER_FORECAST, searchWeatherForecast),
  takeLatest(WeatherForecastTypes.SEARCH_AGGREGATED_RAIN, searchAggregatedRain)
]
