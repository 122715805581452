import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { UserActions } from '@smartcoop/stores/user'
import Button from '@smartcoop/web-components/Button'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'

import { Container, Label, CardContainer, Table } from './styles'

const ChatCardFragment = ({ user }) => {
  const t = useT()
  const { createDialog } = useDialog()
  const dispatch = useDispatch()

  const updateCode = useCallback(
    () => {
      createDialog({
        id: 'confirm-submit',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(UserActions.saveUser(
              {
                updateChatCode: true
              },
              () => {dispatch(UserActions.loadUser())}
            ))
          },
          textWarning: t('do you really want to update your contact code?')
        }
      })

    }, [createDialog, dispatch, t]
  )

  return (
    <Container>
      <Label>
        <I18n params={ { howMany: 2 } }>chat</I18n>
      </Label>
      <CardContainer>
        <Table>
          <tr>
            <td style={ { fontWeight: 'bold' } }>Código: </td>
            <td>{user?.userCode}</td>
            <td>
              <Button
                id="update-code"
                onClick={ updateCode }
                color="white"
                style={ { marginLeft: 7 } }
              >
                <I18n>update code</I18n>
              </Button>
            </td>
          </tr>
        </Table>
      </CardContainer>
    </Container>
  )
}

ChatCardFragment.propTypes = {
  user: PropTypes.object.isRequired
}

export default ChatCardFragment
