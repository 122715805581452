import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterPriceDataForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterPriceDataForm'

import useStyles from './styles'

const RegisterPriceDataModal = (props) => {
  const {
    id,
    open,
    handleClose,
    isEditing,
    priceData,
    reloadDataTable,
    dairyFarm
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const [isLoading, setIsLoading] = useState(false)

  const defaultValues = useMemo(
    () => (
      {
        ...priceData,
        priceDate: priceData && priceData.priceDate ?
          priceData.priceDate.substring(0, priceData.priceDate.lastIndexOf('-'))
          : undefined,
        companyName: isEditing ? (priceData?.companyName ?? '') : (dairyFarm?.companyName ?? '')
      }
    ), [dairyFarm, isEditing, priceData]
  )

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      reloadDataTable()
      handleClose()
    }, [handleClose, reloadDataTable]
  )

  const onSuccess = useCallback(
    () => {
      setIsLoading(false)

      snackbar.success(
        t(`your {this} was ${ isEditing ? 'edited' :'registered' }`, {
          howMany: 2,
          gender: 'male',
          this: t('price data')
        })
      )
      closeModal()

    }, [closeModal, isEditing, snackbar, t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(DairyFarmActions.savePriceData(
        {
          id: defaultValues.id,
          ...data
        },
        onSuccess,
        () => setIsLoading(false)
      ))
    },
    [defaultValues, dispatch, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n>price registration</I18n>
        </>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        {
          isLoading ? <Loader width={ 100 } /> :
            <RegisterPriceDataForm
              ref={ formRef }
              defaultValues={ defaultValues }
              onSubmit={ handleSubmit }
              onCancel={ closeModal }
            />
        }
      </>
    </Modal>
  )}

RegisterPriceDataModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  isEditing: PropTypes.bool,
  priceData: PropTypes.object,
  reloadDataTable: PropTypes.func,
  dairyFarm: PropTypes.object
}

RegisterPriceDataModal.defaultProps = {
  handleClose: () => {},
  isEditing: false,
  priceData: {},
  reloadDataTable: () => {},
  dairyFarm: {}
}

export default RegisterPriceDataModal
