/* eslint-disable radix */

import find from 'lodash/find'
import flow from 'lodash/fp/flow'
import replaceFP from 'lodash/fp/replace'
import map from 'lodash/map'
import replace from 'lodash/replace'
import round from 'lodash/round'
import toString from 'lodash/toString'


export const phAguaStatus = (respostasForm) => {
  const phAgua = parseFloat(find(respostasForm, item => item.name === 'pH em Água')?.value)

  let statusPhAgua = 'Informe o valor do pH em Água'

  if (phAgua < 6) {
    statusPhAgua = 'Não Atende'
  }

  if (phAgua >= 6) {
    statusPhAgua = 'Atende'
  }

  return statusPhAgua
}

const correcaoPh6Solo = async (respostasForm, indicesCalcario) => {
  // console.log('Calculando Correção de PH6,0 no Solo...');

  const indiceSmpInformado = parseFloat(find(respostasForm, item => item.name === 'Índice SMP')?.value)

  let indiceResultado = 'Informe o valor do índice SMP'

  // Procurar o indice SMP na tabela de Indices do Calcário
  await Promise.all(map(indicesCalcario, indiceSmp => {
    if (indiceSmp?.indiceSmp === toString(indiceSmpInformado)) {
      indiceResultado = parseFloat(indiceSmp.six)
    }
  }))

  return `${ indiceSmpInformado >= 7.1 ? 0 :  indiceResultado  } Ton/ha`
}

const necessidadeCorrecaoK2O = (respostasForm) => {
  const saturacaoPotassioSolo = parseFloat(flow(
    replaceFP(' - Teor Baixo', ''),
    replaceFP(' - Teor Alto', '')
  )(find(respostasForm, item => item.name === 'Saturação de Potássio no Solo')?.value))
  const ctccmolcdm3 = parseFloat(find(respostasForm, item => item.name === 'CTC do Solo (cmolc/dm³)')?.value)

  let resultString: any = 'Informar K e CTC'

  if(saturacaoPotassioSolo > 3) {
    resultString = '0'
  } else {
    resultString = `${ (((3 - saturacaoPotassioSolo) * ctccmolcdm3 * 15.64) * 0.6).toFixed(2)  }  Kg de KCl`
  }

  return resultString
}

const saturacaoPotassioSolo = (respostasForm) => {
  const kmgdm3 = parseFloat(find(respostasForm, item => item.name === 'Potássio K (mg/dm³)')?.value)
  const ctccmolcdm3 = parseFloat(find(respostasForm, item => item.name === 'CTC do Solo (cmolc/dm³)')?.value)
  let resultSaturacaoPotassio: any = 'Informe o valor de K e CTC'

  let resultString: any = 'Informe o valor de K e CTC'

  if (kmgdm3 > 0 && ctccmolcdm3 > 0) {
    resultSaturacaoPotassio = (((kmgdm3 / 391) / ctccmolcdm3) * 100).toFixed(2)

    if (resultSaturacaoPotassio < 3) {
      resultString = `${ resultSaturacaoPotassio  } - Teor Baixo`
    } else {
      resultString = `${ resultSaturacaoPotassio  } - Teor Alto`
    }

  }

  return resultString
}

const classificacaoSolo = (respostasForm) => {
  let resultString: any = 'Informe o % de argila'

  const percentualArgila = parseFloat(find(respostasForm, item => item.name === 'Teor de Argila (%)')?.value)

  if (percentualArgila <= 20) {
    resultString = 4
  }

  if (percentualArgila > 20 && percentualArgila <= 40) {
    resultString = 3
  }

  if (percentualArgila > 40 && percentualArgila <= 60) {
    resultString = 2
  }

  if (percentualArgila > 60) {
    resultString = 1
  }

  return toString(resultString)
}

const nivelFosforoSolo = (respostasForm) => {
  const classeSolo = parseInt(find(respostasForm, item => item.name === 'Classe de Solo')?.value)
  const teorPSolo = parseFloat(find(respostasForm, item => item.name === 'Teor de Fósforo no Solo (mg/dm³)')?.value)

  let resultString = 'Informe o % de argila e o teor de P do solo'

  switch (classeSolo) {
    case 1: // Solo do Tipo 1
      if(teorPSolo <= 3){
        resultString = 'Muito Baixo'
      }

      if(teorPSolo > 3 && teorPSolo <= 6){
        resultString = 'Baixo'
      }

      if(teorPSolo > 6 && teorPSolo <= 9){
        resultString = 'Médio'
      }

      if(teorPSolo > 9 && teorPSolo <= 18){
        resultString = 'Alto'
      }

      if(teorPSolo > 18){
        resultString = 'Muito Alto'
      }

      break

    case 2: // Solo do Tipo 2
      if(teorPSolo <= 4){
        resultString = 'Muito Baixo'
      }

      if(teorPSolo > 4 && teorPSolo <= 8){
        resultString = 'Baixo'
      }

      if(teorPSolo > 8 && teorPSolo <= 12){
        resultString = 'Médio'
      }

      if(teorPSolo > 12 && teorPSolo <= 24){
        resultString = 'Alto'
      }

      if(teorPSolo > 24){
        resultString = 'Muito Alto'
      }
      break

    case 3: // Solo do Tipo 3

      if(teorPSolo <= 6){
        resultString = 'Muito Baixo'
      }

      if(teorPSolo > 6 && teorPSolo <= 12){
        resultString = 'Baixo'
      }

      if(teorPSolo > 12 && teorPSolo <= 18){
        resultString = 'Médio'
      }

      if(teorPSolo > 18 && teorPSolo <= 36){
        resultString = 'Alto'
      }

      if(teorPSolo > 36){
        resultString = 'Muito Alto'
      }

      break

    case 4: // Solo do Tipo 4
      if(teorPSolo <= 10){
        resultString = 'Muito Baixo'
      }

      if(teorPSolo > 10 && teorPSolo <= 20){
        resultString = 'Baixo'
      }

      if(teorPSolo > 20 && teorPSolo <= 30){
        resultString = 'Médio'
      }

      if(teorPSolo > 30 && teorPSolo <= 60){
        resultString = 'Alto'
      }

      if(teorPSolo > 60){
        resultString = 'Muito Alto'
      }

      break

    default:
      resultString = 'Informe o % de argila e o teor de P do solo'
      break
  }


  return resultString
}

const necessidadeCorrecaoP2O5 = (respostasForm) => {
  const classeSolo = parseInt(find(respostasForm, item => item.name === 'Classe de Solo')?.value)
  const teorFosforoSolo = parseFloat(find(respostasForm, item => item.name === 'Teor de Fósforo no Solo (mg/dm³)')?.value)

  let resultString: any = 'Informar nível de fósforo e argila do solo'

  if(classeSolo === 1 && teorFosforoSolo < 9){
    resultString = `${ round((9 - teorFosforoSolo) * 20)  } kg P205/ha`
  }else if (classeSolo===1){
    resultString = '0 kg P205/ha'
  }

  if(classeSolo === 2 && teorFosforoSolo < 12){
    resultString = `${ round((12 - teorFosforoSolo) * 15)  } kg P205/ha`
  }else if (classeSolo===2){
    resultString = '0 kg P205/ha'
  }

  if(classeSolo === 3 && teorFosforoSolo < 18){
    resultString = `${ round((18 - teorFosforoSolo) * 10)  } kg P205/ha`
  }else if (classeSolo===3){
    resultString = '0 kg P205/ha'
  }

  if(classeSolo === 4 && teorFosforoSolo < 30){
    resultString = `${ round((30 - teorFosforoSolo) * 7.5)  } kg P205/ha`
  } else if (classeSolo===4){
    resultString = '0 kg P205/ha'
  }

  /* if(teorFosforoSolo > 30){
          resultString = '0 kg P205/ha';
      } */

  return resultString
}

const situacaoFosforoSolo = (respostasForm) => {

  const teorFosforoSolo = find(respostasForm, item => item.name === 'Nível de Fósforo')?.value

  let resultString = 'Informe o % de argila e teor de P para calcular'

  if(teorFosforoSolo === 'Alto' || teorFosforoSolo === 'Muito Alto'){
    resultString = 'Atende'
  } else {
    resultString = 'Não Atende'
  }

  return resultString
}

const areaPastagemCicloAtual = (respostasForm) => {

  const taxaCrescimentoPastoCicloAtual = parseFloat(find(respostasForm, item => item.name === 'Taxa crescimento do pasto ciclo atual (kg MS/dia)')?.value)

  const demandaIngestaoMateriaSecaRebanho = parseFloat(replace(find(respostasForm, item => item.name === 'Demanda de ingestão de matéria de seca de volumoso do rebanho')?.value, ' kg MS/ha', ''))

  const stringResult = (demandaIngestaoMateriaSecaRebanho / taxaCrescimentoPastoCicloAtual).toFixed(2)

  return `${ stringResult  } hectares`
}

const areaSilagem = (respostasForm) => {
  // Demanda de volumoso conservado/ano
  const demandaVolumosoConservadoAno = parseFloat(replace(find(respostasForm, item => item.name === 'Demanda de volumoso conservado/ano (Toneladas de MS/Ano)')?.value, ' Ton de MS/ano', ''))
  // Potencial de produção de MS do alimento conservado
  const potencialProducaoMS = parseFloat(find(respostasForm, item => item.name === 'Potencial de produção de MS do alimento conservado (Ton MS/ha)')?.value)

  const areaDeSilagem = (demandaVolumosoConservadoAno / potencialProducaoMS).toFixed(2)

  const stringResult = `${ areaDeSilagem  } hectares`

  return stringResult
}

export const adubacaoFosforoPastagem = (respostasForm) => {

  const taxaCrescimentoPastoCicloAtual = parseFloat(find(respostasForm, item => item.name === 'Taxa crescimento do pasto ciclo atual (kg MS/dia)')?.value)
  const periodoUtilizacaoDias = parseInt(find(respostasForm, item => item.name === 'Período de Utilização (dias)')?.value)

  // Adubação de Fósforo kg  P205/ha
  const adubacaoFosforo = ((taxaCrescimentoPastoCicloAtual * periodoUtilizacaoDias) * 0.01).toFixed(2)

  return `${ adubacaoFosforo  } Kg P205/ha`
}

const adubacaoPotassioPastagem = (respostasForm) => {

  const taxaCrescimentoPastoCicloAtual = parseFloat(find(respostasForm, item => item.name === 'Taxa crescimento do pasto ciclo atual (kg MS/dia)')?.value)
  const periodoUtilizacaoDias = parseInt(find(respostasForm, item => item.name === 'Período de Utilização (dias)')?.value)

  // Adubação de Fósforo kg  P205/ha
  const adubacaoFosforo = ((taxaCrescimentoPastoCicloAtual * periodoUtilizacaoDias) * 0.01).toFixed(2)

  return `${ adubacaoFosforo  } Kg K2O/ha`
}

const adubacaoNitrogenioSilagem = (respostasForm) => {
  const potencialProducaoMS = parseFloat(find(respostasForm, item => item.name === 'Potencial de produção de MS do alimento conservado (Ton MS/ha)')?.value)

  // Adubação de Nitrogênio
  const adubacaoNitrogenio = potencialProducaoMS * 10

  return `${ adubacaoNitrogenio  } kg N2/ha`
}

const adubacaoFosforoSilagem = (respostasForm) => {
  const potencialProducaoMS = parseFloat(find(respostasForm, item => item.name === 'Potencial de produção de MS do alimento conservado (Ton MS/ha)')?.value)

  // Adubação de Nitrogênio
  const adubacaoNitrogenio = potencialProducaoMS * 10

  return `${ adubacaoNitrogenio  } kg P205/ha`
}

const adubacaoPotassioSilagem = (respostasForm) => {
  const potencialProducaoMS = parseFloat(find(respostasForm, item => item.name === 'Potencial de produção de MS do alimento conservado (Ton MS/ha)')?.value)

  // Adubação de Nitrogênio
  const adubacaoNitrogenio = potencialProducaoMS * 12

  return `${ adubacaoNitrogenio  } Kg K2O/ha`
}

const demandaIngestaoMateriaSeca = (respostasForm) => {

  const vacasLactacao = {
    numero: 0,
    pesoCorporal: 0,
    // Valor fixo
    taxaConsumo: 0.03,
    demanda: 0
  }

  vacasLactacao.numero = parseFloat(find(respostasForm, item => item.name === 'Número (Vacas em Lactação)')?.value)
  vacasLactacao.pesoCorporal = parseFloat(find(respostasForm, item => item.name === 'Peso Corporal (Vacas em Lactação)')?.value)

  // Calcula a demanda das vacas em lactação
  vacasLactacao.demanda = Number((vacasLactacao.numero * vacasLactacao.pesoCorporal * vacasLactacao.taxaConsumo).toFixed(2))

  const vacasSecas = {
    numero: 0,
    pesoCorporal: 0,
    taxaConsumo: 0.02, // Valor fixo
    demanda: 0
  }

  vacasSecas.numero = parseFloat(find(respostasForm, item => item.name === 'Número (Vacas Secas)')?.value)
  vacasSecas.pesoCorporal = parseFloat(find(respostasForm, item => item.name === 'Peso Corporal (Vacas Secas)')?.value)

  // Calcula a demanda das vacas secas
  vacasSecas.demanda = Number((vacasSecas.numero * vacasSecas.pesoCorporal * vacasSecas.taxaConsumo).toFixed(2))

  const novilhas = {
    numero: 0,
    pesoCorporal: 0,
    taxaConsumo: 0.025, // Valor Fixo
    demanda: 0
  }

  novilhas.numero = parseFloat(find(respostasForm, item => item.name === 'Número (Novilhas)')?.value)
  novilhas.pesoCorporal = parseFloat(find(respostasForm, item => item.name === 'Peso Corporal (Novilhas)')?.value)

  // Calcula a demanda das novilhas
  novilhas.demanda = Number((novilhas.numero * novilhas.pesoCorporal * novilhas.taxaConsumo).toFixed(2))

  const terneiras = {
    numero: 0,
    pesoCorporal: 0,
    taxaConsumo: 0.025, // Valor Fixo
    demanda: 0
  }

  terneiras.numero = parseFloat(find(respostasForm, item => item.name === 'Número (Terneiras)')?.value)
  terneiras.pesoCorporal = parseFloat(find(respostasForm, item => item.name === 'Peso Corporal (Terneiras)')?.value)

  // Calcular a demanda das terneiras
  terneiras.demanda = Number((terneiras.numero * terneiras.pesoCorporal * terneiras.taxaConsumo).toFixed(2))

  // Calcular a demandaIngestaoMateriaSeca
  const demanda = Number((vacasLactacao.demanda + vacasSecas.demanda + novilhas.demanda + terneiras.demanda).toFixed(2))

  return `${ demanda  } kg MS/ha`
}

const demandaVolumosoConservadoAno = (respostasForm) => {

  // Tempo de fornecimento de alimento conservado (dias)
  // eslint-disable-next-line radix
  const tempoFornecAlimentoConservado = parseInt(find(respostasForm, item => item.name === 'Tempo de fornecimento de alimento conservado (dias)')?.value)

  // Demanda de ingestão de matéria de seca de volumoso do rebanho
  const demandaIngestaoMateriaSecaRebanho = parseFloat(replace(find(respostasForm, item => item.name === 'Demanda de ingestão de matéria de seca de volumoso do rebanho')?.value, ' kg MS/ha', ''))

  const demandaVolumeConservadoAno = Number(((demandaIngestaoMateriaSecaRebanho * tempoFornecAlimentoConservado) / 1000).toFixed(2))

  return `${ demandaVolumeConservadoAno  } Ton de MS/ano`
}

export const calculatedField = {
  // checklist - correcao da fertilidade do solo
  'correcaoPh6Solo' : correcaoPh6Solo,
  'necessidadeCorrecaoK2O' : necessidadeCorrecaoK2O,
  'saturacaoPotassioSolo' : saturacaoPotassioSolo,
  'phAguaStatus' : phAguaStatus,
  'classificacaoSolo' : classificacaoSolo,
  'necessidadeCorrecaoP2O5' : necessidadeCorrecaoP2O5,
  'situacaoFosforoSolo' : situacaoFosforoSolo,
  'nivelFosforoSolo' : nivelFosforoSolo,

  'areaPastagemCicloAtual' : areaPastagemCicloAtual,
  'areaSilagem' : areaSilagem,
  'adubacaoFosforoPastagem' : adubacaoFosforoPastagem,
  'adubacaoPotassioPastagem' : adubacaoPotassioPastagem,
  'adubacaoNitrogenioSilagem' : adubacaoNitrogenioSilagem,
  'adubacaoFosforoSilagem' : adubacaoFosforoSilagem,
  'adubacaoPotassioSilagem' : adubacaoPotassioSilagem,

  'demandaIngestaoMateriaSeca' : demandaIngestaoMateriaSeca,
  'demandaVolumosoConservadoAno' : demandaVolumosoConservadoAno
}









