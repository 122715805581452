import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

/* Initial State */
const INITIAL_STATE = {
  products: []
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  createProduct: ['params', 'onSuccess', 'onError'],
  editProduct: ['params', 'onSuccess', 'onError'],
  findProduct: ['params', 'onSuccess', 'onError']
})

const logout = () => ({ ...INITIAL_STATE })
/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [AuthenticationTypes.LOGOUT]: logout
})

export { Types as ProductsTypes, Creators as ProductsActions }
