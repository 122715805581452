import React, { useCallback, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import isNull from 'lodash/isNull'
import map from 'lodash/map'

import Grid from '@material-ui/core/Grid'

import I18n from '@smartcoop/i18n'
import { checked, bell } from '@smartcoop/icons'
import { MessagingActions } from '@smartcoop/stores/messaging'
import { selectAllNotifications, selectNewNotificationsCount } from '@smartcoop/stores/messaging/selectorMessaging'
import { selectCurrentModule } from '@smartcoop/stores/module/selectorModule'
import colors from '@smartcoop/styles/colors'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import GoBackIconButton from '@smartcoop/web-components/IconButton/GoBackIconButton'
import Notification from '@smartcoop/web-components/Notification'

import { ButtonContainer, Title, Container, ListContainer, UpperRowContainer } from './styles'

const NotificationsScreen = () => {
  const dispatch = useCallback(useDispatch(), [])
  const history = useHistory()

  const notifications = useSelector(selectAllNotifications)
  const unreadCount = useSelector(selectNewNotificationsCount)
  const currentModule = useSelector(selectCurrentModule)

  const handleReadAllNotificationsClick = useCallback(() => {
    dispatch(MessagingActions.readAllNotifications())
  }, [dispatch])

  const handleNotificationClick = useCallback((notification) => {
    if (!notification.read) {
      dispatch(MessagingActions.readNotification(notification.id))
    }
  }, [dispatch])

  const getAllNotifications = useCallback((firstPage) => {
    dispatch(MessagingActions.getAllNotifications(firstPage))
  }, [dispatch])

  useEffect(() => {
    getAllNotifications(true)
  }, [getAllNotifications])

  return (
    <>
      {isNull(currentModule) && (
        <UpperRowContainer>
          <GoBackIconButton onClick={ () => history.goBack() } iconColor={ colors.primary } />
          <Title
            style={ {
              lineHeight: 1,
              fontSize: 18
            } }
          >
            <I18n>go back</I18n>

          </Title>
        </UpperRowContainer>
      )}
      <Grid container justify="center">
        <Grid item xs={ 11 } sm={ 10 } md={ 8 } lg={ 6 }>
          <Container
            title={ (
              <I18n as={ Title } params={ { howMany: 2 } }>
              notification
              </I18n>
            ) }
            headerLeft={ <Icon icon={ bell } size={ 20 } /> }
            headerRight={ (
              <>
                {!!unreadCount && (
                  <div style={ { justifySelf: 'flex-start' } }>
                    <Button
                      id="read-all-notifications"
                      variant="outlined"
                      size="small"
                      onClick={ handleReadAllNotificationsClick }
                      style={ { textTransform: 'none', fontSize: 14 } }
                    >
                      <ButtonContainer>
                        <Icon icon={ checked } size={ 16 } />
                        <I18n>check all as read</I18n>
                      </ButtonContainer>
                    </Button>
                  </div>
                )}
              </>
            ) }
          >
            <ListContainer>
              <InfiniteScroll
                dataLength={ notifications.length }
                next={ getAllNotifications }
                scrollableTarget="main"
                style={ {
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1
                } }
                hasMore
              >
                {map(notifications, (notification) => (
                  <Notification
                    key={ notification.id }
                    onClick={ handleNotificationClick }
                    notification={ notification }
                  />
                ))}
              </InfiniteScroll>
            </ListContainer>
          </Container>
        </Grid>
      </Grid>
    </>

  )
}

export default NotificationsScreen
