
import React, { createContext } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import first from 'lodash/first'

import { userService } from '@smartcoop/database/services/userService'
import { database } from '@smartcoop/database/web-database'

export const ChatUserContext = createContext({
  localUser: null
})

const ChatUserProvider = ({ children, authenticatedUser }) => {
  const localUser = first(authenticatedUser)

  return (
    <ChatUserContext.Provider value={ { localUser } }>
      {children}
    </ChatUserContext.Provider>
  )
}

ChatUserProvider.propTypes = {
  children: PropTypes.element.isRequired,
  authenticatedUser: PropTypes.array
}

ChatUserProvider.defaultProps = {
  authenticatedUser: []
}

const enhance = withObservables([], () => ({
  authenticatedUser: userService(database).observeAuthenticatedUser()
}))

const EnhancedChatUserProvider = enhance(ChatUserProvider)


export default EnhancedChatUserProvider
