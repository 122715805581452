import React, { useMemo } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import ceil from 'lodash/ceil'
import map from 'lodash/map'

import { useT } from '@smartcoop/i18n'
import {
  getUserProductsWithdraw as getUserProductsWithdrawService
} from '@smartcoop/services/apis/smartcoopApi/resources/productWithdraw'
import { momentBackDateTimeFormat, momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import { formatNumber } from '@smartcoop/utils/formatters'
import DataTable from '@smartcoop/web-components/DataTable'

const ProductsWitdrawListFragment = ({
  filters,
  urlParams,
  service,
  setLastUpdatedAt
}) => {
  const t = useT()

  const columns = useMemo(
    () => [
      {
        title: t('product'),
        field: 'organizationProducts.productName',
        cellStyle: { fontWeight: 'bold' }
      },
      {
        title: t('primary unit balance'),
        field: 'currentBalance',
        render: (row) => `${ formatNumber(ceil(row.currentBalance, 2)) } ${ row.organizationProducts?.measureUnit }`
      },
      {
        title: t('secondary unit balance'),
        field: 'currentBalance',
        render: (row) =>
          // eslint-disable-next-line eqeqeq
          !row.organizationProducts?.conversionFactor || row.organizationProducts?.conversionFactor == 0
            ? t('does not apply - short version')
            : `${ formatNumber((Math.floor(Number(row.currentBalance)/Number(row.organizationProducts?.conversionFactor)))) } ${ t('{this} of', { this: row.organizationProducts?.unitOfMeasuresForConversion }) } ${ formatNumber(ceil(row.organizationProducts?.conversionFactor, 2)) } ${ row.organizationProducts?.measureUnit }`
      }
    ], [t]
  )

  return(
    <DataTable
      onDataLoad={ data => {
        setLastUpdatedAt(moment.max(map(data, item => moment(item.lastUpdate, momentBackDateTimeFormat))).format(momentFriendlyDateTimeFormat))
      } }
      data={ service !== null ? service : getUserProductsWithdrawService }
      queryParams ={ filters }
      columns={ columns }
      urlParams={ urlParams }
      id="products-witdraw-list-table"
    />
  )
}

ProductsWitdrawListFragment.propTypes = {
  filters: PropTypes.object,
  setLastUpdatedAt: PropTypes.func.isRequired,
  urlParams: PropTypes.object,
  service: PropTypes.func
}

ProductsWitdrawListFragment.defaultProps = {
  filters: {},
  urlParams: {},
  service: null
}

export default ProductsWitdrawListFragment
