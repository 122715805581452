/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useMemo } from 'react'
import { useSpring, animated } from 'react-spring/web.cjs'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import Collapse from '@material-ui/core/Collapse'
import TreeItem from '@material-ui/lab/TreeItem'

import CheckboxMaterial from './CheckboxMaterial'
import CheckboxTreeItemInput from './CheckboxTreeItemInput'
import { useStylesTreeItem } from './styles'


const TransitionComponent = (props) => {
  const { in: propsIn } = props
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      opacity: propsIn ? 1 : 0,
      transform: `translate3d(${ propsIn ? 0 : 20 }px,0,0)`
    }
  })

  return (
    <animated.div style={ style }>
      <Collapse { ...props } />
    </animated.div>
  )
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool
}

TransitionComponent.defaultProps = {
  in: false
}

const CheckboxTreeItemComponent = (props) => {
  const {
    nodeId,
    label,
    hasOptions,
    checked,
    onChangeChecked,
    color,
    disabled,
    ...otherProps
  } = props

  const {
    optionWithoutChildrens,
    checkboxContainer,
    checkboxLabel,
    checkedCheckboxContainer,
    ...classes
  } = useStylesTreeItem()

  const CheckboxComponent = useCallback(
    () => (
      // verificar se checked == null é necessário quando o pai tem algum filho checkado
      <span className={ (checked || checked == null) ? checkedCheckboxContainer : checkboxContainer } onClick={ (e) => e.stopPropagation() }>
        <CheckboxMaterial
          id={ `checkbox-${ nodeId }` }
          label={ label }
          value={ nodeId }
          checked={ checked }
          onChange={ (chk) => onChangeChecked(nodeId, chk) }
          size="small"
          disabled={ disabled }
          classes={ {
            label: checkboxLabel
          } }
        />
      </span>
    ),
    [checked, checkboxContainer, checkedCheckboxContainer, nodeId, label, disabled, checkboxLabel, onChangeChecked]
  )
  return (
    <TreeItem
      { ...otherProps }
      nodeId={ nodeId }
      label={ <CheckboxComponent /> }
      classes={ {
        ...classes,
        root: clsx(classes.root, {
          [optionWithoutChildrens]: !hasOptions
        })
      } }
      TransitionComponent={ TransitionComponent }
    />
  )
}

CheckboxTreeItemComponent.propTypes = {
  nodeId: PropTypes.string.isRequired,
  hasOptions: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]).isRequired,
  onChangeChecked: PropTypes.func.isRequired,
  color: PropTypes.string,
  checked: PropTypes.any,
  disabled: PropTypes.bool
}

CheckboxTreeItemComponent.defaultProps = {
  checked: null,
  disabled: false,
  color: null
}

const CheckBoxTreeItem = (props) => {
  const {
    id: nodeId,
    options,
    checked,
    onChangeChecked,
    parentPath,
    disabled,
    creatableInput,
    onCreateOption,
    ...option
  } = props

  const optionPath = useMemo(
    () => (parentPath ? `${ parentPath }.${ nodeId }` : nodeId), [nodeId, parentPath]
  )

  const handleChangeOption = useCallback(
    (creatableValue) => {
      onCreateOption(optionPath, creatableValue)
    },
    [onCreateOption, optionPath]
  )

  return (
    <>
      <CheckboxTreeItemComponent
        nodeId={ nodeId }
        hasOptions={ !isEmpty(options) }
        checked={ checked }
        onChangeChecked={ onChangeChecked }
        disabled={ disabled }
        { ...option }
      >
        {!isEmpty(options) && map(options, (childrenOptions) => (
          <CheckBoxTreeItem
            key={ childrenOptions.id }
            onChangeChecked={ onChangeChecked }
            { ...childrenOptions }
            disabled={ disabled || childrenOptions.disabled }
            parentPath={ optionPath }
          />
        ))}
        {creatableInput && (
          <div>
            <CheckboxTreeItemInput
              creatableInput={ creatableInput }
              onCreateOption={ handleChangeOption }
            />
          </div>
        )}
      </CheckboxTreeItemComponent>


    </>
  )
}

CheckBoxTreeItem.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.any,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  creatableInput: PropTypes.object,
  parentPath: PropTypes.string,
  onChangeChecked: PropTypes.func.isRequired,
  onCreateOption: PropTypes.func,
  color: PropTypes.string
}

CheckBoxTreeItem.defaultProps = {
  checked: null,
  disabled: false,
  options: [],
  color: null,
  creatableInput: null,
  parentPath: '',
  onCreateOption: () => {}
}

export default CheckBoxTreeItem
