import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const Header = styled.div`
  background-color: ${ colors.white };
  padding-bottom: 10px;
`

export const CustomInput = styled.input`
  width: 100%;
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid ${ colors.gray };
  font-family: 'Open Sans';
`

export const Title = styled.h3`
  color: ${ colors.text };
  margin-top: 0;
`

export const InputWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`

export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 5px 0;
`

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const GroupTitle = styled.span`
  width: 100%;
  font-size: .85rem;
  color: ${ colors.darkGrey };
  font-weight: 600;
  padding-top: 5px;
`

export const TextName = styled.label`
  font-family: 'Open Sans';
  font-size: .95rem;
  font-weight: 600;
  color: ${ colors.text };
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ContainerSelectAll = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
