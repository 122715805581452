import React from 'react'

import PropTypes from 'prop-types'

import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import { Button, IconCircle } from './styles'

const LargeButton = ({
  onClick,
  icon,
  iconColor,
  circleColor,
  children,
  className,
  disabled
}) => (
  <Button onClick={ onClick } role="button" className={ className } disabled={ disabled }>
    {icon && (
      <IconCircle circleColor={ disabled ? colors.mediumGrey : circleColor }>
        <Icon icon={ icon } size={ 22 } color={ disabled ? colors.white : iconColor } />
      </IconCircle>
    )}
    {children}
  </Button>
)

LargeButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.oneOfType(
    PropTypes.func,
    PropTypes.element
  ).isRequired,
  children: PropTypes.string,
  iconColor: PropTypes.string,
  circleColor: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

LargeButton.defaultProps = {
  onClick: () => {},
  children: '',
  circleColor: colors.secondary,
  iconColor: colors.white,
  className: '',
  disabled: false
}

export default LargeButton
