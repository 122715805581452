import React from 'react'
import { Marker, Popup } from 'react-leaflet'

import PropTypes from 'prop-types'

const PopupMarker = ({ children, point, ...props }) => (
  <Marker position={ point } { ...props }>
    {children && (
      <Popup>
        <span>{children}</span>
      </Popup>
    )}
  </Marker>
)

PopupMarker.propTypes = {
  children: PropTypes.any,
  point: PropTypes.arrayOf(PropTypes.number)
}

PopupMarker.defaultProps = {
  children: null,
  point: []
}

export default PopupMarker
