import styled from 'styled-components'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`

export const TextDate = styled.div`
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 20px;
`

export const TextPassword = styled.div`
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 10px;
`
