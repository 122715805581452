import React from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { verticalDots } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import IconButton from '../IconButton'

const VerticalDotsIconButton = ({ iconColor, ...props }) => {
  const t = useT()
  return (
    <IconButton
      tooltip={ t('options', { howMany: 1 }) }
      { ...props }
    >
      <Icon icon={ verticalDots } size={ 18 } color={ iconColor } />
    </IconButton>
  )
}

VerticalDotsIconButton.propTypes = {
  iconColor: PropTypes.string
}

VerticalDotsIconButton.defaultProps = {
  iconColor: colors.mutedText
}

export default VerticalDotsIconButton
