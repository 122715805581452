import React, { useCallback, useMemo, useContext } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import first from 'lodash/first'

import { conversationService } from '@smartcoop/database/services/conversationService'
import { profilePictureService } from '@smartcoop/database/services/profilePictureService'
import { generateUuid } from '@smartcoop/database/utils/uuidGenerator'
import { database } from '@smartcoop/database/web-database'
import { useT } from '@smartcoop/i18n'
import { blocked, landscapePicture, microphone, star, marker as markerIcon, video, document as documentIcon } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { colors } from '@smartcoop/styles'
import CheckboxCard from '@smartcoop/web-components/FilteredCheckboxGroup/CheckboxCard'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton/IconButton'

import ChatAvatar from '../../../components/ChatAvatar/ChatAvatar'
import MessageStatusIcon from '../../../components/MessageStatusIcon/MessageStatusIcon'
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser'
import { ChatContext } from '../../../providers/ChatProvider'
import { getHighlightedText } from '../../../utils/chatHighlight'
import { formatConversationDate } from '../../../utils/conversationDateFormat'
import { Card, ChatDefailtWrapper, CardRow, NameText, SmallText, MessageWrapper, MessageText, DeletedSpan, NumberInfo, InfoChat } from './styles'


const ConversationListItem = ({
  conversation,
  selected,
  onSelect,
  openSelect,
  profilePictures,
  searchParams
}) => {
  const t = useT()
  const profilePicture = first(profilePictures)

  const { openChat } = useContext(ChatContext)
  const localUser = useAuthenticatedUser()
  const snackbar = useSnackbar()

  const handleFavorite = useCallback(async (event) => {
    try {
      event.stopPropagation()
      await conversationService(database).updateFavorite(
        (
          (conversation.group || conversation.broadcast) ?
            conversation.conversationId :
            conversation.relationalId
        ),
        !conversation.favorite,
        !conversation.conversationId
      )
    } catch (error) {
      snackbar.warning(t('you can only add 3 favorites'))
    }
  }, [conversation, snackbar, t])

  const handleOpenChat = useCallback(() => {
    if (openSelect) {
      if (conversation.group || conversation.broadcast) {
        return false
      }

      return onSelect(conversation.relationalId)
    }

    const relationalId = generateUuid(conversation?.contactId, localUser?.userId)

    return openChat({
      userId: conversation.contactId || conversation.conversationId,
      conversationId: conversation.conversationId,
      relationalId
    })
  }, [openSelect, conversation, localUser, openChat, onSelect])

  const deletedMessage = useMemo(() => !!conversation?.deleted, [conversation.deleted])

  const messageComponent = useMemo(() => {
    if (conversation?.isGeolocation) {
      return (
        <MessageText>
          <Icon icon={ markerIcon } size={ 14 } color="rgba(0, 0, 0, 0.45)" />
          {t('location')}
        </MessageText>
      )
    }

    if (conversation?.documentFormat === 'audio') {
      return <MessageText>
        <Icon icon={ microphone } size={ 14 } color="rgba(0, 0, 0, 0.45)" />
        {t('audio')}
      </MessageText>
    }

    if (conversation?.documentFormat === 'image') {
      return <MessageText>
        <Icon icon={ landscapePicture } size={ 14 } color="rgba(0, 0, 0, 0.45)" />
        {t('one image')}
      </MessageText>
    }

    if (conversation?.documentFormat === 'video') {
      return <MessageText>
        <Icon icon={ video } size={ 14 } color="rgba(0, 0, 0, 0.45)" />
        {t('video')}
      </MessageText>
    }

    if (conversation?.documentFormat === 'document') {
      return <MessageText>
        <Icon icon={ documentIcon } size={ 14 } color="rgba(0, 0, 0, 0.45)" />
        {t('document')}
      </MessageText>
    }

    return <MessageText>{ conversation?.lastMessage }</MessageText>
  }, [conversation.documentFormat, conversation.isGeolocation, conversation.lastMessage, t])

  return (
    <Card onClick={ handleOpenChat } openSelect={ openSelect } selected={ selected }>
      { openSelect && (
        <CheckboxCard
          style={ { backgroundColor: 'transparent', margin: 0, paddingLeft: 4, paddingRight: 0 } }
          onChange={ () => onSelect(conversation.relationalId) }
          onClick={ event => event.stopPropagation() }
          checked={ selected }
          value={ selected }
          disabled={ conversation.group || conversation.broadcast }
        />
      ) }
      <ChatAvatar
        isGroup={ conversation.group }
        isBroadcast={ conversation.broadcast }
        sourceImage={ profilePicture?.source }
        size={ 40 }
      />
      <ChatDefailtWrapper>
        <CardRow>
          <NameText>{getHighlightedText(conversation.name, searchParams)}</NameText>
          <SmallText>{formatConversationDate((conversation.createdAt), t)}</SmallText>
        </CardRow>
        <CardRow>

          <MessageWrapper>
            {(conversation?.unseenCount >= 1) && (
              <NumberInfo>
                <InfoChat>{conversation?.unseenCount}</InfoChat>
              </NumberInfo>
            )}
            { (
              !conversation?.unseenCount &&
              conversation.whoSend &&
              conversation.whoSend === localUser?.userId &&
              !deletedMessage
            ) && (
              <MessageStatusIcon status={ (conversation?.status || '').toUpperCase() } size={ 15 } />
            ) }

            {deletedMessage ? (
              <DeletedSpan>
                <Icon icon={ blocked } size={ 14 } color="rgba(0, 0, 0, 0.45)" />
                {t('message deleted')}
              </DeletedSpan>
            ) : (messageComponent)}
          </MessageWrapper>
          { !!conversation.conversationId && (
            <IconButton size="small" onClick={ handleFavorite }>
              <Icon
                icon={ star }
                size={ 22 }
                color={ conversation?.favorite ? colors.yellow : colors.lightGrey }
              />
            </IconButton>
          ) }
        </CardRow>
      </ChatDefailtWrapper>
    </Card>
  )}

ConversationListItem.propTypes = {
  conversation: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  openSelect: PropTypes.bool,
  profilePictures: PropTypes.array,
  searchParams: PropTypes.string
}

ConversationListItem.defaultProps = {
  selected: false,
  onSelect: () => {},
  openSelect: false,
  profilePictures: [],
  searchParams: undefined
}

const enhance = withObservables(['userId', 'isGroup'], ({ userId, isGroup }) => ({
  profilePictures: profilePictureService(database).observeProfilePictureByUserId(userId, isGroup)
}))

const EnhancedConversationListItem = enhance(ConversationListItem)

export default EnhancedConversationListItem
