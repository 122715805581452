import React, { useCallback, forwardRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import confirmProducer from '@smartcoop/forms/schemas/producer/confirmProducer.schema'
import I18n, { useT } from '@smartcoop/i18n'
import {
  getUserOnboarding
} from '@smartcoop/services/apis/smartcoopApi/resources/authentication'
import { useSnackbar } from '@smartcoop/snackbar'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { selectAuthenticationError } from '@smartcoop/stores/authentication/selectorAuthentication'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputCpfCnpj from '@smartcoop/web-components/InputCpfCnpj'
import InputSelect from '@smartcoop/web-components/InputSelect'
import { Header, Title, Text } from '@smartcoop/web-containers/layouts/GuestLayout/theme'
import { useRoutes } from '@smartcoop/web-containers/routes/guest'

import { Container, ButtonContainer } from './styles'

const ConfirmProducerForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, onSubmit, setCurrentForm, setDisabledNext, orgs } = props


  const history = useHistory()
  const { routes } = useRoutes()
  const snackbar = useSnackbar()
  const authError = useSelector(selectAuthenticationError)
  const t = useT()
  const dispatch = useCallback(useDispatch(), [])

  useEffect(() => {
    if(authError === 'user already registered') {
      snackbar.error(t('user already registered'))
      dispatch(AuthenticationActions.resetAuthentication())
      history.push('/')
    }
  },[authError, dispatch, history, snackbar, t])

  const handleSubmit = useCallback(
    async (data) => {
      onSubmit(data)
      setCurrentForm('registerProducerData')
    },
    [onSubmit, setCurrentForm]
  )

  const searchUser = useCallback(
    async () => {
      const { document } = formRef.current.getData()
      if(!isEmpty(document)) {
        let data = false
        try{
          await setDisabledNext(true)
          data = await getUserOnboarding(
            {},
            {
              document
            }
          )
        } finally {
          if(data) {
            dispatch(AuthenticationActions.validateSignUp(
              document,
              () => {
                snackbar.warning('Produtor já cadastrado.')
                history.push(routes.identification.path)
              }
            ))
          }
          await setDisabledNext(false)
        }
      }
    },
    [dispatch, formRef, history, routes.identification.path, setDisabledNext, snackbar]
  )

  return (
    <Container>
      <Header>
        <Title style={ { alignSelf: 'flex-end' } }>
          <I18n>welcome to smartcoop</I18n>
        </Title>
      </Header>
      <Text>
        {t('if your are an associated producer to one of the participants cooperatives of the smartcoop project and you are not registered in the platform, register by following the steps below')}
      </Text>
      <Form
        ref={ formRef }
        schemaConstructor={ confirmProducer }
        onSubmit={ handleSubmit }
      >
        <InputCpfCnpj
          name="document"
          label="CPF/CNPJ"
          onBlur={ searchUser }
          fullWidth
        />

        {!isEmpty(orgs) && (
          <InputSelect
            multiple
            label={ t('organization', { howMany: 1 }) }
            name="organization"
            options={ map(orgs, item => ({ label: item?.tradeName, value: item?.id })) }
          />
        )}

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="identification-submit"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
            >
              <I18n>next</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

ConfirmProducerForm.propTypes = {
  onSubmit: PropTypes.func,
  setCurrentForm: PropTypes.func,
  setDisabledNext: PropTypes.func,
  orgs: PropTypes.array,
  withoutSubmitButton: PropTypes.bool
}

ConfirmProducerForm.defaultProps = {
  onSubmit: () => {},
  setCurrentForm: () => {},
  setDisabledNext: () => {},
  orgs: [],
  withoutSubmitButton: true
}

export default ConfirmProducerForm
