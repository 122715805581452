import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import float from '@smartcoop/forms/validators/float.validator'
import number from '@smartcoop/forms/validators/number.validator'


const silageCropManagementSchema = ({ t }) => Yup.object().shape({
  productionSilage: flow(
    float({ t })
  )(Yup.string()),
  dryMatterContent: flow(
    number({ t })
  )(Yup.string()),
  dryMatter: Yup.string(),
  estimatedProduction: Yup.string(),
  relativeFoodValue: flow(
    number({ t })
  )(Yup.string())
})

export default silageCropManagementSchema
