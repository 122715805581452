import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import float from '@smartcoop/forms/validators/float.validator'
import number from '@smartcoop/forms/validators/number.validator'
import required from '@smartcoop/forms/validators/required.validator'

const milkRegisterQuality = ({ t }) => Yup.object().shape({
  userStateRegistrationId: flow(
    required({ t })
  )(Yup.string()),

  protein: flow(
  )(Yup.string()),

  fat: flow(
  )(Yup.string()),

  ccs: flow(
    number({ t })
  )(Yup.string()),

  ctb: flow(
    number({ t })
  )(Yup.string()),

  nul: flow(
    float({ t })
  )(Yup.string()),

  lactose: flow(
    float({ t })
  )(Yup.string()),

  date: flow(
    date({ t }),
    required({ t })
  )(Yup.string())
})

export default milkRegisterQuality
