// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
      <g>
        <path fill="#D3D3D3" d="M198.4,55.3c-0.9-1.2-2.6-1.4-3.7-0.5l-4.6,3.6c0,0,0,0,0,0c-1,1.1-7.6,5.3-15.2,5.4
          c-5.3,0.1-9.9-1.8-13.8-5.6c-12.5-12.6-26.9-5.7-32.7-0.3l0,0c0,0,0,0,0,0c-1,1.1-7.8,5.5-15.4,5.7c-5.3,0.1-9.9-1.8-13.8-5.7
          c-12.5-12.5-26.9-5.7-32.7-0.3c0,0,0,0,0,0c-1,1.1-7.8,5.6-15.4,5.8c-5.3,0.1-9.9-1.8-13.8-5.7c-12.2-12.3-26.2-6-32.3-0.7L2,59.2
          c-1.2,0.9-1.3,2.7-0.4,3.9c0.9,1.2,2.6,1.4,3.8,0.5l2.9-2.3v0c0,0,0,0,0,0l1.8-1.3v0c4-2.8,14-8,23.5,1.5c5.5,5.5,11.6,7.3,17,7.3
          c4.7,0,8.9-1.3,11.8-2.5c1.6-0.7,5.8-2.5,7.8-4.7c0.7-0.6,13.3-11.8,25.3,0.2c5.5,5.5,11.6,7.3,17,7.3c4.7,0,8.9-1.3,11.8-2.5
          c1.5-0.7,5.7-2.5,7.7-4.7l0,0c0.6-0.5,13.3-11.9,25.3,0.1c5.5,5.5,11.6,7.3,17,7.3c4.7,0,8.9-1.3,11.8-2.5c1.3-0.6,4.7-2,6.8-3.9
          l5-3.6C199.2,58.3,199.3,56.4,198.4,55.3z"/>
        <path fill="#D3D3D3" d="M198.4,135.5c-0.9-1.2-2.6-1.4-3.7-0.5l-4.6,3.6c0,0,0,0,0,0c-1,1.1-7.6,5.3-15.2,5.4
          c-5.3,0.1-9.9-1.8-13.8-5.6c-12.5-12.6-26.9-5.7-32.7-0.3l0,0c0,0,0,0,0,0c-1,1.1-7.8,5.5-15.4,5.7c-5.3,0.1-9.9-1.8-13.8-5.7
          c-12.5-12.5-26.9-5.7-32.7-0.3c0,0,0,0,0,0c-1,1.1-7.8,5.6-15.4,5.8c-5.3,0.1-9.9-1.8-13.8-5.7c-12.2-12.3-26.2-6-32.3-0.7
          L2,139.4c-1.2,0.9-1.3,2.7-0.4,3.9c0.9,1.2,2.6,1.4,3.8,0.5l2.9-2.3l0,0c0,0,0,0,0,0l1.8-1.4v0c4-2.8,14-8,23.5,1.5
          c5.5,5.6,11.6,7.3,17,7.3c4.7,0,8.9-1.3,11.8-2.5c1.6-0.7,5.8-2.5,7.8-4.7c0.7-0.6,13.3-11.8,25.3,0.2c5.5,5.5,11.6,7.3,17,7.3
          c4.7,0,8.9-1.3,11.8-2.5c1.5-0.6,5.7-2.5,7.7-4.7l0,0c0.6-0.5,13.3-11.9,25.3,0.1c5.5,5.5,11.6,7.2,17,7.2c4.7,0,8.9-1.3,11.8-2.5
          c1.3-0.6,4.7-2,6.8-3.9l5-3.6C199.2,138.6,199.3,136.7,198.4,135.5z"/>
        <path fill="#D3D3D3" d="M198.4,81.5c-0.9-1.2-2.6-1.4-3.7-0.5l-4.6,3.6c0,0,0,0,0,0c-1,1.1-7.6,5.3-15.2,5.5
          c-5.3,0.1-9.9-1.8-13.8-5.7c-12.5-12.5-26.9-5.7-32.7-0.3h0c0,0,0,0,0,0c-1,1.1-7.8,5.5-15.4,5.7c-5.3,0.1-9.9-1.8-13.8-5.7
          c-12.5-12.5-26.9-5.7-32.7-0.3c0,0,0,0,0,0c-1,1.1-7.8,5.6-15.4,5.8c-5.3,0.1-9.9-1.8-13.8-5.7c-12.2-12.2-26.2-6-32.3-0.7L2,85.4
          c-1.2,0.9-1.3,2.7-0.4,3.9c0.9,1.2,2.6,1.4,3.8,0.5l2.9-2.3l0,0c0,0,0,0,0,0l1.8-1.4v0c4-2.8,14-8,23.5,1.5
          c5.5,5.5,11.6,7.2,17,7.2c4.7,0,8.9-1.3,11.8-2.5c1.6-0.6,5.8-2.5,7.8-4.7c0.7-0.6,13.3-11.8,25.3,0.2c5.5,5.5,11.6,7.2,17,7.2
          c4.7,0,8.9-1.3,11.8-2.5c1.5-0.6,5.7-2.5,7.7-4.7l0,0c0.6-0.5,13.3-11.8,25.3,0.2c5.5,5.5,11.6,7.2,17,7.2c4.7,0,8.9-1.3,11.8-2.5
          c1.3-0.6,4.7-2,6.8-3.9l5-3.6C199.2,84.5,199.3,82.7,198.4,81.5z"/>
        <path fill="#D3D3D3" d="M198.4,108.8c-0.9-1.2-2.6-1.4-3.7-0.5l-4.6,3.6c0,0,0,0,0,0c-1,1-7.6,5.3-15.2,5.4
          c-5.3,0.1-9.9-1.8-13.8-5.7c-12.5-12.5-26.9-5.7-32.7-0.3l0,0c0,0,0,0,0,0c-1,1.1-7.8,5.6-15.4,5.7c-5.3,0.1-9.9-1.8-13.8-5.7
          c-12.5-12.5-26.9-5.7-32.7-0.3c0,0,0,0,0,0c-1,1-7.8,5.6-15.4,5.8c-5.3,0.1-9.9-1.8-13.8-5.7c-12.2-12.2-26.2-6-32.3-0.7L2,112.7
          c-1.2,0.9-1.3,2.7-0.4,3.9c0.9,1.2,2.6,1.4,3.8,0.6l2.9-2.3l0,0c0,0,0,0,0,0l1.8-1.4l0,0c4-2.8,14-8,23.5,1.5
          c5.5,5.5,11.6,7.2,17,7.2c4.7,0,8.9-1.3,11.8-2.5c1.6-0.7,5.8-2.5,7.8-4.7c0.7-0.6,13.3-11.8,25.3,0.2c5.5,5.5,11.6,7.2,17,7.2
          c4.7,0,8.9-1.3,11.8-2.5c1.5-0.7,5.7-2.5,7.7-4.7l0,0c0.6-0.5,13.3-11.9,25.3,0.1c5.5,5.5,11.6,7.3,17,7.3c4.7,0,8.9-1.3,11.8-2.5
          c1.3-0.6,4.7-2.1,6.8-3.9l5-3.6C199.2,111.8,199.3,110,198.4,108.8z"/>
      </g>
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
