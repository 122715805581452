import React from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { userCircle } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import IconButton from '../IconButton'
import useStyles, { UserName } from './styles'

const UserIconButton = ({ iconColor, userName, ...props }) => {
  const t = useT()
  const classes = useStyles()
  return (
    <IconButton classes={ {
      root: classes.root
    } }
    tooltip={ t('user', { howMany: 1 }) }
    { ...props }
    >
      {userName && <UserName>{userName}</UserName>}
      <Icon icon={ userCircle } color={ iconColor } />
    </IconButton>
  )
}

UserIconButton.propTypes = {
  iconColor: PropTypes.string,
  userName: PropTypes.string
}

UserIconButton.defaultProps = {
  iconColor: colors.mutedText,
  userName: ''
}

export default UserIconButton
