
import { call, put, takeLatest } from 'redux-saga/effects'

import {
  getAllWeatherStations as getAllWeatherStationsService,
  getWeatherStation as getWeatherStationService
} from '@smartcoop/services/apis/smartcoopApi/resources/weatherStations'

import { WeatherStationsTypes, WeatherStationsActions } from './duckWeatherStations'


function* loadWeatherStations({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: { data, ...pagination } } = yield call(getAllWeatherStationsService, params)

    yield put(WeatherStationsActions.loadWeatherStationsSuccess(
      data,
      pagination.page,
      () => onSuccess(pagination)
    ))
  } catch (err) {
    const error = err.message
    yield put(WeatherStationsActions.weatherStationsError(error))
    yield call(onError, error)
  }
}

function* loadWeatherStationsSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* loadCurrentWeatherStation({ weatherStationId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(getWeatherStationService,
      {},
      { weatherStationId }
    )
    yield put(WeatherStationsActions.setCurrentWeatherStation(data))
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(WeatherStationsActions.weatherStationsError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(WeatherStationsTypes.LOAD_WEATHER_STATIONS, loadWeatherStations),
  takeLatest(WeatherStationsTypes.LOAD_WEATHER_STATIONS_SUCCESS, loadWeatherStationsSuccess),
  takeLatest(WeatherStationsTypes.LOAD_CURRENT_WEATHER_STATION, loadCurrentWeatherStation)
]
