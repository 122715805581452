import React from 'react'
import Spreadsheet from 'react-spreadsheet'

import PropTypes from 'prop-types'

const SpreadsheetComponent = (props) => {
  const { data, onChange, columnLabels, ...otherProps } = props

  return (
    <Spreadsheet data={ data } onChange={ onChange } columnLabels={ columnLabels } { ...otherProps } />
  )
}

SpreadsheetComponent.propTypes = {
  data: PropTypes.array.isRequired,
  columnLabels: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

SpreadsheetComponent.defaultProps = {
}

export default SpreadsheetComponent
