import { REACT_APP_CHAT_WEBSOCKET_URL } from 'react-native-dotenv'

let isConnected = false
let ws: WebSocket = null

export class WebSocketPersist {
  constructor() {
    this.body = null
    this.intervalId = null
  }

  connect(body) {
    return new Promise((resolve, reject) => {
      this.body = body

      if (ws && (ws.readyState === WebSocket.OPEN || ws.readyState === WebSocket.CONNECTING)) {
        resolve()
      } else {
        ws = new WebSocket(process.env.REACT_APP_CHAT_WEBSOCKET_URL || REACT_APP_CHAT_WEBSOCKET_URL)

        ws.addEventListener('open', () => {
          console.log('[🔌] [WebSocket] Connected')
          if (!isConnected && body?.userId) {
            const payload = {
              userId: body.userId
            }
            ws.send(
              JSON.stringify({
                event: 'onConnection',
                data: payload
              })
            )
          }
          isConnected = true

          clearInterval(this.intervalId)

          this.intervalId = setInterval(() => {
            this.pingServer()
          }, 30000)

          resolve()
        })

        ws.addEventListener('close', () => {
          console.log('[🔌] [WebSocket] Close Connection')
          isConnected = false

          setTimeout(() => {
            isConnected = false
            this.connect(this.body)
          }, 1000)
        })

        ws.addEventListener('error', (error) => {
          console.log('[🔌] [WebSocket] Error Connection')

          setTimeout(() => {
            this.connect(this.body)
          }, 1000)

          reject(error)
        })
      }
    })
  }

  removeEvents() {
    ws.removeEventListener('close', () => {})
    ws.removeEventListener('error', () => {})
    ws.removeEventListener('open', () => {})
  }

  disconnect() {
    ws.close()
  }

  sendMessage(payload) {
    ws.send(
      JSON.stringify({
        event: 'sendMessage',
        data: payload
      })
    )
  }

  receiveAction(callback) {
    ws.onmessage = (data) => {
      const objData = JSON.parse(data?.data)
      callback(objData)
    }
  }

  pingServer() {
    if (ws.readyState === WebSocket.OPEN) {
      console.log('[Websocket] Event Send: ping')
      ws.send(JSON.stringify({ event: 'ping' }))
    }
  }
}

export default WebSocketPersist
