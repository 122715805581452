import React from 'react'
import { Polyline as PolylineLeaflet } from 'react-leaflet'

import PropTypes from 'prop-types'

const Polyline = ({ points, attribution }) => (
  <PolylineLeaflet positions={ points } attribution={ attribution } />
)

Polyline.propTypes = {
  points: PropTypes.array,
  attribution: PropTypes.string
}

Polyline.defaultProps = {
  points: [],
  attribution: null
}

export default Polyline
