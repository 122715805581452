import trimMask from '@meta-awesome/functions/src/trimMask'

import size from 'lodash/size'

export default (value = '', { onlyCpf, onlyCnpj }) => {
  if(value === '') return '99999999999999'

  const cpfSize = 11 // without mask
  const isCpf = size(trimMask(value)) <= cpfSize

  if (onlyCpf || (!onlyCnpj && isCpf)) {
    // CPF mask
    // the last digit enables the mask to change to CNPJ
    return `999.999.999-99${ !onlyCpf ? '9' : '' }`
  }
  // CNPJ mask
  return '99.999.999/9999-99'
}
