import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { select, call, put, takeLatest, all } from 'redux-saga/effects'

import { v4 as uuidv4 } from 'uuid'

import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'

import {
  getInseminations as getInseminationsService,
  createInsemination as createInseminationService,
  updateInsemination as updateInseminationService,
  deleteInsemination as deleteInseminationService,
  getInseminationTypes as getInseminationTypesService
} from '@smartcoop/services/apis/smartcoopApi/resources/insemination'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { selectOfflineAnimalStatus, selectOfflineAnimals } from '@smartcoop/stores/offlineData/userData/offlineAnimalUserData/selectorOfflineAnimalUserData'
import { OfflineTechnicalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData'
import { selectOfflineTechnicalAnimals } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData/selectorOfflineTechnicalUserData'

import { selectModuleIsTechnical } from '../module/selectorModule'
import { selectIsConnected } from '../network/selectorNetwork'
import { OfflineAnimalUserDataActions } from '../offlineData/userData/offlineAnimalUserData/duckOfflineAnimalUserData'
import {
  InseminationActions,
  InseminationTypes
} from './duckInsemination'


function* loadInseminations({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentAnimal = yield select(selectCurrentAnimal)
    const { id } = currentAnimal
    const { data: { data, ...pagination } } = yield call(
      getInseminationsService,
      {
        limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
        ...params
      },
      { animalId: id }
    )

    yield put(InseminationActions.loadInseminationsSuccess(
      data,
      pagination.page,
      onSuccess(data)
    ))
  } catch (err) {
    const error = err.message
    yield put(InseminationActions.inseminationError(error))
    yield call(onError, error)
  }
}

function* saveInsemination({ params, onSuccess = () => {}, onError = () => {}, isEdit = false }) {
  try {
    const service = !isEdit ? createInseminationService : updateInseminationService
    const data = yield call(service, params, { animalId: params?.animalId, inseminationId: params?.id })
    yield put(InseminationActions.resetBlockInsemination())
    yield call(onSuccess, data)
  } catch (error) {
    yield put(InseminationActions.inseminationError(error))
    yield call(onError, error)
  }
}

function* saveOfflineInsemination({ params, onSuccess = () => {}, onError = () => {}, web = false }) {
  try {
    const isTechnical = yield select(selectModuleIsTechnical)
    const status = web ? null : yield select(selectOfflineAnimalStatus)
    // eslint-disable-next-line no-nested-ternary
    const animals = web ? null : (isTechnical ? yield select(selectOfflineTechnicalAnimals) : yield select(selectOfflineAnimals))
    const isConnected = web ? true : yield select(selectIsConnected)

    yield all(params?.animalsId.map(id => put(InseminationActions.addBlockInsemination(id))))

    if(!web) {
      const newInsemination = {
        ...params,
        id: params?.id,
        // eslint-disable-next-line no-unused-vars
        ids: params?.id ? [params?.id] : map(params?.animalsId, _ => uuidv4())
      }
      const updatedAnimals = map(params?.animalsId, id => {
        const foundAnimal = find(animals, each => each.id === id)
        return {
          ...foundAnimal,
          inseminations: [
            ...filter(foundAnimal?.inseminations, item => item.id !== params.id),
            newInsemination
          ],
          animalStatus: find(status, item => item.id === 2),
          statusId: find(status, item => item.id === 2).id,
          alreadyInseminated: !isConnected ? true : null
        }
      })
      if (isTechnical) {
        yield all(updatedAnimals.map(animal => put(OfflineTechnicalUserDataActions.updateOfflineAnimal(animal))))
      } else {
        yield all(updatedAnimals.map(animal => put(OfflineAnimalUserDataActions.updateOfflineAnimal(animal))))
      }

      yield put(InseminationActions.saveInsemination(newInsemination, onSuccess, onError, params?.id))
    }
    else {
      yield put(InseminationActions.saveInsemination(params, onSuccess, onError, params?.id))
    }

  } catch (error) {
    yield put(InseminationActions.inseminationError(error))
    yield call(onError, error)
  }
}

function* deleteInsemination({ inseminationId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentAnimal = yield select(selectCurrentAnimal)
    const { id } = currentAnimal

    yield call(deleteInseminationService, {}, { animalId: id, inseminationId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(InseminationActions.inseminationError(error))
    yield call(onError, error)
  }
}

function* deleteOfflineInsemination({ inseminationId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(OfflineAnimalUserDataActions.removeOfflineInsemination(inseminationId))

    yield put(InseminationActions.deleteInsemination(inseminationId, onSuccess, onError))
  } catch (err) {
    const error = err.message
    yield put(InseminationActions.inseminationError(error))
    yield call(onError, error)
  }
}

function* loadInseminationTypes() {
  try {
    const { id } = yield select(selectCurrentAnimal)
    const { data: types } = yield call(getInseminationTypesService, {}, { animalId: id })
    yield put(InseminationActions.loadInseminationTypesSuccess(types, { animalId: id }))
  } catch (err) {
    const error = err.message
    yield put(InseminationActions.inseminationError(error))
  }
}

function* loadCurrentInsemination({ params }) {
  try {
    const { id } = yield select(selectCurrentAnimal)
    const { data } = yield call(getInseminationsService,
      {
        limit: 1,
        orderBy: '-insemination_date',
        ...params
      }, { animalId: id })

    const currentInsemination = data.data[0]
    yield put(InseminationActions.loadCurrentInseminationSuccess(currentInsemination, { animalId: id }))
  } catch (err) {
    const error = err.message
    yield put(InseminationActions.inseminationError(error))
  }
}

export default [
  takeLatest(InseminationTypes.LOAD_INSEMINATIONS, loadInseminations),

  takeLatest(InseminationTypes.LOAD_CURRENT_INSEMINATION, loadCurrentInsemination),

  takeLatest(InseminationTypes.LOAD_INSEMINATION_TYPES, loadInseminationTypes),

  takeLatest(InseminationTypes.SAVE_INSEMINATION, saveInsemination),
  takeLatest(InseminationTypes.SAVE_OFFLINE_INSEMINATION, saveOfflineInsemination),
  takeLatest(InseminationTypes.DELETE_INSEMINATION, deleteInsemination),
  takeLatest(InseminationTypes.DELETE_OFFLINE_INSEMINATION, deleteOfflineInsemination)
]
