import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

import colors from '@smartcoop/styles/colors'
import Card from '@smartcoop/web-components/Card'


export const Container = styled(Grid).attrs({
  container: true,
  spacing: 4
})`
  padding: 40px 30px;
`

export const WhiteCard = styled(Card).attrs({
  style: {
    marginTop: '15px',
    marginBottom: '20px',
    display: 'block'
  },
  cardStyle: {
    padding: '10px 0px',
    paddingBottom: '20px'
  },
  headerTitleProps: {
    style: {
      fontWeight: 600,
      fontSize: '16px',
      marginBottom: '20px',
      borderBottom: `1px solid ${ colors.lightGrey }`,
      paddingLeft: '20px',
      paddingBottom: '10px'
    },
    align: 'left'
  }
})``

export const CardChildren = styled.div`
  padding: 0px 20px;
`

export const Header = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  margin-left: -12px;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`
