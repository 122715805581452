import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import toString from 'lodash/toString'

import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { AnimalPregnancyActionsActions } from '@smartcoop/stores/animalPregnancyActions'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { momentBackDateTimeFormat } from '@smartcoop/utils/dates'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterPregnancyActionsForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterPregnancyActionsForm'

import useStyles from './styles'

const RegisterPregnancyActionsModal = (props) => {
  const { id, open, onSubmit, handleClose, data: propData, animalId } = props

  const t = useT()
  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const currentAnimal = useSelector(selectCurrentAnimal)
  const propertyId = useSelector(selectCurrentPropertyId)

  const [isLoading, setIsLoading] = useState(false)

  const isEditing = useMemo(() => !isEmpty(toString(propData?.id)), [propData])
  const defaultValues = useMemo(
    () => ({
      earring: currentAnimal?.earringCode || currentAnimal?.earring?.earringCode,
      animalId: animalId || currentAnimal?.animalId || currentAnimal?.id,
      ...propData
    }),
    [animalId, currentAnimal, propData]
  )

  const closeModal = useCallback(() => {
    setIsLoading(false)
    handleClose()
  }, [handleClose])

  const onSuccess = useCallback(() => {
    snackbar.success(
      t(`your {this} was ${ isEditing ? 'edited' : 'registered' }`, {
        howMany: 1,
        gender: 'female',
        this: t('action')
      })
    )
    onSubmit()
    closeModal()
  }, [closeModal, isEditing, onSubmit, snackbar, t])

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(
        AnimalPregnancyActionsActions.saveDrying(
          {
            id: propData?.id || null,
            ...data,
            accomplishedDate: moment(data.accomplishedDate).format(
              momentBackDateTimeFormat
            )
          },
          onSuccess,
          (e) => {
            snackbar.error(e)
            closeModal()
            setIsLoading(false)
          },
          isEditing,
          true
        )
      )
    },
    [closeModal, dispatch, isEditing, onSuccess, propData, snackbar]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={ <I18n params={ { this: t('other actions') } }>{'register {this}'}</I18n> }
      disableFullScreen
      disableEsc
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        {isLoading ? (
          <Loader width={ 100 } />
        ) : (
          <RegisterPregnancyActionsForm
            formRef={ formRef }
            defaultValues={ defaultValues }
            onSubmit={ handleSubmit }
            onCancel={ closeModal }
            propertyId={ propertyId }
          />
        )}
      </>
    </Modal>
  )
}

RegisterPregnancyActionsModal.propTypes = {
  id: PropTypes.string.isRequired,
  animalId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.object
}

RegisterPregnancyActionsModal.defaultProps = {
  animalId: null,
  handleClose: () => {},
  onSubmit: () => {},
  data: {}
}

export default RegisterPregnancyActionsModal
