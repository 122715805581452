import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import monthYear from '@smartcoop/forms/validators/monthYear.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerMonthlyDataHistory = ({ t }) => Yup.object().shape({
  date: flow(
    monthYear({ t }),
    required({ t })
  )(Yup.string()),

  rationData: Yup.array().of(
    Yup.object().shape({
      productId: flow(
      )(Yup.string().nullable()),
      monthlyRation: flow(
      )(Yup.string().nullable()),
      totalCost: flow(
      )(Yup.string().nullable()),
      unitaryCost: flow(
      )(Yup.string().nullable()),
      lotsIds: flow(
      )(Yup.string().nullable()),
      referenceId: flow(
      )(Yup.string().nullable())
    })
  ),
  monthlyMilkForCalves: flow(
  )(Yup.string()),

  monthlyDiscardedMilk: flow(
  )(Yup.string())
})

export default registerMonthlyDataHistory
