import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { colors } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'


export default makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))
export const CommentDiv = styled.div`
  width: 50%;
  height: 100%;
  padding: 0;
`
export const LabelComments = styled(Typography)`
  font-size: ${ fonts.fontSize.XL }px;
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: ${ fonts.fontWeight.bold };
  color: ${ colors.black };
  margin-top: 20px;
  margin-bottom: 14px;
`
export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
