import moment from 'moment/moment'
import * as Yup from 'yup'


import flow from 'lodash/fp/flow'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

import date from '@smartcoop/forms/validators/date.validator'
import hour from '@smartcoop/forms/validators/hour.validator'
import maxDate from '@smartcoop/forms/validators/maxDate.validator'
import minDate from '@smartcoop/forms/validators/minDate.validator'
import monthYear from '@smartcoop/forms/validators/monthYear.validator'
import required from '@smartcoop/forms/validators/required.validator'
import year from '@smartcoop/forms/validators/year.validator'
import { FORM_INPUT_TYPES } from '@smartcoop/utils/constants'

const technicalVisit = ({ t, props }) => Yup.object().shape(mapValues(keyBy(map(props.questions, (item) => ({ name: item?.id, validation: flow(
  item?.require && item?.type !== FORM_INPUT_TYPES.signature && item?.type !== FORM_INPUT_TYPES.image ? required({ t }) : val => val,
  item?.type === FORM_INPUT_TYPES.date ? date({ t }) : val => val,
  item?.type === FORM_INPUT_TYPES.hour ? hour({ t }) : val => val,
  item?.type === FORM_INPUT_TYPES.monthYear ? monthYear({ t }) : val => val,
  item?.type === FORM_INPUT_TYPES.year ? year({ t }) : val => val,
  item?.validation?.maxDate ? maxDate({ t, maxReceiptDate: moment(item?.validation?.maxDate).format('YYYY-MM-DD') }) : val => val,
  item?.validation?.minDate ? minDate({ t, minReceiptDate: moment(item?.validation?.maxDate).format('YYYY-MM-DD') }) : val => val,
  item?.validation?.notFuture ? maxDate({ t, maxReceiptDate: moment().format('YYYY-MM-DD') }) : val => val,
  item?.validation?.notPast ? minDate({ t, minReceiptDate: moment().format('YYYY-MM-DD') }) : val => val
)(Yup.string().nullable()) })), 'name'), 'validation'))

export default technicalVisit
