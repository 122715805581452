import React, { useRef, useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import map from 'lodash/map'

import { OrderActions } from '@smartcoop/stores/order'
import { ProductsActions } from '@smartcoop/stores/products'
import EditProductForm from '@smartcoop/web-containers/forms/shoppingPlatform/product/EditProductForm'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

const INITIAL_PRODUCT = {
  id: '',
  productGroupId: '',
  description: '',
  slug: '',
  shortDescription: '',
  ncm: '',
  unitOfMeasures: '',
  ean: [],
  barcodes: [
    {
      id: '',
      productId: '',
      ean: ''
    }
  ],
  dun14: '',
  cultivate: false,
  active: false,
  purchasing: false,
  commercialization: false,
  digitalization: false,
  extraAttributes: [
    {
      attribute: '',
      value: ''
    }
  ],
  continuous: false,
  scheduled: false,
  createdAt: '',
  updatedAt: '',
  minReceiptDate: ''
}
const EditProductScreen = () => {
  const history = useHistory()
  const { state } = useLocation()
  const { routes } = useRoutes()
  const editProductRef = useRef(null)
  const dispatch = useCallback(useDispatch(), [])

  const [loading, setLoading] = useState(true)
  const [suppliersProduct, setSuppliersProduct] = useState([])
  const [productData, setProductData] = useState(INITIAL_PRODUCT)

  const { productId } = state

  useEffect(() => {
    if (productId) {
      dispatch(
        ProductsActions.findProduct(
          { productId },
          ({ data }) => {
            setProductData(data)
            setLoading(false)
          },
          () => {}
        )
      )
      dispatch(OrderActions.loadSuppliersByProduct(
        productId,
        (data) => {
          setSuppliersProduct(data)
        }
      ))

    }
  }, [dispatch, productId])

  const onSuccess = useCallback(() => {
    history.push(routes.productList.path)
  },
  [history, routes.productList.path])

  const onCancel = useCallback(() => history.goBack(), [history])

  return (
    <EditProductForm
      ref={ editProductRef }
      onSuccess={ onSuccess }
      onCancel={ onCancel }
      loadingData={ loading }
      productData={ productData }
      suppliersProduct={ map(suppliersProduct, item => item.id) }
    />
  )
}

export default EditProductScreen
