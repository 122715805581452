export default ({
  width = 32,
  height = 28,
  color = '#1D1D1B' } = {}) => /* html */`
<svg width="${ width }" height="${ height }" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.6233 15.6383V15.6344L28.4648 16.9075L31.3181 15.8377L24.2893 13.2021C23.9253 13.0661 23.5238 13.0661 23.1599 13.2021L16.1311 15.8377L23.7246 18.6858L26.5661 17.6199L23.7246 16.4477V16.4452L25.6233 15.6383Z" fill="${ color }"/>
<path d="M16.1311 16.7864V24.7801C16.1311 25.1103 16.3357 25.4056 16.6453 25.5222L23.2506 27.9998V19.457L16.1311 16.7864ZM19.9285 25.3616L18.0298 24.6492V23.6364L19.9285 24.3488V25.3616Z" fill="${ color }"/>
<path d="M28.4714 17.8549V20.2328L26.5727 20.9451V18.5672L24.2 19.457V27.9998L30.6731 25.5714C31.0617 25.4263 31.3181 25.0546 31.3181 24.6402V16.7864L28.4714 17.8549Z" fill="${ color }"/>
<path d="M9.49217 15.6383V15.6344L12.3337 16.9075L15.1869 15.8377L8.15816 13.2021C7.79422 13.0661 7.39273 13.0661 7.02879 13.2021L0 15.8377L7.59347 18.6858L10.435 17.6199L7.59347 16.4477V16.4452L9.49217 15.6383Z" fill="${ color }"/>
<path d="M0 16.7864V24.7801C0 25.1103 0.204634 25.4056 0.514175 25.5222L7.11945 27.9998V19.457L0 16.7864ZM3.79738 25.3616L1.89869 24.6492V23.6364L3.79738 24.3488V25.3616Z" fill="${ color }"/>
<path d="M12.3404 17.8549V20.2328L10.4417 20.9451V18.5672L8.06897 19.457V27.9998L14.5421 25.5714C14.9307 25.4263 15.1871 25.0546 15.1871 24.6402V16.7864L12.3404 17.8549Z" fill="${ color }"/>
<path d="M17.5819 2.53817V2.53429L20.4235 3.80742L23.2767 2.73763L16.2479 0.101993C15.8839 -0.0339977 15.4824 -0.0339977 15.1185 0.101993L8.08972 2.73763L15.6832 5.58567L18.5248 4.51976L15.6832 3.34894V3.34635L17.5819 2.53817Z" fill="${ color }"/>
<path d="M8.08948 3.68652V11.6802C8.08948 12.0105 8.29411 12.3058 8.60365 12.4223L15.2089 14.8999V6.35713L8.08948 3.68652ZM11.8869 12.2604L9.98817 11.5481V10.5353L11.8869 11.2476V12.2604Z" fill="${ color }"/>
<path d="M20.4297 4.75502V7.13292L18.531 7.84526V5.46606L16.1583 6.35583V14.8987L22.6315 12.4702C23.02 12.3252 23.2765 11.9535 23.2765 11.539V3.68652L20.4297 4.75502Z" fill="${ color }"/>
</svg>

`
