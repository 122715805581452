import React, { useCallback, forwardRef } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import profileSchema from '@smartcoop/forms/schemas/profile/profileEdit.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputEmail from '@smartcoop/web-components/InputEmail'
import InputPhone from '@smartcoop/web-components/InputPhone'
import InputText from '@smartcoop/web-components/InputText'

import { Container, FormContainer, ButtonsContainer, ButtonContainer } from './styles'

const EditProfileForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel
  } = props

  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data
      })
    },
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ profileSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <InputText
            name="name"
            label={ t('name') }
            defaultValue={ defaultValues.name }
            style={ { width: '100%' } }
          />

          <InputText
            name="chatNickname"
            label={ t('nickname') }
            defaultValue={ defaultValues.chatNickname || defaultValues.name }
            style={ { width: '100%' } }
          />

          <InputDate
            label={ t('date of birth') }
            name="dateOfBirth"
            defaultValue={ defaultValues?.dateOfBirth }
            fullWidth
            pickerProps={ {
              minDate: moment('01-01-1900', 'DD-MM-YYYY').format('DD-MM-YYYY'),
              maxDate: moment().format('DD-MM-YYYY')
            } }
          />

          <InputEmail
            name="email"
            label={ t('email') }
            defaultValue={ defaultValues.email }
            style={ { width: '100%' } }
          />

          <InputPhone
            label={ t('phone') }
            name="cellPhone"
            defaultValue={ defaultValues.cellPhone }
            style={ { width: '100%' } }
          />

          <InputDate
            label={ t('date of birth') }
            name="dateOfBirth"
            defaultValue={ defaultValues?.dateOfBirth }
            fullWidth
            pickerProps={ {
              minDate: moment('01-01-1900', 'DD-MM-YYYY').format('DD-MM-YYYY'),
              maxDate: moment().format('DD-MM-YYYY')
            } }
          />
        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <ButtonContainer>
              <Button
                id="web-cancel-form-button"
                onClick={ onCancel }
                color={ colors.white }
                disabled={ loading }
              >
                <I18n>cancel</I18n>
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button
                id="web-save-form-button"
                onClick={ () => formRef.current.submit() }
                disabled={ loading }
              >
                <I18n>save</I18n>
              </Button>
            </ButtonContainer>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

EditProfileForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  defaultValues: PropTypes.object.isRequired
}

EditProfileForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false
}

export default EditProfileForm
