import React, { useCallback } from 'react'

import PropTypes from 'prop-types'

import cpfCnpjMask from '@smartcoop/forms/masks/cpfCnpj.mask'
import TextField from '@smartcoop/web-components/TextField'

const InputCpfCnpj = (props) => {
  const {
    onlyCpf,
    onlyCnpj,
    ...otherProps
  } = props

  const setMask = useCallback(
    (value) => cpfCnpjMask(value, { onlyCpf, onlyCnpj }),
    [onlyCpf, onlyCnpj]
  )

  return (
    <TextField
      { ...otherProps }
      type="tel"
      setMask={ setMask }
    />
  )
}

InputCpfCnpj.propTypes = {
  /** If `true` just show CPF */
  onlyCpf: PropTypes.bool,
  /** If `true` just show CNPJ */
  onlyCnpj: PropTypes.bool
}

InputCpfCnpj.defaultProps = {
  onlyCpf: false,
  onlyCnpj: false
}

export default InputCpfCnpj
