import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import required from '@smartcoop/forms/validators/required.validator'


const filterRainMapSchema = ({ t }) => Yup.object().shape({
  initialDate: flow(
    date({ t }),
    required({ t })
  )(Yup.string()),

  finalDate: flow(
    date({ t }),
    required({ t })
  )(Yup.string())
})

export default filterRainMapSchema
