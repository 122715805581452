import { tableSchema } from '@nozbe/watermelondb'

export const conversationSchema = tableSchema({
  name: 'conversation',
  columns: [
    { name: 'conversationId', type: 'string' },
    { name: 'lastMessage', type: 'string' },
    { name: 'unseenCount', type: 'number' },
    { name: 'createdAt', type: 'number' },
    { name: 'photo', type: 'string' },
    { name: 'name', type: 'string' },
    { name: 'whoSend', type: 'string' },
    { name: 'favorite', type: 'boolean' },
    { name: 'broadcast', type: 'boolean' },
    { name: 'group', type: 'boolean' },
    { name: 'contactId', type: 'string' },
    { name: 'relationalId', type: 'string' },
    { name: 'status', type: 'string' },
    { name: 'userCode', type: 'string' },
    { name: 'publicKey', type: 'string' },
    { name: 'registry', type: 'string' },
    { name: 'invalidCode', type: 'boolean' },
    { name: 'activeUser', type: 'boolean' },
    { name: 'documentFormat', type: 'string' },
    { name: 'isGeolocation', type: 'boolean' },
    { name: 'status', type: 'string' }
  ]
})
