import React from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import I18n, { useT } from '@smartcoop/i18n'
import { wallet } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import { momentBackDateTimeFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import { formatCurrencyIntl } from '@smartcoop/utils/formatters'
import Card from '@smartcoop/web-components/Card'
import Icon from '@smartcoop/web-components/Icon'

import {
  AccountTitle,
  ContentName,
  ContentBalance,
  HideBalance,
  TextBalance,
  Row,
  Column,
  CreditLimitLabel,
  CreditLimit
} from './styles'

const AccountBalance = ({
  hideBalance,
  accountName,
  balance,
  active,
  onClick,
  creditLimit,
  style
}) => {
  const t = useT()

  return (
    <Card
      style={ { margin: 0 } }
      cardStyle={ {
        padding: '14px',
        width: '100%',
        marginBottom: 20,
        ...(active ? { border: '2px solid #FFC80A' } : { border: '2px solid transparent' }),
        ...style
      } }
      onClick={ onClick }
    >
      <>
        <ContentName>
          <Icon style={ { marginRight: '10px' } } size={ 18 } icon={ wallet } color={ colors.green }/>
          <AccountTitle>{accountName}</AccountTitle>
        </ContentName>
        <ContentBalance>
          {
            hideBalance
              ? <HideBalance/>
              : <TextBalance>{formatCurrencyIntl(balance)}</TextBalance>
          }

          {!isEmpty(creditLimit) && (
            <Row style={ { marginLeft: 5 } }>
              <Column>
                <CreditLimitLabel><I18n>credit limit</I18n></CreditLimitLabel>
                <CreditLimit>{creditLimit.creditLimit ? formatCurrencyIntl(creditLimit.creditLimit) : t('does not apply - short version')}</CreditLimit>
              </Column>
              <Column>
                <CreditLimitLabel><I18n>renovation</I18n></CreditLimitLabel>
                <CreditLimit>{
                  creditLimit.renovationDate
                    ? moment(creditLimit.renovationDate, momentBackDateTimeFormat).format(momentFriendlyDateFormat)
                    : t('does not apply - short version')}
                </CreditLimit>
              </Column>
            </Row>
          )}
        </ContentBalance>
      </>
    </Card>
  )}

AccountBalance.propTypes = {
  hideBalance: PropTypes.bool,
  accountName: PropTypes.string,
  balance: PropTypes.number,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  creditLimit: PropTypes.shape({
    creditLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    renovationDate: PropTypes.string
  })
}

AccountBalance.defaultProps = {
  hideBalance: false,
  accountName: null,
  balance: null,
  active: false,
  onClick: () => {},
  style: {},
  creditLimit: {}
}

export default AccountBalance
