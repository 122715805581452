// eslint-disable-next-line spaced-comment
export default ({ size } = {}) => /*html*/`
<svg width="${ size }" height="${ size }" viewBox="0 0 180 149" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M28.9456 137.118C-3.08331 122.234 4.38368 77.2739 28.8897 71.4761C37.8183 69.3637 57.3981 62.2741 74.1943 37.9909C90.9904 13.7078 115.495 11.5441 124.36 13.2747C168.933 23.695 172.777 68.5925 172.352 85.771C171.928 102.95 155.504 122.53 136.104 122.53C119.547 122.53 90.6662 119.066 78.6001 128.646C61.5638 142.172 43.6967 143.972 28.9456 137.118Z" fill="#F9F8F6"/>
  <path d="M155.992 96.4688C155.296 98.0088 153.477 98.6987 151.938 98.004C150.398 97.3094 149.71 95.4907 150.406 93.9507C151.102 92.4108 152.921 91.7208 154.461 92.4155C156.006 93.1163 156.688 94.9289 155.992 96.4688Z" fill="#E0E0E0"/>
  <path d="M26.3156 96.4688C25.6196 98.0087 23.8003 98.6987 22.2609 98.004C20.7216 97.3094 20.0333 95.4907 20.7293 93.9507C21.4253 92.4107 23.2446 91.7208 24.7839 92.4154C26.3295 93.1163 27.0116 94.9288 26.3156 96.4688Z" fill="#E0E0E0"/>
  <path d="M178.456 84.2198C177.76 85.7598 175.941 86.4497 174.402 85.7551C172.862 85.0605 172.174 83.2417 172.87 81.7018C173.566 80.1618 175.385 79.4719 176.925 80.1665C178.47 80.8549 179.158 82.6736 178.456 84.2198Z" fill="#E0E0E0"/>
  <path d="M153.95 47.4572C153.254 48.9971 151.435 49.687 149.896 48.9924C148.356 48.2978 147.668 46.4791 148.364 44.9391C149.06 43.3991 150.879 42.7092 152.419 43.4039C153.958 44.0985 154.646 45.9172 153.95 47.4572Z" fill="#E0E0E0"/>
  <path d="M47.758 39.2885C47.062 40.8285 45.2427 41.5185 43.7033 40.8238C42.164 40.1292 41.4757 38.3105 42.1717 36.7705C42.8677 35.2305 44.687 34.5406 46.2264 35.2352C47.7719 35.936 48.454 37.7486 47.758 39.2885Z" fill="#E0E0E0"/>
  <path d="M51.8429 145.48C51.1469 147.02 49.3276 147.71 47.7882 147.016C46.2489 146.321 45.5606 144.502 46.2566 142.962C46.9526 141.422 48.7719 140.732 50.3113 141.427C51.8506 142.122 52.5389 143.94 51.8429 145.48Z" fill="#E0E0E0"/>
  <path d="M134.55 5.59304C133.854 7.13303 132.035 7.823 130.495 7.12836C128.956 6.43373 128.268 4.61499 128.964 3.07501C129.66 1.53503 131.479 0.845056 133.018 1.53969C134.564 2.24053 135.246 4.05306 134.55 5.59304Z" fill="#E0E0E0"/>
  <path d="M129.135 95.2501C144.955 79.4292 144.955 53.6866 129.135 37.8657C113.314 22.0448 87.5714 22.0448 71.7508 37.8657C58.329 51.2876 56.2962 71.2493 65.6472 86.8373C65.6472 86.8373 66.3188 87.9642 65.4117 88.8707C60.2364 94.0456 44.7096 109.573 44.7096 109.573C40.589 113.693 39.6081 119.455 43.2622 123.109L43.8914 123.738C47.5455 127.393 53.3074 126.412 57.4276 122.291C57.4276 122.291 72.9215 106.797 78.0862 101.633C79.0368 100.682 80.1634 101.354 80.1634 101.354C95.751 110.704 115.713 108.672 129.135 95.2501ZM79.242 87.7586C67.552 76.0686 67.5523 57.0478 79.2423 45.3578C90.9324 33.6681 109.953 33.6678 121.643 45.3578C133.333 57.0475 133.333 76.0686 121.643 87.7586C109.953 99.448 90.9324 99.448 79.242 87.7586Z" fill="#1D1D1B"/>
  <path d="M81.8318 64.1511C81.2877 64.1511 80.7346 64.0442 80.2014 63.819C78.0717 62.9177 77.0753 60.4603 77.9767 58.3303C83.6419 44.9422 99.1426 38.6594 112.53 44.3247C114.66 45.226 115.656 47.6834 114.755 49.8134C113.853 51.9434 111.397 52.9392 109.266 52.0381C100.132 48.173 89.5552 52.4598 85.6904 61.594C85.0143 63.1915 83.4635 64.1511 81.8318 64.1511Z" fill="#1D1D1B"/>
  <path d="M119.812 99.0452C107.68 99.0452 97.8115 108.914 97.8115 121.045C97.8115 133.176 107.68 143.045 119.812 143.045C131.943 143.045 141.812 133.176 141.812 121.045C141.812 108.914 131.943 99.0452 119.812 99.0452Z" fill="#FFC80A"/>
  <path d="M121.884 133.009C121.884 134.015 121.067 134.832 120.061 134.832C119.055 134.832 118.238 134.015 118.238 133.009C118.238 132.003 119.055 131.186 120.061 131.186C121.067 131.186 121.884 131.998 121.884 133.009Z" fill="#1D1D1B"/>
  <path d="M122.943 123.235C122.296 123.534 121.877 124.181 121.877 124.893V125.71C121.877 126.716 121.061 127.533 120.055 127.533C119.049 127.533 118.232 126.716 118.232 125.71V124.893C118.232 122.762 119.482 120.815 121.414 119.918C123.272 119.062 124.616 116.791 124.616 115.676C124.616 113.161 122.57 111.114 120.055 111.114C117.54 111.114 115.493 113.161 115.493 115.676C115.493 116.681 114.676 117.498 113.67 117.498C112.659 117.498 111.848 116.681 111.848 115.676C111.848 111.149 115.533 107.469 120.055 107.469C124.576 107.469 128.262 111.154 128.262 115.676C128.267 118.146 126.125 121.766 122.943 123.235Z" fill="#1D1D1B"/>
</svg>
`
