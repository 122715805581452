import React, { useCallback, useEffect, forwardRef, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { forOwn } from 'lodash'
import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import mapValues from 'lodash/mapValues'
import toString from 'lodash/toString'

import I18n from '@smartcoop/i18n'
import useSmartcoopApi from '@smartcoop/services/hooks/useSmartcoopApi'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectOfflineProperty, selectActivities } from '@smartcoop/stores/property/selectorProperty'
import Button from '@smartcoop/web-components/Button'
import DynamicForm from '@smartcoop/web-components/DynamicForm'

import { Container, ButtonContainer } from './styles'

const PropertyActivityDetailsForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, onSubmit, isCreate } = props

  const dispatch = useCallback(useDispatch(), [])
  const property = useSelector(selectOfflineProperty)
  const activities = useSelector(selectActivities)
  const [loading, setLoading] = useState(false)

  const { data: activitiesDetail } = useSmartcoopApi(!isEmpty(property?.data?.id) ? `/v1/property/${ property?.data?.id }/activities` : '')

  const fields = useMemo(
    () => filter(activities, (activity) => property?.activities.indexOf(activity.id) >= 0),
    [activities, property?.activities]
  )

  const handleSubmit = useCallback(
    async (data) => {
      dispatch(PropertyActions.updateOfflineProperty(
        { activitiesDetails: data }
      ))
      onSubmit()
    },
    [dispatch, onSubmit]
  )

  const mapValuesDeep = useCallback(
    (obj, cb) => {
      if (isArray(obj)) {
        return obj.map(innerObj => mapValuesDeep(innerObj, cb))
      } if (isObject(obj)) {
        return mapValues(obj, val => mapValuesDeep(val, cb))
      } if(isString(obj) || isBoolean(obj)) {
        return obj
      }
      return cb(obj)
    },
    []
  )

  useEffect(() => {
    if (!isEmpty(property?.activitiesDetails) && loading) {
      const validateProperty = mapValuesDeep(property?.activitiesDetails, toString)
      formRef.current.setData(validateProperty)
    }
  }, [formRef, loading, mapValuesDeep, property?.activitiesDetails])

  useEffect(() => {
    if(activitiesDetail) {
      forEach(fields, item => {
        const activity = find(activitiesDetail?.data, { activityId: item.id })
        forOwn(activity?.details, (value, key) => {
          const fieldActivity = find(item.formFields, { name: key })
          fieldActivity.defaultValue = toString(value)
        })
      })
      setLoading(true)
    }
  }, [activitiesDetail, fields, formRef, mapValuesDeep, property])

  return (
    <Container>
      {(isCreate || loading) && (
        <DynamicForm
          onSubmit={ handleSubmit }
          ref={ formRef }
          fields={ fields }
          withoutSubmitButton
        />
      )}
      {!withoutSubmitButton && (
        <ButtonContainer>
          <Button
            id="web-property-identification-form-button"
            style={ { width: '48%' } }
            onClick={ () => formRef.current.submit() }
          >
            <I18n>next</I18n>
          </Button>
        </ButtonContainer>
      )}
    </Container>
  )
})

PropertyActivityDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  isCreate: PropTypes.bool
}

PropertyActivityDetailsForm.defaultProps = {
  onSubmit: () => {},
  withoutSubmitButton: false,
  isCreate: false
}

export default PropertyActivityDetailsForm
