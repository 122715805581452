import styled from 'styled-components'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
`

export const ButtonContainer = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
`
