import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 25px 0 23px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap-reverse;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 3;
  flex-direction: row;
`

export const SearchContainer = styled.div`
  flex: 1;
`

export const AccountBalanceContainer = styled.div`
  padding-top: 15px;
`

export const inputSearchTheme = (theme) => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff'
      }
    }
  }
})
