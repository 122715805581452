import { call, takeLatest } from 'redux-saga/effects'

import isEmpty from 'lodash/isEmpty'

import { addProductToSuppliers } from '@smartcoop/services/apis/smartcoopApi/resources/organization'
import {
  getProduct,
  patchProduct,
  postProduct
} from '@smartcoop/services/apis/smartcoopApi/resources/product'

import { ProductsTypes } from './duckProducts'

function* createProduct({
  params = {},
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { data, suppliers } = params
    const response = yield call(postProduct, data)
    if(!isEmpty(suppliers)) {
      yield call(addProductToSuppliers, { suppliers }, { organizationId: response?.data?.id })
    }
    yield call(onSuccess, response)
  } catch (error) {
    yield call(onError)
  }
}

function* editProduct({
  params = {},
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { data, suppliers } = params
    const response = yield call(patchProduct, data, { productId: data.id })
    if(!isEmpty(suppliers)) {
      yield call(addProductToSuppliers, { suppliers }, { organizationId: response?.data?.id })
    }
    yield call(onSuccess, response)
  } catch (error) {
    yield call(onError)
  }
}

function* findProduct({
  params = {},
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { productId } = params
    const response = yield call(getProduct, { productId })
    yield call(onSuccess, response)
  } catch (error) {
    yield call(onError)
  }
}

export default [
  takeLatest(ProductsTypes.CREATE_PRODUCT, createProduct),
  takeLatest(ProductsTypes.EDIT_PRODUCT, editProduct),
  takeLatest(ProductsTypes.FIND_PRODUCT, findProduct)
]
