import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const registerDiagnosisActions = ({ t }) => Yup.object().shape({
  result: flow(
    required({ t })
  )(Yup.string()),
  animalsId: flow(
    required({ t })
  )(Yup.string()),
  diagnosisTypeId: flow(
    required({ t })
  )(Yup.string()),
  realizationDate: flow(
    required({ t })
  )(Yup.string())
})

export default registerDiagnosisActions
