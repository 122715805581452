import { call, put, takeLatest } from 'redux-saga/effects'


import moment from 'moment'

import uniqBy from 'lodash/uniqBy'

import {
  getIndicatorsByDate,
  getGeotiffImage as doGetGeotiffImage
} from '@smartcoop/services/apis/smartcoopApi/resources/satelitesvc'

import { SateliteSvcActions, SateliteSvcTypes } from './duckSateliteSvc'

function* searchIndicatorsByDate({ filter, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(SateliteSvcActions.setLoadedFalse())

    const { data: indicators } = yield call(getIndicatorsByDate, filter)

    yield put(SateliteSvcActions.searchIndicatorsByDateSuccess(indicators))
    yield put(SateliteSvcActions.validateGetImagesResult(indicators, filter.period))

    yield call(onSuccess)
  } catch (error) {
    yield put(SateliteSvcActions.sateliteSvcError(error))
    yield call(onError, error)
  }
}

function* getGeotiffImage({ payload, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { url } = yield call(doGetGeotiffImage, payload)

    yield call(onSuccess, url)
  } catch (error) {
    yield put(SateliteSvcActions.sateliteSvcError(error))
    yield call(onError, error)
  }
}

function* validateGetImagesResult({ images, period }) {
  const formattedItems = uniqBy(images, item => item.date)

  const from = moment(period.from)
  const to = moment(period.to)
  const diff = to.diff(from, 'd')

  // less than 1 image per 5 days
  const hasMissingImages = (diff >= 5) && (Math.floor(diff / 5) > formattedItems.length)

  yield put(SateliteSvcActions.setHasMissingImages(hasMissingImages))
}

export default [
  takeLatest(SateliteSvcTypes.SEARCH_INDICATORS_BY_DATE, searchIndicatorsByDate),
  takeLatest(SateliteSvcTypes.GET_GEOTIFF_IMAGE, getGeotiffImage),
  takeLatest(SateliteSvcTypes.VALIDATE_GET_IMAGES_RESULT, validateGetImagesResult)
]
