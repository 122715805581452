// eslint-disable-next-line spaced-comment
export default ({
  width = 26,
  height = 25,
  color = '#1D1D1B'
} = {}) => /* html */ `<svg width="${ width }" height="${ height }" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.9275 4.25895V4.25243L20.6955 6.3887L25.4831 4.59363L13.6891 0.171141C13.0784 -0.0570468 12.4047 -0.0570468 11.794 0.171141L0 4.59363L12.7415 9.37252L17.5096 7.58397L12.7415 5.61721V5.61287L15.9275 4.25895Z" fill="${ color }"/>
<path d="M0 6.18433V19.5974C0 20.1516 0.343368 20.6471 0.862766 20.8426L11.9462 25V10.6655L0 6.18433ZM6.37186 20.5732L3.18593 19.3779V17.6785L6.37186 18.8737V20.5732Z" fill="${ color }"/>
<path d="M20.7063 7.97723V11.9672L17.5204 13.1625V9.17249L13.5391 10.6655V25L24.4008 20.9252C25.0527 20.6818 25.483 20.0581 25.483 19.3627V6.18433L20.7063 7.97723Z" fill="${ color }"/>
</svg>
`
