import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import useSmartcoopApi from '@smartcoop/services/hooks/useSmartcoopApi'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { selectUserId } from '@smartcoop/stores/user/selectorUser'
import Loader from '@smartcoop/web-components/Loader'
import TechnicalVisionReportFragment from '@smartcoop/web-containers/fragments/reports/technician/TechnicalVisionReportFragment'

const TechnicalVisionReportScreen = () => {
  const curentOrganization = useSelector(selectCurrentOrganization)
  const userId = useSelector(selectUserId)

  const { data: token, isValidating } = useSmartcoopApi('/v1/auth/microsoft-token')

  const accessToken = useMemo(
    () => token?.accessToken,
    [token]
  )

  const organizationId = useMemo(() => curentOrganization?.id, [curentOrganization])

  const loading = useMemo(
    () => (!accessToken && isValidating) || !organizationId || !accessToken,
    [accessToken, isValidating, organizationId]
  )

  return loading ? (
    <Loader width={ 100 } />
  ) : (
    <div style={ { display: 'flex', flex: 1 } }>
      <TechnicalVisionReportFragment token={ accessToken } organizationId={ organizationId } technicianId={ userId }/>
    </div>
  )
}

export default TechnicalVisionReportScreen
