import React, { useRef, useMemo } from 'react'


import moment from 'moment/moment'
import PropTypes from 'prop-types'

import capitalize from 'lodash/capitalize'

import { useT } from '@smartcoop/i18n'
import { getPropertiesAssetsMovements } from '@smartcoop/services/apis/smartcoopApi/resources/propertiesAssets'
import { momentBackDateTimeFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import { formatCurrency } from '@smartcoop/utils/formatters'
import DataTable from '@smartcoop/web-components/DataTable'
import Modal from '@smartcoop/web-components/Modal'


import { Content } from './styles'

const PropertiesAssetsMovementModal = (props) => {
  const { id, open, asset } = props
  const t = useT()
  const tableRef = useRef(null)

  const columns = useMemo(
    () => [
      {
        title: t('type'),
        field: 'type',
        align: 'left',
        render: (row) => `${ capitalize(row?.type) } ${ row?.reason ? `/ ${  row?.reason }` : '' }`
      },
      {
        title: t('date', { howMany: 1 }),
        field: 'referenceDate',
        align: 'left',
        render: (row) => moment(row?.referenceDate, momentBackDateTimeFormat).format(momentFriendlyDateFormat)
      },
      {
        title: t('value'),
        field: 'value',
        align: 'right',
        sorting: true,
        render: (row) => formatCurrency(row?.value)
      }

    ], [t]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      disableFullScreen
      maxWidth='sm'
      fullWidth
      disableEsc
      headerProps={ {
        disableTypography: true
      } }
      title={ t('property and assets movements') }
      contentContainerStyle={ { padding: 0 } }
    >
      <>

        <Content>
          <DataTable
            tableRef={ tableRef }
            columns={ columns }
            data={  getPropertiesAssetsMovements }
            queryParams={ {
              assetId: asset?.id
            } }
            id="properties-assets-movement-table"
          />
        </Content>
      </>
    </Modal>
  )
}

PropertiesAssetsMovementModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired
}

PropertiesAssetsMovementModal.defaultProps = {
}

export default PropertiesAssetsMovementModal
