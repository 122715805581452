import api from '../api'

const URL_PRODUCTS = '/v1/products'

export const getProducts = (params) => api.get(`${ URL_PRODUCTS }`, { params })

export const getProduct = ({ productId, ...params }) =>
  api.get(`${ URL_PRODUCTS }/${ productId }`, { params })

export const deleteProduct = ({ productId }) =>
  api.delete(`${ URL_PRODUCTS }/${ productId }`)

export const postProduct = (params) =>
  api.post(`${ URL_PRODUCTS }`, { ...params })

export const patchProduct = (params, { productId }) =>
  api.patch(`${ URL_PRODUCTS }/${ productId }`, { ...params })
