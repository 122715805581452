import React, { useState, useMemo } from 'react'

import PropTypes from 'prop-types'

import { isEmpty, map } from 'lodash'

import {
  Container,
  CurrentImageContainer,
  CurrentImage,
  ThumbsContainer,
  Thumb,
  ThumbImage
} from './styles'

const MachineDetailsImageGallery = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const activeImage = useMemo(() => !isEmpty(images) && !isEmpty(images[activeIndex]?.fileUrl) ? images[activeIndex]?.fileUrl : null, [activeIndex, images])
  return (
    <Container>
      {!isEmpty(activeImage) && (
        <CurrentImageContainer>
          <CurrentImage src={ activeImage } />
        </CurrentImageContainer>)
      }
      <ThumbsContainer>
        {!isEmpty(images) && map(images, (image, index) => (
          <Thumb
            isActive={ activeIndex === index }
            onClick={ () => setActiveIndex(index) }
            key={ image.id }
          >
            <ThumbImage
              src={ images[index].fileUrl }
              image={ images[index].fileUrl }
            />
          </Thumb>
        ))}
      </ThumbsContainer>
    </Container>
  )
}

MachineDetailsImageGallery.propTypes = {
  images: PropTypes.array
}

MachineDetailsImageGallery.defaultProps = {
  images: []
}

export default MachineDetailsImageGallery
