import { makeStyles } from '@material-ui/core/styles'

import colors from '@smartcoop/styles/colors'

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.backgroundHtml
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '16px',
    padding: '12px 13px 0 13px'
  }
})
