import { tableSchema } from '@nozbe/watermelondb'


export const syncFileSchema = tableSchema({
  name: 'sync_file',
  columns: [
    { name: 'type', type: 'string' },
    { name: 'source', type: 'string' },
    { name: 'sourceExtension', type: 'string' },
    { name: 'params', type: 'string' },
    { name: 'url', type: 'string' },
    { name: 'sync', type: 'boolean' },
    { name: 'error', type: 'string' },
    { name: 'localMessageId', type: 'string' },
    { name: 'documentType', type: 'string' },
    { name: 'uri', type: 'string' },
    { name: 'created_at', type: 'number' }
  ]
})
