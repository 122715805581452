import React, { useEffect, useRef, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import I18n, { useT } from '@meta-react/i18n'
import moment from 'moment'

import { useDialog } from '@smartcoop/dialog'
import { getAnimalObservations } from '@smartcoop/services/apis/smartcoopApi/resources/observation'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { InseminationActions } from '@smartcoop/stores/insemination'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import RegisterObservationsModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterObservationsModal'

import { Top } from './styles'

const ListInsemination = () => {
  const { createDialog } = useDialog()
  const t = useT()
  const tableRef = useRef(null)
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const currentAnimal = useSelector(selectCurrentAnimal)
  const userWrite = useSelector(selectUserCanWrite)

  const registerDisabled = useMemo(() => !userWrite,[userWrite])

  const columns = useMemo(
    () => [
      {
        title: t('date', { howMany: 1 }),
        field: 'observationDate',
        render: (row) => moment(row?.observationDate, momentBackDateFormat).format(momentFriendlyDateFormat),
        sorting: true,
        defaultSort: 'desc',
        cellStyle: { width: '10%' }
      },
      {
        title: t('observation', { howMany: 1 }),
        field: 'observation'
      }
    ], [t]
  )

  const urlParams = useMemo(
    () => ({
      animalId: currentAnimal?.id
    }), [currentAnimal.id]
  )

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )


  useEffect(
    () => {
      dispatch(InseminationActions.loadInseminationTypes())
    },[dispatch]
  )

  useEffect(
    () => {
      dispatch(AnimalActions.loadCurrentAnimal())
    },[dispatch]
  )

  const openCreateModal = useCallback(
    (_, observation = {}) => {

      createDialog({
        id: 'create-fields',
        Component: RegisterObservationsModal,
        props: {
          onSubmit: reloadDataTable,
          observation,
          animalId: currentAnimal?.id
        }
      })
    },
    [createDialog, currentAnimal, reloadDataTable]
  )

  const handleDelete = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete-access',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(
              AnimalActions.deleteAnimalObservation(
                row?.id,
                () => {
                  snackbar.success(
                    t('your {this} was removed', { howmany: 1, gender: 'female', this: t('observation', { howMany: 1 }) })
                  )
                  reloadDataTable()
                }
              )
            )
          },
          textWarning: t('are you sure you want to remove the {this}?', {
            howMany: 1,
            gender: 'female',
            this: t('observation', { howMany: 1 })
          })
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, snackbar, t]
  )

  return (
    <>
      <Top>
        <div>
          <Button
            id="register-observation"
            onClick={ openCreateModal }
            color="secondary"
            disabled={ registerDisabled }
          >
            <I18n params={ { this: t('observation', { howMany: 1 }) } }>{'register {this}'}</I18n>
          </Button>
        </div>
      </Top>
      <div style={ { marginTop: 20, width: 'calc(100% - 30px)' } }>
        <DataTable
          columns={ columns }
          data={ getAnimalObservations }
          tableRef={ tableRef }
          urlParams={ urlParams }
          onEditClick={ openCreateModal }
          onDeleteClick={ handleDelete }
          disabled={ !userWrite }
          id="list-observation-table"
        />
      </div>
    </>
  )
}

export default ListInsemination
