import styled, { css } from 'styled-components'

import { colors } from '@smartcoop/styles'

export const TextContainer = styled.span`
  white-space: pre-wrap; /* Preserves spaces and line breaks, allows wrapping */
  word-wrap: break-word;
`
export const Line = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: ${ ({ receiver, system }) => {
    if (system) {
      return 'center'
    }
    return receiver ? 'flex-start' : 'flex-end'
  } };
  height: fit-content;
  column-gap: 5px;
`

export const Card = styled.div`
  min-width: 80px;
  padding: 10px 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(1fr, 3);

  ${ ({ system }) => {
    if (system) {
      return css`
        border-radius: 10px;
        background-color: ${ colors.lightGrey };
      `
    }

    return css`
      border-radius: 10px;
      border-bottom-right-radius: ${ ({ receiver }) => receiver ? '10px' : 0 };
      border-bottom-left-radius: ${ ({ receiver }) => receiver ? 0 : '10px' };
      background-color: ${ ({ receiver }) => receiver ? colors.white : colors.chatYellow };
    `
  } }

  :hover {
    #action {
      bottom: 5px;
      left: 10px;
      opacity: 1;
    }

    #status {
      opacity: 15%;
    }
  }
`

export const CardDetail = styled.div`
  display: grid;
  grid-template-columns: ${ ({ forward, group }) => {
    if (group) {
      return forward ? '1fr auto auto auto' : '1fr auto auto'
    }
    return forward ? '1fr auto auto' : '1fr auto'
  } } ;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
`

export const DateSpan = styled.span`
  color: ${ colors.mediumGrey };
  font-size: .7rem;
  text-align: end;
`

export const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`

export const AudioPlayer = styled.audio`
  height: 35px;

  &::-webkit-media-controls-panel {
    background-color: ${ ({ receiver }) => receiver ? colors.white : colors.chatYellow };
    height: 35px;
    padding: 0;
  }
`
