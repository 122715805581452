import { makeStyles } from '@material-ui/core/styles'

import { colors } from '@smartcoop/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: 30,
    marginBottom: 100,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  loader: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7
  },
  cardContainer: props => ({
    boxShadow: props?.noBoxShadow && 'none',
    border: props?.selected && `2px solid ${ colors.secondary }`,
    padding: props?.noBoxShadow ? 0 : '20px 0',
    backgroundColor: colors.white,
    [`@media (max-width: ${ 454 }px)`]: {
      width: '100% !important'
    }
  }),
  brand: {
    width: '100%'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.35em'
  },
  title: {
    flex: 1
  }
}))

export default useStyles
