import React, { useState, useCallback } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import I18n, { useT } from '@smartcoop/i18n'
import { postPropertyAssetsWriteOff } from '@smartcoop/services/apis/smartcoopApi/resources/propertiesAssets'
import { useSnackbar } from '@smartcoop/snackbar'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputUnit from '@smartcoop/web-components/InputUnit'
import Modal from '@smartcoop/web-components/Modal'

import { Container, MainText, Row } from './styles'

const DischargeModal = (props) => {
  const {
    open,
    handleClose,
    id,
    asset,
    onClose
  } = props

  const t = useT()
  const snackbar = useSnackbar()

  const [reason, setReason] = useState('')
  const [franchiseValue, setFranchiseValue] = useState(0)
  const [saleValue, setSaleValue] = useState(0)
  const [assetValue, setAssetValue] = useState(asset?.totalDepreciatedValue ? asset?.assetValue - asset?.totalDepreciatedValue : asset?.assetValue)
  const [coverageValue, setCoverageValue] = useState(0)
  const [secure, setSecure] = useState(asset?.franchiseValue || false)

  const options = [
    {
      label: 'Venda',
      value: 'Venda'
    },
    {
      label: 'Descarte',
      value: 'Descarte'
    },
    {
      label: 'Obsolescência',
      value: 'Obsolescência'
    },
    {
      label: 'Perda',
      value: 'Perda'
    }
  ]

  const onConfirm = useCallback(async () => {
    await postPropertyAssetsWriteOff({
      assetId: asset?.id,
      referenceDate: moment().format(momentBackDateFormat),
      value: assetValue,
      coverageValue,
      franchiseValue,
      saleValue,
      reason
    }).then(() => {
      onClose()
      snackbar.success(t('your discharge was registered'))
    })
    handleClose()
  },[asset.id, assetValue, coverageValue, franchiseValue, handleClose, onClose, reason, saleValue, snackbar, t])

  return (
    <Modal
      id={ id }
      open={ open }
      title={ <I18n>register discharge</I18n> }
      maxWidth="sm"
      fullWidth
      disableFullScreen
      FooterComponent={
        () => (
          <>
            <Button
              id="close"
              onClick={ handleClose }
              variant="outlined"
              style={ { marginRight: 7 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="save"
              onClick={ onConfirm }
              color="black"
              style={ { marginLeft: 7 } }
              disabled={ isEmpty(reason) || assetValue > (asset?.assetValue - asset?.totalDepreciatedValue) }
            >
              <I18n>confirm</I18n>
            </Button>
          </>
        )
      }
    >
      <Container>
        <MainText>
          <I18n>inform the reason of the discharge</I18n>
        </MainText>

        <InputSelect
          detached
          label={ t('reason ') }
          fullWidth
          options={ options }
          onChange={ (value) => setReason(value) }
          value={ reason }
        />
        <InputUnit
          label={ t('discharge value') }
          detached
          fullWidth
          value={ assetValue }
          type="float"
          unit="R$"
          disabled={ !(reason === 'Perda') }
          onChange={ ({ target: { value } }) => setAssetValue(value) }
          error={ assetValue > (asset?.assetValue - asset?.totalDepreciatedValue)  ? 'Valor superior ao saldo a depreciar' : null }
        />
        { reason === 'Venda' ? (
          <InputUnit
            label={ t('sale value') }
            detached
            fullWidth
            value={ saleValue }
            type="float"
            unit="R$"
            onChange={ ({ target: { value } }) => setSaleValue(value) }
          />
        ) : null }
        { reason === 'Perda' ? (
          <CheckboxButton
            label="Segurado?"
            onChange={ () => setSecure(!secure) }
            checked={ secure }
            value={ secure }
            hideColor
          />
        ) : false}
        { reason === 'Perda' && secure ? (
          <Row>
            <InputUnit
              label={ t('franchise value') }
              detached
              fullWidth
              value={ franchiseValue }
              type="float"
              unit="R$"
              onChange={ ({ target: { value } }) => setFranchiseValue(value) }
            />
            <div style={ { width: 40 } }/>
            <InputUnit
              label={ t('coverage value') }
              detached
              fullWidth
              value={ coverageValue }
              type="float"
              unit="R$"
              onChange={ ({ target: { value } }) => setCoverageValue(value) }
            />
          </Row>
        ) : null }
      </Container>
    </Modal>
  )
}

DischargeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  asset: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired
}

DischargeModal.defaultProps = {
}

export default DischargeModal
