import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;

  > :first-child {
    border-radius: 0 0 0 10px;
  }

  > :last-child {
    border-radius: 0 0 10px 0;
  }
`

export const Item = styled.div`
  flex: 1;
  background-color: ${ ({ color }) => color };
  padding: 2px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-weight: bold;
  vertical-align: center;
`
