import { tableSchema } from '@nozbe/watermelondb'


export const syncHistoricSchema = tableSchema({
  name: 'sync_historic',
  columns: [
    { name: 'table', type: 'string' },
    { name: 'date', type: 'number' },
    { name: 'percentage', type: 'number' }
  ]
})
