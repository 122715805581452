import React from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { nextPage } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import IconButton from '../IconButton'

const NextPageIconButton = ({ iconColor, ...props }) => {
  const t = useT()
  return (
    <IconButton tooltip={ t('datagrid pagination next tooltip') } { ...props }>
      <Icon size={ 14 } icon={ nextPage } color={ iconColor } />
    </IconButton>
  )
}

NextPageIconButton.propTypes = {
  iconColor: PropTypes.string
}

NextPageIconButton.defaultProps = {
  iconColor: colors.mutedText
}

export default NextPageIconButton
