import useSWR from 'swr'

const useFetch = (axiosInstance, url = '', ...axiosParams) => {
  const { data, error, mutate, isValidating } = useSWR(url, async (path) => {
    const response = await axiosInstance.get(path, ...axiosParams)
    return response?.data
  })

  return { data, error, mutate, isValidating }
}

export default useFetch
