import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import Modal from '@smartcoop/web-components/Modal'
import PropertyTechnicalVisitForm from '@smartcoop/web-containers/forms/digitalProperty/technicalVisit/PropertyTechnicalVisitForm'

import useStyles, { Row, Content } from './styles'

const PropertyTechnicalVisitModal = (props) => {
  const { id, open, technicalVisit, disabled, onSuccess, handleClose, readOnly, scheduled, growingSeasonId, fromTechnicalArea } = props
  const technicalVisitRegisterFormRef = useRef(null)
  const classes = useStyles()

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row style={ { marginBottom: '10px' } }>
          <I18n>{scheduled ? 'schedule' : 'register technical visit'}</I18n>
        </Row>
      }
      disableFullScreen
      maxWidth='md'
      fullWidth
      disableEsc
      classes={ { paper: classes.modalBackground } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      contentContainerStyle={ { padding: 0, minWidth: 700 } }
    >
      <>
        <Content>
          <PropertyTechnicalVisitForm
            ref={ technicalVisitRegisterFormRef }
            handleClose={ handleClose }
            technicalVisit={ technicalVisit }
            disabled={ disabled }
            onSuccess={ () => {
              onSuccess()
              handleClose()
            } }
            readOnly={ readOnly }
            scheduled={ scheduled }
            fromTechnicalArea={ fromTechnicalArea }
            growingSeasonId={ growingSeasonId }
          />
        </Content>
      </>
    </Modal>
  )
}

PropertyTechnicalVisitModal.propTypes = {
  id: PropTypes.string.isRequired,
  growingSeasonId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  technicalVisit: PropTypes.object,
  fromTechnicalArea: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  scheduled: PropTypes.bool,
  onSuccess: PropTypes.func
}

PropertyTechnicalVisitModal.defaultProps = {
  technicalVisit: {},
  disabled: false,
  fromTechnicalArea: false,
  readOnly: false,
  scheduled: false,
  growingSeasonId: null,
  onSuccess: () => {}
}

export default PropertyTechnicalVisitModal
