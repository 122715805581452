import React from 'react'

import { useDairyFarmDeliveryChartOptions } from '@smartcoop/utils/charts'
import Chart from '@smartcoop/web-components/Chart'

const DairyFarmDeliveryChart = () => {

  const chartOptions = useDairyFarmDeliveryChartOptions()

  return (
    <div style={ { height: 300, width: '100%' } }>
      <Chart
        options={ chartOptions }
        containerProps={ { style: { height: 300 } } }
      />
    </div>
  )
}

export default DairyFarmDeliveryChart
