import { call, put, takeLatest } from 'redux-saga/effects'

import {
  nfeIntegration as nfeIntegrationService,
  integrationControl as integrationControlService,
  getProducer as getProducerService,
  getNfeTables,
  saveFiscalFigure as postFiscalFigure,
  editFiscalFigure as patchFiscalFigure,
  getCFOP,
  deleteFiscalFigure as deleteFiscalFigureService
} from '@smartcoop/services/apis/smartcoopApi/resources/nfe'

import { NfeActions, NfeTypes } from './duckNfe'

function* integrateNfe({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const data = yield call(nfeIntegrationService, params)
    yield put(NfeActions.integrateNfeSuccess(data))

    yield call(onSuccess, data)
  } catch (error) {
    yield put(NfeActions.nfeError(error))
    yield call(onError, error)
  }
}

function* loadProducer({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(getProducerService, params)
    yield put(NfeActions.loadProducerSuccess(data))

    yield call(onSuccess, data)
  } catch (error) {
    yield put(NfeActions.nfeError(error))
    yield call(onError, error)
  }
}

function* integrationControl({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const data = yield call(integrationControlService, params)

    yield call(onSuccess, data)
  } catch (error) {
    yield put(NfeActions.nfeError(error))
    yield call(onError, error)
  }
}


function* saveFiscalFigure({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    if(params?.id) {
      yield call(patchFiscalFigure, params, { fiscalFigureId: params?.id })
    } else {
      yield call(postFiscalFigure, params)
    }

    yield call(onSuccess)
  } catch (error) {
    yield put(NfeActions.nfeError(error))
    yield call(onError, error)
  }
}

function* deleteFiscalFigure({ fiscalFigureId = null, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteFiscalFigureService, fiscalFigureId)

    yield call(onSuccess)
  } catch (error) {
    yield put(NfeActions.nfeError(error))
    yield call(onError, error)
  }
}

function* loadNfeTablesData({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: { data } } = yield call(getNfeTables, params)
    yield put(NfeActions.loadNfeTablesDataSuccess(data))

    yield call(onSuccess)
  } catch (error) {
    yield put(NfeActions.nfeError(error))
    yield call(onError, error)
  }
}

function* loadCFOP({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(getCFOP, params)
    yield put(NfeActions.loadCFOPSuccess(data))

    yield call(onSuccess, data)
  } catch (error) {
    yield put(NfeActions.nfeError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(NfeTypes.SAVE_FISCAL_FIGURE, saveFiscalFigure),
  takeLatest(NfeTypes.DELETE_FISCAL_FIGURE, deleteFiscalFigure),
  takeLatest(NfeTypes.LOAD_PRODUCER, loadProducer),
  takeLatest(NfeTypes.INTEGRATE_NFE, integrateNfe),
  takeLatest(NfeTypes.INTEGRATION_CONTROL, integrationControl),
  takeLatest(NfeTypes.LOAD_CFOP, loadCFOP),
  takeLatest(NfeTypes.LOAD_NFE_TABLES_DATA, loadNfeTablesData)
]
