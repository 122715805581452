import moment from 'moment'

import capitalize from 'lodash/capitalize'

export function formatConversationDate(date, t) {
  const messageDate = moment(date, 'X')
  const whatDateIs = moment(date, 'X')
    .startOf('day')
    .diff(moment().startOf('day'), 'days')

  if (whatDateIs === 0) {
    return (moment(messageDate).format('HH:mm'))
  }

  if (whatDateIs === -1) {
    return (t('yesterday'))
  }

  if (whatDateIs <= -1 && whatDateIs >= -7) {
    return capitalize(messageDate.format('dddd'))
  }

  return (messageDate.format('DD/MM/YYYY'))
}
