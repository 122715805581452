/* eslint-disable no-param-reassign */
import React, { useCallback, forwardRef, useState, useEffect } from 'react'
import  { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import phoneMask from '@smartcoop/forms/masks/phone.mask'
import registerProducerDataSchema from '@smartcoop/forms/schemas/producer/registerProducerData.schema'
import { useT } from '@smartcoop/i18n'
import { searchCities, searchStates } from '@smartcoop/services/apis/ibgeApi'
import Form from '@smartcoop/web-components/Form'
import InputCep from '@smartcoop/web-components/InputCep'
import InputDate from '@smartcoop/web-components/InputDate'
import InputEmail from '@smartcoop/web-components/InputEmail'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import { Text } from '@smartcoop/web-containers/layouts/GuestLayout/theme'

import { Container } from './styles'

const RegisterProducerDataForm = forwardRef((props, formRef) => {
  const {
    onSubmit,
    defaultValues
  } = props

  const t = useT()

  const dispatch = useDispatch()
  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit(data)
    },
    [onSubmit]
  )

  const receiveAddress = useCallback(({ ...address }) => {
    formRef.current.setData({
      ...address
    })
  }, [formRef])

  const handleStateChange = useCallback(
    async (value) => {
      if (value) {
        let data = await searchCities(value)
        data = map(data, ({ nome }) => ({ label: nome, value: nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '') }))
        setCityOptions(data)
      } else {
        setCityOptions([])
        formRef.current.clearField('city')
      }
    },
    [formRef]
  )

  useEffect(() => {
    async function findStates() {
      let data = await searchStates()
      data = map(data, ({ sigla }) => ({ label: sigla, value: sigla }))
      setStateOptions(data)
    }
    findStates()
  }, [dispatch])


  useEffect(() => {
    setTimeout(() => {
      if(formRef.current && defaultValues?.postalCode) {
        formRef.current.setData({
          postalCode: defaultValues?.postalCode,
          state: defaultValues?.state,
          city: defaultValues?.city
        })
      }
    }, 100)
  }, [defaultValues, formRef])

  return (
    <Container>
      <Text>
      Informe seus dados cadastrais
      </Text>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerProducerDataSchema }
        onSubmit={ handleSubmit }
      >
        <InputText
          name="name"
          label={ t('name') }
          style={ { width: '100%' } }
          defaultValue={ defaultValues?.name }
        />

        <InputEmail
          name="email"
          label={ t('email') }
          style={ { width: '100%' } }
          defaultValue={ defaultValues?.email }
        />

        <InputText
          label={ t('phone') }
          name="cellPhone"
          style={ { width: '100%' } }
          type="tel"
          setMask={ phoneMask }
          defaultValue={ defaultValues?.cellPhone }
        />

        <div style={ { display: 'flex', flexDirection: 'column', width: '100%' } }>
          <InputDate
            name="dateOfBirth"
            label={ t('date of birth') }
            fullWidth
            defaultValue={ defaultValues?.dateOfBirth }
          />

          <InputCep
            name="postalCode"
            label={ t('cep') }
            fullWidth
            onAddressChange={ receiveAddress }
            defaultValue={ defaultValues?.postalCode }
          />

          <InputSelect
            label={ t('uf') }
            name="state"
            onChange={ (e) => handleStateChange(e.target.value) }
            options={ stateOptions }
            defaultValue={ defaultValues?.state }
            fullWidth
            style={
              {
                width: '100%'
              }
            }
          />

          <InputSelect
            label={ t('city', { howMany: 1 }) }
            name="city"
            options={ cityOptions }
            defaultValue={ defaultValues?.city }
            fullWidth
          />

          <InputText
            name="neighborhood"
            label={ t('neighborhood') }
            fullWidth
            defaultValue={ defaultValues?.neighborhood }
          />

          <InputText
            name="street"
            label={ t('street') }
            fullWidth
            defaultValue={ defaultValues?.street }
          />

          <InputText
            name="number"
            label={ t('number') }
            fullWidth
            defaultValue={ defaultValues?.number }
          />
        </div>
      </Form>
    </Container>
  )
})

RegisterProducerDataForm.propTypes = {
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.object
}

RegisterProducerDataForm.defaultProps = {
  onSubmit: () => {},
  defaultValues: {}
}

export default RegisterProducerDataForm
