import React, { useCallback } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'

import useStyles, { Row, Content, TextWarning } from './styles'

const ConfirmModal = ({
  id,
  open,
  handleClose,
  onConfirm,
  onNegative,
  textButtonOnConfirm,
  textButtonOnNegative,
  textWarning,
  hideCancel,
  bodyTextStyle
}) => {
  const classes = useStyles()


  const closeModal = useCallback(
    () => {
      onNegative()
      handleClose()
    },
    [handleClose, onNegative]
  )

  const submitModal = useCallback(
    () => {
      onConfirm()
      handleClose()
    },
    [handleClose, onConfirm]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row>
          <I18n>warning</I18n>
        </Row>
      }
      escape={ false }
      disableFullScreen
      contentContainerStyle={ { padding: 0 } }
      headerProps={ {
        titleClass: classes.title
      } }
      FooterComponent={
        () => (
          <>
            {!hideCancel && (
              <Button
                id="close"
                onClick={ closeModal }
                variant="outlined"
                style={ { marginRight: 7 } }
              >
                {textButtonOnNegative ? <span>{textButtonOnNegative}</span> : <I18n>cancel</I18n>}
              </Button>
            )}
            <Button
              id="filtrate"
              onClick={ submitModal }
              color="black"
              style={ { marginLeft: hideCancel ? 0 : 7 } }
            >
              {textButtonOnConfirm ? <span>{textButtonOnConfirm}</span> : <I18n>confirm</I18n>}
            </Button>
          </>
        )
      }
    >
      <>
        <Content>
          <TextWarning style={ { ...bodyTextStyle } }>{textWarning}</TextWarning>
        </Content>
      </>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onNegative: PropTypes.func,
  textWarning: PropTypes.string,
  textButtonOnConfirm: PropTypes.string,
  textButtonOnNegative: PropTypes.string,
  hideCancel: PropTypes.bool,
  bodyTextStyle: PropTypes.object
}

ConfirmModal.defaultProps = {
  textButtonOnConfirm: '',
  textButtonOnNegative: '',
  onNegative: () => {},
  onConfirm: () => {},
  textWarning: null,
  hideCancel: false,
  bodyTextStyle: {}
}

export default ConfirmModal
