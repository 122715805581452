import { appSchema } from '@nozbe/watermelondb'

import { animalSchema } from './animalSchema'
import { contactSchema } from './contactSchema'
import { conversationFileSchema } from './conversationFileSchema'
import { conversationSchema } from './conversationSchema'
import { groupMemberSchema } from './groupMemberSchema'
import { groupSchema } from './groupSchema'
import { messageSchema } from './messageSchema'
import { profilePictureSchema } from './profilePictureSchema'
import { syncFileSchema } from './syncFileSchema'
import { syncHistoricSchema } from './syncHistoricSchema'
import { syncPendingSchema } from './syncPendingSchema'
import { userSchema } from './userSchema'

export const databaseSchema = appSchema({
  version: 4,
  tables: [
    userSchema,
    messageSchema,
    contactSchema,
    conversationSchema,
    syncHistoricSchema,
    syncPendingSchema,
    syncFileSchema,
    profilePictureSchema,
    groupSchema,
    groupMemberSchema,
    conversationFileSchema,
    animalSchema
  ]
})