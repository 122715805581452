import React, { useCallback, forwardRef, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'


import map from 'lodash/map'

import incomeReportSchema from '@smartcoop/forms/schemas/property/reports/incomeReport.schema'
import { useT } from '@smartcoop/i18n'
import { LotActions } from '@smartcoop/stores/lot'
import { selectLots } from '@smartcoop/stores/lot/selectorLot'
import { generateArrayOfDate } from '@smartcoop/utils/dates'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

import { Row } from './styles'

const IncomeReportForm = forwardRef((props, formRef) => {
  const { onSubmit, group, setGroup, setModel, model, setLotTypes, lotTypes } = props

  const t = useT()
  const dispatch = useDispatch()

  const lotsList = useSelector(selectLots)
  const lotsOptions = useMemo(() => map(lotsList, lot => ({ label: lot.name, value: lot.slug })), [lotsList])

  const isMilk = useMemo(() => model === 'milk', [model])

  useEffect(() => {
    dispatch(LotActions.loadLots({}))
  }, [dispatch])

  useEffect(() => {
    if(!isMilk) {
      setLotTypes(null)
    }
  }, [isMilk, setLotTypes])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data
      })
    },
    [onSubmit]
  )

  const years = useMemo(
    () => generateArrayOfDate(moment().subtract(10, 'year'), moment().add(2, 'year'), 'year'),
    []
  )

  const sowingYearOptions = useMemo(
    () => map(years, (date) => ({ value: date.format('YYYY'), label: date.format('YYYY') })),
    [years]
  )

  const formOptions = useMemo(
    () => {
      if(isMilk) {
        return [
          {
            label: t('general'),
            value: null
          },
          {
            label: t('monthly'),
            value: 'date'
          }
        ]
      }

      return [
        {
          label: t('general'),
          value: null
        },
        {
          label: t('crop', { howMany: 2 }),
          value: 'crop'
        },
        {
          label: t('activity',  { howMany: 2 }),
          value: 'activity'
        },
        {
          label: t('monthly'),
          value: 'date'
        }
      ]
    },
    [isMilk, t]
  )

  const modelOptions = useMemo(
    () => [
      {
        label:`${ t('demonstrative') } ${ t('full') }`,
        value: 'null'
      },
      {
        label: `DRE ${ t('milk pecuary') }`,
        value: 'milk'
      }
    ],
    [t]
  )

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ incomeReportSchema }
      onSubmit={ handleSubmit }
      containerStyle={ { overflowY: 'scroll', paddingTop: 10 } }
    >
      <Row>
        <InputSelect
          label={ t('year', { howMany: 1 }) }
          name="year"
          options={ sowingYearOptions }
          orderDirection="desc"
        />
      </Row>
      <Row>
        <InputSelect
          required
          label={ t('model') }
          detached
          options={ modelOptions }
          onChange={ value => {
            setModel(value)
            setGroup(formOptions[0]?.value)
          } }
          value={ model }
        />
      </Row>
      {isMilk && (<Row>
        <InputSelect
          label={ t('lot', { howMany: 2 }) }
          detached
          multiple
          options={ lotsOptions }
          onChange={ value => {
            setLotTypes(value)
            setGroup(formOptions[0]?.value)
          } }
          value={ lotTypes }
        />
      </Row>)
      }
      <Row>
        <RadioGroup
          detached
          style={ { width: '100%' } }
          onChange={ ({ value }) => setGroup(value) }
          options={ formOptions }
          value={ group }
        />
      </Row>
    </Form>
  )
})

IncomeReportForm.propTypes = {
  onSubmit: PropTypes.func,
  setGroup: PropTypes.func,
  setModel: PropTypes.func,
  setLotTypes: PropTypes.func,
  lotTypes: PropTypes.array,
  group: PropTypes.string,
  model: PropTypes.string
}

IncomeReportForm.defaultProps = {
  onSubmit: () => {},
  setGroup: () => {},
  setModel: () => {},
  setLotTypes: () => {},
  lotTypes: null,
  group: null,
  model: null
}

export default IncomeReportForm
