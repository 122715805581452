import api from '../api'

export const getAllAnimalPregnancyDiagnostics = (params, { animalId }) =>
  api.get(`/v1/herds-management/animals/${ animalId }/pregnancy-diagnostics`, {
    params
  })

export const getAnimalPregnancyDiagnostic = (
  params,
  { animalId, pregnancyId }
) =>
  api.get(`/v1/herds-management/animals/${ animalId }/pregnancy-diagnostics/${ pregnancyId }`,
    {
      params
    }
  )

export const createAnimalPregnancyDiagnostic = (params) =>
  api.post('/v1/herds-management/animals/pregnancy-diagnostics',
    params
  )

export const editAnimalPregnancyDiagnostic = (
  params,
  { animalId, pregnancyId }
) =>
  api.put(`/v1/herds-management/animals/${ animalId }/pregnancy-diagnostics/${ pregnancyId }`,
    params
  )

export const getAnimalPregnancyDiagnosticsTypes = (params, { animalId }) =>
  api.get(`/v1/herds-management/animals/${ animalId }/pregnancy-diagnostics/types`, {
    params
  })

export const getAllAnimalPregnancyDiagnosticsTypes = (params) =>
  api.get('/v1/herds-management/animals/pregnancy-diagnostics/types', { params })

export const deleteAnimalPregnancyDiagnostics = ({ diagnosisId }) =>
  api.delete(`/v1/herds-management/animals/${ diagnosisId }`)
