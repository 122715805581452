import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

// Initial state
const INITIAL_STATE = {
  posts: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadPosts: ['params', 'onSuccess', 'onError'],
  loadPostsSuccess: ['posts', 'page', 'onSuccess'],

  savePost: ['post', 'onSuccess', 'onError'],
  saveOfflinePost: ['post', 'onSuccess', 'onError'],

  socialError: ['error'],

  loadComments: ['params', 'onSuccess', 'onError'],
  saveComment: ['params', 'onSuccess', 'onError'],
  editComment: ['params', 'onSuccess', 'onError' ],
  deleteComment: ['params', 'onSuccess', 'onError' ]
})

/**
 * Assigning offline actions
 */
markActionsOffline(Creators, [
  'savePost'
])


/**
 * Reducers functions
 */
const loadPostsSuccess = (state = INITIAL_STATE, { posts, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  posts: page === 1 ? posts : [
    ...state.posts,
    ...posts
  ]
})


const socialError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})


const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_POSTS_SUCCESS]: loadPostsSuccess,

  [Types.SOCIAL_ERROR]: socialError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as SocialTypes,
  Creators as SocialActions
}
