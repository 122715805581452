import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'

import {
  Badge
} from './styles'

const PropertyAssetsStatus = props => {
  const {
    slug,
    style
  } = props

  const options = useMemo(() => {
    switch (slug) {
      case 'baixado':
        return {
          name: <I18n params={ { gender: 'male' } }>writed off</I18n>,
          background: '(25, 156, 216, 0.1)',
          color: '(25, 156, 216, 1)'
        }
      case 'vendido':
        return {
          name: <I18n params={ { gender: 'male' } }>selled</I18n>,
          background: '(228, 29, 27, 0.1)',
          color: '(228, 29, 27, 1)'
        }
      case 'inativo':
        return {
          name: <I18n params={ { gender: 'male' } }>inative</I18n>,
          background: '(29, 29, 27, 0.1)',
          color: '(29, 29, 27, 1)'
        }

      case 'ativo':
        return {
          name: <I18n params={ { gender: 'male' } }>active</I18n>,
          background: '(124, 179, 66, 0.1)',
          color: '(124, 179, 66, 1)'
        }
      default:
        return {
          name: '',
          background: '(40, 159, 48, 0.1)',
          color: '(40, 159, 48, 1)'
        }
    }
  }, [slug])

  return (
    <Badge
      backgroundColorBadge={ options.background }
      colorBadge={ options.color }
      style={ style }
    >
      {options.name}
    </Badge>
  )
}


PropertyAssetsStatus.propTypes = {
  slug: PropTypes.oneOf([
    'ativo',
    'inativo',
    'vendido',
    'baixado'
  ]).isRequired,
  style: PropTypes.object
}

PropertyAssetsStatus.defaultProps = {
  style: {}
}

export default PropertyAssetsStatus
