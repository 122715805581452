import styled from 'styled-components'

import Card from '@smartcoop/web-components/Card'

export const Container = styled(Card).attrs({
  headerStyle: { padding: '8px 16px 3px', fontWeight: 'bold' },
  cardStyle: { padding: 0, width: '100%' },
  cardContentProps: { style: { paddingTop: 0 } }
})`
  width: 100%;
  margin: 0;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
