import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'

import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { selectCurrentField } from '@smartcoop/stores/field/selectorField'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'

import { selectOfflineBulls } from '../offlineAnimalUserData/selectorOfflineAnimalUserData'

export const selectOfflineTechnicalForms = state => state.offlineTechnicalUserData.technicalForms
export const selectOfflineEvaluationProducers = state => state.offlineTechnicalUserData.evaluationProducers

export const selectProducers = state => state.offlineTechnicalUserData.producers
export const selectOfflineTechnicalGoals = state => state.offlineTechnicalUserData.technicalGoals
export const selectProducersByOrganization = state => filter(state.offlineTechnicalUserData.producers, producer => find(producer.organizationUser, item => item?.organizationId === state.organization?.currentOrganization?.id))
export const selectTechnicalOrganizations = state => state.offlineTechnicalUserData.technicalOrganization
export const selectProducersIDGrouped = state => state.offlineTechnicalUserData.producersIDGrouped

export const selectOfflineProductivityChallengeFormData = state => state.offlineTechnicalUserData.productivityChallengeFormData

export const selectProducersProperties = state => map(state?.offlineTechnicalUserData?.offlineCurrentOwner?.userProperties, item => item.property)

export const selectOfflineTechnicalPropertiesPrecipitation = state => {
  const properties = selectProducersProperties(state)
  const property = selectCurrentProperty(state)

  const currentTechnicalProperty = find(properties, prop => prop.id === property.id)

  return currentTechnicalProperty?.precipitations ?? []
}

export const selectOfflineTechnicalMilkDeliveries = state => {
  const properties = selectProducersProperties(state)
  const property = selectCurrentProperty(state)

  const currentTechnicalProperty = find(properties, prop => prop.id === property.id)

  return currentTechnicalProperty?.dairyFarm?.milkDeliveries
}

export const selectOfflineTechnicalMilkQualities = state => {
  const properties = selectProducersProperties(state)
  const property = selectCurrentProperty(state)

  const currentTechnicalProperty = find(properties, prop => prop.id === property.id)

  const milkQualitiesWithGroups = map(currentTechnicalProperty?.dairyFarm?.milkQualities, item => {
    const groupedItems = []
    const addGroup = (fieldName) => {
      groupedItems.push({
        date: item.date,
        type: fieldName,
        result: item[fieldName],
        limit: item[`${ fieldName  }Limit`]
      })
    }

    if (!isNil(item?.ccs)) addGroup('ccs')
    if (!isNil(item?.ctb)) addGroup('ctb')
    if (!isNil(item?.protein)) addGroup('protein')
    if (!isNil(item?.fat)) addGroup('fat')

    return {
      ...item,
      groupedItems
    }
  })

  return milkQualitiesWithGroups
}

export const selectOfflineTechnicalMilkPrices = state => {
  const properties = selectProducersProperties(state)
  const property = selectCurrentProperty(state)

  const currentTechnicalProperty = find(properties, prop => prop.id === property.id)

  return currentTechnicalProperty?.dairyFarm?.priceDatas
}

export const selectOfflineTechnicalStateRegistrations = state => {
  const property = selectCurrentProperty(state)

  return map(property?.dairyFarm[0]?.dairyFarmStateRegistration, item => item?.userStateRegistration)
}

export const selectTechnicalPropertyFields = (state) => {
  const property = selectCurrentProperty(state)

  const properties = selectProducersProperties(state)

  const currentProperty = find(properties, prop => prop?.id === property?.id)

  return currentProperty?.fields ?? []
}

export const selectTechnicalPropertyOpenFields = (state) => {
  const property = selectCurrentProperty(state)

  const properties = selectProducersProperties(state)

  const currentProperty = find(properties, prop => prop?.id === property?.id)

  return map(currentProperty?.fields, field => ({
    ...field,
    growingSeasons: filter(field?.growingSeasons, item => !item.closed)
  })) ?? []
}

export const selectTechnicalPropertyClosedFields = (state) => {
  const property = selectCurrentProperty(state)

  const properties = selectProducersProperties(state)

  const currentProperty = find(properties, prop => prop?.id === property?.id)

  return map(currentProperty?.fields, field => ({
    ...field,
    growingSeasons: filter(field?.growingSeasons, item => item.closed)
  })) ?? []
}

export const selectTechnicalPropertyPlannedFields = (state) => {
  const property = selectCurrentProperty(state)

  const properties = selectProducersProperties(state)

  const currentProperty = find(properties, prop => prop?.id === property?.id)

  return map(currentProperty?.fields, field => ({
    ...field,
    growingSeasons: filter(field?.growingSeasons, item => item.isPlanned)
  })) ?? []
}

export const selectTechnicalPropertyGrowingSeasons = (state) => {
  const property = selectCurrentProperty(state)

  const properties = selectProducersProperties(state)

  const currentProperty = find(properties, prop => prop?.id === property?.id)

  return flatMap(currentProperty?.fields, field => field?.growingSeasons) ?? []
}

export const selectTechnicalPropertyOpenGrowingSeasons = (state) => {
  const property = selectCurrentProperty(state)

  const properties = selectProducersProperties(state)

  const currentProperty = find(properties, prop => prop?.id === property?.id)

  return filter(flatMap(currentProperty?.fields, field => field?.growingSeasons), item => !item.closed) ?? []
}

export const selectTechnicalCurrntField = (state) => {
  const fields = selectTechnicalPropertyFields(state)
  const currentField = selectCurrentField(state)
  const tent = find(fields, (field) => field?.id === currentField?.id)
  if(!isEmpty(tent?.growingSeason?.cropsManagements)){
    const tent1 = tent?.growingSeason?.cropsManagements.map((item) => ({ ...item, realizationDate: correctDate(item?.id,currentField?.growingSeason) }))
    tent.growingSeason.cropsManagements= tent1
    return tent
  }

  return  find(fields, (field) => field?.id === currentField?.id) ?? {}
}
const correctDate = (id,current)=>{
  const currentDate = current.cropsManagements.find(x=>id===x.id)
  return currentDate.realizationDate
}
export const selectTechnicalPropertyLots = (state) => {
  const property = selectCurrentProperty(state)

  const properties = selectProducersProperties(state)

  const currentProperty = find(properties, prop => prop?.id === property?.id)

  return currentProperty?.lots ?? []
}

export const selectOfflineTechnicalLotsOptions = (state) => {
  const lots = selectTechnicalPropertyLots(state)

  const lotsOptions = map(lots, (item) => ({
    label: item.name,
    value: item.id
  }))

  return lotsOptions
}

export const selectTechnicalPropertyParameters = (state) => {
  const property = selectCurrentProperty(state)

  const properties = selectProducersProperties(state)

  const currentProperty = find(properties, prop => prop?.id === property?.id)

  return currentProperty?.parameters ?? []
}

export const selectOfflineTechnicalAnimals = state => {
  const animalsFilter = filter(state.offlineTechnicalUserData.animals, item => !!item.slaughterDate === false)
  return animalsFilter
}

export const selectOfflineTechnicalGrupedAnimals = state => {
  const property = selectCurrentProperty(state)
  const animals = selectOfflineTechnicalAnimals(state)

  const grupedAnimals = []
  map(animals, item => {
    if(item?.lot?.propertyId === property?.id) {
      const animal = {
        ...item
      }
      grupedAnimals.push(animal)
    }
  })
  return grupedAnimals
}

export const selectOfflineTechnicalInsemination = state => {
  const currentInseminations = []
  const currentAnimal = selectCurrentAnimal(state)
  const animals = selectOfflineTechnicalAnimals(state)
  const bulls = selectOfflineBulls(state)

  const dataInseminations = find(animals, item => item.id === currentAnimal.id)
  const inseminations = dataInseminations?.inseminations
  if(!isEmpty(inseminations)) {
    map(inseminations, data => {
      const bull = find(bulls, item => item.id === data.bullId)
      currentInseminations.push({ ...data, animal: currentAnimal, bull })
    })
  }
  return currentInseminations
}

export const selectOfflineTechnicalProtocols = state => {
  const currentProtocols = []
  const currentAnimal = selectCurrentAnimal(state)
  const animals = selectOfflineTechnicalAnimals(state)

  const dataProtocol = find(animals, item => item.id === currentAnimal.id)
  const protocols = dataProtocol?.protocols
  if(!isEmpty(protocols)) {
    map(protocols, data => {
      currentProtocols.push({ ...data, animal: currentAnimal })
    })
  }
  return currentProtocols
}

export const selectOfflineTechnicalDiagnostics = state => {
  const currentDiagnostics = []
  const currentAnimal = selectCurrentAnimal(state)
  const animals = selectOfflineTechnicalAnimals(state)
  const bulls = selectOfflineBulls(state)

  const dataDiagnostics = find(animals, item => item.id === currentAnimal.id)
  const diagnostics = dataDiagnostics?.diagnostics
  if(!isEmpty(diagnostics)) {
    map(diagnostics, data => {
      const bull = find(bulls, item => item.id === data.bullId)
      currentDiagnostics.push({ ...data, animal: currentAnimal, bull })
    })
  }
  return currentDiagnostics
}

export const selectOfflineTechnicalDrying = state => {
  const currentDrying = []
  const currentAnimal = selectCurrentAnimal(state)
  const animals = selectOfflineTechnicalAnimals(state)
  const bulls = selectOfflineBulls(state)

  const dataDrying = find(animals, item => item.id === currentAnimal.id)
  const drying = dataDrying?.drying
  if(!isEmpty(drying)) {
    map(drying, data => {
      const bull = find(bulls, item => item.id === data.bullId)
      currentDrying.push({ ...data, animal: currentAnimal, bull })
    })
  }
  return currentDrying
}

export const selectOfflineTechnicalPev = state => {
  const currentPev = []
  const currentAnimal = selectCurrentAnimal(state)
  const animals = selectOfflineTechnicalAnimals(state)
  const bulls = selectOfflineBulls(state)

  const dataVoluntaryWaitingPeriod = find(animals, item => item.id === currentAnimal.id)
  const voluntaryWaitingPeriod = dataVoluntaryWaitingPeriod?.voluntaryWaitingPeriod
  if(!isEmpty(voluntaryWaitingPeriod)) {
    map(voluntaryWaitingPeriod, data => {
      const bull = find(bulls, item => item.id === data.bullId)
      currentPev.push({ ...data, animal: currentAnimal, bull })
    })
  }
  return currentPev
}

export const selectOfflineTechnicalObservations = state => {
  const currentObservations = []
  const currentAnimal = selectCurrentAnimal(state)
  const animals = selectOfflineTechnicalAnimals(state)

  const dataObservations = find(animals, item => item.id === currentAnimal.id)
  const voluntaryWaitingPeriod = dataObservations?.observations
  if(!isEmpty(voluntaryWaitingPeriod)) {
    map(voluntaryWaitingPeriod, data => {
      currentObservations.push({ ...data, animal: currentAnimal })
    })
  }
  return currentObservations
}

export const selectOfflineTechnicalBirths = state => {
  const currentAnimal = selectCurrentAnimal(state)
  const animals = selectOfflineTechnicalAnimals(state)
  const data = find(animals, item => item.id === currentAnimal.id)
  const births = data?.births
  return births
}
