import { all, put, takeLatest, take, select } from 'redux-saga/effects'

import { AuthenticationActions } from '../authentication/duckAuthentication'
import { selectAllSlugs } from '../authentication/selectorAuthentication'
import { DairyFarmActions } from '../dairyFarm/duckDairyFarm'
import { OrganizationActions } from '../organization'
import { PropertyActions } from '../property'
import { UserTypes } from '../user'
import { selectUserId } from '../user/selectorUser'
import { AVAILABLE_MODULES, ModuleActions, ModuleTypes } from './duckModule'

function* initModuleBySlug({ slug }) {
  const userId = yield select(selectUserId)

  if (!userId) {
    yield take(UserTypes.LOAD_USER_SUCCESS)
  }

  switch (slug) {
    case AVAILABLE_MODULES.digitalProperty:
      return yield put(ModuleActions.initDigitalPropertyModule())
    case AVAILABLE_MODULES.shoppingPlatform:
      return yield put(ModuleActions.initShoppingPlatformModule())
    case AVAILABLE_MODULES.commercialization:
      return yield put(ModuleActions.initCommercializationModule())
    case AVAILABLE_MODULES.technical:
      return yield put(ModuleActions.initTechnicalModule())
    case AVAILABLE_MODULES.administration:
      return yield put(ModuleActions.initAdministrationModule())
    default:
      throw new Error('user does not any module')
  }
}

function* initDigitalPropertyModule() {
  yield all([
    put(PropertyActions.loadProperties()),
    put(PropertyActions.loadActivities())
  ])
}

function* exitCurrentModule() {
  const slugs = yield select(selectAllSlugs)
  yield put(DairyFarmActions.resetDairyFarmMonth())
  yield put(OrganizationActions.clearCurrentOrganization())
  yield put(AuthenticationActions.setSlug(slugs))
  yield put(PropertyActions.resetCurrentProperty())
}

export default [
  takeLatest(ModuleTypes.INIT_MODULE_BY_SLUG, initModuleBySlug),
  takeLatest(
    ModuleTypes.INIT_DIGITAL_PROPERTY_MODULE,
    initDigitalPropertyModule
  ),

  takeLatest(ModuleTypes.EXIT_CURRENT_MODULE, exitCurrentModule)
]
