import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useImperativeHandle,
  forwardRef,
  useRef
} from 'react'

import trimMask from '@meta-awesome/functions/src/trimMask'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'

import Divider from '@material-ui/core/Divider'

import { FieldProvider, useField } from '@smartcoop/forms'
import { useT } from '@smartcoop/i18n'
import { plus } from '@smartcoop/icons'
import Icon from '@smartcoop/web-components/Icon'

import CheckboxButton from './CheckboxButton'
import {
  Container,
  CheckboxContainer,
  ErrorLabel,
  Label,
  AddNewAreaContainer,
  AddNewArea
} from './styles'

const CheckboxGroup = (props) => {
  const {
    label: externalLabel,
    options: externalOptions,
    variant,
    defaultValue: externalDefaultValue,
    extendable,
    handleChange
  } = props

  const defaultValue = useMemo(
    () => externalDefaultValue || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const [value, setValue] = useState(defaultValue)
  const [mounted, setMounted] = useState(false)
  const [options, setOptions] = useState(externalOptions)

  const t = useT()

  const inputAddNewRef = useRef(null)

  const {
    fieldName,
    fieldRef,
    formRef,
    handleChangeNative,
    setTouched,
    required,
    error,
    validateField
  } = useField()

  const label = useMemo(() => `${ externalLabel }${ required ? ' *' : '' }`, [
    externalLabel,
    required
  ])

  const customMargin = useMemo(
    () =>
      variant === 'row' ? { marginRight: '5px' } : { marginBottom: '5px' },
    [variant]
  )

  const onChange = useCallback((newValue, checked) => {
    setValue((oldValue) => {
      if (checked) {
        // add
        return [...oldValue, newValue]
      }
      // remove
      return filter(oldValue, (val) => val !== newValue)
    })
  }, [])

  const addNewAreaToGroup = useCallback(
    (newArea) => {
      if (trimMask(newArea)) {
        const formatedLabel = kebabCase(newArea)
        const newOptions = [
          ...options,
          {
            label: newArea,
            value: formatedLabel,
            checked: true
          }
        ]
        setOptions(newOptions)
        setValue([...value, formatedLabel])
        inputAddNewRef.current.setValue('')
      }
    },
    [options, value]
  )

  const handlePress = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        addNewAreaToGroup(event.target.value)
      }
    },
    [addNewAreaToGroup]
  )

  useEffect(() => {
    if (mounted) {
      const formFieldRef = formRef.current.getFieldRef(fieldName)
      if (!isEqual(value, formFieldRef.value)) {
        handleChange(value)
        handleChangeNative(value)
        if (isEmpty(value)) {
          setTouched(true)
        }
      }
    } else {
      setMounted(true)
    }

    const checkboxValues = map(options, (option) => ({
      ...option,
      checked: !!find(value, (val) => val === option.value)
    }))
    setOptions(checkboxValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useImperativeHandle(fieldRef, () => ({
    ...(fieldRef.current || {}),
    value,
    defaultValue,
    setValue,
    validateField
  }))

  return (
    <Container>
      <Label error={ error }>{label}</Label>
      <ErrorLabel>{error}</ErrorLabel>
      <CheckboxContainer style={ { ...customMargin, flexDirection: variant } }>
        {map(options, (option) => (
          <CheckboxButton
            style={ { ...customMargin } }
            key={ option.value }
            { ...option }
            onChange={ onChange }
          />
        ))}
      </CheckboxContainer>
      {extendable ? (
        <>
          <Divider />
          <AddNewAreaContainer>
            <Icon icon={ plus } size={ 16 } />
            <AddNewArea
              placeholder={ t('add another acticity') }
              onKeyUp={ (e) => handlePress(e) }
              onKeyDown={ (e) => e.keyCode === 13 && e.preventDefault() }
              ref={ inputAddNewRef }
            />
          </AddNewAreaContainer>
        </>
      ) : (
        ''
      )}
    </Container>
  )
}

CheckboxGroup.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
  variant: PropTypes.oneOf(['row', 'column']).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  extendable: PropTypes.bool
}

CheckboxGroup.defaultProps = {
  label: null,
  handleChange:() => {},
  defaultValue: undefined,
  extendable: false
}

const Field = forwardRef(({ path, ...props }, ref) => (
  <FieldProvider
    ref={ ref }
    { ...props }
    registerFieldOptions={ { path } }
    FieldComponent={ CheckboxGroup }
  />
))

Field.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.oneOf(['row', 'column']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  path: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
}

Field.defaultProps = {
  variant: 'column',
  type: 'text',
  className: '',
  size: 'small',
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
  path: 'value',
  options: [],
  defaultValue: undefined,
  label: null
}

export default Field
