import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import moment from 'moment/moment'
import { v4 as uuidv4 } from 'uuid'

import  filter  from 'lodash/filter'
import  find  from 'lodash/find'
import  includes  from 'lodash/includes'
import map from 'lodash/map'

import { orderConversations } from '@smartcoop/utils/orderConversations'


import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  conversations: [],
  conversationsAll: [],
  contacts: {},
  currentConversation: {},
  currentConversationMessages: [],
  researches: [],
  researchesMessage: [],
  error: null,

  currentAudioPlayingId: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  createConversation: ['newConversation', 'onSuccess'],
  createGroup: ['params', 'onSuccess', 'onError'],
  createOfflineGroup: ['params', 'onSuccess', 'onError'],
  deleteGroupMember: ['conversationId','params','onSuccess', 'onError'],
  deleteOfflineGroupMember: ['conversationId','params','onSuccess', 'onError'],
  updateAdminMember: ['conversationId','params','onSuccess', 'onError'],
  updateOfflineAdminMember: ['conversationId','params','onSuccess', 'onError'],
  addGroupMember: ['conversationId','params', 'onSuccess', 'onError'],
  addOfflineGroupMember: ['conversationId','params', 'onSuccess', 'onError'],
  loadConversations: ['onSuccess', 'onError'],
  loadConversationSuccess: ['conversations', 'onSuccess'],
  loadCurrentConversation: ['idConversation', 'onError'],
  loadCurrentConversationMessages: ['idConversation', 'onError'],
  setCurrentConversation: ['currentConversation'],
  setCurrentConversationMessages: ['currentConversationMessages'],
  // resetCurrentMachine: [],
  sendMessage: ['params', 'onSuccess', 'onError'],
  sendOfflineMessage: ['params', 'onSuccess', 'onError'],
  updateOfflineMessagesData: ['params'],
  deleteConversation: ['conversationIds', 'userId', 'onSuccess', 'onError'],
  loadResearches: ['search', 'onError'],
  loadResearchesSuccess: ['researches'],
  loadResearchesMessage: ['researchesMessage', 'conversationId', 'onError'],
  loadResearchesMessageSuccess: ['researchesMessage'],
  loadOfflineResearchesMessage: ['researchesMessage', 'onError'],
  changeMessagesStatus: ['receivedStats', 'idsArray', 'onError'],
  deleteChatMessages: ['chatIds', 'allUsers', 'idConversation', 'onSuccess', 'onError'],
  markConversationAsFavorite: ['id', 'params', 'onError'],
  markMultipleConversationsAsFavorite: ['idList', 'params', 'onError'],
  markConversationAsFavoriteOffline: ['id', 'params', 'onError'],
  markMultipleConversationsAsFavoriteOffline: ['idList', 'params', 'onError'],
  updateConversationName: ['id', 'params', 'onError'],
  updateConversationPhoto: ['id', 'params', 'onError'],
  updateConversationNameSuccess: ['id', 'params'],
  updateConversationPhotoSuccess: ['id', 'params'],

  loadContacts: ['searchTerm', 'onSuccess'],
  loadContactsSuccess: ['contacts'],
  setNewContact: ['userCode'],
  removeContact: ['userCode'],

  uploadFileMessage: ['fileMessage', 'conversationId', 'uploadType', 'onError'],

  cleanLoadResearchesMessage: [],

  receiveDataSocket: ['params', 'onSuccess', 'onError'],

  chatError: ['error'],

  sendAdvertising: ['advertisingId', 'onSuccess', 'onError'],
  refuseAdvertising: ['advertisingId', 'reason', 'onSuccess', 'onError'],
  createAdvertising: ['params', 'onSuccess', 'onError'],
  deleteAdvertising: ['advertisingId', 'onSuccess', 'onError'],
  createAdvertisingFiles: ['formData', 'onSuccess', 'onError'],

  setCurrentAudioPlaying: ['nextAudioPlayingId']
})

/**
 * Assigning offline actions
 */
markActionsOffline(Creators, [
  'markMultipleConversationsAsFavorite',
  'markConversationAsFavorite',
  'sendMessage',
  'createGroup',
  'addGroupMember',
  'deleteGroupMember',
  'updateAdminMember'
])


/**
 * Reducers functions
 */
const loadConversationSuccess = (state = INITIAL_STATE, { conversations }) => ({
  ...state,
  error: INITIAL_STATE.error,
  conversations
})

const loadResearchesSuccess = (state = INITIAL_STATE, { researches }) => ({
  ...state,
  error: INITIAL_STATE.error,
  researches
})


const createOfflineGroup = (state = INITIAL_STATE, { params }) => {
  const myFavorites = filter(state.conversations, item => !!find(item.userCodes, user => user.userId === params.currentUserId && user.favoritedThisChat))
  const notFavorites = filter(state.conversations, item => !find(item.userCodes, user => user.userId === params.currentUserId && user.favoritedThisChat))
  return {
    ...state,
    error: INITIAL_STATE.error,
    conversations: [{ conversation: [ ...myFavorites, { ...params, id: uuidv4() },...notFavorites] }]
  }

}

const updateOfflineMessagesData = (state = INITIAL_STATE, { params }) => ({
  ...state,
  conversations: map([...state.conversations], item => {
    if (item?.conversation?.id === params?.conversationId) {
      return {
        ...item,
        conversation: {
          ...item?.conversation,
          chatUserRegistry: [
            ...item?.conversation?.chatUserRegistry,
            {
              ...params,
              id: uuidv4(),
              whoSend: params?.userId,
              createdAt: moment().format()
            }
          ]
        }
      }
    }
    return item
  })
})

const loadResearchesMessageSuccess = (state = INITIAL_STATE, { researchesMessage }) => ({
  ...state,
  error: INITIAL_STATE.error,
  researchesMessage
})

const cleanLoadResearchesMessage = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error,
  researchesMessage: []
})

const chatError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const setCurrentAudioPlaying = (state = INITIAL_STATE, { nextAudioPlayingId }) => ({
  ...state,
  currentAudioPlayingId: nextAudioPlayingId
})

const setCurrentConversation = (state = INITIAL_STATE, { currentConversation }) => ({
  ...state,
  currentConversation
})

const setCurrentConversationMessages = (state = INITIAL_STATE, { currentConversationMessages }) => ({
  ...state,
  currentConversationMessages
})

const loadContactsSuccess = (state = INITIAL_STATE, { contacts }) => ({
  ...state,
  contacts
})

const markConversationAsFavoriteOffline = (state = INITIAL_STATE, { id, params }) => ({
  ...state,
  conversations: map(state.conversations, item => {
    if (item.id === id) {
      return {
        ...item,
        ...params
      }
    }
    return item
  })
})

const markMultipleConversationsAsFavoriteOffline = (state = INITIAL_STATE, { idList, params }) => {
  const conversations = orderConversations(map([...state.conversations], item => {
    const currentIdIndex = idList.indexOf(item.id)
    if (currentIdIndex >= 0) {
      const newData = params[currentIdIndex]
      return {
        ...item,
        ...newData
      }
    }
    return item
  }))

  return ({
    ...state,
    conversations
  })
}

const updateConversationNameSuccess = (state = INITIAL_STATE, { id, params }) => ({
  ...state,
  conversations: state.conversations.map(conversation => {
    if (conversation.conversationId === id) {
      return {
        ...conversation,
        conversation: {
          ...conversation.conversation,
          groupName: params.data.groupName
        }
      }
    }
    return conversation
  })
})

const updateConversationPhotoSuccess = (state = INITIAL_STATE, { id, params }) => ({
  ...state,
  conversations: state.conversations.map(conversation => {
    if (conversation.conversationId === id) {
      return {
        ...conversation,
        conversation: {
          ...conversation.conversation,
          photo: params.data.photo
        }
      }
    }
    return conversation
  })
})

const updateOfflineAdminMember = (state = INITIAL_STATE, { conversationId, params }) => ({
  ...state,
  conversations: map(state.conversations, ({ conversation: item }) => {
    if (item.id === conversationId) {
      return { conversation: {
        ...item,
        userCodes:map(item.userCodes, userCode => {
          if(userCode.userId === params.userCodes[0].userId){
            return {
              ...userCode,isAdminGroup:params.userCodes[0].isAdminGroup
            }
          }
          return userCode
        })
      }
      }}
    return { conversation: item }
  })
})

const deleteOfflineGroupMember = (state = INITIAL_STATE, { conversationId, params }) => ({
  ...state,
  conversations: map(state.conversations, ({ conversation: item }) => {
    if (item.id === conversationId) {
      return { conversation: {
        ...item,
        userCodes:filter(item.userCodes, userCode => !includes(params.remove, userCode.userId)),
        users:filter(item.users, user => !includes(params.remove, user.id))
      }
      }}
    return { conversation: item }
  })
})

const logout = () => ({ ...INITIAL_STATE })


/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_CONVERSATION_SUCCESS]: loadConversationSuccess,
  [Types.SET_CURRENT_CONVERSATION]: setCurrentConversation,
  [Types.SET_CURRENT_CONVERSATION_MESSAGES]: setCurrentConversationMessages,
  [Types.CHAT_ERROR]: chatError,
  [Types.SET_CURRENT_AUDIO_PLAYING]: setCurrentAudioPlaying,
  [Types.LOAD_RESEARCHES_SUCCESS]: loadResearchesSuccess,
  [Types.LOAD_RESEARCHES_MESSAGE_SUCCESS]: loadResearchesMessageSuccess,
  [Types.CLEAN_LOAD_RESEARCHES_MESSAGE]: cleanLoadResearchesMessage,
  [Types.DELETE_OFFLINE_GROUP_MEMBER]: deleteOfflineGroupMember,
  [Types.MARK_CONVERSATION_AS_FAVORITE_OFFLINE]: markConversationAsFavoriteOffline,
  [Types.MARK_MULTIPLE_CONVERSATIONS_AS_FAVORITE_OFFLINE]: markMultipleConversationsAsFavoriteOffline,
  [Types.UPDATE_OFFLINE_MESSAGES_DATA]: updateOfflineMessagesData,
  [Types.UPDATE_CONVERSATION_NAME_SUCCESS]: updateConversationNameSuccess,
  [Types.UPDATE_OFFLINE_ADMIN_MEMBER]: updateOfflineAdminMember,
  [Types.UPDATE_CONVERSATION_PHOTO_SUCCESS]: updateConversationPhotoSuccess,
  [Types.CREATE_OFFLINE_GROUP]: createOfflineGroup,
  [Types.LOAD_CONTACTS_SUCCESS]: loadContactsSuccess,
  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as ChatTypes,
  Creators as ChatActions
}
