import * as Yup from 'yup'

import date from '@smartcoop/forms/validators/date.validator'

const filterOrder = ({ t }) => Yup.object().shape({
  startDate: date({ t })(Yup.string()).nullable(),

  productId: Yup.string().nullable(),

  statusId: Yup.string().nullable()
})

export default filterOrder
