import hexToRgba from 'hex-to-rgba'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const InfoCard = styled.div`
  border-radius: 5px;
  background-color: ${ hexToRgba(colors.black, 0.8) };
  color: ${ colors.white };
  font-size: 16px;
  padding: 15px;
  width: 300px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 25px;
  overflow: auto;
  max-height: calc(100vh - 130px);
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  margin-bottom: 20px;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const RowTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Gallery = styled.div`
  display: flex;
  justify-content: ${ ({ isCenter }) => isCenter ? 'center' : 'flex-start' };
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${ ({ isCenter }) => isCenter ? '0' : '20px' };
`
