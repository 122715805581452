import styled from 'styled-components'

import { colors } from '@smartcoop/styles'


export const DetailsTitle = styled.div`
  margin: 0;
  padding: 0;
  padding-top: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  font-size: 16px;
  font-weight: 600;
`

export const EditNameInput = styled.input`
  color: ${ colors.text };
  border: none;
  font-size: 16px;
  font-family: 'Open Sans';
  font-weight: 600;
  background-color: ${ colors.white };
  border-radius: 15px;
  text-align: center;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
`
