import React, { useCallback, forwardRef, useMemo } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import Grid from '@material-ui/core/Grid'

import registerMilkDeliverySchema from '@smartcoop/forms/schemas/dairyFarm/registerMilkDelivery.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { selectCurrentPropertyDairyFarmIEs } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputFloat from '@smartcoop/web-components/InputFloat'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import { Container, FormContainer, ButtonsContainer, Item } from './styles'

const RegisterMilkDeliveryForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel
  } = props

  const t = useT()

  const currentPropertyDairyFarmIEs = useSelector(selectCurrentPropertyDairyFarmIEs)

  const iesOptions = useMemo(
    () => map(currentPropertyDairyFarmIEs, ({ stateRegistration, id }) => ({
      label: stateRegistration,
      value: id
    })),
    [currentPropertyDairyFarmIEs]
  )

  const handleSubmit = useCallback(
    (data) => onSubmit({
      ...data,
      temperature: isEmpty(data.temperature) ? null : data.temperature,
      condemnedVolume: isEmpty(data.condemnedVolume) ? null : data.condemnedVolume
    }),
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerMilkDeliverySchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputSelect
                name="userStateRegistrationId"
                label={ t('state registration') }
                defaultValue={ defaultValues?.userStateRegistrationId }
                options={ iesOptions }
              />
            </Item>
            <Item>
              <InputDate
                label={ t('volume date') }
                name="volumeDate"
                fullWidth
                defaultValue={ defaultValues.volumeDate }
                pickerProps={
                  {
                    maxDate: moment().format()
                  }
                }
              />
            </Item>
            <Item>
              <InputNumber
                label={ t('volume') }
                name="volume"
                fullWidth
                defaultValue={ defaultValues.volume }
              />
            </Item>
            <Item>
              <InputFloat
                label={ t('temperature') }
                name="temperature"
                fullWidth
                defaultValue={ defaultValues.temperature }
                decimalScale={ 1 }
              />
            </Item>
            <Item>
              <InputNumber
                label={ t('condemned volume') }
                name="condemnedVolume"
                fullWidth
                defaultValue={ defaultValues.condemnedVolume }
              />
            </Item>
            <Item>
              <InputText
                label={ t('type here the reason of the condemnation') }
                name="observation"
                multiline
                rows={ 4 }
                fullWidth
                defaultValue={ defaultValues.observation }
              />
            </Item>
          </Grid>
        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-cancel-form-button"
              onClick={ onCancel }
              color={ colors.white }
              disabled={ loading }
              style={ { marginRight:10, marginLeft: 10 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="web-save-form-button"
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
              style={ { marginRight:10, marginLeft: 10 } }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

RegisterMilkDeliveryForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  defaultValues: PropTypes.object.isRequired
}

RegisterMilkDeliveryForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false
}

export default RegisterMilkDeliveryForm
