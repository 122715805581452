import React, { useMemo, useCallback, useRef } from 'react'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import I18n from '@smartcoop/i18n'
import { shield } from '@smartcoop/icons'
import {
  getTechniciansRequestList as getTechniciansRequestListService
} from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import colors from '@smartcoop/styles/colors'
import { formatCpfCnpj, formatPhoneV2 } from '@smartcoop/utils/formatters'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import TechnicalPortfolioStatus from '@smartcoop/web-components/TechnicalPortfolioStatus'
import ConfigurePermissionModal from '@smartcoop/web-containers/modals/profile/ConfigurePermissionModal'

const UserAccessFragment = ({ filterText }) => {
  const tableRef = useRef(null)
  const { createDialog } = useDialog()

  const columns = useMemo(
    () => [
      {
        title: 'Produtor',
        field: 'name'
      },
      {
        title: 'CPF',
        field: 'document',
        render: (row) => formatCpfCnpj(row.document)
      },
      {
        title: 'Telefone',
        field: 'cellPhone',
        render: (row) => formatPhoneV2(row.cellPhone)
      },
      {
        title: 'Acesso',
        field: 'status',
        render: (row) => (
          <TechnicalPortfolioStatus statusNumber={ row.technicianProprietary?.status } />
        )
      }
    ], []
  )

  const configurePermissionModal = useCallback(
    (_, row) => {
      createDialog({
        id: 'configure-permission',
        Component: ConfigurePermissionModal,
        props: {
          userRequestItem: row,
          tableRef
        }
      })
    },
    [createDialog]
  )

  const actions = useMemo(
    () => [
      (row) => ({
        position: 'row',
        onClick: configurePermissionModal,
        disabled: (
          row.technicianProprietary?.status
          && (row.technicianProprietary?.status === 2
          || row.technicianProprietary?.status === 3)
        ),
        iconButtonProps: {
          id: 'request-permission',
          variant: 'outlined',
          size: 'small',
          color: 'black',
          style: {
            width: 200,
            height: 30,
            fontSize: 14,
            fontWeight: 600,
            borderRadius: 5,
            color: colors.black
          }
        },
        icon: () => (
          <>
            <Icon icon={ shield } size={ 14 } style={ { paddingRight: 5 } } />
            <I18n>configure permission</I18n>
          </>
        )
      })
    ],
    [configurePermissionModal]
  )

  const dataTableOptions = useMemo(
    () => ({
      actionsCellDivStyle: {
        alignItems: 'center'
      }
    }),
    []
  )


  return(
    <DataTable
      tableRef={ tableRef }
      columns={ columns }
      actions={ actions }
      options={ dataTableOptions }
      queryParams={ filterText }
      data={ getTechniciansRequestListService }
      id="user-access-table"
    />
  )
}

UserAccessFragment.propTypes = {
  filterText: PropTypes.string
}

UserAccessFragment.defaultProps = {
  filterText: null
}

export default UserAccessFragment
