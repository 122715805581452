// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
      <g>
        <path fill="none" d="M7.5,148.5c-0.1-0.1-0.3-0.2-0.4-0.3C7.2,148.3,7.3,148.4,7.5,148.5z"/>
        <path fill="none" d="M6.7,147.9c-0.1-0.1-0.3-0.2-0.4-0.3C6.4,147.7,6.6,147.8,6.7,147.9z"/>
        <path fill="none" d="M6,147.3c-0.1-0.1-0.3-0.2-0.4-0.3C5.7,147.1,5.8,147.2,6,147.3z"/>
        <path fill="none" d="M4.6,146c-0.1-0.1-0.2-0.3-0.3-0.4C4.4,145.7,4.5,145.8,4.6,146z"/>
        <path fill="none" d="M4.1,145.2c-0.1-0.1-0.2-0.3-0.3-0.5C3.9,144.9,3.9,145.1,4.1,145.2z"/>
        <path fill="none" d="M5.3,146.7c-0.1-0.1-0.2-0.3-0.4-0.4C5,146.4,5.1,146.6,5.3,146.7z"/>
        <path fill="none" d="M10.1,149.6c-0.2-0.1-0.3-0.1-0.5-0.2C9.7,149.5,9.9,149.6,10.1,149.6z"/>
        <path fill="none" d="M8.3,148.9c-0.2-0.1-0.3-0.2-0.4-0.2C8,148.8,8.2,148.8,8.3,148.9z"/>
        <path fill="none" d="M30.4,102.6c0.1-0.1,0.2-0.1,0.3-0.2C30.6,102.4,30.5,102.5,30.4,102.6z"/>
        <path fill="none" d="M9.2,149.3c-0.2-0.1-0.3-0.1-0.5-0.2C8.9,149.2,9,149.2,9.2,149.3z"/>
        <path fill="none" d="M26.2,106.7c0.1-0.1,0.2-0.2,0.2-0.3C26.4,106.5,26.3,106.6,26.2,106.7z"/>
        <path fill="none" d="M3.5,144.4c-0.1-0.2-0.2-0.3-0.3-0.5C3.3,144.1,3.4,144.2,3.5,144.4z"/>
        <path fill="none" d="M25,108.3c0.1-0.1,0.1-0.2,0.2-0.3C25.1,108.2,25.1,108.3,25,108.3z"/>
        <path fill="none" d="M28.9,103.8c0.1-0.1,0.1-0.1,0.2-0.2C29.1,103.7,29,103.8,28.9,103.8z"/>
        <path fill="none" d="M27.6,105.1c0,0,0.1-0.1,0.1-0.1C27.7,105,27.6,105.1,27.6,105.1z"/>
        <path fill="none" d="M24,109.9C24,109.9,24.1,109.9,24,109.9C24.1,109.9,24,109.9,24,109.9z"/>
        <path fill="none" d="M11.9,150c-0.2,0-0.3,0-0.5-0.1C11.6,150,11.8,150,11.9,150z"/>
        <path fill="none" d="M2.7,142.6c-0.1-0.3-0.2-0.6-0.3-1C2.5,142,2.6,142.3,2.7,142.6z"/>
        <path fill="none" d="M3.1,143.5c-0.1-0.2-0.2-0.4-0.3-0.6C2.9,143.1,3,143.3,3.1,143.5z"/>
        <path fill="none" d="M22.3,113.8c0-0.1,0.1-0.2,0.1-0.3C22.3,113.6,22.3,113.7,22.3,113.8z"/>
        <path fill="none" d="M23.1,111.9c0-0.1,0.1-0.2,0.1-0.3C23.2,111.6,23.1,111.7,23.1,111.9z"/>
        <path fill="none" d="M11,149.9c-0.2,0-0.3-0.1-0.5-0.1C10.7,149.8,10.8,149.8,11,149.9z"/>
        <path fill="none" d="M154,92.9c0.1,0,0.1,0,0.2,0.1C154.2,92.9,154.1,92.9,154,92.9z"/>
        <path fill="none" d="M156.1,93.5c0.1,0,0.2,0.1,0.3,0.1C156.3,93.6,156.2,93.5,156.1,93.5z"/>
        <path fill="none" d="M158.3,94.3c0.1,0.1,0.3,0.1,0.4,0.2C158.6,94.4,158.5,94.4,158.3,94.3z"/>
        <path fill="none" d="M151.6,92.4c0.1,0,0.3,0,0.4,0.1C151.9,92.4,151.7,92.4,151.6,92.4z"/>
        <path fill="none" d="M160.5,95.4c0.1,0,0.2,0.1,0.2,0.1C160.7,95.5,160.6,95.4,160.5,95.4z"/>
        <path fill="none" d="M143.1,92.3c0.2,0,0.4-0.1,0.6-0.1C143.5,92.2,143.3,92.3,143.1,92.3z"/>
        <path fill="none" d="M141.4,92.6c0.2,0,0.3-0.1,0.5-0.1C141.7,92.5,141.6,92.5,141.4,92.6z"/>
        <path fill="none" d="M162.5,96.5c0.1,0,0.1,0.1,0.2,0.1C162.6,96.6,162.6,96.5,162.5,96.5z"/>
        <path fill="none" d="M144.9,92.1c0.2,0,0.3,0,0.5,0C145.2,92.1,145,92.1,144.9,92.1z"/>
        <path fill="none" d="M147,92C147,92,147,92,147,92c0,0,0.1,0,0.1,0C147.1,92,147.1,92,147,92z"/>
        <path fill="none" d="M164.4,97.8c0.1,0.1,0.2,0.2,0.3,0.2C164.6,98,164.5,97.9,164.4,97.8z"/>
        <path fill="none" d="M174.3,111c0,0.1,0.1,0.2,0.1,0.4C174.3,111.3,174.3,111.2,174.3,111z"/>
        <path fill="none" d="M173.3,108.7c0.1,0.1,0.1,0.2,0.2,0.4C173.4,108.9,173.3,108.8,173.3,108.7z"/>
        <path fill="none" d="M172.2,106.7C172.3,106.7,172.3,106.7,172.2,106.7C172.3,106.7,172.3,106.7,172.2,106.7z"/>
        <path fill="none" d="M13.5,150.2c0.2,0,0.5,0,0.7,0c-0.3,0-0.6,0-0.9,0C13.4,150.2,13.4,150.2,13.5,150.2z"/>
        <path fill="none" d="M166.3,99.3c0.1,0.1,0.2,0.1,0.2,0.2C166.4,99.5,166.4,99.4,166.3,99.3z"/>
        <path fill="none" d="M170.9,104.6c0.1,0.1,0.1,0.2,0.2,0.3C171.1,104.8,171,104.7,170.9,104.6z"/>
        <path fill="none" d="M12.9,150.1c-0.2,0-0.3,0-0.5,0C12.5,150.1,12.7,150.1,12.9,150.1z"/>
        <path fill="none" d="M167.9,100.9c0,0,0.1,0.1,0.1,0.1C168,101,168,101,167.9,100.9z"/>
        <path fill="none" d="M169.5,102.6c0.1,0.1,0.2,0.2,0.3,0.3C169.6,102.8,169.5,102.7,169.5,102.6z"/>
        <path fill="none" d="M149.2,92.1c0.1,0,0.2,0,0.3,0C149.4,92.1,149.3,92.1,149.2,92.1z"/>
        <path fill="none" d="M35.5,99.6c0.1,0,0.2-0.1,0.3-0.1C35.7,99.5,35.6,99.5,35.5,99.6z"/>
        <path fill="none" d="M33.8,100.4c0.1,0,0.1-0.1,0.2-0.1C33.9,100.3,33.8,100.4,33.8,100.4z"/>
        <path fill="none" d="M39.3,98.2c0.1,0,0.1,0,0.2,0C39.4,98.2,39.4,98.2,39.3,98.2z"/>
        <path fill="none" d="M41.2,97.8C41.2,97.8,41.2,97.8,41.2,97.8C41.2,97.8,41.2,97.8,41.2,97.8L41.2,97.8z"/>
        <path fill="none" d="M37.4,98.8c0.1,0,0.2-0.1,0.3-0.1C37.6,98.7,37.5,98.8,37.4,98.8z"/>
        <path fill="none" d="M32.1,101.4c0.1,0,0.1-0.1,0.2-0.1C32.3,101.3,32.2,101.3,32.1,101.4z"/>
        <path fill="#DDC72D" d="M49.7,97.8c0.1,0,0.3,0,0.4,0.1C50,97.9,49.8,97.9,49.7,97.8z"/>
        <path fill="#DDC72D" d="M45.4,97.5C45.4,97.5,45.3,97.5,45.4,97.5C45.3,97.5,45.4,97.5,45.4,97.5C45.4,97.5,45.4,97.5,45.4,97.5z"
          />
        <path fill="#DDC72D" d="M46.8,97.5c0.1,0,0.3,0,0.4,0C47.1,97.5,46.9,97.5,46.8,97.5z"/>
        <path fill="#F9D915" d="M48,91.6c2.6-9.1,7.8-17.3,15-23.6c3.6-3.2,7.7-5.7,12-7.7c-4.7-5.9-11.9-9.6-20-9.6
          c-14.1,0-25.6,11.5-25.6,25.6c0,6.4,2.4,12.3,6.3,16.8c3.1-1,6.3-1.6,9.7-1.6C46.3,91.5,47.2,91.5,48,91.6z"/>
        <path fill="#DDC72D" d="M48.2,97.6c0.2,0,0.3,0,0.5,0.1C48.5,97.7,48.4,97.6,48.2,97.6z"/>
        <path fill="#DDC72D" d="M43.3,97.6c0.1,0,0.2,0,0.3,0C43.5,97.5,43.4,97.5,43.3,97.6z"/>
        <path fill="#DDC72D" d="M41.5,97.8c-0.1,0-0.2,0-0.3,0.1l0,0C41.3,97.8,41.4,97.8,41.5,97.8z"/>
        <path fill="#F9D915" d="M26.8,31l8.7,21.1c2.5-2.1,5.4-3.7,8.6-4.9L30.2,29.1c0,0,0,0,0,0c-0.6-0.8-1.9-1-2.7-0.3
          c-0.7,0.5-0.9,1.4-0.7,2.2C26.7,30.9,26.7,31,26.8,31z"/>
        <path fill="#F9D915" d="M60,45.6L57.1,23c0,0,0,0,0-0.1c-0.2-1-1.1-1.8-2.2-1.7c-0.8,0.1-1.5,0.7-1.7,1.5c0,0,0,0.1,0,0.1l-3,22.6
          c1.6-0.3,3.2-0.4,4.9-0.4C56.7,45.2,58.4,45.4,60,45.6z"/>
        <path fill="#F9D915" d="M1.6,78.1c0,0,0.1,0,0.1,0l22.6,3c-0.3-1.6-0.4-3.2-0.4-4.9c0-1.7,0.1-3.4,0.4-5L1.7,74.2c0,0,0,0-0.1,0
          c-1,0.2-1.8,1.1-1.6,2.2C0.1,77.2,0.7,77.9,1.6,78.1z"/>
        <path fill="#F9D915" d="M83.4,31.2C83.5,31.2,83.5,31.1,83.4,31.2c0.2-0.5,0.2-1,0-1.5c-0.2-0.5-0.6-0.9-1.1-1.1
          c-0.8-0.3-1.7-0.1-2.2,0.5c0,0-0.1,0.1-0.1,0.1l-14,18.1c3.1,1.2,6,2.9,8.5,5L83.4,31.2z"/>
        <path fill="#F9D915" d="M7.9,51.2l18.1,14c1.2-3.1,2.9-6,5-8.5L9.9,47.8c0,0,0,0-0.1,0c-1-0.4-2.1,0.1-2.5,1.1
          c-0.3,0.8-0.1,1.7,0.5,2.2C7.9,51.1,7.9,51.2,7.9,51.2z"/>
        <path fill="#D3D3D3" d="M175.1,113.6c-0.2-0.7-0.4-1.4-0.7-2.2c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.7-0.5-1.3-0.8-2
          c-0.1-0.1-0.1-0.2-0.2-0.4c-0.3-0.7-0.6-1.3-1-1.9c0,0,0-0.1-0.1-0.1c-0.3-0.6-0.7-1.2-1.1-1.8c-0.1-0.1-0.1-0.2-0.2-0.3
          c-0.4-0.6-0.8-1.1-1.2-1.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.5-0.9-1.1-1.4-1.6c0,0-0.1-0.1-0.1-0.1c-0.5-0.5-0.9-0.9-1.4-1.4
          c-0.1-0.1-0.2-0.1-0.2-0.2c-0.5-0.5-1-0.9-1.6-1.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.5-0.4-1.1-0.8-1.7-1.2c-0.1,0-0.1-0.1-0.2-0.1
          c-0.6-0.4-1.1-0.7-1.7-1c-0.1,0-0.2-0.1-0.2-0.1c-0.6-0.3-1.2-0.6-1.8-0.9c-0.1-0.1-0.2-0.1-0.4-0.2c-0.6-0.3-1.3-0.5-1.9-0.7
          c-0.1,0-0.2-0.1-0.3-0.1c-0.6-0.2-1.3-0.4-1.9-0.6c-0.1,0-0.1,0-0.2-0.1c-0.7-0.2-1.3-0.3-2-0.4c-0.1,0-0.3,0-0.4-0.1
          c-0.7-0.1-1.4-0.2-2-0.3c-0.1,0-0.2,0-0.3,0c-0.7-0.1-1.4-0.1-2.1-0.1c0,0-0.1,0-0.1,0c-0.5,0-1.1,0-1.6,0.1c-0.2,0-0.3,0-0.5,0
          c-0.4,0-0.8,0.1-1.2,0.1c-0.2,0-0.4,0-0.6,0.1c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.1-1.1,0.2-1.7,0.4
          c0,0-1.1,0.3-3.1,0.8C131.7,75.5,115,62,95.1,62c-2.6,0-5.2,0.2-7.6,0.7c-1.4,0.3-2.8,0.6-4.2,1c-1.8,0.5-3.5,1.1-5.1,1.8
          c-13.4,5.8-23.3,18.2-25.5,33.1c-0.4-0.1-0.6-0.2-0.8-0.2c-0.2-0.1-0.3-0.1-0.3-0.1c-0.5-0.1-0.9-0.2-1.4-0.3
          c-0.1,0-0.3-0.1-0.4-0.1c-0.3-0.1-0.7-0.1-1-0.2c-0.2,0-0.3,0-0.5-0.1c-0.3,0-0.7-0.1-1-0.1c-0.1,0-0.3,0-0.4,0
          c-0.5,0-0.9,0-1.4,0c0,0-0.1,0-0.1,0c-0.6,0-1.2,0-1.8,0.1c-0.1,0-0.2,0-0.3,0c-0.6,0.1-1.2,0.1-1.7,0.2c-0.1,0-0.2,0-0.3,0.1
          c0,0,0,0,0,0c-0.6,0.1-1.1,0.2-1.7,0.4c-0.1,0-0.1,0-0.2,0c-0.5,0.1-1.1,0.3-1.6,0.5c-0.1,0-0.2,0.1-0.3,0.1
          c-0.5,0.2-1.1,0.4-1.6,0.6c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.2-1,0.5-1.5,0.7c-0.1,0-0.1,0.1-0.2,0.1c-0.5,0.3-0.9,0.5-1.4,0.8
          c-0.1,0-0.1,0.1-0.2,0.1c-0.5,0.3-0.9,0.6-1.4,1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5,0.3-0.9,0.7-1.3,1.1c-0.1,0.1-0.1,0.1-0.2,0.2
          c-0.4,0.4-0.8,0.8-1.2,1.2c0,0-0.1,0.1-0.1,0.1c-0.4,0.4-0.8,0.9-1.2,1.3c-0.1,0.1-0.2,0.2-0.2,0.3c-0.4,0.4-0.7,0.9-1,1.4
          c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.5-0.6,1-0.9,1.5c0,0,0,0.1-0.1,0.1c-0.3,0.5-0.6,1.1-0.8,1.6c0,0.1-0.1,0.2-0.1,0.3
          c-0.2,0.5-0.5,1.1-0.7,1.7c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.6-0.4,1.2-0.6,1.8c-0.2,0.8-0.4,1.5-0.5,2.3s-0.2,1.5-0.3,2.3
          c-0.1,1.5-0.1,3,0.1,4.5c-6.5,1.8-10.5,2.8-10.5,2.8c-6.1,1.6-9.8,7.9-8.1,14c0.1,0.3,0.2,0.6,0.3,1c0,0.1,0.1,0.2,0.1,0.3
          c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.3,0.3,0.5
          c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.2,0.2,0.3,0.3
          c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.3
          c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0.1,0.3,0.1,0.4,0.2
          c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1
          c0.1,0,0.3,0,0.4,0.1c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0c0.3,0,0.6,0,0.9,0h132.1c0,0,5.5-0.3,8.2-1
          C170,145,179.2,129.1,175.1,113.6z"/>
        <path fill="#F9D915" d="M30.5,95.3c-1.9-2.4-3.5-5.1-4.6-8.1L7.8,101.1c0,0,0,0-0.1,0c-0.4,0.3-0.6,0.8-0.7,1.3
          c-0.1,0.5,0.1,1,0.4,1.5c0.5,0.7,1.4,0.9,2.2,0.7c0,0,0.1,0,0.1,0l18-7.4l3.2-1.3C30.8,95.7,30.7,95.5,30.5,95.3z"/>
        <path fill="#FFFFFF" d="M169.7,103c0.4,0.5,0.8,1.1,1.2,1.7C170.6,104,170.1,103.5,169.7,103z"/>
        <path fill="#FFFFFF" d="M171.1,104.9c0.4,0.6,0.8,1.2,1.1,1.8C171.9,106.1,171.5,105.5,171.1,104.9z"/>
        <path fill="#FFFFFF" d="M166.5,99.5c0.5,0.4,1,0.9,1.4,1.4C167.5,100.5,167,100,166.5,99.5z"/>
        <path fill="#FFFFFF" d="M168.1,101.1c0.5,0.5,0.9,1,1.4,1.6C169,102.1,168.6,101.6,168.1,101.1z"/>
        <path fill="#FFFFFF" d="M152,92.5c0.7,0.1,1.4,0.3,2,0.4C153.4,92.7,152.7,92.6,152,92.5z"/>
        <path fill="#FFFFFF" d="M143.7,92.2c0.4,0,0.8-0.1,1.2-0.1C144.5,92.1,144.1,92.2,143.7,92.2z"/>
        <path fill="#FFFFFF" d="M145.3,92.1c0.6,0,1.1-0.1,1.6-0.1C146.4,92,145.9,92,145.3,92.1z"/>
        <path fill="#FFFFFF" d="M29.1,103.6c0.4-0.4,0.9-0.7,1.3-1.1C30,102.9,29.5,103.3,29.1,103.6z"/>
        <path fill="#FFFFFF" d="M139.7,92.9c0.6-0.1,1.1-0.3,1.7-0.4C140.8,92.7,140.3,92.8,139.7,92.9z"/>
        <path fill="#FFFFFF" d="M149.5,92.1c0.7,0.1,1.4,0.1,2,0.3C150.9,92.3,150.2,92.2,149.5,92.1z"/>
        <path fill="#FFFFFF" d="M141.9,92.5c0.4-0.1,0.8-0.1,1.2-0.2C142.7,92.3,142.3,92.4,141.9,92.5z"/>
        <path fill="#FFFFFF" d="M158.7,94.5c0.6,0.3,1.2,0.6,1.8,0.9C159.9,95.1,159.3,94.8,158.7,94.5z"/>
        <path fill="#FFFFFF" d="M160.8,95.5c0.6,0.3,1.1,0.6,1.7,1C161.9,96.1,161.4,95.8,160.8,95.5z"/>
        <path fill="#FFFFFF" d="M162.7,96.6c0.6,0.4,1.1,0.8,1.7,1.2C163.8,97.4,163.3,97,162.7,96.6z"/>
        <path fill="#FFFFFF" d="M172.3,106.8c0.4,0.6,0.7,1.3,1,1.9C173,108,172.6,107.4,172.3,106.8z"/>
        <path fill="#FFFFFF" d="M164.7,98c0.5,0.4,1.1,0.8,1.6,1.3C165.8,98.9,165.2,98.5,164.7,98z"/>
        <path fill="#FFFFFF" d="M156.4,93.6c0.6,0.2,1.3,0.5,1.9,0.7C157.7,94.1,157.1,93.8,156.4,93.6z"/>
        <path fill="#FFFFFF" d="M154.2,92.9c0.6,0.2,1.3,0.4,1.9,0.6C155.5,93.3,154.9,93.1,154.2,92.9z"/>
        <path fill="#FFFFFF" d="M147.1,92c0.7,0,1.4,0,2.1,0.1C148.5,92.1,147.8,92,147.1,92z"/>
        <path fill="#FFFFFF" d="M21.2,117.9c0.1-0.8,0.3-1.5,0.5-2.3C21.5,116.4,21.3,117.2,21.2,117.9z"/>
        <path fill="#FFFFFF" d="M35.8,99.4c0.5-0.2,1.1-0.4,1.6-0.6C36.9,99,36.4,99.2,35.8,99.4z"/>
        <path fill="#FFFFFF" d="M21.7,115.6c0.2-0.6,0.4-1.2,0.6-1.8C22.1,114.4,21.9,115,21.7,115.6z"/>
        <path fill="#FFFFFF" d="M2.8,142.9c0-0.1-0.1-0.2-0.1-0.3C2.7,142.7,2.8,142.8,2.8,142.9z"/>
        <path fill="#FFFFFF" d="M20.9,120.2c0.1-0.8,0.1-1.5,0.3-2.3C21.1,118.7,21,119.5,20.9,120.2z"/>
        <path fill="#FFFFFF" d="M39.5,98.2c0.6-0.1,1.1-0.3,1.7-0.4C40.6,97.9,40,98,39.5,98.2z"/>
        <path fill="#FFFFFF" d="M3.8,144.8c-0.1-0.1-0.2-0.2-0.2-0.4C3.6,144.5,3.7,144.6,3.8,144.8z"/>
        <path fill="#FFFFFF" d="M3.3,143.9c-0.1-0.1-0.1-0.2-0.2-0.4C3.1,143.7,3.2,143.8,3.3,143.9z"/>
        <path fill="#FFFFFF" d="M37.7,98.7c0.5-0.2,1.1-0.3,1.6-0.5C38.8,98.4,38.2,98.5,37.7,98.7z"/>
        <path fill="#FFFFFF" d="M27.7,105c0.4-0.4,0.8-0.8,1.2-1.2C28.5,104.2,28.1,104.6,27.7,105z"/>
        <path fill="#FFFFFF" d="M32.3,101.2c0.5-0.3,0.9-0.6,1.4-0.8C33.3,100.7,32.8,100.9,32.3,101.2z"/>
        <path fill="#FFFFFF" d="M4.3,145.6c-0.1-0.1-0.2-0.2-0.3-0.3C4.1,145.3,4.2,145.4,4.3,145.6z"/>
        <path fill="#FFFFFF" d="M26.4,106.4c0.4-0.5,0.8-0.9,1.2-1.3C27.2,105.5,26.8,106,26.4,106.4z"/>
        <path fill="#FFFFFF" d="M30.7,102.3c0.5-0.3,0.9-0.7,1.4-1C31.7,101.7,31.2,102,30.7,102.3z"/>
        <path fill="#FFFFFF" d="M24.1,109.8c0.3-0.5,0.6-1,0.9-1.5C24.7,108.8,24.4,109.3,24.1,109.8z"/>
        <path fill="#FFFFFF" d="M34,100.3c0.5-0.3,1-0.5,1.5-0.7C35,99.8,34.5,100,34,100.3z"/>
        <path fill="#FFFFFF" d="M23.2,111.5c0.3-0.6,0.5-1.1,0.8-1.6C23.7,110.4,23.5,111,23.2,111.5z"/>
        <path fill="#FFFFFF" d="M22.4,113.5c0.2-0.6,0.4-1.1,0.7-1.7C22.8,112.4,22.6,112.9,22.4,113.5z"/>
        <path fill="#FFFFFF" d="M25.2,108.1c0.3-0.5,0.7-0.9,1-1.4C25.9,107.1,25.5,107.6,25.2,108.1z"/>
        <path fill="#FFFFFF" d="M173.5,109.1c0.3,0.6,0.6,1.3,0.8,2C174,110.4,173.7,109.7,173.5,109.1z"/>
        <path fill="#FFFFFF" d="M10.5,149.8c-0.1,0-0.3-0.1-0.4-0.1C10.2,149.7,10.3,149.7,10.5,149.8z"/>
        <path fill="#FFFFFF" d="M11.4,150c-0.1,0-0.3-0.1-0.4-0.1C11.1,149.9,11.3,149.9,11.4,150z"/>
        <path fill="#FFFFFF" d="M8.7,149.1c-0.1-0.1-0.3-0.1-0.4-0.2C8.4,149,8.6,149,8.7,149.1z"/>
        <path fill="#FFFFFF" d="M13.3,150.2c-0.1,0-0.3,0-0.4,0C13,150.1,13.2,150.1,13.3,150.2z"/>
        <path fill="#FFFFFF" d="M12.4,150.1c-0.1,0-0.3,0-0.4-0.1C12.1,150.1,12.2,150.1,12.4,150.1z"/>
        <path fill="#FFFFFF" d="M174.4,111.4c0.2,0.7,0.5,1.4,0.7,2.2C174.9,112.8,174.6,112.1,174.4,111.4z"/>
        <path fill="#FFFFFF" d="M7.9,148.7c-0.1-0.1-0.3-0.1-0.4-0.2C7.6,148.5,7.7,148.6,7.9,148.7z"/>
        <path fill="#FFFFFF" d="M9.6,149.5c-0.1-0.1-0.3-0.1-0.4-0.2C9.3,149.4,9.4,149.4,9.6,149.5z"/>
        <path fill="#FFFFFF" d="M5.6,147c-0.1-0.1-0.2-0.2-0.3-0.3C5.4,146.8,5.5,146.9,5.6,147z"/>
        <path fill="#FFFFFF" d="M6.3,147.6c-0.1-0.1-0.2-0.2-0.3-0.3C6.1,147.4,6.2,147.5,6.3,147.6z"/>
        <path fill="#FFFFFF" d="M4.9,146.3c-0.1-0.1-0.2-0.2-0.3-0.3C4.7,146.1,4.8,146.2,4.9,146.3z"/>
        <path fill="#FFFFFF" d="M7.1,148.2c-0.1-0.1-0.2-0.2-0.4-0.3C6.8,148,6.9,148.1,7.1,148.2z"/>
        <path fill="#FFFFFF" d="M51.8,98.3c-0.2-0.1-0.3-0.1-0.3-0.1c-0.5-0.1-0.9-0.2-1.4-0.3c0.5,0.1,0.9,0.2,1.4,0.3
          C51.5,98.2,51.6,98.3,51.8,98.3z"/>
        <path fill="#FFFFFF" d="M47.2,97.5c0.3,0,0.7,0.1,1,0.1C47.9,97.6,47.5,97.6,47.2,97.5z"/>
        <path fill="#FFFFFF" d="M51.8,98.3c0.2,0.1,0.5,0.1,0.8,0.2C52.3,98.5,52,98.4,51.8,98.3z"/>
        <path fill="#FFFFFF" d="M45.4,97.5c0.5,0,0.9,0,1.4,0C46.3,97.5,45.9,97.5,45.4,97.5z"/>
        <path fill="#FFFFFF" d="M41.5,97.8c0.6-0.1,1.1-0.2,1.7-0.2C42.7,97.6,42.1,97.7,41.5,97.8z"/>
        <path fill="#FFFFFF" d="M48.7,97.7c0.3,0,0.7,0.1,1,0.2C49.4,97.8,49,97.7,48.7,97.7z"/>
        <path fill="#FFFFFF" d="M43.6,97.5c0.6,0,1.2-0.1,1.8-0.1C44.7,97.5,44.1,97.5,43.6,97.5z"/>
        <path fill="#D3D3D3" d="M197.4,94.2c-2.4-8.8-11.3-14-20.1-11.7c0,0-0.6,0.2-1.8,0.5c-2.8-10.4-12.3-18.1-23.6-18.1
          c-7.5,0-14.1,3.4-18.6,8.7c3.2,3.7,5.8,7.9,7.9,12.4c1.6-0.2,3.3-0.3,5-0.3c16.9,0,31.8,11.4,36.2,27.8c0.1,0.5,0.2,1,0.3,1.5
          c1-0.1,2.2-0.2,3-0.4C194.6,112,199.8,103,197.4,94.2z"/>
      </g>
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
