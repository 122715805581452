import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import cep from '@smartcoop/forms/validators/cep.validator'
import cpfCnpj from '@smartcoop/forms/validators/cpfCnpj.validator'
import date from '@smartcoop/forms/validators/date.validator'
import email from '@smartcoop/forms/validators/email.validator'
import phone from '@smartcoop/forms/validators/phone.validator'
import required from '@smartcoop/forms/validators/required.validator'


const familyGroupAccess = ({ t, props }) => Yup.object().shape({
  name: flow(
    required({ t })
  )(Yup.string()),

  email: flow(
    email({ t })
  )(Yup.string()),

  cellPhone: flow(
    phone({ t }),
    required({ t })
  )(Yup.string()),

  document: flow(
    cpfCnpj({ t }),
    required({ t })
  )(Yup.string()),

  dateOfBirth: props?.userFound ? Yup.string() : flow(
    date({ t, isBirth: true })
  )(Yup.string()),

  postalCode: flow(
    cep({ t, validateIfEmpty: false })
  )(Yup.string()),

  state: flow(
  )(Yup.string()),

  city: flow(
  )(Yup.string()),

  neighborhood: flow(
  )(Yup.string()),

  street: flow(
  )(Yup.string()),

  number: flow(
  )(Yup.string())
})

export default familyGroupAccess
