import api from '../api'

export const getAddressesByOrganization = (params, { organizationId }) =>
  api.get(`/v1/orgs/${ organizationId }/related`, { params })

export const getOrganizations = (params) =>
  api.get('/v1/orgs', { params })

export const getOrganizationsByUserId = (params, { userId }) =>
  api.get(`/v1/orgs-by-user/${ userId }`, { params })

export const getOrganizationsByUser = (params) =>
  api.get('/v1/orgs-by-user', { params })

export const getSuppliers = (params) =>
  api.get('/v1/suppliers', { params })

export const getOrganization = (params, { organizationId }) =>
  api.get(`/v1/orgs/${ organizationId }`, { params })

export const createOrganization = (params) =>
  api.post('/v1/orgs', params)

export const editOrganization = (params) =>
  api.put('/v1/orgs', params)

export const deleteOrganization = (params, { organizationId }) =>
  api.delete(`/v1/orgs/${ organizationId }`, params)

export const addOrganizationFiles = (params, { organizationId }) =>
  api.post(`/v1/orgs/${ organizationId }/file`, params, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

export const changeOrganizationLogo = (params, { organizationId }) =>
  api.post(`/v1/orgs/${ organizationId }/logo`, params, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

export const deleteOrganizationLogo = ({ logoId }) =>
  api.delete(`/v1/orgs/logo/${ logoId }`)

export const updateSignature = (params, { organizationId }) =>
  api.post(`/v1/eletronic-signature/organization/${ organizationId }`, params)

export const addProductToSuppliers = (params, { organizationId }) =>
  api.post(`/v1/products/${ organizationId }/suppliers`, params)

export const getOrganizationsWithSignatures = (params, { userId }) =>
  api.get(`/v1/eletronic-signature/user/${ userId }`, params)

export const getGuestOrganizations = (params) =>
  api.get('v1/organizations/without-auth', params)
