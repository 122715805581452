import * as Yup from 'yup'

import dateRange from '@smartcoop/forms/validators/dateRange.validator'

const filterTechnicalVisitProducer = ({ t }) => Yup.object().shape({

  organizationId: Yup.string().nullable(),

  technicianId: Yup.string().nullable(),

  fieldId: Yup.string().nullable(),

  rangeDate: dateRange({ t })(Yup.object())
})

export default filterTechnicalVisitProducer
