import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'


export class GroupMemberModel extends Model {
  static table = 'group_member'

  @field('groupMemberId')
  groupMemberId: string

  @field('groupId')
  groupId: string

  @field('userId')
  userId: string

  @field('userName')
  userName: string

  @field('userCode')
  userCode: string

  @field('profiles')
  profiles: string

  @field('isAdmin')
  isAdmin: boolean

  @field('isLeft')
  isLeft: boolean

  @field('publicKey')
  publicKey: string
}
