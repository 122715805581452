import React, { useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'

import {
  checkboxChecked as checkedIcon,
  checkboxUnchecked as uncheckedIcon
} from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'
import Tooltip from '@smartcoop/web-components/Tooltip'

import {
  CheckboxTouchableContainer,
  CheckboxContainer,
  LabelContainer,
  IconContainer,
  Label
} from './styles'

const CheckboxButton = (props) => {
  const {
    checked,
    label,
    style,
    onChange,
    labelStyle,
    value,
    disabled,
    hideColor,
    tooltipTitle
  } = props

  const isChecked = useMemo(() => (checked ? checkedIcon : uncheckedIcon), [
    checked
  ])

  const toggle = useCallback(() => {
    if (!disabled) onChange(value, !checked)
  }, [disabled, onChange, value, checked])

  const checkboxColor = useMemo(() => {
    if (disabled) return colors.grey
    return colors.black
  }, [disabled])

  return (
    <Tooltip title={ tooltipTitle } style={ { ...style } }>
      <CheckboxContainer hideColor={ hideColor } checked={ checked } style={ { ...style } } >
        <CheckboxTouchableContainer onClick={ toggle }>
          <IconContainer>
            <Icon icon={ isChecked } size={ 16 } color={ checkboxColor } />
          </IconContainer>
          <LabelContainer>
            <Label style={ { color: disabled ? colors.grey : colors.text, ...labelStyle } }>{label}</Label>
          </LabelContainer>
        </CheckboxTouchableContainer>
      </CheckboxContainer>
    </Tooltip>
  )
}

CheckboxButton.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object
  ]).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  disabled: PropTypes.bool,
  hideColor: PropTypes.bool,
  tooltipTitle: PropTypes.string
}

CheckboxButton.defaultProps = {
  style: {},
  labelStyle: {},
  checked: false,
  disabled: false,
  hideColor: false,
  tooltipTitle: ''
}

export default CheckboxButton
