import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  getAccountSercuritiesMovement,
  getSecuritieMovementInitialDate
} from '@smartcoop/services/apis/smartcoopApi/resources/securitiesMovement'

import { selectCurrentAccount } from '../account/selectorAccount'
import { selectCurrentOrganization } from '../organization/selectorOrganization'
import { SecuritiesMovementActions, SecuritiesMovementTypes } from './duckSecuritiesMovement'

function* loadSecuritiesMovement({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentAccount = yield select(selectCurrentAccount)
    const currentOrganization = yield select(selectCurrentOrganization)

    const { data: { data: securitiesMovement, ...pagination } } = yield call(getAccountSercuritiesMovement, {
      ...params
    }, { accountId: currentAccount.id, organizationId: currentOrganization.id })

    yield put(SecuritiesMovementActions.loadSecuritiesMovementSuccess(
      securitiesMovement,
      () => onSuccess(pagination)
    ))
  } catch (error) {
    yield put(SecuritiesMovementActions.securitiesMovementError(error))
    yield call(onError, error)
  }
}

function* loadSecuritiesMovementSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* loadSecuritieMovementInitialDate({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentAccount = yield select(selectCurrentAccount)
    const currentOrganization = yield select(selectCurrentOrganization)

    const { data: { transactionDate } } = yield call(getSecuritieMovementInitialDate, {
      limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
      ...params
    }, { accountId: currentAccount.id, organizationId: currentOrganization.id })

    yield call(onSuccess, transactionDate)
  } catch (error) {
    yield put(SecuritiesMovementActions.securitiesMovementError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(SecuritiesMovementTypes.LOAD_SECURITIES_MOVEMENT, loadSecuritiesMovement),
  takeLatest(SecuritiesMovementTypes.LOAD_SECURITIES_MOVEMENT_SUCCESS, loadSecuritiesMovementSuccess),
  takeLatest(SecuritiesMovementTypes.LOAD_SECURITIE_MOVEMENT_INITIAL_DATE, loadSecuritieMovementInitialDate)
]
