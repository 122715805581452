import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import monthYear from '@smartcoop/forms/validators/monthYear.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerPriceData = ({ t }) => Yup.object().shape({
  userStateRegistrationId: flow(
    required({ t })
  )(Yup.string()),

  priceDate: flow(
    monthYear({ t }),
    required({ t })
  )(Yup.string()),

  price: flow(
    required({ t })
  )(Yup.string())

})

export default registerPriceData
