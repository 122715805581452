import React, { useCallback, useState, useMemo } from 'react'
import { useDispatch , useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { getUserStateRegistrations as getUserStateRegistrationsService } from '@smartcoop/services/apis/smartcoopApi/resources/stateRegistration'
import { useSnackbar } from '@smartcoop/snackbar'
import { BarterActions } from '@smartcoop/stores/barter'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import Button from '@smartcoop/web-components/Button'
import InputQuantity from '@smartcoop/web-components/InputQuantity'
import InputSelect from '@smartcoop/web-components/InputSelect'
import SignatureOrderModal from '@smartcoop/web-containers/modals/commercialization/SignatureOrderModal'

import {
  Container,
  LeftItem,
  Row,
  Text,
  RightItem
} from './styles'

const Footer = ({
  barterId,
  price,
  measureUnit,
  closeModal
}) => {
  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const [packageQuantity, setPackageQuantity] = useState(1)
  const [stateRegistrationId, setStateRegistrationId] = useState()
  const [error, setError] = useState()
  const { createDialog } = useDialog()

  const currentOrganization = useSelector(selectCurrentOrganization)

  const totalValue = useMemo(
    () => price ? `${ packageQuantity*price } ${ measureUnit }` : 0,
    [measureUnit, packageQuantity, price]
  )

  const urlParams = useMemo(
    () => ({ organizationId: currentOrganization.id }),
    [currentOrganization.id]
  )

  const onParticipate = useCallback(
    () => {
      if (!stateRegistrationId) {
        setError(t('required field'))
      } else {
        setError()
        createDialog({
          id: 'signature-order-modal',
          Component: SignatureOrderModal,
          props: {
            onSuccess: () => {
              dispatch(BarterActions.joinBarterPackage(
                { barterId, packageQuantity, stateRegistrationId },
                () => {
                  snackbar.success(t('your {this} was registered', {
                    this: t('barter'),
                    gender: 'male',
                    howMany: 1
                  }))
                },
                (er) => snackbar.error(er)
              ))
              closeModal()
            }
          }
        })
      }
    },
    [barterId, closeModal, createDialog, dispatch, packageQuantity, snackbar, stateRegistrationId, t]
  )

  return (
    <Container>
      <LeftItem>
        <Row style={ { marginBottom: 15 } }>
          <InputQuantity
            value={ packageQuantity }
            onChange={ setPackageQuantity }
            min={ 1 }
            max={ 999 }
            style={ { height: 35 } }
          />
          {Number(price) > 0 && (
            <Row style={ { alignItems: 'center', marginLeft: 5 } }>
              <I18n as={ Text } params={ { howMany: 1 } }>total value</I18n>
              <Text style={ { marginLeft: 5 } }>{totalValue}</Text>
            </Row>
          )}
        </Row>
      </LeftItem>
      <RightItem>
        <InputSelect
          value={ stateRegistrationId }
          onChange={ (value) => {setStateRegistrationId(value)} }
          label={ t('state registration') }
          urlParams={ urlParams }
          options={ getUserStateRegistrationsService }
          asyncOptionLabelField="stateRegistration"
          asyncOptionValueField="id"
          error={ error }
          detached
        />
        <Button
          id="participate"
          onClick={ onParticipate }
          color="secondary"
          style={ { flex: 'none', whiteSpace: 'nowrap', marginLeft: 10, marginBottom: 15 } }
        >
          <>
            <I18n>participate</I18n>
          </>
        </Button>
      </RightItem>
    </Container>
  )
}

Footer.propTypes = {
  barterId: PropTypes.number.isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  closeModal: PropTypes.func,
  measureUnit: PropTypes.string
}

Footer.defaultProps = {
  closeModal: () => {},
  price: '',
  measureUnit: ''
}

export default Footer
