import React, { useMemo, useCallback } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import {
  organizationRounded
} from '@smartcoop/icons'
import { momentBackDateTimeFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import RemoveAccessModal from '@smartcoop/web-containers/modals/profile/RemoveAccessModal'

import AddressCardFragment from '../AddressCardFragment'
import ChatAbsenceFragment from '../ChatAbsenceFragment'
import ChatCardFragment from '../ChatCardFragment'
import ListFragment from '../ListFragment'
import {
  Container,
  Item
} from './styles'

const UserAdditionalInfoFragment = (props) => {
  const {
    user,
    userOrganizations,
    userAbsences
  } = props

  const { createDialog } = useDialog()
  const t = useT()

  const address = useMemo(
    () => (
      {
        state: user.state ?? null,
        city: user.city ?? null,
        district: user.district ?? null,
        street: user.street ?? null,
        number: user.number ?? null,
        postalCode: user.postalCode ?? null
      }
    ), [user]
  )

  const openRemoveAccessModal = useCallback((userOrganization) => {
    createDialog({
      id: 'remove-access',
      Component: RemoveAccessModal,
      props: {
        userOrganization,
        organizations: userOrganizations
      }
    })
  }, [createDialog, userOrganizations])

  return (
    <Container>
      <Item>
        <AddressCardFragment
          address={ address }
        />
      </Item>
      <Item>
        <ListFragment
          listId='id'
          listKey="companyName"
          title={ t('associated organizations') }
          icon={ organizationRounded }
          list={ uniqBy(map(userOrganizations, item => ({ ...item, customName: item?.plan ? `${ t('plan') } ${ item?.plan?.description } - ${ t('valid until') } ${ moment(item?.expirationDate, momentBackDateTimeFormat).format(momentFriendlyDateFormat) }` : '' })), 'id') }
          openRemoveAccessModal={ openRemoveAccessModal }
        />
      </Item>
      <Item>
        <ChatCardFragment
          user={ user }
        />
      </Item>
      <Item>
        <ChatAbsenceFragment
          absences={ userAbsences }
        />
      </Item>
    </Container>
  )
}

UserAdditionalInfoFragment.propTypes = {
  user: PropTypes.object.isRequired,
  userOrganizations: PropTypes.array.isRequired,
  userAbsences: PropTypes.array.isRequired
}

export default UserAdditionalInfoFragment
