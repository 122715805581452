import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'

export default ({ t, isNotText }) => YupInstance => {
  let aux = YupInstance

  if (!isNotText) {
    aux = aux.trim()
  } else {
    aux = aux.test(
      'notEmpty',
      t('this field is required'),
      (value) => (
        !isEmpty(value)
        || isNumber(value)
        || isBoolean(value)
      )
    )
  }

  return aux.required(t('this field is required'))
}
