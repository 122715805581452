import React, { useCallback, forwardRef, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import completeSchemaManagement from '@smartcoop/forms/schemas/management/completeManagement.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { FieldActions } from '@smartcoop/stores/field'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import CalendarPicker from '@smartcoop/web-components/CalendarPicker'
import Form from '@smartcoop/web-components/Form'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container, TextDate } from './styles'

const CompleteManagementForm = forwardRef((props, formRef) => {
  const {
    style,
    cropManagementData,
    closeCropManagement,
    handleClose,
    startLoading,
    onSuccess
  } = props
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()
  const [valueDate, setValueDate] = useState(moment().isAfter(moment(cropManagementData.predictedDate)) ? cropManagementData.predictedDate : '')
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()

  const isDisabled = useMemo(
    () => (!valueDate), [valueDate]
  )

  const handleCropManagementComplete = useCallback(
    () => {
      if(cropManagementData.operation.concludeCultivation) {
        createDialog({
          id: 'confirm-delete-cropManagement-growinwewegSeason',
          Component: ConfirmModal,
          props: {
            onConfirm: () => {
              startLoading(true)
              const realizationDate = moment(valueDate).format(momentBackDateFormat) || moment()
              dispatch(FieldActions.saveOfflineCropManagement(
                {
                  ...cropManagementData,
                  items: cropManagementData?.cropManagementItem?.items,
                  cropManagementId: cropManagementData?.id,
                  bulkId: null,
                  operationSlug: cropManagementData.operation.slug,
                  realizationDate
                },
                () => {
                  onSuccess()
                  snackbar.success(
                    t('its management has been completed')
                  )
                  closeCropManagement()
                  handleClose()
                  startLoading(false)
                },
                () => startLoading(false),
                true
              ))
            },
            textWarning: t('are you sure you want to complete your {this}?', {
              howMany: 1,
              this: t('cropManagement', { howMany: 1 })
            })
          }
        })

      }

      if(!cropManagementData.operation.concludeCultivation) {
        createDialog({
          id: 'confirm-complete-cropManagement',
          Component: ConfirmModal,
          props: {
            onConfirm: () => {
              startLoading(true)
              const realizationDate = moment(valueDate).format(momentBackDateFormat) || moment()
              dispatch(FieldActions.saveOfflineCropManagement(
                {
                  ...cropManagementData,
                  cropManagementId: cropManagementData?.id,
                  items: cropManagementData?.cropManagementItem?.items,
                  bulkId: null,
                  operationSlug: cropManagementData.operation.slug,
                  realizationDate
                },
                () => {
                  onSuccess()
                  snackbar.success(
                    t('its management has been completed')
                  )
                  closeCropManagement()
                  handleClose()
                  startLoading(false)
                },
                () => startLoading(false),
                true
              ))
            },
            textWarning: t('are you sure you want to complete your {this}?', {
              howMany: 1,
              this: t('cropManagement', { howMany: 1 })
            })
          }
        })
      }
    },
    [cropManagementData, createDialog, t, startLoading, valueDate, dispatch, snackbar, onSuccess, closeCropManagement, handleClose]
  )

  return (
    <Container>
      <Form
        style={ { ...style } }
        ref={ formRef }
        schemaConstructor={ completeSchemaManagement }
        onSubmit={ handleCropManagementComplete }
      >
        <TextDate>
          <I18n>what was the date of realization?</I18n>
        </TextDate>
        <CalendarPicker
          value={ valueDate }
          onChange={ setValueDate }
          withoutInput
        />

        <ButtonsContainer style={ { paddingTop: 20 } }>
          <Button
            id="cancel-crop-management-form"
            onClick={ handleClose }
            style={ { flex: 1 } }
            variant="outlined"
          >
            <I18n>cancel</I18n>
          </Button>

          <div style={ { width: '10%' } } />

          <Button
            id="submit-crop-management-form"
            onClick={ () => formRef.current.submit() }
            style={ { flex: 1 } }
            disabled={ isDisabled }
          >
            <I18n>complete</I18n>
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  )
})

CompleteManagementForm.propTypes = {
  style: PropTypes.object,
  cropManagementData: PropTypes.object,
  closeCropManagement: PropTypes.func,
  onSuccess: PropTypes.func,
  handleClose: PropTypes.func,
  startLoading: PropTypes.func
}

CompleteManagementForm.defaultProps = {
  style: {},
  cropManagementData: {},
  closeCropManagement: () => {},
  onSuccess: () => {},
  handleClose: () => {},
  startLoading: () => {}
}

export default CompleteManagementForm
