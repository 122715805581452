import React from 'react'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import map from 'lodash/map'

import { useT } from '@smartcoop/i18n'
import { comment, cooperate, publicType } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import { momentBackDateTimeFormat, momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import Avatar from '@smartcoop/web-components/Avatar'
import Badge from '@smartcoop/web-components/Badge'
import Icon from '@smartcoop/web-components/Icon'
import ReadMore from '@smartcoop/web-components/ReadMore'

import {
  Body,
  Col,
  ColFooter1,
  Container,
  FeedButtonCounters,
  FlexDiv,
  Footer,
  Header,
  Identifier,
  Name,
  Privacy,
  Text,
  Timer,
  Type,
  CustomIconButton } from './styles'

const Feed = (props) =>  {
  const { style, feed: { interestAreas, createdAt, text, user, id, totalComments }, length, styleBadge, commentClick } = props
  const t = useT()
  const history = useHistory()
  const iconCooperate = false

  return (
    <Container style={ style } >
      <Header>
        <Identifier>
          <Avatar alt={ user.name } src={ user.image } />
          <Col>
            <Name>{user.name}</Name>
            <Privacy>
              <Icon icon={ publicType } size={ 14 } color={ colors.darkGrey } />
              <Type>{t('public')}</Type>
            </Privacy>
          </Col>
        </Identifier>
        <Timer>{moment(createdAt, momentBackDateTimeFormat).format(momentFriendlyDateTimeFormat)}</Timer>
      </Header>
      <Body>
        <Text>
          <ReadMore length={ length }>
            { text }
          </ReadMore>
        </Text>
      </Body>
      <Footer>
        <ColFooter1 style={ { marginRight: '0px' } }>
          <div style={ { display: 'flex' } }>
            {
              map(interestAreas, (interestArea) => (
                <Badge
                  key={ interestArea.id }
                  backgroundColorBadge={ interestArea.color }
                  colorBadge={ interestArea.color }
                  style={ styleBadge }
                >
                  {interestArea.name}
                </Badge>
              ))
            }
          </div>

          <div style={ { display: 'flex' } }>
            { iconCooperate && <CustomIconButton tooltip={ t('social cooperates') } style={ { marginRight: '14px' } } marginHorizontal={ false } >
              <FlexDiv>
                <Icon icon={ cooperate } fill={ false ? colors.green : colors.darkGrey }/>
                <FeedButtonCounters style={ { color: false ? colors.green : colors.darkGrey } }>0</FeedButtonCounters>
              </FlexDiv>
            </CustomIconButton>}
            <CustomIconButton tooltip={ t('social comments') } onClick={ () => {
              commentClick && history.push(`/social/comments/${ id }`, { feed: props.feed } )} } marginHorizontal={ false }
            >
              <FlexDiv>
                <Icon icon={ comment } />
                <FeedButtonCounters>{totalComments}</FeedButtonCounters>
              </FlexDiv>
            </CustomIconButton>
          </div>
        </ColFooter1>
      </Footer>
    </Container>
  )
}

Feed.propTypes = {
  style: PropTypes.object,
  feed: PropTypes.shape({
    interestAreas: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      categoryId: PropTypes.string,
      category: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      }),
      color: PropTypes.string
    })),
    id: PropTypes.string,
    text: PropTypes.string,
    userId: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    totalComments: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      image: PropTypes.string
    })
  }).isRequired,
  length: PropTypes.number,
  styleBadge: PropTypes.object,
  commentClick: PropTypes.bool
}

Feed.defaultProps = {
  style: {},
  length: 200,
  styleBadge: {
    marginRight: '10px'
  },
  commentClick: false
}

export default Feed
