import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import number from '@smartcoop/forms/validators/number.validator'
import required from '@smartcoop/forms/validators/required.validator'

const parametersRegistration = ({ t }) => Yup.object().shape({
  dryPeriod: flow(
    required({ t }),
    number({ t })
  )(Yup.string()),

  heiferAge: flow(
    required({ t }),
    number({ t })
  )(Yup.string()),

  voluntaryWaitingPeriod: flow(
    required({ t }),
    number({ t })
  )(Yup.string()),

  averageDelGoal: flow(
    required({ t }),
    number({ t })
  )(Yup.string()),

  initialTouch: flow(
    required({ t }),
    number({ t })
  )(Yup.string()),

  reconfirmationTouch: flow(
    required({ t }),
    number({ t })
  )(Yup.string()),

  dryingConfirmation: flow(
    required({ t }),
    number({ t })
  )(Yup.string()),

  preBirth: flow(
    required({ t }),
    number({ t })
  )(Yup.string()),

  animalGestation: flow(
    required({ t }),
    number({ t })
  )(Yup.string())
})

export default parametersRegistration
