import React, { useMemo, useCallback, useState } from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import { organizationRounded, camera } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'
import { CloseRoundedIconButton } from '@smartcoop/web-components/IconButton'
import InputFile from '@smartcoop/web-components/InputFile'

import { Container, IconButtonContainer, Image } from './styles'


const OrganizationLogoInput = (props) => {
  const {
    src,
    onChange,
    style,
    hideUpload,
    onClose
  } = props
  const [selectedFile, setSelectedFile] = useState()

  const source = useMemo(
    () => selectedFile || src, [selectedFile, src]
  )

  const renderImage = useMemo(
    () => (isEmpty(source) ?
      <Icon icon={ organizationRounded } color={ colors?.black } size={ 120 } /> :
      <Image src={ source }/>)
    ,[source]
  )

  const handleChangeFile = useCallback(
    async (event) => {
      const { files } = event.target
      const file = files[0]
      setSelectedFile(URL.createObjectURL(file))
      onChange(file)
    },
    [onChange]
  )

  return (
    <Container style={ style }>
      {onClose && (
        <div style={ { width: '100%', display: 'flex', justifyContent: 'flex-end' } }>
          <CloseRoundedIconButton
            size="small"
            onClick={ () => {
              setSelectedFile(null)
              onClose()
            } }
            iconBorder={ colors.grey }
            iconColor={ colors.darkGrey }
          />
        </div>
      )}
      {renderImage}
      {!hideUpload && (
        <IconButtonContainer>
          <InputFile
            idInput="organization-logo"
            onChange={ (event) => handleChangeFile(event) }
            inputProps={ {
              accept: 'image/png,image/jpeg'
            } }
            buttonProps={ {
              id: 'add-org-logo',
              variant: 'outlined',
              style:{
                minWidth: 0,
                width: 50,
                height: 50,
                border: 0
              }
            } }
          >
            <>
              <Icon
                icon={ camera }
                color={ colors.white }
              />
            </>
          </InputFile>
        </IconButtonContainer>
      )}
    </Container>
  )
}

OrganizationLogoInput.propTypes = {
  style: PropTypes.object,
  src: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  hideUpload: PropTypes.bool
}

OrganizationLogoInput.defaultProps = {
  style: {},
  src: '',
  onChange: () => {},
  onClose: null,
  hideUpload: false
}

export default OrganizationLogoInput
