import styled from 'styled-components'

import fonts from '@smartcoop/styles/fonts'
import Card from '@smartcoop/web-components/Card'
import { Item } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

export const CardStyled = styled(Card).attrs({
  cardStyle: {
    width: '100%',
    padding: '15px 0'
  },
  headerStyle: {
    padding: '0 20px 5px'
  },
  headerTitleProps: {
    style: {
      fontWeight: fonts.fontWeight.bold,
      fontSize: 18
    }
  }
})`
  width: 100%;
  justify-content: flex-start;
  margin: 0;
`

export const ContainerDetails = styled.div`
  width: 100%;
  font-size: 16px;
`

export const OrderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px 20px;
`
export const PaymentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
  padding: 0 20px;
`

export const OrderSubTitleItem = styled(Item)`
  font-size: 16px;
  font-weight: 600;
  padding: 0;
`

export const ItemStatus = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-right: 50px;
  padding-bottom: 13px;
`

export const OrderItem = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  padding-bottom: 13px;
  padding-right: 50px;
`

export const PaymentItem = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: column;
`

export const OrderItemText = styled(Item)`
  padding: 0;
`
