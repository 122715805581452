import React, { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { OperationsActions } from '@smartcoop/stores/operations'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'
import AcceptAccreditmentForm from '@smartcoop/web-containers/forms/operation/AcceptAccreditmentForm'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import RefuseModal from '../../RefuseModal/RefuseModal'
import useStyles, { Row, Content } from './styles'

const AcceptAccreditmentModal = ({ id, open, handleClose, onSubmit, requestStatus, accreditmentId, loadAccrediteds, defaultValues }) => {
  const classes = useStyles()
  const acceptAccreditmentFormRef = useRef(null)
  const snackbar = useSnackbar()
  const t = useT()

  const [loading, setLoading] = useState(false)
  const { createDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])

  const onRefuse = useCallback(
    () => {
      createDialog({
        id: 'confirm-delete-accreditment',
        Component: RefuseModal,
        props: {
          onConfirm: (reason) => {
            dispatch(
              OperationsActions.editAccreditment(
                accreditmentId,
                { motive: reason, requestStatus: 'RECUSADO' },
                () => {
                  snackbar.success(
                    t('accreditment removed')
                  )
                  loadAccrediteds()
                }
              )
            )
          }
        }
      })
    },
    [accreditmentId, createDialog, dispatch, loadAccrediteds, snackbar, t]
  )

  const clearForm = useCallback(
    () => {
      acceptAccreditmentFormRef.current.reset()
      onRefuse()
    },
    [onRefuse]
  )

  const onSuccess = useCallback(
    () => {
      setLoading(false)
      onSubmit()
      loadAccrediteds()
      handleClose()
    },
    [handleClose, loadAccrediteds, onSubmit]
  )

  const onError = useCallback(
    () => {
      setLoading(false)
    },
    []
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row>
          <I18n>{defaultValues?.expirationDate ? 'edit accreditment' : 'accept accreditment'}</I18n>
        </Row>
      }
      escape
      loading={ loading }
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 350 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      disableFullScreen
    >
      <Content>
        <AcceptAccreditmentForm
          ref={ acceptAccreditmentFormRef }
          requestStatus={ requestStatus }
          onSuccess={ onSuccess }
          onError={ onError }
          setLoading={ setLoading }
          withoutSubmitButton
          accreditmentId={ accreditmentId }
          defaultValues={ defaultValues }
        />
        <ButtonsContainer style={ { marginTop: 10 } }>
          {!defaultValues?.expirationDate && (
            <Button
              id="cancel"
              onClick={ clearForm }
              variant="outlined"
              style={ { marginRight: 7 } }
              disabled={ loading || requestStatus === 'RECUSADO' }
            >
              <I18n>refuse</I18n>
            </Button>
          )}
          <Button
            id="save"
            onClick={ () => acceptAccreditmentFormRef.current.submit() }
            color="black"
            style={ { marginLeft: 7 } }
            disabled={ loading }
          >
            <I18n>{defaultValues?.expirationDate ? 'save' : 'accept'}</I18n>
          </Button>
        </ButtonsContainer>
      </Content>
    </Modal>
  )}

AcceptAccreditmentModal.propTypes = {
  id: PropTypes.string.isRequired,
  requestStatus: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  loadAccrediteds: PropTypes.func.isRequired,
  accreditmentId: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func
}

AcceptAccreditmentModal.defaultProps = {
  onSubmit: () => {},
  defaultValues: {}
}

export default AcceptAccreditmentModal
