/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
import { Database, Q } from '@nozbe/watermelondb'
import moment from 'moment'

import { decrypt, decryptToGroup, encrypt, encryptToGroup } from '../utils/cryptography'
import { generateUuid } from '../utils/uuidGenerator'
import { conversationFileService } from './conversationFileService'
import { conversationService } from './conversationService'
import { groupMemberService } from './groupMemberService'
import { groupService } from './groupService'
import { ROUTES } from './routes'
import { syncFileService } from './syncFileService'
import { syncPendingService } from './syncPendingService'
import { userService } from './userService'

const MESSAGE_FIELDS = [
  'conversationId',
  'isDocument',
  'isGeolocation',
  'nickname',
  'notification',
  'whoSend',
  'forward',
  'fileAction',
  'fileType'
]


export function messageService(database: Database) {
  async function decryptMessage(messageItem, privateKey, groupKeys) {
    if (messageItem.deletedAt || messageItem.isDocument) {
      return ''
    }

    if (messageItem.isGroup || messageItem.isBroadcast) {
      // System messages
      if (!messageItem.whoSend) {
        return messageItem.message
      }

      let groupKey = groupKeys[messageItem.conversationId]

      if (!groupKey) {
        const response = await groupService(database).findGroupKeyById(messageItem.conversationId)
        groupKeys[messageItem.conversationId] = response
        groupKey = response
      }

      return decryptToGroup(messageItem.message, groupKey)
    }

    return decrypt(messageItem.message, privateKey)
  }

  async function createOrUpdate(messages = []) {
    const privateKey = await userService(database).getPrivateKey()
    const groupKeys = {}

    await database.write(async () => {
      const promises = messages.map(messageItem => async () => {
        try {
          const register = await database.get('message')
            .query(Q.where('messageId', messageItem.id))
            .fetch()

          if (messageItem.deleted) {
            register.map((item) => item.prepareDestroyPermanently())
            await database.batch(register)
            return
          }

          const decryptedMessage = await decryptMessage(messageItem, privateKey, groupKeys)
          if (register[0]) {
            register[0].update(item => {
              MESSAGE_FIELDS.forEach(field => { item[field] = messageItem[field] })
              item.message = decryptedMessage
              item.sendAt = Number(moment(messageItem.createdAt).format('X'))
              item.delAt = messageItem.deletedAt ? Number(moment(messageItem.deletedAt).format('X')) : null

              if (messageItem.status !== 'unknown') {
                item.status = messageItem.status
              }
            })
            return
          }

          await database.get('message').create(item => {
            item.messageId = messageItem.id
            MESSAGE_FIELDS.forEach(field => { item[field] = messageItem[field] })
            item.message = decryptedMessage
            item.sendAt = Number(moment(messageItem.createdAt).format('X'))
            item.delAt = messageItem.deletedAt ? Number(moment(messageItem.deletedAt).format('X')) : null
            item.status = messageItem.status
            item.documentFormat = messageItem.fileType
            item.documentName = messageItem.isDocument ? messageItem.message : undefined
            item.relationalId = messageItem.conversationId
          })
        } catch(error) {
          console.log('[Sync] Error on save Message >', error)
        }
      })

      await Promise.all(promises.map(fn => fn()))
    })
  }

  async function send(message, forward = false, isGeolocation = false) {
    const relationalId = generateUuid(message.receiverId, message.senderId)

    await database.write(async () => {
      const register = await database.get('message').create(item => {
        item.message = message.message
        item.whoSend = message.senderId
        item.status = 'pending'
        item.relationalId = relationalId
        item.isDocument = message.isDocument || false
        item.isGeolocation = isGeolocation || false
        item.sendAt = Number(moment().format('X'))
        item.forward = forward
      })

      await conversationService(database).updateLastMessage(relationalId, message.message, register.id)

      const senderMessage = await encrypt(message.message, message.senderPublicKey)
      const receiverMessage = await encrypt(message.message, message.receiverPublicKey)

      const messageDTO = {
        forward,
        isGeolocation: isGeolocation || false,
        message: [
          {
            code: message.receiverCode,
            id: message.receiverId,
            message: receiverMessage
          },
          {
            code: message.senderCode,
            id: message.senderId,
            message: senderMessage
          }
        ]
      }

      await syncPendingService(database).create({
        type: 'POST',
        body: JSON.stringify(messageDTO),
        params: {},
        url: `${ ROUTES.SEND_MESSAGE }/${ relationalId }`,
        localMessageId: register.id,
        registerType: 'message'
      })
    })
  }

  async function sendMessageInGroup(
    message,
    conversation,
    options = {
      isGeolocation: false,
      isForward: false,
      isDocument: false,
      isBroadcast: false
    }
  ) {
    await database.write(async () => {
      const register = await database.get('message').create(item => {
        item.message = message.message
        item.whoSend = message.senderId
        item.status = 'pending'
        item.relationalId = conversation.conversationId
        item.conversationId = conversation.conversationId
        item.isDocument = options.isDocument || false
        item.isGeolocation = options.isGeolocation || false
        item.sendAt = Number(moment().format('X'))
        item.forward = options.isForward
      })

      await conversationService(database).updateLastMessage(
        conversation.conversationId,
        message.message,
        register.id
      )

      const encryptedMessage = await encryptToGroup(message.message, conversation.publicKey)

      const customKey = options.isBroadcast ? 'broadcastKey' : 'groupKey'

      const messageDTO = {
        message: [{ message: encryptedMessage }],
        [customKey]: conversation.publicKey,
        isGeolocation: options.isGeolocation,
        isForwarding: options.isForward,
        geolocation: options.isGeolocation ? message.message : null
      }

      await syncPendingService(database).create({
        type: 'POST',
        body: JSON.stringify(messageDTO),
        params: {},
        url: `${ ROUTES.SEND_MESSAGE }/${ conversation.conversationId }`,
        localMessageId: register.id,
        registerType: 'message'
      })
    })
  }

  async function deleteMessages(messages, deleteForAll) {
    await database.write(async () => {
      await syncPendingService(database).create({
        type: 'DELETE',
        body: JSON.stringify({
          ids: messages.filter(item => item.messageId).map(item => item.messageId),
          allUsers: deleteForAll
        }),
        params: {},
        url: `${ ROUTES.DELETE_MESSAGE }`,
        localMessageId: null,
        registerType: 'delete-message'
      })

      const promisses = messages.map(item => async () => {
        const dbRegister = await database.get('message').find(item?.id)

        if (deleteForAll) {
          await dbRegister.update(register => {
            register.delAt = Number(moment().format('X'))
          })
        } else {
          await dbRegister.markAsDeleted()
        }
      })

      await Promise.all(promisses.map(fn => fn()))
    })
  }

  async function updateSync(id, response) {
    const message = await database.get('message').find(id)

    if (message) {
      await database.write(async () => {
        await message.update(item => {
          item.messageId = response?.id
          item.conversationId = response?.conversationId
          item.status = 'sent'
          item.syncId = null
        })
      })
    }

    await conversationService(database).updateInvalidCode(
      response?.conversationId,
      response?.invalidCode || false
    )
  }

  async function updateSyncError(id, syncId) {
    const message = await database.get('message').find(id)

    if (message) {
      await database.write(async () => {
        await message.update(item => {
          item.syncId = syncId
        })
      })
    }
  }

  async function updateReadMessages(relationalId) {
    await database.write(async () => {
      await syncPendingService(database).create({
        type: 'PATCH',
        body: {},
        params: {},
        url: `${ ROUTES.UPDATE_VISUALIZED }/${ relationalId }`,
        registerType: 'update_visualized'
      })
    })
  }

  async function sendFile(
    message,
    { uri = null, format = 'mp3', documentType = 'audio', forward = false, isGroup = false }
  ) {
    const relationalId = isGroup ? message.conversationId : generateUuid(message.receiverId, message.senderId)
    const documentName = `smartcoop/${ documentType }-${ moment().format('X') }.${ format }`

    await database.write(async () => {
      const register = await database.get('message').create(item => {
        item.message = message.message
        item.whoSend = message.senderId
        item.status = 'pending'
        item.relationalId = relationalId
        item.conversationId = relationalId
        item.sendAt = Number(moment().format('X'))
        item.forward = forward
        item.isDocument = true
        item.documentFormat = documentType
        item.documentName = documentName
      })

      let endpoint = documentType === 'audio' ? 'record' : 'attach'

      if (forward) {
        endpoint = 'forward'
      }

      await conversationFileService(database).create({
        conversationId: relationalId,
        documentName,
        source: message.message,
        registerId: register.id
      })

      await syncFileService(database).create({
        uri,
        type: 'POST',
        source: message.message,
        sourceExtension: format,
        params: {},
        documentType,
        url: `${ ROUTES.SEND_FILE }/${ relationalId }/${ documentType }/${ endpoint }`,
        localMessageId: register.id
      })
    })
  }

  async function clearMessagesHistory(conversationId) {
    await database.write(async () => {
      const messages = await database.get('message')
        .query(Q.where('conversationId', conversationId))
        .fetch()

      const deleted = messages.map(message => message.prepareDestroyPermanently())

      database.batch(...deleted)

      await syncPendingService(database).create({
        type: 'DELETE',
        body: {},
        params: {},
        url: `${ ROUTES.CLEAR_CHAT_HISTORY }/${ conversationId }`,
        registerType: 'clear_chat_history'
      })
    })
  }

  async function sendMessageInBroadcast(
    message,
    conversationId,
    props = { isGeolocation: false }
  ) {
    const members = await groupMemberService(database).getMembersToSendMessage(conversationId)
    const localUser = await userService(database).getUserData()

    const promises = members.map(member => async () => {
      const relationalId = generateUuid(localUser?.userId, member.userId)
      const conversation = await conversationService(database).findByRelationalId(relationalId)

      if (!conversation) {
        await conversationService(database).startConversationWithBroadcastMember(
          relationalId,
          member,
          localUser?.userId,
          message
        )
      }

      await send({
        message,
        isDocument: false,
        receiverId: member.userId,
        senderId: localUser.userId,
        receiverCode: member.userCode,
        senderCode: localUser.userCode,
        receiverPublicKey: member.publicKey,
        senderPublicKey: localUser.publicKey
      }, false, props.isGeolocation)
    })

    await Promise.all(promises.map(fn => fn()))
  }

  async function sendFileInBroadcast(file, conversationId) {
    const members = await groupMemberService(database).getMembersToSendMessage(conversationId)
    const localUser = await userService(database).getUserData()

    const promises = members.map(member => async () => {
      try {
        const relationalId = generateUuid(localUser?.userId, member?.userId)
        const conversation = await conversationService(database).findByRelationalId(relationalId)

        if (!conversation) {
          await conversationService(database).startConversationWithBroadcastMember(
            relationalId,
            member,
            localUser?.userId,
            'file'
          )
        }

        await sendFile({
          conversationId,
          message: file.base64,
          receiverId: member.userId,
          senderId: localUser.userId
        }, {
          format: file.extension,
          documentType: file.type,
          uri: file.uri
        })
      } catch(error) {
        console.log('Deu ruim ao enviar arquivo...', error)
      }
    })

    await Promise.all(promises.map(fn => fn()))
  }

  function observeMessagesByConversationId(id, relationalId) {
    const message = database.collections.get('message')

    return message.query(
      Q.or(
        Q.where('conversationId', id),
        Q.where('relationalId', relationalId)
      )
    ).observeWithColumns(['updated_at', 'delAt', 'syncId'])
  }

  function observeMessagesCountByRelationalId(relationalId, userId) {
    const message = database.collections.get('message')

    return message.query(
      Q.or(
        Q.where('whoSend', Q.notEq(userId)),
        Q.where('relationalId', relationalId)
      )
    ).observeCount()
  }

  function observeMessagesByFilter(searchParams, relationalId = null, conversationId = null) {
    const message = database.collections.get('message')

    if (searchParams.length < 3) {
      return message.query(
        Q.where('relationalId', null)
      ).observe()
    }

    if (conversationId && conversationId !== relationalId) {
      return message.query(
        Q.where('message', Q.like(`%${ Q.sanitizeLikeString(searchParams) }%`)),
        Q.or(
          Q.where('delAt', 0),
          Q.where('delAt', Q.eq(null))
        ),
        Q.where('isDocument', Q.notEq(true)),
        Q.or(
          Q.where('conversationId', conversationId),
          Q.where('relationalId', relationalId)
        )
      ).observe()
    }

    if (relationalId) {
      return message.query(
        Q.where('message', Q.like(`%${ Q.sanitizeLikeString(searchParams) }%`)),
        Q.or(
          Q.where('delAt', 0),
          Q.where('delAt', Q.eq(null))
        ),
        Q.where('isDocument', Q.notEq(true)),
        Q.or(
          Q.where('conversationId', relationalId),
          Q.where('relationalId', relationalId)
        )
      ).observe()
    }

    return message.query(
      Q.where('message', Q.like(`%${ Q.sanitizeLikeString(searchParams) }%`)),
      Q.where('isDocument', Q.notEq(true)),
      Q.or(
        Q.where('delAt', 0),
        Q.where('delAt', Q.eq(null))
      )
    ).observe()
  }

  function observeLastMessage(messageId) {
    const message = database.collections.get('message')

    return message.query(
      Q.or(
        Q.where('messageId', messageId),
        Q.where('id', messageId)
      )
    ).observeWithColumns(['status', 'delAt'])
  }

  return {
    send,
    deleteMessages,
    updateSync,
    updateSyncError,
    createOrUpdate,
    sendFile,
    sendMessageInGroup,
    updateReadMessages,
    clearMessagesHistory,
    sendMessageInBroadcast,
    sendFileInBroadcast,
    observeLastMessage,
    observeMessagesByFilter,
    observeMessagesByConversationId,
    observeMessagesCountByRelationalId
  }
}
