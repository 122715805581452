import React, { useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import sumBy from 'lodash/sumBy'
import toNumber from 'lodash/toNumber'

import { useT } from '@smartcoop/i18n'
import { formatCurrencyIntl } from '@smartcoop/utils/formatters'
import DataTable from '@smartcoop/web-components/DataTable'
import SecuritiesMovement from '@smartcoop/web-components/SecuritiesMovement'

import { Container, TotalRow } from './styles'

const SecuritiesMovementListFragment = (props) => {
  const {
    service,
    listType,
    ...rest
  } = props

  const t = useT()

  const [totalValue, setTotalValue] = useState(0)

  const columns = useMemo(
    () => [
      {
        title: '',
        render: (row) => <SecuritiesMovement { ...row } positive={ toNumber(row.value) > 0 } />
      }
    ],
    []
  )

  return(
    <Container>
      {totalValue ? (
        <TotalRow style={ {
          fontSize: 16,
          color: '#595359',
          padding: 8,
          backgroundColor: '#f9f8f6',
          width: '100%',
          justifyContent: 'flex-end'
        } }
        >
          {listType === 'payable' ? t('titles to pay') : t('titles to receive')} {t('of the period')}:
          <span style={ { marginLeft: 10, marginRight: 10, color: totalValue < 0 ? 'red' : 'green', fontSize: 16 } }>
            {formatCurrencyIntl(totalValue)}
          </span>
        </TotalRow>
      ) : null}
      <DataTable
        data={ service }
        columns={ columns }
        { ...rest }
        options={ {
          header: false
        } }
        noShadow
        onDataLoad={ (data) => {
          setTotalValue(sumBy(data, 'value'))
        } }
        pageSize={ 1000 }
        id="securities-movement-list-table"
        hideUpdatedAt
        hideLastRequestDate
      />
    </Container>
  )
}

SecuritiesMovementListFragment.propTypes = {
  service: PropTypes.func.isRequired,
  setLastUpdatedAt: PropTypes.func.isRequired,
  listType: PropTypes.string.isRequired
}

export default SecuritiesMovementListFragment
