import api from '../api'


export const getBucketFile = async ({ bucketId, fileKey }, accessToken = null) => api.get(`/v1/uploads/download-file/bucket/${ bucketId }/file/${ fileKey }`, accessToken ? {
  headers: {
    'authorization': accessToken
  }
} : {})

export const getBucketBinaryFile = async ({ bucketId, fileKey }) => api.get(`/v1/uploads/document/${ bucketId }/${ fileKey }`, { responseType:'blob' })
