import React, { useRef, useMemo, useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'

import filter from 'lodash/filter'
import flatMap from 'lodash/flatMap'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { arrowLeft } from '@smartcoop/icons'
import { getMonthlyDataHistory } from '@smartcoop/services/apis/smartcoopApi/resources/dairyFarm'
import { getProducts } from '@smartcoop/services/apis/smartcoopApi/resources/product'
import { getProductGroups } from '@smartcoop/services/apis/smartcoopApi/resources/productGroup'
import { useSnackbar } from '@smartcoop/snackbar'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import * as colors from '@smartcoop/styles/colors'
import { momentBackDateFormat, momentFriendlyMonthYearFormat } from '@smartcoop/utils/dates'
import { formatNumber } from '@smartcoop/utils/formatters'
import Badge from '@smartcoop/web-components/Badge'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import Tooltip from '@smartcoop/web-components/Tooltip'
import RegisterMonthlyDataHistoryModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterMonthlyDataHistoryModal'

import { Top, Container, Row, Title, RightContainer } from './styles'

const ListProductionDataScreen = () => {
  const dispatch = useCallback(useDispatch(), [])
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()
  const tableRef = useRef(null)
  const history = useHistory()
  const t = useT()

  const currentProperty = useSelector(selectCurrentProperty)
  const userWrite = useSelector(selectUserCanWrite)

  const [productsOptions, setProductsOptions] = useState([])

  const loadProducts = useCallback(async () => {
    const { data: { data: productGroups } } = await getProductGroups({ limit: 9999 })
    const func = map(filter(productGroups, group => group?.slug === 'alimentos-volumosos' || group?.slug === 'racoes-e-concentrados'), async group => {
      const { data: { data } } = await getProducts({ digitalization: true, limit: 99999, productGroupId: group?.id })
      return data
    })


    Promise.all(func).then((response) => {
      setProductsOptions(map(flatMap(response, item => item), item => ({ label: item?.description, value: item?.id, item })))
    })
  },[])

  useEffect(() => {
    loadProducts()
  }, [loadProducts])

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )

  const registerPriceData = useCallback(
    (_, data) => {
      createDialog({
        id: 'register-price-data',
        Component: RegisterMonthlyDataHistoryModal,
        props: {
          defaultValues: data,
          handleSuccess: reloadDataTable
        }
      })
    },
    [createDialog, reloadDataTable]
  )

  const handleDeleteClick = useCallback(
    (_, data) =>{
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(DairyFarmActions.deleteMonthlyDataHistory(
              data.id,
              () => {
                reloadDataTable()
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 2,
                    gender: 'male',
                    this: t('production data')
                  })
                )
              },
              () => {
                reloadDataTable()
              }
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 2,
            gender: 'male',
            this: t('production data')
          })
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, snackbar, t]
  )

  const columns = useMemo(
    () => [
      {
        title: `${ t('month', { howMany: 1 }) }/${ t('year', { howMany: 1 }) }`,
        field: 'date',
        render: (row) => moment(row.date, momentBackDateFormat).format(momentFriendlyMonthYearFormat)
      },
      {
        title: `${ t('feeding') }`,
        field: 'rationData',
        render: (row) =>  {
          const title = uniqBy(filter(productsOptions, product => includes(map(row?.rationData, item => item?.productId), product?.value)), 'value')
          return (
            <div style={ { display: 'flex', justifyContent: 'center' } }>
              <Tooltip title={ map(title, item => item?.item?.description)?.join(', ') }>
                <Badge
                  backgroundColorBadge={ isEmpty(title) ? colors.red : colors.blue }
                  colorBadge={ isEmpty(title) ? colors.red : colors.blue }
                  style={ { width: '20%' } }
                >
                  {row?.rationData?.length || '0' }
                </Badge>
              </Tooltip>
            </div>
          )
        },
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' }
      },
      {
        title: t('calves milk (Lts)'),
        field: 'monthlyMilkForCalves',
        render: (row) => formatNumber(row.monthlyMilkForCalves),
        headerStyle: { textAlign: 'right' },
        cellStyle: { textAlign: 'right' }
      },
      {
        title: t('discarded/consumed milk (Lts)'),
        field: 'monthlyDiscardedMilk',
        render: (row) => formatNumber(row.monthlyDiscardedMilk),
        headerStyle: { textAlign: 'right' },
        cellStyle: { textAlign: 'right' }
      }
    ], [productsOptions, t]
  )

  return (
    <Container>
      <Top>
        <IconButton
          tooltip={ t('go back') }
          onClick={ () => history.goBack() }
        >
          <Icon icon={ arrowLeft } size={ 16 } />
        </IconButton>
        <I18n as={ Title }>monthly data logging</I18n>
      </Top>
      <Row>
        <RightContainer>
          <Button
            id="new-price-data"
            color="secondary"
            style={ { flex: 'none', whiteSpace: 'nowrap', marginLeft: 16 } }
            onClick={ registerPriceData }
            disabled={ !userWrite }
          >
            <I18n>register</I18n>
          </Button>
        </RightContainer>
      </Row>

      <DataTable
        tableRef={ tableRef }
        data={ getMonthlyDataHistory }
        queryParams={ { propertyId: currentProperty?.id } }
        columns={ columns }
        onEditClick={ registerPriceData }
        onDeleteClick={ handleDeleteClick }
        disabledEdit={ () => !userWrite }
        conditionToDelete={ () => userWrite }
        id="list-production-data-table"
      />
    </Container>
  )
}

export default ListProductionDataScreen
