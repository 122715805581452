import styled from 'styled-components'

export const SearchContainer = styled.div`
  height: 2.75rem;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
`
export const InputSearch = styled.input`
    background-repeat: no-repeat;
    background-size: 1rem, 1rem;
    background-position: 0.45rem 0.35rem;
    padding-left: 2rem;
    border: none;
    border-radius: 1rem;
    width: 100%;
    padding: 0.5rem 1rem;

    &:focus {
    outline: none;
}
`