import React, { useCallback, forwardRef } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'


import Grid from '@material-ui/core/Grid'

import parametersRegistationSchema from '@smartcoop/forms/schemas/dairyFarm/parametersRegistation.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputUnit from '@smartcoop/web-components/InputUnit'

import {
  Container,
  FormContainer,
  ButtonsContainer,
  Item
} from './styles'

const ParametersRegistrationForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel
  } = props

  const t = useT()

  const userWrite = useSelector(selectUserCanWrite)

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data
      })
    },
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ parametersRegistationSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputUnit
                label={ t('initial touch') }
                name="initialTouch"
                fullWidth
                defaultValue={ (defaultValues.initialTouch).toString() }
                type="integer"
                unit={ t('days after coverage') }
                maxLength={ 3 }
                disabled={ !userWrite }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('reconfirmation touch') }
                name="reconfirmationTouch"
                fullWidth
                defaultValue={ (defaultValues.reconfirmationTouch).toString() }
                type="integer"
                unit={ t('days after first touch') }
                maxLength={ 3 }
                disabled={ !userWrite }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('drying confirmation') }
                name="dryingConfirmation"
                fullWidth
                defaultValue={ (defaultValues.dryingConfirmation).toString() }
                type="integer"
                unit={ t('days after coverage') }
                maxLength={ 3 }
                disabled={ !userWrite }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('dry period') }
                name="dryPeriod"
                fullWidth
                defaultValue={ (defaultValues.dryPeriod).toString() }
                type="integer"
                unit={ t('days before birth') }
                maxLength={ 3 }
                disabled={ !userWrite }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('pre birth') }
                name="preBirth"
                fullWidth
                defaultValue={ (defaultValues.preBirth).toString() }
                type="integer"
                unit={ t('days before birth') }
                maxLength={ 3 }
                disabled={ !userWrite }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('voluntary waiting period') }
                name="voluntaryWaitingPeriod"
                fullWidth
                defaultValue={ (defaultValues.voluntaryWaitingPeriod).toString() }
                type="integer"
                unit={ t('days after birth') }
                maxLength={ 3 }
                disabled={ !userWrite }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('gestation period') }
                name="animalGestation"
                fullWidth
                defaultValue={ (defaultValues.animalGestation).toString() }
                type="integer"
                unit={ t('days') }
                maxLength={ 3 }
                disabled={ !userWrite }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('average del goal') }
                name="averageDelGoal"
                fullWidth
                defaultValue={ (defaultValues.averageDelGoal).toString() }
                type="integer"
                unit={ t('days in lactation') }
                maxLength={ 3 }
                disabled={ !userWrite }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('heifer age for first insemination') }
                name="heiferAge"
                fullWidth
                defaultValue={ (defaultValues.heiferAge).toString() }
                type="integer"
                unit={ t('month', { howMany: 2 }) }
                maxLength={ 3 }
                disabled={ !userWrite }
              />
            </Item>
          </Grid>
        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-cancel-form-button"
              onClick={ onCancel }
              color={ colors.white }
              disabled={ loading }
              style={ { marginRight:10, marginLeft: 10 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="web-save-form-button"
              onClick={ () => formRef.current.submit() }
              disabled={ loading || !userWrite }
              style={ { marginRight:10, marginLeft: 10 } }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

ParametersRegistrationForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  defaultValues: PropTypes.object
}

ParametersRegistrationForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false,
  defaultValues: {}
}

export default ParametersRegistrationForm
