import { tableSchema } from '@nozbe/watermelondb'


export const profilePictureSchema = tableSchema({
  name: 'profile_picture',
  columns: [
    { name: 'userId', type: 'string' },
    { name: 'sourceUrl', type: 'string' },
    { name: 'source', type: 'string' },
    { name: 'group', type: 'boolean' },
    { name: 'conversationId', type: 'string' }
  ]
})
