import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

export const EmptyState = styled(Typography).attrs({
  variant: 'body1',
  align: 'center'
})`
  padding: 15px;
`
