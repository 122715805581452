import { tableSchema } from '@nozbe/watermelondb'

export const messageSchema = tableSchema({
  name: 'message',
  columns: [
    { name: 'messageId', type: 'string' },
    { name: 'conversationId', type: 'string' },
    { name: 'isDocument', type: 'boolean' },
    { name: 'isGeolocation', type: 'boolean' },
    { name: 'documentFormat', type: 'string' },
    { name: 'documentName', type: 'string' },
    { name: 'fileAction', type: 'string' },
    { name: 'fileType', type: 'string' },
    { name: 'message', type: 'string' },
    { name: 'nickname', type: 'string' },
    { name: 'notification', type: 'boolean' },
    { name: 'whoSend', type: 'string' },
    { name: 'status', type: 'string' },
    { name: 'relationalId', type: 'string' },
    { name: 'sendAt', type: 'number' },
    { name: 'delAt', type: 'number' },
    { name: 'syncId', type: 'string' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
    { name: 'forward', type: 'boolean' }
  ]
})
