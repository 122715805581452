import React, { useState, useEffect, useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'


import ChevronRight from '@material-ui/icons/ChevronRight'

import { tractor } from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { colors } from '@smartcoop/styles'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import Card from '@smartcoop/web-components/Card'
import Icon from '@smartcoop/web-components/Icon'
import MachineStatus from '@smartcoop/web-components/MachineStatus'

import {
  Container,
  TextGroup,
  TextLeft,
  TextRight,
  TextBold,
  Text,
  ModelYear,
  CurrentImage,
  ImageContainer,
  DistanceInfo,
  EmptyImage,
  ViewContainer
} from './styles'

const MachineItem = (props) => {
  const {
    machine,
    active,
    onViewClick,
    t,
    onClick
  } = props
  const [selectedImageSrc, setSelectedImageSrc] = useState('')

  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_MACHINES_ID, fileKey: file?.fileKey })

    return downloadFromBase64(data)
  }, [])

  useEffect(() => {
    if(!isEmpty(machine?.machineFiles)) {
      renderFile(machine.machineFiles[0]).then((data) => {
        setSelectedImageSrc(data)
      })
    }
  }, [machine.machineFiles, renderFile])

  const machineItemImage = useMemo(
    () => {
      if(!selectedImageSrc.length) {
        return (
          <EmptyImage >
            <Icon size={ 40 } icon={ tractor } color={ colors.grey }/>
          </EmptyImage>
        )
      }
      return (
        <CurrentImage src={ selectedImageSrc } />
      )
    },
    [selectedImageSrc]
  )



  const distance = useMemo(
    () => (machine.property?.distance ? (`${ machine.property?.distance  } km`) : ''),
    [machine]
  )

  const status = useMemo(() => {
    if(machine?.rentAvailable) return 'rent'
    if(machine?.saleAvailable) return 'sell'
    if(machine?.serviceProvisionAvailable) return 'service'
    return 'none'
  }, [machine])

  return (
    <Card
      style={ { marginBottom: 17, marginTop: 0, cursor: 'pointer' } }
      cardStyle={ { padding: '14px', width: '100%', marginBottom: 0, border: active ? (`2px solid${  colors.yellow }`) : '2px solid transparent' } }
      onClick={ () => onClick({ machine }) }
    >
      <Container>
        <ImageContainer>
          {machineItemImage}
        </ImageContainer>
        <TextGroup>
          <TextLeft>
            <TextBold>{machine.machineType.description}</TextBold>
            <Text>{machine.machineBrand.description}</Text>
            <ModelYear>
              <Text>{machine.model}</Text>
              <Text>{machine.year}</Text>
            </ModelYear>
          </TextLeft>
          <TextRight>
            {status !== 'none' && (
              <MachineStatus status={ status } style={ { width:  '10vw', height: '40px' } }/>
            )}
            <DistanceInfo>
              {distance || t('unavailable')}

              <ViewContainer onClick={ () => onViewClick({ machine }) }>
                <TextBold>{t('view short')}</TextBold>
                <ChevronRight size={ 40 } color={ colors.grey } />
              </ViewContainer>
            </DistanceInfo>

          </TextRight>
        </TextGroup>
      </Container>
    </Card>
  )
}

MachineItem.propTypes = {
  machine: PropTypes.shape({
    machineType: PropTypes.object,
    machineBrand: PropTypes.object,
    year: PropTypes.number,
    model: PropTypes.string,
    distance: PropTypes.string,
    machineFiles: PropTypes.array,
    rentAvailable: PropTypes.bool,
    saleAvailable: PropTypes.bool,
    serviceProvisionAvailable: PropTypes.bool,
    property: PropTypes.object
  }),
  active: PropTypes.bool,
  onViewClick: PropTypes.func,
  t: PropTypes.func,
  onClick: PropTypes.func
}

MachineItem.defaultProps = {
  machine: {
    machineType: {},
    brand: '',
    year: 0,
    model: '',
    distance: '',
    machineFiles: [],
    rentAvailable: false,
    saleAvailable: false,
    serviceProvisionAvailable: false,
    property: {}
  },
  onClick: () => {},
  onViewClick: () => {},
  t: () => {},
  active: false
}

export default MachineItem
