import React, { useCallback, forwardRef } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filterPregnancyActionsSchema from '@smartcoop/forms/schemas/dairyFarm/filterPregnancyActions.schema'
import I18n, { useT } from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'

import { Container, ButtonContainer } from './styles'

const FilterPregnancyActionsForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()
  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterPregnancyActionsSchema }
        onSubmit={ handleSubmit }
      >
        <InputDate
          label={ t('accomplished date') }
          name="accomplishedDate"
          pickerProps={ {
            maxDate: moment().format()
          } }
          fullWidth
          defaultValue={ filters.accomplishedDate }
        />

        <InputSelect
          label={ t('type') }
          name="type"
          options={ [
            {
              label: t('drying'),
              value: 'secagem'
            },
            {
              label: t('prepartum'),
              value: 'pre-parto'
            },
            {
              label: t('abortion'),
              value: 'aborto'
            }
          ] }
          defaultValue={ filters.type }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="pregnancy-actions-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterPregnancyActionsForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterPregnancyActionsForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterPregnancyActionsForm
