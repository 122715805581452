import React, { useMemo } from 'react'

import withObservables from '@nozbe/with-observables'
import PropType from 'prop-types'

import first from 'lodash/first'

import { Avatar } from '@material-ui/core'


import { database } from '@smartcoop/database'
import { profilePictureService } from '@smartcoop/database/services/profilePictureService'
import CheckboxCard from '@smartcoop/web-components/FilteredCheckboxGroup/CheckboxCard'

import { Card, TextName } from './styles'

const ContactCard = ({ contact, onSelect, selected, profilePictures, disableSelection }) => {
  const profilePicture = first(profilePictures)

  const disabled = useMemo(() => (!selected && disableSelection), [disableSelection, selected])

  const handleSelect = () => {
    if (!disabled) {
      onSelect()
    }
  }

  return (
    <Card onClick={ handleSelect }>
      <CheckboxCard
        style={ { backgroundColor: 'transparent', margin: 0, paddingLeft: 4 } }
        onClick={ event => event.stopPropagation() }
        checked={ selected }
        onChange={ handleSelect }
        disabled={ disabled }
      />

      <Avatar src={ profilePicture?.source } size={ 25 } alt={ contact?.chatNickname } />
      <TextName>{contact?.chatNickname}</TextName>
    </Card>
  )
}

ContactCard.propTypes = {
  contact: PropType.object.isRequired,
  onSelect: PropType.func.isRequired,
  selected: PropType.bool,
  profilePictures: PropType.array,
  disableSelection: PropType.bool
}

ContactCard.defaultProps = {
  selected: false,
  profilePictures: [],
  disableSelection: false
}

const enhance = withObservables(['userId'], ({ userId }) => ({
  profilePictures: profilePictureService(database).observeProfilePictureByUserId(userId)
}))

const EnhancedContactCard = enhance(ContactCard)

export default EnhancedContactCard
