

import { v5 as uuidV5 } from 'uuid'

import sortBy from 'lodash/sortBy'

export function generateUuid(sendId, receiverId) {
  const sortedString = sortBy([sendId, receiverId]).join('-')
  return uuidV5(sortedString, uuidV5.URL)
}
