// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg v
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
      <g>
        <circle fill="#F9D915" cx="100" cy="100" r="46.1"/>
        <path fill="#F9D915" d="M49.2,18.6l15.6,38.1c4.6-3.7,9.8-6.7,15.4-8.9L55.3,15.1c0,0,0-0.1-0.1-0.1c-1.2-1.4-3.4-1.7-4.9-0.6
          c-1.2,0.9-1.7,2.5-1.2,3.9C49.1,18.4,49.1,18.5,49.2,18.6z"/>
        <path fill="#F9D915" d="M100,44.1c3.1,0,6.1,0.3,9,0.7l-5.3-40.7c0,0,0-0.1,0-0.1c-0.3-1.9-2-3.2-4-3c-1.5,0.2-2.7,1.3-3,2.8
          c0,0.1-0.1,0.2-0.1,0.2l-5.5,40.7C94.1,44.3,97,44.1,100,44.1z"/>
        <path fill="#F9D915" d="M44.1,100c0-3.1,0.3-6.1,0.7-9L4.1,96.3c0,0-0.1,0-0.1,0c-1.9,0.3-3.2,2-3,4c0.2,1.5,1.3,2.7,2.8,3
          c0.1,0,0.2,0.1,0.2,0.1l40.8,5.5C44.4,105.9,44.1,103,44.1,100z"/>
        <path fill="#F9D915" d="M152.3,80.2l32.7-24.9c0,0,0.1-0.1,0.1-0.1c1.5-1.2,1.7-3.4,0.6-4.9c-0.9-1.2-2.5-1.7-3.9-1.2
          c-0.1,0-0.2,0-0.2,0.1l-38,15.6C147.1,69.4,150.1,74.6,152.3,80.2z"/>
        <path fill="#F9D915" d="M135.4,56.7l15.8-37.9c0,0,0-0.1,0-0.1c0.3-0.8,0.3-1.8,0-2.6c-0.4-0.9-1-1.6-1.9-1.9
          c-1.4-0.6-3-0.2-4,0.9c-0.1,0.1-0.1,0.1-0.2,0.2L120,47.8C125.6,50,130.8,53,135.4,56.7z"/>
        <path fill="#F9D915" d="M15.3,54.9L47.8,80c2.2-5.6,5.2-10.8,8.9-15.4L18.8,48.8c0,0-0.1,0-0.1,0c-1.7-0.7-3.8,0.2-4.5,1.9
          c-0.6,1.4-0.2,3,0.9,4C15.1,54.8,15.2,54.8,15.3,54.9z"/>
        <path fill="#F9D915" d="M184.7,145.1L152.2,120c-2.2,5.6-5.2,10.8-8.9,15.4l37.9,15.8c0,0,0.1,0,0.1,0c1.7,0.7,3.8-0.2,4.5-1.9
          c0.6-1.4,0.2-3-0.9-4C184.9,145.2,184.8,145.2,184.7,145.1z"/>
        <path fill="#F9D915" d="M199,99.8c-0.2-1.5-1.3-2.7-2.8-3c-0.1,0-0.2-0.1-0.2-0.1l-40.7-5.5c0.5,2.9,0.7,5.8,0.7,8.8
          c0,3.1-0.3,6.1-0.7,9l40.7-5.3c0,0,0.1,0,0.1,0C197.9,103.4,199.2,101.7,199,99.8z"/>
        <path fill="#F9D915" d="M150.8,181.4l-15.6-38.1c-4.6,3.7-9.8,6.7-15.4,8.9l24.9,32.7c0,0,0.1,0.1,0.1,0.1
          c1.2,1.4,3.4,1.7,4.9,0.6c1.2-0.9,1.7-2.5,1.2-3.9C150.9,181.6,150.9,181.5,150.8,181.4z"/>
        <path fill="#F9D915" d="M47.8,119.8l-32.7,24.9c0,0-0.1,0-0.1,0.1c-0.7,0.6-1.1,1.4-1.3,2.3c-0.1,0.9,0.1,1.9,0.7,2.6
          c0.9,1.2,2.5,1.7,3.9,1.2c0.1,0,0.2,0,0.2-0.1l38.1-15.6C52.9,130.6,49.9,125.4,47.8,119.8z"/>
        <path fill="#F9D915" d="M100,155.9c-3.1,0-6.1-0.3-9-0.7l5.3,40.8c0,0,0,0.1,0,0.1c0.3,1.7,1.8,3,3.5,3c0.1,0,0.3,0,0.5,0
          c1.5-0.2,2.7-1.3,3-2.8c0-0.1,0.1-0.2,0.1-0.2l5.5-40.8C105.9,155.6,103,155.9,100,155.9z"/>
        <path fill="#F9D915" d="M64.6,143.2l-15.8,38c0,0,0,0.1,0,0.1c-0.3,0.8-0.3,1.8,0,2.6c0.4,0.9,1,1.6,1.9,1.9c1.4,0.6,3,0.2,4-0.9
          c0.1-0.1,0.1-0.1,0.2-0.2L80,152.2C74.4,150,69.2,147,64.6,143.2z"/>
      </g>
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
