import { REACT_APP_FIREBASE_STORAGE_BUCKET, REACT_APP_PLATFORM } from 'react-native-dotenv'

import {
  all,
  put,
  takeLatest,
  fork,
  cancel,
  take,
  select,
  call,
  delay
} from 'redux-saga/effects'

import moment from 'moment/moment'

import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import map from 'lodash/map'
import reverse from 'lodash/reverse'
import size from 'lodash/size'
import uniq from 'lodash/uniq'

import { firebaseMessaging, messaging, firebaseFirestore } from '@smartcoop/services/apis/firebase/firebase'

import { AuthenticationTypes } from '../authentication'
import { selectFirebaseIdToken, selectFirebaseAuthenticated, selectAuthenticated } from '../authentication/selectorAuthentication'
import rsf from '../firebase/rsf'
import { selectUserId } from '../user/selectorUser'
import { MessagingTypes, MessagingActions } from './duckMessaging'
import { selectHoursPendingAttention, selectIsChatModule, selectLastAllNotificationVisible } from './selectorMessaging'

function* requestPermissionSaga() {
  try {
    const permission = yield firebaseMessaging.requestPermission()
    let granted = true
    if ((process.env.REACT_APP_PLATFORM || REACT_APP_PLATFORM) === 'mobile') {
      granted = (
        permission === messaging?.AuthorizationStatus?.AUTHORIZED
        || permission === messaging?.AuthorizationStatus?.PROVISIONAL
      )
    }

    if (granted) {
      // eslint-disable-next-line no-console
      console.info('Allowed notifications!', permission)
      const token = yield firebaseMessaging.getToken()
      yield put(MessagingActions.setCloudMessagingToken(token))
    }
  } catch (error) {
    if (error.code === 'messaging/token-unsubscribe-failed') {
      yield requestPermissionSaga()
    } else {
      throw error
    }
  }
}


function* setCloudMessagingToken({ token }) {
  try {
    const userId = yield select(selectUserId)
    const firebaseAuthenticated = yield select(selectFirebaseAuthenticated)
    const oldToken = yield select((state) => state.messaging.token)

    if (!firebaseAuthenticated) {
      yield take(AuthenticationTypes.FIREBASE_LOGIN_SUCCESS)
    }

    const doc = `user/${ userId }`

    const snapshot = yield call(rsf.firestore.getDocument, doc)
    const user = snapshot.data() || {}
    const devices = get(user, 'devices', [])

    if (token !== oldToken || !find(devices, device => device === token)) {
      yield call(
        rsf.firestore.setDocument,
        doc,
        {
          ...user,
          devices: uniq([
            ...filter(devices, (device) => device !== oldToken),
            token
          ])
        }
      )
    }

  } catch (e) {
    console.error(MessagingTypes.SET_CLOUD_MESSAGING_TOKEN, 'error', e)
  } finally {
    yield put(MessagingActions.setCloudMessagingTokenSuccess())
  }
}


function* unsetCloudMessagingToken() {
  try {
    const token = yield select((state) => state.messaging.token)
    const userId = yield select(selectUserId)

    const doc = `user/${ userId }`

    const snapshot = yield call(rsf.firestore.getDocument, doc)
    const user = snapshot.data() || {}
    const devices = get(user, 'devices', [])

    yield call(
      rsf.firestore.setDocument,
      doc,
      {
        ...user,
        devices: filter(devices, (device) => device !== token)
      }
    )

    if (token) {
      yield firebaseMessaging.deleteToken(token)
    }

  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('unset firebase messaging token error', e)
  } finally {
    yield put(MessagingActions.unsetCloudMessagingTokenSuccess())
  }
}


function* refreshToken() {
  const channel = yield rsf.messaging.tokenRefreshChannel()
  while (true) {
    const token = yield take(channel)
    yield put(MessagingActions.setCloudMessagingToken(token))
  }
}

function* initCloudMessaging() {
  try {
    if (firebaseMessaging) {

      yield put(MessagingActions.getHoursPendingAttention())

      const vapidKeyStarted = yield select((state) => state.messaging.vapidKeyStarted)

      if ((process.env.REACT_APP_PLATFORM || REACT_APP_PLATFORM) === 'web' && !vapidKeyStarted) {
        yield firebaseMessaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_MESSAGING_VAPID)
      }

      yield requestPermissionSaga()

      const task = yield fork(refreshToken)

      yield take(AuthenticationTypes.LOGOUT_SUCCESS)

      yield cancel(task)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Ocorreu um erro ao iniciar a sessão do firebase cloud messaging:', e)
    yield put(MessagingActions.messagingError(e))
  }
}


function* subscribeUserNotifications() {
  try {
    const userId = yield select(selectUserId)

    const hoursPendingAttention = yield select(selectHoursPendingAttention)

    const notificationsRef = firebaseFirestore().collection(`user/${ userId }/notifications`)

    const query = notificationsRef

    const taskCountUnreadNotifications = yield fork(
      rsf.firestore.syncCollection,
      query.where('read', '==', false),
      {
        successActionCreator: MessagingActions.setUnreadNotificationsCounter,
        failureActionCreator: MessagingActions.messagingError,
        transform: (payload) => size(payload.docs)
      }
    )


    const taskShowNewNotifications = yield fork(
      rsf.firestore.syncCollection,
      query
        .where('notified', '==', false)
        .where('read', '==', false)
        .orderBy('createdAt', 'desc'),
      {
        successActionCreator: MessagingActions.showNewNotifications,
        failureActionCreator: MessagingActions.setMessagingError,
        transform: (payload) => filter(payload.docs, (snapshot) => {
          const notification = snapshot.data()
          return moment().diff(moment(notification.createdAt), 'hours') <= hoursPendingAttention
        })
      }
    )

    const taskGetLastNotifications = yield fork(
      rsf.firestore.syncCollection,
      query
        .orderBy('createdAt', 'desc')
        .limit(10),
      {
        successActionCreator: MessagingActions.getLastNotifications,
        failureActionCreator: MessagingActions.setMessagingError,
        transform: (payload) => map(payload.docs, (doc) => doc.data())
      }
    )

    yield take(MessagingTypes.UNSUBSCRIBE_USER_NOTIFICATIONS)
    yield cancel(taskCountUnreadNotifications)

    if (taskShowNewNotifications) {
      yield cancel(taskShowNewNotifications)
    }
    if (taskGetLastNotifications) {
      yield cancel(taskGetLastNotifications)
    }
  } catch (e) {
    console.error(MessagingTypes.SUBSCRIBE_USER_NOTIFICATIONS, 'error', e)
    yield delay(1000)
    const isAuthenticated = yield select(selectAuthenticated)
    if (isAuthenticated) {
      console.log(MessagingTypes.SUBSCRIBE_USER_NOTIFICATIONS, 'Trying again...')
      yield put(MessagingTypes.SUBSCRIBE_USER_NOTIFICATIONS)
    }
  }
}

function* playNotificationSound() {
  const src = `https://firebasestorage.googleapis.com/v0/b/${
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || REACT_APP_FIREBASE_STORAGE_BUCKET
  }/o/assets%2Fnotification.mp3?alt=media`
  try {
    const audio = new Audio(src)
    yield audio.play()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Audio API is not working', e)
  }
}

function* showNewNotifications({ snapshots }) {
  const isChatModule = yield select(selectIsChatModule)

  if (!isEmpty(snapshots)) {
    const notifications = yield all(map(snapshots, (snapshot) => snapshot.data()))

    const filteredNotifications = filter(notifications, notification => !(notification?.data?.module === 'chat' && isChatModule))

    forEach(reverse(filteredNotifications), (notification) => {
      if (window?.snackbar) {
        window.snackbar.default(
          `${ notification.message.title }: ${ notification.message.body }`
        )
      }
    })

    if ((process.env.REACT_APP_PLATFORM || REACT_APP_PLATFORM) === 'web' && !isEmpty(filteredNotifications)) {
      yield put(MessagingActions.playNotificationSound())
    }

    yield fork(setMessagesNotificated, snapshots)
  }
}

function* setMessagesNotificated(snapshots) {
  try {
    const db = firebaseFirestore()
    const batch = db.batch()

    forEach(snapshots, (snapshot) => {
      batch.update(snapshot.ref, { notified: true })
    })

    yield batch.commit()
  } catch (e) {
    yield put(MessagingActions.messagingError(e))
  }
}

function* readNotification({ notificationId }) {
  yield put(MessagingActions.readNotificationRequest(notificationId))
}

function* readNotificationRequest({ notificationId }) {
  try {
    const userId = yield select(selectUserId)

    yield call(
      rsf.firestore.updateDocument,
      `user/${ userId }/notifications/${ notificationId }`,
      'read',
      true
    )
  } catch (e) {
    yield put(MessagingActions.messagingError(e))
  }
}

function* readAllNotifications({ isFromChat = false }) {
  yield put(MessagingActions.readAllNotificationsRequest(isFromChat))
}

function* readAllNotificationsRequest({ isFromChat = false }) {
  try {
    const userId = yield select(selectUserId)
    const firebaseIdToken = yield select(selectFirebaseIdToken)

    yield call(
      rsf.functions.call,
      isFromChat ? 'api/users/notifications/readAllFromChat' : 'api/users/notifications/readAll',
      {},
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${ firebaseIdToken }`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId })
      }
    )
  } catch (e){
    yield put(MessagingActions.messagingError(e))
  }
}

function* getAllNotifications({ firstPage, onSuccess = () => {}, onError = () => {} }) {
  try {
    const userId = yield select(selectUserId)
    const lastVisible = yield select(selectLastAllNotificationVisible)

    let query = firebaseFirestore()
      .collection(`user/${ userId }/notifications`)
      .orderBy('createdAt', 'desc')

    if (!firstPage && lastVisible) {
      const docSnap = yield firebaseFirestore()
        .collection(`user/${ userId }/notifications`)
        .doc(lastVisible)
        .get()

      query = query.startAfter(docSnap)
    }

    const snap = yield call(
      rsf.firestore.getCollection,
      query.limit(20)
    )

    let notifications = []

    snap.forEach((notification) => {
      notifications = [
        ...notifications,
        notification.data()
      ]
    })

    const newLastVisible = last(notifications)?.id

    yield put(MessagingActions.getAllNotificationsSuccess(
      firstPage,
      notifications,
      newLastVisible
    ))

    const end = !newLastVisible || (!firstPage && newLastVisible === lastVisible)
    yield call(onSuccess, end)
  } catch (e) {
    yield put(MessagingActions.messagingError(e))
    yield call(onError, e)
  }
}

function* getHoursPendingAttention() {
  try {
    const userId = yield select(selectUserId)

    const snap = yield call(rsf.firestore.getDocument, `user/${ userId }`)
    const { hoursPendingAttention } = snap.data()

    yield put(MessagingActions.getHoursPendingAttentionSuccess(hoursPendingAttention))
  } catch (e) {
    yield put(MessagingActions.messagingError(e))
  }
}

export default [
  takeLatest(MessagingTypes.INIT_CLOUD_MESSAGING, initCloudMessaging),
  takeLatest(MessagingTypes.SET_CLOUD_MESSAGING_TOKEN, setCloudMessagingToken),
  takeLatest(MessagingTypes.UNSET_CLOUD_MESSAGING_TOKEN, unsetCloudMessagingToken),
  takeLatest(MessagingTypes.SUBSCRIBE_USER_NOTIFICATIONS, subscribeUserNotifications),
  takeLatest(MessagingTypes.PLAY_NOTIFICATION_SOUND, playNotificationSound),
  takeLatest(MessagingTypes.SHOW_NEW_NOTIFICATIONS, showNewNotifications),
  takeLatest(MessagingTypes.READ_NOTIFICATION, readNotification),
  takeLatest(MessagingTypes.READ_NOTIFICATION_REQUEST, readNotificationRequest),
  takeLatest(MessagingTypes.READ_ALL_NOTIFICATIONS, readAllNotifications),
  takeLatest(MessagingTypes.READ_ALL_NOTIFICATIONS_REQUEST, readAllNotificationsRequest),
  takeLatest(MessagingTypes.GET_ALL_NOTIFICATIONS, getAllNotifications),
  takeLatest(MessagingTypes.GET_HOURS_PENDING_ATTENTION, getHoursPendingAttention)
]
