import api from '../api'

export const getAnimalBirths = (params, { propertyId }) =>
  api.get(`/v1/herds-management/property/${ propertyId }/animals-birth`, { params })

export const getAnimalBirthsById = (params, { propertyId, animalId }) =>
  api.get(`/v1/herds-management/property/${ propertyId }/animals-birth/animal/${ animalId }`, { params })

export const createAnimalBirth = (params, { propertyId }) =>
  api.post(`/v1/herds-management/property/${ propertyId }/animals-birth`, params)

export const editAnimalBirth = (params, { animalBirthId, propertyId }) =>
  api.patch(`/v1/herds-management/property/${ propertyId }/animals-birth/${ animalBirthId }`, params)

export const deleteAnimalBirth = ({ propertyId, animalBirthId }) =>
  api.delete(`/v1/herds-management/property/${ propertyId }/animals-birth/${ animalBirthId }`)
