import api from '../api'

export const getAnimalProtocols = (params, { animalId }) =>
  api.get(`/v1/herds-management/protocols/animal/${ animalId }`, { params })

export const createAnimalProtocol = (params) =>
  api.post('/v1/herds-management/protocols/', params)

export const editAnimalProtocol = (params, { protocolId }) =>
  api.patch(`/v1/herds-management/protocols/${ protocolId }`, params)

export const deleteAnimalProtocol = ({ protocolId }) =>
  api.delete(`/v1/herds-management/protocols/${ protocolId }`)
