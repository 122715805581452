export default ({ size, color = '#289F30' } = {}) => /* html */`
<svg width="${ size }" height="${ size }" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.6745 3.93848C9.00897 4.94161 7.72497 6.47085 7.02514 8.28481C5.71962 8.39664 4.46391 8.83828 3.37581 9.56831C0.188311 11.6933 1.25081 17.0058 1.25081 17.0058C2.25969 17.1799 3.28711 17.2204 4.30656 17.1262" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.8337 26.5674L14.8962 27.6299" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.2078 26.5674L19.1453 27.6299" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.391 4.25684C23.391 2.49592 20.5364 1.06934 17.016 1.06934C13.4956 1.06934 10.641 2.49592 10.641 4.25684C10.641 6.38184 14.466 5.31934 14.466 5.31934C14.4922 5.95107 14.7842 6.5423 15.27 6.94707C15.7557 7.35185 16.3899 7.53246 17.016 7.44434C17.6421 7.53246 18.2763 7.35185 18.762 6.94707C19.2478 6.5423 19.5398 5.95107 19.566 5.31934C19.566 5.31934 23.391 6.38184 23.391 4.25684Z" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.2129 5.053L7.62624 4.25684C6.27788 3.5249 5.17067 2.4177 4.43874 1.06934C4.43874 1.06934 2.31374 4.25684 3.37624 6.38184C3.83226 7.25908 4.46284 8.03381 5.22924 8.65842" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M26.0515 16.251C26.0515 34.0089 18.4496 32.9407 17.9467 32.9407H16.0937C15.5894 32.9407 7.98895 34.0032 7.98895 16.251" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.3635 3.93848C25.029 4.94161 26.313 6.47085 27.0129 8.28481C28.3184 8.39664 29.5741 8.83828 30.6622 9.56831C33.8497 11.6933 32.7872 17.0058 32.7872 17.0058C31.7783 17.1799 30.7509 17.2204 29.7314 17.1262" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.2195 24.939L24.4743 24.4418C19.9009 21.6171 14.1245 21.6171 9.55114 24.4418L8.80597 24.939" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.8195 5.053L26.4118 4.25684C27.7602 3.5249 28.8674 2.4177 29.5993 1.06934C29.5993 1.06934 31.7243 4.25684 30.6618 6.38184C30.2058 7.25908 29.5752 8.03381 28.8088 8.65842" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.2335 11.6943C12.5269 11.6943 12.7647 11.9322 12.7647 12.2256" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.7057 12.2256C11.7057 11.9322 11.9435 11.6943 12.2369 11.6943" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.2369 12.7529C11.9435 12.7529 11.7057 12.5151 11.7057 12.2217" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.7647 12.2217C12.7647 12.5151 12.5269 12.7529 12.2335 12.7529" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.8026 11.6943C22.096 11.6943 22.3339 11.9322 22.3339 12.2256" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.2659 12.2256C21.2659 11.9322 21.5037 11.6943 21.7971 11.6943" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.7971 12.7529C21.5037 12.7529 21.2659 12.5151 21.2659 12.2217" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.3339 12.2217C22.3339 12.5151 22.096 12.7529 21.8026 12.7529" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`
