import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AbsenceActions } from '@smartcoop/stores/absence'
import { selectUserAbsences } from '@smartcoop/stores/absence/selectorAbsence'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { OrganizationActions } from '@smartcoop/stores/organization'
import { selectUserOrganizations } from '@smartcoop/stores/organization/selectorOrganization'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import UserAdditionalInfoFragment from '@smartcoop/web-containers/fragments/profile/UserAdditionalInfoFragment'
import UserInfoFragment from '@smartcoop/web-containers/fragments/profile/UserInfoFragment'
import HorizontalSplitScreenLayout from '@smartcoop/web-containers/layouts/HorizontalSplitScreenLayout'

const EditProfileScreen = () => {
  const user = useSelector(selectUser)
  const userOrganizations = useSelector(selectUserOrganizations)
  const userAbsences = useSelector(selectUserAbsences)
  const dispatch = useCallback(useDispatch(), [])

  useEffect(() => {
    dispatch(OrganizationActions.loadUserOrganizations())
    dispatch(AuthenticationActions.loadPermissions())
    dispatch(AbsenceActions.loadAbsences())
  }, [dispatch])

  return (
    <HorizontalSplitScreenLayout
      upperChildren={
        <UserInfoFragment user={ user } />
      }
      underChildrenStyle={ { justifyContent: 'center', alignItems: 'center' } }
      underChildren={
        <UserAdditionalInfoFragment
          user={ user }
          userOrganizations={ userOrganizations }
          userAbsences={ userAbsences }
        />
      }
    />
  )
}

export default EditProfileScreen
