import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'

export class ConversationFileModel extends Model {
  static table = 'conversation_file'

  @field('registerId')
  registerId: string

  @field('messageId')
  messageId: string

  @field('conversationId')
  conversationId: string

  @field('documentName')
  documentName: string

  @field('source')
  source: string
}
