import React from 'react'

import PropTypes from 'prop-types'

import last from 'lodash/last'

import { useT } from '@smartcoop/i18n'
import {  document as documentIcon, download } from '@smartcoop/icons'
import Icon from '@smartcoop/web-components/Icon'

import { FileContainer, FileDescription } from './styles'

const ChatDocument = ({ conversationItem, receiver }) => {
  const t = useT()

  const onDownloadDocument = file => {
    const fileName = last(file.documentName.split('/'))

    const downloadLink = document.createElement('a')
    downloadLink.href = file.source
    downloadLink.download = fileName
    downloadLink.click()
  }

  const extension = last(conversationItem?.documentName?.split('.'))

  return (
    <FileContainer receiver={ receiver } onClick={ () => onDownloadDocument(conversationItem) }>
      <Icon icon={ documentIcon } size={ 16 } />
      <FileDescription>{t('file')} {extension?.toUpperCase()}</FileDescription>
      <Icon icon={ download } size={ 16 } />
    </FileContainer>
  )
}

ChatDocument.propTypes = {
  conversationItem: PropTypes.object,
  receiver: PropTypes.bool
}

ChatDocument.defaultProps = {
  conversationItem: null,
  receiver: false
}

export default ChatDocument
