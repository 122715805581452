import React, { useRef, useEffect } from 'react'

import PropTypes from 'prop-types'

import { InputTextArea } from './styles'

const TextArea = ({
  onSubmit,
  onChange,
  messages,
  ...props
}) => {
  const inputRef = useRef(null)

  useEffect(() => {
    // Check if messages have changed and focus the input
    inputRef.current.focus()
  }, [messages])

  const onKeyPress = event => {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault()
      onSubmit()
    }
  }


  return (
    <InputTextArea
      ref={ inputRef }
      onKeyPress={ onKeyPress }
      onChange={ event => onChange(event.target.value) }
      { ...props }
    />
  )
}

TextArea.propTypes = {
  onSubmit: PropTypes.func,
  messages: PropTypes.array,
  onChange: PropTypes.func
}

TextArea.defaultProps = {
  messages: [],
  onSubmit: () => {},
  onChange: () => {}
}

export default TextArea
