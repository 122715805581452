import React, { useMemo, useCallback, forwardRef } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import map from 'lodash/map'

import Grid from '@material-ui/core/Grid'

import milkRegisterQualitySchema from '@smartcoop/forms/schemas/property/milkRegisterQuality.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { selectCurrentPropertyDairyFarmIEs } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputUnit from '@smartcoop/web-components/InputUnit'

import {
  Container,
  FormContainer,
  ButtonsContainer,
  ItemContainer,
  Item,
  HalfItem,
  HalfGroup
} from './styles'

const RegisterMilkQualityForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    loading,
    defaultValues,
    onSubmit,
    onCancel
  } = props
  const t = useT()

  const currentPropertyDairyFarmIEs = useSelector(selectCurrentPropertyDairyFarmIEs)

  const iesOptions = useMemo(
    () => map(currentPropertyDairyFarmIEs, ({ stateRegistration, id }) => ({
      label: stateRegistration,
      value: id
    })),
    [currentPropertyDairyFarmIEs]
  )

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({ ...data })
    },
    [onSubmit]
  )

  const options = useMemo(
    () => ([
      {
        label: t('protein'),
        value: 'protein'
      },
      {
        label: t('fat'),
        value: 'fat'
      },
      {
        label: t('ccs'),
        value: 'ccs'
      },
      {
        label: t('ctb'),
        value: 'ctb'
      },
      {
        label: t('nul'),
        value: 'nul'
      },
      {
        label: t('lactose'),
        value: 'lactose'
      }
    ]
    ), [t]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ milkRegisterQualitySchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <HalfGroup>
                <HalfItem>
                  <InputSelect
                    name="userStateRegistrationId"
                    label={ t('state registration') }
                    defaultValue={ defaultValues?.userStateRegistrationId }
                    options={ iesOptions }
                    style={ { paddingRight: 5 } }
                  />
                </HalfItem>
                <HalfItem>
                  <InputDate
                    label={ t('gathering date') }
                    name="date"
                    fullWidth
                    pickerProps={
                      {
                        maxDate: moment().format()
                      }
                    }
                    defaultValue={ defaultValues.date }
                  />
                </HalfItem>
              </HalfGroup>
            </Item>
            <ItemContainer>
              <HalfGroup>
                <HalfItem>
                  <InputSelect
                    label={ t('type') }
                    name='type-protein'
                    options={ options }
                    style={ { paddingBottom: 10, paddingRight: 5 } }
                    clearable="true"
                    value='protein'
                    disabled
                    detached
                  />
                </HalfItem>
                <HalfItem>
                  <InputUnit
                    label={ t('result') }
                    name="protein"
                    fullWidth
                    defaultValue={ defaultValues.protein }
                    unit="%"
                  />
                </HalfItem>
              </HalfGroup>
              <HalfGroup>
                <HalfItem>
                  <InputSelect
                    label={ t('type') }
                    name='type-fat'
                    options={ options }
                    style={ { paddingBottom: 10, paddingRight: 5 } }
                    clearable="true"
                    value='fat'
                    disabled
                    detached
                  />
                </HalfItem>
                <HalfItem>
                  <InputUnit
                    label={ t('result') }
                    name="fat"
                    fullWidth
                    defaultValue={ defaultValues?.fat }
                    unit="%"
                  />
                </HalfItem>
              </HalfGroup>
              <HalfGroup>
                <HalfItem>
                  <InputSelect
                    label={ t('type') }
                    name='type-ccs'
                    options={ options }
                    style={ { paddingBottom: 10, paddingRight: 5 } }
                    clearable="true"
                    value='ccs'
                    disabled
                    detached
                  />
                </HalfItem>
                <HalfItem>
                  <InputUnit
                    name="ccs"
                    label={ t('result') }
                    unit="células/ml"
                    type="integer"
                    fullWidth
                    defaultValue={ defaultValues?.ccs }
                  />
                </HalfItem>
              </HalfGroup>
              <HalfGroup>
                <HalfItem>
                  <InputSelect
                    label={ t('type') }
                    name='type-ctb'
                    options={ options }
                    style={ { paddingBottom: 10, paddingRight: 5 } }
                    clearable="true"
                    value='ctb'
                    disabled
                    detached
                  />
                </HalfItem>
                <HalfItem>
                  <InputUnit
                    name="ctb"
                    label={ t('result') }
                    unit="UFC/ml"
                    type="integer"
                    fullWidth
                    defaultValue={ defaultValues?.ctb }
                  />
                </HalfItem>
              </HalfGroup>
              <HalfGroup>
                <HalfItem>
                  <InputSelect
                    label={ t('type') }
                    name='type-nul'
                    options={ options }
                    style={ { paddingBottom: 10, paddingRight: 5 } }
                    clearable="true"
                    value='nul'
                    disabled
                    detached
                  />
                </HalfItem>
                <HalfItem>
                  <InputUnit
                    name="nul"
                    label={ t('result') }
                    unit="mg/dL"
                    type="float"
                    fullWidth
                    defaultValue={ defaultValues?.nul }
                  />
                </HalfItem>
              </HalfGroup>
              <HalfGroup>
                <HalfItem>
                  <InputSelect
                    label={ t('lactose') }
                    name='type-lactose'
                    options={ options }
                    style={ { paddingBottom: 10, paddingRight: 5 } }
                    clearable="true"
                    value='lactose'
                    disabled
                    detached
                  />
                </HalfItem>
                <HalfItem>
                  <InputUnit
                    name="lactose"
                    label={ t('result') }
                    unit="%"
                    type="float"
                    fullWidth
                    defaultValue={ defaultValues?.lactose }
                  />
                </HalfItem>
              </HalfGroup>
            </ItemContainer>
          </Grid>
        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-cancel-form-button"
              onClick={ onCancel }
              color={ colors.white }
              disabled={ loading }
              style={ { marginRight:10, marginLeft: 10 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="web-save-form-button"
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
              style={ { marginRight:10, marginLeft: 10 } }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

RegisterMilkQualityForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  defaultValues: PropTypes.object,
  withoutSubmitButton: PropTypes.bool
}

RegisterMilkQualityForm.defaultProps = {
  loading: false,
  defaultValues: {},
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false
}

export default RegisterMilkQualityForm
