import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import find from 'lodash/find'

import Grid from '@material-ui/core/Grid'

import { useT } from '@smartcoop/i18n'
import {
  pencil
} from '@smartcoop/icons'
import { formatCpfCnpj, formatCep } from '@smartcoop/utils/formatters'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'

import {
  Cnpj,
  Actions,
  Text
} from './styles'


const Address = (props) => {
  const {
    deliveryLocations,
    style,
    isUserAddress,
    onEdit,
    readOnly,
    fields
  } = props

  const t = useT()

  const currentLocationBilling = useMemo(() => find(deliveryLocations, loc => loc.id === fields?.billingOrganizationId), [fields, deliveryLocations])

  const streetWithNumber = useMemo(
    () => (
      fields.street
        ? `${ fields.street }${
          fields.number
            ? `, ${ fields.number }`
            : '' }`
        : null
    ),
    [fields.number, fields.street]
  )

  const cityStateCountry = useMemo(
    () => {
      const { city, state, country } = fields

      if (!city && !state && !country) return null

      return `${
        city || ''
      }${
        state
          ? `${ city ? '/' : '' }${ state }`
          : ''
      }${
        country
          ? ` ${ city || state ? '-' : '' } ${ country }`
          : ''
      }`
    },
    [fields]
  )



  const hasActions = useMemo(
    () => (
      typeof onEdit === 'function'
    ), [onEdit]
  )

  return (
    <div style={ style }>
      <Grid container>
        <Grid item xs={ 10 }>
          <Cnpj>
            {
              isUserAddress ?
                <>
                  {streetWithNumber || t('{this} unknown', { this: t('street'), gender: 'female' })}
                </> :
                <>
              CNPJ {formatCpfCnpj, formatCpfCnpj(fields.companyDocument)} - {fields?.tradeName}
                </>
            }
          </Cnpj>
        </Grid>
        {
          hasActions ?
            <Grid item xs={ 2 }>
              <Actions>
                <IconButton
                  id="edit"
                  onClick={ onEdit }
                  variant="text"
                  style={ { marginRight: 7 } }
                >
                  <Icon icon={ pencil } size={ 14 } style={ { marginRight: '5px' } }/>
                </IconButton>
              </Actions>
            </Grid> :
            <></>
        }

        <Grid item xs={ 12 } sm={ 6 }>
          <Text>
            {
              isUserAddress ?
                <>
                  {cityStateCountry || t('{this} unknown', { this: t('city', { howMany: 1 }), gender: 'female' })}

                </> :
                <>
                  {fields.complement || t('{this} unknown', { this: t('complement'), gender: 'male' })}
                </>
            }
          </Text>
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <Text>
              CEP {formatCep(fields.postalCode)}
          </Text>
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <Text>
            {(fields.neighborhood || fields.district) || t('{this} unknown', { this: t('neighborhood'), gender: 'male' })}
          </Text>
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <Text>
            {
              isUserAddress ?
                <>

                </> :
                <>
                  {cityStateCountry || t('{this} unknown', { this: t('city', { howMany: 1 }), gender: 'female' })}
                </>
            }
          </Text>
        </Grid>
        { readOnly && (
          <Grid item xs={ 12 } sm={ 12 } style={ { marginTop: 5 } }>
            <Text>
              {t('billing cnpj')}: { formatCpfCnpj(currentLocationBilling?.companyDocument) } - { currentLocationBilling?.tradeName }
            </Text>
          </Grid>
        )}
        { readOnly && (
          <Grid item xs={ 12 } sm={ 12 }>
            <Text>
              {t('delivery observations')}: {fields?.note}
            </Text>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

Address.propTypes = {
  style: PropTypes.object,
  isUserAddress: PropTypes.bool,
  readOnly: PropTypes.bool,
  fields: PropTypes.shape({
    companyDocument: PropTypes.string,
    complement: PropTypes.string,
    street: PropTypes.string,
    neighborhood: PropTypes.string,
    district: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    tradeName: PropTypes.string,
    billingOrganizationId: PropTypes.string,
    note: PropTypes.string,
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  onEdit: PropTypes.func,
  deliveryLocations: PropTypes.array.isRequired
}

Address.defaultProps = {
  style: {},
  isUserAddress: false,
  readOnly: false,
  onEdit: null
}

export default Address
