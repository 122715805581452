import React, { useCallback, useRef } from 'react'
import { useSelector , useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { barnYellow, edit, productivityChallange, trash, userTransfer } from '@smartcoop/icons'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectFamilyGroupAccess, selectIsOwner, selectPropertyAcceptedTermChallenges } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import { formatNumber } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import VerticalDotsIconButton from '@smartcoop/web-components/IconButton/VerticalDotsIconButton'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import Popover from '@smartcoop/web-components/Popover'
import Tooltip from '@smartcoop/web-components/Tooltip'
import EditPropertyModal from '@smartcoop/web-containers/modals/EditPropertyModal'
import TransferPropertyModal from '@smartcoop/web-containers/modals/TransferPropertyModal'

import {
  CardItem,
  FarmName,
  AreasContainer,
  AreaContainer,
  InfoContentTitle,
  InfoContent,
  DotsContainer,
  ButtonContainer
} from './styles'

const PropertyResumeCard = ({ property, moduleIsTechnical }) => {
  const { createDialog } = useDialog()
  const dispatch = useDispatch()
  const t = useT()
  const popoverRef = useRef(null)

  const userWrite = useSelector(selectUserCanWrite)
  const familyGroupAccess = useSelector(selectFamilyGroupAccess)
  const challenges = useSelector(selectPropertyAcceptedTermChallenges)
  const isOwner = useSelector(selectIsOwner)

  const editProperty = useCallback(propertyLocal => {
    dispatch(PropertyActions.setEditPropertyData(propertyLocal.id, propertyLocal))

    createDialog({
      id: 'property-onboarding',
      Component: EditPropertyModal
    })
  }, [createDialog, dispatch])

  const transferProperty = useCallback(() => {
    createDialog({
      id: 'property-transfer',
      Component: TransferPropertyModal
    })
  }, [createDialog])

  const deleteProperty = useCallback(propertyLocal => {
    createDialog({
      id: 'confirm-delete',
      Component: ConfirmModal,
      props: {
        onConfirm: () => {
          dispatch(PropertyActions.deleteProperty(propertyLocal.id))
          dispatch(PropertyActions.saveCurrentProperty({}))
        },
        textWarning: t('are you sure you want to delete the {this}?', {
          howMany: 1,
          gender: 'female',
          this: t('property', {
            howMany: 1
          })
        })
      }
    })
  }, [createDialog, dispatch, t])

  return (
    <CardItem
      headerLeft={ !isEmpty(challenges) && (
        <div style={ { marginLeft: 20 } }>
          <Tooltip title={ <div>{map(challenges, challenge => <p>{challenge?.project?.projectName}</p>)}</div> }>
            <Icon icon={ productivityChallange } size={ 14 } style={ { marginRight: 3 } }/>
          </Tooltip>
        </div>
      ) }
      headerRight={ (
        <DotsContainer>
          {!familyGroupAccess
            ? (
              <Popover
                ref={ popoverRef }
                popoverId="property-actions"
                Component={ VerticalDotsIconButton }
                ComponentProps={ {
                  color: colors.black,
                  edge: 'end',
                  iconColor: colors.black
                } }
              >
                <ButtonContainer>
                  {isOwner ? (
                    <Button
                      onClick={ () =>{
                        transferProperty()
                        popoverRef.current.onClose()
                      } }
                      color={ colors.white }
                      id="transfer-property"
                      disabled={ !userWrite }
                    >
                      <>
                        <Icon style={ { marginRight: 10 } } icon={ userTransfer } size={ 15 } color={ colors.primary } />
                        <I18n>transfer property</I18n>
                      </>
                    </Button>
                  ) : null}
                  <Button
                    onClick={ () =>{
                      editProperty(property)
                      popoverRef.current.onClose()
                    } }
                    color={ colors.white }
                    id="edit-property"
                    disabled={ !userWrite }
                  >
                    <>
                      <Icon style={ { marginRight: 10 } } icon={ edit } size={ 15 } color={ colors.primary } />
                      <I18n>edit property</I18n>
                    </>
                  </Button>
                  {!moduleIsTechnical && (
                    <Button
                      onClick={ () => {
                        deleteProperty(property)
                        popoverRef.current.onClose()
                      } }
                      color={ colors.white }
                      id="delete-property"
                      disabled={ !userWrite }
                    >
                      <>
                        <Icon style={ { marginRight: 10 } } icon={ trash } size={ 15 } color={ colors.red } />
                        <I18n style={ { color: colors.red } }>delete property</I18n>
                      </>
                    </Button>
                  )}
                </ButtonContainer>
              </Popover>
            )
            : (
              <div style={ { marginTop: 42 } } />
            )
          }
        </DotsContainer>
      ) }
    >
      <Icon
        icon={ barnYellow }
        size={ 55 }
      />

      <FarmName>
        {property?.name}
      </FarmName>

      <AreasContainer>
        <AreaContainer>
          <I18n as={ InfoContentTitle }>
            total area
          </I18n>
          <InfoContent>
            {formatNumber(property?.totalArea)} ha
          </InfoContent>
        </AreaContainer>
      </AreasContainer>

    </CardItem>
  )}

PropertyResumeCard.propTypes = {
  property: PropTypes.object.isRequired,
  moduleIsTechnical: PropTypes.bool
}

PropertyResumeCard.defaultProps = {
  moduleIsTechnical: false
}

export default PropertyResumeCard
