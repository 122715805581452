export const convertToArray = value => JSON.parse(value || '[]').map(item => Number(item))

export const PROFILES = {
  SUPPLIER: 1,
  TECHNICIAN: 6,
  PRODUCTOR: 2,
  FAMILY_MEMBER: 3,
  BUYER: 4,
  ADMIN: 5,
  SMARTCOOP: 11
}

export const USER_TYPE = {
  FAMILY: 'family',
  CCGL: 'CCGL',
  ADDED: 'added',
  COOPERATIVE: 'cooperative',
  PRODUCERS_OUT_OF_WALLET: 'producersOutOfWallet',
  TECHNICIAN: 'technician',
  WALLET_PRODUCERS: 'walletProducers'
}

export const checkIsSupplier = value => convertToArray(value).includes(PROFILES.SUPPLIER)
