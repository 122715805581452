import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${ colors.backgroundHtml };
  padding: ${ props => {
    if(props.paddingNone) return 0
    return props.hasChildren ? '20px' : '10px'} };
  border-radius: 5px;
  color: ${ colors.black };
`

export const Top = styled.div`
  display: flex;

`

export const Title = styled.div`
  font-weight: bold;
  margin-left: 8px;
  margin-top: 2.5px;
  font-size: 16px;
`

export const Text = styled.div`
  margin-top: 10px;
`
