import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AccountActions } from '@smartcoop/stores/account'
import {
  selectAccounts,
  selectCurrentAccount
} from '@smartcoop/stores/account/selectorAccount'
import AccountBalance from '@smartcoop/web-components/AccountBalance'
import Button from '@smartcoop/web-components/Button'
import EyeClosedIconButton from '@smartcoop/web-components/IconButton/EyeClosedIconButton'
import EyeOpenedIconButton from '@smartcoop/web-components/IconButton/EyeOpenedIconButton'
import PartialLoading from '@smartcoop/web-components/PartialLoading'

import {
  ContainerAccountBalance,
  HeaderAccountBalance,
  Title,
  TextNoHaveAccounts
} from './styles'

const AccountBalanceListFragment = ({
  clickable,
  onClick: externalOnClick,
  withoutHeader,
  withCreditLimit
}) => {
  const [AccountBalanceMasked, setAccountBalanceMasked] = useState(true)
  const [showAccountButton, setShowAccountButton] = useState(true)
  const [loading, setLoading] = useState(false)
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const t = useT()
  const [mounted, setMounted] = useState(false)

  const accounts = useSelector(selectAccounts)
  const currentAccount = useSelector(selectCurrentAccount)

  const EyeIconButton = useMemo(
    () =>
      AccountBalanceMasked
        ? EyeClosedIconButton
        : EyeOpenedIconButton,
    [AccountBalanceMasked]
  )

  const onClick = useCallback(
    (account) => {
      if (clickable) {
        dispatch(AccountActions.setCurrentAccount(account))
        externalOnClick()
      }
    },
    [clickable, dispatch, externalOnClick]
  )

  const handleEyeClick = useCallback(
    () => {
      setAccountBalanceMasked((old) => {
        if (!old) {
          setLoading(true)
          dispatch(
            AccountActions.loadAccounts(
              () => setLoading(false),
              (err) => {
                setLoading(false)
                snackbar.error(t(err.message))
              }
            )
          )
        }

        return !old
      })
    },
    [dispatch, snackbar, t]
  )

  const handleShowAccount = useCallback(
    () => {
      setLoading(true)
      setAccountBalanceMasked(false)
      setShowAccountButton(false)
      dispatch(
        AccountActions.loadAccounts(
          () => setLoading(false),
          (err) => {
            setLoading(false)
            snackbar.error(t(err.message))
          }
        )
      )
    },
    [dispatch, snackbar, t]
  )


  useEffect(
    () => {
      if (!mounted) {
        setMounted(true)

        if (withoutHeader) {
          setLoading(true)
          dispatch(
            AccountActions.loadAccounts(
              () => setLoading(false),
              (err) => {
                setLoading(false)
                snackbar.error(t(err.message))
              }
            )
          )
        }
      }
    },
    [dispatch, mounted, snackbar, t, withoutHeader]
  )


  useEffect(
    () => {
      if (!mounted) {
        setMounted(true)

        if (withoutHeader) {
          setLoading(true)
          dispatch(
            AccountActions.loadAccounts(
              () => setLoading(false),
              (err) => {
                setLoading(false)
                snackbar.error(t(err.message))
              }
            )
          )
        }
      }
    },
    [dispatch, mounted, snackbar, t, withoutHeader]
  )

  return (
    <ContainerAccountBalance>
      {!withoutHeader && (
        <HeaderAccountBalance>
          <div
            style={ {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between'
            } }
          >
            <Title>
              <I18n>account balance</I18n>
            </Title>
            {showAccountButton && (
              <div>
                <Button
                  onClick={ () => handleShowAccount() }
                  style={ { fontSize: '12px' } }
                >
                  Mostrar Contas
                </Button>
              </div>
            )}
            {!showAccountButton && (
              <EyeIconButton
                tooltip={
                  AccountBalanceMasked ? t('show balance') : t('hide balance')
                }
                size="medium"
                onClick={ () => handleEyeClick() }
              />
            )}
          </div>
        </HeaderAccountBalance>
      )}
      <PartialLoading open={ loading }>
        {!isEmpty(accounts) ? (
          map(accounts, (account) => (
            <AccountBalance
              hideBalance={ AccountBalanceMasked && !withoutHeader }
              accountName={ account.accountName }
              balance={ account.currentBalance }
              active={ currentAccount?.id === account.id }
              onClick={ () => onClick(account) }
              style={ clickable ? { cursor: 'pointer' } : {} }
              key={ account.id }
              creditLimit={
                withCreditLimit
                  ? {
                    renovationDate: account?.renovationDate,
                    creditLimit: account?.creditLimit
                  }
                  : {}
              }
            />
          ))
        ) : (
          <TextNoHaveAccounts>
            <I18n>
              {showAccountButton ? 'click on show accounts' : 'there are no registered accounts'}
            </I18n>
          </TextNoHaveAccounts>
        )}
      </PartialLoading>
    </ContainerAccountBalance>
  )
}

AccountBalanceListFragment.propTypes = {
  clickable: PropTypes.bool,
  onClick: PropTypes.func,
  withoutHeader: PropTypes.bool,
  withCreditLimit: PropTypes.bool
}

AccountBalanceListFragment.defaultProps = {
  clickable: false,
  onClick: () => {},
  withoutHeader: false,
  withCreditLimit: false
}

export default AccountBalanceListFragment
