import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
`

export const WhiteContainer = styled.div`
  background: ${ colors.white };
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 20px;
`
export const LeftSide = styled.div`
  font-size: 16px;
  display: flex;
  align-content: center;
  align-items: center;
`
export const SelectedProposal = styled.span`
  font-weight: bold;
  margin-right: 3px;
`

export const Label = styled.h3`
  display: flex;
`
