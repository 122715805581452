import React, { useCallback, forwardRef, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import snakeCase from 'lodash/snakeCase'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import {  download } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { OperationsActions } from '@smartcoop/stores/operations'
import { selectOperations } from '@smartcoop/stores/operations/selectorOperations'
import { selectIsProfileRTC } from '@smartcoop/stores/organization/selectorOrganization'
import colors from '@smartcoop/styles/colors'
import { STAMP_COLORS , ACCREDITMENT_STATUS_COLORS, ACCREDITMENT_STATUS_NAMES } from '@smartcoop/utils/constants'
import { momentFriendlyShortDateFormat } from '@smartcoop/utils/dates'
import { generateKML } from '@smartcoop/utils/files'
import Badge from '@smartcoop/web-components/Badge'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import CreateOperationModal from '@smartcoop/web-containers/modals/operations/CreateOperationModal'

import RefuseModal from '../../../modals/RefuseModal/RefuseModal'
import { Container } from './styles'

const OperationList = forwardRef(({ filters }, tableRef) => {
  const t = useT()
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()
  const dispatch = useDispatch()

  const operations = useSelector(selectOperations)
  const isRTC = useSelector(selectIsProfileRTC)

  useEffect(() => {
    if(!isRTC) {
      dispatch(OperationsActions.checkAccredited())
    }
    dispatch(
      OperationsActions.loadOperations(
        { ...filters }
      )
    )

  },[dispatch, filters, isRTC])

  const columns = useMemo(
    () => [
      {
        title: t('technical', { howMany: 1, gender: 'male' }),
        field: 'technician.name',
        sorting: true
      },
      {
        title: t('organization', { howMany: 1 }),
        field: 'organizationUser.organization.tradeName',
        sorting: true
      },
      {
        title: t('producer', { howMany: 1, gender: 'male' }),
        field: 'proprietary.name',
        sorting: true
      },
      {
        title: t('field', { howMany: 1 }),
        field: 'field.fieldName',
        sorting: true
      },
      {
        title: t('requesting date'),
        field: 'requestDate',
        render: (row) => moment(row.requestDate, 'YYYY-MM-DD HH:mm:ss').format(momentFriendlyShortDateFormat),
        sorting: true
      },
      {
        title: t('evaluation date'),
        field: 'accreditationDate',
        render: (row) => row.accreditationDate ? moment(row.accreditationDate, 'YYYY-MM-DD HH:mm:ss').format(momentFriendlyShortDateFormat) : '-',
        sorting: true
      },
      {
        title: t('expiration date'),
        field: 'expirationDate',
        render: (row) => row.expirationDate ? moment(row.expirationDate, 'YYYY-MM-DD HH:mm:ss').format(momentFriendlyShortDateFormat) : '-',
        sorting: true
      },
      {
        title: t('stamp'),
        field: 'stamp',
        sorting: true,
        align: 'center',
        render: (row) => {
          if(row?.stamp) {
            return (
              <Badge
                backgroundColorBadge={ STAMP_COLORS[snakeCase(row?.stamp)] }
                colorBadge={ STAMP_COLORS[snakeCase(row?.stamp)] }
              >
                { t(snakeCase(row?.stamp)) }
              </Badge>
            )
          }
          return '-'
        }
      },
      {
        title: t('participation status'),
        field: 'requestStatus',
        sorting: true,
        align: 'center',
        render: (row) => (
          <Badge
            backgroundColorBadge={ ACCREDITMENT_STATUS_COLORS[row?.requestStatus] }
            colorBadge={ ACCREDITMENT_STATUS_COLORS[row?.requestStatus] }
          >
            {ACCREDITMENT_STATUS_NAMES[row?.requestStatus]}
          </Badge>
        )
      }
    ], [t]
  )

  const revokeOperation = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete-operation',
        Component: RefuseModal,
        props: {
          onConfirm: (reason) => {
            dispatch(
              OperationsActions.updateOperation(
                null,
                {
                  ...row,
                  id: row?.id,
                  motive: reason,
                  requestStatus: 'RECUSADO'
                },
                () => {
                  snackbar.success(
                    t('operation removed')
                  )
                  dispatch(
                    OperationsActions.loadOperations(
                      { ...filters }
                    )
                  )
                }
              )
            )
          }
        }
      })
    },
    [createDialog, dispatch, filters, snackbar, t]
  )

  const handleCreate = useCallback(
    () => {
      dispatch(
        OperationsActions.loadOperations(
          { ...filters }
        )
      )
    },
    [dispatch, filters]
  )

  const createNewOperation = useCallback(
    (_, row) => {
      createDialog({
        id: 'create-new-operation',
        Component: CreateOperationModal,
        props: {
          onSubmit: handleCreate,
          defaultValues: row,
          readOnly: row?.requestStatus === 'APROVADO' && !isRTC
        }
      })
    },
    [createDialog, handleCreate, isRTC]
  )

  const actions = useMemo(
    () => [
      (row) => ({
        position: 'row',
        onClick: () => !isEmpty(row?.fieldData?.polygonCoordinates) ? generateKML(JSON.parse(row?.fieldData?.polygonCoordinates), row?.field?.fieldName) : {},
        tooltip: t('download kml'),
        iconButtonProps: {
          id: 'download-kml',
          variant: 'outlined',
          size: 'small',
          color: 'black',
          style: {
            width: 30,
            marginRight: 10,
            height: 30,
            borderRadius: 5,
            color: colors.black
          }
        },
        icon: () => !isEmpty(row?.fieldData?.polygonCoordinates) ? <Icon icon={ download } size={ 14 } /> : null

      })
    ],
    [t]
  )

  return (
    <Container>
      <DataTable
        actions={ actions }
        tableRef={ tableRef }
        columns={ columns }
        data={ orderBy(operations, 'requestDate', 'desc') }
        id="projects-list-table"
        onDeleteClick={ isRTC ? revokeOperation : null }
        disabledRow={ (row) => row?.requestStatus === 'RECUSADO' }
        conditionToDelete={ (row) => row?.requestStatus !== 'RECUSADO' }
        onRowClick={ (_, row) => isRTC || row?.requestStatus === 'DOC_PENDENTE' ||  row?.requestStatus === 'APROVADO' ? createNewOperation(_, row) : null }
      />
    </Container>
  )
})

OperationList.propTypes = {
  filters: PropTypes.object
}

OperationList.defaultProps = {
  filters: {}
}

export default OperationList
