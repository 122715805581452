import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { select, call, all, put, takeLatest } from 'redux-saga/effects'

import { v4 as uuidv4 } from 'uuid'

import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'

import {
  createAnimalPregnancyDiagnostic,
  editAnimalPregnancyDiagnostic,
  getAnimalPregnancyDiagnostic,
  getAllAnimalPregnancyDiagnostics,
  deleteAnimalPregnancyDiagnostics
} from '@smartcoop/services/apis/smartcoopApi/resources/animalPregnancyDiagnostics'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { OfflineAnimalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineAnimalUserData/duckOfflineAnimalUserData'
import { OfflineTechnicalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData'
import { selectOfflineTechnicalAnimals } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData/selectorOfflineTechnicalUserData'

import { selectModuleIsTechnical } from '../module/selectorModule'
import { selectIsConnected } from '../network/selectorNetwork'
import { selectOfflineAnimalStatus, selectOfflineDiagnosticTypes, selectOfflineAnimals } from '../offlineData/userData/offlineAnimalUserData/selectorOfflineAnimalUserData'
import {
  AnimalPregnancyDiagnosticsActions,
  AnimalPregnancyDiagnosticsTypes
} from './duckAnimalPregnancyDiagnostics'

function* savePregnancyDiagnostic({
  params,
  onSuccess = () => {},
  onError = () => {},
  isEdit = false
}) {
  try {
    const { data } = yield call(
      !isEdit
        ? createAnimalPregnancyDiagnostic
        : editAnimalPregnancyDiagnostic,
      params,
      {
        animalId: params?.animalsId?.[0],
        pregnancyId: params.id
      }
    )
    yield call(onSuccess, data)

  } catch (err) {
    const error = err.message
    yield put(AnimalPregnancyDiagnosticsActions.pregnancyDiagnosticError(error))
    yield call(onError, error)
  }
}

function* deletePregnancyDiagnostic({
  diagnosisId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { data } = yield call(deleteAnimalPregnancyDiagnostics, { diagnosisId })
    yield call(onSuccess, data)

  } catch (err) {
    const error = err.message
    yield put(AnimalPregnancyDiagnosticsActions.pregnancyDiagnosticError(error))
    yield call(onError, error)
  }
}

function* deleteOfflinePregnancyDiagnostic({ diagnosisId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(OfflineAnimalUserDataActions.removeOfflineDiagnosis(diagnosisId))

    yield put(AnimalPregnancyDiagnosticsActions.deletePregnancyDiagnostic(diagnosisId, onSuccess, onError))
  } catch (err) {
    const error = err.message
    yield put(AnimalPregnancyDiagnosticsActions.inseminationError(error))
    yield call(onError, error)
  }
}

function* saveOfflinePregnancyDiagnostic({
  params,
  onSuccess = () => {},
  onError = () => {},
  web = false
}) {
  try {
    const isTechnical = yield select(selectModuleIsTechnical)
    const diagnosticTypes = web ? null : yield select(selectOfflineDiagnosticTypes)
    const status = web ? null : yield select(selectOfflineAnimalStatus)
    const isConnected = web ? true :  yield select(selectIsConnected)
    // eslint-disable-next-line no-nested-ternary
    const animals = web ? null : (isTechnical ? yield select(selectOfflineTechnicalAnimals) : yield select(selectOfflineAnimals))

    if(!web) {
      const newDiagnostics = {
        ...params,
        id: params?.id,
        // eslint-disable-next-line no-unused-vars
        ids: params?.id ? [params?.id] : map(params?.animalsId, _ => uuidv4()),
        diagnosisType: find(diagnosticTypes, item => item?.id === params?.diagnosisTypeId)
      }

      const validateNewStatus = () => {
        if (params?.diagnosisTypeId === '1') {
          return 3
        }
        if(params?.diagnosisTypeId === '2' && params?.result === 'Positivo') {
          return 4
        }
        if(params?.diagnosisTypeId ==='2' && params?.result === 'Vazia') {
          return 1
        }
        return 3
      }

      const updatedAnimals = map(params?.animalsId, id => {
        const foundAnimal = find(animals, each => each.id === id)
        return {
          ...foundAnimal,
          diagnostics: [
            ...filter(foundAnimal?.diagnostics, item => item.id !== params.id),
            newDiagnostics
          ],
          animalStatus: find(status, item => item.id === validateNewStatus()),
          statusId: find(status, item => item.id === validateNewStatus()).id,
          alreadyDiagnosed: !isConnected ? true : null
        }
      })

      if (isTechnical) {
        yield all(updatedAnimals.map(animal => put(OfflineTechnicalUserDataActions.updateOfflineAnimal(animal))))
      } else {
        yield all(updatedAnimals.map(animal => put(OfflineAnimalUserDataActions.updateOfflineAnimal(animal))))
      }

      yield put(AnimalPregnancyDiagnosticsActions.savePregnancyDiagnostic(newDiagnostics, onSuccess, onError, !!params.id ))
    } else {
      yield put(AnimalPregnancyDiagnosticsActions.savePregnancyDiagnostic({ ...params }, onSuccess, onError, !!params.id ))
    }

  } catch (err) {
    const error = err.message
    yield put(AnimalPregnancyDiagnosticsActions.pregnancyDiagnosticError(error))
    yield call(onError, error)
  }
}

function* loadPregnancyDiagnostics({
  params = {},
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const currentAnimal = yield select(selectCurrentAnimal)

    const {
      data: { data, ...pagination }
    } = yield call(
      getAllAnimalPregnancyDiagnostics,
      {
        limit:
          process.env.REACT_APP_FAKE_PAGINATION_SIZE ||
          REACT_APP_FAKE_PAGINATION_SIZE,
        ...params
      },
      { animalId: currentAnimal.id }
    )

    yield put(
      AnimalPregnancyDiagnosticsActions.loadPregnancyDiagnosticsSuccess(
        data,
        pagination.page,
        onSuccess(data)
      )
    )
  } catch (err) {
    const error = err.message
    yield put(AnimalPregnancyDiagnosticsActions.pregnancyDiagnosticError(error))
    yield call(onError, error)
  }
}

function* loadPregnancyDiagnosticsSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* loadPregnancyDiagnostic({
  params,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const currentAnimal = yield select(selectCurrentAnimal)
    const { pregnancyId } = params
    const { data } = yield call(
      getAnimalPregnancyDiagnostic,
      {},
      { animalId: currentAnimal.id, pregnancyId }
    )
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(AnimalPregnancyDiagnosticsActions.pregnancyDiagnosticError(error))
    yield call(onError, err)
  }
}

export default [
  takeLatest(
    AnimalPregnancyDiagnosticsTypes.LOAD_PREGNANCY_DIAGNOSTICS,
    loadPregnancyDiagnostics
  ),
  takeLatest(
    AnimalPregnancyDiagnosticsTypes.LOAD_PREGNANCY_DIAGNOSTICS_SUCCESS,
    loadPregnancyDiagnosticsSuccess
  ),

  takeLatest(
    AnimalPregnancyDiagnosticsTypes.LOAD_PREGNANCY_DIAGNOSTIC,
    loadPregnancyDiagnostic
  ),
  takeLatest(
    AnimalPregnancyDiagnosticsTypes.DELETE_PREGNANCY_DIAGNOSTIC,
    deletePregnancyDiagnostic
  ),
  takeLatest(
    AnimalPregnancyDiagnosticsTypes.DELETE_OFFLINE_PREGNANCY_DIAGNOSTIC,
    deleteOfflinePregnancyDiagnostic
  ),
  takeLatest(
    AnimalPregnancyDiagnosticsTypes.SAVE_PREGNANCY_DIAGNOSTIC,
    savePregnancyDiagnostic
  ),
  takeLatest(
    AnimalPregnancyDiagnosticsTypes.SAVE_OFFLINE_PREGNANCY_DIAGNOSTIC,
    saveOfflinePregnancyDiagnostic
  )
]
