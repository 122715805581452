import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import { colors } from '@smartcoop/styles'

export const Cnpj = styled(Typography)`
  font-size: 14px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${ colors.text };
`

export const Text = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
`
