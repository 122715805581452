import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import I18n from '@smartcoop/i18n'
import { shield, pencil } from '@smartcoop/icons'
import {
  getFamilyGroupAccess
} from '@smartcoop/services/apis/smartcoopApi/resources/familyGroup'
import colors from '@smartcoop/styles/colors'
import { formatCpfCnpj, formatPhoneV2 } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import RegisterFamilyGroupAccessModal from '@smartcoop/web-containers/modals/profile/RegisterFamilyGroupAccessModal'

import { Badge } from './styles'

const FamilyGroupAccessFragment = ({ filterText }) => {
  const tableRef = useRef(null)
  const { createDialog } = useDialog()

  const [renderList, setRenderList] = useState(true)

  useEffect(() => {
    if(!renderList){
      setRenderList(true)
    }
  },[renderList])

  const columns = useMemo(
    () => [
      {
        title: 'Produtor',
        field: 'name'
      },
      {
        title: 'CPF',
        field: 'document',
        render: (row) => formatCpfCnpj(row.document)
      },
      {
        title: 'Telefone',
        field: 'cellPhone',
        render: (row) => formatPhoneV2(row.cellPhone)
      },
      {
        title: 'Acesso',
        field: 'userStatus',
        render: (row) => (
          <Badge
            backgroundColorBadge={ row.userStatus === 'Active' ? '(40, 159, 48, 0.1)' : '(216, 27, 96, 0.1)' }
            colorBadge={ row.userStatus === 'Active' ? '(40, 159, 48, 1)' : '(216, 27, 96, 1)' }
          >
            <I18n>{row.userStatus}</I18n>
          </Badge>
        )
      }
    ], []
  )

  const registerFamilyAccessModal = useCallback(
    (_, row, editPermissions = true) => {
      createDialog({
        id: 'register-family',
        Component: RegisterFamilyGroupAccessModal,
        props: {
          userRequestItem: row,
          tableRef,
          editPermissions,
          setRenderList
        }
      })
    },
    [createDialog]
  )

  const actions = useMemo(
    () => [
      (row) => ({
        position: 'row',
        onClick: () => registerFamilyAccessModal(null, row, false),
        disabled: !row.canBeEdited,
        iconButtonProps: {
          id: 'request-permission',
          variant: 'outlined',
          size: 'small',
          color: 'black',
          style: {
            width: 200,
            height: 30,
            fontSize: 14,
            fontWeight: 600,
            borderRadius: 5,
            color: row.canBeEdited ? colors.black : colors.grey
          }
        },
        icon: () => (
          <>
            <Icon icon={ pencil } size={ 14 } style={ { paddingRight: 5 } } color={ row.canBeEdited ? colors.black : colors.grey }/>
            <I18n>edit</I18n>
          </>
        )
      }),
      (row) => ({
        position: 'row',
        onClick: () => registerFamilyAccessModal(null, row, true),
        iconButtonProps: {
          id: 'request-permission',
          variant: 'outlined',
          size: 'small',
          color: 'black',
          style: {
            width: 200,
            height: 30,
            fontSize: 14,
            fontWeight: 600,
            borderRadius: 5,
            color: colors.black
          }
        },
        icon: () => (
          <>
            <Icon icon={ shield } size={ 14 } style={ { paddingRight: 5 } } />
            <I18n>configure permission</I18n>
          </>
        )
      })
    ],
    [registerFamilyAccessModal]
  )

  const dataTableOptions = useMemo(
    () => ({
      actionsCellDivStyle: {
        alignItems: 'center'
      }
    }),
    []
  )

  // eslint-disable-next-line no-constant-condition
  return (
    <div style={ { display: 'flex', flex: 1, flexDirection: 'column' } }>
      <Button
        id="register-family-access"
        onClick={ registerFamilyAccessModal }
        color={ colors.black }
        style={ { alignSelf: 'flex-end', marginBottom: 15 } }
      >
        <I18n>register family access</I18n>
      </Button>
      {renderList && (
        <DataTable
          tableRef={ tableRef }
          columns={ columns }
          actions={ actions }
          options={ dataTableOptions }
          queryParams={ filterText }
          data={ getFamilyGroupAccess }
          id="family-group-access-table"
        />
      )}
    </div>
  )
}

FamilyGroupAccessFragment.propTypes = {
  filterText: PropTypes.string
}

FamilyGroupAccessFragment.defaultProps = {
  filterText: null
}

export default FamilyGroupAccessFragment
