import moment from 'moment'

import capitalize from 'lodash/capitalize'


export const formatChatDate = (date, t) => {
  const momentDate = moment(date, 'X')
  const momentDateStartDay = moment(date, 'X').startOf('day')

  if (momentDateStartDay.isSame(moment().startOf('day'))) {
    return momentDate.format(`[${ t('today') }] HH:mm`)
  }

  if (momentDateStartDay.isSame(moment().subtract(1, 'day').startOf('day'))) {
    return momentDate.format(`[${ t('yesterday') }] HH:mm`)
  }

  if (momentDateStartDay.isSameOrAfter(moment().startOf('week'))) {
    return capitalize(momentDate.format('dddd HH:mm'))
  }

  return momentDate.format('HH:mm DD/MM/YY')
}
