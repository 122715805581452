import React from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { fullScreen } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import IconButton from '../IconButton'

const FullScreenIconButton = ({ iconColor, ...props }) => {
  const t = useT()
  return (
    <IconButton tooltip={ t('full screen') } { ...props }>
      <Icon icon={ fullScreen } color={ iconColor } />
    </IconButton>
  )
}

FullScreenIconButton.propTypes = {
  iconColor: PropTypes.string
}

FullScreenIconButton.defaultProps = {
  iconColor: colors.mutedText
}

export default FullScreenIconButton
