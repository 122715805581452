import React, { useState, useMemo, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'

import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'

import { ThemeProvider } from '@material-ui/core'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import {
  getPropertaryProductsWithdraw as getPropertaryProductsWithdrawService
} from '@smartcoop/services/apis/smartcoopApi/resources/productWithdraw'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { selectCurrentOwnerId } from '@smartcoop/stores/technical/selectorTechnical'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import InputSearch from '@smartcoop/web-components/InputSearch'
import ProductsWitdrawListFragment from '@smartcoop/web-containers/fragments/commercialization/ProductsWitdrawListFragment'
import CreateOrderModal from '@smartcoop/web-containers/modals/commercialization/CreateOrderModal'

import {
  Container,
  Row,
  ButtonContainer,
  SearchContainer,
  inputSearchTheme,
  TabButton
} from './styles'


const TechnicalCommercializationProductsListScreen = () => {
  const t = useT()
  const tableRef = useRef(null)

  const { createDialog, removeDialog } = useDialog()

  const [listType, setListType] = useState('productsWithdraw')
  const [filterText, setFilterText] = useState('')
  const [debouncedFilterText, setDebouncedFilterText] = useState('')

  const currentOrganization = useSelector(selectCurrentOrganization)
  const currentOwnerId = useSelector(selectCurrentOwnerId)

  const tableFilters = useMemo(
    () => debouncedFilterText ? ({ q: debouncedFilterText }) : ({}),
    [debouncedFilterText]
  )

  const isSalesOrdersList = useMemo(
    () => listType === 'salesOrders',
    [listType]
  )

  const urlParams = useMemo(
    () => ({ organizationId: currentOrganization.id, userId: currentOwnerId }),
    [currentOrganization.id, currentOwnerId]
  )

  const dataTableList = useMemo(
    () => {
      switch (listType) {
        case 'productsWithdraw':
        default:
          return (
            <ProductsWitdrawListFragment
              filters={ tableFilters }
              urlParams={ urlParams }
              service={ getPropertaryProductsWithdrawService }
            />
          )
      }
    },
    [listType, tableFilters, urlParams]
  )

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )

  const onNewOrderClick = useCallback(
    () => {
      createDialog({
        id: 'create-oreder-modal',
        Component: CreateOrderModal,
        props: {
          handleClose: () => {
            const { query } = tableRef.current.state
            tableRef.current.onQueryChange({
              ...query,
              page: 0
            })
            removeDialog({ id: 'create-oreder-modal' })
          }
        }
      })
    },
    [createDialog, removeDialog]
  )

  return (
    <Container>
      <Row>
        <ButtonContainer>
          <TabButton
            id="quotation"
            onClick={ () => setListType('productsWithdraw') }
            color={ listType === 'productsWithdraw' ? colors.black : colors.white }
          >
            <I18n>supplies withdrawal</I18n>
          </TabButton>
        </ButtonContainer>
        <SearchContainer>
          <ThemeProvider theme={ inputSearchTheme }>
            <InputSearch
              detached
              onChange={ onChangeSearchFilter }
              value={ filterText }
              placeholder={ t('search') }
              fullWidth
            />
          </ThemeProvider>
          {isSalesOrdersList && (
            <Button
              id="filter-order"
              onClick={ onNewOrderClick }
              color={ colors.secondary }
              style={ { flex: '0 0 auto', marginBottom: 22, marginLeft: 8 } }
            >
              <I18n>new order</I18n>
            </Button>
          )}
        </SearchContainer>
      </Row>
      {!isEmpty(currentOrganization) && (
        dataTableList
      )}
    </Container>

  )
}

export default TechnicalCommercializationProductsListScreen
