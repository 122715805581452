import { call, put, select, takeLatest, fork, take } from 'redux-saga/effects'

import find from 'lodash/find'

import { getAccountsBalance } from '@smartcoop/services/apis/smartcoopApi/resources/account'

import { OrganizationTypes } from '../organization/duckOrganization'
import { selectCurrentOrganization } from '../organization/selectorOrganization'
import { AccountActions, AccountTypes } from './duckAccount'
import { selectAccounts, selectCurrentAccount } from './selectorAccount'

function* loadAccounts({ onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)

    const {
      data: { data: accounts }
    } = yield call(
      getAccountsBalance,
      {},
      { organizationId: currentOrganization.id }
    )

    yield put(AccountActions.loadAccountsSuccess(accounts, onSuccess))
  } catch (error) {
    yield put(AccountActions.accountsError(error))
    yield call(onError, error)
  }
}

function* updateCurrentAccount() {
  try {
    const accounts = yield select(selectAccounts)
    const currentAccount = yield select(selectCurrentAccount)

    const updatedCurrentAccount = find(accounts, account => account.id === currentAccount.id)

    yield put(AccountActions.setCurrentAccount(updatedCurrentAccount))
  } catch (error) {
    yield put(AccountActions.accountsError(error))
  }
}

function* loadAccountsSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess, { page: 1, totalPages: 1 })
}

function* observeCurrentOrganization() {
  while (true) {
    yield take(OrganizationTypes.SET_CURRENT_ORGANIZATION)
    yield put(AccountActions.resetAccounts())
  }
}

export default [
  fork(observeCurrentOrganization),
  takeLatest(AccountTypes.LOAD_ACCOUNTS, loadAccounts),
  takeLatest(AccountTypes.LOAD_ACCOUNTS_SUCCESS, loadAccountsSuccess),
  takeLatest(AccountTypes.UPDATE_CURRENT_ACCOUNT, updateCurrentAccount)
]
