import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  border-right: 1px solid #cbcbcb;
`

export const ContactsBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 20px;
  overflow-y: scroll;
  max-height: calc(100vh - 120px);
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const CustomInput = styled.input`
  width: 100%;
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid ${ colors.gray };
  font-family: 'Open Sans';
`

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
