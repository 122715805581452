import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Button = styled.button`
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  background-color: ${ colors.white };
  color: ${ colors.text }
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Open Sans';
  cursor: pointer;

  :hover {
    filter: brightness(0.95);
  }
`

export const IconCircle = styled.div`
  background-color: ${ ({ circleColor }) => circleColor };
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`


