import React, { memo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { selectPermissionsResponse } from '@smartcoop/stores/authentication/selectorAuthentication'
import { AVAILABLE_MODULES } from '@smartcoop/stores/module'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { TechnicalActions } from '@smartcoop/stores/technical'
import { UserActions } from '@smartcoop/stores/user'
import { withLayout } from '@smartcoop/web-containers/layouts'
import ChangeOrganizationModal from '@smartcoop/web-containers/modals/ChangeOrganizationModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import RouterSwitch from '../../RouterSwitch'

const TechnicalScreenRouter = (props) => {
  const { routes } = useRoutes()
  const dispatch = useCallback(useDispatch(), [])
  const { createDialog } = useDialog()

  const permissions = useSelector(selectPermissionsResponse)
  const currentOrganization = useSelector(selectCurrentOrganization)

  useEffect(
    () => () => {
      dispatch(AuthenticationActions.loadPermissions())
      dispatch(UserActions.loadUser())
      dispatch(TechnicalActions.resetTechnicalCurrentOwner())
      dispatch(TechnicalActions.resetTechnicalVisits())
    },
    [dispatch]
  )

  useEffect(() => {
    if(isEmpty(currentOrganization)) {
      createDialog({
        id: 'organization-onboarding',
        Component: ChangeOrganizationModal,
        props: {
          module: AVAILABLE_MODULES.technical
        }
      })
    }
  },[createDialog, currentOrganization, permissions, routes])

  return <RouterSwitch routes={ routes } { ...props } />
}

export default memo(withLayout('authenticated')(TechnicalScreenRouter))
