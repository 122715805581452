import hexToRgba from 'hex-to-rgba'
import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: ${ hexToRgba(colors.white, 0.9) };
  z-index: 99;
`
