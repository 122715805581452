import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const barterDetails = ({ t }) => Yup.object().shape({
  barterName: flow(
    required({ t })
  )(Yup.string()),

  cropInternalCode: Yup.string(),

  stateRegistrationId: flow(
    required({ t })
  )(Yup.string())
})

export default barterDetails
