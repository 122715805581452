export const extensionToContentType = {
  // Images
  'png': 'image/png',
  'tiff': 'image/tiff',
  'tif': 'image/tiff',
  'jpg': 'image/jpeg',
  'jpeg': 'image/jpeg',
  'gif': 'image/gif',
  'bmp': 'image/bmp',
  // Videos
  'mkv': 'video/x-matroska',
  'mp4': 'video/mp4',
  'avi': 'video/x-msvideo',
  'wmv': 'video/x-ms-wmv',
  'mov': 'video/quicktime',
  'webm': 'video/webm',
  // Audio
  'mp3': 'audio/mp3',
  'aac': 'audio/aac',
  // Documentos
  // 'doc': 'application/msword',
  'pdf': 'application/pdf',
  'rtf': 'application/rtf',
  'doc': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'xls': 'application/vnd.ms-excel',
  'ppt': 'application/vnd.ms-powerpoint',
  'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'csv': 'text/csv',
  // 'rtf': 'text/rtf',
  'txt': 'text/plain'
}
