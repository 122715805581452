import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
`
export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Text = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 13px;
`
