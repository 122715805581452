import React, { useState, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { SalesOrdersActions } from '@smartcoop/stores/salesOrders'
import Button from '@smartcoop/web-components/Button'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import SignatureOrderForm from '@smartcoop/web-containers/forms/commercialization/SignatureOrderForm'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import useStyles, { Row, Content } from './styles'

const SignatureOrderModal = ({ id, open, handleClose, onSuccess, onCancel }) => {
  const classes = useStyles()
  const signatureElectronicFormRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()
  const snackbar = useSnackbar()

  const clearForm = useCallback(
    () => {
      signatureElectronicFormRef.current.reset()
      setIsLoading(false)
      handleClose()
      onCancel()
    },
    [handleClose, onCancel]
  )

  const submit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(SalesOrdersActions.validationSignatureEletronic(
        data.signatureOrder,
        () => {
          snackbar.success(t('success validate electronic signature'))
          onSuccess()
          setIsLoading(false)
          handleClose()
        },
        () => {
          setIsLoading(false)
          snackbar.error(t('invalid electronic signature'))
        }
      ))
    }, [dispatch, handleClose, onSuccess, snackbar, t]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row>
          <I18n>electronic confirmation</I18n>
        </Row>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { width: 500, padding: 20, paddingTop: 0 } }
      onClose={ clearForm }
    >
      <>
        {isLoading
          ? <Loader/>
          : (
            <Content>
              <SignatureOrderForm
                ref={ signatureElectronicFormRef }
                onSubmit={ submit }
                handleClose={ handleClose }
                withoutSubmitButton
              />
              <ButtonsContainer style={ { marginTop: 18 } }>
                <Button
                  id="cancel"
                  onClick={ () => clearForm() }
                  variant="outlined"
                  style={ { marginRight: 7 } }
                  disabled={ isLoading }
                >
                  <I18n>cancel</I18n>
                </Button>
                <Button
                  id="save"
                  onClick={ () => signatureElectronicFormRef.current.submit() }
                  color="black"
                  style={ { marginLeft: 7 } }
                  disabled={ isLoading }
                >
                  <I18n>confirm</I18n>
                </Button>
              </ButtonsContainer>
            </Content>
          )
        }
      </>
    </Modal>
  )}

SignatureOrderModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func
}

SignatureOrderModal.defaultProps = {
  onCancel: () => {}
}

export default SignatureOrderModal
