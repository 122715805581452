import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { call, put, takeLatest, select } from 'redux-saga/effects'

import {
  createMachine,
  getMachinery,
  editMachine,
  addMachineFiles as addMachineFilesService,
  editMachineFiles as editMachineFilesService,
  deleteMachine as deleteMachineService,
  getMachineryById
} from '@smartcoop/services/apis/smartcoopApi/resources/machine'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'

import { MachineActions, MachineTypes } from './duckMachine'



function* loadMachinery({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: { data, ...pagination } } = yield call(getMachinery, {
      limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
      ...params
    })

    yield put(MachineActions.loadMachinerySuccess(
      data,
      pagination.page,
      () => onSuccess(data)
    ))
  } catch (err) {
    const error = err.message
    yield put(MachineActions.machineError(error))
    yield call(onError, error)
  }
}

function* loadMachinerySuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* saveOfflineMachine({ params, onSuccess = () => {}, onError = () => {} }) {
  yield put(MachineActions.saveMachine(params, onSuccess, onError))
}

function* saveMachine({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(createMachine, params)
    yield call(onSuccess, data)
  } catch (error) {
    yield put(MachineActions.machineError(error))
    yield call(onError, error)
  }
}

function* updateMachine({ params, machineId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(editMachine, params, { machineId })

    const property = yield select(selectCurrentProperty)

    const { data: newCurrentMachine } = yield call(getMachineryById, { propertyId: property?.id }, { machineId: data.id })

    yield put(MachineActions.setCurrentMachine({ ...newCurrentMachine, isUserMachine: true }))

    yield call(onSuccess, data)
  } catch (error) {
    yield put(MachineActions.machineError(error))
    yield call(onError, error)
  }
}

function* addMachineFiles({ params, machineId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(addMachineFilesService, params, { machineId })
    yield call(onSuccess)
  } catch (error) {
    yield put(MachineActions.machineError(error))
    yield call(onError, error)
  }
}

function* editMachineFiles({ params, machineId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(editMachineFilesService, { oldFiles: params }, { machineId })
    yield call(onSuccess)
  } catch (error) {
    yield put(MachineActions.machineError(error))
    yield call(onError, error)
  }
}

function* deleteMachine({ machineId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteMachineService, { machineId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(MachineActions.machineError(error))
    yield call(onError, error)
  }
}


export default [
  takeLatest(MachineTypes.LOAD_MACHINERY, loadMachinery),
  takeLatest(MachineTypes.LOAD_MACHINERY_SUCCESS, loadMachinerySuccess),

  takeLatest(MachineTypes.SAVE_MACHINE, saveMachine),
  takeLatest(MachineTypes.SAVE_OFFLINE_MACHINE, saveOfflineMachine),
  takeLatest(MachineTypes.UPDATE_MACHINE, updateMachine),
  takeLatest(MachineTypes.ADD_MACHINE_FILES, addMachineFiles),
  takeLatest(MachineTypes.DELETE_MACHINE, deleteMachine),
  takeLatest(MachineTypes.EDIT_MACHINE_FILES, editMachineFiles)
]
