// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
      <g>
        <path fill="none" d="M143.4,92.2c0.4,0.1,0.8,0.1,1.2,0.2C144.2,92.4,143.8,92.3,143.4,92.2z"/>
        <path fill="none" d="M154.2,98.7c0.2,0.3,0.4,0.5,0.6,0.8C154.5,99.2,154.4,99,154.2,98.7z"/>
        <path fill="none" d="M153.1,97.4c0.3,0.3,0.5,0.6,0.8,0.9C153.6,98,153.3,97.7,153.1,97.4z"/>
        <path fill="none" d="M141.9,92.1c0.3,0,0.7,0.1,1,0.1C142.6,92.1,142.3,92.1,141.9,92.1z"/>
        <path fill="none" d="M74.3,98.4c0.2-0.2,0.5-0.4,0.7-0.6C74.7,98,74.5,98.2,74.3,98.4z"/>
        <path fill="none" d="M155,100c0.2,0.3,0.3,0.6,0.5,0.8C155.3,100.6,155.2,100.3,155,100z"/>
        <path fill="none" d="M155.8,101.3c0.2,0.3,0.3,0.6,0.4,1C156,102,155.9,101.7,155.8,101.3z"/>
        <path fill="none" d="M73.1,99.5c0.3-0.3,0.6-0.6,0.9-0.9C73.7,98.9,73.4,99.2,73.1,99.5z"/>
        <path fill="none" d="M149.4,94.4c0.3,0.2,0.5,0.3,0.8,0.5C150,94.8,149.7,94.6,149.4,94.4z"/>
        <path fill="none" d="M78.7,95.9c0.4-0.1,0.8-0.3,1.2-0.4C79.5,95.6,79.1,95.7,78.7,95.9z"/>
        <path fill="none" d="M148.1,93.7c0.3,0.1,0.6,0.3,0.8,0.4C148.7,94,148.4,93.8,148.1,93.7z"/>
        <path fill="none" d="M150.7,95.2c0.3,0.2,0.6,0.4,0.8,0.7C151.2,95.7,151,95.4,150.7,95.2z"/>
        <path fill="none" d="M146.7,93.1c0.3,0.1,0.7,0.3,1,0.4C147.3,93.3,147,93.2,146.7,93.1z"/>
        <path fill="none" d="M144.9,92.5c0.5,0.1,0.9,0.3,1.4,0.4C145.8,92.8,145.4,92.6,144.9,92.5z"/>
        <path fill="none" d="M151.8,96.2c0.4,0.3,0.7,0.7,1.1,1C152.5,96.8,152.2,96.5,151.8,96.2z"/>
        <path fill="none" d="M84,95.1c0.4,0,0.8,0.1,1.2,0.1C84.8,95.2,84.4,95.2,84,95.1z"/>
        <path fill="none" d="M76.4,96.9c0.2-0.1,0.5-0.3,0.7-0.4C76.9,96.7,76.7,96.8,76.4,96.9z"/>
        <path fill="none" d="M140.9,92c-0.2,0-0.5,0-0.7,0c0.4,0,0.8,0,1.2,0C141.2,92,141.1,92,140.9,92z"/>
        <path fill="none" d="M83.2,95.1c-0.1,0-0.3,0-0.4,0c0.3,0,0.6,0,1,0C83.6,95.1,83.4,95.1,83.2,95.1z"/>
        <path fill="none" d="M77.5,96.3c0.3-0.1,0.6-0.2,0.8-0.3C78.1,96.1,77.8,96.2,77.5,96.3z"/>
        <path fill="none" d="M80.1,95.5c0.3-0.1,0.7-0.1,1-0.2C80.8,95.3,80.4,95.4,80.1,95.5z"/>
        <path fill="none" d="M81.5,95.2c0.3,0,0.6-0.1,0.8-0.1C82.1,95.2,81.8,95.2,81.5,95.2z"/>
        <path fill="none" d="M138.6,92.2c0.5-0.1,1-0.1,1.4-0.1C139.5,92.1,139,92.1,138.6,92.2z"/>
        <path fill="none" d="M73,99.6c-0.2,0.2-0.4,0.5-0.6,0.7c0,0,0,0,0,0C72.5,100.1,72.8,99.9,73,99.6z"/>
        <path fill="none" d="M75.3,97.6c0.2-0.2,0.4-0.3,0.7-0.4C75.8,97.3,75.6,97.4,75.3,97.6z"/>
        <path fill="none" d="M156.4,102.8c0.2,0.5,0.3,1,0.5,1.5C156.7,103.7,156.5,103.2,156.4,102.8z"/>
        <path fill="none" d="M136.8,92.6c0.5-0.1,1-0.2,1.5-0.3C137.8,92.3,137.3,92.4,136.8,92.6z"/>
        <path fill="none" d="M90.1,87.5c-1.4,2.5-2.3,5.3-2.8,8.2c-0.1,0-0.2-0.1-0.3-0.1c0.1,0,0.2,0,0.3,0.1
          C87.8,92.8,88.8,90,90.1,87.5z"/>
        <path fill="none" d="M85.5,95.3c0.4,0.1,0.8,0.1,1.2,0.3C86.3,95.5,85.9,95.4,85.5,95.3z"/>
        <path fill="#DEDDDD" d="M70.9,102.6c0.1-0.2,0.3-0.5,0.4-0.7C71.2,102.1,71,102.3,70.9,102.6z"/>
        <path fill="#DEDDDD" d="M62.2,124.3c-0.2-0.1-0.3-0.2-0.5-0.3C61.8,124.1,62,124.2,62.2,124.3z"/>
        <path fill="#DEDDDD" d="M71.6,101.4c0.2-0.2,0.3-0.5,0.5-0.7C71.9,101,71.7,101.2,71.6,101.4z"/>
        <path fill="#DEDDDD" d="M61.3,123.8c-0.2-0.1-0.3-0.3-0.5-0.4C61,123.5,61.1,123.6,61.3,123.8z"/>
        <path fill="#DEDDDD" d="M65.1,125c0.1,0,0.3,0,0.4,0c-0.3,0-0.5,0-0.8,0C64.9,125,65,125,65.1,125z"/>
        <path fill="#DEDDDD" d="M63.2,124.7c-0.2-0.1-0.4-0.1-0.5-0.2C62.8,124.6,63,124.7,63.2,124.7z"/>
        <path fill="#DEDDDD" d="M64.2,125c-0.2,0-0.4-0.1-0.6-0.1C63.8,124.9,64,124.9,64.2,125z"/>
        <path fill="#DEDDDD" d="M60.5,123.1c-0.2-0.2-0.3-0.3-0.4-0.5C60.2,122.7,60.3,122.9,60.5,123.1z"/>
        <path fill="#DEDDDD" d="M70.3,103.8c0.1-0.3,0.2-0.5,0.4-0.8C70.6,103.3,70.5,103.5,70.3,103.8z"/>
        <path fill="#DEDDDD" d="M72.3,100.3C72.3,100.4,72.3,100.4,72.3,100.3C72.3,100.4,72.3,100.4,72.3,100.3
          C72.4,100.4,72.4,100.3,72.3,100.3z"/>
        <path fill="#DEDDDD" d="M69.8,105.4c0.1-0.4,0.3-0.8,0.4-1.3C70,104.6,69.9,105,69.8,105.4z"/>
        <path fill="#DEDDDD" d="M89.4,131H65.7c-0.2,0-0.4,0-0.6,0c-5.6,0-10.6-3.8-12.1-9.3c-0.9-3.2-0.4-6.6,1.3-9.5
          c1.7-2.9,4.4-5,7.6-5.8l0,0c0.3-0.1,0.9-0.2,1.7-0.5c0.1-0.7,0.3-1.4,0.4-2.1c1.1-3.9,3.3-7.3,6.2-9.9
          C59.5,59.9,65.8,24.8,86.9,3.2c-7.1,2.2-14,5.1-20.7,9C18.9,39.5,2.6,100,30,147.3c27.3,47.3,87.9,63.6,135.2,36.2
          c6.7-3.9,12.7-8.4,18.1-13.4C151.2,178.3,113.8,163,89.4,131z"/>
        <path fill="#DEDDDD" d="M59.2,121.3c-0.2-0.3-0.3-0.7-0.4-1.1C58.9,120.6,59.1,120.9,59.2,121.3z"/>
        <path fill="#DEDDDD" d="M59.8,122.2c-0.1-0.2-0.3-0.4-0.4-0.6C59.5,121.8,59.6,122,59.8,122.2z"/>
        <path fill="#D3D3D3" d="M156.8,104.3c-0.1-0.5-0.3-1-0.5-1.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.3-0.3-0.6-0.4-1
          c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.3-0.6-0.5-0.8c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.5-0.6-0.8c-0.1-0.1-0.2-0.3-0.3-0.4
          c-0.2-0.3-0.5-0.6-0.8-0.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.4-0.7-0.7-1.1-1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.2-0.5-0.4-0.8-0.7
          c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3-0.2-0.5-0.3-0.8-0.5c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.2-0.6-0.3-0.8-0.4c-0.2-0.1-0.3-0.2-0.5-0.2
          c-0.3-0.1-0.7-0.3-1-0.4c-0.1,0-0.2-0.1-0.4-0.1c-0.5-0.2-0.9-0.3-1.4-0.4c-0.1,0-0.2,0-0.3-0.1c-0.4-0.1-0.8-0.2-1.2-0.2
          c-0.2,0-0.3,0-0.5-0.1c-0.3,0-0.6-0.1-1-0.1c-0.2,0-0.4,0-0.6,0c-0.4,0-0.8,0-1.2,0c-0.1,0-0.1,0-0.2,0c-0.5,0-1,0.1-1.4,0.1
          c-0.1,0-0.2,0-0.3,0.1c-0.5,0.1-1,0.2-1.5,0.3c0,0-0.6,0.2-1.8,0.5C132.2,82.6,122.7,75,111.5,75c-9.2,0-17.2,5.1-21.3,12.6
          c-1.4,2.5-2.3,5.3-2.8,8.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.4-0.1-0.4-0.1c-0.4-0.1-0.8-0.2-1.2-0.3c-0.1,0-0.2,0-0.3,0
          c-0.4-0.1-0.8-0.1-1.2-0.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-1,0c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6,0-0.8,0.1c-0.1,0-0.3,0-0.4,0.1
          c-0.3,0.1-0.7,0.1-1,0.2c-0.1,0-0.1,0-0.2,0c-0.4,0.1-0.8,0.2-1.2,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.2-0.8,0.3
          c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.5,0.2-0.7,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0.1-0.5,0.3-0.7,0.4c-0.1,0.1-0.2,0.2-0.4,0.3
          c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.3-0.6,0.6-0.9,0.9c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.2-0.4,0.5-0.6,0.7
          c0,0,0,0-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.3,0.5-0.5,0.7c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.5-0.4,0.7
          c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.3-0.2,0.5-0.4,0.8c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.4-0.3,0.8-0.4,1.3c-0.5,1.7-0.6,3.5-0.4,5.2
          c-3.7,1-6,1.6-6,1.6c-3.5,0.9-5.5,4.5-4.6,8c0.1,0.4,0.2,0.7,0.4,1.1c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.4,0.4,0.6
          c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.1,0.1,0.3,0.2,0.4,0.3
          c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.2,0.5,0.2c0.2,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0.1,0.6,0.1
          c0.2,0,0.3,0,0.5,0c0.3,0,0.5,0,0.8,0h19.6h55.4c0,0,0.2,0,0.5,0c0.2,0,0.3,0,0.6,0c0.2,0,0.4,0,0.7-0.1c0.2,0,0.5,0,0.8-0.1
          c0.3,0,0.5-0.1,0.8-0.1c0.1,0,0.3,0,0.4-0.1c0.2,0,0.5-0.1,0.7-0.1c0.1,0,0.2,0,0.3-0.1C154,122.1,159.2,113.1,156.8,104.3z"/>
        <path fill="#FFFFFF" d="M78.4,96c0.1,0,0.2-0.1,0.3-0.1C78.6,95.9,78.5,96,78.4,96z"/>
        <path fill="#FFFFFF" d="M75,97.8c0.1-0.1,0.2-0.2,0.4-0.3C75.2,97.7,75.1,97.7,75,97.8z"/>
        <path fill="#FFFFFF" d="M74,98.6c0.1-0.1,0.2-0.1,0.3-0.2C74.2,98.5,74.1,98.5,74,98.6z"/>
        <path fill="#FFFFFF" d="M73,99.6c0.1-0.1,0.1-0.1,0.2-0.2C73.1,99.5,73,99.6,73,99.6z"/>
        <path fill="#FFFFFF" d="M77.1,96.5c0.1-0.1,0.3-0.1,0.4-0.2C77.4,96.4,77.3,96.5,77.1,96.5z"/>
        <path fill="#FFFFFF" d="M76,97.1c0.1-0.1,0.3-0.2,0.4-0.2C76.3,97,76.2,97.1,76,97.1z"/>
        <path fill="#FFFFFF" d="M151.5,95.9c0.1,0.1,0.2,0.2,0.3,0.3C151.7,96.1,151.6,96,151.5,95.9z"/>
        <path fill="#FFFFFF" d="M155.5,100.8c0.1,0.2,0.2,0.3,0.3,0.5C155.7,101.2,155.6,101,155.5,100.8z"/>
        <path fill="#FFFFFF" d="M152.9,97.2c0.1,0.1,0.1,0.1,0.2,0.2C153,97.3,152.9,97.2,152.9,97.2z"/>
        <path fill="#FFFFFF" d="M147.7,93.5c0.2,0.1,0.3,0.2,0.5,0.2C148,93.6,147.8,93.5,147.7,93.5z"/>
        <path fill="#FFFFFF" d="M153.8,98.3c0.1,0.1,0.2,0.3,0.3,0.4C154.1,98.6,154,98.4,153.8,98.3z"/>
        <path fill="#FFFFFF" d="M149,94.1c0.2,0.1,0.3,0.2,0.5,0.3C149.3,94.3,149.1,94.2,149,94.1z"/>
        <path fill="#FFFFFF" d="M150.2,94.9c0.1,0.1,0.3,0.2,0.4,0.3C150.5,95.1,150.4,95,150.2,94.9z"/>
        <path fill="#FFFFFF" d="M154.7,99.5c0.1,0.2,0.2,0.3,0.3,0.5C154.9,99.8,154.8,99.7,154.7,99.5z"/>
        <path fill="#FFFFFF" d="M141.6,125c-0.2,0-0.4,0-0.6,0C141.2,125,141.4,125,141.6,125z"/>
        <path fill="#FFFFFF" d="M142.3,124.9c-0.2,0-0.5,0-0.7,0.1C141.8,124.9,142,124.9,142.3,124.9z"/>
        <path fill="#FFFFFF" d="M144.9,124.5c-0.2,0-0.5,0.1-0.7,0.1C144.4,124.6,144.6,124.6,144.9,124.5z"/>
        <path fill="#FFFFFF" d="M143.8,124.7c-0.3,0-0.5,0.1-0.8,0.1C143.3,124.8,143.5,124.8,143.8,124.7z"/>
        <path fill="#FFFFFF" d="M156.2,102.3c0.1,0.2,0.1,0.3,0.2,0.5C156.3,102.6,156.2,102.5,156.2,102.3z"/>
        <path fill="#FFFFFF" d="M81.1,95.3c0.1,0,0.3,0,0.4-0.1C81.4,95.2,81.2,95.3,81.1,95.3z"/>
        <path fill="#FFFFFF" d="M138.2,92.3c0.1,0,0.2,0,0.3-0.1C138.5,92.2,138.4,92.2,138.2,92.3z"/>
        <path fill="#FFFFFF" d="M140,92.1c0.1,0,0.1,0,0.2,0C140.1,92,140.1,92,140,92.1z"/>
        <path fill="#FFFFFF" d="M85.2,95.3c0.1,0,0.2,0,0.3,0C85.4,95.3,85.3,95.3,85.2,95.3z"/>
        <path fill="#FFFFFF" d="M82.4,95.2c0.2,0,0.3,0,0.5,0C82.7,95.1,82.5,95.1,82.4,95.2z"/>
        <path fill="#FFFFFF" d="M141.4,92c0.2,0,0.4,0,0.6,0C141.8,92.1,141.6,92,141.4,92z"/>
        <path fill="#FFFFFF" d="M79.9,95.5c0.1,0,0.1,0,0.2,0C80,95.5,80,95.5,79.9,95.5z"/>
        <path fill="#FFFFFF" d="M83.8,95.1c0.1,0,0.1,0,0.2,0C83.9,95.1,83.9,95.1,83.8,95.1z"/>
        <path fill="#FFFFFF" d="M144.6,92.4c0.1,0,0.2,0,0.3,0.1C144.8,92.5,144.7,92.5,144.6,92.4z"/>
        <path fill="#FFFFFF" d="M146.3,92.9c0.1,0,0.3,0.1,0.4,0.1C146.5,93,146.4,93,146.3,92.9z"/>
        <path fill="#FFFFFF" d="M142.9,92.2c0.2,0,0.3,0,0.5,0.1C143.3,92.2,143.1,92.2,142.9,92.2z"/>
        <path fill="#FFFFFF" d="M71.3,101.8c0.1-0.1,0.2-0.3,0.3-0.4C71.5,101.6,71.4,101.7,71.3,101.8z"/>
        <path fill="#FFFFFF" d="M70.7,103c0.1-0.1,0.1-0.3,0.2-0.4C70.8,102.7,70.8,102.8,70.7,103z"/>
        <path fill="#FFFFFF" d="M63.6,124.8c-0.2,0-0.3-0.1-0.5-0.1C63.3,124.8,63.5,124.8,63.6,124.8z"/>
        <path fill="#FFFFFF" d="M64.7,125c-0.2,0-0.3,0-0.5,0C64.4,125,64.6,125,64.7,125z"/>
        <path fill="#FFFFFF" d="M72,100.8c0.1-0.1,0.2-0.2,0.3-0.3C72.2,100.5,72.1,100.6,72,100.8z"/>
        <path fill="#FFFFFF" d="M60,122.6c-0.1-0.1-0.2-0.2-0.3-0.3C59.8,122.3,59.9,122.5,60,122.6z"/>
        <path fill="#FFFFFF" d="M62.6,124.5c-0.1-0.1-0.3-0.1-0.4-0.2C62.3,124.4,62.5,124.5,62.6,124.5z"/>
        <path fill="#FFFFFF" d="M60.8,123.4c-0.1-0.1-0.2-0.2-0.3-0.3C60.6,123.2,60.7,123.3,60.8,123.4z"/>
        <path fill="#FFFFFF" d="M59.4,121.6c-0.1-0.1-0.1-0.2-0.2-0.3C59.3,121.4,59.3,121.5,59.4,121.6z"/>
        <path fill="#FFFFFF" d="M70.2,104.2c0-0.1,0.1-0.3,0.2-0.4C70.3,103.9,70.2,104.1,70.2,104.2z"/>
        <path fill="#FFFFFF" d="M61.7,124c-0.1-0.1-0.3-0.2-0.4-0.3C61.4,123.9,61.5,123.9,61.7,124z"/>
      </g>
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
