import React, { useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import CreateSupplierForm from '@smartcoop/web-containers/forms/shoppingPlatform/supplier/CreateSupplierForm'


const CreateSupplierScreen = () => {
  const createOrganizationRef = useRef(null)

  const history = useHistory()

  const onCancel = useCallback(() => history.goBack(), [history])
  const onSuccess = useCallback(() => history.goBack(), [history]
  )

  return (
    <CreateSupplierForm
      ref={ createOrganizationRef }
      onSuccess={ onSuccess }
      onCancel={ onCancel }
    />
  )
}

export default CreateSupplierScreen
