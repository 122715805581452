import React, { useState } from 'react'

import PropTypes from 'prop-types'

import size from 'lodash/size'
import truncate from 'lodash/truncate'

import I18n from '@smartcoop/i18n'

import { Link } from './styles'

const ReadMore = ({ children, length, readLess }) =>  {
  const [truncated, setTruncated] = useState(length < children.length)
  const truncatedText = `${ truncate(children, { 'length': length }) } `

  return (
    <>
      {truncated ? truncatedText : `${ children } `}
      {truncated && (
        <Link
          onClick={ () => { setTruncated(!truncated) } }
        >
          <I18n>read more</I18n>
        </Link>
      )}
      {!truncated && readLess && size(truncatedText) > length && (
        <Link
          onClick={ () => { setTruncated(!truncated) } }
        >
          <I18n>read less</I18n>
        </Link>
      )}
    </>
  )
}

ReadMore.propTypes = {
  children: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  readLess: PropTypes.bool
}

ReadMore.defaultProps = {
  readLess: false
}

export default ReadMore
