import React, { useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'

import filterSupplierSchema from '@smartcoop/forms/schemas/shoppingPlatform/supplier/filterSupplier.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getProducts } from '@smartcoop/services/apis/smartcoopApi/resources/product'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'

import { Container, ButtonContainer } from './styles'

const FilterSupplierForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()

  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterSupplierSchema }
        onSubmit={ handleSubmit }
      >
        <InputDate
          label={ t('proposal deadline') }
          name="proposalDeadline"
          defaultValue={ filters.proposalDeadline }
          fullWidth
        />

        <InputDate
          label={ t('receipt deadline') }
          name="receiptDeadline"
          defaultValue={ filters.receiptDeadline }
          fullWidth
        />

        <InputSelect
          label={ t('product') }
          name="productId"
          options={ getProducts }
          defaultValue={ filters.productId }
          asyncOptionLabelField="shortDescription"
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-order-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterSupplierForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterSupplierForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterSupplierForm
