import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 45px;
`

export const Payment = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 30%;
`

export const PaymentType = styled.div`
  font-weight: bold;
  text-transform: capitalize;
  display: flex;

`

export const Amount = styled.div`
  display: flex;
  margin-left: 3px;
`
