import { Model } from '@nozbe/watermelondb'
import { field, readonly, date } from '@nozbe/watermelondb/decorators'


export class MessageModel extends Model {
  static table = 'message'

  @field('messageId')
  messageId: string

  @field('conversationId')
  conversationId: string

  @field('isDocument')
  isDocument: boolean

  @field('isGeolocation')
  isGeolocation: boolean

  @field('documentFormat')
  documentFormat: string

  @field('fileAction')
  fileAction: string

  @field('fileType')
  fileType: string

  @field('documentName')
  documentName: string

  @field('message')
  message: string

  @field('nickname')
  nickname: string

  @field('notification')
  notification: boolean

  @field('whoSend')
  whoSend: string

  @field('status')
  status: string

  @field('relationalId')
  relationalId: string

  @field('sendAt')
  sendAt: number

  @field('delAt')
  delAt: number

  @field('forward')
  forward: boolean

  @field('syncId')
  syncId: string

  @readonly @date('created_at') createdAt

  @readonly @date('updated_at') updatedAt
}
