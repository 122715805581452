import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  width: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 13px;
`

export const ItemRow = styled(Row)`
  align-self: flex-start;
  cursor: pointer;
  margin-bottom: ${ props => props.margin ? '10px' : 0 };
`

export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid #E0E0E0;
  margin: 15px 0;
`

export const Label = styled(Typography)`
  color: ${ colors.black };
  font-size: 14px;
  font-weight: 600;
`

export const Description = styled(Row)`
  color: ${ colors.black };
  font-size: 12px;
  font-weight: 400;
  margin-left: 44px;
`
export const ItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`
