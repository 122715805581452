import React, { useMemo, useCallback } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'

import { groupMemberService } from '@smartcoop/database/services/groupMemberService'
import { database } from '@smartcoop/database/web-database'
import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'

import { PROFILES } from '../../utils/validateProfiles'
import GroupMemberCard from '../GroupMemberCard/GroupMemberCard'
import { ListWrapper } from './styles'

const GroupMembers = ({
  groupMembers,
  className,
  isGroupAdmin,
  localUserId,
  groupId,
  isBroadcast
}) => {
  const t = useT()
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()

  const orderedGroupMembers = useMemo(
    () => uniqBy(orderBy(groupMembers, ['isAdmin', 'userName'], 'desc'), 'userId'),
    [groupMembers]
  )

  const handleRemoveMember = useCallback((member) => {
    createDialog({
      id: 'confirm-remove-member',
      Component: ConfirmModal,
      props: {
        onConfirm: async () => {
          try {
            await groupMemberService(database).removeMember(member.userId, groupId, isBroadcast)
            snackbar.success(t('member successfully removed'))
          } catch(error) {
            snackbar.error(t('an error occurred while remove member from group'))
          }
        },
        textWarning: t(isBroadcast ? 'do you want to remove "{name}" from the broadcast?' : 'do you want to remove "{name}" from the group?', { name: member.userName })
      }
    })
  }, [createDialog, groupId, isBroadcast, snackbar, t])

  const handleTurnAdmin = useCallback((member) => {
    createDialog({
      id: 'confirm-delete-conversation',
      Component: ConfirmModal,
      props: {
        onConfirm: async () => {
          try {
            await groupMemberService(database).setGroupAdmin(member.userId, groupId, !member.isAdmin)
            snackbar.success(t('member made admin successfully'))
          } catch(error) {
            snackbar.error(t('an error occurred while making an admin member'))
          }
        },
        textWarning: member.isAdmin ?
          t('do you want to dismiss "{name}" as an administrator?', { name: member.userName }) :
          t('do you want to make "{name}" administrator?', { name: member.userName })
      }
    })
  }, [createDialog, groupId, snackbar, t])

  const isFamilyGroup = useMemo(() => {
    const profiles = groupMembers.map(member => member?.profiles ? JSON.parse(member?.profiles) : null)
    return profiles.every(profile => profile ? profile.includes(PROFILES.FAMILY_MEMBER) : null)
  }, [groupMembers])

  return (
    <ListWrapper className={ className }>
      {orderedGroupMembers.map(member => (
        <GroupMemberCard
          key={ member.id }
          userId={ member.userId }
          member={ member }
          isGroupAdmin={ isGroupAdmin }
          isLoggedUser={ localUserId === member.userId }
          onRemoveMember={ handleRemoveMember }
          onTurnAdmin={ handleTurnAdmin }
          isBroadcast={ isBroadcast }
          isFamilyGroup={ isFamilyGroup }
        />
      ))}
    </ListWrapper>
  )
}

GroupMembers.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupMembers: PropTypes.array,
  isGroupAdmin: PropTypes.bool,
  localUserId: PropTypes.string,
  className: PropTypes.string,
  isBroadcast: PropTypes.bool
}

GroupMembers.defaultProps = {
  groupMembers: [],
  isGroupAdmin: false,
  localUserId: null,
  className: '',
  isBroadcast: false
}

const enhance = withObservables([
  'groupId',
  'searchName',
  'isBroadcast',
  'isGroupAdmin'
], ({
  groupId,
  searchName,
  isBroadcast,
  isGroupAdmin
}) => ({
  groupMembers: groupMemberService(database)
    .observeGroupMemberByGroupIdAndFilterByName(groupId, searchName, isBroadcast && !isGroupAdmin)
}))

const EnhancedGroupMembers = enhance(GroupMembers)

export default EnhancedGroupMembers
