import React from 'react'

import { SnackbarProvider as NotistackProvider, withSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { success, warningSnackbar, error } from '@smartcoop/icons'
import { SnackbarController, SnackbarContext } from '@smartcoop/snackbar'
import colors from '@smartcoop/styles/colors'
import Icon from '@smartcoop/web-components/Icon'

import styles from './styles'

export const SnackbarsProvider = (props) => {
  const {
    children, options, anchorOrigin, ...rest
  } = props

  const Component = withSnackbar((notistack) => {
    const snackbarController = new SnackbarController(
      {
        openSnackbar: notistack.enqueueSnackbar,
        closeSnackbar: notistack.closeSnackbar
      },
      {
        anchorOrigin,
        ...options
      }
    )

    return (
      <SnackbarContext.Provider value={ snackbarController }>
        {children}
      </SnackbarContext.Provider>
    )
  })

  const Result = withStyles(styles)((styleProps) => (
    <NotistackProvider
      iconVariant={ {
        success:
          <Icon
            icon={ success }
            size={ 20 }
            color={ colors.white }
            style={ { marginRight: 10 } }
          />,
        error:
          <Icon
            icon={ error }
            size={ 16 }
            color={ colors.white }
            style={ { marginRight: 10 } }
          />,
        warning:
          <Icon
            icon={ warningSnackbar }
            size={ 18 }
            color={ colors.white }
            style={ { marginRight: 10 } }
          />
      } }
      { ...rest }
      { ...styleProps }
    >
      <Component />
    </NotistackProvider>
  ))

  return <Result />
}

SnackbarsProvider.propTypes = {
  /** maximum number of snackbars appearing simultaneously */
  maxSnack: PropTypes.number,
  /** snackbars position */
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right'])
  }),
  /** default options to all snackbars */
  options: PropTypes.shape({
    persist: PropTypes.bool,
    autoHideDuration: PropTypes.number,
    variant: PropTypes.oneOf(['default', 'success', 'warning', 'error', 'info'])
  }),
  children: PropTypes.any
}

SnackbarsProvider.defaultProps = {
  maxSnack: 5,
  options: {},
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  },
  children: null
}
