import { makeStyles } from '@material-ui/core/styles'

import { colors, hexToRgba } from '@smartcoop/styles'

export default makeStyles((theme) => ({
  contentText: {
    fontFamily: theme.typography.fontFamily,
    // color: Colors.muted,
    // fontSize: Fonts.fontSize.M,
    '& p': {
      marginBottom: 5
    }
  },
  dialogTop: {
    alignItems: 'flex-start'
  },
  withoutFooter: {
    paddingBottom: 24
  },
  footerContainer: {
    backgroundColor: colors.white,
    boxShadow: `0 1px 14px 0 ${ hexToRgba(colors.black, 0.14) }`,
    zIndex: 420
  }
}))
