import React, { useState, useMemo } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import get from 'lodash/get'
import uniqWith from 'lodash/uniqWith'

import { database } from '@smartcoop/database'
import { contactService } from '@smartcoop/database/services/contactService'
import { useT } from '@smartcoop/i18n'
import { addContact, emptyFilter } from '@smartcoop/icons'
import { getChatContact } from '@smartcoop/services/apis/smartcoopApi/resources/chat'
import { useSnackbar } from '@smartcoop/snackbar'
import EmptyState from '@smartcoop/web-components/EmptyState'
import CheckboxCard from '@smartcoop/web-components/FilteredCheckboxGroup/CheckboxCard'

import IconButton from '../../components/IconButton/IconButton'
import { PROFILES, USER_TYPE, checkIsSupplier } from '../../utils/validateProfiles'
import ContactCard from '../ContactCard'
import { Container, ContentList, CustomInput, GroupTitle, GroupWrapper, Header, InputWrapper, Title, TextName, ContainerSelectAll } from './styles'

const SelectContacts = ({
  contacts,
  selecteds,
  onSelect,
  className,
  localUserProfiles,
  hiddenMembers,
  isBroadcast,
  children,
  setSelecteds,
  maxMembers
}) => {
  const [userCode, setUserCode] = useState('')
  const [customIntegrants, setCustomIntegrants] = useState([])
  const [selectedGroups, setSelectedGroups] = useState(
    {
      added: false,
      family: false,
      organizationContacts: false,
      producer: false,
      walletProducers: false,
      producersOutOfWallet: false,
      cooperative: false,
      ccgl: false,
      smartcoop: false,
      technician: false
    }
  )

  const t = useT()
  const snackbar = useSnackbar()

  const onSearchContact = async () => {
    try {
      if (userCode?.length !== 8) {
        return snackbar.warning(t('invalid contact code'))
      }

      if ([...contacts, ...customIntegrants].some(item => item.userCode === userCode)) {
        return snackbar.warning(t('contact is already present in the list. select it'))
      }

      const { data: response } = await getChatContact(userCode.toUpperCase())

      setCustomIntegrants(state => [
        ...state,
        { ...response, contactId: response.id }
      ])
      onSelect({ ...response, contactId: response.id })

      snackbar.success(t('user "{name}" added to the group', { name: response.chatNickname }))
      return setUserCode('')
    } catch(error) {
      const message = get(error, 'response.data.message', 'error on search contact')

      return snackbar.error(t(message?.toLowerCase()))
    }
  }

  const avaibleContacts = useMemo(() => {
    if (isBroadcast) {
      const contactsToFilter = uniqWith(
        contacts
          .filter(contact => !hiddenMembers.includes(contact.contactId)),
        (a, b) => a.contactId === b.contactId || a.userCode === b.userCode
      )

      if (!localUserProfiles.includes(PROFILES.SMARTCOOP)) {
        return contactsToFilter.filter(contact => !checkIsSupplier(contact.profiles))
      }

      return contactsToFilter
    }


    const contactsToFilter = uniqWith(
      contacts
        .filter(contact => !hiddenMembers.includes(contact.contactId))
        .filter(contact => !checkIsSupplier(contact.profiles)),
      (a, b) => a.contactId === b.contactId || a.userCode === b.userCode
    )

    if (localUserProfiles.includes(PROFILES.ADMIN)) {
      return contactsToFilter
    }

    if (localUserProfiles.includes(PROFILES.TECHNICIAN)) {
      return contactsToFilter
    }

    if (localUserProfiles.includes(PROFILES.BUYER)) {
      return contactsToFilter.filter(contact => contact.contactType === USER_TYPE.CCGL)
    }

    if (localUserProfiles.includes(PROFILES.PRODUCTOR)) {
      return contactsToFilter.filter(contact => contact.contactType === USER_TYPE.FAMILY)
    }

    return contactsToFilter
  }, [contacts, hiddenMembers, isBroadcast, localUserProfiles])

  const showFinderInput = useMemo(() => (
    !localUserProfiles.includes(PROFILES.BUYER) ||
    !localUserProfiles.includes(PROFILES.PRODUCTOR)
  ), [localUserProfiles])

  const categories = [
    { description: t('added contacts'), field: 'added' },
    { description: t('family contacts'), field: 'family' },
    { description: t('organization contacts'), field: 'organizationContacts' },
    { description: t('producer contacts'), field: 'producer' },
    { description: t('wallet producers'), field: 'walletProducers' },
    { description: t('out of wallet producers'), field: 'producersOutOfWallet' },
    { description: t('cooperative group'), field: 'cooperative' },
    { description: t('ccgl'), field: 'ccgl' },
    { description: t('smartcoop contacts'), field: 'smartcoop' },
    { description: t('technician contacts'), field: 'technician' }
  ]

  const contactsByCategory = useMemo(() => ({
    added: avaibleContacts?.filter(item => item.contactType === 'added'),
    family: avaibleContacts?.filter(item => item.contactType === 'family'),
    organizationContacts: avaibleContacts?.filter(item => item.contactType === 'organizationContacts'),
    producer: avaibleContacts?.filter(item => item.contactType === 'producer'),
    walletProducers: avaibleContacts?.filter(item => item.contactType === 'walletProducers'),
    producersOutOfWallet: avaibleContacts?.filter(item => item.contactType === 'producersOutOfWallet'),
    cooperative: avaibleContacts?.filter(item => item.contactType === 'cooperative'),
    ccgl: avaibleContacts?.filter(item => item.contactType === 'CCGL'),
    smartcoop: avaibleContacts?.filter(item => item.contactType === 'smartcoop'),
    technician: avaibleContacts?.filter(item => item.contactType === 'technician')
  }), [avaibleContacts])

  const categoriesToShow = useMemo(
    () => categories.filter(
      category => avaibleContacts.some(contact => contact.contactType?.toLowerCase() === category.field?.toLowerCase())
    ),
    [categories, avaibleContacts]
  )

  return (
    <Container>
      <Header>
        <Title>{t(isBroadcast ? 'select broadcast members' : 'select group members')}</Title>
        {showFinderInput && (
          <InputWrapper>
            <CustomInput
              placeholder={ t('user code') }
              value={ userCode }
              onChange={ (e) => setUserCode(e.target.value) }
              maxLength={ 8 }
            />
            <IconButton icon={ addContact } onClick={ onSearchContact } />
          </InputWrapper>
        )}
        {children}
      </Header>

      <ContentList className={ className }>
        {!customIntegrants.length && !avaibleContacts.length && (
          <EmptyState
            text={ t('no results found') }
            icon={ emptyFilter }
          />
        )}

        {customIntegrants.map(contact => (
          <ContactCard
            key={ contact.id }
            contact={ contact }
            userId={ contact.contactId || null }
            onSelect={ () => onSelect(contact) }
            selected={ selecteds.some(item => item.id === contact.id) }
            disableSelection={ maxMembers && selecteds?.length >= maxMembers }
          />
        ))}

        { categoriesToShow?.map(category => (
          <GroupWrapper key={ category?.field }>
            <GroupTitle>{category?.description}</GroupTitle>
            {setSelecteds && (
              <ContainerSelectAll>
                <CheckboxCard
                  style={ { backgroundColor: 'transparent', margin: 0, paddingLeft: 4 } }
                  onClick={ event => event.stopPropagation() }
                  checked={ selectedGroups[category?.field] }
                  disabled={ !selectedGroups[category?.field] && maxMembers && selecteds?.length >= maxMembers }
                  onChange={ () => {
                    if(!selectedGroups[category?.field]) {
                      const contactsToAdd = filter(contacts, contact => contact.contactType === category?.field && !selecteds.some(item => item.id === contact.id))
                      setSelecteds(old => ([...old, ...contactsToAdd]))
                    } else {
                      setSelecteds(old => filter(old, contact => contact.contactType !== category?.field))
                    }
                    setSelectedGroups(old => ({ ...old, [category?.field]: !old[category?.field] }))
                  } }
                />
                <TextName>{t('select all')}</TextName>
              </ContainerSelectAll>
            )}
            {contactsByCategory[category?.field]?.map(contact => (
              <ContactCard
                key={ contact.id }
                contact={ contact }
                userId={ contact.contactId || null }
                onSelect={ () => onSelect(contact) }
                selected={ selecteds.some(item => item.id === contact.id) }
                disableSelection={ maxMembers && selecteds?.length >= maxMembers }
              />
            ))}
          </GroupWrapper>
        ))}
      </ContentList>
    </Container>
  )
}

SelectContacts.propTypes = {
  contacts: PropTypes.array,
  selecteds: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  setSelecteds: PropTypes.func,
  className: PropTypes.string,
  localUserProfiles: PropTypes.array,
  hiddenMembers: PropTypes.array,
  isBroadcast: PropTypes.bool,
  children: PropTypes.element,
  maxMembers: PropTypes.number
}

SelectContacts.defaultProps = {
  contacts: [],
  className: '',
  localUserProfiles: [],
  hiddenMembers: [],
  isBroadcast: false,
  setSelecteds: null,
  children: null,
  maxMembers: null
}


const enhance = withObservables(['searchParams'], ({ searchParams }) => ({
  contacts: contactService(database).observeContactsByFilter(searchParams)
}))

const EnhancedSelectContacts = enhance(SelectContacts)

export default EnhancedSelectContacts
