/* eslint-disable no-nested-ternary */
import React, { useCallback, useState, useEffect ,useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import capitalize from 'lodash/capitalize'
import filter from 'lodash/filter'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import round from 'lodash/round'
import split from 'lodash/split'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'

import{ useT } from '@smartcoop/i18n'
import { CostsActions } from '@smartcoop/stores/costs/duckCosts'
import { LotActions } from '@smartcoop/stores/lot'
import { selectLots } from '@smartcoop/stores/lot/selectorLot'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import colors from '@smartcoop/styles/colors'
import { momentBackDateFormat, momentBackDateTimeFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import InputDate from '@smartcoop/web-components/InputDate'
import InputFloat from '@smartcoop/web-components/InputFloat'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'
import Modal from '@smartcoop/web-components/Modal'

import { Container, ButtonGroup, Title, Row } from './styles'

const NewCostModal = (props) => {
  const {
    open,
    handleClose,
    defaultValues,
    id,
    costsOptions,
    cropsOptions,
    onSuccess,
    growingSeasonId,
    managementOptions,
    isActivity,
    year
  } = props

  const mounted = useRef(false)
  const t = useT()
  const dispatch = useCallback(useDispatch(), [])

  const propertyId = useSelector(selectCurrentPropertyId)
  const lots = useSelector(selectLots)
  const [costId, setCostId] = useState((defaultValues?.[0] || defaultValues)?.rubricItemId || null)
  const [rubricClassification, setRubricClassification] = useState((defaultValues?.[0] || defaultValues)?.rubricClassification || null)
  const [managementId, setManagementId] = useState(isActivity && find(defaultValues, item => item?.activity?.id) ? uniq(map(defaultValues, item => item?.activity?.id)) || null : (defaultValues?.[0] || defaultValues)?.cropManagementId || null )
  const [cropsId, setCropsId] = useState([])
  const [lotsType, setLotsType] = useState([])
  const [description, setDescription] = useState((defaultValues?.[0] || defaultValues)?.description || null)
  const [realizationMonths, setRealizationMonths] = useState((defaultValues?.[0] || defaultValues)?.realizationMonths || [])
  const [monthlyApportionment, setMonthlyApportionment] = useState(!isEmpty((defaultValues?.[0] || defaultValues)?.realizationMonths) || false)
  const [realizationDate, setRealizationDate] = useState(!!(defaultValues?.[0] || defaultValues)?.realizationDate && !(defaultValues?.[0] || defaultValues)?.realizationMonths ? moment((defaultValues?.[0] || defaultValues)?.realizationDate, momentBackDateTimeFormat).format(momentBackDateFormat) : '')
  const [price, setPrice] = useState(toString((defaultValues?.[0] || defaultValues)?.value) || '0')
  const [activityPercentages, setActivityPercentages] = useState({})
  const [cropPercentages, setCropPercentages] = useState({})
  const [lotsPercentages, setLotsPercentages] = useState({})
  const [sharing, setSharing] = useState([])

  const lotsOptions = useMemo(() => map(lots, lot => ({ label: lot.name, value: lot.slug })), [lots])

  useEffect(() => {
    const defaultCrops = {}
    const defaultLots = {}
    map(uniq(map(defaultValues?.activities || defaultValues, item => item?.activityId || item?.activity?.id)), item => {

      const currentCrops = filter(defaultValues?.activities || defaultValues, activity => (activity?.activityId || activity?.activity?.id) === item && activity?.crop)
      if(!isEmpty(currentCrops)) {
        defaultCrops[item] = map(currentCrops, crop => `${ crop.cropId }|${ crop?.sowingYear }`)
      }
    })
    map(uniq(map(defaultValues?.activities || defaultValues, item => item?.activityId || item?.activity?.id)), item => {
      const currentLots = filter(defaultValues?.activities || defaultValues, activity => (activity?.activityId || activity?.activity?.id) === item && activity?.lotType)
      if(!isEmpty(currentLots)) {
        defaultLots[item] = map(currentLots, lot => lot.lotType)
      }
    })
    setCropsId(defaultCrops)
    setLotsType(defaultLots)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[defaultValues])

  const handleSubmit = useCallback(
    () => {
      dispatch(CostsActions.saveCosts(
        isActivity ? {
          bulkId: defaultValues?.[0]?.bulkId,
          propertyId,
          rubricItemId: costId,
          rubricClassification,
          activities: map(managementId, item => ({
            activityId: item,
            percentage: toNumber(activityPercentages[item]),
            crops: map(cropPercentages[item], crop => {
              const cropData = split(crop?.cropId,'|')
              return ({
                ...crop,
                cropId: cropData[0],
                sowingYear: cropData[1],
                percentage: toNumber(crop?.percentage)
              })
            }),
            lots: map(lotsPercentages[item], lot => {
              const cropData = split(lot?.lotType,'|')
              return ({
                ...lot,
                lotType: cropData[0],
                percentage: toNumber(lot?.percentage)
              })
            })
          })),
          description,
          value: toNumber(price),
          realizationMonths,
          realizationDate
        } : {
          ...defaultValues,
          propertyId,
          rubricItemId: costId,
          rubricClassification,
          cropManagementId: managementId,
          description,
          value: toNumber(price),
          realizationMonths,
          realizationDate,
          growingSeasonId
        },
        () => {
          onSuccess()
          handleClose()
        }
      ))
    },
    [activityPercentages, costId, cropPercentages, defaultValues, description, dispatch, growingSeasonId, handleClose, isActivity, managementId, onSuccess, price, propertyId, realizationDate, realizationMonths, rubricClassification, lotsPercentages]
  )

  useEffect(() => {
    if(!isEmpty(managementId) && !isActivity) {
      setRealizationDate(find(managementOptions, item => item.value === managementId)?.date)
    }
  },[isActivity, managementId, managementOptions])

  useEffect(() => {
    if(isActivity) {
      if((defaultValues?.[0] || defaultValues)?.bulkId && !mounted.current && (defaultValues?.activity?.id || defaultValues?.[0]?.activity?.id)) {
        const defaultActivityPercentages = {}
        const defaultCropPercentages = []
        const defaultCropPercentagesByActivity = {}
        const defaultLotPercentages = []
        const defaultLotPercentagesByActivity = {}
        const defaultSharing = {}

        const groupedActivities = groupBy(defaultValues?.activities || defaultValues, item => item?.activityId || item?.activity?.id)
        map(groupedActivities, (item, key) => {
          defaultActivityPercentages[key] = sumBy(item, percentage => toNumber(percentage.percentage))
          defaultSharing[key] = ''
        })

        setActivityPercentages(defaultActivityPercentages)
        map(defaultValues?.activities || defaultValues, (item) => {
          if(item?.cropId) {
            defaultCropPercentages.push({ activityId: (item?.activityId || item?.activity?.id), cropId: `${ item?.cropId }|${ item?.sowingYear }`, percentage: round(toNumber(item?.percentage), 2) })
          }
          if (!isEmpty(item?.lots)) {
            map(item?.lots, lot => {
              defaultLotPercentages.push({ activityId: item?.activityId || item?.activity?.id, lotType: lot?.lotType, percentage: round(toNumber(lot?.percentage), 2) })
            })
          } else if (!isEmpty(item?.lotType)) {
            defaultLotPercentages.push({ activityId: item?.activityId || item?.activity?.id, lotType: item?.lotType, percentage: round(toNumber(item?.percentage), 2) })
          }
        })

        map(uniqBy(defaultValues?.activities || defaultValues, item => item?.activityId || item?.activity?.id), item => {
          defaultCropPercentagesByActivity[(item?.activityId || item?.activity?.id)] = filter(defaultCropPercentages, crop => crop?.activityId === (item?.activityId || item?.activity?.id))
          defaultLotPercentagesByActivity[(item?.activityId || item?.activity?.id)] = filter(defaultLotPercentages, lot => lot?.activityId === (item?.activityId || item?.activity?.id))
        })
        map(defaultCropPercentagesByActivity, (item, index) => {
          if(!isEmpty(item)) {
            defaultSharing[index] = 'crop'
          }
        })
        map(defaultLotPercentagesByActivity, (item, index) => {
          if(!isEmpty(item)) {
            defaultSharing[index] = 'lot'
          }
        })
        setCropPercentages(defaultCropPercentagesByActivity)
        setLotsPercentages(defaultLotPercentagesByActivity)
        setSharing(defaultSharing)

        mounted.current = true
      } else {
        const currentData = { ...activityPercentages }
        const notInObject = filter(map(activityPercentages, (item, index) => !includes(index, managementId) ? index : null), val => val)
        map(notInObject, item => {
          delete currentData.[item]
        })
        map(managementId, item => {
          currentData[item] = 0
        })
        if(managementId?.length === 1) {
          currentData[managementId[0]] = '100'
        }

        setActivityPercentages(currentData)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[managementId, defaultValues])

  const blockedSave = useMemo(() => {
    if(isActivity && !isEmpty(activityPercentages)) {
      let block = false
      if(sum(map(activityPercentages, item => toNumber(item))) !== 100){
        block = '* O somatório das porcentagens do rateio por atividade deve ser 100%.'
      }
      map(cropPercentages, item => {
        if(!isEmpty(item) && sum(map(item, crop => toNumber(crop?.percentage))) !== 100) {
          block = '* O somatório das porcentagens do rateio por cultura deve ser 100%.'
        }
      })

      map(lotsPercentages, item => {
        if(!isEmpty(item) && sum(map(item, lot => toNumber(lot?.percentage))) !== 100) {
          block = '* O somatório das porcentagens do rateio por lote deve ser 100%.'
        }
      })

      return block
    }
    return false
  }, [activityPercentages, cropPercentages, isActivity, lotsPercentages])

  const rubricClassificationOptions = useMemo(() => {
    const foundCost = find(costsOptions, item => item?.value === costId)
    const arr = split(foundCost?.rubricGroup?.classification, '-')
    const response = map(arr, item => ({ label: capitalize(item.replaceAll('_', ' ')), value: item }))
    if((defaultValues?.[0] || defaultValues)?.id) {
      if(response.length === 1) {
        setRubricClassification(response?.[0]?.value)
      } else {
        setRubricClassification(null)
      }
    }
    return response
  }, [costId, costsOptions, defaultValues])

  const datesOptions = useMemo(() => {
    const initialDate = moment(`01-01-${ year }`, 'DD-MM-YYYY')
    const endDate = moment(initialDate).add(60, 'month')
    const responseDate = []
    for(let date = moment(initialDate); date.isBefore(endDate); date = date.add(1, 'month')) {
      responseDate.push({
        label: date.format('MM/YYYY'),
        value: date.format('YYYY-MM')
      })
    }
    return responseDate
  }, [year])

  useEffect(() => {
    if (isEmpty(lots)) {
      dispatch(LotActions.loadLots({}))
    }
  }, [dispatch, lots])

  return (
    <Modal
      id={ id }
      open={ open }
      title={ <div style={ { display: 'flex', flexDirection: 'row', alignItems: 'flex-end' } }><div>{(defaultValues?.[0] || defaultValues)?.bulkId ? t('edit cost') : t('new cost')}</div><div style={ { fontSize: 12, marginLeft: 5 } }>{`(${ t('receipt/cost/expense') })`}</div></div> }
      maxWidth='sm'
      fullWidth
      disableEsc
      onClose={ onSuccess }
    >
      <Container>
        <InputSelect
          isRequired
          detached
          label={ `${ t('rubric') }*` }
          value={ costId }
          onChange={ setCostId }
          options={ costsOptions }
          fullWidth
        />
        <InputSelect
          isRequired
          detached
          label={ `${ t('classification') }*` }
          value={ rubricClassification }
          onChange={ setRubricClassification }
          options={ rubricClassificationOptions }
          fullWidth
        />
        <InputSelect
          detached
          label={ isActivity ? `${ t('activity', { howMany: 2 }) }` : `${ t('management') }` }
          value={ managementId }
          onChange={ setManagementId }
          options={ managementOptions }
          fullWidth
          multiple={ isActivity }
        />
        <CheckboxButton
          detached
          label={ t('monthly apportionment') }
          onChange={ () => setMonthlyApportionment(!monthlyApportionment) }
          checked={ monthlyApportionment ?? false }
          value={ monthlyApportionment ?? false }
        />
        {monthlyApportionment ? (
          <InputSelect
            isRequired
            detached
            multiple
            label={ `${ t('month', { howMany: 2 }) }*` }
            fullWidth
            options={ datesOptions }
            value={ realizationMonths }
            order={ false }
            onChange={ setRealizationMonths }
          />
        ) : (
          <InputDate
            isRequired
            detached
            label={ `${ t('realization date') }*` }
            placeholder={ `${ t('realization date') }*` }
            fullWidth
            value={ realizationDate }
            onChange={ setRealizationDate }
          />
        )}

        <InputText
          detached
          label={ `${ t('description') }*` }
          value={ description }
          onChange={ ({ target: { value } }) => setDescription(value) }
          fullWidth
        />
        <InputFloat
          isRequired
          detached
          label={ `${ t('total value') }*` }
          value={ price }
          onChange={ ({ target: { value } }) => setPrice(value) }
          type="float"
          fullWidth
          decimalScale={ 2 }
        />
        {isActivity && managementId?.length > 0 && (
          <>
            <Title style={ { fontSize: 18 } }>
              {t('sharing by activity')}
            </Title>

            {map(managementId, item => (
              <fieldset style={ { borderRadius: 10, border: '1px solid #babaca' } }>
                <legend>
                  <Title>
                    {find(managementOptions, option => option?.value === item)?.label}
                  </Title>
                </legend>

                <InputUnit
                  unit="%"
                  key={ `input-${ item }` }
                  detached
                  label={ t('percentage') }
                  type="float"
                  fullWidth
                  maxValue="100.00"
                  value={ managementId.length === 1 ? '100' : activityPercentages[item] }
                  onChange={ ({ target: { value } }) => {
                    const currentData = { ...activityPercentages }
                    currentData[item] = toNumber(value) > 100 ? '100' : value
                    setActivityPercentages(currentData)
                  } }
                  disabled={ managementId.length === 1 }
                />
                <Title>
                  Rateio
                </Title>
                <Row>
                  <CheckboxButton
                    value={ sharing?.[item] === 'crop' }
                    checked={ sharing?.[item] === 'crop' }
                    onChange={ () => setSharing((old) => {
                      const currentData = { ...old }
                      if(old?.[item] === 'crop') {
                        currentData[item] = ''
                      } else {
                        currentData[item] = 'crop'
                      }
                      const currentCropPercentages = { ...cropPercentages }
                      delete currentCropPercentages?.[item]
                      setCropPercentages(currentCropPercentages)
                      const currentCropsIds = { ...cropsId }
                      delete currentCropsIds?.[item]
                      setCropsId(currentCropsIds)

                      const currentLotsPercentages = { ...lotsPercentages }
                      delete currentLotsPercentages?.[item]
                      setLotsPercentages({})
                      const currenLotsTypes = { ...lotsType }
                      delete currenLotsTypes?.[item]
                      setLotsType(currenLotsTypes)

                      return currentData
                    }) }
                    label={ t('by crop') }
                    style={ { marginRight: 10 } }
                  />
                  {(find(managementOptions, (option) => option?.value === item)?.slug === 'pecuaria-de-leite' || find(managementOptions, (option) => option?.value === item)?.slug === 'pecuaria-de-corte') ? (
                    <CheckboxButton
                      value={ sharing?.[item] === 'lot' }
                      checked={ sharing?.[item] === 'lot' }
                      onChange={ () => setSharing((old) => {
                        const currentData = { ...old }
                        if(old?.[item] === 'lot') {
                          currentData[item] = ''
                        } else {
                          currentData[item] = 'lot'
                        }
                        const currentCropPercentages = { ...cropPercentages }
                        delete currentCropPercentages?.[item]
                        setCropPercentages(currentCropPercentages)
                        const currentCropsIds = { ...cropsId }
                        delete currentCropsIds?.[item]
                        setCropsId(currentCropsIds)

                        const currentLotsPercentages = { ...lotsPercentages }
                        delete currentLotsPercentages?.[item]
                        setLotsPercentages({})
                        const currenLotsTypes = { ...lotsType }
                        delete currenLotsTypes?.[item]
                        setLotsType(currenLotsTypes)

                        return currentData
                      }) }
                      label={ t('by lot') }
                    />
                  ) : null}
                </Row>
                { sharing?.[item] === 'crop' ? (
                  <>
                    <InputSelect
                      detached
                      label={ t('crop', { howMany: 2 }) }
                      value={ cropsId[item] }
                      onChange={ (value) => {
                        const currentData = { ...cropsId }
                        currentData[item] = value
                        setCropsId(currentData)
                        const currentCropPercentages = { ...cropPercentages }
                        currentCropPercentages[item] = map(value, crop => ({ cropId: crop, percentage: toString(round(100 / value.length, 2)) }))
                        setCropPercentages(currentCropPercentages)
                      } }
                      options={ cropsOptions }
                      fullWidth
                      multiple
                    />
                    {cropsId?.[item]?.length > 1  && (
                      <>
                        <Title>
                          {t('sharing by crop')}
                        </Title>
                        <Row>
                          {map(cropsId?.[item] , subItem => (
                            <InputUnit
                              unit="%"
                              style={ { marginLeft: 5, marginRight: 5 } }
                              key={ `input-${ subItem }` }
                              detached
                              label={ find(cropsOptions, option => option?.value === subItem)?.label }
                              type="float"
                              fullWidth
                              value={ find(cropPercentages[item], crop => crop?.cropId === subItem)?.percentage }
                              onChange={ ({ target: { value } }) => {
                                const currentData = { ...cropPercentages }
                                const foundData = !!find(currentData[item], crop => crop?.cropId === subItem)
                                currentData[item] = foundData ?
                                  map(currentData[item], crop => crop?.cropId === subItem
                                    ? { ...crop, percentage: toNumber(value) > 100 ? '100' : value } : crop) : (currentData[item]?.length
                                    ? [...currentData[item], { cropId: subItem, percentage: toNumber(value) > 100 ? '100' : value }]
                                    : [{ cropId: subItem, percentage: toNumber(value) > 100 ? '100' : value }])
                                setCropPercentages(currentData)
                              } }
                            />
                          ))}
                        </Row>
                      </>
                    )}
                  </>
                ) : null }
                {sharing?.[item] === 'lot' ? (
                  <>
                    {(find(managementOptions, option => option?.value === item)?.slug === 'pecuaria-de-leite' || find(managementOptions, option => option?.value === item)?.slug === 'pecuaria-de-corte') && (
                      <InputSelect
                        detached
                        label={ t('lot type') }
                        name="slug"
                        options={ lotsOptions }
                        value={ lotsType[item] }
                        onChange={ (value) => {
                          const currentData = { ...lotsType }
                          currentData[item] = value
                          setLotsType(currentData)
                          const currentLotsPercentages = { ...lotsPercentages }
                          currentLotsPercentages[item] = map(value, crop => ({ lotType: crop, percentage: toString(round(100 / value.length, 2)) }))
                          setLotsPercentages(currentLotsPercentages)
                        } }
                        fullWidth
                        multiple
                      />
                    )}

                    {lotsType?.[item]?.length > 1  && (
                      <>
                        <Title>
                          {t('sharing by lot')}
                        </Title>
                        <Row>
                          {map(lotsType?.[item] , subItem => (
                            <InputUnit
                              unit="%"
                              style={ { marginLeft: 5, marginRight: 5 } }
                              key={ `input-${ subItem }` }
                              detached
                              label={ find(lotsOptions, option => option?.value === subItem)?.label }
                              type="float"
                              fullWidth
                              value={ find(lotsPercentages[item], lot => lot?.lotType === subItem)?.percentage }
                              onChange={ ({ target: { value } }) => {
                                const currentData = { ...lotsPercentages }
                                const foundData = !!find(currentData[item], lot => lot?.lotType === subItem)
                                currentData[item] = foundData ?
                                  map(currentData[item], lot => lot?.lotType === subItem
                                    ? { ...lot, percentage: toNumber(value) > 100 ? '100' : value } : lot) : (currentData[item]?.length
                                    ? [...currentData[item], { lotType: subItem, percentage: toNumber(value) > 100 ? '100' : value }]
                                    : [{ lotType: subItem, percentage: toNumber(value) > 100 ? '100' : value }])
                                setLotsPercentages(currentData)
                              } }
                            />
                          ))}
                        </Row>

                      </>
                    )}
                  </>
                ) : null}

              </fieldset>
            ))}
          </>
        )}
        {blockedSave && (
          <p style={ { color: 'red', fontSize: 12 } }>{blockedSave}</p>
        )}
        <ButtonGroup>
          <Button
            id="cancel"
            onClick={ handleClose }
            color={ colors.white }
            fullWidth
            style={ { marginRight: '10px' } }
          >
            {t('cancel')}
          </Button>
          <Button
            id="submit"
            onClick={ handleSubmit }
            disabled={ blockedSave || isEmpty(costId) || isEmpty(monthlyApportionment ? realizationMonths : realizationDate) || isEmpty(price) || isEmpty(description)  }
            fullWidth
            style={ { marginLeft: '10px' } }
          >
            {t('save')}
          </Button>
        </ButtonGroup>
      </Container>
    </Modal>
  )
}

NewCostModal.propTypes = {
  id: PropTypes.string,
  growingSeasonId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  year: PropTypes.string.isRequired,
  isActivity: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  costsOptions: PropTypes.array.isRequired,
  cropsOptions: PropTypes.array.isRequired,
  managementOptions: PropTypes.array.isRequired,
  onSuccess: PropTypes.func,
  defaultValues: PropTypes.object
}

NewCostModal.defaultProps = {
  onSuccess: () => {},
  id: null,
  growingSeasonId: null,
  isActivity: false,
  defaultValues: {}
}

export default NewCostModal
