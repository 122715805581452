import React, { useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import RegisterProductForm from '@smartcoop/web-containers/forms/shoppingPlatform/product/RegisterProductForm'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

const RegisterProductScreen = () => {
  const history = useHistory()
  const { routes } = useRoutes()
  const registerProductRef = useRef(null)


  const onSuccess = useCallback(() => history.push(routes.productList.path), [
    history,
    routes.productList.path
  ])
  const onCancel = useCallback(() => history.goBack(), [history])

  return (
    <RegisterProductForm
      ref={ registerProductRef }
      onSuccess={ onSuccess }
      onCancel={ onCancel }
    />
  )
}

export default RegisterProductScreen
