import React, {
  forwardRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useImperativeHandle
} from 'react'

import PropTypes from 'prop-types'
import uuid from 'short-uuid'


import { FieldProvider, useField } from '@smartcoop/forms'

import RadioGroupStyled from './RadioGroupStyled'

const RadioGroupForm = forwardRef((props, externalRef) => {
  const {
    defaultValue,
    zIndex,
    onFieldValueChange,
    transformValue,
    setNull,
    ...rest
  } = props

  const [value, setValue] = useState(defaultValue)
  const [mounted, setMounted] = useState(false)

  const {
    error,
    fieldRef,
    fieldName,
    required,
    handleChangeNative,
    resetField,
    externalOnChange,
    validateField
  } = useField()

  const id = useMemo(
    () => `${ fieldName }-${ uuid().new() }`,
    [fieldName]
  )

  const handleChange = useCallback(
    ({ value: newValue }) => {
      setValue(oldValue => {
        if(oldValue === newValue) {
          return setNull ? null : newValue
        }
        return newValue
      })
    },
    [setNull]
  )
  useEffect(() => {
    const newValue = transformValue(value)
    onFieldValueChange(newValue)
    if (mounted) {
      handleChangeNative(newValue)
    } else {
      setMounted(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const imperativeHandles = useMemo(
    () => ({
      value,
      defaultValue,
      setValue,
      resetField,
      externalOnChange,
      validateField
    }),
    [defaultValue, externalOnChange, resetField, validateField, value]
  )

  useImperativeHandle(fieldRef, () => ({
    ...fieldRef.current,
    ...imperativeHandles
  }))

  useImperativeHandle(externalRef, () => ({
    ...fieldRef.current,
    ...imperativeHandles
  }))

  // TODO disabled input style
  return (
    <RadioGroupStyled
      { ...rest }
      id={ id }
      value={ value }
      onChange={ handleChange }
      error={ error }
      required={ required }
    />
  )
})

RadioGroupForm.propTypes = {
  defaultValue: PropTypes.any,
  transformValue: PropTypes.func,
  onFieldValueChange: PropTypes.func,
  zIndex: PropTypes.number,
  setNull: PropTypes.bool
}

RadioGroupForm.defaultProps = {
  defaultValue: '',
  transformValue: v => v,
  onFieldValueChange: () => {},
  zIndex: undefined,
  setNull: false
}

const Field = forwardRef(({ path, ...props }, ref) => (
  <FieldProvider
    ref={ ref }
    { ...props }
    registerFieldOptions={ { path } }
    FieldComponent={ RadioGroupForm }
  />
))

Field.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  transformValue: PropTypes.func,
  onFieldValueChange: PropTypes.func,
  path: PropTypes.string,
  defaultValue: PropTypes.any,
  numberFormatProps: PropTypes.object,
  helperText: PropTypes.string
}

Field.defaultProps = {
  disabled: false,
  onChange: () => {},
  transformValue: v => v,
  onFieldValueChange: () => {},
  onBlur: () => {},
  path: 'value',
  defaultValue: '',
  numberFormatProps: undefined,
  helperText: null
}

export default Field
