import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { filter } from 'lodash'

import { AuthenticationTypes } from '../authentication'

/* Initial State */
const INITIAL_STATE = {
  technicalVisits: [],
  technicalVisit: {},
  currentOwner: {},
  propertiesByOwner: {},
  currentOwnerFamilyGroup: [],
  requestAccessList: [],
  owners: [],
  technicalForms: [],
  formAnswers: [],
  evaluationProducers: [],
  offlineVisits: [],
  currentForm: null,
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadPropertiesByOwner: ['currentOwner', 'onSuccess', 'onError'],
  loadPropertiesByOwnerSuccess: ['properties'],

  newOfflineVisit: ['offlineVisit'],

  saveTechnicalVisit: ['technicalVisit', 'formData', 'propertyId', 'onSuccess', 'onError', 'isEdit'],
  saveOfflineTechnicalVisit: ['technicalVisit', 'formData', 'onSuccess', 'onError', 'isEdit'],

  requestAccessTechnicalForProprietary: ['propertyId', 'onSuccess', 'onError'],

  acceptTechnicianAccess: [
    'permission',
    'accessRequestId',
    'onSuccess',
    'onError'
  ],

  loadTechnicalVisitById: ['params', 'onSuccess', 'onError'],
  loadTechnicalVisitByIdSuccess: ['technicalVisit', 'onSuccess'],

  loadTechnicalVisits: ['params', 'onSuccess', 'onError'],
  loadTechnicalVisitsSuccess: ['technicalVisits', 'page', 'onSuccess'],
  resetTechnicalVisits: [],

  setCurrentOwnerFamilyGroup: ['currentOwnerFamilyGroup'],

  loadTechnicalOwners: ['params', 'onSuccess', 'onError'],
  loadTechnicalOwnersSuccess: ['owners', 'page', 'onSuccess'],

  loadRequestAccessList: ['params', 'onSuccess', 'onError'],
  loadRequestAccessListSuccess: ['requestAccessList', 'page', 'onSuccess'],

  deleteTechnicalVisit: ['technicalVisitId', 'onSuccess', 'onError'],

  revokeTechnicalAccess: ['id', 'statusId', 'onSuccess', 'onError'],

  setTechnicalCurrentOwner: ['currentOwner', 'onSuccess', 'onError'],
  setTechnicalCurrentOwnerSuccess: ['currentOwner'],
  resetTechnicalCurrentOwner: [],

  loadTechnicalForms: ['params', 'onSuccess', 'onError'],
  loadTechnicalFormsSuccess: ['technicalForms'],
  setCurrentTechnicalForm: ['currentForm'],
  resetCurrentTechnicalForm: [],

  resetTechnicalEvaluationProducers: [],
  loadTechnicalEvaluationProducers: ['params','onSuccess', 'onError'],
  loadTechnicalEvaluationProducersSuccess: [ 'evaluationProducers','onSuccess', 'onError'],

  createOfflineTechnicalEvaluation: ['quizId', 'params','onSuccess', 'onError'],
  updateOfflineTechnicalEvaluationAnswers: ['formAnswer'],
  createTechnicalEvaluation: ['quizId', 'params','onSuccess', 'onError'],
  createTechnicalEvaluationFiles: ['params', 'answerId', 'questionId','onSuccess', 'onError'],

  loadTechnicalEvaluationAnswers: ['params', 'onSuccess', 'onError'],
  loadTechnicalEvaluationAnswersSuccess: ['formAnswers'],

  createTechnicianGroups: ['params','onSuccess', 'onError'],
  deleteTechnicianGroups: ['groupId', 'onSuccess', 'onError'],
  updateTechnicianGroups: ['groupId', 'params','onSuccess', 'onError'],

  toggleOfflineTechnicalAccess: ['availableOffline', 'proprietaryTechnicianId'],

  technicalError: ['error']
})

markActionsOffline(Creators,
  [
    'createTechnicalEvaluation',
    'createTechnicalEvaluationFiles',
    'saveTechnicalVisit'
  ]
)

const loadPropertiesByOwnerSuccess = (
  state = INITIAL_STATE,
  { properties }
) => ({
  ...state,
  propertiesByOwner: {
    ...state.propertiesByOwner,
    [state.currentOwner.id]: properties
  }
})

const setCurrentTechnicalForm = (state = INITIAL_STATE, { currentForm }) => ({
  ...state,
  currentForm
})

const resetCurrentTechnicalForm = (state = INITIAL_STATE) => ({
  ...state,
  currentForm: INITIAL_STATE.currentForm
})

const resetTechnicalEvaluationProducers = (state = INITIAL_STATE) => ({
  ...state,
  evaluationProducers: INITIAL_STATE.evaluationProducers
})

const newOfflineVisit = (state = INITIAL_STATE, { offlineVisit }) => ({
  ...state,
  offlineVisits: [...state.offlineVisits, offlineVisit]
})

const loadTechnicalEvaluationAnswersSuccess = (state = INITIAL_STATE, { formAnswers }) => ({
  ...state,
  formAnswers
})

const updateOfflineTechnicalEvaluationAnswers = (state = INITIAL_STATE, { formAnswer }) => {
  const currentAnswers = filter([...state.formAnswers], item => item?.id !== formAnswer?.id)
  return {
    ...state,
    formAnswers: [formAnswer, ...currentAnswers]
  }
}

const loadTechnicalEvaluationProducersSuccess = (state = INITIAL_STATE, { evaluationProducers }) => ({
  ...state,
  evaluationProducers
})

const loadTechnicalFormsSuccess = (state = INITIAL_STATE, { technicalForms }) => ({
  ...state,
  technicalForms
})

const setCurrentOwnerFamilyGroup = (state = INITIAL_STATE, { currentOwnerFamilyGroup }) => ({
  ...state,
  currentOwnerFamilyGroup
})

const saveTechnicalVisit = (state = INITIAL_STATE, { technicalVisit }) => ({
  ...state,
  technicalVisits: [...state.technicalVisits, technicalVisit]
})

const loadTechnicalVisitByIdSuccess = (
  state = INITIAL_STATE,
  { technicalVisit }
) => ({
  ...state,
  error: INITIAL_STATE.error,
  technicalVisit
})

const loadTechnicalVisitsSuccess = (
  state = INITIAL_STATE,
  { technicalVisits, page }
) => ({
  ...state,
  error: INITIAL_STATE.error,
  technicalVisits:
    page === 1
      ? technicalVisits
      : [...state.technicalVisits, ...technicalVisits]
})

const resetTechnicalVisits = (
  state = INITIAL_STATE
) => ({
  ...state,
  error: INITIAL_STATE.error,
  technicalVisits: INITIAL_STATE.technicalVisits
})

const loadRequestAccessListSuccess = (
  state = INITIAL_STATE,
  { requestAccessList, page }
) => ({
  ...state,
  error: INITIAL_STATE.error,
  requestAccessList:
    page === 1
      ? requestAccessList
      : [...state.requestAccessList, ...requestAccessList]
})

const loadTechnicalOwnersSuccess = (
  state = INITIAL_STATE,
  { owners, page }
) => ({
  ...state,
  error: INITIAL_STATE.error,
  owners: page === 1 ? owners : [...state.owners, ...owners]
})

const setTechnicalCurrentOwnerSuccess = (
  state = INITIAL_STATE,
  { currentOwner }
) => ({
  ...state,
  currentOwner
})

const resetTechnicalCurrentOwner = (state = INITIAL_STATE) => ({
  ...state,
  currentOwner: INITIAL_STATE.currentOwner,
  currentOwnerFamilyGroup: INITIAL_STATE.currentOwnerFamilyGroup,
  offlineVisits: INITIAL_STATE.offlineVisits
})

const technicalError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_TECHNICAL_VISIT_BY_ID_SUCCESS]: loadTechnicalVisitByIdSuccess,

  [Types.NEW_OFFLINE_VISIT]: newOfflineVisit,

  [Types.LOAD_TECHNICAL_VISITS_SUCCESS]: loadTechnicalVisitsSuccess,

  [Types.LOAD_TECHNICAL_FORMS_SUCCESS]: loadTechnicalFormsSuccess,

  [Types.RESET_TECHNICAL_VISITS]: resetTechnicalVisits,

  [Types.LOAD_REQUEST_ACCESS_LIST_SUCCESS]: loadRequestAccessListSuccess,

  [Types.LOAD_TECHNICAL_OWNERS_SUCCESS]: loadTechnicalOwnersSuccess,

  [Types.LOAD_PROPERTIES_BY_OWNER_SUCCESS]: loadPropertiesByOwnerSuccess,

  [Types.SAVE_TECHNICAL_VISIT]: saveTechnicalVisit,

  [Types.SET_TECHNICAL_CURRENT_OWNER_SUCCESS]: setTechnicalCurrentOwnerSuccess,
  [Types.RESET_TECHNICAL_CURRENT_OWNER]: resetTechnicalCurrentOwner,

  [Types.SET_CURRENT_OWNER_FAMILY_GROUP]: setCurrentOwnerFamilyGroup,

  [Types.RESET_CURRENT_TECHNICAL_FORM]: resetCurrentTechnicalForm,
  [Types.SET_CURRENT_TECHNICAL_FORM]: setCurrentTechnicalForm,

  [Types.LOAD_TECHNICAL_EVALUATION_ANSWERS_SUCCESS]: loadTechnicalEvaluationAnswersSuccess,

  [Types.UPDATE_OFFLINE_TECHNICAL_EVALUATION_ANSWERS]: updateOfflineTechnicalEvaluationAnswers,

  [Types.LOAD_TECHNICAL_EVALUATION_PRODUCERS_SUCCESS]: loadTechnicalEvaluationProducersSuccess,
  [Types.RESET_TECHNICAL_EVALUATION_PRODUCERS]: resetTechnicalEvaluationProducers,

  [Types.TECHNICAL_ERROR]: technicalError,

  [AuthenticationTypes.LOGOUT]: logout
})

export { Types as TechnicalTypes, Creators as TechnicalActions }
