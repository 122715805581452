
import api from '../api'

export const getFieldsByUser = async (params, { userId }) => {
  const { data: { data } } = await api.get(`/v1/fields/user/${ userId }`, { params })

  return data
}

export const getFields = async (params) => {
  const { data } = await api.get('/v1/fields', { params })
  return data
}

export const getFieldsByPropertyId = async (params, { propertyId }) => {
  const { data } = await api.get(`/v1/fields/property/${ propertyId }`, { params })

  return data
}

export const getFieldsHistoryByPropertyId = async ({ propertyId }) => {
  const { data } = await api.get(`/v1/fields/history/${ propertyId }`)
  return data
}

export const getField = async (params, { fieldId }) => {
  const { data } = await api.get(`/v1/fields/${ fieldId }`, { params })
  return data
}

export const createField = (params) => api.post('/v1/fields', params)

export const editField = (params, { fieldId }) => api.patch(`/v1/fields/${ fieldId }`, params)

export const deleteField = ({ fieldId }) => api.delete(`/v1/fields/${ fieldId }`)

export const uploadFieldShape = (params) => api.post('/v1/fields/import-kml', params, {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data'
  }
})
