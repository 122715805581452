import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const ChatAbsenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const ChatAbsenceHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ChatAbsenceHeaderTitle = styled.span`
  font-weight: 700;
  font-size: 17px;
`

export const ChatAbsenceContent = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${ colors.white };
  padding: 20px;
  border-radius: 5px;
  gap: 2em;
`

export const ChatAbsenceItemContainer = styled.div`
  display: grid;
  grid-template-areas: 
    "main-title main-title switcher"
    "period-title period-content switcher"
    "message-title message-content switcher"
  ;
  gap: 1em;
`

const ChatAbsenceItemInformation = styled.div``

export const ChatAbsenceMainTitle = styled(ChatAbsenceItemInformation)`
  display: flex;
  justify-content: space-between;
  grid-area: main-title;

  & > span {
    font-weight: 600;
  }
`

export const ChatAbsencePeriodTitle = styled(ChatAbsenceItemInformation)`
  grid-area: period-title;
`

export const ChatAbsencePeriodContent = styled(ChatAbsenceItemInformation)`
  grid-area: period-content;
`

export const ChatAbsenceMessageTitle = styled(ChatAbsenceItemInformation)`
  grid-area: message-title;
`

export const ChatAbsenceMessageContent = styled(ChatAbsenceItemInformation)`
  grid-area: message-content;
`

export const ChatAbsenceSwitcherContainer = styled(ChatAbsenceItemInformation)`
  display: flex;
  justify-content: flex-end;
  grid-area: switcher;
`