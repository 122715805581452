// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
      <g>
        <path fill="none" d="M19.5,127.2c-0.1-0.1-0.3-0.2-0.4-0.3C19.2,127,19.3,127.1,19.5,127.2z"/>
        <path fill="none" d="M18.7,126.7c-0.1-0.1-0.3-0.2-0.4-0.3C18.4,126.4,18.5,126.6,18.7,126.7z"/>
        <path fill="none" d="M17.9,126.1c-0.1-0.1-0.3-0.2-0.4-0.3C17.7,125.8,17.8,126,17.9,126.1z"/>
        <path fill="none" d="M16.6,124.7c-0.1-0.1-0.2-0.3-0.3-0.4C16.4,124.4,16.5,124.6,16.6,124.7z"/>
        <path fill="none" d="M16,123.9c-0.1-0.1-0.2-0.3-0.3-0.5C15.8,123.6,15.9,123.8,16,123.9z"/>
        <path fill="none" d="M17.2,125.4c-0.1-0.1-0.2-0.3-0.4-0.4C17,125.2,17.1,125.3,17.2,125.4z"/>
        <path fill="none" d="M22,128.3c-0.2-0.1-0.3-0.1-0.5-0.2C21.7,128.2,21.9,128.3,22,128.3z"/>
        <path fill="none" d="M20.3,127.6c-0.2-0.1-0.3-0.2-0.4-0.2C20,127.5,20.1,127.6,20.3,127.6z"/>
        <path fill="none" d="M42.4,81.3c0.1-0.1,0.2-0.1,0.3-0.2C42.6,81.1,42.5,81.2,42.4,81.3z"/>
        <path fill="none" d="M21.1,128c-0.2-0.1-0.3-0.1-0.5-0.2C20.8,127.9,21,128,21.1,128z"/>
        <path fill="none" d="M38.2,85.4c0.1-0.1,0.2-0.2,0.2-0.3C38.3,85.2,38.3,85.3,38.2,85.4z"/>
        <path fill="none" d="M15.5,123.1c-0.1-0.2-0.2-0.3-0.3-0.5C15.3,122.8,15.4,123,15.5,123.1z"/>
        <path fill="none" d="M37,87.1c0.1-0.1,0.1-0.2,0.2-0.3C37.1,86.9,37,87,37,87.1z"/>
        <path fill="none" d="M40.9,82.5c0.1-0.1,0.1-0.1,0.2-0.2C41,82.4,41,82.5,40.9,82.5z"/>
        <path fill="none" d="M39.6,83.8c0,0,0.1-0.1,0.1-0.1C39.6,83.7,39.6,83.8,39.6,83.8z"/>
        <path fill="none" d="M36,88.6C36,88.6,36,88.6,36,88.6C36,88.6,36,88.6,36,88.6z"/>
        <path fill="none" d="M23.9,128.8c-0.2,0-0.3,0-0.5-0.1C23.6,128.7,23.7,128.7,23.9,128.8z"/>
        <path fill="none" d="M14.7,121.3c-0.1-0.3-0.2-0.6-0.3-1C14.5,120.7,14.6,121,14.7,121.3z"/>
        <path fill="none" d="M15,122.3c-0.1-0.2-0.2-0.4-0.3-0.6C14.9,121.9,15,122.1,15,122.3z"/>
        <path fill="none" d="M34.2,92.5c0-0.1,0.1-0.2,0.1-0.3C34.3,92.3,34.3,92.4,34.2,92.5z"/>
        <path fill="none" d="M35,90.6c0-0.1,0.1-0.2,0.1-0.3C35.1,90.4,35.1,90.5,35,90.6z"/>
        <path fill="none" d="M23,128.6c-0.2,0-0.3-0.1-0.5-0.1C22.6,128.5,22.8,128.6,23,128.6z"/>
        <path fill="none" d="M166,71.6c0.1,0,0.1,0,0.2,0.1C166.1,71.6,166.1,71.6,166,71.6z"/>
        <path fill="none" d="M168.1,72.2c0.1,0,0.2,0.1,0.3,0.1C168.3,72.3,168.2,72.3,168.1,72.2z"/>
        <path fill="none" d="M170.3,73c0.1,0.1,0.3,0.1,0.4,0.2C170.5,73.2,170.4,73.1,170.3,73z"/>
        <path fill="none" d="M163.6,71.1c0.1,0,0.3,0,0.4,0.1C163.8,71.2,163.7,71.1,163.6,71.1z"/>
        <path fill="none" d="M172.5,74.1c0.1,0,0.2,0.1,0.2,0.1C172.7,74.2,172.6,74.1,172.5,74.1z"/>
        <path fill="none" d="M155.1,71c0.2,0,0.4-0.1,0.6-0.1C155.4,71,155.3,71,155.1,71z"/>
        <path fill="none" d="M153.4,71.3c0.2,0,0.3-0.1,0.5-0.1C153.7,71.2,153.5,71.3,153.4,71.3z"/>
        <path fill="none" d="M174.4,75.2c0.1,0,0.1,0.1,0.2,0.1C174.6,75.3,174.5,75.3,174.4,75.2z"/>
        <path fill="none" d="M156.8,70.8c0.2,0,0.3,0,0.5,0C157.2,70.8,157,70.8,156.8,70.8z"/>
        <path fill="none" d="M159,70.7C159,70.7,159,70.7,159,70.7c0,0,0.1,0,0.1,0C159.1,70.7,159,70.7,159,70.7z"/>
        <path fill="none" d="M176.3,76.5c0.1,0.1,0.2,0.2,0.3,0.2C176.6,76.7,176.5,76.6,176.3,76.5z"/>
        <path fill="none" d="M186.2,89.8c0,0.1,0.1,0.2,0.1,0.4C186.3,90,186.3,89.9,186.2,89.8z"/>
        <path fill="none" d="M185.2,87.4c0.1,0.1,0.1,0.2,0.2,0.4C185.4,87.7,185.3,87.5,185.2,87.4z"/>
        <path fill="none" d="M184.2,85.4C184.2,85.4,184.2,85.5,184.2,85.4C184.2,85.5,184.2,85.4,184.2,85.4z"/>
        <path fill="none" d="M25.5,128.9c0.2,0,0.5,0,0.7,0c-0.3,0-0.6,0-0.9,0C25.4,128.9,25.4,128.9,25.5,128.9z"/>
        <path fill="none" d="M178.2,78.1c0.1,0.1,0.2,0.1,0.2,0.2C178.4,78.2,178.3,78.1,178.2,78.1z"/>
        <path fill="none" d="M182.9,83.3c0.1,0.1,0.1,0.2,0.2,0.3C183,83.5,183,83.4,182.9,83.3z"/>
        <path fill="none" d="M24.9,128.9c-0.2,0-0.3,0-0.5,0C24.5,128.8,24.7,128.8,24.9,128.9z"/>
        <path fill="none" d="M179.9,79.7c0,0,0.1,0.1,0.1,0.1C180,79.7,180,79.7,179.9,79.7z"/>
        <path fill="none" d="M181.4,81.4c0.1,0.1,0.2,0.2,0.3,0.3C181.6,81.6,181.5,81.5,181.4,81.4z"/>
        <path fill="none" d="M161.2,70.8c0.1,0,0.2,0,0.3,0C161.4,70.8,161.3,70.8,161.2,70.8z"/>
        <path fill="none" d="M47.5,78.3c0.1,0,0.2-0.1,0.3-0.1C47.7,78.2,47.6,78.2,47.5,78.3z"/>
        <path fill="none" d="M45.7,79.1c0.1,0,0.1-0.1,0.2-0.1C45.9,79.1,45.8,79.1,45.7,79.1z"/>
        <path fill="none" d="M51.3,77c0.1,0,0.1,0,0.2,0C51.4,76.9,51.3,76.9,51.3,77z"/>
        <path fill="none" d="M53.2,76.6C53.2,76.6,53.2,76.6,53.2,76.6C53.2,76.6,53.2,76.6,53.2,76.6C53.2,76.6,53.2,76.6,53.2,76.6z"/>
        <path fill="none" d="M49.4,77.5c0.1,0,0.2-0.1,0.3-0.1C49.6,77.5,49.5,77.5,49.4,77.5z"/>
        <path fill="none" d="M44.1,80.1c0.1,0,0.1-0.1,0.2-0.1C44.2,80,44.2,80,44.1,80.1z"/>
        <path fill="#DDC72D" d="M61.7,76.6c0.1,0,0.3,0,0.4,0.1C61.9,76.6,61.8,76.6,61.7,76.6z"/>
        <path fill="#DDC72D" d="M57.4,76.2C57.3,76.2,57.3,76.2,57.4,76.2C57.3,76.2,57.3,76.2,57.4,76.2C57.4,76.2,57.4,76.2,57.4,76.2z"
          />
        <path fill="#DDC72D" d="M58.8,76.2c0.1,0,0.3,0,0.4,0C59,76.2,58.9,76.2,58.8,76.2z"/>
        <path fill="#F9D915" d="M60,70.3c2.6-9.1,7.8-17.3,15-23.6c3.6-3.2,7.7-5.7,12-7.7c-4.7-5.9-11.9-9.6-20-9.6
          c-14.1,0-25.6,11.5-25.6,25.6c0,6.4,2.4,12.3,6.3,16.8c3.1-1,6.3-1.6,9.7-1.6C58.2,70.2,59.1,70.2,60,70.3z"/>
        <path fill="#DDC72D" d="M60.2,76.4c0.2,0,0.3,0,0.5,0.1C60.5,76.4,60.3,76.4,60.2,76.4z"/>
        <path fill="#DDC72D" d="M55.2,76.3c0.1,0,0.2,0,0.3,0C55.4,76.3,55.3,76.3,55.2,76.3z"/>
        <path fill="#DDC72D" d="M53.5,76.5c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0C53.3,76.5,53.4,76.5,53.5,76.5z"/>
        <path fill="#F9D915" d="M38.7,9.8l8.7,21.1c2.5-2.1,5.4-3.7,8.6-4.9L42.1,7.8c0,0,0,0,0,0c-0.6-0.8-1.9-1-2.7-0.3
          c-0.7,0.5-0.9,1.4-0.7,2.2C38.7,9.7,38.7,9.7,38.7,9.8z"/>
        <path fill="#F9D915" d="M72,24.4L69.1,1.7c0,0,0,0,0-0.1c-0.2-1-1.1-1.8-2.2-1.7c-0.8,0.1-1.5,0.7-1.7,1.5c0,0,0,0.1,0,0.1
          l-3,22.6c1.6-0.3,3.2-0.4,4.9-0.4C68.7,23.9,70.3,24.1,72,24.4z"/>
        <path fill="#F9D915" d="M13.5,56.8c0,0,0.1,0,0.1,0l22.6,3c-0.3-1.6-0.4-3.2-0.4-4.9c0-1.7,0.1-3.4,0.4-5l-22.6,2.9c0,0,0,0-0.1,0
          c-1,0.2-1.8,1.1-1.6,2.2C12.1,56,12.7,56.6,13.5,56.8z"/>
        <path fill="#F9D915" d="M95.4,9.9C95.4,9.9,95.4,9.9,95.4,9.9c0.2-0.5,0.2-1,0-1.5c-0.2-0.5-0.6-0.9-1.1-1.1
          c-0.8-0.3-1.7-0.1-2.2,0.5c0,0-0.1,0.1-0.1,0.1L78.1,26c3.1,1.2,6,2.9,8.5,5L95.4,9.9z"/>
        <path fill="#F9D915" d="M19.9,29.9l18.1,14c1.2-3.1,2.9-6,5-8.5l-21.1-8.8c0,0,0,0-0.1,0c-1-0.4-2.1,0.1-2.5,1.1
          c-0.3,0.8-0.1,1.7,0.5,2.2C19.8,29.9,19.9,29.9,19.9,29.9z"/>
        <path fill="#D3D3D3" d="M187,92.3c-0.2-0.7-0.4-1.4-0.7-2.2c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.7-0.5-1.3-0.8-2
          c-0.1-0.1-0.1-0.2-0.2-0.4c-0.3-0.7-0.6-1.3-1-1.9c0,0,0-0.1-0.1-0.1c-0.3-0.6-0.7-1.2-1.1-1.8c-0.1-0.1-0.1-0.2-0.2-0.3
          c-0.4-0.6-0.8-1.1-1.2-1.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.5-0.9-1.1-1.4-1.6c0,0-0.1-0.1-0.1-0.1c-0.5-0.5-0.9-0.9-1.4-1.4
          c-0.1-0.1-0.2-0.1-0.2-0.2c-0.5-0.5-1-0.9-1.6-1.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.5-0.4-1.1-0.8-1.7-1.2c-0.1,0-0.1-0.1-0.2-0.1
          c-0.6-0.4-1.1-0.7-1.7-1c-0.1,0-0.2-0.1-0.2-0.1c-0.6-0.3-1.2-0.6-1.8-0.9c-0.1-0.1-0.2-0.1-0.4-0.2c-0.6-0.3-1.3-0.5-1.9-0.7
          c-0.1,0-0.2-0.1-0.3-0.1c-0.6-0.2-1.3-0.4-1.9-0.6c-0.1,0-0.1,0-0.2-0.1c-0.7-0.2-1.3-0.3-2-0.4c-0.1,0-0.3,0-0.4-0.1
          c-0.7-0.1-1.4-0.2-2-0.3c-0.1,0-0.2,0-0.3,0c-0.7-0.1-1.4-0.1-2.1-0.1c0,0-0.1,0-0.1,0c-0.5,0-1.1,0-1.6,0.1c-0.2,0-0.3,0-0.5,0
          c-0.4,0-0.8,0.1-1.2,0.1c-0.2,0-0.4,0-0.6,0.1c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.1-1.1,0.2-1.7,0.4
          c0,0-1.1,0.3-3.1,0.8c-4.9-18.3-21.6-31.8-41.5-31.8c-2.6,0-5.2,0.2-7.6,0.7c-1.4,0.3-2.8,0.6-4.2,1c-1.8,0.5-3.5,1.1-5.1,1.8
          c-13.4,5.8-23.3,18.2-25.5,33.1c-0.4-0.1-0.6-0.2-0.8-0.2C63.6,77,63.5,77,63.5,77c-0.5-0.1-0.9-0.2-1.4-0.3
          c-0.1,0-0.3-0.1-0.4-0.1c-0.3-0.1-0.7-0.1-1-0.2c-0.2,0-0.3,0-0.5-0.1c-0.3,0-0.7-0.1-1-0.1c-0.1,0-0.3,0-0.4,0
          c-0.5,0-0.9,0-1.4,0c0,0-0.1,0-0.1,0c-0.6,0-1.2,0-1.8,0.1c-0.1,0-0.2,0-0.3,0c-0.6,0.1-1.2,0.1-1.7,0.2c-0.1,0-0.2,0-0.3,0.1
          c0,0,0,0,0,0c-0.6,0.1-1.1,0.2-1.7,0.4c-0.1,0-0.1,0-0.2,0c-0.5,0.1-1.1,0.3-1.6,0.5c-0.1,0-0.2,0.1-0.3,0.1
          c-0.5,0.2-1.1,0.4-1.6,0.6c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.2-1,0.5-1.5,0.7c-0.1,0-0.1,0.1-0.2,0.1c-0.5,0.3-0.9,0.5-1.4,0.8
          c-0.1,0-0.1,0.1-0.2,0.1c-0.5,0.3-0.9,0.6-1.4,1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5,0.3-0.9,0.7-1.3,1.1c-0.1,0.1-0.1,0.1-0.2,0.2
          c-0.4,0.4-0.8,0.8-1.2,1.2c0,0-0.1,0.1-0.1,0.1c-0.4,0.4-0.8,0.9-1.2,1.3c-0.1,0.1-0.2,0.2-0.2,0.3c-0.4,0.4-0.7,0.9-1,1.4
          C37.1,86.9,37,87,37,87.1c-0.3,0.5-0.6,1-0.9,1.5c0,0,0,0.1-0.1,0.1c-0.3,0.5-0.6,1.1-0.8,1.6c0,0.1-0.1,0.2-0.1,0.3
          c-0.2,0.5-0.5,1.1-0.7,1.7c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.6-0.4,1.2-0.6,1.8c-0.2,0.8-0.4,1.5-0.5,2.3S33,98.2,32.9,99
          c-0.1,1.5-0.1,3,0.1,4.5c-6.5,1.8-10.5,2.8-10.5,2.8c-6.1,1.6-9.8,7.9-8.1,14c0.1,0.3,0.2,0.6,0.3,1c0,0.1,0.1,0.2,0.1,0.3
          c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.3,0.3,0.5
          c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.2,0.2,0.3,0.3
          c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.3
          c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0.1,0.3,0.1,0.4,0.2
          c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1
          c0.1,0,0.3,0,0.4,0.1c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0c0.3,0,0.6,0,0.9,0h132.1c0,0,5.5-0.3,8.2-1
          C182,123.7,191.2,107.8,187,92.3z"/>
        <path fill="#F9D915" d="M42.5,74.1c-1.9-2.4-3.5-5.1-4.6-8.1L19.8,79.8c0,0,0,0-0.1,0c-0.4,0.3-0.6,0.8-0.7,1.3
          c-0.1,0.5,0.1,1,0.4,1.5c0.5,0.7,1.4,0.9,2.2,0.7c0,0,0.1,0,0.1,0l18-7.4l3.2-1.3C42.7,74.4,42.6,74.2,42.5,74.1z"/>
        <path fill="#FFFFFF" d="M181.7,81.7c0.4,0.5,0.8,1.1,1.2,1.7C182.5,82.8,182.1,82.2,181.7,81.7z"/>
        <path fill="#FFFFFF" d="M183.1,83.6c0.4,0.6,0.8,1.2,1.1,1.8C183.9,84.8,183.5,84.2,183.1,83.6z"/>
        <path fill="#FFFFFF" d="M178.5,78.3c0.5,0.4,1,0.9,1.4,1.4C179.5,79.2,179,78.7,178.5,78.3z"/>
        <path fill="#FFFFFF" d="M180,79.8c0.5,0.5,0.9,1,1.4,1.6C181,80.8,180.5,80.3,180,79.8z"/>
        <path fill="#FFFFFF" d="M164,71.2c0.7,0.1,1.4,0.3,2,0.4C165.3,71.4,164.6,71.3,164,71.2z"/>
        <path fill="#FFFFFF" d="M155.6,70.9c0.4,0,0.8-0.1,1.2-0.1C156.4,70.9,156,70.9,155.6,70.9z"/>
        <path fill="#FFFFFF" d="M157.3,70.8c0.6,0,1.1-0.1,1.6-0.1C158.4,70.7,157.9,70.8,157.3,70.8z"/>
        <path fill="#FFFFFF" d="M41.1,82.4c0.4-0.4,0.9-0.7,1.3-1.1C42,81.6,41.5,82,41.1,82.4z"/>
        <path fill="#FFFFFF" d="M151.7,71.7c0.6-0.1,1.1-0.3,1.7-0.4C152.8,71.4,152.3,71.5,151.7,71.7z"/>
        <path fill="#FFFFFF" d="M161.5,70.9c0.7,0.1,1.4,0.1,2,0.3C162.9,71,162.2,70.9,161.5,70.9z"/>
        <path fill="#FFFFFF" d="M153.9,71.2c0.4-0.1,0.8-0.1,1.2-0.2C154.7,71.1,154.3,71.1,153.9,71.2z"/>
        <path fill="#FFFFFF" d="M170.7,73.2c0.6,0.3,1.2,0.6,1.8,0.9C171.9,73.8,171.3,73.5,170.7,73.2z"/>
        <path fill="#FFFFFF" d="M172.7,74.2c0.6,0.3,1.1,0.6,1.7,1C173.9,74.9,173.3,74.5,172.7,74.2z"/>
        <path fill="#FFFFFF" d="M174.7,75.3c0.6,0.4,1.1,0.8,1.7,1.2C175.8,76.1,175.2,75.7,174.7,75.3z"/>
        <path fill="#FFFFFF" d="M184.3,85.5c0.4,0.6,0.7,1.3,1,1.9C184.9,86.8,184.6,86.1,184.3,85.5z"/>
        <path fill="#FFFFFF" d="M176.7,76.8c0.5,0.4,1.1,0.8,1.6,1.3C177.7,77.6,177.2,77.2,176.7,76.8z"/>
        <path fill="#FFFFFF" d="M168.4,72.3c0.6,0.2,1.3,0.5,1.9,0.7C169.7,72.8,169,72.5,168.4,72.3z"/>
        <path fill="#FFFFFF" d="M166.2,71.7c0.6,0.2,1.3,0.4,1.9,0.6C167.5,72,166.8,71.8,166.2,71.7z"/>
        <path fill="#FFFFFF" d="M159.1,70.7c0.7,0,1.4,0,2.1,0.1C160.5,70.8,159.8,70.7,159.1,70.7z"/>
        <path fill="#FFFFFF" d="M33.2,96.7c0.1-0.8,0.3-1.5,0.5-2.3C33.5,95.1,33.3,95.9,33.2,96.7z"/>
        <path fill="#FFFFFF" d="M47.8,78.1c0.5-0.2,1.1-0.4,1.6-0.6C48.9,77.7,48.3,77.9,47.8,78.1z"/>
        <path fill="#FFFFFF" d="M33.7,94.4c0.2-0.6,0.4-1.2,0.6-1.8C34,93.1,33.8,93.7,33.7,94.4z"/>
        <path fill="#FFFFFF" d="M14.8,121.6c0-0.1-0.1-0.2-0.1-0.3C14.7,121.4,14.8,121.5,14.8,121.6z"/>
        <path fill="#FFFFFF" d="M32.9,99c0.1-0.8,0.1-1.5,0.3-2.3C33,97.4,33,98.2,32.9,99z"/>
        <path fill="#FFFFFF" d="M51.4,76.9c0.6-0.1,1.1-0.3,1.7-0.4C52.6,76.7,52,76.8,51.4,76.9z"/>
        <path fill="#FFFFFF" d="M15.7,123.5c-0.1-0.1-0.2-0.2-0.2-0.4C15.6,123.2,15.6,123.4,15.7,123.5z"/>
        <path fill="#FFFFFF" d="M15.2,122.6c-0.1-0.1-0.1-0.2-0.2-0.4C15.1,122.4,15.2,122.5,15.2,122.6z"/>
        <path fill="#FFFFFF" d="M49.7,77.4c0.5-0.2,1.1-0.3,1.6-0.5C50.7,77.1,50.2,77.3,49.7,77.4z"/>
        <path fill="#FFFFFF" d="M39.7,83.7c0.4-0.4,0.8-0.8,1.2-1.2C40.5,82.9,40.1,83.3,39.7,83.7z"/>
        <path fill="#FFFFFF" d="M44.3,80c0.5-0.3,0.9-0.6,1.4-0.8C45.2,79.4,44.8,79.7,44.3,80z"/>
        <path fill="#FFFFFF" d="M16.3,124.3c-0.1-0.1-0.2-0.2-0.3-0.3C16.1,124.1,16.2,124.2,16.3,124.3z"/>
        <path fill="#FFFFFF" d="M38.4,85.1c0.4-0.5,0.8-0.9,1.2-1.3C39.2,84.3,38.8,84.7,38.4,85.1z"/>
        <path fill="#FFFFFF" d="M42.7,81.1c0.5-0.3,0.9-0.7,1.4-1C43.6,80.4,43.2,80.7,42.7,81.1z"/>
        <path fill="#FFFFFF" d="M36.1,88.5c0.3-0.5,0.6-1,0.9-1.5C36.7,87.5,36.3,88,36.1,88.5z"/>
        <path fill="#FFFFFF" d="M45.9,79c0.5-0.3,1-0.5,1.5-0.7C47,78.5,46.4,78.7,45.9,79z"/>
        <path fill="#FFFFFF" d="M35.2,90.3c0.3-0.6,0.5-1.1,0.8-1.6C35.7,89.2,35.4,89.7,35.2,90.3z"/>
        <path fill="#FFFFFF" d="M34.3,92.2c0.2-0.6,0.4-1.1,0.7-1.7C34.8,91.1,34.6,91.7,34.3,92.2z"/>
        <path fill="#FFFFFF" d="M37.1,86.8c0.3-0.5,0.7-0.9,1-1.4C37.8,85.9,37.5,86.3,37.1,86.8z"/>
        <path fill="#FFFFFF" d="M185.4,87.8c0.3,0.6,0.6,1.3,0.8,2C186,89.1,185.7,88.4,185.4,87.8z"/>
        <path fill="#FFFFFF" d="M22.5,128.5c-0.1,0-0.3-0.1-0.4-0.1C22.2,128.4,22.3,128.4,22.5,128.5z"/>
        <path fill="#FFFFFF" d="M23.4,128.7c-0.1,0-0.3-0.1-0.4-0.1C23.1,128.6,23.2,128.7,23.4,128.7z"/>
        <path fill="#FFFFFF" d="M20.7,127.8c-0.1-0.1-0.3-0.1-0.4-0.2C20.4,127.7,20.5,127.8,20.7,127.8z"/>
        <path fill="#FFFFFF" d="M25.3,128.9c-0.1,0-0.3,0-0.4,0C25,128.9,25.2,128.9,25.3,128.9z"/>
        <path fill="#FFFFFF" d="M24.3,128.8c-0.1,0-0.3,0-0.4-0.1C24,128.8,24.2,128.8,24.3,128.8z"/>
        <path fill="#FFFFFF" d="M186.4,90.1c0.2,0.7,0.5,1.4,0.7,2.2C186.8,91.5,186.6,90.8,186.4,90.1z"/>
        <path fill="#FFFFFF" d="M19.8,127.4c-0.1-0.1-0.3-0.1-0.4-0.2C19.6,127.3,19.7,127.3,19.8,127.4z"/>
        <path fill="#FFFFFF" d="M21.6,128.2c-0.1-0.1-0.3-0.1-0.4-0.2C21.3,128.1,21.4,128.1,21.6,128.2z"/>
        <path fill="#FFFFFF" d="M17.6,125.7c-0.1-0.1-0.2-0.2-0.3-0.3C17.3,125.5,17.4,125.6,17.6,125.7z"/>
        <path fill="#FFFFFF" d="M18.3,126.3c-0.1-0.1-0.2-0.2-0.3-0.3C18,126.2,18.2,126.3,18.3,126.3z"/>
        <path fill="#FFFFFF" d="M16.9,125c-0.1-0.1-0.2-0.2-0.3-0.3C16.7,124.8,16.8,124.9,16.9,125z"/>
        <path fill="#FFFFFF" d="M19,126.9c-0.1-0.1-0.2-0.2-0.4-0.3C18.8,126.7,18.9,126.8,19,126.9z"/>
        <path fill="#FFFFFF" d="M63.8,77C63.6,77,63.5,77,63.5,77c-0.5-0.1-0.9-0.2-1.4-0.3c0.5,0.1,0.9,0.2,1.4,0.3
          C63.5,77,63.6,77,63.8,77z"/>
        <path fill="#FFFFFF" d="M59.2,76.3c0.3,0,0.7,0.1,1,0.1C59.8,76.3,59.5,76.3,59.2,76.3z"/>
        <path fill="#FFFFFF" d="M63.8,77c0.2,0.1,0.5,0.1,0.8,0.2C64.3,77.2,64,77.1,63.8,77z"/>
        <path fill="#FFFFFF" d="M57.4,76.2c0.5,0,0.9,0,1.4,0C58.3,76.2,57.8,76.2,57.4,76.2z"/>
        <path fill="#FFFFFF" d="M53.5,76.5c0.6-0.1,1.1-0.2,1.7-0.2C54.6,76.3,54.1,76.4,53.5,76.5z"/>
        <path fill="#FFFFFF" d="M60.7,76.4c0.3,0,0.7,0.1,1,0.2C61.3,76.5,61,76.5,60.7,76.4z"/>
        <path fill="#FFFFFF" d="M55.5,76.3c0.6,0,1.2-0.1,1.8-0.1C56.7,76.2,56.1,76.2,55.5,76.3z"/>
      </g>
      <path fill="#D3D3D3" d="M77.1,153.8c-0.1,0-0.1,0-0.2,0l-6,1.6l-5.3-3.1l5.3-3l6,1.6c0.4,0.1,0.8-0.1,0.9-0.5
        c0.1-0.3-0.1-0.6-0.3-0.8c0,0,0,0,0,0l-3.7-2.1l3.4-2.6l0,0c0.3-0.2,0.3-0.5,0.2-0.8c-0.1-0.3-0.4-0.4-0.7-0.4
        c-0.1,0-0.2,0-0.3,0.1l-3.9,1.6v-4.2c0-0.3-0.2-0.7-0.5-0.7c-0.4-0.1-0.8,0.1-0.9,0.5l-1.6,6l-5.3,3.1v-6.1l4.4-4.4
        c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.2-0.6-0.3-0.9-0.1c0,0,0,0,0,0l-3.7,2.1l-0.6-4.3c-0.1-0.3-0.3-0.6-0.7-0.6
        c0,0-0.1,0-0.1,0c-0.3,0-0.6,0.3-0.6,0.6c0,0,0,0,0,0l-0.5,4.2l-3.7-2.1c0,0,0,0,0,0c-0.3-0.1-0.6-0.1-0.9,0.1
        c-0.3,0.3-0.3,0.7,0,1l4.4,4.4v6.1l-5.3-3.1l-1.6-6c-0.1-0.4-0.5-0.6-0.9-0.5c-0.3,0.1-0.5,0.4-0.5,0.7v0v4.3l-3.9-1.6
        c-0.3-0.1-0.8,0-0.9,0.4c-0.1,0.3,0,0.6,0.2,0.8c0,0,0,0,0,0l3.4,2.6l-3.7,2.1c0,0,0,0,0,0c-0.3,0.2-0.4,0.5-0.3,0.8
        c0.1,0.3,0.4,0.5,0.7,0.5c0.1,0,0.1,0,0.2,0l6-1.6l5.3,3l-5.2,3.1l-6-1.6c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.3,0.1,0.6,0.3,0.8
        c0,0,0,0,0,0l3.7,2.1l-3.4,2.6c0,0,0,0,0,0c-0.3,0.2-0.3,0.5-0.2,0.8c0.1,0.3,0.4,0.4,0.7,0.4c0.1,0,0.2,0,0.3-0.1l3.9-1.6v4.3l0,0
        c0,0.3,0.2,0.6,0.5,0.7c0.4,0.1,0.8-0.1,0.9-0.5l1.6-6l5.3-3v6.1l-4.4,4.4c-0.1,0.1-0.2,0.3-0.2,0.5s0.1,0.4,0.2,0.5
        c0.2,0.2,0.6,0.3,0.9,0.1l0,0l3.7-2.1l0.6,4.3c0.1,0.3,0.3,0.6,0.7,0.6c0,0,0.1,0,0.1,0c0.3-0.1,0.6-0.3,0.6-0.6c0,0,0,0,0,0
        l0.5-4.2l3.7,2.1h0c0.3,0.1,0.6,0.1,0.9-0.1c0.3-0.3,0.3-0.7,0-1l-4.4-4.4v-6.1l5.3,3l1.6,6c0.1,0.4,0.5,0.6,0.9,0.5
        c0.3-0.1,0.5-0.4,0.5-0.7l0,0v-4.3l4,1.6c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.5-0.2,0.7-0.4c0.1-0.3,0-0.6-0.2-0.8c0,0,0,0,0,0l-3.4-2.6
        l3.7-2.1l0,0c0.3-0.1,0.4-0.5,0.3-0.8C77.7,154,77.4,153.8,77.1,153.8z M62.8,168.8L62.8,168.8L62.8,168.8L62.8,168.8z"/>
      <path fill="#D3D3D3" d="M150.9,171.9c-0.1,0-0.2,0-0.4,0.1l-11,2.9l-9.7-5.6l9.7-5.6l11.1,3c0.7,0.2,1.4-0.3,1.6-0.9
        c0.2-0.6-0.1-1.2-0.6-1.5c0,0,0,0-0.1,0l-6.8-3.9l6.2-4.7l0,0c0.5-0.4,0.6-1,0.4-1.5c-0.2-0.5-0.7-0.8-1.2-0.8
        c-0.2,0-0.3,0.1-0.5,0.1l-7.2,3v-7.8c0.1-0.6-0.4-1.2-0.9-1.4c-0.7-0.2-1.4,0.2-1.6,0.9l-2.9,11l-9.7,5.6v-11.2l8.1-8.1
        c0.3-0.3,0.4-0.6,0.4-0.9c0-0.4-0.2-0.7-0.4-0.9c-0.4-0.4-1.1-0.5-1.6-0.2c0,0,0,0-0.1,0l-6.8,3.9l-1-7.8c-0.1-0.6-0.6-1.1-1.3-1.1
        c-0.1,0-0.2,0-0.2,0c-0.6,0.1-1,0.5-1.1,1.1c0,0,0,0,0,0.1l-1,7.7l-6.8-3.9c0,0,0,0-0.1,0c-0.5-0.3-1.2-0.2-1.6,0.2
        c-0.5,0.5-0.5,1.3,0.1,1.8l8.1,8.1v11.2l-9.7-5.6l-3-11.1c-0.2-0.7-0.9-1.1-1.6-0.9c-0.6,0.2-0.9,0.7-0.9,1.3v0.1v7.8l-7.2-3
        c-0.6-0.3-1.4,0.1-1.7,0.7c-0.2,0.5-0.1,1.2,0.4,1.5c0,0,0,0,0.1,0l6.2,4.7l-6.8,3.9c0,0,0,0-0.1,0c-0.5,0.3-0.8,0.9-0.6,1.5
        c0.2,0.6,0.7,0.9,1.3,0.9c0.1,0,0.2,0,0.4-0.1l11-2.9l9.7,5.6l-9.5,5.6l-11.1-3c-0.7-0.2-1.4,0.3-1.6,0.9c-0.2,0.6,0.1,1.2,0.6,1.5
        c0,0,0,0,0.1,0l6.8,3.9l-6.2,4.7c0,0,0,0-0.1,0c-0.5,0.4-0.6,1-0.4,1.5c0.2,0.5,0.7,0.8,1.2,0.8c0.2,0,0.3-0.1,0.5-0.1l7.2-3v7.8
        l0,0c0,0.6,0.4,1.1,0.9,1.3c0.7,0.2,1.4-0.2,1.6-0.9l2.9-11l9.7-5.6v11.2l-8.1,8.1c-0.3,0.3-0.4,0.6-0.4,0.9c0,0.3,0.2,0.7,0.4,0.9
        c0.4,0.4,1.1,0.5,1.6,0.2l0,0l6.8-3.9l1,7.8c0.1,0.6,0.6,1.1,1.3,1.1c0.1,0,0.2,0,0.2,0c0.6-0.1,1-0.5,1.1-1.1c0,0,0,0,0-0.1l1-7.7
        l6.8,3.9h0.1c0.5,0.3,1.2,0.2,1.6-0.2c0.5-0.5,0.5-1.3-0.1-1.9l-8.1-8.1v-11.2l9.7,5.6l3,11.1c0.2,0.7,0.9,1.1,1.6,0.9
        c0.6-0.2,0.9-0.7,0.9-1.3l0,0v-7.8l7.3,3c0.2,0.1,0.3,0.1,0.5,0.1c0.5,0,1-0.3,1.2-0.8c0.2-0.5,0.1-1.2-0.4-1.5c0,0,0,0-0.1,0
        l-6.2-4.7l6.8-3.9l0,0c0.5-0.3,0.8-0.9,0.6-1.5C152,172.3,151.5,171.9,150.9,171.9z M124.7,199.3L124.7,199.3L124.7,199.3
        L124.7,199.3z"/>
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
