import React from 'react'


import PropTypes from 'prop-types'

import Link from '@material-ui/core/Link'

import { trash, download } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import Icon from '@smartcoop/web-components/Icon'

import {
  ArchiveName,
  Container,
  DeleteDocument,
  DownloadDocument
} from './styles'

const CooperativeDocument = ({
  documentName,
  deleteDocument,
  documentSrc,
  alignOnEnd,
  style,
  textStyle
}) => (
  <Container style={ style }>
    <ArchiveName alignOnEnd={ alignOnEnd } style={ textStyle }>
      {documentName}
    </ArchiveName>
    {(deleteDocument || documentSrc) &&
      <div style={ { display: 'flex', flexDirection: 'row' } }>
        {deleteDocument &&
          <DeleteDocument
            id="delete-document"
            variant="outlined"
            onClick={ deleteDocument }
          >
            <Icon size={ 14 } icon={ trash } color={ colors.red }/>
          </DeleteDocument>
        }
        {document &&
          <DownloadDocument
            id="download-document"
            variant="outlined"
            component={ Link }
            href={ documentSrc  }
            download={ documentName }
            alignOnEnd={ alignOnEnd }
          >
            <Icon size={ 14 } icon={ download }/>
          </DownloadDocument>
        }
      </div>
    }
  </Container>
)


CooperativeDocument.propTypes = {
  style: PropTypes.object,
  textStyle: PropTypes.object,
  documentName: PropTypes.string,
  alignOnEnd: PropTypes.bool,
  deleteDocument: PropTypes.func,
  documentSrc: PropTypes.string
}

CooperativeDocument.defaultProps = {
  style: {},
  textStyle: {},
  documentName: null,
  alignOnEnd: false,
  deleteDocument: null,
  documentSrc: ''
}

export default CooperativeDocument
