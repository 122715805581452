import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'



export const Container = styled.div`
  background: ${ colors.white };
  box-shadow: 0px 0px 6px ${ colors.semiTransparent };
  border-radius: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 15px;
  margin: 15px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
`
