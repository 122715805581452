import React, {  useMemo } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import first from 'lodash/first'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'

import { groupMemberService } from '@smartcoop/database/services/groupMemberService'
import { profilePictureService } from '@smartcoop/database/services/profilePictureService'
import { database } from '@smartcoop/database/web-database'
import { close, forward, search, trash } from '@smartcoop/icons'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'

import ChatAvatar from '../../../components/ChatAvatar/ChatAvatar'
import { ActionsWrapper, ConversationDetails, HeaderContainer, InfoWrapper, MembersSpan, NameText } from './styles'

const ChatHeader = ({
  conversation,
  profilePictures,
  handleDeleteMessages,
  handleForwardMessages,
  handleShowDetails,
  handleShowSearch,
  handleCancelSelecion,
  showOptions,
  members
}) => {
  const profilePicture = first(profilePictures)

  const orderedGroupMembers = useMemo(
    () => uniqBy(orderBy(members, ['isAdmin', 'userName'], 'desc'), 'userId'),
    [members]
  )

  const membersNames = useMemo(
    () => orderedGroupMembers.map(item => item.userName).join(', '),
    [orderedGroupMembers]
  )

  return (
    <HeaderContainer>
      { conversation && (
        <>
          <InfoWrapper onClick={ handleShowDetails }>
            <ChatAvatar
              isGroup={ conversation.group }
              isBroadcast={ conversation.broadcast }
              sourceImage={ profilePicture?.source }
              size={ 40 }
            />

            { conversation?.group || conversation?.broadcast ? (
              <ConversationDetails>
                <NameText>{conversation?.name}</NameText>
                <MembersSpan>{membersNames}</MembersSpan>
              </ConversationDetails>
            ) : (
              <NameText>{conversation?.name}</NameText>
            ) }
          </InfoWrapper>
          <ActionsWrapper>
            <IconButton size="medium" onClick={ handleShowSearch }>
              <Icon icon={ search } size={ 20 } />
            </IconButton>
            { showOptions && (
              <>
                <IconButton size="medium" onClick={ handleCancelSelecion }>
                  <Icon icon={ close } size={ 25 } />
                </IconButton>
                <IconButton size="medium" onClick={ handleDeleteMessages }>
                  <Icon icon={ trash } size={ 20 } />
                </IconButton>
                <IconButton size="medium" onClick={ handleForwardMessages }>
                  <Icon icon={ forward } size={ 20 } />
                </IconButton>
              </>
            ) }
          </ActionsWrapper>
        </>
      ) }
    </HeaderContainer>
  )
}

ChatHeader.propTypes = {
  conversation: PropTypes.object.isRequired,
  profilePictures: PropTypes.array,
  members: PropTypes.array,
  handleDeleteMessages: PropTypes.func.isRequired,
  handleForwardMessages: PropTypes.func.isRequired,
  handleShowDetails: PropTypes.func.isRequired,
  handleShowSearch: PropTypes.func.isRequired,
  handleCancelSelecion: PropTypes.func.isRequired,
  showOptions: PropTypes.bool
}

ChatHeader.defaultProps = {
  profilePictures: [],
  members: [],
  showOptions: false
}

const enhance = withObservables(['userId', 'conversationId', 'isGroup', 'isBroadcast'], ({ userId, conversationId, isGroup, isBroadcast }) => ({
  profilePictures: profilePictureService(database).observeProfilePictureByUserId(userId, isGroup),
  members: groupMemberService(database).observeGroupMemberByGroupId(conversationId, isBroadcast)
}))

const EnhancedChatHeader = enhance(ChatHeader)

export default EnhancedChatHeader
