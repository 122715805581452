import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { toString } from 'lodash'

import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterMilkDeliveryForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterMilkDeliveryForm'

import useStyles from './styles'

const RegisterMilkDeliveryModal = (props) => {
  const {
    id,
    open,
    data,
    handleClose,
    reloadDataTable
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const [isLoading, setIsLoading] = useState(false)
  const t = useT()

  const defaultValues = useMemo(
    () => ({
      ...data,
      volumeDate: toString(data.volumeDate) ?? '',
      volume: toString(data.volume) ?? '',
      condemnedVolume: toString(data.condemnedVolume) ?? '',
      observation: data.observation ?? '',
      temperature: toString(data.temperature) ?? ''
    }), [data]
  )

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      reloadDataTable()
      handleClose()
    }, [handleClose, reloadDataTable]
  )

  const isEditing = useMemo(
    () => (defaultValues?.id), [defaultValues]
  )

  const onSuccess = useCallback(
    () => {
      snackbar.success(
        t(`your {this} was ${  isEditing ? 'edited' :'registered' }`, {
          howMany: 1,
          gender: 'female',
          this: t('delivery', { howMany: 1 })
        })
      )
      closeModal()
    },
    [closeModal, isEditing, snackbar, t]
  )

  const submitForms = useCallback(
    (formData) => {
      setIsLoading(true)
      dispatch(DairyFarmActions.saveMilkDelivery(
        {
          ...data,
          ...formData
        },
        onSuccess,
        () => setIsLoading(false)
      ))
    }, [data, dispatch, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n>register delivery</I18n>
        </>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      {
        isLoading ?
          <Loader width={ 100 } /> :
          (
            <RegisterMilkDeliveryForm
              ref={ formRef }
              defaultValues={ defaultValues }
              onSubmit={ submitForms }
              onCancel={ closeModal }
            />
          )
      }
    </Modal>
  )}

RegisterMilkDeliveryModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleClose: PropTypes.func,
  reloadDataTable: PropTypes.func
}

RegisterMilkDeliveryModal.defaultProps = {
  handleClose: () => {},
  reloadDataTable: () => {},
  data: {}
}

export default RegisterMilkDeliveryModal
