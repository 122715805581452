import React, { useMemo, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'


import confirmPasswordSchema from '@smartcoop/forms/schemas/auth/confirmPassword.schema'
import I18n, { useT } from '@smartcoop/i18n'
import {
  getOrganizationsWithSignatures as getOrganizationsWithSignaturesService
} from '@smartcoop/services/apis/smartcoopApi/resources/organization'
import { useSnackbar } from '@smartcoop/snackbar'
import { OrganizationActions } from '@smartcoop/stores/organization'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputPassword from '@smartcoop/web-components/InputPassword'
import InputSelect from '@smartcoop/web-components/InputSelect'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'

import {
  Container,
  Content,
  Buttons,
  Title,
  Item
} from './styles'

const ChangeSignatureModal = ({ id, open, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const t = useT()
  const user = useSelector(selectUser)

  const formRef = useRef(null)
  const onClose = useCallback(
    () => {handleClose()}, [handleClose]
  )

  const urlParams = useMemo(
    () => ({ userId: user.id }), [user]
  )

  const onSuccess = useCallback(
    () => {
      setIsLoading(false)
      snackbar.success(t('your new password was generated and sent to your email and SMS'))
      onClose()
    }, [onClose, snackbar, t]
  )

  const onError = useCallback(
    (error) => {
      setIsLoading(false)
      formRef.current.reset()
      snackbar.error(t(error.message))
    }, [snackbar, t]
  )

  const onSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(OrganizationActions.updateSignature(
        { ...user, ...data },
        () => {onSuccess()},
        (error) => {onError(error)}
      ))
    }, [dispatch, onError, onSuccess, user]
  )

  const schemaConstructor = useCallback(
    (opts) => confirmPasswordSchema({ ...opts, user }),
    [user]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      disableFullScreen
      escape={ false }
      title={
        <I18n>eletronic signature</I18n>
      }

    >
      <Container>
        {isLoading ? <Loader /> :
          <>
            <Content>
              <Item>
                <I18n as={ Title }>
                      please confirm your password to update the eletronic signature
                </I18n>
              </Item>
              <Item>
                <Form
                  style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
                  ref={ formRef }
                  schemaConstructor={ schemaConstructor }
                  onSubmit={ onSubmit }
                >
                  <InputSelect
                    name="organizationId"
                    label={ t('cooperative', { howMany: 1 }) }
                    options={ getOrganizationsWithSignaturesService }
                    urlParams={ urlParams }
                    asyncOptionValueField='id'
                    asyncOptionLabelField='companyName'
                  />
                  <InputPassword
                    label={ t('password') }
                    name="password"
                  />
                </Form>
              </Item>
            </Content>
            <Buttons>
              <Button
                id="cancel-button"
                onClick={ onClose }
                style={ { flex: 1 } }
                variant="outlined"
              >
                <I18n>cancel</I18n>
              </Button>
              <Button
                id="confirm-button"
                onClick={ () => formRef.current.submit() }
                style={ { flex: 1 } }
              >
                <I18n>confirm</I18n>
              </Button>
            </Buttons>
          </>
        }
      </Container>
    </Modal>
  )
}

ChangeSignatureModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default ChangeSignatureModal
