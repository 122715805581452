
import api from '../api'

export const getSoilAnalysis = async (params, { propertyId }) => {
  const { data: { data } } = await api.get(`/v1/soil-analysis/property/${ propertyId }`, { params })

  return data
}

export const createSoilAnalysis = (params, { fieldId }) => api.post(`/v1/soil-analysis/field/${ fieldId }`, params)

export const uploadSoilAnalysisDocuments = (params, { analysisId }) => api.post(`/v1/soil-analysis/${ analysisId }/documents`, params, {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data'
  }
})

export const putFilesSoilAnalysis = (params, { reportId }) =>
  api.put(`/v1/plagues/growing-seasons/report/${ reportId }/file`, params)


export const putFilesPestReport = (params, { reportId }) =>
  api.put(`/v1/plagues/growing-seasons/report/${ reportId }/file`, params)


export const getSoilAnalysisById = async (params, { soilAnalysisId }) => {
  const { data: { data } } = await api.get(`/v1/soil-analysis/${ soilAnalysisId }`, { params })

  return data
}

export const editSoilAnalysis = (params, { analysisId }) => api.patch(`/v1/soil-analysis/${ analysisId }`, params)

export const deleteSoilAnalysis = ({ analysisId }) => api.delete(`/v1/soil-analysis/${ analysisId }`)

export const deleteSoilAnalysisDocuments = (params) => api.delete('/v1/soil-analysis/documents', params)
