import moment from 'moment/moment'
import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import minDate from '@smartcoop/forms/validators/minDate.validator'
import required from '@smartcoop/forms/validators/required.validator'

const acceptAccreditment = ({ t }) => Yup.object().shape({
  expirationDate: flow(
    required({ t }),
    date({ t }),
    minDate({ t, minReceiptDate: moment().format('YYYY-MM-DD') })
  )(Yup.string())
})

export default acceptAccreditment
