import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

const INITIAL_ORDER = {
  productGroupId: undefined,
  productId: undefined,
  receiptDeadline: undefined,
  statusId: undefined,
  aggregatedVolume: undefined,
  paymentForms: [],
  organizationPurchases: []
}

/* Initial State */
const INITIAL_STATE = {
  currentOrder: {},
  bestProposal: {},
  error: null,
  orderFilters: {}
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  saveOrder: ['order', 'onSuccess', 'onError'],
  saveOfflineOrder: ['order', 'onSuccess', 'onError'],

  joinOrder: ['order', 'isEditing', 'organizationPurchaseId', 'onSuccess', 'onError'],
  joinOfflineOrder: ['order', 'isEditing', 'organizationPurchaseId', 'onSuccess', 'onError'],

  exitOrder: ['onSuccess', 'onError'],
  exitOfflineOrder: ['onSuccess', 'onError'],

  deleteOrder: ['orderId'],
  deleteOfflineOrder: ['orderId'],

  updateOfflineOrderParticipants: ['organizationPurchases'],
  updateCurrentOrder: ['order'],
  resetCurrentOrder: [],

  loadCurrentOrder: ['orderId', 'onSuccess', 'onError', 'onlyAccepted'],

  acceptProposal: ['proposalId', 'onSuccess', 'onError'],
  acceptOfflineProposal: ['onSuccess', 'onError'],

  rejectProposals: ['mainPurchaseId', 'onSuccess', 'onError'],
  rejectOfflineProposals: ['onSuccess', 'onError'],

  loadBestProposal: ['orderId', 'onSuccess', 'onError'],
  updateBestProposal: ['proposal'],

  refuseOfflineBestProposal: ['proposalId', 'deniedProposalReason', 'onSuccess', 'onError'],
  refuseBestProposal: ['proposalId', 'deniedProposalReason', 'onSuccess', 'onError'],

  acceptOfflineBestProposal: ['proposalId', 'proposalPaymentFormId', 'onSuccess', 'onError'],
  acceptBestProposal: ['proposalId', 'proposalPaymentFormId', 'onSuccess', 'onError'],

  acceptReceipt: ['params', 'deliveryLocationId', 'onSuccess', 'onError'],

  attachBankSlip: ['params', 'deliveryLocationId', 'supplierId', 'onSuccess', 'onError'],
  deleteBankSlip: ['bankSlipId', 'onSuccess', 'onError'],

  loadDemandsStatus: ['onSuccess', 'onError'],

  setOrderFilters: ['orderFilters'],

  loadSuppliersByProduct: ['productId', 'onSuccess', 'onError'],

  orderError: ['error']
})

/**
 * Assigning offline actions
 */
markActionsOffline(Creators, [
  'joinOrder',
  'saveOrder',
  'deleteOrder',
  'exitOrder',
  'acceptProposal',
  'rejectProposals',
  'refuseBestProposal',
  'acceptBestProposal'
])

const updateBestProposal = (state = INITIAL_STATE, { proposal }) => ({
  ...state,
  bestProposal: {
    ...proposal
  }
})

const updateCurrentOrder = (state = INITIAL_STATE, { order }) => ({
  ...state,
  currentOrder: {
    ...INITIAL_ORDER,
    ...order
  }
})

const resetCurrentOrder = (state = INITIAL_STATE) => ({
  ...state,
  currentOrder: { ...INITIAL_STATE.currentOrder },
  bestProposal: {}
})

const setOrderFilters = (state = INITIAL_STATE, { orderFilters }) => ({
  ...state,
  orderFilters
})

const orderError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_CURRENT_ORDER]: updateCurrentOrder,
  [Types.UPDATE_BEST_PROPOSAL]: updateBestProposal,
  [Types.RESET_CURRENT_ORDER]: resetCurrentOrder,
  [Types.SET_ORDER_FILTERS]: setOrderFilters,

  [Types.ORDER_ERROR]: orderError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as OrderTypes,
  Creators as OrderActions
}
