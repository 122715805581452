import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { formatNCM } from '@smartcoop/utils/formatters'
import DataTable from '@smartcoop/web-components/DataTable'
import Modal from '@smartcoop/web-components/Modal'

import {
  ProductItem,
  ProductItemText,
  ProductSubTitleItem
} from './styles'

const ProductDetailsModal = (props) => {
  const { id, open, product,t } = props

  const productData = useMemo(
    () => [
      {
        subtitle: 'productCode',
        value: product?.productCode || ''
      },
      {
        subtitle: 'description',
        value: product?.description || ''
      },
      {
        subtitle: 'ncm',
        value: formatNCM(product?.ncm)
      },
      {
        subtitle: 'unitOfMeasure',
        value: product?.unitOfMeasures
      },
      {
        subtitle: 'ean',
        value: map(product?.barcodes, barcode => barcode.ean).join(', ')
      },
      {
        subtitle: 'dun14',
        value: product?.dun14
      }
    ],
    [product]
  )

  const attributesColumns = useMemo(
    () => [
      {
        title: 'Atributo',
        field: 'attribute'
      },
      {
        title: 'Valor do Atributo',
        field: 'value'
      }
    ],
    []
  )

  return (
    <Modal
      id={ id }
      open={ open }
      disableFullScreen
      maxWidth="md"
      fullWidth
      title={ `${ t('product details') } - ${ product?.shortDescription }` }
    >
      <>
        {map(productData, ({ subtitle, value }) => !isEmpty(value) && (
          <ProductItem key={ subtitle }>
            <ProductSubTitleItem>{subtitle === 'unitOfMeasure' ? t(subtitle, { howMany: 1 }) : t(subtitle)}</ProductSubTitleItem>
            <ProductItemText>{value}</ProductItemText>
          </ProductItem>
        ))}
        {!isEmpty(product?.extraAttributes) && (
          <DataTable
            columns={ attributesColumns }
            data={ product?.extraAttributes }
            id="product-details-table"
          />
        )}
      </>
    </Modal>
  )
}

ProductDetailsModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export default ProductDetailsModal
