export default ({ size, color = '#000' } = {}) => /* html */`
<svg width="${ size }" height="${ size }" inkscape:version="1.0 (4035a4fb49, 2020-05-01)" sodipodi:docname="DEL.svg" version="1.1" viewBox="0 0 10.41 11.976" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd">
 <sodipodi:namedview id="base" bordercolor="${ color }" borderopacity="1.0" inkscape:current-layer="g999" inkscape:cx="6.8719135" inkscape:cy="17.060151" inkscape:document-rotation="0" inkscape:document-units="mm" inkscape:pageopacity="0.0" inkscape:pageshadow="2" inkscape:window-height="715" inkscape:window-maximized="1" inkscape:window-width="1366" inkscape:window-x="-8" inkscape:window-y="-8" inkscape:zoom="4.5562752" pagecolor="#ffffff" showgrid="false"/>
 <metadata>
  <rdf:RDF>
   <cc:Work rdf:about="">
    <dc:format>image/svg+xml</dc:format>
    <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
    <dc:title/>
   </cc:Work>
  </rdf:RDF>
 </metadata>
 <g inkscape:groupmode="layer" inkscape:label="Layer 1">
  <g transform="matrix(.26458 0 0 .26458 31.985 86.734)">
   <g transform="matrix(1.2412 0 0 1.2412 -98.977 -425.93)" stroke="${ color }">
    <g fill="none">
     <g stroke-width="1.8243">
      <path d="m-8.1927 87.489-5.0049 7.5339v18.983s-0.08976 0.82162 0.0181 0.64345h12.78" sodipodi:nodetypes="ccccc"/>
      <path d="m5.2223 87.491 5.005 7.5339v18.983s0.08976 0.82162-0.0181 0.64346h-12.78" sodipodi:nodetypes="ccccc"/>
      <path d="m-8.5664 87.714h14.185" sodipodi:nodetypes="cc"/>
      <path d="m-13.198 95.023h23.333" sodipodi:nodetypes="cc"/>
      <path d="m-13.257 111.01 23.571 0.0659" sodipodi:nodetypes="cc"/>
      <path d="m-1.1394 84.01h-8.495s-0.01664-0.91615 0.1169-1.3396c0.20457-0.64867 0.56717-1.2514 1.0132-1.7285 0.38157-0.40821 0.8558-0.73948 1.3639-0.90749l4.0713 0.0218" sodipodi:nodetypes="ccaacc"/>
      <path d="m-2.1509 84.015h8.495s0.016637-0.91617-0.1169-1.3396c-0.20457-0.64868-0.56717-1.2514-1.0132-1.7286-0.38157-0.4082-0.8558-0.73947-1.3639-0.90748l-7.0893 0.0176" sodipodi:nodetypes="ccaacc"/>
      <path d="m-8.1927 87.489v-3.3001h0.20936"/>
      <path d="m5.2223 87.491v-3.3001h0.20936"/>
     </g>
     <path d="m9.3437 92.209 3.5502-3.4646" stroke-linecap="round" stroke-width="2.3904" inkscape:transform-center-x="0.59909853" inkscape:transform-center-y="-0.22145962"/>
     <path d="m-12.864 92.253-3.5501-3.4645" stroke-linecap="round" stroke-width="2.3904"/>
    </g>
    <text transform="scale(.9496 1.0531)" x="-9.1119223" y="98.409676" fill="${ color }" font-family="sans-serif" font-size="7.9891px" stroke-width=".08322" style="line-height:1.25" xml:space="preserve"><tspan x="-9.1119223" y="98.409676" fill="${ color }" font-family="'Arial Rounded MT Bold'" font-size="7.9891px" font-weight="bold" stroke="${ color }" stroke-width=".08322" sodipodi:role="line">DEL</tspan></text>
   </g>
  </g>
 </g>
</svg>
`
