import React, { useCallback, useState } from 'react'
import {
  useSelector,
  useDispatch
} from 'react-redux'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { NonCompliancesActions } from '@smartcoop/stores/nonCompliance'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Modal from '@smartcoop/web-components/Modal'
import PostNonCompliance from '@smartcoop/web-components/PostNonCompliance'

import useStyles from './styles'

const CreateNonComplianceModal = (props) => {
  const { id, open, handleClose, deliveryLocation, getNonCompliances } = props
  const t = useT()
  const classes = useStyles()
  const snackbar = useSnackbar()

  const organization = useSelector(selectCurrentOrganization)
  const user = useSelector(selectUser)

  const dispatch = useCallback(useDispatch(), [])

  const [loading, setLoading] = useState(false)

  const handleError = () => setLoading(false)

  const saveNonComplianceSuccess = useCallback(() => {
    snackbar.success(t('non compliance create success'))
    setLoading(false)
    handleClose()
    getNonCompliances()
  },[getNonCompliances, handleClose, snackbar, t])

  const handleSubmit = useCallback((data) => {
    setLoading(true)
    dispatch(NonCompliancesActions.saveNonCompliance({ deliveryLocation, ...data }, saveNonComplianceSuccess , handleError))
  }, [deliveryLocation, dispatch, saveNonComplianceSuccess])

  return (
    <Modal
      id={ id }
      open={ open }
      title={ t('create non compliance') }
      disableFullScreen
      escape
      contentContainerStyle={ { padding: 0 } }
      maxWidth='md'
      fullWidth
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      loading={ loading }
    >
      <div>
        <PostNonCompliance
          organization={ organization }
          user={ user }
          onSubmit={ handleSubmit }
        />
      </div>
    </Modal>
  )
}

CreateNonComplianceModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  getNonCompliances: PropTypes.func.isRequired,
  deliveryLocation: PropTypes.string.isRequired
}

CreateNonComplianceModal.defaultProps = {
}

export default CreateNonComplianceModal
