import React from 'react'

import TableHeader from 'material-table/dist/components/m-table-header'
import PropTypes from 'prop-types'

const Header = ({ tableHeaderClassName, ...props }) => (
  <TableHeader
    { ...props }
    classes={ { header: tableHeaderClassName } }
  />
)

Header.propTypes = {
  tableHeaderClassName: PropTypes.string
}

Header.defaultProps = {
  tableHeaderClassName: null
}

export default Header
