import React, { useState, useMemo } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import { groupMemberService } from '@smartcoop/database/services/groupMemberService'
import { database } from '@smartcoop/database/web-database'
import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { MaxSizeOfGroupMembers } from '@smartcoop/utils/constants'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'

import {
  Container,
  CustomInput,
  CustomSelectContacts,
  Header,
  ModalFooter,
  ModalTitle
} from './styles'

const AddMembersModal = ({
  id,
  open,
  handleClose,
  groupId,
  groupMembers,
  isBroadcast,
  localUser
}) => {
  const [searchParams, setSearchParams] = useState('')
  const [selecteds, setSelecteds] = useState([])

  const membersIds = useMemo(() => groupMembers?.map(member => member.userId), [groupMembers])

  const t = useT()
  const snackbar = useSnackbar()

  const userProfiles = useMemo(() => {
    if (localUser?.profiles) {
      return JSON.parse(localUser?.profiles, '[]')
        .map(item => Number(item))
    }
    return []
  }, [localUser])

  const handleSelect = (item) => {
    if (selecteds.includes(item)) {
      return setSelecteds(state => state.filter(element => element.contactId !== item.contactId))
    }
    return setSelecteds(state => [...state, item])
  }

  const onSubmit = async () => {
    try {
      const dto = selecteds.map(selected => ({
        code: selected.userCode,
        userId: selected.contactId,
        userName: selected.chatNickname,
        isLeft: false,
        isAdminGroup: false,
        publicKey: selected?.publicKey,
        profiles: typeof selected.profiles === 'string' ? selected.profiles : JSON.stringify(selected.profiles)
      }))

      if(dto.length === 0) {
        snackbar.error(t('At least one contact must be added'))
        return
      }

      await groupMemberService(database).addMembers(groupId, dto, isBroadcast)

      snackbar.success(t('member successfully added'))

      handleClose()
    } catch(error) {
      snackbar.success(t('an error occurred while add new member'))
    }
  }

  return (
    <Modal
      id={ id }
      open={ open }
      escape={ false }
      maxWidth="sm"
      fullWidth
      hideHeader
    >
      <Container>
        <Header>
          <ModalTitle>{isBroadcast ? t('add member in broadcast') : t('add member in group')}</ModalTitle>
        </Header>

        <CustomSelectContacts
          localUserProfiles={ userProfiles }
          selecteds={ selecteds }
          onSelect={ handleSelect }
          hiddenMembers={ membersIds }
          searchParams={ searchParams }
          isBroadcast={ isBroadcast }
          maxMembers={ MaxSizeOfGroupMembers + 1 - groupMembers?.length }
        >
          <CustomInput
            value={ searchParams }
            onChange={ e => setSearchParams(e.target?.value) }
            placeholder={ t('filter by name') }
            marginTop={ 20 }
          />
        </CustomSelectContacts>

        <ModalFooter>
          <Button id="cancel-new-group" onClick={ handleClose }>{t('cancel')}</Button>
          <Button id="confirm-new-group" color="secondary" onClick={ onSubmit }>{t('save')}</Button>
        </ModalFooter>
      </Container>
    </Modal>
  )
}

AddMembersModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  groupMembers: PropTypes.array,
  isBroadcast: PropTypes.bool,
  localUser: PropTypes.object.isRequired
}

AddMembersModal.defaultProps = {
  groupMembers: [],
  isBroadcast: false
}

const enhance = withObservables(['groupId'], ({ groupId }) => ({
  groupMembers: groupMemberService(database).observeGroupMemberByGroupId(groupId)
}))

const EnhancedAddMembersModal = enhance(AddMembersModal)

export default EnhancedAddMembersModal
