import React, { useContext, useState, useMemo } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import first from 'lodash/first'

import { conversationService } from '@smartcoop/database/services/conversationService'
import { syncHistoricService } from '@smartcoop/database/services/syncHistoricService'
import { generateUuid } from '@smartcoop/database/utils/uuidGenerator'
import { database } from '@smartcoop/database/web-database'
import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { group, transmission } from '@smartcoop/icons'
import Loader from '@smartcoop/web-components/Loader'

import LargeButton from '../../../components/LargeButton'
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser'
import NewBroadcastModal from '../../../modals/NewBroadcastModal/NewBroadcastModal'
import NewGroupModal from '../../../modals/NewGroupModal/NewGroupModal'
import { ChatContext } from '../../../providers/ChatProvider'
import { PROFILES } from '../../../utils/validateProfiles'
import ContactList from '../ContactList'
import ContactsHeader from '../ContactsHeader'
import { ButtonWrapper, ContactsBody, Container, CustomInput, LoaderContainer } from './styles'

const Contacts = ({ syncHistoric }) => {
  const [contactName, setContactName] = useState('')

  const t = useT()
  const { createDialog } = useDialog()
  const localUser = useAuthenticatedUser()
  const syncFinished = first(syncHistoric)
  const { navigateToConversation, openChat } = useContext(ChatContext)

  const userProfiles = useMemo(() => {
    if (localUser?.profiles) {
      return JSON.parse(localUser?.profiles, '[]')
        .map(item => Number(item))
    }
    return []
  }, [localUser])

  const onStartConversation = async (contact) => {
    const relationalId = generateUuid(contact?.contactId, localUser?.userId)

    const conversation = await conversationService(database).findByRelationalId(relationalId)

    if (conversation) {
      openChat({
        relationalId,
        userId: conversation.contactId || conversation.conversationId,
        conversationId: conversation.conversationId
      })
      return navigateToConversation()
    }

    await conversationService(database).startConversation(relationalId, contact)
    openChat({ relationalId, userId: contact.contactId })

    return navigateToConversation()
  }

  const createNewGroup = () => {
    createDialog({
      id: 'create-new-group-modal',
      Component: NewGroupModal,
      props: { openChat, localUser }
    })
  }

  const createNewBroadcast = () => {
    createDialog({
      id: 'create-new-group-modal',
      Component: NewBroadcastModal,
      props: { openChat, localUser }
    })
  }

  const canCreateGroup = useMemo(() => (
    userProfiles.some(item => item !== PROFILES.FAMILY_MEMBER) &&
    !userProfiles.includes(PROFILES.SUPPLIER)
  ), [userProfiles])

  const canCreateBroadcast = useMemo(
    () => userProfiles.some(profile => (
      profile !== PROFILES.SUPPLIER &&
      profile !== PROFILES.PRODUCTOR &&
      profile !== PROFILES.FAMILY_MEMBER
    )),
    [userProfiles]
  )

  return (
    <Container>
      <ContactsHeader onGoBack={ navigateToConversation } />

      <ContactsBody>
        <ButtonWrapper>
          {canCreateGroup && (
            <LargeButton onClick={ createNewGroup } icon={ group }>{t('new group')}</LargeButton>
          )}
          {canCreateBroadcast && (
            <LargeButton onClick={ createNewBroadcast } icon={ transmission }>{t('new broadcast')}</LargeButton>
          )}
        </ButtonWrapper>

        <CustomInput
          placeholder={ t('search contacts or start new conversation') }
          value={ contactName }
          onChange={ e => setContactName(e.target.value) }
        />

        {!syncFinished ? (
          <LoaderContainer>
            <p>{t('loading contact list, please await')}...</p>
            <Loader width={ 100 } />
          </LoaderContainer>
        ) : (
          <ContactList
            contactName={ contactName }
            onStartConversation={ onStartConversation }
          />
        )}

      </ContactsBody>
    </Container>
  )
}

Contacts.propTypes = {
  syncHistoric: PropTypes.array
}

Contacts.defaultProps = {
  syncHistoric: []
}

const enhance = withObservables([], () => ({
  syncHistoric: syncHistoricService(database).observeSyncHistoric('contact')
}))

const EnhancedContacts = enhance(Contacts)

export default EnhancedContacts
