import React, { useCallback, forwardRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import propertyIdentificationSchema from '@smartcoop/forms/schemas/property/propertyIdentification.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { searchStates, searchCities } from '@smartcoop/services/apis/ibgeApi'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectOfflineProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'
import { FormLabel } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container, ButtonContainer } from './styles'

const PropertyIdentificationForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, onSubmit, style } = props

  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])

  const user = useSelector(selectUser)
  const property = useSelector(selectOfflineProperty)

  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const handleSubmit = useCallback(
    async (data) => {
      dispatch(PropertyActions.updateOfflineProperty(
        { data }
      ))
      onSubmit()
    },
    [dispatch, onSubmit]
  )

  const handleStateChange = useCallback(
    async ({ target: { value } }) => {
      if (value) {
        let data = await searchCities(value)
        data = map(data, ({ nome }) => ({ label: nome, value: nome }))
        setCityOptions(data)
      } else {
        setCityOptions([])
        formRef.current.clearField('city')
      }
    },
    [formRef]
  )

  useEffect(() => {
    async function findStates() {
      let data = await searchStates()
      data = map(data, ({ sigla, nome }) => ({ label: nome, value: sigla }))
      setStateOptions(data)
    }
    findStates()
  }, [])

  useEffect(() => {
    if (property?.data.state || user.state) {
      handleStateChange({ target: { value: property?.data.state || user.state } })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Form
        style={ { ...style } }
        ref={ formRef }
        schemaConstructor={ propertyIdentificationSchema }
        onSubmit={ handleSubmit }
      >
        <FormLabel>
          <I18n>identification</I18n>
        </FormLabel>
        <InputText
          name="name"
          label={ t('property name') }
          defaultValue={ property?.data.name }
          autoFocus
        />

        <InputUnit
          name="totalArea"
          label={ t('total area') }
          type="float"
          unit="ha"
          defaultValue={ (property?.data.totalArea || '').toString() }
        />

        <FormLabel>
          <I18n params={ { howMany: 1 } }>address</I18n>
        </FormLabel>
        <InputSelect
          label={ t('state', { howMany: 1 }) }
          name="state"
          onChange={ handleStateChange }
          options={ stateOptions }
          defaultValue={ property?.data.state || user.state }
        />

        <InputSelect
          label={ t('city', { howMany: 1 }) }
          name="city"
          options={ cityOptions }
          defaultValue={ property?.data.city || user.city }
        />

        <InputText
          name="addressComplement"
          label={ t('complement') }
          helperText={ t('example: line, community, attachment, etc.') }
          defaultValue={ property?.data.addressComplement }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-property-identification-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
            >
              <I18n>next</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

PropertyIdentificationForm.propTypes = {
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  style: PropTypes.object
}

PropertyIdentificationForm.defaultProps = {
  onSubmit: () => {},
  withoutSubmitButton: false,
  style: {}
}

export default PropertyIdentificationForm
