import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import debounce from 'lodash/debounce'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import {
  emptyFilter,
  filter,
  noFieldRegistered,
  cutLayout,
  openLayout
} from '@smartcoop/icons'
import { FieldActions } from '@smartcoop/stores/field'
import { selectFieldsHistory } from '@smartcoop/stores/field/selectorField'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import colors from '@smartcoop/styles/colors'
import { findMapZoom, getPolygonCenter, GROWING_SEASON_COLOR } from '@smartcoop/utils/maps'
import Button from '@smartcoop/web-components/Button'
import EmptyState from '@smartcoop/web-components/EmptyState'
import GrowingSeasonHistoryItem from '@smartcoop/web-components/GrowingSeasonHistoryItem'
import Icon from '@smartcoop/web-components/Icon'
import InputSearch from '@smartcoop/web-components/InputSearch'
import Loader from '@smartcoop/web-components/Loader'
import Maps from '@smartcoop/web-components/Maps'
import Control from '@smartcoop/web-components/Maps/components/Control'
import Polygon from '@smartcoop/web-components/Maps/components/Polygon'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import FilterFieldsHistory from '@smartcoop/web-containers/modals/DigitalProperty/FilterFieldsHistory'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  ButtonFilter,
  ContainerListFields,
  SearchContainer
} from './styles'

const FieldListHistoryScreen = () => {
  const t = useT()
  const history = useHistory()
  const mounted = useRef(false)
  const mapRef = useRef(null)

  const { routes } = useRoutes()
  const { createDialog } = useDialog()
  const fields = useSelector(selectFieldsHistory)

  const [filters, setFilters] = useState({})
  const [loading, setLoading] = useState(false)
  const [filterText, setFilterText] = useState('')
  const [propertyHasFields, setPropertyHasFields] = useState(false)
  const [debouncedFilterText, setDebouncedFilterText] = useState('')
  const currentProperty = useSelector(selectCurrentProperty)
  const [opened, setOpened] = useState(true)

  const dispatch = useCallback(useDispatch(), [])

  const allPolygonCoordinates = useMemo(
    () =>
      reduce(
        fields,
        (acc, field) => [...acc, ...field?.fieldData?.polygonCoordinates || []],
        []
      ),
    [fields]
  )

  const mapRegion = useMemo(
    () =>
      !isEmpty(allPolygonCoordinates)
        ? getPolygonCenter(allPolygonCoordinates)
        : undefined,
    [allPolygonCoordinates]
  )

  const mapZoom = useMemo(
    () =>
      !isEmpty(allPolygonCoordinates)
        ? findMapZoom(allPolygonCoordinates)
        : undefined,
    [allPolygonCoordinates]
  )

  const iconChange = useMemo(() => (opened ? openLayout : cutLayout), [opened])

  const handleParams = useCallback(
    (values) =>
      Object.keys(values)
        .filter((key) => typeof values[key] === 'boolean' || values[key])
        .reduce((prev, curr) => ({ ...prev, [curr]: values[curr] }), {}),
    []
  )

  const params = useMemo(() => {
    const filterParams = {
      ...filters,
      q: debouncedFilterText
    }
    return handleParams(filterParams)
  }, [debouncedFilterText, filters, handleParams])

  const handleFieldHistoryClick = useCallback(
    (field, childrenPolygonId, itemGrowingSeason, growingSeasons) => {
      dispatch(FieldActions.setCurrentFieldHistory(field))
      dispatch(FieldActions.setFieldNavigationData({ isFromHistory: true, childrenPolygonId, historyGrowingSeason: itemGrowingSeason, growingSeasons }))
      history.push(routes.fieldDetails.path.replace(':fieldId', field.id))
    },
    [dispatch, history, routes]
  )

  const loadFieldsHistory = useCallback(
    debounce(() => {
      dispatch(
        FieldActions.loadFieldsHistory(
          params,
          () => setLoading(false),
          () => setLoading(false)
        )
      )
    }, 300),
    [dispatch, params]
  )

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )

  const handleFilter = useCallback((values) => {
    setLoading(true)
    setFilters(values)
  }, [])

  const openFilterFieldsHistory = useCallback(() => {
    createDialog({
      id: 'filter-fields-history',
      Component: FilterFieldsHistory,
      props: {
        onSubmit: handleFilter,
        filters
      }
    })
  }, [createDialog, filters, handleFilter])

  const toggleLayout = useCallback(() => {
    setOpened((old) => !old)
  }, [])

  useEffect(() => {
    if (!isEmpty(currentProperty)) {
      setLoading(true)
      loadFieldsHistory()
    }
  }, [dispatch, currentProperty, loadFieldsHistory])

  useEffect(() => {
    setPropertyHasFields(false)
    if (!isEmpty(currentProperty) && mounted.current) {
      setFilters({})
      setFilterText('')
      setDebouncedFilterText('')
    }
  }, [currentProperty])

  useEffect(() => {
    if (fields?.length) {
      setPropertyHasFields(true)
    }
  }, [fields])

  useEffect(() => {
    mapRef.current && mapRef.current.leafletElement._onResize()
  }, [opened])

  useEffect(() => {
    mounted.current = true
  }, [])

  useEffect(() => {
    dispatch(FieldActions.resetCurrentFieldHistory())
  }, [dispatch])

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  const findPolygonColorById = useCallback((id) => {
    const foundGrowingSeason = find(fields, item => item.childrenPolygonId === id)

    return foundGrowingSeason ? GROWING_SEASON_COLOR[foundGrowingSeason?.crop?.slug] : '#fff'
  },[fields])

  return (
    <SplitedScreenLayout
      title={ { name: t('fields history') } }
      withoutDivider
      divRightStyle={ { padding: 0 } }
      leftChildren={
        opened && (
          <>
            {propertyHasFields && (
              <div>
                <SearchContainer>
                  <InputSearch
                    style={ { marginBottom: 0, marginRight: 8 } }
                    adornmentStyle={ { marginRight: 15 } }
                    detached
                    onChange={ onChangeSearchFilter }
                    value={ filterText }
                    placeholder={ t('search') }
                    fullWidth
                  />
                  <div>
                    <ButtonFilter
                      id="filter-field"
                      style={ { marginBottom: 0 } }
                      color={ isEmpty(filters) ? 'white' : 'secondary' }
                      onClick={ openFilterFieldsHistory }
                      disabled={ loading }
                    >
                      <>
                        <Icon
                          style={ { marginRight: '8px' } }
                          size={ 14 }
                          icon={ filter }
                        />
                        <I18n>filtrate</I18n>
                      </>
                    </ButtonFilter>
                  </div>
                </SearchContainer>
              </div>
            )}

            <ContainerListFields isCenter={ isEmpty(fields) }>
              {loading ? (
                <Loader width={ 100 } />
              ) : (
                map(fields, (item) => (
                  <GrowingSeasonHistoryItem
                    key={ item.id }
                    growingSeason={ item }
                    growingSeasons={ fields }
                    onClick={ handleFieldHistoryClick }
                    dispatch={ dispatch }
                  />
                ))
              )}

              {!loading && !propertyHasFields && (
                <EmptyState
                  text={ t('there are no closed growing seasons') }
                  icon={ noFieldRegistered }
                />
              )}
              {!loading && propertyHasFields && isEmpty(fields) && (
                <EmptyState text={ t('no results found') } icon={ emptyFilter } />
              )}
            </ContainerListFields>
          </>
        )
      }
      rightChildren={
        <>
          {!isEmpty(allPolygonCoordinates) && !loading && (
            <Maps
              ref={ mapRef }
              region={ mapRegion }
              zoom={ mapZoom }
              style={ { flex: 1, overflow: 'none' } }
              cursor="crosshair"
            >
              <Control position="topcenter">
                <div style={ { height: 70, display: 'flex' } }>
                  <div
                    style={ {
                      margin: '10px 20px 10px 10px',
                      justifyContent: 'flex-end'
                    } }
                  >
                    <Button
                      id="toggle-Layout"
                      color={ colors.white }
                      style={ {
                        padding: '7.5px 10px',
                        fontSize: '0.875rem'
                      } }
                      onClick={ toggleLayout }
                    >
                      <Icon
                        icon={ iconChange }
                        size={ 17 }
                        style={ { marginLeft: 5 } }
                      />
                    </Button>
                  </div>
                </div>
              </Control>
              {map(fields, field => !isEmpty(field?.fieldData?.childrenPolygons) ? map(field?.fieldData?.childrenPolygons, item => (
                <Polygon
                  key={ `polygon-${ item.id }` }
                  points={ item?.coordinates }
                  color={ findPolygonColorById(item.id) }
                />
              )) :
                <Polygon
                  points={ field?.fieldData?.polygonCoordinates }
                  color={ !isEmpty(field.growingSeasons) ? GROWING_SEASON_COLOR[field.crop.slug] : colors.secondary }
                />)}
            </Maps>
          )}
        </>
      }
    />
  )
}

export default FieldListHistoryScreen
