import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Card = styled.div`
  background-color: ${ colors.backgroundHtml };
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  cursor: pointer;
  column-gap: 10px;
  font-weight: 600;
  font-family: 'Open Sans';
  color: ${ colors.text };

  :hover {
    filter: brightness(0.95);
  }
`
