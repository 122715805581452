import * as Yup from 'yup'


import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const rubric = ({ t }) => Yup.object().shape({
  groupId: flow(
    required({ t })
  )(Yup.string()),

  description: flow(
    required({ t })
  )(Yup.string())
})

export default rubric
