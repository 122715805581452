import styled from 'styled-components'


export const CheckboxTouchableContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

export const CheckboxContainer = styled.div`
  background-color: ${ props => props.checked && !props.hideColor ? '#FFC80A' : 'rgb(249, 248, 246)' };
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 30px;
  padding: 15px;
  margin-bottom: 10px;
  align-items: center;
`

export const IconContainer = styled.div`
  max-width: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;

`

export const Label = styled.label`
  font-weight: 600;
  margin-left: 8px;
  font-size: 16px;
  cursor: pointer;
`
