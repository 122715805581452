import React from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { eyeClosed } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import IconButton from '../IconButton'

const EyeClosedIconButton = ({ iconColor, ...props }) => {
  const t = useT()
  return (
    <IconButton tooltip={ t('show password') } { ...props }>
      <Icon icon={ eyeClosed } color={ iconColor } />
    </IconButton>
  )
}

EyeClosedIconButton.propTypes = {
  iconColor: PropTypes.string
}

EyeClosedIconButton.defaultProps = {
  iconColor: colors.mutedText
}

export default EyeClosedIconButton
