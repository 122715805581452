import React from 'react'

import codeMask from '@smartcoop/forms/masks/code.mask'
import TextField from '@smartcoop/web-components/TextField'

const InputCode = props => (
  <TextField
    { ...props }
    type="tel"
    setMask={ codeMask }
  />
)

export default InputCode
