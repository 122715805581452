// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
      <g>
        <path fill="#D3D3D3" d="M169.6,38.1l-30-6.1l-6.1-30c-0.1-0.5-0.5-1-1.1-1.1c-0.7-0.1-1.3,0.4-1.4,1.1L125,32l-30,6.1
          c-0.5,0.1-1,0.5-1.1,1.1c-0.1,0.7,0.4,1.3,1.1,1.4l30,6.1l6.1,30c0.1,0.5,0.5,1,1.1,1.1c0.7,0.1,1.3-0.4,1.4-1.1l6.1-30l30-6.1
          c0.5-0.1,1-0.5,1.1-1.1C170.7,38.8,170.3,38.2,169.6,38.1z"/>
        <path fill="#D3D3D3" d="M66.1,120.2C42.6,79.5,46.2,32.1,72.3,5.3c-7.1,2.2-14,5.1-20.7,9C4.2,41.6-12,102.2,15.3,149.5
          c27.3,47.3,87.9,63.6,135.2,36.2c6.7-3.9,12.7-8.4,18.1-13.4C132.4,181.5,89.5,160.9,66.1,120.2z"/>
        <path fill="#D3D3D3" d="M197.2,105.9l-21.3-4.4l-4.4-21.3c0-0.4-0.3-0.7-0.8-0.8c-0.5-0.1-0.9,0.3-1,0.8l-4.4,21.3l-21.3,4.4
          c-0.4,0.1-0.7,0.4-0.8,0.8c-0.1,0.5,0.3,0.9,0.8,1l21.3,4.4l4.4,21.3c0.1,0.4,0.3,0.7,0.8,0.8c0.5,0.1,0.9-0.3,1-0.8l4.4-21.3
          l21.3-4.4c0.4-0.1,0.7-0.4,0.8-0.8C198,106.4,197.7,105.9,197.2,105.9z"/>
      </g>
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
