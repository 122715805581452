import api from '../api'

export const getBartersByOrg = (params, { organizationId }) => api.get(`/v1/barters/organization/${ organizationId }`, { params })

export const getPackagesByOrg = (params, { organizationId }) => api.get(`/v1/barters/packages/organization/${ organizationId }`, { params })

export const createBarter = async (params) => api.post('/v1/barters', params)

export const joinBarter = async (params) => api.post('/v1/barters/participate', params)

export const editBarter = async (params, { barterOrderNumber, organizationId }) => api.patch(`/v1/barters/${ barterOrderNumber }/organization/${ organizationId }`, params)

export const editBarterPackageOrder = async (params, { barterOrderNumber, organizationId }) => api.patch(`/v1/barters/order/${ barterOrderNumber }/organization/${ organizationId }`, params)

export const deleteBarter = async (params, { barterOrderNumber, organizationId }) => api.delete(`/v1/barters/${ barterOrderNumber }/organization/${ organizationId }`, params)

export const getAllBartersByUser = (params) => api.get('/v1/barters/organizations/user', { params })

export const getAllPackagesByUser = (params) => api.get('/v1/barters/packages/organizations/user', { params })
