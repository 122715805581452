import filter from 'lodash/filter'

export const selectNewNotificationsCount = (state) => filter(state.messaging.lastNotifications, item => item?.data?.module !== 'chat' && !item?.read).length

export const selectChatNewNotificationsCount = (state) => filter(state.messaging.lastNotifications, item => item?.data?.module === 'chat' && !item?.read).length

export const selectLastNotifications = (state) => filter(state.messaging.lastNotifications, item => item?.data?.module !== 'chat')

export const selectAllNotifications = (state) => filter(state.messaging.allNotifications , item => item?.data?.module !== 'chat') || []

export const selectLastAllNotificationVisible = (state) => state.messaging.lastVisible

export const selectHoursPendingAttention = (state) => state.messaging.hoursPendingAttention

export const selectFirebaseInitialized = (state) => state.messaging.firebaseInitialized

export const selectIsChatModule = (state) => state.messaging.isChatModule
