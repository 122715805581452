import React from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import first from 'lodash/first'

import { Avatar } from '@material-ui/core'

import { profilePictureService } from '@smartcoop/database/services/profilePictureService'
import { database } from '@smartcoop/database/web-database'

import { getHighlightedText } from '../../../utils/chatHighlight'
import { Card } from './styles'

const ConversationSearchContact = ({ contact, profilePictures, startConversation, searchParams }) => {
  const profilePicture = first(profilePictures)

  return (
    <Card onClick={ () => startConversation(contact) }>
      <Avatar src={ profilePicture?.source } size={ 25 } alt={ contact.chatNickname } />
      <span>
        {getHighlightedText(contact.chatNickname, searchParams)}
      </span>
    </Card>
  )
}

ConversationSearchContact.propTypes = {
  contact:PropTypes.object.isRequired,
  profilePictures: PropTypes.array.isRequired,
  startConversation: PropTypes.func.isRequired,
  searchParams: PropTypes.string.isRequired
}


const enhance = withObservables(['userId'], ({ userId }) => ({
  profilePictures: profilePictureService(database).observeProfilePictureByUserId(userId)
}))

const EnhancedConversationSearchContact = enhance(ConversationSearchContact)

export default EnhancedConversationSearchContact
