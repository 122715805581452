import api from '../api'

export const getMachinery = (params) => api.get('/v1/machines', { params })

export const getMachineryById = (params, { machineId }) => api.get(`/v1/machines/${ machineId }`, { params })

export const deleteMachine = ({ machineId }) => api.delete(`/v1/machines/${ machineId }`)

export const createMachine = (params) =>
  api.post('/v1/machines', params)

export const getMachinesTypes = (params) => api.get('/v1/machines/types', { params })

export const getMachinesBrands = (params) => api.get('/v1/machines/brands', { params })

export const editMachine = (params, { machineId }) =>
  api.patch(`/v1/machines/${ machineId }`, params)

export const addMachineFiles = (params, { machineId }) =>
  api.post(`/v1/machines/${ machineId }/file`, params, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

export const editMachineFiles = (params, { machineId }) =>
  api.put(`/v1/machines/${ machineId }/file`, params)
