import React, { useCallback, forwardRef } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filterDiagnosisSchema from '@smartcoop/forms/schemas/dairyFarm/filterDiagnosis.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getAnimalPregnancyDiagnosticsTypes } from '@smartcoop/services/apis/smartcoopApi/resources/animalPregnancyDiagnostics'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import { Container, ButtonContainer } from './styles'

const FilterDiagnosisActionsForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()
  const handleSubmit = useCallback((data) => onSubmit(data), [onSubmit])
  const currentAnimal = useSelector(selectCurrentAnimal)

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterDiagnosisSchema }
        onSubmit={ handleSubmit }
      >
        <InputText
          label={ t('earring') }
          name="earring"
          fullWidth
          defaultValue={ filters.earring }
        />
        <InputDate
          label={ t('accomplished date') }
          name="date"
          pickerProps={ {
            maxDate: moment().format()
          } }
          fullWidth
          defaultValue={ filters.date }
        />

        <InputSelect
          label={ t('type of diagnosis') }
          name="diagnosisType"
          defaultValue={ filters.diagnosisType }
          options={ getAnimalPregnancyDiagnosticsTypes }
          urlParams={ { animalId: currentAnimal.id } }
          asyncOptionLabelField="typeName"
          asyncOptionValueField="typeName"
        />

        <InputSelect
          label={ t('result') }
          name="result"
          defaultValue={ filters.result }
          options={ [
            {
              label: t('pregnant'),
              value: 'Positivo'
            },
            {
              label: t('empty'),
              value: 'Vazia'
            },
            {
              label: t('inconclusive'),
              value: 'Inconclusivo'
            }
          ] }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="pregnancy-actions-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterDiagnosisActionsForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterDiagnosisActionsForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterDiagnosisActionsForm
