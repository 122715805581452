/* eslint-disable no-nested-ternary */
import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import find from 'lodash/find'
import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import map from 'lodash/map'

import I18n, { useT } from '@smartcoop/i18n'
import { getOpenGrowingSeasonsByProperty } from '@smartcoop/services/apis/smartcoopApi/resources/growingSeasons'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { momentBackDateFormat, momentBackDateTimeZoneFormat } from '@smartcoop/utils/dates'
import InputSelect from '@smartcoop/web-components/InputSelect'
import Modal from '@smartcoop/web-components/Modal'
import ManagementForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm'

import useStyles, { Row, Content } from './styles'

const TechnicalVisitRecommendationModal = (props) => {
  const { id, open, onSuccess, handleClose, onSubmit, defaultValues, readOnly, growingSeasonId, producer, propertyId: externalPropertyId } = props
  const technicalVisitRecommendationFormRef = useRef(null)
  const t = useT()
  const snackbar = useSnackbar()
  const classes = useStyles()

  const propertyId = useSelector(selectCurrentPropertyId)
  const [growingSeasons, setGrowingSeasons] = useState(defaultValues?.growingSeasonIds ? defaultValues?.growingSeasonIds : growingSeasonId ? [growingSeasonId] : null)
  const [growingSeasonOptions, setGrowingSeasonOptions] = useState(null)
  const [growingSeasonsData, setGrowingSeasonsData] = useState(null)
  const [operation, setOperation] = useState(null)
  const [cropManagement, setCropManagement] = useState(isString(defaultValues?.cropManagement) ?  defaultValues?.cropManagement === 'true' : isBoolean(defaultValues?.cropManagement) ? defaultValues?.cropManagement : false)

  useEffect(() => {
    const getGrowingSeasons = async () => {
      const { data } = await getOpenGrowingSeasonsByProperty({ propertyId: externalPropertyId || propertyId })

      setGrowingSeasonsData(data)
      setGrowingSeasonOptions(map(data, item => {
        const childrenPolygon = find(item?.field?.childrenPolygons, polygon => polygon.id === item.childrenPolygonId)
        return (
          {
            label: `${ item.field.fieldName } - ${ !isEmpty(childrenPolygon) ? (`${ childrenPolygon.name  } -`) : '' } ${ item?.crop?.description }/${ item.sowingYear }`,
            value: item.id
          }
        )
      }))

    }
    getGrowingSeasons()
  },[externalPropertyId, propertyId])

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row style={ { marginBottom: '10px' } }>
          <I18n>register recommendation</I18n>
        </Row>
      }
      disableFullScreen
      disableEsc
      maxWidth="sm"
      fullWidth
      classes={ { paper: classes.modalBackground } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      contentContainerStyle={ { padding: 0 } }
    >
      <>
        <Content>
          <InputSelect
            detached
            multiple
            label="Selecione"
            options={ growingSeasonOptions }
            value={ growingSeasons }
            onChange={ (value) => {
              const currentValues = map(value, item => find(growingSeasonsData, data => data.id === item))
              const crops = map(currentValues, item => item?.cropId)
              const equalCrops = crops.every(val => val === crops[0])

              if((operation === 'semeadura' || operation === 'colheita' || operation === 'producao-de-silagem' || operation === 'producao-de-feno') && !equalCrops) {
                snackbar.error(t('all crops must be the same for operation seeding'))
              } else {
                setGrowingSeasons(value)
              }
            } }
            disabled={ readOnly }
          />
          <ManagementForm
            ref={ technicalVisitRecommendationFormRef }
            handleClose={ handleClose }
            externalGrowingSeasons={ map(growingSeasons, item => find(growingSeasonsData, data => data.id === item)) }
            onSuccess={ onSuccess }
            withoutSubmitButton
            isModal
            onSubmit={ (data) => onSubmit({ ...defaultValues, ...data, cropManagement }) }
            onChangeOperation={ setOperation }
            externalDefaultValues={ { ...defaultValues, predictedDate: moment(defaultValues?.predictedDate, momentBackDateTimeZoneFormat).format(momentBackDateFormat) } }
            onChangeApplyToManagement={ () => setCropManagement(!cropManagement) }
            applyToManagement={ cropManagement }
            readOnly={ readOnly }
            producer={ producer }
            propertyId={ externalPropertyId }
          />
        </Content>
      </>
    </Modal>
  )
}

TechnicalVisitRecommendationModal.propTypes = {
  id: PropTypes.string.isRequired,
  growingSeasonId: PropTypes.string,
  propertyId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.object,
  producer: PropTypes.object,
  onSuccess: PropTypes.func
}

TechnicalVisitRecommendationModal.defaultProps = {
  readOnly: false,
  growingSeasonId: null,
  propertyId: null,
  onSuccess: () => {},
  defaultValues: {},
  producer: {},
  onSubmit: () => {}
}

export default TechnicalVisitRecommendationModal
