import styled from 'styled-components'


export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  button {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
    font-size: .9rem;
  }
`
