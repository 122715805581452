import trimMask from '@meta-awesome/functions/src/trimMask'
import moment from 'moment/moment'
import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import notInclude from '@smartcoop/forms/validators/notInclude.validator'
import password from '@smartcoop/forms/validators/password.validator'
import required from '@smartcoop/forms/validators/required.validator'

const confirmPassword = ({ t, user }) => Yup.object().shape({

  organizationId: flow(
    required({ t })
  )(Yup.string()),

  password: flow(
    password({ t }),
    notInclude({ t, text: 'invalid password', compareWith: trimMask(moment(user.dateOfBirth).format('L')) }),
    notInclude({ t, text: 'invalid password', compareWith: user.document }),
    notInclude({ t, text: 'invalid password', compareWith: trimMask(user.cellPhone.split(' ')[1] || user.cellPhone) }),
    required({ t })
  )(Yup.string())
})

export default confirmPassword
