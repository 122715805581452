import * as Yup from 'yup'

const filterDiagnosisSchema = () =>
  Yup.object().shape({
    earring: Yup.string(),
    date: Yup.string(),
    diagnosisType: Yup.string(),
    result: Yup.string()
  })

export default filterDiagnosisSchema
