import React, { useContext } from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'

import { ChatContext } from '../../../providers/ChatProvider'
import { formatChatDate } from '../../../utils/chatDateFormat'
import { getHighlightedText } from '../../../utils/chatHighlight'
import { Card, DateSpan, MessageContent, MessageSpan, NicknameSpan } from './styles'

const ChatSearchMessage = ({ message, searchParams, openChat }) => {
  const t = useT()
  const { generateContactUuid } = useContext(ChatContext)

  const handleOpenChat = () => {
    openChat({
      conversationId: message?.conversationId,
      relationalId: generateContactUuid(message?.contactId),
      userId: message?.contactId || message?.conversationId,
      localMessageId: message.id
    })
  }

  return (
    <Card onClick={ handleOpenChat }>
      <MessageContent>
        <NicknameSpan>{message.nickname}</NicknameSpan>
        <MessageSpan>{getHighlightedText(message.message, searchParams)}</MessageSpan>
      </MessageContent>
      <DateSpan>{formatChatDate(message.sendAt, t)}</DateSpan>
    </Card>
  )
}

ChatSearchMessage.propTypes = {
  message: PropTypes.object.isRequired,
  searchParams: PropTypes.string.isRequired,
  openChat: PropTypes.func.isRequired
}

export default ChatSearchMessage
