import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'


import I18n, { useT } from '@meta-react/i18n'
import withObservables from '@nozbe/with-observables'
import moment from 'moment/moment'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import toString from 'lodash/toString'
import values from 'lodash/values'

import { ThemeProvider } from '@material-ui/core'

import { animalService } from '@smartcoop/database/services/animalService'
import { database } from '@smartcoop/database/web-database'
import { useDialog } from '@smartcoop/dialog'
import { vaccine, alert as iconAlert, medical, observation  } from '@smartcoop/icons'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectListUpdatedAt } from '@smartcoop/stores/animal/selectorAnimal'
import { selectUserCanWrite, selectUserExtraAttributes } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import { AnimalStatusCode, MEDICATION_CONTROL_COLORS } from '@smartcoop/utils/constants'
import {
  momentBackDateFormat,
  momentBackDateTimeFormat,
  momentFriendlyDateFormat
} from '@smartcoop/utils/dates'
import { formatKebabCase } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import FilterButton from '@smartcoop/web-components/FilterButton'
import Icon from '@smartcoop/web-components/Icon'
import VerticalDotsIconButton from '@smartcoop/web-components/IconButton/VerticalDotsIconButton'
import InputSearch from '@smartcoop/web-components/InputSearch'
import MenuPopUp from '@smartcoop/web-components/MenuPopUp'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import Popover from '@smartcoop/web-components/Popover'
import Tooltip from '@smartcoop/web-components/Tooltip'
import BullListModal from '@smartcoop/web-containers/modals/dairyFarm/BullListModal/BullListModal'
import ChangeLotsModal from '@smartcoop/web-containers/modals/dairyFarm/ChangeLotsModal/ChangeLotsModal'
import FilterCattleManagementModal from '@smartcoop/web-containers/modals/dairyFarm/FilterCattleManagementModal'
import ImportAnimalModal from '@smartcoop/web-containers/modals/dairyFarm/ImportAnimalModal'
import LotListModal from '@smartcoop/web-containers/modals/dairyFarm/LotListModal/LotListModal'
import MedicationControlModal from '@smartcoop/web-containers/modals/dairyFarm/MedicationControlModal'
import ParametersRegistrationModal from '@smartcoop/web-containers/modals/dairyFarm/ParametersRegistrationModal/ParametersRegistrationModal'
import RegisterAnimalBirthModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterAnimalBirthModal/RegisterAnimalBirthModal'
import RegisterDiagnosisActionsModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterDiagnosisActionsModal'
import RegisterInseminationModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterInseminationModal/RegisterInseminationModal'
import RegisterObservationsModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterObservationsModal'
import RegisterPevModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterPevModal'
import RegisterPregnancyActionsModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterPregnancyActionsModal'
import RegisterProtocolModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterProtocolModal'
import WeightControlModal from '@smartcoop/web-containers/modals/dairyFarm/WeightControlModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { Top, inputSearchTheme, IconsContainer } from './styles'

const CattleManagement = ({ animals, propertyId }) => {
  const { createDialog } = useDialog()
  const t = useT()
  const mounted = useRef(false)
  const { routes } = useRoutes()
  const history = useHistory()
  const dispatch = useCallback(useDispatch(), [])

  const [filters, setFilters] = useState({})
  const [filterText, setFilterText] = useState('')
  const [loading, setLoading] = useState(false)
  const [debouncedFilterText, setDebouncedFilterText] = useState('')
  const userWrite = useSelector(selectUserCanWrite)
  const extraAttributes = useSelector(selectUserExtraAttributes)
  const listUpdatedAt = useSelector(selectListUpdatedAt)

  const handleOnRowClick = useCallback(
    (_, currentAnimal = {}) => {
      dispatch(AnimalActions.setCurrentAnimal(currentAnimal))
      dispatch(AnimalActions.setIsDetail(true))
      history.push(routes.registerAnimals.path)
    },
    [dispatch, history, routes.registerAnimals.path]
  )

  const mapValuesDeep = useCallback((obj, cb) => {
    if (isArray(obj)) {
      return obj.map((innerObj) => mapValuesDeep(innerObj, cb))
    }
    if (isObject(obj)) {
      return mapValues(obj, (val) => mapValuesDeep(val, cb))
    }
    if (isString(obj) || isBoolean(obj)) {
      return obj
    }
    return cb(obj)
  }, [])

  const tableFilters = useMemo(() => {
    const allFilters = debouncedFilterText
      ? { ...filters, q: debouncedFilterText }
      : filters
    const result = mapValuesDeep(allFilters, toString)
    return result
  }, [debouncedFilterText, filters, mapValuesDeep])

  const filteredAnimals = useMemo(() => {
    if(loading) {
      return []
    }
    let updatedAnimals = [...animals]
    if(!tableFilters?.dead) {
      updatedAnimals = filter([...updatedAnimals], item => item?.active)
    }
    if(!isEmpty(tableFilters)) {
      if(!isEmpty(tableFilters?.endBirthDate)) {
        updatedAnimals = filter([...updatedAnimals], item => moment(item?.birthDate, momentBackDateFormat).isBetween(moment(tableFilters?.startBirthDate, momentBackDateFormat), moment(tableFilters?.endBirthDate, momentBackDateFormat)))
      }if(!isEmpty(tableFilters?.lotId)) {
        updatedAnimals = filter([...updatedAnimals], item => item?.lotId === tableFilters?.lotId)
      }if(!isEmpty(tableFilters?.predominantBreedId)) {
        updatedAnimals = filter([...updatedAnimals], item => toString(item?.breedId) === tableFilters?.predominantBreedId)
      }if(!isEmpty(tableFilters?.statusId)) {
        updatedAnimals = filter([...updatedAnimals], item => toString(item?.statusId) === tableFilters?.statusId)
      }if(!isEmpty(tableFilters?.nextActionSlug)) {
        updatedAnimals = filter([...updatedAnimals], item => includes(tableFilters?.nextActionSlug, item?.nextAction))
      }
    }
    if(!isEmpty(debouncedFilterText)) {
      updatedAnimals = filter([...updatedAnimals], item => includes(formatKebabCase(item?.nameAndCode), formatKebabCase(debouncedFilterText)))
    }

    return map(updatedAnimals, item => ({
      ...item?._raw,
      nextActionDate: isEmpty(item?._raw?.nextActionDate) ? '31/12/9999' : item?._raw?.nextActionDate
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterText, loading, tableFilters])

  const loadAnimals = useCallback(
    (updatedAt = null) => {
      setLoading(true)
      animalService(database).syncAnimals({ updatedAt, deleted: !isEmpty(updatedAt) }, propertyId).finally(() => setLoading(false))
      dispatch(AnimalActions.setListUpdatedAt(moment().subtract(4, 'minutes').format(momentBackDateTimeFormat)))
    },
    [dispatch, propertyId]
  )

  const reloadDataTable = useCallback(() => {
    loadAnimals(listUpdatedAt)
  }, [listUpdatedAt, loadAnimals])

  useEffect(() => {
    if(!mounted?.current && isEmpty(animals)) {
      loadAnimals()
    } else if(moment(listUpdatedAt, momentBackDateTimeFormat).startOf('day').isBefore(moment().startOf('day'))) {
      loadAnimals()
    } else {
      reloadDataTable()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const registerActionModal = useMemo(
    () => ({
      protocol: RegisterProtocolModal,
      insemination: RegisterInseminationModal,
      calving: RegisterAnimalBirthModal,
      pregnancyAction: RegisterPregnancyActionsModal,
      pregnancyDiagnosis: RegisterDiagnosisActionsModal,
      medicine: MedicationControlModal,
      weight: WeightControlModal,
      pev: RegisterPevModal,
      observation: RegisterObservationsModal
    }),
    []
  )

  const openRegisterActionModal = useCallback(
    (type, row) => {
      createDialog({
        id: 'create-insemination',
        Component: registerActionModal[type],
        props: {
          animalId: row?.animalId,
          onSubmit: reloadDataTable,
          onAccept: (currentAnimal) => {history.push(routes.registerAnimals.path, { currentAnimal })},
          defaultValues: {
            animal: {
              id: row?.animalId
            }
          }
        }
      })
    },
    [createDialog, history, registerActionModal, reloadDataTable, routes.registerAnimals.path]
  )

  const handleDotsClick = useCallback(
    (row, type) => {
      dispatch(AnimalActions.setCurrentAnimal(row))
      openRegisterActionModal(type, row)
    },
    [dispatch, openRegisterActionModal]
  )

  const renderMenus = useCallback((row) => (
    <Popover
      popoverId="user-menu"
      popoverStyle={ { width: 250 } }
      Component={ VerticalDotsIconButton }
      key={ row?.id }
    >
      <MenuPopUp
        options={ [
          {
            icon: null,
            text: t('register protocol'),
            onClick: () => {
              handleDotsClick(row, 'protocol')
            },
            disabled:
                        (row.category !== 'vaca' &&
                        row.category !== 'novilha') ||
                        (toString(row?.statusId) !== AnimalStatusCode.VAZIA &&
                          toString(row?.statusId) !== AnimalStatusCode.APTAS &&
                          toString(row?.statusId) !== AnimalStatusCode.PEV &&
                          toString(row?.statusId) !== AnimalStatusCode.NENHUM)
                          || !userWrite
          },
          {
            icon: null,
            text: t('register insemination'),
            onClick: () => {
              handleDotsClick(row, 'insemination')
            },
            disabled:
                        (row.category !== 'vaca' &&
                        row.category !== 'novilha') ||
                        (toString(row?.statusId) !== AnimalStatusCode.VAZIA &&
                          toString(row?.statusId) !== AnimalStatusCode.APTAS &&
                          toString(row?.statusId) !== AnimalStatusCode.EM_PROTOCOLO &&
                          toString(row?.statusId) !== AnimalStatusCode.INSEMINADA_A_CONFIRMAR &&
                          toString(row?.statusId) !== AnimalStatusCode.INSEMINADA &&
                          toString(row?.statusId) !== AnimalStatusCode.PEV &&
                          toString(row?.statusId) !== AnimalStatusCode.NENHUM)
                          || !userWrite
          },
          {
            icon: null,
            text: t('register {this}', {
              this: t('diagnosis', { howMany: 1 })
            }),
            disabled:
                        (row?.category !== 'vaca' &&
                        row?.category !== 'novilha') ||
                        (toString(row?.statusId) !== AnimalStatusCode.INSEMINADA &&
                          toString(row?.statusId) !== AnimalStatusCode.INSEMINADA_A_CONFIRMAR &&
                          toString(row?.statusId) !== AnimalStatusCode.PRENHA)
                          || !userWrite,
            onClick: () => {
              handleDotsClick(row, 'pregnancyDiagnosis')
            }
          },
          {
            icon: null,
            text: t('register {this}', { this: t('other actions') }),
            disabled:
                        (row?.category !== 'vaca' &&
                        row?.category !== 'novilha') ||
                        toString(row?.statusId) !== AnimalStatusCode.PRENHA
                        || !userWrite,
            onClick: () => {
              handleDotsClick(row, 'pregnancyAction')
            }
          },
          {
            icon: null,
            text: t('register {this}', { this: t('calving') }),
            disabled:
                        (row?.category !== 'vaca' &&
                        row?.category !== 'novilha') ||
                        (toString(row?.statusId) !== AnimalStatusCode.PRENHA &&
                          toString(row?.statusId) !== AnimalStatusCode.PEV)
                          || !userWrite,
            onClick: () => {
              handleDotsClick(row, 'calving')
            }
          },
          {
            icon: null,
            text: t('register {this}', { this: t('pev') }),
            disabled:
                        (row?.category !== 'vaca' &&
                        row?.category !== 'novilha') ||
                        toString(row?.statusId) !== AnimalStatusCode.PEV
                        || !userWrite,
            onClick: () => {
              handleDotsClick(row, 'pev')
            }
          },
          {
            icon: null,
            text: t('vaccines and medications'),
            onClick: () => {
              handleDotsClick(row, 'medicine')
            }
          },
          {
            icon: null,
            text: t('weight control'),
            onClick: () => {
              handleDotsClick(row, 'weight')
            }
          },
          {
            icon: null,
            text: t('register {this}', { this: t('observation', { howMany: 1 }) }),
            onClick: () => {
              handleDotsClick(row, 'observation')
            }
          }
        ] }
      />
    </Popover>
  ), [handleDotsClick, t, userWrite])

  const columns = useMemo(
    () => [
      {
        title: '',
        disableClick: true,
        render: renderMenus,
        width: '4%'
      },
      {
        width: '6%',
        title: t('earring'),
        field: 'earringCode',
        render: (row) => row?.earringCode,
        sorting: true,
        defaultSort: 'asc',
        type: 'numeric',
        align: 'center'
      },
      {
        width: '8%',
        title: t('last birth'),
        sorting: true,
        field: 'lastBirth',
        render: (row) => row?.lastBirth ?
          moment(row.lastBirth, momentBackDateFormat).format(
            momentFriendlyDateFormat
          ) : '-'
      },
      {
        width: '8%',
        title: t('del'),
        field: 'del',
        sorting: true,
        render: (row) => row?.del ?
          `${ row?.del } ${ t('days') }` : '-'
      },
      {
        width: '12%',
        title: t('lot', { howMany: 1 }),
        sorting: true,
        field: 'lotId',
        render: (row) => row?.lotName
      },
      {
        width: '12%',
        title: t('status'),
        field: 'statusName',
        sorting: true,
        render: (row) => row?.statusName
      },
      {
        width: '14%',
        title: t('next action'),
        field: 'actionName',
        sorting: true,
        render: (row) =>
          row?.actionName || '-'
      },
      {
        width: '9%',
        title: t('next action date'),
        field: 'nextActionDate',
        sorting: true,
        render: (row) =>
          <span style={ { color: row?.deadlineColor || 'inherit' } }>
            {
              row?.nextActionDate && row?.nextActionDate !== '31/12/9999'
                ? moment(
                  row?.nextActionDate,
                  momentBackDateTimeFormat
                ).format(momentFriendlyDateFormat)
                : '-'
            }
          </span>
      },
      {
        width: '4%',
        title:
          <Tooltip title="Calendário de Vacinação">
            <Icon size={ 24 } icon={ vaccine } style={ { backgroundColor: MEDICATION_CONTROL_COLORS['calendario-de-vacinacao'], color: '#fff', borderRadius: '20px' } } />
          </Tooltip>
        ,
        field: 'calendarDate',
        sorting: true,
        render: row =>
          <IconsContainer>
            {row?.calendarDate ?
              <Tooltip title={ moment(row?.calendarDate, momentBackDateFormat).format(momentFriendlyDateFormat) }>
                <Icon size={ 24 } icon={ vaccine } style={ { backgroundColor: '#199cd8', color: '#fff', borderRadius: '20px' } } />
              </Tooltip>
              : null}
          </IconsContainer>,
        align: 'center'
      },
      {
        width: '4%',
        title:
          <Tooltip title="Diagnóstico de Doença">
            <Icon size={ 24 } icon={ medical } style={ { backgroundColor: MEDICATION_CONTROL_COLORS['diagnostico-de-doenca'], color: '#fff', borderRadius: '20px' } } />
          </Tooltip>
        ,
        field: 'diagnosisDate',
        sorting: true,
        render: row =>
          <IconsContainer>
            {row?.diagnosisDate ?
              <Tooltip title={ moment(row?.diagnosisDate, momentBackDateFormat).format(momentFriendlyDateFormat) }>
                <Icon size={ 24 } icon={ medical } style={ { backgroundColor: '#e41d1b', color: '#fff', borderRadius: '20px' } } />
              </Tooltip>
              : null }
          </IconsContainer>,
        align: 'center'
      },
      {
        width: '4%',
        title:
          <Tooltip title="Período de Carência">
            <Icon size={ 24 } icon={ iconAlert } style={ { backgroundColor: colors.secondary, color: '#fff', borderRadius: '20px' } } />
          </Tooltip>
        ,
        field: 'carencyDate',
        render: row =>
          <IconsContainer>
            {row?.carencyDate ? <Tooltip title={ moment(row?.carencyDate, momentBackDateFormat).format(momentFriendlyDateFormat) }>
              <Icon size={ 24 } icon={ iconAlert } style={ { backgroundColor: colors.secondary, color: '#fff', borderRadius: '20px' } } />
            </Tooltip>
              : null }
          </IconsContainer>,
        sorting: true,
        align: 'center'
      },
      {
        width: '4%',
        title: '',
        field: 'lastObservation',
        render: row =>
          <IconsContainer>
            {row?.lastObservation ? <Tooltip title={ row?.lastObservation }>
              <Icon size={ 24 } icon={ observation } />
            </Tooltip>
              : null }
          </IconsContainer>,
        sorting: true,
        align: 'center'
      }
    ],
    [renderMenus, t]
  )

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )

  const openFilterModal = useCallback(() => {
    createDialog({
      id: 'filter-cattle-management',
      Component: FilterCattleManagementModal,
      props: {
        onSubmit: setFilters,
        filters
      }
    })
  }, [createDialog, filters])

  const handleParametersRegistration = useCallback(() => {
    createDialog({
      id: 'parameters-registration-modal',
      Component: ParametersRegistrationModal,
      props: {
        onClose: reloadDataTable
      }
    })
  }, [createDialog, reloadDataTable])

  const handleMilkControl = useCallback(() => {
    history.push(routes.listMilkControl.path)
  }, [history, routes])

  const handleWeightControl = useCallback(() => {
    history.push(routes.listWeightControl.path)
  }, [history, routes])

  const handleLotList = useCallback(() => {
    createDialog({
      id: 'lot-list-modal',
      Component: LotListModal
    })
  }, [createDialog])

  const handleEditAnimal = useCallback(
    (_, currentAnimal = {}) => {
      dispatch(AnimalActions.setCurrentAnimal(currentAnimal))
      history.push(routes.registerAnimals.path, { currentAnimal })
    },
    [dispatch, history, routes]
  )
  const handleDeleteAnimal = useCallback(
    (_, currentAnimal = {}) => {
      createDialog({
        id: 'confirm-deletion-modal',
        Component: ConfirmModal,
        props: {
          onConfirm: () => dispatch(AnimalActions.deleteOfflineAnimal(currentAnimal?.animalId, reloadDataTable)),
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('animal', { howMany: 1 })
          })
        }
      })

    },
    [createDialog, dispatch, reloadDataTable, t]
  )

  const handleRegisterAnimal = useCallback(() => {
    dispatch(AnimalActions.resetCurrentAnimal())
    dispatch(AnimalActions.resetIsDetail())
    history.push(routes.registerAnimals.path)
  }, [dispatch, history, routes.registerAnimals.path])

  return (
    <>
      <Top>
        <div style={ { marginRight: 10 } }>
          <Button
            id="weight-control-list"
            onClick={ handleWeightControl }
            color="white"
          >
            <I18n>weight control</I18n>
          </Button>
        </div>
        <div style={ { marginRight: 10 } }>
          <Button
            id="milk-control-registeration"
            onClick={ handleMilkControl }
            color="white"
          >
            <I18n>milk control</I18n>
          </Button>
        </div>
        <div style={ { marginRight: 10 } }>
          <Button
            id="parameters-registeration"
            onClick={ handleParametersRegistration }
            color="white"
          >
            <I18n>parameters</I18n>
          </Button>
        </div>
        <div style={ { marginRight: 10 } }>
          <Button
            id="lot-list"
            onClick={ handleLotList }
            color="white"
          >
            <I18n params={ { howMany: 2 } }>lot</I18n>
          </Button>
        </div>
        <div style={ { marginRight: 10 } }>
          <ThemeProvider theme={ inputSearchTheme }>
            <InputSearch
              detached
              onChange={ onChangeSearchFilter }
              value={ filterText }
              placeholder={ t('search') }
              fullWidth
            />
          </ThemeProvider>
        </div>
        <div>
          <FilterButton
            onClick={ openFilterModal }
            isActive={
              !values(
                mapValuesDeep(
                  { ...filters, dead: filters?.dead ? 'dead' : '' },
                  toString
                )
              ).every(isEmpty)
            }
          />
        </div>
        <div>
          <Button
            id="register-animals"
            onClick={ handleRegisterAnimal }
            color="secondary"
            disabled={ !userWrite }
          >
            <I18n params={ { howMany: 1, this: t('animal', { howMany: 1 }) } }>
              {'register {this}'}
            </I18n>
          </Button>
        </div>
        <div>
          <Popover
            popoverId="animal-options"
            Component={ VerticalDotsIconButton }
          >
            <div style={ { width: 200 } }>
              <MenuPopUp
                options={ extraAttributes?.enabledBulls ? [
                  {
                    icon: null,
                    text: t('change lots'),
                    onClick: () => {
                      createDialog({
                        id: 'change-lots',
                        Component: ChangeLotsModal,
                        props: {
                          t,
                          onSubmit: reloadDataTable
                        }
                      })
                    }
                  },
                  {
                    icon: null,
                    text: t('import animals'),
                    onClick: () => {
                      createDialog({
                        id: 'filter-cattle-management',
                        Component: ImportAnimalModal,
                        props: {
                          t,
                          reloadDataTable
                        }
                      })
                    }
                  },
                  {
                    icon: null,
                    text: t('bulls'),
                    onClick: () => {
                      createDialog({
                        id: 'bulls-cattle-management',
                        Component: BullListModal,
                        props: {
                          t,
                          reloadDataTable
                        }
                      })
                    }
                  }
                ] : [
                  {
                    icon: null,
                    text: t('change lots'),
                    onClick: () => {
                      createDialog({
                        id: 'change-lots',
                        Component: ChangeLotsModal,
                        props: {
                          t,
                          onSubmit: reloadDataTable
                        }
                      })
                    }
                  },
                  {
                    icon: null,
                    text: t('import animals'),
                    onClick: () => {
                      createDialog({
                        id: 'filter-cattle-management',
                        Component: ImportAnimalModal,
                        props: {
                          t,
                          reloadDataTable
                        }
                      })
                    }
                  }
                ] }
              />
            </div>
          </Popover>
        </div>
      </Top>
      <div style={ { marginTop: 20 } }>
        <DataTable
          columns={ columns }
          data={ filteredAnimals }
          onRowClick={ handleOnRowClick }
          onEditClick={ handleEditAnimal }
          onDeleteClick={ handleDeleteAnimal }
          disabled={ !userWrite }
          loading={ loading }
          id="cattle-management-table"
          pageSize={ 100 }
        />
      </div>
    </>
  )
}

CattleManagement.propTypes = {
  animals: PropTypes.array,
  propertyId: PropTypes.string
}

CattleManagement.defaultProps = {
  animals: [],
  propertyId: null
}

const enhance = withObservables(['propertyId'], ({ propertyId }) => ({
  animals: animalService(database).observeAnimals(propertyId)
}))

const EnhancedCattleManagement = enhance(CattleManagement)

export default EnhancedCattleManagement
