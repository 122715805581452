import React from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import first from 'lodash/first'

import { Avatar } from '@material-ui/core'

import { database } from '@smartcoop/database'
import { profilePictureService } from '@smartcoop/database/services/profilePictureService'
import CheckboxCard from '@smartcoop/web-components/FilteredCheckboxGroup/CheckboxCard'

import { Card } from './styles'

const ConversationCard = ({ conversation, profilePictures, selected, handleSelect }) => {
  const profilePicture = first(profilePictures)

  return (
    <Card onClick={ handleSelect }>
      <CheckboxCard
        style={ { backgroundColor: 'transparent', margin: 0, paddingLeft: 4 } }
        onClick={ event => event.stopPropagation() }
        checked={ selected }
        onChange={ handleSelect }
      />
      <Avatar src={ profilePicture?.source } size={ 25 } alt={ conversation.name } />
      <span>
        {conversation.name}
      </span>
    </Card>
  )
}

ConversationCard.propTypes = {
  conversation: PropTypes.object.isRequired,
  profilePictures: PropTypes.array.isRequired,
  selected: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired
}

const enhance = withObservables(['userId', 'isGroup'], ({ userId, isGroup }) => ({
  profilePictures: profilePictureService(database).observeProfilePictureByUserId(userId, isGroup)
}))

const EnhancedConversationCard = enhance(ConversationCard)

export default EnhancedConversationCard
