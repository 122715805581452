import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  margin: 20px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

`

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.5px;
  margin: 20px;
`


export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 20px;
`

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Label = styled(Typography)`
  font-size: ${ `${ fonts.fontSize.S }px` };
  font-family: ${ fonts.fontFamilyMontserrat };
  margin-bottom: 10px;
  font-weight: ${ fonts.fontWeight.semiBold };
`
