/* eslint-disable no-console */
import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { NonCompliancesActions } from '@smartcoop/stores/nonCompliance'
import { colors } from '@smartcoop/styles'
import Modal from '@smartcoop/web-components/Modal'

import useStyles, { FlexDiv, CustomButton, TextDiv } from './styles'

const ResolveNonComplianceModal = (props) => {
  const { id, open, handleClose, getNonCompliances, selectedNonCompliance } = props
  const t = useT()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const [loading, setLoading] = useState(false)

  const handleError = () => setLoading(false)

  const handleSuccess = useCallback(() => {
    snackbar.success(t('non compliance resolved success'))
    setLoading(false)
    handleClose()
    getNonCompliances()
  },[getNonCompliances, handleClose, snackbar, t])

  const handleResolve = () => {
    setLoading(true)
    dispatch(NonCompliancesActions.resolveNonCompliance({ nonComplianceId: selectedNonCompliance.id }, handleSuccess , handleError))
  }

  return (
    <Modal
      id={ id }
      open={ open }
      title={ t('non compliance treat as resolved') }
      disableFullScreen
      escape
      contentContainerStyle={ { padding: 0, minWidth: 500 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      footerContainerStyle={ { boxShadow: 'none' } }
      FooterComponent={ () => (
        <div
          style={ {
            display: 'flex',
            width: '-webkit-fill-available',
            justifyContent: 'center'
          } }
        >
          <FlexDiv>
            <CustomButton onClick={ handleResolve }>
              {t('non compliance treat as resolved')}
            </CustomButton>
          </FlexDiv>
          <FlexDiv>
            <CustomButton
              style={ { marginLeft: '5px' } }
              variant="outlined"
              color={ colors.black }
            >
              {t('non compliance cancel')}
            </CustomButton>
          </FlexDiv>
        </div>
      ) }
      loading={ loading }
    >
      <TextDiv>
        <I18n>non compliance resolved modal</I18n>
      </TextDiv>
    </Modal>
  )
}

ResolveNonComplianceModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  getNonCompliances: PropTypes.func.isRequired,
  selectedNonCompliance: PropTypes.object.isRequired
}

ResolveNonComplianceModal.defaultProps = {
}

export default ResolveNonComplianceModal
