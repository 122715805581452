// eslint-disable-next-line spaced-comment
export default ({ size } = {}) => /*html*/`
<svg width="${ size }" height="${ size }" viewBox="0 0 173 139" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M125.071 5.35315C124.405 6.82708 122.664 7.48746 121.19 6.82261C119.717 6.15777 119.058 4.41705 119.724 2.94311C120.39 1.46918 122.132 0.808807 123.605 1.47365C125.084 2.14443 125.737 3.87921 125.071 5.35315Z" fill="#E0E0E0"/>
  <path d="M27.7041 127.327C-2.95107 113.082 4.19565 70.0502 27.6505 64.5011C36.1962 62.4793 54.9362 55.6938 71.012 32.4522C87.0877 9.21057 110.541 7.13971 119.026 8.79607C161.688 18.7694 165.366 61.7412 164.96 78.1829C164.554 94.6246 148.835 113.365 130.266 113.365C114.42 113.365 86.7774 110.05 75.2289 119.219C58.9232 132.164 41.8225 133.888 27.7041 127.327Z" fill="#F9F8F6"/>
  <path d="M149.3 88.4217C148.634 89.8956 146.893 90.556 145.42 89.8911C143.946 89.2262 143.288 87.4855 143.954 86.0116C144.62 84.5377 146.361 83.8774 147.834 84.5423C149.314 85.2131 149.967 86.9478 149.3 88.4217Z" fill="#E0E0E0"/>
  <path d="M25.1872 88.4217C24.521 89.8956 22.7797 90.5559 21.3064 89.8911C19.8331 89.2262 19.1743 87.4855 19.8405 86.0116C20.5066 84.5377 22.2479 83.8774 23.7212 84.5422C25.2005 85.213 25.8533 86.9478 25.1872 88.4217Z" fill="#E0E0E0"/>
  <path d="M170.802 76.6986C170.136 78.1726 168.395 78.8329 166.922 78.1681C165.448 77.5032 164.79 75.7625 165.456 74.2886C166.122 72.8147 167.863 72.1543 169.337 72.8192C170.816 73.4781 171.475 75.2188 170.802 76.6986Z" fill="#E0E0E0"/>
  <path d="M147.347 41.5143C146.681 42.9882 144.939 43.6486 143.466 42.9837C141.993 42.3189 141.334 40.5782 142 39.1043C142.666 37.6304 144.408 36.97 145.881 37.6349C147.354 38.2997 148.013 40.0404 147.347 41.5143Z" fill="#E0E0E0"/>
  <path d="M45.7097 33.6945C45.0435 35.1684 43.3022 35.8288 41.8289 35.1639C40.3555 34.4991 39.6968 32.7584 40.3629 31.2844C41.0291 29.8105 42.7704 29.1501 44.2437 29.815C45.723 30.4858 46.3758 32.2205 45.7097 33.6945Z" fill="#E0E0E0"/>
  <path d="M49.6195 135.333C48.9534 136.807 47.2121 137.467 45.7388 136.802C44.2654 136.137 43.6067 134.397 44.2728 132.923C44.9389 131.449 46.6802 130.789 48.1535 131.453C49.6269 132.118 50.2856 133.859 49.6195 135.333Z" fill="#E0E0E0"/>
  <path d="M43.978 106.524C43.978 111.38 47.9177 115.32 52.7735 115.32H117.274C122.13 115.32 126.07 111.38 126.07 106.524V56.6829H43.978V106.524ZM55.7054 71.3421C55.7054 69.7296 57.0247 68.4103 58.6372 68.4103H76.2283C77.8408 68.4103 79.1601 69.7296 79.1601 71.3421V88.9332C79.1601 90.5457 77.8408 91.865 76.2283 91.865H58.6372C57.0247 91.865 55.7054 90.5457 55.7054 88.9332V71.3421ZM117.274 33.2282H108.478V24.4327C108.478 22.8202 107.159 21.5009 105.547 21.5009H99.683C98.0705 21.5009 96.7511 22.8202 96.7511 24.4327V33.2282H73.2964V24.4327C73.2964 22.8202 71.9771 21.5009 70.3646 21.5009H64.5009C62.8884 21.5009 61.5691 22.8202 61.5691 24.4327V33.2282H52.7735C47.9177 33.2282 43.978 37.1679 43.978 42.0237V50.8193H126.07V42.0237C126.07 37.1679 122.13 33.2282 117.274 33.2282Z" fill="#1D1D1B"/>
  <path d="M119.026 90.889C106.093 90.889 95.571 101.411 95.571 114.344C95.571 127.277 106.093 137.798 119.026 137.798C131.959 137.798 142.48 127.277 142.48 114.344C142.48 101.411 131.959 90.889 119.026 90.889Z" fill="#FFC80A"/>
  <path d="M121.233 127.098C121.233 128.17 120.362 129.041 119.29 129.041C118.217 129.041 117.346 128.17 117.346 127.098C117.346 126.025 118.217 125.155 119.29 125.155C120.362 125.155 121.233 126.02 121.233 127.098Z" fill="#1D1D1B"/>
  <path d="M122.365 116.677C121.674 116.995 121.229 117.686 121.229 118.445V119.316C121.229 120.388 120.358 121.259 119.285 121.259C118.213 121.259 117.342 120.388 117.342 119.316V118.445C117.342 116.172 118.675 114.097 120.735 113.141C122.715 112.228 124.149 109.807 124.149 108.617C124.149 105.936 121.966 103.754 119.285 103.754C116.604 103.754 114.422 105.936 114.422 108.617C114.422 109.69 113.551 110.561 112.479 110.561C111.401 110.561 110.536 109.69 110.536 108.617C110.536 103.791 114.465 99.8677 119.285 99.8677C124.106 99.8677 128.035 103.797 128.035 108.617C128.04 111.251 125.757 115.111 122.365 116.677Z" fill="#1D1D1B"/>
</svg>
`
