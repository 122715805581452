import React from 'react'

import I18n from '@meta-react/i18n'
import PropTypes from 'prop-types'

import { filter as filterIcon } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'

import Button from '../Button/Button'
import Icon from '../Icon/Icon'

const FilterButton = props => {
  const {
    isActive,
    onClick,
    ...otherProps
  } = props


  return (
    <Button
      id="filter-button"
      color={ isActive ? colors.secondary : colors.white }
      style={ {
        marginRight: 10
      } }
      onClick={ onClick }
      { ...otherProps }
    >
      <>
        <Icon icon={ filterIcon } size={ 14 } style={ { marginRight: 6 } } />
        <I18n>filtrate</I18n>
      </>
    </Button>
  )
}

FilterButton.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func
}

FilterButton.defaultProps = {
  isActive: false,
  onClick: () => {}
}


export default FilterButton
