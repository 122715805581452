import moment from 'moment/moment'

import find from 'lodash/find'
import orderBy from 'lodash/orderBy'


export const selectOperations = state => state.operations?.operations || []

export const selectIsAccredited = state => find(state.operations.accredited, item => item.requestStatus === 'APROVADO' && moment(item?.expirationDate, 'YYYY-MM-DD h:mm:ss').isSameOrAfter(moment().format('YYYY-MM-DD h:mm:ss')))

export const selectIsWaiting = state => find(state.operations.accredited, item => item.requestStatus === 'AGUARDANDO_APROVACAO')

export const selectAccredited = state => state.operations?.accredited

export const selectAccrediteds = state => orderBy(state.operations?.accrediteds, 'requestStatus', 'asc') || []
