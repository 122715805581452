import { tableSchema } from '@nozbe/watermelondb'


export const userSchema = tableSchema({
  name: 'user',
  columns: [
    { name: 'userId', type: 'string' },
    { name: 'userCode', type: 'string' },
    { name: 'name', type: 'string' },
    { name: 'profilePhoto', type: 'string' },
    { name: 'publicKey', type: 'string' },
    { name: 'privateKey', type: 'string' },
    { name: 'profiles', type: 'string' }
  ]
})
