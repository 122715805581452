
import styled from 'styled-components'

import { Item } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'



export const ProductSubTitleItem = styled(Item)`
  font-size: 16px;
  font-weight: 600;
  padding-top: 0;
  padding-bottom: 0;
`

export const ProductItem = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  padding-bottom: 13px;
`

export const ProductItemText = styled(Item)`
  padding-top: 0;
  padding-bottom: 0;
`
