import styled from 'styled-components'

import { Typography } from '@material-ui/core'

import { colors } from '@smartcoop/styles'


export const PlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  background-color: ${ colors.lightGrey };
  margin-right: 25px;
  padding: 10px 10px;
  border: none;
  border-radius: 8px;
  height: 50px;
`

export const ButtonControls = styled.button`
  background-color: ${ colors.lightGrey };
  border: none;
`

export const TimerCounter = styled(Typography)`
  color: ${ colors.primary };
  font-size: 18px;
`

export const AudioRecorderButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  background-color: ${ ({ disabled }) => disabled ? colors.mediumGrey : colors.yellow };
  margin-right: 15px;
`
