import * as Yup from 'yup'

import { flow } from 'lodash'


import cep from '@smartcoop/forms/validators/cep.validator'
import cpfCnpj from '@smartcoop/forms/validators/cpfCnpj.validator'
import email from '@smartcoop/forms/validators/email.validator'
import phone from '@smartcoop/forms/validators/phone.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerSupplier = ({ t }) => Yup.object().shape({

  active: flow(
    required({ t })
  )(Yup.string()),

  companyDocument: flow(
    required({ t }),
    cpfCnpj({ t, onlyCnpj: true })
  )(Yup.string()),

  companyName: flow(
    required({ t })
  )(Yup.string()),

  tradeName: flow(
    required({ t })
  )(Yup.string()),

  phone: flow(
    phone({ t }),
    required({ t })
  )(Yup.string()),

  email: flow(
    email({ t }),
    required({ t })
  )(Yup.string()),

  exemptStateRegistration: flow(
    required({ t })
  )(Yup.string()),

  stateRegistration: Yup.string().when(
    'exemptStateRegistration', {
      is: 'false',
      then: flow(
        required({ t })
      )(Yup.string())
    }
  ),

  products: flow(
    required({ t, isNotText: true })
  )(Yup.array()),

  state: flow(
    required({ t })
  )(Yup.string()),

  city: flow(
    required({ t })
  )(Yup.string()),

  cep: flow(
    cep({ t }),
    required({ t })
  )(Yup.string()),

  street: flow(
    required({ t })
  )(Yup.string()),

  number: flow(
    required({ t })
  )(Yup.string()),

  neighborhood: flow(
    required({ t })
  )(Yup.string()),

  addressComplement: Yup.string()

})

export default registerSupplier
