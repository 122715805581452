import React, { useCallback, forwardRef, useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import capitalize from 'lodash/capitalize'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import round from 'lodash/round'
import toNumber from 'lodash/toNumber'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { alert as iconAlert , plus } from '@smartcoop/icons'
import { getItems, getCosts, editCostsByBulk } from '@smartcoop/services/apis/smartcoopApi/resources/costs'
import { useSnackbar } from '@smartcoop/snackbar'
import { CostsActions } from '@smartcoop/stores/costs/duckCosts'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import { momentBackDateTimeFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import { formatCurrency } from '@smartcoop/utils/formatters'
import { convertValues, getBaseVolume } from '@smartcoop/utils/functions'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import InputUnit from '@smartcoop/web-components/InputUnit'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import NewCostModal from '@smartcoop/web-containers/modals/NewCostModal'

import { Container, Title, SubTitle, Row, Description, ItemGroup, ButtonContainer } from './styles'

const FieldCostsForm = forwardRef((props) => {
  const { growingSeason, year } = props

  const t = useT()
  const { createDialog } = useDialog()
  const tableRef = useRef(null)
  const fromManagementTableRef = useRef(null)
  const dispatch = useDispatch()
  const snackbar = useSnackbar()

  const propertyId = useSelector(selectCurrentPropertyId)

  const [costsItems, setCostsItems] = useState([])
  const [seasonCosts, setSeasonCosts] = useState([])
  const [editedCosts, setEditedCosts] = useState({})

  const createdCosts = useMemo(() => filter(seasonCosts, item => !item?.referenceId), [seasonCosts])
  const fromManagementCosts = useMemo(() => orderBy(map(filter(seasonCosts, item => item?.referenceId), item => ({
    ...item,
    value: item?.value
  }) ), 'realizationDate'), [seasonCosts])

  const area = useMemo(() => {
    if(growingSeason?.childrenPolygonId) {
      return find(growingSeason?.fieldData?.childrenPolygons, item => item?.id === growingSeason?.childrenPolygonId)?.area
    }
    return growingSeason?.fieldData?.area
  }, [growingSeason])

  const getCostsGrowingSeason = useCallback(async () => {
    const { data } = await getCosts({ growingSeasonId: growingSeason?.id })
    setSeasonCosts(data)
    setEditedCosts({})
  }, [growingSeason])

  const saveCosts = useCallback(() => {
    createDialog({
      id: 'confirm-save-rubric',
      Component: ConfirmModal,
      props: {
        onConfirm: async () => {
          await editCostsByBulk(map(editedCosts, (item) => ({ ...item }))).then(
            () => {
              getCostsGrowingSeason()
            }
          )
        },
        textWarning: t('are you sure you want to save these changes')
      }
    })
  }, [createDialog, editedCosts, getCostsGrowingSeason, t])

  useEffect(() => {
    const getCostsItems = async () => {
      const { data } = await getItems({ propertyId })
      setCostsItems(data)
    }

    getCostsItems()
    getCostsGrowingSeason()
  },[getCostsGrowingSeason, growingSeason, propertyId])

  const openAddCostModal = useCallback((defaultValues = {}) => {
    const realizationMonths = defaultValues?.monthlyApportionment ? map(filter(createdCosts, item => defaultValues?.bulkId === item?.bulkId), item => moment(item?.realizationDate, momentBackDateTimeFormat).format('YYYY-MM')) : null

    createDialog({
      id: 'organization-onboarding',
      Component: NewCostModal,
      props: {
        defaultValues: !isEmpty(defaultValues) ? {
          ...defaultValues,
          realizationMonths,
          value: realizationMonths ? defaultValues?.value * realizationMonths.length : defaultValues?.value
        } : {},
        year,
        managementOptions: map(growingSeason?.cropsManagements || growingSeason?.cropManagements, item => ({ date: item?.realizationDate || item?.predictedDate, label: `${ item?.operation?.name } - ${ moment(item?.realizationDate || item?.predictedDate, momentBackDateTimeFormat).format(momentFriendlyDateFormat) }`, value: item?.id })),
        costsOptions: map(costsItems, item => ({ value: item?.id, label: item?.description, ...item })),
        onSuccess: getCostsGrowingSeason,
        growingSeasonId: growingSeason?.id
      }
    })
  },[costsItems, createDialog, createdCosts, getCostsGrowingSeason, growingSeason, year])

  const handleDoseChange = useCallback(
    debounce((valueDose, row, convertedCost) => {
      const valueHA = toNumber(valueDose) * convertedCost

      setEditedCosts(old => ({
        ...old,
        [row?.referenceId]: {
          ...old?.[row?.referenceId],
          id: row?.id,
          valueDose: !isEmpty(valueDose) ? toNumber(valueDose) : valueDose,
          valueHA,
          value: valueHA*area
        }
      }))
    }, 800),
    [] // Dependencies should be empty to prevent recreation of the debounced function
  )

  const handleHAChange = useCallback(
    debounce((valueHA, row, convertedCost, disabled) => {
      let valueDose
      if (!disabled) {
        valueDose = toNumber(valueHA) / convertedCost
      }

      setEditedCosts(old => ({
        ...old,
        [row?.referenceId]: {
          ...old?.[row?.referenceId],
          id: row?.id,
          valueDose,
          valueHA: !isEmpty(valueHA) ? toNumber(valueHA) : valueHA,
          value: valueHA*area
        }
      }))
    }, 800),
    [] // Dependencies should be empty to prevent recreation of the debounced function
  )

  const fromManagementColumns = useMemo(
    () => [
      {
        title: t('date', { howMany: 1 }),
        field: 'realizationDate',
        render: (row) => row?.realizationDate ? moment(row?.realizationDate, momentBackDateTimeFormat).format(momentFriendlyDateFormat) : '-',
        cellStyle: { width: '10%' }
      },
      {
        title: t('management'),
        field: 'management',
        render: (row) => {
          const management = find(growingSeason?.cropManagements || growingSeason?.cropsManagements, item => item.id === row?.cropManagementId)
          return isEmpty(management) ? '-' : `${ management?.operation?.name } - ${ moment(management?.realizationDate || management?.predictedDate, momentBackDateTimeFormat).format(momentFriendlyDateFormat) }`
        }
      },
      {
        title: `${ t('description') } | ${ t('product') }`,
        field: 'description',
        render: (row) =>  {
          // eslint-disable-next-line react/prop-types
          const management = find(growingSeason?.cropManagements || growingSeason?.cropsManagements, item => item.id === row?.cropManagementId)

          const foundItem = find(flatMap(management?.cropsManagementItems, item => item?.items?.formFields), item => item?.referenceId === row?.referenceId)

          const disabled = isEmpty(foundItem?.unit) || (isEmpty(foundItem?.dosePhyntosanitary) && isEmpty(foundItem?.dose))

          return <div style={ { display: 'flex', flexDirection: 'column' } }>
            <div>
              {row?.product?.shortDescription === 'Formulado' ? capitalize(row?.description) : row?.product?.shortDescription}
            </div>
            <div style={ { fontSize: 12, color: colors.mutedText } }>
              { disabled ? '' : `${ foundItem?.dosePhyntosanitary || foundItem?.dose } ${ foundItem?.unit }` }
            </div>
          </div>
        }
      },
      {
        title: t('dose'),
        field: 'dose',
        render: (row) =>  {
          // eslint-disable-next-line react/prop-types
          const management = find(growingSeason?.cropManagements || growingSeason?.cropsManagements, item => item.id === row?.cropManagementId)

          const foundItem = find(flatMap(management?.cropsManagementItems, item => item?.items?.formFields), item => item?.referenceId === row?.referenceId)

          return `${ foundItem?.dose || foundItem?.dosePhyntosanitary } ${ foundItem?.unit }`
        }
      },
      {
        title: t('total value'),
        field: 'value',
        render: (row) =>
          // eslint-disable-next-line no-nested-ternary
          editedCosts?.[row?.referenceId] ? formatCurrency(editedCosts?.[row?.referenceId]?.valueHA*area) : row?.value ? formatCurrency(row?.value) : '-'
        ,
        sorting: true,
        cellStyle: { width: '10%' }
      },
      {
        title: t('product value'),
        field: 'valueByDose',
        cellStyle: { width: '20%' },
        render: (row) =>  {
          // eslint-disable-next-line react/prop-types
          const management = find(growingSeason?.cropManagements || growingSeason?.cropsManagements, item => item.id === row?.cropManagementId)

          const foundItem = find(flatMap(management?.cropsManagementItems, item => item?.items?.formFields), item => item?.referenceId === row?.referenceId)
          const convertedCost = convertValues(foundItem?.dosePhyntosanitary || foundItem?.dose, foundItem?.unit, getBaseVolume(foundItem?.unit))

          const disabled = isEmpty(foundItem?.unit) || (isEmpty(toString(foundItem?.dosePhyntosanitary)) && isEmpty(toString(foundItem?.dose)))
          return (
            <InputUnit
              disabled={ disabled }
              detached
              type="float"
              unit={ `R$/${ foundItem?.unit ? getBaseVolume(foundItem?.unit) : 'dose' }` }
              fullWidth
              decimalScale={ 2 }
              style={ { marginBottom: '0' } }
              value={ editedCosts?.[row?.referenceId]?.valueDose || (row?.value ? (row?.value/area) / convertedCost : null) }
              onChange={ ({ target: { value } }) => handleDoseChange(value, row, convertedCost) }
            />
          )
        }
      },
      {
        title: t('value by ha'),
        field: 'valueByHa',
        cellStyle: { width: '20%' },
        render: (row) =>  {
          // eslint-disable-next-line react/prop-types
          const management = find(growingSeason?.cropManagements || growingSeason?.cropsManagements, item => item.id === row?.cropManagementId)

          const foundItem = find(flatMap(management?.cropsManagementItems, item => item?.items?.formFields), item => item?.referenceId === row?.referenceId)
          const convertedCost = convertValues(foundItem?.dosePhyntosanitary || foundItem?.dose, foundItem?.unit, getBaseVolume(foundItem?.unit))
          const disabled = isEmpty(foundItem?.unit) || (isEmpty(toString(foundItem?.dosePhyntosanitary)) && isEmpty(toString(foundItem?.dose)))

          return (
            <InputUnit
              detached
              type="float"
              unit="R$/ha"
              fullWidth
              decimalScale={ 2 }
              style={ { marginBottom: '0' } }
              value={ editedCosts?.[row?.referenceId]?.valueHA || (row?.value ? row?.value/area : null) }
              onChange={ ({ target: { value } }) => handleHAChange(value, row, convertedCost, disabled) }
            />
          )
        }
      }
    ],
    [area, editedCosts, growingSeason, handleDoseChange, handleHAChange, t]
  )

  const columns = useMemo(
    () => [
      {
        title: t('rubric'),
        field: 'name',
        render: (row) => {
          const costItem = find(costsItems, item => item.id === row?.rubricItemId)
          return costItem?.description
        }
      },
      {
        title: t('date', { howMany: 1 }),
        field: 'realizationDate',
        render: (row) => moment(row?.realizationDate, momentBackDateTimeFormat).format(momentFriendlyDateFormat),
        defaultSort: 'desc',
        sorting: true,
        cellStyle: { width: '10%' }
      },
      {
        title: t('management'),
        field: 'management',
        render: (row) => {
          const management = find(growingSeason?.cropsManagements, item => item.id === row?.cropManagementId)
          return isEmpty(management) ? '-' : `${ management?.operation?.name } - ${ moment(management?.realizationDate || management?.predictedDate, momentBackDateTimeFormat).format(momentFriendlyDateFormat) }`
        }
      },
      {
        title: `${ t('description') } | ${ t('product') }`,
        field: 'description',
        sorting: true
      },
      {
        title: t('total value'),
        field: 'value',
        render: (row) => row?.value ? formatCurrency(row?.value) : '-',
        sorting: true,
        cellStyle: { width: '10%' }
      },{
        title: t('value by ha'),
        field: 'valueByHa',
        render: (row) => row?.value ? formatCurrency(row?.value/area) : '-',
        sorting: true,
        cellStyle: { width: '10%' }
      }
    ],
    [area, costsItems, growingSeason, t]
  )

  const actions = useMemo(
    () => [
      (row) => ({
        position: 'row',
        onClick: () => {},
        iconButtonProps: {},
        tooltip: row?.status === 'pendente' ? t('pending') : '',
        icon: row?.status === 'pendente' ? () => (<Icon size={ 24 } icon={ iconAlert } style={ { width: '24px', backgroundColor: colors.secondary, color: '#fff', borderRadius: '20px' } } />) : () => (<></>)
      })
    ],
    [t]
  )

  const removeCost = useCallback((_, row) => {
    createDialog({
      id: 'confirm-delete',
      Component: ConfirmModal,
      props: {
        onConfirm: () => {
          dispatch(CostsActions.deleteCosts(
            row?.bulkId,
            () => {
              snackbar.success(
                t('your {this} was deleted', {
                  howMany: 1,
                  gender: 'male',
                  this: t('cost', { howMany: 1 })
                })
              )
              getCostsGrowingSeason()
            }
          ))
        },
        textWarning: t('are you sure you want to delete the {this}?', {
          howMany: 1,
          gender: 'male',
          this: t('cost')
        })
      }
    })
  },[createDialog, dispatch, getCostsGrowingSeason, snackbar, t])

  return (
    <Container>
      <Row>
        <ItemGroup>
          <SubTitle>
            {t('crop', { howMany: 1 })}:
          </SubTitle>
          <Description>
            {`${ growingSeason?.crop?.description } / ${ growingSeason?.sowingYear }`}
          </Description>
        </ItemGroup>
        <ItemGroup>
          <SubTitle>
            {t('field', { howMany: 1 })}:
          </SubTitle>
          <Description>
            {`${ growingSeason?.fieldData?.fieldName } ${ !growingSeason?.childrenPolygonId ? `/ ${ round(area, 2) } ha`: '' } ${ growingSeason?.childrenPolygonId ? ` - ${ find(growingSeason?.fieldData?.childrenPolygons, item => item.id === growingSeason?.childrenPolygonId).name } / ${ round(area, 2) } ha` : '' }`}
          </Description>
        </ItemGroup>
      </Row>
      <Title>
        {t('management applied inputs')}
      </Title>
      <DataTable
        tableRef={ fromManagementTableRef }
        columns={ fromManagementColumns }
        data={ orderBy(fromManagementCosts, ['status', 'realizationDate', 'product.shortDescription']) }
        id="field-costs-table"
        emptyMessage={ t('there is no costs registered') }
        hidePagination
        hasPagination={ false }
        actions={ actions }
      />
      {!isEmpty(editedCosts) && (
        <ButtonContainer>
          <Button
            id="cancel-costs"
            variant='outlined'
            onClick={ () => {
              createDialog({
                id: 'confirm-save-rubric',
                Component: ConfirmModal,
                props: {
                  onConfirm: () => {
                    setEditedCosts({})
                  },
                  textWarning: t('are you sure you want to cancel these changes')
                }
              })
            } }
          >
            {t('cancel')}
          </Button>
          <Button
            id="save-costs"
            color="secondary"
            onClick={ saveCosts }
          >
            {t('save')}
          </Button>
        </ButtonContainer>
      )}
      <div style={ { display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between', marginBottom: 10, marginTop: 20 } }>
        <Title>
          {t('other direct cost')}
        </Title>
        <IconButton
          onClick={ () => openAddCostModal({}) }
          style={ { backgroundColor: colors.secondary } }
        >
          <Icon size={ 16 } icon={ plus } color={ colors.black } />
        </IconButton>
      </div>
      <DataTable
        tableRef={ tableRef }
        columns={ columns }
        data={ createdCosts }
        onDeleteClick={ removeCost }
        onEditClick={ (_, row) => openAddCostModal(row) }
        id="field-additional-costs-table"
        emptyMessage={ t('there is no aditional costs registered') }
        hidePagination
        hasPagination={ false }
      />
    </Container>
  )
})

FieldCostsForm.propTypes = {
  growingSeason: PropTypes.object.isRequired,
  year: PropTypes.string.isRequired
}

FieldCostsForm.defaultProps = {
}

export default FieldCostsForm
