import React, { useEffect, useMemo, useCallback, forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'

import Grid from '@material-ui/core/Grid'

import registerAnimalBirthSchema from '@smartcoop/forms/schemas/dairyFarm/registerAnimalBirth.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { selectCurrentInsemination } from '@smartcoop/stores/insemination/selectorInsemination'
import { LotActions } from '@smartcoop/stores/lot'
import { selectLots } from '@smartcoop/stores/lot/selectorLot'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import { momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputUnit from '@smartcoop/web-components/InputUnit'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

import {
  Container,
  ButtonsContainer,
  FormContainer,
  Item
} from './styles'

const RegisterAnimalBirthForm = forwardRef((props, formRef) => {
  const { defaultValues, onSubmit, onCancel, loading, animalId } = props
  const currentAnimal = useSelector(selectCurrentAnimal)
  const propertyId = useSelector(selectCurrentPropertyId)
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const lots = useSelector(selectLots)

  useEffect(() => {
    dispatch(
      LotActions.loadLots(
        { propertyId }
      )
    )
  }, [propertyId, dispatch, t])

  const options = useMemo(() =>  map(filter(lots, lot => lot?.slug === 'em-lactacao'), item => ({ value: item?.id, label: item?.name, item })), [lots])

  const currentInsemination = useSelector(selectCurrentInsemination)

  const defaultOptions = useMemo(
    () => [
      {
        label: 'Assistido',
        value: 'assistido'
      },
      {
        label: 'Normal',
        value: 'normal'
      },
      {
        label: 'Distocico',
        value: 'distocico'
      }
    ],
    []
  )

  const situationOptions = useMemo(
    () => [
      {
        label: 'Vivo',
        value: true
      },
      {
        label: 'Morto',
        value: false
      }
    ],
    []
  )

  const genderOptions = useMemo(
    () => [
      {
        label: 'Macho',
        value: 'macho'
      },
      {
        label: 'Fêmea',
        value: 'femea'
      }
    ],
    []
  )

  const gestationDays = 283
  const expectedDate = useMemo(() => {
    if (moment(currentAnimal?.animalListView?.expectedBirthDate, 'YYYY-MM-DD HH:mm:ss').isValid()) {
      return moment(currentAnimal.animalListView.expectedBirthDate, 'YYYY-MM-DD HH:mm:ss')
    }
    return isEmpty(currentInsemination)
      ? moment()
      : moment(currentInsemination.inseminationDate, 'YYYY-MM-DD')
        .add(gestationDays, 'days')
        .format(momentFriendlyDateFormat)
  }, [currentAnimal, currentInsemination])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data,
        weight: toNumber(data.weight),
        expectedDate: moment(expectedDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        animalId: animalId || currentAnimal?.id
      })
    },
    [animalId, currentAnimal.id, expectedDate, onSubmit]
  )

  useEffect(() => {
    if (isEmpty(lots)) {
      dispatch(LotActions.loadLots({ propertyId }))
    }
  }, [dispatch, lots, propertyId])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerAnimalBirthSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputSelect
                label={ t('type') }
                placeholder={ t('type') }
                name="birthType"
                options={ defaultOptions }
                fullWidth
                defaultValue={ defaultValues.birthType }
              />
            </Item>

            <Item style={ { marginBottom: 15 } }>
              <RadioGroup
                label={ t('situation') }
                placeholder={ t('situation') }
                name="isAlive"
                variant="row"
                options={ situationOptions }
                fullWidth
                defaultValue={ defaultValues.isAlive }
              />
            </Item>
            <Item>
              <InputDate
                label={ t('expected date') }
                placeholder={ t('expected date') }
                name="expectedDate"
                options={ defaultOptions }
                fullWidth
                defaultValue={ expectedDate }
                disabled
              />
            </Item>
            <Item>
              <InputDate
                label={ t('occurrence date') }
                name="occurrenceDate"
                fullWidth
                defaultValue={ defaultValues.occurrenceDate }
              />
            </Item>
            <Item>
              <InputSelect
                label={ t('lot', { howMany: 1 }) }
                placeholder={ t('lot', { howMany: 1 }) }
                name="lotId"
                options={ options }
                fullWidth
                defaultValue={ defaultValues?.lotId || find(options, item => item?.item?.defaultLot)?.value }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('weight') }
                placeholder={ t('weight') }
                name="weight"
                options={ defaultOptions }
                fullWidth
                defaultValue={ defaultValues.weight }
                unit="Kg"
              />
            </Item>
            <Item>
              <RadioGroup
                label={ t('gender') }
                placeholder={ t('gender') }
                name="gender"
                options={ genderOptions }
                fullWidth
                variant="row"
                defaultValue={ defaultValues.gender }
              />
            </Item>
          </Grid>
        </FormContainer>
        <ButtonsContainer>
          <Button
            id="web-cancel-form-button"
            onClick={ onCancel }
            color={ colors.white }
            disabled={ loading }
            fullWidth
            style={ { marginRight: 5 } }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="web-save-form-button"
            onClick={ () => formRef.current.submit() }
            disabled={ loading }
            fullWidth
            style={ { marginLeft: 5 } }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  )
})

RegisterAnimalBirthForm.propTypes = {
  loading: PropTypes.bool,
  animalId: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  defaultValues: PropTypes.object.isRequired
}

RegisterAnimalBirthForm.defaultProps = {
  loading: false,
  animalId: null,
  onSubmit: () => {},
  onCancel: () => {}
}

export default RegisterAnimalBirthForm
