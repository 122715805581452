import styled, { css } from 'styled-components'

import CircularProgress from '@material-ui/core/CircularProgress'

import { colors } from '@smartcoop/styles'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  background-color: ${ colors.white };
  padding: 15px;
  row-gap: 10px;
`

export const Title = styled.span`
  width: 100%;
  font-size: 1rem;
  color: ${ colors.darkGrey };
  font-weight: 600;
`

export const AddContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
`

export const CustomInput = styled.input`
  width: 100%;
  padding: 0px 10px;
  border-radius: 10px;
  border: 1px solid ${ colors.gray };
  font-family: 'Open Sans';
`

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const GroupTitle = styled.span`
  width: 100%;
  font-size: .85rem;
  color: ${ colors.darkGrey };
  font-weight: 600;
`

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const GroupDropdown = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  column-gap: 5px;
  justify-content: center;
  padding-top: 10px;

  > div {
    transition: transform .3s;
    ${ ({ opened }) => opened ? css`transform: rotate(90deg);` : '' }
  }
`

export const ButtonLoader = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background-color: ${ colors.yellow };
  border-radius: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
`

export const CustomLoader = styled(CircularProgress)`
  color: ${ colors.white };
`
