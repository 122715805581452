import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '@smartcoop/stores/authentication'

export const AVAILABLE_MODULES = {
  digitalProperty: 'digital-property',
  shoppingPlatform: 'purchasing-platform',
  commercialization: 'commercialization',
  technical: 'technician-area',
  administration: 'administration'
}

// Initial state
const INITIAL_STATE = {
  currentModule: null,
  oldModuleData: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  initModuleBySlug: ['slug'],
  initDigitalPropertyModule: [],
  initShoppingPlatformModule: [],
  initCommercializationModule: [],
  initTechnicalModule: [],
  initAdministrationModule: [],
  exitCurrentModule: [],
  setOldModuleData: ['oldModuleData']
})


/**
 * Reducers functions
 */

const initDigitalPropertyModule = (state = INITIAL_STATE) => ({
  ...state,
  currentModule: AVAILABLE_MODULES.digitalProperty
})

const setOldModuleData = (state = INITIAL_STATE, { oldModuleData = null }) => ({
  ...state,
  oldModuleData
})

const initShoppingPlatformModule = (state = INITIAL_STATE) => ({
  ...state,
  currentModule: AVAILABLE_MODULES.shoppingPlatform
})

const initCommercializationModule = (state = INITIAL_STATE) => ({
  ...state,
  currentModule: AVAILABLE_MODULES.commercialization
})

const initTechnicalModule = (state = INITIAL_STATE) => ({
  ...state,
  currentModule: AVAILABLE_MODULES.technical
})

const initAdministrationModule = (state = INITIAL_STATE) => ({
  ...state,
  currentModule: AVAILABLE_MODULES.administration
})

const exitCurrentModule = (state = INITIAL_STATE) => ({
  ...state,
  currentModule: INITIAL_STATE.currentModule
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.INIT_DIGITAL_PROPERTY_MODULE]: initDigitalPropertyModule,
  [Types.INIT_SHOPPING_PLATFORM_MODULE]: initShoppingPlatformModule,
  [Types.INIT_COMMERCIALIZATION_MODULE]: initCommercializationModule,
  [Types.INIT_TECHNICAL_MODULE]: initTechnicalModule,
  [Types.INIT_ADMINISTRATION_MODULE]: initAdministrationModule,
  [Types.EXIT_CURRENT_MODULE]: exitCurrentModule,
  [Types.SET_OLD_MODULE_DATA]: setOldModuleData,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as ModuleTypes,
  Creators as ModuleActions
}
