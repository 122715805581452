import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

import SelectContacts from '../../containers/SelectContacts/SelectContacts'


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 25px;
`

export const ModalTitle = styled.h2`
  margin: 0;
  padding: 0;
  text-align: center;
`

export const ModalFooter = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  column-gap: 10px;
`

export const CustomInput = styled.input`
  width: 100%;
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid ${ colors.gray };
  font-family: 'Open Sans';
  margin-top: ${ ({ marginTop }) => marginTop || 0 }px;
`

export const CustomSelectContacts = styled(SelectContacts)`
  overflow-y: auto;
  max-height: calc(70vh - 250px);
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  > div {
    zoom: 1.5;
  }
`
