import React, {
  forwardRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useImperativeHandle
} from 'react'

import PropTypes from 'prop-types'
import uuid from 'short-uuid'

import { FieldProvider, useField } from '@smartcoop/forms'

import FilteredCheckboxGroupStyled from './FilteredCheckboxGroupStyled'

const FilteredCheckboxGroupForm = forwardRef((props, externalRef) => {
  const {
    defaultValue,
    zIndex,
    ...rest
  } = props

  const [value, setValue] = useState(defaultValue)
  const [mounted, setMounted] = useState(false)

  const {
    fieldName,
    error,
    fieldRef,
    handleChangeNative,
    handleBlur,
    required,
    resetField,
    externalOnChange,
    validateField
  } = useField()

  const id = useMemo(
    () => `${ fieldName }-${ uuid().new() }`,
    [fieldName]
  )

  const handleChange = useCallback(
    (newValue) => setValue(newValue),
    []
  )

  useEffect(() => {
    if (mounted) {
      handleChangeNative(value)
    } else {
      setMounted(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const imperativeHandles = useMemo(
    () => ({
      value,
      defaultValue,
      setValue,
      resetField,
      externalOnChange,
      validateField
    }),
    [defaultValue, externalOnChange, resetField, validateField, value]
  )

  useImperativeHandle(fieldRef, () => ({
    ...fieldRef.current,
    ...imperativeHandles
  }))

  useImperativeHandle(externalRef, () => ({
    ...fieldRef.current,
    ...imperativeHandles
  }))

  // TODO disabled input style
  return (
    <FilteredCheckboxGroupStyled
      { ...rest }
      ref={ fieldRef }
      id={ id }
      value={ value }
      onChange={ handleChange }
      onBlur={ handleBlur }
      error={ error }
      required={ required }
    />
  )
})

FilteredCheckboxGroupForm.propTypes = {
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  zIndex: PropTypes.number
}

FilteredCheckboxGroupForm.defaultProps = {
  defaultValue: [],
  zIndex: undefined
}

const Field = forwardRef(({ path, ...props }, ref) => (
  <FieldProvider
    ref={ ref }
    { ...props }
    registerFieldOptions={ { path } }
    FieldComponent={ FilteredCheckboxGroupForm }
  />
))

Field.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  path: PropTypes.string,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  helperText: PropTypes.string
}

Field.defaultProps = {
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
  path: 'value',
  defaultValue: [],
  helperText: null
}

export default Field
