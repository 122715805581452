import React from 'react'

import PropTypes from 'prop-types'

import InputUnit from '@smartcoop/web-components/InputUnit'

const InputPercentage = (props) => {
  const { maxValue, maxLength, ...rest } = props

  return (
    <InputUnit
      maxLength={ maxLength }
      max={ maxValue }
      unit="%"
      { ...rest }
    />
  )
}

InputPercentage.propTypes = {
  maxValue: PropTypes.number,
  maxLength: PropTypes.number
}

InputPercentage.defaultProps = {
  maxValue: '100',
  maxLength: 3
}

export default InputPercentage
