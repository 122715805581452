import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const technicalVisitReport = ({ t }) => Yup.object().shape({
  period: flow(
    required({ t })
  )(Yup.string()),
  producerId: flow(
    required({ t })
  )(Yup.string()),
  propertyIds: flow(
    required({ t })
  )(Yup.string()),
  fieldId: flow(
  )(Yup.string())
})

export default technicalVisitReport
