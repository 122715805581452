import * as Yup from 'yup'

import flow from 'lodash/fp/flow'
import isNull from 'lodash/isNull'

import float from '@smartcoop/forms/validators/float.validator'
import required from '@smartcoop/forms/validators/required.validator'


const seedTreatmentCropManagementSchema = ({ t, props }) =>
  Yup.object().shape({
    formFields: Yup.array()
      .of(
        Yup.object().shape({
          productGroup: props?.fromSeeding ? Yup.string().nullable() : flow(
            required({ t })
          )(Yup.string().nullable()),

          products: Yup.string().when('productGroup', {
            is: value => !(value === '0' || value === '0.00' || value === '') && !isNull(value),
            then: flow(required({ t }))(Yup.string()),
            otherwise: Yup.string().nullable()
          }),

          valueKgSemHA: Yup.string().nullable(),
          value: Yup.string().nullable(),
          valueHA: Yup.string().nullable(),
          valueDose: Yup.string().nullable(),

          treatmentMode: Yup.string().when('productGroup', {
            is: value => !(value === '0' || value === '0.00' || value === '') && !isNull(value),
            then: flow(required({ t }))(Yup.string()),
            otherwise: Yup.string().nullable()
          }),

          dosePhyntosanitary: flow(
            float({ t })
          )(Yup.string().nullable()),

          unit: Yup.string().when('dosePhyntosanitary', {
            is: value => !(value === '0' || value === '0.00' || value === '') && !isNull(value),
            then: flow(required({ t }))(Yup.string()),
            otherwise: Yup.string().nullable()
          })
        })
      )
  })

export default seedTreatmentCropManagementSchema
