import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
`

export const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  z-index: 1;
`

export const MapHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

export const MapFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
`
