import React from 'react'

import PropTypes from 'prop-types'

import map from 'lodash/map'


import I18n from '@smartcoop/i18n'
import {
  blocked
} from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'

import { Container, Title, Label, List, Item } from './styles'

const ListFragment = ({ title, icon, list, listKey, listId, openRemoveAccessModal }) => (
  <Container>
    <Title>{title}</Title>
    <List>
      {
        map(list, (item) => (
          <Item key={ item[listId] }>
            {icon && <Icon icon={ icon } />}
            <div style={ { display: 'flex', flex: 2, flexDirection: 'column' } }>
              <Label>{item[listKey]}</Label>
              <Label style={ { marginLeft: 20, fontSize: 12 } }>{item?.customName}</Label>
            </div>
            <Button
              id="remove-access"
              color={ colors.white }
              onClick={ () => openRemoveAccessModal(item) }
              style={ { display: 'flex', flex: 1 } }
            >
              <>
                <Icon icon={ blocked } size={ 17 } style={ { marginRight: 5 } } color={ colors.text } />
                <I18n>remove access</I18n>
              </>
            </Button>
          </Item>
        ))
      }
    </List>
  </Container>
)

ListFragment.propTypes = {
  icon: PropTypes.any,
  list: PropTypes.array.isRequired,
  listKey: PropTypes.string.isRequired,
  listId: PropTypes.string.isRequired,
  openRemoveAccessModal: PropTypes.func.isRequired,
  title: PropTypes.string
}

ListFragment.defaultProps = {
  icon: null,
  title: ''
}


export default ListFragment
