import * as Yup from 'yup'

const filterCattleManagement = () => Yup.object().shape({
  lotId: Yup.string(),
  predominantBreedId: Yup.string(),
  statusId: Yup.string(),
  birthDate: Yup.string(),
  nextActionSlug: Yup.string()
})

export default filterCattleManagement
