import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import email from '@smartcoop/forms/validators/email.validator'
import phone from '@smartcoop/forms/validators/phone.validator'
import required from '@smartcoop/forms/validators/required.validator'

const profileEdit = ({ t }) => Yup.object().shape({

  name: flow(
    required({ t })
  )(Yup.string()),

  chatNickname: Yup.string(),

  dateOfBirth: flow(
    required({ t }),
    date({ t, isBirth: true })
  )(Yup.string()),

  email: flow(
    email({ t })
  )(Yup.string()),

  cellPhone: flow(
    phone({ t })
  )(Yup.string())
})

export default profileEdit
