import api from '../api'

export const getCosts = (params, accessToken) =>
  api.get('/v1/costs-management/costs', {
    params,
    headers: accessToken ? {
      'authorization': accessToken
    } : {}
  })

export const getCostsByProperty = (params) =>
  api.get(`/v1/costs-management/property/${ params?.propertyId }`, { params })

export const getFieldCostsByProperty = (params) =>
  api.get(`/v1/costs-management/costs/property/${ params?.propertyId }/fields`, { params })

export const getItems = (params) =>
  api.get('/v1/costs-management/items', { params })

export const saveCosts = async (params) => api.post('/v1/costs-management/costs', params)

export const editCosts = async (params, { costId }) => api.patch(`/v1/costs-management/costs/${ costId }`, params)

export const editCostsByBulk = async (params) => api.patch('/v1/costs-management/costs/bulk-update', params)

export const deleteCosts = async (costId) => api.delete(`/v1/costs-management/costs/${ costId }`)

export const getOfflineCosts = () => api.get('/v1/costs-management')
