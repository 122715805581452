import styled from 'styled-components'

export const ContainerField = styled.div`
  display: flex;
  align-items: center;
  overflow: visible;
`

export const TextGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: visible;
`

export const ItemBorderLeft = styled.div`
  width: 1px;
  background: #000000;
  height: ${ ({ isLast }) => isLast ? '50%' : '220%' };
  overflow: visible;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  overflow: visible;
`

export const Line = styled.div`
  width: 50px;
  height: 1px;
  background: #000;
  align-self: center;
  margin-right: 10px;
  overflow: visible;
`

export const Badge = styled.div`
  background-color: rgba${ props => props.backgroundColorBadge };
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
  & > span {
    font-size: 12px;
    font-weight: 600;
    font-family: "Open Sans";
    color: rgb${ props => props.colorBadge };
  }
  overflow: visible;
`

export const TextLeft = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: visible;
`
export const TextRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`
export const TextBold = styled.span`
  margin: 0;
  font-weight: 600;
`

export const Text = styled.span`
  margin: 0;
`

export const DataTextGroup = styled.div`
  display: flex;
  overflow: visible;
  flex-direction: column;
`

export const CropText = styled.span`
  margin: 0 5px 0 0;
`

export const TemperatureText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
