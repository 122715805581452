import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'


/* Initial State */
const INITIAL_STATE = {
  data: {},
  error: null,
  nfeTablesData: [],
  CFOP: [],
  producer: {}
}

/**
 * Creating actions and types with reduxsauce.
 */
export const { Types, Creators } = createActions({
  integrateNfe: ['params', 'onSuccess', 'onError'],
  loadProducer: ['params', 'onSuccess', 'onError'],
  loadProducerSuccess: ['producer'],
  loadNfeTablesData: ['params', 'onSuccess', 'onError'],
  loadNfeTablesDataSuccess: ['nfeTablesData'],
  loadCFOP: ['params', 'onSuccess', 'onError'],
  loadCFOPSuccess: ['CFOP'],
  integrateNfeSuccess: ['data'],
  integrationControl: ['params', 'onSuccess', 'onError'],
  saveFiscalFigure: ['params', 'onSuccess', 'onError'],
  deleteFiscalFigure: ['fiscalFigureId', 'onSuccess', 'onError'],
  nfeError: ['error'],
  resetNfeData: []
})


/**
 * Reducers functions
 */
const integrateNfeSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  error: INITIAL_STATE.error,
  data
})

const loadProducerSuccess = (state = INITIAL_STATE, { producer }) => ({
  ...state,
  error: INITIAL_STATE.error,
  producer
})

const loadNfeTablesDataSuccess = (state = INITIAL_STATE, { nfeTablesData }) => ({
  ...state,
  error: INITIAL_STATE.error,
  nfeTablesData
})

const loadCFOPSuccess = (state = INITIAL_STATE, { CFOP }) => ({
  ...state,
  error: INITIAL_STATE.error,
  CFOP
})

const resetNfeData = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error,
  data: INITIAL_STATE.data
})

const nfeError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

const resetAuthentication = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.INTEGRATE_NFE_SUCCESS]: integrateNfeSuccess,
  [Types.LOAD_PRODUCER_SUCCESS]: loadProducerSuccess,
  [Types.LOAD_NFE_TABLES_DATA_SUCCESS]: loadNfeTablesDataSuccess,
  [Types.LOAD_CFOP_SUCCESS]: loadCFOPSuccess,
  [Types.NFE_ERROR]: nfeError,
  [Types.RESET_NFE_DATA]: resetNfeData,

  [AuthenticationTypes.FIREBASE_LOGOUT_SUCCESS]: logout,
  [AuthenticationTypes.RESET_AUTHENTICATION]: resetAuthentication
})

export {
  Types as NfeTypes,
  Creators as NfeActions
}
