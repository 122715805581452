import React, { useCallback, forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import includes from 'lodash/includes'

import filterCattleManagementSchema from '@smartcoop/forms/schemas/dairyFarm/filterCattleManagement.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getAnimalBreeds as getAnimalBreedsService } from '@smartcoop/services/apis/smartcoopApi/resources/animalBreed'
import { getAnimalStatus as getAnimalStatusService } from '@smartcoop/services/apis/smartcoopApi/resources/animalStatus'
import { getAnimalsNextActionsTypes } from '@smartcoop/services/apis/smartcoopApi/resources/herdsManagement'
import { getLots as getLotsService } from '@smartcoop/services/apis/smartcoopApi/resources/lot'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { momentBackDateTimeFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Form from '@smartcoop/web-components/Form'
import InputDateRange from '@smartcoop/web-components/InputDateRange'
import InputSelect from '@smartcoop/web-components/InputSelect'

import { Container, ButtonContainer } from './styles'

const FilterCattleManagementForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()
  const { id: propertyId } = useSelector(selectCurrentProperty)
  const [checkValues, setCheckValues] = useState([])
  const handleSubmit = useCallback(
    (data) => onSubmit({
      ...data,
      startBirthDate: data.birthDate.from,
      endBirthDate: data.birthDate.to,
      birthDate: undefined,
      dead: includes(checkValues, 'dead')
    }),
    [checkValues, onSubmit]
  )

  const handleChangeCheckBox = useCallback((value) => {
    if(includes(checkValues, value)) {
      setCheckValues(filter(checkValues, (item) => item !== value))
    } else {
      setCheckValues([...checkValues, value])
    }
  }, [checkValues])

  useEffect(() => {
    if(filters.dead) {
      setCheckValues([filters.dead ? 'dead' : ''])
    }
  }, [filters])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterCattleManagementSchema }
        onSubmit={ handleSubmit }
      >

        <InputDateRange
          label={ t('date of birth') }
          name="birthDate"
          defaultValue={
            filters.startBirthDate ?
              {
                from: moment(filters.startBirthDate, momentBackDateTimeFormat), to: moment(filters.endBirthDate, momentBackDateTimeFormat)
              } : {
                from: '', to: ''
              }
          }
          fullWidth
        />

        <InputSelect
          label={ t('predominant race') }
          name="predominantBreedId"
          options={ getAnimalBreedsService }
          urlParams={ { propertyId } }
          defaultValue={ filters?.predominantBreedId }
        />

        <InputSelect
          label={ t('status') }
          name="statusId"
          options={ getAnimalStatusService }
          defaultValue={ filters?.statusId }
        />

        <InputSelect
          label={ t('name of lot') }
          name="lotId"
          options={ getLotsService }
          urlParams={ { propertyId } }
          asyncOptionLabelField="name"
          defaultValue={ filters?.lotId }
        />

        <InputSelect
          label={ t('next action') }
          name="nextActionSlug"
          options={ getAnimalsNextActionsTypes }
          urlParams={ { propertyId } }
          asyncOptionLabelField="name"
          asyncOptionValueField="slug"
          defaultValue={ filters?.nextActionSlug }
          multiple
        />

        <CheckboxButton
          label={ t('display animals already slaughtered') }
          onChange={ () => handleChangeCheckBox('dead') }
          checked={ includes(checkValues, 'dead') }
          value={ includes(checkValues, 'dead') }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="lot-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterCattleManagementForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterCattleManagementForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterCattleManagementForm
