import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  currentSection: 'dairyFarm',
  dairyFarmMonth: null,
  milkDeliveries: [],
  currentMilkDelivery: {},
  milkQualities: [],
  priceData: [],
  currentPriceData: {},
  monthlyDataHistory: [],
  dashboardData: {
    prices: [],
    milkDeliveries: [],
    milkQualities: []
  },
  error: null
}


/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadDashboardData: ['params', 'onSuccess', 'onError'],
  loadDashboardDataSuccess: ['params', 'onSuccess', 'onError'],

  loadMilkDeliveries: ['params', 'onSuccess', 'onError'],
  loadMilkDeliveriesSuccess: ['milkDeliveries', 'page', 'onSuccess'],

  saveMilkDelivery: ['params', 'onSuccess', 'onError'],
  deleteMilkDelivery: ['milkDeliveryId', 'onSuccess', 'onError'],

  loadMilkQualities: ['params', 'onSuccess', 'onError'],
  loadMilkQualitiesSuccess: ['milkQualities', 'page', 'onSuccess'],

  saveMilkQuality: ['params', 'onSuccess', 'onError'],
  deleteMilkQuality: ['milkQualityId', 'onSuccess', 'onError'],

  setCurrentSection: ['currentSection'],
  resetCurrentSection: [],

  setCurrentMilkDelivery: ['currentMilkDelivery'],
  resetCurrentMilkDelivery: [],

  setDairyFarmMonth: ['dairyFarmMonth'],
  resetDairyFarmMonth: [],

  loadPriceData: ['params', 'onSuccess', 'onError'],
  loadPriceDataSuccess: ['priceData', 'page', 'onSuccess'],

  saveTamboType: ['params', 'onSuccess', 'onError'],
  loadTambo: ['onSuccess', 'onError'],

  savePriceData: ['params', 'onSuccess', 'onError'],
  deletePriceData: ['priceDataId', 'onSuccess', 'onError'],

  saveMonthlyDataHistory: ['params', 'onSuccess', 'onError'],
  deleteMonthlyDataHistory: ['id', 'onSuccess', 'onError'],
  loadMonthlyDataHistory: ['params', 'onSuccess', 'onError'],
  loadMonthlyDataHistorySuccess: ['monthlyDataHistory', 'page', 'onSuccess'],

  setCurrentPriceData: ['currentPriceData'],
  resetCurrentPriceData: [],

  loadPriceComposition: ['params', 'onSuccess', 'onError'],

  dairyFarmError: ['error']
})

markActionsOffline(Creators, [
  'saveMilkDelivery',
  'saveMilkQuality',
  'deleteMilkDelivery',
  'deleteMilkQuality',
  'saveTamboType',
  'loadTambo',
  'savePriceData',
  'deletePriceData'
])

/**
 * Reducers functions
 */

const setCurrentSection = (state = INITIAL_STATE, { currentSection }) => ({
  ...state,
  currentSection
})

const setDairyFarmMonth = (state = INITIAL_STATE, { dairyFarmMonth }) => ({
  ...state,
  dairyFarmMonth
})

const resetDairyFarmMonth = (state = INITIAL_STATE) => ({
  ...state,
  dairyFarmMonth: INITIAL_STATE.dairyFarmMonth
})

const resetCurrentSection = (state = INITIAL_STATE) => ({
  ...state,
  currentSection: INITIAL_STATE.currentSection
})

const loadMilkDeliveriesSuccess = (state = INITIAL_STATE, { milkDeliveries, page }) => (
  {
    ...state,
    error: INITIAL_STATE.error,
    milkDeliveries: page === 1 ? milkDeliveries : [
      ...state.milkDeliveries,
      ...milkDeliveries
    ]

  }
)
const loadMilkQualitiesSuccess = (state = INITIAL_STATE, { milkQualities, page }) => (
  {
    ...state,
    error: INITIAL_STATE.error,
    milkQualities: page === 1 ? milkQualities : [
      ...state.milkQualities,
      ...milkQualities
    ]

  }
)

const dairyFarmError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})


const setCurrentMilkDelivery = (state = INITIAL_STATE, { currentMilkDelivery }) => ({
  ...state,
  currentMilkDelivery
})

const resetCurrentMilkDelivery = (state = INITIAL_STATE) => ({
  ...state,
  currentMilkDelivery: INITIAL_STATE.currentMilkDelivery
})

const loadPriceDataSuccess = (state = INITIAL_STATE, { priceData, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  priceData: page === 1 ? priceData : [
    ...state.priceData,
    ...priceData
  ]
})

const loadMonthlyDataHistorySuccess = (state = INITIAL_STATE, { monthlyDataHistory, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  monthlyDataHistory: page === 1 ? monthlyDataHistory : [
    ...state.monthlyDataHistory,
    ...monthlyDataHistory
  ]
})

const loadDashboardDataSuccess = (state = INITIAL_STATE, { params }) => ({
  ...state,
  error: INITIAL_STATE.error,
  dashboardData: { ...params }
})

const setCurrentPriceData = (state = INITIAL_STATE, { currentPriceData }) => ({
  ...state,
  currentPriceData
})

const resetCurrentPriceData = (state = INITIAL_STATE) => ({
  ...state,
  currentPriceData: INITIAL_STATE.currentPriceData
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_MILK_DELIVERIES_SUCCESS]: loadMilkDeliveriesSuccess,
  [Types.LOAD_MILK_QUALITIES_SUCCESS]: loadMilkQualitiesSuccess,

  [Types.SET_CURRENT_SECTION]: setCurrentSection,
  [Types.RESET_CURRENT_SECTION]: resetCurrentSection,

  [Types.DAIRY_FARM_ERROR]: dairyFarmError,

  [Types.SET_CURRENT_MILK_DELIVERY]: setCurrentMilkDelivery,
  [Types.RESET_CURRENT_MILK_DELIVERY]: resetCurrentMilkDelivery,
  [Types.SET_DAIRY_FARM_MONTH]: setDairyFarmMonth,
  [Types.RESET_DAIRY_FARM_MONTH]: resetDairyFarmMonth,

  [Types.LOAD_PRICE_DATA_SUCCESS]: loadPriceDataSuccess,

  [Types.LOAD_DASHBOARD_DATA_SUCCESS]: loadDashboardDataSuccess,

  [Types.LOAD_MONTHLY_DATA_HISTORY_SUCCESS]: loadMonthlyDataHistorySuccess,

  [Types.SET_CURRENT_PRICE_DATA]: setCurrentPriceData,
  [Types.RESET_CURRENT_PRICE_DATA]: resetCurrentPriceData,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as DairyFarmTypes,
  Creators as DairyFarmActions
}
