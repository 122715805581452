import React from 'react'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import { useT } from '@smartcoop/i18n'
import { formatCurrency } from '@smartcoop/utils/formatters'

import { Container, Payment, PaymentType, Amount } from './styles'

const PaymentForms = ({ paymentForms }) => {
  const t = useT()
  return (
    <Container>
      {map(paymentForms, ({ deferredPayment, id, daysAmount, value }) => (
        <Payment key={ id }>
          <PaymentType>{deferredPayment ? `${ t('deferred')  } ${  daysAmount  } ${ t('day', { howMany: 2 }) }` : t('in cash') }: </PaymentType>
          <span> </span>
          <Amount>{ `  ${  formatCurrency(value) }` }</Amount>
        </Payment>
      ))}
    </Container>
  )
}

PaymentForms.propTypes = {
  paymentForms: PropTypes.array.isRequired
}

export default PaymentForms
