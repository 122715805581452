import React, { useMemo, useState, useCallback } from 'react'

import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n from '@smartcoop/i18n'
import { filter as filterIcon } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import PropertyTechnicalVisitProducerList from '@smartcoop/web-containers/fragments/PropertyTechnicalVisitProducerList'
import TechnicalEvaluationList from '@smartcoop/web-containers/fragments/technical/TechnicalEvaluationList/TechnicalEvaluationList'
import FilterTechnicalVisitProducerModal from '@smartcoop/web-containers/modals/DigitalProperty/FilterTechnicalVisitProducerModal'



import {
  Container,
  Row,
  TabButton
} from './styles'


const TechnicalAreaScreen = () => {
  const { createDialog } = useDialog()

  const [listType, setListType] = useState('technical-visits')
  const [filters, setFilters] = useState({})

  const dataTableList = useMemo(
    () => ({
      'technical-visits': <PropertyTechnicalVisitProducerList filters={ filters }/>,
      'technical-evaluation': <TechnicalEvaluationList />
    }[listType]),
    [filters, listType]
  )

  const filterData = useCallback(
    () => {
      createDialog({
        id: 'filter-technical-visit',
        Component: FilterTechnicalVisitProducerModal,
        props: {
          onSubmit: setFilters,
          filters
        }
      })
    },
    [createDialog, filters]
  )

  return (
    <Container>
      <Row>
        <div>
          <TabButton
            id="technical-visits"
            onClick={ () => setListType('technical-visits') }
            color={ listType === 'technical-visits' ? colors.black : colors.white }
          >
            <I18n>technical visit</I18n>
          </TabButton>
          <TabButton
            id="technical-evaluation"
            onClick={ () => setListType('technical-evaluation') }
            color={ listType === 'technical-evaluation' ? colors.black : colors.white }
          >
            <I18n>technical evaluation</I18n>
          </TabButton>
        </div>
        {listType === 'technical-visits' && (
          <Button
            id="filter"
            onClick={ filterData }
            color={ isEmpty(filters) ? 'white' : 'secondary' }
            style={ { justifySelf: 'flex-end', flex: 'none', whiteSpace: 'nowrap', marginRight: 12 } }
          >
            <>
              <Icon style={ { paddingRight: 6 } } icon={ filterIcon } size={ 14 }/>
              <I18n>filtrate</I18n>
            </>
          </Button>
        )}
      </Row>
      {dataTableList}
    </Container>
  )
}

export default TechnicalAreaScreen
