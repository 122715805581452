/* eslint-disable no-unused-expressions */
import React, { useCallback, forwardRef, useState, useMemo, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import SignaturePad from 'react-signature-canvas'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import join from 'lodash/join'
import map from 'lodash/map'
import size from 'lodash/size'
import split from 'lodash/split'
import trim from 'lodash/trim'
import zipObject from 'lodash/zipObject'

import technicalEvaluationSchema from '@smartcoop/forms/schemas/property/technicalEvaluation.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { trash } from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { getCrops } from '@smartcoop/services/apis/smartcoopApi/resources/crop'
import { getLimestoneIndices } from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import { TechnicalActions } from '@smartcoop/stores/technical'
import { FORM_INPUT_TYPES, RECOMMENDED_ANSWERS } from '@smartcoop/utils/constants'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import { calculatedField } from '@smartcoop/utils/technicalEvaluationFunctions'
import Button from '@smartcoop/web-components/Button'
import CheckboxGroup from '@smartcoop/web-components/CheckboxGroup'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import InputDate from '@smartcoop/web-components/InputDate'
import InputFile from '@smartcoop/web-components/InputFile'
import InputHour from '@smartcoop/web-components/InputHour'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import ThumbnailImage from '@smartcoop/web-components/ThumbnailImage'
import useFile from '@smartcoop/web-containers/hooks/useFile'
import { Title } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container, GroupContainer, QuestionContainer, SubtitleLabel, TextLabel, RequiredSymbol, Badge } from './styles'

const TechnicalEvaluationForm = forwardRef((props, formRef) => {
  const { defaultValues, quizAnswerId, style, currentForm, producer, onSuccess, setBlockedSave } = props

  const t = useT()
  const dispatch = useDispatch()

  // eslint-disable-next-line no-unused-vars
  const canvasRef = zipObject(map(filter(flatMap(currentForm?.questionGroup, group => group?.questions), question => question?.type === FORM_INPUT_TYPES.signature), item => item?.id), map(filter(flatMap(currentForm?.questionGroup, group => group?.questions), question => question?.type === FORM_INPUT_TYPES.signature), x => useRef(null)))

  const {
    handleAddTitledDocument,
    selectedFiles,
    handleRemove
  } = useFile([], [])

  const [updatedData, setUpdatedData] = useState(!isEmpty(defaultValues) ? map(defaultValues, (item,index) => ({ value: item, id: index })) : [])
  const [recommendations, setRecommendations] = useState([])
  const [indicesCalcario, setIndicesCalcario] = useState([])
  const [crops, setCrops] = useState([])
  const [defaultImages, setDefaultImages] = useState({})

  const calculatedFields = useMemo(() => {
    const questions = filter(flatMap(currentForm?.questionGroup, group => group.questions), question => question.type === FORM_INPUT_TYPES.calculated)

    return map(questions, item => {
      const calcFunc = calculatedField[trim(item?.options)]
      return { name: item?.name, calcFunc, id: item?.id }
    })
  },[currentForm])

  useEffect(() => {
    const getIndices = async () => {
      const { data: response } = await getLimestoneIndices()
      setIndicesCalcario(response)
    }
    getIndices()
  }, [])

  useEffect(() => {
    const getCropsOptions = async () => {
      const { data: response } = await getCrops({ limit: 9999 })
      setCrops(map(response?.data, item => ({ label: item?.description, value: item?.description })))
    }
    getCropsOptions()
  }, [])

  const functions = useMemo(() => ({
    '=fnCulturas': crops
  }),[crops])

  useEffect(() => {
    map(calculatedFields, async ({ calcFunc, id }) => {
      if(isFunction(calcFunc)){
        const data = formRef?.current?.getData()
        const calculatedQuestions = filter(flatMap(currentForm?.questionGroup, group => group.questions), question => question.type === FORM_INPUT_TYPES.calculated)
        const calculatedQuestionsWithData = map(calculatedQuestions, item => ({ name: item?.name, value: data[item?.id] }))
        const calculatedQuestionsIds = map(calculatedQuestions, item => item.name)
        const updatedDataWithoutCalculated = filter([...updatedData], item => !includes(calculatedQuestionsIds, item?.id))
        const value = await calcFunc([...calculatedQuestionsWithData, ...updatedDataWithoutCalculated], indicesCalcario)
        formRef?.current?.setFieldValue(id, value)
      }
    }
    )
  },[calculatedFields, currentForm, formRef, indicesCalcario, updatedData])

  const checkCanSave = useCallback(() => {
    const requiredSignatureIds = map(filter(flatMap(currentForm?.questionGroup, group => group.questions), question => question.type === FORM_INPUT_TYPES.signature && question?.require), item => item?.id)

    const found = find(canvasRef, (item, key) => {
      if(includes(requiredSignatureIds, key)) {
        if(item?.current?.isEmpty()) {
          return true
        }
      }
      return false
    })

    if(found) {
      setBlockedSave(true)
    } else {
      setBlockedSave(false)
    }
  }, [canvasRef, currentForm, setBlockedSave])


  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_QUIZ_ID, fileKey: file })

    return downloadFromBase64(data)
  }, [])

  useEffect(() => {
    const getDefaultImages = async () => {
      const imagesQuestions = filter(flatMap(currentForm?.questionGroup, questionGroup => flatMap(questionGroup?.questions, question => question)), question => question?.type === FORM_INPUT_TYPES.image)
      const images = {}
      await Promise.all(map(imagesQuestions, async (item) => {
        if(!isEmpty(defaultValues[item?.id])) {
          const response = await renderFile(defaultValues[item?.id])
          images[item?.id] = response
        }
      }))
      setDefaultImages(images)
    }
    getDefaultImages()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  const renderItem = useCallback((params) => {
    const validation = params?.validation ? JSON.parse(params?.validation) : null

    if(params?.type === FORM_INPUT_TYPES.information ) {
      return (
        <Badge
          backgroundColorBadge="(29, 29, 27, 0.1)"
          colorBadge="(29, 29, 27)"
        >
          {params?.name} {params?.require ? <RequiredSymbol>*</RequiredSymbol> : null}
        </Badge>
      )
    }
    if(params?.type === FORM_INPUT_TYPES.area || params?.type === FORM_INPUT_TYPES.text) {
      return validation?.unit ? (
        <InputUnit
          unit={ validation?.unit }
          maxLength={ validation?.maxLength || null }
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          defaultValue={ isEmpty(defaultValues) ? null : defaultValues[params?.id] }
          onChange={ ({ target: { value } }) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value, id: params?.id }]
            })
          } }
          fullWidth
        />
      ) : (
        <InputText
          maxLength={ validation?.maxLength || null }
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          multiple={ params?.type === FORM_INPUT_TYPES.area }
          defaultValue={ isEmpty(defaultValues) ? null : defaultValues[params?.id] }
          onChange={ ({ target: { value } }) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value, id: params?.id }]
            })
          } }
          fullWidth
        />
      )
    }
    if(params?.type === FORM_INPUT_TYPES.number ) {
      return validation?.unit ? (
        <InputUnit
          unit={ validation?.unit }
          maxLength={ validation?.maxLength || null }
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          defaultValue={ isEmpty(defaultValues) ? null : defaultValues[params?.id] }
          onChange={ ({ target: { value } }) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value, id: params?.id }]
            })
          } }
          fullWidth
        />
      ) : (
        <InputNumber
          showMask
          precision={ validation?.precision || 0 }
          maxLength={ validation?.maxLength || null }
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          defaultValue={ isEmpty(defaultValues) ? null : defaultValues[params?.id] }
          onChange={ ({ target: { value } }) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value, id: params?.id }]
            })
          } }
          fullWidth
        />
      )
    }
    if(params?.type === FORM_INPUT_TYPES.calculated ) {
      return (
        <InputText
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          placeholder={ params?.placeholder }
          defaultValue=""
          disabled
          fullWidth
        />
      )
    }
    if(params?.type === FORM_INPUT_TYPES.select ) {
      return (
        <InputSelect
          withoutConfirmButton
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          options={ includes(params?.options, '=fn') ? functions[params?.options] : map(split(params?.options, ','), item => ({ label: trim(item), value: trim(item) })) }
          defaultValue={ isEmpty(defaultValues) ? null : defaultValues[params?.id] }
          onChange={ ({ target: { value } }) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value, id: params?.id }]
            })
          } }
          fullWidth
        />
      )
    }
    if(params?.type === FORM_INPUT_TYPES.selectAlert ) {
      return (
        <InputSelect
          withoutConfirmButton
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          options={ includes(params?.options, '=fn') ? functions[params?.options] : map(split(params?.options, ','), item => ({ label: trim(item), value: trim(item) })) }
          defaultValue={ isEmpty(defaultValues) ? null : defaultValues[params?.id] }
          onChange={ ({ target: { value } }) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value, id: params?.id }]
            })
          } }
          fullWidth
        />
      )
    }
    if(params?.type === FORM_INPUT_TYPES.selectMultiple ) {
      return (
        <InputSelect
          withoutConfirmButton
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          options={ includes(params?.options, '=fn') ? functions[params?.options] : map(split(params?.options, ','), item => ({ label: trim(item), value: trim(item) })) }
          defaultValue={ isEmpty(defaultValues) ? null : defaultValues[params?.id] }
          multiple
          onChange={ ({ target: { value } }) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value, id: params?.id }]
            })
          } }
          fullWidth
        />
      )
    }
    if(params?.type === FORM_INPUT_TYPES.month ) {
      return (
        <InputDate
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          defaultValue={ isEmpty(defaultValues) ? '' : defaultValues[params?.id] }
          onChange={ (value) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value, id: params?.id }]
            })
          } }
          pickerProps={ {
            maxDate: validation?.maxDate || validation?.notFuture ? moment().format() : null,
            minDate: validation?.minDate || validation?.notPast ? moment().format() : null
          } }
          fullWidth
        />
      )
    }
    if(params?.type === FORM_INPUT_TYPES.year ) {
      return (
        <InputDate
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          defaultValue={ isEmpty(defaultValues) ? '' : defaultValues[params?.id] }
          onChange={ (value) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value, id: params?.id }]
            })
          } }
          pickerProps={ {
            maxDate: validation?.maxDate || validation?.notFuture ? moment().format() : null,
            minDate: validation?.minDate || validation?.notPast ? moment().format() : null
          } }
          fullWidth
        />
      )
    }
    if(params?.type === FORM_INPUT_TYPES.monthYear ) {
      return (
        <InputDate
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          defaultValue={ isEmpty(defaultValues) ? '' : defaultValues[params?.id] }
          onChange={ (value) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value, id: params?.id }]
            })
          } }
          pickerProps={ {
            maxDate: validation?.maxDate || validation?.notFuture ? moment().format() : null,
            minDate: validation?.minDate || validation?.notPast ? moment().format() : null
          } }
          fullWidth
        />
      )
    }
    if(params?.type === FORM_INPUT_TYPES.date ) {
      return (
        <InputDate
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          defaultValue={ isEmpty(defaultValues) ? '' : defaultValues[params?.id] }
          onChange={ (value) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value, id: params?.id }]
            })
          } }
          pickerProps={ {
            maxDate: validation?.maxDate || validation?.notFuture ? moment().format() : null,
            minDate: validation?.minDate || validation?.notPast ? moment().format() : null
          } }
          fullWidth
        />
      )
    }
    if(params?.type === FORM_INPUT_TYPES.hour ) {
      return (
        <InputHour
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          defaultValue={ isEmpty(defaultValues) ? '' : defaultValues[params?.id] }
          onChange={ ({ target: { value } }) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value, id: params?.id }]
            })
          } }
          pickerProps={ {
            maxDate: validation?.maxDate || validation?.notFuture ? moment().format() : null,
            minDate: validation?.minDate || validation?.notPast ? moment().format() : null
          } }
          fullWidth
        />
      )
    }
    if(params?.type === FORM_INPUT_TYPES.radio ) {
      return (
        <RadioGroup
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          options={ map(split(params?.options, ','), item => ({ label: trim(item), value: trim(item) })) }
          defaultValue={ isEmpty(defaultValues) ? null : defaultValues[params?.id] }
          onChange={ ({ target: { value } }) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value, id: params?.id }]
            })
          } }
          fullWidth
        />
      )
    }
    if(params?.type === FORM_INPUT_TYPES.checkbox) {
      return (
        <CheckboxGroup
          key={ params?.id }
          name={ params?.id }
          label={ params?.placeholder }
          options={ map(split(params?.options, ','), item => ({ label: trim(item), value: trim(item) })) }
          defaultValue={ isEmpty(defaultValues) ? null : split(defaultValues?.[params?.id], ',') }
          handleChange={ (value) => {
            setUpdatedData(old => {
              const current = filter(old, item => item.id !== params?.id)
              return [...current, { name: params?.name, value: join(value, ','), id: params?.id }]
            })
          } }
          fullWidth
        />
      )
    }
    if(params?.type === FORM_INPUT_TYPES.image ) {
      return (
        <div style={ { marginBottom: 10 } }>
          <InputFile
            idInput={ params?.id }
            key={ params?.id }
            onChange={ (e) => {
              setUpdatedData(old => {
                const current = filter(old, item => item.id !== params?.id)
                return [...current, { name: params?.name, value: '', id: params?.id }]
              })
              handleAddTitledDocument(e, params?.id)
            } }
            maxNumberFile={ 20 - selectedFiles.length }
            maxNumberFileDisplay={ 20 }
            inputProps={ {
              accept: 'image/png,image/jpeg,image/tiff',
              multiple: true
            } }
            buttonProps={ {
              id: 'attach-docs',
              color: 'secondary'
            } }
          >
            <I18n>{defaultImages[params?.id] ? 'replace images' : 'add images'}</I18n>
          </InputFile>
        </div>

      )
    }
    if(params?.type === FORM_INPUT_TYPES.signature ) {
      if(!isEmpty(defaultValues) && canvasRef?.[params?.id]?.current) {
        canvasRef?.[params?.id]?.current?.fromDataURL(defaultValues?.[params?.id])
      }
      return (
        <div style={ { justifyContent: 'center', alignItems: 'flex-start', display: 'flex', flexDirection: 'row' } }>
          <SignaturePad
            ref={ canvasRef[params?.id]  }
            backgroundColor="#ebebeb"
            canvasProps={ { style: { width: 300, height: 150, margin: 10 } } }
            onEnd={ () => {
              const debounced = debounce(() => {
                checkCanSave()
              }, 5)
              debounced()
            } }
          />
          <div style={ { marginTop: 10, width: 100 } }>
            <Button
              key={ `${ params?.id }-clear` }
              textStyle={ { fontSize: 12 } }
              onClick={ () => {
                canvasRef[params?.id]?.current.clear()
                const debounced = debounce(() => {
                  checkCanSave()
                }, 5)
                debounced()
              } }
              icon={ <Icon size={ 12 } icon={ trash } /> }
              variant="outlined"
              style={ { width: 100 } }
            >
              {t('clear')}
            </Button>
          </div>
        </div>
      )
    }
    return null
  },[canvasRef, checkCanSave, defaultImages, defaultValues, functions, handleAddTitledDocument, selectedFiles.length, t])

  const handleSubmit = useCallback(async (data) => {
    const calculatedQuestions = filter(flatMap(currentForm?.questionGroup, group => group.questions), question => question.type === FORM_INPUT_TYPES.calculated)
    const calculatedQuestionsWithData = map(calculatedQuestions, item => ({ name: item?.name, value: data[item?.id], id: item?.id }))
    const calculatedQuestionsIds = map(calculatedQuestions, item => item.id)
    const updatedDataWithoutCalculated = filter([...updatedData], item => !includes(calculatedQuestionsIds, item?.id))
    const signatureFields = filter(flatMap(currentForm?.questionGroup, group => group.questions), question => question.type === FORM_INPUT_TYPES.signature)

    const updatedSignatures = []

    const debounced = debounce(() => {
      dispatch(TechnicalActions.createTechnicalEvaluation(
        currentForm?.id,
        {
          editing: !!quizAnswerId,
          proprietaryId: producer?.id,
          quizId: currentForm?.id,
          quizAnswerId,
          answers: map([...calculatedQuestionsWithData, ...updatedDataWithoutCalculated, ...updatedSignatures], item => ({
            questionId: item?.id,
            answer: item?.value,
            recommendations: map(filter(recommendations, recommendation => recommendation?.questionId === item?.id && recommendation?.checked), recommendation => recommendation?.value) }))
        },
        (answerId) => {
          if(!isEmpty(selectedFiles)) {
            const groupedFiles = groupBy(selectedFiles, 'type')
            map(groupedFiles, (item,key) => {
              const formData = new FormData()
              map(item, each => {
                formData.append('uploads', each?.file)
              })
              dispatch(TechnicalActions.createTechnicalEvaluationFiles(formData, answerId, key, () => onSuccess(answerId)))
            })
            dispatch(TechnicalActions.resetCurrentTechnicalForm())
          } else {
            onSuccess(answerId)
            dispatch(TechnicalActions.resetCurrentTechnicalForm())
          }
        },
        () => setBlockedSave(false)
      ))
    }, 500)

    const func = map(signatureFields, async (item) => {
      updatedSignatures.push({
        id: item?.id,
        value: canvasRef[item?.id].current.getTrimmedCanvas().toDataURL('image/png')
      })
      debounced()
    })

    if(isEmpty(func)) {
      debounced()
    } else {
      Promise.all(func)
    }
  }, [canvasRef, currentForm, dispatch, onSuccess, producer, quizAnswerId, recommendations, selectedFiles, setBlockedSave, updatedData])

  return (
    <Container>
      <Form
        style={ { ...style } }
        ref={ formRef }
        schemaConstructor={ technicalEvaluationSchema }
        schemaProps={ { questions: flatMap(currentForm?.questionGroup, group => map(filter(group.questions, question => !(question?.type === FORM_INPUT_TYPES.signature || question?.type === FORM_INPUT_TYPES.information))), question => ({ ...question, validation: question?.validation ? JSON.parse(question?.validation) : null })) } }
        onSubmit={ handleSubmit }
      >
        <Title>
          {currentForm?.name}
        </Title>
        {map(currentForm?.questionGroup, group => (
          <GroupContainer key={ group?.id }>
            {group?.description !== 'default' ? (
              <SubtitleLabel>
                {group?.description}
              </SubtitleLabel>
            ) : null}

            {map(group?.questions, question => {
              const currentFile = question?.type === FORM_INPUT_TYPES.image ? find(selectedFiles, item => item?.type === question?.id)?.file : null

              return <QuestionContainer key={ `question-container-${ question?.id }` }>
                {(isEmpty(question?.placeholder) && question?.type !== FORM_INPUT_TYPES.information) || (question?.type !== FORM_INPUT_TYPES.checkbox && size(split(question?.options, ',') > 1)) ? (
                  <TextLabel>
                    {question?.name} {question?.require ? <RequiredSymbol>*</RequiredSymbol> : null}
                  </TextLabel>
                ): null}
                {renderItem(question)}
                {question?.type === FORM_INPUT_TYPES.image ? (
                  <ThumbnailImage
                    key={ `image-${ question?.id }` }
                    src={ currentFile ? URL.createObjectURL(currentFile) : defaultImages[question?.id] }
                    size={ 80 }
                    onClose={ currentFile ? () => handleRemove('selectedFiles', find(selectedFiles, item => item?.type === question?.id)) : null }
                  />
                ) : null}
                {!isEmpty(question?.questionsRecommendation) && (includes(RECOMMENDED_ANSWERS, find(updatedData, item => item?.id === question?.id)?.value)) ? (
                  <div>
                    <SubtitleLabel>
                      {t('recommendations')}
                    </SubtitleLabel>
                    {map(question?.questionsRecommendation, recommendation => (
                      <CheckboxButton
                        key={ `recommendations-${ recommendation.id }` }
                        label={ recommendation?.recommendation }
                        onChange={ () => setRecommendations(old => {
                          const found = find(old, item => item.value === recommendation.id)
                          const current = filter(old, item => item.value !== recommendation.id)

                          if(found) {
                            return [...current, { ...found, checked: !found?.checked }]
                          }
                          return [...current, { questionId: question?.id, value: recommendation.id, checked: true }]
                        }) }
                        checked={ find(recommendations, item => item.value === recommendation?.id)?.checked }
                        value={ find(recommendations, item => item.value === recommendation?.id)?.checked }
                        hideColor
                        style={ { width: '100%' } }
                      />
                    ))}
                  </div>
                ) : null}
              </QuestionContainer>
            })}
          </GroupContainer>
        ))}
      </Form>
    </Container>
  )
})

TechnicalEvaluationForm.propTypes = {
  style: PropTypes.object,
  defaultValues: PropTypes.object,
  producer: PropTypes.object,
  onSuccess: PropTypes.func,
  setBlockedSave: PropTypes.func,
  quizAnswerId: PropTypes.string,
  currentForm: PropTypes.object
}

TechnicalEvaluationForm.defaultProps = {
  style: {},
  currentForm: {},
  quizAnswerId: null,
  defaultValues: {},
  onSuccess: () => {},
  setBlockedSave: () => {},
  producer: {}
}

export default TechnicalEvaluationForm
