import React, { useCallback, forwardRef, useMemo } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import isArray from 'lodash/isArray'
import map from 'lodash/map'

import Grid from '@material-ui/core/Grid'

import registerPriceDataSchema from '@smartcoop/forms/schemas/dairyFarm/registerPriceData.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { selectCurrentPropertyDairyFarmIEs } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputMonthYear from '@smartcoop/web-components/InputMonthYear'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'

import {
  Container,
  FormContainer,
  ButtonsContainer,
  Item,
  Label
} from './styles'

const RegisterPriceDataForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel
  } = props

  const t = useT()

  const currentPropertyDairyFarmIEs = useSelector(selectCurrentPropertyDairyFarmIEs)

  const iesOptions = useMemo(
    () => map(currentPropertyDairyFarmIEs, ({ stateRegistration, id }) => ({
      label: stateRegistration,
      value: id
    })),
    [currentPropertyDairyFarmIEs]
  )

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data,
        priceDate: isArray(data.priceDate) ? data.priceDate[0] : data.priceDate
      })
    },
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerPriceDataSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputSelect
                name="userStateRegistrationId"
                label={ t('state registration') }
                defaultValue={ defaultValues?.userStateRegistrationId }
                options={ iesOptions }
              />
            </Item>
            <Item>
              <InputMonthYear
                label={ `${ t('month', { howMany: 1 }) }/${ t('year', { howMany: 1 }) }` }
                name="priceDate"
                fullWidth
                defaultValue={ defaultValues.priceDate }
              />
            </Item>
            <Item>
              <InputUnit
                name="price"
                label={ t('price', { howMany: 1 }) }
                unit="R$"
                type="float"
                fullWidth
                decimalScale={ 4 }
                defaultValue={ defaultValues.price }
              />
            </Item>
            <Item>
              <InputText
                name="companyName"
                label={ t('third company', { howMany: 1 }) }
                fullWidth
                disabled
                detached
                value={ defaultValues.companyName }
              />
            </Item>
            <Item>
              <Label><I18n>to change the commercialization company name, return to the inital panel</I18n></Label>
            </Item>
          </Grid>
        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-cancel-form-button"
              onClick={ onCancel }
              color={ colors.white }
              disabled={ loading }
              style={ { marginRight:10, marginLeft: 10 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="web-save-form-button"
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
              style={ { marginRight:10, marginLeft: 10 } }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

RegisterPriceDataForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  defaultValues: PropTypes.object.isRequired
}

RegisterPriceDataForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false
}

export default RegisterPriceDataForm
