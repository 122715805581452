import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const CircularButton = styled.button`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50px;
  background-color: ${ colors.yellow };
  border: none;
  cursor: pointer;

  :hover {
    filter: brightness(0.95);
  }
`
