import React, { Suspense, memo, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import supportsHistory from '@meta-awesome/functions/src/supportsHistory'

import debounce from 'lodash/debounce'

import { useT } from '@smartcoop/i18n'
import smartcoopApi from '@smartcoop/services/apis/smartcoopApi'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectAuthenticated } from '@smartcoop/stores/authentication/selectorAuthentication'
import CircularLoader from '@smartcoop/web-components/Loader/CircularLoader'
import AuthenticatedScreenRouter from '@smartcoop/web-containers/screens/authenticated/AuthenticatedScreenRouter'
import GuestScreenRouter from '@smartcoop/web-containers/screens/guest/GuestScreenRouter'

const forceRefresh = !supportsHistory()

const ScreenRouter = () => {
  const snackbar = useSnackbar()
  const t = useT()
  const authenticated = useSelector(selectAuthenticated)

  const RouterContext = authenticated
    ? AuthenticatedScreenRouter
    : GuestScreenRouter

  const createSnackbarError = useCallback(debounce(snackbar.error, 1000), [])

  useEffect(() => {
    smartcoopApi.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error?.response?.data?.message &&
          error?.response?.status !== 403 &&
          error?.response?.status !== 404
        ) {
          createSnackbarError(t(error?.response?.data?.message))
        }
        return Promise.reject(error)
      }
    )
  }, [createSnackbarError, snackbar, t])

  useEffect(
    () => {
      const handleInvalidToken = e => {
        if (e.key === 'logoutFlag') {
          setTimeout(() => {
            window.location.reload(true)
          }, 100)
        }
      }
      window.addEventListener('storage', handleInvalidToken)
      return function cleanup() {
        window.removeEventListener('storage', handleInvalidToken)
      }
    },
    []
  )


  return (
    <Router forceRefresh={ forceRefresh }>
      <Suspense fallback={ <CircularLoader fullSpace /> }>
        <RouterContext />
      </Suspense>
    </Router>
  )
}

export default memo(ScreenRouter)
