import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import useSmartcoopApi from '@smartcoop/services/hooks/useSmartcoopApi'
import Loader from '@smartcoop/web-components/Loader'

import {
  Container,
  Content,
  Title
} from './styles'

const TermsFragment = ({ slug }) => {
  const { data } = useSmartcoopApi(`/v1/terms/public?slug=${ slug }`)

  const term = useMemo(
    () => ({
      description: isEmpty(data) ? '' : data.data[0].description,
      title:  isEmpty(data) ? '' : data.data[0].name
    }), [data]
  )

  const render = useMemo(
    () => (isEmpty(data) ? <Loader /> : <Content dangerouslySetInnerHTML={ { __html: term.description } } />),
    [data, term]
  )

  return (
    <Container>
      <Title>
        {term.title}
      </Title>
      {render}
    </Container>
  )}

TermsFragment.propTypes = {
  slug: PropTypes.string.isRequired
}

export default TermsFragment
