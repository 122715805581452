import React, { useState, useCallback, useEffect } from 'react'

import PropTypes from 'prop-types'

import {
  send,
  record,
  trash
} from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import colors from '@smartcoop/styles/colors'
import Icon from '@smartcoop/web-components/Icon'

import {
  PlayerContainer,
  ButtonControls,
  TimerCounter,
  AudioRecorderButton
} from './styles'

const AudioRecorder = ({ onRecordingComplete, disabled }) => {
  const [isRecording, setIsRecording] = useState(false)
  const [shouldSave, setShouldSave] = useState(false)
  const [recordingTime, setRecordingTime] = useState(0)
  const [mediaRecorder, setMediaRecorder] = useState()
  const [timerInterval, setTimerInterval] = useState()
  const [recordingBlob, setRecordingBlob] = useState()
  const snackbar = useSnackbar()

  useEffect(() => {
    if (
      !shouldSave &&
      recordingBlob
    ) {
      setRecordingBlob(null)
    }

    if (
      shouldSave &&
      recordingBlob != null &&
      onRecordingComplete != null
    ) {
      onRecordingComplete(recordingBlob)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSave, recordingBlob])

  const startTimer = () => {
    const interval = setInterval(() => {
      setRecordingTime((time) => time + 1)
    }, 1000)
    setTimerInterval(interval)
  }

  const stopTimer = () => {
    timerInterval != null && clearInterval(timerInterval)
    setTimerInterval(undefined)
  }

  const startRecording = useCallback(() => {
    if (timerInterval != null) return

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setIsRecording(true)
        const recorder: MediaRecorder = new MediaRecorder(stream)
        setMediaRecorder(recorder)
        recorder.start()
        startTimer()

        recorder.addEventListener('dataavailable', (event) => {
          setRecordingBlob(event.data)
          recorder.stream.getTracks().forEach((t) => t.stop())
          setMediaRecorder(null)
        })
      })
      .catch(() => {
        snackbar.error('Falha ao gravar audio.')
      })
  }, [snackbar, timerInterval])

  const stopRecording = () => {
    mediaRecorder.stop()
    stopTimer()
    setRecordingTime(0)
    setIsRecording(false)
  }

  const stopAudioRecorder = (save) => {
    setShouldSave(save)
    stopRecording()
  }

  if (isRecording) {
    return (
      <PlayerContainer>
        <ButtonControls
          type="button"
          onClick={ () => stopAudioRecorder(false) }
        >
          <Icon icon={ trash } color={ colors.primary } size={ 25 } />
        </ButtonControls>

        <TimerCounter>
          {Math.floor(recordingTime / 60)}:{String(recordingTime % 60).padStart(2, '0')}
        </TimerCounter>

        <ButtonControls
          type="button"
          onClick={ () => stopAudioRecorder(true) }
        >
          <Icon icon={ send } color={ colors.primary } size={ 25 } />
        </ButtonControls>
      </PlayerContainer>
    )
  }

  return (
    <AudioRecorderButton
      type="submit"
      onClick={ startRecording }
      disabled={ disabled }
    >
      <Icon icon={ record } color={ disabled ? colors.white : colors.primary } size={ 25 } />
    </AudioRecorderButton>
  )
}

AudioRecorder.propTypes = {
  onRecordingComplete: PropTypes.func,
  disabled: PropTypes.bool
}

AudioRecorder.defaultProps = {
  onRecordingComplete: null,
  disabled: true
}


export default AudioRecorder
