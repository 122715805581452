import * as Yup from 'yup'

import dateRange from '@smartcoop/forms/validators/dateRange.validator'

const filterTechnicalVisit = ({ t }) => Yup.object().shape({

  proprietaryId: Yup.string().nullable(),

  propertyId: Yup.string().nullable(),

  fieldId: Yup.string().nullable(),

  rangeDate: dateRange({ t })(Yup.object())
})

export default filterTechnicalVisit
