import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import number from '@smartcoop/forms/validators/number.validator'
import required from '@smartcoop/forms/validators/required.validator'

const seedingCropManagementSchema = ({ t }) => Yup.object().shape({
  productGroupSeeding: flow(
    required({ t })
  )(Yup.string()),

  cultivate: flow(
    required({ t })
  )(Yup.string()),

  numberOfPlants: Yup.string(),

  lineSpacing: flow(
    number({ t })
  )(Yup.string()),

  replanting: Yup.string(),

  germination: flow(
    number({ t })
  )(Yup.string()),

  vigor: flow(
    number({ t })
  )(Yup.string())
})

export default seedingCropManagementSchema
