import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Container = styled.div`
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  width: 90vw;
  height: 90vh;

  .rec.rec-arrow {
    background-color: white;
    z-index: 10000;
  }
  .rec.rec-arrow {
    color: #000 !important;
  }
  .rec.rec-dot_active {
    box-shadow: none;
  }

  .rec.rec-dot:hover {
    box-shadow: 0 0 1px 2px ${ colors.secondary };
  }
  .rec-slider-container {
    overflow: visible;
  }
  .rec-slider {
    overflow: visible;
  }
  .rec-item-wrapper {
    overflow: visible;
  }
`

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 90vw;
  height: 90vh;
  margin-right: -40px;
`
