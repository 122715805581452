import api from '../api'

export const getAllSecuritieMovementByUser = (params) => api.get('/v1/securities-movement/account/organizations/user', { params })
export const getAccountSercuritiesMovement = (params,  { accountId, organizationId }) => api.get(`/v1/securities-movement/account/${ accountId }/organization/${ organizationId }`, { params,
  headers: {
    'pragma': 'no-cache',
    'Cache-Control': 'no-cache'
  }
})
export const getSecuritieMovementInitialDate = (params,  { accountId, organizationId }) => api.get(`/v1/securities-movement/initial-date/account/${ accountId }/organization/${ organizationId }`, { params,
  headers: {
    'pragma': 'no-cache',
    'Cache-Control': 'no-cache'
  }
})
