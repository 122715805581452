import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import { colors } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'

export const Badge = styled.div`
  background-color: rgba${ props => props.backgroundColorBadge };
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open Sans";
  color: rgba${ props => props.colorBadge };
  width: 130px;
`


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 30px 23px;
  background-color: ${ colors.backgroundHtml };
  justify-content: center;
`

export const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: 10px;
`

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Title = styled(Typography)`
  font-size: 18px;
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: 700;
  color: ${ colors.black };
  display: flex;
  flex-direction: row;
`


