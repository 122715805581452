import React from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { calendar } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import IconButton from '../IconButton'

const BellIconButton = ({ iconColor, ...props }) => {
  const t = useT()
  return (
    <IconButton tooltip={ t('calendar', { howMany: 2 }) } { ...props }>
      <Icon size={ 22 } icon={ calendar } color={ colors.white } />
    </IconButton>
  )
}

BellIconButton.propTypes = {
  iconColor: PropTypes.string
}

BellIconButton.defaultProps = {
  iconColor: colors.mutedText
}

export default BellIconButton
