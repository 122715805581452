import React from 'react'

import PropTypes from 'prop-types'

import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'
import uniqBy from 'lodash/uniqBy'

import { Divider } from '@material-ui/core'

import { useT } from '@smartcoop/i18n'
import { formatCpfCnpj, formatNumber } from '@smartcoop/utils/formatters'
import Modal from '@smartcoop/web-components/Modal'

import useStyles, { Container, ItemText, ItemWrapper, TextLabel } from './styles'

const BillingDataModal = (props) => {
  const { id, open, data, unit } = props
  const t = useT()
  const classes = useStyles()

  const grouped = groupBy(data?.deliveryLocations, 'billingOrganizationId')

  return (
    <Modal
      id={ id }
      open={ open }
      title={ t('billing data') }
      disableFullScreen
      escape
      maxWidth='md'
      fullWidth
      contentContainerStyle={ { padding: 0, minWidth: 500 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      {map(grouped, group => map(uniqBy(group,'billingOrganizationId'), item => (
        <>
          <Divider/>
          <Container>
            <ItemWrapper key="CNPJ">
              <TextLabel>CNPJ</TextLabel>
              <ItemText> { formatCpfCnpj(item?.billingOrganization?.companyDocument) }</ItemText>
            </ItemWrapper>
            <ItemWrapper key="stateRegistration">
              <TextLabel>{t('state registration')}</TextLabel>
              <ItemText>{ item?.billingOrganization?.stateRegistration || 'Isenta'}</ItemText>
            </ItemWrapper>
            <ItemWrapper key="companyName">
              <TextLabel>{t('company name')}</TextLabel>
              <ItemText>{ item?.billingOrganization?.tradeName }</ItemText>
            </ItemWrapper>
            <ItemWrapper key="amount">
              <TextLabel>{t('quantity')}</TextLabel>
              <ItemText>{formatNumber(sumBy(group, 'amount'))} {unit}</ItemText>
            </ItemWrapper>
            <ItemWrapper key="address">
              <TextLabel>{t('address', { howMany: 1 })}</TextLabel>
              <ItemText>{ `${ item?.billingOrganization?.street }, ${ item?.billingOrganization?.district }, ${ item?.billingOrganization?.city }, ${ item?.billingOrganization?.state }` }</ItemText>
            </ItemWrapper>
          </Container>
        </>
      )))}
    </Modal>
  )
}

BillingDataModal.propTypes = {
  id: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired
}

BillingDataModal.defaultProps = {}

export default BillingDataModal
