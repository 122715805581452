const hexToRgba = require('hex-to-rgba')

module.exports = {
  primary: '#151818',
  secondary: '#FFC80A',
  text: '#0e0e0e',
  backgroundHtml: '#F9F8F6',
  white: '#FFF',
  black: '#1D1D1B',
  blackLight: '#33332F',
  darkGrey: '#595959',
  mediumGrey: '#8D8D8D',
  grey: '#c1c1c1',
  gray: '#c1c1c1',
  lightGrey: '#E0E0E0',
  yellow: '#FFC80A',
  lightYellow: '#FBEF75',
  chatYellow: '#FFEEB5',
  green: '#289F30',
  orange: '#F57D0D',
  warning: '#ff9800',
  info: '#2196f3',
  blue: '#199CD8',
  error: '#f44336',
  red: '#E41D1B',
  mutedText: hexToRgba('#000', 0.54),
  muted: hexToRgba('#000', 0.23),
  semiTransparent: hexToRgba('#000', 0.16),
  semiTransparentWhite: hexToRgba('#FFF', 0.16),
  shadow: hexToRgba('#000', 0.75),
  transparentYellow: hexToRgba('#FFC80A', 0.16),
  transparent: 'transparent',
  blueRain: '#a5e4ff'
}
