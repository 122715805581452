import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap-reverse;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
  align-self: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 4;
  flex-direction: row;
  margin-bottom: 22px;
  margin-right: 10px;
  align-items: center;
`

export const SearchContainer = styled.div`
  display: flex;
  flex: 5;
  flex-direction: row;
`

export const Text = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

const useStyles = makeStyles(() => ({
  white: {
    color: colors.white,
    backgroundColor: 'transparent',
    border: `1px solid ${ colors.white }`,
    width: 25,
    height: 25
  }
}))

export default useStyles

export const inputSearchTheme = (theme) => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff'
      }
    }
  }
})
