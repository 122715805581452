import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'
import Button from '@smartcoop/web-components/Button'


export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
`

export const ArchiveName = styled.p`
  font-size: 16px;
  color: ${ colors.darkGrey };
  margin: 0;
  text-align: ${ ({ alignOnEnd }) => alignOnEnd ? 'end' : 'start' };
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const DeleteDocument = styled(Button)`
  min-width: 32px;
  height: 32px;

  :not(:last-child) {
    margin-right: 5px;
  }
`

export const DownloadDocument = styled(Button)`
  min-width: 32px;
  height: 32px;
  margin-left: ${ ({ alignOnEnd }) => alignOnEnd ? '10px' : '0' };

  :hover {
    text-decoration: none;
  }
`
