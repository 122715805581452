import React, { useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'


import Badge from '@material-ui/core/Badge'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { bell } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import { formatCurrencyIntl } from '@smartcoop/utils/formatters'
import { GROWING_SEASON_COLOR } from '@smartcoop/utils/maps'
import Button from '@smartcoop/web-components/Button'
import Card from '@smartcoop/web-components/Card'
import Icon from '@smartcoop/web-components/Icon'
import PolygonToSvg from '@smartcoop/web-components/PolygonToSvg'
import FieldCostsModal from '@smartcoop/web-containers/modals/FieldCostsModal'

import {
  TextGroup,
  TextLeft,
  TextRight,
  Text,
  CropTextGroup,
  CropText,
  Row
} from './styles'

const FieldCostsItem = (props) => {
  const {
    growingSeason,
    getCosts
  } = props
  const { createDialog } = useDialog()

  const t = useT()

  const field = useMemo(() => growingSeason?.fieldData,[growingSeason.fieldData])

  const polygon = useMemo(() => growingSeason?.childrenPolygonId ? find(field?.childrenPolygons, item => item?.id === growingSeason?.childrenPolygonId) : growingSeason?.fieldData,[field.childrenPolygons, growingSeason.childrenPolygonId, growingSeason.fieldData])

  const expenses = useMemo(() => -sumBy(filter(growingSeason?.costs, item => item?.rubricItem?.rubricGroup?.operationType === 'debit'), 'value') || 0,[growingSeason.costs])

  const costPendencyCount = useMemo(() => filter(growingSeason?.costs, item => item.status === 'pendente')?.length, [growingSeason])

  const openFieldCostsModal = useCallback(() => {
    createDialog({
      id: 'field-costs-modal',
      Component: FieldCostsModal,
      props: {
        growingSeason,
        onExit: getCosts
      }
    })
  }, [createDialog, getCosts, growingSeason])

  return (
    <Card
      key="property-data"
      style={ { margin: '10px 0 15px 0', width: '100%' } }
      cardStyle={ { width: '100%', padding: 10, backgroundColor: colors.white } }
    >
      <div style={ { display: 'flex', flexDirection: 'row', flex: 1 } }>
        <PolygonToSvg
          shape={ map(polygon?.coordinates || polygon?.polygonCoordinates, ([ lat, lng ]) => ({ lat, lng })) }
          color={ GROWING_SEASON_COLOR[growingSeason?.crop?.slug] }
          width={ 40 }
        />
        <TextGroup>
          <TextLeft>
            <Row style={ { fontWeight: 600 } }>
              {field.fieldName} {polygon?.coordinates && ` - ${ polygon?.coordinates ? polygon?.name : '' }` }
            </Row>
            <CropTextGroup>
              <CropText style={ { marginRight: 3 } }>
                {growingSeason?.crop?.description}
              </CropText>
              <CropText>
                {growingSeason?.sowingYear}
              </CropText>
            </CropTextGroup>
            <Text>{parseFloat(polygon?.area).toFixed(2)} ha</Text>
          </TextLeft>

          <TextRight>
            <table style={ { fontWeight: 600, width: '100%' } }>
              <tr>
                <td>
                  {t('expenses')}:
                </td>
                <td style={ { color: 'red', textAlign: 'right',  paddingRight: 20 } }>
                  {formatCurrencyIntl(expenses)}
                </td>
              </tr>
              <tr>
                <td>{' '}
                </td>
                <td style={ { textAlign: 'right', color: expenses < 0 ? 'red' : 'black' } }>
                  {formatCurrencyIntl((expenses) / parseFloat(polygon?.area).toFixed(2))}/ha
                </td>
              </tr>
            </table>
          </TextRight>
          <TextRight style={ { flex: 1 } }>
            <Button
              id="button-cost"
              color="white"
              onClick={ openFieldCostsModal }
              style={ { maxHeight: 50, width: '70%' } }
            >
              {costPendencyCount > 0 && (
                <Badge
                  badgeContent={ <div style={ { height: 15, width: 15, borderRadius: 15, backgroundColor: colors.secondary, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 10 } }>{costPendencyCount}</div> }
                  overlap="circle"
                  anchorOrigin={ {
                    vertical: 'top',
                    horizontal: 'right'
                  } }
                >
                  <Icon size={ 18 } icon={ bell } color={ colors.primary }  />
                </Badge>
              )}
              <div style={ { marginLeft: 10 } }>
                {t('release')}
              </div>
            </Button>
          </TextRight>
        </TextGroup>
      </div>
    </Card>
  )
}

FieldCostsItem.propTypes = {
  growingSeason: PropTypes.object,
  getCosts: PropTypes.func.isRequired
}

FieldCostsItem.defaultProps = {
  growingSeason: {}
}

export default FieldCostsItem
