import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import colors from '@smartcoop/styles/colors'

export const FormContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 20px;
    flex-direction: column;
`

export const EmptyDataContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 20px;
    flex-direction: column;
`
export const Item = styled(Grid).attrs({
  item: true,
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12
})``

export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid ${ colors.lightGrey };
  margin: 0;
`

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.white
  },

  title: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    padding: '12px 13px 0 13px'
  }
})
