// eslint-disable-next-line spaced-comment
export default ({ size, color = '#6D3E1D' } = {}) => /*html*/`
<svg width="${ size }" height="${ size }" version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 1080 1080" style="enable-background:new 0 0 1080 1080;" xml:space="preserve">
<path fill="#000000" id="XMLID_714_" d="M992.77,255.24L873.96,136.43c-6.2-6.2-16.3-6.77-22.78-0.87c-6.92,6.3-7.11,17.03-0.57,23.57l5.04,5.04
 l-12.18,38.5c-4.79,15.16-1.52,31.46,8.51,43.52L465.11,633.06c-3.71,3.71-8.42,6.24-13.56,7.27l-33.8,6.81l-44-44
 c-4.83-4.83-12.67-4.83-17.5,0L231.18,728.2c8.77-0.69,17.93-1.05,27.5-1.05c11.86,0,24.54,0.54,37.7,1.61
 c66.16,5.37,83.78,18.6,99.32,30.27c14.91,11.19,27.78,20.86,89.75,20.86c8.05,0,16.91-0.17,26.32-0.51
 c0.38-0.01,0.75-0.02,1.13-0.02c1.93,0,3.79,0.19,5.6,0.5l7.23-7.23c4.83-4.83,4.83-12.67,0-17.5l-44-44l6.81-33.8
 c1.03-5.14,3.56-9.86,7.27-13.56L882.68,276.9c12.06,10.03,28.35,13.3,43.51,8.51l38.5-12.18l4.71,4.71
 c6.2,6.2,16.3,6.77,22.78,0.87C999.12,272.51,999.31,261.78,992.77,255.24z M924.1,278.78c-12.67,4.01-26.27,1.36-36.47-6.83
 l2.07-2.07c3.28-3.28,3.28-8.59,0-11.87l-18.83-18.83c-3.28-3.28-8.59-3.28-11.87,0l-2.07,2.07c-8.2-10.2-10.84-23.8-6.83-36.47
 l11.09-35.06l97.98,97.98L924.1,278.78z"/>
<path fill="${ color }" id="XMLID_685_" d="M884.43,948.56c-24.6-40.96-73.54-68.89-129.93-68.89c-0.77,0-1.53,0.01-2.29,0.02
 c-18.5,0.25-36.62-6.56-51.44-19.7c-33.61-29.8-80.22-48.26-131.74-48.26c-0.12,0-0.24,0-0.35,0
 c-20.89,0.03-35.78-26.06-56.66-25.32c-162.1,5.8-77.07-39.35-216.21-50.63c-176.6-14.32-213.35,70.07-213.35,188.1
 c0,8.35,0.55,16.58,1.61,24.68H884.43z"/>
</svg>
`
