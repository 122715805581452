import React, { useCallback, forwardRef, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import { useDialog } from '@smartcoop/dialog'
import rubricSchema from '@smartcoop/forms/schemas/property/rubric.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectCurrentPropertyId, selectRubricGroups } from '@smartcoop/stores/property/selectorProperty'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container } from './styles'

const RegisterRubricForm = forwardRef((props, formRef) => {
  const { style, defaultValues, onSuccess, handleClose, readOnly } = props
  const dispatch = useCallback(useDispatch(), [])

  const { createDialog } = useDialog()
  const t = useT()
  const propertyId = defaultValues?.propertyId || useSelector(selectCurrentPropertyId)
  const rubricGroups = useSelector(selectRubricGroups)

  const rubricGroupsOptions = useMemo(() => map(rubricGroups, item => ({ label: item?.description, value: item?.id })),[rubricGroups])
  useEffect(() => {
    dispatch(PropertyActions.loadRubricGroups())
  },[dispatch])

  const handleSubmitRegisterRubric = useCallback(
    (data) => {
      createDialog({
        id: 'confirm-register-rubric',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(PropertyActions.saveRubric(
              {
                ...defaultValues,
                ...data,
                propertyId
              },
              onSuccess,
              handleClose
            ))
          },
          textWarning:  defaultValues?.id ?
            t('are you sure you want to edit the {this}?', {
              howMany: 1,
              gender: 'female',
              this: t('rubric')
            })
            : t('are you sure you want to register the {this}?', {
              howMany: 1,
              gender: 'female',
              this: t('rubric')
            })
        }
      })
    },
    [createDialog, defaultValues, dispatch, handleClose, onSuccess, propertyId, t]
  )

  return (
    <Container>
      <Form
        style={ { ...style } }
        ref={ formRef }
        schemaConstructor={ rubricSchema }
        onSubmit={ handleSubmitRegisterRubric }
      >
        <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'center' } }>
          <InputText
            name="description"
            label={ t('description') }
            fullWidth
            multiline
            defaultValue={ defaultValues?.description }
            disabled={ readOnly }
          />
          <InputSelect
            fullWidth
            label={ t('rubric group') }
            name="groupId"
            options={ rubricGroupsOptions }
            defaultValue={ defaultValues?.rubricGroup?.id }
            disabled={ readOnly }
            creatable
          />
        </div>
        { !readOnly && (
          <ButtonsContainer>
            <Button
              id="cancel-technical-visit-form"
              onClick={ handleClose }
              style={ { flex: 1, marginRight: 10 } }
              variant="outlined"
            >
              <I18n>cancel</I18n>
            </Button>

            <Button
              id="submit-technical-visit-form"
              onClick={ () => formRef.current.submit() }
              style={ { flex: 1 } }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

RegisterRubricForm.propTypes = {
  style: PropTypes.object,
  handleClose: PropTypes.func,
  defaultValues: PropTypes.object,
  readOnly: PropTypes.bool,
  onSuccess: PropTypes.func
}

RegisterRubricForm.defaultProps = {
  style: {},
  handleClose: () => {},
  defaultValues: {},
  readOnly: false,
  onSuccess: () => {}
}

export default RegisterRubricForm
