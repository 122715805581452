
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import hexToRgba from 'hex-to-rgba'
import moment from 'moment/moment'

import { filter, isNil, toNumber } from 'lodash'
import compact from 'lodash/compact'
import find from 'lodash/find'
import filterFP from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import mapFP from 'lodash/fp/map'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import partition from 'lodash/partition'

import { useT } from '@smartcoop/i18n'
import { getFieldMonitoration, getFieldWeatherHistoric } from '@smartcoop/services/apis/smartcoopApi/resources/fieldsMonitoration'
import {
  getPropertyActivitiesConsolidate,
  getPropertyGrowingSeasonsConsolidate
} from '@smartcoop/services/apis/smartcoopApi/resources/property'
import { selectDashboardData } from '@smartcoop/stores/dairyFarm/selectorDairyFarm'
import { colors } from '@smartcoop/styles'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import { formatNumberJs } from '@smartcoop/utils/formatters'

export const partitionCropManagements = (cropManagements) => partition(cropManagements, item => item.realizationDate)

export const createFieldTimelineChartOptions = async ({
  fieldId,
  growingSeasonId,
  dates,
  t,
  closed,
  accessToken
}) => {
  const [
    { data: fieldMonitorationData },
    { data: fieldWeatherHistoric }
  ] = await Promise.all([
    getFieldMonitoration({
      startDate: dates?.from,
      endDate: dates?.to,
      closed
    }, { growingSeasonId }, accessToken),
    getFieldWeatherHistoric({
      from: dates?.from,
      to: dates?.to
    }, { fieldId }, accessToken)
  ])

  const data = mapValues(fieldMonitorationData, (monitoration, date) => ({
    monitoration,
    weather: find(fieldWeatherHistoric, { date })
  }))

  return {
    title: { style: { display: 'none' } },
    plotOptions: {
      series: {
        turboThreshold: 0,
        dataLabels: {
          zIndex: -1
        },
        fillOpacity: 0.2,
        keys: ['y', 'custom.tooltipContent'],
        marker: {
          radius: 4,
          lineWidth: 1
        }
      },
      spline: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5
          }
        },
        marker: {
          enabled: false
        }
      }
    },
    xAxis: {
      type: 'datetime',
      categories: map(
        data,
        (_, date) => moment(date, momentBackDateFormat).format(momentFriendlyDateFormat.replace('YYYY', 'YY'))
      )
    },
    yAxis: [
      {
        title: { text: t('millimeters') },
        labels: { format: '{value} mm' },
        max: Math.max(...map(data, ({ monitoration }) => monitoration?.rain?.rainMm || 0)) + 20,
        endOnTick: false,
        zoomEnabled: false,
        tickInterval: 10,
        gridLineColor: hexToRgba(colors.black, 0.3)
      },
      { // Secondary yAxis
        title: { text: 'Manejo' },
        visible: false
      },
      {
        title: { text: t('temperature') },
        labels: { format: '{value} °C' },
        max: Math.max(
          ...flow(
            filterFP(({ weather }) => !!weather),
            mapFP(({ weather }) => weather?.max || 0)
          )(data)
        ) + 20,
        endOnTick: false,
        zoomEnabled: false,
        tickInterval: 5,
        opposite: true,
        alternateGridColor: hexToRgba(colors.black, 0.05),
        gridLineWidth: 0
      }
    ],
    series: [
      {
        yAxis: 0,
        type: 'column',
        name: t('precipitation'),
        data: map(data, ({ monitoration }) => monitoration?.rain?.rainMm || 0),
        color: colors.blueRain,
        marker: { lineColor: colors.blueRain },
        tooltip: {
          valueSuffix: 'mm <br/>'
        }
      },
      {
        yAxis: 1,
        type: 'lollipop',
        id: 'crop-management-finished',
        name: t('crops management ended'),
        color: colors.mutedText,
        dashStyle: 'Dash',
        connectorWidth: 2,
        getExtremesFromAll: true,
        marker: {
          enabled: false,
          lineColor: colors.transparent
        },
        tooltip: {
          pointFormat: '<span style="color:{series.color}">●</span> {series.name}: <b>{point.options.custom.tooltipContent}</b><br/>'
        },
        data: map(data, ({ monitoration }) => {
          const [finished] = partitionCropManagements(monitoration?.cropManagements)
          return (
            isEmpty(finished)
              ? null
              : [
                2,
                finished.map(cropManagement => `
                  <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;${ cropManagement?.operation?.name }
                  </div>
                `).join('')
              ]
          )
        }),
        dataLabels: {
          enabled: true,
          useHTML: true,
          zIndex: -1,
          y: 0,
          formatter() {
            return `
              <div style="display:flex;align-items:center">
                <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.727783 22.0195C0.727783 23.2617 1.7356 24.2695 2.97778 24.2695H19.4778C20.72 24.2695 21.7278 23.2617 21.7278 22.0195V9.26953H0.727783V22.0195ZM3.72778 13.0195C3.72778 12.607 4.06528 12.2695 4.47778 12.2695H8.97778C9.39028 12.2695 9.72778 12.607 9.72778 13.0195V17.5195C9.72778 17.932 9.39028 18.2695 8.97778 18.2695H4.47778C4.06528 18.2695 3.72778 17.932 3.72778 17.5195V13.0195ZM19.4778 3.26953H17.2278V1.01953C17.2278 0.607031 16.8903 0.269531 16.4778 0.269531H14.9778C14.5653 0.269531 14.2278 0.607031 14.2278 1.01953V3.26953H8.22778V1.01953C8.22778 0.607031 7.89028 0.269531 7.47778 0.269531H5.97778C5.56528 0.269531 5.22778 0.607031 5.22778 1.01953V3.26953H2.97778C1.7356 3.26953 0.727783 4.27734 0.727783 5.51953V7.76953H21.7278V5.51953C21.7278 4.27734 20.72 3.26953 19.4778 3.26953Z" fill="#595959"/>
                </svg>
              </div>
            `
          }
        }
      },
      {
        yAxis: 1,
        type: 'lollipop',
        id: 'crop-management-schedule',
        name: t('crops management scheduled'),
        color: colors.green,
        dashStyle: 'Dash',
        connectorWidth: 2,
        connectEnds: true,
        marker: {
          enabled: false,
          lineColor: colors.transparent
        },
        tooltip: {
          pointFormat: '<span style="color:{series.color}">●</span> {series.name}: <b>{point.options.custom.tooltipContent}</b><br/>'
        },
        data: map(data, ({ monitoration }) => {
          const [, scheduled] = partitionCropManagements(monitoration?.cropManagements)
          return (
            isEmpty(scheduled)
              ? null
              : [
                2,
                scheduled.map(cropManagement => `
                  <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;${ cropManagement.operation?.name }
                  </div>
                `).join('')
              ]
          )
        }),
        dataLabels: {
          enabled: true,
          useHTML: true,
          y: 0,
          formatter() {
            return `
              <div style="display:flex;align-items:center">
                <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.510986 22.9258C0.510986 24.2197 1.56079 25.2695 2.85474 25.2695H20.0422C21.3362 25.2695 22.386 24.2197 22.386 22.9258V9.64453H0.510986V22.9258ZM3.63599 13.5508C3.63599 13.1211 3.98755 12.7695 4.41724 12.7695H9.10474C9.53442 12.7695 9.88599 13.1211 9.88599 13.5508V18.2383C9.88599 18.668 9.53442 19.0195 9.10474 19.0195H4.41724C3.98755 19.0195 3.63599 18.668 3.63599 18.2383V13.5508ZM20.0422 3.39453H17.6985V1.05078C17.6985 0.621094 17.3469 0.269531 16.9172 0.269531H15.3547C14.925 0.269531 14.5735 0.621094 14.5735 1.05078V3.39453H8.32349V1.05078C8.32349 0.621094 7.97192 0.269531 7.54224 0.269531H5.97974C5.55005 0.269531 5.19849 0.621094 5.19849 1.05078V3.39453H2.85474C1.56079 3.39453 0.510986 4.44434 0.510986 5.73828V8.08203H22.386V5.73828C22.386 4.44434 21.3362 3.39453 20.0422 3.39453Z" fill="#289F30"/>
                </svg>
              </div>
            `
          }
        }
      },
      {
        yAxis: 2,
        type: 'spline',
        name: t('max'),
        data: map(data, ({ weather }) => weather?.max !== undefined ? weather.max : null),
        color: colors.orange,
        tooltip: {
          valueSuffix: '°C <br/>'
        }
      },
      {
        yAxis: 2,
        type: 'spline',
        name: t('min'),
        data: map(data, ({ weather }) => weather?.min !== undefined ? weather.min : null),
        color: colors.blue,
        tooltip: {
          valueSuffix: '°C <br/>'
        }
      }
    ]
  }
}

export const createPropertyActivitiesChartOptions = async ({
  propertyId,
  t
}) => {
  const { data: { data } } = await getPropertyActivitiesConsolidate({ propertyId })

  return {
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },
    subtitle: {
      text: '',
      style: {
        display: 'none'
      }
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    legend: {
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      itemMarginBottom: 10,
      itemStyle: { fontSize: 14 },
      labelFormat: '{custom} ha {name}'
    },
    plotOptions: {
      pie: {
        innerSize: '50%',
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.2f} %'
        },
        showInLegend: true
      }
    },
    series: [
      {
        name: t('total area'),
        colorByPoint: true,
        tooltip: {
          valueSuffix: ' ha',
          headerFormat: '<b>{point.key}</b><br/>',
          pointFormatter() {
            return `${ this.series.name }: <b>${ this.custom } ha</b>`
          }
        },
        data: map(data, ({ area, activity }) => ({
          name: activity.name,
          y: area,
          custom: formatNumberJs(area)
        }))
      }
    ],
    lang: {
      noData: 'Sem dados para exibir'
    },
    noData: {
      style: {
        fontSize: '1rem',
        fontFamily: 'Open Sans, Roboto, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1.5,
        color: 'rgba(0, 0, 0, 0.87)'
      }
    }
  }
}

export const createPropertyGrowingSeasonsChartOptions = async ({
  propertyId,
  t
}) => {
  const { data: { data } } = await getPropertyGrowingSeasonsConsolidate({ propertyId })

  return {
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },
    subtitle: {
      text: '',
      style: {
        display: 'none'
      }
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    legend: {
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      itemMarginBottom: 10,
      itemStyle: { fontSize: 14 },
      labelFormat: '{custom} ha {name}'
    },
    plotOptions: {
      pie: {
        innerSize: '50%',
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.2f}%'
        },
        showInLegend: true
      }
    },
    series: [
      {
        name: t('total area'),
        colorByPoint: true,
        tooltip: {
          valueSuffix: ' ha',
          headerFormat: '<b>{point.key}</b><br/>',
          pointFormatter() {
            return `${ this.series.name }: <b>${ this.custom } ha</b>`
          }
        },
        data: filter(map(data, ({ area, areaFormatted, cropName, cultivationGoalName }) => ({
          name: `${ cropName } ${ cultivationGoalName }`,
          y: area,
          custom: `${ areaFormatted }`
        })), ({ custom }) => !isNil(custom))
      }
    ],
    lang: {
      noData: 'Sem dados para exibir'
    },
    noData: {
      style: {
        fontSize: '1rem',
        fontFamily: 'Open Sans, Roboto, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1.5,
        color: 'rgba(0, 0, 0, 0.87)'
      }
    }
  }
}


export const useDairyFarmDeliveryChartOptions = () => {

  const t = useT()

  const dashboardData = useSelector(selectDashboardData)
  const milkDeliveries = useMemo(
    () => dashboardData?.milkDeliveries ?? [], [dashboardData]
  )

  const volume = useMemo(
    () => map(milkDeliveries, (delivery) => (
      [delivery[0], delivery[1]?.status?.slug !== 'depending-on-approval' ? toNumber(delivery[1].volume ?? 0) : 0]
    )),[milkDeliveries])

  const pendingVolume = useMemo(
    () => map(milkDeliveries, (delivery) => (
      [delivery[0], delivery[1]?.status?.slug === 'depending-on-approval' ? toNumber(delivery[1].volume ?? 0) : 0]
    )),[milkDeliveries])

  const condemnedVolume = useMemo(
    () => (
      map(milkDeliveries, (delivery) => (
        [delivery[0], toNumber(delivery[1].condemnedVolume ?? 0) ]
      ))
    ),[milkDeliveries]
  )

  const temperature = useMemo(
    () => (
      compact(map(milkDeliveries, (delivery) => {
        const temp = toNumber(delivery[1].temperature ?? 0)

        return temp !== 0 ? [delivery[0], temp] : undefined
      }))
    ),[milkDeliveries]
  )

  const showCondemnedVolume = useMemo(() => !!find(condemnedVolume, item => item[1] !== 0), [condemnedVolume])

  const chartSeries = useMemo(() => showCondemnedVolume ? [
    {
      name: 'Volume Condenado',
      type: 'column',
      color: colors.yellow,
      data: condemnedVolume,
      yAxis: 0
    },
    {
      name: 'Volume Diário',
      type: 'column',
      color: colors.blue,
      data: volume,
      yAxis: 0
    },
    {
      name: 'Volume Pendente de Confirmação',
      type: 'column',
      color: colors.blueRain,
      data: pendingVolume,
      yAxis: 0
    },
    {
      name: 'Temperatura Média',
      type: 'line',
      color: colors.orange,
      data: temperature,
      yAxis: 1
    }
  ] : [
    {
      name: 'Volume Diário',
      type: 'column',
      color: colors.blue,
      data: volume,
      yAxis: 0
    },
    {
      name: 'Volume Pendente de Confirmação',
      type: 'column',
      color: colors.blueRain,
      data: pendingVolume,
      yAxis: 0
    },
    {
      name: 'Temperatura Média',
      type: 'line',
      color: colors.orange,
      data: temperature,
      yAxis: 1
    }
  ],[condemnedVolume, pendingVolume, showCondemnedVolume, temperature, volume])

  const chartOptions = useMemo(
    () => ({
      title: {
        text: ''
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45
        }
      },
      yAxis: [
        {
          min: 0,
          title: {
            text:  t('milk volume')
          }
        },
        {
          min: 0,
          // max: 7,
          title: {
            text:  'Temperatura'
          },
          opposite: true
        }
      ],
      legend: {
        enabled: true
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      series: chartSeries
    }),
    [chartSeries, t]
  )

  return chartOptions
}


export const useDairyFarmPriceChartOptions = (shouldDisplayCosts = true) => {
  const t = useT()
  const dashboardData = useSelector(selectDashboardData)

  const prices = useMemo(
    () => dashboardData?.prices ?? [],
    [dashboardData]
  )

  const price = useMemo(
    () => (
      map(prices, (month) => (
        [month[0], toNumber(month[1].price ?? 0) || null]
      ))
    ),[prices]
  )

  const monthlyMilkVolume = useMemo(
    () => (
      map(prices, (month) => (
        [month[0], toNumber(month[1].monthlyMilkVolume ?? 0) ]
      ))
    ),[prices]
  )

  const showPrice = useMemo(() => shouldDisplayCosts && !!find(price, item => item[1] !== null), [price, shouldDisplayCosts])

  const chartSeries = useMemo(() => showPrice ? [
    {
      name: 'Volume Acumulado',
      type: 'column',
      color: colors.yellow,
      data: monthlyMilkVolume,
      yAxis: 0
    },
    {
      name: 'Preço Médio',
      type: 'line',
      color: colors.blue,
      data: price,
      yAxis: 1
    }
  ] : [
    {
      name: 'Volume Acumulado',
      type: 'column',
      color: colors.yellow,
      data: monthlyMilkVolume,
      yAxis: 0
    }
  ],[monthlyMilkVolume, price, showPrice])
  const chartOptions = useMemo(
    () => ({
      title: {
        text: ''
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45
        }
      },
      yAxis: [
        {
          min: 0,
          title: {
            text:  t('milk volume')
          }
        },
        {
          min: 0,
          title: {
            text:  'Preço Médio'
          },
          opposite: true
        }
      ],
      legend: {
        enabled: true
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      series: chartSeries
    }),
    [t, chartSeries]
  )

  return chartOptions
}

export const useDairyFarmQualityChartOptions = (type) => {
  const t = useT()
  const dashboardData = useSelector(selectDashboardData)

  const milkQualities = useMemo(
    () => dashboardData?.milkQualities ?? [], [dashboardData]
  )
  const data = useMemo(
    () => (
      map(milkQualities, (month) => (
        [month[0], toNumber(month[1][`${ type }`]  ?? 0) ]
      ))
    ),[milkQualities, type]
  )


  const dataLimit = useMemo(
    () => (
      map(milkQualities, (month) => (
        [month[0], month[1][`${ type }Limit`] ? toNumber(month[1][`${ type }Limit`]) : 0]
      ))
    ),[type, milkQualities]
  )

  const subtitleSeries = useMemo(
    () => (
      type === 'ccs' || type === 'ctb' ? `${ type } (x1000)` : type
    ), [type]
  )

  const subtitleSeriesLimit = useMemo(
    () => (
      type === 'ccs' || type === 'ctb' ? 'Limite (x1000)' : 'Limite'
    ), [type]
  )

  const chartOptions = useMemo(
    () => ({
      chart: {
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45
        }
      },
      yAxis:{
        min: 0,
        title: {
          text:  type && t(type)
        }
      },
      legend: {
        enabled: true
      },
      series: [
        {
          name: type && t(subtitleSeries),
          type: 'column',
          color: colors.blue,
          data
        },
        {
          name: subtitleSeriesLimit,
          type: 'line',
          color: colors.orange,
          data: dataLimit,
          dashStyle: 'ShortDash'
        }
      ]
    }),
    [type, t, subtitleSeries, data, subtitleSeriesLimit, dataLimit]
  )

  return chartOptions
}
