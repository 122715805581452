// eslint-disable-next-line spaced-comment
export default ({ size } = {}) => /*html*/`
  <svg width="${ size }" height="${ size }" viewBox="0 0 108 125" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M108 36.008C108 55.8865 91.8865 72 71.992 72C52.1294 72 36 55.8865 36 36.008C36 16.1294 52.1294 0 72.008 0C91.8865 0 108 16.1294 108 36.008Z" fill="#FFC80A"/>
    <path d="M88.6798 21.9047C88.6798 24.3138 86.7334 26.2601 84.3244 26.2601C81.9154 26.2601 79.969 24.3138 79.969 21.9047C79.969 19.4957 81.9154 17.5493 84.3244 17.5493C86.7334 17.5493 88.6798 19.4957 88.6798 21.9047Z" fill="black"/>
    <path d="M63.6322 21.9047C63.6322 24.3138 61.6858 26.2601 59.2768 26.2601C56.8678 26.2601 54.9214 24.3138 54.9214 21.9047C54.9214 19.4957 56.8678 17.5493 59.2768 17.5493C61.6858 17.5493 63.6322 19.4957 63.6322 21.9047Z" fill="black"/>
    <path d="M57.0433 54.4506C56.0541 54.4506 55.2405 53.6529 55.2405 52.6478C55.2405 44.9102 61.4785 38.6084 69.1363 38.6084H75.1829C82.8407 38.6084 89.0787 44.8623 89.0787 52.5681C89.0787 53.5572 88.281 54.3709 87.2759 54.3709C86.2868 54.3709 85.4731 53.5732 85.4731 52.5681C85.4731 46.8406 80.8465 42.198 75.1669 42.198H69.1204C63.4408 42.198 58.8142 46.8885 58.8142 52.6478C58.8301 53.6529 58.0324 54.4506 57.0433 54.4506Z" fill="black"/>
    <path d="M2.30899 32C2.30899 32 8.58574 46.6443 30.8128 46.6443C30.8128 46.6443 46.9935 47.1425 52.3954 67.8121C52.6444 68.7587 53.0461 69.6586 53.5847 70.4776C55.9665 74.0927 62.7508 87.641 61.6704 123.534C61.6704 123.534 60.232 125.234 59.7089 124.973C59.7089 124.973 62.5359 89.0017 52.3985 72.3241C52.2459 72.0718 51.9314 71.9722 51.6575 72.0843C49.8299 72.8347 43.055 75.2511 38.7895 71.8819C35.1997 69.0483 32.1578 69.0795 30.7443 69.3161C29.5861 69.5092 28.4154 69.6119 27.2416 69.5714C20.5446 69.3379 3.28662 66.4638 0.347502 43.3748C0.347502 43.3779 -1.22169 38.1467 2.30899 32Z" fill="#1D1D1B"/>
    <path d="M60.8666 100.91C60.8666 100.91 57.3858 100.028 54.2785 101.498C53.5033 101.865 52.6004 101.797 51.8656 101.351C49.7609 100.072 45.1405 98.3216 39.692 102.862C35.6444 106.234 30.1367 107.05 25.9367 107.09C22.8855 107.118 19.8654 106.495 17.0321 105.362C15.0457 104.568 12.3246 103.699 10.0953 103.78C9.93965 103.786 9.8338 103.939 9.87427 104.088C10.2292 105.387 12.1346 110.571 19.7689 110.026C28.4866 109.404 25.9958 112.206 30.3547 112.829C34.7135 113.452 41.2518 115.32 53.3943 104.11C53.3943 104.11 56.8191 100.996 60.8666 102.242V100.91Z" fill="#1D1D1B"/>
    <path d="M9.13013 89.6027C9.13013 89.6027 23.723 76.8827 29.9779 76.5152C34.9252 76.2225 43.3721 79.1246 46.691 80.3546C47.5597 80.6753 48.4906 80.7563 49.4028 80.6037C51.4671 80.2643 55.4336 80.0494 57.4076 82.8456L56.5887 83.1726C56.5887 83.1726 54.3564 80.9991 50.5891 81.2825C49.3997 81.3728 48.2851 81.9084 47.4756 82.7896C45.8317 84.58 42.1983 88.0208 38.02 88.6872C36.2889 88.9643 34.6388 89.5871 33.191 90.5742C28.6703 93.6537 18.4519 98.6763 9.13013 89.6027Z" fill="#1D1D1B"/>
    <path d="M84 90.5019C84 95.1954 79.9716 99 74.998 99C70.0324 99 66 95.1954 66 90.5019C66 85.8083 70.0324 82 75.002 82C79.9716 82 84 85.8083 84 90.5019Z" fill="#1D1D1B"/>
  </svg>
`
