import styled from 'styled-components'

export const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${ ({ receiver }) => receiver ? '#bcbcbc' : '#f1e2b2' };
  border-radius: 15px;
  padding: 10px;
  column-gap: 10px;
  margin-top: 10px;
  cursor: pointer;

  :hover {
    filter: brightness(0.95);
  }
`

export const FileDescription = styled.span`
  font-weight: 600;
`
