import React,
{ useMemo, useCallback, useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import {
  noAddress
} from '@smartcoop/icons'
import Address from '@smartcoop/web-components/Address'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Loader from '@smartcoop/web-components/Loader'
import EditAddressModal from '@smartcoop/web-containers/modals/profile/EditAddressModal'

import { Container, Label, AddressContainer } from './styles'

const AddressCardFragment = (props) => {
  const { address } = props
  const t = useT()
  const { createDialog } = useDialog()
  const [addressState, setAddressState] = useState('loading')
  const isAddressEmpty = useMemo(
    () => (
      every(address, isEmpty)
    ), [address]
  )

  const editAddress = useCallback(
    () => {
      createDialog({
        id: 'edit-address',
        Component: EditAddressModal
      })
    }, [createDialog]
  )

  useEffect(() => {
    isAddressEmpty ? setAddressState('empty') : setAddressState('loaded')
  }, [address, isAddressEmpty])

  const renderAddressCard = useMemo(
    () => (
      {
        loading: <Loader width={ 80 } />,
        empty: (
          <EmptyState
            text={ t('no address registered') }
            icon={ noAddress }
            iconProps={ {
              size: 120,
              style: { marginRight: 40 }
            } }
            action={ {
              text: t('register address'),
              onClick: editAddress
            } }
            style={ { flexDirection: 'row', justifyContent: 'flex-start' } }
            contentStyle={ { alignItems: 'flex-start' } }
          />),
        loaded: (
          <AddressContainer>
            <Address
              fields={ address }
              isUserAddress
              onEdit={  editAddress }
            />
          </AddressContainer>)

      }[addressState]
    ), [address, addressState, editAddress, t]
  )


  return (
    <Container>
      <Label>
        <I18n params={ { howMany: 2 } }>address</I18n>
      </Label>
      {renderAddressCard}
    </Container>
  )
}

AddressCardFragment.propTypes = {
  address: PropTypes.object.isRequired
}

export default AddressCardFragment
