import React, { useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import { warning } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import Icon from '@smartcoop/web-components/Icon'
import RadioButton from '@smartcoop/web-components/RadioButton'
import RadioCard from '@smartcoop/web-components/RadioCard'
import RadioItemLined from '@smartcoop/web-components/RadioItemLined'

import { Container, RadioGroupContainer, Label, LabelError } from './styles'

const RadioGroupStyled = (props) => {
  const {
    options,
    value,
    variant,
    style,
    label: externalLabel,
    clearable,
    onChange,
    disabled,
    itemStyle,
    containerStyle,
    activeId,
    activeMessage,
    error,
    required,
    ...rest
  } = props

  const handleChange = useCallback(
    ({ value: val }) => {
      if (!disabled) {
        onChange({ value: clearable && val === value ? null : val })
      }
    },
    [clearable, disabled, onChange, value]
  )

  const customMargin = useMemo(
    () =>
      variant === 'row' ? { marginRight: '5px' } : { marginBottom: '5px' },
    [variant]
  )

  const ChoosenRadio = useMemo(() => {
    switch (variant) {
      case 'card':
        return RadioCard
      case 'lined':
        return RadioItemLined
      default:
        return RadioButton
    }
  }, [variant])
  const flexDirection = useMemo(() => (variant === 'row' ? 'row' : 'column'), [
    variant
  ])

  return (
    <Container style={ { ...style } }>
      {externalLabel !== '' && (
        <div>
          <Label style={ error ? { color: colors.error } : {} }>
            {externalLabel}
            {required && variant !== 'card' ? ' *' : ''}
          </Label>
        </div>
      )}

      {error && (
        <div style={ { display: 'flex', flexDirection: 'row' } }>
          <Icon
            style={ { paddingRight: 5 } }
            icon={ warning }
            color={ colors.error }
            size={ 12 }
          />
          <LabelError>{error}</LabelError>
        </div>
      )}
      <RadioGroupContainer style={ { flexDirection, ...containerStyle } }>
        {map(options, ({ label, value: optionValue, children }, index) => (
          <ChoosenRadio
            key={ optionValue }
            label={ label }
            value={ optionValue }
            index={ optionValue }
            onChange={ handleChange }
            checked={ value === optionValue }
            position={ index }
            style={ { ...customMargin, ...itemStyle } }
            activeItem={ activeId && activeId === optionValue }
            activeMessage={ activeMessage }
            disabled={ disabled }
            { ...rest }
          >
            {children}
          </ChoosenRadio>
        ))}
      </RadioGroupContainer>
    </Container>
  )
}

RadioGroupStyled.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string
  ]),
  variant: PropTypes.oneOf(['row', 'column', 'card', 'lined']),
  style: PropTypes.object,
  containerStyle: PropTypes.object,
  itemStyle: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  activeId: PropTypes.string,
  activeMessage: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool
}

RadioGroupStyled.defaultProps = {
  value: '',
  variant: 'column',
  style: {},
  itemStyle: {},
  containerStyle: {},
  label: '',
  clearable: false,
  onChange: (value) => value,
  disabled: false,
  activeId: null,
  activeMessage: null,
  error: null,
  required: false
}

export default RadioGroupStyled
