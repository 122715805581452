
import React, { useMemo } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import ceil from 'lodash/ceil'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'

import { useT } from '@smartcoop/i18n'
import {
  getProductsQuotationByOrg as getProductsQuotationByOrgService
} from '@smartcoop/services/apis/smartcoopApi/resources/productsQuotation'
import { colors } from '@smartcoop/styles'
import { momentBackDateTimeFormat, momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import { formatCurrency, formatNumber } from '@smartcoop/utils/formatters'
import DataTable from '@smartcoop/web-components/DataTable'

const ProductsQuotationListFragment = ({ filters, urlParams, setLastUpdatedAt }) => {
  const t = useT()
  const columns = useMemo(
    () => [
      {
        title: 'Produto',
        field: 'organizationProduct.productName',
        cellStyle: { fontWeight: 'bold' },
        render: (row) => row.organizationProduct?.productName
      },
      {
        title: 'Unidade',
        field: 'unit',
        render: (row) =>
          row.organizationProduct?.conversionFactor !== 1 && row.organizationProduct?.conversionFactor !== undefined
            ? `${ row.organizationProduct?.unitOfMeasuresForConversion } ${ t('of') } ${ formatNumber(ceil(row.organizationProduct?.conversionFactor, 2)) } ${ row.organizationProduct?.measureUnit }`
            : row.organizationProduct?.measureUnit
      },
      {
        title: 'Valor',
        field: 'unitValue',
        render: (row) =>
          row.organizationProduct?.conversionFactor !== 1 && row.organizationProduct?.conversionFactor !== undefined
            ? formatCurrency(toNumber(row.unitValue) * toNumber(row.organizationProduct?.conversionFactor))
            : formatCurrency(row.unitValue)

      },
      {
        title: 'Valido até',
        field: 'expirationDate',
        headerStyle: { whiteSpace: 'nowrap' },
        render: (row) => (
          <div style={ moment(row.expirationDate).diff(moment()) < 0 ? { color: colors.red } : {} }>
            {
              moment(row.expirationDate, momentBackDateTimeFormat).format(`DD/MM/YYYY[ ${ t('until') } ]HH[h]mm`)
            }
          </div>
        )
      }
    ], [t]
  )
  return(
    <DataTable
      onDataLoad={ data => {
        setLastUpdatedAt(moment.max(map(data, item => moment(item.lastUpdate, momentBackDateTimeFormat))).format(momentFriendlyDateTimeFormat))
      } }
      data={ getProductsQuotationByOrgService }
      queryParams ={ filters }
      columns={ columns }
      urlParams={ urlParams }
      id="products-quotation-list-table"
      hideLastRequestDate
    />
  )
}

ProductsQuotationListFragment.propTypes = {
  filters: PropTypes.object,
  setLastUpdatedAt: PropTypes.func.isRequired,
  urlParams: PropTypes.object
}

ProductsQuotationListFragment.defaultProps = {
  filters: {},
  urlParams: {}

}

export default ProductsQuotationListFragment
