import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import Modal from '@smartcoop/web-components/Modal'
import RegisterRubricForm from '@smartcoop/web-containers/forms/digitalProperty/property/RegisterRubricForm'

const RegisterRubricModal = (props) => {
  const { id, open, defaultValues, onSuccess, handleClose, readOnly } = props
  const registerRubricFormRef = useRef(null)
  const t = useT()

  return (
    <Modal
      id={ id }
      open={ open }
      title={ defaultValues?.id ? t('edit rubric') : t('register rubric') }
      disableFullScreen
      maxWidth='md'
      fullWidth
    >
      <RegisterRubricForm
        ref={ registerRubricFormRef }
        handleClose={ handleClose }
        defaultValues={ defaultValues }
        onSuccess={ onSuccess }
        readOnly={ readOnly }
      />
    </Modal>
  )
}

RegisterRubricModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  readOnly: PropTypes.bool,
  onSuccess: PropTypes.func
}

RegisterRubricModal.defaultProps = {
  defaultValues: {},
  readOnly: false,
  onSuccess: () => {}
}

export default RegisterRubricModal
