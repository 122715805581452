import api from '../api'

export const getPestReportTypes = (params) =>
  api.get('/v1/plagues/growing-seasons/report-types', { params })

export const createPestReport = (params, { growingSeasonId }) =>
  api.post(`/v1/plagues/growing-seasons/${ growingSeasonId }/report`, params)

export const editPestReport = (params, { reportId }) =>
  api.patch(`/v1/plagues/growing-seasons/report/${ reportId }`, params)

export const deletePestReport = ({ reportId }) =>
  api.delete(`/v1/plagues/growing-seasons/report/${ reportId }`)

export const postFilesPestReport = (params, { reportId }) =>
  api.post(`/v1/plagues/growing-seasons/report/${ reportId }/file`, params, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

export const putFilesPestReport = (params, { reportId }) =>
  api.put(`/v1/plagues/growing-seasons/report/${ reportId }/file`, params)

export const getDiseases = (params) =>
  api.get('/v1/disease', { params })

export const getWeeds = (params) =>
  api.get('/v1/weed', { params })

export const getPests = (params) =>
  api.get('/v1/pest', { params })
