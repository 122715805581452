import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

// Initial state
const INITIAL_STATE = {
  nonCompliances: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadNonCompliances: ['params', 'onSuccess', 'onError'],
  loadNonCompliancesSuccess: ['nonCompliances', 'page', 'onSuccess'],

  saveNonCompliance: ['params', 'onSuccess', 'onError'],

  nonComplianceError: ['error'],

  updateNonCompliance: ['params', 'onSuccess', 'onError' ],
  resolveNonCompliance: ['params', 'onSuccess', 'onError' ],
  eraseNonCompliance: ['params', 'onSuccess', 'onError' ],

  saveNonComplianceComment: ['params', 'onSuccess', 'onError']
})

/**
 * Reducers functions
 */
const loadNonCompliancesSuccess = (state = INITIAL_STATE, { nonCompliances, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  nonCompliances: page === 1 ? nonCompliances : [
    ...state.nonCompliances,
    ...nonCompliances
  ]
})


const nonComplianceError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})


const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_NON_COMPLIANCES_SUCCESS]: loadNonCompliancesSuccess,

  [Types.NON_COMPLIANCE_ERROR]: nonComplianceError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as NonCompliancesTypes,
  Creators as NonCompliancesActions
}
