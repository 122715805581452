import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  cropManagements: [],
  cropManagement: {},
  fertilizationProduct: '',
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadCropManagement: ['params', 'onSuccess', 'onError'],
  loadCropManagementSuccess: ['cropManagement', 'onSuccess'],

  addManagementFiles: ['params', 'managementId', 'onSuccess', 'onError'],
  editManagementFiles: ['params', 'managementId', 'onSuccess', 'onError'],

  setFertilizationProduct: ['fertilizationProduct'],
  resetFertilizationProduct: [],

  cropManagementError: ['error']
})

/**
 * Reducers functions
 */
const loadCropManagementSuccess = (state = INITIAL_STATE, { cropManagement }) => ({
  ...state,
  error: INITIAL_STATE.error,
  cropManagement
})


const cropManagementError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const setFertilizationProduct = (state = INITIAL_STATE, { fertilizationProduct }) => ({
  ...state,
  fertilizationProduct
})

const resetFertilizationProduct = (state = INITIAL_STATE) => ({
  ...state,
  fertilizationProduct: INITIAL_STATE.fertilizationProduct
})


const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_CROP_MANAGEMENT_SUCCESS]: loadCropManagementSuccess,

  [Types.CROP_MANAGEMENT_ERROR]: cropManagementError,

  [Types.SET_FERTILIZATION_PRODUCT]: setFertilizationProduct,
  [Types.RESET_FERTILIZATION_PRODUCT]: resetFertilizationProduct,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as CropManagementTypes,
  Creators as CropManagementActions
}
