import React, { useState, useMemo } from 'react'

import PropTypes from 'prop-types'

import { groupService } from '@smartcoop/database/services/groupService'
import { database } from '@smartcoop/database/web-database'
import { useT } from '@smartcoop/i18n'
import { pencil } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'

import { DetailsTitle, EditNameInput } from './styles'

const ChatDetailsName = ({ conversation, isAdmin, isGroupMember }) => {
  const [editing, setEditing] = useState(false)
  const [name, setName] = useState(conversation?.name)

  const t = useT()
  const snackbar = useSnackbar()

  const saveGroupName = async () => {
    if (name?.length < 3) {
      snackbar.error(t('the name must contain at least 3 characters'))
      return
    }

    try {
      await groupService(database).updateGroupName(
        conversation.conversationId,
        name
      )

      snackbar.success(t('group name successfully changed'))
      setEditing(false)
    } catch(error) {
      snackbar.error(t('an error occurred while change group name'))
    }
  }

  const handleClick = async () => {
    if (editing) {
      await saveGroupName()
      return
    }

    setEditing(true)
    setName(conversation?.name)
  }

  const canEditName = useMemo(() => (
    (conversation.group && isAdmin && isGroupMember) ||
    (conversation.broadcast && isAdmin)
  ), [conversation, isAdmin, isGroupMember])

  return (
    <DetailsTitle>
      {/* Group or Broadcast Title */}
      { editing ? (
        <EditNameInput
          value={ name }
          onChange={ e => setName(e?.target?.value) }
          placeholder={ t('group name') }
        />
      ) : (
        <span>{conversation.name}</span>
      ) }

      {/* Action Button */}
      { canEditName && (
        <IconButton size="small" onClick={ handleClick }>
          <Icon icon={ pencil } size={ 14 } />
        </IconButton>
      ) }
    </DetailsTitle>
  )
}

ChatDetailsName.propTypes = {
  conversation: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isGroupMember: PropTypes.bool.isRequired
}

export default ChatDetailsName
