export const orderConversations = (conversations) => {
  const conversationList = [...conversations]
  const getChatLastMessage = (chat) => chat.conversation.chatUserRegistry[chat.conversation.chatUserRegistry.length - 1]
  const getChatData = (chat) => chat.conversation?.userCodes.find((user) => user.userId === currentUser?.id)
  const currentUser = {
    id: getChatLastMessage(conversationList[0])?.userId
  }
  conversationList.sort((chatA, chatB) => {
    const dataChatA = getChatData(chatA)
    const dataChatB = getChatData(chatB)
    const lastMessageSentOnChatA = new Date(getChatLastMessage(chatA)?.sendedAt).getTime()
    const lastMessageSentOnChatB = new Date(getChatLastMessage(chatB)?.sendedAt).getTime()
    const isFavoriteChatA = dataChatA?.favoritedThisChat || false
    const isFavoriteChatB = dataChatB?.favoritedThisChat || false
    const favoritedAtChatA = new Date(dataChatA?.favoritedAt).getTime()
    const favoritedAtChatB = new Date(dataChatB?.favoritedAt).getTime()
    if (isFavoriteChatA && !isFavoriteChatB) {
      return -1
    }
    if (!isFavoriteChatA && isFavoriteChatB) {
      return 1
    }
    if (isFavoriteChatA && isFavoriteChatB && favoritedAtChatA < favoritedAtChatB) {
      return -1
    }
    if (isFavoriteChatA && isFavoriteChatB && favoritedAtChatA > favoritedAtChatB) {
      return 1
    }
    if (lastMessageSentOnChatA > lastMessageSentOnChatB) {
      return -1
    }
    if (lastMessageSentOnChatA < lastMessageSentOnChatB) {
      return 1
    }
    return 0
  })
  return conversationList
}
