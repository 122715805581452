import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const post = ({ t }) => Yup.object().shape({
  text: flow(
    required({ t })
  )(Yup.string())
})

export default post
