import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/messaging'
import 'firebase/firestore'

const {
  initializeApp,
  storage,
  analytics,
  messaging,
  firestore
} = firebase

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY ,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL ,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ,
  appId: process.env.REACT_APP_FIREBASE_APP_ID ,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const firebaseApp = initializeApp(firebaseConfig)
analytics()

const firebaseStorage = storage
const firebaseMessaging = messaging.isSupported() ? messaging() : false
const firebaseFirestore = firestore
const firebaseAuth = firebase.auth

firebaseFirestore().enablePersistence()

export {
  firebaseAuth,
  firebaseApp,
  firebaseFirestore,
  firebaseStorage,
  messaging,
  firebaseMessaging
}
