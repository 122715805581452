import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const ArrowContainer = styled.span`
  font-size: 20px;
  margin-left: 5px;
  font-weight: bold;
`

export const Container = styled.div`
  min-width: 550px;
  color: #595959;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: ${ ({ maxContent }) => maxContent ? 'max-content' : 'inhert' };
  font-weight: ${ ({ bold }) => bold ? '600' : 'inhert' };
`

export const AverageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
`

export const AverageImage = styled.img`
  width: 3.5rem;
`

export const AverageNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const AverageNumber = styled.span`
  font-size: 3rem;
  font-weight: bold;
`

export const AverageType = styled.span`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
`

export const Description = styled.span`
  width: 100%;
  text-align: center;
`

export const Divider = styled.hr`
  margin: 1rem -24px;
  color: ${ colors.lightGrey };
`

export const Title = styled.span`
  color: ${ colors.darkGrey };
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
`

export const ContainerHour = styled.div`
  display: flex;
  flex-direction: Column;
`

export const ContainerCardHours = styled.div`
  display: flex;
  column-gap: 15px;
  width: 100%;
  overflow-y: auto;
  padding: 10px;
`

export const CardHours = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 0px 5px 1px #eeeeee;
  padding: 10px 15px;
  border-radius: 5px;
`

export const VerticalLine = styled.div`
  background-color: black;
  width: 5px;
  height: 100%;
`

export const ScrollDays = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  width: 100%;
  overflow-y: auto;
  padding: 10px;
  max-height: 300px;
`

export const ContainerDays = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 15px;
`

export const DayCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const DayCircle = styled.div`
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 50px;
  box-shadow: 0px 0px 3px 1px #e5e5e5;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: .9rem;
  line-height: .9rem;
`
