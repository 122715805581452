import React, { useCallback, forwardRef, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import padStart from 'lodash/padStart'
import times from 'lodash/times'
import toString from 'lodash/toString'
import trim from 'lodash/trim'

import fiscalRegisterSchema from '@smartcoop/forms/schemas/administration/fiscalRegister.schema'
import { useT } from '@smartcoop/i18n'
import { minus, plus } from '@smartcoop/icons'
import { searchStates } from '@smartcoop/services/apis/ibgeApi'
import { getProducts } from '@smartcoop/services/apis/smartcoopApi/resources/product'
import { NfeActions } from '@smartcoop/stores/nfe'
import { selectCFOP, selectNfeTablesData } from '@smartcoop/stores/nfe/selectorNfe'
import { formatNCM } from '@smartcoop/utils/formatters'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import InputFloat from '@smartcoop/web-components/InputFloat'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import { Container, Row, Subtitle, CustomIconButton, Divider, TableProducts, RequiredText } from './styles'

const FiscalRegisterForm = forwardRef((props, formRef) => {
  const { onSubmit, defaultValues, setDisabledSubmit } = props
  const t = useT()
  const dispatch = useDispatch()

  const nfeTablesData = useSelector(selectNfeTablesData)
  const CFOP = useSelector(selectCFOP)

  const [PISEntrada, setPISEntrada] = useState(null)
  const [PISSaida, setPISSaida] = useState(null)
  const [COFINSEntrada, setCOFINSEntrada] = useState(null)
  const [COFINSSaida, setCOFINSSaida] = useState(null)
  const [IPIEntrada, setIPIEntrada] = useState(null)
  const [IPISaida, setIPISaida] = useState(null)
  const [ufs, setUfs] = useState([])
  const [icms, setIcms] = useState(1)
  const [productsOptions, setProductsOptions] = useState([])
  const [products, setProducts] = useState(map(defaultValues?.products, item => item?.id) || [])
  const [cfop, setCfop] = useState(null)
  const [active, setActive] = useState(isEmpty(toString(defaultValues?.active)) ? 'true' : `${ defaultValues?.active }`)

  const isInactive = useMemo(() => active !== 'true', [active])

  useEffect(() => {
    setPISEntrada(find(nfeTablesData.PIS, item => padStart(item?.CST, 2, '0') === defaultValues?.pisEntrada))
    setPISSaida(find(nfeTablesData.PIS, item => padStart(item?.CST, 2, '0') === defaultValues?.pisSaida))
    setCOFINSEntrada(find(nfeTablesData.COFINS, item => padStart(item?.CST, 2, '0') === defaultValues?.cofinsEntrada))
    setCOFINSSaida(find(nfeTablesData.COFINS, item => padStart(item?.CST, 2, '0') === defaultValues?.cofinsSaida))
    setIPIEntrada(find(nfeTablesData.IPI, item => padStart(item?.CST, 2, '0') === defaultValues?.ipiEntrada))
    setIPISaida(find(nfeTablesData.IPI, item => padStart(item?.CST, 2, '0') === defaultValues?.ipiSaida))
  },[nfeTablesData, defaultValues])

  useEffect(() => {
    if(defaultValues?.cfop?.code) {
      setCfop(find(CFOP, item => item?.code === defaultValues?.cfop?.code)?.operationType)
    }
  },[CFOP, defaultValues])

  const loadProducts = useCallback(async () => {
    const { data: { data } } = await getProducts({ nfe: true, limit: 99999 })

    setProductsOptions(data)
  },[])

  useEffect(() => {
    loadProducts()
    dispatch(NfeActions.loadNfeTablesData())
    dispatch(NfeActions.loadCFOP())
  }, [dispatch, loadProducts])

  const handleSubmit = useCallback(
    (data) => {
      const updatedData = { ...data }
      if(!defaultValues?.id) {
        map([...updatedData.fiscalFigureIcms], (_, key) => {
          delete updatedData.fiscalFigureIcms[key].id
        })
      }
      onSubmit({
        ...defaultValues,
        ...data,
        active: data?.active === 'true'
      })
    },
    [defaultValues, onSubmit]
  )

  useEffect(
    () => {
      async function findStates() {
        let data = await searchStates()
        data = map(data, ({ sigla }) => ({ label: sigla, value: sigla }))
        setUfs(data)
      }
      findStates()
    },
    []
  )

  useEffect(() => {
    setDisabledSubmit(icms === 0 || isNil(icms))
  },[icms, setDisabledSubmit])

  const onChangeCfop = useCallback((_, { item }) => {
    setCfop(item?.operationType)
    formRef.current.getFieldRef('cfop.operationType').setValue(item?.operationType)
  }, [formRef])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flex: 1, flexDirection: 'column' } }
        ref={ formRef }
        schemaConstructor={ fiscalRegisterSchema }
        onSubmit={ handleSubmit }
        schemaProps={ { cfop, defaultValues } }
      >
        <Row>
          <InputText
            label={ t('description') }
            name="description"
            defaultValue={ defaultValues?.description }
            multiple
            style={ { width: defaultValues?.cfop?.code ? '70%' : '100%', marginRight: defaultValues?.cfop?.code ? 5 : 0 } }
            disabled={ isInactive }
          />
          {defaultValues?.cfop?.code && (
            <InputSelect
              label={ t('status') }
              name="active"
              options={ [{ label: t('active', { gender: 'male' }), value: 'true' }, { label: t('inactive', { gender: 'male' }), value: 'false' }] }
              onChange={ ({ target: { value } }) => setActive(value) }
              defaultValue={ `${ defaultValues?.active }` }
              style={ { width: '30%' } }
            />
          )}
        </Row>
        <Row>
          <InputSelect
            label="CFOP"
            name="cfop.code"
            options={ map(CFOP, item => ({ value: item?.code, label: `${ item?.code } - ${ item?.description }`, item })) }
            onChange={ onChangeCfop }
            defaultValue={ defaultValues?.cfop?.code }
            disabled={ isInactive }
          />
          <InputText
            name="cfop.operationType"
            disabled
            defaultValue={ defaultValues?.cfop?.operationType }
            style={ { display: 'none' } }
          />
        </Row>
        <Row>
          <InputSelect
            label={ t('products') }
            name="products"
            options={ map(productsOptions, item => ({
              label: `${ item.shortDescription } - NCM ${ formatNCM(item?.ncm) }`,
              value: item.id
            })) }
            defaultValue={ map(defaultValues?.products, item => item?.id) }
            onChange={ ({ target: { value } }) => setProducts(value) }
            multiple
            disabled={ isInactive }
          />
        </Row>
        {!isEmpty(products) && (
          <Row style={ { justifyContent: 'center' } }>
            <TableProducts>
              <thead>
                <th>Grupo</th>
                <th>Produto</th>
                <th>NCM</th>
                <th>Unidade</th>
              </thead>
              {map(filter(productsOptions, item => includes(products, item.id)), item => (
                <tr>
                  <td style={ { textAlign: 'left' } }>{item?.productGroup?.name}</td>
                  <td style={ { textAlign: 'left' } }>{item?.shortDescription}</td>
                  <td>{formatNCM(item?.ncm)}</td>
                  <td>{item?.unitOfMeasures}</td>
                </tr>
              ))}
            </TableProducts>
          </Row>
        )}
        {
          !isEmpty(cfop) && (
            <>
              <Row style={ { marginTop: 20 } }>
                <Subtitle>
          Tributos Federais
                </Subtitle>
              </Row>
              {cfop === 'S' ? (
                <>
                  <Row>
                    <InputSelect
                      label="PIS saída"
                      name="pisSaida"
                      options={ map(nfeTablesData?.PIS, item => ({ value: padStart(item?.CST, 2, '0'), label: `CST ${ padStart(item?.CST, 2, '0') } - ${ item.Descricao }`  }) ) }
                      defaultValue={ defaultValues?.pisSaida }
                      onChange={ ({ target: { value } }) => setPISSaida(find(nfeTablesData.PIS, item => item?.CST === value)) }
                      fullWidth
                      style={ { marginRight: 5 } }
                      disabled={ isInactive }
                    />
                    <InputText
                      detached
                      disabled
                      visibleButDisabled
                      value={ PISSaida?.Aliquota }
                      label="Alíquota"
                      style={ { marginRight: 5 } }
                    />
                  </Row>
                  <Row>
                    <InputSelect
                      label="COFINS saída"
                      name="cofinsSaida"
                      options={ map(nfeTablesData?.COFINS, item => ({ value: padStart(item?.CST, 2, '0'), label: `CST ${ padStart(item?.CST, 2, '0') } - ${ item.Descricao }` }) ) }
                      defaultValue={ defaultValues?.cofinsSaida }
                      onChange={ ({ target: { value } }) => setCOFINSSaida(find(nfeTablesData.COFINS, item => item?.Codigo === value)) }
                      fullWidth
                      style={ { marginRight: 5 } }
                      disabled={ isInactive }
                    />
                    <InputText
                      detached
                      disabled
                      visibleButDisabled
                      value={ COFINSSaida?.Aliquota }
                      label="Alíquota"
                      style={ { marginRight: 5 } }
                    />
                  </Row>
                  <Row>
                    <InputSelect
                      label="IPI saída"
                      name="ipiSaida"
                      options={ map(nfeTablesData?.IPI, item => ({ value: padStart(item?.CST, 2, '0'), label: `CST ${ item.Descricao }` }) ) }
                      defaultValue={ defaultValues?.ipiSaida }
                      onChange={ ({ target: { value } }) => setIPISaida(find(nfeTablesData.IPI, item => item?.Codigo === value)) }
                      fullWidth
                      style={ { marginRight: 5 } }
                      disabled={ isInactive }
                    />
                    <InputText
                      detached
                      disabled
                      visibleButDisabled
                      value={ IPISaida?.Aliquota }
                      label="Alíquota"
                      style={ { marginRight: 5 } }
                    />
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <InputSelect
                      label="PIS entrada"
                      name="pisEntrada"
                      options={ map(nfeTablesData?.PIS, item => ({ value:padStart(item?.CST, 2, '0'), label: `CST ${ padStart(item?.CST, 2, '0') } - ${ item.Descricao }` }) ) }
                      defaultValue={ defaultValues?.pisEntrada }
                      onChange={ ({ target: { value } }) => setPISEntrada(find(nfeTablesData.PIS, item => item?.Codigo === value)) }
                      fullWidth
                      style={ { marginRight: 5 } }
                      disabled={ isInactive }
                    />
                    <InputText
                      detached
                      disabled
                      visibleButDisabled
                      value={ PISEntrada?.Aliquota }
                      label="Alíquota"
                      style={ { marginRight: 5 } }
                    />
                  </Row>
                  <Row>
                    <InputSelect
                      label="COFINS entrada"
                      name="cofinsEntrada"
                      options={ map(nfeTablesData?.COFINS, item => ({ value: padStart(item?.CST, 2, '0'), label: `CST ${ padStart(item?.CST, 2, '0') } - ${ item.Descricao }` }) ) }
                      defaultValue={ defaultValues?.cofinsEntrada }
                      onChange={ ({ target: { value } }) => setCOFINSEntrada(find(nfeTablesData.COFINS, item => item?.Codigo === value)) }
                      fullWidth
                      style={ { marginRight: 5 } }
                      disabled={ isInactive }
                    />
                    <InputText
                      detached
                      disabled
                      visibleButDisabled
                      value={ COFINSEntrada?.Aliquota }
                      label="Alíquota"
                      style={ { marginRight: 5 } }
                    />
                  </Row>
                  <Row>
                    <InputSelect
                      label="IPI entrada"
                      name="ipiEntrada"
                      options={ map(nfeTablesData?.IPI, item => ({ value: padStart(item?.CST, 2, '0'), label: `CST ${ item.Descricao }` }) ) }
                      defaultValue={ defaultValues?.ipiEntrada }
                      onChange={ ({ target: { value } }) => setIPIEntrada(find(nfeTablesData.IPI, item => item?.Codigo === value)) }
                      fullWidth
                      style={ { marginRight: 5 } }
                      disabled={ isInactive }
                    />
                    <InputText
                      detached
                      disabled
                      visibleButDisabled
                      value={ IPIEntrada?.Aliquota }
                      label="Alíquota"
                      style={ { marginRight: 5 } }
                    />
                  </Row>
                </>
              )}

              <Row style={ { marginTop: 20, marginBottom: 20, justifyContent: 'space-between' } }>
                <Subtitle>
          ICMS
                </Subtitle>
                {(icms === 0 || isNil(icms)) && (
                  <RequiredText>* É necessário ao menos 1 IMPOSTO SOBRE CIRCULAÇÃO DE MERCADORIAS E PRESTAÇÃO DE SERVIÇOS </RequiredText>
                )}
                <CustomIconButton secondary onClick={ !isInactive ? () => setIcms(old => old+1) : null }>
                  <Icon icon={ plus } size={ 20 } />
                </CustomIconButton>
              </Row>
              { times(icms, (item) => (
                <div key={ item }>
                  {item !== 0 && (
                    <Row>
                      <Divider />
                    </Row>
                  )}

                  <Row style={ { display: 'none' } }>
                    <InputText
                      label=""
                      name={ `fiscalFigureIcms[${ item }].id` }
                      defaultValue={ defaultValues?.fiscalFigureIcms[item]?.id }
                      disabled={ isInactive }
                    />
                    <InputText
                      label=""
                      name={ `fiscalFigureIcms[${ item }].nfeFiscalFigureId` }
                      defaultValue={ defaultValues?.fiscalFigureIcms[item]?.nfeFiscalFigureId }
                      disabled={ isInactive }
                    />
                  </Row>
                  <Row>
                    <InputSelect
                      label={ t('origin') }
                      name={ `fiscalFigureIcms[${ item }].origemUf` }
                      options={ ufs }
                      defaultValue={ defaultValues?.fiscalFigureIcms[item]?.origemUf || '' }
                      style={ { marginRight: 5 } }
                      disabled={ isInactive }
                    />
                    <InputSelect
                      label={ t('destination') }
                      name={ `fiscalFigureIcms[${ item }].destinoUf` }
                      options={ ufs }
                      defaultValue={ defaultValues?.fiscalFigureIcms[item]?.destinoUf || '' }
                      disabled={ isInactive }
                    />
                  </Row>

                  {cfop === 'S' ? (
                    <>
                      <Row>
                        <InputSelect
                          label="Saída PF"
                          name={ `fiscalFigureIcms[${ item }].icmsSaidaPf` }
                          options={ map(nfeTablesData?.ICMS, itemICMS => ({ value: itemICMS.Codigo, label: `CST ${ itemICMS.Descricao }` }) ) }
                          defaultValue={ defaultValues?.fiscalFigureIcms[item]?.icmsSaidaPf || '' }
                          disabled={ isInactive }
                        />
                      </Row>
                      <Row>
                        <InputSelect
                          label="Saída PJ"
                          name={ `fiscalFigureIcms[${ item }].icmsSaidaPj` }
                          options={ map(nfeTablesData?.ICMS, itemICMS => ({ value: itemICMS.Codigo, label: `CST ${ itemICMS.Descricao }` }) ) }
                          defaultValue={ defaultValues?.fiscalFigureIcms[item]?.icmsSaidaPj || '' }
                          disabled={ isInactive }
                        />
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row>
                        <InputSelect
                          label="Entrada PF"
                          name={ `fiscalFigureIcms[${ item }].icmsEntradaPf` }
                          options={ map(nfeTablesData?.ICMS, itemICMS => ({ value: itemICMS.Codigo, label: `CST ${ itemICMS.Descricao }` }) ) }
                          defaultValue={ defaultValues?.fiscalFigureIcms[item]?.icmsEntradaPf || '' }
                          disabled={ isInactive }
                        />
                      </Row>
                      <Row>
                        <InputSelect
                          label="Entrada PJ"
                          name={ `fiscalFigureIcms[${ item }].icmsEntradaPj` }
                          options={ map(nfeTablesData?.ICMS, itemICMS => ({ value: itemICMS.Codigo, label: `CST ${ itemICMS.Descricao }` }) ) }
                          defaultValue={ defaultValues?.fiscalFigureIcms[item]?.icmsEntradaPj || '' }
                          disabled={ isInactive }
                        />
                      </Row>
                    </>
                  )}

                  <Row>
                    <InputFloat
                      decimalScale={ 4 }
                      label="% Tributos Federais (IBPT)"
                      name={ `fiscalFigureIcms[${ item }].impostoFederal` }
                      defaultValue={ defaultValues?.fiscalFigureIcms[item]?.impostoFederal || '0.0000' }
                      fullWidth
                      style={ { marginRight: 5 } }
                      disabled={ isInactive }
                    />
                    <InputFloat
                      decimalScale={ 4 }
                      label="% Tributos Estaduais (IBPT)"
                      name={ `fiscalFigureIcms[${ item }].impostoEstadual` }
                      defaultValue={ defaultValues?.fiscalFigureIcms[item]?.impostoEstadual || '0.0000' }
                      fullWidth
                      style={ { marginRight: 5 } }
                      disabled={ isInactive }
                    />
                    <InputFloat
                      decimalScale={ 4 }
                      label="% Tributos Municipais (IBPT)"
                      name={ `fiscalFigureIcms[${ item }].impostoMunicipal` }
                      defaultValue={ defaultValues?.fiscalFigureIcms[item]?.impostoMunicipal || '0.0000' }
                      fullWidth
                      disabled={ isInactive }
                    />
                  </Row>
                  <Row>
                    <InputSelect
                      label={ t('decree') }
                      name={ `fiscalFigureIcms[${ item }].decreto` }
                      options={ map(nfeTablesData?.DispLegal, disp => ({ value: trim(disp?.Codigo), label: `Codigo ${ trim(disp?.Codigo) } - ${ disp.Descricao }` }) ) }
                      defaultValue={ toString(defaultValues?.fiscalFigureIcms[item]?.decreto) }
                      disabled={ isInactive }
                    />
                  </Row>
                  <Row style={ { marginBottom: 20, justifyContent: 'space-between' } }>
                    <InputText
                      label="Código de Benefício Fiscal"
                      name={ `fiscalFigureIcms[${ item }].cbenef` }
                      defaultValue={ defaultValues?.fiscalFigureIcms[item]?.cbenef || '' }
                      style={ { width: '50%' } }
                      disabled={ isInactive }
                    />
                    <CustomIconButton onClick={ !isInactive ? () => setIcms(old => old-1) : null }>
                      <Icon icon={ minus } size={ 20 } />
                    </CustomIconButton>
                  </Row>
                </div>
              ))}
            </>
          )
        }
      </Form>
    </Container>
  )
})

FiscalRegisterForm.propTypes = {
  onSubmit: PropTypes.func,
  setDisabledSubmit: PropTypes.func,
  defaultValues: PropTypes.object
}

FiscalRegisterForm.defaultProps = {
  onSubmit: () => {},
  setDisabledSubmit: () => {},
  defaultValues: null
}

export default FiscalRegisterForm
