import React, { useState, useMemo, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'

import { isEmpty, isUndefined } from 'lodash'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { arrowLeft, filter } from '@smartcoop/icons'
import {
  getMilkDeliveries as getMilkDeliveriesService
} from '@smartcoop/services/apis/smartcoopApi/resources/dairyFarm'
import { useSnackbar } from '@smartcoop/snackbar'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import { formatNumber } from '@smartcoop/utils/formatters'
import Badge from '@smartcoop/web-components/Badge'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import Loader from '@smartcoop/web-components/Loader'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import FilterMilkDeliveryModal from '@smartcoop/web-containers/modals/dairyFarm/FilterMilkDeliveryModal'
import RegisterMilkDeliveryModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterMilkDeliveryModal'

import { Top, Container, Row, Title, SingleButtonContainer, WarningDiv } from './styles'

const ListMilkDeliveryScreen = () => {
  const dispatch = useCallback(useDispatch(), [])
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()
  const tableRef = useRef(null)
  const history = useHistory()
  const t = useT()

  const currentProperty = useSelector(selectCurrentProperty)
  const userWrite = useSelector(selectUserCanWrite)

  const [filters, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const dairyFarm = useMemo(
    () => currentProperty?.dairyFarm[0],
    [currentProperty]
  )

  const urlParams = useMemo(
    () => ({ dairyFarmId: dairyFarm?.id }),
    [dairyFarm]
  )

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )

  const columns = useMemo(
    () => [
      {
        title: t('volume date'),
        field: 'volumeDate',
        sorting: true,
        defaultSort: 'desc',
        render: (row) => moment(row.volumeDate, momentBackDateFormat).format(momentFriendlyDateFormat)
      },
      {
        title: t('volume'),
        field: 'volume',
        sorting: true,
        render: (row) => `${ row.volume  } ${ t('liters') }`
      },
      {
        title: t('condemned volume'),
        field: 'condemnedVolume',
        sorting: true,
        render: (row) => isUndefined(row.condemnedVolume) ? '-' : `${ row.condemnedVolume  } ${  t('liters') }`
      },
      {
        title: t('temperature'),
        field: 'temperature',
        sorting: true,
        render: (row) => isUndefined(row.temperature) ? '-' : `${ formatNumber(row.temperature) } °C`
      },
      {
        title: t('state registration'),
        field: 'userStateRegistration.stateRegistration',
        render: (row) => row?.userStateRegistration?.stateRegistration ?? '-'
      },
      {
        title: t('status'),
        field: 'status',
        render: (row) => (
          <div style={ { display: 'flex' } }>
            <Badge
              backgroundColorBadge={ row.status.color }
              colorBadge={ row.status.color }
            >
              {row.status.name}
            </Badge>
          </div>
        )
      }
    ], [t]
  )

  const registerDelivery = useCallback(
    (data = {}) => {
      createDialog({
        id: 'register-delivery',
        Component: RegisterMilkDeliveryModal,
        props: {
          data,
          reloadDataTable
        }
      })
    },
    [createDialog, reloadDataTable]
  )

  const filterData = useCallback(
    (data) => {
      setFilters(data)
    }, []
  )

  const filterDelivery = useCallback(
    () => {
      createDialog({
        id: 'filter-delivery',
        Component: FilterMilkDeliveryModal,
        props: {
          onSubmit: filterData,
          filters
        }
      })
    },
    [createDialog, filterData, filters]
  )

  const onEditClick = useCallback(
    (_, data) => registerDelivery(data),
    [registerDelivery]
  )

  const handleDeliveryDelete = useCallback(
    (data) => {
      createDialog({
        id: 'confirm-delete-delivery',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            setIsLoading(true)
            dispatch(DairyFarmActions.deleteMilkDelivery(
              data.id,
              () => {
                setIsLoading(false)
                reloadDataTable()
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'female',
                    this: t('delivery', { howMany: 1 })
                  })
                )
              },
              () => {
                setIsLoading(false)
                reloadDataTable()
              }
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            gender: 'female',
            howMany: 1,
            this: t('delivery', { howMany: 1 })
          })
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, snackbar, t]
  )

  const onDeleteClick = useCallback(
    (_, data) => handleDeliveryDelete(data),
    [handleDeliveryDelete]
  )

  const isManualStatus = useCallback(
    (row) => (
      row.status?.slug
      && row.status?.slug === 'manual'
    ),
    []
  )

  const isCooperative = useCallback(
    (row) => !isEmpty(row.organizationId),
    []
  )

  return (
    isLoading ? <Loader width={ 100 } /> :
      <Container>
        <Top>
          <IconButton
            tooltip={ t('go back') }
            onClick={ () => history.goBack() }
          >
            <Icon icon={ arrowLeft } size={ 16 } />
          </IconButton>
          <Title>
            <I18n>milk deliveries</I18n>
          </Title>
        </Top>
        <Row>
          <SingleButtonContainer>
            { (isEmpty(dairyFarm) || isCooperative(dairyFarm)) && <WarningDiv>
              <I18n>data inclusion will be done by the organization</I18n>
            </WarningDiv>}
            <Button
              id="filter"
              color="white"
              style={ {
                flex: 'none',
                whiteSpace: 'nowrap',
                marginRight: 10,
                backgroundColor: isEmpty(filters) ? colors.white : colors.secondary
              } }
              onClick={ filterDelivery }
              disabled={ isEmpty(dairyFarm) }
            >
              <>
                <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 }/>
                <I18n>filtrate</I18n>
              </>
            </Button>
            <Button
              id="new-milk-delivery"
              color="secondary"
              style={ { flex: 'none', whiteSpace: 'nowrap' } }
              onClick={ () => registerDelivery() }
              disabled={ isEmpty(dairyFarm) || isCooperative(dairyFarm) || !userWrite }
            >
              <I18n>register delivery</I18n>
            </Button>
          </SingleButtonContainer>
        </Row>

        {!isEmpty(dairyFarm) && (
          <DataTable
            tableRef={ tableRef }
            data={ getMilkDeliveriesService }
            columns={ columns }
            urlParams={  urlParams }
            queryParams={ { ...filters, startDate: filters?.volumeDate?.from, endDate: filters?.volumeDate?.to , volumeDate: null } }
            onEditClick={ onEditClick }
            disabledEdit={ (row) => !isManualStatus(row) || !userWrite }
            conditionToDelete={ (row) => isManualStatus(row) && userWrite }
            onDeleteClick={ onDeleteClick }
            id="list-milk-delivery-table"
          />
        )}
      </Container>
  )
}

export default ListMilkDeliveryScreen
