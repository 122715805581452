import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

/* Initial State */
const INITIAL_STATE = {
  barters: [],
  packages: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  saveProducerBarter: ['barter', 'onSuccess', 'onError'],
  saveOfflineProducerBarter: ['barter', 'onSuccess', 'onError'],

  joinBarterPackage: ['barter', 'onSuccess', 'onError'],
  joinOfflineBarterPackage: ['barter', 'onSuccess', 'onError'],

  loadBarters: ['params', 'onSuccess', 'onError'],
  loadBartersSuccess: ['barters', 'page', 'onSuccess'],

  loadPackages: ['params', 'onSuccess', 'onError'],
  loadPackagesSuccess: ['packages', 'page', 'onSuccess'],

  deleteOfflineBarter: ['barterOrderNumber', 'onSuccess', 'onError'],
  deleteBarter: ['barterOrderNumber', 'onSuccess', 'onError'],

  resetBarters: [],

  barterError: ['error']
})

markActionsOffline(Creators, [
  'saveBarter',
  'deleteBarter'
])

const loadBarters = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error
})

const loadBartersSuccess = (state = INITIAL_STATE, { barters, page }) => ({
  ...state,
  barters: page === 1 ? barters : [
    ...state.barters,
    ...barters
  ]
})

const loadPackages = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error
})

const loadPackagesSuccess = (state = INITIAL_STATE, { packages, page }) => ({
  ...state,
  packages: page === 1 ? packages : [
    ...state.packages,
    ...packages
  ]
})

const barterError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const resetBarters = (state = INITIAL_STATE) => ({
  ...state,
  barters: INITIAL_STATE.barter
})

const logout = () => ({ ...INITIAL_STATE })
const resetAuthentication = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_BARTERS]: loadBarters,
  [Types.LOAD_BARTERS_SUCCESS]: loadBartersSuccess,

  [Types.LOAD_PACKAGES]: loadPackages,
  [Types.LOAD_PACKAGES_SUCCESS]: loadPackagesSuccess,

  [Types.RESET_BARTERS]: resetBarters,

  [Types.BARTER_ERROR]: barterError,

  [AuthenticationTypes.LOGOUT]: logout,
  [AuthenticationTypes.RESET_AUTHENTICATION]: resetAuthentication
})

export {
  Types as BarterTypes,
  Creators as BarterActions
}
