import React from 'react'

import PropTypes from 'prop-types'

import { organizationRounded } from '@smartcoop/icons'
import Avatar from '@smartcoop/web-components/Avatar'
import Icon from '@smartcoop/web-components/Icon'
import ReadMore from '@smartcoop/web-components/ReadMore'

import { Body, Col,  Container, Header, Identifier, Name,  Text, Privacy, Type } from './styles'

const SimpleComment = (props) =>  {
  const { style, commentStyle, comment: { text, user, organization }, length, productWall } = props

  return (
    <Container style={ style } >
      <Header>
        <Identifier>
          <Avatar alt={ user.name } src={ user.image } />
          <Col>
            <Name>{user.name}</Name>
            { productWall && <Privacy>
              <Icon icon={ organizationRounded } />
              <Type>{organization.tradeName}</Type>
            </Privacy>}
          </Col>
        </Identifier>
      </Header>
      <Body>
        <Text style={ commentStyle }>
          <ReadMore length={ length }>
            { text }
          </ReadMore>
        </Text>
      </Body>
    </Container>
  )
}

SimpleComment.propTypes = {
  style: PropTypes.object,
  commentStyle: PropTypes.object,
  comment: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    userId: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      image: PropTypes.string
    }),
    organization: PropTypes.shape({
      companyName: PropTypes.string,
      id: PropTypes.string,
      tradeName: PropTypes.string
    })
  }).isRequired,
  length: PropTypes.number,
  productWall: PropTypes.bool
}

SimpleComment.defaultProps = {
  style: {},
  commentStyle: {
    fontSize: '16px',
    lineHeight: '22px',
    color:'#595959'
  },
  length: 200,
  productWall: false
}

export default SimpleComment
