import { barn, currencyWithoutSpace, dashboard, shoppingPlatform } from '@smartcoop/icons'
import { AVAILABLE_MODULES } from '@smartcoop/stores/module'

export const iconByModule = (mod) => {
  switch(mod) {
    default:
    case AVAILABLE_MODULES.digitalProperty:
      return barn
    case AVAILABLE_MODULES.commercialization:
      return currencyWithoutSpace
    case AVAILABLE_MODULES.shoppingPlatform:
      return shoppingPlatform
    case AVAILABLE_MODULES.administration:
      return dashboard
  }
}
