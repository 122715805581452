import React from 'react'

import PropTypes from 'prop-types'

import InputAdornment from '@material-ui/core/InputAdornment'

import DynamicInput from '@smartcoop/web-components/DynamicInput'
import Icon from '@smartcoop/web-components/Icon'

const InputIcon = (props) => {
  const { onClick, size, icon, color, style, adornmentStyle, type, ...rest } = props

  return (
    <DynamicInput
      { ...rest }
      type={ type }
      style={ { ...style } }
      InputProps={ {
        endAdornment: (
          <InputAdornment style={ { ...adornmentStyle } } position="end">
            <Icon
              icon={ icon }
              size={ size }
              color={ color }
              onClick={ () => onClick() }
            />
          </InputAdornment>
        )
      } }
    />
  )
}

InputIcon.propTypes = {
  icon: PropTypes.func.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
  adornmentStyle: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.string
}

InputIcon.defaultProps = {
  size: 18,
  color: '#151818',
  style: {},
  adornmentStyle: {
    marginRight: 10
  },
  onClick: () => {},
  type: 'text'
}

export default InputIcon
