import styled from 'styled-components'

import Button from '@material-ui/core/Button'

export const StyledButton = styled(Button)`
  flex: 1;
  width: ${ ({ fullWidth }) => fullWidth ? '100%' : 'auto' };
  justify-content: ${ (({ spaced }) => spaced ? 'space-between' : 'center') };
  text-transform: capitalize;
  font-size: 16px;
`
