import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import withObservables from '@nozbe/with-observables'
import moment from 'moment/moment'
import PropTypes from 'prop-types'


import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import toString from 'lodash/toString'

import Grid from '@material-ui/core/Grid'

import { animalService } from '@smartcoop/database/services/animalService'
import { database } from '@smartcoop/database/web-database'
import registerPregnancyActionsSchema from '@smartcoop/forms/schemas/dairyFarm/registerPregnancyActions.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { LotActions } from '@smartcoop/stores/lot'
import {
  selectLotsOptions
} from '@smartcoop/stores/lot/selectorLot'
import { colors } from '@smartcoop/styles'
import { AnimalStatusCode } from '@smartcoop/utils/constants'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'

import {
  Container,
  FormContainer,
  ButtonsContainer,
  Item
} from './styles'

const RegisterPregnancyActionsForm = (props) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel,
    animalsList,
    formRef,
    propertyId
  } = props

  const t = useT()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const [disableLot, setDisableLot] = useState(false)
  const [statusDoesNotMatchWithType, setStatusDoesNotMatchWithType] = useState(
    false
  )
  const [selectedAnimals, setSelectedAnimals] = useState([])

  const lotsOptions = useSelector(selectLotsOptions)
  const currentAnimal = useSelector(selectCurrentAnimal)

  useEffect(() => {
    dispatch(
      LotActions.loadLots(
        { propertyId },
        () => {},
        (err) => {
          snackbar.error(t(err.message))
        }
      )
    )
  }, [dispatch, propertyId, snackbar, t])

  useEffect(() => {
    const { type } = defaultValues
    if (type !== 'aborto') {
      setDisableLot(true)
    }
  }, [defaultValues])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({ ...data })
    },
    [onSubmit]
  )

  const handleTypeChange = useCallback((value) => {
    const lotPreParto = find(lotsOptions, item => item?.label === 'Pré-parto')
    const lotSeca = find(lotsOptions, item => item?.label === 'Seca')
    switch (value) {
      case 'aborto':
        setDisableLot(false)
        formRef.current.getFieldRef('lotId').setValue('')
        setStatusDoesNotMatchWithType(
          (currentAnimal?.statusName || currentAnimal?.animalStatus?.name) !== 'Prenha'
        )
        break
      case 'pre-parto':
        setDisableLot(true)
        formRef.current
          .getFieldRef('lotId')
          .setValue(lotPreParto?.value)
        setStatusDoesNotMatchWithType(
          (currentAnimal?.statusName || currentAnimal?.animalStatus?.name) !== 'Prenha'
        )
        break
      case 'secagem':
        setDisableLot(true)
        formRef.current
          .getFieldRef('lotId')
          .setValue(lotSeca?.value)
        setStatusDoesNotMatchWithType(
          (currentAnimal?.statusName || currentAnimal?.animalStatus?.name) !== 'Prenha' ||
            currentAnimal?.category !== 'vaca'
        )
        break
      default:
    }
  }, [currentAnimal, formRef, lotsOptions])

  const animals = useMemo(() => {
    let filteredAnimals = filter(animalsList, item => (item?.category === 'vaca' ||
      item?.category === 'novilha') &&
      (toString(item?.statusId) === AnimalStatusCode.INSEMINADA ||
        toString(item?.statusId) === AnimalStatusCode.INSEMINADA_A_CONFIRMAR ||
        toString(item?.statusId) === AnimalStatusCode.PRENHA))

    if(!isEmpty(selectedAnimals)) {
      filteredAnimals = filter([...filteredAnimals], item => toString(item?.statusId) === toString(selectedAnimals?.[0]?.statusId))
    }
    return map(filteredAnimals, item => ({ label: item?.earringCode, value: item.animalId }))
  }, [animalsList, selectedAnimals])

  useEffect(() => {
    setSelectedAnimals(filter(animalsList, item => includes([defaultValues?.animalId], item?.animalId)))
  }, [animalsList, defaultValues])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerPregnancyActionsSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <InputSelect
              label={ t('earrings') }
              name="animalsId"
              options={ animals }
              multiple
              defaultValue={ [defaultValues?.animalId] }
              disabled={ !!defaultValues?.id }
              onChange={ (value) => {
                setSelectedAnimals(filter(animalsList, item => includes(value, item?.animalId)))
              } }
              checkBoxSelectAll
            />
            <Item>
              <InputSelect
                label={ t('type') }
                name="type"
                options={ [
                  {
                    label: t('drying'),
                    value: 'secagem'
                  },
                  {
                    label: t('prepartum'),
                    value: 'pre-parto'
                  },
                  {
                    label: t('abortion'),
                    value: 'aborto'
                  }
                ] }
                onChange={ ({ target: { value } }) => handleTypeChange(value) }
                defaultValue={ defaultValues?.type }
              />
            </Item>
            <Item>
              <InputSelect
                label={ t('lot', { howMany: 1 }) }
                name="lotId"
                options={ lotsOptions }
                defaultValue={ defaultValues?.animal?.lot?.id }
                disabled={ disableLot }
              />
            </Item>
            <Item>
              <InputDate
                label={ t('accomplished date') }
                name="accomplishedDate"
                pickerProps={ {
                  maxDate: moment().format()
                } }
                fullWidth
                defaultValue={
                  defaultValues?.accomplishedDate
                    ? moment(
                      defaultValues?.accomplishedDate,
                      momentBackDateFormat
                    )
                    : moment()
                }
              />
            </Item>
            {statusDoesNotMatchWithType && (
              <Item style={ { marginBottom: 15 } }>
                <I18n style={ { color: colors.red } }>
                  invalid animal status or category
                </I18n>
              </Item>
            )}
          </Grid>
        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-cancel-form-button"
              onClick={ onCancel }
              color={ colors.white }
              disabled={ loading }
              fullWidth
              style={ { marginRight: 5 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="web-save-form-button"
              onClick={ () => formRef.current.submit() }
              disabled={ loading || statusDoesNotMatchWithType }
              fullWidth
              style={ { marginLeft: 5 } }
            >
              <I18n>register</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
}

RegisterPregnancyActionsForm.propTypes = {
  loading: PropTypes.bool,
  animalsList: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  propertyId: PropTypes.string,
  defaultValues: PropTypes.object.isRequired,
  formRef: PropTypes.object.isRequired
}

RegisterPregnancyActionsForm.defaultProps = {
  propertyId: null,
  loading: false,
  animalsList: [],
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false
}

const enhance = withObservables(['propertyId'], ({ propertyId }) => ({
  animalsList: animalService(database).observeAnimals(propertyId)
}))

const EnhancedRegisterPregnancyActionsForm = enhance(RegisterPregnancyActionsForm)

export default EnhancedRegisterPregnancyActionsForm