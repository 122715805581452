import moment from 'moment/moment'

export default ({ t, minHour, text }) => YupInstance => YupInstance
  .test('hour', text ?? t('invalid hour'), (date) => {
    const format = 'HH:mm'

    const dateMoment = moment(date, format)

    const minHourFormatted = moment(minHour, format)

    return dateMoment.isAfter(minHourFormatted)
  })
