import styled from 'styled-components'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`

export const ButtonContainer = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
`

export const TextDate = styled.div`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  padding: 10px 0;
`

export const ContentRadioGroup = styled.div`
  margin-top: 15px;
`
