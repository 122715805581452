import React from 'react'

import PropTypes from 'prop-types'

import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import InputFile from '@smartcoop/web-components/InputFile'

import { Container, Content, Label } from './styles'

function EmptyState(props) {
  const {
    style,
    contentStyle,
    labelStyle,
    text,
    icon,
    iconProps,
    isInputFile,
    file,
    action,
    buttonProps
  } = props

  return (
    <Container style={ style }>
      {icon && <Icon icon={ icon } { ...iconProps } />}
      <Content style={ contentStyle }>
        <Label style={ labelStyle }>{text}</Label>
        {action.text &&
          (isInputFile ?
            <InputFile
              idInput={ file.idInput }
              onChange={ action.onClick }
              inputProps={ file.inputProps }
              buttonProps={ buttonProps }
              { ...props }
            >
              {action.text}
            </InputFile>
            :
            <Button
              id="button"
              color="secondary"
              onClick={ action.onClick }
              { ...buttonProps }
            >
              {action.text}
            </Button>
          )
        }
      </Content>
    </Container>
  )
}

EmptyState.propTypes = {
  style: PropTypes.object,
  contentStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  text: PropTypes.string.isRequired,
  icon: PropTypes.func,
  iconProps: PropTypes.object,
  isInputFile: PropTypes.bool,
  file: PropTypes.shape({
    idInput: PropTypes.string,
    inputProps: PropTypes.object
  }),
  action: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func
  }),
  buttonProps: PropTypes.object
}

EmptyState.defaultProps = {
  style: {},
  contentStyle: {},
  labelStyle: {},
  icon: null,
  iconProps: {
    size: 176
  },
  isInputFile: false,
  file: {
    idInput: '',
    inputProps: {}
  },
  action: {
    text: '',
    onClick: () => {}
  },
  buttonProps: {}
}

export default EmptyState
