import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  getMedicationControls as getMedicationControlsService,
  createMedicationControl as createMedicationControlService,
  editMedicationControl as editMedicationControlService,
  deleteMedicationControl as deleteMedicationControlService
} from '@smartcoop/services/apis/smartcoopApi/resources/herdsManagement'

import { selectCurrentPropertyId } from '../property/selectorProperty'
import {
  MedicationControlActions,
  MedicationControlTypes
} from './duckMedicationControl'

function* loadMedicationControls({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const propertyId = yield select(selectCurrentPropertyId)

    const { data: { data, ...pagination } } = yield call(
      getMedicationControlsService,
      {
        limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
        ...params
      },
      {
        propertyId
      }
    )

    yield put(MedicationControlActions.loadMedicationControlsSuccess(
      data,
      pagination.page,
      onSuccess(data)
    ))
  } catch (err) {
    const error = err.message
    yield put(MedicationControlActions.medicationControlError(error))
    yield call(onError, error)
  }
}

function* saveMedicationControl({ params, onSuccess = () => {}, onError = () => {}, isEdit = false }) {
  try {
    const service = !isEdit ? createMedicationControlService : editMedicationControlService
    const data = yield call(service, params, { registerCode: params?.registerCode })
    yield call(onSuccess, data)
  } catch (error) {
    yield put(MedicationControlActions.medicationControlError(error))
    yield call(onError, error)
  }
}

function* saveOfflineMedicationControl({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(MedicationControlActions.saveMedicationControl(params, onSuccess, onError, params?.id))
  } catch (error) {
    yield put(MedicationControlActions.medicationControlError(error))
    yield call(onError, error)
  }
}

function* deleteMedicationControl({ params, registerCode, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteMedicationControlService, params, { registerCode })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(MedicationControlActions.medicationControlError(error))
    yield call(onError, error)
  }
}

function* deleteOfflineMedicationControl({ params, registerCode, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(MedicationControlActions.deleteMedicationControl(params, registerCode, onSuccess, onError))
  } catch (err) {
    const error = err.message
    yield put(MedicationControlActions.inseminationError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(MedicationControlTypes.LOAD_MEDICATION_CONTROLS, loadMedicationControls),
  takeLatest(MedicationControlTypes.SAVE_MEDICATION_CONTROL, saveMedicationControl),
  takeLatest(MedicationControlTypes.SAVE_OFFLINE_MEDICATION_CONTROL, saveOfflineMedicationControl),
  takeLatest(MedicationControlTypes.DELETE_MEDICATION_CONTROL, deleteMedicationControl),
  takeLatest(MedicationControlTypes.DELETE_OFFLINE_MEDICATION_CONTROL, deleteOfflineMedicationControl)
]
