import React, { useCallback, useState, useEffect } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import find from 'lodash/find'
import isObject from 'lodash/isObject'
import map from 'lodash/map'

import I18n, { useT } from '@smartcoop/i18n'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import InputSelect from '@smartcoop/web-components/InputSelect'
import Modal from '@smartcoop/web-components/Modal'

import useStyles, { Content } from './styles'

const SelectMonthYearModal = ({
  id,
  open,
  handleClose,
  onConfirm,
  onNegative,
  monthYears,
  textButtonOnConfirm,
  textButtonOnNegative,
  textWarning,
  monthlyDataList,
  setItems,
  hideCancel
}) => {
  const classes = useStyles()
  const t = useT()

  const [monthYear, setMonthYear] = useState('')
  const [sent, setSent] = useState(false)

  const closeModal = useCallback(
    () => {
      onNegative()
      handleClose()
    },
    [handleClose, onNegative]
  )

  const beforeConfirm = useCallback(
    () => {
      const found = find(monthlyDataList, item => moment(item?.date, momentBackDateFormat).format('MM/YYYY') === monthYear)
      const updatedFound = isObject(found?.rationData) ? ({
        ...found,
        rationData: map(found?.rationData, (item) => ({
          ...item
        }))
      }) : (
        {
          ...found
        }
      )
      setItems(updatedFound?.rationData?.length || 1)
      setSent(true)
    },
    [monthYear, monthlyDataList, setItems]
  )

  useEffect(() => {
    if(sent) {
      onConfirm(monthYear)
      handleClose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sent])

  const submitModal = useCallback(
    () => {
      beforeConfirm()
    },
    [beforeConfirm]
  )


  return (
    <Modal
      id={ id }
      open={ open }
      title={
        textWarning
      }
      escape={ false }
      disableFullScreen
      contentContainerStyle={ { padding: 0 } }
      headerProps={ {
        titleClass: classes.title
      } }
      FooterComponent={
        () => (
          <>
            {!hideCancel && (
              <Button
                id="close"
                onClick={ closeModal }
                variant="outlined"
                style={ { marginRight: 7 } }
              >
                {textButtonOnNegative ? <span>{textButtonOnNegative}</span> : <I18n>cancel</I18n>}
              </Button>
            )}
            <Button
              id="filtrate"
              onClick={ submitModal }
              color="black"
              style={ { marginLeft: hideCancel ? 0 : 7 } }
            >
              {textButtonOnConfirm ? <span>{textButtonOnConfirm}</span> : <I18n>confirm</I18n>}
            </Button>
          </>
        )
      }
    >
      <>
        <Content>
          <InputSelect
            label={ t('month', { howMany: 1 }) }
            detached
            options={ monthYears }
            value={ monthYear }
            onChange={ (value) => setMonthYear(value) }
            order={ false }
          />
        </Content>
      </>
    </Modal>
  )
}

SelectMonthYearModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onNegative: PropTypes.func,
  setItems: PropTypes.func,
  textWarning: PropTypes.string,
  textButtonOnConfirm: PropTypes.string,
  textButtonOnNegative: PropTypes.string,
  hideCancel: PropTypes.bool,
  monthYears: PropTypes.array,
  monthlyDataList: PropTypes.array
}

SelectMonthYearModal.defaultProps = {
  textButtonOnConfirm: '',
  textButtonOnNegative: '',
  onNegative: () => {},
  onConfirm: () => {},
  setItems: () => {},
  monthYears: [],
  monthlyDataList: [],
  textWarning: null,
  hideCancel: false
}

export default SelectMonthYearModal
