import React, { useEffect, useState, createContext } from 'react'
import { useSelector } from 'react-redux'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import first from 'lodash/first'

import { messageService } from '@smartcoop/database/services/messageService'
import { syncFileService } from '@smartcoop/database/services/syncFileService'
import { syncHistoricService } from '@smartcoop/database/services/syncHistoricService'
import { syncPendingService } from '@smartcoop/database/services/syncPendingService'
import { syncService } from '@smartcoop/database/services/syncService'
import { database } from '@smartcoop/database/web-database'
import WebSocketPersist from '@smartcoop/services/apis/socket'
import { selectUser } from '@smartcoop/stores/user/selectorUser'

import FullScreenLoading from '../containers/FullScreenLoading'
import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser'

export const ChatSyncContext = createContext({})

const ChatSyncProvider = ({ children, syncPending, syncPendingMessages, syncPendingFiles, syncHistoricConversation }) => {
  const [connected, setConnected] = useState(false)
  const currentUser = useSelector(selectUser)

  const [syncInProgress, setSyncInProgress] = useState(false)
  const [syncMessagesInProgress, setSyncMessagesInProgress] = useState(false)
  const [syncFilesInProgress, setSyncFilesInProgress] = useState(false)
  const [loading, setLoading] = useState()
  const [userSynced, setUserSynced] = useState(false)

  const localUser = useAuthenticatedUser()
  const websocket = new WebSocketPersist()

  const firstSyncDone = Boolean(first(syncHistoricConversation))

  useEffect(() => {
    if (currentUser?.id === localUser?.userId && !userSynced) {
      setUserSynced(true)
      setLoading(true)
      syncService(database)
        .sync()
        .then(() => console.log('[🌿] [Loki] Sync Conversation List Completed!'))
        .finally(() => setLoading(false))
    }
  }, [currentUser, localUser, userSynced])

  const syncNewMessages = async () => {
    await syncService(database).syncConversations()
  }

  useEffect(() => {
    if (connected && userSynced && !loading && firstSyncDone) {
      websocket.receiveAction(event => {
        console.log('[Websocket] Event Receive: ', event?.event)
        if (
          (
            event?.event === 'newMessage' ||
            event?.event === 'updateMessageStatus' ||
            event?.event === 'messageDeleted' ||
            event?.event === 'addGroupMember' ||
            event?.event === 'removeMember' ||
            event?.event === 'memberLeavingGroup' ||
            event?.event === 'updateGroupAdmin' ||
            event?.event === 'removeBroadcastMember' ||
            event?.event === 'addBroadcastMember'
          ) && !syncMessagesInProgress
        ) {
          syncNewMessages()
        }

        if (
          (
            event?.event === 'updateFavorite'
          ) && !syncMessagesInProgress
        ) {
          syncService(database).syncConversations()
        }
      })
    }
  }, [websocket, connected, syncMessagesInProgress, userSynced, loading, firstSyncDone])

  /**
   * Connect to WebSocket
   */
  useEffect(() => {
    if (!connected && localUser?.userId && userSynced) {
      websocket.connect({ userId: localUser.userId })
        .then(()=> {
          setConnected(true)
        })
        .catch(() => setConnected(false))
    }
  }, [connected, localUser, userSynced, websocket])

  useEffect(() => {
    if (!syncMessagesInProgress && userSynced && !loading && firstSyncDone) {
      syncNewMessages()
    }
  }, [firstSyncDone, loading, syncMessagesInProgress, userSynced])

  const syncMessages = async () => syncPendingService(database)
    .sync(
      'message',
      async (item, response) => messageService(database).updateSync(item.localMessageId, response?.data),
      messageService(database).updateSyncError
    )
    .catch((error) => console.log('Erro ao sincronizar!', error))

  const syncGeneral = async () => syncPendingService(database)
    .sync()
    .catch((error) => console.log('Erro ao sincronizar!', error))

  const syncFiles = async () => syncFileService(database)
    .sync(
      async (id, response) => messageService(database).updateSync(id, response?.data),
      messageService(database).updateSyncError
    )
    .catch((error) => console.log('Erro ao sincronizar!', error))

  /**
   * Sync pending changes
   */
  useEffect(() => {
    let intervalId

    if (syncPending.length) {
      if (window.navigator.onLine && !syncInProgress) {
        setSyncInProgress(true)
        syncGeneral().finally(() => setSyncInProgress(false))
      } else {
        intervalId = setInterval(() => {
          if (window.navigator.onLine && !syncInProgress) {
            clearInterval(intervalId)

            setSyncInProgress(true)
            syncGeneral().finally(() => setSyncInProgress(false))
          }
        }, 1000)
      }
    }

    return () => clearInterval(intervalId)
  }, [syncInProgress, syncPending])

  /**
   * Sync pending messages
   */
  useEffect(() => {
    let intervalId

    if (syncPendingMessages.length && userSynced) {
      if (window.navigator.onLine && !syncMessagesInProgress) {
        setSyncMessagesInProgress(true)
        syncMessages().finally(() => setSyncMessagesInProgress(false))
      } else {
        intervalId = setInterval(() => {
          if (window.navigator.onLine && !syncMessagesInProgress) {
            clearInterval(intervalId)

            setSyncMessagesInProgress(true)
            syncMessages().finally(() => setSyncMessagesInProgress(false))
          }
        }, 1000)
      }
    }

    return () => clearInterval(intervalId)
  }, [syncInProgress, syncMessagesInProgress, syncPendingMessages, userSynced])

  /**
   * Sync pending files
   */
  useEffect(() => {
    let intervalId

    if (syncPendingFiles.length && userSynced) {
      if (window.navigator.onLine && !syncFilesInProgress) {
        setSyncFilesInProgress(true)
        syncFiles().finally(() => setSyncFilesInProgress(false))
      } else {
        intervalId = setInterval(() => {
          if (window.navigator.onLine && !syncFilesInProgress) {
            clearInterval(intervalId)

            setSyncFilesInProgress(true)
            syncFiles().finally(() => setSyncFilesInProgress(false))
          }
        }, 1000)
      }
    }

    return () => clearInterval(intervalId)
  }, [syncFilesInProgress, syncPendingFiles, syncPendingMessages, userSynced])

  return (
    <ChatSyncContext.Provider value={ { loading } }>
      {!userSynced && (
        <FullScreenLoading />
      )}

      {children}
    </ChatSyncContext.Provider>
  )
}


ChatSyncProvider.propTypes = {
  children: PropTypes.element.isRequired,
  syncPending: PropTypes.array,
  syncPendingMessages: PropTypes.array,
  syncPendingFiles: PropTypes.array,
  syncHistoricConversation: PropTypes.array
}

ChatSyncProvider.defaultProps = {
  syncPending: [],
  syncPendingMessages: [],
  syncPendingFiles: [],
  syncHistoricConversation: []
}

const enhance = withObservables([], () => ({
  syncPending: syncPendingService(database).observeUnsyncChanges(),
  syncPendingFiles: syncFileService(database).observeUnsyncChanges(),
  syncPendingMessages: syncPendingService(database).observeUnsyncChanges('message'),
  syncHistoricConversation: syncHistoricService(database).observeSyncHistoric('conversation')
}))

const EnhancedChatSyncProvider = enhance(ChatSyncProvider)

export default EnhancedChatSyncProvider
