import React, { useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'

import filterAccreditmentsSchema from '@smartcoop/forms/schemas/operations/filterAccreditments.schema'
import { useT } from '@smartcoop/i18n'
import Form from '@smartcoop/web-components/Form'
import InputCpfCnpj from '@smartcoop/web-components/InputCpfCnpj'
import InputDateRange from '@smartcoop/web-components/InputDateRange'
import InputSelect from '@smartcoop/web-components/InputSelect'
import Loader from '@smartcoop/web-components/Loader'

import { Container } from './styles'

const FilterAccreditmentsForm = forwardRef((props, formRef) => {
  const { loading, onSubmit, filters } = props
  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      const newData = {
        ...data,
        expirationStartDate: data?.expirationDate?.from,
        expirationEndDate: data?.expirationDate?.to
      }
      return onSubmit(omitBy(newData, val => isEmpty(val)))
    },
    [onSubmit]
  )

  const statusOptions = [
    {
      label: 'Aguardando Aprovação',
      value: 'AGUARDANDO_APROVACAO'
    },{
      label: 'Recusado',
      value: 'RECUSADO'
    },{
      label: 'Aprovado',
      value: 'APROVADO'
    },{
      label: 'Expirado',
      value: 'EXPIRADO'
    }
  ]
  return (
    <Container>
      {
        loading ? <Loader /> : (
          <Form
            style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
            ref={ formRef }
            schemaConstructor={ filterAccreditmentsSchema }
            onSubmit={ handleSubmit }
          >
            <InputCpfCnpj
              name="document"
              label="CPF"
              onlyCpf
              fullWidth
              defaultValue={ filters?.document }
            />
            <InputSelect
              name="requestStatus"
              label="Status"
              options={ statusOptions }
              defaultValue={ filters?.status }
            />
            <InputDateRange
              name="expirationDate"
              label={ t('expiration date') }
              defaultValue={ filters?.expirationDate }
              fullWidth
            />
          </Form>
        )
      }
    </Container>
  )
})

FilterAccreditmentsForm.propTypes = {
  loading: PropTypes.bool,
  filters: PropTypes.object,
  onSubmit: PropTypes.func
}

FilterAccreditmentsForm.defaultProps = {
  loading: false,
  filters: {},
  onSubmit: () => {}
}

export default FilterAccreditmentsForm
