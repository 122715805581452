import styled from 'styled-components'

import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 32px 30px 23px;
  background-color: ${ colors.backgroundHtml };
`

export const AccountBalanceContainer = styled.div`
  padding-top: 50px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap-reverse;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 3;
  flex-direction: row;
  margin-bottom: 22px;
`

export const SearchContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
  min-width: 300px;
`

export const inputSearchTheme = (theme) => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff'
      }
    }
  }
})

export const TabButton = styled(Button)`
  flex: none;
  white-space: nowrap;
  margin-right: 12px;
`
