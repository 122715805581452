import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import { colors } from '@smartcoop/styles'

const useStyles = makeStyles(() => ({
  root: {
    height: 8,
    color: `${ colors.yellow }`
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: colors.white,
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  markLabel: {
    fontWeight: 600
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 7px)',
    top: -22,
    '& *': {
      background: 'transparent',
      fontWeight: 600,
      padding: '5px 10px',
      fontSize: 16,
      color: colors.grey
    }
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4,
    color: `${ colors.grey }`
  }
}))

export const Label = styled.span`
  font-weight: 600;
  margin-left: 8px;
  font-size: 16px;
  margin-bottom: 10px;
  color: ${ props => props.error ? colors.error : colors.black };
`
export const ErrorLabel = styled(Label)`
  font-weight: normal;
  margin-left: 8px;
  font-size: 13px;
  margin-bottom: 10px;
  color: ${ colors.error };
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 10px;
`

export default useStyles
