import React, { useCallback, forwardRef, useMemo, useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'


import acceptProposalSchema from '@smartcoop/forms/schemas/shoppingPlatform/order/acceptProposal.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { warning, hammer } from '@smartcoop/icons'
import { ProposalStatusCode } from '@smartcoop/utils/constants'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import ObservationBox from '@smartcoop/web-components/ObservationBox'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

import Header from './Header/Header'
import PaymentForms from './PaymentForms/PaymentForms'
import {
  Container,
  ButtonContainer,
  WhiteContainer,
  LeftSide,
  SelectedProposal,
  Label
} from './styles'

const AcceptProposalForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    purchaseProposals,
    deliveryPercentage,
    onSubmit,
    disabled,
    onAcceptProposal,
    onRejectProposals,
    onChangeSelected
  } = props

  const t = useT()

  const radioGroupValue = useMemo(
    () =>
      find(purchaseProposals,
        ({ status }) =>
          status.slug !== 'recusado'
      )?.id || null,
    [purchaseProposals]
  )

  const [selectedId, setSelectedId] = useState(radioGroupValue)

  const handleSubmit = useCallback((data) => onSubmit(data), [onSubmit])

  const renderHeader = useCallback(
    (label, codeProposal, percentage) => (
      <Header
        label={ label }
        codeProposal={ `${ t('code proposal') }: ${ codeProposal }` }
        component={
          <ObservationBox
            icon={ warning }
            title={ t('comply with {this} of the demand', { this: percentage }) }
          />
        }
      />
    ),
    [t]
  )

  const renderChildren = useCallback(
    (paymentForms) => (
      <PaymentForms
        paymentForms={ map(paymentForms, ({ value, paymentForm }) => ({
          daysAmount: paymentForm.daysAmount,
          value,
          deferredPayment: paymentForm.deferredPayment,
          id: paymentForm.id
        })) }
      />
    ),
    []
  )

  const options = useMemo(
    () =>
      map(filter(purchaseProposals, item => item?.statusId !== ProposalStatusCode.REJEITADO && item?.statusId !== ProposalStatusCode.INVALID), (proposal) => ({
        value: proposal.id,
        label: renderHeader(
          proposal.supplier.companyName,
          proposal.code,
          deliveryPercentage
        ),
        children: renderChildren(proposal.proposalPaymentForms)
      })),
    [deliveryPercentage, purchaseProposals, renderChildren, renderHeader]
  )

  const selectedProposal = useMemo(
    () => find(purchaseProposals, ({ id }) => selectedId === id),
    [purchaseProposals, selectedId]
  )

  useEffect(() => {
    onChangeSelected(selectedProposal)
  },[onChangeSelected, selectedProposal])


  const rejectProposal = useCallback(() => {
    onRejectProposals()
  }, [onRejectProposals])

  const acceptProposal = useCallback(() => {
    formRef.current.submit()
    if (selectedProposal) {
      onAcceptProposal(selectedProposal)
    }
  }, [formRef, onAcceptProposal, selectedProposal])

  const bestProposalId = useMemo(() => {
    const proposal = find(purchaseProposals, (item) => !!item.bestProposal)

    return proposal ? proposal?.id : null
  }, [purchaseProposals])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ acceptProposalSchema }
        onSubmit={ handleSubmit }
      >
        <RadioGroup
          label={
            <Label>
              <Icon icon={ hammer } size={ 20 } style={ { marginRight: 10 } } />
              {t('received proposals')}
            </Label>
          }
          name="proposalPaymentFormId"
          options={ options }
          variant="card"
          onChange={ ({ target: { value } }) => setSelectedId(value) }
          disabled={ disabled }
          clearable
          activeId={ bestProposalId }
          activeMessage={ t('best proposal') }
          defaultValue={ radioGroupValue }
        />
        {!withoutSubmitButton && !disabled && (
          <WhiteContainer>
            <LeftSide>
              <SelectedProposal>{t('selected proposal')}: </SelectedProposal>
              {selectedProposal && selectedProposal.supplier.companyName}
            </LeftSide>
            <ButtonContainer>
              <Button
                id="web-order-deny-form-button"
                style={ { width: '48%', marginRight: 14 } }
                variant="outlined"
                onClick={ rejectProposal }
                disabled={ disabled || !selectedId }
              >
                <I18n>deny</I18n>
              </Button>
              <Button
                id="web-order-accept-form-button"
                style={ { width: '48%' } }
                onClick={ acceptProposal }
                disabled={ disabled || !selectedId }
              >
                <I18n>accept</I18n>
              </Button>
            </ButtonContainer>
          </WhiteContainer>
        )}
      </Form>
    </Container>
  )
})

AcceptProposalForm.propTypes = {
  onSubmit: PropTypes.func,
  onAcceptProposal: PropTypes.func,
  onRejectProposals: PropTypes.func,
  onChangeSelected: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  purchaseProposals: PropTypes.array.isRequired,
  deliveryPercentage: PropTypes.number.isRequired,
  disabled: PropTypes.bool
}

AcceptProposalForm.defaultProps = {
  onSubmit: () => {},
  onAcceptProposal: () => {},
  onChangeSelected: () => {},
  onRejectProposals: () => {},
  withoutSubmitButton: false,
  disabled: false
}

export default AcceptProposalForm
