import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import required from '@smartcoop/forms/validators/required.validator'

const pestReport = ({ t }) => Yup.object().shape({

  occurenceDate: flow(
    required({ t }),
    date({ t })
  )(Yup.string()),

  typeSlug: flow(
    required({ t })
  )(Yup.string()),

  plagueIds: Yup.string().when('typeSlug', {
    is: (val) => val === 'other' || val === 'emergency' || val === 'erosion',
    then: Yup.string(),
    otherwise: required({ t })(Yup.string().nullable())
  }),


  description: Yup.string().when('typeSlug', {
    is: (val) => val === 'other' || val === 'emergency'  ,
    then: required({ t })(Yup.string()),
    otherwise: Yup.string()
  }),

  observations: Yup.string()

})

export default pestReport
