import styled from 'styled-components'


export const Container = styled.div`

`

export const Title = styled.span`
  font-weight: 700;
  font-size: 17px;
`

export const Label = styled.span`
  margin-left: 8px;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
`

export const Item = styled.div`
  margin-top: 10px;
  display: flex;
  font-weight: 600;
  align-items: center;
`
