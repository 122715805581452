import React, { useMemo, useCallback, forwardRef } from 'react'
import MaskedInput from 'react-input-mask'
import { NumericFormat } from 'react-number-format'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import uuid from 'short-uuid'

import toNumber from 'lodash/toNumber'

import { warning, info } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import Icon from '@smartcoop/web-components/Icon'

import useStyles, {
  TextFieldStyled as MuiTextFieldStyled,
  HelperTextContainer,
  HelperTextIconContainer,
  HelperText
} from './styles'

const TextFieldStyled = forwardRef((props, inputRef) => {
  const {
    id,
    numberFormatProps,
    mask,
    value,
    onChange,
    onKeyDown,
    onBlur,
    required,
    disabled,
    error,
    helperText: externalHelperText,
    className,
    setMask,
    transformRender,
    minWidth,
    notEditable,
    maxLength,
    max,
    visibleButDisabled,
    ...rest
  } = props

  const classes = useStyles()

  const inputId = useMemo(
    () => id || uuid().new(),
    [id]
  )

  const handleChange = useCallback(
    (event) => {
      if (!notEditable) {
        if (numberFormatProps) {
          return onChange({ target: { value: event.value } })
        }
        return onChange(event)
      }
      return undefined
    },
    [notEditable, numberFormatProps, onChange]
  )

  const helperText = useMemo(
    () => (
      <span className={ classes.helperTextContainer }>
        {externalHelperText && (
          <HelperTextContainer>
            <HelperTextIconContainer>
              <Icon
                icon={ info }
                color={ error ? colors.error : colors.mutedText }
                size={ 12 }
              />
            </HelperTextIconContainer>
            <HelperText error={ error } italic>
              {externalHelperText}
            </HelperText>
          </HelperTextContainer>
        )}
        {error && (
          <HelperTextContainer>
            <HelperTextIconContainer>
              <Icon
                icon={ warning }
                color={ colors.error }
                size={ 12 }
              />
            </HelperTextIconContainer>
            <HelperText error>
              {error}
            </HelperText>
          </HelperTextContainer>
        )}
      </span>
    ),
    [classes.helperTextContainer, error, externalHelperText]
  )

  const textFieldProps = useMemo(
    () => ({
      inputRef,
      id: inputId,
      required,
      minWidth,
      className: clsx({
        [classes.textField]: true,
        [className]: true,
        [classes.fieldsetDisabled]: visibleButDisabled ? false : disabled,
        [classes.visibleButDisabled]: visibleButDisabled
      }),
      error: !!error,
      helperText,
      inputProps: {
        ...(rest.InputProps || {}),
        classes: {
          ...((rest.InputProps || {}).classes || {}),
          disabled: visibleButDisabled ? classes.visibleButDisabled : classes.disabled
        },
        maxlength: maxLength,
        max
      },
      disabled,
      visibleButDisabled,
      ...rest
    }),
    [inputRef, inputId, required, minWidth, classes.textField, classes.fieldsetDisabled, classes.visibleButDisabled, classes.disabled, className, visibleButDisabled, disabled, error, helperText, rest, maxLength, max]
  )

  const transformedValue = useMemo(
    () => transformRender(value),
    [value, transformRender]
  )

  return (
    <>
      { numberFormatProps ? (
        <NumericFormat
          value={ transformedValue ? toNumber(transformedValue) : null }
          onValueChange={ handleChange }
          onBlur={ onBlur }
          onKeyDown={ onKeyDown }
          customInput={ MuiTextFieldStyled }
          { ...numberFormatProps }
          { ...textFieldProps }
        />
      ) : (
        <>
          {textFieldProps.multiline ? (
            <MuiTextFieldStyled
              { ...textFieldProps }
              onChange={ handleChange }
              value={ transformedValue }
              onBlur={ onBlur }
              onKeyDown={ onKeyDown }
              disabled={ disabled }
            />
          ) : (
            <MaskedInput
              mask={ mask }
              onChange={ handleChange }
              value={ transformedValue }
              onBlur={ onBlur }
              onKeyDown={ onKeyDown }
              maskPlaceholder={ null }
              disabled={ disabled }
            >
              <MuiTextFieldStyled
                { ...textFieldProps }
                value={ transformedValue }
              />
            </MaskedInput>
          )}
        </>
      ) }
    </>
  )
})

TextFieldStyled.propTypes = {
  id: PropTypes.string,
  numberFormatProps: PropTypes.object,
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  value: PropTypes.any,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  setMask: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  visibleButDisabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object
  ]),
  type: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  transformRender: PropTypes.func,
  minWidth: PropTypes.number,
  maxLength: PropTypes.number,
  max: PropTypes.string,
  notEditable: PropTypes.bool
}

TextFieldStyled.defaultProps = {
  id: undefined,
  numberFormatProps: undefined,
  mask: undefined,
  onBlur: () => {},
  onKeyDown: () => {},
  setMask: undefined,
  required: false,
  className: null,
  max: null,
  error: null,
  disabled: false,
  visibleButDisabled: false,
  helperText: null,
  maxLength: null,
  type: 'text',
  variant: 'outlined',
  size: 'small',
  value: '',
  onChange: () => {},
  transformRender: v => v,
  minWidth: 0,
  notEditable: false
}

export default TextFieldStyled
