import React, { useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import RegisterOrderForm from '@smartcoop/web-containers/forms/shoppingPlatform/order/RegisterOrderForm'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

const RegisterOrderScreen = () => {
  const registerOrderRef = useRef(null)

  const history = useHistory()

  const { routes } = useRoutes()

  const onSuccess = useCallback(() => history.push(routes.orderList.path), [history, routes.orderList.path])
  const onCancel = useCallback(() => history.goBack(), [history])

  const defaultValues = history?.location?.state?.defaultValues

  return (
    <RegisterOrderForm
      ref={ registerOrderRef }
      onSuccess={ onSuccess }
      onCancel={ onCancel }
      defaultValues={ defaultValues }
    />
  )
}

export default RegisterOrderScreen
