import React from 'react'

import PropTypes from 'prop-types'

import HtmlToComponent from '@smartcoop/web-components/HtmlToComponent'

import { Container } from './styles'

const Icon = ({ id, icon, style, onClick, ...iconProps }) => icon && (
  <Container id={ id } style={ { ...style } } onClick={ onClick }>
    <HtmlToComponent html={ icon(iconProps) } />
  </Container>
)

Icon.propTypes = {
  icon: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
  size: PropTypes.number,
  color: PropTypes.string,
  id: PropTypes.string
}

Icon.defaultProps = {
  style: {},
  onClick: () => {},
  size: 24,
  color: undefined,
  id: undefined
}

export default Icon
