import React, { forwardRef, useMemo, useState, useCallback } from 'react'

import PropTypes from 'prop-types'

import lowerCase from 'lodash/lowerCase'

import harvestCropManagementSchema from '@smartcoop/forms/schemas/management/harvestCropManagement.schema'
import { useT } from '@smartcoop/i18n'
import Form from '@smartcoop/web-components/Form'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputPercentage from '@smartcoop/web-components/InputPercentage'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputUnit from '@smartcoop/web-components/InputUnit'

const HarvestForm = forwardRef(({ defaultValues, handleSubmit, growingSeason, readOnly }, formRef) => {
  const t = useT()
  const [unit, setUnit] = useState(defaultValues?.unitProductivity || '')

  const { crop, activity } = growingSeason

  const handleUnitsChange = useCallback(
    (selectedItem) => {
      setUnit(selectedItem.target.value)
    },
    []
  )

  const unitProductivity = useMemo(
    () => (
      [
        {
          label: 'kg/ha',
          value: 'kg/ha'
        },
        {
          label: 'sacas/ha',
          value: 'sacas/ha'
        }
      ]
    ),
    []
  )

  const inputProductivity = useMemo(
    () => {
      const inputTypeProductivity = {
        'kg/ha':
          <InputNumber
            label={ t('productivity') }
            name="productivityKg"
            fullWidth
            maxLength={ 9 }
            defaultValue={ defaultValues?.productivityKg || '' }
            disabled={ readOnly }
          />,
        'sacas/ha':
          <InputUnit
            label={ t('productivity') }
            name="productivityBags"
            fullWidth
            maxLength={ 6 }
            defaultValue={ defaultValues?.productivityBags || '' }
            disabled={ readOnly }
          />
      }
      return inputTypeProductivity[unit]

    },
    [defaultValues.productivityBags, defaultValues.productivityKg, readOnly, t, unit]
  )

  const complementaryForm = useMemo(
    () => {
      const cropSelectForm = {
        'trigo':
          <InputPercentage
            name="ph"
            label="ph"
            maxLength={ 2 }
            maxValue={ 99 }
            unit="%"
            type="integer"
            fullWidth
            defaultValue={ defaultValues?.ph  }
            disabled={ readOnly }
          />,
        'cevada':
          <>
            <InputPercentage
              name="ph"
              label="ph"
              maxLength={ 2 }
              maxValue={ 99 }
              type="integer"
              fullWidth
              defaultValue={ defaultValues?.ph }
              disabled={ readOnly }
            />
            <InputPercentage
              name="germinationProductivity"
              label={ t('germination') }
              type="integer"
              fullWidth
              defaultValue={ defaultValues?.germinationProductivity }
              disabled={ readOnly }
            />
          </>,
        'aveia':
          <InputPercentage
            name="ph"
            label="ph"
            maxLength={ 2 }
            maxValue={ 99 }
            type="integer"
            fullWidth
            defaultValue={ defaultValues?.ph }
            disabled={ readOnly }
          />,
        'arroz':
          <>
            <InputPercentage
              name="wholeGrain"
              label={ t('whole grain') }
              type="integer"
              fullWidth
              defaultValue={ defaultValues?.wholeGrain }
              disabled={ readOnly }
            />
            <InputPercentage
              name="brokenGrain"
              label={ t('broken grain') }
              type="integer"
              fullWidth
              defaultValue={ defaultValues?.brokenGrain }
              disabled={ readOnly }
            />
            <InputPercentage
              name="shellAndBran"
              label={ t('shell and bran') }
              type="integer"
              fullWidth
              defaultValue={ defaultValues?.shellAndBran }
              disabled={ readOnly }
            />
          </>
      }

      return cropSelectForm[lowerCase(crop?.description)]
    },
    [crop.description, defaultValues.brokenGrain, defaultValues.germinationProductivity, defaultValues.ph, defaultValues.shellAndBran, defaultValues.wholeGrain, readOnly, t]
  )

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ harvestCropManagementSchema }
      containerStyle={ { height: 'auto' } }
      onSubmit={ handleSubmit }
    >
      <InputSelect
        label={ t('inform produtivity') }
        name="unitProductivity"
        options={ unitProductivity }
        onChange={ handleUnitsChange }
        defaultValue={ defaultValues?.unitProductivity }
        disabled={ readOnly }
      />
      { unit && (
        inputProductivity
      )}

      {activity?.slug === 'graos' && (
        <InputPercentage
          name="impurity"
          label={ t('impurity percentage') }
          maxLength={ 2 }
          maxValue={ 99 }
          type="integer"
          fullWidth
          defaultValue={ defaultValues?.impurity }
          disabled={ readOnly }
        />
      )}

      {activity?.slug === 'graos' && (
        <InputPercentage
          name="humidity"
          label={ t('humidity percentage') }
          maxLength={ 2 }
          maxValue={ 99 }
          type="integer"
          fullWidth
          defaultValue={ defaultValues?.impurity }
          disabled={ readOnly }
        />
      )}

      { crop?.description && (
        complementaryForm
      )}

    </Form>
  )
})

HarvestForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  growingSeason: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  defaultValues: PropTypes.object
}

HarvestForm.defaultProps = {
  readOnly: false,
  defaultValues: {}
}

export default HarvestForm
