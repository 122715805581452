import styled, { css } from 'styled-components'

import { Avatar } from '@material-ui/core'

export const IconWrapper = styled.div`
  background-color: #BCBCBC;
  border-radius: 100%;
  padding: 10px;
  width: ${ props => props.size }px;
  height: ${ props => props.size }px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${ props => props.clickable ? css`
      cursor: pointer;

    :hover {
      filter: brightness(0.95);
    }
  ` : null }
`


export const CustomAvatar = styled(Avatar)`
  width: ${ props => props.size }px;
  height: ${ props => props.size }px;

  ${ props => props.clickable ? css`
      cursor: pointer;

    :hover {
      filter: brightness(0.95);
    }
  ` : null }
`
