import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export const Identifier = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 14px;
  margin-bottom: 0.35em;
`

export const Text = styled(Typography)`
  font-size: ${ fonts.fontSize.M }px;
  font-weight: ${ fonts.fontWeight.regular };
  font-family: ${ fonts.fontFamilySans };
`

export const CNPJ = styled(Text)`
  font-weight: ${ fonts.fontWeight.semiBold };
  margin-bottom: 10px;
`

export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid ${ colors.lightGrey };
  margin: 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
`

export const GroupLocation = styled.div`
  :not(:last-child) {
    margin-bottom: 18px;
  }
`

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.backgroundHtml
  },

  title: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    padding: '12px 13px 0 13px'
  }
})
