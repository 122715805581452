// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
      <g>
        <path fill="#D3D3D3" d="M72.9,71.8c-0.1,0-0.3,0-0.5,0.1l-14.7,3.9l-13-7.5l13-7.4l14.8,4c0.9,0.2,1.9-0.4,2.1-1.2
          c0.2-0.8-0.1-1.5-0.8-2c0,0,0,0-0.1,0l-9-5.2l8.3-6.3l0,0c0.6-0.5,0.8-1.3,0.6-2c-0.3-0.6-0.9-1.1-1.6-1.1c-0.2,0-0.4,0.1-0.7,0.1
          l-9.7,4V40.9c0.1-0.8-0.5-1.6-1.3-1.8c-0.9-0.2-1.9,0.3-2.1,1.3l-3.9,14.7l-13,7.5V47.6L52,36.8c0.4-0.4,0.5-0.8,0.5-1.2
          c0-0.5-0.2-0.9-0.5-1.2c-0.6-0.6-1.5-0.6-2.1-0.3c0,0,0,0-0.1,0l-9,5.3l-1.4-10.4c-0.1-0.8-0.8-1.5-1.7-1.5c-0.1,0-0.2,0-0.2,0
          c-0.8,0.1-1.4,0.7-1.5,1.5c0,0,0,0,0,0.1l-1.3,10.3l-9-5.2c0,0,0,0-0.1,0c-0.6-0.4-1.5-0.3-2.1,0.3c-0.6,0.6-0.6,1.8,0.1,2.5
          l10.8,10.8v14.9l-13-7.5l-4-14.8c-0.3-0.9-1.2-1.5-2.1-1.2C14.5,39.3,14,40,14,40.8v0.1v10.4l-9.7-4c-0.8-0.4-1.9,0.1-2.2,0.9
          c-0.3,0.7-0.1,1.5,0.6,2c0,0,0,0,0.1,0l8.3,6.3l-9,5.2c0,0,0,0-0.1,0c-0.7,0.4-1.1,1.2-0.8,2C1.3,64.5,2,65,2.7,65
          c0.1,0,0.3,0,0.5-0.1L18,61l13,7.4l-12.8,7.5l-14.8-4c-0.9-0.2-1.9,0.4-2.1,1.2c-0.2,0.8,0.1,1.5,0.8,2c0,0,0,0,0.1,0l9,5.2
          L3,86.6c0,0,0,0-0.1,0c-0.6,0.5-0.8,1.3-0.6,2c0.3,0.6,0.9,1.1,1.6,1.1c0.2,0,0.4-0.1,0.7-0.1l9.7-4V96l0,0c0,0.8,0.5,1.5,1.3,1.7
          c0.9,0.3,1.9-0.3,2.1-1.3l3.9-14.7l13-7.4v15l-10.9,10.9c-0.4,0.4-0.5,0.8-0.5,1.2s0.2,0.9,0.5,1.2c0.6,0.6,1.5,0.6,2.1,0.3l0,0
          l9-5.2l1.4,10.4c0.1,0.8,0.8,1.5,1.7,1.5c0.1,0,0.2,0,0.2,0c0.8-0.1,1.4-0.7,1.5-1.5c0,0,0,0,0-0.1L41,97.6l9,5.2h0.1
          c0.6,0.4,1.5,0.3,2.1-0.3c0.7-0.6,0.7-1.8-0.1-2.5L41.2,89.2V74.3l13,7.4l4,14.8c0.2,0.9,1.2,1.5,2.1,1.2c0.8-0.2,1.3-0.9,1.3-1.7
          l0,0V85.6l9.7,4c0.2,0.1,0.4,0.1,0.6,0.1c0.7,0,1.3-0.4,1.6-1.1c0.3-0.7,0.1-1.5-0.6-2c0,0,0,0-0.1,0l-8.3-6.3l9-5.2l0,0
          c0.7-0.4,1.1-1.2,0.8-2C74.3,72.4,73.6,71.8,72.9,71.8z M37.9,108.6L37.9,108.6L37.9,108.6L37.9,108.6z"/>
        <g>
          <path fill="#D3D3D3" d="M85.7,109.4c-3.5,0-6.4-2.8-6.4-6.4s2.8-6.4,6.4-6.4h85.2c4.1-0.1,8-1.5,10.8-4.3
            c2.9-2.8,4.6-6.6,4.6-10.5c0.1-3-1.1-5.9-3.2-8c-2.1-2.2-5-3.3-8-3.4H175c-2.2,0-4.3,0.8-5.9,2.4c-1.6,1.5-2.5,3.7-2.5,5.9
            c0,0.9,0.2,1.8,0.6,2.6c1.5,3.2,0.1,7-3.1,8.5c-3.2,1.5-7,0.1-8.5-3.1c-1.2-2.6-1.8-5.3-1.7-8.1c0.1-5.7,2.3-10.9,6.4-14.9
            c4-3.9,9.3-6,14.8-6h0.1c6.5,0.1,12.5,2.6,17,7.2c4.5,4.6,6.9,10.7,6.9,17.1c-0.1,7.4-3.1,14.3-8.5,19.5
            c-5.1,5-12.1,7.7-19.5,7.8L85.7,109.4L85.7,109.4z"/>
          <path fill="#D3D3D3" d="M150.8,172.5c-5.5,0-10.8-2.1-14.8-6c-4.1-4-6.3-9.3-6.4-14.9c0-2.8,0.6-5.5,1.7-8.1
            c1.5-3.2,5.3-4.6,8.5-3.1c3.2,1.5,4.6,5.3,3.1,8.5c-0.4,0.8-0.6,1.7-0.6,2.6c0,2.3,0.9,4.4,2.5,5.9c1.6,1.5,3.7,2.4,5.9,2.4h0.1
            c3,0,5.8-1.2,7.9-3.4s3.3-5,3.2-8c-0.1-4-1.7-7.7-4.6-10.5c-2.8-2.7-6.7-4.2-10.9-4.3H47.4c-3.5,0-6.4-2.8-6.4-6.4
            c0-3.5,2.8-6.4,6.4-6.4h99.3c7.5,0.1,14.5,2.9,19.6,7.8c5.4,5.2,8.4,12.2,8.5,19.5c0.1,6.4-2.4,12.5-6.9,17
            c-4.5,4.6-10.5,7.1-16.9,7.2L150.8,172.5L150.8,172.5z"/>
        </g>
      </g>
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
