import React, { useState, useMemo } from 'react'

import PropTypes from 'prop-types'

import { database } from '@smartcoop/database'
import { conversationService } from '@smartcoop/database/services/conversationService'
import { groupService } from '@smartcoop/database/services/groupService'
import { generateKey } from '@smartcoop/database/utils/cryptography'
import { generateUuid } from '@smartcoop/database/utils/uuidGenerator'
import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { MaxSizeOfGroupMembers } from '@smartcoop/utils/constants'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'

import ChatAvatar from '../../components/ChatAvatar/ChatAvatar'
import {
  Container,
  CustomInput,
  CustomSelectContacts,
  Header,
  ModalFooter,
  ModalTitle
} from './styles'

const NewBroadcastModal = ({
  id,
  open,
  openChat,
  handleClose,
  localUser
}) => {
  const [name, setName] = useState('')
  const [searchParams, setSearchParams] = useState('')
  const [selecteds, setSelecteds] = useState([])

  const t = useT()
  const snackbar = useSnackbar()

  const userProfiles = useMemo(() => {
    if (localUser?.profiles) {
      return JSON.parse(localUser?.profiles, '[]')
        .map(item => Number(item))
    }
    return []
  }, [localUser])

  const handleSelect = (item) => {
    if (selecteds.includes(item)) {
      return setSelecteds(state => state.filter(element => element.contactId !== item.contactId))
    }
    return setSelecteds(state => [...state, item])
  }

  const prepareDTO = async () => {
    const broadcastKey = await generateKey()
    const broadcastId = await generateUuid(localUser?.userId, new Date().toISOString())

    return {
      id: broadcastId,
      broadcastKey,
      groupChat: false,
      broadcast: true,
      groupName: name,
      userCodes: [...selecteds.map(item => ({
        userId: item.contactId,
        userName: item.chatNickname,
        code: item.userCode,
        isLeft: false,
        isAdminGroup: false,
        publicKey: item.publicKey
      })), {
        userId: localUser?.userId,
        userName: localUser?.name,
        code: localUser?.userCode,
        isLeft: false,
        isAdminGroup: true,
        publicKey: localUser.publicKey
      }]
    }
  }

  const onSubmit = async () => {
    if (!name) {
      return snackbar.warning(t('required to enter a name for the broadcast'))
    }

    if (!selecteds.length) {
      return snackbar.warning(t('select at least one participant for the broadcast'))
    }

    if (selecteds.length > MaxSizeOfGroupMembers) {
      return snackbar.warning(t('the broadcast must have a maximum of 200 members'))
    }

    try {
      const dto = await prepareDTO()

      await groupService(database).create(dto)
      await conversationService(database).startGroupConversation(dto)

      snackbar.success(t('broadcast "{name}" created successfully', { name: dto.groupName }))

      openChat({
        conversationId: dto.id,
        relationalId: dto.id,
        localMessageId: null,
        userId: dto.id
      })

      return handleClose()
    } catch(error) {
      return snackbar.error(t('an error occurred while creating the broadcast'))
    }
  }

  return (
    <Modal
      id={ id }
      open={ open }
      escape={ false }
      maxWidth="sm"
      fullWidth
      hideHeader
    >
      <Container>
        <Header>
          <ChatAvatar isBroadcast size={ 40 } />
          <ModalTitle>{t('new broadcast')}</ModalTitle>
        </Header>

        <CustomInput
          value={ name }
          onChange={ e => setName(e.target?.value) }
          placeholder={ t('enter the broadcast name') }
        />

        <CustomSelectContacts
          localUserProfiles={ userProfiles }
          selecteds={ selecteds }
          onSelect={ handleSelect }
          searchParams={ searchParams }
          setSelecteds={ setSelecteds }
          isBroadcast
          maxMembers={ MaxSizeOfGroupMembers }
        >
          <CustomInput
            value={ searchParams }
            onChange={ e => setSearchParams(e.target?.value) }
            placeholder={ t('filter by name') }
            marginTop={ 20 }
          />
        </CustomSelectContacts>

        <ModalFooter>
          <Button id="cancel-new-broadcast" onClick={ handleClose }>{t('cancel')}</Button>
          <Button id="confirm-new-broadcast" color="secondary" onClick={ onSubmit }>{t('create broadcast')}</Button>
        </ModalFooter>
      </Container>
    </Modal>
  )
}

NewBroadcastModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  openChat: PropTypes.func.isRequired,
  localUser: PropTypes.object.isRequired
}

NewBroadcastModal.defaultProps = {}

export default NewBroadcastModal
