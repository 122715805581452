import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  productGroups: [],
  currentProductGroup: {},
  error: null,
  loading: false
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadProductGroups: ['params', 'onSuccess', 'onError'],
  loadProductGroupsSuccess: ['productGroups', 'page', 'onSuccess'],

  saveProductGroup: ['params', 'onSuccess', 'onError'],
  updateProductGroup: ['params', 'productGroupId', 'onSuccess', 'onError'],
  deleteProductGroup: ['productGroupId', 'onSuccess', 'onError'],

  setCurrentProductGroup: ['currentProductGroup'],
  resetCurrentProductGroup: [],

  setLoadingProductGroup: ['loading'],

  productGroupError: ['error']
})

/**
 * Assigning offline actions
 */
markActionsOffline(Creators, [])


/**
 * Reducers functions
 */
const loadProductGroupsSuccess = (state = INITIAL_STATE, { productGroups, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  productGroups: page === 1 ? productGroups : [
    ...state.productGroups,
    ...productGroups
  ]
})

const productGroupError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const setLoadingProductGroup = (state = INITIAL_STATE, { loading }) => ({
  ...state,
  loading
})

const setCurrentProductGroup = (state = INITIAL_STATE, { currentProductGroup }) => ({
  ...state,
  currentProductGroup
})

const resetCurrentProductGroup = (state = INITIAL_STATE) => ({
  ...state,
  currentProductGroup: INITIAL_STATE.currentProductGroup
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_PRODUCT_GROUPS_SUCCESS]: loadProductGroupsSuccess,

  [Types.PRODUCT_GROUP_ERROR]: productGroupError,

  [Types.SET_CURRENT_PRODUCT_GROUP]: setCurrentProductGroup,
  [Types.SET_LOADING_PRODUCT_GROUP]: setLoadingProductGroup,
  [Types.RESET_CURRENT_PRODUCT_GROUP]: resetCurrentProductGroup,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as ProductGroupTypes,
  Creators as ProductGroupActions
}
