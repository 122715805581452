import styled , { css } from 'styled-components'

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'

import { colors } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 32px 30px 23px;
  background-color: ${ colors.backgroundHtml };
  max-width: 100vw;
  overflow-x: hidden;
`

export const Title = styled(Typography)`
  font-size: 18px;
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: 700;
  color: ${ colors.black };
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 70%;
  align-self: flex-end;
`

export const ContainerListFields = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
  flex: 1;
  max-height: calc(100vh - 231px);

  ${ ({ isCenter }) => isCenter && css`
    justify-content: center;
    align-items: center;
  ` }
`

export const TabsGroup = styled(Tabs).attrs({
  variant: 'fullWidth',
  indicatorColor: 'primary'
})`
  color: ${ colors.black };
`

export const TabItem = styled(Tab)`
  min-width: 0;
  min-height: 0;
  pointer-events: inherit !important;
  height: 100%;
`
