import { reducer } from 'redux-offline-queue'
import { createActions } from 'reduxsauce'

import { makeActionFree } from '../stores'

export default reducer

const { Types, Creators } = createActions({
  changeNetworkStatus: ['connected']
})

makeActionFree('changeNetworkStatus')

export {
  Types as NetworkTypes,
  Creators as NetworkActions
}
