/* eslint-disable react/react-in-jsx-scope */
export default ({ size } = {}) => /* html */`
<svg width="${ size }" height="${ size }" viewBox="0 0 176 145" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M30.6855 51.3956C41.9123 67.643 26.1073 84.8082 16.8014 91.3599C-1.77905 107.691 8.68729 135.893 25.5358 141.363C43.3654 147.151 58.9077 135.788 65.9819 128.125C85.3284 107.167 118.644 112.531 141.984 112.475C183.603 112.377 179.47 55.5705 148.827 55.7644C130.41 55.8809 121.135 53.8745 116.399 42.085C110.475 27.339 96.3779 13.9427 92.4183 11.9547C53.6932 -7.48755 16.6519 31.0865 30.6855 51.3956Z" fill="#F9F8F6"/>
  <path d="M52.1383 120.851C50.7991 120.81 49.4599 120.733 48.1299 120.625C46.8831 120.512 45.4978 120.328 44.8051 119.727C44.371 119.347 44.2971 118.877 44.2417 118.425C40.2703 89.6742 38.5432 60.8164 39.0697 31.9765C39.0789 31.0784 39.199 30.0613 40.3996 29.4785C41.2678 29.0621 42.4684 28.9848 43.6044 28.9253C45.1837 28.8421 46.7631 28.7588 48.3424 28.6755C48.3701 28.7885 48.3885 28.8956 48.3978 29.0086C48.3978 29.0086 48.4682 29.1382 48.4682 29.6009L48.4682 30.0635C50.223 41.5305 51.2054 52.0199 51.7965 63.5463C52.4985 77.1604 52.5723 90.7804 52.4246 104.4C52.3507 109.878 52.2491 115.362 52.1383 120.851Z" fill="#1D1D1B"/>
  <path d="M57.1032 120.914C57.3341 109.471 57.5188 98.022 57.408 86.5848C57.2879 72.9886 56.7707 59.3864 55.4408 45.8259C54.8774 40.1043 54.1662 34.3827 53.2888 28.673C53.2888 28.6552 53.2796 28.6314 53.2796 28.6135C53.2704 28.56 53.2611 28.4946 53.2519 28.4351C55.0344 28.3459 56.8261 28.2507 58.6086 28.1556C59.0519 28.1139 59.5045 28.0783 59.9386 28.0366C61.7857 39.8426 62.8848 51.7021 63.5036 63.5676C64.2055 77.1817 64.2794 90.8017 64.1316 104.422C64.0762 109.822 63.9746 115.223 63.8638 120.629C61.601 120.772 59.3567 120.873 57.1032 120.914Z" fill="#1D1D1B"/>
  <path d="M68.9573 120.285C69.179 109.056 69.3452 97.8148 69.2436 86.5857C69.1235 72.9895 68.6063 59.3873 67.2764 45.8268C66.6761 39.7483 65.9187 33.6817 64.9582 27.6211C68.828 27.3059 72.6885 27.0085 76.5583 26.7587C77.6297 41.3898 78.4147 56.021 78.9134 70.664C79.4214 85.3486 79.6338 100.033 79.5599 114.712C79.5507 116.312 79.5415 117.912 79.5138 119.512C78.544 119.565 77.5835 119.631 76.6045 119.696C74.0647 119.88 71.5064 120.089 68.9573 120.285Z" fill="#1D1D1B"/>
  <path d="M84.5862 119.261C84.6693 111.279 84.6508 103.303 84.5492 95.3215C84.3738 80.6368 83.912 65.9522 83.1454 51.2735C82.7206 43.0599 82.2126 34.8462 81.6123 26.6326C81.603 26.5791 81.5938 26.5136 81.5846 26.4601C85.0665 26.2698 88.5483 26.133 92.0394 26.0557C93.1293 38.4505 94.1359 50.8393 94.8933 63.2401C95.7799 77.55 96.3525 91.8659 96.3895 106.188C96.3987 110.458 96.3525 114.729 96.2509 118.999C92.3627 119.005 88.4652 119.094 84.5862 119.261Z" fill="#1D1D1B"/>
  <path d="M107.987 119.149C107.405 119.143 106.851 119.137 106.343 119.119C104.671 119.072 102.99 119.036 101.309 119.012C101.559 108.491 101.448 97.9637 101.106 87.4364C100.653 73.1146 99.7484 58.8047 98.6308 44.5007C98.1506 38.3271 97.6334 32.1534 97.0977 25.9858C99.8223 25.9858 102.556 26.0155 105.299 26.1107C107.165 38.1903 108.171 50.3293 108.624 62.4684C109.141 76.2728 108.956 90.0891 108.532 103.893C108.375 108.979 108.181 114.064 107.987 119.149Z" fill="#1D1D1B"/>
  <path d="M113.068 119.008C113.502 107.964 113.881 96.919 113.983 85.8743C114.112 72.0759 113.807 58.2715 112.579 44.4968C112.034 38.4184 111.323 32.3518 110.39 26.2853C113.124 26.1663 118.166 25.5597 119.644 26.1604C121.02 26.7194 122.359 27.4629 122.72 28.4205C125.361 35.5041 124.724 41.642 124.724 47.9643C124.715 54.2866 125.223 58.1049 126.294 64.4035C126.894 67.9542 126.589 73.9791 127.153 77.5298C127.845 81.8894 127.439 86.2847 127.023 90.6562C126.294 98.287 127.291 105.394 124.816 112.906C124.41 114.137 123.394 115.208 122.6 116.421C121.584 117.985 117.354 118.723 113.068 119.008Z" fill="#1D1D1B"/>
  <path d="M119.488 89.7394C107.242 89.7394 97.2803 99.7016 97.2803 111.947C97.2803 124.193 107.242 134.155 119.488 134.155C131.733 134.155 141.696 124.193 141.696 111.947C141.696 99.7016 131.733 89.7394 119.488 89.7394Z" fill="#FFC80A"/>
  <path d="M121.579 124.036C121.579 125.051 120.754 125.876 119.739 125.876C118.723 125.876 117.899 125.051 117.899 124.036C117.899 123.02 118.723 122.196 119.739 122.196C120.754 122.196 121.579 123.015 121.579 124.036Z" fill="#1D1D1B"/>
  <path d="M122.656 114.151C122.002 114.453 121.58 115.106 121.58 115.825V116.65C121.58 117.665 120.755 118.49 119.74 118.49C118.725 118.49 117.9 117.665 117.9 116.65V115.825C117.9 113.674 119.162 111.708 121.112 110.803C122.987 109.939 124.345 107.646 124.345 106.52C124.345 103.982 122.279 101.916 119.74 101.916C117.201 101.916 115.135 103.982 115.135 106.52C115.135 107.536 114.311 108.36 113.295 108.36C112.275 108.36 111.456 107.536 111.456 106.52C111.456 101.951 115.176 98.236 119.74 98.236C124.305 98.236 128.024 101.956 128.024 106.52C128.029 109.014 125.868 112.668 122.656 114.151Z" fill="#1D1D1B"/>
  <path d="M18.6154 50.4093C17.9847 51.8048 16.336 52.43 14.9411 51.8005C13.5461 51.171 12.9223 49.5228 13.553 48.1273C14.1838 46.7318 15.8324 46.1066 17.2274 46.7361C18.628 47.3712 19.2462 49.0137 18.6154 50.4093Z" fill="#E0E0E0"/>
  <path d="M18.6155 113.331C17.9847 114.727 16.336 115.352 14.9411 114.722C13.5461 114.093 12.9223 112.445 13.5531 111.049C14.1838 109.654 15.8325 109.028 17.2275 109.658C18.6281 110.293 19.2462 111.935 18.6155 113.331Z" fill="#E0E0E0"/>
  <path d="M144.461 82.7974C143.83 84.193 142.181 84.8182 140.786 84.1887C139.391 83.5592 138.768 81.9111 139.398 80.5155C140.029 79.12 141.678 78.4948 143.073 79.1242C144.473 79.7481 145.097 81.3963 144.461 82.7974Z" fill="#E0E0E0"/>
  <path d="M158.351 46.7057C157.72 48.1012 156.071 48.7264 154.676 48.0969C153.281 47.4674 152.658 45.8193 153.288 44.4237C153.919 43.0282 155.568 42.403 156.963 43.0325C158.358 43.662 158.982 45.3101 158.351 46.7057Z" fill="#E0E0E0"/>
  <path d="M55.6414 13.3949C55.0106 14.7905 53.3619 15.4157 51.9669 14.7862C50.572 14.1567 49.9482 12.5086 50.579 11.113C51.2097 9.71745 52.8584 9.09219 54.2534 9.72168C55.654 10.3568 56.2721 11.9993 55.6414 13.3949Z" fill="#E0E0E0"/>
  <path d="M55.6413 131.837C55.0106 133.233 53.3619 133.858 51.9669 133.228C50.5719 132.599 49.9482 130.951 50.5789 129.555C51.2096 128.16 52.8583 127.534 54.2533 128.164C55.6483 128.793 56.2721 130.442 55.6413 131.837Z" fill="#E0E0E0"/>
  <path d="M165.742 119.81C165.112 121.205 163.463 121.831 162.068 121.201C160.673 120.572 160.049 118.923 160.68 117.528C161.311 116.132 162.96 115.507 164.354 116.137C165.755 116.772 166.373 118.414 165.742 119.81Z" fill="#E0E0E0"/>
</svg>
`
