import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  button: {
    marginTop: 5,
    fontSize: '1.2em',
    flex: 1
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center'
  }
})
