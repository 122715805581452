import React, { useMemo, useCallback, useState } from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import { userCircle, camera, animalCircle } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'
import InputFile from '@smartcoop/web-components/InputFile'

import { Container, IconButtonContainer, Image } from './styles'


const AvatarInput = (props) => {
  const {
    src,
    onChange,
    style,
    animalAvatar,
    hideUpload
  } = props
  const [selectedFile, setSelectedFile] = useState()

  const source = useMemo(
    () => selectedFile || src, [selectedFile, src]
  )

  const renderImage = useMemo(
    () => (isEmpty(source) ?
      <Icon icon={ animalAvatar ? animalCircle : userCircle } size={ 120 } /> :
      <Image src={ source }/>)
    ,[source, animalAvatar]
  )

  const handleChangeFile = useCallback(
    async (event) => {
      const { files } = event.target
      const file = files[0]
      setSelectedFile(URL.createObjectURL(file))
      onChange(file)
    },
    [onChange]
  )


  return (
    <Container style={ style }>
      {renderImage}
      {!hideUpload && (
        <IconButtonContainer>
          <InputFile
            idInput="contained-button-file"
            onChange={ (event) => handleChangeFile(event) }
            inputProps={ {
              accept: 'image/png,image/jpeg'
            } }
            buttonProps={ {
              id: 'add-documents',
              variant: 'outlined',
              style:{
                minWidth: 0,
                width: 50,
                height: 50,
                border: 0
              }
            } }
          >
            <>
              <Icon
                icon={ camera }
                color={ colors.white }
              />
            </>
          </InputFile>
        </IconButtonContainer>
      )}
    </Container>
  )
}

AvatarInput.propTypes = {
  style: PropTypes.object,
  src: PropTypes.string,
  onChange: PropTypes.func,
  animalAvatar: PropTypes.bool,
  hideUpload: PropTypes.bool
}

AvatarInput.defaultProps = {
  style: {},
  src: '',
  onChange: () => {},
  animalAvatar: false,
  hideUpload: false
}

export default AvatarInput
