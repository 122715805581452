import * as Yup from 'yup'

import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/isEmpty'

import float from '@smartcoop/forms/validators/float.validator'
import required from '@smartcoop/forms/validators/required.validator'


const correctionCropmanagementSchema = ({ t }) => Yup.object().shape({
  formFields: Yup.array()
    .of(
      Yup.object().shape({
        product: flow(required({ t }))(Yup.string()),

        dose: flow(
          float({ t })
        )(Yup.string()),

        unit:  Yup.string().when('dose', {
          is: val => !isEmpty(val) && val !== '' && val !== 0 && val !== '0' && val !== '0,00',
          then: required({ t })(Yup.string()),
          otherwise: Yup.string()
        }),

        value: Yup.string().nullable(),
        valueHA: Yup.string().nullable(),
        valueDose: Yup.string().nullable(),

        applicationMode: Yup.string().nullable()
      })
    )
})

export default correctionCropmanagementSchema
