import React, { useRef, useCallback, useMemo, useState } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import InputAdornment from '@material-ui/core/InputAdornment'

import monthYearMask from '@smartcoop/forms/masks/monthYear.mask'
import { calendar } from '@smartcoop/icons'
import { momentBackMonthYearFormat, momentFriendlyMonthYearFormat } from '@smartcoop/utils/dates'
import CalendarPicker from '@smartcoop/web-components/CalendarPicker'
import Icon from '@smartcoop/web-components/Icon'
import TextField from '@smartcoop/web-components/TextField'

const InputMonthYear = (props) => {
  const {
    value,
    onChange,
    onClick,
    adornmentStyle,
    pickerProps,
    fullWidth,
    ...rest
  } = props

  const { detached, disabled } = rest

  const [calendarValue, setCalendarValue] = useState(value)

  const date = useMemo(
    () => detached ? value : calendarValue,
    [calendarValue, detached, value]
  )

  const inputRef = useRef()

  const calendarOptions = useMemo(
    () => ({ mode: 'single' }),
    []
  )

  const transformRender = useCallback(
    (newDate) => {
      const newMoment = moment(newDate, momentBackMonthYearFormat, true)
      if (newMoment.isValid()) {
        return newMoment.format(momentFriendlyMonthYearFormat)
      }
      return newMoment._i
    },
    []
  )

  const transformValue = useCallback(
    (newDate) => {
      const newMoment = moment(newDate, momentFriendlyMonthYearFormat, true)
      if (newMoment.isValid()) {
        return newMoment.format(momentBackMonthYearFormat)
      }
      return newMoment._i
    },
    []
  )

  // only use it outside a form
  const handleInputChange = useCallback(
    ({ target: { value: newDate } }) => {
      onChange(transformValue(newDate))
    },
    [onChange, transformValue]
  )

  // only use it inside a form
  const handleFieldChange = useCallback(
    (newDate) => {
      if (!detached) {
        if (newDate !== value && moment(newDate, momentBackMonthYearFormat, true).isValid()) {
          setCalendarValue(newDate)
        } else {
          setCalendarValue('')
        }
      }
    },
    [detached, value]
  )

  const handleCalendarChange = useCallback(
    (newDate) => {
      if (!detached && inputRef.current.setValue) {
        inputRef.current.setValue(newDate)
      }
      onChange(newDate)
    },
    [detached, onChange]
  )

  if (detached) {
    rest.value = date
    rest.onChange = handleInputChange
  } else {
    rest.transformValue = transformValue
    rest.onFieldValueChange = handleFieldChange
  }

  const input = (
    <TextField
      data-input
      ref={ inputRef }
      { ...rest }
      autoComplete="off"
      transformRender={ transformRender }
      type="tel"
      setMask={ monthYearMask }
      InputProps={ {
        endAdornment: (
          <InputAdornment style={ { paddingRight: 18, ...adornmentStyle } } position="end">
            <Icon
              icon={ calendar }
              size={ 18 }
              color="#151818"
              onClick={ onClick }
              data-toggle
            />
          </InputAdornment>
        )
      } }
    />
  )

  return (
    disabled
      ? input
      : (
        <CalendarPicker
          value={ date }
          onChange={ handleCalendarChange }
          options={ calendarOptions }
          mode="month"
          fullWidth={ fullWidth }
          { ...pickerProps }
        >
          {input}
        </CalendarPicker>
      )
  )
}

InputMonthYear.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  adornmentStyle: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  pickerProps: PropTypes.object
}

InputMonthYear.defaultProps = {
  value: '',
  onChange: () => {},
  adornmentStyle: {},
  onClick: () => {},
  disabled: false,
  fullWidth: false,
  pickerProps: {}
}

export default InputMonthYear
