import React from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import { notFound } from '@smartcoop/icons'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import Modal from '@smartcoop/web-components/Modal'

import { Container, MainText, HeaderText } from './styles'

const ErrorModal = (props) => {
  const { id, open, handleClose, message } = props

  return (
    <Modal
      id={ id }
      open={ open }
      escape={ false }
      hideHeader
    >
      <Container>

        <HeaderText>
          <I18n>ops</I18n>
        </HeaderText>

        <Icon icon={ notFound } size={ 125 } />

        {message && (
          <MainText>
            {message}
          </MainText>
        )}

        <Button
          id="ok-error"
          onClick={ handleClose }
          style={ { minWidth: 100 } }
        >
          <I18n>ok</I18n>
        </Button>

      </Container>
    </Modal>
  )
}

ErrorModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
}

ErrorModal.defaultProps = {
  message: null
}

export default ErrorModal
