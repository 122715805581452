import React, { useState, useCallback, useMemo } from 'react'

import get from 'lodash/get'
import map from 'lodash/map'

import { useT } from '@smartcoop/i18n'
import { useDairyFarmQualityChartOptions } from '@smartcoop/utils/charts'
import Chart from '@smartcoop/web-components/Chart'

import useStyles, {
  TabsGroup,
  TabItem
} from './styles'

const DairyFarmQualityChart = () => {
  const t = useT()
  const classes = useStyles()

  const [active, setActive] = useState(0)

  const options = useMemo(
    () => [
      { label: t('ccs'), value: 'ccs' },
      { label: t('ctb'), value: 'ctb' },
      { label: t('protein'), value: 'protein' },
      { label: t('fat'), value: 'fat' }
    ],
    [t]
  )

  const type = useMemo(
    () => get(options, `[${ active }].value`),
    [options, active]
  )

  const chartOptions = useDairyFarmQualityChartOptions(type)

  const onChange = useCallback(
    (_, newTab) => setActive(newTab),
    []
  )

  return (
    <div style={ { height: 300, width: '100%', flexGrow: 1 } }>
      <TabsGroup
        aria-label="milk quality"
        value={ active }
        onChange={ onChange }
        classes={ classes }
      >
        {map(options, option => (
          <TabItem key={ option.value } label={ option.label }/>
        ))}
      </TabsGroup>

      <Chart
        options={ chartOptions }
        containerProps={ { style: { height: 260 } } }
      />
    </div>
  )
}

export default DairyFarmQualityChart
