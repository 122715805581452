// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
      <g>
        <path fill="none" d="M19.5,148.5c-0.1-0.1-0.3-0.2-0.4-0.3C19.2,148.3,19.3,148.4,19.5,148.5z"/>
        <path fill="none" d="M18.7,147.9c-0.1-0.1-0.3-0.2-0.4-0.3C18.4,147.7,18.5,147.8,18.7,147.9z"/>
        <path fill="none" d="M17.9,147.3c-0.1-0.1-0.3-0.2-0.4-0.3C17.7,147.1,17.8,147.2,17.9,147.3z"/>
        <path fill="none" d="M16.6,146c-0.1-0.1-0.2-0.3-0.3-0.4C16.4,145.7,16.5,145.8,16.6,146z"/>
        <path fill="none" d="M16,145.2c-0.1-0.1-0.2-0.3-0.3-0.5C15.8,144.9,15.9,145.1,16,145.2z"/>
        <path fill="none" d="M17.2,146.7c-0.1-0.1-0.2-0.3-0.4-0.4C17,146.4,17.1,146.6,17.2,146.7z"/>
        <path fill="none" d="M22,149.6c-0.2-0.1-0.3-0.1-0.5-0.2C21.7,149.5,21.9,149.6,22,149.6z"/>
        <path fill="none" d="M20.3,148.9c-0.2-0.1-0.3-0.2-0.4-0.2C20,148.8,20.1,148.8,20.3,148.9z"/>
        <path fill="none" d="M42.4,102.6c0.1-0.1,0.2-0.1,0.3-0.2C42.6,102.4,42.5,102.5,42.4,102.6z"/>
        <path fill="none" d="M21.1,149.3c-0.2-0.1-0.3-0.1-0.5-0.2C20.8,149.2,21,149.2,21.1,149.3z"/>
        <path fill="none" d="M38.2,106.7c0.1-0.1,0.2-0.2,0.2-0.3C38.3,106.5,38.3,106.6,38.2,106.7z"/>
        <path fill="none" d="M15.5,144.4c-0.1-0.2-0.2-0.3-0.3-0.5C15.3,144.1,15.4,144.2,15.5,144.4z"/>
        <path fill="none" d="M37,108.3c0.1-0.1,0.1-0.2,0.2-0.3C37.1,108.2,37,108.3,37,108.3z"/>
        <path fill="none" d="M40.9,103.8c0.1-0.1,0.1-0.1,0.2-0.2C41,103.7,41,103.8,40.9,103.8z"/>
        <path fill="none" d="M39.6,105.1c0,0,0.1-0.1,0.1-0.1C39.6,105,39.6,105.1,39.6,105.1z"/>
        <path fill="none" d="M36,109.9C36,109.9,36,109.9,36,109.9C36,109.9,36,109.9,36,109.9z"/>
        <path fill="none" d="M23.9,150c-0.2,0-0.3,0-0.5-0.1C23.6,150,23.7,150,23.9,150z"/>
        <path fill="none" d="M14.7,142.6c-0.1-0.3-0.2-0.6-0.3-1C14.5,142,14.6,142.3,14.7,142.6z"/>
        <path fill="none" d="M15,143.5c-0.1-0.2-0.2-0.4-0.3-0.6C14.9,143.1,15,143.3,15,143.5z"/>
        <path fill="none" d="M34.2,113.8c0-0.1,0.1-0.2,0.1-0.3C34.3,113.6,34.3,113.7,34.2,113.8z"/>
        <path fill="none" d="M35,111.9c0-0.1,0.1-0.2,0.1-0.3C35.1,111.6,35.1,111.7,35,111.9z"/>
        <path fill="none" d="M23,149.9c-0.2,0-0.3-0.1-0.5-0.1C22.6,149.8,22.8,149.8,23,149.9z"/>
        <path fill="none" d="M166,92.9c0.1,0,0.1,0,0.2,0.1C166.1,92.9,166.1,92.9,166,92.9z"/>
        <path fill="none" d="M168.1,93.5c0.1,0,0.2,0.1,0.3,0.1C168.3,93.6,168.2,93.5,168.1,93.5z"/>
        <path fill="none" d="M170.3,94.3c0.1,0.1,0.3,0.1,0.4,0.2C170.5,94.4,170.4,94.4,170.3,94.3z"/>
        <path fill="none" d="M163.6,92.4c0.1,0,0.3,0,0.4,0.1C163.8,92.4,163.7,92.4,163.6,92.4z"/>
        <path fill="none" d="M172.5,95.4c0.1,0,0.2,0.1,0.2,0.1C172.7,95.5,172.6,95.4,172.5,95.4z"/>
        <path fill="none" d="M155.1,92.3c0.2,0,0.4-0.1,0.6-0.1C155.4,92.2,155.3,92.3,155.1,92.3z"/>
        <path fill="none" d="M153.4,92.6c0.2,0,0.3-0.1,0.5-0.1C153.7,92.5,153.5,92.5,153.4,92.6z"/>
        <path fill="none" d="M174.4,96.5c0.1,0,0.1,0.1,0.2,0.1C174.6,96.6,174.5,96.5,174.4,96.5z"/>
        <path fill="none" d="M156.8,92.1c0.2,0,0.3,0,0.5,0C157.2,92.1,157,92.1,156.8,92.1z"/>
        <path fill="none" d="M159,92C159,92,159,92,159,92c0,0,0.1,0,0.1,0C159.1,92,159,92,159,92z"/>
        <path fill="none" d="M176.3,97.8c0.1,0.1,0.2,0.2,0.3,0.2C176.6,98,176.5,97.9,176.3,97.8z"/>
        <path fill="none" d="M186.2,111c0,0.1,0.1,0.2,0.1,0.4C186.3,111.3,186.3,111.2,186.2,111z"/>
        <path fill="none" d="M185.2,108.7c0.1,0.1,0.1,0.2,0.2,0.4C185.4,108.9,185.3,108.8,185.2,108.7z"/>
        <path fill="none" d="M184.2,106.7C184.2,106.7,184.2,106.7,184.2,106.7C184.2,106.7,184.2,106.7,184.2,106.7z"/>
        <path fill="none" d="M25.5,150.2c0.2,0,0.5,0,0.7,0c-0.3,0-0.6,0-0.9,0C25.4,150.2,25.4,150.2,25.5,150.2z"/>
        <path fill="none" d="M178.2,99.3c0.1,0.1,0.2,0.1,0.2,0.2C178.4,99.5,178.3,99.4,178.2,99.3z"/>
        <path fill="none" d="M182.9,104.6c0.1,0.1,0.1,0.2,0.2,0.3C183,104.8,183,104.7,182.9,104.6z"/>
        <path fill="none" d="M24.9,150.1c-0.2,0-0.3,0-0.5,0C24.5,150.1,24.7,150.1,24.9,150.1z"/>
        <path fill="none" d="M179.9,100.9c0,0,0.1,0.1,0.1,0.1C180,101,180,101,179.9,100.9z"/>
        <path fill="none" d="M181.4,102.6c0.1,0.1,0.2,0.2,0.3,0.3C181.6,102.8,181.5,102.7,181.4,102.6z"/>
        <path fill="none" d="M161.2,92.1c0.1,0,0.2,0,0.3,0C161.4,92.1,161.3,92.1,161.2,92.1z"/>
        <path fill="none" d="M47.5,99.6c0.1,0,0.2-0.1,0.3-0.1C47.7,99.5,47.6,99.5,47.5,99.6z"/>
        <path fill="none" d="M45.7,100.4c0.1,0,0.1-0.1,0.2-0.1C45.9,100.3,45.8,100.4,45.7,100.4z"/>
        <path fill="none" d="M51.3,98.2c0.1,0,0.1,0,0.2,0C51.4,98.2,51.3,98.2,51.3,98.2z"/>
        <path fill="none" d="M53.2,97.8C53.2,97.8,53.2,97.8,53.2,97.8C53.2,97.8,53.2,97.8,53.2,97.8L53.2,97.8z"/>
        <path fill="none" d="M49.4,98.8c0.1,0,0.2-0.1,0.3-0.1C49.6,98.7,49.5,98.8,49.4,98.8z"/>
        <path fill="none" d="M44.1,101.4c0.1,0,0.1-0.1,0.2-0.1C44.2,101.3,44.2,101.3,44.1,101.4z"/>
        <path fill="#DDC72D" d="M61.7,97.8c0.1,0,0.3,0,0.4,0.1C61.9,97.9,61.8,97.9,61.7,97.8z"/>
        <path fill="#DDC72D" d="M57.4,97.5C57.3,97.5,57.3,97.5,57.4,97.5C57.3,97.5,57.3,97.5,57.4,97.5C57.4,97.5,57.4,97.5,57.4,97.5z"
          />
        <path fill="#DDC72D" d="M58.8,97.5c0.1,0,0.3,0,0.4,0C59,97.5,58.9,97.5,58.8,97.5z"/>
        <path fill="#F9D915" d="M60,91.6c2.6-9.1,7.8-17.3,15-23.6c3.6-3.2,7.7-5.7,12-7.7c-4.7-5.9-11.9-9.6-20-9.6
          c-14.1,0-25.6,11.5-25.6,25.6c0,6.4,2.4,12.3,6.3,16.8c3.1-1,6.3-1.6,9.7-1.6C58.2,91.5,59.1,91.5,60,91.6z"/>
        <path fill="#DDC72D" d="M60.2,97.6c0.2,0,0.3,0,0.5,0.1C60.5,97.7,60.3,97.6,60.2,97.6z"/>
        <path fill="#DDC72D" d="M55.2,97.6c0.1,0,0.2,0,0.3,0C55.4,97.5,55.3,97.5,55.2,97.6z"/>
        <path fill="#DDC72D" d="M53.5,97.8c-0.1,0-0.2,0-0.3,0.1l0,0C53.3,97.8,53.4,97.8,53.5,97.8z"/>
        <path fill="#F9D915" d="M38.7,31l8.7,21.1c2.5-2.1,5.4-3.7,8.6-4.9L42.1,29.1c0,0,0,0,0,0c-0.6-0.8-1.9-1-2.7-0.3
          c-0.7,0.5-0.9,1.4-0.7,2.2C38.7,30.9,38.7,31,38.7,31z"/>
        <path fill="#F9D915" d="M72,45.6L69.1,23c0,0,0,0,0-0.1c-0.2-1-1.1-1.8-2.2-1.7c-0.8,0.1-1.5,0.7-1.7,1.5c0,0,0,0.1,0,0.1l-3,22.6
          c1.6-0.3,3.2-0.4,4.9-0.4C68.7,45.2,70.3,45.4,72,45.6z"/>
        <path fill="#F9D915" d="M13.5,78.1c0,0,0.1,0,0.1,0l22.6,3c-0.3-1.6-0.4-3.2-0.4-4.9c0-1.7,0.1-3.4,0.4-5l-22.6,2.9c0,0,0,0-0.1,0
          c-1,0.2-1.8,1.1-1.6,2.2C12.1,77.2,12.7,77.9,13.5,78.1z"/>
        <path fill="#F9D915" d="M95.4,31.2C95.4,31.2,95.4,31.1,95.4,31.2c0.2-0.5,0.2-1,0-1.5c-0.2-0.5-0.6-0.9-1.1-1.1
          c-0.8-0.3-1.7-0.1-2.2,0.5c0,0-0.1,0.1-0.1,0.1l-14,18.1c3.1,1.2,6,2.9,8.5,5L95.4,31.2z"/>
        <path fill="#F9D915" d="M19.9,51.2l18.1,14c1.2-3.1,2.9-6,5-8.5l-21.1-8.8c0,0,0,0-0.1,0c-1-0.4-2.1,0.1-2.5,1.1
          c-0.3,0.8-0.1,1.7,0.5,2.2C19.8,51.1,19.9,51.2,19.9,51.2z"/>
        <path fill="#D3D3D3" d="M187,113.6c-0.2-0.7-0.4-1.4-0.7-2.2c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.7-0.5-1.3-0.8-2
          c-0.1-0.1-0.1-0.2-0.2-0.4c-0.3-0.7-0.6-1.3-1-1.9c0,0,0-0.1-0.1-0.1c-0.3-0.6-0.7-1.2-1.1-1.8c-0.1-0.1-0.1-0.2-0.2-0.3
          c-0.4-0.6-0.8-1.1-1.2-1.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.5-0.9-1.1-1.4-1.6c0,0-0.1-0.1-0.1-0.1c-0.5-0.5-0.9-0.9-1.4-1.4
          c-0.1-0.1-0.2-0.1-0.2-0.2c-0.5-0.5-1-0.9-1.6-1.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.5-0.4-1.1-0.8-1.7-1.2c-0.1,0-0.1-0.1-0.2-0.1
          c-0.6-0.4-1.1-0.7-1.7-1c-0.1,0-0.2-0.1-0.2-0.1c-0.6-0.3-1.2-0.6-1.8-0.9c-0.1-0.1-0.2-0.1-0.4-0.2c-0.6-0.3-1.3-0.5-1.9-0.7
          c-0.1,0-0.2-0.1-0.3-0.1c-0.6-0.2-1.3-0.4-1.9-0.6c-0.1,0-0.1,0-0.2-0.1c-0.7-0.2-1.3-0.3-2-0.4c-0.1,0-0.3,0-0.4-0.1
          c-0.7-0.1-1.4-0.2-2-0.3c-0.1,0-0.2,0-0.3,0c-0.7-0.1-1.4-0.1-2.1-0.1c0,0-0.1,0-0.1,0c-0.5,0-1.1,0-1.6,0.1c-0.2,0-0.3,0-0.5,0
          c-0.4,0-0.8,0.1-1.2,0.1c-0.2,0-0.4,0-0.6,0.1c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.1-1.1,0.2-1.7,0.4
          c0,0-1.1,0.3-3.1,0.8C143.7,75.5,127,62,107.1,62c-2.6,0-5.2,0.2-7.6,0.7c-1.4,0.3-2.8,0.6-4.2,1c-1.8,0.5-3.5,1.1-5.1,1.8
          c-13.4,5.8-23.3,18.2-25.5,33.1c-0.4-0.1-0.6-0.2-0.8-0.2c-0.2-0.1-0.3-0.1-0.3-0.1c-0.5-0.1-0.9-0.2-1.4-0.3
          c-0.1,0-0.3-0.1-0.4-0.1c-0.3-0.1-0.7-0.1-1-0.2c-0.2,0-0.3,0-0.5-0.1c-0.3,0-0.7-0.1-1-0.1c-0.1,0-0.3,0-0.4,0
          c-0.5,0-0.9,0-1.4,0c0,0-0.1,0-0.1,0c-0.6,0-1.2,0-1.8,0.1c-0.1,0-0.2,0-0.3,0c-0.6,0.1-1.2,0.1-1.7,0.2c-0.1,0-0.2,0-0.3,0.1
          c0,0,0,0,0,0c-0.6,0.1-1.1,0.2-1.7,0.4c-0.1,0-0.1,0-0.2,0c-0.5,0.1-1.1,0.3-1.6,0.5c-0.1,0-0.2,0.1-0.3,0.1
          c-0.5,0.2-1.1,0.4-1.6,0.6c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.2-1,0.5-1.5,0.7c-0.1,0-0.1,0.1-0.2,0.1c-0.5,0.3-0.9,0.5-1.4,0.8
          c-0.1,0-0.1,0.1-0.2,0.1c-0.5,0.3-0.9,0.6-1.4,1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5,0.3-0.9,0.7-1.3,1.1c-0.1,0.1-0.1,0.1-0.2,0.2
          c-0.4,0.4-0.8,0.8-1.2,1.2c0,0-0.1,0.1-0.1,0.1c-0.4,0.4-0.8,0.9-1.2,1.3c-0.1,0.1-0.2,0.2-0.2,0.3c-0.4,0.4-0.7,0.9-1,1.4
          c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.5-0.6,1-0.9,1.5c0,0,0,0.1-0.1,0.1c-0.3,0.5-0.6,1.1-0.8,1.6c0,0.1-0.1,0.2-0.1,0.3
          c-0.2,0.5-0.5,1.1-0.7,1.7c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.6-0.4,1.2-0.6,1.8c-0.2,0.8-0.4,1.5-0.5,2.3s-0.2,1.5-0.3,2.3
          c-0.1,1.5-0.1,3,0.1,4.5c-6.5,1.8-10.5,2.8-10.5,2.8c-6.1,1.6-9.8,7.9-8.1,14c0.1,0.3,0.2,0.6,0.3,1c0,0.1,0.1,0.2,0.1,0.3
          c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.3,0.3,0.5
          c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.2,0.2,0.3,0.3
          c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.3
          c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0.1,0.3,0.1,0.4,0.2
          c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1
          c0.1,0,0.3,0,0.4,0.1c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0c0.3,0,0.6,0,0.9,0h132.1c0,0,5.5-0.3,8.2-1
          C182,145,191.2,129.1,187,113.6z"/>
        <path fill="#F9D915" d="M42.5,95.3c-1.9-2.4-3.5-5.1-4.6-8.1l-18.2,13.9c0,0,0,0-0.1,0c-0.4,0.3-0.6,0.8-0.7,1.3
          c-0.1,0.5,0.1,1,0.4,1.5c0.5,0.7,1.4,0.9,2.2,0.7c0,0,0.1,0,0.1,0l18-7.4l3.2-1.3C42.7,95.7,42.6,95.5,42.5,95.3z"/>
        <path fill="#FFFFFF" d="M181.7,103c0.4,0.5,0.8,1.1,1.2,1.7C182.5,104,182.1,103.5,181.7,103z"/>
        <path fill="#FFFFFF" d="M183.1,104.9c0.4,0.6,0.8,1.2,1.1,1.8C183.9,106.1,183.5,105.5,183.1,104.9z"/>
        <path fill="#FFFFFF" d="M178.5,99.5c0.5,0.4,1,0.9,1.4,1.4C179.5,100.5,179,100,178.5,99.5z"/>
        <path fill="#FFFFFF" d="M180,101.1c0.5,0.5,0.9,1,1.4,1.6C181,102.1,180.5,101.6,180,101.1z"/>
        <path fill="#FFFFFF" d="M164,92.5c0.7,0.1,1.4,0.3,2,0.4C165.3,92.7,164.6,92.6,164,92.5z"/>
        <path fill="#FFFFFF" d="M155.6,92.2c0.4,0,0.8-0.1,1.2-0.1C156.4,92.1,156,92.2,155.6,92.2z"/>
        <path fill="#FFFFFF" d="M157.3,92.1c0.6,0,1.1-0.1,1.6-0.1C158.4,92,157.9,92,157.3,92.1z"/>
        <path fill="#FFFFFF" d="M41.1,103.6c0.4-0.4,0.9-0.7,1.3-1.1C42,102.9,41.5,103.3,41.1,103.6z"/>
        <path fill="#FFFFFF" d="M151.7,92.9c0.6-0.1,1.1-0.3,1.7-0.4C152.8,92.7,152.3,92.8,151.7,92.9z"/>
        <path fill="#FFFFFF" d="M161.5,92.1c0.7,0.1,1.4,0.1,2,0.3C162.9,92.3,162.2,92.2,161.5,92.1z"/>
        <path fill="#FFFFFF" d="M153.9,92.5c0.4-0.1,0.8-0.1,1.2-0.2C154.7,92.3,154.3,92.4,153.9,92.5z"/>
        <path fill="#FFFFFF" d="M170.7,94.5c0.6,0.3,1.2,0.6,1.8,0.9C171.9,95.1,171.3,94.8,170.7,94.5z"/>
        <path fill="#FFFFFF" d="M172.7,95.5c0.6,0.3,1.1,0.6,1.7,1C173.9,96.1,173.3,95.8,172.7,95.5z"/>
        <path fill="#FFFFFF" d="M174.7,96.6c0.6,0.4,1.1,0.8,1.7,1.2C175.8,97.4,175.2,97,174.7,96.6z"/>
        <path fill="#FFFFFF" d="M184.3,106.8c0.4,0.6,0.7,1.3,1,1.9C184.9,108,184.6,107.4,184.3,106.8z"/>
        <path fill="#FFFFFF" d="M176.7,98c0.5,0.4,1.1,0.8,1.6,1.3C177.7,98.9,177.2,98.5,176.7,98z"/>
        <path fill="#FFFFFF" d="M168.4,93.6c0.6,0.2,1.3,0.5,1.9,0.7C169.7,94.1,169,93.8,168.4,93.6z"/>
        <path fill="#FFFFFF" d="M166.2,92.9c0.6,0.2,1.3,0.4,1.9,0.6C167.5,93.3,166.8,93.1,166.2,92.9z"/>
        <path fill="#FFFFFF" d="M159.1,92c0.7,0,1.4,0,2.1,0.1C160.5,92.1,159.8,92,159.1,92z"/>
        <path fill="#FFFFFF" d="M33.2,117.9c0.1-0.8,0.3-1.5,0.5-2.3C33.5,116.4,33.3,117.2,33.2,117.9z"/>
        <path fill="#FFFFFF" d="M47.8,99.4c0.5-0.2,1.1-0.4,1.6-0.6C48.9,99,48.3,99.2,47.8,99.4z"/>
        <path fill="#FFFFFF" d="M33.7,115.6c0.2-0.6,0.4-1.2,0.6-1.8C34,114.4,33.8,115,33.7,115.6z"/>
        <path fill="#FFFFFF" d="M14.8,142.9c0-0.1-0.1-0.2-0.1-0.3C14.7,142.7,14.8,142.8,14.8,142.9z"/>
        <path fill="#FFFFFF" d="M32.9,120.2c0.1-0.8,0.1-1.5,0.3-2.3C33,118.7,33,119.5,32.9,120.2z"/>
        <path fill="#FFFFFF" d="M51.4,98.2c0.6-0.1,1.1-0.3,1.7-0.4C52.6,97.9,52,98,51.4,98.2z"/>
        <path fill="#FFFFFF" d="M15.7,144.8c-0.1-0.1-0.2-0.2-0.2-0.4C15.6,144.5,15.6,144.6,15.7,144.8z"/>
        <path fill="#FFFFFF" d="M15.2,143.9c-0.1-0.1-0.1-0.2-0.2-0.4C15.1,143.7,15.2,143.8,15.2,143.9z"/>
        <path fill="#FFFFFF" d="M49.7,98.7c0.5-0.2,1.1-0.3,1.6-0.5C50.7,98.4,50.2,98.5,49.7,98.7z"/>
        <path fill="#FFFFFF" d="M39.7,105c0.4-0.4,0.8-0.8,1.2-1.2C40.5,104.2,40.1,104.6,39.7,105z"/>
        <path fill="#FFFFFF" d="M44.3,101.2c0.5-0.3,0.9-0.6,1.4-0.8C45.2,100.7,44.8,100.9,44.3,101.2z"/>
        <path fill="#FFFFFF" d="M16.3,145.6c-0.1-0.1-0.2-0.2-0.3-0.3C16.1,145.3,16.2,145.4,16.3,145.6z"/>
        <path fill="#FFFFFF" d="M38.4,106.4c0.4-0.5,0.8-0.9,1.2-1.3C39.2,105.5,38.8,106,38.4,106.4z"/>
        <path fill="#FFFFFF" d="M42.7,102.3c0.5-0.3,0.9-0.7,1.4-1C43.6,101.7,43.2,102,42.7,102.3z"/>
        <path fill="#FFFFFF" d="M36.1,109.8c0.3-0.5,0.6-1,0.9-1.5C36.7,108.8,36.3,109.3,36.1,109.8z"/>
        <path fill="#FFFFFF" d="M45.9,100.3c0.5-0.3,1-0.5,1.5-0.7C47,99.8,46.4,100,45.9,100.3z"/>
        <path fill="#FFFFFF" d="M35.2,111.5c0.3-0.6,0.5-1.1,0.8-1.6C35.7,110.4,35.4,111,35.2,111.5z"/>
        <path fill="#FFFFFF" d="M34.3,113.5c0.2-0.6,0.4-1.1,0.7-1.7C34.8,112.4,34.6,112.9,34.3,113.5z"/>
        <path fill="#FFFFFF" d="M37.1,108.1c0.3-0.5,0.7-0.9,1-1.4C37.8,107.1,37.5,107.6,37.1,108.1z"/>
        <path fill="#FFFFFF" d="M185.4,109.1c0.3,0.6,0.6,1.3,0.8,2C186,110.4,185.7,109.7,185.4,109.1z"/>
        <path fill="#FFFFFF" d="M22.5,149.8c-0.1,0-0.3-0.1-0.4-0.1C22.2,149.7,22.3,149.7,22.5,149.8z"/>
        <path fill="#FFFFFF" d="M23.4,150c-0.1,0-0.3-0.1-0.4-0.1C23.1,149.9,23.2,149.9,23.4,150z"/>
        <path fill="#FFFFFF" d="M20.7,149.1c-0.1-0.1-0.3-0.1-0.4-0.2C20.4,149,20.5,149,20.7,149.1z"/>
        <path fill="#FFFFFF" d="M25.3,150.2c-0.1,0-0.3,0-0.4,0C25,150.1,25.2,150.1,25.3,150.2z"/>
        <path fill="#FFFFFF" d="M24.3,150.1c-0.1,0-0.3,0-0.4-0.1C24,150.1,24.2,150.1,24.3,150.1z"/>
        <path fill="#FFFFFF" d="M186.4,111.4c0.2,0.7,0.5,1.4,0.7,2.2C186.8,112.8,186.6,112.1,186.4,111.4z"/>
        <path fill="#FFFFFF" d="M19.8,148.7c-0.1-0.1-0.3-0.1-0.4-0.2C19.6,148.5,19.7,148.6,19.8,148.7z"/>
        <path fill="#FFFFFF" d="M21.6,149.5c-0.1-0.1-0.3-0.1-0.4-0.2C21.3,149.4,21.4,149.4,21.6,149.5z"/>
        <path fill="#FFFFFF" d="M17.6,147c-0.1-0.1-0.2-0.2-0.3-0.3C17.3,146.8,17.4,146.9,17.6,147z"/>
        <path fill="#FFFFFF" d="M18.3,147.6c-0.1-0.1-0.2-0.2-0.3-0.3C18,147.4,18.2,147.5,18.3,147.6z"/>
        <path fill="#FFFFFF" d="M16.9,146.3c-0.1-0.1-0.2-0.2-0.3-0.3C16.7,146.1,16.8,146.2,16.9,146.3z"/>
        <path fill="#FFFFFF" d="M19,148.2c-0.1-0.1-0.2-0.2-0.4-0.3C18.8,148,18.9,148.1,19,148.2z"/>
        <path fill="#FFFFFF" d="M63.8,98.3c-0.2-0.1-0.3-0.1-0.3-0.1c-0.5-0.1-0.9-0.2-1.4-0.3c0.5,0.1,0.9,0.2,1.4,0.3
          C63.5,98.2,63.6,98.3,63.8,98.3z"/>
        <path fill="#FFFFFF" d="M59.2,97.5c0.3,0,0.7,0.1,1,0.1C59.8,97.6,59.5,97.6,59.2,97.5z"/>
        <path fill="#FFFFFF" d="M63.8,98.3c0.2,0.1,0.5,0.1,0.8,0.2C64.3,98.5,64,98.4,63.8,98.3z"/>
        <path fill="#FFFFFF" d="M57.4,97.5c0.5,0,0.9,0,1.4,0C58.3,97.5,57.8,97.5,57.4,97.5z"/>
        <path fill="#FFFFFF" d="M53.5,97.8c0.6-0.1,1.1-0.2,1.7-0.2C54.6,97.6,54.1,97.7,53.5,97.8z"/>
        <path fill="#FFFFFF" d="M60.7,97.7c0.3,0,0.7,0.1,1,0.2C61.3,97.8,61,97.7,60.7,97.7z"/>
        <path fill="#FFFFFF" d="M55.5,97.5c0.6,0,1.2-0.1,1.8-0.1C56.7,97.5,56.1,97.5,55.5,97.5z"/>
      </g>
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
