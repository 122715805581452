import React, { useRef, useCallback } from 'react'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import I18n from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'
import TechnicianGroupsList from '@smartcoop/web-containers/fragments/technical/TechnicianGroupsList'
import TechnicianGroupsModal from '@smartcoop/web-containers/modals/technical/TechnicianGroupsModal'

import useStyles, { Row, Content } from './styles'

const TechnicianGroupsListModal = ({ id, open }) => {
  const classes = useStyles()
  const technicianGroupsTableRef = useRef()
  const { createDialog } = useDialog()

  const createNewGroup = useCallback(
    () => {
      createDialog({
        id: 'create-new-group',
        Component: TechnicianGroupsModal,
        props: {
          onClose: () => {
            technicianGroupsTableRef.current.onQueryChange()
          }
        }
      })
    },
    [createDialog]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row>
          <I18n>producer groups</I18n>
        </Row>
      }
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 350 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      disableFullScreen
      maxWidth="md"
      fullWidth
    >
      <>
        <Content>
          <Row style={ { justifyContent: 'flex-end', marginBottom: 10 } }>
            <Button
              id="new-operation"
              onClick={ createNewGroup }
              color="secondary"
              style={ { flex: 'none', whiteSpace: 'nowrap', alignSelf: 'flex-end' } }
            >
              <I18n>register group</I18n>
            </Button>
          </Row>
          <TechnicianGroupsList
            ref={ technicianGroupsTableRef }
          />
        </Content>
      </>
    </Modal>
  )}

TechnicianGroupsListModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
}

TechnicianGroupsListModal.defaultProps = {
}

export default TechnicianGroupsListModal
