import isNil from 'lodash/isNil'
import map from 'lodash/map'

import api from '../api'

export const getMilkQualities = async (params, { dairyFarmId }) => {
  const response = await api.get(`/v1/dairy-farms/${ dairyFarmId }/milk-qualities`, { params })

  const data = map(response.data.data, item => {
    const groupedItems = []
    const addGroup = (fieldName) => {
      groupedItems.push({
        date: item.date,
        type: fieldName,
        result: item[fieldName],
        limit: item[`${ fieldName  }Limit`]
      })
    }

    if (!isNil(item.ccs)) addGroup('ccs')
    if (!isNil(item.ctb)) addGroup('ctb')
    if (!isNil(item.nul)) addGroup('nul')
    if (!isNil(item.protein)) addGroup('protein')
    if (!isNil(item.fat)) addGroup('fat')
    if (!isNil(item.lactose)) addGroup('lactose')

    return {
      ...item,
      groupedItems
    }
  })

  return {
    ...response,
    data: {
      ...response.data,
      data
    }
  }
}

export const createMilkQuality = async (params) => {
  const { data } = await api.post('/v2/dairy-farms/milk-qualities', params)
  return data
}


export const updateMilkQuality = async (params, { milkQualityId }) => {
  const { data } = await api.put(`/v2/dairy-farms/milk-qualities/${ milkQualityId }`, params)
  return data
}

export const deleteMilkQuality = async ({ milkQualityId }) => {
  const { data } = await api.delete(`/v2/dairy-farms/milk-qualities/${ milkQualityId }`)
  return data
}

export const getMilkDeliveries = (params, { dairyFarmId }) =>  api.get(`/v1/dairy-farms/${ dairyFarmId }/milk-deliveries`, { params })

export const createMilkDelivery = async (params) => {
  const { data } = await api.post('/v2/dairy-farms/milk-deliveries', params)
  return data
}

export const getDashboardData = async (params, { dairyFarmId }) =>
  api.get(`/v1/dairy-farms/${ dairyFarmId }/dashboard-data`, { params })

export const getMonthlyDataHistory = async (params) =>
  api.get('/v1/dairy-farms/dashboard-data/monthly-data-history', { params })

export const getPriceComposition = async (params) =>
  api.get('/v1/dairy-farms/dashboard-data/price-composition', { params })

export const createMonthlyDataHistory = async (params) => {
  const { data } = await api.post('/v1/dairy-farms/dashboard-data/monthly-data-history', params)
  return data
}

export const updateMonthlyDataHistory = async (params, { id }) => {
  const { data } = await api.patch(`/v1/dairy-farms/dashboard-data/monthly-data-history/${ id }`, params)
  return data
}

export const deleteMonthlyDataHistory = async ({ id }) => {
  const { data } = await api.delete(`/v1/dairy-farms/dashboard-data/monthly-data-history/${ id }`)
  return data
}

export const createDairyFarm = async (params) => {
  const { data } = await api.post('/v2/properties/dairy-farms', params)
  return data
}

export const getDairyFarm = async ({ propertyId } ) => {
  const { data } = await api.get(`/v1/properties/${ propertyId }/dairy-farms`)
  return data
}

export const updateDairyFarm = async (params, { dairyFarmId }) => {
  const { data } = await api.patch(`/v2/properties/dairy-farms/${ dairyFarmId }`, params)
  return data
}

export const updateMilkDelivery = async (params, { milkDeliveryId }) => {
  const { data } = await api.put(`/v2/dairy-farms/milk-deliveries/${ milkDeliveryId }`, params)
  return data
}

export const deleteMilkDelivery = async ({ dairyFarmId, milkDeliveryId }) => {
  const { data } = await api.delete(`/v1/dairy-farms/${ dairyFarmId }/milk-deliveries/${ milkDeliveryId }`)
  return data
}

export const getPriceData = (params, { dairyFarmId }) =>
  api.get(`/v1/dairy-farms/${ dairyFarmId }/price-datas`, { params })

export const createPriceData = (params) =>
  api.post('/v2/dairy-farms/price-datas', params)

export const editPriceData =  (params, { priceDataId }) =>
  api.patch(`/v2/dairy-farms/price-datas/${ priceDataId }`, params)

export const deletePriceData = ({ dairyFarmId, priceDataId }) =>
  api.delete(`/v1/dairy-farms/${ dairyFarmId }/price-datas/${ priceDataId }`)

export const getAllPriceData = (params) =>
  api.get('/v2/dairy-farms/price-datas/user', params)

export const getAllMilkDeliveries = (params) =>
  api.get('/v2/dairy-farms/milk-deliveries/user', params)

export const getAllMilkQualities = (params) =>
  api.get('/v1/dairy-farms/milk-qualities/user', params)
