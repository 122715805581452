import React, { useEffect, useCallback, forwardRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { ChallengesActions } from '@smartcoop/stores/challenges'
import { selectChallenges } from '@smartcoop/stores/challenges/selectorChallenges'
import { momentFriendlyShortDateFormat } from '@smartcoop/utils/dates'
import Badge from '@smartcoop/web-components/Badge'
import DataTable from '@smartcoop/web-components/DataTable'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'

const ProjectsList = forwardRef(({ filters }, tableRef) => {
  const t = useT()
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()
  const dispatch = useDispatch()

  const projects = useSelector(selectChallenges)

  useEffect(() => {
    dispatch(
      ChallengesActions.loadChallenges(
        { ...filters }
      )
    )
  },[dispatch, filters])

  const columns = useMemo(
    () => [
      {
        title: t('project type'),
        field: 'project.projectName',
        sorting: true
      },
      {
        title: t('producer', { howMany: 1, gender: 'male' }),
        field: 'proprietary.name',
        sorting: true
      },
      {
        title: t('property', { howMany: 1 }),
        field: 'property.name',
        sorting: true
      },
      {
        title: t('field', { howMany: 1 }),
        field: 'field.fieldName',
        sorting: true
      },
      {
        title: t('date', { howMany: 1 }),
        field: 'accreditationDate',
        render: (row) => moment(row.accreditationDate, 'YYYY-MM-DD HH:mm:ss').format(momentFriendlyShortDateFormat),
        sorting: true
      },
      {
        title: t('terms'),
        field: 'termType',
        sorting: true,
        align: 'center',
        render: (row) => (
          <Badge
            backgroundColorBadge={ !isEmpty(row?.acceptedTermDate) ? '#199cd8' : '#e41d1b' }
            colorBadge={ !isEmpty(row?.acceptedTermDate) ? '#199cd8' : '#e41d1b' }
          >
            { !isEmpty(row?.acceptedTermDate) ? t('yes') : t('no') }
          </Badge>
        )
      }
    ], [t]
  )

  const revokeTechnicalAccess = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete-access',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(
              ChallengesActions.deleteOfflineChallenge(
                { productivityChallengeId: row?.id },
                () => {
                  snackbar.success(
                    t('participation removed')
                  )
                  dispatch(
                    ChallengesActions.loadChallenges(
                      { ...filters }
                    )
                  )
                }
              )
            )
          },
          textWarning: t('are you sure you want to remove the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('project', { howMany: 1 })
          })
        }
      })
    },
    [createDialog, dispatch, filters, snackbar, t]
  )

  return (
    <DataTable
      tableRef={ tableRef }
      columns={ columns }
      data={ projects }
      onDeleteClick={ revokeTechnicalAccess }
      id="projects-list-table"
    />
  )
})

ProjectsList.propTypes = {
  filters: PropTypes.object
}

ProjectsList.defaultProps = {
  filters: {}
}

export default ProjectsList
