/* eslint-disable no-param-reassign */
import { Database, Q } from '@nozbe/watermelondb'

import first from 'lodash/first'
import get from 'lodash/get'

import { groupMemberService } from './groupMemberService'
import { ROUTES } from './routes'
import { syncPendingService } from './syncPendingService'

export function groupService(database: Database) {
  async function createOrUpdate(group) {
    const registers = await database.get('group')
      .query(Q.where('groupId', group.id))
      .fetch()

    if (registers && registers[0]) {
      return registers[0].update(item => {
        item.groupKey = group.publicKey
        item.name = group.name
        item.photo = group.photo
        item.isBroadcast = group.broadcast
      })
    }

    return database.get('group').create(item => {
      item.groupId = group.id
      item.groupKey = group.publicKey
      item.name = group.name
      item.photo = group.photo
      item.isBroadcast = group.broadcast
    })
  }

  async function create(group) {
    await database.write(async () => {
      const register = await database.get('group').create(item => {
        item.groupId = group.id
        item.name = group.groupName
        item.groupKey = group.publicKey
      })

      await groupMemberService(database).createMembers(group.id, group.userCodes)

      await syncPendingService(database).create({
        type: 'POST',
        body: JSON.stringify(group),
        params: {},
        url: ROUTES.CREATE_CONVERSATION,
        localMessageId: register.id,
        registerType: 'create_group'
      })
    })
  }

  async function findGroupKeyById(groupId) {
    const groups = await database.get('group')
      .query(
        Q.where('groupId', groupId)
      ).fetch()

    return get(first(groups), 'groupKey', undefined)
  }

  async function updateConversationNameById(conversationId, name) {
    const registers = await database.get('conversation')
      .query(
        Q.where('conversationId', conversationId)
      )
      .fetch()

    if (registers[0]) {
      await registers[0].update(item => {
        item.name = name
      })
    }
  }

  async function updateGroupName(groupId, groupName) {
    const registers = await database.get('group')
      .query(Q.where('groupId', groupId))
      .fetch()

    const group = first(registers)

    if (group) {
      await database.write(async () => {
        await group.update(item => {
          item.groupName = groupName
        })

        await updateConversationNameById(
          groupId,
          groupName
        )

        await syncPendingService(database).create({
          type: 'PATCH',
          body: JSON.stringify({ groupName }),
          params: {},
          url: `${ ROUTES.UPDATE_GROUP_NAME }/${ groupId }`,
          registerType: 'update_group_name'
        })
      })
    }
  }

  function observeGroupById(groupId) {
    return database.collections.get('group')
      .query(
        Q.where('groupId', groupId)
      )
      .observe()
  }

  return {
    create,
    createOrUpdate,
    observeGroupById,
    findGroupKeyById,
    updateGroupName
  }
}
