import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useRouteMatch } from 'react-router-dom'

import moment from 'moment/moment'

import { find, map, orderBy, some } from 'lodash'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { NonCompliancesActions } from '@smartcoop/stores/nonCompliance'
import { selectNonCompliances } from '@smartcoop/stores/nonCompliance/selectorNonCompliance'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Loader from '@smartcoop/web-components/Loader'
import NonCompliance from '@smartcoop/web-components/NonCompliance'
import NonComplianceAnswer from '@smartcoop/web-components/NonComplianceAnswer'
import NonComplianceCard from '@smartcoop/web-components/NonComplianceCard'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import AnswerNonComplianceModal from '@smartcoop/web-containers/modals/shoppingPlatform/AnswerNonComplianceModal'
import CreateNonComplianceModal from '@smartcoop/web-containers/modals/shoppingPlatform/CreateNonComplianceModal'
import ResolveNonComplianceModal from '@smartcoop/web-containers/modals/shoppingPlatform/ResolveNonComplianceModal'

import {
  CommentDiv,
  LabelComments,
  FlexDiv,
  CustomButton,
  CardContainer
} from './styles'

const NonComplianceScreen = () => {
  const t = useT()
  const params = useParams()
  const history = useHistory()
  const match = useRouteMatch()

  const { createDialog } = useDialog()

  const dispatch = useCallback(useDispatch(), [])

  const nonCompliances = useSelector(selectNonCompliances)

  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const [selectedNonCompliance, setSelectedNonCompliance] = useState(null)

  const supplierScreen = useMemo(
    () => match.path === '/shopping-platform/supplier-quotation/:orderId/non-compliance',
    [match.path])

  const nonCompliancesSorted = useMemo(
    () => orderBy(nonCompliances,
      (item) => moment(item.createdAt),
      ['desc']),
    [nonCompliances])

  const getNonCompliances = useCallback(() => {
    setLoading(true)
    dispatch(NonCompliancesActions.loadNonCompliances(
      supplierScreen ? { mainPurchaseId: params.orderId } : { deliveryLocationId: params.deliveryLocationId },
      () => setLoading(false),
      () => setLoading(false)
    ))
  },[dispatch, params.deliveryLocationId, params.orderId, supplierScreen])

  const createAnswer = useCallback(() => {
    createDialog({
      id: 'create-comment',
      Component: AnswerNonComplianceModal,
      props: {
        originalPost: selectedNonCompliance,
        getNonCompliances
      }
    })
  }, [createDialog, getNonCompliances, selectedNonCompliance])

  const treatAsResolved = useCallback(() => {
    createDialog({
      id: 'create-comment',
      Component: ResolveNonComplianceModal,
      props: {
        getNonCompliances,
        selectedNonCompliance
      }
    })
  }, [createDialog, getNonCompliances, selectedNonCompliance])

  const handleCreateNonCompliance = useCallback(() => {
    createDialog({
      id: 'create-non-compliance',
      Component: CreateNonComplianceModal,
      props: {
        deliveryLocation: params.deliveryLocationId,
        getNonCompliances
      }
    })
  },[createDialog, getNonCompliances, params.deliveryLocationId])

  const handleSelectNonCompliance = (id) => {
    setSelected(id)
  }

  useEffect(() => {
    getNonCompliances()
  },[getNonCompliances])

  useEffect(() => {
    selected === null && nonCompliancesSorted[0]?.id  && setSelected(nonCompliancesSorted[0].id)
  },[nonCompliancesSorted, selected])

  useEffect(() => {
    if(selected === null){
      setSelectedNonCompliance(null)
    }

    const found = find(nonCompliancesSorted, (item) => item.id === selected )
    setSelectedNonCompliance(found || null)
  },[nonCompliancesSorted, selected])

  const handleCancel = () => {
    history.goBack()
  }

  const showActions = useMemo(
    () => {
      if(supplierScreen){
        return false
      }
      return (selectedNonCompliance && selectedNonCompliance?.statusId === 1)},
    [selectedNonCompliance, supplierScreen])

  const showAnswerButton = useMemo(
    () => selectedNonCompliance && selectedNonCompliance?.statusId === 2,
    [selectedNonCompliance])

  const showCreateNonComplianceButton = useMemo(
    () => {
      if(supplierScreen){
        return false
      }
      return !some(nonCompliances, (item) => item.statusId === 1)
    },
    [nonCompliances, supplierScreen]
  )

  return loading ? (
    <Loader width={ 100 } />
  ) : (
    <SplitedScreenLayout
      withGoBack
      withoutDivider
      title={ { name: t('non compliance title') } }
      leftChildren={
        <div>
          <div style={ { display: 'flex', justifyContent: 'flex-end', marginRight: '2%' } }>
            { showCreateNonComplianceButton && <FlexDiv>
              <CustomButton
                style={ { marginLeft: '5px' } }
                color="secondary"
                onClick={ handleCreateNonCompliance }
              >
                {t('create non compliance')}
              </CustomButton>
            </FlexDiv>}
          </div>
          {nonCompliances.length > 0
            ?
            nonCompliancesSorted.map(item => <NonComplianceCard key={ `${ item.id }` } nonCompliance={ item } handleClick={ handleSelectNonCompliance } selectedNonCompliance={ selectedNonCompliance }/>)
            :
            <CardContainer>
              {t('zero non compliances')}
            </CardContainer>
          }
        </div>
      }
      rightGridStyle={ {
        backgroundColor: colors.backgroundHtml
      } }
      divRightStyle={ {
        backgroundColor: colors.backgroundHtml,
        height: 'auto',
        padding: 0
      } }
      rightHeaderStyle={ { justifyContent: 'center', padding: 0 } }
      rightActions={
        showActions ? <div style={ { width: '75%', display: 'flex', justifyContent: 'flex-end' } }>
          <FlexDiv>
            <CustomButton onClick={ treatAsResolved }>
              {t('non compliance treat as resolved')}
            </CustomButton>
          </FlexDiv>
          <FlexDiv>
            <CustomButton
              style={ { marginLeft: '5px' } }
              variant="outlined"
              color={ colors.black }
              onClick={ handleCancel }
            >
              {t('non compliance cancel')}
            </CustomButton>
          </FlexDiv>
        </div>
          :
          <div style={ { marginBottom: '10px' } } />
      }
      rightChildrenStyle={ { justifyContent: 'center' } }
      rightChildren={
        selectedNonCompliance &&
          <CommentDiv>
            <NonCompliance nonCompliance={ selectedNonCompliance }/>
            <FlexDiv>
              <div>
                <LabelComments>{t('non compliance answers')}</LabelComments>
              </div>
              <div>
                <Button id="button" color="secondary" onClick={ createAnswer } disabled={ showAnswerButton }>
                  {t('non compliance answer')}
                </Button>
              </div>
            </FlexDiv>
            {map(selectedNonCompliance.commentaries, (item) => (
              <NonComplianceAnswer
                key={ `comment-${ item.id }` }
                answer={ item }
              />
            ))}
          </CommentDiv>
      }

    />
  )
}

export default NonComplianceScreen
