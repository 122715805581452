import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const confirmProducer = ({ t }) => Yup.object().shape({
  document: flow(
    required({ t })
  )(Yup.string()),

  organization: flow(
    required({ t })
  )(Yup.string())
})

export default confirmProducer
