import React from 'react'

import PropTypes from 'prop-types'

import CardMui from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import useStyles from './styles'

const Card = (props) => {
  const {
    headerLeft,
    headerRight,
    headerAction,
    headerTitleProps,
    title,
    children,
    className,
    style,
    cardContentProps,
    cardStyle,
    onClick,
    onKeyDown,
    headerStyle,
    childrenStyle,
    onHeaderClick,
    noBoxShadow,
    selected
  } = props

  const classes = useStyles({ noBoxShadow, selected })

  return (
    <div
      role="button"
      tabIndex="0"
      className={ [classes.container, className].join(' ') }
      style={ style }
      onClick={ onClick }
      onKeyDown={ onKeyDown }
    >

      <CardMui className={ classes.cardContainer } style={ cardStyle }>
        <CardContent { ...cardContentProps } style={ { padding: 0, ...(cardContentProps.style || {}) } }>

          {(title || headerLeft || headerRight) && (
            <div
              className={ classes.header }
              style={ {
                ...headerStyle,
                cursor: onHeaderClick ? 'pointer' : undefined
              } }
              onClick={ onHeaderClick }
              role="button"
              tabIndex="-1"
              aria-hidden="true"
            >
              {headerLeft}
              {title && (
                <Typography
                  className={ classes.title }
                  variant="body1"
                  component="h2"
                  align={ headerLeft ? 'left' : 'center' }
                  { ...headerTitleProps }
                  style={ {
                    paddingLeft: headerLeft ? 10 : 0,
                    ...(headerTitleProps.style || {})
                  } }
                >
                  {title}
                </Typography>
              )}
              {headerAction}
              {headerRight}
            </div>
          )}


          <div style={ { ...childrenStyle } }>
            {children}
          </div>
        </CardContent>
      </CardMui>
    </div>
  )
}

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headerTitleProps: PropTypes.object,
  headerLeft: PropTypes.element,
  headerRight: PropTypes.element,
  headerAction: PropTypes.element,
  children: PropTypes.any,
  loading: PropTypes.bool,
  noBoxShadow: PropTypes.bool,
  selected: PropTypes.bool,
  className: PropTypes.string,
  headerStyle: PropTypes.object,
  childrenStyle: PropTypes.object,
  style: PropTypes.object,
  cardContentProps: PropTypes.object,
  cardStyle: PropTypes.object,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onHeaderClick: PropTypes.func
}

Card.defaultProps = {
  onHeaderClick: undefined,
  loading: false,
  noBoxShadow: false,
  selected: false,
  headerTitleProps: {},
  headerLeft: null,
  headerRight: null,
  headerAction: null,
  title: null,
  cardContentProps: {},
  className: null,
  headerStyle: {},
  childrenStyle: {},
  style: {},
  cardStyle: {},
  onClick: () => {},
  onKeyDown: () => {},
  children: null
}

export default Card
