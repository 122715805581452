import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'

import isFunction from 'lodash/isFunction'
import mapValues from 'lodash/mapValues'

import Grid from '@material-ui/core/Grid'


import { colors } from '@smartcoop/styles'
import GoBackIconButton from '@smartcoop/web-components/IconButton/GoBackIconButton'
import { Title } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Row, IconContainer, LeftHeader, Divider, FlexDiv } from './styles'

function SplitedScreenLayout(props) {
  const {
    leftColumns: externalLeftColumns,
    leftChildren,
    leftActions,
    rightChildren,
    rightActions,
    appendChildren,
    appendColumns: externalAppendColumns,
    rightHeaderChildren,
    title,
    handleGoBack,
    withoutGoBack,
    withoutDivider,
    divRightStyle,
    divLeftStyle,
    withGoBackRight,
    rightChildrenStyle,
    rightGridStyle,
    rightHeaderStyle
  } = props
  const history = useHistory()

  const goBack = useCallback(
    () => {
      if(isFunction(handleGoBack)) {
        handleGoBack()
      } else {
        history.goBack()
      }
    },
    [handleGoBack, history]
  )

  const leftColumns = useMemo(
    () => ({
      xl: leftChildren ? 3 : 0,
      lg: leftChildren ? 4 : 0,
      md: leftChildren ? 4 : 0,
      sm: leftChildren ? 12 : 0,
      xs: leftChildren ? 12 : 0,
      ...externalLeftColumns
    }),
    [externalLeftColumns, leftChildren]
  )

  const appendColumns = useMemo(
    () => ({
      xl: appendChildren ? 2 : 0,
      lg: appendChildren ? 3 : 0,
      md: appendChildren ? 3 : 0,
      sm: appendChildren ? 12 : 0,
      xs: appendChildren ? 12 : 0,
      ...(externalAppendColumns || {})
    }),
    [appendChildren, externalAppendColumns]
  )

  const rightColumns = useMemo(
    () => {
      const columns = {
        xl: 12 - leftColumns.xl - appendColumns.xl,
        lg: 12 - leftColumns.lg - appendColumns.lg,
        md: 12 - leftColumns.md - appendColumns.md,
        sm: 12 - leftColumns.sm - appendColumns.sm,
        xs: 12 - leftColumns.xs - appendColumns.xs
      }
      return mapValues(columns, (column) => column < 1 ? 12 : column)
    },
    [
      appendColumns,
      leftColumns
    ]
  )

  return (
    <Grid container style={ { height: '100%' } }>
      {leftChildren && (
        <Grid
          item
          { ...leftColumns }
          style={ {
            backgroundColor: colors.white,
            display: 'flex',
            flexDirection: 'column',
            ...divLeftStyle
          } }
        >
          <LeftHeader>
            {!withoutGoBack && (
              <IconContainer>
                <GoBackIconButton onClick={ goBack } iconColor={ colors.primary } />
              </IconContainer>
            )}
            <Title
              style={ {
                lineHeight: 1,
                fontSize: 18,
                margin: '18px 0',
                ...title.style
              } }
            >
              { title.name }
            </Title>
            { leftActions }
          </LeftHeader>
          {!withoutDivider && (
            <Divider/>
          )}
          { leftChildren }
        </Grid>
      )}

      <Grid item
        { ...rightColumns }
        style={ { backgroundColor: colors.backgroundHtml, ...rightGridStyle } }
      >
        {(withGoBackRight || rightActions) && (
          <LeftHeader style={ rightHeaderStyle }>
            <FlexDiv>
              {withGoBackRight && (
                <IconContainer>
                  <GoBackIconButton onClick={ goBack } iconColor={ colors.primary } />
                </IconContainer>
              )}
              {withGoBackRight && (
                <Title
                  style={ {
                    lineHeight: 1,
                    fontSize: 18,
                    margin: '18px 0',
                    ...title.style
                  } }
                >
                  { title.name }
                </Title>
              )}
            </FlexDiv>
            { rightActions }
          </LeftHeader>
        )}
        <div
          style={ {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
            paddingLeft: '5%',
            paddingRight: '5%',
            ...divRightStyle
          } }
        >
          {rightHeaderChildren && (
            <div style={ { width: '100%', padding: '10px 0' } }>
              { rightHeaderChildren }
            </div>
          )}
          <Row style={ { marginTop: !rightHeaderChildren ? 0 : undefined, ...rightChildrenStyle } }>
            { rightChildren }
          </Row>
        </div>
      </Grid>

      {appendChildren && (
        <Grid item
          { ...appendColumns }
          style={ { backgroundColor: colors.white } }
        >
          {appendChildren}
        </Grid>
      )}
    </Grid>
  )
}

SplitedScreenLayout.propTypes = {
  divRightStyle: PropTypes.object,
  divLeftStyle: PropTypes.object,
  rightChildrenStyle: PropTypes.object,
  rightGridStyle: PropTypes.object,
  rightHeaderStyle: PropTypes.object,
  handleGoBack: PropTypes.func,
  withoutGoBack: PropTypes.bool,
  withGoBackRight: PropTypes.bool,
  withoutDivider: PropTypes.bool,
  leftActions: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool
  ]),
  leftColumns: PropTypes.object,
  leftChildren: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool
  ]),
  rightChildren: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool
  ]),
  rightActions: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool
  ]),
  appendChildren: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool
  ]),
  appendColumns: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  rightHeaderChildren: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool
  ]),
  title: PropTypes.shape({
    name: PropTypes.string,
    style: PropTypes.object
  })
}

SplitedScreenLayout.defaultProps = {
  handleGoBack: undefined,
  withoutGoBack: false,
  withGoBackRight: false,
  withoutDivider: false,
  divRightStyle: {},
  divLeftStyle: {},
  rightChildrenStyle: {},
  rightGridStyle: {},
  rightHeaderStyle: {},
  leftChildren: null,
  leftColumns: {},
  leftActions: null,
  rightChildren: null,
  rightActions: null,
  appendChildren: null,
  appendColumns: {},
  rightHeaderChildren: null,
  title: {
    name: '',
    style: {}
  }
}

export default SplitedScreenLayout
