import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

/* Initial State */
const INITIAL_STATE = {
  fieldsMonitoration: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */

const { Types, Creators } = createActions({
  saveFieldsMonitoration: [ 'fieldMonitoration', 'onSuccess', 'onError', 'propertyId', 'isEdit' ],
  saveOfflineFieldsMonitoration: ['fieldMonitoration', 'onSuccess', 'onError'],

  loadFieldsMonitoration: ['params', 'onSuccess', 'onError'],
  loadFieldsMonitorationSuccess: ['params', 'page', 'onSuccess'],

  deleteFieldsMonitoration: ['precipitationId', 'onSuccess', 'onError', 'propertyId'],
  deleteOfflineFieldsMonitoration: ['precipitationId', 'onSuccess', 'onError'],

  fieldsMonitorationError: ['error']
})

markActionsOffline(Creators,
  [
    'deleteFieldsMonitoration',
    'saveFieldsMonitoration'
  ]
)

const loadFieldsMonitorationSuccess = (state = INITIAL_STATE, { params, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  fieldsMonitoration: page === 1 ? params : [
    ...state.fieldsMonitoration,
    ...params
  ]
})

const fieldsMonitorationError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */

export default createReducer(INITIAL_STATE, {

  [Types.LOAD_FIELDS_MONITORATION_SUCCESS]: loadFieldsMonitorationSuccess,

  [Types.FIELDS_MONITORATION_ERROR]: fieldsMonitorationError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as FieldsMonitorationTypes,
  Creators as FieldsMonitorationActions
}
