import moment from 'moment/moment'

import map from 'lodash/map'

import { momentBackDateTimeFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'

import api from '../api'

const transformTechnicalVisit = (item) => ({
  ...item,
  groupDate: moment(item.visitDateTime, momentBackDateTimeFormat).format(momentFriendlyDateFormat)
})


export const registerTechnicalVisit = (params, { propertyId }) => api.post(`/v1/technical-visits/${ propertyId }`, params)

export const registerTechnicalEvaluationAnswer = (params, { quizId }) => api.post(`/quiz/${ quizId }/answer`, params)

export const registerPropertyTechnicalVisit = (params, { propertyId }) => api.post(`/v2/technicians/technical-visit/property/${ propertyId }`, params,
  {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

export const editTechnicalVisit = (params, { visitId }) => api.patch(`/v1/technical-visits/${ visitId }`, params)

export const requestAccessForProprietary = (params) => api.post('/v1/technicians/access/request', params)

export const postToggleOfflineTechnicalAccess = (params, { proprietaryTechnicianId }) => api.post(`/v1/technicians/access/${ proprietaryTechnicianId }/offline`, params)

export const acceptAccessTechnical = (params, { accessRequestId }) => api.patch(`/v1/technicians/access/${ accessRequestId }/accept`, params)

export const getTechnicalVisitUsersList = (params) => api.get('/v1/technical-visits/users', { params })

export const getTechnicalPortfolio = (params) => api.get('/v1/technicians/portfolio', { params })

export const getOfflineTechnicalAccessList = (params, { userId }) => api.get(`v1/technicians/access-list/user/${ userId }`, { params })

export const getOfflineTechnicalFields = (params, { propertyId }) => api.get(`/v1/technicians/access-list/fields/${ propertyId }`, { params })

export const getOfflineTechnicalDairyFarms = (params, { propertyId }) => api.get(`/v1/technicians/access-list/dairyfarm/${ propertyId }`, { params })

export const getOfflineTechncialPrecipitations = (params, { propertyId }) => api.get(`/v1/technicians/access-list/precipitations/property/${ propertyId }`, { params })

export const getOfflineAllAnimals = (params) => api.get('v1/technicians/access-list/herds-management', { params })

export const getTechniciansRequestList = (params) => api.get('/v1/technicians/proprietary/requests', { params })

export const getTechnicalGoals = (params) => api.get('/v2/technicians/visit-goals', { params })

export const getTechnicalVisitById = async (params, { technicalVisitId }) => {
  const response = api.get(`/v1/technical-visits/${ technicalVisitId }`, { params })
  response.data = transformTechnicalVisit(response.data)
  return response
}

export const getTechnicalVisits = async (params) => {
  const response = await api.get('/v1/technical-visits', { params })

  return {
    ...response,
    data: {
      ...response.data,
      data: map(response.data.data, transformTechnicalVisit)
    }
  }
}

export const getTechnicalVisitsByProperty = async (params, { propertyId }) => {
  const response = await api.get(`/v1/technical-visits/property/${ propertyId }`, { params })

  return {
    ...response,
    data: {
      ...response.data,
      data: map(response.data.data, transformTechnicalVisit)
    }
  }
}

export const deleteVisit = ({ technicalVisitId }) => api.delete(`/v1/technical-visits/${ technicalVisitId }`)

export const deleteVisitById = ({ technicalVisitId }) => api.delete(`/v2/technicians/technical-visit/visit/${ technicalVisitId }`)

export const editVisitById = (params, { propertyId, visitId }) => api.patch(`/v2/technicians/technical-visit/property/${ propertyId }/visit/${ visitId }`,  params)

export const editVisitImages = (params, { propertyId, visitId }) => api.patch(`/v2/technicians/technical-visit/property/${ propertyId }/visit/${ visitId }/images`,  params, {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data'
  }
})

export const revokeAccess = ({ id, statusId }) => api.patch(`/v1/technicians/access/${ id }/revoke/${ statusId }`)

export const deleteTechnicalEvaluation = ({ quizAnswerId }) => api.delete(`/v1/quiz/answer/${ quizAnswerId }`)

export const getTechnicalEvaluation = (params) => api.get('/v1/quiz', { params })

export const getTechnicalEvaluationReport = (params, accessToken) => api.get('/v1/quiz/report', {
  params,
  headers: accessToken ? {
    'authorization': accessToken
  } : {}
})

export const getTechnicalEvaluationProducers = (params) => api.get('/v1/quiz/technician-producers', { params })

export const getLimestoneIndices = (params) => api.get('/v1/quiz/getLimestoneIndices', { params })

export const getTechnicalEvaluationAnswers = (params) => api.get('/v1/quiz/getQuizAnswersByUser', { params })

export const deleteTechnicalEvaluationAnswer = ({ quizAnswerId  }) => api.delete(`/v1/quiz/answer/${ quizAnswerId }`)

export const editTechnicalEvaluationAnswer = (params, { quizAnswerId  }) => api.patch(`/v1/quiz/answer/${ quizAnswerId }`, params)

export const getTechnicalEvaluationAnswersById = ({ quizAnswerId  }, params) => api.get(`/v1/quiz/getQuizAnswers/${ quizAnswerId  }`, { params })

export const getTechnicalOwnerFamilyGroupMembers = (params, { userId }) => api.get(`/v1/technicians/family-group/user/${ userId }`, { params })

export const postTechnicalEvaluation = (params, { quizId }) => api.post(`/v1/quiz/${ quizId }/answer`,  params)

export const createTechnicalVisitReport = (params, accessToken = null) => api.post('/v1/technical-visits/report', params, accessToken ? {
  headers: {
    'authorization': accessToken
  }
} : {})

export const postTechnicalEvaluationFiles = (params, { answerId,  questionId }) =>
  api.post(`/v1/quiz/answer/${ answerId }/question/${ questionId }/file`, params, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

export const getTechnicianGroups = (params) => api.get('/v1/technicians-groups', { params })

export const createTechnicianGroups = (params) => api.post('/v1/technicians-groups', params)

export const deleteTechnicianGroups = ({ groupId }) => api.delete(`/v1/technicians-groups/${ groupId }`)

export const updateTechnicianGroups = (params, { groupId }) => api.patch(`/v1/technicians-groups/${ groupId }`, params)
