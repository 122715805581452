import api from '../api'

export const getProductGroups = (params) =>
  api.get('/v1/product-groups', { params })

export const createProductGroup = (params) =>
  api.post('/v1/product-groups', params)

export const editProductGroup = (params, { productGroupId }) =>
  api.patch(`/v1/product-groups/${ productGroupId }`, params)

export const deleteProductGroup = ({ productGroupId }) =>
  api.delete(`/v1/product-groups/${ productGroupId }`)
