import styled from 'styled-components'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
`

export const ButtonGroup = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  justify-content: space-around;
  margin-top: 20px;
`

export const Title = styled.p`
  flex-direction: row;
  display: flex;
  font-weight: bold;
`

export const Row = styled.div`
  flex-direction: row;
  display: flex;
`
