import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import min from '@smartcoop/forms/validators/min.validator'
import required from '@smartcoop/forms/validators/required.validator'

const identification = ({ t }) => Yup.object().shape({
  name: flow(
    min({ t, min: 3, name: t('full name') }),
    required({ t })
  )(Yup.string()),

  cellPhone: flow(
    required({ t })
  )(Yup.string()),

  email: flow(
  )(Yup.string()).nullable()
})

export default identification
