import React, { useState } from 'react'

import PropTypes from 'prop-types'

import InputAdornment from '@material-ui/core/InputAdornment'

import EyeClosedIconButton from '@smartcoop/web-components/IconButton/EyeClosedIconButton'
import EyeOpenedIconButton from '@smartcoop/web-components/IconButton/EyeOpenedIconButton'
import TextField from '@smartcoop/web-components/TextField'

const InputPassword = ({ size, ...props }) => {
  const [passwordMasked, setPasswordMasked] = useState(true)

  const EyeIconButton = passwordMasked ? EyeClosedIconButton : EyeOpenedIconButton

  return (
    <TextField
      size={ size }
      { ...props }
      InputProps={ {
        endAdornment: (
          <InputAdornment style={ { paddingRight: 10 } } position="end">
            <EyeIconButton
              size={ size }
              onClick={ () => setPasswordMasked(old => !old) }
            />
          </InputAdornment>
        )
      } }
      type={ passwordMasked ? 'password' : 'text' }
    />
  )
}

InputPassword.propTypes = {
  size: PropTypes.oneOf(['small', 'medium'])
}

InputPassword.defaultProps = {
  size: 'small'
}

export default InputPassword
