import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const CurrentImage = styled.img`
  border-radius: 8px;
  background-color: ${ colors.lightGrey };
  height: 70px;
  width: 70px;
  object-fit: cover;

`

export const ImageContainer = styled.div`
  background-color: ${ colors.lightGrey };
  width: 70px;
  height: 70px;
  border-radius: 5px;
`

export const TextGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: 1;
`

export const TextLeft = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const TextRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: nowrap;
  white-space: nowrap;
`
export const TextBold = styled.span`
  margin: 0;
  font-weight: 600;
`
export const DistanceInfo = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  font-weight: bold;
  justify-content: center;
`

export const Text = styled.span`
  margin-right: 10px;
`
export const ModelYear = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ViewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
  background-color: rgb(249, 248, 246);

  :hover {
    background-color: lightgray;
    z-index: 1000;
  }
`

export const EmptyImage = styled.div`
  border-radius: 8px;
  background-color: ${ colors.lightGrey };
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`
