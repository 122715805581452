import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { group, transmission, user } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import { CustomAvatar, IconWrapper } from './styles'

const ChatAvatar = ({ isGroup, isBroadcast, sourceImage, size, onClick, clickable }) => {
  const profileIcon = useMemo(() => {
    if (isGroup) {
      return group
    }

    if (isBroadcast) {
      return transmission
    }

    return user
  }, [isBroadcast, isGroup])

  if (sourceImage) {
    return (
      <CustomAvatar
        src={ sourceImage }
        size={ size }
        alt="Profile Picture"
        onClick={ onClick }
        clickable={ clickable }
      />
    )
  }

  return (
    <IconWrapper size={ size } onClick={ onClick } clickable={ clickable }>
      <Icon
        icon={ profileIcon }
        size={ size * 0.7 }
        color={ colors.white }
      />
    </IconWrapper>
  )
}

ChatAvatar.propTypes = {
  isGroup: PropTypes.bool,
  isBroadcast: PropTypes.bool,
  sourceImage: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
  clickable: PropTypes.bool
}

ChatAvatar.defaultProps = {
  isGroup: false,
  isBroadcast: false,
  sourceImage: undefined,
  size: 40,
  onClick: () => {},
  clickable: false
}

export default ChatAvatar
