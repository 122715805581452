import styled from 'styled-components'

import Card from '@smartcoop/web-components/Card'

export const Container = styled(Card).attrs({
  cardStyle: {
    width: '100%',
    padding: 15,
    maxWidth: 1024
  },
  style: {
    width: '100%',
    alignItems: 'flex-start'
  }
})``

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`
export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
`

export const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
`

export const SecondRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  gap: 20px;
`

export const GridContainerAnimal = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
`

export const AvatarContainer = styled.div`
  padding: 20px;
  padding-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
