import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import maxNumberValue from '@smartcoop/forms/validators/maxNumberValue.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerProtocol = ({ t }) => Yup.object().shape({
  animalsIds: Yup.string(),
  protocolDate: flow(
    date({ t }),
    required({ t })
  )(Yup.string()),
  numberOfDays: flow(
    maxNumberValue({ t, name: t('Days'), max: 50, ignoreReplace: true }),
    required({ t })
  )(Yup.string()),

  type: flow(
    required({ t })
  )(Yup.string())
})

export default registerProtocol
