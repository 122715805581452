import { find } from 'lodash'
import filter from 'lodash/filter'
import toUpper from 'lodash/toUpper'

export const selectUserOrganizations = (state) =>
  filter(state.organization.userOrganizations, organization => organization.companyDocument !== '99999999999999')

export const selectUserOrganizationsByModule = (state) =>
  state.organization.userOrganizationsByModule

export const selectCurrentOrganization = (state) =>
  state.organization.currentOrganization

export const selectCurrentOrganizationAddresses = (state) =>
  state.organization.currentOrganizationAddresses

export const selectUserOrganizationIsSmartcoop = (state) =>
  state.organization.currentOrganization?.tradeName === 'SMARTCOOP'

export const selectIsProfileRTC = (state) => toUpper(state.organization.currentOrganization?.profileName) === 'RTC'

export const selectOrganizationRTC = (state) => find(state.organization.userOrganizations, item => toUpper(item?.tradeName) === 'RTC')

export const selectIsOrganizationAdmin = (state) => toUpper(state.organization.currentOrganization?.profileName) === 'ADMINISTRADOR DE COOPERATIVA'
