import React, { useCallback, forwardRef, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import html2pdf from 'html2pdf.js'
import moment from 'moment/moment'
import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { download } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { OperationsActions } from '@smartcoop/stores/operations'
import { selectAccrediteds, selectAccredited } from '@smartcoop/stores/operations/selectorOperations'
import { selectIsProfileRTC } from '@smartcoop/stores/organization/selectorOrganization'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import technicalCertificate from '@smartcoop/styles/assets/docs/technicalCertificate'
import { ACCREDITMENT_STATUS_COLORS, ACCREDITMENT_STATUS_NAMES } from '@smartcoop/utils/constants'
import { momentFriendlyDateFormat, momentFriendlyShortDateFormat } from '@smartcoop/utils/dates'
import { formatCpfCnpj } from '@smartcoop/utils/formatters'
import Badge from '@smartcoop/web-components/Badge'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import AcceptAccreditmentModal from '@smartcoop/web-containers/modals/operations/AcceptAccreditmentModal/AcceptAccreditmentModal'
import RefuseModal from '@smartcoop/web-containers/modals/RefuseModal'

const AccreditmentList = forwardRef(({ filters }, tableRef) => {
  const t = useT()
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()
  const dispatch = useDispatch()

  const accrediteds = useSelector(selectAccrediteds)
  const accredited = useSelector(selectAccredited)
  const isRTC = useSelector(selectIsProfileRTC)
  const user = useSelector(selectUser)

  useEffect(() => {
    if(isRTC) {
      dispatch(
        OperationsActions.loadAccrediteds(
          { ...filters }
        )
      )
    } else {
      dispatch(OperationsActions.checkAccredited())
    }
  },[dispatch, filters, isRTC])

  const columns = useMemo(
    () => !isRTC ?
      [
        {
          title: t('date', { howMany: 1 }),
          field: 'requestDate',
          render: (row) => moment(row.requestDate, 'YYYY-MM-DD HH:mm:ss').format(momentFriendlyDateFormat),
          sorting: true
        },
        {
          title: t('expiration'),
          field: 'expirationDate',
          render: (row) => row?.expirationDate ? moment(row?.expirationDate, 'YYYY-MM-DD HH:mm:ss').format(momentFriendlyShortDateFormat) : '-',
          sorting: true
        },
        {
          title: t('status'),
          field: 'requestStatus',
          sorting: true,
          defaultSort: 'asc',
          align: 'center',
          render: (row) => {
            const currentStatus = row.requestStatus === 'APROVADO' && moment(row?.expirationDate, 'YYYY-MM-DD h:mm:ss').isBefore(moment().format('YYYY-MM-DD h:mm:ss')) ? 'EXPIRADO' : row.requestStatus

            return (
              <Badge
                backgroundColorBadge={ ACCREDITMENT_STATUS_COLORS[currentStatus] }
                colorBadge={ ACCREDITMENT_STATUS_COLORS[currentStatus] }
              >
                { ACCREDITMENT_STATUS_NAMES[currentStatus] }
              </Badge>
            )
          }
        }
      ] : [
        {
          title: 'CPF',
          field: 'user[0].document',
          sorting: true,
          render: row => formatCpfCnpj(row?.user[0]?.document)
        },
        {
          title: t('name'),
          field: 'user[0].name',
          sorting: true
        },
        {
          title: t('organization', { howMany: 1 }),
          field: 'organization.tradeName',
          sorting: true
        },
        {
          title: t('date', { howMany: 1 }),
          field: 'requestDate',
          render: (row) => moment(row.requestDate, 'YYYY-MM-DD HH:mm:ss').format(momentFriendlyDateFormat),
          sorting: true
        },
        {
          title: t('expiration'),
          field: 'expirationDate',
          render: (row) => row?.expirationDate ? moment(row?.expirationDate, 'YYYY-MM-DD HH:mm:ss').format(momentFriendlyShortDateFormat) : '-',
          sorting: true
        },
        {
          title: t('status'),
          field: 'requestStatus',
          sorting: true,
          defaultSort: 'asc',
          align: 'center',
          render: (row) => {
            const currentStatus = row.requestStatus === 'APROVADO' && moment(row?.expirationDate, 'YYYY-MM-DD h:mm:ss').isBefore(moment().format('YYYY-MM-DD h:mm:ss')) ? 'EXPIRADO' : row.requestStatus

            return (
              <Badge
                backgroundColorBadge={ ACCREDITMENT_STATUS_COLORS[currentStatus] }
                colorBadge={ ACCREDITMENT_STATUS_COLORS[currentStatus] }
              >
                { ACCREDITMENT_STATUS_NAMES[currentStatus] }
              </Badge>
            )
          }
        }
      ], [isRTC, t]
  )

  const revokeAccreditment = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete-accreditment',
        Component: RefuseModal,
        props: {
          onConfirm: (reason) => {
            dispatch(
              OperationsActions.editAccreditment(
                row?.id,
                { motive: reason, requestStatus: 'RECUSADO' },
                () => {
                  snackbar.success(
                    t('accreditment removed')
                  )
                  dispatch(
                    OperationsActions.loadAccrediteds(
                      { ...filters }
                    )
                  )
                }
              )
            )
          }
        }
      })
    },
    [createDialog, dispatch, filters, snackbar, t]
  )

  const acceptAccreditment = useCallback(
    (_, row) => {
      createDialog({
        id: 'accept-accreditment',
        Component: AcceptAccreditmentModal,
        props: {
          defaultValues: row,
          accreditmentId: row?.id,
          requestStatus: row?.requestStatus,
          loadAccrediteds: () => dispatch(
            OperationsActions.loadAccrediteds(
              { ...filters }
            )
          )
        }
      })
    },
    [createDialog, dispatch, filters]
  )

  const downloadCertificate = useCallback(async (row) => {
    const opt = {
      margin:       [30, 30, 30, 30], // top, left, buttom, right
      filename:    `certificado_operacao365_${ row?.user?.[0]?.name || user?.name }.pdf`,
      image:        { type: 'jpeg', quality: 0.20 },
      html2canvas:  { scale: 2 },
      jsPDF:        { format: 'a4', orientation: 'portrait', hotfixes: ['px_scaling'], unit: 'px' },
      pageBreak: { mode: 'legacy' },
      compressPDF: true
    }

    const  blob = html2pdf()

    await blob.set(opt).from(
      technicalCertificate
        .replace('{technicalName}', row?.user?.[0]?.name || user?.name)
        .replace('{document}', formatCpfCnpj(row?.user?.[0].document || user?.document))
        .replace('{tradeName}', row?.organization?.tradeName)
        .replace('{accreditationDate}', moment(row?.accreditationDate, 'YYYY-MM-DD h:mm:ss').format('DD/MM/YYYY'))
        .replace('{expiringDate}', moment(row?.expirationDate, 'YYYY-MM-DD h:mm:ss').format('DD/MM/YYYY')))
      .toPdf().save()
  }, [user.document, user.name])

  const actions = useMemo(
    () => [
      (row) => ({
        position: 'row',
        onClick: () => downloadCertificate(row),
        disabled: (
          row.requestStatus === 'AGUARDANDO_APROVACAO' ||
          row.requestStatus === 'RECUSADO'
        ),
        iconButtonProps: {
          id: 'download-certificate-',
          variant: 'outlined',
          size: 'small',
          color: 'black',
          style: {
            width: 30,
            marginRight: 10,
            height: 30,
            borderRadius: 5,
            color: colors.black
          }
        },
        icon: () => (row.requestStatus === 'AGUARDANDO_APROVACAO' ||
        row.requestStatus === 'RECUSADO') ? null : (
            <>
              <Icon icon={ download } size={ 14 } />
            </>
          )
      })
    ],
    [downloadCertificate]
  )

  return (
    <DataTable
      actions={ actions }
      tableRef={ tableRef }
      columns={ columns }
      data={ isRTC ? accrediteds : accredited }
      onDeleteClick={ isRTC ? revokeAccreditment : null }
      disabledRow={ (row) => row?.requestStatus === 'RECUSADO' }
      conditionToDelete={ (row) => row?.requestStatus !== 'RECUSADO' }
      onRowClick={ isRTC ? acceptAccreditment : null }
      id="accrediteds-list-table"
    />
  )
})

AccreditmentList.propTypes = {
  filters: PropTypes.object
}

AccreditmentList.defaultProps = {
  filters: {}
}

export default AccreditmentList
