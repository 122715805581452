
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import Card from '@smartcoop/web-components/Card'
import { Item } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`

export const Divider = styled.hr`
  border: none;
  border-bottom: 0.935252px solid #E0E0E0;
  margin: 0;
  width: 100%;
`

export const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
`

export const RowLocations = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 18px;
  margin: 2px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 5px;
`

export const InformationContainer = styled.div`
  width: auto;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  margin: 5px 0 10px;
  font-size: 16px;
`

export const InformationContent = styled.div`
  width: auto;
  flex: 2;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  margin-right: 15px;
  line-height: 22px;
`

export const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 21px;
  font-size: 16px;
  flex: 1;
`

export const AddressContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
`

export const Volume = styled.span``

export const CardAddress = styled(Card)`
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  margin: 0 0 14px 0px;
`

export const QuantityAddress = styled(CardAddress)`
  margin: 0 0 12px 14px;
`

export const VolumeContainer = styled(InformationContent)`
  flex: 1;
`
export const OrderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.5px;
`
export const OrderSubTitleItem = styled(Item)`
  font-size: 16px;
  font-weight: 600;
  padding-top: 0;
  padding-bottom: 0;
`

export const OrderItem = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 13px;
`

export const OrderItemText = styled(Item)`
  padding-top: 0;
  padding-bottom: 0;
`
export const OrganizationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
`

export const OrganizationItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
`
export const HeaderDiv = styled.div`
  display: flex;
  width: 79%;
  margin: 10px 0;
  align-items: center;
`
