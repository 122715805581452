/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'
import PropTypes from 'prop-types'


import { isString } from 'lodash'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import round from 'lodash/round'
import toNumber from 'lodash/toNumber'
import upperFirst from 'lodash/upperFirst'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import {
  arrowDownField,
  arrowUpField,
  calendar,
  pencil,
  trash,
  dropPercentage,
  wind,
  rain,
  closeBold
} from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import useSmartcoopApi from '@smartcoop/services/hooks/useSmartcoopApi'
import { useSnackbar } from '@smartcoop/snackbar'
import { CropManagementActions } from '@smartcoop/stores/cropManagement'
import { selectCropManagement } from '@smartcoop/stores/cropManagement/selectorCropManagement'
import { FieldActions } from '@smartcoop/stores/field'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectTechnicalFinancialDataAccess } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import { momentFriendlyDateFormat, momentBackDateFormat } from '@smartcoop/utils/dates'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import { formatCurrency , formatNumber } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import Loader from '@smartcoop/web-components/Loader'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import PictureModal from '@smartcoop/web-components/Modal/PictureModal'
import ThumbnailImage from '@smartcoop/web-components/ThumbnailImage'
import CompleteCropManagementModal from '@smartcoop/web-containers/modals/CompleteCropManagementModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  Container,
  Header,
  ManagementName,
  DateContent,
  TextContent,
  WheaterManagement,
  TemperatureContent,
  HumidityText,
  PrecipationText,
  WindText,
  DetailsManagement,
  DetailsText,
  TitleWheater,
  DateText,
  TemperatureText,
  TemperatureNumber,
  TitleInfoTemp,
  TextInfoTemp,
  DateSingle,
  ImagesContainer,
  Temperature,
  Divider
} from './style'

const CropManagementDetailFragment = (props) => {
  const {
    cropManagementData: externalCropManagementData,
    growingSeasonId,
    growingSeason,
    field,
    closeCropManagement,
    childrenPolygon,
    startLoading,
    canConcludeGrowingSeason,
    isFromHistory,
    isPlanned,
    fieldId,
    readOnly,
    accessToken
  } = props

  const history = useHistory()
  const { routes } = useRoutes()
  const t = useT()
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const userWrite = useSelector(selectUserCanWrite)
  const financialDataAccess = useSelector(selectTechnicalFinancialDataAccess)
  const isTechnical = useSelector(selectModuleIsTechnical)
  const cropManagement = useSelector(selectCropManagement)

  const cropManagementData =  useMemo(() => {
    if(readOnly) {
      return {
        ...externalCropManagementData,
        cropManagementItem: {
          ...externalCropManagementData.cropManagementItem,
          items: isString(externalCropManagementData?.cropManagementItem?.items) ? JSON.parse(externalCropManagementData?.cropManagementItem?.items) : externalCropManagementData?.cropManagementItem?.items
        }
      }
    }
    return cropManagement
  }, [cropManagement, externalCropManagementData, readOnly])

  const [loadingManagement, setLoadingManagement] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [imageFiles, setImageFiles] = useState([])

  const selectProductGroupSeedId = useMemo(() => cropManagementData?.cropManagementItem?.items?.productGroupSeeding || null, [cropManagementData])

  const area = useMemo(() => {
    if(growingSeason?.childrenPolygonId) {
      return round(find(growingSeason?.fieldData?.childrenPolygons, item => item.id === growingSeason?.childrenPolygonId)?.area, 2)
    }
    return round(growingSeason?.fieldData?.area, 2)
  }, [growingSeason])


  const { data: remoteData, isValidating: loadingProductGroupName  } = useSmartcoopApi(selectProductGroupSeedId ? `/v1/product-groups/${ selectProductGroupSeedId }` : '')

  useEffect(
    () => {
      if(!readOnly) {
        setLoadingManagement(true)
        dispatch(
          CropManagementActions.loadCropManagement(
            { growingSeasonId,
              externalCropManagementData,
              cropManagementId: externalCropManagementData.id
            },
            () => setLoadingManagement(false),
            () => setLoadingManagement(false)
          ))
      }
    }
    ,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [externalCropManagementData]
  )


  const handleCropManagementDelete = useCallback(
    () => {
      createDialog({
        id: 'confirm-delete-cropManagement',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            startLoading(true)
            dispatch(FieldActions.deleteOfflineCropManagement(
              growingSeasonId,
              cropManagementData.id,
              () => {
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'male',
                    this: t('cropManagement', { howMany: 1 })
                  })
                )
                startLoading(false)
                closeCropManagement()
              },
              () => startLoading(false),
              true
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            gender: 'male',
            howMany: 1,
            this: t('cropManagement', { howMany: 1 })
          })
        }
      })
    },
    [closeCropManagement, createDialog, cropManagementData.id, dispatch, growingSeasonId, snackbar, startLoading, t]
  )

  const handleCropManagementEdit = useCallback(
    () => history.push(routes.managementRegister.path, { field, isPlanned, childrenPolygon, isEdit: true, cropManagementData, growingSeason }),
    [childrenPolygon, cropManagementData, field, growingSeason, history, isPlanned, routes]
  )

  const handleCompleteCropManagement = useCallback(
    () => {
      createDialog({
        id: 'complete-crop-management',
        Component: CompleteCropManagementModal,
        props: {
          cropManagementData,
          growingSeasonId,
          closeCropManagement,
          canConcludeGrowingSeason,
          startLoading: (value) => startLoading(value),
          endLoading: () => startLoading(false),
          onSuccess: () => {
            dispatch(FieldActions.loadCurrentField(fieldId, isPlanned))
          }
        }
      })
    },
    [canConcludeGrowingSeason, closeCropManagement, createDialog, cropManagementData, dispatch, fieldId, growingSeasonId, isPlanned, startLoading]
  )

  const isDisabled = useCallback(
    () => cropManagementData.realizationDate ? setDisabled(true) : setDisabled(false)
    ,
    [cropManagementData.realizationDate]
  )

  useEffect(() => {
    isDisabled()
  }, [growingSeasonId, isDisabled])

  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_CROP_MANAGEMENT_IMAGES_ID, fileKey: file?.fileKey }, accessToken)

    return downloadFromBase64(data)
  }, [accessToken])

  useEffect(() => {
    const getRenderedFiles = async() => {
      const receivedElements = await Promise.all(map(cropManagementData?.cropsManagementFiles, async(file) => {
        const fileSrc = await renderFile(file)
        return ({ ...file, fileSrc })
      }))

      setImageFiles(map(receivedElements, (file, index) => (
        <ThumbnailImage
          key={ file?.fileId }
          src={ file?.fileSrc }
          cursor="pointer"
          onClick={ () => createDialog({
            id: `picture-modal-${ file?.fileKey }`,
            Component: PictureModal,
            props: {
              src: receivedElements,
              initialFirstItem: index
            }
          }) }
        />)))
    }

    getRenderedFiles()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cropManagementData])

  const shouldRenderItem = useCallback((key, value) => {
    if(key === 'productGroupSeeding' || key === 'rubricItemId' || key === 'referenceId' || key === 'productId' || key === 'unitProductivity' || key === 'valueHA') {
      return false
    }
    if(key === 'value' && isTechnical && !financialDataAccess) {
      return false
    }
    if ((key === 'value' && !value) || (key === 'value' && value === 0) || (key === 'value' && value === '0') || (key === 'value' && value === null) || isEmpty(toString(value))) {
      return false
    }
    return true
  },[financialDataAccess, isTechnical])

  return(
    <Container>
      {loadingManagement || loadingProductGroupName ? (
        <Loader width={ 100 } />
      ) : (
        <>
          <Header>
            {!isEmpty(cropManagementData.operation) && (
              <TextContent>
                <ManagementName>
                  {cropManagementData.operation.name}
                </ManagementName>
                <DateContent>
                  <DateSingle>
                    <I18n>scheduled to:</I18n>
                    <Icon
                      style={ { margin: '0 5px 0 3px' } }
                      size={ 10 } icon={ calendar }
                      color={ colors.green }
                    />
                    {moment(cropManagementData.predictedDate, momentBackDateFormat).format(momentFriendlyDateFormat)}
                  </DateSingle>
                  {!isEmpty(cropManagementData.realizationDate) && (
                    <>
                      <span style={ { margin: '0 5px' } }>-</span>
                      <DateSingle>
                        <I18n>held in:</I18n>
                        <Icon
                          style={ { margin: '0 5px 0 3px' } }
                          size={ 10 } icon={ calendar }
                          color={ colors.grey }
                        />
                        {moment(cropManagementData.realizationDate, momentBackDateFormat).format(momentFriendlyDateFormat)}
                      </DateSingle>
                    </>
                  )}
                </DateContent>
              </TextContent>
            )}
            <div>
              {(userWrite && !isFromHistory && !readOnly) && (
                <>
                  <Button
                    id="edit-crop-management"
                    style={ { padding: '5.5px 10px', fontSize: '0.875rem', marginRight: 10 } }
                    color={ colors.white }
                    onClick={ handleCropManagementEdit }
                    disabled={ isFromHistory }
                  >
                    <>
                      <Icon size={ 17 } icon={ pencil } color={ colors.black } />
                      <I18n>edit</I18n>
                    </>
                  </Button>

                  <Button
                    id="delete-field"
                    style={ { padding: '5.5px 10px', fontSize: '0.875rem', marginRight: 10 } }
                    color={ colors.white }
                    onClick={ handleCropManagementDelete }
                    disabled={ isFromHistory }
                  >
                    <>
                      <Icon size={ 17 } icon={ trash } color={ colors.red } />
                      <I18n>exclude</I18n>
                    </>
                  </Button>
                  <Button
                    id="complete-operation"
                    style={ { padding: '5.5px 10px', fontSize: '0.875rem', marginRight: 10 } }
                    color="primary"
                    onClick={ handleCompleteCropManagement }
                    disabled={ disabled || isFromHistory || isPlanned }
                  >
                    <>
                      <I18n>complete operation</I18n>
                    </>
                  </Button>
                </>
              )}
              {!readOnly && (
                <Button
                  id="close-crop-management"
                  variant="outlined"
                  color={ colors.white }
                  style={ { padding: 0, fontSize: '0.875rem' } }
                  onClick={ closeCropManagement }
                >
                  <Icon size={ 30 } icon={ closeBold } color={ colors.darkGrey }/>
                </Button>
              )}
            </div>
          </Header>
          {!isEmpty(cropManagementData.weather) && (
            <>
              <Divider/>
              <WheaterManagement>
                <div>
                  <TitleWheater>
                    <I18n>forecast for realization date</I18n>
                  </TitleWheater>
                  <DateText>{moment(cropManagementData?.realizationDate ?? cropManagementData.predictedDate, momentBackDateFormat).format('DD [de] MMMM, dddd')}</DateText>
                </div>
                <TemperatureContent>
                  <Temperature>
                    <TemperatureText>
                      <I18n>max</I18n>
                    </TemperatureText>
                    <TemperatureNumber>{cropManagementData.weather.high}°C</TemperatureNumber>
                    <Icon style={ { marginLeft: '7px' } } size={ 9 } icon={ arrowUpField } color={ colors.orange } />
                  </Temperature>

                  <Temperature>
                    <TemperatureText>
                      <I18n>min</I18n>
                    </TemperatureText>
                    <TemperatureNumber>{cropManagementData.weather.low}°C</TemperatureNumber>
                    <Icon style={ { marginLeft: '7px' } } size={ 9 } icon={ arrowDownField } color={ colors.blue } />
                  </Temperature>
                </TemperatureContent>

                <HumidityText>
                  <TitleInfoTemp>
                    <I18n>humidity percentage</I18n>
                  </TitleInfoTemp>
                  <TextInfoTemp>
                    <Icon style={ { marginRight: '7px' } } size={ 9 } icon={ dropPercentage } color={ colors.blue } />
                    <p>{cropManagementData.weather.humidity}%</p>
                  </TextInfoTemp>
                </HumidityText>

                <PrecipationText>
                  <TitleInfoTemp>
                    <I18n>precipitation</I18n>
                  </TitleInfoTemp>
                  <TextInfoTemp>
                    <Icon style={ { marginRight: '7px' } } size={ 9 } icon={ rain } color={ colors.blue } />
                    <p>{cropManagementData.weather.precipitation}mm</p>
                  </TextInfoTemp>
                </PrecipationText>

                <WindText>
                  <TitleInfoTemp>
                    <I18n>wind</I18n>
                  </TitleInfoTemp>
                  <TextInfoTemp >
                    <Icon style={ { marginRight: '7px' } } size={ 9 } icon={ wind } color={ colors.blue } />
                    <p>{cropManagementData.weather.wind} km/h</p>
                  </TextInfoTemp>
                </WindText>
              </WheaterManagement>
            </>)}
          <Divider/>
          <DetailsManagement>
            { cropManagementData?.cropManagementItem?.items && remoteData?.name && (
              <DetailsText style={ { minWidth: '20%' } }>
                <div style={ { fontWeight: 600 } }>
                  <I18n>productGroupSeeding</I18n>
                </div>
                <div style={ { fontSize: 16 } }>
                  <p>{remoteData?.name}</p>
                </div>
              </DetailsText>
            )}
            {cropManagementData.cropManagementItem?.items?.formFields ? (
              <>
                {cropManagementData.cropManagementItem.items?.hour && (
                  <DetailsText style={ { minWidth: '20%' } }>
                    <div style={ { fontWeight: 600 } }>
                      <I18n>hour</I18n>
                    </div>
                    <div style={ { fontSize: 16 } }>
                      <p>{cropManagementData.cropManagementItem.items?.hour}</p>
                    </div>
                  </DetailsText>
                )}
                {map(cropManagementData.cropManagementItem.items?.formFields, (item, itemKey) => (
                  <div key={ itemKey } style={ { display: 'flex', flexDirection: 'row', minWidth: '100%', flexWrap: 'wrap' } }>
                    {
                      map(item, (value, key) => shouldRenderItem(key, value) && (
                        <div style={ { display: 'flex', flexDirection: 'row', minWidth: key === 'value' ? '40%' : '20%' } }>
                          <DetailsText key={ key } style={ { width: key === 'value' ? '50%' : 'auto' } }>
                            <div style={ { fontWeight: 600 } }>
                              <I18n>{key.toString() === 'value' ? 'total cost' : key.toString()}</I18n>
                            </div>
                            <div style={ { fontSize: 16 } }>
                              <p>{value?.name ?? key === 'unit' ? value : key === 'value' || key === 'valueDose' ? formatCurrency(value) : (key === 'valueKgSemHA' ? formatNumber(value) : upperFirst(value))}</p>
                            </div>
                          </DetailsText>
                          {key === 'value' && (
                            <DetailsText key={ `${ key }-ha` } style={ { width: '50%' } }>
                              <div style={ { fontWeight: 600 } }>
                                <I18n>cost by ha</I18n>
                              </div>
                              <div style={ { fontSize: 16 } }>
                                <p>{formatCurrency(round(value/area, 2))}</p>
                              </div>
                            </DetailsText>
                          )}
                        </div>
                      ))
                    }
                    <Divider />
                  </div>
                ))}
              </>
            )
              : cropManagementData.cropManagementItem && (
                map(cropManagementData.cropManagementItem.items, (value, key) =>
                  shouldRenderItem(key, value) && (
                    <div key={ key } style={ { display: 'flex', flexDirection: 'row', minWidth: key === 'value' ? '40%' : '20%' } }>
                      <DetailsText style={ { width: key === 'value' ? '50%' : 'auto' } }>
                        <div style={ { fontWeight: 600 } }>
                          <I18n>{key.toString() === 'value' ? 'total cost' : key.toString()}</I18n>
                        </div>
                        <div style={ { fontSize: 16 } }>
                          <p>{value?.name ?? key === 'unit' ? value : key === 'value' || key === 'valueDose' ? formatCurrency(value) : !isNaN(toNumber(value)) ? formatNumber(value) : key === 'irrigationFinalDate' ? moment(value, momentBackDateFormat).format(momentFriendlyDateFormat) : upperFirst(value)}</p>
                        </div>
                      </DetailsText>
                      {key === 'value' && (
                        <DetailsText style={ { width: '50%' } } key={ `${ key }-ha` }>
                          <div style={ { fontWeight: 600 } }>
                            <I18n>cost by ha</I18n>
                          </div>
                          <div style={ { fontSize: 16 } }>
                            <p>{formatCurrency(round(value/area, 2))}</p>
                          </div>
                        </DetailsText>
                      )}
                    </div>
                  )))}
            <div style={ { display: 'flex', flexDirection: 'column' } }>
              {cropManagementData.observations && (
                <DetailsText style={ { minWidth: '25%' } }>
                  <p style={ { fontWeight: 600 } }>
              Observações
                  </p>
                  <div style={ { fontSize: 16 } }>
                    <p>{cropManagementData.observations}</p>
                  </div>
                </DetailsText>
              )}
              {!isEmpty(imageFiles) && (
                <DetailsText style={ { minWidth: '25%' } }>
                  <p style={ { fontWeight: 600 } }> {t('images')}</p>
                  <ImagesContainer>
                    {imageFiles}
                  </ImagesContainer>
                </DetailsText>
              )}
            </div>
          </DetailsManagement>
        </>
      )}
    </Container>
  )
}

CropManagementDetailFragment.propTypes = {
  cropManagementData: PropTypes.object,
  growingSeason: PropTypes.object,
  childrenPolygon: PropTypes.object,
  field: PropTypes.object,
  growingSeasonId: PropTypes.string,
  accessToken: PropTypes.string,
  fieldId: PropTypes.string,
  closeCropManagement: PropTypes.func,
  canConcludeGrowingSeason: PropTypes.bool,
  startLoading: PropTypes.func,
  readOnly: PropTypes.bool,
  isFromHistory: PropTypes.bool,
  isPlanned: PropTypes.bool
}

CropManagementDetailFragment.defaultProps = {
  cropManagementData: {},
  growingSeason: {},
  field: {},
  childrenPolygon: {},
  growingSeasonId: null,
  fieldId: null,
  accessToken: null,
  canConcludeGrowingSeason: false,
  closeCropManagement: () => {},
  startLoading: () => {},
  isFromHistory: false,
  readOnly: false,
  isPlanned: false
}

export default CropManagementDetailFragment
