import React, { forwardRef, useMemo, useEffect, useContext } from 'react'

import PropTypes from 'prop-types'

import orderBy from 'lodash/orderBy'
import uniqWith from 'lodash/uniqWith'

import { syncFileService } from '@smartcoop/database/services/syncFileService'
import { syncPendingService } from '@smartcoop/database/services/syncPendingService'
import { database } from '@smartcoop/database/web-database'
import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { emptyFilter } from '@smartcoop/icons'
import EmptyState from '@smartcoop/web-components/EmptyState'

import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser'
import ImageDetailModal from '../../../modals/ImageDetailModal'
import { ChatContext } from '../../../providers/ChatProvider'
import ChatMessageItem from '../ChatMessageItem'
import { Container } from './styles'

const ChatFeed = forwardRef(({
  messages,
  selecteds,
  handleChangeSelected,
  conversationFiles,
  isGroup,
  isBroadcast,
  hiddenFooter
}, ref) => {
  const { createDialog } = useDialog()
  const { openedChat } = useContext(ChatContext)
  const localUser = useAuthenticatedUser()
  const t = useT()

  const orderedMessages = useMemo(
    () => orderBy(uniqWith(messages, (a, b) => (
      a.messageId === b.messageId &&
      a.message === b.message
    )
    ), ['sendAt']),
    [messages]
  )

  useEffect(() => {
    if (openedChat?.localMessageId) {
      setTimeout(() => {
        const element = document.getElementById(openedChat?.localMessageId)

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
          // Add style to highlighting
          element.style.filter = 'brightness(0.90)'
          setTimeout(() => {element.style.filter = null}, 1000)
        }
      }, 500)
    }
  }, [openedChat])

  const onOpenImage = (imageSrc, imageName) => {
    createDialog({
      id: 'confirm-delete-messages',
      Component: ImageDetailModal,
      props: { imageSrc, imageName }
    })
  }

  const trySendAgain = async (syncId, isDocument) => {
    if (!isDocument) {
      await syncPendingService(database).updateTryAgain(syncId)
    } else {
      await syncFileService(database).updateTryAgain(syncId)
    }
  }

  const emptyStateMessage = useMemo(() => {
    if (isGroup) {
      return t('send your first message to this group')
    }

    if (isGroup) {
      return t('send your first message to this broadcast')
    }

    return t('send your first message to this contact')
  }, [isGroup, t])

  return (
    <Container empty={ !messages.length } hiddenFooter={ hiddenFooter }>
      { !messages.length && (
        <EmptyState
          text={ emptyStateMessage }
          icon={ emptyFilter }
        />
      ) }

      {orderedMessages.map(item => (
        <ChatMessageItem
          key={ item.id }
          message={ item }
          localUser={ localUser }
          showCheckbox={ !!selecteds?.length }
          selected={ selecteds.includes(item.id) }
          handleChangeSelected={ handleChangeSelected }
          onOpenImage={ onOpenImage }
          trySendAgain={ trySendAgain }
          isGroup={ isGroup }
          isBroadcast={ isBroadcast }
          conversationFile={ conversationFiles?.find(file => (
            (file.messageId && file.messageId === item?.messageId) ||
            file.registerId === item?.id
          )) }
        />
      ))}
      <div ref={ ref } />
    </Container>
  )
})

ChatFeed.propTypes = {
  messages: PropTypes.array.isRequired,
  selecteds: PropTypes.array.isRequired,
  handleChangeSelected: PropTypes.func.isRequired,
  conversationFiles: PropTypes.array,
  isGroup: PropTypes.bool,
  isBroadcast: PropTypes.bool,
  hiddenFooter: PropTypes.bool
}

ChatFeed.defaultProps = {
  conversationFiles: [],
  isGroup: false,
  isBroadcast: false,
  hiddenFooter: false
}

export default ChatFeed
