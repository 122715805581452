import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'

import { selectCurrentField as selectOnlineCurrentField } from '@smartcoop/stores/field/selectorField'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'


export const selectFields = state => state.offlineFieldUserData.fields
export const selectFieldsPropertyIDGrouped = state => state.offlineFieldUserData.fieldsPropertyIDGrouped

export const selectPropertyFields = (state) => {
  const property = selectCurrentProperty(state)
  const fields = selectFieldsPropertyIDGrouped(state)[property?.id] ?? []
  return map(fields, field => ({
    ...field,
    growingSeasons: filter(field.growingSeasons, growingSeason => !growingSeason?.isPlanned)
  }))
}

export const selectPlannedPropertyFields = (state) => {
  const property = selectCurrentProperty(state)
  const fields = selectFieldsPropertyIDGrouped(state)[property?.id] ?? []
  return map(fields, field => ({
    ...field,
    growingSeasons: filter(field.growingSeasons, growingSeason => growingSeason?.isPlanned)
  }))
}

export const selectCurrentField = (state) => {
  const fields = selectPropertyFields(state)
  const currentField = selectOnlineCurrentField(state)
  return find(fields, (field) => field?.id === currentField?.id) ?? {}
}
