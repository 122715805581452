/* eslint-disable no-unused-expressions */
import React, { forwardRef, useState, useEffect, useCallback } from 'react'

import PropTypes from 'prop-types'

import round from 'lodash/round'
import toNumber from 'lodash/toNumber'

import silageCropManagementSchema from '@smartcoop/forms/schemas/management/silageCropManagement.schema'
import { useT } from '@smartcoop/i18n'
import Form from '@smartcoop/web-components/Form'
import InputUnit from '@smartcoop/web-components/InputUnit'

const SilageProductionForm = forwardRef(({ defaultValues, handleSubmit, readOnly }, formRef) => {
  const t = useT()

  const [productionSilage, setProductionSilage] = useState(toNumber(defaultValues?.productionSilage) || null)
  const [dryMatterContent, setDryMatterContent] = useState(toNumber(defaultValues?.dryMatterContent) || null)
  const [relativeFoodValue, setRelativeFoodValue] = useState(toNumber(defaultValues?.relativeFoodValue) || null)


  const updateCalcValues = useCallback(() => {
    if(formRef?.current?.getFieldRef('dryMatter')) {
      formRef?.current?.getFieldRef('dryMatter')?.setValue(round(productionSilage * (dryMatterContent/100), 2))
    }
    if(formRef?.current?.getFieldRef('estimatedProduction')) {
      formRef?.current?.getFieldRef('estimatedProduction')?.setValue(round(relativeFoodValue * (productionSilage * (dryMatterContent/100)), 2))
    }
  },[dryMatterContent, formRef, productionSilage, relativeFoodValue])

  useEffect(() => {
    updateCalcValues()
  },[dryMatterContent, formRef, productionSilage, relativeFoodValue, updateCalcValues])

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ silageCropManagementSchema }
      containerStyle={ { height: 'auto' } }
      onSubmit={ handleSubmit }
    >
      <InputUnit
        name="productionSilage"
        label={ t('production') }
        unit="t/ha"
        defaultValue={ defaultValues?.productionSilage || 0 }
        onChange={ ({ target: { value } }) => setProductionSilage(value) }
        fullWidth
        disabled={ readOnly }
      />
      <InputUnit
        name="dryMatterContent"
        label={ t('dry matter content') }
        unit="%"
        onChange={ ({ target: { value } }) => setDryMatterContent(value) }
        defaultValue={ defaultValues?.dryMatterContent || 0 }
        fullWidth
        disabled={ readOnly }
        decimalScale={ 0 }
        type="integer"
      />
      <InputUnit
        disabled
        name="dryMatter"
        label={ t('dry matter') }
        unit="t/ha"
        fullWidth
        defaultValue={ toNumber(defaultValues?.dryMatter) }
      />
      <InputUnit
        name="relativeFoodValue"
        label={ t('relative food value') }
        unit="l/t"
        onChange={ ({ target: { value } }) => setRelativeFoodValue(value) }
        defaultValue={ defaultValues?.relativeFoodValue || 0 }
        fullWidth
        decimalScale={ 0 }
        type="integer"
      />
      <InputUnit
        disabled
        name="estimatedProduction"
        label={ t('estimated production') }
        unit="l/ha"
        fullWidth
        defaultValue={ toNumber(defaultValues?.estimatedProduction) }
      />
    </Form>
  )
})

SilageProductionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  defaultValues: PropTypes.object
}

SilageProductionForm.defaultProps = {
  readOnly: false,
  defaultValues: {}
}

export default SilageProductionForm
