import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import maxLengthValidator from '@smartcoop/forms/validators/maxLength.validator'
import required from '@smartcoop/forms/validators/required.validator'

const absence = ({ t }) => Yup.object().shape({
  startDate: flow(
    date({ t }),
    required({ t })
  )(Yup.string()),

  startHour: flow(
    required({ t })
  )(Yup.string()),

  endDate: flow(
    date({ t }),
    required({ t })
  )(Yup.string()),

  endHour: flow(
    required({ t })
  )(Yup.string()),

  name: flow(
    required({ t })
  )(Yup.string()),

  message: flow(
    required({ t }),
    maxLengthValidator({ t, field: t('the message'), length: 900 })
  )(Yup.string())
})

export default absence