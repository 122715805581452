import { REACT_APP_SMARTCOOP_API_URL } from 'react-native-dotenv'

import axios from 'axios'

const api = axios.create({
  baseURL:
    process.env.REACT_APP_SMARTCOOP_API_URL || REACT_APP_SMARTCOOP_API_URL
})

export default api
