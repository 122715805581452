import colors from '@smartcoop/styles/colors'

export default () => ({
  variantSuccess: {
    backgroundColor: `${ colors.green } !important`
  },
  variantWarning: {
    backgroundColor: `${ colors.warning } !important`,
    color: colors.text
  },variantError: {
    backgroundColor: `${ colors.error } !important`
  }
})
