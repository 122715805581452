import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import cpfCnpj from '@smartcoop/forms/validators/cpfCnpj.validator'
import required from '@smartcoop/forms/validators/required.validator'

const signUp = ({ t }) => Yup.object().shape({
  document: flow(
    cpfCnpj({ t }),
    required({ t })
  )(Yup.string())

})

export default signUp
