import React from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  emptyFilter,
  noFieldRegistered,
  filter
} from '@smartcoop/icons'
import EmptyState from '@smartcoop/web-components/EmptyState'
import GrowingSeasonHistoryItem from '@smartcoop/web-components/GrowingSeasonHistoryItem'
import Icon from '@smartcoop/web-components/Icon'
import InputSearch from '@smartcoop/web-components/InputSearch'
import Loader from '@smartcoop/web-components/Loader'

import {
  ContainerListFields,
  SearchContainer,
  ButtonContainer,
  ButtonFilter
} from './styles'

const FieldListPlanned = (props) => {

  const {
    history,
    routes,
    onChangeSearchFilter,
    filterText,
    editFields,
    filters,
    filterFields,
    loading,
    fields,
    handleFieldClick,
    propertyHasFields,
    t,
    loadFields
  } = props

  const dispatch = useDispatch()

  return propertyHasFields && (
    <>
      <ButtonContainer style={ { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', margin: 15 } }>
        <ButtonFilter
          id="register-field-plan"
          color="secondary"
          onClick={ () => history.push(routes.growingSeasonRegister.path, { isPlanned: true }) }
        >
          {t('register plan')}
        </ButtonFilter>
      </ButtonContainer>
      <SearchContainer>
        <InputSearch
          style={ { marginRight: 8, marginBottom: 0, flex: 5 } }
          adornmentStyle={ { marginRight: 15 } }
          detached
          onChange={ onChangeSearchFilter }
          value={ filterText }
          placeholder={ t('search') }
        />
        {!editFields && (
          <ButtonFilter
            id="filter-field"
            color={ isEmpty(filters) ? 'white' : 'secondary' }
            onClick={ filterFields }
            disabled={ loading }
          >
            <>
              <Icon
                style={ { marginRight: '8px' } }
                size={ 14 }
                icon={ filter }
              />
              {t('filtrate')}
            </>
          </ButtonFilter>
        )}
      </SearchContainer>

      <ContainerListFields isCenter={ isEmpty(fields) }>
        {loading ? (
          <Loader width={ 100 } />
        ) : (
          map(fields, (item) => (
            <GrowingSeasonHistoryItem
              key={ item.id }
              growingSeason={ item }
              onClick={ handleFieldClick }
              isPlanned
              loadFields={ loadFields }
              dispatch={ dispatch }
            />
          ))
        )}

        {!loading && !propertyHasFields && (
          <EmptyState
            text={ t('no field registered') }
            icon={ noFieldRegistered }
          />
        )}
        {!loading && propertyHasFields && isEmpty(fields) && (
          <EmptyState text={ t('no results found') } icon={ emptyFilter } />
        )}
      </ContainerListFields>
    </>
  )
}

FieldListPlanned.propTypes = {
  routes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onChangeSearchFilter: PropTypes.func.isRequired,
  filterText: PropTypes.string.isRequired,
  editFields: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  filterFields: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
  handleFieldClick: PropTypes.func.isRequired,
  propertyHasFields: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  loadFields: PropTypes.func.isRequired
}

export default FieldListPlanned
