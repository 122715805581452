import get from 'lodash/get'
import last from 'lodash/last'

import { conversationFileService } from '@smartcoop/database/services/conversationFileService'
import { conversationService } from '@smartcoop/database/services/conversationService'
import { messageService } from '@smartcoop/database/services/messageService'
import { generateUuid } from '@smartcoop/database/utils/uuidGenerator'
import { database } from '@smartcoop/database/web-database'
import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'

export function useForwardMessages(conversation, localUser) {
  const t = useT()
  const snackbar = useSnackbar()

  const getConversationDto = (selected) => ({
    id: conversation.relationalId,
    userCodes: [
      {
        userId: localUser?.userId,
        code: localUser?.userCode
      },
      {
        userId: selected?.contactId,
        code: selected?.userCode
      }
    ]
  })

  const startConversation = async (selected, messages) => {
    const conversationRelationalId = generateUuid(selected?.contactId, localUser?.userId)

    await conversationService(database).startConversation(
      conversationRelationalId,
      selected,
      get(last(messages), 'message', '')
    )
  }

  const forwardFile = async (selected, message) => {
    const fileExtension = last(message.documentName.split('.'))
    const conversationFile = await conversationFileService(database)
      .findDocumentByName(message.documentName)

    await messageService(database).sendFile(
      {
        senderId: localUser.userId,
        receiverId: selected.contactId,
        message: conversationFile.source,
        conversationId: selected?.group ? selected?.conversationId : undefined
      }, {
        format: fileExtension,
        documentType: message.documentFormat,
        forward: true,
        isGroup: selected?.group
      }
    )
  }

  const forwardMessage = async (selected, message) => {
    await messageService(database).send({
      senderId: localUser?.userId,
      senderCode: localUser?.userCode,
      senderPublicKey: localUser?.publicKey,
      receiverId: selected.contactId,
      receiverCode: selected.userCode,
      receiverPublicKey: selected.publicKey,
      message: message.message?.trim()
    }, true, message.isGeolocation)
  }

  const forwardMessages = async (selecteds = [], messages = []) => {
    try {
      const promises = []

      selecteds.forEach((selected) => {
        messages.forEach((message, index) => {
          // Start conversation with contact
          if (selected.table === 'contact' && index === 0) {
            promises.push(async () => startConversation(selected, messages))
            promises.push(async () => {
              const dto = getConversationDto(selected)
              await conversationService(database).createConversation(dto)
            })
          }

          if (message.isDocument) {
            promises.push(async () => forwardFile(selected, message))
          } else {
            promises.push(async () => forwardMessage(selected, message))
          }
        })
      })

      await Promise.all(promises.map(fn => fn()))

      snackbar.success(t('message forwarded successfully'))
    } catch(error) {
      snackbar.error(t('error forwarding messages. contact your system administrator'))
    }
  }

  return { forwardMessages }
}
