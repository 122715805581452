import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

/* Initial State */
const INITIAL_STATE = {
  accounts: [],
  currentAccount: {},
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({

  loadAccounts: ['onSuccess', 'onError'],
  loadAccountsSuccess: ['accounts', 'onSuccess'],

  setCurrentAccount: ['account'],

  updateCurrentAccount: [],

  resetAccounts: [],
  resetCurrentAccount: [],

  accountsError: ['error']
})

const loadAccounts = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error
})

const loadAccountsSuccess = (state = INITIAL_STATE, { accounts }) => ({
  ...state,
  accounts
})

const setCurrentAccount = (state = INITIAL_STATE, { account }) => ({
  ...state,
  currentAccount: account
})

const accountsError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const resetAccounts = (state = INITIAL_STATE) => ({
  ...state,
  accounts: INITIAL_STATE.accounts,
  currentAccount: INITIAL_STATE.currentAccount
})

const resetCurrentAccount = (state = INITIAL_STATE) => ({
  ...state,
  currentAccount: INITIAL_STATE.currentAccount
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {

  [Types.LOAD_ACCOUNTS]: loadAccounts,
  [Types.LOAD_ACCOUNTS_SUCCESS]: loadAccountsSuccess,

  [Types.SET_CURRENT_ACCOUNT]: setCurrentAccount,

  [Types.RESET_ACCOUNTS]: resetAccounts,
  [Types.RESET_CURRENT_ACCOUNT]: resetCurrentAccount,

  [Types.ACCOUNTS_ERROR]: accountsError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as AccountTypes,
  Creators as AccountActions
}
