import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const registerAnimalBirth = ({ t }) => Yup.object().shape({

  expectedDate: flow(
    required({ t })
  )(Yup.string()),

  occurrenceDate: flow(
    required({ t })
  )(Yup.string()),

  gender: flow(
    required({ t })
  )(Yup.string()),

  isAlive: flow(
    required({ t })
  )(Yup.string()),

  lotId: flow(
    required({ t })
  )(Yup.string()),

  weight: Yup.string().when('isAlive', {
    is: val => val,
    then: flow(
      required({ t })
    )(Yup.string())
  }),

  birthType: flow(
    required({ t })
  )(Yup.string())

})

export default registerAnimalBirth
