import styled from 'styled-components'

import { Typography } from '@material-ui/core'

import { colors } from '@smartcoop/styles'

export const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  padding: 5px 15px;
  cursor: pointer;
  background-color: ${ colors.white };

  :hover {
    filter: brightness(0.95);
  }
`
export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const NicknameSpan = styled.span`
  font-weight: 700;
  font-family: 'Open Sans';
  color: ${ colors.text };
`

export const DateSpan = styled(Typography)`
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: end;
`

export const MessageSpan = styled(Typography)`
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 300px;
`
