import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { OrderActions } from '@smartcoop/stores/order'
import { formatCurrency, formatNumber } from '@smartcoop/utils/formatters'
import Address from '@smartcoop/web-components/Address'
import DemandStatus from '@smartcoop/web-components/DemandStatus'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'

import {
  AddressContainer,
  CardAddress,
  QuantityAddress,
  Title,
  ComplianceButtons,
  ComplianceButton,
  Volume
} from './styles'

const AddressFragment = ({
  location,
  deliveryLocations,
  productUnit,
  slug,
  freightValue,
  handleNonCompliance,
  showNonComplianceButton,
  resetOrder
}) => {
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const t = useT()
  const { createDialog } = useDialog()

  const hasReceived = useMemo(() => location?.received ?? false, [location])

  const willShowButtons = useMemo(
    () => slug === 'aguardando_entrega' && !hasReceived,
    [hasReceived, slug]
  )

  const onReceivedProduct = useCallback(
    () =>
      dispatch(
        OrderActions.acceptReceipt(
          { received: true },
          location.deliveryId,
          () => {
            snackbar.success(t('receipt confirmed'))
            resetOrder()
          }
        )
      ),
    [dispatch, location.deliveryId, resetOrder, snackbar, t]
  )

  const confirmReceivement = useCallback(
    () =>
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          textWarning: t(
            'are you sure you want to confirm the receivement of this purchase?'
          ),
          onConfirm: () => {
            onReceivedProduct()
          }
        }
      }),
    [createDialog, onReceivedProduct, t]
  )

  return (
    <AddressContainer>
      <Grid item xs={ 8 }>
        <CardAddress
          style={ { alignItems: 'flex-start' } }
          cardStyle={ {
            width: '100%',
            padding: '10px',
            boxShadow: '0px 0px 6px rgb(0 0 0 / 16%)',
            borderRadius: '5px'
          } }
        >
          <div style={ { display: 'flex' } }>
            <Address
              deliveryLocations={ deliveryLocations }
              readOnly
              fields={ {
                companyDocument: location.cnpj,
                street: location.street,
                neighborhood: location.neighborhood,
                postalCode: location.cep,
                city: location.city,
                state: location.state,
                country: location.country,
                number: location.number,
                tradeName: location.tradeName,
                billingOrganizationId: location.billingOrganizationId,
                note: location.note
              } }
            />
            {hasReceived && (
              <div style={ { margin: '6px 10px' } }>
                <DemandStatus slug="entregue" />
              </div>
            )}
          </div>
        </CardAddress>
      </Grid>
      <QuantityAddress>
        <div style={ { marginBottom: 10 } }>
          <Title style={ { margin: '5px 0px 5px 0px' } }>
            <I18n>quantity</I18n>
          </Title>
          <Volume>
            {formatNumber(location.amount)} {productUnit}
          </Volume>
        </div>
        <div>
          <Title style={ { margin: '5px 0px 5px 0px' } }>
            <I18n>cfa freight cost</I18n>
          </Title>
          <Volume>
            {formatCurrency(freightValue)}
          </Volume>
        </div>
      </QuantityAddress>
      <ComplianceButtons>
        {willShowButtons && (
          <ComplianceButton
            id="received-product-btn"
            color="secondary"
            onClick={ confirmReceivement }
          >
            <I18n>received product</I18n>?
          </ComplianceButton>
        )}
        {showNonComplianceButton && (
          <ComplianceButton
            id="my-docs"
            color="black"
            onClick={ () => handleNonCompliance(location.deliveryId) }
            style={ { flex: 'none', whiteSpace: 'nowrap' } }
          >
            <I18n>non-compliance</I18n>
          </ComplianceButton>
        )}
      </ComplianceButtons>
    </AddressContainer>
  )
}

AddressFragment.propTypes = {
  location: PropTypes.shape({
    amount: PropTypes.number,
    deliveryId: PropTypes.string,
    received: PropTypes.bool,
    cep: PropTypes.string,
    city: PropTypes.string,
    cnpj: PropTypes.string,
    country: PropTypes.string,
    neighborhood: PropTypes.string,
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    state: PropTypes.string,
    tradeName: PropTypes.string,
    street: PropTypes.string,
    billingOrganizationId: PropTypes.string,
    note: PropTypes.string
  }).isRequired,
  productUnit: PropTypes.string.isRequired,
  slug: PropTypes.string,
  freightValue: PropTypes.number,
  handleNonCompliance: PropTypes.func,
  showNonComplianceButton: PropTypes.bool.isRequired,
  deliveryLocations: PropTypes.array,
  resetOrder: PropTypes.func
}

AddressFragment.defaultProps = {
  slug: '',
  freightValue: null,
  handleNonCompliance: () => {},
  deliveryLocations: [],
  resetOrder: () => {}
}

export default AddressFragment
