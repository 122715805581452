export default ({ size } = {}) => /* html */`
<svg width="${ size }" height="${ size }" viewBox="0 0 191 158" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.8965 56.2877C46.0294 73.8464 28.9487 92.397 18.8918 99.4775C-1.18827 117.126 10.1228 147.605 28.3311 153.516C47.5998 159.772 64.3965 147.491 72.0417 139.209C92.9495 116.56 128.954 122.357 154.178 122.297C199.156 122.19 194.689 60.7996 161.573 61.0091C141.67 61.135 131.647 58.9666 126.528 46.2256C120.126 30.2896 104.891 15.812 100.612 13.6636C58.7611 -7.34786 18.7303 34.3395 33.8965 56.2877Z" fill="white"/>
<path d="M20.8585 55.2224C20.1768 56.7306 18.3951 57.4063 16.8875 56.726C15.3799 56.0457 14.7058 54.2645 15.3875 52.7563C16.0691 51.2482 17.8508 50.5725 19.3584 51.2528C20.8721 51.9392 21.5401 53.7143 20.8585 55.2224Z" fill="#E0E0E0"/>
<path d="M35.8585 95.2224C35.1768 96.7306 33.3951 97.4063 31.8875 96.726C30.3799 96.0457 29.7058 94.2645 30.3875 92.7563C31.0691 91.2481 32.8509 90.5724 34.3585 91.2527C35.8721 91.9391 36.5401 93.7142 35.8585 95.2224Z" fill="#E0E0E0"/>
<path d="M184.859 83.2243C184.177 84.7325 182.395 85.4082 180.887 84.7279C179.38 84.0476 178.706 82.2664 179.387 80.7582C180.069 79.2501 181.851 78.5744 183.359 79.2547C184.872 79.9289 185.546 81.7101 184.859 83.2243Z" fill="#E0E0E0"/>
<path d="M160.859 47.2224C160.177 48.7306 158.395 49.4063 156.887 48.726C155.38 48.0457 154.706 46.2645 155.387 44.7563C156.069 43.2481 157.851 42.5724 159.358 43.2527C160.866 43.933 161.54 45.7142 160.859 47.2224Z" fill="#E0E0E0"/>
<path d="M60.8585 15.2224C60.1769 16.7306 58.3951 17.4063 56.8875 16.726C55.3799 16.0457 54.7058 14.2645 55.3875 12.7563C56.0692 11.2481 57.8509 10.5724 59.3585 11.2527C60.8722 11.9391 61.5402 13.7142 60.8585 15.2224Z" fill="#E0E0E0"/>
<path d="M60.8585 143.222C60.1768 144.731 58.395 145.406 56.8875 144.726C55.3799 144.046 54.7058 142.264 55.3874 140.756C56.0691 139.248 57.8509 138.573 59.3584 139.253C60.866 139.933 61.5401 141.714 60.8585 143.222Z" fill="#E0E0E0"/>
<path d="M126.046 115.99H52.3789C47.6844 115.99 43.8789 112.184 43.8789 107.49V56.4897C43.8789 51.7953 47.6844 47.9897 52.3789 47.9897H126.046C130.74 47.9897 134.546 51.7953 134.546 56.4897V107.49C134.546 112.184 130.74 115.99 126.046 115.99ZM63.7122 57.9064C58.2354 57.9064 53.7956 62.3462 53.7956 67.8231C53.7956 73.2999 58.2354 77.7397 63.7122 77.7397C69.1891 77.7397 73.6289 73.2999 73.6289 67.8231C73.6289 62.3462 69.1891 57.9064 63.7122 57.9064ZM55.2122 104.656H123.212V84.8231L107.715 69.3256C106.885 68.4958 105.539 68.4958 104.71 69.3256L80.7122 93.3231L70.8815 83.4923C70.0516 82.6625 68.7062 82.6625 67.8762 83.4923L55.2122 96.1564V104.656Z" fill="#1D1D1B"/>
<path d="M128.734 93.7437C115.501 93.7437 104.734 104.51 104.734 117.744C104.734 130.977 115.501 141.744 128.734 141.744C141.968 141.744 152.734 130.977 152.734 117.744C152.734 104.51 141.968 93.7437 128.734 93.7437Z" fill="#FFC80A"/>
<path d="M130.996 130.796C130.996 131.893 130.105 132.784 129.008 132.784C127.91 132.784 127.02 131.893 127.02 130.796C127.02 129.699 127.91 128.808 129.008 128.808C130.105 128.808 130.996 129.693 130.996 130.796Z" fill="#1D1D1B"/>
<path d="M132.151 120.133C131.445 120.459 130.988 121.165 130.988 121.942V122.833C130.988 123.93 130.097 124.821 129 124.821C127.903 124.821 127.012 123.93 127.012 122.833V121.942C127.012 119.616 128.375 117.492 130.483 116.514C132.509 115.58 133.976 113.103 133.976 111.886C133.976 109.142 131.743 106.909 129 106.909C126.256 106.909 124.024 109.142 124.024 111.886C124.024 112.983 123.133 113.874 122.035 113.874C120.932 113.874 120.047 112.983 120.047 111.886C120.047 106.947 124.067 102.933 129 102.933C133.933 102.933 137.953 106.953 137.953 111.886C137.958 114.58 135.622 118.53 132.151 120.133Z" fill="#1D1D1B"/>
</svg>
`
