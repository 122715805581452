import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`

export const Video = styled.video`
  width: 100%;
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-top: 25px;
  width: 100%;
`

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60vh;
  height: 60vh;
`

export const ImageContainer =styled.div`
  opacity: ${ ({ visible }) => visible ? 1 : 0 };
  height: ${ ({ visible }) => visible ? '45vh' : 0 };
  width: ${ ({ visible }) => visible ? '45vh' : 0 };
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${ colors.black };
  border-radius: 10px;
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 17px;
  color: ${ colors.blackLight };
  text-align: center;
`

export const FileSizeContainer = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 16px;
  color: ${ colors.blackLight };
  justify-content: flex-end;
  width: 50%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`

export const UnavailablePreview = styled.span`
  padding: 24px;
  text-align: center;
  color: ${ colors.grey };
`
