import { tableSchema } from '@nozbe/watermelondb'

export const conversationFileSchema = tableSchema({
  name: 'conversation_file',
  columns: [
    { name: 'registerId', type: 'string' },
    { name: 'messageId', type: 'string' },
    { name: 'conversationId', type: 'string' },
    { name: 'documentName', type: 'string' },
    { name: 'source', type: 'string' }
  ]
})
