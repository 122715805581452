/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { nfeLogo, userEdit } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { UserActions } from '@smartcoop/stores/user'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import InputSearch from '@smartcoop/web-components/InputSearch'
import Modal from '@smartcoop/web-components/Modal'
import Stepper from '@smartcoop/web-components/Stepper'
import NfeIntegratorForm from '@smartcoop/web-containers/forms/profile/NfeIntegratorForm/NfeIntegratorForm'
import { Title } from '@smartcoop/web-containers/layouts/GuestLayout/theme'

const NfeIntegratorModal = (props) => {
  const { id, open, handleClose } = props
  const NfeIntegratorFormRef = useRef(null)
  const t = useT()
  const snackbar = useSnackbar()
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const { removeDialog } = useDialog()

  const [step, setStep] = useState(user?.activeNfe ? 4 : 1)
  const [inputSearch, setInputSearch] = useState(null)

  const onSuccess = (nextStep = true) => {
    if(nextStep) {
      setStep(step + 1)
      dispatch(UserActions.loadUser())
    }
    snackbar.success(t('integration succeed'))
    removeDialog({
      id: 'loading'
    })
  }

  return (
    <Modal
      id={ id }
      open={ open }
      disableEsc
      title={ <div style={ { marginTop: '10px', display: 'flex', flexDirection: 'column' } }>
        {step >= 4 && (
          <div style={ { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flex: 1, fontSize: 12, lineHeight: 1 } }>
            <Icon icon={ nfeLogo } size="16vh" style={ { marginRight: 25 } }/>
            <div style={ { display: 'flex', flexDirection: 'column' } }>
              <b style={ { marginBottom: 10 } }><span style={ { fontSize: 18 } }>✔</span> Usuário habilitado para uso do SmartCoop NF-e.</b>
              <span style={ { marginBottom: 5 } }>Baixe o aplicativo SmartCoop NF-e nas lojas da Play Store ou Apple Store.</span>
              <span>Verifique em seu e-mail as instruções de acesso ao aplicativo SmartCoop NF-e.</span>
            </div>
            <Button
              id="go-back"
              onClick={ () => setStep(2) }
              variant="outlined"
              style={ { marginLeft: 30, marginRight: 30 } }
            >
              <Icon icon={ userEdit } size={ 18 } style={ { marginRight: '5px' } }/>
              {t('update my data')}
            </Button>
          </div>
        )}
        <Stepper step={ step === 4 ? 1 : step === 5 ? 2 : step } steps={ step < 4 ? 3 : 2 } />
        <div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
          <Title style={ { marginRight: '5px' } }>
            {t('nfe integrator')}
          </Title>
          { step === 2 &&
          `- ${ t('user register data') }`
          }
          { step === 3 &&
          `- ${ t('state registration') }`
          }
          { step === 4 &&
          `- ${ t('recommended products') }`
          }
          { step === 5 &&
          `- ${ t('customers') }`
          }
        </div>
        {(step === 4 || step === 5) && (
          <InputSearch
            style={ { alignSelf: 'flex-end' } }
            detached
            name="select-search-input"
            label={ t('search') }
            value={ inputSearch }
            onChange={ ({ target: { value } }) => setInputSearch(value) }
          />
        )}
      </div> }
      disableFullScreen
      maxWidth='md'
      fullWidth
    >
      <NfeIntegratorForm
        ref={ NfeIntegratorFormRef }
        handleClose={ handleClose }
        onSuccess={ onSuccess }
        setStep={ setStep }
        step={ step }
        inputSearch={ inputSearch }
        setInputSearch={ setInputSearch }
      />
    </Modal>
  )
}

NfeIntegratorModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}

NfeIntegratorModal.defaultProps = {
}

export default NfeIntegratorModal
