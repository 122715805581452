import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { colors } from '@smartcoop/styles'


export default makeStyles({
  scroller: {
    display: 'flex'
  }
})

export const TabsGroup = styled(Tabs)`
  min-height: 0;
  color: ${ colors.white };
`

export const TabItem = styled(Tab)`
  min-width: 0;
  min-height: 0;

  pointer-events: inherit !important;
`

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const CloudPercentContainer = styled.div`
  display: flex;
`

export const DateText = styled.span`
  font-size: 0.7rem;
`

export const WeatherPercentText = styled.span`
  font-size: 0.6rem;
  font-weight: bold;
`
