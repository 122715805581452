import styled from 'styled-components'

import Button from '@smartcoop/web-components/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 32px 30px 23px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap-reverse;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 3;
  margin-bottom: 22px;
`

export const TabButton = styled(Button)`
  flex: none;
  white-space: nowrap;
  margin-right: 12px;
`

export const OperationHeader = styled.div`
  display: flex;
  flex-direction: row;
`

export const UpperRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 30px;
  align-items: center;
`
