import React from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { lastPage } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import IconButton from '../IconButton'

const LastPageIconButton = ({ iconColor, ...props }) => {
  const t = useT()
  return (
    <IconButton tooltip={ t('datagrid pagination last tooltip') } { ...props }>
      <Icon size={ 14 } icon={ lastPage } color={ iconColor } />
    </IconButton>
  )
}

LastPageIconButton.propTypes = {
  iconColor: PropTypes.string
}

LastPageIconButton.defaultProps = {
  iconColor: colors.mutedText
}

export default LastPageIconButton
