import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import Typography from '@material-ui/core/Typography'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { organizationRounded, plus } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { OrderActions } from '@smartcoop/stores/order'
// import { OrganizationActions } from '@smartcoop/stores/organization'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import { formatCpfCnpj } from '@smartcoop/utils/formatters'
import CooperativeDocument from '@smartcoop/web-components/CooperativeDocument'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Icon from '@smartcoop/web-components/Icon'
import InputFile from '@smartcoop/web-components/InputFile'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'

import useStyles, {
  Content,
  Identifier,
  Text,
  CNPJ,
  Row,
  GridFile,
  GroupLocation
} from './styles'

const AttachBankSlipModal = (props) => {
  const {
    id,
    open,
    handleLoad,
    purchase: { organization, deliveryLocations }
  } = props

  const classes = useStyles()

  const dispatch = useCallback(useDispatch(), [])
  const [locations, setLocations] = useState(deliveryLocations)
  const [isLoading, setIsLoading] = useState(false)
  const currentOrganization = useSelector(selectCurrentOrganization)
  const snackbar = useSnackbar()
  const t = useT()
  const { createDialog } = useDialog()

  const addFileInLocation = useCallback(
    (file, location) => {
      location.bankSlip.push(file)
      const newLocations = [...locations]
      const index = findIndex(
        newLocations,
        (filteredLocation) => filteredLocation.id === location.id
      )
      newLocations.splice(index, 1, location)
      setLocations(newLocations)
    },
    [locations]
  )

  const handleChangeFile = useCallback(
    async (event, location) => {
      const { files } = event.target
      const file = files[0]
      const formData = new FormData()
      formData.append('upload', file)

      setIsLoading(true)
      dispatch(
        OrderActions.attachBankSlip(
          formData,
          location.id,
          currentOrganization.id,
          (registeredFile) => {
            setIsLoading(false)
            addFileInLocation(registeredFile, location)
            snackbar.success(
              t('your {this} was registered', {
                howMany: 1,
                gender: 'male',
                this: t('doc', { howMany: 1 })
              })
            )
          },
          () => {
            setIsLoading(false)
          }
        )
      )
    },
    [addFileInLocation, currentOrganization.id, dispatch, snackbar, t]
  )

  const deleteFileInLocation = useCallback(
    (file, location) => {
      const newLocation = { ...location }
      newLocation.bankSlip = filter(
        location.bankSlip,
        (bankSlip) => bankSlip.id !== file.id
      )

      const newLocations = [...locations]
      const index = findIndex(
        newLocations,
        (filteredLocation) => filteredLocation.id === newLocation.id
      )
      newLocations.splice(index, 1, newLocation)
      setLocations(newLocations)
    },
    [locations]
  )

  const handleDeleteDocument = useCallback(
    (location, file) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            setIsLoading(true)
            dispatch(
              OrderActions.deleteBankSlip(
                file.id,
                () => {
                  setIsLoading(false)
                  deleteFileInLocation(file, location)
                  snackbar.success(
                    t('your {this} was deleted', {
                      howMany: 1,
                      gender: 'male',
                      this: t('doc', { howMany: 1 })
                    })
                  )
                },
                () => {
                  setIsLoading(false)
                }
              )
            )
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('archive', { howMany: 1 })
          })
        }
      })
    },
    [createDialog, deleteFileInLocation, dispatch, snackbar, t]
  )

  // useEffect(() => {
  //   dispatch(OrganizationActions.loadUserOrganizationsByModule())
  // }, [dispatch])

  return (
    <Modal
      id={ id }
      open={ open }
      onClose={ handleLoad }
      title={
        <>
          <I18n>attach documents</I18n>
          <Identifier>
            <Icon icon={ organizationRounded } color={ colors.green } size={ 25 } />
            <Typography
              variant="body1"
              component="h2"
              align="left"
              style={ {
                paddingLeft: 10,
                paddingRight: 30,
                fontWeight: fonts.fontWeight.bold
              } }
            >
              {organization.companyName}
            </Typography>
            <Text>
              <I18n>company document</I18n>{' '}
              {formatCpfCnpj(organization.companyDocument)}
            </Text>
          </Identifier>
        </>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      {isLoading ? (
        <Loader width={ 100 } />
      ) : (
        <>
          <Content>
            {map(locations, (location) => (
              <GroupLocation key={ location.id }>
                <Row>
                  <CNPJ>
                    <I18n>delivery location</I18n>:{' '}
                    {formatCpfCnpj(location.organization.companyDocument)}
                  </CNPJ>
                  <InputFile
                    idInput={ `contained-button-file-${ location.id }` }
                    onChange={ (event) => handleChangeFile(event, location) }
                    inputProps={ {
                      accept: 'image/png,image/jpeg,image/tiff,application/pdf'
                    } }
                    buttonProps={ {
                      id: `add-docs-${ location.id }`,
                      variant: 'outlined'
                    } }
                  >
                    <>
                      <Icon icon={ plus } size={ 16 } style={ { marginRight: 5 } } />
                      <I18n>add documents</I18n>
                    </>
                  </InputFile>
                </Row>
                {isEmpty(location.bankSlip) ? (
                  <Row>
                    <EmptyState text={ t('no file') } />
                  </Row>
                ) : (
                  <GridFile>
                    {map(location.bankSlip, (file) => (
                      <CooperativeDocument
                        key={ file.id }
                        documentName={ file.fileName }
                        deleteDocument={ () =>
                          handleDeleteDocument(location, file)
                        }
                        documentSrc={ URL.createObjectURL(file) }
                      />
                    ))}
                  </GridFile>
                )}
              </GroupLocation>
            ))}
          </Content>
        </>
      )}
    </Modal>
  )
}

AttachBankSlipModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  purchase: PropTypes.object.isRequired,
  handleLoad: PropTypes.func
}

AttachBankSlipModal.defaultProps = {
  handleLoad: () => {}
}

export default AttachBankSlipModal
