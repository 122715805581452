import React from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import { emptyFilter, plus } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'

import AbsenceModal from '../../../modals/AbsenceModal/AbsenceModal'
import ChatAbsenceItemFragment from './ChatAbsenceItemFragment'
import {
  ChatAbsenceContainer,
  ChatAbsenceContent,
  ChatAbsenceHeader,
  ChatAbsenceHeaderTitle
} from './styles'

export default function ChatAbsenceFragment({
  absences
}) {
  const { createDialog } = useDialog()

  const handleOpenAbsenceModal = (currentData?) => {
    createDialog({
      id: 'absence-modal',
      Component: AbsenceModal,
      props: {
        currentData: currentData || {},
        isEdit: Boolean(currentData)
      }
    })
  }

  const formatTime = (time) => moment(new Date(time))
    .format('YYYY-MM-DD HH:mm')

  const handleOpenEditModal = ({
    startTime,
    endTime,
    ...data
  }) => {
    const [startDate, startHour] = formatTime(startTime).split(' ')
    const [endDate, endHour] = formatTime(endTime).split(' ')
    const newData = {
      startDate,
      startHour,
      endDate,
      endHour,
      ...data
    }
    handleOpenAbsenceModal(newData)
  }

  return (
    <ChatAbsenceContainer>
      <ChatAbsenceHeader>
        <ChatAbsenceHeaderTitle>
          Ausência
        </ChatAbsenceHeaderTitle>
        <IconButton onClick={ () => handleOpenAbsenceModal() }>
          <Icon
            icon={ plus }
            size={ 20 }
            color={ colors.text }
          />
        </IconButton>
      </ChatAbsenceHeader>
      <ChatAbsenceContent>
        {absences?.length ? (
          absences.map((absence) => (
            <ChatAbsenceItemFragment
              absence={ absence }
              handleOpenEditModal={ handleOpenEditModal }
              key={ absence.id }
            />
          ))
        ) : (
          <EmptyState
            text="Nenhuma ausência cadastrada"
            icon={ emptyFilter }
            iconProps={ {
              size: 120,
              style: { marginRight: 40 }
            } }
          />
        )}
      </ChatAbsenceContent>
    </ChatAbsenceContainer>
  )
}

ChatAbsenceFragment.propTypes = {
  absences: PropTypes.array
}

ChatAbsenceFragment.defaultProps = {
  absences: []
}
