import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  animalProtocols: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadAnimalProtocols: ['params', 'onSuccess', 'onError'],
  loadAnimalProtocolsSuccess: ['params', 'page'],

  saveAnimalProtocols: ['params', 'onSuccess', 'onError', 'isEdit', 'isConnected'],
  saveOfflineAnimalProtocols: ['params', 'onSuccess', 'onError', 'web'],
  deleteAnimalProtocols: ['id', 'onSuccess', 'onError'],
  deleteOfflineAnimalProtocols: ['id', 'onSuccess', 'onError'],

  animalProtocolsError: ['error']
})

markActionsOffline(Creators, [
  'saveAnimalProtocols',
  'deleteAnimalProtocols'
])

/**
 * Reducers functions
 */

const loadAnimalProtocolsSuccess = (state = INITIAL_STATE, { params, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  animalProtocols: page === 1 ? params : [
    ...state.animalProtocols,
    ...params
  ]
})

const animalProtocolsError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */

export default createReducer(INITIAL_STATE, {
  [Types.LOAD_ANIMAL_PROTOCOLS_SUCCESS]: loadAnimalProtocolsSuccess,

  [Types.ANIMAL_PROTOCOLS_ERROR]: animalProtocolsError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as AnimalProtocolsTypes,
  Creators as AnimalProtocolsActions
}
