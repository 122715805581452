import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { call, put, select, takeLatest } from 'redux-saga/effects'

import { getUserProductsBalanceWithQuotation as getUserProductsBalanceWithQuotationService } from '@smartcoop/services/apis/smartcoopApi/resources/productBalance'
import {
  getSalesOrdersByOrg as getSalesOrdersByOrgService,
  createSalesOrder as createSalesOrderService,
  createFutureOrder as createFutureOrderService,
  createScheduledOrder as createScheduledOrderService,
  deleteSaleOrder as deleteSaleOrderService,
  validationSignatureEletronic as validationSignatureOrderService,
  getProductBatchByOrg as getProductBatchByOrgService,
  getSettlementDateByOrg as getSettlementDateByOrgService
} from '@smartcoop/services/apis/smartcoopApi/resources/salesOrders'

import { selectCurrentOrganization } from '../organization/selectorOrganization'
import { SalesOrdersActions, SalesOrdersTypes } from './duckSalesOrders'

function* saveOfflineSalesOrder({ order, onSuccess = () => {}, onError = () => {} }) {
  yield put(SalesOrdersActions.saveSalesOrder(order, onSuccess, onError))
}

function* saveSalesOrder({ order, onSuccess = () => {}, onError = () => {} }) {
  const currentOrganization = yield select(selectCurrentOrganization)
  let createService = null
  let salesType = 'À Vista'

  switch (order.type) {
    case 'future_sale':
      createService = createFutureOrderService
      salesType = 'Futura'
      break
    case 'trigger':
      createService = createScheduledOrderService
      salesType = 'Gatilho'
      break
    case 'in_cash':
    default:
      createService = createSalesOrderService
      salesType = 'À Vista'
      break
  }

  const { type, ...body } = order

  try {
    yield call(
      createService,
      {
        ...body,
        salesType,
        organizationId: currentOrganization.id
      },
      { organizationId: currentOrganization.id }
    )

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(SalesOrdersActions.salesOrdersError(error))
    yield call(onError)
  }
}

function* loadSalesOrders({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)

    const { data: { data: products, ...pagination } } = yield call(
      getSalesOrdersByOrgService,
      {
        ...params
      },
      { organizationId: currentOrganization.id })

    yield put(SalesOrdersActions.loadSalesOrdersSuccess(
      products,
      pagination.page,
      () => onSuccess(pagination)
    ))
  } catch (error) {
    yield put(SalesOrdersActions.salesOrdersError(error))
    yield call(onError, error)
  }
}


function* loadSalesOrdersSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* loadSalesOrdersProducts({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)

    const { data: { data: products, ...pagination } } = yield call(getUserProductsBalanceWithQuotationService, {
      limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
      ...params
    }, { organizationId: currentOrganization.id })

    yield put(SalesOrdersActions.loadSalesOrdersProductsSuccess(
      products,
      () => onSuccess(pagination)
    ))
  } catch (error) {
    yield put(SalesOrdersActions.salesOrdersError(error))
    yield call(onError, error)
  }
}

function* loadSalesOrdersProductsSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* loadSalesOrdersBatches({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)

    const { data: { data: batches, ...pagination } } = yield call(getProductBatchByOrgService, {
      limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
      ...params
    }, { organizationId: currentOrganization.id })

    yield put(SalesOrdersActions.loadSalesOrdersBatchesSuccess(
      batches,
      () => onSuccess(pagination)
    ))
  } catch (error) {
    yield put(SalesOrdersActions.salesOrdersError(error))
    yield call(onError, error)
  }
}

function* loadSalesOrdersBatchesSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* loadSalesOrdersSettlementDates({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)

    const { data: { data: settlementDates, ...pagination } } = yield call(getSettlementDateByOrgService, {
      limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
      ...params
    }, { organizationId: currentOrganization.id })

    yield put(SalesOrdersActions.loadSalesOrdersSettlementDatesSuccess(
      settlementDates,
      () => onSuccess(pagination)
    ))
  } catch (error) {
    yield put(SalesOrdersActions.salesOrdersError(error))
    yield call(onError, error)
  }
}

function* loadSalesOrdersSettlementDatesSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* validationSignatureEletronic({ password, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)
    yield call(
      validationSignatureOrderService,
      {
        signature: password
      },
      { organizationId: currentOrganization.id }
    )

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(SalesOrdersActions.salesOrdersError(error))
    yield call(onError)
  }
}

function* deleteOfflineSalesOrders({ saleOrderId, onSuccess = () => {}, onError = () => {} }) {
  yield put(SalesOrdersActions.deleteSalesOrders(saleOrderId, onSuccess, onError))
}

function* deleteSalesOrders({ saleOrderId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)

    yield call(deleteSaleOrderService, {}, { saleOrderId, organizationId: currentOrganization.id })

    yield call(onSuccess)
    yield put(SalesOrdersActions.loadSalesOrders())
  } catch (error) {
    yield put(SalesOrdersActions.salesOrdersError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(SalesOrdersTypes.SAVE_OFFLINE_SALES_ORDER, saveOfflineSalesOrder),
  takeLatest(SalesOrdersTypes.SAVE_SALES_ORDER, saveSalesOrder),

  takeLatest(SalesOrdersTypes.LOAD_SALES_ORDERS, loadSalesOrders),
  takeLatest(SalesOrdersTypes.LOAD_SALES_ORDERS_SUCCESS, loadSalesOrdersSuccess),

  takeLatest(SalesOrdersTypes.LOAD_SALES_ORDERS_PRODUCTS, loadSalesOrdersProducts),
  takeLatest(SalesOrdersTypes.LOAD_SALES_ORDERS_PRODUCTS_SUCCESS, loadSalesOrdersProductsSuccess),

  takeLatest(SalesOrdersTypes.LOAD_SALES_ORDERS_BATCHES, loadSalesOrdersBatches),
  takeLatest(SalesOrdersTypes.LOAD_SALES_ORDERS_BATCHES_SUCCESS, loadSalesOrdersBatchesSuccess),

  takeLatest(SalesOrdersTypes.LOAD_SALES_ORDERS_SETTLEMENT_DATES, loadSalesOrdersSettlementDates),
  takeLatest(SalesOrdersTypes.LOAD_SALES_ORDERS_SETTLEMENT_DATES_SUCCESS, loadSalesOrdersSettlementDatesSuccess),

  takeLatest(SalesOrdersTypes.DELETE_OFFLINE_SALES_ORDERS, deleteOfflineSalesOrders),
  takeLatest(SalesOrdersTypes.DELETE_SALES_ORDERS, deleteSalesOrders),

  takeLatest(SalesOrdersTypes.VALIDATION_SIGNATURE_ELETRONIC, validationSignatureEletronic)
]
