import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import cpfCnpj from '@smartcoop/forms/validators/cpfCnpj.validator'
import password from '@smartcoop/forms/validators/password.validator'
import required from '@smartcoop/forms/validators/required.validator'

const loginSchema = ({ t }) => Yup.object().shape({
  document: flow(
    cpfCnpj({ t }),
    required({ t })
  )(Yup.string()),
  password: flow(
    password({ t }),
    required({ t })
  )(Yup.string())
})

export default loginSchema
