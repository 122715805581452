import React, { useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'

import signatureOrder from '@smartcoop/forms/schemas/commercialization/signatureOrder.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { colors } from '@smartcoop/styles'
import Form from '@smartcoop/web-components/Form'
import InputPassword from '@smartcoop/web-components/InputPassword'
import useTerm from '@smartcoop/web-containers/hooks/useTerm'

import { Container, TextDate, TextPassword } from './styles'

const SignatureOrderForm = forwardRef((props, formRef) => {
  const { style, onSubmit } = props
  const [termModal] = useTerm('use-term', false)
  const t = useT()

  const handleSubmitSignatureElectronic = useCallback(
    (data) => {
      onSubmit(data)
    },
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { ...style } }
        ref={ formRef }
        schemaConstructor={ signatureOrder }
        onSubmit={ handleSubmitSignatureElectronic }
      >
        <TextDate>
          <I18n>by completing the transaction via electronic signature, you are agreeing to the terms of use of the sales order</I18n>
        </TextDate>
        <TextDate >
          <I18n
            onClick={ () => {termModal(() => {}, true)} }
            style={ {
              textDecoration: 'underline', cursor: 'pointer', color: colors.info
            } }
          >
            terms of use
          </I18n>
        </TextDate>

        <TextPassword>
          <I18n>insert your electronic signature here</I18n>
        </TextPassword>
        <InputPassword
          name="signatureOrder"
          label={ t('electronic signature') }
          fullWidth
        />
      </Form>
    </Container>
  )
})

SignatureOrderForm.propTypes = {
  style: PropTypes.object,
  onSubmit: PropTypes.func
}

SignatureOrderForm.defaultProps = {
  style: {},
  onSubmit: () => {}
}

export default SignatureOrderForm
