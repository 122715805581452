import React, { forwardRef, useMemo, useState, useCallback, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import round from 'lodash/round'
import toNumber from 'lodash/toNumber'

import seedingCropManagementSchema from '@smartcoop/forms/schemas/management/seedingCropManagement.schema'
import { useT } from '@smartcoop/i18n'
import { getProducts as getProductsService } from '@smartcoop/services/apis/smartcoopApi/resources/product'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { ProductGroupActions } from '@smartcoop/stores/productGroup/duckProductGroup'
import { selectProductGroups, selectLoadingProductGroups } from '@smartcoop/stores/productGroup/selectorProductGroup'
import { selectFamilyGroupAccess, selectFamilyGroupFinancialDataAccess } from '@smartcoop/stores/property/selectorProperty'
import { selectCurrentOwnerExtraAttributes, selectTechnicalFinancialDataAccess } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUserExtraAttributes } from '@smartcoop/stores/user/selectorUser'
import CardAccordion from '@smartcoop/web-components/CardAccordion'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputUnit from '@smartcoop/web-components/InputUnit'
import Loader from '@smartcoop/web-components/Loader'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import FertilizationForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/FertilizationForm'
import SeedTreatmentForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/SeedTreatmentForm'

const SeedingForm = forwardRef(({ handleSubmit, defaultValues, growingSeason, readOnly, area }, formRef) => {
  const [productGroupSlug, setProductGroupSlug] = useState(defaultValues?.productGroupSeeding)
  const [productId, setProductId] = useState(defaultValues?.productId)
  const [value, setValue] = useState(round(defaultValues?.value/area, 2))
  const [fertilizationData, setFertilizationData] = useState(null)
  const [seedTreatmentData, setSeedTreatmentData] = useState(null)
  const [fertilizationSent, setFertilizationSent] = useState(false)
  const [seedTreatmentSent, setSeedTreatmentSent] = useState(false)
  const [seedingData, setSeedingData] = useState(null)

  const fertilizationRef = useRef()
  const seedTreatmentRef = useRef()

  const t = useT()
  const dispatch = useDispatch()

  const productGroups = useSelector(selectProductGroups)
  const loading = useSelector(selectLoadingProductGroups)
  const userExtraAttributes = useSelector(selectUserExtraAttributes)
  const currentOwnerExtraAttributes = useSelector(selectCurrentOwnerExtraAttributes)

  const technicalFinancialDataAccess = useSelector(selectTechnicalFinancialDataAccess)
  const familyGroupFinancialDataAccess = useSelector(selectFamilyGroupFinancialDataAccess)

  const familyGroupAccess = useSelector(selectFamilyGroupAccess)
  const isTechnical = useSelector(selectModuleIsTechnical)

  const extraAttributes = useMemo(() => userExtraAttributes || {}, [userExtraAttributes])

  // eslint-disable-next-line no-nested-ternary
  const shouldDisplayCosts = useMemo(() => familyGroupAccess ? familyGroupFinancialDataAccess : isTechnical ? technicalFinancialDataAccess && currentOwnerExtraAttributes?.enabledCosts : extraAttributes?.enabledCosts,[currentOwnerExtraAttributes, extraAttributes, familyGroupAccess, familyGroupFinancialDataAccess, isTechnical, technicalFinancialDataAccess])

  useEffect(() => {
    dispatch(ProductGroupActions.loadProductGroups({ cropId: growingSeason?.cropId }))
  },[dispatch, growingSeason])

  const productGroupOptions = useMemo(
    () => orderBy(map(productGroups, item => ({
      label: item.name,
      value: item.id
    })), [option => option.label.toLowerCase()], ['asc']),
    [productGroups]
  )

  const selectOptions = useMemo(
    () => (
      [
        {
          label: t('yes'),
          value: 'Sim'
        },
        {
          label: t('no'),
          value: 'Não'
        }
      ]
    ),
    [t]
  )


  const handleProductGroup = useCallback(
    (selectedItem) => {
      setProductGroupSlug(selectedItem.target.value)
    },
    []
  )

  useEffect(() => {
    if(!isEmpty(productGroupOptions)) {
      setProductGroupSlug(productGroupOptions[0].value)
    }
  }, [productGroupOptions])

  const productQueryParams = useMemo(
    () => (!isEmpty(productGroupSlug) ? { productGroupId: productGroupSlug, cultivate: true, digitalization: true  } : { digitalization: true }),
    [productGroupSlug]
  )

  const onSubmit = useCallback(async (data) => {
    if(!defaultValues?.cultivate) {
      fertilizationRef.current.submit()
      seedTreatmentRef.current.submit()
    }

    const referenceId = uuidv4()
    let updatedData = {
      ...data,
      productId,
      referenceId
    }
    if(shouldDisplayCosts) {
      updatedData = {
        ...updatedData,
        value: value ?  round(value*area, 2) : null,
        valueHA: value ? toNumber(value) : null
      }
    }
    setSeedingData(updatedData)
  }, [area, defaultValues, productId, shouldDisplayCosts, value])

  useEffect(() => {
    const debouncedSubmit = debounce(() => {
      const objectToSubmit = { 'semeadura': seedingData }
      if(fertilizationData?.product || fertilizationData?.formFields?.[0]?.productId) {
        objectToSubmit.fertilizacao = fertilizationData
      }
      if(seedTreatmentData?.formFields?.[0]?.productGroup) {
        objectToSubmit.['tratamento-de-sementes'] = seedTreatmentData
      }
      handleSubmit(objectToSubmit)
    }, 1200)

    if(defaultValues?.cultivate) {
      handleSubmit(seedingData)
    } else if(seedingData && fertilizationSent && seedTreatmentSent) {
      debouncedSubmit()
    }
  },[defaultValues, fertilizationData, fertilizationSent, handleSubmit, seedTreatmentData, seedTreatmentSent, seedingData])

  return loading ? <Loader />: (
    <Form
      ref={ formRef }
      schemaConstructor={ seedingCropManagementSchema }
      containerStyle={ { height: 'auto' } }
      onSubmit={ onSubmit }
    >
      <InputSelect
        label={ t('productGroup') }
        name="productGroupSeeding"
        options={ productGroupOptions }
        queryParams={ { cropId: growingSeason?.cropId } }
        onChange={ handleProductGroup }
        asyncOptionLabelField="name"
        defaultValue={ defaultValues?.productGroupSeeding || productGroupOptions[0]?.value || '' }
        disabled={ readOnly }
      />

      <InputSelect
        label={ t('cultivate') }
        name="cultivate"
        options={ getProductsService }
        queryParams={ productQueryParams }
        asyncOptionLabelField="shortDescription"
        asyncOptionValueField="shortDescription"
        disabled={ !productGroupSlug || readOnly }
        defaultValue={ defaultValues?.cultivate || '' }
        disabledWhenLoading
        onChange={ (_, item) => setProductId(item?.id) }
      />

      <InputUnit
        label={ t('number of plants per linear meter') }
        name="numberOfPlants"
        type="float"
        fullWidth
        defaultValue={ defaultValues?.numberOfPlants }
        disabled={ readOnly }
      />

      <InputUnit
        name="lineSpacing"
        label={ t('line spacing') }
        maxLength={ 2 }
        unit="cm"
        type="integer"
        fullWidth
        defaultValue={ defaultValues?.lineSpacing }
        disabled={ readOnly }
      />

      <RadioGroup
        style={ { marginBottom: 10 } }
        label={ t('replanting?') }
        name="replanting"
        options={ selectOptions }
        defaultValue={ defaultValues?.replanting ?? 'Não' }
        variant="row"
        disabled={ readOnly }
      />

      <InputUnit
        name="germination"
        label={ t('germination') }
        maxLength={ 3 }
        max={ 100 }
        unit="%"
        type="integer"
        fullWidth
        disabled={ readOnly }
        defaultValue={ defaultValues?.germination }
      />

      <InputUnit
        name="vigor"
        label={ t('vigor') }
        maxLength={ 3 }
        max={ 100 }
        unit="%"
        type="integer"
        fullWidth
        disabled={ readOnly }
        defaultValue={ defaultValues?.vigor }
      />

      {shouldDisplayCosts ? (
        <div style={ { display: 'flex', flexDirection: 'row' } }>
          <InputUnit
            detached
            label={ t('cost by ha') }
            unit="R$/ha"
            type="float"
            fullWidth
            onChange={ ({ target: { value: response } }) => setValue(response) }
            value={ value }
          />
          <InputUnit
            detached
            disabled
            label={ t('total cost') }
            unit="R$"
            type="float"
            fullWidth
            value={ round(value*area, 2) }
          />
        </div>
      ) : null}

      {defaultValues?.cultivate ? null : (
        <CardAccordion defaultOpened headerLeft={ t('fertilization') } title=" " style={ { marginBottom: 10 } }>
          <div style={ { padding: 10 } }>
            <FertilizationForm
              fromSeeding
              ref={ fertilizationRef }
              handleSubmit={ (data) => {
                setFertilizationData(data)
                setFertilizationSent(true)
              } }
              growingSeason={ growingSeason }
              readOnly={ readOnly }
              area={ area }
              defaultValues={ {} }
            />
          </div>
        </CardAccordion>
      )}
      {defaultValues?.cultivate ? null : (
        <CardAccordion defaultOpened headerLeft={ t('seed treatment') } title=" " style={ { marginBottom: 10 } }>
          <div style={ { padding: 10 } }>
            <SeedTreatmentForm
              fromSeeding
              ref={ seedTreatmentRef }
              handleSubmit={ (data) => {
                setSeedTreatmentData(data)
                setSeedTreatmentSent(true)
              } }
              growingSeason={ growingSeason }
              readOnly={ readOnly }
              area={ area }
              defaultValues={ {} }
            />
          </div>
        </CardAccordion>
      )}
    </Form>
  )
})

SeedingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  area: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  defaultValues: PropTypes.object,
  growingSeason: PropTypes.object
}

SeedingForm.defaultProps = {
  readOnly: false,
  defaultValues: {},
  growingSeason: {}
}

export default SeedingForm
