import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const WhiteContainer = styled(Container)`
  background: white;
  border-radius: 12px;
  width: 100%;
  padding: 30px;
  max-height: calc(100vh - 120px);
  overflow: auto;
`

export const FirstContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 120px);
  margin: 50px;
  margin-bottom: 0;
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const ImageContainer = styled(InfoContainer)`
  margin-top: 0;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 18px;
  margin-right: 20px;
`

export const Subtitle = styled(Title)`
  font-size: 20px;
  margin-top: 20px;
`

export const Text = styled.span`
  font-size: 16px;
  margin-right: 50px;
`
export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 10px;
`

export const TextInfo = styled(Text)`
  font-weight: bold;
`

export const DistanceText = styled(Title)`
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  color: ${ colors.green };
`

export const Half = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const MidContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`

export const TitleScreen = styled.div`
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const EmptyImage = styled.div`
  border-radius: 8px;
  background-color: ${ colors.lightGrey };
  max-width: 400px;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  color: ${ colors.mediumGrey };
`
export const StatusContainer = styled.div`
  align-self: flex-end;
`

export const EmptyImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
  margin-left: 20px;
`
