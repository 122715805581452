import api from '../api'

export const registerProductPriceHistory = (params, { productId, organizationId }) => api.post(`/v1/products/${ productId }/organization/${ organizationId }/history`, params)

export const getProductsPriceHistoryByOrganization = (params, { productId, organizationId }) => api.get(`/v1/products/${ productId }/organization/${ organizationId }/history`, { params })

export const getProductsPriceHistory = (params, { productId }) => api.get(`/v1/products/${ productId }/history`, { params })

export const deleteProductsPriceHistory = ({ productId, organizationId, historyId }) => api.delete(`/v1/products/${ productId }/organization/${ organizationId }/history/${ historyId }`)




