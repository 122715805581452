import styled from 'styled-components'

export const ContainerField = styled.div`
  display: flex;
  height: 50px;
  overflow: visible;
`
export const ItemBorderLeft = styled.div`
  width: 1px;
  background: #000000;
  height: ${ ({ isLast }) => isLast ? '50%' : '200%' };
  overflow: visible;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  overflow: visible;
  align-items: center;
`

export const TextGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const TextLeft = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TextRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

export const TextBold = styled.span`
  margin: 0;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Text = styled.span`
  margin: 0;
`

export const CropTextGroup = styled.span`
  display: flex;
`

export const CropText = styled.span`
  margin: 2px 2px 0 0;
`

export const TemperatureText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Line = styled.div`
  width: 50px;
  height: 1px;
  background: #000;
  align-self: center;
  margin-right: 10px;
`
