import React, { useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import absence from '@smartcoop/forms/schemas/profile/absence.schema'
import { AbsenceActions } from '@smartcoop/stores/absence'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputHour from '@smartcoop/web-components/InputHour'
import InputText from '@smartcoop/web-components/InputText'
import Modal from '@smartcoop/web-components/Modal'

import { PeriodFormRow, Title } from './styles'

export default function AbsenceModal({
  id,
  open,
  handleClose,
  currentData,
  isEdit
}) {
  const dispatch = useDispatch()
  const formRef = useRef()

  const handleSubmit = useCallback(({
    startDate,
    startHour,
    endDate,
    endHour,
    ...data
  }) => {
    const newData = {
      startTime: `${ startDate } ${ startHour }:00.000 -0300`,
      endTime: `${ endDate } ${ endHour }:00.000 -0300`,
      ...data
    }
    if (isEdit) {
      dispatch(AbsenceActions.editAbsence(currentData.id, newData, handleClose))
    } else {
      dispatch(AbsenceActions.createAbsence(newData, handleClose))
    }

  }, [currentData, dispatch, handleClose, isEdit])

  return (
    <Modal
      id={ id }
      open={ open }
      disableFullScreen
      title={
        <Title>{ isEdit ? 'Editar Ausência' : 'Nova Ausência'}</Title>
      }
      FooterComponent={ () => (
        <>
          <Button
            id="back"
            onClick={ handleClose }
            variant="outlined"
          >
            <span>Voltar</span>
          </Button>
          <Button
            id="submit"
            onClick={ () => formRef.current.submit() }
          >
            <span>{ isEdit ? 'Editar' : 'Cadastrar' }</span>
          </Button>
        </>
      ) }
    >
      <Form
        ref={ formRef }
        schemaConstructor={ absence }
        onSubmit={ handleSubmit }
      >
        <PeriodFormRow>
          <InputDate
            label="Data inicial"
            placeholder=""
            name="startDate"
            fullWidth
            defaultValue={ currentData.startDate }
          />
          <InputHour
            label="Horário"
            placeholder=""
            name="startHour"
            fullWidth
            defaultValue={ currentData.startHour }
          />
        </PeriodFormRow>
        <PeriodFormRow>
          <InputDate
            label="Data final"
            placeholder=""
            name="endDate"
            fullWidth
            defaultValue={ currentData.endDate }
          />
          <InputHour
            label="Horário"
            placeholder=""
            name="endHour"
            fullWidth
            defaultValue={ currentData.endHour }
          />
        </PeriodFormRow>
        <InputText
          label="Nome"
          placeholder=""
          name="name"
          fullWidth
          defaultValue={ currentData.name }
        />
        <InputText
          label="Mensagem"
          placeholder=""
          name="message"
          fullWidth
          multiline
          rows={ 4 }
          defaultValue={ currentData.message }
        />
      </Form>
    </Modal>
  )
}

AbsenceModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentData: PropTypes.object,
  isEdit: PropTypes.bool
}

AbsenceModal.defaultProps = {
  currentData: {},
  isEdit: false
}