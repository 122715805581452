import React, { useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import registerLotSchema from '@smartcoop/forms/schemas/dairyFarm/registerLot.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { colors } from '@smartcoop/styles'
import { LOT_SLUG_OPTIONS } from '@smartcoop/utils/constants'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import {
  Container,
  FormContainer,
  ButtonsContainer,
  Item
} from './styles'

const RegisterLotForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel
  } = props

  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data
      })
    },
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerLotSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputText
                label={ t('lot name') }
                name="name"
                fullWidth
                defaultValue={ defaultValues.name }
              />
            </Item>
            <Item>
              <InputSelect
                label={ t('lot type') }
                name="slug"
                options={ LOT_SLUG_OPTIONS }
                fullWidth
                defaultValue={ defaultValues.slug }
              />
            </Item>
          </Grid>
        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-cancel-form-button"
              onClick={ onCancel }
              color={ colors.white }
              disabled={ loading }
              style={ { marginRight: 10 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="web-save-form-button"
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
              style={ { marginLeft: 10 } }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

RegisterLotForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  defaultValues: PropTypes.object.isRequired
}

RegisterLotForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false
}

export default RegisterLotForm
