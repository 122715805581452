import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { NfeActions } from '@smartcoop/stores/nfe'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import FiscalRegisterForm from '@smartcoop/web-containers/forms/administration/FiscalRegisterForm'

import useStyles, { Row, Content, ButtonsContainer  } from './styles'

const FiscalRegisterModal = (props) => {
  const classes = useStyles()
  const fiscalRegisterFormRef = useRef(null)
  const dispatch = useDispatch()
  const t = useT()
  const { createDialog } = useDialog()

  const { open, handleClose, name, defaultValues, reloadDataTable } = props

  const currentOrganization = useSelector(selectCurrentOrganization)
  const snackbar = useSnackbar()

  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const handleSubmit = (data) => {
    if(defaultValues?.integrationCount && defaultValues?.integrationCount !== 0) {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(NfeActions.saveFiscalFigure({ organizationId: currentOrganization?.id, ...data }, () => {
              snackbar.success(data?.id ? 'Figura fiscal editada com sucesso!' : 'Figura fiscal criada com sucesso!')
              reloadDataTable()
              handleClose()
            }))
          },
          textWarning: t('your update will affect {this} producers, do you want to continue?', { this: defaultValues?.integrationCount })
        }
      })
    } else {
      dispatch(NfeActions.saveFiscalFigure({ organizationId: currentOrganization?.id, ...data }, () => {
        snackbar.success(data?.id ? 'Figura fiscal editada com sucesso!' : 'Figura fiscal criada com sucesso!')
        reloadDataTable()
        handleClose()
      }))
    }
  }

  return (
    <Modal
      id
      open={ open }
      onClose={ handleClose }
      title={
        <Row>
          <I18n>{name}</I18n>
        </Row>
      }
      disableFullScreen
      disableEsc
      maxWidth='md'
      fullWidth
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <Content>
        <FiscalRegisterForm
          ref={ fiscalRegisterFormRef }
          onSubmit={ handleSubmit }
          defaultValues={ defaultValues }
          setDisabledSubmit={ setDisabledSubmit }
        />
        <ButtonsContainer>
          <Button
            id="submit-fiscal-register-form"
            onClick={ () => fiscalRegisterFormRef.current.submit() }
            style={ { flex: 1 } }
            disabled={ disabledSubmit }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Content>
    </Modal>
  )
}

FiscalRegisterModal.propTypes = {
  name: PropTypes.string.isRequired,
  open: PropTypes.bool,
  defaultValues: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  reloadDataTable: PropTypes.func.isRequired
}

FiscalRegisterModal.defaultProps = {
  open: false,
  defaultValues: null
}

export default FiscalRegisterModal
