// eslint-disable-next-line spaced-comment
export default ({ size } = {}) => /*html*/`
<svg width="${ size }" height="${ size }" viewBox="0 0 152 125" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.9024 44.7116C36.5196 58.6296 22.9805 73.3339 15.0088 78.9463C-0.907784 92.9358 8.05802 117.095 22.491 121.78C37.7644 126.739 51.0784 117.005 57.1385 110.44C73.7112 92.4869 102.25 97.0817 122.244 97.0343C157.897 96.9498 154.356 48.2879 128.106 48.454C112.33 48.5538 104.385 46.835 100.327 36.7358C95.2527 24.1039 83.1766 12.6282 79.7847 10.9252C46.6115 -5.72963 14.8808 27.3141 26.9024 44.7116Z" fill="white"/>
<path d="M23.2958 86.7848C22.7555 87.9803 21.3432 88.5159 20.1482 87.9766C18.9532 87.4374 18.4188 86.0255 18.9592 84.83C19.4995 83.6346 20.9118 83.099 22.1068 83.6382C23.3066 84.1823 23.8361 85.5893 23.2958 86.7848Z" fill="#E0E0E0"/>
<path d="M146.563 66.0636C146.023 67.2591 144.611 67.7947 143.416 67.2554C142.221 66.7162 141.686 65.3043 142.227 64.1088C142.767 62.9134 144.179 62.3778 145.374 62.917C146.574 63.4514 147.109 64.8633 146.563 66.0636Z" fill="#E0E0E0"/>
<path d="M127.54 37.5241C127 38.7195 125.587 39.2551 124.392 38.7159C123.197 38.1766 122.663 36.7648 123.203 35.5693C123.744 34.3738 125.156 33.8382 126.351 34.3775C127.546 34.9167 128.08 36.3286 127.54 37.5241Z" fill="#E0E0E0"/>
<path d="M46.412 21.464C45.8717 22.6594 44.4594 23.1951 43.2644 22.6558C42.0694 22.1166 41.5351 20.7047 42.0754 19.5092C42.6157 18.3137 44.028 17.7781 45.223 18.3174C46.4228 18.8614 46.9524 20.2685 46.412 21.464Z" fill="#E0E0E0"/>
<path d="M32.2274 115.602C31.6871 116.798 30.2748 117.333 29.0798 116.794C27.8848 116.255 27.3505 114.843 27.8908 113.647C28.4311 112.452 29.8434 111.916 31.0384 112.456C32.2334 112.995 32.7677 114.407 32.2274 115.602Z" fill="#E0E0E0"/>
<path d="M76.2636 14.5469C65.0992 14.5469 56.0493 23.5968 56.0493 34.7611C56.0493 43.787 69.2608 60.2375 74.3224 66.2087C75.3475 67.4184 77.1812 67.4184 78.2048 66.2087C83.2664 60.2375 96.4778 43.787 96.4778 34.7611C96.4778 23.5968 87.4279 14.5469 76.2636 14.5469ZM76.2636 41.4992C72.5416 41.4992 69.5255 38.4831 69.5255 34.7611C69.5255 31.0391 72.5416 28.023 76.2636 28.023C79.9856 28.023 83.0017 31.0391 83.0017 34.7611C83.0017 38.4831 79.9856 41.4992 76.2636 41.4992ZM33.2874 49.1919C32.3348 49.5729 31.5181 50.2306 30.9428 51.0802C30.3674 51.9298 30.0598 52.9322 30.0596 53.9583V94.1172C30.0596 95.9333 31.8933 97.175 33.5794 96.5012L55.7285 86.4198V49.0266C54.3103 46.4629 53.1503 43.9666 52.3193 41.5794L33.2874 49.1919ZM76.2636 72.249C74.0063 72.249 71.871 71.2575 70.4063 69.528C67.2522 65.8061 63.8976 61.5675 60.8622 57.2198V86.4182L91.6649 96.6857V57.2214C88.6296 61.5675 85.2766 65.8077 82.1209 69.5296C80.6562 71.2575 78.5208 72.249 76.2636 72.249ZM118.948 40.4019L96.7987 50.4833V96.6873L119.24 87.7112C120.193 87.3304 121.009 86.6727 121.585 85.8231C122.16 84.9735 122.468 83.971 122.468 82.9449V42.7859C122.468 40.9698 120.634 39.7281 118.948 40.4019Z" fill="#1D1D1B"/>
<path d="M119.176 73.2139C108.686 73.2139 100.152 81.7477 100.152 92.2376C100.152 102.728 108.686 111.261 119.176 111.261C129.666 111.261 138.2 102.728 138.2 92.2376C138.2 81.7477 129.666 73.2139 119.176 73.2139Z" fill="#FFC80A"/>
<path d="M120.968 102.585C120.968 103.455 120.261 104.161 119.392 104.161C118.522 104.161 117.815 103.455 117.815 102.585C117.815 101.715 118.522 101.009 119.392 101.009C120.261 101.009 120.968 101.711 120.968 102.585Z" fill="#1D1D1B"/>
<path d="M121.884 94.1327C121.325 94.391 120.963 94.9509 120.963 95.5667V96.2729C120.963 97.1427 120.257 97.849 119.387 97.849C118.517 97.849 117.811 97.1427 117.811 96.2729V95.5667C117.811 93.7236 118.892 92.0398 120.562 91.2647C122.169 90.524 123.331 88.5604 123.331 87.5958C123.331 85.4211 121.561 83.6512 119.387 83.6512C117.212 83.6512 115.442 85.4211 115.442 87.5958C115.442 88.4656 114.736 89.1718 113.866 89.1718C112.992 89.1718 112.29 88.4656 112.29 87.5958C112.29 83.6814 115.477 80.499 119.387 80.499C123.297 80.499 126.483 83.6857 126.483 87.5958C126.488 89.7317 124.636 92.8623 121.884 94.1327Z" fill="#1D1D1B"/>
</svg>

`
