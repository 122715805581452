import { call, put, select, takeLatest } from 'redux-saga/effects'

import { v4 as uuidv4 } from 'uuid'

import filter from 'lodash/filter'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'

import { createCropManagement, deleteCropManagements, editCropManagement } from '@smartcoop/services/apis/smartcoopApi/resources/cropManagement'
import {
  createField,
  deleteField as deleteFieldService,
  editField,
  getField,
  getFieldsByPropertyId,
  uploadFieldShape as uploadFieldShapeService
} from '@smartcoop/services/apis/smartcoopApi/resources/field'
import { createGrowingSeason, createGrowingSeasons, deleteGrowingSeasons, editGrowingSeasons, getGrowingSeasonHistory } from '@smartcoop/services/apis/smartcoopApi/resources/growingSeasons'
import {
  createPestReport as createPestReportService,
  deletePestReport as deletePestReportService,
  editPestReport,
  postFilesPestReport,
  putFilesPestReport
} from '@smartcoop/services/apis/smartcoopApi/resources/pestReport'
import { getPropertyGrowingSeasonsHistory } from '@smartcoop/services/apis/smartcoopApi/resources/property'
import {
  createSoilAnalysis as createSoilAnalysisService,
  uploadSoilAnalysisDocuments as uploadSoilAnalysisDocumentsService,
  editSoilAnalysis as editSoilAnalysisService,
  deleteSoilAnalysis as deleteSoilAnalysisService,
  deleteSoilAnalysisDocuments as deleteSoilAnalysisDocumentsService,
  getSoilAnalysis as getSoilAnalysisService
} from '@smartcoop/services/apis/smartcoopApi/resources/soilAnalysis'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectIsConnected } from '@smartcoop/stores/network/selectorNetwork'
import { OfflineFieldUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineFieldUserData/duckOfflineFieldUserData'
import { selectFields as selectOfflineFields, selectPlannedPropertyFields } from '@smartcoop/stores/offlineData/userData/offlineFieldUserData/selectorOfflineFieldUserData'
import { OfflineTechnicalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData/duckOfflineTechnicalUserData'
import { selectTechnicalPropertyFields } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData/selectorOfflineTechnicalUserData'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'

import { FieldActions, FieldTypes } from './duckField'
import { selectCurrentField, selectCurrentFieldHistory, selectFieldsPlanned, selectFields } from './selectorField'

function* loadFields({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const data = yield call(getFieldsByPropertyId, params, { propertyId: currentProperty?.id })

    yield put(FieldActions.loadFieldsSuccess(
      data,
      onSuccess
    ))
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, error)
  }
}

function* loadFieldsAnalysis({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const data = yield call(getSoilAnalysisService, params, { propertyId: currentProperty?.id })

    yield put(FieldActions.loadFieldsAnalysisSuccess(
      data
    ))

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, error)
  }
}

function* loadCurrentField({ fieldId, isPlanned, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(FieldActions.toggleCurrentFieldLoading(true))
    let id = fieldId
    const currentField = yield select(selectCurrentField)
    if (!fieldId && currentField.id) {
      id = currentField.id
    }
    const data = yield call(getField, { isPlanned }, { fieldId: id })

    if(data?.growingSeason) {
      const growingSeasonDates = yield call(getGrowingSeasonHistory, { growingSeasonId: data?.growingSeason?.id })
      yield put(FieldActions.setCurrentField({
        ...data,
        startDate: growingSeasonDates?.startDate,
        endDate: growingSeasonDates?.endDate
      }))
    } else {
      yield put(FieldActions.setCurrentField({ ...data }))
    }

    yield put(FieldActions.toggleCurrentFieldLoading(false))
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, error)
  }
}

function* loadCurrentFieldHistory({ growingSeasonId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(FieldActions.toggleCurrentFieldLoading(true))
    let id = growingSeasonId
    const currentFieldHistory = yield select(selectCurrentFieldHistory)
    if (!growingSeasonId && currentFieldHistory.id) {
      id = currentFieldHistory.id
    }
    const data = yield call(getGrowingSeasonHistory, { growingSeasonId: id })
    yield put(FieldActions.setCurrentFieldHistory(data))
    yield put(FieldActions.toggleCurrentFieldLoading(false))
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, error)
  }
}

function* loadFieldsSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* loadFieldsHistory({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const { data: {
      data
    } } = yield call(getPropertyGrowingSeasonsHistory, params, { propertyId: currentProperty?.id })

    yield put(FieldActions.loadFieldsHistorySuccess(
      data,
      onSuccess
    ))
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }
}

function* loadFieldsPlanned({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const data = yield call(getFieldsByPropertyId, params, { propertyId: currentProperty?.id })

    yield put(FieldActions.loadFieldsPlannedSuccess(
      data
    ))

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }
}

// eslint-disable-next-line no-unused-vars
function* loadFieldsHistorySuccess({ data, onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* saveOfflineField({ field, onSuccess = () => {}, onError = () => {},  web = false }) {
  try {
    const property = yield select(selectCurrentProperty)
    const moduleIsTechnical = yield select(selectModuleIsTechnical)
    const isConnected = web || (yield select(selectIsConnected))

    const newField = {
      ...field,
      id: field?.id || uuidv4(),
      propertyId: property?.id,
      area: toNumber(field?.area)
    }

    if(moduleIsTechnical) {
      yield put(OfflineTechnicalUserDataActions.updateOfflineTechnicalCurrentField(
        newField,
        property.id
      ))

    } else {
      yield put(OfflineFieldUserDataActions.updateOfflineCurrentField(
        newField
      ))
    }

    yield put(FieldActions.setCurrentField(
      newField
    ))

    if(!isConnected){
      yield call(onSuccess, newField?.id)
    }
    yield put(FieldActions.saveField(newField, onSuccess, onError, !!field?.id, isConnected))
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }

}

function* saveField({ field, onSuccess = () => {}, onError = () => {}, isEdit = false }) {
  try {
    const { data } = yield call(
      isEdit ? editField : createField,
      { ...field, propertyId: field.propertyId }, { fieldId: field.id }
    )

    yield call(onSuccess, data.id)

  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }
}

function* deleteOfflineField({ fieldId, onSuccess = () => {}, onError = () => {}, web = false }) {
  const property = yield select(selectCurrentProperty)
  const moduleIsTechnical = yield select(selectModuleIsTechnical)

  if(!web) {
    if(moduleIsTechnical) {
      yield put(OfflineTechnicalUserDataActions.deleteOfflineTechnicalCurrentField(
        fieldId, property?.id
      ))
    } else {
      yield put(OfflineFieldUserDataActions.deleteOfflineCurrentField(
        fieldId
      ))
      yield put(OfflineFieldUserDataActions.updateFieldsPropertyIDGrouped())
    }
  }
  yield put(FieldActions.deleteField(fieldId, onSuccess, onError))
}

function* deleteField({ fieldId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteFieldService, { fieldId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }
}

function* saveGrowingSeason({ growingSeason, onSuccess = () => {}, onError = () => {}, isEdit, isConnected, fieldId, isPlanned = false }) {
  try {
    let response = {}
    if(isPlanned) {
      yield call(
        createGrowingSeasons,
        { growingSeasons: growingSeason },
        { growingSeasonId: growingSeason?.id }
      )
    } else {
      response = yield call(
        isEdit ? editGrowingSeasons : createGrowingSeason,
        {
          sowingYear: growingSeason?.sowingYear,
          closed: growingSeason?.closed,
          cropId: growingSeason?.cropId,
          fieldId: fieldId || growingSeason?.fieldId,
          cultivationGoalId: growingSeason?.cultivationGoalId,
          userStateRegistrationsIds: growingSeason?.userStateRegistrationsIds,
          activityId: growingSeason?.activityId,
          childrenPolygonId: growingSeason?.childrenPolygonId,
          isPlanned: growingSeason?.isPlanned,
          id: growingSeason?.id,
          fieldData: growingSeason?.fieldData
        },
        { growingSeasonId: growingSeason?.id }
      )
    }

    if(isConnected) {
      yield put(FieldActions.resetCurrentField())
      if(!isPlanned) {
        yield put(FieldActions.loadCurrentField(fieldId || growingSeason?.fieldId, growingSeason?.isPlanned))
      }

      yield call(onSuccess, { fieldId: response?.data?.fieldId, growingSeasonId: response?.data?.id })
    }
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }
}

function* saveOfflineGrowingSeason({ growingSeason, onSuccess = () => {}, onError = () => {}, isPlanned = false, web = false }) {
  try {
    const isConnected = web || (yield select(selectIsConnected))
    const field = yield select(selectCurrentField)
    const fields = yield select(isConnected ? selectFields : selectOfflineFields)
    const property = yield select(selectCurrentProperty)
    const moduleIsTechnical = yield select(selectModuleIsTechnical)

    const currentField = find(fields, item => item.id === growingSeason?.fieldId || item.id === field?.id)

    const currentFieldData = growingSeason?.id ? growingSeason?.fieldData : currentField
    if(web) {
      yield put(FieldActions.saveGrowingSeason(growingSeason, onSuccess, onError, !!growingSeason?.id, isConnected, growingSeason?.fieldId || field?.id, isPlanned))
    } else {
      const newGrowingSeason = {
        ...growingSeason,
        fieldId: growingSeason?.fieldId || field.id,
        id: growingSeason?.id || uuidv4(),
        fieldData: {
          ...currentFieldData,
          growingSeasons: []
        }
      }

      const currentGrowingSeasons = filter(currentField?.growingSeasons, item => item.id !== newGrowingSeason?.id)
      if(moduleIsTechnical) {
        yield put(OfflineTechnicalUserDataActions.updateOfflineTechnicalCurrentField(
          {
            ...currentField,
            growingSeasons: [...currentGrowingSeasons, { ...newGrowingSeason }]
          },
          property.id
        ))
      } else {
        yield put(OfflineFieldUserDataActions.updateOfflineCurrentField(
          {
            ...currentField,
            growingSeasons: [...currentGrowingSeasons, { ...newGrowingSeason }]
          }
        ))

        yield put(FieldActions.setCurrentField(
          {
            ...currentField,
            growingSeasons: [...currentGrowingSeasons, { ...newGrowingSeason }]
          }
        ))
      }

      if(!isConnected) {
        yield call(onSuccess, { fieldId: newGrowingSeason?.fieldId, growingSeasonId: newGrowingSeason?.id })
      }
      yield put(FieldActions.saveGrowingSeason(isPlanned ? growingSeason : newGrowingSeason, isConnected ? onSuccess : () => {}, isConnected ? onError : () => {}, !!growingSeason?.id, isConnected, currentField?.id, isPlanned && !growingSeason?.isPlanned))
    }

  } catch (err) {
    console.warn(err)
  }

}

function* saveOfflinePestReport({ pestReport, growingSeasonId, onSuccess = () => {}, onError = () => {}, web = false }) {
  const field = yield select(selectCurrentField)
  const property = yield select(selectCurrentProperty)
  const moduleIsTechnical = yield select(selectModuleIsTechnical)

  if(web) {
    yield put(FieldActions.savePestReport(pestReport, growingSeasonId, onSuccess, onError))
  } else {
    const newPestReport = {
      reportImages: [],
      ...pestReport,
      id: pestReport?.id || uuidv4(),
      growingSeasonId: growingSeasonId || pestReport?.growingSeasonId
    }

    const growingSeasonWithNewPestReport = find(field?.growingSeasons, item => item.id === pestReport?.growingSeasonId)

    const growingSeasonsWithoutCurrent = filter(field?.growingSeasons, item => item.id !== pestReport?.growingSeasonId)

    const fieldWithNewPestReport = {
      ...field,
      growingSeasons: [...growingSeasonsWithoutCurrent, {
        ...growingSeasonWithNewPestReport,
        plagueReports: [
          ...growingSeasonWithNewPestReport?.plagueReports,
          { ...newPestReport }
        ]
      }]
    }

    if(moduleIsTechnical) {
      yield put(OfflineTechnicalUserDataActions.updateOfflineTechnicalCurrentField(fieldWithNewPestReport, property.id))
    } else {
      yield put(OfflineFieldUserDataActions.updateOfflineCurrentField(
        fieldWithNewPestReport
      ))
    }

    yield put(FieldActions.setCurrentField(
      fieldWithNewPestReport
    ))

    yield put(FieldActions.savePestReport(newPestReport, growingSeasonId || pestReport?.growingSeasonId, onSuccess, onError))
  }
}

function* saveOfflineFieldAnalysis({ analysis, fieldId, onSuccess = () => {}, onError = () => {}, web = false }) {
  if(web) {
    yield put(FieldActions.saveFieldAnalysis(analysis, fieldId, onSuccess, onError))
  } else {
    const newAnalysis = {
      reportImages: [],
      ...analysis,
      id: analysis?.id || uuidv4()
    }

    yield put(FieldActions.saveFieldAnalysis(newAnalysis, fieldId, onSuccess, onError))
  }
}

function* updateOfflineFieldAnalysis({ analysis, analysisId, onSuccess = () => {}, onError = () => {} }) {
  yield put(FieldActions.updateFieldAnalysis(analysis, analysisId, onSuccess, onError))
}

function* saveOfflineCropManagement({ cropManagement, onSuccess = () => {}, onError = () => {}, web = false }) {
  try {
    const isConnected = web ? true : yield select(selectIsConnected)
    const currentField = yield select(selectCurrentField)
    const allFields = isConnected ? yield select(selectFields) : yield select(selectOfflineFields)
    const allFieldsPlanned = isConnected ? yield select(selectFieldsPlanned) : yield select(selectPlannedPropertyFields)
    const technicalFields = yield select(selectTechnicalPropertyFields)
    const moduleIsTechnical = yield select(selectModuleIsTechnical)

    const growingSeasonIds = cropManagement?.growingSeasonIds || cropManagement?.[0]?.growingSeasonIds || [cropManagement?.growingSeasonId]

    if(!cropManagement?.id && !cropManagement?.[0]?.id) {
      const fieldsWithNewCropManagement = []
      const fixedUUId = []

      if (!web) {
        map(growingSeasonIds, (growingSeasonId) => {
          const field = find(!isConnected && moduleIsTechnical ? technicalFields : [currentField, ...allFieldsPlanned, ...allFields], data => find(data?.growingSeasons, item => item.id === growingSeasonId))

          const currentGrowingSeason = find(field?.growingSeasons, item => item.id === growingSeasonId)
          const newCropManagements = []

          if(isArray(cropManagement)) {
            map(cropManagement, item => {
              const id = uuidv4()
              newCropManagements.push({
                ...item,
                id,
                growingSeasonId: currentGrowingSeason?.id,
                fieldId: field?.id
              })
              fixedUUId.push({
                id,
                growingSeasonId: currentGrowingSeason?.id
              })
            })
          } else {
            const id = uuidv4()
            newCropManagements.push({
              ...cropManagement,
              id,
              growingSeasonId: currentGrowingSeason?.id,
              fieldId: field?.id
            })
            fixedUUId.push({
              id,
              growingSeasonId: currentGrowingSeason?.id
            })
          }

          const newCropManagementsArray = map(newCropManagements, item => ({
            ...item,
            cropManagementItem: {
              cropManagementId: item?.cropManagementId,
              items: item?.items
            },
            operation: {
              id: item?.operationSlug,
              name: item?.operationLabel,
              slug: item?.operationSlug
            },
            off: true
          }))

          const growingSeasons = filter(field?.growingSeasons, item => item.id !== growingSeasonId)

          const fieldWithNewCropManagementObject = {
            ...field,
            growingSeasons: [...(growingSeasons || []), {
              ...currentGrowingSeason,
              cropsManagements: [
                ...(currentGrowingSeason?.cropsManagements || []),
                ...newCropManagementsArray
              ]
            }]
          }

          fieldsWithNewCropManagement.push(fieldWithNewCropManagementObject)
        })

        if(moduleIsTechnical) {
          yield put(OfflineTechnicalUserDataActions.updateOfflineTechnicalCurrentField(fieldsWithNewCropManagement, cropManagement?.propertyId || cropManagement?.[0]?.propertyId, true))
        } else {
          yield put(OfflineFieldUserDataActions.updateOfflineCurrentField(
            fieldsWithNewCropManagement,
            true
          ))
        }
      }
      if(!isConnected) {
        const currentFieldUpdated = find(fieldsWithNewCropManagement,item => item?.id === currentField?.id)
        yield put(FieldActions.setCurrentField(
          currentFieldUpdated
        ))
        yield put(FieldActions.saveCropManagement(filter(flatten(map(currentFieldUpdated?.growingSeasons, item => item?.cropsManagements)), item => item?.off), () => {}, () => {}, false))
      } else {
        yield put(FieldActions.saveCropManagement(cropManagement , onSuccess, onError, false))
      }

    } else {
      if (!web) {
        const currentGrowingSeason = find(currentField?.growingSeasons, item => item?.id === cropManagement?.growingSeasonId)

        const growingSeasons = filter(currentField?.growingSeasons, item => item?.id !== cropManagement?.growingSeasonId)

        const currentCropManagements = filter(currentGrowingSeason?.cropsManagements, item => item?.id !== cropManagement?.id)

        const fieldWithNewCropManagement = {
          ...currentField,
          growingSeasons: [...(growingSeasons || []), {
            ...currentGrowingSeason,
            cropsManagements: [
              ...(currentCropManagements || []),
              {
                ...cropManagement,
                operation: {
                  id: cropManagement?.operationSlug,
                  name: cropManagement?.operationLabel || cropManagement?.operation?.name,
                  slug: cropManagement?.operationSlug
                },
                cropManagementItem: {
                  cropManagementId: cropManagement?.id,
                  items: cropManagement?.items
                }
              }
            ]
          } ]
        }

        const fieldsWithNewCropManagement = []

        map(growingSeasonIds, (growingSeasonId) => {
          const field = find(!isConnected && moduleIsTechnical ? technicalFields : [currentField, ...allFieldsPlanned, ...allFields], data => find(data?.growingSeasons, item => item.id === growingSeasonId))
          const fieldCurrentGrowingSeason = find(field?.growingSeasons, item => item.id === growingSeasonId)
          const growingSeasonsWithoutCurrent = filter(field?.growingSeasons, item => item.id !== fieldCurrentGrowingSeason?.id)

          const fieldWithNewCropManagementObject = {
            ...field,
            growingSeasons: [...(growingSeasonsWithoutCurrent || []), {
              ...fieldCurrentGrowingSeason,
              cropsManagements: [
                ...(fieldCurrentGrowingSeason?.cropsManagements || []),
                {
                  ...cropManagement,
                  operation: {
                    id: cropManagement?.operationSlug,
                    name: cropManagement?.operationLabel,
                    slug: cropManagement?.operationSlug
                  },
                  cropManagementItem: {
                    cropManagementId: cropManagement?.id,
                    items: cropManagement?.items
                  }
                }
              ]
            }]
          }
          fieldsWithNewCropManagement.push(fieldWithNewCropManagementObject)
        })

        if(moduleIsTechnical) {
          yield put(OfflineTechnicalUserDataActions.updateOfflineTechnicalCurrentField(fieldsWithNewCropManagement, cropManagement?.propertyId, true))
        } else {

          yield put(OfflineFieldUserDataActions.updateOfflineCurrentField(
            fieldsWithNewCropManagement,
            true
          ))
        }

        yield put(FieldActions.setCurrentField(
          fieldWithNewCropManagement
        ))
      }
      yield put(FieldActions.saveCropManagement(cropManagement, isConnected ? onSuccess : () => {}, isConnected ? onError : () => {}, true))
    }

  } catch (err) {
    console.warn(err)
  }
}

function* deleteOfflineGrowingSeason({ growingSeasonId, onSuccess = () => {}, onError = () => {}, web = false }) {
  const field = yield select(selectCurrentField)
  const property = yield select(selectCurrentProperty)
  const moduleIsTechnical = yield select(selectModuleIsTechnical)
  const isConnected = web || (yield select(selectIsConnected))

  if(web) {
    yield put(FieldActions.deleteGrowingSeason(growingSeasonId, onSuccess, onError, isConnected))
  } else {
    const fieldWithoutGrowingSeason = {
      ...field,
      growingSeasons: filter(field?.growingSeasons, item => item.id !== growingSeasonId)
    }

    if(moduleIsTechnical) {
      yield put(OfflineTechnicalUserDataActions.updateOfflineTechnicalCurrentField(fieldWithoutGrowingSeason, property.id))
    } else {
      yield put(OfflineFieldUserDataActions.updateOfflineCurrentField(
        fieldWithoutGrowingSeason
      ))
    }

    yield put(FieldActions.setCurrentField(
      fieldWithoutGrowingSeason
    ))

    yield put(FieldActions.deleteGrowingSeason(growingSeasonId, onSuccess, onError, isConnected))
  }
}

function* deleteGrowingSeason({ growingSeasonId, onSuccess = () => {}, onError = () => {}, isConnected }) {
  try {
    if(isConnected) {
      yield put(FieldActions.toggleCurrentFieldLoading(true))
    }
    yield call(deleteGrowingSeasons, { growingSeasonId })
    if(isConnected) {
      yield put(FieldActions.toggleCurrentFieldLoading(false))
      yield call(onSuccess)
      yield put(FieldActions.loadCurrentField())
    }
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }
}

function* saveCropManagement({ cropManagement, onSuccess = () => {}, onError = () => {}, edit = false }) {
  try {
    const cropService = edit ?
      editCropManagement : createCropManagement

    const { data: {
      data: currentCropManagement
    } } = yield call(
      cropService,
      cropManagement
    )

    yield call(onSuccess, currentCropManagement[0])
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }
}

function* reopenGrowingSeason({ growingSeasonId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(
      editGrowingSeasons,
      { closed: false },
      { growingSeasonId }
    )

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }
}

function* deleteOfflineCropManagement({ growingSeasonId, cropManagementId, onSuccess = () => {}, onError = () => {}, web = false }) {
  const field = yield select(selectCurrentField)
  const property = yield select(selectCurrentProperty)
  const moduleIsTechnical = yield select(selectModuleIsTechnical)
  const isConnected = web || (yield select(selectIsConnected))
  const crops = filter(field.growingSeason?.cropsManagements, item => item.id !== cropManagementId)

  if(web) {
    yield put(FieldActions.deleteCropManagement(growingSeasonId, cropManagementId, onSuccess, onError, isConnected))
  } else {
    const fieldWithoutCropManagement = {
      ...field,
      growingSeason: {
        ...field?.growingSeason,
        cropsManagements: [
          ...crops
        ]
      }
    }

    if(moduleIsTechnical) {
      yield put(OfflineTechnicalUserDataActions.updateOfflineTechnicalCurrentField(fieldWithoutCropManagement, property.id))
    } else {
      yield put(OfflineFieldUserDataActions.updateOfflineCurrentField(
        fieldWithoutCropManagement
      ))
    }

    yield put(FieldActions.setCurrentField(
      fieldWithoutCropManagement
    ))

    yield put(FieldActions.deleteCropManagement(growingSeasonId, cropManagementId, onSuccess, onError, isConnected))
  }
}

function* deleteCropManagement({ growingSeasonId, cropManagementId, onSuccess = () => {}, onError = () => {}, isConnected }) {
  try {
    if(isConnected) {
      yield put(FieldActions.toggleCurrentFieldLoading(true))
    }
    yield call(deleteCropManagements, { growingSeasonId, cropManagementId })
    if(isConnected) {
      yield put(FieldActions.toggleCurrentFieldLoading(false))
      yield put(FieldActions.loadCurrentField())
    }
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }
}

function* deletePestReport({ reportId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deletePestReportService, { reportId })
    yield put(FieldActions.loadCurrentField(null, null, onSuccess, onError))
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }
}

function* deleteFieldAnalysis({ analysisId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteSoilAnalysisService, { analysisId })
    yield call(deleteSoilAnalysisDocumentsService, { analysisId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }
}

function* deleteFieldAnalysisDocuments({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteSoilAnalysisDocumentsService, { data: params })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }
}

function* uploadFieldShape({ file, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: {
      coordinates
    } } = yield call(uploadFieldShapeService, file)

    yield call(onSuccess, coordinates)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError, err)
  }
}

function* savePestReport({ pestReport, growingSeasonId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(
      createPestReportService,
      pestReport,
      { growingSeasonId }
    )

    yield put(FieldActions.loadCurrentField())
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError)
  }
}

function* updateFieldAnalysis({ analysis, analysisId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(
      editSoilAnalysisService,
      analysis,
      { analysisId }
    )

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError)
  }
}

function* saveFieldAnalysis({ analysis, fieldId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(
      createSoilAnalysisService,
      analysis,
      { fieldId }
    )

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError)
  }
}

function* saveFieldAnalysisDocument({ upload, analysisId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(
      uploadSoilAnalysisDocumentsService,
      upload,
      { analysisId }
    )

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(FieldActions.fieldError(error))
    yield call(onError)
  }
}

function* deleteOfflinePestReport({ reportId, onSuccess = () => {}, onError = () => {} }) {
  const field = yield select(selectCurrentField)
  const property = yield select(selectCurrentProperty)
  const moduleIsTechnical = yield select(selectModuleIsTechnical)

  const reports = filter(field.growingSeason.plagueReports, item => item.id !== reportId)

  if(moduleIsTechnical) {
    yield put(OfflineTechnicalUserDataActions.updateOfflineTechnicalCurrentField({
      ...field,
      growingSeason: {
        ...field.growingSeason,
        plagueReports: [...reports]
      }
    }, property.id))
  } else {
    yield put(OfflineFieldUserDataActions.updateOfflineCurrentField(
      {
        ...field,
        growingSeason: {
          ...field.growingSeason,
          plagueReports: [...reports]
        }
      }
    ))
  }

  yield put(FieldActions.setCurrentField(
    {
      ...field,
      growingSeason: {
        ...field.growingSeason,
        plagueReports: [...reports]
      }
    }
  ))

  yield put(FieldActions.deletePestReport(reportId, onSuccess, onError))
}

function* updatePestReport({ params, reportId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(editPestReport, params, { reportId })

    yield put(FieldActions.loadCurrentField())
    yield call(onSuccess, data)
  } catch (error) {
    yield put(FieldActions.fieldError(error))
    yield call(onError, error)
  }
}

function* updateOfflinePestReport({ params, reportId, onSuccess = () => {}, onError = () => {} }) {
  const field = yield select(selectCurrentField)
  const property = yield select(selectCurrentProperty)
  const moduleIsTechnical = yield select(selectModuleIsTechnical)

  let report = find(field?.growingSeason?.plagueReports, item => item.id === reportId)
  const reports = filter(field?.growingSeason?.plagueReports, item => item.id !== reportId)

  report = {
    ...report,
    ...params
  }

  if(moduleIsTechnical) {
    yield put(OfflineTechnicalUserDataActions.updateOfflineTechnicalCurrentField({
      ...field,
      growingSeason: {
        ...field?.growingSeason,
        plagueReports: [...reports, report]
      }
    }, property.id))
  } else {
    yield put(OfflineFieldUserDataActions.updateOfflineCurrentField(
      {
        ...field,
        growingSeason: {
          ...field.growingSeason,
          plagueReports: [...reports, report]
        }
      }
    ))
  }

  yield put(FieldActions.setCurrentField(
    {
      ...field,
      growingSeason: {
        ...field.growingSeason,
        plagueReports: [...reports, report]
      }
    }
  ))


  yield put(FieldActions.updatePestReport(params, reportId, onSuccess, onError))
}

function* addPestReportFiles({ params, reportId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(postFilesPestReport, params, { reportId })
    yield call(onSuccess)
  } catch (error) {
    yield put(FieldActions.fieldError(error))
    yield call(onError, error)
  }
}

function* addOfflinePestReportFiles({ params, reportId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(FieldActions.addPestReportFiles(params, reportId, onSuccess, onError))
  } catch (error) {
    yield put(FieldActions.fieldError(error))
    yield call(onError, error)
  }
}

function* editOfflinePestReportFiles({ params, reportId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(FieldActions.editPestReportFiles(params, reportId, onSuccess, onError))
  } catch (error) {
    yield put(FieldActions.fieldError(error))
    yield call(onError, error)
  }
}

function* editPestReportFiles({ params, reportId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(putFilesPestReport, { oldFiles: params }, { reportId })
    yield call(onSuccess)
  } catch (error) {
    yield put(FieldActions.fieldError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(FieldTypes.LOAD_FIELDS, loadFields),
  takeLatest(FieldTypes.LOAD_FIELDS_SUCCESS, loadFieldsSuccess),

  takeLatest(FieldTypes.LOAD_CURRENT_FIELD, loadCurrentField),

  takeLatest(FieldTypes.LOAD_CURRENT_FIELD_HISTORY, loadCurrentFieldHistory),

  takeLatest(FieldTypes.LOAD_FIELDS_HISTORY, loadFieldsHistory),
  takeLatest(FieldTypes.LOAD_FIELDS_PLANNED, loadFieldsPlanned),
  takeLatest(FieldTypes.LOAD_FIELDS_ANALYSIS, loadFieldsAnalysis),
  takeLatest(FieldTypes.LOAD_FIELDS_HISTORY_SUCCESS, loadFieldsHistorySuccess),

  takeLatest(FieldTypes.SAVE_FIELD, saveField),
  takeLatest(FieldTypes.SAVE_OFFLINE_FIELD, saveOfflineField),

  takeLatest(FieldTypes.DELETE_FIELD, deleteField),
  takeLatest(FieldTypes.DELETE_OFFLINE_FIELD, deleteOfflineField),

  takeLatest(FieldTypes.UPLOAD_FIELD_SHAPE, uploadFieldShape),

  takeLatest(FieldTypes.SAVE_GROWING_SEASON, saveGrowingSeason),
  takeLatest(FieldTypes.SAVE_OFFLINE_GROWING_SEASON, saveOfflineGrowingSeason),

  takeLatest(FieldTypes.DELETE_GROWING_SEASON, deleteGrowingSeason),
  takeLatest(FieldTypes.DELETE_OFFLINE_GROWING_SEASON, deleteOfflineGrowingSeason),

  takeLatest(FieldTypes.REOPEN_GROWING_SEASON, reopenGrowingSeason),

  takeLatest(FieldTypes.SAVE_CROP_MANAGEMENT, saveCropManagement),
  takeLatest(FieldTypes.SAVE_OFFLINE_CROP_MANAGEMENT, saveOfflineCropManagement),

  takeLatest(FieldTypes.DELETE_OFFLINE_CROP_MANAGEMENT, deleteOfflineCropManagement),
  takeLatest(FieldTypes.DELETE_CROP_MANAGEMENT, deleteCropManagement),

  takeLatest(FieldTypes.SAVE_PEST_REPORT, savePestReport),
  takeLatest(FieldTypes.SAVE_OFFLINE_PEST_REPORT, saveOfflinePestReport),
  takeLatest(FieldTypes.SAVE_FIELD_ANALYSIS, saveFieldAnalysis),
  takeLatest(FieldTypes.SAVE_OFFLINE_FIELD_ANALYSIS, saveOfflineFieldAnalysis),
  takeLatest(FieldTypes.SAVE_FIELD_ANALYSIS_DOCUMENT, saveFieldAnalysisDocument),
  takeLatest(FieldTypes.UPDATE_FIELD_ANALYSIS, updateFieldAnalysis),
  takeLatest(FieldTypes.UPDATE_OFFLINE_FIELD_ANALYSIS, updateOfflineFieldAnalysis),
  takeLatest(FieldTypes.DELETE_FIELD_ANALYSIS, deleteFieldAnalysis),
  takeLatest(FieldTypes.DELETE_FIELD_ANALYSIS_DOCUMENTS, deleteFieldAnalysisDocuments),
  takeLatest(FieldTypes.DELETE_OFFLINE_PEST_REPORT, deleteOfflinePestReport),
  takeLatest(FieldTypes.DELETE_PEST_REPORT, deletePestReport),
  takeLatest(FieldTypes.UPDATE_PEST_REPORT, updatePestReport),
  takeLatest(FieldTypes.UPDATE_OFFLINE_PEST_REPORT, updateOfflinePestReport),
  takeLatest(FieldTypes.ADD_PEST_REPORT_FILES, addPestReportFiles),
  takeLatest(FieldTypes.EDIT_PEST_REPORT_FILES, editPestReportFiles),
  takeLatest(FieldTypes.ADD_OFFLINE_PEST_REPORT_FILES, addOfflinePestReportFiles),
  takeLatest(FieldTypes.EDIT_OFFLINE_PEST_REPORT_FILES, editOfflinePestReportFiles)
]
