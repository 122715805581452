import React, { useRef, useCallback, memo } from 'react'
import { useHistory } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'

import I18n, { useT } from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import LoginForm from '@smartcoop/web-containers/forms/auth/LoginForm'
import SignUpForm from '@smartcoop/web-containers/forms/auth/SignUpForm'
import { withLayout } from '@smartcoop/web-containers/layouts'
import { Item, Header, Title, Subtitle, Text, TextUnderlined } from '@smartcoop/web-containers/layouts/GuestLayout/theme'
import { useRoutes } from '@smartcoop/web-containers/routes/guest'

const LoginScreen = () => {
  const signUpFormRef = useRef(null)
  const loginFormRef = useRef(null)

  const history = useHistory()
  const { routes } = useRoutes()

  const t = useT()

  const handleSignUpSubmit = useCallback(
    () => {
      history.push(routes.identification.path)
    },
    [history, routes.identification.path]
  )

  const handleForgotPasswordClick = useCallback(
    () => {
      history.push(routes.recoverPassword.path)
    },
    [history, routes.recoverPassword.path]
  )

  return (
    <>
      <Item>
        <Header>
          <Title>
            <I18n>welcome to smartcoop</I18n>
          </Title>
        </Header>

        <Text>
          <I18n>to enter, insert your data</I18n>
        </Text>

        <LoginForm
          ref={ loginFormRef }
          onSubmit={ () => localStorage.setItem('logoutFlag', true) }
          withoutSubmitButton
        />

        <TextUnderlined
          onClick={ handleForgotPasswordClick }
          style={ { marginTop: -5, marginBottom: 10 } }
        >
          <I18n>I forgot my password</I18n>
        </TextUnderlined>

        <Button
          color="yellow"
          id="login-submit"
          onClick={ () => loginFormRef.current.submit() }
          fullWidth
        >
          <I18n>login</I18n>
        </Button>
      </Item>

      <Divider />

      <Item>
        <Subtitle>
          <I18n>first access?</I18n>
        </Subtitle>

        <Text>
          {t('to us do your data validation, fill the fields below').replace('\n', ' ')}
        </Text>

        <SignUpForm
          ref={ signUpFormRef }
          onSubmit={ handleSignUpSubmit }
        />

      </Item>
    </>
  )
}

export default memo(withLayout('guest')(LoginScreen))
