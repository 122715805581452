import styled, { css } from 'styled-components'

import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'

export const Line = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: ${ ({ receiver, system }) => {
    if (system) {
      return 'center'
    }
    return receiver ? 'flex-start' : 'flex-end'
  } };
  height: fit-content;
  column-gap: 5px;
`

export const Card = styled.div`
  min-width: 80px;
  padding: 10px 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(1fr, 3);

  ${ ({ system }) => {
    if (system) {
      return css`
        border-radius: 10px;
        background-color: ${ colors.lightGrey };
      `
    }

    return css`
      border-radius: 10px;
      border-bottom-right-radius: ${ ({ receiver }) => receiver ? '10px' : 0 };
      border-bottom-left-radius: ${ ({ receiver }) => receiver ? 0 : '10px' };
      background-color: ${ ({ receiver }) => receiver ? colors.white : colors.chatYellow };
    `
  } }

  :hover {
    #action {
      bottom: 5px;
      left: 10px;
      opacity: 1;
    }

    #status {
      opacity: 15%;
    }
  }
`

export const CardDetail = styled.div`
  display: grid;
  grid-template-columns: ${ ({ forward, group }) => {
    if (group) {
      return forward ? '1fr auto auto auto' : '1fr auto auto'
    }
    return forward ? '1fr auto auto' : '1fr auto'
  } } ;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
`

export const DateSpan = styled.span`
  color: ${ colors.mediumGrey };
  font-size: .7rem;
  text-align: end;
`

export const ForwardSpan = styled.span`
  color: ${ colors.mediumGrey };
  font-size: .7rem;
  text-align: start;
  padding-right: 10px;
  display: flex;
  column-gap: 5px;
`

export const ActionButton = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  top: 5px;
  /* left: 10px; */
  height: 0;
  transition: left .1s, bottom .1s, opacity .2s;
  transition-timing-function: ease-in-out;
  opacity: 0;
  cursor: pointer;

  span {
    height: 20px;
    background-color: white;
    border-radius: 5px;
  }
`

export const LineWrapper = styled.div`
  display: flex;
  align-items: center;

  ${ ({ showCheckbox  }) => showCheckbox ? css`
    padding-left: 5px;

    :hover {
      filter: brightness(0.95);
      background-color: rgba(219, 219, 219, .25);
    }
  ` : '' }
`

export const DeletedSpan = styled.span`
  color: ${ colors.mediumGrey };
  font-style: italic;
  font-size: 12px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
    font-size: .9rem;
  }
`

export const AudioPlayer = styled.audio`
  height: 35px;

  &::-webkit-media-controls-panel {
    background-color: ${ ({ receiver }) => receiver ? colors.white : colors.chatYellow };
    height: 35px;
    padding: 0;
  }
`

export const VideoPlayer = styled.video`
margin-top: 10px;
  height: 200px;
  width: 100%;
`

export const ImagePreview = styled.img`
  max-height: 300px;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
`

export const ImageLoading = styled.div`
  height: 250px;
  width: 250px;
  background-color: ${ colors.blackLight };
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NameSpan = styled.span`
  font-weight: 600;
  color: ${ colors.darkGrey };
`

export const PopoverButton = styled(Button)`
  > span {
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 10px;
  }
`

export const MessageText = styled.span`
  white-space: pre-wrap;
  overflow-wrap: break-word;
`