import React, { useCallback, forwardRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import loginSchema from '@smartcoop/forms/schemas/auth/login.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { OrganizationActions } from '@smartcoop/stores/organization'
import { UserActions } from '@smartcoop/stores/user'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputCpfCnpj from '@smartcoop/web-components/InputCpfCnpj'
import InputPassword from '@smartcoop/web-components/InputPassword'
import ErrorModal from '@smartcoop/web-containers/modals/ErrorModal'
import LoadingModal from '@smartcoop/web-containers/modals/LoadingModal'

import { Container } from './styles'

const LoginForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit } = props

  const dispatch = useCallback(useDispatch(), [])
  const { createDialog, removeDialog } = useDialog()
  const t = useT()

  useEffect(() => {
    dispatch(UserActions.resetUserData())
    dispatch(OrganizationActions.loadUserOrganizationsSuccess([]))
  },[dispatch])

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        formRef.current.submit()
      }
    },
    [formRef]
  )

  const handleSubmit = useCallback(
    ({ document: doc, password }) => {
      createDialog({
        id: 'loading',
        Component: LoadingModal
      })

      const removeLoader = () => {
        setTimeout(() => removeDialog({ id: 'loading' }), 150)
      }

      dispatch(AuthenticationActions.login(
        doc,
        password,
        () => {
          onSubmit()
          removeLoader()
        },
        (error) => {
          createDialog({
            id: 'user-not-found',
            Component: ErrorModal,
            props: {
              message: t(error)
            }
          })
          removeLoader()
        }
      ))
    },
    [createDialog, dispatch, onSubmit, removeDialog, t]
  )

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ loginSchema }
      onSubmit={ handleSubmit }
    >
      <Container>
        <InputCpfCnpj
          name="document"
          label="CPF/CNPJ"
          disabled={ loading }
          fullWidth
          onKeyDown={ handleKeyDown }
        />

        <InputPassword
          name="password"
          label={ t('password') }
          disabled={ loading }
          fullWidth
          onKeyDown={ handleKeyDown }
        />

        {!withoutSubmitButton && (
          <Button
            id="signup-submit"
            onClick={ () => formRef.current.submit() }
            disabled={ loading }
            fullWidth
          >
            <I18n>login</I18n>
          </Button>
        )}
      </Container>
    </Form>
  )
})

LoginForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

LoginForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default LoginForm
