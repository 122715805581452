import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import filter from 'lodash/filter'

import { AuthenticationTypes } from '../authentication'

// const INITIAL_FIELD = {
//   fieldName: '',
//   area: undefined,
//   fieldMode: undefined,
//   irrigated: undefined,
//   polygonCoordinates: undefined
// }

// Initial state
const INITIAL_STATE = {
  fields: [],
  currentField: {},
  fieldNavigationData: {},
  error: null,
  loadingCurrentField: false,
  fieldsHistory: [],
  fieldsPlanned: [],
  fieldAnalysis: [],
  currentFieldHistory: {}
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadFields: ['params', 'onSuccess', 'onError'],
  loadFieldsSuccess: ['fields', 'onSuccess'],
  resetFields: [],

  loadFieldsHistory: ['params', 'onSuccess', 'onError'],
  loadFieldsHistorySuccess: ['fields', 'onSuccess'],

  loadFieldsPlanned: ['params', 'onSuccess', 'onError'],
  loadFieldsPlannedSuccess: ['fields'],

  saveField: ['field', 'onSuccess', 'onError', 'isEdit', 'isConnected'],
  saveOfflineField: ['field', 'onSuccess', 'onError', 'web'],
  updateOfflineField: ['field'],

  uploadFieldShape: ['file', 'onSuccess', 'onError'],

  deleteField: ['fieldId', 'onSuccess', 'onError'],
  deleteOfflineField: ['fieldId','onSuccess', 'onError', 'web'],

  setCurrentField: ['currentField'],
  resetCurrentField: [],

  setCurrentFieldHistory: ['currentFieldHistory'],
  resetCurrentFieldHistory: [],

  loadCurrentField: ['fieldId', 'isPlanned', 'onSuccess', 'onError'],

  loadCurrentFieldHistory: ['growingSeasonId', 'onSuccess', 'onError'],

  reopenGrowingSeason: ['growingSeasonId', 'onSuccess', 'onError'],

  saveGrowingSeason: ['growingSeason', 'onSuccess', 'onError', 'isEdit','isConnected', 'fieldId', 'isPlanned'],
  saveOfflineGrowingSeason: ['growingSeason', 'onSuccess', 'onError', 'isPlanned', 'web'],

  deleteGrowingSeason: ['growingSeasonId', 'onSuccess', 'onError', 'isConnected'],
  deleteOfflineGrowingSeason: ['growingSeasonId', 'onSuccess', 'onError', 'web'],

  saveCropManagement: ['cropManagement', 'onSuccess', 'onError', 'edit'],
  saveOfflineCropManagement: ['cropManagement', 'onSuccess', 'onError', 'web'],

  deleteCropManagement: ['growingSeasonId', 'cropManagementId', 'onSuccess', 'onError', 'isConnected'],
  deleteOfflineCropManagement: ['growingSeasonId', 'cropManagementId', 'onSuccess', 'onError', 'web'],

  fieldError: ['error'],

  setFieldNavigationData: ['fieldNavigationData'],

  savePestReport: ['pestReport', 'growingSeasonId', 'onSuccess', 'onError'],
  saveOfflinePestReport: ['pestReport', 'growingSeasonId', 'onSuccess', 'onError', 'web'],

  loadFieldsAnalysis: ['params', 'onSuccess', 'onError'],
  loadFieldsAnalysisSuccess: ['fieldAnalysis', 'onSuccess'],
  saveFieldAnalysis: ['analysis', 'fieldId', 'onSuccess', 'onError'],
  saveFieldAnalysisDocument: ['upload', 'analysisId', 'onSuccess', 'onError'],
  saveOfflineFieldAnalysis: ['analysis', 'fieldId', 'onSuccess', 'onError', 'web'],
  updateFieldAnalysis: ['analysis', 'analysisId', 'onSuccess', 'onError'],
  updateOfflineFieldAnalysis: ['analysis', 'analysisId', 'onSuccess', 'onError'],
  deleteFieldAnalysis: ['analysisId', 'onSuccess', 'onError'],
  deleteFieldAnalysisDocuments: ['params', 'onSuccess', 'onError'],

  deletePestReport: ['reportId', 'onSuccess', 'onError'],
  deleteOfflinePestReport: ['reportId', 'onSuccess', 'onError'],

  updatePestReport: ['params', 'reportId', 'onSuccess', 'onError'],
  updateOfflinePestReport: ['params', 'reportId', 'onSuccess', 'onError'],

  addOfflinePestReportFiles: ['params', 'reportId', 'onSuccess', 'onError'],
  editOfflinePestReportFiles: ['params', 'reportId', 'onSuccess', 'onError'],
  addPestReportFiles: ['params', 'reportId', 'onSuccess', 'onError'],
  editPestReportFiles: ['params', 'reportId', 'onSuccess', 'onError'],

  toggleCurrentFieldLoading: ['loading']
})

/**
 * Assigning offline actions
 */
markActionsOffline(Creators,
  [
    'saveField',
    'saveGrowingSeason',
    'deleteGrowingSeason',
    'deleteField',
    'saveCropManagement',
    'deleteCropManagement',
    'savePestReport',
    'deletePestReport',
    'updatePestReport',
    'addPestReportFiles',
    'editPestReportFiles'
  ]
)

/**
 * Reducers functions
 */
const loadFieldsSuccess = (state = INITIAL_STATE, { fields }) => ({
  ...state,
  error: INITIAL_STATE.error,
  fields
})

const loadFieldsAnalysisSuccess = (state = INITIAL_STATE, { fieldAnalysis }) => ({
  ...state,
  error: INITIAL_STATE.error,
  fieldAnalysis
})

const setFieldNavigationData = (state = INITIAL_STATE, { fieldNavigationData }) => ({
  ...state,
  fieldNavigationData
})

const loadFieldsHistorySuccess = (state = INITIAL_STATE, { fields }) => ({
  ...state,
  error: INITIAL_STATE.error,
  fieldsHistory: fields
})
const loadFieldsPlannedSuccess = (state = INITIAL_STATE, { fields }) => ({
  ...state,
  error: INITIAL_STATE.error,
  fieldsPlanned: fields
})

const toggleCurrentFieldLoading = (state = INITIAL_STATE, { loading }) => ({
  ...state,
  loadingCurrentField: loading
})

const deleteOfflineField = (state = INITIAL_STATE, { fieldId }) => ({
  ...state,
  fields: filter(state.fields, ({ id }) => id !== fieldId)
})

const fieldError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const setCurrentField = (state = INITIAL_STATE, { currentField }) => ({
  ...state,
  currentField
})

const resetCurrentField = (state = INITIAL_STATE) => ({
  ...state,
  currentField: INITIAL_STATE.currentField
})

const setCurrentFieldHistory = (state = INITIAL_STATE, { currentFieldHistory }) => ({
  ...state,
  currentFieldHistory
})

const resetCurrentFieldHistory = (state = INITIAL_STATE) => ({
  ...state,
  currentFieldHistory: INITIAL_STATE.currentFieldHistory
})

const resetFields = (state = INITIAL_STATE) => ({
  ...state,
  fields: INITIAL_STATE.fields,
  fieldsHistory: INITIAL_STATE.fieldsHistory,
  fieldsPlanned: INITIAL_STATE.fieldsPlanned,
  fieldAnalysis: INITIAL_STATE.fieldAnalysis
})

const saveOfflineGrowingSeason = (state = INITIAL_STATE, { growingSeason }) => ({
  ...state,
  currentField: {
    ...state.currentField,
    growingSeason: state.currentField.growingSeason
      ? {
        ...state.currentField.growingSeason,
        ...growingSeason
      }
      : state.currentField.growingSeason
  }
})

const deleteOfflineGrowingSeason = (state = INITIAL_STATE) => ({
  ...state,
  currentField: {
    ...state.currentField,
    growingSeason: {}
  }
})

const deleteOfflinePestReport = (state = INITIAL_STATE) => ({
  ...state,
  currentField: {
    ...state.currentField,
    growingSeason: {
      plagueReport: {}
    }
  }
})


const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_FIELDS_SUCCESS]: loadFieldsSuccess,
  [Types.LOAD_FIELDS_ANALYSIS_SUCCESS]: loadFieldsAnalysisSuccess,
  [Types.RESET_FIELDS]: resetFields,

  [Types.LOAD_FIELDS_HISTORY_SUCCESS]: loadFieldsHistorySuccess,

  [Types.LOAD_FIELDS_PLANNED_SUCCESS]: loadFieldsPlannedSuccess,

  [Types.DELETE_OFFLINE_FIELD]: deleteOfflineField,

  [Types.FIELD_ERROR]: fieldError,

  [Types.SET_CURRENT_FIELD]: setCurrentField,
  [Types.RESET_CURRENT_FIELD]: resetCurrentField,

  [Types.SET_CURRENT_FIELD_HISTORY]: setCurrentFieldHistory,
  [Types.RESET_CURRENT_FIELD_HISTORY]: resetCurrentFieldHistory,

  [Types.SAVE_OFFLINE_GROWING_SEASON]: saveOfflineGrowingSeason,
  [Types.DELETE_OFFLINE_GROWING_SEASON]: deleteOfflineGrowingSeason,

  [Types.DELETE_OFFLINE_PEST_REPORT]: deleteOfflinePestReport,

  [Types.SET_FIELD_NAVIGATION_DATA]: setFieldNavigationData,

  [Types.TOGGLE_CURRENT_FIELD_LOADING]: toggleCurrentFieldLoading,

  [AuthenticationTypes.LOGOUT]: logout
})

export { Types as FieldTypes, Creators as FieldActions }
