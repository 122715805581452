import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 23px 30px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap-reverse;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
  margin-bottom: 22px;
`

export const SingleItemContainer = styled.div`
  width: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 22px;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const inputSearchTheme = (theme) => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiFormControl: {
      ...theme.overrides?.MuiFormControl,
      root: {
        ...theme.overrides?.MuiFormControl?.root,
        marginBottom: '0 !important'
      }
    },
    MuiFormHelperText: {
      ...theme.overrides?.MuiFormHelperText,
      root: {
        ...theme.overrides?.MuiFormHelperText?.root,
        marginTop: '0 !important'
      }
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff'
      }
    }
  }
})


