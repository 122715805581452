import React, { useMemo, forwardRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import ceil from 'lodash/ceil'
import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import {
  getSalesOrdersByOrg as getSalesOrdersByOrgService
} from '@smartcoop/services/apis/smartcoopApi/resources/salesOrders'
import { useSnackbar } from '@smartcoop/snackbar'
import { SalesOrdersActions } from '@smartcoop/stores/salesOrders'
import { momentBackDateTimeFormat } from '@smartcoop/utils/dates'
import { formatCurrency, formatNumber } from '@smartcoop/utils/formatters'
import Badge from '@smartcoop/web-components/Badge'
import DataTable from '@smartcoop/web-components/DataTable'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import SignatureOrderModal from '@smartcoop/web-containers/modals/commercialization/SignatureOrderModal'

const SalesOrdersListFragment = forwardRef(({
  filters,
  urlParams
}, ref) => {
  const t = useT()
  const { createDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()

  const getQuotationUnit = useCallback((product) => {
    // eslint-disable-next-line eqeqeq
    const isConversionFactorEmpty = !product.conversionFactor || product.conversionFactor == 0
    return isConversionFactorEmpty
      ? product.measureUnit
      : `${
        t('{this} of', { this: product.unitOfMeasuresForConversion } )
      } ${
        formatNumber(ceil(Number(product.conversionFactor), 2))
      } ${
        product.measureUnit
      }`
  }, [t])

  const columns = useMemo(
    () => [
      {
        title: t('product'),
        field: 'organizationProduct.productName',
        cellStyle: { fontWeight: 'bold' }
      },
      {
        title: 'ID',
        field: 'id'
      },
      {
        title: t('quotation', { howMany: 1 }),
        field: 'quotation',
        render: (row) => (row.salesType === 'Gatilho' ? `${ formatCurrency(row.scheduledSale.targetPrice * row.organizationProduct.conversionFactor)  }/${  getQuotationUnit(row.organizationProduct) }` :`${ formatCurrency(row.quotation * row.organizationProduct.conversionFactor) }/${ getQuotationUnit(row.organizationProduct) }`)
      },
      {
        title: t('date', { howMany: 1 }),
        field: 'releaseDate',
        render: (row) => (row.salesType === 'Gatilho' ? moment(row.scheduledSale.expirationDate, momentBackDateTimeFormat).format('DD/MM/YYYY') : moment(row.releaseDate, momentBackDateTimeFormat).format('DD/MM/YYYY'))
      },
      {
        title: t('type'),
        field: 'salesType'
      },
      {
        title: t('total'),
        field: 'totalValue',
        render: (row) => formatCurrency(row.totalValue)
      },
      {
        title: t('quantity'),
        field: 'quantity',
        render: (row) => formatNumber(row.quantity / row.organizationProduct.conversionFactor)
      },
      {
        title: t('status'),
        field: 'salesOrdersStatus.statusName',
        render: (row) => (
          <Badge
            backgroundColorBadge={ row.salesOrdersStatus.statusColor }
            colorBadge={ row.salesOrdersStatus.statusColor }
          >
            {row.salesOrdersStatus.statusName}
          </Badge>
        )
      }
    ], [getQuotationUnit, t]
  )

  const handleDelete = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            createDialog({
              id: 'signature-order-modal',
              Component: SignatureOrderModal,
              props: {
                onSuccess: () => {
                  dispatch(SalesOrdersActions.deleteSalesOrders(
                    row.id,
                    () => {
                      snackbar.success(
                        t('your {this} was deleted', {
                          howMany: 1,
                          gender: 'female',
                          this: t('order', { howMany: 1 })
                        })
                      )
                      const { query } = ref.current.state
                      ref.current.onQueryChange({
                        ...query,
                        page: 0
                      })
                    }
                  ))
                }
              }
            })
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'female',
            this: t('order', { howMany: 1 })
          })
        }
      })
    },
    [createDialog, dispatch, ref, snackbar, t]
  )

  const canDeleteSaleOrder = useCallback(
    (row) => row.salesOrdersStatus.slug === 'aberta',
    []
  )

  return (
    <DataTable
      tableRef={ ref }
      data={ getSalesOrdersByOrgService }
      queryParams ={ filters }
      columns={ columns }
      urlParams={ urlParams }
      onDeleteClick={ handleDelete }
      conditionToDelete={ canDeleteSaleOrder }
      id="sales-order-list-table"
      detailPanel={ [{ render: (row) => !isEmpty(row?.observation) ? <div style={ { fontSize: 14, paddingLeft: '4vw', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' } }><div style={ { fontWeight: 'bold' } }>{t('observation', { howMany: 1 })}:</div> <div style={ { color: 'red', paddingLeft: 10 } }>{row?.observation}</div></div>  : <div style={ { fontSize: 14, paddingLeft: '4vw', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' } }><div style={ { fontWeight: 'bold' } }>{t('observation', { howMany: 1 })}:</div> <div style={ { paddingLeft: 10 } } /></div>, icon: () => <div/>, disabled: true }]  }
      onRowClick={ (event, rowData, togglePanel) => togglePanel() }
    />
  )
})

SalesOrdersListFragment.propTypes = {
  filters: PropTypes.object,
  urlParams: PropTypes.object
}

SalesOrdersListFragment.defaultProps = {
  filters: {},
  urlParams: {}
}

export default SalesOrdersListFragment
