import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import { colors } from '@smartcoop/styles'
import TextField from '@smartcoop/web-components/TextField'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 10px;
`
export const AddNewArea = styled(TextField).attrs({
  detached: true
})`
  margin: 10px;
  font-size: 16px;
  width: 100%;
`

export const AddNewAreaContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
`

// export const Label = styled.h2`
//   font-weight: 600;
//   margin-left: 8px;
//   font-size: 16px;
//   margin-bottom: 10px;
//   color: ${ props => props.error ? colors.error : colors.black }

export const Label = styled(Typography)`
  font-weight: bold;
  font-size: 16px;
  color: ${ props => props.error ? colors.error : colors.black };
`

export const ErrorLabel = styled(Typography)`
  font-size: 13px;
  margin-bottom: 10px;
  color: ${ colors.error }
`
