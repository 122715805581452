import * as Yup from 'yup'

import monthYear from '@smartcoop/forms/validators/monthYear.validator'

const filterPev = ({ t }) => Yup.object().shape({
  userStateRegistrationId: Yup.string(),
  from: monthYear({ t })(Yup.string()),
  to: monthYear({ t })(Yup.string()),
  initialPrice: Yup.string(),
  finalPrice: Yup.string()
})

export default filterPev
