import trimMask from '@meta-awesome/functions/src/trimMask'
import moment from 'moment/moment'
import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import equal from '@smartcoop/forms/validators/equal.validator'
import notInclude from '@smartcoop/forms/validators/notInclude.validator'
import password from '@smartcoop/forms/validators/password.validator'
import required from '@smartcoop/forms/validators/required.validator'

const createPasswordSchema = ({ t, data, user }) =>
  Yup.object().shape({
    newPassword: flow(
      password({ t }),
      notInclude({
        t,
        text: 'invalid password',
        compareWith: trimMask(moment(user.dateOfBirth).format('L'))
      }),
      notInclude({ t, text: 'invalid password', compareWith: user.document }),
      notInclude({
        t,
        text: 'invalid password',
        compareWith: trimMask(
          // eslint-disable-next-line no-nested-ternary
          user.cellPhone === undefined
            ? ''
            : user.cellPhone.split(' ')[1] === undefined
              ? user.cellPhone.split(' ')[0] || user.cellPhone
              : user.cellPhone.split(' ')[1] || user.cellPhone
        )
      }),
      required({ t })
    )(Yup.string()),

    confirmPassword: flow(
      equal({
        t,
        text: 'passwords do not match',
        compareWith: data.newPassword
      }),
      required({ t })
    )(Yup.string())
  })

export default createPasswordSchema
