import React, { useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Report } from 'powerbi-report-component'

import useSmartcoopApi from '@smartcoop/services/hooks/useSmartcoopApi'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import Loader from '@smartcoop/web-components/Loader'


const PropertyCostsReportFragment = () => {
  const { data: token, isValidating } = useSmartcoopApi('/v1/auth/microsoft-token')

  // eslint-disable-next-line no-unused-vars
  const [report, setReport] = useState(null)
  const [showReport, setShowReport] = useState(true)

  const currentProperty = useSelector(selectCurrentProperty)

  const currentPropertyId = useMemo(
    () => currentProperty?.id, [currentProperty]
  )

  const filters = useMemo(() => ({
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'dProperties',
      column: 'property_id'
    },
    operator: 'In',
    values: [currentPropertyId]
  }), [currentPropertyId])

  const handleReportLoad = useCallback(async (data) => {
    let currentFilters = []
    await data.getFilters().then((oldFilters => {
      currentFilters = oldFilters
    }))
    await data.setFilters([...currentFilters, filters])
    if (!showReport) {
      setShowReport(true)
    }

    setReport(data)
  }, [filters, showReport])


  const handleReportRender = useCallback(
    data => setReport(data),
    [setReport]
  )

  const accessToken = useMemo(
    () => token?.accessToken,
    [token]
  )

  const loading = useMemo(
    () => (!accessToken && isValidating) || !currentPropertyId || !accessToken,
    [accessToken, currentPropertyId, isValidating]
  )

  return (
    loading ? <Loader /> :
      <Report
        tokenType="Aad"
        accessToken={ accessToken }
        embedUrl="https://app.powerbi.com/reportEmbed"
        embedId="647597c9-d38a-42da-be04-2c0fd1129297"
        groupId ="049c3fb9-864c-4001-bfc1-1df372818bad"
        pageName="ReportSection"
        reportMode="View"
        permissions="Read"
        onLoad={ handleReportLoad }
        onRender={ handleReportRender }
        style={ {
          width: '100%',
          height: '120vh'
        } }
        extraSettings={ {
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
          hideErrors: false
        } }
      />
  )
}

export default PropertyCostsReportFragment
