import api from '../api'

const URL_POSTS = '/social-network/posts'
const URL_COMMENTS = '/social-network/comments'


export const getPosts = (params) => api.get(`/v1${ URL_POSTS }`, { params })

export const createPost = (params) => api.post(`/v1${ URL_POSTS }`, params)

export const getPostComments = (params = {}, postId = '' ) => api.get(`/v1${ URL_POSTS }/${ postId }/comments`, params)

export const createPostComment = (params = {}, postId = '' ) => api.post(`/v1${ URL_POSTS }/${ postId }/comments`, params)

export const deletePostComment = (commentId = '') => api.delete(`/v1${ URL_COMMENTS }/${ commentId }`)

export const editPostComment = (params = {}, commentId = '' ) => api.patch(`/v1${ URL_COMMENTS }/${ commentId }`, params)
