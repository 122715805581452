import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 20px;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const Subtitle = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const Text = styled(Typography)`
  color: ${ colors.text };
  font-size: 14px;
  margin-bottom: 15px;
`

export const TextUnderlined = styled(Typography).attrs({
  component: 'span'
})`
  width: max-content;
  text-decoration: underline;
  cursor: pointer;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const SimpleItem = styled.div`
  display: flex;
`
