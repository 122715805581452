// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
      <g>
        <g>
          <path fill="#6EC4E9" d="M52.1,122.4c0.2-0.6-0.1-1.2-0.7-1.4c-0.6-0.2-1.2,0-1.4,0.5h0L38,136.8c-0.2,0.3-0.5,0.6-0.6,1
            c-0.7,1.9,0.4,3.9,2.4,4.6c2.1,0.7,4.3-0.2,5.1-2h0L52.1,122.4L52.1,122.4L52.1,122.4z"/>
          <path fill="#6EC4E9" d="M108.8,121.1c-1-0.2-2,0.2-2.5,1l0,0l-34.8,53.7c-0.6,0.9-1.1,1.8-1.4,2.9c-1.6,5.4,2,11,8.1,12.4
            c6,1.4,12.2-1.7,13.9-7.1h0l8.4-27.9c0,0,0-0.1,0-0.1l0,0l9.8-32.5l0-0.2l0,0C110.6,122.5,109.9,121.4,108.8,121.1z"/>
          <path fill="#6EC4E9" d="M82.1,123.3c0.4-0.9-0.2-1.9-1.2-2.2c-0.9-0.3-1.9,0-2.3,0.7h0l-19.4,24.8c-0.4,0.5-0.7,1-1,1.6
            c-1.2,3,0.6,6.4,4,7.5c3.4,1.1,7-0.4,8.3-3.3h0L82,123.5L82.1,123.3L82.1,123.3z"/>
          <path fill="#6EC4E9" d="M142.1,123.3c0.4-0.9-0.2-1.9-1.2-2.2c-0.9-0.3-1.9,0-2.3,0.7h0l-19.4,24.8c-0.4,0.5-0.7,1-1,1.6
            c-1.2,3,0.6,6.4,4,7.5c3.4,1.1,7-0.4,8.3-3.3h0l11.5-28.9L142.1,123.3L142.1,123.3z"/>
        </g>
        <g>
          <g>
            <path fill="#D3D3D3" d="M175.1,72.2c-4.1-15.4-19.9-24.6-35.3-20.6c0,0-1.1,0.3-3.1,0.8c-4.9-18.3-21.6-31.8-41.5-31.8
              c-21.5,0-39.4,15.9-42.5,36.6c-0.7-0.2-1.1-0.3-1.1-0.3c-13-3.4-26.3,4.4-29.8,17.4c-0.8,3.1-1,6.2-0.7,9.1
              c-6.5,1.8-10.5,2.8-10.5,2.8c-6.1,1.6-9.8,7.9-8.1,14c1.4,5.4,6.5,8.8,11.8,8.5h132.1c0,0,5.5-0.3,8.2-1
              C170,103.6,179.2,87.7,175.1,72.2z"/>
          </g>
          <path fill="#D3D3D3" d="M197.4,52.8c-2.4-8.8-11.3-14-20.1-11.7c0,0-0.6,0.2-1.8,0.5c-2.8-10.4-12.3-18.1-23.6-18.1
            c-7.5,0-14.1,3.4-18.6,8.7c3.2,3.7,5.8,7.9,7.9,12.4c1.6-0.2,3.3-0.3,5-0.3c16.9,0,31.8,11.4,36.2,27.8c0.1,0.5,0.2,1,0.3,1.5
            c1-0.1,2.2-0.2,3-0.4C194.6,70.7,199.8,61.6,197.4,52.8z"/>
        </g>
      </g>
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
