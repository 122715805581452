import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import phone from '@smartcoop/forms/validators/phone.validator'
import required from '@smartcoop/forms/validators/required.validator'


const machineryRegister = ({ t }) => Yup.object().shape({

  description: Yup.string(),

  propertyId: flow(
    required({ t })
  )(Yup.string()),

  model: flow(
    required({ t })
  )(Yup.string()),

  machineTypeId: flow(
    required({ t })
  )(Yup.string().nullable()),

  machineBrandId: flow(
    required({ t })
  )(Yup.string().nullable()),

  year: flow(
    required({ t })
  )(Yup.string()),

  availableHours: flow(
    required({ t })
  )(Yup.string()),

  phone: flow(
    phone({ t }),
    required({ t })
  )(Yup.string()),

  contactInformation: Yup.string()

})

export default machineryRegister
