/* eslint-disable react/destructuring-assignment */
import React, { PureComponent, useRef, useImperativeHandle } from 'react'

import MaterialTable from 'material-table'
import moment from 'moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import flow from 'lodash/fp/flow'
import get from 'lodash/get'
import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import omitBy from 'lodash/omitBy'
import snakeCase from 'lodash/snakeCase'

import { ThemeProvider, withStyles } from '@material-ui/core/styles'

import { withT } from '@smartcoop/i18n'
import { emptyFilter } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import { momentFriendlyDateFormat, momentFriendlyTimeFormat } from '@smartcoop/utils/dates'
import { PaginationContext } from '@smartcoop/web-app/src/hooks/usePagination'
import EmptyState from '@smartcoop/web-components/EmptyState'

import Header from './components/Header/Header'
import Pagination from './components/Pagination/Pagination'
import datagridI18 from './i18n'
import icons from './icons'
import options from './options'
import styles from './styles'

class DataTable extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static contextType = PaginationContext

  configs = undefined

  queryParams = undefined

  mounted = false

  constructor(props) {
    super(props)


    // eslint-disable-next-line react/prop-types
    this.i18n = datagridI18(props?.t)

    this.state = {
      filteredRows: [],
      lastRequestDate: null,
      internalLoading: false,
      internalPageSize: !this?.props?.hasPagination ? 99999 : this.props?.pageSize
    }

    this.mounted = true
  }


  componentDidMount() {
    const {
      pageSize,
      hasPagination
    } = this.props


    if(pageSize !== this.state.internalPageSize && hasPagination) {
      this.setState({ internalPageSize: pageSize })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      urlParams,
      queryParams,
      tableRef,
      pageSize,
      hasPagination,
      data
    } = this.props

    if (
      (isFunction(data) && !isEqual(prevProps?.data, data))
      || !isEqual(prevProps?.urlParams, urlParams)
      || !isEqual(prevProps?.queryParams, queryParams)
    ) {
      if (tableRef) {
        const { query } = tableRef.current.state
        tableRef.current.onQueryChange({
          ...query,
          page: this.context.page[this.props?.id] + 1
        })
      }
    }

    if (hasPagination && pageSize !== prevProps?.pageSize) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ internalPageSize: pageSize })
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  asyncData = async (query) => {
    const {
      t,
      onDataLoad,
      data,
      params,
      urlParams,
      queryParams,
      hasPagination
    } = this.props

    try {
      if (this.mounted) {

        this.setState({ internalLoading: true })

        const { page: contextPage } = this.context
        // const filters = get(query, 'filters')
        const searchTerm = get(query, 'search')
        const size = get(query, 'pageSize', this.state.internalPageSize)
        const orderBy = get(query, 'orderBy.field')
        const orderDirection = get(query, 'orderDirection')
        let page = contextPage[this.props?.id] || 0

        const currentConfigs = {
          size,
          searchTerm,
          orderBy,
          orderDirection
        }

        if (!isNil(this.configs) && (!isEqual(currentConfigs, this.configs) || !isEqual(queryParams, this.queryParams))) {
          page = 0 // if has changes, return to first page
        }

        this.queryParams = { ...queryParams }
        this.configs = { ...currentConfigs }

        let paginationConfig = {
          page: page + 1,
          limit: size
        }
        if (!isEmpty(orderBy)) {
          paginationConfig = {
            ...paginationConfig,
            orderBy: `${ orderDirection === 'desc' ? '-' : '' }${ snakeCase(orderBy) }`
            // orderDirection
          }
        }
        if (!isEmpty(searchTerm)) {
          paginationConfig = {
            ...paginationConfig,
            q: searchTerm
          }
        }
        if (!isEmpty(queryParams)) {
          paginationConfig = {
            ...omitBy(queryParams, param => !isBoolean(param) && param !== 0 && isEmpty(param)),
            ...paginationConfig
          }
        }

        let datagridData = []
        let totalRecords = 0

        if(!hasPagination) {
          const { data: newData } = await data({ ...params }, urlParams)
          datagridData = newData
        } else {
          const { data: { data: newData, totalRecords: newTotalRecords } } = await data({ ...params, ...paginationConfig }, urlParams)
          datagridData = newData
          totalRecords = newTotalRecords
        }

        if (this.mounted) {
          this.setState({ internalLoading: false })
          this.setState({
            lastRequestDate: moment().format(`${ momentFriendlyDateFormat } [às] ${ momentFriendlyTimeFormat }`)
          })

          onDataLoad(datagridData)

          return {
            data: datagridData,
            page,
            totalCount: totalRecords || 0
          }
        }
      }
      return {}
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(this.i18n.fetchLoadError, e)
      throw t('contact your administrator')
    }
  }

  render() {
    const {
    // eslint-disable-next-line react/prop-types
      t,
      classes,
      data,
      options: propOptions,
      noShadow,
      title,
      // onAddClick,
      // addTitle,
      // addTooltip,
      onEditClick,
      conditionToDelete,
      disabledEdit,
      onDeleteClick,
      onExportClick,
      onRowClick,
      pageSize,
      loading,
      disabled,
      disabledRow,
      onSelectionChange,
      tableBodyClassName,
      tableHeaderClassName,
      sortLabelClasses,
      components,
      emptyMessage,
      actions: customActions,
      hideLastRequestDate,
      style: externalStyle,
      darkMode,
      detailPanel,
      hideRowClick,
      id,
      hidePagination,
      ...otherProps
    } = this.props

    let { columns } = this.props

    const { filteredRows, lastRequestDate, internalLoading } = this.state

    let newActions = []

    if (onExportClick) {
      const ExportIcon = icons.Export
      newActions = [
        {
          icon: ExportIcon,
          tooltip: t('datagrid-toolbar-export-title'),
          onClick: onExportClick,
          isFreeAction: true,
          position: 'toolbar',
          disabled: loading || disabled
        },
        ...newActions
      ]
    }

    // if (onAddClick) {
    //   const AddIcon = icons.Add
    //   newActions = [
    //     {
    //       icon: AddIcon,
    //       tooltip: addTooltip,
    //       onClick: onAddClick,
    //       isFreeAction: true,
    //       position: 'toolbar',
    //       disabled: loading || disabled,
    //       iconButtonProps: {
    //         color: 'secondary',
    //         size: 'small',
    //         style: {
    //           marginLeft: addTitle ? 5 : 0
    //         }
    //       },
    //       title: <span style={ { lineHeight: '0.1em' } }>{addTitle}</span>,
    //       iconProps: {
    //         style: {
    //           marginRight: addTitle ? 8 : 0
    //         }
    //       }
    //     },
    //     ...newActions
    //   ]
    // }

    if (onRowClick) {
      const AccessRowIcon = icons.AccessRow
      newActions = [
        (row) => {
          const rowIsDisabled = disabledRow(row)
          return {
            icon: AccessRowIcon,
            tooltip: !rowIsDisabled ? t('datagrid body access tooltip') : undefined,
            onClick: onRowClick,
            disabled: loading || disabled || rowIsDisabled,
            iconButtonProps: {
              size: 'small',
              style: {
                display: 'block'
              }
            },
            position: 'row'
          }
        },
        ...newActions
      ]
    }

    if (onDeleteClick) {
      const DeleteIcon = icons.Delete
      newActions = [
        (row) => {
          const rowDisabled = loading || disabled || !conditionToDelete(row)
          return {
            icon: (props) => <DeleteIcon { ...props } disabled={ rowDisabled }/>,
            tooltip: rowDisabled ? '' : t('datagrid body delete tooltip'),
            onClick: onDeleteClick,
            disabled: rowDisabled,
            iconProps: { disabled: rowDisabled },
            iconButtonProps: {
              size: 'small'
            },
            position: 'row'
          }
        },
        ...newActions
      ]
    }

    if (onEditClick) {
      const EditIcon = icons.Edit
      newActions = [
        (row) => {
          const rowDisabled = loading || disabled || disabledEdit(row)
          return {
            icon: (props) => <EditIcon { ...props } disabled={ rowDisabled }/>,
            tooltip: rowDisabled ? '' : t('datagrid body edit tooltip'),
            onClick: onEditClick,
            disabled: rowDisabled,
            iconProps: { disabled: rowDisabled },
            iconButtonProps: {
              size: 'small'
            },
            position: 'row'
          }
        },
        ...newActions
      ]
    }

    const actions = map(
      [...customActions, ...newActions],
      (action) => {
        if (isFunction(action)) return action
        return {
          ...action,
          onClick: action.position === 'toolbarOnSelect'
            ? () => action.onClick(filteredRows)
            : action.onClick
        }
      }
    )

    columns = map(columns, (column) => ({
      sorting: false,
      emptyValue: column.render ? undefined : '-',
      ...column
    }))

    const customOptions = {
      selectionProps: (row) => {
        const rowDisabled = disabledRow(row)
        const response = {
        // color: 'primary',
          disabled: rowDisabled
        }
        if (rowDisabled) {
          response.checked = false
        }
        return response
      },
      ...options,
      ...propOptions,
      showTitle: !isEmpty(title),
      pageSize: this.state.internalPageSize,
      searchFieldProps: {
        ...options.searchFieldProps,
        ...get(propOptions, 'searchFieldProps', {}),
        classes: {
          root: classes.searchField,
          ...get(options, 'searchFieldProps.classes', {}),
          ...get(propOptions, 'searchFieldProps.classes', {})
        },
        InputProps: {
          endAdornment: null
        }
      }
    // tableBodyProps: {
    //   className: classes.stripedRows
    // }
    }

    let style = { ...externalStyle }
    if (noShadow) {
      style = {
        ...style,
        boxShadow: 'none'
      }
    }

    let realData = data
    let customProps = {}
    if (isFunction(data)) {
      realData = this.asyncData
    } else {
      customProps = {
        ...customProps,
        isLoading: loading
      }
    }

    const i18n = {
      ...(this.i18n || {}),
      body: {
        ...get(this.i18n, 'body', {}),
        emptyDataSourceMessage: <div style={ { height: 300, display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' } }>
          <EmptyState
            text={ t('no results found') }
            icon={ emptyFilter }
          />
        </div>
      }
    }

    return (
      <ThemeProvider
        theme={ (theme) => ({
          ...theme,
          overrides: {
            ...theme.overrides,
            MuiTableCell: {
              ...(theme.overrides.MuiTableCell || {}),
              root: {
                borderBottomColor: `${ colors.lightGrey } !important`,
                fontSize: fonts.fontSize.M,
                padding: 0,
                paddingLeft: 16,
                paddingRight: 16,
                paddingBottom: 8,
                paddingTop: 8
              },
              body: {
                fontSize: fonts.fontSize.M
              },
              head: {
                fontWeight: fonts.fontWeight.bold,
                backgroundColor: `${ darkMode ? colors.lightGrey : colors.white } !important`
              }
            },
            MuiTablePagination: {
              ...(theme.overrides.MuiTablePagination || {}),
              root: {
                padding: '0 5px !important'
              }
            },
            MuiPaper: {
              ...(theme.overrides.MuiPaper || {}),
              root: {
                width: '100%',
                backgroundColor: darkMode ? colors.backgroundHtml : colors.white
              },
              rounded: {
                borderRadius: 4
              }
            },
            MuiCheckbox: {
              ...(theme.overrides.MuiCheckbox || {}),
              root: {
                marginLeft: '5px !important'
              }
            },
            MuiTypography: {
              ...(theme.overrides.MuiTypography || {}),
              caption: {
                padding: '0 5px'
              }
            }
          }
        }) }
      >
        <MaterialTable
          { ...otherProps }
          { ...customProps }
          detailPanel={ detailPanel }
          icons={ icons }
          options={ customOptions }
          columns={ columns }
          data={ realData }
          localization={ i18n }
          onRowClick={ onRowClick }
          disabledRow={ disabledRow }
          style={ style }
          title={ title }
          actions={ actions }
          components={ {
            Header: (headerProps) => (
              <Header
                { ...headerProps }
                tableHeaderClassName={ tableHeaderClassName }
                sortLabelClasses={ sortLabelClasses }
              />
            ),
            Pagination: paginationProps => (
              <>
                <Pagination
                  id={ id }
                  hidePagination={ hidePagination }
                  lastRequestDate={ lastRequestDate }
                  hideLastRequestDate={ hideLastRequestDate }
                  disabled={ loading || disabled || internalLoading }
                  { ...paginationProps }
                  onChangeRowsPerPage={ (e) => {
                    this.setState({ internalPageSize: e.target.value })
                    paginationProps.onChangeRowsPerPage(e)
                  } }
                />
              </>
            ),
            ...components
          } }
          onSelectionChange={ (rows) => {
            const newFilteredRows = filter(rows, (row) => !disabledRow(row))
            const rowsById = newFilteredRows.reduce((acc, row) => {
              acc[row.id] = row
              return acc
            }, {})
            onSelectionChange(rowsById)
            this.setState({ filteredRows: rowsById })
          } }
        />
      </ThemeProvider>
    )
  }
}

DataTable.propTypes = {
  /** - */
  classes: PropTypes.object.isRequired,
  /** injected by @smartcoop/i18n */
  t: PropTypes.func.isRequired,
  /** columns by material-table */
  columns: PropTypes.array.isRequired,
  /** actions by material-table */
  actions: PropTypes.array,
  detailPanel: PropTypes.array,
  id: PropTypes.string.isRequired,
  /** data by material-table */
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func
  ]),
  /** options by material-table */
  options: PropTypes.object,
  /** params to inject into request */
  params: PropTypes.object,
  /** pageSize by material-table */
  pageSize: PropTypes.number,
  /** remove shadow of the datagrid container */
  noShadow: PropTypes.bool,
  hideRowClick: PropTypes.bool,
  /** title by material-table */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /* Enable Add button, and set the callback called when this button is clicked */
  // onAddClick: PropTypes.func,
  /** tooltip for add button */
  // addTooltip: PropTypes.string,
  /** Triggers a function when the async data finishs the loading, returns data */
  onDataLoad: PropTypes.func,
  /** title for add button */
  // addTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onSelectionChange: PropTypes.func,
  /** Enable Edit button, and set the callback called when this button is clicked */
  onEditClick: PropTypes.func,
  /** Enable Delete button, and set the callback called when this button is clicked */
  onDeleteClick: PropTypes.func,

  conditionToDelete: PropTypes.func,
  /** Disable Edit button for a given row */
  disabledEdit: PropTypes.func,
  /** Enable Export button, and set the callback called when this button is clicked */
  onExportClick: PropTypes.func,
  /** Enable Row click */
  onRowClick: PropTypes.func,
  /** datagrid is loading */
  loading: PropTypes.bool,
  /** disable all actions */
  disabled: PropTypes.bool,
  /** has pagination */
  hasPagination: PropTypes.bool,
  /** customize row when row was softdeleted */
  disabledRow: PropTypes.func,
  /** customize row when row was softdeleted */
  emptyMessage: PropTypes.string,
  urlParams: PropTypes.object,
  queryParams: PropTypes.object,
  tableBodyClassName: PropTypes.string,
  tableHeaderClassName: PropTypes.string,
  sortLabelClasses: PropTypes.object,
  components: PropTypes.object,
  tableRef: PropTypes.any,
  hideLastRequestDate: PropTypes.bool,
  hidePagination: PropTypes.bool,
  style: PropTypes.object,
  darkMode: PropTypes.bool
}

DataTable.defaultProps = {
  data: [],
  options: {},
  params: {},
  noShadow: false,
  hasPagination: true,
  hidePagination: false,
  actions: [],
  pageSize: 10,
  title: '',
  style: {},
  // onAddClick: null,
  // addTooltip: '',
  // addTitle: null,
  onSelectionChange: () => [],
  onDataLoad: () => {},
  onEditClick: null,
  onDeleteClick: null,
  conditionToDelete: () => true,
  disabledEdit: () => false,
  onExportClick: null,
  onRowClick: undefined,
  loading: undefined,
  disabled: false,
  disabledRow: () => false,
  emptyMessage: null,
  urlParams: {},
  tableBodyClassName: null,
  tableHeaderClassName: null,
  detailPanel: null,
  sortLabelClasses: {},
  components: {},
  queryParams: {},
  tableRef: {},
  hideLastRequestDate: false,
  hideRowClick: false,
  darkMode: false
}

export default flow(
  withStyles(styles),
  withT
)((props) => {
  // eslint-disable-next-line
  const { tableRef, ...rest } = props

  const ref = useRef(null)

  useImperativeHandle(tableRef, () => ref.current)

  return <DataTable tableRef={ ref } { ...rest } />
})

export { DataTable as DataTableProps }
