import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background-color: ${ colors.lightGrey };
  padding: 5px 15px;
  height: 52px;
`

export const NameText = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${ colors.text };
`

export const InfoWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: max-content 1fr;
  column-gap: 10px;
  cursor: pointer;
`

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
`

export const ConversationDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const RightContainer = styled.div`
  display: flex;
  align-self: right;
`

export const MembersSpan = styled.span`
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 950px);
`
