import React from 'react'

import phoneMask from '@smartcoop/forms/masks/phone.mask'
import TextField from '@smartcoop/web-components/TextField'

const InputPhone = (props) => (
  <TextField
    { ...props }
    type="tel"
    setMask={ phoneMask }
  />
)

export default InputPhone
