import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { LotActions } from '@smartcoop/stores/lot'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterLotForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterLotForm'

import useStyles from './styles'

const RegisterLotModal = (props) => {
  const {
    id,
    open,
    onSubmit,
    handleClose,
    lot
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()
  const currentProperty = useSelector(selectCurrentProperty)

  const [isLoading, setIsLoading] = useState(false)

  const isEditing = useMemo(
    () => (!isEmpty(lot?.id)),[lot]
  )
  const defaultValues = useMemo(
    () => (
      {
        code: '',
        name: '',
        property: {
          id: currentProperty?.id
        },
        ...lot
      }
    ), [currentProperty, lot]
  )

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      handleClose()
    }, [handleClose]
  )

  const onSuccess = useCallback(
    () => {
      snackbar.success(
        t(`your {this} was ${  isEditing ? 'edited' :'registered' }`, {
          howMany: 1,
          gender: 'male',
          this: t('lot', { howMany: 1 })
        })
      )
      onSubmit()
      closeModal()

    }, [closeModal, isEditing, onSubmit, snackbar, t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(LotActions.saveLot(
        {
          ...lot,
          ...data
        },
        onSuccess,
        () => setIsLoading(false)
      ))
    },
    [dispatch, lot, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n>register lots</I18n>
        </>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        {
          isLoading ? <Loader width={ 100 } /> :
            <RegisterLotForm
              ref={ formRef }
              defaultValues={ defaultValues }
              onSubmit={ handleSubmit }
              onCancel={ closeModal }
            />
        }
      </>
    </Modal>
  )}

RegisterLotModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  lot: PropTypes.object
}

RegisterLotModal.defaultProps = {
  handleClose: () => {},
  onSubmit: () => {},
  lot: {}
}

export default RegisterLotModal
