import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import { barn } from '@smartcoop/icons'
import Button from '@smartcoop/web-components/Button'
import Card from '@smartcoop/web-components/Card'
import Icon from '@smartcoop/web-components/Icon'
import PropertyActivityDetailsForm from '@smartcoop/web-containers/forms/digitalProperty/property/PropertyActivityDetailsForm'
import { Item, Title, ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { IconContainer } from './styles'

const PropertyActivityDetailsScreen = (props) => {
  const { onConfirm, onCancel, isCreate } = props
  const formRef = useRef(null)

  return (
    <Card style={ { marginTop: 0 } } cardStyle={ { minWidth: 450 } }>
      <Item>
        <IconContainer>
          <Icon icon={ barn } size={ 25 } />
        </IconContainer>

        <Title>
          <I18n>property activities</I18n>
        </Title>

        <PropertyActivityDetailsForm
          style={ { display: 'flex', flexDirection: 'column' } }
          ref={ formRef }
          onSubmit={ onConfirm }
          withoutSubmitButton
          isCreate={ isCreate || false }
        />

        <ButtonsContainer>
          <Button
            id="cancel-property-activity-details-form"
            onClick={ onCancel }
            variant="outlined"
            style={ { width: '40%', flex: '0 0 auto' } }
          >
            <I18n>go back</I18n>
          </Button>

          <Button
            id="submit-property-activity-details-form"
            onClick={ () => formRef.current.submit() }
            style={ { width: '40%', flex: '0 0 auto' } }
          >
            <I18n>next</I18n>
          </Button>
        </ButtonsContainer>
      </Item>
    </Card>
  )
}

PropertyActivityDetailsScreen.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isCreate: PropTypes.bool
}

PropertyActivityDetailsScreen.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  isCreate: false
}

export default PropertyActivityDetailsScreen
