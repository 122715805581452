import React, { useRef, useCallback } from 'react'

import PropTypes from 'prop-types'

import Modal from '@smartcoop/web-components/Modal'
import RegisterAmountPaidForm from '@smartcoop/web-containers/forms/shoppingPlatform/order/RegisterAmountPaidForm'

const RegistrationAmountPaidModal = (props) => {
  const { id, open, handleClose, productId, productUnit } = props
  const registerAmountPaidFormRef = useRef(null)

  const onSuccess = useCallback(
    () => handleClose(),
    [handleClose]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      disableFullScreen
    >
      <RegisterAmountPaidForm
        ref={ registerAmountPaidFormRef }
        onSuccess={ onSuccess }
        productId={ productId }
        productUnit={ productUnit }
      />
    </Modal>
  )
}

RegistrationAmountPaidModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  productId: PropTypes.string,
  productUnit: PropTypes.string
}

RegistrationAmountPaidModal.defaultProps = {
  productId: null,
  productUnit: null
}

export default RegistrationAmountPaidModal
