import React, { useCallback, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { OrderActions } from '@smartcoop/stores/order'
import { selectCurrentOrder } from '@smartcoop/stores/order/selectorOrder'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import JoinOrderForm from '@smartcoop/web-containers/forms/shoppingPlatform/order/JoinOrderForm'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'


const JoinOrderScreen = () => {
  const history = useHistory()
  const location = useLocation()
  const { orderId } = useParams()
  const { routes } = useRoutes()

  const dispatch = useCallback(useDispatch(), [])

  const joinOrderRef = useRef(null)

  const currentOrder = useSelector(selectCurrentOrder)
  const currentOrganization = useSelector(selectCurrentOrganization)
  const isEditing = useMemo(() => location.state?.isEditing || false, [
    location.state
  ])

  const accepted = useMemo(() => location.state?.accepted, [
    location.state
  ])

  const onSuccess = useCallback(
    () => {
      history.replace(routes.orderDetails.path.replace(':orderId', currentOrder.id))
    },
    [currentOrder.id, history, routes.orderDetails.path])

  useEffect(() => {
    dispatch(
      OrderActions.loadCurrentOrder(
        orderId,
        () => {},
        () => history.replace(routes.orderList.path),
        accepted
      )
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization, orderId, accepted])

  useEffect(
    () => () => {
      dispatch(OrderActions.resetCurrentOrder())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  )

  return (
    <JoinOrderForm
      ref={ joinOrderRef }
      onSuccess={ onSuccess }
      isEditing={ isEditing }
    />
  )
}

export default JoinOrderScreen
