import React, { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import I18n, { useT } from '@smartcoop/i18n'
import { trash } from '@smartcoop/icons'
import {
  getProductsPriceHistoryByOrganization,
  getProductsPriceHistory
} from '@smartcoop/services/apis/smartcoopApi/resources/productPriceHistory'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { ProductsPriceHistoryActions } from '@smartcoop/stores/productsPriceHistory'
import colors from '@smartcoop/styles/colors'
import { momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import Modal from '@smartcoop/web-components/Modal'

const ViewListAmountPaidModal = (props) => {
  const { id, open, productId, organizationId, handleClose } = props
  const t = useT()

  const dispatch = useCallback(useDispatch(), [])

  const currentOrganization = useSelector(selectCurrentOrganization)

  const deleteHistoryPrice = useCallback(
    (data) => {
      dispatch(
        ProductsPriceHistoryActions.deleteProductPriceHistory(
          data.id,
          data.product.id,
          data.organizationId
        )
      )
      handleClose()
    },
    [dispatch, handleClose]
  )

  const columns = useMemo(
    () => [
      {
        title: 'Valor Pago',
        field: 'price',
        sorting: true,
        render: (row) =>
          row.price.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          })
      },
      {
        title: 'Unidade',
        field: 'unit'
      },
      {
        title: t('date of purchase'),
        field: 'date',
        type: 'date',
        sorting: true,
        render: (row) => moment(row.date).format(momentFriendlyDateFormat)
      },
      {
        render: (row) => (
          <Button
            id="delete-history-price"
            variant="outlined"
            color={ colors.text }
            onClick={ () => deleteHistoryPrice(row) }
            style={ {
              borderColor: colors.lightGrey,
              backgroundColor:
                currentOrganization.id !== row.organizationId
                  ? colors.lightGrey
                  : colors.white
            } }
            disabled={ currentOrganization.id !== row.organizationId }
          >
            <Icon color={ colors.red } icon={ trash } size={ 16 } />
          </Button>
        )
      }
    ],
    [currentOrganization.id, deleteHistoryPrice, t]
  )

  const urlParamsPricesHistory = useMemo(
    () => (organizationId ? { productId, organizationId } : { productId }),
    [organizationId, productId]
  )

  const ServiceDataPricesHistory = useMemo(
    () =>
      organizationId
        ? getProductsPriceHistoryByOrganization
        : getProductsPriceHistory,
    [organizationId]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      contentContainerStyle={ { width: 600 } }
      disableFullScreen
    >
      <div style={ { fontSize: 18, fontWeight: 600, marginBottom: 20 } }>
        <I18n>paid price history</I18n>
      </div>
      <DataTable
        options={ { paging: false } }
        data={ ServiceDataPricesHistory }
        urlParams={ urlParamsPricesHistory }
        columns={ columns }
        id="view-list-amout-paid-table"
      />
    </Modal>
  )
}

ViewListAmountPaidModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  productId: PropTypes.string,
  organizationId: PropTypes.string
}

ViewListAmountPaidModal.defaultProps = {
  productId: null,
  organizationId: ''
}

export default ViewListAmountPaidModal
