import React, { useCallback, forwardRef, useMemo, useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import toString from 'lodash/toString'
import uniqBy from 'lodash/uniqBy'


import endOfSeasonReportSchema from '@smartcoop/forms/schemas/property/reports/endOfSeasonReport.schema'
import { useT } from '@smartcoop/i18n'
import { getCrops } from '@smartcoop/services/apis/smartcoopApi/resources/crop'
import { createGrowingSeasonReport } from '@smartcoop/services/apis/smartcoopApi/resources/growingSeasons'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { generateArrayOfDate } from '@smartcoop/utils/dates'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'

import { RequiredText, Row, Subtitle } from './styles'

const EndOfSeasonsReportForm = forwardRef((props, formRef) => {
  const { onSubmit, setDisabledSave } = props
  const t = useT()
  const propertyId = useSelector(selectCurrentPropertyId)

  const [fields, setFields] = useState([])
  const [cropIds, setCropIds] = useState([])
  const [sowingYears, setSowingYears] = useState([])
  const [isPlanned, setIsPlanned] = useState(false)
  const [isClosed, setIsClosed] = useState(false)
  const [isCurrent, setIsCurrent] = useState(true)
  const [cropManagement, setCropManagement] = useState(true)
  const [timeline, setTimeline] = useState(true)
  const [plagueReport, setPlagueReport] = useState(true)

  const disableFields = useMemo(() => isEmpty(cropIds) || isEmpty(sowingYears),[cropIds, sowingYears])

  const getFields = useRef(debounce(async (crops, years, property) => {
    const { data: { data } } = await createGrowingSeasonReport({ cropIds: crops, sowingYears: years, propertyId: property, limit: 9999, management: false, timeline: false, plagueReport: false, isPlanned: 'true', isClosed: 'true', isCurrent: 'true' })

    const filtered = uniqBy(data, 'fieldId')

    setFields(map(filtered, item => ({
      value: item?.fieldId,
      label: item?.fieldData?.fieldName
    })))
  }, 800))

  useEffect(() => {
    if(!disableFields) {
      getFields.current(cropIds, sowingYears, propertyId)
    }
  },[cropIds, disableFields, getFields, propertyId, sowingYears])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data,
        isPlanned: toString(isPlanned),
        isClosed: toString(isClosed),
        isCurrent: toString(isCurrent),
        cropManagement,
        timeline,
        plagueReport
      })
    },
    [cropManagement, isClosed, isCurrent, isPlanned, onSubmit, plagueReport, timeline]
  )

  const years = useMemo(
    () => generateArrayOfDate(moment().subtract(10, 'year'), moment().add(2, 'year'), 'year'),
    []
  )

  const sowingYearOptions = useMemo(
    () => map(years, (date) => ({ value: date.format('YYYY'), label: date.format('YYYY') })),
    [years]
  )

  const disabledSave = useMemo(() => !isCurrent && !isClosed && !isPlanned,[isClosed, isCurrent, isPlanned])

  useEffect(() => {
    setDisabledSave(disabledSave)
  },[disabledSave, setDisabledSave])
  return (
    <Form
      ref={ formRef }
      schemaConstructor={ endOfSeasonReportSchema }
      onSubmit={ handleSubmit }
      containerStyle={ { height: '70vh', overflowY: 'scroll', paddingTop: 10 } }
    >
      <Row>
        <InputSelect
          label={ t('crops', { howMany: 2 }) }
          name="cropIds"
          options={ getCrops }
          onChange={ ({ target: { value } }) => setCropIds(value) }
          multiple
          asyncOptionLabelField="description"
          asyncOptionValueField="id"
        />
      </Row>
      <Row>
        <InputSelect
          label={ t('sowing year') }
          name="sowingYears"
          onChange={ ({ target: { value } }) => setSowingYears(value) }
          options={ sowingYearOptions }
          orderDirection="desc"
          multiple
        />
      </Row>
      <Row>
        <InputSelect
          label={ t('fields', { howMany: 2 }) }
          name="fieldIds"
          options={ fields }
          disabled={ disableFields || isEmpty(fields) }
          loading={ isEmpty(fields) && !disableFields }
          disabledWhenLoading
          multiple
          urlParams={ { propertyId } }
        />
      </Row>
      <Subtitle>
        {t('should show fields')}
      </Subtitle>
      <RequiredText style={ { opacity: disabledSave ? 1 : 0 } }>
        {t('select at least one option')}
      </RequiredText>
      <CheckboxButton
        label={ t('current growing seasons') }
        onChange={ () => setIsCurrent(!isCurrent) }
        checked={ isCurrent }
        value={ isCurrent }
        hideColor
      />
      <CheckboxButton
        label={ t('closeds') }
        onChange={ () => setIsClosed(!isClosed) }
        checked={ isClosed }
        value={ isClosed }
        hideColor
      />
      <CheckboxButton
        label={ t('planneds') }
        onChange={ () => setIsPlanned(!isPlanned) }
        checked={ isPlanned }
        value={ isPlanned }
        hideColor
      />
      <Row>
        <Subtitle>
          {t('more report options')}
        </Subtitle>
      </Row>
      <CheckboxButton
        label={ t('managements') }
        onChange={ () => setCropManagement(!cropManagement) }
        checked={ cropManagement }
        value={ cropManagement }
        hideColor
      />
      <CheckboxButton
        label={ t('timeline') }
        onChange={ () => setTimeline(!timeline) }
        checked={ timeline }
        value={ timeline }
        hideColor
      />
      <CheckboxButton
        label={ t('plague report') }
        onChange={ () => setPlagueReport(!plagueReport) }
        checked={ plagueReport }
        value={ plagueReport }
        hideColor
      />
    </Form>
  )
})

EndOfSeasonsReportForm.propTypes = {
  onSubmit: PropTypes.func,
  setDisabledSave: PropTypes.func
}

EndOfSeasonsReportForm.defaultProps = {
  onSubmit: () => {},
  setDisabledSave: () => {}
}

export default EndOfSeasonsReportForm
