import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const registerPriceData = ({ t }) => Yup.object().shape({

  name: flow(
    required({ t })
  )(Yup.string()),

  slug: flow(
    required({ t })
  )(Yup.string())

})

export default registerPriceData
