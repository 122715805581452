import React, { useState, useMemo } from 'react'

import PropTypes from 'prop-types'

import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import Fade from '@material-ui/core/Fade'

import ArrowDownIconButton from '@smartcoop/web-components/IconButton/ArrowDownIconButton'
import ArrowUpIconButton from '@smartcoop/web-components/IconButton/ArrowUpIconButton'

import { Container, Row } from './styles'

const CardAccordion = (props) => {
  const {
    children,
    defaultOpened,
    hideButton,
    headerRight,
    spacingIconLeft,
    ...rest
  } = props

  const [opened, setOpened] = useState(defaultOpened)

  const IconButton = useMemo(
    () => opened ? ArrowUpIconButton : ArrowDownIconButton,
    [opened]
  )

  return (
    <Container
      { ...rest }
      headerRight={
        <Row>
          {headerRight}
          {hideButton ? <div style={ { width: 35 } } /> : (
            <IconButton size="small" onClick={ () => setOpened(old => !old) } style={ { marginLeft: `${ spacingIconLeft }` } } />
          )}
        </Row>
      }
    >
      <Collapse in={ opened }>
        <Fade in={ opened }>
          <CardContent style={ { padding: 0 } }>
            {children}
          </CardContent>
        </Fade>
      </Collapse>
    </Container>
  )
}

CardAccordion.propTypes = {
  children: PropTypes.any,
  headerRight: PropTypes.any,
  defaultOpened: PropTypes.bool,
  hideButton: PropTypes.bool,
  spacingIconLeft: PropTypes.string
}

CardAccordion.defaultProps = {
  children: null,
  headerRight: null,
  defaultOpened: false,
  hideButton: false,
  spacingIconLeft: null
}

export default CardAccordion
