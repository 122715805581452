import React, { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

import { useT } from '@smartcoop/i18n'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { selectTerms } from '@smartcoop/stores/authentication/selectorAuthentication'
import ItemSelection from '@smartcoop/web-components/ItemSelection'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import useTerm from '@smartcoop/web-containers/hooks/useTerm'

import useStyles from './styles'

const TermsListModal = ({ id, open }) => {
  const classes = useStyles()
  const [termModal] = useTerm('use-term', false, () => {}, () => {}, true)
  const [privacyTerm] = useTerm('privacy-term', false, () => {}, () => {}, true)
  const [commercializationTerm] = useTerm('commercialization-term', false, () => {}, () => {}, true)
  const [supplierTerm] = useTerm('supplier-term', false, () => {}, () => {}, true)
  const [organizationTerm] = useTerm('organization-term', false, () => {}, () => {}, true)
  const [socialNetworkTerm] = useTerm('social-network-term', false, () => {}, () => {}, true)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const terms = useSelector(selectTerms)
  const t = useT()

  useEffect(() => {
    dispatch(AuthenticationActions.loadTerms(() => setLoading(false), () => {}, true))

    return () => {
      dispatch(AuthenticationActions.loadTerms())
    }
  }, [dispatch])

  const options = useMemo(
    () => (
      filter([
        find(terms, term => term?.termType?.slug === 'use-term') && {
          value: 'use-term',
          label: t('use terms')
        },
        find(terms, term => term?.termType?.slug === 'privacy-term') && {
          value: 'privacy-term',
          label: t('privacy terms')
        },
        find(terms, term => term?.termType?.slug === 'commercialization-term') && {
          value: 'commercialization-term',
          label: t('comercialization terms')
        },
        find(terms, term => term?.termType?.slug === 'supplier-term') && {
          value: 'supplier-term',
          label: t('supplier terms')
        },
        find(terms, term => term?.termType?.slug === 'organization-term') && {
          value: 'organization-term',
          label: t('organization terms')
        }
      ], (item) => !isEmpty(item))
    ),
    [t, terms]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={ t('platform terms') }
      maxWidth="xs"
      disableFullScreen
      escapeWhenLoading
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ {
        padding: '15px 0 0 0'
      } }
      headerProps={ {
        titleClass: classes.title
      } }
    >
      {loading ? <Loader /> : (
        <ItemSelection
          options={ options }
          onChange={ ({ value }) => {
            switch(value){
              case 'use-term':
                termModal(() => {}, true)
                break
              case 'privacy-term':
                privacyTerm(() => {}, true)
                break
              case 'commercialization-term':
                commercializationTerm(() => {}, true)
                break
              case 'supplier-term':
                supplierTerm(() => {}, true)
                break
              case 'organization-term':
                organizationTerm(() => {}, true)
                break
              case 'social-network-term':
                socialNetworkTerm(() => {}, true)
                break
              default:
                break
            }
          } }
        />
      ) }
    </Modal>
  )}

TermsListModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
}

export default TermsListModal
