import React, { useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { syncService } from '@smartcoop/database/services/syncService'
import { database } from '@smartcoop/database/web-database'
import { MessagingActions } from '@smartcoop/stores/messaging'
import { selectUser } from '@smartcoop/stores/user/selectorUser'

import Contacts from './fragments/contacts/Contacts/Contacts'
import Conversations from './fragments/conversations/Conversations/Conversations'
import ChatDetails from './fragments/details/ChatDetails/ChatDetails'
import ChatMessages from './fragments/messages/ChatMessages'
import ChatSearch from './fragments/search/ChatSearch'
import { useAuthenticatedUser } from './hooks/useAuthenticatedUser'
import { ChatContext, ChatProvider } from './providers/ChatProvider'
import ChatSyncProvider from './providers/ChatSyncProvider'
import ChatUserProvider from './providers/ChatUserProvider'
import { Container } from './styles'

const SCREENS = {
  CONVERSATION: 'conversation',
  CONTACTS: 'contacts',
  GROUP: 'group'
}

const ChatScreen = () => {
  const { screen, openedChat } = useContext(ChatContext)
  const [showSearch, setShowSearch] = useState(false)
  const [showDetails, setShowDetails] = useState(false)

  const currentUser = useSelector(selectUser)
  const dispatch = useDispatch()
  const localUser = useAuthenticatedUser()

  useEffect(() => {
    setShowSearch(false)
    setShowDetails(false)
  }, [openedChat])

  useEffect(() => {
    const updateUser = async () => {
      if (database) {
        await syncService(database).syncAuthenticatedUser(currentUser?.document, currentUser?.id)
      }
    }
    updateUser()
    dispatch(MessagingActions.setIsChatModule(true))
    dispatch(MessagingActions.readAllNotifications(true))
  },[currentUser, dispatch])

  useEffect(() => () => {
    dispatch(MessagingActions.setIsChatModule(false))
  },[dispatch])

  return (
    <Container showSearch={ showSearch || showDetails }>
      { screen === SCREENS.CONVERSATION && (
        <Conversations />
      ) }
      { screen === SCREENS.CONTACTS && (
        <Contacts/>
      ) }
      <ChatMessages
        conversationId={ openedChat?.conversationId || null }
        relationalId={ openedChat?.relationalId || null }
        userId={ localUser?.userId || null }
        handleShowSearch={ () => setShowSearch(state => !state) }
        handleShowDetails={ () => setShowDetails(state => !state) }
      />
      { Boolean(showDetails && (openedChat?.relationalId || openedChat?.conversationId)) && (
        <ChatDetails
          handleCloseDetails={ () => setShowDetails(false) }
          relationalId={ openedChat?.relationalId }
          conversationId={ openedChat?.conversationId }
          userId={ openedChat?.userId || null }
          localUserId={ localUser?.userId }
          localUser={ localUser }
        />
      ) }
      { Boolean(showSearch && !showDetails && (openedChat?.relationalId || openedChat?.conversationId)) && (
        <ChatSearch
          handleCloseSearch={ () => setShowSearch(false) }
          relationalId={ openedChat?.relationalId || null }
          conversationId={ openedChat?.conversationId || null }
        />
      ) }
    </Container>
  )
}

export default () => (
  <ChatUserProvider>
    <ChatSyncProvider>
      <ChatProvider>
        <ChatScreen />
      </ChatProvider>
    </ChatSyncProvider>
  </ChatUserProvider>
)

