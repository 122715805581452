import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  border-left: 1px solid #cbcbcb;
`

export const SearchWrapper = styled.div`
  padding: 10px;
  background-color: #f7f7f7;
`

export const ListWrapper = styled.div`
  padding: 10px;
  overflow-y: scroll;
  max-height: calc(100vh - 170px);
`
