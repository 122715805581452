import React, { useMemo }from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'


import { organizationRounded } from '@smartcoop/icons'
import { momentBackDateTimeFormat, momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import Icon from '@smartcoop/web-components//Icon'
import Avatar from '@smartcoop/web-components/Avatar'
import ReadMore from '@smartcoop/web-components/ReadMore'

import {
  Body,
  Col,
  Container,
  Header,
  Identifier,
  Name,
  Text,
  Timer,
  FlexDiv,
  Privacy,
  Type } from './styles'

const NonComplianceAnswer = (props) =>  {
  const { style, answer, length } = props
  const { comment, organization, user, createdAt } = answer

  const time = useMemo( () => moment(createdAt, momentBackDateTimeFormat).subtract(3, 'hours').format(momentFriendlyDateTimeFormat), [createdAt])

  return (
    <Container style={ style } >
      <Header>
        <Identifier>
          <Avatar alt={ user?.name || 'name' } src={ user?.image || 'image' } />
          <Col>
            <Name>{user?.name || 'name' }</Name>
            <Privacy>
              <Icon icon={ organizationRounded } />
              <Type>{organization?.tradeName || 'Organização'}</Type>
            </Privacy>
          </Col>
        </Identifier>
        <FlexDiv>
          <Timer>{time}</Timer>
        </FlexDiv>
      </Header>
      <Body>
        <Text>
          <ReadMore length={ length }>
            { comment }
          </ReadMore>
        </Text>
      </Body>
    </Container>
  )
}

NonComplianceAnswer.propTypes = {
  style: PropTypes.object,
  answer: PropTypes.shape({
    images: PropTypes.array,
    id: PropTypes.string,
    comment: PropTypes.string,
    userId: PropTypes.string,
    notComplianceId: PropTypes.string,
    createdAt: PropTypes.string,
    organization: PropTypes.shape({
      tradeName: PropTypes.string,
      companyName: PropTypes.string
    }),
    user: PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.string
    })
  }),
  length: PropTypes.number
}

NonComplianceAnswer.defaultProps = {
  style: {},
  length: 200,
  answer: {
    createdAt: '',
    images: [],
    id: '',
    comment: '',
    userId: '',
    notComplianceId: '',
    organization: {},
    user: {}
  }
}

export default NonComplianceAnswer
