import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding: 20px;
  overflow-y: scroll;
  max-height: ${ ({ hiddenFooter }) => hiddenFooter ? 'calc(100vh - 116px)' : 'calc(100vh - 210px)' };
  justify-content: ${ ({ empty }) => empty ? 'center' : 'inherit' };
`
