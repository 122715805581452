import React, { useEffect } from 'react'

import { useDialog } from '@smartcoop/dialog'
import { useRoutes } from '@smartcoop/web-containers/routes/guest'

import RouterSwitch from '../RouterSwitch'

const GuestScreenRouter = (props) => {
  const { routes } = useRoutes()
  const { resetDialogs } = useDialog()

  useEffect(() => {
    resetDialogs()
  }, [resetDialogs])

  return <RouterSwitch routes={ routes } { ...props } />
}

export default GuestScreenRouter
