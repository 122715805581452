import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { call, put, takeLatest } from 'redux-saga/effects'

import {
  getProductGroups,
  createProductGroup,
  editProductGroup,
  deleteProductGroup as deleteProductGroupService

} from '@smartcoop/services/apis/smartcoopApi/resources/productGroup'

import { ProductGroupActions, ProductGroupTypes } from './duckProductGroup'

function* loadProductGroups({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(ProductGroupActions.setLoadingProductGroup(true))
    const { data: { data, ...pagination } } = yield call(getProductGroups, {
      limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
      ...params
    })

    yield put(ProductGroupActions.loadProductGroupsSuccess(
      data,
      pagination.page,
      () => onSuccess(data)
    ))
  } catch (err) {
    const error = err.message
    yield put(ProductGroupActions.productGroupError(error))
    yield put(ProductGroupActions.setLoadingProductGroup(false))
    yield call(onError, error)
  }
}

function* loadProductGroupsSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
  yield put(ProductGroupActions.setLoadingProductGroup(false))
}

function* saveProductGroup({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(createProductGroup, params)
    yield call(onSuccess, data)
  } catch (error) {
    yield put(ProductGroupActions.productGroupError(error))
    yield call(onError, error)
  }
}

function* updateProductGroup({ params, productGroupId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(editProductGroup, params, { productGroupId })
    yield call(onSuccess, data)
  } catch (error) {
    yield put(ProductGroupActions.productGroupError(error))
    yield call(onError, error)
  }
}

function* deleteProductGroup({ productGroupId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteProductGroupService, { productGroupId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(ProductGroupActions.productGroupError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(ProductGroupTypes.LOAD_PRODUCT_GROUPS, loadProductGroups),
  takeLatest(ProductGroupTypes.LOAD_PRODUCT_GROUPS_SUCCESS, loadProductGroupsSuccess),

  takeLatest(ProductGroupTypes.SAVE_PRODUCT_GROUP, saveProductGroup),
  takeLatest(ProductGroupTypes.UPDATE_PRODUCT_GROUP, updateProductGroup),
  takeLatest(ProductGroupTypes.DELETE_PRODUCT_GROUP, deleteProductGroup)
]
