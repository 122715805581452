/* eslint-disable no-unused-expressions */
import React, { useRef, useCallback, useEffect } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import first from 'lodash/first'

import { database } from '@smartcoop/database'
import { profilePictureService } from '@smartcoop/database/services/profilePictureService'
import { blobToBase64 } from '@smartcoop/database/utils/base64Blob'
import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AllowedImageTypesExtensions, AllowedSizeFile } from '@smartcoop/utils/constants'
import useFile from '@smartcoop/web-containers/hooks/useFile'

import ChatAvatar from '../../../components/ChatAvatar/ChatAvatar'
import { HiddenInput } from './styles'

const ChatDetailsImage = ({ conversation, profilePictures, isAdmin }) => {
  const t = useT()
  const inputRef = useRef()
  const snackbar = useSnackbar()
  const profilePicture = first(profilePictures)
  const { selectedFiles, handleAdd, clearFiles } = useFile([], [])

  const handleSelectProfilePicture = useCallback(() => {
    if (isAdmin && (conversation.group || conversation.broadcast)) {
      inputRef.current?.click()
    }
  }, [conversation.broadcast, conversation.group, isAdmin])

  const handleUpdatePicture = useCallback(async (picture) => {
    if (picture.size > AllowedSizeFile) {
      snackbar.error(t('file exceeded the maximum upload size of 16MB'))
      return
    }

    try {
      const base64 = await blobToBase64(picture)

      await profilePictureService(database)
        .setConversationProfilePicture(conversation.conversationId, picture.name, base64)

      snackbar.success(t('image updated successfully'))
    } catch(error) {
      snackbar.error(t('an error occurred while updating the image'))
    }

  }, [conversation.conversationId, snackbar, t])

  useEffect(() => {
    if (selectedFiles?.length) {
      handleUpdatePicture(first(selectedFiles))
      clearFiles()
    }
  }, [clearFiles, handleUpdatePicture, selectedFiles])

  return (
    <>
      <ChatAvatar
        isGroup={ conversation.group }
        isBroadcast={ conversation.broadcast }
        sourceImage={ profilePicture?.source }
        size={ 75 }
        onClick={ handleSelectProfilePicture }
        clickable={ isAdmin && (conversation.group || conversation.broadcast) }
      />
      <HiddenInput
        type='file'
        ref={ inputRef }
        onChange={ handleAdd }
        accept={ AllowedImageTypesExtensions.join(', ') }
      />
    </>
  )
}

ChatDetailsImage.propTypes = {
  conversation: PropTypes.object.isRequired,
  profilePictures: PropTypes.array,
  isAdmin: PropTypes.bool
}

ChatDetailsImage.defaultProps = {
  profilePictures: [],
  isAdmin: false
}

const enhance = withObservables(['userId', 'isGroup'], ({ userId, isGroup }) => ({
  profilePictures: profilePictureService(database)
    .observeProfilePictureByUserId(userId, isGroup)
}))

const EnhancedChatDetailsImage = enhance(ChatDetailsImage)


export default EnhancedChatDetailsImage
