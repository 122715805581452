import api from '../api'

const URL_BASE = '/fields-monitoration/property'

export const getFieldMonitoration = async (params, { growingSeasonId }, accessToken) =>
  api.get(`/v2/fields-monitoration/field/${ growingSeasonId }/monitoration`, {
    params,
    headers: accessToken ? {
      'authorization': accessToken
    } : {}
  })

export const getFieldWeatherHistoric = async (params, { fieldId }, accessToken) =>
  api.get(`/v1/weather/historic/${ fieldId }`, {
    params,
    headers: accessToken ? {
      'authorization': accessToken
    } : {}
  })

export const getPropertyPrecipitations = (params, { propertyId }) => api.get(`/v1${ URL_BASE }/${ propertyId }/precipitations`, { params })

export const createFieldPrecipations = (params, { propertyId }) => api.post(`/v1${ URL_BASE }/${ propertyId }/precipitations`, params)

export const editFieldPrecipations = (params, { propertyId, precipitationId }) => api.patch(`/v1${ URL_BASE }/${ propertyId }/precipitations/${ precipitationId }`, params)

export const getFieldPrecipitation = async (params, { fieldId }) =>
  api.get(`/v1${ URL_BASE }/${ fieldId }/precipitation`, { params })

export const deleteFieldPrecipitation = async (
  { propertyId, precipitationId }
) => api.delete(`/v1${ URL_BASE }/${ propertyId }/precipitations/${ precipitationId }`)

export const getUserPropertiesPrecipations = async (params, { userId }) =>
  api.get(`/v1/fields-monitoration/precipitations/user/${ userId }`, { params })
