/* eslint-disable no-param-reassign */
import React, {
  useMemo,
  useCallback,
  forwardRef
} from 'react'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { ThemeProvider } from '@material-ui/core'

import I18n from '@smartcoop/i18n'
import { warning } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'
import InputSearch from '@smartcoop/web-components/InputSearch'

import CheckboxCard from './CheckboxCard'
import {
  ErrorLabel,
  Label,
  inputSearchTheme,
  inputQuantityTheme,
  NoDataFoundRow,
  CheckboxContainer,
  Row
} from './styles'

// eslint-disable-next-line no-unused-vars
const FilteredCheckboxGroup = forwardRef((props, ref) => {
  const {
    options: externalOptions,
    value,
    setFilterText,
    filterText,
    onChange,
    placeholder,
    inputLabel,
    error,
    label,
    required,
    disabled
  } = props

  const options = useMemo(
    () => map(externalOptions, (option) => ({
      ...option,
      checked: !!find(value, val => val === option.value)
    })),
    [externalOptions, value]
  )

  const handleChange = useCallback(
    (newValue, checked) => {
      onChange((oldValue) => {
        if (checked) {
          // add
          return [...oldValue, newValue]
        }
        // remove
        return filter(oldValue, val => val !== newValue)
      })
    },
    [onChange]
  )

  return (
    <div>
      {label && (
        <Label color={ error ? 'error' : undefined } >
          { label }{required && ' *'}
        </Label>
      )}
      {!!error && (
        <Row>
          <Icon
            icon={ warning }
            color={ colors.error }
            size={ 12 }
          />
          <ErrorLabel>{ error }</ErrorLabel>
        </Row>
      )}

      <ThemeProvider theme={ inputSearchTheme }>
        <InputSearch
          placeholder={ placeholder }
          label={ inputLabel }
          onChange={ ({ target }) => setFilterText(target.value) }
          value={ filterText }
          fullWidth
          detached
        />
      </ThemeProvider>

      <ThemeProvider theme={ inputQuantityTheme }>
        <CheckboxContainer style={ { flexDirection: 'variant' } }>
          {
            map(options, (option) => (
              <CheckboxCard
                style={ { backgroundColor: '#fff', width: '100%' } }
                key={ option.value }
                { ...option }
                checked={ !!option.checked }
                onChange={ handleChange }
                disabled={ disabled }
              >
                <option.render option={ option } />
              </CheckboxCard>
            ))
          }
          {
            isEmpty(options) && (
              <NoDataFoundRow>
                <I18n>no data found</I18n>
              </NoDataFoundRow>
            )
          }
        </CheckboxContainer>
      </ThemeProvider>
    </div>
  )
})

FilteredCheckboxGroup.propTypes = {
  inputLabel: PropTypes.string,
  style: PropTypes.object,
  options: PropTypes.array,
  value: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  filterText: PropTypes.string,
  setFilterText: PropTypes.func,
  inputSearchStyle: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool
}

FilteredCheckboxGroup.defaultProps = {
  inputLabel: '',
  options: [],
  style: {},
  label: '',
  filterText: '',
  setFilterText: () => {},
  inputSearchStyle: {},
  placeholder: '',
  value: [],
  onChange: () => {},
  error: null,
  required: false,
  disabled: false
}

export default FilteredCheckboxGroup
