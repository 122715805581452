import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

export const TitleContainer = styled.div`
  padding: 0px 20px;
`

export const FieldsContainer = styled(Grid).attrs({
  container: true,
  spacing: 3
})`
  padding: 10px 20px;
`

export const Item = styled(Grid).attrs({
  item: true,
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
  xl: 6
})``

export const ItemLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 30px;
`

export const Row = styled(Grid).attrs({
  item: true,
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12
})``

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`
export const ButtonContainer = styled.div`
  margin-left: 10px;
`
