import React from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import {
  emptyFilter,
  noFieldRegistered,
  filter
} from '@smartcoop/icons'
import EmptyState from '@smartcoop/web-components/EmptyState'
import FieldItem from '@smartcoop/web-components/FieldItem'
import Icon from '@smartcoop/web-components/Icon'
import InputSearch from '@smartcoop/web-components/InputSearch'
import Loader from '@smartcoop/web-components/Loader'

import {
  ContainerListFields,
  SearchContainer,
  ButtonFilter
} from './styles'

const FieldListProducer = (props) => {

  const {
    propertyHasFields,
    onChangeSearchFilter,
    filterText,
    t,
    fields,
    loading,
    handleRegisterFieldClick,
    handleFieldClick,
    currentProperty,
    userWrite,
    filters,
    filterFields,
    editFields
  } = props
  return (
    <>
      {propertyHasFields && (
        <SearchContainer>
          <InputSearch
            style={ { marginRight: 8, marginBottom: 0, flex: 5 } }
            adornmentStyle={ { marginRight: 15 } }
            detached
            onChange={ onChangeSearchFilter }
            value={ filterText }
            placeholder={ t('search') }
          />
          {!editFields && (
            <ButtonFilter
              id="filter-field"
              color={ isEmpty(filters) ? 'white' : 'secondary' }
              onClick={ filterFields }
              disabled={ loading }
            >
              <>
                <Icon
                  style={ { marginRight: '8px' } }
                  size={ 14 }
                  icon={ filter }
                />
                {t('filtrate')}
              </>
            </ButtonFilter>
          )}
        </SearchContainer>
      )}

      <ContainerListFields isCenter={ isEmpty(fields) }>
        {loading ? (
          <Loader width={ 100 } />
        ) : (
          map(fields, (item, index) => (
            <FieldItem
              field={ item }
              key={ `${ item.id }-${ index }` }
              onClick={ handleFieldClick }
              editFields={ editFields }
            />
          ))
        )}

        {!loading && !propertyHasFields && (
          <EmptyState
            text={ t('no field registered') }
            icon={ noFieldRegistered }
            buttonProps={ { disabled: isEmpty(currentProperty) || !userWrite } }
            action={ {
              text: t('register field'),
              onClick: handleRegisterFieldClick
            } }
          />
        )}
        {!loading && propertyHasFields && isEmpty(fields) && (
          <EmptyState text={ t('no results found') } icon={ emptyFilter } />
        )}
      </ContainerListFields>
    </>

  )
}

FieldListProducer.propTypes = {
  propertyHasFields: PropTypes.bool.isRequired,
  onChangeSearchFilter: PropTypes.func.isRequired,
  filterFields: PropTypes.func.isRequired,
  filterText: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  editFields: PropTypes.bool.isRequired,
  handleRegisterFieldClick: PropTypes.func.isRequired,
  handleFieldClick: PropTypes.func.isRequired,
  currentProperty: PropTypes.object.isRequired,
  userWrite: PropTypes.bool.isRequired
}

export default FieldListProducer
