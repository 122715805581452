// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
      <g>
        <path fill="#D3D3D3" d="M82,141.3c-0.1,0-0.1,0-0.2,0l-6,1.6l-5.3-3.1l5.3-3l6,1.6c0.4,0.1,0.8-0.1,0.9-0.5
          c0.1-0.3-0.1-0.6-0.3-0.8c0,0,0,0,0,0l-3.7-2.1l3.4-2.6l0,0c0.3-0.2,0.3-0.5,0.2-0.8c-0.1-0.3-0.4-0.4-0.7-0.4
          c-0.1,0-0.2,0-0.3,0.1l-3.9,1.6v-4.2c0-0.3-0.2-0.7-0.5-0.7c-0.4-0.1-0.8,0.1-0.9,0.5l-1.6,6l-5.3,3.1v-6.1l4.4-4.4
          c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.2-0.6-0.3-0.9-0.1c0,0,0,0,0,0l-3.7,2.1l-0.6-4.3
          c-0.1-0.3-0.3-0.6-0.7-0.6c0,0-0.1,0-0.1,0c-0.3,0-0.6,0.3-0.6,0.6c0,0,0,0,0,0l-0.5,4.2l-3.7-2.1c0,0,0,0,0,0
          c-0.3-0.1-0.6-0.1-0.9,0.1c-0.3,0.3-0.3,0.7,0,1l4.4,4.4v6.1l-5.3-3.1l-1.6-6c-0.1-0.4-0.5-0.6-0.9-0.5c-0.3,0.1-0.5,0.4-0.5,0.7
          v0v4.3l-3.9-1.6c-0.3-0.1-0.8,0-0.9,0.4c-0.1,0.3,0,0.6,0.2,0.8c0,0,0,0,0,0l3.4,2.6l-3.7,2.1c0,0,0,0,0,0
          c-0.3,0.2-0.4,0.5-0.3,0.8c0.1,0.3,0.4,0.5,0.7,0.5c0.1,0,0.1,0,0.2,0l6-1.6l5.3,3l-5.2,3.1l-6-1.6c-0.4-0.1-0.8,0.1-0.9,0.5
          c-0.1,0.3,0.1,0.6,0.3,0.8c0,0,0,0,0,0l3.7,2.1l-3.4,2.6c0,0,0,0,0,0c-0.3,0.2-0.3,0.5-0.2,0.8c0.1,0.3,0.4,0.4,0.7,0.4
          c0.1,0,0.2,0,0.3-0.1l3.9-1.6v4.3l0,0c0,0.3,0.2,0.6,0.5,0.7c0.4,0.1,0.8-0.1,0.9-0.5l1.6-6l5.3-3v6.1l-4.4,4.4
          c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5c0.2,0.2,0.6,0.3,0.9,0.1l0,0l3.7-2.1l0.6,4.3c0.1,0.3,0.3,0.6,0.7,0.6
          c0,0,0.1,0,0.1,0c0.3-0.1,0.6-0.3,0.6-0.6c0,0,0,0,0,0l0.5-4.2l3.7,2.1h0c0.3,0.1,0.6,0.1,0.9-0.1c0.3-0.3,0.3-0.7,0-1l-4.4-4.4
          v-6.1l5.3,3l1.6,6c0.1,0.4,0.5,0.6,0.9,0.5c0.3-0.1,0.5-0.4,0.5-0.7l0,0v-4.3l4,1.6c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.5-0.2,0.7-0.4
          c0.1-0.3,0-0.6-0.2-0.8c0,0,0,0,0,0l-3.4-2.6l3.7-2.1l0,0c0.3-0.1,0.4-0.5,0.3-0.8C82.6,141.5,82.3,141.3,82,141.3z M67.7,156.2
          L67.7,156.2L67.7,156.2L67.7,156.2z"/>
        <g>
          <path fill="#6EC4E9" d="M113.9,129.6c-1-0.2-2,0.2-2.5,1l0,0l-34.8,53.7c-0.6,0.9-1.1,1.8-1.4,2.9c-1.6,5.4,2,11,8.1,12.4
            c6,1.4,12.2-1.7,13.9-7.1h0l8.4-27.9c0,0,0-0.1,0-0.1l0,0l9.8-32.5l0-0.2l0,0C115.7,130.9,115,129.9,113.9,129.6z"/>
          <path fill="#6EC4E9" d="M147.2,125.5c0.4-0.9-0.2-1.9-1.2-2.2c-0.9-0.3-1.9,0-2.3,0.7h0l-19.4,24.8c-0.4,0.5-0.7,1-1,1.6
            c-1.2,3,0.6,6.4,4,7.5c3.4,1.1,7-0.4,8.3-3.3h0l11.5-28.9L147.2,125.5L147.2,125.5z"/>
        </g>
      </g>
      <g>
        <path fill="#D3D3D3" d="M197.9,67.2c-4.7-17.5-22.7-28-40.2-23.4c0,0-1.3,0.3-3.5,0.9c-5.6-20.8-24.6-36.2-47.2-36.2
          c-24.5,0-44.8,18.1-48.3,41.6c-0.8-0.2-1.3-0.3-1.3-0.3c-14.8-3.8-30,5-33.9,19.8c-0.9,3.5-1.1,7-0.8,10.4c-7.4,2-12,3.2-12,3.2
          c-7,1.9-11.1,9-9.2,16c1.6,6.1,7.3,10,13.4,9.7h150.3c0,0,6.2-0.3,9.4-1.1C192.1,102.9,202.6,84.8,197.9,67.2z"/>
      </g>
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
