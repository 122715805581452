import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'


import { colors } from '@smartcoop/styles'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid ${ colors.lightGrey };
  margin: 0;
`

export const WarningDiv = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  background-color: rgba(254,241,182,0.8);
  margin-right: 10px;
  text-align: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
`

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.backgroundHtml
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    padding: '12px 13px 0 13px'
  }
})

export const TabsGroup = styled(Tabs).attrs({})`
  color: ${ colors.black };

  & .MuiTab-root.Mui-selected: {
      color: '#B3A369',
  }
`

export const TabItem = styled(Tab)`
  min-height: 0;
  height: 100%;
  color: black;
  font-weight: bold;
`

export const SingleButtonContainer = styled.div`
  width: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 22px;
  align-items: center;
`
