import styled from 'styled-components'

import { colors } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'
import Card from '@smartcoop/web-components/Card'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
`

export const WhiteContainer = styled.div`
  background: ${ colors.white };
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
`

export const LeftSide = styled.div`
  font-size: 16px;
  display: flex;
  align-content: center;
  align-items: center;
`
export const ProposalCard = styled(Card).attrs({
  cardStyle: {
    width: '100%',
    paddingLeft: '30px',
    // flex: 0, --> Issue #1610 Item 4 (só acontece no firefox)
    paddingRight: '20px'
  }
})`
  flex-direction: flex-start;
  width: 100%;
  align-items: flex-start;
  font-weight: bold;
  margin-bottom: 50px;
`

export const Label = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  flex: 1;
`

export const LabelItem = styled.span`
  font-weight: ${ fonts.fontWeight.regular };
`

export const Payment = styled.div`
  display: flex;
`

export const PaymentType = styled.span`
  font-weight: bold;
  padding-right: 3px;
  text-transform: capitalize;
`

export const FormTitle = styled.span`
  font-weight: bold;
  font-size: 18px;
  display: flex;
`
