import React from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { colors } from '@smartcoop/styles'
import GoBackIconButton from '@smartcoop/web-components/IconButton/GoBackIconButton'

import { ContactInfoWrapper, HeaderContainer, NameText } from './styles'



const ContactsHeader = ({ onGoBack }) => {
  const t = useT()

  return (
    <div>
      <HeaderContainer>
        <ContactInfoWrapper>
          <GoBackIconButton
            onClick={ onGoBack }
            iconColor={ colors.primary }
          />
          <NameText>{t('contacts')}</NameText>
        </ContactInfoWrapper>
      </HeaderContainer>
    </div>
  )
}

ContactsHeader.propTypes = {
  onGoBack: PropTypes.func.isRequired
}

export default ContactsHeader
