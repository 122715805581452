import React, { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { TechnicalActions } from '@smartcoop/stores/technical'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'
import TechnicianGroupsForm from '@smartcoop/web-containers/forms/digitalProperty/technicalVisit/TechnicianGroupsForm'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import useStyles, { Row, Content } from './styles'

const TechnicianGroupsModal = ({ id, defaultValues, open, handleClose, onClose, onSubmit }) => {
  const classes = useStyles()
  const technicianGroupsFormRef = useRef(null)
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const t = useT()

  const [loading, setLoading] = useState(false)


  const onSuccess = useCallback(
    () => {
      setLoading(false)
      onSubmit()
      handleClose()
    },
    [handleClose, onSubmit]
  )

  const onError = useCallback(
    () => {
      setLoading(false)
    },
    []
  )

  const handleSave = useCallback((data) => {
    setLoading(true)
    if(defaultValues?.id) {
      dispatch(
        TechnicalActions.updateTechnicianGroups(
          defaultValues?.id,
          data,
          () => {
            snackbar.success(t('your group was updated'))
            onClose()
            handleClose()
          },
          () => {
            handleClose()
          }
        )
      )
    } else {
      dispatch(
        TechnicalActions.createTechnicianGroups(
          data,
          () => {
            snackbar.success(t('your group was created'))
            onClose()
            handleClose()
          },
          () => {
            handleClose()
          }
        )
      )
    }
  },[defaultValues, dispatch, handleClose, onClose, snackbar, t])

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row>
          <I18n>{defaultValues?.id ? 'edit group' : 'register group' }</I18n>
        </Row>
      }
      escape={ false }
      disableEsc
      loading={ loading }
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 350 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      disableFullScreen
      maxWidth="sm"
      fullWidth
    >
      <>
        <Content>
          <TechnicianGroupsForm
            ref={ technicianGroupsFormRef }
            onSubmit={ handleSave }
            onSuccess={ onSuccess }
            onError={ onError }
            defaultValues={ defaultValues }
          />
          <ButtonsContainer style={ { marginTop: 10 } }>
            <Button
              id="cancel"
              onClick={ () => {
                handleClose()
              } }
              variant="outlined"
              style={ { marginRight: 7 } }
              disabled={ loading }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="save"
              onClick={ () => technicianGroupsFormRef.current.submit() }
              color="black"
              style={ { marginLeft: 7 } }
              disabled={ loading }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        </Content>
      </>
    </Modal>
  )}

TechnicianGroupsModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  defaultValues: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
}

TechnicianGroupsModal.defaultProps = {
  defaultValues: null,
  onSubmit: () => {}
}

export default TechnicianGroupsModal
