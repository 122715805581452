import React, { forwardRef } from 'react'

import { Form as Unform } from '@unform/web'
import PropTypes from 'prop-types'

import { FormProvider } from '@smartcoop/forms'
import Loader from '@smartcoop/web-components/Loader'

import { Container, LoaderContainer } from './styles'

const Form = forwardRef(({ loading, containerStyle, ...props }, formRef) => (
  <Container style={ containerStyle }>
    {loading && (
      <LoaderContainer>
        <Loader width={ 100 } />
      </LoaderContainer>
    )}
    <FormProvider
      ref={ formRef }
      { ...props }
      UnformComponent={ Unform }
      noValidate
    >
      {props.children}
    </FormProvider>
  </Container>
))

Form.propTypes = {
  schemaConstructor: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.any,
  loading: PropTypes.bool,
  containerStyle: PropTypes.object
}

Form.defaultProps = {
  schemaConstructor: undefined,
  onSubmit: () => {},
  children: null,
  loading: false,
  containerStyle: {}
}

export default Form
