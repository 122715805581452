import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

/* Initial State */
const INITIAL_STATE = {
  productsPriceHistory: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  saveProductsPriceHistory: ['productsPriceHistory'],

  loadProductsPriceHistoryByOrganization: ['params', 'onSuccess', 'onError'],
  loadProductsPriceHistoryByOrganizationSuccess: ['productsPriceHistory', 'onSuccess'],

  loadProductsPriceHistory: ['params', 'onSuccess', 'onError'],
  loadProductsPriceHistorySuccess: ['productsPriceHistory', 'onSuccess'],

  deleteProductPriceHistory: ['historyId', 'productId', 'organizationId', 'onSuccess', 'onError'],

  productsPriceHistoryError: ['error']

})

const saveProductsPriceHistory = (state = INITIAL_STATE, { productsPriceHistory }) => ({
  ...state,
  ...productsPriceHistory
})


const loadProductsPriceHistoryByOrganizationSuccess = (state = INITIAL_STATE, { productsPriceHistory }) => ({
  ...state,
  error: INITIAL_STATE.error,
  productsPriceHistory
})

const productsPriceHistoryError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const loadProductsPriceHistorySuccess = (state = INITIAL_STATE, { productsPriceHistory }) => ({
  ...state,
  error: INITIAL_STATE.error,
  productsPriceHistory
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {

  [Types.LOAD_PRODUCTS_PRICE_HISTORY_BY_ORGANIZATION_SUCCESS]: loadProductsPriceHistoryByOrganizationSuccess,

  [Types.LOAD_PRODUCTS_PRICE_HISTORY_SUCCESS]: loadProductsPriceHistorySuccess,

  [Types.SAVE_PRODUCTS_PRICE_HISTORY]: saveProductsPriceHistory,

  // [Types.DELETE_PRODUCTS_PRICE_HISTORY]: deleteProductsPriceHistory,

  [AuthenticationTypes.LOGOUT]: logout,

  [Types.PRODUCTS_PRICE_HISTORY_ERROR]: productsPriceHistoryError
})

export {
  Types as ProductsPriceHistoryTypes,
  Creators as ProductsPriceHistoryActions
}
