import React, { useState, useMemo, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'

import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { arrowLeft, filter } from '@smartcoop/icons'
import { getMilkControl } from '@smartcoop/services/apis/smartcoopApi/resources/herdsManagement'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import InputSearch from '@smartcoop/web-components/InputSearch'
import Loader from '@smartcoop/web-components/Loader'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import FilterMilkControlModal from '@smartcoop/web-containers/modals/dairyFarm/FilterMilkControlModal'
import ImportMilkControlModal from '@smartcoop/web-containers/modals/dairyFarm/ImportMilkControlModal'
import RegisterMilkControlModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterMilkControlModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { Top, Container, Row, Title, SingleButtonContainer } from './styles'

const ListMilkControlScreen = () => {
  const dispatch = useCallback(useDispatch(), [])
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()
  const tableRef = useRef(null)
  const history = useHistory()
  const t = useT()
  const { routes } = useRoutes()

  const currentProperty = useSelector(selectCurrentProperty)
  const userWrite = useSelector(selectUserCanWrite)

  const [filters, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [filterText, setFilterText] = useState('')
  const [debouncedFilterText, setDebouncedFilterText] = useState('')

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )

  const columns = useMemo(
    () => [
      {
        title: t('date', { howMany: 1 }),
        field: 'date',
        sorting: true,
        defaultSort: 'desc',
        render: (row) => moment(row.date, momentBackDateFormat).format(momentFriendlyDateFormat)
      },
      {
        title: t('earring'),
        field: 'earring',
        sorting: true,
        render: row => `${ row?.animal?.earring?.earringCode } - ${ row?.animal?.name }`
      },
      {
        title: t('DEL'),
        field: 'del',
        sorting: true,
        defaultSort: 'desc'
      },
      {
        title: `${ t('volume') } (L)`,
        field: 'volume',
        sorting: true
      },
      {
        title: `${ t('ccs') } (Cél/ml)`,
        field: 'ccs',
        sorting: true
      },
      {
        title:`${ t('fat') } (%)` ,
        field: 'fat',
        sorting: true
      },
      {
        title: `${ t('protein') } (%)`,
        field: 'protein',
        sorting: true
      },
      {
        title: `${ t('lactose') } (%)`,
        field: 'lactose',
        sorting: true
      },
      {
        title: `${ t('total solids') } (%)`,
        field: 'totalSolids',
        sorting: true
      },
      {
        title: `${ t('nul') } (mg/dL)`,
        field: 'nul',
        sorting: true
      },
      {
        title: `${ t('esd') } (%)`,
        field: 'esd',
        sorting: true
      },
      {
        title: `${ t('casein') } (%)` ,
        field: 'casein',
        sorting: true
      },
      {
        title: t('lot', { howMany: 1 }),
        field: 'lotId',
        sorting: true,
        render: (row) => `${ row?.lot?.code } - ${ row?.lot?.name }`
      }
    ], [t]
  )

  const registerControl = useCallback(
    (data = {}) => {
      createDialog({
        id: 'register-control',
        Component: RegisterMilkControlModal,
        props: {
          defaultValues: data,
          reloadDataTable
        }
      })
    },
    [createDialog, reloadDataTable]
  )

  const filterData = useCallback(
    (data) => {
      setFilters(data)
    }, []
  )

  const filterMilkControl = useCallback(
    () => {
      createDialog({
        id: 'filter-control',
        Component: FilterMilkControlModal,
        props: {
          onSubmit: filterData,
          filters
        }
      })
    },
    [createDialog, filterData, filters]
  )

  const milkControlModal = useCallback(
    () => {
      createDialog({
        id: 'filter-control',
        Component: ImportMilkControlModal,
        props: {
          reloadDataTable,
          propertyId: currentProperty?.id
        }
      })
    },
    [createDialog, currentProperty, reloadDataTable]
  )

  const onEditClick = useCallback(
    (_, data) => registerControl(data),
    [registerControl]
  )

  const handleMilControlDelete = useCallback(
    (data) => {
      createDialog({
        id: 'confirm-delete-milk-control',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            setIsLoading(true)
            dispatch(AnimalActions.saveMilkControl(
              { data: [
                { ...data, deleted: true }
              ] },
              () => {
                setIsLoading(false)
                reloadDataTable()
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'male',
                    this: t('milk control')
                  })
                )
              },
              () => {
                setIsLoading(false)
                reloadDataTable()
              }
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            gender: 'male',
            howMany: 1,
            this: t('milk control')
          })
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, snackbar, t]
  )

  const onDeleteClick = useCallback(
    (_, data) => handleMilControlDelete(data),
    [handleMilControlDelete]
  )

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )

  const tableFilters = useMemo(
    () =>
      debouncedFilterText
        ? { ...filters, q: debouncedFilterText, startDate: filters?.date?.from, endDate: filters?.date?.to }
        : { ...filters, startDate: filters?.date?.from, endDate: filters?.date?.to },
    [debouncedFilterText, filters]
  )

  return (
    isLoading ? <Loader width={ 100 } /> :
      <Container>
        <Top>
          <IconButton
            tooltip={ t('go back') }
            onClick={ () => {
              dispatch(DairyFarmActions.setCurrentSection('cattleManagement'))
              history.push(routes.dairyFarmDashboard.path)
            } }
          >
            <Icon icon={ arrowLeft } size={ 16 } />
          </IconButton>
          <Title>
            <I18n>milk control</I18n>
          </Title>
        </Top>
        <Row>
          <SingleButtonContainer>
            <InputSearch
              detached
              onChange={ onChangeSearchFilter }
              value={ filterText }
              placeholder={ t('search') }
              style={ { marginRight: 10, backgroundColor: '#fff', marginBottom: -4 } }
            />
            <Button
              id="filter"
              color="white"
              style={ {
                flex: 'none',
                whiteSpace: 'nowrap',
                marginRight: 10,
                backgroundColor: isEmpty(filters) ? colors.white : colors.secondary
              } }
              onClick={ filterMilkControl }
            >
              <>
                <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 }/>
                <I18n>filtrate</I18n>
              </>
            </Button>
            <Button
              id="new-milk-control"
              color="secondary"
              style={ { flex: 'none', whiteSpace: 'nowrap' } }
              onClick={ () => registerControl() }
              disabled={ !userWrite }
            >
              <I18n>new registry</I18n>
            </Button>
            <Button
              id="import-milk-control"
              color="black"
              style={ { flex: 'none', marginLeft: 10, whiteSpace: 'nowrap' } }
              onClick={ milkControlModal }
              disabled={ !userWrite }
            >
              <I18n>sheet</I18n>
            </Button>
          </SingleButtonContainer>
        </Row>

        <DataTable
          tableRef={ tableRef }
          data={ getMilkControl }
          columns={ columns }
          urlParams={  { propertyId: currentProperty?.id } }
          queryParams={ tableFilters }
          onEditClick={ userWrite ? onEditClick : null }
          onDeleteClick={ userWrite ? onDeleteClick : null }
          id="list-milk-delivery-table"
        />
      </Container>
  )
}

export default ListMilkControlScreen
