/* eslint-disable react/prop-types */
import React from 'react'

import { useT } from '@smartcoop/i18n'

import { SearchContainer, InputSearch } from './styles'

const SearchChat = ({ value, onChange }) => {
  const t = useT()
  return(
    <SearchContainer>
      <InputSearch
        type="text"
        placeholder={ t('search or start a new conversation') }
        value={ value }
        onChange={ onChange }
      />
    </SearchContainer>
  )
}

export default SearchChat

