import * as Yup from 'yup'

const filterFields = () => Yup.object().shape({
  // sowingYear: date({ t })(Yup.string()),

  sowingYear: Yup.string(),

  cultivationGoalId: Yup.string(),

  cropId: Yup.string(),

  closed: Yup.string().nullable()
})

export default filterFields
