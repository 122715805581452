import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import { colors } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 32px 75px 23px;
  background-color: ${ colors.backgroundHtml };
`
export const inputSearchTheme = (theme) => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff'
      }
    }
  }
})

export const LabelFeed = styled(Typography)`
  font-size: ${ fonts.fontSize.XL }px;
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: ${ fonts.fontWeight.bold };
  color: ${ colors.black };
  margin-top: 20px;
  margin-bottom: 14px;
`
