// eslint-disable-next-line spaced-comment
export default ({ size = 25 }) => /*html*/`
  <svg
    viewBox="0 0 200 200"
    width="${ size }"
    height="${ size }"
  >
    <g id="sun">
    </g>
    <g id="clear-night">
    </g>
    <g id="mostly-sunny">
    </g>
    <g id="mostly-clear-night">
    </g>
    <g id="fog">
    </g>
    <g id="wind">
      <g>
        <g>
          <path fill="#D3D3D3" d="M56.9,92.8c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8h106.8c5.2-0.1,10-2,13.5-5.4c3.7-3.5,5.7-8.2,5.8-13.2
            c0-3.8-1.4-7.4-4-10.1c-2.7-2.7-6.2-4.2-10-4.3h-0.1c-2.8,0-5.4,1.1-7.4,3c-2,2-3.1,4.6-3.2,7.4c0,1.1,0.2,2.2,0.7,3.3
            c1.9,4,0.1,8.7-3.9,10.6c-4,1.9-8.7,0.1-10.6-3.9c-1.5-3.2-2.2-6.6-2.2-10.2c0.1-7.1,2.9-13.7,8-18.7c5-4.9,11.6-7.6,18.5-7.6
            h0.2c8.1,0.1,15.7,3.3,21.3,9.1c5.6,5.8,8.7,13.4,8.6,21.4c-0.1,9.3-3.9,18-10.6,24.5c-6.5,6.2-15.2,9.7-24.5,9.8H56.9z"/>
        </g>
        <g>
          <path fill="#D3D3D3" d="M138.7,172c-7,0-13.5-2.7-18.5-7.6c-5.1-5-7.9-11.6-8-18.7c0-3.5,0.7-7,2.2-10.2c1.9-4,6.6-5.7,10.6-3.9
            c4,1.9,5.7,6.6,3.9,10.6c-0.5,1-0.7,2.1-0.7,3.3c0,2.8,1.2,5.4,3.2,7.4c2,1.9,4.6,3,7.4,3h0.2c3.7,0,7.2-1.6,9.9-4.3
            c2.7-2.7,4.1-6.3,4-10.1c-0.1-5-2.1-9.7-5.7-13.2c-3.5-3.4-8.4-5.3-13.6-5.4H9c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8h124.6
            c9.4,0.1,18.1,3.6,24.6,9.8c6.8,6.5,10.6,15.2,10.7,24.5c0.1,8-3,15.6-8.6,21.4c-5.6,5.8-13.2,9-21.2,9.1H138.7z"/>
        </g>
      </g>
    </g>
    <g id="cloudy">
    </g>
    <g id="partly-cloudy">
    </g>
    <g id="partly-cloudy-night">
    </g>
    <g id="mostly-cloudy">
    </g>
    <g id="mostly-cloudy-night">
    </g>
    <g id="default">
    </g>
    <g id="light-rain">
    </g>
    <g id="rain">
    </g>
    <g id="heavy-rain">
    </g>
    <g id="scattered-showers-night">
    </g>
    <g id="isolated-t-storms">
    </g>
    <g id="scattered-t-storms">
    </g>
    <g id="scattered-t-storms-night">
    </g>
    <g id="strong-t-storms">
    </g>
    <g id="flurries">
    </g>
    <g id="snow">
    </g>
    <g id="heavy-snow">
    </g>
    <g id="blowing-snow">
    </g>
    <g id="scattered-snow">
    </g>
    <g id="scattered-snow-night">
    </g>
    <g id="freezing-drizzle">
    </g>
    <g id="freezing-rain">
    </g>
    <g id="wintry-mix">
    </g>
    <g id="sleet">
    </g>
    <g id="tornado">
    </g>
    <g id="hurricane">
    </g>
  </svg>
`
