import { call, put, takeLatest, select } from 'redux-saga/effects'

import isEmpty from 'lodash/isEmpty'

import {
  getOrderById as getOrderByIdService
} from '@smartcoop/services/apis/smartcoopApi/resources/order'
import {
  saveSupplierQuotationProposal as saveSupplierQuotationProposalService,
  editSupplierQuotationProposal as editSupplierQuotationProposalService,
  getSupplierQuotationProposal as getSupplierQuotationProposalService,
  refuseSupplierQuotationProposal as refuseSupplierQuotationProposalService,
  removeSupplierQuotationProposal as removeSupplierQuotationProposalService
} from '@smartcoop/services/apis/smartcoopApi/resources/supplierQuotation'

import { selectCurrentOrganization } from '../organization/selectorOrganization'
import { SupplierQuotationActions, SupplierQuotationTypes } from './duckSupplierQuotation'
import { selectCurrentSupplierQuotation, selectCurrentSupplierQuotationProposal } from './selectorSupplierQuotation'

function* loadCurrentSupplierQuotation({ supplierQuotationId, onSuccess = () => {}, onError = () => {}, onlyAccepted = null }) {
  try {
    const { data } = yield call(getOrderByIdService, { orderId: supplierQuotationId }, { onlyAccepted })
    yield put(SupplierQuotationActions.updateCurrentSupplierQuotation(
      data,
      onSuccess
    ))

  } catch (err) {
    const error = err.message
    yield put(SupplierQuotationActions.supplierQuotationError(error))
    yield call(onError, error)
  }
}

function* loadCurrentSupplierQuotationProposal({ supplierQuotationId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentSupplier = yield select(selectCurrentOrganization)

    const { data } = yield call(getSupplierQuotationProposalService, {}, { supplierQuotationId, supplierId: currentSupplier.id })
    yield put(SupplierQuotationActions.updateCurrentSupplierQuotationProposal(
      data,
      onSuccess
    ))

  } catch (err) {
    const error = err.message
    yield put(SupplierQuotationActions.supplierQuotationError(error))
    yield put(SupplierQuotationActions.resetCurrentSupplierQuotationProposal())
    yield call(onError, error)
  }
}

function* updateCurrentSupplierQuotation({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* updateCurrentSupplierQuotationProposal({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* saveOfflineSupplierQuotationProposal({ proposal, onSuccess = () => {}, onError = () => {} }) {
  yield put(SupplierQuotationActions.saveSupplierQuotationProposal(proposal, onSuccess, onError))
}

function* saveSupplierQuotationProposal({ proposal, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentSupplier = yield select(selectCurrentOrganization)
    const currentSupplierQuotation = yield select(selectCurrentSupplierQuotation)
    const currentProposal = yield select(selectCurrentSupplierQuotationProposal)

    yield call(
      isEmpty(currentProposal) ? saveSupplierQuotationProposalService : editSupplierQuotationProposalService,
      proposal,
      { supplierQuotationId: currentSupplierQuotation.id, supplierId: currentSupplier.id, supplierQuotationProposalId: currentProposal?.id || null }
    )

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(SupplierQuotationActions.supplierQuotationError(error))
    yield call(onError)
  }
}

function* removeOfflineSupplierQuotationProposal({ onSuccess = () => {}, onError = () => {} }) {
  yield put(SupplierQuotationActions.removeSupplierQuotationProposal(onSuccess, onError))
}

function* removeSupplierQuotationProposal({ onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProposal = yield select(selectCurrentSupplierQuotationProposal)

    yield call(
      removeSupplierQuotationProposalService,
      {},
      { proposalId: currentProposal.id }
    )

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(SupplierQuotationActions.supplierQuotationError(error))
    yield call(onError)
  }
}

function* refuseOfflineSupplierQuotationProposal({ onSuccess = () => {}, onError = () => {} }) {
  yield put(SupplierQuotationActions.refuseSupplierQuotationProposal(onSuccess, onError))
}

function* refuseSupplierQuotationProposal({ onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentSupplier = yield select(selectCurrentOrganization)
    const currentSupplierQuotation = yield select(selectCurrentSupplierQuotation)

    yield call(
      refuseSupplierQuotationProposalService,
      {
        supplierId: currentSupplier.id,
        supplierQuotationId: currentSupplierQuotation.id
      }
    )

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(SupplierQuotationActions.supplierQuotationError(error))
    yield call(onError)
  }
}

export default [
  takeLatest(SupplierQuotationTypes.LOAD_CURRENT_SUPPLIER_QUOTATION, loadCurrentSupplierQuotation),

  takeLatest(SupplierQuotationTypes.LOAD_CURRENT_SUPPLIER_QUOTATION_PROPOSAL, loadCurrentSupplierQuotationProposal),

  takeLatest(SupplierQuotationTypes.UPDATE_CURRENT_SUPPLIER_QUOTATION, updateCurrentSupplierQuotation),
  takeLatest(SupplierQuotationTypes.UPDATE_CURRENT_SUPPLIER_QUOTATION_PROPOSAL, updateCurrentSupplierQuotationProposal),

  takeLatest(SupplierQuotationTypes.SAVE_OFFLINE_SUPPLIER_QUOTATION_PROPOSAL, saveOfflineSupplierQuotationProposal),
  takeLatest(SupplierQuotationTypes.SAVE_SUPPLIER_QUOTATION_PROPOSAL, saveSupplierQuotationProposal),

  takeLatest(SupplierQuotationTypes.REMOVE_OFFLINE_SUPPLIER_QUOTATION_PROPOSAL, removeOfflineSupplierQuotationProposal),
  takeLatest(SupplierQuotationTypes.REMOVE_SUPPLIER_QUOTATION_PROPOSAL, removeSupplierQuotationProposal),

  takeLatest(SupplierQuotationTypes.REFUSE_OFFLINE_SUPPLIER_QUOTATION_PROPOSAL, refuseOfflineSupplierQuotationProposal),
  takeLatest(SupplierQuotationTypes.REFUSE_SUPPLIER_QUOTATION_PROPOSAL, refuseSupplierQuotationProposal)
]
