import api from '../api'

export const getCurrent = async (lat, long) => {
  const { data } = await api.get(`/v1/weather/current/${ lat }/${ long }`)
  return data
}

export const getForecast = async (lat, long) => {
  const { data } = await api.get(`/v1/weather/forecast/${ lat }/${ long }`)
  return data
}

export const getAggregatedRain = async (initialDate, finalDate) => {
  const { data } = await api.get('/v1/fields-monitoration/aggregated-rain', {
    params: { initialDate, finalDate }
  })
  return data
}
