import styled from 'styled-components'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  column-gap: 10px;
`

export const Title = styled.p`
  font-size: 18px;
  font-weight: 600;
  flex: 1;
  align-self: flex-start;
`

export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`
export const Description = styled.div`
  font-size: 16px;
  margin-right: 20px;
  margin-left: 5px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
export const ItemGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`
