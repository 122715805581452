import styled from 'styled-components'

import { colors } from '@smartcoop/styles'


export const DarkBackground = styled.div`
  background-color: ${ colors.shadow };
  border-radius: 4px;
`

export const Container = styled(DarkBackground)`
  height: fit-content;
  display: flex;
  flex-wrap: nowrap;
`

export const FormContainer = styled.div`
  display: flex;
  column-gap: 6px;
  padding: 0;
  padding: 9px 5px 7px 10px;
`

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;

  * {
    margin: 0;
  }
`

export const InputGroup = styled.div`
  display: flex;
  column-gap: 15px;
`

export const PeriodContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const DownloadIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 0 3px;
  border-left: solid 1px ${ colors.lightGrey };
`

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  align-self: center;
`
