import React, { useCallback, forwardRef, useMemo, useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import filterOrderSchema from '@smartcoop/forms/schemas/shoppingPlatform/order/filterOrder.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getProducts } from '@smartcoop/services/apis/smartcoopApi/resources/product'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

import { Container, ButtonContainer } from './styles'

const FilterOrderForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const [products, setProducts] = useState([])

  const t = useT()

  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  const statusOptions = useMemo(
    () => [
      {
        label: t('opened', { gender: 'female' }),
        value: '1'
      },
      {
        label: t('waiting decision'),
        value: '5'
      },
      {
        label: t('waiting delivery'),
        value: '8'
      }
    ],
    [t]
  )

  const getProductList = useCallback(async () => {
    const { data: { data } } = await getProducts({ purchasing: true, limit: 99999 })
    setProducts(map(data, item => ({
      label: `${ item?.productCode } - ${ item?.description }`,
      value: item?.id
    })))
  }, [])

  useEffect(() => {
    getProductList()
  },[getProductList])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterOrderSchema }
        onSubmit={ handleSubmit }
      >
        <InputDate
          label={ t('opening date') }
          name="startDate"
          defaultValue={ filters.startDate }
          fullWidth
        />

        <InputSelect
          label={ t('product') }
          name="productId"
          options={ products }
          defaultValue={ filters.productId }
        />

        <RadioGroup
          label={ t('situation') }
          name="statusId"
          options={ statusOptions }
          variant="row"
          style={ { paddingBottom: 10 } }
          defaultValue={ filters.statusId }
          clearable
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-order-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterOrderForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterOrderForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterOrderForm
