import React from 'react'

import InputAdornment from '@material-ui/core/InputAdornment'

import hourMask from '@smartcoop/forms/masks/hour.mask'
import { clock } from '@smartcoop/icons'
import Icon from '@smartcoop/web-components/Icon'
import TextField from '@smartcoop/web-components/TextField'

const InputHour = (props) => (
  <TextField
    { ...props }
    type="tel"
    setMask={ hourMask }
    InputProps={ {
      endAdornment: (
        <InputAdornment style={ { paddingRight: 18 } } position="end">
          <Icon
            icon={ clock }
            size={ 18 }
            color="#151818"
            data-toggle
          />
        </InputAdornment>
      )
    } }
  />
)

export default InputHour
