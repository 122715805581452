import React, { useCallback, useState } from 'react'
import { useSelector , useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { ProductWallActions } from '@smartcoop/stores/productWall'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Modal from '@smartcoop/web-components/Modal'
import PostComment from '@smartcoop/web-components/PostComment'
import SimpleComment from '@smartcoop/web-components/simpleComment'

import useStyles from './styles'

const CommentModal = (props) => {
  const { id, open, originalPost, handleClose, getComments } = props
  const t = useT()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const user = useSelector(selectUser)
  const currentOrganization = useSelector(selectCurrentOrganization)

  const dispatch = useCallback(useDispatch(), [])

  const [loading, setLoading] = useState(false)

  const handleError = () => setLoading(false)

  const saveCommentSuccess = useCallback(() => {
    snackbar.success(t('social comment post success'))
    setLoading(false)
    handleClose()
    getComments()
  },[getComments, handleClose, snackbar, t])

  const handleSubmit = useCallback((data) => {
    setLoading(true)
    dispatch(ProductWallActions.saveProductWallComment({ postId: originalPost.id, comment: data }, saveCommentSuccess , handleError))
  },[dispatch, originalPost.id, saveCommentSuccess])

  return (
    <Modal
      id={ id }
      open={ open }
      title={ t('social modal title') }
      disableFullScreen
      escape
      contentContainerStyle={ { padding: 0, minWidth: 500 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      loading={ loading }
    >
      <div>
        <SimpleComment comment={ originalPost } productWall/>
        <PostComment user={ user } onSubmit={ handleSubmit } currentOrganization={ currentOrganization } productWall/>
      </div>
    </Modal>
  )

}

CommentModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  originalPost: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    userId: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      image: PropTypes.string
    }),
    organization: PropTypes.shape({
      companyName: PropTypes.string,
      id: PropTypes.string,
      tradeName: PropTypes.string
    })
  }).isRequired,
  getComments: PropTypes.func.isRequired
}

CommentModal.defaultProps = {
}

export default CommentModal
