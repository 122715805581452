import React, { memo } from 'react'


import { withLayout } from '@smartcoop/web-containers/layouts'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import RouterSwitch from '../../RouterSwitch'

const ModuleScreenRouter = (props) => {
  const { routes } = useRoutes()

  return <RouterSwitch routes={ routes } { ...props } />
}

export default memo(withLayout('authenticated')(ModuleScreenRouter))
