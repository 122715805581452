import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const RadioTouchableContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
`

export const Container = styled.div`
  background-color: ${ colors.backgroundHtml };
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 30px;
  padding: 10px;
`

export const IconContainer = styled.div`
  max-width: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 5px;
`

export const Label = styled.span`
  font-size: 14px;
  font-weight: 600;
`
