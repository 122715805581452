import React, { useMemo, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'


import { find } from 'lodash'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { selectTerms } from '@smartcoop/stores/authentication/selectorAuthentication'
import Button from '@smartcoop/web-components/Button'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'

import AcceptTermModalConfirm from '../AcceptTermModalConfirm/AcceptTermModalConfirm'
import {
  Container,
  Content,
  Buttons,
  Title,
  Term,
  Item,
  AcceptCheckbox
} from './styles'

const AcceptTermModal = (props) => {
  const {
    id,
    open,
    slug,
    viewOnly,
    handleClose,
    onWillClose,
    forceShow,
    term
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const t = useT()
  const { createDialog } = useDialog()

  const terms = useSelector(selectTerms)

  const [accepted, setAccepted] = useState(false)

  const selectedTerm = useMemo(
    () => term || find(terms, { termType: { slug } }), [slug, term, terms]
  )

  const onClose = useCallback(
    () => {
      onWillClose()
      handleClose()
    }, [handleClose, onWillClose]
  )

  const onSuccess = useCallback(
    () => {
      setIsLoading(false)
      snackbar.success(t('the term was accepted succesfully'))
      onClose()
    }, [onClose, snackbar, t]
  )

  const onError = useCallback(
    () => {
      setIsLoading(false)
    }, []
  )

  const openConfirmModal = useCallback(
    () => {
      createDialog({
        id: `confirm-term-modal-${  slug }`,
        Component: AcceptTermModalConfirm

      })
    }, [createDialog, slug]
  )

  const cancel = useCallback(
    () => {
      if(forceShow) {
        handleClose()
      } else {
        openConfirmModal()
      }
    }, [forceShow, handleClose, openConfirmModal]
  )

  const onSubmit = useCallback(
    () => {
      if(!viewOnly) {
        setIsLoading(true)
        dispatch(AuthenticationActions.updateTerm(
          { slug, termId: selectedTerm.id },
          () => {onSuccess()},
          (error) => {onError(error)}
        ))
      }
      onClose()
    }, [dispatch, onClose, onError, onSuccess, selectedTerm, slug, viewOnly]
  )



  const isAccepted = useMemo(
    () => accepted || viewOnly,
    [accepted, viewOnly]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      escape={ forceShow }
      title={ selectedTerm?.termType?.type }
      disableFullScreen
      maxWidth="md"
      style={ { zIndex: 99999 } }
    >
      {isLoading ? <Loader /> :
        <Container>
          <Content>
            <Item>
              <I18n as={ Title }>
              please read the terms below with attention
              </I18n>
            </Item>
            <Term dangerouslySetInnerHTML={ { __html: (selectedTerm?.description) } } />
            <Item>
              { term && viewOnly ?
                (
                  <></>
                ) : (
                  <AcceptCheckbox
                    label={ t('i declare that i read and im aware of the norms that are in the term above') }
                    value={ accepted }
                    checked={ isAccepted }
                    onChange={ (value) => {setAccepted(!value)} }
                  />
                )
              }
            </Item>
          </Content>
          <Buttons>
            {
              !viewOnly && <Button
                id="cancel-button"
                onClick={ cancel }
                style={ { flex: 1 } }
                variant="outlined"
              >
                <I18n>cancel</I18n>
              </Button>
            }

            <Button
              id="confirm-button"
              onClick={ onSubmit }
              style={ { flex: 1 } }
              disabled={ !isAccepted }
            >
              <I18n>confirm</I18n>
            </Button>
          </Buttons>
        </Container>}
    </Modal>
  )
}

AcceptTermModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  viewOnly: PropTypes.bool,
  forceShow: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onWillClose: PropTypes.func,
  slug: PropTypes.oneOf([
    'privacy-term',
    'social-network-term',
    'commercialization-term',
    'supplier-term',
    'organization-term',
    'use-term'
  ]).isRequired,
  term: PropTypes.object
}

AcceptTermModal.defaultProps = {
  onWillClose: () => {},
  viewOnly: false,
  forceShow: false,
  term: null
}



export default AcceptTermModal
