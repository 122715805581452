import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import { colors } from '@smartcoop/styles'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0;
`

export const ButtonGroup = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  justify-content: space-around;
  margin-top: 20px;
`

export const Table = styled.table`
  width: 100%;

  tr {
    width: 100%;
  }
  th {
    text-align: left;
  }
  td {
  }
`

export const Title = styled.span`
  font-weight: bold;
`

export const ColumnValue = styled.span`
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  column-gap: 10px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.backgroundHtml
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    padding: '12px 13px 0 13px'
  }
})
