import { createActions, createReducer } from 'reduxsauce'

import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import remove from 'lodash/remove'

import { AuthenticationTypes } from '../../../authentication'

// Initial state
const INITIAL_STATE = {
  fields: [],
  fieldsPropertyIDGrouped: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadData: ['onFinish'],

  loadAllFields: ['onFinish'],
  loadAllFieldsSuccess: ['fields'],

  updateOfflineCurrentField: ['field', 'cropManagement'],
  deleteOfflineCurrentField: ['fieldId'],
  updateFieldsPropertyIDGrouped: [],

  offlineFieldUserDataError: ['error']
}, { prefix: 'OFFLINE_FIELD_USER_DATA' })

/**
 * Reducers functions
 */
const loadAllFieldsSuccess = (state = INITIAL_STATE, { fields }) => ({
  ...state,
  error: INITIAL_STATE.error,
  fields,
  fieldsPropertyIDGrouped: groupBy(fields, 'propertyId')
})

const updateOfflineCurrentField = (state = INITIAL_STATE, { field, cropManagement }) => {
  const fields = [...state?.fields]
  const temp = {}

  if(cropManagement) {
    map(field, (item) => {
      remove(fields, (removeField) => removeField?.id === item?.id)
    })
  }

  if(cropManagement) {
    temp[field[0]?.propertyId] = [...field]
  } else {
    temp[field?.propertyId] = [field, ...filter(state.fieldsPropertyIDGrouped[field.propertyId], (item) => item.id !== field.id)]
  }

  return ({
    ...state,
    fields: cropManagement ? [...field, ...fields] : [field, ...filter(state.fields, (item) => item.id !== field.id)],
    fieldsPropertyIDGrouped:{ ...state.fieldsPropertyIDGrouped, ...temp }
  })
}

const deleteOfflineCurrentField = (state = INITIAL_STATE, { fieldId }) => ({
  ...state,
  fields: [...filter(state.fields, (item) => item.id !== fieldId)]
})

const updateFieldsPropertyIDGrouped = (state = INITIAL_STATE) => ({
  ...state,
  fieldsPropertyIDGrouped: groupBy(state.fields, 'propertyId')
})

const offlineFieldUserDataError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_ALL_FIELDS_SUCCESS]: loadAllFieldsSuccess,

  [Types.UPDATE_OFFLINE_CURRENT_FIELD]: updateOfflineCurrentField,
  [Types.DELETE_OFFLINE_CURRENT_FIELD]: deleteOfflineCurrentField,
  [Types.UPDATE_FIELDS_PROPERTY_ID_GROUPED]: updateFieldsPropertyIDGrouped,

  [Types.OFFLINE_FIELD_USER_DATA_ERROR]: offlineFieldUserDataError,
  [AuthenticationTypes.LOGOUT]: logout
})

export { Types as OfflineFieldUserDataTypes, Creators as OfflineFieldUserDataActions }
