export const selectTechnicalVisit = state => state.technical.technicalVisit

export const selectTechnicalVisits = state => state.technical.technicalVisits

export const selectRequestAccessList = state => state.technical.requestAccessList

export const selectCurrentOwner = state => state.technical.currentOwner

export const selectCurrentOwnerId = state => state.technical.currentOwner?.id

export const selectOfflineVisits = state => state.technical.offlineVisits

export const selectCurrentOwnerExtraAttributes = state => {
  const extraAttributes = state.technical.currentOwner?.extraAttributes ? JSON.parse(state.technical.currentOwner?.extraAttributes) : {}
  return {
    ...extraAttributes,
    enabledCosts: true
  }
}

export const selectPropertiesByOwner = state => {
  const { id: ownerId } = selectCurrentOwner(state)
  return state.technical.propertiesByOwner[ownerId] ?? []
}

export const selectTechnicalOwners = state => state.technical.owners

export const selectCurrentForm = state => state.technical.currentForm
export const selectTechnicalForms = state => state.technical.technicalForms

export const selectTechnicalFormsAnswers = state => state.technical.formAnswers

export const selectEvaluationProducers = state => state.technical.evaluationProducers

export const selectTechnicalWrite = state => state.technical.currentOwner?.proprietaryTechnician?.write

export const selectTechnicalFinancialDataAccess = state => state.technical.currentOwner?.proprietaryTechnician?.financialDataAccess || false

export const selectCurrentOwnerFamilyGroup = state => state.technical.currentOwnerFamilyGroup
