import React, { useMemo, useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import trimMask from '@meta-awesome/functions/src/trimMask'
import PropTypes from 'prop-types'


import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { shield } from '@smartcoop/icons'
import { getTechnicalPortfolio as getTechnicalPortfolioService } from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import { useSnackbar } from '@smartcoop/snackbar'
import {
  selectUserHaveTechnical,
  selectHasOrganizationToken
} from '@smartcoop/stores/authentication/selectorAuthentication'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { PropertyActions } from '@smartcoop/stores/property'
import { TechnicalActions } from '@smartcoop/stores/technical'
import colors from '@smartcoop/styles/colors'
import { TechnicalPortfolioStatusCode } from '@smartcoop/utils/constants'
import { formatPhoneV2 } from '@smartcoop/utils/formatters'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import TechnicalPortfolioStatus from '@smartcoop/web-components/TechnicalPortfolioStatus'

const TechnicalPortfolio = ({ filters }) => {
  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const tableRef = useRef(null)
  const { createDialog } = useDialog()

  const hasOrganizationToken = useSelector(selectHasOrganizationToken)
  const userHaveTechnical = useSelector(selectUserHaveTechnical)
  const currentOrganization = useSelector(selectCurrentOrganization)

  const reloadDataTable = useCallback(() => {
    if (tableRef?.current) {
      tableRef.current.onQueryChange()
    }
  }, [])

  useEffect(() => {
    reloadDataTable()
  }, [currentOrganization, reloadDataTable])

  const columns = useMemo(
    () => [
      // {
      //   title: '',
      //   disableClick: true,
      //   render: (row) => (
      //     <div>
      //       <Popover
      //         popoverId="user-menu"
      //         popoverStyle={ { width: 250 } }
      //         Component={ VerticalDotsIconButton }
      //       >
      //         <div>
      //           <MenuPopUp
      //             options={ [
      //               {
      //                 icon: null,
      //                 text: t('register insemination'),
      //                 onClick: () => {

      //                 },
      //                 disabled: row.proprietaryTechnician?.status !== TechnicalPortfolioStatusCode.VISUALIZAÇÃO_EDIÇÃO
      //               }
      //             ] }
      //           />
      //         </div>
      //       </Popover>
      //     </div>
      //   )
      // },
      {
        title: 'Produtor',
        field: 'name'
      },
      {
        title: 'Matrícula',
        field: 'organizationUser[0].registry'
      },
      {
        title: 'Telefone',
        field: 'cellPhone',
        render: (row) => formatPhoneV2(row.cellPhone)
      },
      {
        title: 'Email',
        field: 'email'
      },
      {
        title: 'Acesso',
        field: 'status',
        render: (row) => (
          <TechnicalPortfolioStatus
            statusNumber={ row.proprietaryTechnician?.status }
          />
        )
      }
    ],
    []
  )

  const disableAccessRequest = useCallback(
    (row) =>
      row.proprietaryTechnician?.status &&
    row.proprietaryTechnician?.status !==
      TechnicalPortfolioStatusCode.NEGADO &&
    row.proprietaryTechnician?.status !==
      TechnicalPortfolioStatusCode.REVOGADO,
    []
  )

  const actions = useMemo(
    () => [
      (row) => ({
        position: 'row',
        onClick: () =>
          createDialog({
            id: 'confirm-delete-access',
            Component: ConfirmModal,
            props: {
              onConfirm: () => {
                dispatch(
                  TechnicalActions.requestAccessTechnicalForProprietary(
                    { proprietaryId: row.id },
                    () => {
                      snackbar.success(t('your access request has been sent'))
                      const { query } = tableRef.current.state
                      tableRef.current.onQueryChange(query)
                    }
                  )
                )
              },
              textWarning: t('are you sure you want to send the {this}?', {
                howMany: 1,
                gender: 'female',
                this: t('access request', { howMany: 1 })
              })
            }
          }),
        disabled: disableAccessRequest(row),
        iconButtonProps: {
          id: 'request-permission',
          variant: 'outlined',
          size: 'small',
          color: 'black',
          style: {
            width: 200,
            height: 30,
            fontSize: 14,
            fontWeight: 600,
            borderRadius: 5,
            color: colors.black
          }
        },
        icon: () => (
          !disableAccessRequest(row) && <>
            <Icon icon={ shield } size={ 14 } style={ { paddingRight: 5 } } />
            <I18n>request permission</I18n>
          </>
        )
      })
    ],
    [createDialog, disableAccessRequest, dispatch, snackbar, t]
  )

  const disabledRowNotAccess = useCallback(
    (row) =>
      row.proprietaryTechnician?.status !==
        TechnicalPortfolioStatusCode.SOMENTE_VISUALIZAÇÃO &&
      row.proprietaryTechnician?.status !==
        TechnicalPortfolioStatusCode.VISUALIZAÇÃO_EDIÇÃO,
    []
  )

  const accessPropertiesToOwner = useCallback(
    (_, row) => {
      !(
        row.proprietaryTechnician?.status !==
          TechnicalPortfolioStatusCode.SOMENTE_VISUALIZAÇÃO &&
        row.proprietaryTechnician?.status !==
          TechnicalPortfolioStatusCode.VISUALIZAÇÃO_EDIÇÃO
      ) &&
        dispatch(
          TechnicalActions.setTechnicalCurrentOwner(
            row,
            () => {
              dispatch(PropertyActions.loadActivities())
            },
            (error) => {
              snackbar.error(t(error.message))
            }
          )
        )
    },
    [dispatch, snackbar, t]
  )

  const conditionToDelete = useCallback(
    (row) =>
      row.proprietaryTechnician?.status &&
      row.proprietaryTechnician?.status !==
        TechnicalPortfolioStatusCode.REVOGADO,
    []
  )

  const revokeTechnicalAccess = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete-access',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(
              TechnicalActions.revokeTechnicalAccess(
                row.proprietaryTechnician?.id,
                3,
                () => {
                  snackbar.success(t('your access has been removed'))
                  const { query } = tableRef.current.state
                  tableRef.current.onQueryChange(query)
                },
                (err) => {
                  snackbar.error(t(err.message ? err.message : err))
                }
              )
            )
          },
          textWarning: t('are you sure you want to remove the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('access', { howMany: 1 })
          })
        }
      })
    },
    [createDialog, dispatch, snackbar, t]
  )

  const dataTableOptions = useMemo(
    () => ({
      actionsCellDivStyle: {
        alignItems: 'center'
      }
    }),
    []
  )

  const queryParams = useMemo(
    () => ({
      ...filters,
      cellPhone: filters?.cellPhone ? trimMask(filters.cellPhone) : '',
      document: filters?.document ? trimMask(filters.document) : ''
    }),
    [filters]
  )

  return (
    hasOrganizationToken &&
    userHaveTechnical && (
      <DataTable
        tableRef={ tableRef }
        columns={ columns }
        actions={ actions }
        options={ dataTableOptions }
        onRowClick={ accessPropertiesToOwner }
        data={ getTechnicalPortfolioService }
        onDeleteClick={ revokeTechnicalAccess }
        conditionToDelete={ conditionToDelete }
        disabledRow={ disabledRowNotAccess }
        queryParams={ queryParams }
        id="technical-portfolio-table"
      />
    )
  )
}

TechnicalPortfolio.propTypes = {
  filters: PropTypes.object
}

TechnicalPortfolio.defaultProps = {
  filters: {}
}

export default TechnicalPortfolio
