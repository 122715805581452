import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { size } from 'lodash'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import toNumber from 'lodash/toNumber'

import { AuthenticationTypes } from '../authentication'

const INITIAL_PROPERTY = {
  data: {
    name: '',
    totalArea: undefined,
    geolocalization: {
      latitude: undefined,
      longitude: undefined
    },
    state: '',
    city: '',
    addressComplement: '',
    id: null
  },
  activities: [],
  activitiesDetails: {},
  familyGroupProperties: []
}

/* Initial State */
const INITIAL_STATE = {
  properties: [],
  propertyPendingManagements: [],
  propertyDairyFarmNextActions: [],
  currentProperty: {},
  propertyChallenges: [],
  offlineProperty: { ...INITIAL_PROPERTY },
  activities: [],
  suggestPropertyRegister: false,
  reloadData: false,
  propertyParameters: {},
  error: null,
  rubricGroups: []
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadProperties: ['onSuccess', 'onError'],
  loadCurrentProperty: ['onSuccess', 'onError'],
  loadPropertiesSuccess: ['properties', 'onSuccess'],
  loadPropertiesFamilyGroupSuccess: ['groupFamilyProperties', 'onSuccess'],

  updateOfflineProperty: ['property'],
  saveOfflineProperty: [],
  resetOfflineProperty: [],
  resetPropertyChallenges: [],

  saveProperty: ['property'],
  transferProperty: ['params', 'onSuccess', 'onError'],

  deleteProperty: ['propertyId'],
  onDeletePropertySuccess: ['propertyId'],

  loadPropertyChallenges: ['params', 'onSuccess', 'onError'],
  loadPropertyChallengesSuccess: ['propertyChallenges'],

  editProperty: ['propertyId', 'propertyData'],
  setEditPropertyData: ['propertyId', 'propertyData'],

  saveCurrentProperty: ['currentProperty'],
  resetCurrentProperty: [],

  loadActivities: [],
  loadActivitiesSuccess: ['activities'],

  loadPropertyPendingManagements: ['propertyId', 'onSuccess', 'onError'],
  loadPropertyPendingManagementsSuccess: ['propertyPendingManagements'],

  loadPropertyDairyFarmNextActions: ['propertyId', 'onSuccess', 'onError'],
  loadPropertyDairyFarmNextActionsSuccess: ['propertyDairyFarmNextActions'],

  loadRubricGroups: ['onSuccess', 'onError'],
  loadRubricGroupsSuccess: ['rubricGroups'],
  saveRubric: ['params', 'onSuccess', 'onError'],
  deleteRubric: ['rubricId', 'onSuccess', 'onError'],

  loadParameters: ['onSuccess', 'onError'],
  updatePropertyParameters: ['parameters', 'onSuccess', 'onError'],
  updatePropertyParametersSuccess: ['parameters'],

  setReloadData: ['flag'],

  propertyError: ['error'],

  savePropertiesAssets: ['propertiesAssets', 'onSuccess', 'onError'],
  deletePropertyAsset: ['propertyAssetsId', 'onSuccess'],
  postPropertyAssetsMovements: ['dto', 'onSuccess', 'onError'],
  postPropertyAssetsList: ['dto', 'onSuccess', 'onError']
})

/**
 * Assigning offline actions
 */
markActionsOffline(Creators, ['saveProperty'])

/**
 * Reducers functions
 */
const loadPropertiesSuccess = (state = INITIAL_STATE, { properties }) => ({
  ...state,
  error: INITIAL_STATE.error,
  properties,
  suggestPropertyRegister: isEmpty(properties),
  currentProperty: find(properties, (item) => item.id === state.currentProperty?.id) ?? state.currentProperty
})

const loadPropertiesFamilyGroupSuccess = (state = INITIAL_STATE, { groupFamilyProperties }) => ({
  ...state,
  familyGroupProperties: groupFamilyProperties
})

const loadRubricGroupsSuccess = (state = INITIAL_STATE, { rubricGroups }) => ({
  ...state,
  rubricGroups
})

const loadPropertyChallengesSuccess = (state = INITIAL_STATE, { propertyChallenges }) => ({
  ...state,
  propertyChallenges
})

const updateOfflineProperty = (state = INITIAL_STATE, { property }) => {
  let { totalArea } = state.offlineProperty.data
  if (property?.data?.totalArea) {
    totalArea = toNumber(property?.data.totalArea)
  }
  return {
    ...state,
    offlineProperty: {
      ...state.offlineProperty,
      ...property,
      data: {
        ...state.offlineProperty.data,
        ...(property?.data || {}),
        totalArea
      }
    }
  }
}

const resetOfflineProperty = (state = INITIAL_STATE) => ({
  ...state,
  offlineProperty: { ...INITIAL_PROPERTY }
})

const resetPropertyChallenges = (state = INITIAL_STATE) => ({
  ...state,
  propertyChallenges: INITIAL_STATE.propertyChallenges
})

const loadActivitiesSuccess = (state = INITIAL_STATE, { activities }) => ({
  ...state,
  error: INITIAL_STATE.error,
  activities
})

const loadPropertyPendingManagementsSuccess = (
  state = INITIAL_STATE,
  { propertyPendingManagements }
) => ({
  ...state,
  error: INITIAL_STATE.error,
  propertyPendingManagements
})

const loadPropertyDairyFarmNextActionsSuccess = (
  state = INITIAL_STATE,
  { propertyDairyFarmNextActions }
) => ({
  ...state,
  error: INITIAL_STATE.error,
  propertyDairyFarmNextActions
})

const propertyError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const saveCurrentProperty = (state = INITIAL_STATE, { currentProperty }) => ({
  ...state,
  currentProperty,
  propertyPendingManagements: INITIAL_STATE.propertyPendingManagements,
  propertyDairyFarmNextActions: INITIAL_STATE.propertyDairyFarmNextActions
})

const updatePropertyParametersSuccess = (state = INITIAL_STATE, { parameters }) => ({
  ...state,
  propertyParameters: parameters
})

const resetCurrentProperty = (state = INITIAL_STATE) => ({
  ...state,
  currentProperty: INITIAL_STATE.currentProperty,
  propertyPendingManagements: INITIAL_STATE.propertyPendingManagements,
  propertyDairyFarmNextActions: INITIAL_STATE.propertyDairyFarmNextActions,
  propertyChallenges: INITIAL_STATE.propertyChallenges
})

const logout = () => ({ ...INITIAL_STATE })

const onDeletePropertySuccess = (state = INITIAL_STATE, { propertyId }) => {
  const propertyIndex = findIndex(
    state.properties,
    (property) => property?.id === propertyId
  )

  if (propertyId === -1) return state

  const properties = [...state.properties]
  properties.splice(propertyIndex, 1)

  return {
    ...state,
    properties,
    suggestPropertyRegister: size(properties) === 0
  }
}

const setEditPropertyData = (
  state = INITIAL_STATE,
  { propertyId, propertyData }
) => ({
  ...state,
  offlineProperty: {
    data: {
      name: propertyData?.name,
      totalArea: propertyData?.totalArea,
      geolocalization: {
        latitude: propertyData?.geolocalization?.latitude,
        longitude: propertyData?.geolocalization?.longitude
      },
      state: propertyData?.state,
      city: propertyData?.city,
      addressComplement: propertyData?.addressComplement,
      id: propertyId
    },
    activities: propertyData?.activities,
    activitiesDetails: propertyData?.activities
  }
})

const setReloadData = (state = INITIAL_STATE, { flag }) => ({
  ...state,
  reloadData: !!flag
})

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_PROPERTIES_SUCCESS]: loadPropertiesSuccess,
  [Types.LOAD_PROPERTIES_FAMILY_GROUP_SUCCESS]: loadPropertiesFamilyGroupSuccess,

  [Types.UPDATE_OFFLINE_PROPERTY]: updateOfflineProperty,
  [Types.RESET_OFFLINE_PROPERTY]: resetOfflineProperty,

  [Types.LOAD_ACTIVITIES_SUCCESS]: loadActivitiesSuccess,

  [Types.LOAD_PROPERTY_CHALLENGES_SUCCESS]: loadPropertyChallengesSuccess,

  [Types.LOAD_PROPERTY_PENDING_MANAGEMENTS_SUCCESS]: loadPropertyPendingManagementsSuccess,
  [Types.LOAD_PROPERTY_DAIRY_FARM_NEXT_ACTIONS_SUCCESS]: loadPropertyDairyFarmNextActionsSuccess,

  [Types.SAVE_CURRENT_PROPERTY]: saveCurrentProperty,
  [Types.RESET_CURRENT_PROPERTY]: resetCurrentProperty,

  [Types.PROPERTY_ERROR]: propertyError,
  [Types.RESET_PROPERTY_CHALLENGES]: resetPropertyChallenges,

  [Types.ON_DELETE_PROPERTY_SUCCESS]: onDeletePropertySuccess,
  [Types.SET_EDIT_PROPERTY_DATA]: setEditPropertyData,

  [Types.UPDATE_PROPERTY_PARAMETERS_SUCCESS]: updatePropertyParametersSuccess,

  [Types.LOAD_RUBRIC_GROUPS_SUCCESS]: loadRubricGroupsSuccess,

  [Types.SET_RELOAD_DATA]: setReloadData,

  [AuthenticationTypes.LOGOUT]: logout
})

export { Types as PropertyTypes, Creators as PropertyActions }
