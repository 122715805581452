import React, { useMemo, useCallback,useEffect } from 'react'
import { useSelector , useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'

import { isEmpty } from 'lodash'
import find from 'lodash/find'
import map from 'lodash/map'

import { ThemeProvider } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { cow, pencil, landProductivityCows, concentratedMilkCows, mediumDel, lactatingCows, percentLactationAnimals } from '@smartcoop/icons'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import { selectDairyFarmMonth, selectDashboardData } from '@smartcoop/stores/dairyFarm/selectorDairyFarm'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectCurrentProperty, selectCurrentPropertyDairyFarmIEs, selectFamilyGroupAccess, selectFamilyGroupFinancialDataAccess } from '@smartcoop/stores/property/selectorProperty'
import { selectCurrentOwnerExtraAttributes, selectTechnicalFinancialDataAccess } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUserCanWrite, selectUserExtraAttributes } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import { momentBackMonthYearFormat } from '@smartcoop/utils/dates'
import { formatCurrency } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import InputMonthYear from '@smartcoop/web-components/InputMonthYear'
import MilkDataCard from '@smartcoop/web-components/MilkDataCard'
import Tooltip from '@smartcoop/web-components/Tooltip'
import DairyFarmDeliveryChart from '@smartcoop/web-containers/fragments/DairyFarmDeliveryChart'
import DairyFarmPriceChart from '@smartcoop/web-containers/fragments/DairyFarmPriceChart'
import DairyFarmQualityChart from '@smartcoop/web-containers/fragments/DairyFarmQualityChart'
import MilkDeliveryModal from '@smartcoop/web-containers/modals/dairyFarm/MilkDeliveryModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  CardItemTitle,
  Title,
  Content,
  Line,
  GridIndicators,
  inputSearchTheme
} from './styles'

const DairyFarmDashboard = () => {
  const { createDialog } = useDialog()
  const t = useT()
  const dispatch = useCallback(useDispatch(),[])
  const { routes } = useRoutes()
  const history = useHistory()

  const currentProperty = useSelector(selectCurrentProperty)
  const dairyFarmIEs = useSelector(selectCurrentPropertyDairyFarmIEs)
  const dairyFarmMonth = useSelector(selectDairyFarmMonth)
  const dairyFarm = useMemo(() => !isEmpty(currentProperty?.dairyFarm) && currentProperty?.dairyFarm[0], [currentProperty])
  const dashboardData = useSelector(selectDashboardData)
  const userWrite = useSelector(selectUserCanWrite)

  const userExtraAttributes = useSelector(selectUserExtraAttributes)
  const currentOwnerExtraAttributes = useSelector(selectCurrentOwnerExtraAttributes)

  const technicalFinancialDataAccess = useSelector(selectTechnicalFinancialDataAccess)
  const familyGroupFinancialDataAccess = useSelector(selectFamilyGroupFinancialDataAccess)

  const familyGroupAccess = useSelector(selectFamilyGroupAccess)
  const isTechnical = useSelector(selectModuleIsTechnical)

  const extraAttributes = useMemo(() => userExtraAttributes || {}, [userExtraAttributes])

  // eslint-disable-next-line no-nested-ternary
  const shouldDisplayCosts = useMemo(() => familyGroupAccess ? familyGroupFinancialDataAccess : isTechnical ? technicalFinancialDataAccess && currentOwnerExtraAttributes?.enabledCosts : extraAttributes?.enabledCosts,[currentOwnerExtraAttributes, extraAttributes, familyGroupAccess, familyGroupFinancialDataAccess, isTechnical, technicalFinancialDataAccess])

  const onCancel = useCallback(
    () => {
      isEmpty(dairyFarm) && history.push(routes.propertyHome.path)
    },
    [dairyFarm, history, routes.propertyHome.path]
  )

  const openMilkDeliveryModal = useCallback(
    () => {
      createDialog({
        id: 'milk-delivery',
        Component: MilkDeliveryModal,
        props: {
          onCancel,
          dairyFarm: {
            ...dairyFarm,
            stateRegistrationId: map(dairyFarmIEs, ({ id }) => id)
          }
        }
      })
    },
    [createDialog, dairyFarm, dairyFarmIEs, onCancel]
  )

  const cards = useMemo(
    () => [
      {
        slug: 'land-productivity',
        icon: landProductivityCows,
        color: colors.yellow,
        text: t('land productivity'),
        data: find(dashboardData.indicators, { slug: 'land-productivity' }) || {}
      },
      {
        slug: 'cow-productivity',
        icon: cow,
        color: colors.blue,
        text: t('cow productivity'),
        data: find(dashboardData.indicators, { slug: 'cow-productivity' }) || {}
      },
      {
        slug: 'concentrated-milk-relation',
        icon: concentratedMilkCows,
        color: colors.blue,
        text: t('concentrated milk ratio'),
        data: find(dashboardData.indicators, { slug: 'concentrated-milk-relation' }) || {}
      },
      {
        slug: 'average-del',
        icon: mediumDel,
        color: colors.yellow,
        text: t('average del'),
        data: find(dashboardData.indicators, { slug: 'average-del' }) || {}
      },
      {
        slug: 'lactation-animals',
        icon: percentLactationAnimals,
        color: colors.yellow,
        text: t('percent lactation animals'),
        data: find(dashboardData.indicators, { slug: 'lactation-animals-now' }) || {},
        subData: find(dashboardData.indicators, { slug: 'lactation-animals' }) || {}
      },
      {
        slug: 'lactating-cows',
        icon: lactatingCows,
        color: colors.blue,
        text: t('lactating cows'),
        data: find(dashboardData.indicators, { slug: 'lactating-cows-now' }) || {},
        subData: find(dashboardData.indicators, { slug: 'lactating-cows' }) || {}
      }
    ],
    [dashboardData.indicators, t]
  )

  const formatValue = useCallback(
    (value, slug) => {
      if(slug === 'lactation-animals' || slug === 'concentrated-milk-relation') {
        return formatCurrency(value, 1, ',', '.', '')
      }
      return formatCurrency(value, 0, ',', '.', '')
    },
    []
  )

  useEffect(
    () => {
      if (isEmpty(dairyFarm) || isEmpty(dairyFarmIEs)) {
        openMilkDeliveryModal()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )


  useEffect(() => {
    if (!isEmpty(dairyFarm)) {
      dispatch(DairyFarmActions.loadDashboardData({ date: dairyFarmMonth || moment().format(momentBackMonthYearFormat) }))
    }
  }, [currentProperty, dairyFarm, dairyFarmMonth, dispatch])

  return (
    <>
      <Line>
        <ThemeProvider theme={ inputSearchTheme }>
          <InputMonthYear
            detached
            label="Mês/Ano"
            name="monthYear"
            value={ dairyFarmMonth || moment().format(momentBackMonthYearFormat) }
            onChange={ (value) => dispatch(DairyFarmActions.setDairyFarmMonth(value[0])) }
            style={ { width: 'auto', marginRight: 10 } }
          />
        </ThemeProvider>
        <div>
          <Button
            id="edit-company"
            color="white"
            style={ { marginRight: 10 } }
            onClick={ openMilkDeliveryModal }
            disabled={ !userWrite }
          >
            <Icon style={ { marginRight: 6 } } icon={ pencil } size={ 14 }/>
            <I18n>edit company</I18n>
          </Button>
          <Button
            id="production-data"
            color="black"
            onClick={ () => history.push(routes.listProductionData.path) }
            disabled={ !userWrite }
          >
            <I18n>production data</I18n>
          </Button>
        </div>
      </Line>
      {!isEmpty(dairyFarm) && (
        <Content>
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 } sm={ 12 } md={ 7 }>
              <CardItemTitle
                title={ <I18n as={ Title }>milk deliveries</I18n> }
                headerRight={ (
                  <div>
                    <Button
                      id="register-delivery"
                      color="black"
                      onClick={ () => history.push(routes.listMilkDelivery.path) }
                    >
                      <I18n>register delivery</I18n>
                    </Button>
                  </div>
                ) }
                childrenStyle={ {
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 0,
                  paddingLeft: 0
                } }
              >
                <DairyFarmDeliveryChart />
              </CardItemTitle>
            </Grid>
            <Grid item xs={ 12 } sm={ 12 } md={ 5 }>

              <GridIndicators>
                {map(cards, (card) => (
                  <MilkDataCard
                    key={ card.slug }
                    icon={ card.icon }
                    measureUnit={ card.data?.measureUnit }
                    title={ card.text }
                    color={ card.color }
                    lowerText={ card?.subData ? `${ formatValue(card.subData?.value, card.slug) }${ card.subData?.measureUnit } / mês` : null }
                  >
                    {
                      dashboardData.indicators ? (
                        formatValue(card.data?.value, card.slug)
                      ) : (
                        <Tooltip
                          title={ t('there is no suficient data to calculate the indicators for this period') }
                        >
                          <I18n>does not apply - short version</I18n>
                        </Tooltip>
                      )
                    }
                  </MilkDataCard>
                ))}
              </GridIndicators>
            </Grid>

            <Grid item xs={ 12 } sm={ 12 } md={ 5 }>
              <CardItemTitle
                title={ (
                  <I18n as={ Title } params={ { howMany: 2 } }>
                      price
                  </I18n>
                ) }
                headerRight={ shouldDisplayCosts ? (
                  <div>
                    <Button
                      id="register-price"
                      color="black"
                      onClick={ () => history.push(routes.listPriceData.path) }
                    >
                      <I18n>register price</I18n>
                    </Button>
                  </div>
                ) : null }
                childrenStyle={ {
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 0,
                  paddingLeft: 0
                } }
              >
                <DairyFarmPriceChart shouldDisplayCosts={ shouldDisplayCosts }/>
              </CardItemTitle>
            </Grid>

            <Grid item xs={ 12 } sm={ 12 } md={ 7 }>
              <CardItemTitle
                title={ <I18n as={ Title }>quality analysis</I18n> }
                headerRight={ (
                  <div>
                    <Button
                      id="register-analysis"
                      color="black"
                      onClick={ () => history.push(routes.listMilkQuality.path) }
                    >
                      <I18n>register analysis</I18n>
                    </Button>
                  </div>
                ) }
                childrenStyle={ {
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 0,
                  paddingLeft: 0
                } }
                headerStyle={ {
                  marginBottom: 0
                } }
              >
                <DairyFarmQualityChart />
              </CardItemTitle>
            </Grid>
          </Grid>
        </Content>
      )}
    </>
  )
}

export default DairyFarmDashboard
