/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'


import moment from 'moment/moment'

import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import size from 'lodash/size'


import { ThemeProvider } from '@material-ui/core'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { filter as filterIcon, hammer, organizationRounded, thumbsUp, help, warning } from '@smartcoop/icons'
import {
  getMyOrdersByOrganization as getMyOrdersByOrganizationService,
  getOrdersByOrganization as getOrdersByOrganizationService
} from '@smartcoop/services/apis/smartcoopApi/resources/order'
import { selectUserProfileIsAdmin, selectUserProfileIsSmartcoop } from '@smartcoop/stores/authentication/selectorAuthentication'
import { OrderActions } from '@smartcoop/stores/order'
import { selectOrderFilters } from '@smartcoop/stores/order/selectorOrder'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import colors from '@smartcoop/styles/colors'
import { ProposalStatusCode } from '@smartcoop/utils/constants'
import {
  momentFriendlyDateFormat,
  momentBackDateTimeZoneFormat
} from '@smartcoop/utils/dates'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import Avatar from '@smartcoop/web-components/Avatar'
import AvatarGroup from '@smartcoop/web-components/AvatarGroup'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import DemandStatus from '@smartcoop/web-components/DemandStatus'
import Icon from '@smartcoop/web-components/Icon'
import VerticalDotsIconButton from '@smartcoop/web-components/IconButton/VerticalDotsIconButton'
import InputSearch from '@smartcoop/web-components/InputSearch'
import MenuPopUp from '@smartcoop/web-components/MenuPopUp'
import Popover from '@smartcoop/web-components/Popover'
import Tooltip from '@smartcoop/web-components/Tooltip'
import useTerm from '@smartcoop/web-containers/hooks/useTerm'
import HelpModal from '@smartcoop/web-containers/modals/HelpModal/HelpModal'
import FilterOrderModal from '@smartcoop/web-containers/modals/shoppingPlatform/FilterOrderModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import useStyles, {
  Container,
  Row,
  Title,
  ButtonContainer,
  SearchContainer,
  inputSearchTheme,
  Text
} from './styles'

const OrderListScreen = () => {
  const { createDialog } = useDialog()
  const [listAll, setListAll] = useState(true)
  const [filterText, setFilterText] = useState('')
  const [debouncedFilterText, setDebouncedFilterText] = useState('')
  const [statusDescriptions, setStatusDescriptions] = useState([])
  const [userId, setUserId] = useState(false)

  const history = useHistory()
  const { routes } = useRoutes()
  const dispatch = useDispatch()
  const t = useT()

  const currentOrganization = useSelector(selectCurrentOrganization)
  const filters = useSelector(selectOrderFilters)
  const userProfileIsAdmin = useSelector(selectUserProfileIsAdmin)
  const userProfileIsSmartcoop = useSelector(selectUserProfileIsSmartcoop)

  useTerm('organization-term')

  const classes = useStyles()

  useEffect(() => {
    dispatch(OrderActions.loadDemandsStatus(
      (status) => setStatusDescriptions(status?.purchaseStatus)
    ))
  }, [dispatch])

  const redirectCreateOrder = useCallback((row = {}) => {
    history.push(routes.registerOrder.path, { defaultValues: row })
  }, [history, routes])

  const redirectBySlug = useCallback(
    (slug, proposalDeadline) => {
      switch (slug) {
        case 'aguardando_decisao':
          if(userProfileIsAdmin || userProfileIsSmartcoop) {
            return routes.acceptOrder
          }
          return routes.orderDetails
        case 'aguardando_resposta':
          if ((userProfileIsAdmin || userProfileIsSmartcoop) && moment(proposalDeadline).isBefore(moment()))
            return routes.acceptOrder
          return routes.orderDetails
        default:
          return routes.orderDetails
      }
    },
    [routes.acceptOrder, routes.orderDetails, userProfileIsAdmin, userProfileIsSmartcoop]
  )

  const redirectToOrder = useCallback(
    (data) => {
      const {
        id,
        status: { slug },
        proposalDeadline,
        organizationPurchases
      } = data
      const route = redirectBySlug(slug, proposalDeadline)
      history.push(route.path.replace(':orderId', id), { accepted: (slug === 'aguardando_entrega') && !find(organizationPurchases, item => item.proposalAccepted === false && item.organizationId === currentOrganization.id) ? true : null })
    },
    [currentOrganization, history, redirectBySlug]
  )

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [setFilterText, debouncedChangeSearchFilter]
  )

  const tableFilters = useMemo(
    () => debouncedFilterText ? (
      {
        ...filters,
        userId,
        q: debouncedFilterText
      }
    ) : {
      ...filters,
      userId
    },
    [debouncedFilterText, filters, userId]
  )


  const getCurrentOrganizationPurchase = useCallback(
    (row) => find(
      row?.organizationPurchases,
      ({ organizationId }) => organizationId === currentOrganization.id
    ),
    [currentOrganization.id]
  )

  const renderMenus = useCallback((row) => (
    <Popover
      popoverId="order-menu"
      popoverStyle={ { width: 250 } }
      Component={ VerticalDotsIconButton }
      key={ row?.id }
    >
      <MenuPopUp
        options={ [
          {
            icon: null,
            text: t('recreate order'),
            onClick: () => redirectCreateOrder(row)
          }
        ] }
      />
    </Popover>
  ), [redirectCreateOrder, t])


  const columns = useMemo(
    () => [
      {
        title: '',
        disableClick: true,
        render: renderMenus,
        width: '4%'
      },
      {
        title: 'Código',
        field: 'code',
        sorting: true,
        align: 'center',
        defaultSort: 'desc'
      },
      {
        title: 'Produtos',
        field: 'shortDescription',
        cellStyle: { fontWeight: 'bold' },
        sorting: true,
        render: (row) => `${ row?.product?.productCode } - ${ row?.product?.shortDescription }`
      },
      {
        title: 'Abertura',
        field: 'startDate',
        type: 'date',
        sorting: true,
        render: (row) => moment(row?.createdAt, momentBackDateTimeZoneFormat).format(momentFriendlyDateFormat) },
      {
        title: 'Expiração',
        field: 'participationDeadline',
        type: 'date',
        sorting: true,
        render: (row) =>
          moment(includes(['aberta', 'expirado', 'aguardando_resposta', 'cancelada'], row?.status?.slug) ? row?.participationDeadline : row?.decisionDeadline, momentBackDateTimeZoneFormat).format(
            momentFriendlyDateFormat
          )
      },
      {
        title: 'Volume Agregado',
        field: 'aggregatedVolume',
        align: 'center',
        headerStyle: { whiteSpace: 'nowrap' },
        cellStyle: { paddingRight: 44 },
        render: (row) =>
          `${ Number(row?.aggregatedVolume).toLocaleString('pt-BR') } ${
            row?.product?.unitOfMeasures ?? ''
          }`
      },
      {
        title: 'Cooperativas',
        field: 'organizationPurchases',
        cellStyle: { fontWeight: 'bold' },
        render: (row) => (
          <Row style={ { flexWrap: 'nowrap' } }>
            <Text style={ { paddingRight: 6 } }>
              {size(row?.organizationPurchases)}
            </Text>
            <AvatarGroup max={ 99 }>
              {map(row?.organizationPurchases, ({ organization }, index) => (
                <Avatar
                  key={ index }
                  alt={ organization.tradeName || organization.companyName }
                  tooltipProps={ {
                    title: organization.tradeName || organization.companyName,
                    arrow: true,
                    placement: 'top'
                  } }
                  className={ classes.white }
                >
                  <Icon
                    icon={ organizationRounded }
                    color={
                      organization.id === currentOrganization.id
                        ? colors.green
                        : colors.mediumGrey
                    }
                    size={ 25 }
                  />
                </Avatar>
              ))}
            </AvatarGroup>
          </Row>
        )
      },
      {
        title: 'Propostas',
        field: 'purchaseProposals',
        render: (row) => {
          const currentProposals = filter(row?.purchaseProposals, item => item?.statusId !== ProposalStatusCode.REJEITADO && item?.statusId !== ProposalStatusCode.INVALID)
          return (
            <Tooltip title={ !isEmpty(currentProposals) ? <div style={ { whiteSpace: 'pre-line' } }>{map(currentProposals, proposal => proposal?.supplier?.tradeName).join('\n')}</div> : '' }>
              <div style={ { display: 'flex', flexDirection: 'row' } }>
                <Icon
                  icon={ hammer }
                  size={ 16 }
                  color={ colors.secondary }
                  style={ { paddingRight: '8px' } }
                />
                <Text>{`${ currentProposals.length } ${ t('received', {
                  gender: 'female',
                  howMany: currentProposals.length
                }) }`}</Text>
              </div>
            </Tooltip>
          )
        }
      },
      {
        title: 'Situação',
        field: 'statusId',
        sorting: true,
        render: (row) => (
          <div style={ { display: 'flex', alignItems: 'center' } }>
            <DemandStatus
              style={ { padding: '6px 10px' } }
              slug={ (() => {
                if(userProfileIsAdmin) {
                  return row?.status?.slug
                }
                if(row?.status?.slug === 'aguardando_entrega' && find(row?.organizationPurchases, item => item.received === true && item.organizationId === currentOrganization.id)) {
                  return 'entregue'
                }
                if(row?.status?.slug === 'aguardando_entrega' && !!find(row?.organizationPurchases, item => item.proposalAccepted === false && item.organizationId === currentOrganization.id)) {
                  return 'recusado'
                } if((row?.status?.slug === 'aguardando_decisao' || row?.status?.slug === 'aguardando_resposta' || row?.status?.slug === 'aguardando_entrega') && !find(row?.organizationPurchases, org => org.organizationId === currentOrganization.id)) {
                  return 'expirado'
                }
                return row?.status?.slug
              })() }
            />
            {
              !isNil(getCurrentOrganizationPurchase(row)) &&
              (
                row?.status?.slug === 'aguardando_decisao'
                || (row?.status?.slug === 'aguardando_entrega'
                    && moment().isAfter(moment(row?.receiptDeadline))
                )
              ) &&
              !(row?.status?.slug === 'aguardando_entrega'
                && find(row?.organizationPurchases, item => item.received === true && item.organizationId === currentOrganization.id)
              )
                && (
                  <Tooltip
                    title={
                      (() => {
                        if(row?.status?.slug === 'aguardando_decisao') {
                          if (isUndefined(getCurrentOrganizationPurchase(row)?.proposalAccepted)) {
                            return t('waiting answer')
                          }
                          if (getCurrentOrganizationPurchase(row)?.proposalAccepted) {
                            return t('proposal accepted')
                          }
                          return t('{this} refused', { gender: 'female', this: t('proposal', { howMany: 1 }) })
                        }
                        return t('late delivery')
                      })()
                    }
                  >
                    <Icon
                      icon={
                        (() => {
                          if(row?.status?.slug === 'aguardando_decisao') {
                            if (isUndefined(getCurrentOrganizationPurchase(row)?.proposalAccepted)) {
                              return help
                            }
                            if (getCurrentOrganizationPurchase(row)?.proposalAccepted) {
                              return thumbsUp
                            }
                            return thumbsUp
                          }
                          return warning
                        })()
                      }
                      color={ colors.black }
                      size={ 20 }
                      style={ {
                        ...{ marginLeft: 5 },
                        ...(() => {
                          if (!isUndefined(getCurrentOrganizationPurchase(row)?.proposalAccepted) && !getCurrentOrganizationPurchase(row)?.proposalAccepted) {
                            return { transform: 'rotate(180deg)' }
                          }
                          return {}
                        })()
                      } }
                    />
                  </Tooltip>
                )}
          </div>
        )
      }
    ],
    [classes.white, currentOrganization.id, getCurrentOrganizationPurchase, renderMenus, t, userProfileIsAdmin]
  )

  const handleFilter = useCallback((values) => dispatch(OrderActions.setOrderFilters(values)), [dispatch])

  const filterData = useCallback(() => {
    createDialog({
      id: 'filter-order',
      Component: FilterOrderModal,
      props: {
        onSubmit: handleFilter,
        filters
      }
    })
  }, [createDialog, filters, handleFilter])

  const onRowDisabled = useCallback(
    ({ status: { slug } }) =>
      slug === 'cancelada',
    []
  )

  const onRowDisabledAfterParticipationDeadLine = useCallback(
    (data) =>
      moment().isAfter(moment(data.receiptDeadline)) &&
      !data.organizationPurchases.includes(currentOrganization.id),
    [currentOrganization.id]
  )

  const onRowClick = useCallback(
    (event, data) => {
      if (
        onRowDisabledAfterParticipationDeadLine(data) ||
        !onRowDisabled(data)
      ) {
        redirectToOrder(data)
      }
    },
    [onRowDisabled, onRowDisabledAfterParticipationDeadLine, redirectToOrder]
  )

  const serviceUsers = useMemo(
    () =>
      listAll
        ? getOrdersByOrganizationService
        : getMyOrdersByOrganizationService,
    [listAll]
  )

  const urlParams = useMemo(
    () => ({ organizationId: currentOrganization.id }),
    [currentOrganization]
  )

  return (
    <Container>
      <div style={ { display: 'flex', flexDirection: 'row', marginBottom: 15, marginTop: 5 } }>
        {currentOrganization?.logoData && (
          <div style={ { display: 'flex', flexDirection: 'row' } }>
            <img alt={ currentOrganization?.tradeName } src={ downloadFromBase64(currentOrganization?.logoData) } style={ { height: 60, width: 120, objectFit: 'contain', padding: 4 } } />
            <div style={ { marginRight: 10, marginLeft: 10, height: '100%', width: 8, backgroundColor: colors?.secondary } }/>
          </div>
        )}
        <Title>
          <I18n>purchase orders</I18n>
        </Title>
      </div>

      <Row>
        <ButtonContainer>
          <Button
            id="all-orders"
            onClick={ () => {
              setListAll(true)
              setUserId(false)
            } }
            color={ listAll ? colors.black : colors.white }
            style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 12 } }
          >
            <I18n params={ { gender: 'female' } }>all</I18n>
          </Button>

          <Button
            id="my-orders"
            onClick={ () => {
              setListAll(false)
              setUserId(false)
            } }
            color={ !listAll && !userId ? colors.black : colors.white }
            style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 12 } }
          >
            <I18n>my organization</I18n>
          </Button>
          <Button
            id="my-user"
            onClick={ () => {
              setListAll(false)
              setUserId(true)
            } }
            color={ userId ? colors.black : colors.white }
            style={ { flex: 'none', whiteSpace: 'nowrap' } }
          >
            <I18n>mine only</I18n>
          </Button>
        </ButtonContainer>

        <SearchContainer>
          <ButtonContainer>
            <Button
              id="open-help-modal"
              onClick={ () => createDialog({
                id: 'help',
                Component: HelpModal,
                props: {
                  statusDescriptions
                }
              }) }
              style={ { width: 'auto', marginRight: 10 } }
              color={ colors.white }
            >
              <I18n>help</I18n>
            </Button>
            <Button
              id="filter"
              onClick={ filterData }
              style={ { flex: 'none', whiteSpace: 'nowrap' } }
              color={ isEmpty(filters) ? 'white' : 'secondary' }
            >
              <>
                <Icon style={ { paddingRight: 6 } } icon={ filterIcon } size={ 14 } />
                <I18n>filtrate</I18n>
              </>
            </Button>
          </ButtonContainer>
          <ThemeProvider theme={ inputSearchTheme }>
            <InputSearch
              detached
              onChange={ onChangeSearchFilter }
              value={ filterText }
              placeholder={ t('search') }
              fullWidth
            />
          </ThemeProvider>
          <Button
            id="new-order"
            onClick={ () => redirectCreateOrder({}) }
            color="secondary"
            style={ { flex: '0 0 auto', marginBottom: 22, marginLeft: 8 } }
          >
            <I18n>new demand</I18n>
          </Button>
        </SearchContainer>
      </Row>

      {!isEmpty(urlParams.organizationId) && (
        <DataTable
          onRowClick={ onRowClick }
          data={ serviceUsers }
          queryParams={ tableFilters }
          deps={ tableFilters }
          urlParams={ urlParams }
          columns={ columns }
          disabledRow={ onRowDisabled }
          id="order-list-table"
        />
      )}
    </Container>
  )
}

export default OrderListScreen
