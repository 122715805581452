import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import length from '@smartcoop/forms/validators/length.validator'
import maxLength from '@smartcoop/forms/validators/maxLength.validator'
import number from '@smartcoop/forms/validators/number.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerProduct = ({ t }) => Yup.object().shape({
  description: flow(
    maxLength({ t, field: t('description'), length: 500 }),
    required({ t })
  )(Yup.string()),

  shortDescription: flow(
    maxLength({ t, field: t('shortDescription'), length: 500 }),
    required({ t })
  )(Yup.string()),

  ncm: Yup.lazy((value) => {
    if(value !== '') {
      return Yup.string().matches(/\d\d\d\d.\d\d.\d\d/g, t('invalid format, must be 0000.00.00'))
    }
    return Yup.string()
  }),

  unitOfMeasures: flow(
    maxLength({ t, field: t('unitOfMeasures'), length: 15 }),
    required({ t })
  )(Yup.string()),

  ean: (Yup.array()),

  dun14: Yup.lazy((value) => {
    if(value !== ''){
      return flow(
        number({ t }),
        length({ t, field: t('dun14'), length: 14 })
      )(Yup.string())
    }
    return Yup.string()
  }),

  productGroupId: flow(
    required({ t })
  )(Yup.string()),

  purchaseType: flow(
    required({ t })
  )(Yup.string()),

  active: flow(
    required({ t })
  )(Yup.string()),

  cultivate: Yup.string(),

  purchasing: Yup.string(),

  commercialization: Yup.string(),

  digitalization: Yup.string()

})

export default registerProduct
