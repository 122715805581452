import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`

`

export const AddressContainer = styled.div`
  background-color: ${ colors.white };
  padding: 20px;
  border-radius: 5px;
  margin-top: 10px;
`

export const Label = styled.span`
  font-weight: 700;
  font-size: 17px;
`
