import React, { useMemo, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'


import { useWindowHeight } from '@react-hook/window-size'

import { find, isEmpty, map, size, split } from 'lodash'

import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'


import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { rain, calendar, info } from '@smartcoop/icons'
import { resetToken } from '@smartcoop/services/apis/smartcoopApi/resources/authentication'
import { useSnackbar } from '@smartcoop/snackbar'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { selectAccessToken, selectRefreshToken, selectTerms } from '@smartcoop/stores/authentication/selectorAuthentication'
import { ChallengesActions } from '@smartcoop/stores/challenges'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectCurrentProperty, selectProperties, selectReloadData, selectFamilyGroupProperties, selectPropertyError, selectPropertyNotAcceptedTermChallenges } from '@smartcoop/stores/property/selectorProperty'
import { UserActions } from '@smartcoop/stores/user'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import Tooltip from '@smartcoop/web-components/Tooltip'
import DairyFarmActionsListFragment from '@smartcoop/web-containers/fragments/digitalProperty/homeScreen/DairyFarmActionsListFragment'
import PropertyActivitiesChart from '@smartcoop/web-containers/fragments/PropertyActivitiesChart'
import PropertyGrowingSeasonsChart from '@smartcoop/web-containers/fragments/PropertyGrowingSeasonsChart'
import PropertyPendingManagementsList from '@smartcoop/web-containers/fragments/PropertyPendingManagementsList'
import PropertyResumeCard from '@smartcoop/web-containers/fragments/PropertyResumeCard'
import RainMapFragmentCard from '@smartcoop/web-containers/fragments/RainMapFragmentCard'
import WeatherForecastCard from '@smartcoop/web-containers/fragments/WeatherForecastCard'
import ChallengeTermModal from '@smartcoop/web-containers/modals/challenges/ChallengeTermModal'
import ChangePropertyModal from '@smartcoop/web-containers/modals/ChangePropertyModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  Container,
  CardItemTitle,
  CardItemBackground,
  HeaderTitle,
  CardButton,
  Row,
  HeaderTitleBold
} from './styles'

const PropertyHomeScreen = () => {
  const user = useSelector(selectUser)
  const currentProperty = useSelector(selectCurrentProperty)
  const t = useT()
  const history = useHistory()
  const { routes } = useRoutes()
  const { createDialog, removeDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const windowHeight = useWindowHeight()
  const snackbar = useSnackbar()

  const terms = useSelector(selectTerms)
  const userProperties = useSelector(selectProperties)
  const notAcceptedTerms = useSelector(selectPropertyNotAcceptedTermChallenges)
  const familyGroupProperties = useSelector(selectFamilyGroupProperties)
  const reloadData = useSelector(selectReloadData)
  const moduleIsTechnical = useSelector(selectModuleIsTechnical)
  const propertyError = useSelector(selectPropertyError)
  const oldAccessToken = useSelector(selectAccessToken)
  const oldRefreshToken = useSelector(selectRefreshToken)

  const theme = useTheme()
  const breakRightToBottom = useMediaQuery(theme.breakpoints.down('sm'))
  const breakMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const goToRainRecordList = useCallback(
    () => history.push(routes.rainRecordList.path),
    [history, routes]
  )

  const properties = useMemo(() => (
    [...userProperties, ...familyGroupProperties]
  ), [userProperties, familyGroupProperties])

  const changePropertyDialog = useCallback(() => {
    if (
      (size(properties) >= 1 && isEmpty(currentProperty)) ||
       size(properties) === 0
    ) {
      createDialog({
        id: 'change-property',
        Component: ChangePropertyModal,
        props: {
          onChooseProperty: () => {
            if(!moduleIsTechnical) {
              resetToken(
                {
                  accessToken: split(oldAccessToken, ' ')[1],
                  refreshToken: oldRefreshToken
                }
              )
                .then(({ accessToken, refreshToken, iaToken, tokenType }) => {
                  dispatch(
                    AuthenticationActions.refreshTokenSuccess(
                      `${ tokenType } ${ accessToken }`,
                      refreshToken,
                      iaToken
                    )
                  )
                })
            }
            history.push('/digital-property')
          }
        }
      })
    }
  }, [createDialog, currentProperty, dispatch, history, moduleIsTechnical, oldAccessToken, oldRefreshToken, properties])


  useEffect(() => {
    if(reloadData){
      dispatch(PropertyActions.setReloadData(false))
    }
  },[dispatch, reloadData])

  useEffect(() => {
    dispatch(AuthenticationActions.loadPermissions())
    dispatch(UserActions.loadUser())
    if(propertyError){
      snackbar.error(propertyError)
    }
  },[dispatch, propertyError, snackbar])

  useEffect(() => {
    changePropertyDialog()
  }, [changePropertyDialog, currentProperty])

  useEffect(() => {
    if(!isEmpty(currentProperty)) {
      dispatch(PropertyActions.loadPropertyChallenges())
    }

    return () => {
      dispatch(PropertyActions.resetPropertyChallenges())
    }
  }, [currentProperty, dispatch])

  useEffect(() => {
    if(!isEmpty(notAcceptedTerms)) {
      map(notAcceptedTerms, item => {
        createDialog({
          id: `accepted-term-modal-${ item?.project?.slug }`,
          Component: ChallengeTermModal,
          props: {
            handleClose: () => {
              removeDialog({ id: `accepted-term-modal-${ item?.project?.slug }` })
            },
            challengeTerm: {
              term: find(terms, term => term?.termTypeId === item?.project?.termTypeId),
              productivityChallenge: item
            },
            confirmModal: () => createDialog({
              id: 'confirm-delete-productivity-challange',
              Component: ConfirmModal,
              props: {
                onConfirm: () => {
                  dispatch(
                    ChallengesActions.deleteOfflineChallenge(
                      { productivityChallengeId: item?.id },
                      () => {
                        snackbar.success(
                          t('participation denied')
                        )
                        dispatch(PropertyActions.loadPropertyChallenges())
                        removeDialog({ id: `accepted-term-modal-${ item?.project?.slug }` })
                      }
                    )
                  )
                },
                textWarning: `${ t('are you sure you want to delete your property from') } ${ item?.project?.projectName }?`
              }
            }),
            challenge: item
          }
        })
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notAcceptedTerms])

  const rightContent = useMemo(
    () => (
      <Grid
        container
        spacing={ 2 }
        style={ breakRightToBottom ? { marginTop: 5, marginBottom: 15 } : undefined }
      >
        <Grid
          item
          xs={ 12 }
          sm={ 6 }
          md={ 12 }
        >
          <Grid container>

            <Grid item xs={ 12 }>
              <WeatherForecastCard />
            </Grid>

            <Grid item xs={ 12 }>
              <CardButton
                id="rain-register"
                onClick={ goToRainRecordList }
              >
                <>
                  <Icon
                    icon={ rain }
                    color={ colors.text }
                    size={ 20 }
                    style={ { marginRight: 10 } }
                  />
                  <I18n>rain record</I18n>
                </>
              </CardButton>
            </Grid>
          </Grid>

          <Grid item xs={ 12 } sm={ 6 } md={ 12 }>
            <RainMapFragmentCard property={ currentProperty } />
          </Grid>

          <Grid item xs={ 12 } sm={ 6 } md={ 12 }>
            <PropertyResumeCard property={ currentProperty } moduleIsTechnical={ moduleIsTechnical }/>
          </Grid>
        </Grid>

      </Grid>
    ),
    [breakRightToBottom, currentProperty, goToRainRecordList, moduleIsTechnical]
  )

  return (
    <Container>

      <Grid
        container
        spacing={ 2 }
        style={ {
          flex: 1,
          paddingTop: 15,
          paddingBottom: 0,
          paddingLeft: 15,
          paddingRight: 5,
          height: '100%'
        } }
      >

        <Grid
          item
          xs={ 12 }
          sm={ 12 }
          md={ 8 }
          lg={ 9 }
          style={ {
            display: 'flex',
            flexDirection: 'column'
          } }
        >

          <Grid container style={ { flex: '0 1 auto' } }>
            <Grid item xs={ 12 }>
              <CardItemBackground>
                <I18n as={ HeaderTitle } params={ { user: user?.name } }>
                  welcome
                </I18n>
                <HeaderTitleBold>
                  {user?.name}!
                </HeaderTitleBold>
              </CardItemBackground>
            </Grid>
          </Grid>

          {breakRightToBottom && rightContent}

          <Grid
            container
            spacing={ 2 }
            style={ {
              flex: 1,
              paddingBottom: 15,
              flexDirection: breakMobile ? 'column-reverse' : 'row'
            } }
          >
            <Grid
              item xs={ 12 }
              sm={ 5 }
              md={ 4 }
              // style={ {
              //   display: 'flex'
              // } }
            >
              <Grid container>
                <CardItemTitle
                  title={ (
                    <div style={ { display: 'flex' } }>
                      <Icon
                        icon={ calendar }
                        color={ colors.text }
                        size={ 20 }
                        style={ { marginRight: 10 } }
                      />
                      <I18n>
                        crops management scheduled
                      </I18n>
                    </div>
                  ) }
                  style={ {
                    justifyContent: 'flex-start',
                    marginBottom: breakRightToBottom ? 7.5 : 0
                  } }
                  childrenStyle={ {
                    maxHeight: breakRightToBottom ? 510 : ((windowHeight - 265)/2),
                    overflowY: 'auto',
                    padding: 0
                  } }
                  headerStyle={ { marginBottom: 0 } }
                >
                  <PropertyPendingManagementsList property={ currentProperty } />
                </CardItemTitle>
                <CardItemTitle
                  title={ (
                    <Row>
                      <I18n>
                        predicted actions - dairy farm
                      </I18n>
                      <Tooltip title={ t('late or scheduled actions for the next 7 days') }>
                        <Icon
                          icon={ info }
                          color={ colors.text }
                          size={ 15 }
                          style={ { marginRight: 10 } }
                        />
                      </Tooltip>
                    </Row>
                  ) }
                  style={ {
                    justifyContent: 'flex-start',
                    marginBottom: breakRightToBottom ? 7.5 : 0
                  } }
                  childrenStyle={ {
                    maxHeight: breakRightToBottom ? 510 : ((windowHeight - 265)/2),
                    overflowY: 'auto',
                    padding: 0
                  } }
                  headerStyle={ { marginBottom: 0 } }
                >
                  <DairyFarmActionsListFragment property={ currentProperty } />
                </CardItemTitle>
              </Grid>
            </Grid>

            <Grid item xs={ 12 } sm={ 7 } md={ 8 } style={ { display: 'flex' } }>
              <Grid container >

                <Grid item xs={ 12 }>
                  <CardItemTitle
                    title={ t('activity', { howMany: 1 }) }
                  >
                    <PropertyActivitiesChart property={ currentProperty } />
                  </CardItemTitle>
                </Grid>

                <Grid item xs={ 12 }>
                  <CardItemTitle
                    title={ t('finality and culture') }
                  >
                    <PropertyGrowingSeasonsChart property={ currentProperty } />
                  </CardItemTitle>
                </Grid>

              </Grid>
            </Grid>

          </Grid>
        </Grid>

        {!breakRightToBottom && (
          <Grid
            item
            xs={ 12 }
            sm={ 12 }
            md={ 4 }
            lg={ 3 }
          >
            <Container style={ { width: '100%' } }>
              {rightContent}
            </Container>
          </Grid>
        )}

      </Grid>
    </Container>
  )
}

PropertyHomeScreen.propTypes = {

}

export default PropertyHomeScreen
