import React, { useCallback, forwardRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { ChallengesActions } from '@smartcoop/stores/challenges'

import ProductivityForm from './forms/ProductivityForm'
import { Container } from './styles'

const CreateProjectForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    onSuccess,
    onError
  } = props

  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(
    (incomingData) => {
      setLoading(true)
      dispatch(ChallengesActions.saveProductivityChallenge(
        incomingData,
        () => {
          snackbar.success(t('your {this} was registered', {
            howMany: 1,
            this: t('project', { howMany: 1 }),
            gender: 'male'
          }))
          setLoading(false)
          onSuccess(incomingData)
        },
        () => {
          setLoading(false)
          onError()
        }
      ))
    },
    [dispatch, onError, onSuccess, snackbar, t]
  )

  useEffect(() => {
    dispatch(ChallengesActions.loadProjectTypes())
  }, [dispatch])

  return (
    <Container>
      <ProductivityForm
        ref={ formRef }
        onSubmit={ handleSubmit }
        withoutSubmitButton={ withoutSubmitButton }
        loading={ loading }
      />
    </Container>
  )
})

CreateProjectForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

CreateProjectForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
  withoutSubmitButton: false
}

export default CreateProjectForm
