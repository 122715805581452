import React from 'react'

import { colors } from '@smartcoop/styles'

function sanitizeString(value) {
  function isValidCharacter(codePoint) {
    return (codePoint >= 0x41 && codePoint <= 0x5A) // A..Z
    || (codePoint >= 0x61 && codePoint <= 0x7A) // a..z
    || (codePoint >= 0x30 && codePoint <= 0x39) // 0..9;
  }

  const chars = []

  for (const char of value) {
    if (!isValidCharacter(char.codePointAt(0))) {
      chars.push('\\')
    }
    chars.push(char)
  }

  return chars.join('')
}

export function getHighlightedText(text, higlight = '') {
  const parts = text.split(new RegExp(`(${ sanitizeString(higlight) })`, 'gi'))
  return parts.map((part, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={ `${ index }-${ part }` }>
      {part?.toLowerCase() === higlight?.toLowerCase() ? (
        <b style={ { backgroundColor: colors.yellow } }>{part}</b>
      ) : (
        part
      )}
    </React.Fragment>
  ))
}
