import React, { useRef, useEffect, useCallback, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { circle } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import CreatePasswordForm from '@smartcoop/web-containers/forms/auth/CreatePasswordForm'
import { withLayout } from '@smartcoop/web-containers/layouts'
import {
  Item,
  Header,
  Title,
  Text,
  ButtonsContainer,
  ListItem
} from '@smartcoop/web-containers/layouts/GuestLayout/theme'
import LoadingModal from '@smartcoop/web-containers/modals/LoadingModal'
import { useRoutes } from '@smartcoop/web-containers/routes/guest'

const CreatePasswordScreen = () => {
  const formRef = useRef(null)

  const dispatch = useCallback(useDispatch(), [])

  const user = useSelector(selectUser)

  const t = useT()
  const history = useHistory()
  const snackbar = useSnackbar()
  const { routes } = useRoutes()

  const {
    state: { mode }
  } = useLocation()
  const { createDialog, removeDialog } = useDialog()

  const handleSubmit = useCallback(
    (password) => {
      if (mode === 'signUp') {
        createDialog({
          id: 'loading-sign-up',
          Component: LoadingModal
        })
        const removeLoader = () => {
          setTimeout(() => removeDialog({ id: 'loading-sign-up' }), 150)
        }
        dispatch(
          AuthenticationActions.login(
            user.document,
            password,
            () => {
              removeLoader()
            },
            removeLoader
          )
        )
      } else if (mode === 'recover') {
        history.push(routes.login.path)
      }
    },
    [
      createDialog,
      dispatch,
      history,
      mode,
      removeDialog,
      routes.login.path,
      user.document
    ]
  )

  useEffect(() => {
    if (isEmpty(user)) {
      snackbar.error(t('you have no register'))
      history.replace(routes.login.path)
    }
  }, [history, routes.login, snackbar, t, user])

  return (
    <>
      <Item>
        <Header>
          <Title style={ { alignSelf: 'flex-end' } }>
            <I18n>all right</I18n>
          </Title>
        </Header>

        <Text>
          <I18n>your password must contain</I18n>
        </Text>

        <ListItem>
          <Icon icon={ circle } size={ 12 } />
          <Text style={ { paddingLeft: 10, marginTop: -4 } }>
            <I18n>at least length of five</I18n>
          </Text>
        </ListItem>

        <ListItem>
          <Icon icon={ circle } size={ 12 } />
          <Text style={ { paddingLeft: 10, marginTop: -4 } }>
            <I18n>at least one letter</I18n>
          </Text>
        </ListItem>

        <ListItem>
          <Icon icon={ circle } size={ 12 } />
          <Text style={ { paddingLeft: 10, marginTop: -4 } }>
            <I18n>at least one number</I18n>
          </Text>
        </ListItem>

        <ListItem>
          <Icon icon={ circle } size={ 12 } />
          <Text style={ { paddingLeft: 10, marginTop: -4 } }>
            <I18n>
              it must not contain your date of birth, social security number,
              registration number, telephone number, etc.
            </I18n>
          </Text>
        </ListItem>

        <CreatePasswordForm
          ref={ formRef }
          onSubmit={ handleSubmit }
          withoutSubmitButton
        />
      </Item>

      <Item>
        <ButtonsContainer style={ { flexDirection: 'row-reverse' } }>
          <Button
            id="create-password-submit"
            onClick={ () => formRef.current.submit() }
            style={ { width: '48%', flex: '0 0 auto' } }
          >
            <I18n>confirm</I18n>
          </Button>
        </ButtonsContainer>
      </Item>
    </>
  )
}

export default memo(withLayout('guest')(CreatePasswordScreen))
