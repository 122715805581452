import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

// Initial state
const INITIAL_STATE = {
  webinars: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadWebinars: ['params', 'onSuccess', 'onError'],
  loadWebinarsSuccess: ['webinars', 'onSuccess'],

  saveInformative: ['params', 'onSuccess', 'onError'],
  deleteInformative: ['params', 'onSuccess', 'onError'],
  accessWebinar: ['params', 'onSuccess', 'onError'],
  informativesError: ['error']
})

/**
 * Reducers functions
 */
const loadWebinarsSuccess = (state = INITIAL_STATE, { webinars }) => ({
  ...state,
  error: INITIAL_STATE.error,
  webinars
})


const informativesError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})


const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_WEBINARS_SUCCESS]: loadWebinarsSuccess,

  [Types.INFORMATIVES_ERROR]: informativesError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as InformativesTypes,
  Creators as InformativesActions
}
