import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import colors from '@smartcoop/styles/colors'
import { Item } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'


export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.5px;
  padding: 10px;
`
export const SubTitleItem = styled(Item)`
  font-size: 16px;
  font-weight: 600;
  padding: 0;
`

export const ItemWrapper = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: row;
`

export const ItemText = styled.div`
  padding: 0;
`
export const TextLabel = styled.div`
  font-weight: 600;
  padding-right: 5px;
`

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.backgroundHtml
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    padding: '12px 13px 0 13px'
  }
})
