import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import notInclude from '@smartcoop/forms/validators/notInclude.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerNewAnimal = ({ t, props }) =>
  Yup.object().shape({
    name: flow(required({ t }))(Yup.string()),

    earringCode: flow(
      required({ t }),
      notInclude({ t, text: 'invalid earring', compareWith: props.unavailableEarrings })
    )(Yup.string()),

    category: flow(required({ t }))(Yup.string()),

    birthDate: flow(required({ t }), date({ t }))(Yup.string()),

    lotId: flow(required({ t }))(Yup.string()),

    predominantBreedId: flow(required({ t }))(Yup.string()),

    statusId: flow(required({ t }))(Yup.string())
  })

export default registerNewAnimal
