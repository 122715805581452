/* eslint-disable no-nested-ternary */
import React, { useCallback, forwardRef, useMemo, useEffect, useState, useRef } from 'react'
import { useDispatch , useSelector } from 'react-redux'


import moment from 'moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import round from 'lodash/round'
import split from 'lodash/split'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'

import propertiesAssets from '@smartcoop/forms/schemas/property/propertiesAssets.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getActivities as getActivitiesService } from '@smartcoop/services/apis/smartcoopApi/resources/activity'
import { getDepreciationTypes } from '@smartcoop/services/apis/smartcoopApi/resources/propertiesAssets'
import { LotActions } from '@smartcoop/stores/lot'
import { selectLots } from '@smartcoop/stores/lot/selectorLot'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputFloat from '@smartcoop/web-components/InputFloat'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Title , Container, GridContainer, Row, InputRightGridContainer } from './styles'

const PropertiesAssetsForm = forwardRef((props, formRef) => {
  const { style, handleClose, onSuccess, readOnly, propertiesAssetsData, defaultValues, disabled, cropsOptions, isEditable } = props

  const lots = useSelector(selectLots)
  const mounted = useRef(false)
  const property = useSelector(selectCurrentProperty)

  const [managementId, setManagementId] = useState(uniq(map(defaultValues.assessments, data => data.activityId)) || null)
  const [activities, setActivities] = useState([])
  const [activityPercentages, setActivityPercentages] = useState({})
  const [cropsId, setCropsId] = useState([])
  const [cropPercentages, setCropPercentages] = useState({})
  const [lotsId, setLotsId] = useState([])
  const [lotsPercentages, setLotsPercentages] = useState({})
  const [depreciationGroups, setDepreciationGroups] = useState([])
  const [balanceToDepreciate, setBalanceToDepreciate] = useState(0)
  const [totalDepreciated, setTotalDepreciated] = useState(0)
  const [sharing, setSharing] = useState({})

  const isWritedOff = useMemo(() => defaultValues?.status === 'baixado', [defaultValues])
  const hasMovements = useMemo(() => defaultValues?.totalDepreciatedValue > 0, [defaultValues])

  const t = useT()
  const dispatch = useCallback(useDispatch(), [])

  const lotsOptions = useMemo(() => map(lots, lot => ({ label: lot.name, value: lot.id })), [lots])

  useEffect(() => {
    const defaultCrops = {}
    const defaultLots = {}
    map(uniq(map(defaultValues?.assessments, item => item?.activityId)), item => {
      const currentCrops = flatMap(defaultValues?.assessments, activity => {
        if (activity?.activityId === item) {
          return activity?.crops
        }
        return []
      })
      const currentLots = flatMap(defaultValues?.assessments, activity => {
        if (activity?.activityId === item) {
          return activity?.lots
        }
        return []
      })
      if(!isEmpty(currentCrops)) {
        defaultCrops[item] = map(currentCrops, crop => `${ crop.cropId }|${ crop?.sowingYear }`)
      }
      if(!isEmpty(currentLots)) {
        defaultLots[item] = map(currentLots, lot => lot.lotId)
      }
    })
    setCropsId(defaultCrops)
    setLotsId(defaultLots)
  },[defaultValues])

  const handleSubmitRegisterPropertyAsset = useCallback(
    (data) => {
      dispatch(PropertyActions.savePropertiesAssets({
        ...data,
        id: !isEmpty(defaultValues.id) ? defaultValues.id : null,
        propertyId: property.id,
        linkedAssetsId: !isEmpty(data.linkedAssetsId) ? data.linkedAssetsId : null,
        assetValue: toNumber(data.assetValue),
        assetMonthlyDepreciation: toNumber(data.assetMonthlyDepreciation),
        assetPurchaseDate: moment(data.assetPurchaseDate).format('YYYY-MM-DD'),
        startDepreciationDate: moment(data.startDepreciationDate).format('YYYY-MM-DD'),
        assessments: map(managementId, item => ({
          activityId: item,
          percentage: toNumber(activityPercentages[item]) || 100,
          type: 2,
          crops: map(cropPercentages[item], crop => {
            const cropData = split(crop?.cropId,'|')
            return ({
              ...crop,
              cropId: cropData[0],
              sowingYear: cropData[1],
              percentage: toNumber(crop?.percentage) || 100
            })
          }),
          lots: map(lotsPercentages[item], lot => {
            const lotData = split(lot?.lotId,'|')
            return ({
              ...lot,
              lotId: lotData[0],
              percentage: toNumber(lot?.percentage) || 100
            })
          })
        }))
      }, () => {
        onSuccess()
        handleClose()
      }))
    },
    [activityPercentages, cropPercentages, dispatch, handleClose, lotsPercentages, managementId, onSuccess, property.id, defaultValues.id]
  )

  useEffect(() => {
    if(defaultValues && !mounted.current) {
      const defaultActivityPercentages = {}
      const defaultCropPercentages = []
      const defaultCropPercentagesByActivity = {}
      const defaultLotPercentages = []
      const defaultLotPercentagesByActivity = {}
      const defaultSharing = {}

      const groupedActivities = groupBy(defaultValues?.assessments, 'activityId')
      map(groupedActivities, (item, key) => {
        defaultActivityPercentages[key] = sumBy(item, percentage => toNumber(percentage.percentage))
        defaultSharing[key] = ''
      })
      setActivityPercentages(defaultActivityPercentages)
      map(defaultValues?.assessments, (item) => {
        if(item?.crops) {
          map(item?.crops, crop => {
            defaultCropPercentages.push({ activityId: item?.activityId, cropId: `${ crop?.cropId }|${ crop?.sowingYear }`, percentage: round(((toNumber(crop?.percentage)/100)/(toNumber(defaultActivityPercentages[item?.activityId])/100))*100,2) })
          })
        }
        if (item?.lots) {
          map(item?.lots, lot => {
            defaultLotPercentages.push({ activityId: item?.activityId, lotId: lot?.lotId, percentage: round(((toNumber(lot?.percentage)/100)/(toNumber(defaultActivityPercentages[item?.activityId])/100))*100,2) })
          })
        }
      })

      map(uniqBy(defaultValues?.assessments, 'activityId'), item => {
        if(item?.crops) {
          defaultCropPercentagesByActivity[item?.activityId] = filter(defaultCropPercentages, crop => crop?.activityId === item?.activityId)
        }
        if (item?.lots) {
          defaultLotPercentagesByActivity[item?.activityId] = filter(defaultLotPercentages, lot => lot?.activityId === item?.activityId)
        }
      })
      map(defaultCropPercentagesByActivity, (item, index) => {
        if(!isEmpty(item)) {
          defaultSharing[index] = 'crop'
        }
      })
      map(defaultLotPercentagesByActivity, (item, index) => {
        if(!isEmpty(item)) {
          defaultSharing[index] = 'lot'
        }
      })
      setCropPercentages(defaultCropPercentagesByActivity)
      setLotsPercentages(defaultLotPercentagesByActivity)
      setSharing(defaultSharing)
      mounted.current = true
    } else {
      const currentData = { ...activityPercentages }
      const notInObject = filter(map(activityPercentages, (item, index) => !includes(index, managementId) ? index : null), val => val)
      map(notInObject, item => {
        delete currentData.[item]
      })
      map(managementId, item => {
        currentData[item] = 0
      })
      if(managementId.length === 1) {
        currentData[managementId[0]] = '100'
      }
      setActivityPercentages(currentData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[managementId, defaultValues])

  const blockedSave = useMemo(() => {
    if(!isEmpty(activityPercentages)) {
      let block = false
      if(sum(map(activityPercentages, item => toNumber(item))) !== 100){
        block = '* O somatório das porcentagens do rateio por atividade deve ser 100%.'
      }
      map(cropPercentages, item => {
        if(!isEmpty(item) && sum(map(item, crop => toNumber(crop?.percentage))) !== 100) {
          block = '* O somatório das porcentagens do rateio por cultura deve ser 100%.'
        }
      })

      map(lotsPercentages, item => {
        if(!isEmpty(item) && sum(map(item, lot => toNumber(lot?.percentage))) !== 100) {
          block = '* O somatório das porcentagens do rateio por lote deve ser 100%.'
        }
      })

      return block
    }
    return false
  }, [activityPercentages, cropPercentages, lotsPercentages])

  const propertiesOptions = useMemo(() => propertiesAssetsData.map((propertyItem) => ({
    label: propertyItem.assetDescription,
    value: propertyItem.id
  })), [propertiesAssetsData])

  const statusOptions = useMemo(() => ([
    { label: 'Ativo', value: 'ativo', disabled: true },
    { label: 'Inativo', value: 'inativo', disabled: false },
    { label: 'Baixado', value: 'baixado', disabled: false },
    { label: 'Vendido', value: 'vendido', disabled: false }
  ]), [])

  const handleMonthlyDepreciation = useCallback(() => {
    const assetValue = formRef?.current?.getFieldValue('assetValue') || defaultValues?.assetValue
    const assetEstimatedLife = formRef?.current?.getFieldValue('assetEstimatedLife') || defaultValues?.assetEstimatedLife

    if (assetValue && assetEstimatedLife) {
      const monthlyDepreciation = (assetValue / assetEstimatedLife) / 12

      if (monthlyDepreciation) {
        formRef.current.setFieldValue('assetMonthlyDepreciation', monthlyDepreciation.toFixed(2))
      }
    }
  }, [defaultValues.assetEstimatedLife, defaultValues.assetValue, formRef])

  const handleBalanceToDepreciate = useCallback(() => {
    const assetValue = formRef.current.getFieldValue('assetValue') || defaultValues.assetValue
    const totalDepreciatedValue = formRef.current.getFieldValue('totalDepreciatedValue') || defaultValues.totalDepreciatedValue

    if (assetValue && totalDepreciatedValue) {
      const balanceToDepreciateValue = assetValue - totalDepreciatedValue

      setBalanceToDepreciate(balanceToDepreciateValue)
      setTotalDepreciated(totalDepreciatedValue)
      formRef.current.setFieldValue('balanceToDepreciate', round(balanceToDepreciateValue,2))
    }
  }, [defaultValues, formRef])

  const handleAssetLife = useCallback((_, item) => {

    formRef.current.getFieldRef('assetEstimatedLife').setValue(item?.usefulLife)

  }, [formRef])

  const getActivities = useCallback(async () => {
    const { data } = await getActivitiesService({ limit: 9999 })
    setActivities(map(data, item => ({ label: item?.name, value: item?.id, slug: item?.slug })))
  }, [])

  const getDepreciationGroups = useCallback(async () => {
    const { data } = await getDepreciationTypes()
    setDepreciationGroups(map(data.data, item => ({ label: item?.name, value: item?.id, slug: item?.slug, usefulLife: item?.usefulLife })))
  }, [])

  useEffect(() => {
    handleBalanceToDepreciate()
  }, [handleBalanceToDepreciate])

  useEffect(() => {
    getActivities()
  },[getActivities, property])

  useEffect(() => {
    if (isEmpty(lots)) {
      dispatch(LotActions.loadLots({}))
    }
  }, [dispatch, lots])

  useEffect(() => {
    getDepreciationGroups()
  }, [getDepreciationGroups])

  return (
    <Container>
      <Form
        style={ { ...style } }
        ref={ formRef }
        schemaConstructor={ propertiesAssets }
        schemaProps={ { totalDepreciated, isEditable } }
        onSubmit={ handleSubmitRegisterPropertyAsset }
      >
        <InputRightGridContainer>
          <div/>
          {isEditable && (
            <InputSelect
              label={ t('status') }
              name="status"
              options={ statusOptions }
              defaultValue={ defaultValues.status }
              disabled
            />
          )}
        </InputRightGridContainer>
        <GridContainer>
          <InputText
            name="assetDescription"
            label={ t('asset description') }
            fullWidth
            defaultValue={ defaultValues.assetDescription }
          />
          <InputSelect
            fullWidth
            label={ t('linked asset') }
            name="linkedAssetsId"
            options={ propertiesOptions }
            defaultValue={ defaultValues.linkedAssetsId }
            disabled={ disabled }
          />
          <InputText
            name="assetBrand"
            label={ t('asset brand') }
            fullWidth
            defaultValue={ defaultValues.assetBrand }
          />
          <InputNumber
            name="assetYear"
            label={ t('asset year') }
            fullWidth
            notSeparate
            maxLength={ 4 }
            defaultValue={ defaultValues.assetYear }
          />
          <InputDate
            fullWidth
            name="assetPurchaseDate"
            label={ t('asset purchase date') }
            pickerProps={ { maxDate: moment().format() } }
            defaultValue={ defaultValues.assetPurchaseDate ? moment(defaultValues.assetPurchaseDate).format('YYYY-MM-DD') : '' }
            disabled={ isWritedOff }
          />
          <InputDate
            fullWidth
            name="startDepreciationDate"
            label={ t('start depreciation date') }
            pickerProps={ { minDate: moment().startOf('month').format() } }
            defaultValue={ defaultValues.startDepreciationDate ? moment(defaultValues.startDepreciationDate).format('YYYY-MM-DD') : '' }
            disabled={ isWritedOff || hasMovements }
          />
          <InputSelect
            fullWidth
            label={ t('depreciation group') }
            name="assetTypeId"
            options={ depreciationGroups }
            onChange={ handleAssetLife }
            defaultValue={ defaultValues.assetTypeId }
            disabled={ isWritedOff }
          />
          <InputFloat
            name="assetValue"
            label={ t('asset value') }
            fullWidth
            maxLength={ 15 }
            onChange={ handleMonthlyDepreciation }
            defaultValue={ defaultValues.assetValue }
            disabled={ isWritedOff }
          />
          <InputNumber
            name="assetEstimatedLife"
            label={ t('asset estimated life') }
            fullWidth
            notSeparate
            maxLength={ 4 }
            onChange={ handleMonthlyDepreciation }
            defaultValue={ defaultValues.assetEstimatedLife }
            disabled={ isWritedOff || hasMovements }
          />
          <InputFloat
            name="assetMonthlyDepreciation"
            label={ t('asset monthly depreciation') }
            fullWidth
            disabled
            defaultValue={ defaultValues.assetMonthlyDepreciation }
          />
          {isEditable && (
            <>
              <InputFloat
                name="totalDepreciatedValue"
                label={ t('total depreciation') }
                fullWidth
                maxLength={ 15 }
                disabled
                onChange={ handleBalanceToDepreciate }
                defaultValue={ defaultValues.totalDepreciatedValue }
              />
              <InputFloat
                name="balanceToDepreciate"
                label={ t('balance to depreciate') }
                defaultValue={ balanceToDepreciate }
                disabled
                fullWidth
                maxLength={ 15 }
              />
            </>
          )}
        </GridContainer>

        <Title style={ { fontSize: 18 } }>
          {t('activity config')}
        </Title>
        <InputSelect
          detached
          name="managementId"
          label={ `${ t('activity', { howMany: 2 }) }` }
          options={ activities }
          value={ managementId }
          onChange={ setManagementId }
          fullWidth
          multiple
          disabled={ isWritedOff }
        />

        {managementId.length > 0 && (
          <>
            {map(managementId, item => (
              <fieldset style={ { borderRadius: 10, border: '1px solid #babaca' } }>
                <legend>
                  <Title>
                    {find(activities, option => option?.value === item)?.label}
                  </Title>
                </legend>

                <InputUnit
                  unit="%"
                  key={ `input-${ item }` }
                  detached
                  label={ t('percentage') }
                  type="float"
                  fullWidth
                  maxValue="100.00"
                  disabled={ managementId.length === 1 || isWritedOff }
                  value={ managementId.length === 1 ? '100' : activityPercentages[item] }
                  onChange={ ({ target: { value } }) => {
                    const currentData = { ...activityPercentages }
                    currentData[item] = toNumber(value) >= 100 ? '100' : value
                    setActivityPercentages(currentData)
                  } }
                />

                <Title>
                  Rateio
                </Title>
                <Row>
                  <CheckboxButton
                    value={ sharing?.[item] === 'crop' }
                    checked={ sharing?.[item] === 'crop' }
                    onChange={ () => setSharing((old) => {
                      const currentData = { ...old }
                      if(old?.[item] === 'crop') {
                        currentData[item] = ''
                      } else {
                        currentData[item] = 'crop'
                      }
                      const currentCropPercentages = { ...cropPercentages }
                      delete currentCropPercentages?.[item]
                      setCropPercentages(currentCropPercentages)
                      const currentCropsIds = { ...cropsId }
                      delete currentCropsIds?.[item]
                      setCropsId(currentCropsIds)

                      const currentLotsPercentages = { ...lotsPercentages }
                      delete currentLotsPercentages?.[item]
                      setLotsPercentages({})
                      const currenLotsTypes = { ...lotsId }
                      delete currenLotsTypes?.[item]
                      setLotsId(currenLotsTypes)

                      return currentData
                    }) }
                    label={ t('by crop') }
                    style={ { marginRight: 10 } }
                  />
                  {(find(activities, (option) => option?.value === item)?.slug === 'pecuaria-de-leite' || find(activities, (option) => option?.value === item)?.slug === 'pecuaria-de-corte') ? (
                    <CheckboxButton
                      value={ sharing?.[item] === 'lot' }
                      checked={ sharing?.[item] === 'lot' }
                      onChange={ () => setSharing((old) => {
                        const currentData = { ...old }
                        if(old?.[item] === 'lot') {
                          currentData[item] = ''
                        } else {
                          currentData[item] = 'lot'
                        }
                        const currentCropPercentages = { ...cropPercentages }
                        delete currentCropPercentages?.[item]
                        setCropPercentages(currentCropPercentages)
                        const currentCropsIds = { ...cropsId }
                        delete currentCropsIds?.[item]
                        setCropsId(currentCropsIds)

                        const currentLotsPercentages = { ...lotsPercentages }
                        delete currentLotsPercentages?.[item]
                        setLotsPercentages({})
                        const currenLotsTypes = { ...lotsId }
                        delete currenLotsTypes?.[item]
                        setLotsId(currenLotsTypes)

                        return currentData
                      }) }
                      label={ t('by lot') }
                    />
                  ) : null}
                </Row>
                { sharing?.[item] === 'crop' ? (
                  <>
                    {find(activities, option => option?.value === item)?.slug !== 'reserva-legal' && (
                      <InputSelect
                        detached
                        label={ t('crop', { howMany: 2 }) }
                        value={ cropsId[item] }
                        onChange={ (value) => {
                          const currentData = { ...cropsId }
                          currentData[item] = value
                          setCropsId(currentData)
                          const currentCropPercentages = { ...cropPercentages }
                          currentCropPercentages[item] = map(value, crop => ({ cropId: crop, percentage: toString(round(100 / value.length, 2)) }))
                          setCropPercentages(currentCropPercentages)
                        } }x
                        options={ cropsOptions }
                        fullWidth
                        multiple
                        disabled={ isWritedOff }
                      />
                    )}
                    {cropsId?.[item]?.length > 1  && (
                      <>
                        <Title>
                          {t('sharing by crop')}
                        </Title>
                        <Row>
                          {map(cropsId?.[item] , subItem => (
                            <InputUnit
                              unit="%"
                              style={ { marginLeft: 5, marginRight: 5 } }
                              key={ `input-${ subItem }` }
                              detached
                              label={ find(cropsOptions, option => option?.value === subItem)?.label }
                              type="float"
                              fullWidth
                              value={ find(cropPercentages[item], crop => crop?.cropId === subItem)?.percentage }
                              onChange={ ({ target: { value } }) => {
                                const currentData = { ...cropPercentages }
                                const foundData = !!find(currentData[item], crop => crop?.cropId === subItem)
                                currentData[item] = foundData ?
                                  map(currentData[item], crop => crop?.cropId === subItem
                                    ? { ...crop, percentage: toNumber(value) > 100 ? '100' : value } : crop) : (currentData[item]?.length
                                    ? [...currentData[item], { cropId: subItem, percentage: toNumber(value) > 100 ? '100' : value }]
                                    : [{ cropId: subItem, percentage: toNumber(value) > 100 ? '100' : value }])
                                setCropPercentages(currentData)
                              } }
                              disabled={ isWritedOff }
                            />
                          ))}
                        </Row>
                      </>
                    )}
                  </>
                ) : null }
                { sharing?.[item] === 'lot' ? (
                  <>
                    {(find(activities, option => option?.value === item)?.slug === 'pecuaria-de-leite' || find(activities, option => option?.value === item)?.slug === 'pecuaria-de-corte') && (
                      <InputSelect
                        detached
                        label={ t('lot type') }
                        name="slug"
                        options={ lotsOptions }
                        value={ lotsId[item] }
                        onChange={ (value) => {
                          const currentData = { ...lotsId }
                          currentData[item] = value
                          setLotsId(currentData)
                          const currentLotsPercentages = { ...lotsPercentages }
                          currentLotsPercentages[item] = map(value, crop => ({ lotId: crop, percentage: toString(round(100 / value.length, 2)) }))
                          setLotsPercentages(currentLotsPercentages)
                        } }
                        fullWidth
                        multiple
                        disabled={ isWritedOff }
                      />
                    )}

                    {lotsId?.[item]?.length > 1  && (
                      <>
                        <Title>
                          {t('sharing by lot')}
                        </Title>
                        <Row>
                          {map(lotsId?.[item] , subItem => (
                            <InputUnit
                              unit="%"
                              style={ { marginLeft: 5, marginRight: 5 } }
                              key={ `input-${ subItem }` }
                              detached
                              label={ find(lotsOptions, option => option?.value === subItem)?.label }
                              type="float"
                              fullWidth
                              value={ find(lotsPercentages[item], lot => lot?.lotId === subItem)?.percentage }
                              onChange={ ({ target: { value } }) => {
                                const currentData = { ...lotsPercentages }
                                const foundData = !!find(currentData[item], lot => lot?.lotId === subItem)
                                currentData[item] = foundData ?
                                  map(currentData[item], lot => lot?.lotId === subItem
                                    ? { ...lot, percentage: toNumber(value) > 100 ? '100' : value } : lot) : (currentData[item]?.length
                                    ? [...currentData[item], { lotId: subItem, percentage: toNumber(value) > 100 ? '100' : value }]
                                    : [{ lotId: subItem, percentage: toNumber(value) > 100 ? '100' : value }])
                                setLotsPercentages(currentData)
                              } }
                              disabled={ isWritedOff }
                            />
                          ))}
                        </Row>
                      </>
                    )}
                  </>
                ) : null }
              </fieldset>
            ))}
          </>
        )}

        {blockedSave && (
          <p style={ { color: 'red', fontSize: 12 } }>{blockedSave}</p>
        )}
        { !readOnly && (
          <ButtonsContainer>
            <Button
              id="cancel-properties-assets-form"
              onClick={ handleClose }
              style={ { flex: 1, marginRight: 10 } }
              variant="outlined"
            >
              <I18n>cancel</I18n>
            </Button>

            <Button
              id="submit-properties-assets-form"
              disabled={ blockedSave }
              onClick={ () => formRef.current.submit() }
              style={ { flex: 1 } }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

PropertiesAssetsForm.propTypes = {
  style: PropTypes.object,
  handleClose: PropTypes.func,
  readOnly: PropTypes.bool,
  propertiesAssetsData: PropTypes.array,
  onSuccess: PropTypes.func,
  defaultValues: PropTypes.object,
  disabled: PropTypes.bool,
  cropsOptions: PropTypes.array.isRequired,
  isEditable: PropTypes.bool.isRequired
}

PropertiesAssetsForm.defaultProps = {
  style: {},
  handleClose: () => {},
  readOnly: false,
  propertiesAssetsData: [],
  onSuccess: () => {},
  defaultValues: {},
  disabled: false
}

export default PropertiesAssetsForm
