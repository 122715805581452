import fonts from '@smartcoop/styles/fonts'

export default {
  // loadingType: 'linear',
  selection: false,
  columnsButton: false,
  sorting: true,
  grouping: false,
  filtering: false,
  debounceInterval: 500,
  pageSizeOptions: [10, 20, 50, 100],
  initialPage: 0,
  actionsColumnIndex: -1,
  emptyRowsWhenPaging: false,
  toolbar: false,
  search: false,
  draggable: false,
  addRowPosition: 'first',
  toolbarProps: {
    color: 'primary'
  },
  paginationIconButtonProps: {
    size: 'small'
  },
  headerStyle: {
    zIndex: 11,
    fontWeight: fonts.fontWeight.bold
  },
  actionsCellDivStyle: {
    justifyContent: 'flex-end'
  },
  actionsCellStyle: {
    width: 'auto'
  },
  searchFieldProps: {
    type: 'search',
    InputProps: {
      endAdornment: null
    }
  }
}
