import React, { useCallback, forwardRef, useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import html2pdf from 'html2pdf.js'
import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import flow from 'lodash/fp/flow'
import replaceFP from 'lodash/fp/replace'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import toUpper from 'lodash/toUpper'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { download } from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { deleteTechnicalEvaluation } from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectIsProfileRTC } from '@smartcoop/stores/organization/selectorOrganization'
import { TechnicalActions } from '@smartcoop/stores/technical'
import { selectTechnicalFormsAnswers } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import technicalEvaluationReport from '@smartcoop/styles/assets/docs/technicalEvaluationReport'
import technicalEvaluationReportNoProducer from '@smartcoop/styles/assets/docs/technicalEvaluationReportNoProducer'
import { FORM_INPUT_TYPES } from '@smartcoop/utils/constants'
import { momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'

const TechnicalEvaluationList = forwardRef(({ filters }, tableRef) => {
  const t = useT()
  const dispatch = useDispatch()
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()

  const isRTC = useSelector(selectIsProfileRTC)
  const user = useSelector(selectUser)
  const technicalFormsAnswers = useSelector(selectTechnicalFormsAnswers)
  const isTechnical = useSelector(selectModuleIsTechnical)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    dispatch(
      TechnicalActions.loadTechnicalEvaluationAnswers({}, () => setLoading(false), () => setLoading(false))
    )
  },[dispatch, filters, isRTC, isTechnical])

  const columns = useMemo(
    () => [
      {
        title: t('evaluation date'),
        field: 'createdAt',
        sorting: true,
        defaultSort: 'desc',
        render: row => moment(row?.createdAt, 'YYYY-MM-DD HH:mm:ss').format(momentFriendlyDateTimeFormat)
      },{
        title: t('organization', { howMany: 1 }),
        field: 'technicianOrganizationUser.organization.tradeName',
        sorting: true
      },
      {
        title: isTechnical ? t('producer', { howMany: 1, gender: 'male' }) : t('technical'),
        field: isTechnical ? 'proprietary.name' : 'technicianOrganizationUser.user.name',
        sorting: true
      },
      {
        title: t('name'),
        field: 'quiz.name',
        sorting: true
      }
    ], [isTechnical, t]
  )

  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_QUIZ_ID, fileKey: file })

    return downloadFromBase64(data)
  }, [])

  const downloadCertificate = useCallback(async (row) => {
    const opt = {
      margin:       [30, 30, 30, 30], // top, left, bottom, right
      filename:    `relatorio-${ row?.quiz?.name }.pdf`,
      image:        { type: 'jpeg', quality: 0.40 },
      html2canvas:  { scale: 2 },
      jsPDF:        { format: 'a4', orientation: 'portrait', hotfixes: ['px_scaling'], unit: 'px', letterRendering: true  },
      pageBreak: { mode: ['avoid-all', 'css', 'legacy'], avoid: ['tr'] }
    }

    const  blob = html2pdf()

    const renderRecommendations = group => {

      const recommendations = filter(group.answers, item => !isEmpty(item.recommendationsAnswer))
      const recommendationsData = flatMap(recommendations, item => item.recommendationsAnswer)

      return map(recommendationsData, item => `
        <tr>
        <td>${ find(recommendations, recommendation => recommendation.id === item.questionAnswerId)?.quizQuestion?.name }</td>
        <td>
          <p style="text-align: left;">
            ${ item?.recommendation?.recommendation || '-' }
          </p>
          <p style="text-align: left;">
            Causa raiz:
            ${ item?.recommendation?.rootCause || '-' }
          </p>
        </td>
        </tr>
      `).join(' ')

    }
    const imagesList = {}
    const imagesArray = filter(flatMap(row?.quiz?.questionGroup, group => flatMap(group?.answers, item => item)), item => item?.quizQuestion?.type === FORM_INPUT_TYPES.image)
    await Promise.all(map(imagesArray, async item => {
      const file = await renderFile(item?.answer)
      imagesList[item?.id] = file
    }))

    const response = await flow(
      replaceFP('{tableData}', map(row?.quiz?.questionGroup, group => !isEmpty(filter(group?.answers, item => item?.quizQuestion?.type !== FORM_INPUT_TYPES?.signature)) ?
        `<table>
      <thead>
        <tr>
          <th scope="col" colSpan="2">➧ ${ toUpper(group?.description === 'default' ? row?.quiz?.name : group?.description) }</th>
        </tr>
      </thead>
      <tbody id="result">
      ${ map(group?.answers, item => item?.quizQuestion?.type !== FORM_INPUT_TYPES?.signature ? `
      <tr>
        <td>${ item?.quizQuestion?.name }</td>
        <td>${ item?.quizQuestion?.type === FORM_INPUT_TYPES.image ? `<img src="${ imagesList[item?.id] }" height="100" />` : item?.answer }</td>
      </tr>
      ` : '').join(' ') }
      </tbody>
    </table>
    ${ !isEmpty(filter(group?.answers, (answer) => !isEmpty(answer?.recommendationsAnswer))) ?
    `
          <table>
          <thead>
            <tr>
              <th scope="col" colSpan="2">➧ RECOMENDAÇÕES TÉCNICAS</th>
            </tr>
          </thead>
          <tbody id="result">
            ${ renderRecommendations(group) }
            </tbody>
        </table>
          ` : '' }
    ` : '').join(' ')),
      replaceFP('{quizName}', row?.quiz?.name),
      replaceFP('{quizName}', row?.quiz?.name),
      replaceFP('{producerName}', row?.proprietary?.name),
      replaceFP('{technicalName}', row?.technicianOrganizationUser?.user?.name),
      replaceFP('{stateRegisterment}', row?.technicianOrganizationUser?.registry),
      replaceFP('{organizationName}', row?.technicianOrganizationUser?.organization?.tradeName),
      replaceFP('{city}', user?.city),
      replaceFP('{currentDate}', moment().format(momentFriendlyDateTimeFormat)),
      replaceFP('{signatures}', () => {
        const signatures = filter(row?.answers, answer => answer?.quizQuestion?.type === FORM_INPUT_TYPES?.signature)

        return map(signatures, signature => `
          <div class="signature">
          <img class="logo" src="${ downloadFromBase64(signature?.answer) }" />
          <p>${ signature?.quizQuestion?.name }</p>
          </div>
        `)
      }),
      replaceFP('{createdAt}', moment(row?.createdAt).format(momentFriendlyDateTimeFormat))
    )(row?.proprietary?.name ? technicalEvaluationReport : technicalEvaluationReportNoProducer)
    await blob.set(opt).from(response).toPdf().save()
  }, [renderFile, user.city])

  const actions = useMemo(
    () => [
      (row) => ({
        position: 'row',
        onClick: () => downloadCertificate(row),
        iconButtonProps: {
          id: 'download-technical-evaluation',
          variant: 'outlined',
          size: 'small',
          color: 'black',
          style: {
            marginTop: 4,
            width: 30,
            marginRight: 10,
            height: 30,
            borderRadius: 5,
            color: colors.black
          }
        },
        icon: () => (
          <>
            <Icon icon={ download } size={ 14 } />
          </>
        )
      })
    ],
    [downloadCertificate]
  )

  return (
    <DataTable
      actions={ actions }
      tableRef={ tableRef }
      columns={ columns }
      data={ filter(technicalFormsAnswers, answer => answer?.quiz?.modelSlug === 'pqfl') }
      loading={ loading }
      onDeleteClick={ (_, row) => {
        createDialog({
          id: 'delete-evaluation',
          Component: ConfirmModal,
          props: {
            onConfirm: async () => {
              await deleteTechnicalEvaluation({ quizAnswerId: row?.id }).then(
                () => {
                  setLoading(true)
                  dispatch(
                    TechnicalActions.loadTechnicalEvaluationAnswers({}, () => setLoading(false), () => setLoading(false))
                  )
                  snackbar.success(
                    t('your {this} was deleted', {
                      gender: 'female',
                      howMany: 1,
                      this: t('technical evaluation')
                    })
                  )
                }
              )
            },
            textWarning: t('are you sure you want to delete the {this}?', {
              gender: 'female',
              howMany: 1,
              this: t('technical evaluation')
            })
          }
        })
      } }
      id="accrediteds-list-table"
    />
  )
})

TechnicalEvaluationList.propTypes = {
  filters: PropTypes.object
}

TechnicalEvaluationList.defaultProps = {
  filters: {}
}

export default TechnicalEvaluationList
