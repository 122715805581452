import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import Card from '@smartcoop/web-components/Card'

export const CardItem = styled(Card).attrs({
  cardStyle: {
    width: '100%',
    padding: 0
  },
  childrenStyle: {
    padding: '0 20px 25px',
    marginTop: -15
  }
})`
  margin-top: 0px;
  margin-bottom: 0;
  width: 100%;
`

export const DotsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 3px;
`

export const FarmName = styled(Typography).attrs({
  align: 'center'
})`
  font-weight: ${ fonts.fontWeight.bold };
  font-size: 16px;
  padding: 10px 10px 20px;
`

export const AreasContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`

export const AreaContainer = styled.div`
  text-align: center;
`

export const InfoContent = styled(Typography).attrs({
  variant: 'body2'
})``

export const InfoContentTitle = styled(InfoContent)`
  color: ${ colors.darkGrey };
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
    font-size: .9rem;
  }
`
