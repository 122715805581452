import flow from 'lodash/fp/flow'

import min from './min.validator'
import regex from './regex.validator'

export default ({ t }) => YupInstance => flow(
  regex({ t, text: 'invalid password', regex: '^(?=.*[a-z A-Z])(?=.*[0-9])' }),
  min({
    t,
    name: t('password'),
    min: 5
  })
)(YupInstance)

