import React from 'react'

import map from 'lodash/map'

import { scaleColors } from '@smartcoop/utils/constants'

import { Container, Item } from './styles'

const RainScaleFragment = () => (
  <Container>
    { map(scaleColors, item => (
      <Item key={ item.color } color={ item.color }>{item.text}</Item>
    )) }
  </Container>
)

export default RainScaleFragment
