import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  getBartersByOrg as getBartersByOrgService,
  getPackagesByOrg as getPackagesByOrgService,
  createBarter as createBarterService,
  editBarter as editBarterService,
  editBarterPackageOrder as editBarterPackageOrderService,
  joinBarter as joinBarterService,
  deleteBarter as deleteBarterService
} from '@smartcoop/services/apis/smartcoopApi/resources/barter'

import { selectCurrentOrganization } from '../organization/selectorOrganization'
import { BarterActions, BarterTypes } from './duckBarter'

function* saveProducerBarter({ barter, onSuccess = () => {}, onError = () => {} }) {
  const currentOrganization = yield select(selectCurrentOrganization)

  try {
    yield call(
      barter.barterOrderNumber ? editBarterService : createBarterService,
      {
        ...barter,
        barterOrderNumber: barter.barterOrderNumber,
        organizationId: currentOrganization.id
      },
      {
        barterOrderNumber: barter.barterOrderNumber,
        organizationId: currentOrganization.id
      }
    )

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(BarterActions.barterError(error))
    yield call(onError, error)
  }
}

function* saveOfflineProducerBarter({ barter, onSuccess = () => {}, onError = () => {} }) {
  yield put(BarterActions.saveProducerBarter(barter, onSuccess, onError))
}

function* joinBarterPackage({ barter, onSuccess = () => {}, onError = () => {} }) {
  const currentOrganization = yield select(selectCurrentOrganization)

  try {
    yield call(
      barter.barterOrderNumber ? editBarterPackageOrderService : joinBarterService,
      {
        ...barter,
        organizationId: currentOrganization.id
      },
      { organizationId: currentOrganization.id, barterOrderNumber: barter.barterOrderNumber }
    )

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(BarterActions.barterError(error))
    yield call(onError, error)
  }
}

function* joinOfflineBarterPackage({ barter, onSuccess = () => {}, onError = () => {} }) {
  yield put(BarterActions.saveProducerBarter(barter, onSuccess, onError))
}

function* loadBarters({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)

    const { data: { data: products, ...pagination } } = yield call(getBartersByOrgService, {
      limit: 10,
      ...params
    }, { organizationId: currentOrganization.id })

    yield put(BarterActions.loadBartersSuccess(
      products,
      pagination.page,
      () => onSuccess(pagination)
    ))
  } catch (error) {
    yield put(BarterActions.barterError(error))
    yield call(onError, error)
  }
}


function* loadBartersSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* loadPackages({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)

    const { data: { data: products, ...pagination } } = yield call(getPackagesByOrgService, {
      limit: 10,
      ...params
    }, { organizationId: currentOrganization.id })

    yield put(BarterActions.loadPackagesSuccess(
      products,
      pagination.page,
      () => onSuccess(pagination)
    ))
  } catch (error) {
    yield put(BarterActions.barterError(error))
    yield call(onError, error)
  }
}

function* loadPackagesSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* deleteOfflineBarter({ barterOrderNumber, onSuccess = () => {}, onError = () => {} }) {
  yield put(BarterActions.deleteBarter(barterOrderNumber, onSuccess, onError))
}

function* deleteBarter({ barterOrderNumber, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)

    yield call(deleteBarterService, {}, { barterOrderNumber, organizationId: currentOrganization.id })

    yield call(onSuccess)
    yield put(BarterActions.loadBarters())
  } catch (error) {
    yield put(BarterActions.barterError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(BarterTypes.SAVE_PRODUCER_BARTER, saveProducerBarter),
  takeLatest(BarterTypes.SAVE_OFFLINE_PRODUCER_BARTER, saveOfflineProducerBarter),

  takeLatest(BarterTypes.JOIN_BARTER_PACKAGE, joinBarterPackage),
  takeLatest(BarterTypes.JOIN_OFFLINE_BARTER_PACKAGE, joinOfflineBarterPackage),

  takeLatest(BarterTypes.LOAD_BARTERS, loadBarters),
  takeLatest(BarterTypes.LOAD_BARTERS_SUCCESS, loadBartersSuccess),

  takeLatest(BarterTypes.LOAD_PACKAGES, loadPackages),
  takeLatest(BarterTypes.LOAD_PACKAGES_SUCCESS, loadPackagesSuccess),

  takeLatest(BarterTypes.DELETE_OFFLINE_BARTER, deleteOfflineBarter),
  takeLatest(BarterTypes.DELETE_BARTER, deleteBarter)
]
