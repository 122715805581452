import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import Modal from '@smartcoop/web-components/Modal'
import CompleteForm from '@smartcoop/web-containers/forms/digitalProperty/management/CompleteManagementForm/CompleteForm/CompleteManagementForm'

const CompleteCropManagementModal = (props) => {
  const {
    id,
    open,
    handleClose,
    onSuccess,
    cropManagementData,
    growingSeasonId,
    closeCropManagement,
    canConcludeGrowingSeason,
    startLoading,
    endLoading
  } = props
  const completeManagementFormRef = useRef(null)

  return (
    <Modal
      id={ id }
      open={ open }
      disableFullScreen
    >
      <CompleteForm
        ref={ completeManagementFormRef }
        cropManagementData={ cropManagementData }
        growingSeasonId={ growingSeasonId }
        closeCropManagement={ closeCropManagement }
        handleClose={ handleClose }
        onSuccess={ onSuccess }
        withoutSubmitButton
        startLoading={ startLoading }
        endLoading={ endLoading }
        canConcludeGrowingSeason={ canConcludeGrowingSeason }
      />
    </Modal>
  )
}

CompleteCropManagementModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  canConcludeGrowingSeason: PropTypes.bool,
  onSuccess: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  cropManagementData: PropTypes.object.isRequired,
  growingSeasonId: PropTypes.string.isRequired,
  closeCropManagement: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  endLoading: PropTypes.func.isRequired
}

CompleteCropManagementModal.defaultProps = {
  canConcludeGrowingSeason: false,
  onSuccess: () => {}
}

export default CompleteCropManagementModal
