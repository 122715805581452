/* eslint-disable no-unused-expressions */
import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import userConfigurePermission from '@smartcoop/forms/schemas/property/userConfigurePermission.schema'
import I18n ,{ useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { TechnicalActions } from '@smartcoop/stores/technical'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import Modal from '@smartcoop/web-components/Modal'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { ContainerDataUser, LabelText, TextUser, Row } from './styles'

const ConfigurePermissionModal = (props) => {
  const { id, open, userRequestItem, tableRef, handleClose } = props
  const t = useT()
  const configurePermissionFormRef = useRef()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()

  const [selectedPermission, setSelectedPermission] = useState(userRequestItem.technicianProprietary.status || '')

  useEffect(() => {
    if(selectedPermission === 6) {
      configurePermissionFormRef?.current?.getFieldRef?.('financialDataAccess')?.setValue?.(false)
    }
  },[selectedPermission])

  const applicationModeOptions = useMemo(
    () => (
      [
        {
          label: 'Somente visualização',
          value: 4
        },
        {
          label: 'Visualização e edição',
          value: 5
        },
        {
          label: 'Somente assistência',
          value: 6
        },
        {
          label: 'Negar acesso',
          value: 2
        }
      ]
    ),
    []
  )

  const financialDataAccessOptions = useMemo(() => (
    [
      {
        label: t('yes'),
        value: true
      },
      {
        label: t('no'),
        value: false
      }
    ]
  ), [t])

  const permissions = useMemo(
    () => ({
      read: 4,
      readAndWrite: 5,
      assistance: 6,
      denied: 2
    }),
    []
  )

  const handleSubmitConfigurePermission = useCallback(
    (data) => {
      if (data.permission === permissions.denied) {
        dispatch(TechnicalActions.revokeTechnicalAccess(
          userRequestItem.technicianProprietary?.id,
          data.permission,
          () => {
            snackbar.success(
              t('permission change performed successfully')
            )
            const { query } = tableRef.current.state
            tableRef.current.onQueryChange({
              ...query,
              page: 0
            })
          }
        ))
        handleClose()
      } else {
        dispatch(TechnicalActions.acceptTechnicianAccess(
          {
            read: data.permission === permissions.readAndWrite || data.permission === permissions.read,
            write: data.permission === permissions.readAndWrite,
            assistance: data.permission === permissions.assistance,
            financialDataAccess: data.financialDataAccess
          },
          userRequestItem.technicianProprietary?.id,
          () => {
            snackbar.success(
              t('permission change performed successfully')
            )
            const { query } = tableRef.current.state
            tableRef.current.onQueryChange({
              ...query,
              page: 0
            })
          }
        ))
        handleClose()
      }
    },
    [dispatch, handleClose, permissions.assistance, permissions.denied, permissions.read, permissions.readAndWrite, snackbar, t, tableRef, userRequestItem.technicianProprietary.id]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <I18n>configure permission</I18n>
      }
      disableFullScreen
    >
      <>
        <ContainerDataUser>
          <div>
            <LabelText>
              <I18n params={ { howMany: 1 } }>cooperative</I18n>
            </LabelText>
            <TextUser>{userRequestItem.technicianProprietary?.organizationsUser[0]?.organization?.tradeName}</TextUser>
          </div>
          <div>
            <LabelText>
              <I18n>user name</I18n>
            </LabelText>
            <TextUser>{userRequestItem.name}</TextUser>
          </div>
        </ContainerDataUser>
        <Form
          ref={ configurePermissionFormRef }
          schemaConstructor={ userConfigurePermission }
          onSubmit={ handleSubmitConfigurePermission }
          schemaProps={ { selectedPermission } }
        >
          <Row>
            <RadioGroup
              style={ { width: '48%' } }
              label={ t('permission') }
              name="permission"
              options={ applicationModeOptions }
              onChange={ ({ target: { value } }) => setSelectedPermission(value) }
              defaultValue={ userRequestItem.technicianProprietary.status || '' }
              variant="column"
            />
            {selectedPermission !== 2 && (
              <RadioGroup
                style={ {  width: '48%' } }
                label={ t('show financial data') }
                name="financialDataAccess"
                options={ financialDataAccessOptions }
                defaultValue={ userRequestItem.technicianProprietary.financialDataAccess ?? false }
                variant="column"
                disabled={ selectedPermission === 6 }
              />
            )}
          </Row>

          <ButtonsContainer>
            <Button
              id="cancel-configure-permission-form"
              onClick={ handleClose }
              style={ { flex: 1, marginRight: 10 } }
              variant="outlined"
            >
              <I18n>cancel</I18n>
            </Button>

            <Button
              id="submit-configure-permission-form"
              onClick={ () => configurePermissionFormRef.current.submit() }
              style={ { flex: 1 } }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        </Form>
      </>
    </Modal>
  )
}

ConfigurePermissionModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userRequestItem: PropTypes.object,
  tableRef: PropTypes.any
}

ConfigurePermissionModal.defaultProps = {
  userRequestItem: {},
  tableRef: () => {}
}

export default ConfigurePermissionModal
