import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { makeActionFree } from '../stores'

/* Initial State */
const INITIAL_STATE = {
  permissions: {},
  allModules: [],
  allSlugs: [],
  permissionsResponse: [],
  permissionsLoaded: false,
  authenticated: false,
  firebaseCustomToken: null,
  firebaseIdToken: null,
  accessToken: null,
  refreshToken: null,
  terms: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  authenticationError: ['error'],

  validateSignUp: ['document', 'onSuccess', 'onError', 'mobile', 'ignoreErrors'],

  requestVerificationCode: ['protocol', 'onSuccess', 'onError'],
  validateVerificationCode: ['code', 'onSuccess', 'onError'],

  changePassword: ['password', 'onSuccess', 'onError'],

  initAuthSagas: [],
  refreshTokenSuccess: [
    'accessToken',
    'refreshToken',
    'iaToken',
    'onSuccess'
  ],

  setFirebaseCustomToken: ['firebaseCustomToken'],
  firebaseLogin: [],
  firebaseLoginSuccess: ['firebaseIdToken'],
  firebaseLogout: [],
  firebaseLogoutSuccess: [],
  syncFirebaseUserSaga: [],

  loadPermissions: ['onSuccess'],
  loadAllModules: ['allModules'],
  loadAllSlugs: ['allSlugs'],
  loadPermissionsSuccess: ['permissions', 'onSuccess'],
  loadPermissionsResponse: ['permissionsResponse'],

  clearSlugs: [],
  setSlug: ['slug'],

  loadModulesSuccess: ['modules'],

  getTokenByOrganization: ['organization', 'onSuccess', 'onError'],

  recoverPassword: ['document', 'onSuccess', 'onError'],

  login: ['document', 'password', 'onSuccess', 'onError'],
  loginSuccess: [],

  updateTerm: ['params', 'onSuccess', 'onError'],
  loadTerms: ['onSuccess', 'onError', 'accepted'],
  loadTermsSuccess: ['terms', 'onSuccess', 'onError'],

  logout: [],
  logoutSuccess: [],

  resetAuthentication: []
})

markActionsOffline(Creators, [
  'initAuthSagas',
  'syncFirebaseUserSaga'
])

makeActionFree('authenticationError')
makeActionFree('validateSignUp')
makeActionFree('requestVerificationCode')
makeActionFree('validateVerificationCode')
makeActionFree('changePassword')
makeActionFree('initAuthSagas')
makeActionFree('refreshTokenSuccess')
makeActionFree('setFirebaseCustomToken')
makeActionFree('firebaseLogin')
makeActionFree('firebaseLoginSuccess')
makeActionFree('firebaseLogout')
makeActionFree('firebaseLogoutSuccess')
makeActionFree('syncFirebaseUserSaga')
makeActionFree('clearSlugs')
makeActionFree('recoverPassword')
makeActionFree('login')
makeActionFree('loadTerms')
makeActionFree('loadTermsSuccess')
makeActionFree('loginSuccess')
makeActionFree('logout')
makeActionFree('logoutSuccess')
makeActionFree('resetAuthentication')
makeActionFree('loadPermissions')
makeActionFree('loadPermissionsSuccess')
makeActionFree('loadAllModules')
makeActionFree('loadAllSlugs')
makeActionFree('loadPermissionsResponse')

const authenticationError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const validateSignUp = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error
})

const refreshTokenSuccess = (
  state = INITIAL_STATE,
  { accessToken, refreshToken, iaToken }
) => ({
  ...state,
  error: INITIAL_STATE.error,
  accessToken,
  refreshToken,
  iaToken
})

const login = () => ({ ...INITIAL_STATE })
const loginSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error,
  authenticated: true
})

const setFirebaseCustomToken = (
  state = INITIAL_STATE,
  { firebaseCustomToken }
) => ({
  ...state,
  firebaseCustomToken
})

const loadPermissionsSuccess = (state = INITIAL_STATE, { permissions }) => ({
  ...state,
  permissions,
  permissionsLoaded: true
})

const loadModulesSuccess = (state = INITIAL_STATE, { modules }) => ({
  ...state,
  permissions: {
    modules
  },
  permissionsLoaded: true
})

const loadAllModules = (state = INITIAL_STATE, { allModules }) => ({
  ...state,
  allModules
})

const loadAllSlugs = (state = INITIAL_STATE, { allSlugs }) => ({
  ...state,
  allSlugs
})

const loadPermissionsResponse = (state = INITIAL_STATE, { permissionsResponse }) => ({
  ...state,
  permissionsResponse
})

const firebaseLoginSuccess = (state = INITIAL_STATE, { firebaseIdToken }) => ({
  ...state,
  firebaseIdToken
})

const loadTermsSuccess = (state = INITIAL_STATE, { terms }) => ({
  ...state,
  terms: [...terms]
})

const logout = (state = INITIAL_STATE) => ({
  ...state,
  authenticated: INITIAL_STATE.authenticated
})

const logoutSuccess = (state = INITIAL_STATE) => ({
  ...INITIAL_STATE,
  firebaseCustomToken: state.firebaseCustomToken,
  firebaseIdToken: state.firebaseIdToken
})

const clearSlugs = (state = INITIAL_STATE) => ({
  ...state,
  permissions: {
    ...state.permissions,
    profile: {
      ...state.permissions.profile,
      slug: []
    }
  }
})

const setSlug = (state = INITIAL_STATE, { slug }) => ({
  ...state,
  permissions: {
    ...state.permissions,
    profile: {
      ...state.permissions.profile,
      slug
    }
  }
})


const firebaseLogoutSuccess = () => ({ ...INITIAL_STATE })

const resetAuthentication = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.AUTHENTICATION_ERROR]: authenticationError,

  [Types.VALIDATE_SIGN_UP]: validateSignUp,

  [Types.REFRESH_TOKEN_SUCCESS]: refreshTokenSuccess,

  [Types.LOGIN]: login,
  [Types.LOGIN_SUCCESS]: loginSuccess,

  [Types.LOAD_TERMS_SUCCESS]: loadTermsSuccess,

  [Types.SET_FIREBASE_CUSTOM_TOKEN]: setFirebaseCustomToken,
  [Types.FIREBASE_LOGIN_SUCCESS]: firebaseLoginSuccess,
  [Types.FIREBASE_LOGOUT_SUCCESS]: firebaseLogoutSuccess,

  [Types.LOAD_PERMISSIONS_SUCCESS]: loadPermissionsSuccess,
  [Types.LOAD_PERMISSIONS_RESPONSE]: loadPermissionsResponse,
  [Types.LOAD_MODULES_SUCCESS]: loadModulesSuccess,
  [Types.LOAD_ALL_MODULES]: loadAllModules,
  [Types.LOAD_ALL_SLUGS]: loadAllSlugs,

  [Types.LOGOUT]: logout,
  [Types.CLEAR_SLUGS]: clearSlugs,
  [Types.SET_SLUG]: setSlug,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,

  [Types.RESET_AUTHENTICATION]: resetAuthentication
})

export { Types as AuthenticationTypes, Creators as AuthenticationActions }
