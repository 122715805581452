import api from '../api'

export const getWebinars = (params) =>
  api.get('/v1/webinars', { params })

export const accessWebinar = (params) =>
  api.post('/v1/webinars/access', params)

export const createInformative = (params) =>
  api.post('/v1/webinars', params)

export const deleteInformative = ({ informativeId }) =>
  api.delete(`/v1/webinars/${ informativeId }`)

export const editInformative = (params, { informativeId }) =>
  api.patch(`/v1/webinars/${ informativeId }`, params)

