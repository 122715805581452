import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const fiscalRegister = ({ t, props }) => Yup.object().shape({
  description: flow(
  )(Yup.string()),
  active: props?.defaultValues?.cfop?.code ? flow(
    required({ t })
  )(Yup.string()) : Yup.string(),
  cfop: Yup.object().shape({
    code: flow(
      required({ t })
    )(Yup.string()),
    operationType: Yup.string().nullable()
  }),
  products: flow(
    required({ t })
  )(Yup.string()),
  cofinsEntrada: props?.cfop === 'E'
    ? flow(
      required({ t })
    )(Yup.string())
    : Yup.string().nullable(),
  cofinsSaida: props?.cfop === 'S'
    ? flow(
      required({ t })
    )(Yup.string())
    : Yup.string().nullable(),
  pisEntrada: props?.cfop === 'E'
    ? flow(
      required({ t })
    )(Yup.string())
    : Yup.string().nullable(),
  pisSaida: props?.cfop === 'S'
    ? flow(
      required({ t })
    )(Yup.string())
    : Yup.string().nullable(),
  ipiEntrada: props?.cfop === 'E'
    ? flow(
      required({ t })
    )(Yup.string())
    : Yup.string().nullable(),
  ipiSaida: props?.cfop === 'S'
    ? flow(
      required({ t })
    )(Yup.string())
    : Yup.string().nullable(),
  fiscalFigureIcms: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().nullable(),
        nfeFiscalFigureId: Yup.string().nullable(),
        origemUf: flow(
          required({ t })
        )(Yup.string()),

        destinoUf: flow(
          required({ t })
        )(Yup.string()),

        icmsSaidaPf: props?.cfop === 'S'
          ? flow(
            required({ t })
          )(Yup.string())
          : Yup.string().nullable(),

        icmsSaidaPj: props?.cfop === 'S'
          ? flow(
            required({ t })
          )(Yup.string())
          : Yup.string().nullable(),

        icmsEntradaPf: props?.cfop === 'E'
          ? flow(
            required({ t })
          )(Yup.string())
          : Yup.string().nullable(),

        icmsEntradaPj: props?.cfop === 'E'
          ? flow(
            required({ t })
          )(Yup.string())
          : Yup.string().nullable(),

        impostoFederal: flow(
          required({ t })
        )(Yup.string()),

        impostoEstadual: flow(
          required({ t })
        )(Yup.string()),

        impostoMunicipal: flow(
          required({ t })
        )(Yup.string()),

        decreto: flow(
          required({ t })
        )(Yup.string()),

        cbenef: flow(
        )(Yup.string())
      })
    )
})

export default fiscalRegister
