import React, { useCallback, useState, useRef, useMemo, useEffect, memo } from 'react'
import useGeolocation from 'react-hook-geolocation'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import trimMask from '@meta-awesome/functions/src/trimMask'
import html2pdf from 'html2pdf.js'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'


import I18n, { useT } from '@smartcoop/i18n'
import { sign } from '@smartcoop/services/apis/signstampApi/api'
import { getGuestOrganizations } from '@smartcoop/services/apis/smartcoopApi/resources/organization'
import { preRegistration } from '@smartcoop/services/apis/smartcoopApi/resources/user'
import { useSnackbar } from '@smartcoop/snackbar'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { acceptedAccessDocument } from '@smartcoop/utils/documents'
import { formatCpfCnpj } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import Loader from '@smartcoop/web-components/Loader'
import ConfirmProducerForm from '@smartcoop/web-containers/forms/digitalProperty/producer/ConfirmProducerForm'
import RegisterProducerDataForm from '@smartcoop/web-containers/forms/digitalProperty/producer/RegisterProducerDataForm'
import SignatureForm from '@smartcoop/web-containers/forms/digitalProperty/producer/SignatureForm'
import TakePictureForm from '@smartcoop/web-containers/forms/digitalProperty/producer/TakePictureForm'
import { withLayout } from '@smartcoop/web-containers/layouts'
import { Item, ButtonsContainer } from '@smartcoop/web-containers/layouts/GuestLayout/theme'

import { UnderText } from './styles'

const IdentificationScreen = () => {
  const formRef = useRef(null)
  const history = useHistory()
  const geolocation = useGeolocation()
  const snackbar = useSnackbar()
  const dispatch = useDispatch()
  const t = useT()

  const [currentForm, setCurrentForm] = useState('confirmProducer')
  const [disabledNext, setDisabledNext] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingMsg, setLoadingMsg] = useState('')
  const [formData, setFormData] = useState({})
  const [orgs, setOrgs] = useState({})

  const fetchOrgs = useCallback(async () => {
    const { data: { data } } = await getGuestOrganizations()
    setOrgs(data)
  },[])

  useEffect(() => {
    fetchOrgs()
  },[fetchOrgs])

  const termData = useMemo(() => ({
    userName: formData?.name,
    userDocument: formData?.document,
    organizations: formData?.organization
  }) ,[formData])

  const onFinish = useCallback(async (currentData) => {
    setLoadingMsg(t('processing term sign'))
    setLoading(true)
    let userOrganizationDocs = []

    const createUserData = new FormData()

    const promises = map(currentData?.organization, async item => {
      const currentOrg = find(orgs, org => org.id === item)

      const request = new FormData()

      const opt = {
        margin:       [30, 30, 30, 30], // top, left, buttom, right
        filename:    `termo_smartcoop_${ currentOrg?.tradeName }.pdf`,
        image:        { type: 'jpeg', quality: 0.20 },
        html2canvas:  { dpi: 192, scale: 2, letterRendering: true },
        jsPDF:        { unit: 'pt', format: 'a4', orientation: 'portrait' },
        pageBreak: { mode: 'legacy' },
        compressPDF: true
      }

      const  blob = html2pdf()
      await blob.set(opt).from(
        acceptedAccessDocument
          .replace('{companyName}', currentOrg?.companyName)
          .replace('{companyDocument}', formatCpfCnpj(currentOrg?.companyDocument))
          .replace('{userName}', termData?.userName)
          .replace('{userDocument}', formatCpfCnpj(termData?.userDocument)))
        .toPdf().output('blob').then( async (data: Blob) => {
          request.append('documentFile', data)
          request.append('quickSignRequest', JSON.stringify({
            documentInformation: {
              title: 'Termo',
              summary: 'Sumário Termo'
            },
            signatureSetup: {
              signatory: {
                uid: currentData?.document,
                name: currentData?.name,
                email: currentData?.email,
                cellphone:currentData?.cellPhone
              },
              signatureStampConfig: {
                singlePageSettings: [
                  {
                    pageNumber: 2,
                    placeHolderType: 'coordinates',
                    x: 290,
                    y: 700,
                    width: 200,
                    height: 81,
                    showEvidences: true
                  }
                ]
              }
            },
            signatureEvidences: {
              thumbnailImageData: currentData?.signature.replace(/^data:image\/[a-z]+;base64,/, ''),
              latitude: geolocation?.latitude,
              longitude: geolocation?.longitude,
              timestamp: Math.floor(Date.now() / 1000),
              customEvidences: {
                myCustomEvidence01: currentData?.picture
              }
            }
          }))

          const response = await sign(request)

          const file = new File([response], `termo_smartcoop_${ currentOrg?.tradeName }.pdf`, { type: 'application/pdf' })

          userOrganizationDocs = [...userOrganizationDocs, {
            organizationId: currentOrg.id,
            documentName: `termo_smartcoop_${ currentOrg?.tradeName }.pdf`
          }]

          createUserData.append('uploads', file)
        })
    })

    await Promise.all(promises)

    await createUserData.append('user', JSON.stringify({
      name: isEmpty(currentData?.name) ? null : currentData?.name,
      email: currentData?.email,
      cellPhone: currentData?.cellPhone,
      document: isEmpty(currentData?.document) ? null : trimMask(currentData?.document),
      dateOfBirth: isEmpty(currentData?.dateOfBirth) ? null : currentData?.dateOfBirth,
      street: currentData?.street,
      number: currentData?.number,
      district: currentData?.district,
      city: currentData?.city,
      state: currentData?.state,
      postalCode: currentData?.postalCode,
      userOrganizationDocs
    }))

    try{
      setLoadingMsg(t('creating register'))
      await Promise.all([preRegistration(createUserData)]).then(() => {
        dispatch(AuthenticationActions.validateSignUp(trimMask(currentData?.document), () => {
          snackbar.success(t('your registration was effected!'))
          history.push('/sign-up/identification')
        }))
      })
    } catch {
      snackbar.error('Não foi possível realizar seu cadastro, tente novamente.')
      setFormData({})
      setCurrentForm('confirmProducer')
      setLoading(false)
      setDisabledNext(false)
    }

  }, [dispatch, geolocation.latitude, geolocation.longitude, history, orgs, snackbar, t, termData.userDocument, termData.userName])

  const handleSubmit = useCallback(
    async (data) => {
      const currentFormData = { ...formData }
      setFormData({ ...currentFormData, ...data })

      if(currentForm === 'confirmProducer') {
        setCurrentForm('registerProducerData')
      } else if(currentForm === 'registerProducerData') {
        setCurrentForm('takePicture')
      } else if(currentForm === 'takePicture') {
        setCurrentForm('signature')
      }

      if(currentForm === 'signature') {
        onFinish({ ...currentFormData, ...data })
      }
    },
    [currentForm, formData, onFinish]
  )



  const moveBack = useCallback(
    () => {
      if(currentForm === 'registerProducerData') {
        setDisabledNext(false)
        setCurrentForm('confirmProducer')
      } else if(currentForm === 'takePicture') {
        setDisabledNext(false)
        setCurrentForm('registerProducerData')
      } else if(currentForm === 'signature') {
        setDisabledNext(false)
        setCurrentForm('takePicture')
      }
    },
    [currentForm]
  )

  return loading ? <Loader message={ loadingMsg } width={ 100 } /> : (
    <>
      <Item>
        {currentForm === 'confirmProducer' && (
          <ConfirmProducerForm
            orgs={ orgs }
            setCurrentForm={ setCurrentForm }
            ref={ formRef }
            onSubmit={ handleSubmit }
            setDisabledNext={ setDisabledNext }
          />
        )}
        {currentForm === 'registerProducerData' && (
          <RegisterProducerDataForm
            setCurrentForm={ setCurrentForm }
            ref={ formRef }
            onSubmit={ handleSubmit }
            defaultValues={ formData }
          />
        )}
        {currentForm === 'takePicture' && (
          <TakePictureForm
            setCurrentForm={ setCurrentForm }
            ref={ formRef } onSubmit={ handleSubmit }
            setDisabledNext={ setDisabledNext }
            termData={ termData }
            onCancelModal={ () => history.go() }
            defaultValues={ formData }
            orgs={ orgs }
          />
        )}
        {currentForm === 'signature' && (
          <SignatureForm
            ref={ formRef }
            onSubmit={ handleSubmit }
            setDisabledNext={ setDisabledNext }
            termData={ termData }
          />
        )}
      </Item>

      <Item>
        <ButtonsContainer>
          {currentForm !== 'confirmProducer' && (
            <Button
              id="go-back"
              onClick={ moveBack }
              style={ { width: '48%', flex: '0 0 auto' } }
              variant="outlined"
            >
              <I18n>go back</I18n>
            </Button>
          )}

          <Button
            id="identification-submit"
            onClick={ () => formRef.current.submit() }
            style={ { width: currentForm === 'confirmProducer' ? '100%' : '48%' ,flex: currentForm === 'confirmProducer' ? '1' : '0 0 auto' } }
            disabled={ disabledNext || loading }
            color="secondary"
          >
            <I18n>{currentForm === 'signature' ? 'complete' : 'next' }</I18n>
          </Button>
        </ButtonsContainer>
      </Item>
      { currentForm === 'confirmProducer' && (
        <UnderText>
          De acordo com o termo de uso da plataforma, as cooperativas se reservam o direito de inativar usuários não identificados no seu quadro social.
        </UnderText>
      )}
    </>
  )
}

export default memo(withLayout('guest')(IdentificationScreen))
