import styled from 'styled-components'

export const Preview = styled.div`
  width: ${ (props) => `${ props.size }px` };
  height: ${ (props) => `${ props.size }px` };
  border-radius: 5px;
  background-image: url(${ (props) => props.src });
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
  margin-bottom: 10px;
  border: ${ props =>  props?.isImage ? '' : '1px solid #babaca' };
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`

