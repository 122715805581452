import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import PropTypes from 'prop-types'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { shoppingCart, organizationRounded, fileText } from '@smartcoop/icons'
import { selectUserHaveAdministration } from '@smartcoop/stores/authentication/selectorAuthentication'
import {
  selectCurrentOrder,
  selectCurrentOrderProduct
} from '@smartcoop/stores/order/selectorOrder'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { colors } from '@smartcoop/styles'
import { formatCpfCnpj, formatCurrency, formatNumber } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import CardAccordion from '@smartcoop/web-components/CardAccordion'
import Icon from '@smartcoop/web-components/Icon'
import ProductDetailsModal from '@smartcoop/web-containers/modals/ProductDetailsModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  Title,
  IconContainer,
  Divider,
  Header,
  OrderContainer,
  OrderSubTitleItem,
  OrderItem,
  OrderItemText,
  OrganizationsContainer,
  AccordionTitle,
  AccordionItems,
  AccordionItem,
  OrganizationItem
} from './styles'

const OrderDetailsFragment = ({ isEdit, organizationsVariant, children, selectedProposal }) => {
  const history = useHistory()
  const t = useT()
  const { routes } = useRoutes()
  const { createDialog } = useDialog()

  const currentOrder = useSelector(selectCurrentOrder)
  const product = useSelector(selectCurrentOrderProduct)
  const currentOrganization = useSelector(selectCurrentOrganization)
  const userIsAdmin = useSelector(selectUserHaveAdministration)

  const proposalAccepted = find(currentOrder?.purchaseProposals, proposal => proposal.status.slug === 'aguardando_cooperativas')

  const [verifiedCurrentOrder, setVerifiedCurrentOrder] = useState(false)
  const { orderId } = useParams()

  const productUnit = useMemo(() => product.unitOfMeasures || 'kg', [product])

  const currentOrganizationPurchase = useMemo(
    () =>
      find(
        currentOrder.organizationPurchases,
        ({ organizationId }) => organizationId === currentOrganization.id
      ),
    [currentOrder.organizationPurchases, currentOrganization.id]
  )

  const orderData = useMemo(
    () => [
      {
        subtitle: 'product',
        value: `${ product?.productCode } - ${ product?.description }` || ''
      },
      {
        subtitle: 'aggregated volume',
        value:
          `${ formatNumber(currentOrder.aggregatedVolume) } ${ productUnit }` || ''
      }
      // {
      //   subtitle: 'opening date',
      //   value:
      //     currentOrder.createdAt &&
      //     moment(currentOrder.createdAt, momentBackDateTimeZoneFormat).format(
      //       momentFriendlyDateFormat
      //     )
      // },
      // {
      //   subtitle: 'expiration date',
      //   value:
      //     (currentOrder?.participationDeadline || currentOrder.decisionDeadline) &&
      //     moment(
      //       includes(['aberta', 'expirado', 'aguardando_resposta', 'cancelada'], currentOrder.status.slug) ? currentOrder.participationDeadline : currentOrder.decisionDeadline,
      //       momentBackDateTimeZoneFormat
      //     ).format(momentFriendlyDateFormat)
      // },
      // {
      //   subtitle: 'receivement deadline',
      //   value:
      //     currentOrder?.receiptDeadline &&
      //     moment(currentOrder.receiptDeadline, momentBackDateTimeZoneFormat).format(
      //       momentFriendlyDateFormat
      //     )
      // }
    ],
    [ currentOrder, product, productUnit ]
  )

  const organizations = useMemo(
    () =>
      map(currentOrder.organizationPurchases, (item) => ({
        value: item.organization.tradeName || item.organization.companyName,
        cnpj: formatCpfCnpj(item.organization.companyDocument),
        totalAmount: item.totalAmount,
        deliveryAmount: item.deliveryLocations.length,
        totalFreightValue: !isEmpty(selectedProposal?.deliveryLocations) ? sumBy(selectedProposal?.deliveryLocations, 'freightValue') : null
      })),
    [currentOrder, selectedProposal]
  )

  const paymentMethods = useMemo(
    () =>
      map(
        currentOrder.paymentForms,
        ({ deferredPayment, daysAmount }, index) => ({
          value: `${ index + 1 }. ${
            !deferredPayment
              ? t('in cash')
              : `${ t('deferred') } ${ daysAmount } ${ t('day', { howMany: 2 }) }`
          }`
        })
      ),
    [currentOrder.paymentForms, t]
  )

  useEffect(() => {
    if (orderId) {
      setVerifiedCurrentOrder(false)
    }
  }, [orderId])

  useEffect(() => {
    if (!isEmpty(currentOrder) && !verifiedCurrentOrder) {
      const isParticipating = currentOrganizationPurchase
      const path = routes.joinOrder.path.replace(':orderId', orderId)
      if (!isParticipating && !userIsAdmin) {
        history.replace(path)
      } else {
        setVerifiedCurrentOrder(true)
      }
    }
  }, [
    currentOrder,
    verifiedCurrentOrder,
    currentOrganization.id,
    history,
    orderId,
    routes.joinOrder,
    currentOrganizationPurchase,
    organizations,
    userIsAdmin
  ])

  const ShowProductDetails = useCallback(() => {
    createDialog({
      id: 'product-details',
      Component: ProductDetailsModal,
      props: {
        product,
        t
      }
    })
  }, [createDialog, product, t])

  return (
    <>
      <Header style={ { paddingLeft: 30 } }>
        <IconContainer>
          <Icon icon={ shoppingCart } size={ 18 } />
        </IconContainer>

        <Title style={ { fontSize: 16, fontWeight: 600 } }>
          <I18n>total purchase order</I18n>
        </Title>
      </Header>
      <Header>
        <OrderItem key="order code">
          <OrderSubTitleItem>{t('order code')}</OrderSubTitleItem>
          <OrderItemText>{ currentOrder?.code }</OrderItemText>
        </OrderItem>
        { proposalAccepted && (
          <OrderItem key="proposal code">
            <OrderSubTitleItem>{t('proposal code')}</OrderSubTitleItem>
            <OrderItemText>{ proposalAccepted?.code }</OrderItemText>
          </OrderItem>
        )}
      </Header>
      <OrderContainer>
        {map(orderData, ({ subtitle, value }) => (
          <OrderItem key={ subtitle }>
            <OrderSubTitleItem>{t(subtitle)}</OrderSubTitleItem>
            <OrderItemText>{value}</OrderItemText>
          </OrderItem>
        ))}
      </OrderContainer>

      <OrderContainer style={ { gridTemplateColumns: '1fr' } }>
        <Button
          id="show-product-details"
          onClick={ ShowProductDetails }
          style={ { marginLeft: '20px', marginRight: '20px', marginTop: '15px', fontWeight: 600 } }
          color={ colors.white }
        >
          <Icon size={ 16 } icon={ fileText } style={ { color: colors.black, marginRight: '5px' } } />
          <I18n>product details</I18n>
        </Button>
      </OrderContainer>
      <Divider style={ { marginTop: '20px', marginBottom: '20px' } }/>
      <OrderContainer>
        <OrderItem>
          <OrderSubTitleItem>
            <I18n>payment methods</I18n>
          </OrderSubTitleItem>
          {map(paymentMethods, ({ value }) => (
            <OrderItemText key={ value }>{value}</OrderItemText>
          ))}
        </OrderItem>
        {!isEdit && (
          <OrderItem>
            <OrderSubTitleItem>
              <I18n>freight type</I18n>
            </OrderSubTitleItem>
            <OrderItemText>{currentOrder?.freightType}</OrderItemText>
          </OrderItem>
        )}
      </OrderContainer>
      <Divider />
      <OrganizationsContainer>
        <Header>
          <Title style={ { fontSize: 16, fontWeight: 600, paddingLeft: 15 } }>
            <I18n>participating organizations</I18n>
          </Title>
        </Header>
        {organizationsVariant === 'default'
          ? map(organizations, ({ value }) => (
            <OrganizationItem key={ value } style={ { paddingLeft: 30 } }>
              <Icon icon={ organizationRounded } size={ 24 } />
              <OrderItemText style={ { fontSize: 14, paddingLeft: 8 } }>
                {value}
              </OrderItemText>
            </OrganizationItem>
          ))
          : map(
            organizations,
            ({ value, cnpj, deliveryAmount, totalAmount, totalFreightValue }) => (
              <OrganizationItem
                key={ value }
                style={ { paddingLeft: 30, paddingRight: 30 } }
              >
                <CardAccordion
                  title={ <AccordionTitle>{value}</AccordionTitle> }
                  headerLeft={ <Icon icon={ organizationRounded } size={ 20 } /> }
                >
                  <AccordionItems>
                    <AccordionItem>CNPJ: {cnpj}</AccordionItem>
                    <AccordionItem>{`${ t('total volume') }: ${ formatNumber(
                      totalAmount
                    ) } ${ productUnit }`}</AccordionItem>
                    <AccordionItem>{`${ t(
                      'delivery in'
                    ) }: ${ deliveryAmount } ${ t('place', {
                      howMany: deliveryAmount
                    }) }`}</AccordionItem>
                    {
                      totalFreightValue && (
                        <AccordionItem>{t('total cfa freight cost')}: {formatCurrency(totalFreightValue)}</AccordionItem>
                      )
                    }
                  </AccordionItems>
                </CardAccordion>
              </OrganizationItem>
            )
          )}
      </OrganizationsContainer>
      {children && (
        <>
          <Divider />
          <div style={ { paddingLeft: 15, paddingRight: 15, display: 'flex', flexDirection: 'column'  } }>
            {children}
          </div>
        </>
      )}
    </>
  )
}

OrderDetailsFragment.propTypes = {
  organizationsVariant: PropTypes.oneOf(['default', 'accordion']),
  children: PropTypes.any,
  selectedProposal: PropTypes.object,
  isEdit: PropTypes.bool
}

OrderDetailsFragment.defaultProps = {
  organizationsVariant: 'default',
  isEdit: false,
  selectedProposal: {},
  children: null
}

export default OrderDetailsFragment
