import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import get from 'lodash/get'

import { useTheme, ThemeProvider } from '@material-ui/core/styles'

import { colors } from '@smartcoop/styles'

import { StyledButton } from './styles'

const Button = props => {
  const {
    children,
    color,
    ...otherProps
  } = props

  const mainTheme = useTheme()

  const buttonColor = useMemo(
    () => get(colors, color) || color || mainTheme.palette.primary.main,
    [color, mainTheme]
  )

  return (
    <ThemeProvider
      theme={ theme => ({
        ...theme,
        palette: {
          ...theme.palette,
          primary: {
            main: buttonColor,
            contrastText: mainTheme.palette.getContrastText(buttonColor)
          },
          secondary: {
            main: '#FFC80A'
          }
        }
      }) }
    >
      <StyledButton
        color="primary"
        variant="contained"
        { ...otherProps }
      >
        {children}
      </StyledButton>
    </ThemeProvider>
  )
}

Button.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  /** name on @smartcoop/styles/colors or hex of the color  */
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
}

Button.defaultProps = {
  onClick: () => {},
  color: 'primary',
  children: null
}

export default Button
