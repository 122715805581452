import React, { forwardRef, useMemo } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import barterDetailsSchema from '@smartcoop/forms/schemas/commercialization/barter/barterDetails.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getCrops as getCropsService } from '@smartcoop/services/apis/smartcoopApi/resources/crop'
import { getUserStateRegistrations as getUserStateRegistrationsService } from '@smartcoop/services/apis/smartcoopApi/resources/stateRegistration'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import Button from '@smartcoop/web-components/Button'
import Card from '@smartcoop/web-components/Card'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container, Row } from './styles'

const BarterDetailsForm = forwardRef((props, formRef) => {
  const { onSubmit, withoutSubmitButton, barterName, cropInternalCode, stateRegistrationId } = props
  const t = useT()

  const currentOrganization = useSelector(selectCurrentOrganization)

  const urlParams = useMemo(
    () => ({ organizationId: currentOrganization.id }),
    [currentOrganization.id]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ barterDetailsSchema }
        onSubmit={ (data) => onSubmit(data) }
      >
        <Card
          style={ { margin: '0 0 18px 0' } }
          cardStyle={ { width: '100%', padding: '17px 15px 4px 15px' } }
        >
          <Row>
            <InputText
              name="barterName"
              label={ t('name') }
              defaultValue={ barterName || 'Meu pacote' }
              fullWidth
            />
            <div style={ { marginRight: 15 } }/>
            <InputSelect
              name="cropInternalCode"
              label={ t('crop used in trade') }
              defaultValue={ cropInternalCode }
              options={ getCropsService }
              asyncOptionLabelField="description"
              asyncOptionValueField="internalCode"
            />
            <div style={ { marginRight: 15 } }/>
            <InputSelect
              name="stateRegistrationId"
              label={ t('state registration') }
              urlParams={ urlParams }
              defaultValue={ stateRegistrationId }
              options={ getUserStateRegistrationsService }
              asyncOptionLabelField="stateRegistration"
              asyncOptionValueField="id"
            />
          </Row>
          {!withoutSubmitButton && (
            <ButtonsContainer>
              <Button
                id="web-barter-details-form-button"
                style={ { width: '48%' } }
                onClick={ () => formRef.current.submit() }
              >
                <I18n>save</I18n>
              </Button>
            </ButtonsContainer>
          )}
        </Card>
      </Form>
    </Container>
  )
})

BarterDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  withoutSubmitButton: PropTypes.bool,
  barterName: PropTypes.string,
  cropInternalCode: PropTypes.string,
  stateRegistrationId: PropTypes.string
}

BarterDetailsForm.defaultProps = {
  withoutSubmitButton: false,
  barterName: '',
  cropInternalCode: '',
  stateRegistrationId: ''
}

export default BarterDetailsForm
