import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const EmptyState = styled(Typography).attrs({
  variant: 'body1',
  align: 'center'
})`
  padding: 15px;
`

export const ActionName = styled(Typography).attrs({
  variant: 'body2'
})`
  margin: 0;
`
