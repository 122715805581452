export default ({ size, color = '#000' } = {}) => /* html */`
<svg width="${ size }" height="${ size }" inkscape:version="1.0 (4035a4fb49, 2020-05-01)" sodipodi:docname="percentage.svg" version="1.1" viewBox="0 0 60.003 43.102" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd">
 <sodipodi:namedview id="base" bordercolor="${ color }" borderopacity="1.0" inkscape:current-layer="g900" inkscape:cx="95.82442" inkscape:cy="169.93633" inkscape:document-rotation="0" inkscape:document-units="mm" inkscape:pageopacity="0.0" inkscape:pageshadow="2" inkscape:window-height="1027" inkscape:window-maximized="1" inkscape:window-width="1920" inkscape:window-x="-8" inkscape:window-y="-8" inkscape:zoom="0.68170455" pagecolor="#ffffff" showgrid="false"/>
 <metadata>
  <rdf:RDF>
   <cc:Work rdf:about="">
    <dc:format>image/svg+xml</dc:format>
    <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
    <dc:title/>
   </cc:Work>
  </rdf:RDF>
 </metadata>
 <g inkscape:groupmode="layer" inkscape:label="Layer 1">
  <g transform="matrix(.26458 0 0 .26458 31.985 86.734)">
   <text x="-109.97256" y="-279.8074" fill="${ color }" font-family="sans-serif" font-size="63.781px" stroke-width="1.5945" style="line-height:1.25" xml:space="preserve"><tspan x="-109.97256" y="-279.8074" fill="${ color }" font-weight="bold" stroke-width="1.5945" sodipodi:role="line">%</tspan></text>
   <g transform="matrix(5.8133 0 0 5.8133 -121.27 -377.52)" fill="none" stroke="${ color }" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8">
    <path d="m32.431 23.589h0.7677c0.909 2e-4 1.6962-0.6309 1.8936-1.5183l1.0779-4.8491c0.1274-0.5743-0.0127-1.1754-0.3808-1.6343s-0.9245-0.726-1.5128-0.7263h-2.9224c-0.5883 3e-4 -1.1447 0.2674-1.5128 0.7263s-0.5082 1.06-0.3808 1.6343l1.0779 4.8491c0.1973 0.887 0.9839 1.518 1.8925 1.5183z" clip-rule="evenodd" fill-rule="evenodd"/>
    <path d="m29.445 16.461h-11.225c-2.399 0-3.9983 2.399-3.9983 4.798v11.195"/>
    <path d="m35.269 15.134 0.5437-0.2718c0.4316-0.2043 0.761-0.5759 0.9119-1.0288 0.151-0.453 0.1105-0.9479-0.1122-1.3702"/>
    <path d="m30.557 15.032-0.3423-0.1706c-0.4315-0.2043-0.7609-0.5759-0.9119-1.0288-0.1509-0.453-0.1104-0.9479 0.1123-1.3702"/>
    <path d="m31.014 26.857v5.5976"/>
    <path d="m27.816 32.455v-4.798c0-0.8832-0.716-1.5993-1.5993-1.5993h-7.1969c-1.5993 0-1.5993 1.5993-1.5993 2.399v3.9983"/>
    <path d="m23.817 26.058s-0.7996 3.1986-3.1986 3.1986h-3.1986"/>
    <path d="m20.619 29.256v1.5993"/>
    <path d="m22.826 28.072 0.9915 1.982"/>
    <path d="m29.462 17.224-1.6462 1.6377"/>
    <path d="m36.218 16.867 1.9927 1.9927"/>
    <path d="m1.3874 35.789h15.076"/>
    <path d="m25.736 35.789h10.438"/>
    <path d="m1 29.688s3.6445 2.4297 3.6445 6.0742"/>
    <path d="m8.2876 29.688s-3.6445 2.4297-3.6445 6.0742"/>
    <path d="M 37.445,35.764 H 1"/>
   </g>
  </g>
 </g>
</svg>
`
