import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import isEmpty from 'lodash/isEmpty'

import { getTechnicalProductivityChallenges ,
  createProductivityChallenge,
  getProductivityChallengeProducers,
  deleteProductivityChallenge as deleteProductivityChallengeService,
  getProductivityChallengeTerm,
  getProductivityChallengeTermByField,
  patchProductivityChallengeTerm,
  getProjectTypes
} from '@smartcoop/services/apis/smartcoopApi/resources/challenges'

import { FieldActions } from '../field/duckField'
import { ChallengesActions, ChallengesTypes } from './duckChallenges'

function* loadProjectTypes({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: { data } } = yield call(getProjectTypes, params)

    yield put(ChallengesActions.loadProjectTypesSuccess(
      data
    ))
    onSuccess()
  } catch (error) {
    yield put(ChallengesActions.challengesError(error))
    yield call(onError, error)
  }
}

function* loadChallenges({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: { data: challenges, ...pagination } } = yield call(getTechnicalProductivityChallenges, params)

    yield put(ChallengesActions.loadChallengesSuccess(
      challenges,
      pagination.page
    ))
    onSuccess(pagination)
  } catch (error) {
    yield put(ChallengesActions.challengesError(error))
    yield call(onError, error)
  }
}

function* loadChallengeTerm({ fieldId, projectSlug, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(getProductivityChallengeTerm, { projectSlug }, { fieldId })

    if(!isEmpty(data)) {
      yield call(onSuccess, data)
    }
  } catch (error) {
    yield put(ChallengesActions.challengesError(error))
    yield call(onError, error)
  }
}

function* loadChallengeTermByField({ fieldId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(getProductivityChallengeTermByField, {}, { fieldId })

    yield call(onSuccess, data)
  } catch (error) {
    yield put(ChallengesActions.challengesError(error))
    yield call(onError, error)
  }
}

function* loadProductivityChallengeDataSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* loadProductivityChallengeData({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: { data } } = yield call(getProductivityChallengeProducers, {
      ...params
    })

    yield put(ChallengesActions.loadProductivityChallengeDataSuccess(
      data,
      onSuccess
    ))
  } catch (error) {
    yield put(ChallengesActions.challengesError(error))
    yield call(onError, error)
  }
}

function* saveOfflineChallenge({ challenge, onSuccess = () => {}, onError = () => {} }) {
  yield put(ChallengesActions.saveProductivityChallenge(challenge, onSuccess, onError))
}

function* updateOfflineChallenge({ challenge, onSuccess = () => {}, onError = () => {} }) {
  yield put(ChallengesActions.saveProductivityChallenge(challenge, onSuccess, onError))
}

function* saveProductivityChallenge({ challenge, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(
      createProductivityChallenge,
      { ...challenge }
    )

    yield put(ChallengesActions.saveProductivityChallengeSuccess(onSuccess))

    onSuccess()
  } catch (err) {
    const error = err.message
    yield put(ChallengesActions.challengesError(error))
    yield call(onError, error)
  }
}


function* deleteOfflineChallenge({ params, onSuccess = () => {}, onError = () => {} }) {
  yield put(ChallengesActions.deleteProductivityChallenge(params, onSuccess, onError))
}

function* acceptOfflineProductivityTerm({ productivityChallengeId, termId, onSuccess = () => {}, onError = () => {} }) {
  yield put(ChallengesActions.acceptProductivityTerm(productivityChallengeId, termId, onSuccess, onError))
}

function* acceptProductivityTerm({ productivityChallengeId, termId, onSuccess = () => {}, onError = () => {} }) {
  try {

    yield call(patchProductivityChallengeTerm, { productivityChallengeId, termId })

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(ChallengesActions.challengesError(error))
    yield call(onError, error)
  }
}

function* deleteProductivityChallenge({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { productivityChallengeId } = params

    yield call(deleteProductivityChallengeService, {}, { productivityChallengeId })
    if(params?.isField) {
      yield put(FieldActions.loadCurrentField())
    }
    yield call(onSuccess)
  } catch (error) {
    yield put(ChallengesActions.challengesError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(ChallengesTypes.UPDATE_OFFLINE_CHALLENGE, updateOfflineChallenge),
  takeLatest(ChallengesTypes.SAVE_OFFLINE_CHALLENGE, saveOfflineChallenge),
  takeLatest(ChallengesTypes.SAVE_PRODUCTIVITY_CHALLENGE, saveProductivityChallenge),

  takeLatest(ChallengesTypes.LOAD_PROJECT_TYPES, loadProjectTypes),

  takeLatest(ChallengesTypes.LOAD_CHALLENGES, loadChallenges),
  takeEvery(ChallengesTypes.LOAD_CHALLENGE_TERM, loadChallengeTerm),
  takeLatest(ChallengesTypes.LOAD_CHALLENGE_TERM_BY_FIELD, loadChallengeTermByField),

  takeLatest(ChallengesTypes.LOAD_PRODUCTIVITY_CHALLENGE_DATA, loadProductivityChallengeData),
  takeLatest(ChallengesTypes.LOAD_PRODUCTIVITY_CHALLENGE_DATA_SUCCESS, loadProductivityChallengeDataSuccess),

  takeLatest(ChallengesTypes.ACCEPT_OFFLINE_PRODUCTIVITY_TERM, acceptOfflineProductivityTerm),
  takeLatest(ChallengesTypes.ACCEPT_PRODUCTIVITY_TERM, acceptProductivityTerm),
  takeLatest(ChallengesTypes.DELETE_OFFLINE_CHALLENGE, deleteOfflineChallenge),
  takeLatest(ChallengesTypes.DELETE_PRODUCTIVITY_CHALLENGE, deleteProductivityChallenge)
]
