import styled from 'styled-components'

import { colors } from '@smartcoop/styles'
import IconButton from '@smartcoop/web-components/IconButton'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
`

export const ButtonContainer = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

export const Subtitle = styled.span`
  font-weight: bold;
  margin-top: 20px;
`

export const RequiredText = styled.span`
  color: red;
  font-size: 0.6rem;
`

export const CustomIconButton = styled(IconButton)`
  padding: 10px;
  border-radius: 20px;
  background-color: ${ props => props?.secondary ? colors.secondary : '#fff' };
  border: 1px solid #babaca;
`

export const Divider = styled.hr`
  border: none;
  border-bottom: 0.935252px solid #babaca;
  margin: 0 0 20px 0;
  width: 100%;
`

export const TableProducts = styled.table`
 width: 100%;
 border-collapse: collapse;
  tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, .1);
  }

  td {
    border: 1px solid #babaca;
    padding: 10px;
    text-align: center;
    border-collapse: collapse;
  }
`

