import React, { useCallback, useState } from 'react'
import {
  useSelector,
  useDispatch
} from 'react-redux'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { NonCompliancesActions } from '@smartcoop/stores/nonCompliance'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Modal from '@smartcoop/web-components/Modal'
import NonCompliance from '@smartcoop/web-components/NonCompliance'
import PostNonComplianceComment from '@smartcoop/web-components/PostNonComplianceComment'

import useStyles from './styles'

const AnswerNonComplianceModal = (props) => {
  const { id, open, originalPost, handleClose, getNonCompliances } = props
  const t = useT()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const organization = useSelector(selectCurrentOrganization)
  const user = useSelector(selectUser)

  const [loading, setLoading] = useState(false)

  const handleError = () => setLoading(false)

  const saveCommentSuccess = useCallback(() => {
    snackbar.success(t('social comment post success'))
    setLoading(false)
    handleClose()
    getNonCompliances()
  },[getNonCompliances, handleClose, snackbar, t])

  const handleSubmit = useCallback((data) => {
    setLoading(true)
    dispatch(NonCompliancesActions.saveNonComplianceComment({ nonComplianceId: originalPost.id , ...data }, saveCommentSuccess , handleError))
  }, [dispatch, originalPost.id, saveCommentSuccess])

  return (
    <Modal
      id={ id }
      open={ open }
      title={ t('non compliance answer') }
      disableFullScreen
      escape
      maxWidth='md'
      fullWidth
      contentContainerStyle={ { padding: 0, minWidth: 500 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      loading={ loading }
    >
      <div>
        <NonCompliance nonCompliance={ originalPost } />
        <PostNonComplianceComment
          organization={ organization }
          user={ user }
          onSubmit={ handleSubmit }
          answer
        />
      </div>
    </Modal>
  )
}

AnswerNonComplianceModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  getNonCompliances: PropTypes.func.isRequired,
  originalPost: PropTypes.shape({
    id: PropTypes.string,
    observations: PropTypes.string
  }).isRequired
}

AnswerNonComplianceModal.defaultProps = {}

export default AnswerNonComplianceModal
