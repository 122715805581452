import styled from 'styled-components'

import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

import { colors } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'

export const Container = styled(ListItem)`
  border: 1px solid ${ colors.lightGrey };
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 15px;
`

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 7px;
`

export const Date = styled(Typography).attrs({
  variant: 'caption'
}).withConfig({
  shouldForwardProp: (propName) => propName !== 'recent'
})`
  line-height: 1em;
  color: ${ ({ recent }) => recent ? colors.green : colors.mutedText };
  font-weight: ${ ({ recent }) => recent ? 'bold' : 'normal' };
`

export const UnreadSignal = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${ colors.red };
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const ModuleIconContainer = styled.div`
  background-color: ${ colors.darkGrey };
  padding: 6px;
  border-radius: 50%;
  margin-right: 5px;
`

export const Title = styled(Typography).attrs({
  variant: 'body2'
}).withConfig({
  shouldForwardProp: (propName) => propName !== 'read'
})`
  font-weight: ${ fonts.fontWeight.bold };
  line-height: 1em;
  color: ${ ({ read }) => read ? colors.mutedText : colors.text };
`

export const BodyContainer = styled.div`
  padding-left: 32px;
  padding-right: 15px;
  width: 100%;
`

export const Body = styled(Typography).attrs({
  variant: 'body2'
}).withConfig({
  shouldForwardProp: (propName) => propName !== 'read'
})`
  line-height: 1.1em;
  color: ${ ({ read }) => read ? colors.mutedText : colors.text };
`
