import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { colors } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'
import Button from '@smartcoop/web-components/Button'

export default makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))
export const CommentDiv = styled.div`
  width: 75%;
  height: 100%;
  padding: 0;
`
export const LabelComments = styled(Typography)`
  font-size: ${ fonts.fontSize.XL }px;
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: ${ fonts.fontWeight.bold };
  color: ${ colors.black };
  margin-top: 20px;
  margin-bottom: 14px;
`
export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const CustomButton = styled(Button)`
  margin: 10px 0;
`
export const CardContainer = styled.div`
  background: ${ colors.white };
  box-shadow: 0px 0px 6px ${ colors.semiTransparent };
  border-radius: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 15px;
  margin: 15px;
`
