import React, { useRef, useCallback } from 'react'

import PropTypes from 'prop-types'

import { ThemeProvider } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'

import productWallPostSchema from '@smartcoop/forms/schemas/shoppingPlatform/productWall/productWallPost.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { organizationRounded } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import Avatar from '@smartcoop/web-components/Avatar'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import InputText from '@smartcoop/web-components/InputText'

import { Container, Name, Header, Identifier, Body, Footer, textFieldTheme, Privacy, Col, Type, ColFooter2, ColFooter1 } from './styles'

const ProductWallPost = ({ style, user, onSubmit, textFieldProps, buttonProps, organization }) =>  {
  const t = useT()
  const formRef = useRef(null)

  const handleSubmit = useCallback(
    (data) => {
      formRef.current.reset()
      onSubmit(data)
    },
    [onSubmit]
  )

  return (
    <ThemeProvider theme={ textFieldTheme }>
      <Container
        style={ style }
        ref={ formRef }
        schemaConstructor={ productWallPostSchema }
        onSubmit={ handleSubmit }
      >
        <Header>
          <Identifier>
            <Avatar alt={ user.name } src={ user.image } />
            <Col>
              <Name>{user.name}</Name>
              <Privacy>
                <Icon icon={ organizationRounded } color={ colors.green } />
                <Type>{organization.tradeName}</Type>
              </Privacy>
            </Col>
          </Identifier>
        </Header>
        <Body>

          <InputText
            placeholder={ t('type your post here').replace('\n', ' ') }
            name="text"
            fullWidth
            multiline
            rows={ 4 }
            style={ { marginBottom: 0 } }
            { ...textFieldProps }
          />
        </Body>
        <Divider style={ { margin: 0 } } />
        <Footer>
          <ColFooter1 />
          <ColFooter2>
            <div>
              <Button
                id="button"
                color="secondary"
                onClick={ () => formRef.current.submit() }
                { ...buttonProps }
              >
                <I18n>publish</I18n>
              </Button>
            </div>
          </ColFooter2>
        </Footer>
      </Container>

    </ThemeProvider>
  )}

ProductWallPost.propTypes = {
  style: PropTypes.object,
  user: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string }),
  onSubmit: PropTypes.func,
  textFieldProps: PropTypes.object,
  buttonProps: PropTypes.object,
  organization: PropTypes.object
}

ProductWallPost.defaultProps = {
  style: {},
  user: {
    name: '',
    image: ''
  },
  onSubmit: () => {},
  textFieldProps: {},
  buttonProps: {},
  organization: {}
}

export default ProductWallPost
