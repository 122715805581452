import React, { useMemo, useCallback, forwardRef, useImperativeHandle, useEffect, useRef } from 'react'
import { Polygon as PolygonLeaflet } from 'react-leaflet'

import Leaflet from 'leaflet'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import get from 'lodash/get'
import map from 'lodash/map'

import I18n from '@smartcoop/i18n'
import { polygonEdge } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import { getPolygonArea } from '@smartcoop/utils/maps'
import Button from '@smartcoop/web-components/Button'

import MarkersList from '../MarkersList'

const Polygon = forwardRef((props, polygonRef) => {
  const {
    onChange,
    onChangeArea,
    points,
    color,
    fillOpacity,
    strokeWidth,
    selectedPoint,
    setSelectedPoint,
    ...rest
  } = props

  const ref = useRef(null)

  const polygonMarker = useMemo(
    () => new Leaflet.DivIcon({
      html: polygonEdge({ color, size: 15 }),
      iconSize: new Leaflet.Point(16, 16),
      iconAnchor: [8, 25]
    }),
    [color]
  )

  const selectedPolygonMarker = useMemo(
    () => new Leaflet.DivIcon({
      html: polygonEdge({ color: '#9500ff', size: 15 }),
      iconSize: new Leaflet.Point(16, 16),
      iconAnchor: [8, 25]
    }),
    []
  )

  const onDragendPolygonMarker = useCallback((limitIndex, event) => {
    const newLimitsPoints = map(points, (limit, index) => {
      if (limitIndex === index) {
        const lat = get(event, 'target._latlng.lat')
        const lng = get(event, 'target._latlng.lng')
        return [lat, lng]
      }
      return limit
    })
    onChange(newLimitsPoints)
  }, [points, onChange])

  const onRemoveLimitMarker = useCallback((limitIndex) => {
    const newLimitsPoints = filter(
      points,
      (limit, index) => limitIndex !== index
    )
    onChange(newLimitsPoints)
  }, [points, onChange])

  const sortNewPoint = useCallback(
    (newPoint) => [...points, newPoint],
    [points]
  )

  useEffect(
    () => onChangeArea(getPolygonArea(points)),
    [onChangeArea, points]
  )

  useImperativeHandle(polygonRef, () => ({
    sortNewPoint
  }))

  const markersList = useMemo(() => (
    <MarkersList
      markers={ map(
        points,
        ([lat, lng], index) => ({
          key: `${ lat }${ lng }`,
          point: [lat, lng],
          icon: selectedPoint === index ? selectedPolygonMarker : polygonMarker,
          draggable: true,
          onClick: () => setSelectedPoint(index),
          onDragend: (event) => onDragendPolygonMarker(index, event),
          children: (
            <Button
              id="remove-point"
              size="small"
              color={ color }
              onClick={ () => onRemoveLimitMarker(index) }
            >
              <I18n>remove</I18n>
            </Button>
          )
        })
      ) }
    />
  ), [color, onDragendPolygonMarker, onRemoveLimitMarker, points, polygonMarker, selectedPoint, selectedPolygonMarker, setSelectedPoint])

  return (
    <>
      <PolygonLeaflet
        ref={ ref }
        color={ color }
        positions={ points }
        fillOpacity={ fillOpacity }
        weight={ strokeWidth }
        { ...rest }
      />

      {onChange ? markersList : null }
    </>
  )
})

Polygon.propTypes = {
  points: PropTypes.array,
  attribution: PropTypes.string,
  color: PropTypes.string,
  fillOpacity: PropTypes.number,
  zIndex: PropTypes.number,
  strokeWidth: PropTypes.number,
  onChange: PropTypes.func,
  setSelectedPoint: PropTypes.func,
  selectedPoint: PropTypes.number,
  onChangeArea: PropTypes.func
}

Polygon.defaultProps = {
  points: [],
  attribution: null,
  fillOpacity: 0.2,
  strokeWidth: 2,
  selectedPoint: null,
  zIndex: 10,
  color: colors.black,
  onChange: null,
  onChangeArea: () => {},
  setSelectedPoint: () => {}
}

export default Polygon
