import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const productivityContest = ({ t, props }) => Yup.object().shape({
  projectId: flow(
    required({ t })
  )(Yup.string()),

  userId: flow(
    required({ t })
  )(Yup.string()),

  propertyId: flow(
    required({ t })
  )(Yup.string()),

  fieldId: props?.isFieldRequired ? flow(
    required({ t })
  )(Yup.string()) : Yup.string().nullable()
})

export default productivityContest
