import React from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { eyeOpened } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import IconButton from '../IconButton'

const EyeOpenedIconButton = ({ iconColor, ...props }) => {
  const t = useT()
  return (
    <IconButton tooltip={ t('hide password') } { ...props }>
      <Icon icon={ eyeOpened } color={ iconColor } />
    </IconButton>
  )
}

EyeOpenedIconButton.propTypes = {
  iconColor: PropTypes.string
}

EyeOpenedIconButton.defaultProps = {
  iconColor: colors.mutedText
}

export default EyeOpenedIconButton
