import toNumber from 'lodash/toNumber'

export default ({ t, max, name, customSizeMessage, ignoreReplace }) => YupInstance => YupInstance
  .test('maxNumberValue',
    t('{this} must not exceed {size}', {
      this: name,
      size: customSizeMessage || max
    }),
    (value) => ignoreReplace ? value <= max : toNumber((value.replace('.', '') || '').replace(',', '.')) <= toNumber((max.replace('.', '') || '').replace(',', '.'))
  )
