import moment from 'moment/moment'

export default ({ t, minReceiptDate, text }) => YupInstance => YupInstance
  .test('date', text ?? t('invalid date'), (date) => {
    const format = 'YYYY-MM-DD'

    let dateMoment = moment()
    if (date !== 'today') {
      dateMoment = moment(date, format)
    }

    const minReceiptDateMoment = moment(minReceiptDate, format)

    return dateMoment.isSameOrAfter(minReceiptDateMoment)
  })
