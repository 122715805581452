import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'

import {
  Badge
} from './styles'

const GrowingSeasonStatus = props => {
  const {
    slug,
    style
  } = props

  const options = useMemo(() => {
    switch (slug) {
      case 'ativo':
        return {
          name: <I18n params={ { gender: 'male' } }>active</I18n>,
          background: '(40, 159, 48, 0.1)',
          color: '(40, 159, 48, 1)'
        }

      case 'encerrado':
        return {
          name: <I18n params={ { gender: 'male' } }>closed</I18n>,
          background: '(245, 125, 13, 0.1)',
          color: '(245, 125, 13, 1)'
        }

      case 'planejado':
        return {
          name: <I18n params={ { gender: 'male' } }>planned</I18n>,
          background: '(25,156,216,0.1)',
          color: '(25,156,216,1)'
        }

      default:
        return {
          name: '',
          background: '(40, 159, 48, 0.1)',
          color: '(40, 159, 48, 1)'
        }
    }
  }, [slug])

  return (
    <Badge
      backgroundColorBadge={ options.background }
      colorBadge={ options.color }
      style={ style }
    >
      {options.name}
    </Badge>
  )
}

GrowingSeasonStatus.propTypes = {
  slug: PropTypes.oneOf(['ativo', 'encerrado']).isRequired,
  style: PropTypes.object
}

GrowingSeasonStatus.defaultProps = {
  style: {}
}

export default GrowingSeasonStatus
