import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { colors } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'

const hexToRgba = require('hex-to-rgba')

export const UserName = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  font-family: ${ fonts.fontFamilySans };
  margin-right: 9px;
`

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 50,
    '&:hover': {
      backgroundColor: hexToRgba(colors.white, 0.1)
    }
  }
}))

export default useStyles
