import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  posts: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadProductWallPosts: ['params', 'onSuccess', 'onError'],
  loadProductWallPostsSuccess: ['posts', 'page', 'onSuccess'],

  saveProductWallPost: ['post', 'onSuccess', 'onError'],
  saveOfflineProductWallPost: ['post', 'onSuccess', 'onError'],

  productWallError: ['error'],

  loadProductWallComments: ['params', 'onSuccess', 'onError'],
  saveProductWallComment: ['params', 'onSuccess', 'onError'],
  editProductWallComment: ['params', 'onSuccess', 'onError' ],
  deleteProductWallComment: ['params', 'onSuccess', 'onError' ]
})

/**
 * Assigning offline actions
 */
markActionsOffline(Creators, [
  'savePost'
])


/**
 * Reducers functions
 */
const loadProductWallPostsSuccess = (state = INITIAL_STATE, { posts, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  posts: page === 1 ? posts : [
    ...state.posts,
    ...posts
  ]
})


const productWallError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})


const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_PRODUCT_WALL_POSTS_SUCCESS]: loadProductWallPostsSuccess,

  [Types.PRODUCT_WALL_ERROR]: productWallError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as ProductWallTypes,
  Creators as ProductWallActions
}
