import styled from 'styled-components'

import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
`

export const Tab = styled(Button)`
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: ${ props => props.active ? colors.white : colors.blackLight };
  box-shadow: none;
  margin: 0 4px;
`
