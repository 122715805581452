import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { isNumber } from 'lodash'
import capitalize from 'lodash/capitalize'
import filter from 'lodash/filter'
import flow from 'lodash/fp/flow'
import mapValuesFP from 'lodash/fp/mapValues'
import omitByFP from 'lodash/fp/omitBy'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import map from 'lodash/map'
import omitBy from 'lodash/omitBy'
import size from 'lodash/size'
import uniqBy from 'lodash/uniqBy'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import useSmartcoopApi from '@smartcoop/services/hooks/useSmartcoopApi'
import { useSnackbar } from '@smartcoop/snackbar'
import { BarterActions } from '@smartcoop/stores/barter'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import GoBackIconButton from '@smartcoop/web-components/IconButton/GoBackIconButton'
import Loader from '@smartcoop/web-components/Loader'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import BarterDetailsForm from '@smartcoop/web-containers/forms/commercialization/barter/BarterDetailsForm'
import CreateBarterFragment from '@smartcoop/web-containers/fragments/commercialization/CreateBarterFragment'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SignatureOrderModal from '@smartcoop/web-containers/modals/commercialization/SignatureOrderModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  Container,
  IconContainer,
  Title,
  Row,
  Column
} from './styles'

const CreateBarterScreen = () => {
  const history = useHistory()
  const t = useT()
  const { createDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const { routes } = useRoutes()
  const params = useParams()

  const formRef = useRef(null)

  const [onEditMounted, setOnEditMounted] = useState(false)
  const [loading, setLoading] = useState(false)

  const currentOrganization = useSelector(selectCurrentOrganization)

  const { barterOrderNumber } = params

  const [result, setResult] = useState({
    fertilizantes: [
      { productInternalCode: '', unit: '', quantity: '' }
    ],
    herbicidas: [
      { productInternalCode: '', unit: '', quantity: '' }
    ],
    fungicidas: [
      { productInternalCode: '', unit: '', quantity: '' }
    ],
    inseticidas: [
      { productInternalCode: '', unit: '', quantity: '' }
    ],
    sementes: [
      { productInternalCode: '', unit: '', quantity: '' }
    ],
    outros: [
      { productInternalCode: '', unit: '', quantity: '' }
    ]
  })

  const [details, setDetails] = useState({
    barterName: '',
    cropInternalCode: '',
    stateRegistration: ''
  })

  const barterDetails = useMemo(
    () => details,
    [details]
  )

  const { data: barter, isValidating } = useSmartcoopApi(barterOrderNumber ? `/v1/barters/${ barterOrderNumber }/organization/${ currentOrganization.id }` : '')

  const loaderActive = useMemo(
    () => (isEmpty(barter) && isValidating) || loading,
    [barter, isValidating, loading]
  )

  const handleError = useCallback(
    (errorMessage) => {
      createDialog({
        id: 'errors-modal',
        Component: ConfirmModal,
        props: {
          textWarning: errorMessage,
          textButtonOnConfirm: t('ok'),
          hideCancel: true,
          bodyTextStyle: { textAlign: 'left' }
        }
      })
    },
    [createDialog, t]
  )

  const submit = useCallback(
    (products, formData) => {
      setLoading(true)
      const barterProducts = []

      map(products, (data, name) => map(data, ({ productInternalCode, unit, quantity }) => {
        barterProducts.push({
          productGroupName: name,
          productInternalCode,
          measureUnit: unit,
          productQuantity: quantity
        })
      }))

      if (size(barterProducts) < 1) {
        handleError(t('select at least one {this}', { gender: 'male', this: t('product') }))
      } else {
        createDialog({
          id: 'signature-order-modal',
          Component: SignatureOrderModal,
          props: {
            onSuccess: () => {
              dispatch(BarterActions.saveProducerBarter(
                {
                  barterProducts,
                  barterOrderNumber,
                  ...formData
                },
                () => {
                  snackbar.success(t(barterOrderNumber ? 'your {this} was edited' : 'your {this} was registered', {
                    this: t('barter'),
                    gender: 'male',
                    howMany: 1
                  }))
                  history.push(routes.barterList.path)
                  setLoading(false)
                },
                (error) => {
                  snackbar.error(error)
                  setLoading(false)
                }
              ))
            },
            onCancel: () => setLoading(false)
          }
        })
      }

    },
    [barterOrderNumber, createDialog, dispatch, handleError, history, routes.barterList.path, snackbar, t]
  )

  const onSave = useCallback(
    (formData) => {
      const errors = []
      const dataValidated = flow(
        mapValuesFP.convert({ cap: false })((arrayResultType, name) => filter(arrayResultType, (row, index) => {
          const rowWithoutEmptyColumns = omitBy(row, (item) => isEmpty(item) && (isNumber(item) ? Number(item) === 0 : true))

          const fieldsFilled = size(rowWithoutEmptyColumns)

          if (fieldsFilled === 3) {
            return true
          }

          if (fieldsFilled > 0) {
            // montar array de erros
            errors.push({ type: name, rowIndex: index })
          }
          return false
        })),
        omitByFP(arrayResultType => isEmpty(arrayResultType))
      )(result)

      if (isEmpty(errors)) {
        submit(dataValidated, formData)
      } else {
        handleError(
          `${ t('found items that still need to be filled, check the following category:', { howMany: size(errors) }) }
          ${ join(map(uniqBy(errors, 'type'), ({ type }) => `${ capitalize(type) }`), ', ') }`
        )
      }
    },
    [handleError, result, submit, t]
  )

  const goBack = useCallback(
    () => {
      history.goBack()
    },
    [history]
  )

  useEffect(
    () => {
      if (!isEmpty(barter)) {
        if (!onEditMounted) {
          const types = {
            fertilizantes: [],
            herbicidas: [],
            fungicidas: [],
            inseticidas: [],
            sementes: [],
            outros: []
          }

          map(barter.barterProducts, (item) => types[item.productGroupName].push({
            productInternalCode: item.productInternalCode,
            unit: item.measureUnit,
            quantity: item.productQuantity
          }))

          setDetails({
            barterName: barter.barterName || '',
            cropInternalCode: barter.cropInternalCode || '',
            stateRegistration: barter.stateRegistration || ''
          })

          setResult(types)
          setOnEditMounted(true)
        }
      }
    },
    [barter, onEditMounted, setOnEditMounted]
  )

  return loaderActive ? <Loader width={ 100 } /> : (
    <Container>
      <Column style={ { flex: 3 } }>
        <Row>
          <IconContainer>
            <GoBackIconButton onClick={ goBack } iconColor={ colors.primary } />
          </IconContainer>
          <Title
            style={ {
              lineHeight: 1,
              fontSize: 18,
              margin: '18px 0'
            } }
          >
            <I18n
              params={ {
                gender: 'male',
                howMany: 1,
                this: t('barter') }
              }
            >
              {`${ barterOrderNumber ? 'edit' : 'new' } {this}`}
            </I18n>
          </Title>
        </Row>
        <BarterDetailsForm
          ref={ formRef }
          onSubmit={ (data) => onSave(data) }
          { ...barterDetails }
          withoutSubmitButton
        />
        <CreateBarterFragment
          orgId={ currentOrganization?.id }
          data={ result.fertilizantes }
          onResultChange={ setResult }
          title={ t('fertilizer', { howMany: 2 }) }
          type="fertilizantes"
        />
        <CreateBarterFragment
          orgId={ currentOrganization?.id }
          data={ result.herbicidas }
          onResultChange={ setResult }
          title={ t('herbicide', { howMany: 2 }) }
          type="herbicidas"
        />
        <CreateBarterFragment
          orgId={ currentOrganization?.id }
          data={ result.fungicidas }
          onResultChange={ setResult }
          title={ t('fungicide', { howMany: 2 }) }
          type="fungicidas"
        />
        <CreateBarterFragment
          orgId={ currentOrganization?.id }
          data={ result.inseticidas }
          onResultChange={ setResult }
          title={ t('insecticide', { howMany: 2 }) }
          type="inseticidas"
        />
        <CreateBarterFragment
          orgId={ currentOrganization?.id }
          data={ result.sementes }
          onResultChange={ setResult }
          title={ t('seed', { howMany: 2 }) }
          type="sementes"
        />
        <CreateBarterFragment
          orgId={ currentOrganization?.id }
          data={ result.outros }
          onResultChange={ setResult }
          title={ t('other', { howMany: 2 }) }
          type="outros"
        />
      </Column>
      <Column>
        <ButtonsContainer style={ { paddingLeft: 20 } }>
          <Button
            id="cancel-field-form"
            onClick={ goBack }
            style={ { flex: 1 } }
            variant="outlined"
            disabled={ loading }
          >
            <I18n>cancel</I18n>
          </Button>

          <div style={ { width: '10%' } } />

          <Button
            id="submit-field-form"
            onClick={ () => formRef.current.submit() }
            style={ { flex: 1 } }
            disabled={ loading }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Column>

    </Container>
  )
}

export default CreateBarterScreen


