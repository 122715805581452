// eslint-disable-next-line spaced-comment
export default ({ size, color = '#1D1D1B' } = {}) => /*html*/`
<svg width="${ size }" height="${ size }" viewBox="0 0 18 18" fill="none">
  <path d="M0 1.1233C0 0.502917 0.500539 0 1.11799 0H16.7698C17.3872 0 17.8878 0.502918 17.8878 1.1233V3.1381H0V1.1233Z" fill="${ color }"/>
  <path d="M0.846254 14.9773H0V17.0608C0 17.5644 0.378896 17.9728 0.846254 17.9728H2.23597V16.4751C2.23597 15.6457 1.61352 14.9773 0.846254 14.9773Z" fill="${ color }"/>
  <path d="M0.940274 9.73524H0V12.8927H0.940274C2.82866 12.8927 4.36494 14.4635 4.36494 16.3941V17.9728H7.45324V16.3941C7.45324 12.7163 4.54347 9.73524 0.940274 9.73524Z" fill="${ color }"/>
  <path d="M0.902661 5.24205H0V8.26036H0.902661C5.35338 8.26036 8.96042 11.9254 8.96042 16.4636V17.9728H11.9252V16.4636C11.9252 10.276 6.98052 5.24205 0.902661 5.24205Z" fill="${ color }"/>
  <path d="M13.2735 12.9362C12.9376 11.5717 12.4622 10.6277 12.033 9.94074H18V12.9362H13.2735Z" fill="${ color }"/>
  <path d="M13.6985 18C13.7414 16.8685 13.7082 15.8766 13.6211 15.0045H18V16.8767C18 17.4971 17.4995 18 16.882 18H13.6985Z" fill="${ color }"/>
  <path d="M6.70791 5.01974C8.01803 5.65708 9.34083 6.60619 10.5851 8.01519H18V5.01974H6.70791Z" fill="${ color }"/>
</svg>
`
