import JwtDecode from 'jwt-decode'

import find from 'lodash/find'
import filterFP from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import sizeFP from 'lodash/fp/size'
import includes from 'lodash/includes'
import map from 'lodash/map'
import size from 'lodash/size'
import uniqBy from 'lodash/uniqBy'

import { AVAILABLE_MODULES } from '@smartcoop/stores/module'
import { PROFILES } from '@smartcoop/utils/constants'

export const selectAuthenticated = (state) => state.authentication.authenticated

export const selectFirebaseAuthenticated = (state) =>
  !!state.authentication.firebaseIdToken

export const selectAccessToken = (state) => state.authentication.accessToken

export const selectRefreshToken = (state) => state.authentication.refreshToken

export const selectIaToken = (state) => state.authentication.iaToken

export const selectFirebaseCustomToken = (state) =>
  state.authentication.firebaseCustomToken

export const selectFirebaseIdToken = (state) =>
  state.authentication.firebaseIdToken

export const selectAuthenticationError = (state) =>
  state.authentication.error

export const selectHasOrganizationToken = (state) => {
  const accessToken = selectAccessToken(state)
  return !!JwtDecode(accessToken)?.organizationId
}

export const selectPermissionsLoaded = (state) =>
  state.authentication.permissionsLoaded

export const selectUserModules = (state) =>
  state.authentication.permissions.modules || []

export const selectAllModules = (state) => state.authentication.allModules || []
export const selectAllSlugs = (state) => state.authentication.allSlugs || []

export const selectHasFamilyGroupAndNotProperty = (state) => !!(includes(state.authentication.allSlugs, 'family-group') && !includes(state.authentication.allSlugs, 'associated-rural-producer'))

export const selectUserProfile = (state) =>
  state.authentication.permissions.profile || {}

export const selectTerms = (state) => state.authentication.terms

export const loadedPermissions = (state) =>
  state.authentication.permissions.loadedPermissions

export const selectPermissionWeatherStations = (state) =>
  state.authentication.permissions.permissionWeatherStations

export const selectUserIsSupplier = (state) => {
  const userProfile = selectUserProfile(state)
  return includes(userProfile.slug, 'supplier')
}

export const selectPermissionsResponse = (state) =>
  state.authentication.permissionsResponse

export const selectPermissionsResponseProfiles = (state) =>
  uniqBy(map(state.authentication.permissionsResponse, item => item.profile), 'id')

export const selectUserIsOrganization = (state) => {
  const userProfile = selectUserProfile(state)
  return includes(userProfile.slug, 'buyer')
}

export const selectUserIsAdmin = (state) => {
  const userProfile = selectUserProfile(state)
  return includes(userProfile.slug, 'admin')
}

export const selectUserIsRuralOwner = (state) => {
  const userProfile = selectUserProfile(state)
  return includes(userProfile.slug, 'associated-rural-producer')
}

export const selectUserIsTechnician = (state) => {
  const userProfile = selectUserProfile(state)
  return includes(userProfile.slug, 'technical')
}

export const selectUserHaveDigitalProperty = (state) => {
  const userModules = selectAllModules(state)
  return !!find(userModules, { slug: AVAILABLE_MODULES.digitalProperty })
}

export const selectUserHaveCommercialization = (state) => {
  const userModules = selectAllModules(state)
  return !!find(userModules, { slug: AVAILABLE_MODULES.commercialization })
}

export const selectUserHaveShoppingPlatform = (state) => {
  const userModules = selectAllModules(state)
  return !!find(userModules, { slug: AVAILABLE_MODULES.shoppingPlatform })
}

export const selectModulesHaveTechnical = (state) => {
  const userModules = selectAllModules(state)
  return !!find(userModules, { slug: AVAILABLE_MODULES.technical })
}

export const selectUserHaveTechnical = (state) => {
  const userModules = selectUserModules(state)
  return !!find(userModules, { slug: AVAILABLE_MODULES.technical })
}

export const selectUserHaveAdministration = (state) => {
  const userModules = selectAllModules(state)
  return !!find(userModules, { slug: AVAILABLE_MODULES.administration })
}

export const selectUserHaveChat = (state) => {
  const userModules = selectAllModules(state)
  return !!find(userModules, { slug: AVAILABLE_MODULES.chat })
}

export const selectUserHasMultipleModules = (state, isMobile = false) => {
  const userModules = selectAllModules(state)

  if (!isMobile) return size(userModules) > 1

  const result = flow(
    filterFP((mod) => mod.slug !== AVAILABLE_MODULES.shoppingPlatform),
    sizeFP
  )(userModules)
  return result > 1
}

export const selectUserProfileIsSmartcoop = (state) => {
  const userProfile = selectUserProfile(state)
  return includes(userProfile.slug, PROFILES.smartcoop)
}

export const selectUserProfileIsBuyer = (state) => {
  const userProfile = selectUserProfile(state)
  return includes(userProfile.slug, PROFILES.buyer)
}

export const selectUserProfileIsAdmin = (state) => {
  const userProfile = selectUserProfile(state)
  return includes(userProfile.slug, PROFILES.admin)
}

export const selectUserHasAdmin = (state) => {
  const allSlugs = selectAllSlugs(state)
  return includes(allSlugs, PROFILES.admin)
}

export const selectUserHasCommercialGrain = (state) => {
  const allSlugs = selectAllSlugs(state)
  return includes(allSlugs, PROFILES.commercialGrain)
}

export const selectUserHasCommercialInputs = (state) => {
  const allSlugs = selectAllSlugs(state)
  return includes(allSlugs, PROFILES.commercialInputs)
}

export const selectUserHasDetec = (state) => {
  const allSlugs = selectAllSlugs(state)
  return includes(allSlugs, PROFILES.detec)
}

export const selectUserHasRTC = (state) => {
  const allSlugs = selectAllSlugs(state)
  return includes(allSlugs, PROFILES.rtc)
}

export const selectUserProfileByOrganization = (state) => state.authentication?.permissions?.profile?.slug
