/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React, { useCallback, useMemo, forwardRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import html2pdf from 'html2pdf.js'
import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import flow from 'lodash/fp/flow'
import replaceFP from 'lodash/fp/replace'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import round from 'lodash/round'
import toNumber from 'lodash/toNumber'
import toUpper from 'lodash/toUpper'
import uniqBy from 'lodash/uniqBy'

import { useDialog } from '@smartcoop/dialog'
import operationSchema from '@smartcoop/forms/schemas/operations/operation.schema'
import { useT } from '@smartcoop/i18n'
import { camera, doc, checked, download } from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { getTechnicalEvaluationAnswersById } from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import { useSnackbar } from '@smartcoop/snackbar'
import { ChallengesActions } from '@smartcoop/stores/challenges'
import { selectProductivityChallengeFormData } from '@smartcoop/stores/challenges/selectorChallenges'
import { OperationsActions } from '@smartcoop/stores/operations'
import { colors } from '@smartcoop/styles'
import technicalOperationEvaluationReport from '@smartcoop/styles/assets/docs/technicalOperationEvaluationReport'
import { CALCULATED_STAMP, FORM_INPUT_TYPES } from '@smartcoop/utils/constants'
import { momentBackDateFormat, momentFriendlyDateFormat, momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import Badge from '@smartcoop/web-components/Badge'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import InputDate from '@smartcoop/web-components/InputDate'
import InputFile from '@smartcoop/web-components/InputFile'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import RegisterTechnicalEvaluationModal from '@smartcoop/web-containers/modals/technical/RegisterTechnicalEvaluationModal'

import { Container, ButtonContainer, Row, ButtonText, InputUnitStyled, CertificateDataTable, TableData } from './styles'

const CreateOperationForm = forwardRef((props, formRef) => {
  const {
    onSubmit,
    onSuccess,
    onError,
    isRTC,
    defaultValues,
    loading,
    setLoading,
    readOnly,
    technicalEvaluationAnswerId,
    setTechnicalEvaluationAnswerId
  } = props

  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()

  const productivityChallengeFormData = useSelector(selectProductivityChallengeFormData)

  const [userId, setUserId] = useState(defaultValues?.proprietaryId || '')
  const [propertyId, setPropertyId] = useState(defaultValues?.propertyId || '')
  const [technicalEvaluationAnswerData, setTechnicalEvaluationAnswerData] = useState(null)
  const [newOperationFiles, setNewOperationFiles] = useState([])
  const [operationFiles, setOperationFiles] = useState(
    defaultValues?.operation365Files
      ? map(defaultValues?.operation365Files, item => {
        if(includes(item.fileName, 'evaluation-report')) {
          return {
            type: 'evaluation-report',
            file: item
          }
        }
        if(includes(item.fileName, 'granular-structure')) {
          return {
            type: 'granular-structure',
            file: item
          }
        }
        if(includes(item.fileName, 'soil-analysis-0-10')) {
          return {
            type: 'soil-analysis-0-10',
            file: item
          }
        }
        if(includes(item.fileName, 'soil-analysis-10-20')) {
          return {
            type: 'soil-analysis-10-20',
            file: item
          }
        }
        if(includes(item.fileName, 'soil-analysis-20-40')) {
          return {
            type: 'soil-analysis-20-40',
            file: item
          }
        }
        if(includes(item.fileName, 'trench-picture')) {
          return {
            type: 'trench-picture',
            file: item
          }
        }
        if(includes(item.fileName, 'producer-picture')) {
          return {
            type: 'producer-picture',
            file: item
          }
        }
        return item
      })
      : []
  )
  const [certificate, setCertificate] = useState(defaultValues?.certificate || {})

  const granularStructureFile = useMemo(() => find(operationFiles, item => item?.type === 'granular-structure'),[operationFiles])
  const evaluationReportFile = useMemo(() => find(operationFiles, item => item?.type === 'evaluation-report'),[operationFiles])
  const soilAnalysis010File = useMemo(() => find(operationFiles, item => item?.type === 'soil-analysis-0-10'),[operationFiles])
  const soilAnalysis1020File = useMemo(() => find(operationFiles, item => item?.type === 'soil-analysis-10-20'),[operationFiles])
  const soilAnalysis2040File = useMemo(() => find(operationFiles, item => item?.type === 'soil-analysis-20-40'),[operationFiles])
  const trenchPictureFile = useMemo(() => find(operationFiles, item => item?.type === 'trench-picture'),[operationFiles])
  const producerPictureFile = useMemo(() => find(operationFiles, item => item?.type === 'producer-picture'),[operationFiles])

  const hasAllFiles = useMemo(() => granularStructureFile && soilAnalysis010File && soilAnalysis1020File && soilAnalysis2040File && trenchPictureFile && producerPictureFile, [granularStructureFile, producerPictureFile, soilAnalysis010File, soilAnalysis1020File, soilAnalysis2040File, trenchPictureFile])

  const divValue = useMemo(() => (round((certificate?.PGV/1) + (certificate?.ID/36) + (certificate?.PGI/3), 2)) || 0, [certificate])
  const urtValue = useMemo(() => (round((certificate?.HCC + certificate?.CRI)/4, 2)) || 0, [certificate])
  const csaValue = useMemo(() => (round((certificate?.SC/1) + (certificate?.CS/1) + (certificate?.FE/1), 2)) || 0, [certificate])
  const npValue = useMemo(() => (round((certificate?.AE/1) + (certificate?.AS/1), 2)) || 0, [certificate])
  const qesValue = useMemo(() => round(certificate?.ECSS/8, 2) || 0 , [certificate])
  const iqmValue = useMemo(() => round(divValue + urtValue + npValue + csaValue + qesValue, 2) , [csaValue, divValue, npValue, qesValue, urtValue])

  const stamp = useMemo(() => CALCULATED_STAMP(iqmValue), [iqmValue])

  const producers = useMemo(() => uniqBy(map(productivityChallengeFormData, item => item?.userProperty?.user) || [], 'id'), [productivityChallengeFormData])

  const properties = useMemo(() => filter(productivityChallengeFormData, item => item?.userProperty?.userId === userId), [productivityChallengeFormData, userId])

  const fields = useMemo(() => filter(flatMap(productivityChallengeFormData, item => item?.fields), item => item?.propertyId === propertyId), [productivityChallengeFormData, propertyId])

  useEffect(() => {
    dispatch(ChallengesActions.loadProductivityChallengeData({ needGrowingSeason: false }))
  },[dispatch])

  const renderFile = useCallback(async (file, blockDownload = false) => {
    if(isEmpty(file)) {
      return null
    }
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_OPERATION_365_ID, fileKey: file?.fileKey })

    if(blockDownload) {
      return downloadFromBase64(data)
    }
    const a = document.createElement('a')
    a.href = downloadFromBase64(data)
    a.download = file?.fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }, [])

  const handleSubmit = useCallback(
    (incomingData) => {
      setLoading(true)
      onSubmit()

      const updatedData = { ...incomingData, quizAnswerId: technicalEvaluationAnswerId }

      updatedData.certificate = {
        AE: toNumber(incomingData?.certificate?.AE),
        AS:toNumber(incomingData?.certificate?.AS),
        CRI:toNumber(incomingData?.certificate?.CRI),
        CS: toNumber(incomingData?.certificate?.CS),
        ECSS: toNumber(incomingData?.certificate?.ECSS),
        FE: toNumber(incomingData?.certificate?.FE),
        HCC:toNumber(incomingData?.certificate?.HCC),
        ID:toNumber(incomingData?.certificate?.ID),
        PGI:toNumber(incomingData?.certificate?.PGI),
        PGV:toNumber(incomingData?.certificate?.PGV),
        SC:toNumber(incomingData?.certificate?.SC)
      }

      if(isRTC) {
        dispatch(OperationsActions.updateOperation(
          null,
          {
            ...updatedData,
            stamp: stamp.name,
            id: defaultValues?.id,
            requestStatus: 'APROVADO',
            accreditationDate: moment().format('YYYY-MM-DD')
          },
          () => {
            snackbar.success(t('your {this} was accepted', {
              howMany: 1,
              this: t('operation 365'),
              gender: 'female'
            }))
            setLoading(false)
            onSuccess(updatedData)
          },
          () => {
            setLoading(false)
            onError()
          }
        ))
      } else {
        const formData = new FormData()
        if(!isEmpty(newOperationFiles)) {
          map(newOperationFiles, ({ file, type }) => {
            formData.append('uploads', file, `${ type }-${ file?.name }`)
          })
        }
        if(defaultValues?.id) {
          dispatch(OperationsActions.updateOperation(
            !isEmpty(newOperationFiles) ? formData : null,
            {
              ...updatedData,
              id: defaultValues?.id,
              requestStatus: hasAllFiles ? 'AGUARDANDO_APROVACAO' : 'DOC_PENDENTE'
            },
            () => {
              snackbar.success(t('your {this} was registered', {
                howMany: 1,
                this: t('operation 365'),
                gender: 'female'
              }))
              setLoading(false)
              onSuccess(updatedData)
            },
            () => {
              setLoading(false)
              onError()
            }
          ))
        } else {
          dispatch(OperationsActions.saveOperation(
            !isEmpty(newOperationFiles) ? formData : null,
            {
              ...updatedData,
              requestStatus: hasAllFiles ? 'AGUARDANDO_APROVACAO' : 'DOC_PENDENTE'
            },
            () => {
              snackbar.success(t('your {this} was registered', {
                howMany: 1,
                this: t('operation 365'),
                gender: 'female'
              }))
              setLoading(false)
              onSuccess(updatedData)
            },
            () => {
              setLoading(false)
              onError()
            }
          ))
        }
      }
    },
    [defaultValues, dispatch, hasAllFiles, isRTC, newOperationFiles, onError, onSubmit, onSuccess, setLoading, snackbar, stamp, t, technicalEvaluationAnswerId]
  )

  const producersOptions = useMemo(
    () => map(producers, (item) => ({ ...item, value: item.id, label: item.name })) || [],
    [producers]
  )

  const propertiesOptions = useMemo(
    () => !userId
      ? []
      : map(
        properties,
        (item) => ({
          ...item,
          value: item.id,
          label: item?.name
        })) || [],
    [properties, userId]
  )

  const fieldOptions = useMemo(
    () => !propertyId
      ? []
      : map(
        fields,
        (item) => ({
          ...item,
          value: item?.id,
          label: item?.fieldName
        })) || [],
    [fields, propertyId]
  )

  const handleProducerChange = useCallback(
    (value) => {
      setUserId(value)
      setPropertyId()
      formRef.current.getFieldRef('propertyId').setValue('')
      formRef.current.getFieldRef('fieldId').setValue('')
    },
    [formRef]
  )

  const handlePropertyChange = useCallback(
    (value) => {
      setPropertyId(value)
      formRef.current.getFieldRef('fieldId').setValue('')
    },
    [formRef]
  )

  const handleChangeFile = useCallback(
    async (event, type) => {
      const { files } = event.target
      const file = files[0]
      setOperationFiles(old => [...filter([...old], item => item?.type !== type), { type, file } ])
      setNewOperationFiles(old => [...filter([...old], item => item?.type !== type), { type, file } ])
    },
    []
  )

  const onChangeValues = useCallback(({ target: { value } }, name) => {
    setCertificate(certs => ({ ...certs, [name]: toNumber(value) }))
  }, [])

  const updateEvaluationAnswer = useCallback(async () => {
    if(technicalEvaluationAnswerId) {
      const { data } = await getTechnicalEvaluationAnswersById({ quizAnswerId: technicalEvaluationAnswerId })
      setTechnicalEvaluationAnswerData(data)
    }
  }, [technicalEvaluationAnswerId])

  useEffect(() => {
    if(!isEmpty(technicalEvaluationAnswerId)) {
      updateEvaluationAnswer()
    }
  },[technicalEvaluationAnswerId, updateEvaluationAnswer])

  const renderEvaluationFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_QUIZ_ID, fileKey: file })

    return downloadFromBase64(data)
  }, [])

  const downloadEvaluation = useCallback(async (row) => {
    if(isEmpty(row)) {
      return null
    }
    const opt = {
      margin:       [30, 30, 30, 30], // top, left, bottom, right
      filename:    `relatorio-${ row?.quiz?.name }.pdf`,
      image:        { type: 'jpeg', quality: 0.50 },
      html2canvas:  { scale: 2 },
      jsPDF:        { format: 'letter', hotfixes: ['px_scaling'], unit: 'px', letterRendering: true  },
      pageBreak: { mode: ['avoid-all'], avoid: 'img' }
    }

    const  blob = html2pdf()

    const getQuestionNameById = questionId => {
      const questions = flatMap(row?.quiz?.questionGroup, item => item.questions)
      const foundQuestion = find(questions, item => item.id === questionId)
      return foundQuestion?.name
    }

    const getQuestionRecommendationById = questionRecommendationId => {
      const questions = flatMap(row?.quiz?.questionGroup, item => item.questions)
      const recommendations = flatMap(questions, item => item.questionsRecommendation)
      const foundRecommendation = find(recommendations, item => item.id === questionRecommendationId)
      return foundRecommendation?.recommendation
    }

    const renderRecommendations = group => {

      const recommendations = filter(group.answers, item => !isEmpty(item.recommendationsAnswer))
      const recommendationsData = flatMap(recommendations, item => item.recommendationsAnswer)

      return map(recommendationsData, item => `
        <tr>
          <td>${ getQuestionNameById(find(recommendations, recommendation => recommendation.id === item.questionAnswerId).questionId) }</td>
          <td>${ getQuestionRecommendationById(item.questionRecommendationId)  }</td>
        </tr>
      `).join(' ')

    }
    const imagesList = {}
    const imagesArray = filter(flatMap(row?.quiz?.questionGroup, group => flatMap(group?.answers, item => item)), item => item?.quizQuestion?.type === FORM_INPUT_TYPES.image)
    await Promise.all(map(imagesArray, async item => {
      const file = await renderEvaluationFile(item?.answer)
      imagesList[item?.id] = file
    }))

    const evaluationOperationFiles = []
    await Promise.all(map(filter([granularStructureFile, trenchPictureFile, producerPictureFile], item => item), async file => {
      const responsedFile = await renderFile(file?.file, true)
      let name = null
      if(file?.type === 'granular-structure') {
        name = 'Foto da estrutura granular do solo'
      } if(file?.type === 'trench-picture') {
        name = 'Foto da trincheira'
      } if(file?.type === 'producer-picture') {
        name = 'Foto com o produtor'
      }

      evaluationOperationFiles.push(`
        <div class="outer-signature">
          <div class="signature">
            <img class="logo" src="${ responsedFile }" />
            <p>${ name }</p>
          </div>
        </div>
      `)
    }))

    const response = await flow(
      replaceFP('{tableData}', map(orderBy(row?.quiz?.questionGroup, 'groupOrder'), group => !isEmpty(filter(group?.answers, item => item?.quizQuestion?.type !== FORM_INPUT_TYPES?.signature)) ?
        `<table>
      <thead>
        <tr>
          <th scope="col" colSpan="2">➧ ${ toUpper(group?.description === 'default' ? row?.quiz?.name : group?.description) }</th>
        </tr>
      </thead>
      <tbody id="result">
      ${ map(orderBy(group?.answers, 'quizQuestion.questionOrder'), item => item?.quizQuestion?.type !== FORM_INPUT_TYPES?.signature ? `
      <tr>
        <td>${ item?.quizQuestion?.name }</td>
        <td>
          ${ item?.quizQuestion?.type === FORM_INPUT_TYPES.image ? `<img src="${ imagesList[item?.id] }" height="100" />` : item?.quizQuestion?.type === FORM_INPUT_TYPES.date ? moment(item?.answer, momentBackDateFormat).format(momentFriendlyDateFormat) : item?.answer }</td>
      </tr>
      ` : '').join(' ') }
      </tbody>
    </table>
    ${ !isEmpty(filter(group?.answers, (answer) => !isEmpty(answer?.recommendationsAnswer))) ?
    `
          <table>
          <thead>
            <tr>
              <th scope="col" colSpan="2">➧ RECOMENDAÇÕES TÉCNICAS</th>
            </tr>
          </thead>
          <tbody id="result">
            ${ renderRecommendations(group) }
            </tbody>
        </table>
          ` : '' }
    ` : '').join(' ')),
      replaceFP('{quizName}', row?.quiz?.name),
      replaceFP('{producerName}', row?.proprietary?.name),
      replaceFP('{technicalName}', row?.technicianOrganizationUser?.user?.name),
      replaceFP('{stateRegisterment}', row?.technicianOrganizationUser?.registry),
      replaceFP('{organizationName}', row?.technicianOrganizationUser?.organization?.tradeName),
      replaceFP('{city}', defaultValues?.property?.city || ''),
      replaceFP('{fieldName}', defaultValues?.fieldData?.fieldName || ''),
      replaceFP('{fieldArea}', defaultValues?.fieldData?.area ? round(defaultValues?.fieldData?.area, 2) : ''),
      replaceFP('{signatures}', () => {
        const signatures = filter(row?.answers, answer => answer?.quizQuestion?.type === FORM_INPUT_TYPES?.signature)

        return map(signatures, signature => `
          <div class="signature">
          <img class="logo" src="${ downloadFromBase64(signature?.answer) }" />
          <p>${ signature?.quizQuestion?.name }</p>
          </div>
        `)
      }),
      replaceFP('{operationFiles}', evaluationOperationFiles.join(' ')),
      replaceFP('{createdAt}', moment(row?.createdAt).format(momentFriendlyDateTimeFormat))
    )(technicalOperationEvaluationReport)
    await blob.set(opt).from(response).toPdf().save()
  }, [defaultValues, granularStructureFile, producerPictureFile, renderEvaluationFile, renderFile, trenchPictureFile])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ operationSchema }
        schemaProps={ { isRTC: isRTC || readOnly  } }
        onSubmit={ (data) => handleSubmit(data) }
      >
        <Row>
          {(isRTC || readOnly) && (
            <InputSelect
              detached
              fullWidth
              label={ t('technical') }
              options={ [{ label: defaultValues?.technician?.name, value: defaultValues?.technicianId }] }
              value={ defaultValues?.technicianId }
              disabled
            />
          )}
          <InputSelect
            name="proprietaryId"
            label={ t('associate name') }
            options={ isRTC ? [{ label: defaultValues?.proprietary?.name, value: defaultValues?.proprietaryId }] : producersOptions }
            onChange={ ({ target: { value } }) => handleProducerChange(value) }
            defaultValue={ defaultValues?.proprietaryId }
            disabled={ isRTC || readOnly || technicalEvaluationAnswerId }
            fullWidth
          />
        </Row>
        {(isRTC || readOnly) && (
          <Row>
            <InputSelect
              detached
              label={ t('organization', { howMany: 1 }) }
              options={ [{ label: defaultValues?.organizationUser?.organization?.tradeName, value: defaultValues?.organizationUser?.organizationId }] }
              onChange={ ({ target: { value } }) => handleProducerChange(value) }
              value={ defaultValues?.organizationUser?.organizationId }
              disabled
              fullWidth
            />
            <InputDate
              fullWidth
              label="Data de Solicitação"
              detached
              disabled
              value={ defaultValues?.requestDate ? moment(defaultValues?.requestDate, 'YYYY-MM-DD h:mm:ss').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD') }
            />
          </Row>
        )}
        <Row>
          <InputSelect
            name="propertyId"
            label={ t('property', { howMany: 1 }) }
            options={ isRTC ? [{ label: defaultValues?.property?.name, value: defaultValues?.propertyId }] : propertiesOptions }
            onChange={ ({ target: { value } }) => handlePropertyChange(value) }
            defaultValue={ defaultValues?.propertyId }
            disabled={ isRTC || readOnly }
            fullWidth
            style={ { marginRight: 5 } }
          />

          <InputSelect
            name="fieldId"
            label={ t('field', { howMany: 1 }) }
            options={ isRTC ? [{ label: defaultValues?.field?.fieldName || defaultValues?.fieldData?.fieldName, value: defaultValues?.fieldId }] : fieldOptions }
            defaultValue={ defaultValues?.fieldId }
            disabled={ isRTC || readOnly }
            fullWidth
            style={ { marginLeft: 5 } }
          />
        </Row>
        {(isRTC || readOnly) && (
          <Row>
            <InputDate
              label="Data de Validade"
              name="expirationDate"
              pickerProps={ {
                minDate: moment().add(1, 'days').format('YYYY-MM-DD'),
                fullWidth: true
              } }
              defaultValue={ defaultValues?.expirationDate ? moment(defaultValues?.expirationDate, 'YYYY-MM-DD h:mm:ss').format('YYYY-MM-DD') : undefined }
              fullWidth
              disabled={ readOnly }
            />

            <Badge
              backgroundColorBadge={ stamp?.color }
              colorBadge={ stamp?.color }
              style={ { marginRight: 5, marginLeft: 5, width: '100%', fontSize: 18, marginTop: -10 } }
            >
              {t(stamp?.name)}
            </Badge>
          </Row>
        )}
        {(isRTC || readOnly) && (
          <Row>
            <InputText
              name="note"
              label={ t('observations') }
              fullWidth
              style={ { marginRight: 5, marginLeft: 5 } }
              defaultValue={ defaultValues?.note }
              disabled={ readOnly }
              multiline
              rows={ 5 }
            />
          </Row>
        )}
        {(isRTC || readOnly) && (
          <div style={ { display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-start', marginBottom: 20, width: '100%' } }>
            <ButtonText style={ { marginBottom: 10, marginTop: 10 } }>
              Relatório de avaliação
            </ButtonText>
            <CertificateDataTable>
              <thead>
                <th> </th>
                <th>MAX</th>
                <th>Nota</th>
              </thead>
              <tr>
                <td>
                  <InputUnitStyled
                    name="certificate.ID"
                    label="ID"
                    unit={ t('month', { howMany: 2 }) }
                    type="integer"
                    fullWidth
                    disabled={ loading || readOnly }
                    defaultValue={ defaultValues?.certificate?.ID }
                    onChange={ (e) => onChangeValues(e, 'ID') }
                  />
                </td>
                <td>
                  <TableData>
                  36
                  </TableData>
                </td>
                <td>
                  <TableData>
                    {certificate?.ID ? round(certificate?.ID/36, 2) : '0'}
                  </TableData>
                </td>
                <td rowSpan={ 3 } style={ { background: '#fff' } }>
                  <InputUnitStyled
                    detached
                    unit="DIV"
                    type="float"
                    disabled
                    value={ divValue === 0 ? '0' : divValue }
                    style={ { width: '120px' } }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <InputUnitStyled
                    name="certificate.PGV"
                    label="PGV"
                    unit="UN"
                    type="integer"
                    fullWidth
                    disabled={ loading || readOnly }
                    defaultValue={ defaultValues?.certificate?.PGV }
                    onChange={ (e) => onChangeValues(e, 'PGV') }
                  />
                </td>
                <td>
                  <TableData>
                  1
                  </TableData>
                </td>
                <td>
                  <TableData>
                    {certificate?.PGV ? round(certificate?.PGV/1, 2) : '0'}
                  </TableData>
                </td>
              </tr>
              <tr>
                <td>
                  <InputUnitStyled
                    name="certificate.PGI"
                    label="PGI"
                    unit="UN"
                    type="integer"
                    fullWidth
                    disabled={ loading || readOnly }
                    defaultValue={ defaultValues?.certificate?.PGI }
                    onChange={ (e) => onChangeValues(e, 'PGI') }
                  />
                </td>
                <td>
                  <TableData>
                  3
                  </TableData>
                </td>
                <td>
                  <TableData>
                    {certificate?.PGI ? round(certificate?.PGI/3, 2) : '0'}
                  </TableData>
                </td>
              </tr>
              <tr>
                <td>
                  <InputUnitStyled
                    name="certificate.HCC"
                    label="HCC"
                    unit="nota"
                    type="float"
                    fullWidth
                    disabled={ loading || readOnly }
                    defaultValue={ defaultValues?.certificate?.HCC }
                    onChange={ (e) => onChangeValues(e, 'HCC') }
                  />
                </td>
                <td>
                  <TableData>
                  1
                  </TableData>
                </td>
                <td>
                  <TableData>
                    {certificate?.HCC ? round(certificate?.HCC/1, 2) : '0'}
                  </TableData>
                </td>
                <td rowSpan={ 2 } style={ { background: '#fff' } }>
                  <InputUnitStyled
                    detached
                    unit="URT"
                    type="float"
                    disabled
                    value={ urtValue === 0 ? '0' : urtValue }
                    style={ { width: '120px' } }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <InputUnitStyled
                    name="certificate.CRI"
                    label="CRI"
                    unit="UN"
                    type="integer"
                    fullWidth
                    disabled={ loading || readOnly }
                    defaultValue={ defaultValues?.certificate?.CRI }
                    onChange={ (e) => onChangeValues(e, 'CRI') }
                  />
                </td>
                <td>
                  <TableData>
                  3
                  </TableData>
                </td>
                <td>
                  <TableData>
                    {certificate?.CRI ? round(certificate?.CRI/3, 2) : '0'}
                  </TableData>
                </td>
              </tr>
              <tr>
                <td>
                  <InputUnitStyled
                    name="certificate.SC"
                    label="SC"
                    unit="nota"
                    type="float"
                    fullWidth
                    disabled={ loading || readOnly }
                    defaultValue={ defaultValues?.certificate?.SC }
                    onChange={ (e) => onChangeValues(e, 'SC') }
                  />
                </td>
                <td>
                  <TableData>
                  1
                  </TableData>
                </td>
                <td>
                  <TableData>
                    {certificate?.SC ? round(certificate?.SC/1, 2) : '0'}
                  </TableData>
                </td>
                <td rowSpan={ 3 } style={ { background: '#fff' } }>
                  <InputUnitStyled
                    detached
                    unit="CSA"
                    type="float"
                    disabled
                    value={ csaValue === 0 ? '0' : csaValue }
                    style={ { width: '120px' } }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <InputUnitStyled
                    name="certificate.CS"
                    label="CS"
                    unit="nota"
                    type="float"
                    fullWidth
                    disabled={ loading || readOnly }
                    defaultValue={ defaultValues?.certificate?.CS }
                    onChange={ (e) => onChangeValues(e, 'CS') }
                  />
                </td>
                <td>
                  <TableData>
                  1
                  </TableData>
                </td>
                <td>
                  <TableData>
                    {certificate?.CS ? round(certificate?.CS/1, 2) : '0'}
                  </TableData>
                </td>
              </tr>
              <tr>
                <td>
                  <InputUnitStyled
                    name="certificate.FE"
                    label="FE"
                    unit="nota"
                    type="float"
                    fullWidth
                    disabled={ loading || readOnly }
                    defaultValue={ defaultValues?.certificate?.FE }
                    onChange={ (e) => onChangeValues(e, 'FE') }
                  />
                </td>
                <td>
                  <TableData>
                  1
                  </TableData>
                </td>
                <td>
                  <TableData>
                    {certificate?.FE ? round(certificate?.FE/1, 2) : '0'}
                  </TableData>
                </td>
              </tr>
              <tr>
                <td>
                  <InputUnitStyled
                    name="certificate.AE"
                    label="AE"
                    unit="nota"
                    type="float"
                    fullWidth
                    disabled={ loading ||readOnly }
                    defaultValue={ defaultValues?.certificate?.AE }
                    onChange={ (e) => onChangeValues(e, 'AE') }
                  />
                </td>
                <td>
                  <TableData>
                  1
                  </TableData>
                </td>
                <td>
                  <TableData>
                    {certificate?.AE ? round(certificate?.AE/1, 2) : '0'}
                  </TableData>
                </td>
                <td rowSpan={ 2 } style={ { background: '#fff' } }>
                  <InputUnitStyled
                    detached
                    unit="NP"
                    type="float"
                    disabled
                    value={ npValue === 0 ? '0' : npValue }
                    style={ { width: '120px' } }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <InputUnitStyled
                    decimalScale={ 3 }
                    name="certificate.AS"
                    label="AS"
                    unit="nota"
                    type="float"
                    fullWidth
                    disabled={ loading || readOnly }
                    defaultValue={ defaultValues?.certificate?.AS }
                    onChange={ (e) => onChangeValues(e, 'AS') }
                  />
                </td>
                <td>
                  <TableData>
                  1
                  </TableData>
                </td>
                <td>
                  <TableData>
                    {certificate?.AS ? round(certificate?.AS/1, 2) : '0'}
                  </TableData>
                </td>
              </tr>
              <tr>
                <td>
                  <InputUnitStyled
                    name="certificate.ECSS"
                    label="ECSS"
                    unit="cm"
                    type="float"
                    fullWidth
                    disabled={ loading || readOnly }
                    defaultValue={ defaultValues?.certificate?.ECSS }
                    onChange={ (e) => onChangeValues(e, 'ECSS') }
                  />
                </td>
                <td>
                  <TableData>
                  8
                  </TableData>
                </td>
                <td>
                  <TableData>
                    {certificate?.ECSS ? round(certificate?.ECSS/8, 2) : '0'}
                  </TableData>
                </td>
                <td rowSpan={ 1 } style={ { background: '#fff' } }>
                  <InputUnitStyled
                    detached
                    unit="QES"
                    type="float"
                    disabled
                    value={ qesValue === 0 ? '0' : qesValue }
                    style={ { width: '120px' } }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={ 4 }>
                  <InputUnitStyled
                    detached
                    label="IQM"
                    unit="nota"
                    type="float"
                    fullWidth
                    disabled
                    value={ iqmValue === 0 ? '0' : iqmValue }
                  />
                </td>
              </tr>
            </CertificateDataTable>
          </div>
        )}

        <ButtonContainer>
          <Button
            id="add-soil-evaluation-report"
            variant="outlined"
            fullWidth
            spaced
            disabled={ isEmpty(userId) }
            onClick={  isRTC || readOnly ? evaluationReportFile?.file ? () => renderFile(evaluationReportFile?.file) : () => downloadEvaluation(technicalEvaluationAnswerData) : () => createDialog({
              id: 'evaluation-modal',
              Component: RegisterTechnicalEvaluationModal,
              props: {
                onSubmit: (data) => {
                  setTechnicalEvaluationAnswerId(data)
                  updateEvaluationAnswer()
                },
                defaultForm: 'operacao-365',
                defaultProducer: find(producersOptions, item => item?.id === userId),
                defaultValues: technicalEvaluationAnswerData
              }
            })
            }
          >
            <div style={ { display: 'flex', flexDirection: 'row' } }>
              <Icon icon={ doc } color={ colors.text } />
              <ButtonText>
                {isRTC || readOnly || technicalEvaluationAnswerId ? 'Relatório de Avaliação' : 'Preencher  Avaliação'}
              </ButtonText>
            </div>
            {technicalEvaluationAnswerId || evaluationReportFile?.file ? (
              <Icon icon={ isRTC || readOnly ? download : checked } color={ colors.text } />
            ) : null}
          </Button>
        </ButtonContainer>

        <ButtonContainer>
          <InputFile
            style={ { margin: 5, width: '100%' } }
            idInput="contained-button-file-soil-analysis-0-10"
            onChange={ (event) => handleChangeFile(event, 'soil-analysis-0-10') }
            inputProps={ {
              accept: 'image/png,image/jpeg,image/tiff,application/pdf',
              disabled: isRTC || readOnly
            } }
            buttonProps={ {
              id: 'add-soil-analysis-0-10-file }',
              variant: 'outlined',
              fullWidth: true,
              spaced: true,
              onClick: isRTC || readOnly ? () => renderFile(soilAnalysis010File?.file) : () => {}
            } }
          >
            <div style={ { display: 'flex', flexDirection: 'row' } }>
              <Icon icon={ doc } color={ colors.text } />
              <ButtonText>
                {isRTC || readOnly ? 'Análise de solo 0 - 10cm' : 'Anexar análise de solo 0 - 10cm'}
              </ButtonText>
            </div>
            {soilAnalysis010File && (
              <Icon icon={ isRTC || readOnly ? download : checked } color={ colors.text } />
            )}
          </InputFile>
        </ButtonContainer>
        <ButtonContainer>
          <InputFile
            style={ { margin: 5, width: '100%' } }
            idInput="contained-button-file-soil-analysis-10-20"
            onChange={ (event) => handleChangeFile(event, 'soil-analysis-10-20') }
            inputProps={ {
              accept: 'image/png,image/jpeg,image/tiff,application/pdf',
              disabled: isRTC || readOnly
            } }
            buttonProps={ {
              id: 'add-soil-analysis-10-20-file }',
              variant: 'outlined',
              fullWidth: true,
              spaced: true,
              onClick: isRTC || readOnly ? () => renderFile(soilAnalysis1020File?.file) : () => {}
            } }
          >
            <div style={ { display: 'flex', flexDirection: 'row' } }>
              <Icon icon={ doc } color={ colors.text } />
              <ButtonText>
                {isRTC || readOnly ? 'Análise de solo 10 - 20cm' : 'Anexar análise de solo 10 - 20cm'}
              </ButtonText>
            </div>
            {soilAnalysis1020File && (
              <Icon icon={ isRTC || readOnly ? download : checked } color={ colors.text } />
            )}
          </InputFile>
        </ButtonContainer>

        <ButtonContainer>
          <InputFile
            style={ { margin: 5, width: '100%' } }
            idInput="contained-button-file-soil-analysis-20-40"
            onChange={ (event) => handleChangeFile(event, 'soil-analysis-20-40') }
            inputProps={ {
              accept: 'image/png,image/jpeg,image/tiff,application/pdf',
              disabled: isRTC || readOnly
            } }
            buttonProps={ {
              id: 'add-soil-analysis-20-40-file }',
              variant: 'outlined',
              fullWidth: true,
              spaced: true,
              onClick: isRTC || readOnly ? () => renderFile(soilAnalysis2040File?.file) : () => {}
            } }
          >
            <div style={ { display: 'flex', flexDirection: 'row' } }>
              <Icon icon={ doc } color={ colors.text } />
              <ButtonText>
                {isRTC || readOnly ? 'Análise de solo 20 - 40cm' : 'Anexar análise de solo 20 - 40cm'}
              </ButtonText>
            </div>
            {soilAnalysis2040File && (
              <Icon icon={ isRTC || readOnly ? download : checked } color={ colors.text } />
            )}
          </InputFile>
        </ButtonContainer>
        <ButtonContainer>
          <InputFile
            style={ { margin: 5, width: '100%' } }
            idInput="contained-button-file-granular-structure"
            onChange={ (event) => handleChangeFile(event, 'granular-structure') }
            inputProps={ {
              accept: 'image/png,image/jpeg,image/tiff',
              disabled: isRTC || readOnly
            } }
            buttonProps={ {
              id: 'add-granular-structure }',
              variant: 'outlined',
              fullWidth: true,
              spaced: true,
              onClick: isRTC || readOnly ? () => renderFile(granularStructureFile?.file) : () => {}
            } }
          >
            <div style={ { display: 'flex', flexDirection: 'row' } }>
              <Icon icon={ camera } color={ colors.text } />
              <ButtonText>
                {isRTC || readOnly ? 'Foto da estrutura granular do solo' : 'Anexar Foto da estrutura granular do solo'}
              </ButtonText>
            </div>
            {granularStructureFile && (
              <Icon icon={ isRTC || readOnly ? download : checked } color={ colors.text } />
            )}
          </InputFile>
        </ButtonContainer>
        <ButtonContainer>
          <InputFile
            style={ { margin: 5, width: '100%' } }
            idInput="contained-button-file-trench-picture"
            onChange={ (event) => handleChangeFile(event, 'trench-picture') }
            inputProps={ {
              accept: 'image/png,image/jpeg,image/tiff',
              disabled: isRTC || readOnly
            } }
            buttonProps={ {
              id: 'add-trench-picture-file }',
              variant: 'outlined',
              fullWidth: true,
              spaced: true,
              onClick: isRTC || readOnly ? () => renderFile(trenchPictureFile?.file) : () => {}
            } }
          >
            <div style={ { display: 'flex', flexDirection: 'row' } }>
              <Icon icon={ camera } color={ colors.text } />
              <ButtonText>
                {isRTC || readOnly ? 'Foto da trincheira' : 'Anexar foto da trincheira'}
              </ButtonText>
            </div>
            {trenchPictureFile && (
              <Icon icon={ isRTC || readOnly ? download : checked } color={ colors.text } />
            )}
          </InputFile>
        </ButtonContainer>

        <ButtonContainer>
          <InputFile
            style={ { margin: 5, width: '100%' } }
            idInput="contained-button-file-producer-picture"
            onChange={ (event) => handleChangeFile(event, 'producer-picture') }
            inputProps={ {
              accept: 'image/png,image/jpeg,image/tiff',
              disabled: isRTC || readOnly
            } }
            buttonProps={ {
              id: 'add-producer-picture-file }',
              variant: 'outlined',
              fullWidth: true,
              spaced: true,
              onClick: isRTC || readOnly ? () => renderFile(producerPictureFile?.file) : () => {}
            } }
          >
            <div style={ { display: 'flex', flexDirection: 'row' } }>
              <Icon icon={ camera } color={ colors.text } />
              <ButtonText>
                {isRTC || readOnly ? 'Foto com produtor' : 'Anexar foto com produtor'}
              </ButtonText>
            </div>
            {producerPictureFile && (
              <Icon icon={ isRTC || readOnly ? download : checked } color={ colors.text } />
            )}
          </InputFile>
        </ButtonContainer>
      </Form>
    </Container>
  )
})

CreateOperationForm.propTypes = {
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  isRTC: PropTypes.bool,
  readOnly: PropTypes.bool,
  technicalEvaluationAnswerId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  setTechnicalEvaluationAnswerId: PropTypes.func.isRequired,
  onError: PropTypes.func,
  defaultValues: PropTypes.object
}

CreateOperationForm.defaultProps = {
  onSuccess: () => {},
  onSubmit: () => {},
  onError: () => {},
  defaultValues: {},
  isRTC: false,
  technicalEvaluationAnswerId: null,
  readOnly: false
}

export default CreateOperationForm
