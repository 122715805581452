import React, { useState } from 'react'

import PropTypes from 'prop-types'

import get from 'lodash/get'

import I18n, { useT } from '@smartcoop/i18n'
import { updateChatContactCode } from '@smartcoop/services/apis/smartcoopApi/resources/chat'
import { useSnackbar } from '@smartcoop/snackbar'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'

import { Container, HeaderText , ButtonContainer, BodyText, CodeInput } from './styles'


const ChangeCodeModal = ({ id, open, handleClose, name, contactId, handleSubmit }) => {
  const [code, setCode] = useState('')

  const t = useT()
  const snackbar = useSnackbar()

  const onSubmit = async () => {
    if (code?.length !== 8) {
      return snackbar.warning(t('invalid contact code'))
    }

    try {
      await updateChatContactCode(contactId, code)
      await handleSubmit(contactId, code)

      snackbar.success(t('contact updated successfully'))

      return handleClose()
    } catch(error) {
      return snackbar.error(get(
        error,
        'response.data.message',
        t('an error occurred while updating the contact')
      ))
    }
  }

  return (
    <Modal
      id={ id }
      open={ open }
      escape={ false }
      hideHeader
    >
      <Container>
        <HeaderText>
          <I18n>update contact code</I18n>
        </HeaderText>

        <BodyText>
          {t('contact "{name}" changed the code. type the new contact code below to continue chatting', { name })}
        </BodyText>

        <CodeInput
          onChange={ e => setCode(e.target?.value) }
          value={ code }
          placeholder={ t('contact code') }
          maxLength={ 8 }
        />

        <ButtonContainer>
          <Button
            id="cancel"
            onClick={ handleClose }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="submit"
            onClick={ onSubmit }
            color="secondary"
          >
            <I18n>send</I18n>
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  )
}

ChangeCodeModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  contactId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func
}

ChangeCodeModal.defaultProps = {
  handleSubmit: () => {}
}

export default ChangeCodeModal
