import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  indicators: [],
  loaded: false,
  error: null,
  hasMissingImages: false
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  searchIndicatorsByDate: ['filter'],
  searchIndicatorsByDateSuccess: ['indicators'],

  resetSateliteSvc: [],

  sateliteSvcError: ['error'],

  getGeotiffImage: ['payload', 'onSuccess', 'onError'],

  setLoadedFalse: [],
  validateGetImagesResult: ['images', 'period'],
  setHasMissingImages: ['hasMissingImages']
})

/**
 * Reducers functions
 */
const searchIndicatorsByDateSuccess = (state = INITIAL_STATE, { indicators }) => ({
  ...state,
  indicators,
  loaded: true
})

const resetSateliteSvc = () => ({ ...INITIAL_STATE })

const sateliteSvcError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error,
  loaded: true
})

const logout = () => ({ ...INITIAL_STATE })

const setLoadedFalse = () => ({ ...INITIAL_STATE, loaded: false })

const setHasMissingImages = (state = INITIAL_STATE, { hasMissingImages }) => ({
  ...state,
  hasMissingImages
})

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.SEARCH_INDICATORS_BY_DATE_SUCCESS]: searchIndicatorsByDateSuccess,

  [Types.RESET_SATELITE_SVC]: resetSateliteSvc,

  [Types.SATELITE_SVC_ERROR]: sateliteSvcError,

  [AuthenticationTypes.LOGOUT]: logout,

  [Types.SET_LOADED_FALSE]: setLoadedFalse,
  [Types.SET_HAS_MISSING_IMAGES]: setHasMissingImages
})

export {
  Types as SateliteSvcTypes,
  Creators as SateliteSvcActions
}
