import crypto from 'crypto'

export async function encrypt(message, rawPublicKey) {
  const key = crypto.randomBytes(32) // Generate a random key
  const iv = crypto.randomBytes(16) // Generate a random IV
  const cipher = crypto.createCipheriv('aes-256-cbc', key, iv)
  const encryptedData = Buffer.concat([cipher.update(message), cipher.final()])

  const encryptedKey = crypto.publicEncrypt({
    key: rawPublicKey,
    padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
    oaepHash: 'sha1'
  }, key)

  return Promise.resolve(`${ iv.toString('base64') }.${ encryptedKey.toString('base64') }.${ encryptedData.toString('base64') }`)
}

export async function decrypt(message, rawPrivateKey) {
  try {
    const [rawIV, encryptedKey, encryptedData] = message.split('.').map(x => Buffer.from(x, 'base64'))

    const decryptedKey = crypto.privateDecrypt({
      key: rawPrivateKey,
      padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
      oaepHash: 'sha1'
    }, encryptedKey)

    const decipher = crypto.createDecipheriv('aes-256-cbc', decryptedKey, rawIV)
    const decryptedData = Buffer.concat([decipher.update(encryptedData), decipher.final()])
    return Promise.resolve(decryptedData.toString())
  } catch (error) {
    // console.log('[Decrypt] Error: ', error)
    return Promise.resolve(message)
  }
}

export async function generateKey() {
  const key = crypto.randomBytes(32) // Generate a random key
  const iv = crypto.randomBytes(16) // Generate a random IV

  return `${ key.toString('base64') }.${  iv.toString('base64') }`
}

export async function encryptToGroup(message, aesKey) {
  const [key, iv] = aesKey.split('.').map(x => Buffer.from(x, 'base64'))

  const cipher = crypto.createCipheriv('aes-256-cbc', key, iv)
  const encryptedData = Buffer.concat([cipher.update(message), cipher.final()])

  return encryptedData.toString('base64')
}

export async function decryptToGroup(message, aesKey) {
  try {
    const [key, iv] = aesKey.split('.').map(x => Buffer.from(x, 'base64'))

    const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv)
    const decryptedData = Buffer.concat([decipher.update(Buffer.from(message, 'base64')), decipher.final()])

    return Promise.resolve(decryptedData.toString())
  } catch(error) {
    return Promise.resolve(message)
  }
}
