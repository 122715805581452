import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
`

export const HourWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 3;
  margin-right: 10px;
`

export const TotalQuantityRow = styled(Row)`
  margin-top: 20;
  background-color: #fff;
  padding: 12px 15px 12px 35px;
  justify-content: space-between;
  align-items: center;
`

export const TotalQuantity = styled(Typography)`
  font-weight: 600;
  font-size: 16;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
`
