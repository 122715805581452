import moment from 'moment/moment'
import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import maxDate from '@smartcoop/forms/validators/maxDate.validator'
import maxNumberValue from '@smartcoop/forms/validators/maxNumberValue.validator'
import minNumberValue from '@smartcoop/forms/validators/minNumberFloatValue.validator'
import required from '@smartcoop/forms/validators/required.validator'

const propertyTechnicalVisit = ({ t }) => Yup.object().shape({
  dateVisit: flow(
    date({ t }),
    maxDate({ t, maxReceiptDate: moment().format('YYYY-MM-DD') }),
    required({ t })
  )(Yup.string()),

  observations: Yup.string(),

  hour: flow(
    required({ t })
  )(Yup.string().matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, t('invalid hour'))),

  visitMode: flow(
    required({ t })
  )(Yup.string()),

  visitGoalId: flow(
    required({ t })
  )(Yup.string()),

  fields: Yup.array()
    .of(
      Yup.object().shape({
        aditionalInformations: Yup.object().shape({
          climateEvent: Yup.string().nullable(),
          phenologicalStage: Yup.string().nullable(),
          stage: Yup.string().nullable(),
          numberOfPlants: Yup.string().nullable()
        }).nullable(),

        estimateUnity: Yup.string().nullable(),

        productivityEstimate:  Yup.string().when('estimateUnity', {
          is: 'kg/ha',
          then: flow(
            required({ t }),
            minNumberValue({ t, field: t('productivity in this unit'), limit: 999 })
          )(Yup.string()),
          otherwise: Yup.string().when('estimateUnity', {
            is: 'sacas/ha',
            then: flow(
              required({ t }),
              maxNumberValue({ t, name: t('productivity in this unit'), customSizeMessage: '999 sacas', max: '999' })
            )(Yup.string()),
            otherwise: Yup.string().nullable()
          })
        })
      })
    )
})

export default propertyTechnicalVisit
