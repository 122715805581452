import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import { barn } from '@smartcoop/icons'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { selectAllModules } from '@smartcoop/stores/authentication/selectorAuthentication'
import { ModuleActions } from '@smartcoop/stores/module'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import Modal from '@smartcoop/web-components/Modal'

import { Container, Text, TextUnderlined } from './styles'

const ZeroPropertiesModal = ({
  id,
  open,
  handleClose,
  onConfirm,
  onCancel
}) => {
  const dispatch = useCallback(useDispatch(), [])

  const allModules = useSelector(selectAllModules)

  const exitModule = useCallback(
    () => dispatch(ModuleActions.exitCurrentModule()),
    [dispatch]
  )

  const showGoOutModule = useMemo(() => {
    if (allModules.length > 1) {
      return true
    }
    return false
  }, [allModules.length])

  const handleExit = useCallback(
    () => {
      if (showGoOutModule) {
        exitModule()
        handleClose()
        onCancel()
      } else {
        dispatch(AuthenticationActions.logout())
      }
    },
    [dispatch, exitModule, handleClose, onCancel, showGoOutModule]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      escape={ false }
      hideHeader
      style={ { zIndex: 9999 } }
    >
      <Container>
        <Text style={ { marginTop: 0 } }>
          <I18n>your registration was effected!</I18n>
        </Text>

        <Icon icon={ barn } size={ 85 } />

        <Text>
          <I18n>to start, let&apos;s register your property!</I18n>
        </Text>

        <Button
          id="register-property"
          onClick={ () => {
            handleClose()
            onConfirm()
          } }
          color="secondary"
        >
          <I18n>register property</I18n>
        </Button>

        <TextUnderlined style={ { marginTop: 20 } } onClick={ handleExit }>
          <I18n>{showGoOutModule ? 'exit module' : 'logout'}</I18n>
        </TextUnderlined>
      </Container>
    </Modal>
  )
}

ZeroPropertiesModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

ZeroPropertiesModal.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {}
}

export default ZeroPropertiesModal
