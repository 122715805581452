/* eslint-disable import/no-cycle */
import { useMemo } from 'react'

import ReportDownloadScreen from '../screens/authenticated/ReportDownloadScreen'
import CreatePasswordScreen from '../screens/guest/CreatePasswordScreen'
import IdentificationScreen from '../screens/guest/IdentificationScreen'
import LoginScreen from '../screens/guest/LoginScreen'
import OrganizationsScreen from '../screens/guest/OrganizationsScreen'
import ProducerRegisterScreen from '../screens/guest/ProducerRegisterScreen'
import RecoverPasswordScreen from '../screens/guest/RecoverPasswordScreen'
import ResetCodeScreen from '../screens/guest/ResetCodeScreen'
import StampScreen from '../screens/guest/StampScreen'
import PrivacyTermScreen from '../screens/guest/term/PrivacyTermScreen'
import UseTermScreen from '../screens/guest/term/UseTermScreen'

export const useRoutes = () => {
  const routes = useMemo(() => ({
    login: {
      path: '/',
      exact: true,
      Component: LoginScreen
    },
    identification: {
      path: '/sign-up/identification',
      exact: true,
      Component: IdentificationScreen
    },
    organizations: {
      path: '/sign-up/organizations',
      exact: true,
      Component: OrganizationsScreen
    },
    resetCode: {
      path: '/reset-code',
      exact: true,
      Component: ResetCodeScreen
    },
    createPassword: {
      path: '/create-password',
      exact: true,
      Component: CreatePasswordScreen
    },
    recoverPassword: {
      path: '/recover-password',
      exact: true,
      Component: RecoverPasswordScreen
    },
    privacyTermScreen: {
      path: '/term/privacy',
      exact: true,
      Component: PrivacyTermScreen
    },
    useTermScreen: {
      path: '/term/use',
      exact: true,
      Component: UseTermScreen
    },
    producerRegisterScreen: {
      path: '/acesso-produtor',
      exact: true,
      Component: ProducerRegisterScreen
    },
    stamps: {
      path: '/selos/:hash/:stampSlug',
      exact: true,
      Component: StampScreen
    },
    reportDownload: {
      path: '/report/:reportId',
      exact: true,
      Component: ReportDownloadScreen
    }
  }), [])

  return { routes }
}
