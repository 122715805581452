import React, { useRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'

import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import map from 'lodash/map'
import toString from 'lodash/toString'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { eyeOpened, pencil, send, trash } from '@smartcoop/icons'
import { getAdvertisements } from '@smartcoop/services/apis/smartcoopApi/resources/advertising'
import { selectUserProfileIsSmartcoop } from '@smartcoop/stores/authentication/selectorAuthentication'
import { ChatActions } from '@smartcoop/stores/chat'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { colors } from '@smartcoop/styles'
import { MODULES_IDS, PROFILES_IDS } from '@smartcoop/utils/constants'
import { momentBackDateTimeFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import { formatNumberJs } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import AdvertisingRegisterModal from '@smartcoop/web-containers/modals/AdvertisingRegisterModal'
import RefuseModal from '@smartcoop/web-containers/modals/RefuseModal'

import { Badge, Container, FilterRow } from './style'

const AdvertisingScreen = () => {
  const t = useT()
  const tableRef = useRef(null)
  const { createDialog } = useDialog()
  const dispatch = useDispatch()

  const userProfileIsSmartcoop = useSelector(selectUserProfileIsSmartcoop)
  const currentOrganization = useSelector(selectCurrentOrganization)

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )

  const columns = useMemo(
    () => [
      {
        title: t('organization', { howMany: 2 }),
        field: 'organizationsIds',
        render: (row) => isEmpty(row?.organizations) ? '-' : join(map(row?.organizations, item => item.tradeName), ', ')
      },
      {
        title: t('profile', { howMany: 2 }),
        field: 'profilesIds',
        render: row => {
          const foundProfiles = []
          map(PROFILES_IDS, (item, index) => {
            if(includes(row?.profilesIds, toString(item))) {
              foundProfiles.push(t(index))
            }
          })

          return isEmpty(foundProfiles) ? '-' : join(foundProfiles, ', ')
        }
      },
      {
        title: t('module', { howMany: 2 }),
        field: 'modulesIds',
        render: row => {
          const foundModules = []
          map(MODULES_IDS, (item, index) => {
            if(includes(row?.modulesIds, toString(item))) {
              foundModules.push(t(index, { howMany: 2 }))
            }
          })

          return isEmpty(foundModules) ? '-' : join(foundModules, ', ')
        }
      },
      {
        title: t('start date'),
        field: 'startDate',
        defaultSort: 'desc',
        render: row => moment(row.startDate, momentBackDateTimeFormat).format(momentFriendlyDateFormat),
        sorting: true
      },
      {
        title: t('end date'),
        field: 'endDate',
        render: row => moment(row.endDate, momentBackDateTimeFormat).format(momentFriendlyDateFormat),
        sorting: true
      },
      {
        title: t('status'),
        field: 'status',
        render: row => {
          let badge = null
          switch (row?.status) {
            case 'solicitado':
              badge = {
                name: <I18n params={ { gender: 'female' } }>requested</I18n>,
                background: '(25, 156, 216, 0.1)',
                color: '(25, 156, 216, 1)'
              }
              break
            case 'recusado':
              badge = {
                name: <I18n params={ { gender: 'female' } }>refused</I18n>,
                background: '(228, 29, 27, 0.1)',
                color: '(228, 29, 27, 1)'
              }
              break
            case 'enviado':
              badge = {
                name: <I18n params={ { gender: 'female' } }>sent</I18n>,
                background: '(40, 159, 48, 0.1)',
                color: '(40, 159, 48, 1)'
              }
              break
            case 'finalizado':
              badge = {
                name: <I18n params={ { gender: 'female' } }>finished</I18n>,
                background: '(29, 29, 27, 0.1)',
                color: '(29, 29, 27, 1)'
              }
              break
            default:
              badge = {
                name: '',
                background: '(40, 159, 48, 0.1)',
                color: '(40, 159, 48, 1)'
              }
              break
          }

          return (
            <Badge
              backgroundColorBadge={ badge.background }
              colorBadge={ badge.color }
            >
              {badge.name}
            </Badge>
          )
        },
        sorting: true
      },
      {
        title: t('impacted users'),
        field: 'impactedUsers',
        sorting: true,
        render: (row) => row?.impactedUsers ? formatNumberJs(row?.impactedUsers, 0) : '-'
      }
    ],
    [t]
  )

  const openModal = useCallback((data, readOnly) => {
    createDialog({
      id: 'advertising-register',
      Component: AdvertisingRegisterModal,
      props: {
        name: t('advertising'),
        defaultValues: data,
        reloadDataTable,
        readOnly
      }

    })
  }, [createDialog, reloadDataTable, t])

  const confirmDelete = useCallback(
    (row) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          textWarning: t('are you sure you want to delete this advertising?'),
          onConfirm: () => {
            dispatch(ChatActions.deleteAdvertising(row?.id, () => {
              reloadDataTable()
            }))
          }
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, t]
  )

  const sendAdvertising = useCallback((data) => {
    dispatch(ChatActions.sendAdvertising(data?.id, () => {
      reloadDataTable()
    }))
  }, [dispatch, reloadDataTable])

  const confirmRefuse = useCallback(
    (row) => {
      createDialog({
        id: 'confirm-refuse',
        Component: RefuseModal,
        props: {
          onConfirm: (reason) => {
            dispatch(ChatActions.refuseAdvertising(row?.id, reason, () => {
              reloadDataTable()
            }))
          }
        }
      })
    },
    [createDialog, dispatch, reloadDataTable]
  )

  const actions = useMemo(
    () => [
      (row) => ({
        position: 'row',
        onClick: () => openModal(row, true),
        iconButtonProps: {},
        tooltip: row?.status === 'solicitado' && row?.requestOrganizationId === currentOrganization?.id ? t('edit') : t('view'),
        icon: row?.status === 'solicitado' && row?.requestOrganizationId === currentOrganization?.id  ? () => <Icon size={ 24 } icon={ pencil }  /> : () => <Icon size={ 24 } icon={ eyeOpened }  />
      }),
      (row) => row?.status === 'solicitado' && row?.requestOrganizationId === currentOrganization?.id ? ({
        position: 'row',
        onClick: () => confirmDelete(row),
        iconButtonProps: {},
        tooltip: t('delete'),
        icon: () => <Icon size={ 24 } color={ colors.error } icon={ trash }  />
      }) : null,
      (row) => row?.status === 'solicitado' && userProfileIsSmartcoop ? ({
        position: 'row',
        onClick: () => confirmRefuse(row),
        iconButtonProps: {},
        tooltip:t('refuse'),
        icon: () => <Icon size={ 24 } color={ colors.error } icon={ trash } />
      }) : null,
      (row) => row?.status === 'solicitado' && userProfileIsSmartcoop ? ({
        position: 'row',
        onClick: () => sendAdvertising(row),
        iconButtonProps: {},
        tooltip: t('send'),
        icon: () => <Icon size={ 24 } icon={ send } />
      }) : null
    ],
    [confirmDelete, confirmRefuse, currentOrganization, openModal, sendAdvertising, t, userProfileIsSmartcoop]
  )

  return (
    <Container>
      <FilterRow>
        <div style={ { width: '20vw', marginLeft: 20 } }>
          <Button
            variant="contained"
            color={ colors.secondary }
            onClick={ openModal }
            fullWidth
          >
            {t('new advertisement')}
          </Button>
        </div>
      </FilterRow>
      <DataTable
        columns={ columns }
        data={ getAdvertisements }
        tableRef={ tableRef }
        actions={ actions }
        id="list-advertisements"
      />
    </Container>
  )
}

export default AdvertisingScreen
