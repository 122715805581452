import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  margin: 40px 180px 40px 120px;
  flex-direction: column;
  flex: 1;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const Item = styled.div`
  margin: 20px;
  margin-left: 0;
  margin-bottom: 0;
  width: 20vw;
`

export const Top = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const AvatarContainer = styled.div`
  padding: 20px;
  padding-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AvatarNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  flex: 1;
`

export const ButtonContainer = styled.div`
`

export const UpperRowContainer = styled(RowContainer)`
  margin-bottom: 15px;
  max-width: 30px;
  align-items: center;
`

export const FirstRowContainer = styled(RowContainer)`
  justify-content: flex-start;
`

export const Text = styled.span`
  font-size: 16px;
`

export const Name = styled(Text)`
  font-weight: 700;
  font-size: 17px;
`
