import React, { forwardRef, useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import map from 'lodash/map'

import I18n, { useT } from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import DynamicForm from '@smartcoop/web-components/DynamicForm'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

const CreateSupplierQuotationProposalForm = forwardRef((props, formRef) => {
  const {
    paymentForms,
    productUnit,
    defaultValue,
    onSuccess,
    disabled,
    deliveryFreightValues,
    withoutSubmitButton
  } = props

  const t = useT()

  const fields = useMemo(
    () => ([
      {
        name: '',
        id: 'quantity',
        formFields: [
          {
            name: 'amount',
            label: t('minimum demand'),
            type: 'float',
            unit: productUnit,
            defaultValue: defaultValue.amount,
            disabled: true
          }
        ]
      },
      {
        name: t('unitary values register'),
        subtitle: t('register value considering all incident taxes'),
        id: 'proposalPaymentForms',
        formFields: map(paymentForms, ({ deferredPayment, daysAmount, id }) => ({
          name: id,
          label: `${
            t('{this} value', { this: deferredPayment ? t('deferred') : t('in cash') })
          } ${
            deferredPayment ? `${ daysAmount } ${ t('day', { howMany: 2 }) }` : ''
          }`,
          type: 'float',
          unit: 'R$',
          defaultValue: !isEmpty(defaultValue.paymentForms) ? defaultValue.paymentForms[id] : ''
        }))
      }
    ]),
    [defaultValue, paymentForms, productUnit, t]
  )

  const handleSaveClick = useCallback(
    () => {
      formRef.current.submit()
    },
    [formRef]
  )

  const handleSubmit = useCallback(
    (data) => {
      const paymentIds = keys(data.proposalPaymentForms)
      onSuccess({
        deliveryFreightValues,
        amount: data.quantity.amount,
        proposalPaymentForms: map(paymentIds, (id) => ({
          paymentFormId: id,
          value: data.proposalPaymentForms[id]
        }))
      })
      formRef.current.reset()
    },
    [deliveryFreightValues, formRef, onSuccess]
  )

  return (
    <>
      <DynamicForm
        ref={ formRef }
        onSubmit={ handleSubmit }
        fields={ fields }
        disabled={ disabled }
        withoutSubmitButton={ withoutSubmitButton }
      />

      {!withoutSubmitButton && (
        <ButtonsContainer>
          <Button
            id="web-create-supplier-quotation-proposal-form-button"
            style={ { width: '48%' } }
            onClick={ () => handleSaveClick() }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      )}
    </>
  )
})

CreateSupplierQuotationProposalForm.propTypes = {
  paymentForms: PropTypes.array.isRequired,
  defaultValue: PropTypes.shape({
    amount: PropTypes.string,
    paymentForms: PropTypes.object
  }),
  onSuccess: PropTypes.func,
  deliveryFreightValues: PropTypes.object,
  productUnit: PropTypes.string,
  disabled: PropTypes.bool,
  withoutSubmitButton: PropTypes.bool
}

CreateSupplierQuotationProposalForm.defaultProps = {
  onSuccess: () => {},
  defaultValue: {
    amount: '',
    paymentForms: {}
  },
  productUnit: '',
  disabled: false,
  deliveryFreightValues: {},
  withoutSubmitButton: false
}

export default CreateSupplierQuotationProposalForm
