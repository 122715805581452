import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  background-color: ${ colors.white };
  border-radius: 5px 5px 0 0;
  padding: 10px;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0;
`

export const ManagementName = styled.p`
  margin: 0;
  font-size: 16px;
`

export const DateContent = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
`

export const TextContent = styled.div`
`

export const WheaterManagement = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
  padding: 0 20px 0;
`

export const TextWheater = styled.div`

`

export const TitleWheater = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
`

export const Divider = styled.hr`
border: none;
border-bottom: 0.935252px solid #babaca;
margin: 5px 0 10px 0;
width: 100%;
`

export const DateText = styled.p`
  margin: 0;
  font-size: 16px;
  color: ${ colors.darkGrey };
`

export const TemperatureText = styled.p`
  margin: 0;
  font-weight: 600;
`

export const TemperatureNumber = styled.p`
  margin: 0 3px;
  font-size: 16px;
`

export const Temperature = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100px;
`

export const TitleInfoTemp = styled.div`
  color: ${ colors.darkGrey };
`

export const TextInfoTemp = styled.div`
  display: flex;
  font-size: 16px;
`

export const TemperatureContent = styled.div`

`

export const HumidityText = styled.div`
  p{
    margin: 0;
  }
`

export const PrecipationText = styled.div`
  p{
    margin: 0;
  }
`

export const WindText = styled.div`
  p{
    margin: 0;
  }
`

export const DetailsManagement = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`

export const DetailsText = styled.div`
  margin-bottom: 10px;
    p{
      margin: 0;
    }
`

export const DateSingle = styled.div`
  display: flex;
`

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 4px;
`
