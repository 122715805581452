import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'


export class ConversationModel extends Model {
  static table = 'conversation'

  @field('conversationId')
  conversationId: string

  @field('lastMessage')
  lastMessage: string

  @field('unseenCount')
  unseenCount: number

  @field('createdAt')
  createdAt: number

  @field('photo')
  photo: string

  @field('name')
  name: string

  @field('whoSend')
  whoSend: string

  @field('favorite')
  favorite: boolean

  @field('broadcast')
  broadcast: boolean

  @field('group')
  group: boolean

  @field('contactId')
  contactId: string

  @field('relationalId')
  relationalId: string

  @field('status')
  status: string

  @field('userCode')
  userCode: string

  @field('publicKey')
  publicKey: string

  @field('registry')
  registry: string

  @field('invalidCode')
  invalidCode: boolean

  @field('activeUser')
  activeUser: boolean

  @field('documentFormat')
  documentFormat: string

  @field('isGeolocation')
  isGeolocation: boolean

  @field('status')
  status: string

}
