import * as Yup from 'yup'

import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/isEmpty'

import date from '@smartcoop/forms/validators/date.validator'
import number from '@smartcoop/forms/validators/number.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerMilkDelivery = ({ t }) => Yup.object().shape({
  userStateRegistrationId: flow(
    required({ t })
  )(Yup.string()),

  volumeDate: flow(
    date({ t }),
    required({ t })
  )(Yup.string()),

  volume: flow(
    number({ t }),
    required({ t })
  )(Yup.string()),

  temperature: flow(
  )(Yup.string()),

  condemnedVolume: flow(
    number({ t })
  )(Yup.string()),

  observation: Yup.string().when('condemnedVolume', {
    is: (condemnedVolume) => isEmpty(condemnedVolume),
    then: (Yup.string()),
    otherwise: required({ t }) (Yup.string())
  })
})

export default registerMilkDelivery
