import React, { useCallback, forwardRef } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'


import filterLotSchema from '@smartcoop/forms/schemas/dairyFarm/filterLot.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getLots as getLotsService } from '@smartcoop/services/apis/smartcoopApi/resources/lot'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'

import { Container, ButtonContainer } from './styles'

const FilterLotForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()
  const { id: propertyId } = useSelector(selectCurrentProperty)
  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterLotSchema }
        onSubmit={ handleSubmit }
      >

        <InputSelect
          label={ t('lot code') }
          name="code"
          options={ getLotsService }
          urlParams={ { propertyId } }
          asyncOptionLabelField="code"
          asyncOptionValueField="code"
          defaultValue={ filters.code }
        />

        <InputSelect
          label={ t('name of lot') }
          name="name"
          options={ getLotsService }
          urlParams={ { propertyId } }
          asyncOptionLabelField="name"
          asyncOptionValueField="name"
          defaultValue={ filters.name }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="lot-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterLotForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterLotForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterLotForm
