import React, { useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'

import ceil from 'lodash/ceil'
import filter from 'lodash/filter'
import map from 'lodash/map'

import { useT } from '@smartcoop/i18n'
import { plus, trash } from '@smartcoop/icons'
import useSmartcoopApi from '@smartcoop/services/hooks/useSmartcoopApi'
import { colors } from '@smartcoop/styles'
import { formatNumber } from '@smartcoop/utils/formatters'
import Card from '@smartcoop/web-components/Card'
import Icon from '@smartcoop/web-components/Icon'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import {
  InputsContainer,
  InputItem,
  IconText,
  IconContainer,
  Title,
  Divisor
} from './styles'

const CreateBarterFragment = ({
  orgId,
  title,
  type,
  onResultChange,
  data
}) => {
  const t = useT()

  const { data: requestProducts } = useSmartcoopApi(`/v1/barters/products/organization/${ orgId }?page=1&limit=${ process.env.REACT_APP_FAKE_PAGINATION_SIZE }&q=${ type }`)

  const products = useMemo(
    () => map(requestProducts?.data, (item) => ({ ...item, value: item.productInternalCode, label: item.productName })),
    [requestProducts]
  )

  const handleItemChange = useCallback(
    (externalIndex, externalItem) => {
      onResultChange(
        (old) => ({
          ...old,
          [type]: map(old[type], (item, index) =>
            index === externalIndex
              ? {
                ...item,
                ...externalItem
              }
              : item
          )
        })
      )
    },
    [onResultChange, type]
  )

  const handleAddMoreItems = useCallback(
    () => {
      onResultChange(
        (old) => ({
          ...old,
          [type]: [
            ...old[type],
            []
          ]
        })
      )
    },
    [onResultChange, type]
  )

  const removeItem = useCallback(
    (externalIndex) => {
      onResultChange(
        (old) => ({
          ...old,
          [type]: filter(old[type], (_, index) => index !== externalIndex)
        })
      )
    },
    [onResultChange, type]
  )

  const handleOnChange = useCallback( (id, value, index) => {
    if(value === undefined){
      handleItemChange(index, { productInternalCode: '', unit: '', quantity: '' })
    }else{
      const { unitOfMeasuresForConversion, conversionFactor, measureUnit } = value
      handleItemChange(index, { productInternalCode: id })
      handleItemChange(index, {
        unit: conversionFactor === 1
          ?
          measureUnit
          :
          `${ unitOfMeasuresForConversion === undefined ? '' : unitOfMeasuresForConversion } ${ t('of') } ${ formatNumber(ceil(conversionFactor, 2)) } ${ measureUnit }` }
      )
    }
  },[handleItemChange, t])

  return (
    <Card
      title={
        <Title>{title}</Title>
      }
      headerTitleProps={ {
        style: {
          textAlign: 'left',
          paddingLeft: 20        }
      } }
      style={ { margin: '10px 0 20px 0' } }
      cardStyle={ { width: '100%', paddingTop: 0 } }
    >
      <Divisor />
      {map(data, ({ productInternalCode, unit, quantity }, index) => (
        <InputsContainer key={ index }>
          <InputItem>
            <InputSelect
              options={ products }
              value={ productInternalCode }
              onChange={
                (id, value) => handleOnChange(id, value, index)
              }
              label={ t('product') }
              detached
            />
          </InputItem>

          <InputItem>
            <InputText
              value={ unit }
              onChange={ ({ target: { value } }) => handleItemChange(index, { unit: value }) }
              label={ t('unit') }
              fullWidth
              disabled
              detached
            />
          </InputItem>

          <InputItem>
            <InputText
              value={ quantity }
              onChange={ ({ target: { value } }) => handleItemChange(index, { quantity: value }) }
              label={ t('quantity') }
              fullWidth
              detached
            />
          </InputItem>

          <InputItem
            style={ {
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 15,
              padding: 0
            } }
          >
            <button
              type="button"
              style={ {
                cursor: 'pointer',
                border: 'none',
                backgroundColor: 'transparent'
              } }
              onClick={ () => removeItem(index) }
            >
              <Icon style={ { padding: 0 } } size={ 16 } icon={ trash } color={ colors.red }/>
            </button>
          </InputItem>
        </InputsContainer>
      ))}

      <IconContainer style={ { cursor: 'pointer' } } onClick={ handleAddMoreItems }>
        <Icon style={ { paddingRight: 6 } }icon={ plus } size={ 13 }/>
        <IconText>add product</IconText>
      </IconContainer>
    </Card>
  )
}

CreateBarterFragment.propTypes = {
  orgId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onResultChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
}

export default CreateBarterFragment
