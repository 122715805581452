import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${ colors.backgroundHtml };
  z-index: 5;
`
