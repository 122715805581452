import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  min-width: 500px;
`

export const FormContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 20px;
    flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
`

export const ButtonContainer = styled.div`
  width: 80px;
`
export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Label = styled(Typography)`
  font-size: ${ `${ fonts.fontSize.S }px` };
  font-family: ${ fonts.fontFamilyMontserrat };
  margin-bottom: 10px;
  font-weight: ${ fonts.fontWeight.semiBold };
`
