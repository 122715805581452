
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

import { secondary, lightYellow } from '@smartcoop/styles/colors'

const StyledSwitch = withStyles({
  switchBase: {
    color: secondary,
    '&$checked': {
      color: secondary
    },
    '&$checked + $track': {
      backgroundColor: lightYellow
    }
  },
  checked: {},
  track: {}
})(Switch)

export default StyledSwitch
