export const ROUTES = {
  CONVERSATION: '/v2/chat/conversation-list',
  MESSAGE: '/v2/chat/load-messages',
  MEMBERS: '/v1/conversation/conversation-members',
  CONTACT: '/v1/contact/find?date=:date',
  SEND_MESSAGE: '/v1/chat/send',
  UPDATE_VISUALIZED: '/v1/chat/registry/read-status',
  UPDATE_FAVORITE: '/v1/conversation/favorite',
  DELETE_MESSAGE: '/v1/chat/registry/delete-message',
  DELETE_CONVERSATION: '/v1/conversation',
  SEND_FILE: '/v1/chat/docs',
  CREATE_CONVERSATION: 'v1/conversation',
  REMOVE_GROUP_MEMBER: '/v1/conversation/remove-group-member',
  REMOVE_BROADCAST_MEMBER: '/v1/conversation/remove-broadcast-member',
  EXIT_GROUP: '/v1/conversation/member-leaving-group',
  TURN_ADMIN: '/v1/conversation/update-group-admin',
  ADD_GROUP_MEMBERS: '/v1/conversation/add-group-member',
  ADD_BROADCAST_MEMBERS: '/v1/conversation/add-broadcast-member',
  UPDATE_GROUP_NAME: '/v1/conversation',
  UPDATE_GROUP_IMAGE: '/v1/conversation/update-photo',
  CLEAR_CHAT_HISTORY: '/v1/chat/registry/clear-message-history'
}
