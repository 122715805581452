import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

/* Initial State */
const INITIAL_STATE = {
  orders: [],
  error: null,
  products: [],
  batches: [],
  settlementDates: []
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  saveSalesOrder: ['order', 'onSuccess', 'onError'],
  saveOfflineSalesOrder: ['order', 'onSuccess', 'onError'],

  loadSalesOrders: ['params', 'onSuccess', 'onError'],
  loadSalesOrdersSuccess: ['orders', 'page', 'onSuccess'],

  loadSalesOrdersProducts: ['params', 'onSuccess', 'onError'],
  loadSalesOrdersProductsSuccess: ['products', 'onSuccess'],

  loadSalesOrdersBatches: ['params', 'onSuccess', 'onError'],
  loadSalesOrdersBatchesSuccess: ['batches', 'onSuccess'],

  loadSalesOrdersSettlementDates: ['params', 'onSuccess', 'onError'],
  loadSalesOrdersSettlementDatesSuccess: ['settlementDates', 'onSuccess'],

  deleteOfflineSalesOrders: ['saleOrderId', 'onSuccess', 'onError'],
  deleteSalesOrders: ['saleOrderId', 'onSuccess', 'onError'],

  validationSignatureEletronic: ['password', 'onSuccess', 'onError'],

  resetSalesOrders: [],

  salesOrdersError: ['error']
})

markActionsOffline(Creators, [
  'saveSalesOrder',
  'deleteSalesOrders'
])

const loadSalesOrders = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error
})

const loadSalesOrdersSuccess = (state = INITIAL_STATE, { orders, page }) => ({
  ...state,
  orders: page === 1 ? orders : [
    ...state.orders,
    ...orders
  ]
})

const loadSalesOrdersProductsSuccess = (state = INITIAL_STATE, { products }) => ({
  ...state,
  products
})

const loadSalesOrdersBatchesSuccess = (state = INITIAL_STATE, { batches }) => ({
  ...state,
  batches
})

const loadSalesOrdersSettlementDatesSuccess = (state = INITIAL_STATE, { settlementDates }) => ({
  ...state,
  settlementDates
})

const salesOrdersError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const resetSalesOrders = (state = INITIAL_STATE) => ({
  ...state,
  orders: INITIAL_STATE.salesOrders
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_SALES_ORDERS]: loadSalesOrders,
  [Types.LOAD_SALES_ORDERS_SUCCESS]: loadSalesOrdersSuccess,

  [Types.LOAD_SALES_ORDERS_PRODUCTS_SUCCESS]: loadSalesOrdersProductsSuccess,

  [Types.LOAD_SALES_ORDERS_BATCHES_SUCCESS]: loadSalesOrdersBatchesSuccess,

  [Types.LOAD_SALES_ORDERS_SETTLEMENT_DATES_SUCCESS]: loadSalesOrdersSettlementDatesSuccess,

  [Types.RESET_SALES_ORDERS]: resetSalesOrders,

  [Types.SALES_ORDERS_ERROR]: salesOrdersError,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as SalesOrdersTypes,
  Creators as SalesOrdersActions
}
