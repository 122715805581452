// import indexOf from 'lodash/indexOf'
// import first from 'lodash/first'
import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import map from 'lodash/map'

import { AuthenticationTypes } from '../authentication'
import { makeActionFree } from '../stores'

/*
 * Creating Initial state and Redux handlers
 * */

const INITIAL_STATE = {
  token: null,
  vapidKeyStarted: false,
  lastNotifications: [],
  allNotifications: [],
  unreadCounter: 0,
  hoursPendingAttention: 1,
  firebaseInitialized: false,
  isChatModule: false,
  error: null
}

/*
 * Creating action types & creators
 * */
const { Types, Creators } = createActions({
  // initFirebaseCloudMessaging: [],
  initCloudMessaging: [],
  setCloudMessagingToken: ['token'],
  setCloudMessagingTokenSuccess: [],
  unsetCloudMessagingToken: [],
  unsetCloudMessagingTokenSuccess: [],

  subscribeUserNotifications: [],
  unsubscribeUserNotifications: [],
  playNotificationSound: [],
  getLastNotifications: ['notifications'],
  getAllNotifications: ['firstPage', 'onSuccess', 'onError'],
  getAllNotificationsSuccess: ['firstPage', 'notifications', 'lastVisible'],
  showNewNotifications: ['snapshots'],
  setUnreadNotificationsCounter: ['unreadCounter'],
  getHoursPendingAttention: [],
  getHoursPendingAttentionSuccess: ['hoursPendingAttention'],

  readAllNotifications: ['isFromChat'],
  readAllNotificationsRequest: ['isFromChat'],

  setIsChatModule: ['isChatModule'],

  readNotification: ['notificationId'],
  readNotificationRequest: ['notificationId'],

  messagingError: ['error']
})

makeActionFree('messagingError')
makeActionFree('initCloudMessaging')
makeActionFree('setCloudMessagingToken')
makeActionFree('setCloudMessagingTokenSuccess')
makeActionFree('unsetCloudMessagingToken')
makeActionFree('unsetCloudMessagingTokenSuccess')
makeActionFree('subscribeUserNotifications')
makeActionFree('unsubscribeUserNotifications')
makeActionFree('setIsChatModule')

/**
 * Assigning offline actions
 */
markActionsOffline(Creators, [
  'subscribeUserNotifications',
  'readAllNotificationsRequest',
  'readNotificationRequest'
])

const initCloudMessaging = (state = INITIAL_STATE) => ({
  ...state,
  vapidKeyStarted: true,
  firebaseInitialized: true
})

const setCloudMessagingToken = (state = INITIAL_STATE, { token }) => ({
  ...state,
  token
})

const setIsChatModule = (state = INITIAL_STATE, { isChatModule }) => ({
  ...state,
  isChatModule
})


const getLastNotifications = (state = INITIAL_STATE, { notifications }) => ({
  ...state,
  lastNotifications: notifications
})

const getAllNotificationsSuccess = (state = INITIAL_STATE, { firstPage, notifications, lastVisible }) => ({
  ...state,
  allNotifications: firstPage ? notifications : [
    ...state.allNotifications,
    ...notifications
  ],
  lastVisible
})

const setUnreadNotificationsCounter = (state = INITIAL_STATE, { unreadCounter }) => ({
  ...state,
  unreadCounter,
  allNotifications: unreadCounter
    ? state.allNotifications
    : map(state.allNotifications, (notification) => ({
      ...notification,
      read: true
    }))
})

const readAllNotifications = (state = INITIAL_STATE) => ({
  ...state,
  unreadCounter: 0,
  lastNotifications: map(state.lastNotifications, (notification) => ({
    ...notification,
    read: true
  })),
  allNotifications: map(state.allNotifications, (notification) => ({
    ...notification,
    read: true
  }))
})

const readNotification = (state = INITIAL_STATE, { notificationId }) => ({
  ...state,
  allNotifications: map(state.allNotifications, (notification) => {
    if (notification.id === notificationId) {
      return {
        ...notification,
        read: true
      }
    }
    return notification
  })
})

const getHoursPendingAttentionSuccess = (state = INITIAL_STATE, { hoursPendingAttention }) => ({
  ...state,
  hoursPendingAttention
})

const messagingError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = (state = INITIAL_STATE) => ({
  ...INITIAL_STATE,
  vapidKeyStarted: state.vapidKeyStarted,
  token: state.token,
  firebaseInitialized: state.firebaseInitialized
})

const firebaseLogout = (state = INITIAL_STATE) => ({
  ...state,
  token: INITIAL_STATE.token,
  firebaseInitialized: INITIAL_STATE.firebaseInitialized
})

/*
 * Creating reducer
 * */
export default createReducer(INITIAL_STATE, {
  [Types.INIT_CLOUD_MESSAGING]: initCloudMessaging,
  [Types.SET_CLOUD_MESSAGING_TOKEN]: setCloudMessagingToken,
  [Types.GET_LAST_NOTIFICATIONS]: getLastNotifications,
  [Types.GET_ALL_NOTIFICATIONS_SUCCESS]: getAllNotificationsSuccess,
  [Types.SET_UNREAD_NOTIFICATIONS_COUNTER]: setUnreadNotificationsCounter,
  [Types.READ_ALL_NOTIFICATIONS]: readAllNotifications,
  [Types.READ_NOTIFICATION]: readNotification,
  [Types.GET_HOURS_PENDING_ATTENTION_SUCCESS]: getHoursPendingAttentionSuccess,
  [Types.MESSAGING_ERROR]: messagingError,
  [Types.SET_IS_CHAT_MODULE]: setIsChatModule,
  [AuthenticationTypes.LOGOUT_SUCCESS]: logout,
  [AuthenticationTypes.FIREBASE_LOGOUT_SUCCESS]: firebaseLogout
})

export {
  Types as MessagingTypes,
  Creators as MessagingActions
}
