import React, { useRef, useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import map from 'lodash/map'

import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import CreateProductGroupForm from '@smartcoop/web-containers/forms/shoppingPlatform/productGroup/CreateProductGroupForm'

const CreateProductGroupScreen = () => {
  const registerProductGroupRef = useRef(null)
  const history = useHistory()
  const location = useLocation()
  const snackbar = useSnackbar()
  const t = useT()

  const productGroup = useMemo(
    () => location?.state?.productGroup ?? {}, [location]
  )

  const defaultValues = useMemo(
    () => ({
      ...productGroup,
      name: productGroup?.name ?? '',
      description: productGroup?.description ?? '',
      cropsId: map(productGroup?.crops ?? [], (crop) => (crop.id))
    }), [productGroup]
  )

  const isEditing = useMemo(() =>  location.pathname.includes('edit'),[location.pathname])

  const handleClose = useCallback(() => history.goBack(), [history])

  const handleSuccess = useCallback(
    () => {
      snackbar.success(
        t(`your {this} was ${  isEditing ? 'edited' :'registered' }`, {
          howMany: 1,
          gender: 'male',
          this: t('product group')
        })
      )
      handleClose()
    },
    [handleClose, isEditing, snackbar, t]
  )

  return (
    <CreateProductGroupForm
      ref={ registerProductGroupRef }
      defaultValues={ defaultValues }
      isEditing={ isEditing }
      onSuccess={ handleSuccess }
      onCancel={ handleClose }
    />
  )
}

export default CreateProductGroupScreen
