import api from '../api'

export const postPropertiesAssets = (dto) =>
  api.post('/v1/properties-assets', dto)

export const getPropertiesAssets = (params) =>
  api.get('/v1/properties-assets', { params })

export const getPropertiesAssetsMovements = (params) =>
  api.get('/v1/properties-assets-movements', { params })

export const getPropertiesAssetsByProperty = (params) =>
  api.get(`/v1/properties-assets/property/${ params?.propertyId }`, { params })

export const putPropertiesAssets = async (body) => api.patch('/v1/properties-assets', body)

export const deletePropertiesAssets = async (propertyAssetId) => api.delete(`/v1/properties-assets/${ propertyAssetId }`)

export const postPropertyAssetsMovementsService = (dto) =>
  api.post('/v1/properties-assets-movements', dto)

export const postPropertyAssetsWriteOff = (params) =>
  api.post('/v1/properties-assets-movements/write-off', params)

export const postPropertyAssetsListService = (dto) => api.post('/v1/properties-assets/list', dto)

export const getDepreciationTypes = () => api.get('/v1/properties-assets/types')
