import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import Button from '@smartcoop/web-components/Button'
import Card from '@smartcoop/web-components/Card'

export const Container = styled.div`
  margin: 30px;
`

export const CardItemTitle = styled(Card).attrs(props => ({
  ...props,
  cardStyle: {
    width: '100%',
    padding: 0,
    ...(props.cardStyle || {})
  },
  headerTitleProps: {
    align: 'left',
    style: {
      fontWeight: fonts.fontWeight.bold
    }
  },
  headerStyle: {
    padding: '10px 15px',
    borderBottom: `2px solid ${ colors.lightGrey }`,
    ...(props.headerStyle || {})
  },
  childrenStyle: {
    padding: '5px 20px 17px',
    ...(props.childrenStyle || {})
  }
}))
`
  margin-bottom: 0;
  margin-top: 7.5px;
  width: 100%;
`

export const Buttons = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
`

export const Tab = styled(Button)`
  margin-right: 10px;
  border-bottom: 4px solid black;
  border-radius: 5px;
  border-color: ${ props => props.isActive ? colors.black : 'transparent' };
`

export const Title = styled(Typography)`
  font-size: 18px;
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: 700;
  color: ${ colors.black };
`

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
`
export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Half = styled.div`
  display: flex;
`

export const Content = styled(Grid).attrs({
  container: true
})``

export const CardsContainer = styled(Grid).attrs({
  item: true,
  xs: 12,
  sm: 12,
  md: 12,
  lg: 4,
  xl: 4
})`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

export const DeliveryChartContainer = styled(Grid).attrs({
  item: true,
  xs: 12,
  sm: 12,
  md: 12,
  lg: 8,
  xl: 8
})`
  display: flex;
`
export const Line = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`

export const QualityChartContainer = styled(Grid).attrs({
  item: true,
  xs: 12,
  sm: 12,
  md: 12,
  lg: 7,
  xl: 7
})`
  display: flex;
`

export const PricesChartContainer = styled(Grid).attrs({
  item: true,
  xs: 12,
  sm: 12,
  md: 12,
  lg: 5,
  xl: 5
})`
  display: flex;
`

export const GridIndicators = styled.div`
  width: 100%;
  padding-top: 9px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  height: 100%;

  @media (max-width: 715px) {
    grid-template-columns: 1fr;
  }
`

export const inputSearchTheme = (theme) => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff'
      }
    }
  }
})
