import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import { fileDocument, fileImage, fileMusic, fileVideo } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import { AllowedAudioTypesExtensions, AllowedDocumentTypesEnum, AllowedImageTypesExtensions, AllowedSizeFile, AllowedVideoTypesExtensions } from '@smartcoop/utils/constants'
import Icon from '@smartcoop/web-components/Icon'
import AttachButtonComponent from '@smartcoop/web-components/IconButton/AttachButton'
import InputFile from '@smartcoop/web-components/InputFile'
import Popover from '@smartcoop/web-components/Popover'

import { ButtonContainer } from './styles'

const AttachButton = ({ onUploadFile, disabled }) => {
  const popoverRef = useRef()

  return (
    <Popover
      disabled
      ref={ popoverRef }
      popoverId="property-actions"
      Component={ AttachButtonComponent }
      ComponentProps={ {
        color: colors.black,
        edge: 'end',
        iconColor: colors.black,
        disabled
      } }
    >
      <ButtonContainer>
        <InputFile
          maxFileSize={ AllowedSizeFile }
          idInput="contained-button-file-image"
          onChange={ (event) => onUploadFile(event.target.files, 'image') }
          inputProps={ { accept: AllowedImageTypesExtensions.join(', '), multiple: true } }
          buttonProps={ {
            id: 'add-image',
            variant: 'outlined',
            style: {
              minWidth: 0,
              width: '100%',
              border: 0,
              justifyContent: 'flex-start'
            }
          } }
        >
          <Icon icon={ fileImage } style={ { marginRight: 5 } } color={ colors.primary } size={ 25 } />
          <I18n>send image</I18n>
        </InputFile>
        <InputFile
          maxFileSize={ AllowedSizeFile }
          idInput="contained-button-file-video"
          onChange={ (event) => onUploadFile(event.target.files, 'video') }
          inputProps={ { accept: AllowedVideoTypesExtensions.join(', '), multiple: true } }
          buttonProps={ {
            id: 'add-video',
            variant: 'outlined',
            style: {
              minWidth: 0,
              width: '100%',
              border: 0,
              justifyContent: 'flex-start'
            }
          } }
        >
          <Icon icon={ fileVideo } style={ { marginRight: 5 } } color={ colors.primary } size={ 25 } />
          <I18n>send video</I18n>
        </InputFile>
        <InputFile
          maxFileSize={ AllowedSizeFile }
          idInput="contained-button-file-audio"
          onChange={ (event) => onUploadFile(event.target.files, 'audio') }
          inputProps={ { accept: AllowedAudioTypesExtensions.join(', '), multiple: true } }
          buttonProps={ {
            id: 'add-audio',
            variant: 'outlined',
            style: {
              minWidth: 0,
              width: '100%',
              border: 0,
              justifyContent: 'flex-start'
            }
          } }
        >
          <Icon icon={ fileMusic } style={ { marginRight: 5 } } color={ colors.primary } size={ 25 } />
          <I18n>send audio</I18n>
        </InputFile>
        <InputFile
          maxFileSize={ AllowedSizeFile }
          idInput="contained-button-file-document"
          onChange={ (event) => onUploadFile(event.target.files, 'document') }
          inputProps={ { accept: AllowedDocumentTypesEnum.join(', '), multiple: true } }
          buttonProps={ {
            id: 'add-documents',
            variant: 'outlined',
            style: {
              minWidth: 0,
              width: '100%',
              border: 0,
              justifyContent: 'flex-start'
            }
          } }
        >
          <Icon icon={ fileDocument } style={ { marginRight: 5 } } color={ colors.primary } size={ 25 } />
          <I18n>attach documents</I18n>
        </InputFile>
      </ButtonContainer>
    </Popover>
  )
}

AttachButton.propTypes = {
  onUploadFile: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

AttachButton.defaultProps = {
  disabled: false
}

export default AttachButton
