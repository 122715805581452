import React, { useCallback, forwardRef } from 'react'
import { useDispatch } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import registerAmountPaid from '@smartcoop/forms/schemas/shoppingPlatform/order/registerAmountPaid.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { ProductsPriceHistoryActions } from '@smartcoop/stores/productsPriceHistory'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputUnit from '@smartcoop/web-components/InputUnit'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container, TextDate } from './styles'

const RegisterAmountPaidForm = forwardRef((props, formRef) => {
  const { style, onSuccess, productId, productUnit } = props
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()


  const handleSubmitPriceHistory = useCallback(
    (data) => {
      dispatch(ProductsPriceHistoryActions.saveProductsPriceHistory(
        {
          productsPriceHistory: {
            date: moment(data.payDay).format(momentBackDateFormat),
            price: data.unitPrice,
            unit: productUnit
          },
          productId
        }
      ))
      onSuccess()
    },
    [dispatch, onSuccess, productId, productUnit]
  )

  return (
    <Container>
      <Form
        style={ { ...style } }
        ref={ formRef }
        schemaConstructor={ registerAmountPaid }
        onSubmit={ handleSubmitPriceHistory }
      >
        <TextDate>
          <I18n>enter a price paid for the purchase of this product</I18n>
        </TextDate>
        <InputDate
          name="payDay"
          label={ t('date of purchase') }
          pickerProps={
            {
              maxDate: moment().format()
            }
          }
          fullWidth
        />
        <InputUnit
          // maxLength={ 6 }
          label={ t('unit price') }
          name="unitPrice"
          unit={ productUnit }
          type="float"
          fullWidth
        />

        <ButtonsContainer style={ { paddingTop: 10 } }>
          <Button
            id="submit-crop-management-form"
            onClick={ () => formRef.current.submit() }
            style={ { justifySelf: 'center' } }
          >
            <I18n>register</I18n>
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  )
})

RegisterAmountPaidForm.propTypes = {
  style: PropTypes.object,
  onSuccess: PropTypes.func,
  productId: PropTypes.string,
  productUnit: PropTypes.string
}

RegisterAmountPaidForm.defaultProps = {
  style: {},
  onSuccess: () => {},
  productId: null,
  productUnit: 'kg'
}

export default RegisterAmountPaidForm
