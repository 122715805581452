import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const propertyActivity = ({ t }) => Yup.object().shape({
  activities: flow(
    required({ t })
  )(Yup.string())
})

export default propertyActivity
