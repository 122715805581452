import React from 'react'

import PropTypes from 'prop-types'

import Modal from '@smartcoop/web-components/Modal'

import { Image } from './styles'

const ImageDetailModal = ({ id, open, imageSrc, imageName }) => (
  <Modal
    id={ id }
    open={ open }
    escape
    hideHeader
    maxWidth="sm"
    fullWidth
    disableFullScreen
    contentContainerStyle={ { padding: 10 } }
  >
    <Image src={ imageSrc } alt={ imageName } />
  </Modal>
)

ImageDetailModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageName: PropTypes.string
}

ImageDetailModal.defaultProps = {
  imageName: 'Imagem Smartcoop'
}

export default ImageDetailModal
