import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'


export class UserModel extends Model {
  static table = 'user'

  @field('userId')
  userId: string

  @field('userCode')
  userCode: string

  @field('name')
  name: string

  @field('profilePhoto')
  profilePhoto: string

  @field('publicKey')
  publicKey: string

  @field('privateKey')
  privateKey: string

  @field('profiles')
  profiles: string
}
