import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { toNumber } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { useT } from '@smartcoop/i18n'
import { wirelessMarker } from '@smartcoop/icons'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { WeatherStationsActions } from '@smartcoop/stores/weatherStations'
import { selectWeatherStations } from '@smartcoop/stores/weatherStations/selectorWeatherStations'
import { findMapZoom, getPolygonCenter } from '@smartcoop/utils/maps'
import Maps from '@smartcoop/web-components/Maps'
import Control from '@smartcoop/web-components/Maps/components/Control'
import PinMarker from '@smartcoop/web-components/Maps/markers/PinMarker'
import WeatherStationResumeFragment from '@smartcoop/web-containers/fragments/WeatherStationResumeFragment'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'

const WeatherStationMapScreen = () => {
  const t = useT()
  const mapRef = useRef(null)

  const weatherStations = useSelector(selectWeatherStations)
  const currentOrganization = useSelector(selectCurrentOrganization)
  const [currentId, setCurrentId] = useState('')

  const dispatch = useCallback(useDispatch(), [])

  const allPolygonCoordinates = useMemo(
    () => map(
      weatherStations,
      weatherStation => [ toNumber(weatherStation?.geolocalization?.latitude), toNumber(weatherStation?.geolocalization?.longitude)]
    ),
    [weatherStations]
  )

  const mapRegion = useMemo(
    () =>
      !isEmpty(allPolygonCoordinates)
        ? getPolygonCenter(allPolygonCoordinates)
        : undefined,
    [allPolygonCoordinates]
  )

  const mapZoom = useMemo(
    () =>
      !isEmpty(allPolygonCoordinates)
        ? findMapZoom(allPolygonCoordinates)
        : undefined,
    [allPolygonCoordinates]
  )

  const resetCurrentStation = useCallback(
    () => dispatch( WeatherStationsActions.resetCurrentWeatherStation()) ,
    [dispatch])

  const loadCurrentStation = useCallback(
    (weatherStationId) => dispatch( WeatherStationsActions.loadCurrentWeatherStation(weatherStationId)),
    [dispatch])

  const loadWeatherStations = useCallback(
    () => dispatch( WeatherStationsActions.loadWeatherStations()),
    [dispatch])


  useEffect(() => {
    loadWeatherStations()
  }, [dispatch, currentOrganization, loadWeatherStations])

  return (
    <SplitedScreenLayout
      withoutGoBack
      withoutDivider
      title={ { name: t('field', { howMany: 2 }) } }
      divRightStyle={ { padding: 0 } }
      rightChildren={
        <>
          <Maps
            ref={ mapRef }
            region={ mapRegion }
            zoom={ mapZoom }
            layer="google"
            style={ { flex: 1, overflow: 'none' } }
            cursor="crosshair"
          >
            <Control position="topcenter" />
            {!isEmpty(weatherStations) && (
              map(weatherStations, weatherStation => (
                weatherStation.active && (
                  <PinMarker
                    key={ weatherStation.agrosmartId }
                    onClosePopup={ resetCurrentStation }
                    onOpenPopup={ () => loadCurrentStation(weatherStation.id) }
                    coordinate={ {
                      latitude: toNumber(weatherStation.geolocalization.latitude),
                      longitude: toNumber(weatherStation.geolocalization.longitude)
                    } }
                    customIcon={ wirelessMarker }
                    onClick={ () => setCurrentId(weatherStation.id) }
                    size={ 28 }
                    iconAnchor={ [
                      13,
                      40
                    ] }
                    popupProps={ { minWidth: 360 } }
                  >
                    <WeatherStationResumeFragment
                      stationName={ weatherStation.name }
                      stationId={ weatherStation.id }
                      weatherStationId={ currentId }
                    />
                  </PinMarker>
                )
              ))
            )}
          </Maps>
        </>
      }
    />
  )
}

export default WeatherStationMapScreen
