import { conversationService } from '@smartcoop/database/services/conversationService'
import { messageService } from '@smartcoop/database/services/messageService'
import { database } from '@smartcoop/database/web-database'
import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'

export function useSendMessage(conversation, localUser) {
  const t = useT()
  const snackbar = useSnackbar()

  const getConversationDto = () => ({
    id: conversation.relationalId,
    userCodes: [
      {
        userId: localUser?.userId,
        code: localUser?.userCode
      },
      {
        userId: conversation?.contactId,
        code: conversation?.userCode
      }
    ]
  })

  const sendMessageToGroup = async (message) => messageService(database)
    .sendMessageInGroup(
      { message, senderId: localUser?.userId },
      conversation,
      { isBroadcast: conversation.broadcast }
    )

  const sendMessageToContact = async (message) => {
    // Precisa criar a conversa se ainda não existir
    if (!conversation.conversationId && !conversation.group && !conversation.broadcast) {
      const dto = getConversationDto()
      await conversationService(database).createConversation(dto)
    }

    await messageService(database)
      .send({
        senderId: localUser.userId,
        senderCode: localUser.userCode,
        senderPublicKey: localUser.publicKey,
        receiverId: conversation.contactId,
        receiverCode: conversation.userCode,
        receiverPublicKey: conversation.publicKey,
        message: message?.trim()
      })
  }

  const sendMessage = async (message, callback) => {
    try {
      if (conversation.group) {
        await sendMessageToGroup(message)
        return
      }

      if (conversation.broadcast) {
        await sendMessageToGroup(message)
        return
      }

      await sendMessageToContact(message)
    } catch(error) {
      snackbar.error(t('an error occurred while sending the message'))
    } finally {
      callback()
    }
  }


  return { sendMessage }
}
