import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'

import { isEmpty } from 'lodash'

import I18n, { useT } from '@smartcoop/i18n'
import { getAccountSercuritiesMovement } from '@smartcoop/services/apis/smartcoopApi/resources/securitiesMovement'
import { useSnackbar } from '@smartcoop/snackbar'
import { AccountActions } from '@smartcoop/stores/account'
import { selectCurrentAccount } from '@smartcoop/stores/account/selectorAccount'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { SecuritiesMovementActions } from '@smartcoop/stores/securitiesMovement'
import { colors } from '@smartcoop/styles'
import { momentBackDateTimeFormat, momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import DateTabs from '@smartcoop/web-components/DateTabs'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import AccountBalanceListFragment from '@smartcoop/web-containers/fragments/commercialization/AccountBalanceListFragment'
import SecuritiesMovementListFragment from '@smartcoop/web-containers/fragments/commercialization/SecuritiesMovementListFragment'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'

import {
  Container,
  Row,
  ButtonContainer,
  AccountBalanceContainer
} from './styles'

const SecuritiesMovementListScreen = () => {
  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()

  const currentAccount = useSelector(selectCurrentAccount)
  const currentOrganization = useSelector(selectCurrentOrganization)

  const [mounted, setMounted] = useState(false)
  const [listType, setListType] = useState('receivable')
  const [initialDate, setInitialDate] = useState()
  const [filterDate, setFilterDate] = useState(moment().format('YYYY-MM'))
  const [filterShowBy, setFilterShowBy] = useState('transactionDate')

  const urlParams = useMemo(
    () => ({
      accountId: currentAccount?.id,
      organizationId: currentOrganization?.id
    }),
    [currentAccount, currentOrganization]
  )

  const queryParams = useMemo(
    () => ({ type: listType, date: filterDate, showBy: filterShowBy, limit: 99999 }),
    [filterDate, filterShowBy, listType]
  )

  const changeListType = useCallback(
    (type) => {
      dispatch(AccountActions.loadAccounts())
      setListType(type)
    },
    [dispatch]
  )

  const changeAccount = useCallback(
    () => {
      dispatch(AccountActions.loadAccounts())
      dispatch(
        SecuritiesMovementActions.loadSecuritieMovementInitialDate(
          {},
          (value) => {
            setInitialDate(value)
          }
        )
      )
    },
    [dispatch]
  )

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      dispatch(
        SecuritiesMovementActions.loadSecuritieMovementInitialDate(
          {},
          (value) => {
            setInitialDate(value)
          },
          () => {
            snackbar.error(t('This account does not have securities movements'))
          }
        )
      )
    }
  }, [dispatch, mounted, snackbar, t])

  useEffect(
    () => () => {
      dispatch(AccountActions.resetCurrentAccount())
    },
    [dispatch]
  )

  return (
    <SplitedScreenLayout
      title={ {
        name: t('home')
      } }
      divLeftStyle={ {
        borderRight: `1px solid ${ colors.lightGrey }`
      } }
      leftChildren={
        <AccountBalanceContainer>
          <AccountBalanceListFragment
            onClick={ changeAccount }
            withoutHeader
            clickable
            withCreditLimit
          />
        </AccountBalanceContainer>
      }
      divRightStyle={ {
        backgroundColor: colors.white,
        width: '100%',
        height: '100%',
        padding: 0
      } }
      rightChildren={
        <Container>
          <Row style={ { fontStyle: 'italic', justifyContent: 'flex-end', marginBottom: 5, paddingRight: 10 } }>
            {!isEmpty(currentAccount?.lastUpdate) && `${ t('data updated the last time at') } ${ moment(currentAccount?.lastUpdate, momentBackDateTimeFormat).format(momentFriendlyDateTimeFormat) }`}
          </Row>
          <Row style={ { marginLeft: 10 } }>
            <RadioGroup
              label={ `${ t('show by') }:` }
              options={ [
                {
                  label: t('launch date'),
                  value:'transactionDate'
                },
                {
                  label: t('due date'),
                  value:'dueDate'
                }
              ] }
              variant="row"
              style={ { marginBottom: 20 } }
              value={ filterShowBy }
              onChange={ (e) => setFilterShowBy(e.value) }
              detached
            />
          </Row>
          <Row>
            <ButtonContainer>
              <Button
                id="receive"
                onClick={ () => changeListType('receivable') }
                variant="text"
                style={ {
                  fontSize: 14,
                  flex: 1,
                  fontWeight: 'bold',
                  backgroundColor: 'transparent',
                  borderBottom: `${ listType === 'receivable' ? 4 : 1 }px solid`,
                  borderColor: `${
                    listType === 'receivable' ? colors.black : colors.lightGrey
                  }`,
                  color: `${
                    listType === 'receivable' ? colors.black : colors.grey
                  }`,
                  borderRadius: 0
                } }
              >
                <I18n>titles to receive</I18n>
              </Button>
              <Button
                id="to-pay"
                onClick={ () => changeListType('payable') }
                variant="text"
                style={ {
                  fontSize: 14,
                  flex: 1,
                  fontWeight: 'bold',
                  backgroundColor: 'transparent',
                  borderBottom: `${ listType === 'payable' ? 4 : 1 }px solid`,
                  borderColor: `${
                    listType === 'payable' ? colors.black : colors.lightGrey
                  }`,
                  color: `${
                    listType === 'payable' ? colors.black : colors.grey
                  }`,
                  borderRadius: 0
                } }
              >
                <I18n>titles to pay</I18n>
              </Button>
            </ButtonContainer>
          </Row>

          {!isEmpty(initialDate) && (
            <DateTabs
              tabsQuantityToBeShown={ 6 }
              initialDate={ moment(initialDate, momentBackDateTimeFormat) }
              endDate={ filterShowBy === 'transactionDate' ? moment() : moment().add(8, 'months') }
              onChange={ (value) => {
                setFilterDate(value.format('YYYY-MM'))
              } }
              currentYearFormat="MMM"
              style={ { padding: '10px 0 10px' } }
              defaultValue={ moment() }
              key={ initialDate }
            />
          )}
          {!isEmpty(filterDate) && (
            <SecuritiesMovementListFragment
              service={ getAccountSercuritiesMovement }
              urlParams={ urlParams }
              queryParams={ queryParams }
              listType={ listType }
              hidePagination
            />
          )}
        </Container>
      }
    />
  )
}

export default SecuritiesMovementListScreen
