import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import code from '@smartcoop/forms/validators/code.validator'
import required from '@smartcoop/forms/validators/required.validator'

const resetCode = ({ t }) => Yup.object().shape({
  code: flow(
    code({ t }),
    required({ t })
  )(Yup.string())
})

export default resetCode
