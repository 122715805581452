import React, { useRef, useEffect, useCallback, memo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'

import I18n, { useT } from '@smartcoop/i18n'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Button from '@smartcoop/web-components/Button'
import IdentificationForm from '@smartcoop/web-containers/forms/digitalProperty/producer/IdentificationForm'
import { withLayout } from '@smartcoop/web-containers/layouts'
import { Item, Header, Title, Subtitle, Text, ButtonsContainer } from '@smartcoop/web-containers/layouts/GuestLayout/theme'
import { useRoutes } from '@smartcoop/web-containers/routes/guest'

const IdentificationScreen = () => {
  const formRef = useRef(null)

  const user = useSelector(selectUser)
  const t = useT()

  const history = useHistory()
  const { routes } = useRoutes()

  const handleSubmit = useCallback(
    () => {
      if(user.typeSlug === 'supplier' || user.onlyNonAssociated) {
        history.push(routes.resetCode.path, { mode: 'signUp' })
      } else {
        history.push(routes.organizations.path)
      }
    },
    [history, routes, user]
  )

  useEffect(
    () => {
      if (isEmpty(user)) {
        history.replace(routes.login.path)
      }
    },
    [history, routes.login, user]
  )

  return (
    <>
      <Item>
        <Header>
          <Title style={ { alignSelf: 'flex-end' } }>
            <I18n>all right</I18n>
          </Title>
        </Header>
        <Text>
          {t('to continue your registration, confirm your data').replace('\n', ' ')}
        </Text>

        <Subtitle>
          <I18n>identification</I18n>
        </Subtitle>

        <IdentificationForm
          ref={ formRef }
          onSubmit={ handleSubmit }
          withoutSubmitButton
        />
      </Item>

      <Item>
        <ButtonsContainer>
          <Button
            id="go-back"
            onClick={ () => history.goBack() }
            style={ { width: '48%', flex: '0 0 auto' } }
            variant="outlined"
          >
            <I18n>go back</I18n>
          </Button>
          <Button
            id="identification-submit"
            onClick={ () => formRef.current.submit() }
            style={ { width: '48%', flex: '0 0 auto' } }
          >
            <I18n>next</I18n>
          </Button>
        </ButtonsContainer>
      </Item>
    </>
  )
}

export default memo(withLayout('guest')(IdentificationScreen))
