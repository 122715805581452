import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'


import PropTypes from 'prop-types'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { logout as logoutIcon, nfe, user as userIcon, userShield as accessUsers } from '@smartcoop/icons'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { selectModuleIsCommercialization, selectModuleIsDigitalProperty, selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import Icon from '@smartcoop/web-components/Icon'
import NfeIntegratorModal from '@smartcoop/web-containers/modals/profile/NfeIntegratorModal'
import TermsListModal from '@smartcoop/web-containers/modals/TermsListModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import useStyles from './styles'

const UserMenu = (props) => {
  const { onClosePopup } = props

  const dispatch = useCallback(useDispatch(), [])
  const { createDialog } = useDialog()
  const classes = useStyles()
  const history = useHistory()
  const { routes } = useRoutes()
  const t = useT()

  const isTechnicalModule = useSelector(selectModuleIsTechnical)
  const isDigitalPropertyModule = useSelector(selectModuleIsDigitalProperty)
  const isCommercializationModule = useSelector(selectModuleIsCommercialization)

  const handleClick = useCallback(
    (onClick) => {
      onClick()
      onClosePopup()
    },
    [onClosePopup]
  )

  const createOption = useCallback(
    ({ icon, text, onClick }) => (
      <ListItem button onClick={ () => handleClick(onClick) }>
        <ListItemIcon classes={ { root: classes.listItemIcon } }>
          {icon}
        </ListItemIcon>
        <ListItemText primary={ text } />
      </ListItem>
    ),
    [handleClick, classes]
  )

  return (
    <List>
      {createOption({
        icon: <Icon icon={ userIcon } />,
        text: t('my account'),
        onClick: () => history.push(routes.editProfile.path)
      })}
      {!isTechnicalModule && createOption({
        icon: <Icon icon={ accessUsers } />,
        text: t('user access'),
        onClick: () => history.push(routes.userAccess.path)
      })}
      {!isTechnicalModule && createOption({
        icon: <Icon icon={ accessUsers } />,
        text: t('terms of use and privacy'),
        onClick: () => createDialog({
          id: 'terms-of-use-modal',
          Component: TermsListModal
        })
      })}
      { (isDigitalPropertyModule || isCommercializationModule) && createOption({
        icon: <Icon icon={ nfe } />,
        text: t('nfe integrator'),
        onClick: () => createDialog({
          id: 'nfe-info-modal',
          Component: NfeIntegratorModal
        })
      })}
      {createOption({
        icon: <Icon icon={ logoutIcon } />,
        text: t('logout'),
        onClick: () => {
          localStorage.removeItem('logoutFlag')
          dispatch(AuthenticationActions.logout())
        }
      })}
    </List>
  )
}

UserMenu.propTypes = {
  onClosePopup: PropTypes.func
}

UserMenu.defaultProps = {
  onClosePopup: () => {}
}

export default UserMenu
