import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Container = styled.div`
  background-color: ${ colors.backgroundHtml };
  display: flex;
  flex: 1;
`

export const ButtonGroup = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const TextLabel = styled.span`
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
`
export const RequiredSymbol = styled.span`
  font-weight: bold;
  color: red;
  margin-left: 5px;
`

export const SubtitleLabel = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;

`

export const GroupContainer = styled.div`
  padding: 15px;
  margin: 10px;
  border-radius: 10px;
  width: 98%;
  background: #fff;
`
export const QuestionContainer = styled.div`
`

export const Badge = styled.div`
  background-color: rgba${ props => props.backgroundColorBadge };
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open Sans";
  color: rgb${ props => props.colorBadge };
  margin: 10px 0 10px 0;
`

export const Name = styled.span`
  font-weight: bold;
  color: ${ colors?.text };
`

export const ContainerButtons = styled.div`
  width: 100%;
  margin-top: 20px;
`
