import styled from 'styled-components'

export const Container = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
`

export const TermContainer = styled.div`
  height: 20%;
  width: 30%;
`

export const Term = styled.div`
  overflow-y: auto;
  height: 400px;
  width: 200px;
  padding: 10px;
`
