import React, { useCallback, forwardRef, useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import omitBy from 'lodash/omitBy'
import uniqBy from 'lodash/uniqBy'

import filterOperationsSchema from '@smartcoop/forms/schemas/operations/filterOperations.schema'
import { useT } from '@smartcoop/i18n'
import { ChallengesActions } from '@smartcoop/stores/challenges'
import { selectOperations } from '@smartcoop/stores/operations/selectorOperations'
import Form from '@smartcoop/web-components/Form'
import InputDateRange from '@smartcoop/web-components/InputDateRange'
import InputSelect from '@smartcoop/web-components/InputSelect'

import { Container } from './styles'

const FilterOperationsForm = forwardRef((props, formRef) => {
  const { onSubmit, filters } = props
  const t = useT()
  const dispatch = useCallback(useDispatch(), [])

  const operations = useSelector(selectOperations)

  const handleSubmit = useCallback(
    (data) => {
      const newData = {
        ...data,
        accreditationStartDate: data?.accreditmentDate?.from,
        accreditationEndDate: data?.accreditmentDate?.to,
        requestStartDate: data?.requestedDate?.from,
        requestEndDate: data?.requestedDate?.to
      }
      return onSubmit(omitBy(newData, val => isEmpty(val)))
    },
    [onSubmit]
  )

  const [producerId, setProducerId] = useState(filters?.producerId || '')
  const [propertyId, setPropertyId] = useState(filters?.propertyId)

  useEffect(() => {
    dispatch(ChallengesActions.loadProductivityChallengeData({ needGrowingSeason: false }))
  },[dispatch])

  const producersOptions = useMemo(
    () => map(operations, (item) => ({ value: item.proprietaryId, label: item?.proprietary?.name })) || [],
    [operations]
  )

  const propertiesOptions = useMemo(
    () => !producerId
      ? []
      : map(
        uniqBy(filter(operations, item => item?.proprietaryId === producerId && item?.property), 'propertyId'),
        (item) => ({
          value: item?.propertyId,
          label: item?.property?.name
        })) || [],
    [operations, producerId]
  )

  const fieldOptions = useMemo(
    () => !propertyId
      ? []
      : map(
        uniqBy(filter(operations, item => item.propertyId === propertyId), 'fieldId'),
        (item) => ({
          value: item?.fieldId,
          label: item?.field?.fieldName
        })) || [],
    [operations, propertyId]
  )

  const handleProducerChange = useCallback(
    (value) => {
      setProducerId(value)
      setPropertyId()
      formRef.current.getFieldRef('propertyId').setValue('')
      formRef.current.getFieldRef('fieldId').setValue('')
    },
    [formRef]
  )

  const handlePropertyChange = useCallback(
    (value) => {

      setPropertyId(value)
      formRef.current.getFieldRef('fieldId').setValue('')
    },
    [formRef]
  )

  const statusOptions = [
    {
      label: 'Aguardando Aprovação',
      value: 'AGUARDANDO_APROVACAO'
    },{
      label: 'Recusado',
      value: 'RECUSADO'
    },{
      label: 'Aprovado',
      value: 'APROVADO'
    },{
      label: 'Expirado',
      value: 'EXPIRADO'
    },{
      label: 'Pendente de Documentação',
      value: 'DOC_PENDENTE'
    }
  ]

  const stampOptions = [
    {
      label: 'Black',
      value: 'black'
    },{
      label: 'Platinum',
      value: 'platinum'
    },{
      label: 'Gold',
      value: 'gold'
    }
  ]

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterOperationsSchema }
        onSubmit={ handleSubmit }
      >
        <InputSelect
          name="proprietaryId"
          label={ t('producer', { howMany: 1, gender: 'male' }) }
          options={ producersOptions }
          defaultValue={ filters?.producerId }
          onChange={ ({ target: { value } }) => handleProducerChange(value) }
        />

        <InputSelect
          name="propertyId"
          label={ t('property', { howMany: 1 }) }
          defaultValue={ filters?.propertyId }
          options={ propertiesOptions }
          onChange={ ({ target: { value } }) => handlePropertyChange(value) }
          disabled={ isEmpty(producerId) }
        />

        <InputSelect
          name="fieldId"
          label={ t('field', { howMany: 1 }) }
          options={ fieldOptions }
          defaultValue={ filters?.fieldId }
          disabled={ isEmpty(propertyId) }
        />

        <InputSelect
          name="requestStatus"
          label="Status"
          options={ statusOptions }
          defaultValue={ filters?.requestStatus }
        />
        <InputSelect
          name="stamp"
          label={ t('stamp') }
          options={ stampOptions }
          defaultValue={ filters?.stamp }
        />
        <InputDateRange
          name="accreditmentDate"
          label={ t('approving date') }
          defaultValue={ filters?.accreditmentDate }
          fullWidth
        />
        <InputDateRange
          name="requestedDate"
          label={ t('requesting date') }
          defaultValue={ filters?.requestedDate }
          fullWidth
        />
      </Form>
    </Container>
  )
})

FilterOperationsForm.propTypes = {
  filters: PropTypes.object,
  onSubmit: PropTypes.func
}

FilterOperationsForm.defaultProps = {
  filters: {},
  onSubmit: () => {}
}

export default FilterOperationsForm
