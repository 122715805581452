import React from 'react'

import PropTypes from 'prop-types'

import I18n, { useT } from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'

import { Container, HeaderText , ButtonContainer, MessageText } from './styles'


const DeleteConversationsModal = ({ id, open, handleClose, handleDelete }) => {
  const t = useT()

  const onDelete = async () => {
    await handleDelete()
    handleClose()
  }

  return (
    <Modal
      id={ id }
      open={ open }
      escape={ false }
      hideHeader
    >
      <Container>
        <HeaderText>
          {t('delete conversation(s)')}
        </HeaderText>

        <MessageText>
          {t('do you really want to delete the conversation(s)?')}
        </MessageText>

        <ButtonContainer>
          <Button
            id="cancel"
            onClick={ onDelete }
            style={ { minWidth: '100%' } }
            color="secondary"
          >
            {t('delete conversation(s)')}
          </Button>
          <Button
            id="cancel"
            onClick={ handleClose }
            style={ { minWidth: '100%' } }
          >
            <I18n>cancel</I18n>
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  )
}

DeleteConversationsModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
}


export default DeleteConversationsModal
