import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  background-color: ${ colors.white };
  border-radius: 5px 5px 0 0;
  padding: 10px;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0;
`

export const ManagementName = styled.p`
  margin: 0;
  font-size: 16px;
`

export const DateContent = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
`

export const TextContent = styled.div`
  padding-left: 8px;
`

export const DetailsManagement = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: 30px;
  padding: 0 20px 0;
`

export const DetailsText = styled.div`
  margin-right: 60px;
    p{
      margin: 0;
    }
`

export const DateSingle = styled.div`
  display: flex;
  margin-right: 10px;
`

export const WhiteCard = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardChildren = styled.div`
  padding: 0px 20px;
`

export const WarningDiv = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  background-color: rgba(254,241,182,0.8);
  width: 100%;
  text-align: center;
  justify-content: center;
`
