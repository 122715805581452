import styled from 'styled-components'

import ListItem from '@material-ui/core/ListItem'

import colors from '@smartcoop/styles/colors'
import Card from '@smartcoop/web-components/Card'

export const CardContainer = styled(Card).attrs({
  cardStyle: {
    width: '100%',
    padding: 0
  }
})`
  margin: 0 0 15px;
`

export const CardContent = styled.div`
  padding: 5px 2rem;
  display: flex;
  flex-direction: column;
`

export const CardButton = styled(ListItem).attrs({
  button: true
})`
  padding: 5px 15px 7px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  justify-content: center;
  border-top: 1px solid #E0E0E0;
`

export const Title = styled.span`
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
`

export const AverageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
`

export const AverageImage = styled.img`
  width: 4rem;
`

export const AverageNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const AverageNumber = styled.span`
  font-size: 3rem;
  font-weight: bold;
`

export const AverageType = styled.span`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
`

export const Description = styled.span`
  width: 100%;
  text-align: center;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MinMaxText = styled.span`
  text-align: center;
`

export const DateText = styled.span`
  text-align: center;
  font-weight: bold;
  color: ${ colors.darkGrey };
`
