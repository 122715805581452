import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Card = styled.div`
  padding: 10px;
  border: 1px solid ${ colors.lightGrey };
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  display: grid;
  grid-template-columns: ${ ({ isAdmin, showOptions }) => {
    if (!showOptions) {
      return 'max-content max-content auto'
    }

    return isAdmin ? 'max-content max-content auto 1px' : 'max-content auto 1px'
  } };
  align-items: center;
  column-gap: 15px;

  :hover {
    #action {
      opacity: 1;
      /* width: fit-content; */
      /* height: fit-content; */
    }
  }
`

export const TextName = styled.span`
  font-family: 'Open Sans';
  font-size: .95rem;
  font-weight: 600;
  color: ${ colors.text };
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
`

export const IconCircle = styled.div`
  background-color: ${ ({ circleColor }) => circleColor };
  width: 25px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

export const AdminLabel = styled.span`
  color: ${ colors.mediumGrey };
  font-size: 10px;
  font-style: italic;
  width: 100%;
  text-align: end;
`

export const ActionButton = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  bottom: 10px;
  transition: left .1s, bottom .1s, opacity .2s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
  opacity: 0;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
    font-size: .9rem;
  }
`
