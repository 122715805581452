import { AnimalModel } from './animalModel'
import { ContactModel } from './contactModel'
import { ConversationFileModel } from './conversationFileModel'
import { ConversationModel } from './conversationModel'
import { GroupMemberModel } from './groupMemberModel'
import { GroupModel } from './groupModel'
import { MessageModel } from './messageModel'
import { ProfilePictureModel } from './profilePictureModel'
import { SyncFileModel } from './syncFileModel'
import { SyncHistoricModel } from './syncHistoricModel'
import { SyncPendingModel } from './syncPendingModel'
import { UserModel } from './userModel'

export const models = [
  UserModel,
  ContactModel,
  MessageModel,
  ConversationModel,
  SyncHistoricModel,
  SyncPendingModel,
  SyncFileModel,
  ProfilePictureModel,
  GroupModel,
  GroupMemberModel,
  ConversationFileModel,
  AnimalModel
]
