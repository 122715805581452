import React from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { closeRounded } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import IconButton from '../IconButton'

const CloseRoundedIconButton = ({ iconBorder, iconFill, iconColor, ...props }) => {
  const t = useT()
  return (
    <IconButton tooltip={ t('exclude') } { ...props }>
      <Icon
        icon={ closeRounded }
        border={ iconBorder }
        fill={ iconFill }
        color={ iconColor }
      />
    </IconButton>
  )
}

CloseRoundedIconButton.propTypes = {
  iconBorder: PropTypes.string,
  iconFill: PropTypes.string,
  iconColor: PropTypes.string
}

CloseRoundedIconButton.defaultProps = {
  iconBorder: colors.mutedText,
  iconFill: colors.white,
  iconColor: colors.mutedText
}

export default CloseRoundedIconButton
