import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import I18n from '@smartcoop/i18n'
import { PropertyActions } from '@smartcoop/stores/property'
import {
  selectPropertyDairyFarmNextActions,
  selectReloadData
} from '@smartcoop/stores/property/selectorProperty'
import Badge from '@smartcoop/web-components/Badge'
import Loader from '@smartcoop/web-components/Loader'

import {
  EmptyState,
  Container,
  ActionName
} from './styles'

const DairyFarmActionsListFragment = (props) => {
  const { property } = props

  const dispatch = useCallback(useDispatch(), [])
  const mounted = useRef(false)

  const [loading, setLoading] = useState(false)

  const propertyDairyFarmNextActions = useSelector(
    selectPropertyDairyFarmNextActions
  )
  const reloadData = useSelector(selectReloadData)

  useEffect(() => {
    if (property?.id || reloadData) {
      setLoading(true)
      dispatch(
        PropertyActions.loadPropertyDairyFarmNextActions(
          property?.id,
          () => mounted.current && setLoading(false),
          () => mounted.current && setLoading(false)
        )
      )
    }
  }, [dispatch, property, reloadData])

  useEffect(() => {
    mounted.current = true
  }, [])

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  return loading && isEmpty(propertyDairyFarmNextActions) ? (
    <Loader width={ 100 } />
  ) : (
    <>
      {isEmpty(propertyDairyFarmNextActions) ? (
        <I18n as={ EmptyState }>no actions planned</I18n>
      ) : (
        <List style={ { padding: 0 } }>
          {map(propertyDairyFarmNextActions, (actions) => (
            <ListItem
              divider
              key={ actions.nextAction }
            >
              <Container>
                <ActionName>{actions.actionName}</ActionName>
                <Badge
                  backgroundColorBadge={ actions.color }
                  colorBadge={ actions.color }
                >
                  {actions.total}</Badge>
              </Container>
            </ListItem>
          ))}
        </List>
      )}
    </>
  )
}

DairyFarmActionsListFragment.propTypes = {
  property: PropTypes.object
}

DairyFarmActionsListFragment.defaultProps = {
  property: {}
}

export default DairyFarmActionsListFragment
