import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import colors, { lightGrey } from '@smartcoop/styles/colors'

export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid ${ lightGrey };
  margin: 0;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
`

export const Line = styled.div`
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
`

export const ItemColumn = styled.div`
      display: flex;
    flex-direction: column;
  > span:first-child {
    font-weight: bold;
    color: ${ colors.darkGrey }
  }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
`
export const ButtonLabel = styled.span`
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    /* Black */
    color: #1D1D1B;
`

export const ItemTitle = styled.span`
    margin-bottom: 10px;
`

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.backgroundHtml
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    padding: '12px 13px 0 13px'
  }
})
