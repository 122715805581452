import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import { successFlower } from '@smartcoop/icons'
import { PropertyActions } from '@smartcoop/stores/property'
import Icon from '@smartcoop/web-components/Icon'
import Modal from '@smartcoop/web-components/Modal'

import { Container, MainText, HeaderText, MainButton, TextUnderlined } from './styles'

const SucessModal = (props) => {
  const {
    open,
    handleClose,
    message,
    id,
    buttonText,
    underlinedText,
    onConfirm,
    onUnderlinedClick,
    reloadDataAction
  } = props

  const dispatch = useCallback(useDispatch(), [])

  return (
    <Modal id={ id } open={ open } hideHeader title="Modal" >
      <Container>

        <Icon icon={ successFlower } size={ 125 } />

        <HeaderText>
          <I18n>success</I18n>
        </HeaderText>

        {message && (
          <MainText>
            {message}
          </MainText>
        )}

        <MainButton
          id="dialog-button"
          onClick={ () => {
            reloadDataAction && dispatch(PropertyActions.setReloadData(true))
            handleClose()
            onConfirm()
          } }
          color="black"
          style={ { minWidth: 100, textTransform: 'none' } }
        >
          {buttonText}
        </MainButton>

        {underlinedText && (
          <TextUnderlined
            onClick={ () => {
              reloadDataAction && dispatch(PropertyActions.setReloadData(true))
              handleClose()
              onUnderlinedClick()
            } }
          >
            {underlinedText}
          </TextUnderlined>
        )}
      </Container>
    </Modal>
  )
}

SucessModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  id: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  underlinedText: PropTypes.string,
  onConfirm: PropTypes.func,
  onUnderlinedClick: PropTypes.func,
  reloadDataAction: PropTypes.bool
}

SucessModal.defaultProps = {
  message: null,
  buttonText: 'ok',
  underlinedText: null,
  onConfirm: () => {},
  onUnderlinedClick: () => {},
  reloadDataAction: false
}

export default SucessModal
