import React, { forwardRef, useMemo } from 'react'

import PropTypes from 'prop-types'

import FilteredCheckboxGroupForm from './FilteredCheckboxGroupForm'
import FilteredCheckboxGroupStyled from './FilteredCheckboxGroupStyled'

const FilteredCheckboxGroup = forwardRef((props, ref) => {
  const {
    detached,
    value,
    ...rest
  } = props

  const Input = useMemo(
    () => detached ? FilteredCheckboxGroupStyled : FilteredCheckboxGroupForm,
    [detached]
  )

  return (
    <Input
      ref={ ref }
      value={ value }
      { ...rest }
    />
  )
})

FilteredCheckboxGroup.propTypes = {
  detached: PropTypes.bool,
  value: PropTypes.any
}

FilteredCheckboxGroup.defaultProps ={
  detached: false,
  value: []
}

export default FilteredCheckboxGroup
