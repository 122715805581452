import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import I18n from '@smartcoop/i18n'
import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import Card from '@smartcoop/web-components/Card'

export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid #E0E0E0;
  margin: 0;
`
export const IconText = styled(I18n)`
  font-size: 18px;
  font-family: OpenSans;
  padding-left: 5px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
`

export const IconLike = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: bold;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.backgroundHtml
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    padding: '12px 13px 0 13px'
  }
})

export const CardItemTitle = styled(Card).attrs(props => ({
  cardStyle: {
    width: '160px',
    padding: 0,
    ...(props.cardStyle || {})
  },
  headerTitleProps: {
    align: 'left',
    style: {
      fontWeight: fonts.fontWeight.bold,
      padding: '10px 15px',
      borderBottom: `2px solid ${ colors.lightGrey }`
    }
  }
}))`
  margin-bottom: 0;
  margin-top: 7.5px;

  button{
    border:none;
    background-color: white;

    svg:hover path {
    fill: #008000;
}
  }


`

