import styled from 'styled-components'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`

export const ButtonContainer = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
`

export const CheckboxContainer = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
`

export const TextDate = styled.div`
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 15px;
`

export const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
`

export const ContentRadioGroup = styled.div`
  margin-top: 15px;
`

export const Gallery = styled.div`
  display: flex;
  justify-content: ${ ({ isCenter }) => isCenter ? 'center' : 'flex-start' };
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${ ({ isCenter }) => isCenter ? '0' : '20px' };
`
