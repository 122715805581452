import React, { useCallback, forwardRef } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import registerBullSchema from '@smartcoop/forms/schemas/dairyFarm/registerBull.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getAnimalBreeds as getAnimalBreedsService } from '@smartcoop/services/apis/smartcoopApi/resources/animalBreed'
import { getBulls } from '@smartcoop/services/apis/smartcoopApi/resources/herdsManagement'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'

import {
  Container,
  FormContainer,
  ButtonsContainer,
  Item
} from './styles'

const RegisterBullForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel
  } = props

  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data
      })
    },
    [onSubmit]
  )

  const propertyId = useSelector(selectCurrentPropertyId)

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerBullSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputSelect
                name="name"
                label={ t('name', { howMany: 1 }) }
                fullWidth
                defaultValue={ defaultValues?.name }
                creatable
                options={ getBulls }
                asyncOptionLabelField="name"
                asyncOptionValueField="name"
                createdSameLabel
              />
            </Item>
            <Item>
              <InputSelect
                name="code"
                label={ t('code', { howMany: 1 }) }
                fullWidth
                defaultValue={ defaultValues?.code }
                creatable
                options={ getBulls }
                asyncOptionLabelField="code"
                asyncOptionValueField="code"
                createdSameLabel
                disabled={ defaultValues?.code }
              />
            </Item>
            <Item>
              <InputSelect
                name="breedId"
                label={ t('race') }
                options={ getAnimalBreedsService }
                urlParams={ { propertyId } }
                defaultValue={ defaultValues?.breedId }
              />
            </Item>
          </Grid>
        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-cancel-form-button"
              onClick={ onCancel }
              color={ colors.white }
              disabled={ loading }
              style={ { marginRight:10, marginLeft: 10 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="web-save-form-button"
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
              style={ { marginRight:10, marginLeft: 10 } }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

RegisterBullForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  defaultValues: PropTypes.object.isRequired
}

RegisterBullForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false
}

export default RegisterBullForm
