import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const Gallery = styled.div`
  display: flex;
  justify-content: ${ ({ isCenter }) => isCenter ? 'center' : 'flex-start' };
  align-items: center;
  flex-wrap: wrap;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const RowTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
`
