import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'

import debounce from 'lodash/debounce'
import find from 'lodash/find'
import map from 'lodash/map'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { arrowLeft } from '@smartcoop/icons'
import { getWebinars } from '@smartcoop/services/apis/smartcoopApi/resources/informatives'
import { useSnackbar } from '@smartcoop/snackbar'
import { InformativesActions } from '@smartcoop/stores/informatives'
import { OrganizationActions } from '@smartcoop/stores/organization'
import { selectCurrentOrganization, selectOrganizationRTC, selectIsProfileRTC } from '@smartcoop/stores/organization/selectorOrganization'
import { colors } from '@smartcoop/styles'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import Badge from '@smartcoop/web-components/Badge'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import InputSearch from '@smartcoop/web-components/InputSearch'
import InputSelect from '@smartcoop/web-components/InputSelect'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import RegisterInformativeModal from '@smartcoop/web-containers/modals/RegisterInformativeModal'

import { Container, FilterRow, Title } from './style'





const RegisterInformativeScreen = () => {
  const dispatch = useDispatch()
  const t = useT()
  const tableRef = useRef(null)
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()
  const history = useHistory()

  const [filterText, setFilterText] = useState('')
  const [debouncedFilterText, setDebouncedFilterText] = useState('')
  const currentOrganization = useSelector(selectCurrentOrganization)
  const organizationRTC = useSelector(selectOrganizationRTC)
  const isRTC = useSelector(selectIsProfileRTC)

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )

  useEffect(() => {
    dispatch(OrganizationActions.loadUserOrganizations(true))
  }, [dispatch])

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )

  const handleParams = useCallback(
    (values) =>
      Object.keys(values)
        .filter((key) => typeof values[key] === 'boolean' || values[key])
        .reduce((prev, curr) => ({ ...prev, [curr]: values[curr] }), {}),
    []
  )

  const params = useMemo(() => {
    const filterParams = {
      q: debouncedFilterText,
      organizationId: isRTC && organizationRTC ? organizationRTC?.id : currentOrganization?.id
    }

    return handleParams(filterParams)
  }, [currentOrganization, debouncedFilterText, handleParams, isRTC, organizationRTC])

  const informativeOptions = useMemo(() => [
    {
      label: 'Webinar',
      value: 'webinar'
    },
    {
      label: 'Vídeo técnico',
      value: 'technicalVideos'
    },
    {
      label: 'Boletim técnico',
      value: 'boletim-tecnico'
    },
    {
      label: 'Resultado de Pesquisa',
      value: 'searchResults'
    },
    {
      label: 'Novidades',
      value: 'news'
    },
    {
      label: 'Informativos',
      value: 'informatives'
    }
  ],[])

  const columns = useMemo(
    () => [
      {
        title: t('publication date'),
        field: 'publicationDate',
        render: (row) =>
          moment(
            row?.publicationDate,
            momentBackDateFormat
          ).format(momentFriendlyDateFormat),
        sorting: true,
        defaultSort: 'desc'
      },
      {
        title: t('organization', { howMany: 1 }),
        field: 'organization',
        render: (row) => row?.organization?.tradeName,
        sorting: true
      },
      {
        title: t('file type'),
        field: 'fileType',
        render: (row) => {
          const value = find(informativeOptions, item => item.value === row?.fileType)
          return value?.label
        },
        sorting: true
      },
      {
        title: t('name', { howMany: 1 }),
        field: 'name',
        sorting: true
      },
      {
        align: 'center',
        title: t('likes'),
        field: 'likes',
        sorting: true
      },
      {
        align: 'center',
        title: t('views'),
        field: 'accessNumber',
        sorting: true
      },
      {
        align: 'center',
        title: t('proprietary allow'),
        field: 'proprietaryAllow',
        sorting: true,
        render: (row) =>
          <Badge
            backgroundColorBadge={ row?.proprietaryAllow === 'true' ? '#199cd8' : '#e41d1b' }
            colorBadge={ row?.proprietaryAllow === 'true' ? '#199cd8' : '#e41d1b' }
          >
            { row?.proprietaryAllow === 'true' ? t('yes') : t('no') }
          </Badge>
      }
    ],
    [informativeOptions, t]
  )

  const organizationOptions = useMemo(
    () =>
      map(isRTC && organizationRTC ? [organizationRTC] : [currentOrganization], (item) => ({
        label: item?.tradeName,
        value: item?.id
      })),
    [currentOrganization, isRTC, organizationRTC]
  )

  const openModal = useCallback((_, data) => {
    createDialog({
      id: 'informative-register-file',
      Component: RegisterInformativeModal,
      props: {
        name: t('register material'),
        defaultValues: data,
        reloadDataTable,
        organizationOptions
      }

    })
  }, [createDialog, organizationOptions, reloadDataTable, t])

  const handleInformativeDelete = useCallback(
    (data) => {
      createDialog({
        id: 'confirm-delete-informative',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(InformativesActions.deleteInformative(
              { informativeId: data.id },
              () => {
                reloadDataTable()
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'female',
                    this: t('informative', { howMany: 1 })
                  })
                )
              },
              () => {
                reloadDataTable()
              }
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            gender: 'male',
            howMany: 1,
            this: t('file', { howMany: 1 })
          })
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, snackbar, t]
  )

  const onDeleteClick = useCallback(
    (_, data) => handleInformativeDelete(data),
    [handleInformativeDelete]
  )

  return (
    <Container>
      <Title style={ { marginBottom: 20 } }>
        <Icon style={ { cursor: 'pointer', marginRight: 10 } } onClick={ () => history.goBack() } icon={ arrowLeft } size={ 16 } /> {t('materials') }
      </Title>
      <FilterRow>
        <InputSearch
          adornmentStyle={ { marginRight: 15 } }
          detached
          onChange={ onChangeSearchFilter }
          value={ filterText }
          placeholder={ t('search') }
          style={ { width: '80vw', marginRight: 20 } }
        />
        <InputSelect
          detached
          label={ t('select one organization') }
          name="organization"
          options={ organizationOptions }
          value={ isRTC && organizationRTC ? organizationRTC?.id : currentOrganization?.id }
        />
        <div style={ { width: '60vw', marginLeft: 20 } }>
          <Button
            variant="contained"
            color={ colors.secondary }
            onClick={ openModal }
            fullWidth
          >
            {t('register material')}
          </Button>
        </div>
      </FilterRow>
      <DataTable
        columns={ columns }
        data={ getWebinars }
        queryParams={ params }
        tableRef={ tableRef }
        onEditClick={ openModal }
        onDeleteClick={ onDeleteClick }
        id="list-informative-files"
      />
    </Container>
  )
}

export default RegisterInformativeScreen
