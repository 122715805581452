import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie'

import PropTypes from 'prop-types'

import Spinner from '@smartcoop/styles/assets/animations/spinner.json'

import { Container, Message } from './styles'

const lottieStyle = {
  height: '100%',
  alignSelf: 'center'
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Spinner
}

const AnimatedLoader = ({ message, width, style, dynamic }) => {
  const messages = ['Validando seus dados...', 'Em processamento...', 'Aguarde um momento...', 'Dependendo do volume de dados pode acabar demorando um pouco mais...']

  const [messageIndex, setMessageIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex(prevIndex => (prevIndex + 1) % messages.length)
    }, 30000) // 30 seconds

    return () => clearInterval(interval)
  }, [messages])


  return (
    <Container style={ style }>
      <Lottie options={ defaultOptions } isClickToPauseDisabled style={ lottieStyle } width={ width }/>
      {message && <Message>{message}</Message>}
      {dynamic && <Message>{messages[messageIndex]}</Message>}
    </Container>
  )
}

AnimatedLoader.propTypes = {
  message: PropTypes.string,
  dynamic: PropTypes.bool,
  style: PropTypes.object,
  width: PropTypes.number
}

AnimatedLoader.defaultProps = {
  message: null,
  dynamic: false,
  style: {},
  width: 200
}


export default AnimatedLoader
