import React, { useCallback, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import identification from '@smartcoop/forms/schemas/producer/identification.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { UserActions } from '@smartcoop/stores/user'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputEmail from '@smartcoop/web-components/InputEmail'
import InputText from '@smartcoop/web-components/InputText'

import { Container, ButtonContainer } from './styles'

const IdentificationForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, onSubmit } = props

  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const user = useSelector(selectUser)

  const handleSubmit = useCallback(
    async (data) => {
      dispatch(UserActions.updateUser(
        data,
        onSubmit
      ))
    },
    [dispatch, onSubmit]
  )

  return (
    <Container>
      <Form
        ref={ formRef }
        schemaConstructor={ identification }
        onSubmit={ handleSubmit }
      >
        <InputText
          name="name"
          label={ t('full name') }
          defaultValue={ user.name }
          fullWidth
          disabled
        />

        <InputText
          name="cellPhone"
          label={ t('cellphone') }
          defaultValue={ user.cellPhone }
          fullWidth
          disabled
        />

        <InputEmail
          name="email"
          label={ t('email') }
          defaultValue={ user.email }
          fullWidth
          disabled
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="identification-submit"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
            >
              <I18n>next</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

IdentificationForm.propTypes = {
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

IdentificationForm.defaultProps = {
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default IdentificationForm
