import { WebSocketPersist } from '@smartcoop/services/apis/socket/socket'

import api from '../api'

// export const getChatConversations = (params) => api.get('/v1/chat', { params })

export const getChatConversationById = (params, { chatId }) => api.get(`/v1/conversation/${ chatId }`, { params })

export const getChatUserRegistryByIdConversation = (params, { chatId }) => api.get(`/v1/chat/${ chatId }`, { params })

// export const deleteMachine = ({ chatId }) => api.delete(`/v1/chat/${ chatId }`)

export const createChatConversation = (params) =>
  api.post('/v1/conversation', params)

export const getChatConversationsByUserId = () =>
  api.get('/v1/conversation')

export const patchConversation = (id, params) =>
  api.patch(`/v1/conversation/${ id }`, params)

export const getChatMessagesByConversationId = (params) =>
  api.get(`/v1/chat/registry/${ params }`)

export const UpdateGroupAdministrator = (conversationId, params) =>
  api.post(`/v1/conversation/update-group-admin/${ conversationId }`, params)

export const SendChatMessage = (params) =>
  api.post('/v1/chat', params)

export const SendChatSearch = (params) =>
  api.post('/v1/chat/registry/search', params)

export const DeleteConversation = (params) =>
  api.delete(`/v1/chat/registry/${ params }`)

export const DeleteGroupMember = (conversationId, params) =>
  api.post(`/v1/conversation/remove-group-member/${ conversationId }`, params)

export const getSearchMessageByConversationId = (conversationID, params) =>
  api.post(`/v1/chat/registry/search/${ conversationID }`, params)

export const updateChatMessageStatus = (params) =>
  api.patch('/v1/chat/registry', params)

export const deleteChatMessage = (params) =>
  api.post('/v1/chat/registry/delete-message', params)

export const addGroupMember = (conversationId, params) =>
  api.post(`/v1/conversation/add-group-member/${ conversationId }`, params)

export const sendFileMessageByConversationId = (conversationID, uploadType, params) =>
  api.post(`/v1/chat/docs/${ conversationID }/${ uploadType }`, params, {
    headers: {
      Accept: 'videos/*',
      'Content-Type': 'multipart/form-data'
    }
  })

export const updateConversationImage = (conversationID, params) =>
  api.post(`/v1/conversation/update-photo/${ conversationID }`, params, {
    headers: {
      Accept: 'images/*',
      'Content-Type': 'multipart/form-data'
    }
  })

export const getChatContacts = (userCode) =>
  api.get(`/v1/contact?code=${ userCode }`)

// WSocket routes
export const SendChatMessageSocket = (params) => {
  const ws = new WebSocketPersist()
  ws.sendMessage(params)
}


/**
 * Refactor
 */
export const getChatContact = (userCode) =>
  api.get(`/v1/contact?code=${ userCode }`)

export const addChatContact = (userCode) =>
  api.post(`/v1/contact?code=${ userCode }`)

export const deleteChatContact = (userCode) =>
  api.delete(`/v1/contact?code=${ userCode }`)

export const updateChatContactCode = (contactId, userCode) =>
  api.patch(`/v1/contact?contactId=${ contactId }&code=${ userCode }`)
