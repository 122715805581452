import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'

import { trash, plus, minus } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import Icon from '@smartcoop/web-components/Icon'

import {
  Container,
  Button,
  Input
} from './styles'

const InputQuantity = (props) => {
  const {
    min,
    max,
    value,
    onChange,
    disabled,
    deletable,
    onDelete,
    forceDelete,
    leftIconColor,
    rightIconColor,
    readOnly,
    style
  } = props

  const inputRef = useRef(null)

  const customValue = useMemo(
    () => ((value < min) ? min : value) || min,
    [min, value]
  )

  const incrementDisabled = useMemo(
    () => customValue >= max,
    [customValue, max]
  )

  const decreaseDisabled = useMemo(
    () => customValue <= min,
    [customValue, min]
  )

  const [inputValue, setInputValue] = useState(customValue)

  const onIncrement = useCallback(
    () => {
      if (!incrementDisabled) {
        onChange(customValue + 1)
      }
    },
    [customValue, incrementDisabled, onChange]
  )

  const onDecrease = useCallback(
    () => {
      if (!decreaseDisabled) {
        onChange(customValue - 1)
      }
    },
    [customValue, decreaseDisabled, onChange]
  )

  const onChangeDebounced = useCallback(
    debounce(
      (newValue, setaFoco) => {
        onChange(newValue)
        if (setaFoco && inputRef.current) {
          inputRef.current.select()
        }
      },
      500
    ),
    []
  )

  const onCustomChange = useCallback(
    (val, setaFoco = true) => {
      let newValue = parseInt(val, 10)

      if (newValue >= min) {
        if (max && newValue > max) {
          newValue = max
        }
      } else {
        newValue = min
      }

      setInputValue(newValue)
      onChangeDebounced(newValue, setaFoco)
    },
    [max, min, onChangeDebounced]
  )

  const onInputChange = useCallback(
    ({ target: { value: iptValue } }) => {
      onCustomChange(iptValue)
    },
    [onCustomChange]
  )

  useEffect(() => {
    if (inputValue !== customValue) {
      setInputValue(customValue)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customValue, onCustomChange])

  return (
    <Container style={ style }>
      {(deletable && inputValue <= min) || forceDelete ? (
        <Button
          onClick={ onDelete }
          disabled={ readOnly || disabled }
        >
          <Icon
            icon={ trash }
            color={ leftIconColor }
            size={ 18 }
          />
        </Button>
      ) : (
        <Button
          disabled={ disabled || decreaseDisabled }
          onClick={ onDecrease }
        >
          <Icon
            icon={ minus }
            color={ leftIconColor }
            size={ 18 }
          />
        </Button>
      )}
      <Input
        ref={ inputRef }
        type="tel"
        onClick={ event => event.target.select() }
        value={ inputValue }
        onChange={ onInputChange }
        disabled={ disabled }
      />
      <Button
        disabled={ disabled || incrementDisabled }
        onClick={ onIncrement }
      >
        <Icon
          icon={ plus }
          color={ rightIconColor }
          size={ 18 }
        />
      </Button>
    </Container>
  )
}

InputQuantity.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
  forceDelete: PropTypes.bool,
  leftIconColor: PropTypes.string,
  rightIconColor: PropTypes.string,
  style: PropTypes.object
}

InputQuantity.defaultProps = {
  min: 1,
  max: null,
  value: null,
  onChange: () => {},
  disabled: false,
  readOnly: false,
  deletable: false,
  onDelete: () => {},
  forceDelete: false,
  leftIconColor: colors.grey,
  rightIconColor: colors.secondary,
  style: {}
}

export default InputQuantity
