import React, { useState, useCallback } from 'react'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'

import  I18n from '@smartcoop/i18n'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Modal from '@smartcoop/web-components/Modal'

import useStyles, { Divisor, ButtonsContainer, Container } from './styles'

const CheckboxesModal = (props) => {
  const {
    id,
    open,
    onConfirm,
    items,
    title,
    handleClose
  } = props

  const classes = useStyles()

  const [selected, setSelected] = useState([])

  const onClose = useCallback(() => {
    onConfirm(selected)
    handleClose()
  },[handleClose, onConfirm, selected])

  const onChangeCheckbox = useCallback((value) => {
    let currentSelected = [...selected]

    const hasValue = includes(currentSelected, value)

    currentSelected = hasValue ? filter(currentSelected, item => item !== value) : [...currentSelected, value]

    setSelected(currentSelected)
  }, [selected])

  return (
    <Modal
      id={ id }
      open={ open }
      title={ title }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      maxWidth="xs"
      fullWidth
    >
      <Divisor style={ { marginTop: '10px', marginBottom: '10px' } }/>
      <Container>

        {map(items, item => (
          <CheckboxButton
            key={ item.label }
            checked={ includes(selected, item?.value) }
            onChange={ () => onChangeCheckbox(item?.value) }
            label={ item.label }
          />
        ))}
        <ButtonsContainer>
          <Button
            id="web-cancel-form-button"
            onClick={ handleClose }
            color={ colors.white }
            fullWidth
            style={ { marginRight: 5 } }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="web-save-form-button"
            onClick={ onClose }
            fullWidth
            style={ { marginLeft: 5 } }
          >
            <I18n>confirm</I18n>
          </Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  )}

CheckboxesModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onConfirm: PropTypes.func,
  items: PropTypes.array,
  title: PropTypes.string
}

CheckboxesModal.defaultProps = {
  handleClose: () => {},
  title: '',
  items: [],
  onConfirm: () => {}
}

export default CheckboxesModal
