// eslint-disable-next-line spaced-comment
export default ({ size, color = '#000' } = {}) => /*html*/`
  <svg width="${ size }" height="${ size }" viewBox="0 0 24 24">
    <g>
      <title>background</title>
      <rect fill="none" id="canvas_background" height="602" width="802" y="-1" x="-1"/>
    </g>
    <g>
      <title>Layer 1</title>
      <ellipse ry="11.93069" rx="11.91419" id="svg_2" cy="12" cx="12" fill="#fff"/>
      <ellipse ry="6" rx="6" id="svg_8" cy="12" cx="12" fill="${ color }"/>
    </g>
  </svg>
`
