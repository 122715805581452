import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'
import Card from '@smartcoop/web-components/Card'

export const RadioTouchableContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

export const Top = styled.div`
  background-color: ${ colors.white };
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 30px;
  width: 100%;
  padding: 10px;
`

export const Divider = styled.hr`
  border: none;
  border-bottom: 0.935252px solid #E0E0E0;
  margin: 0;
  width: 100%;
`

export const Children = styled.div`
  background-color: ${ colors.white };
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 30px;
  width: 100%;
  padding: 10px;
  margin-top: 16px;
`

export const Container = styled(Card).attrs({
  headerStyle: { padding: '8px 16px 3px' },
  cardContentProps: { style: { paddingTop: 0 } }
})`
  width: 100%;
  margin: 0;
`

export const IconContainer = styled.div`
  max-width: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  margin-top: 2px;
  font-size: 16px;
  flex: 1;
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
`

export const GreenText = styled.span`
  font-weight: bold;
  color: ${ colors.green };
  /* Remover margin default do card */
  margin-bottom: -15px;
`
