import React, { useCallback, forwardRef, useMemo, useState } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import fieldAnalysisSchema from '@smartcoop/forms/schemas/property/fields/fieldAnalysis.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { FIELD_ANALYSIS_RESULT } from '@smartcoop/utils/constants'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'

import { Container, ButtonContainer } from './styles'

const FieldAnalysisForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    isEditing,
    loading,
    onSubmit,
    defaultValues,
    markerCoordinate,
    selectedField,
    setSelectedField,
    fieldsOptions
  } = props

  const t = useT()
  const [showResult, setShowResult] = useState(!isEmpty(defaultValues?.analysisResult))

  const resultFields = FIELD_ANALYSIS_RESULT

  const analysisTypeOptions = useMemo(
    () => [
      {
        label: 'Química basica',
        value: 'basic chemistry'
      },
      {
        label: 'Química completa',
        value: 'complete chemistry'
      }
    ],
    []
  )

  const sampleDepthOptions = useMemo(
    () => [
      {
        label: '0-5 cm',
        value: '0-5 cm'
      },
      {
        label: '0-10 cm',
        value: '0-10 cm'
      },
      {
        label: '0-15 cm',
        value: '0-15 cm'
      },
      {
        label: '0-20 cm',
        value: '0-20 cm'
      },
      {
        label: '10-20 cm',
        value: '10-20 cm'
      },
      {
        label: '20-40 cm',
        value: '20-40 cm'
      }
    ],
    []
  )

  const matches = useMediaQuery('(max-height:900px)')

  const handleSubmit = useCallback(
    async (data) => {
      if(!isEmpty(data?.analysisResult)) {
        const results = {}
        await map(resultFields, (item, index) => {
          if(!isEmpty(data?.analysisResult[index]?.value)) {
            results[item?.slug] = data?.analysisResult[index]?.value
          }
        })
        onSubmit({
          ...data,
          fieldId: selectedField,
          pinLocation: markerCoordinate,
          results
        })
      } else {
        onSubmit({
          ...data,
          fieldId: selectedField,
          pinLocation: markerCoordinate
        })
      }
    },
    [markerCoordinate, onSubmit, resultFields, selectedField]
  )

  const getDefaultValue = (item) => {
    const response = find(defaultValues?.analysisResult, result => result.slug === item.slug)
    return response?.value || ''
  }

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ fieldAnalysisSchema }
        onSubmit={ handleSubmit }
      >
        <InputDate
          label={ t('select one date') }
          name="dateOfCollection"
          placeholder={ t('date', { howMany: 1 }) }
          fullWidth
          disabled={ loading || isEditing }
          defaultValue={ defaultValues.dateOfCollection || moment().format(momentBackDateFormat) }
        />

        <InputSelect
          detached
          required
          fullWidth
          label={ t('field', { howMany: 1 }) }
          options={ fieldsOptions }
          value={ selectedField }
          onChange={ setSelectedField }
          disabled={ isEditing }
        />

        <InputSelect
          label={ t('analysis type') }
          name="analysisType"
          options={ analysisTypeOptions }
          defaultValue={ defaultValues.analysisType }
        />

        <InputSelect
          label={ t('sample depth') }
          name="sampleDepth"
          options={ sampleDepthOptions }
          defaultValue={ defaultValues.sampleDepth }
        />

        <InputText
          label={ t('laboratory') }
          name="laboratory"
          fullWidth
          defaultValue={ defaultValues.laboratory }
        />

        <CheckboxButton
          label={ t('result') }
          onChange={ () => setShowResult(!showResult) }
          value={ showResult }
          checked={ showResult }
          style={ { marginBottom: '20px' } }
        />

        {showResult && (
          <InputDate
            label={ t('report date') }
            name="analysisResultDate"
            fullWidth
            disabled={ loading }
            defaultValue={ defaultValues?.analysisResultDate || moment().format(momentBackDateFormat) }
          />
        )}
        {showResult && map(resultFields, (item, index) => (
          <InputUnit
            key={ index }
            label={ item?.name }
            name={ `analysisResult[${ index }].value` }
            defaultValue={ getDefaultValue(item) }
            fullWidth
            type="float"
            decimalScale={ 1 }
            unit={ item?.unit }
          />
        ))}

        <InputText
          placeholder={ t('observations') }
          name="observation"
          fullWidth
          multiline
          rows={ matches ? 2 : 4 }
          defaultValue={ defaultValues.observation }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-field-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>next</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FieldAnalysisForm.propTypes = {
  loading: PropTypes.bool,
  isEditing: PropTypes.bool,
  onSubmit: PropTypes.func,
  setSelectedField: PropTypes.func,
  selectedField: PropTypes.string,
  markerCoordinate: PropTypes.object,
  withoutSubmitButton: PropTypes.bool,
  fieldsOptions: PropTypes.array,
  defaultValues: PropTypes.object
}

FieldAnalysisForm.defaultProps = {
  markerCoordinate: {},
  isEditing: false,
  loading: false,
  fieldsOptions: [],
  selectedField: '',
  setSelectedField: () => {},
  onSubmit: () => {},
  withoutSubmitButton: false,
  defaultValues: {}
}

export default FieldAnalysisForm
