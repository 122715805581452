import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid #E0E0E0;
  margin: 0;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Section = styled(Row)`
  padding: 20px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
export const Text = styled.span`
  font-size: 14px;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const Label = styled(Text)`
  color: ${ colors.darkGrey };
  font-weight: 600;
`

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.white
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    padding: '12px 13px 0 13px'
  }
})
