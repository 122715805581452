/* eslint-disable no-param-reassign */
import { Database, Q } from '@nozbe/watermelondb'
import moment from 'moment'

export const START_SYNC_DATE = moment('2021-01-01').format('X')

export function syncHistoricService(database: Database) {
  // Return last sync date or start sync date
  async function getSyncHistoric(table) {
    const response = await database.get('sync_historic')
      .query(Q.where('table', table))
      .fetch()

    return response[0]?.date || START_SYNC_DATE
  }

  async function updateSyncHistoric(table) {
    await database.write(async () => {
      const response = await database.get('sync_historic')
        .query(Q.where('table', table))
        .fetch()

      if (response[0]) {
        await response[0].update(sync => { sync.date = Number(moment().format('X')) })
      }

      await database.get('sync_historic').create(sync => {
        sync.table = table
        sync.date = Number(moment().format('X'))
      })
    })
  }

  function observeSyncHistoric(table) {
    return database.collections.get('sync_historic')
      .query(Q.where('table', table))
      .observeWithColumns(['isFinished'])
  }

  async function updatePagesData(table, percentage) {
    await database.write(async () => {
      const response = await database.get('sync_historic')
        .query(Q.where('table', table))
        .fetch()

      if (response[0]) {
        await response[0].update(sync => {
          sync.percentage = percentage
        })
      }

      await database.get('sync_historic').create(sync => {
        sync.table = table
        sync.percentage = percentage
      })
    })
  }


  return {
    updatePagesData,
    getSyncHistoric,
    updateSyncHistoric,
    observeSyncHistoric
  }
}
