import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  min-width: 500px;
`

export const FormContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 20px;
    flex-direction: column;
`

export const CodeContainer = styled.div`
  width: 100%;
  margin-top: -20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 20px;
`

export const ButtonContainer = styled.div`
  width: 80px;
`
export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
