/* eslint-disable no-nested-ternary */
import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import colors from '@smartcoop/styles/colors'

const useStyles = makeStyles(() => ({
  textField: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 15
  },
  labelSmall: {
    transform: 'translate(14px, 11px) scale(1)'
  },
  helperTextContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  disabled: {
    backgroundColor: colors.backgroundHtml
  },
  visibleButDisabled: {
    color: `${ colors.text } !important`
  },
  fieldsetDisabled: {
    '& fieldset': {
      borderColor: `${ colors.backgroundHtml } !important`
    }
  }
}))

export default useStyles

export const TextFieldStyled = styled(TextField)
  .attrs({
    FormHelperTextProps: {
      component: 'div',
      style: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0
      }
    }
  })
  .withConfig({
    shouldForwardProp: (propName) =>
      propName !== 'minWidth' && propName !== 'validateOnBlur'
  })`
  & input {
    min-width: ${ ({ minWidth }) => minWidth }px;
    color: ${ ({ visibleButDisabled, disabled }) => visibleButDisabled ? colors.text : disabled ? colors.mutedText : colors.text };
  }
`

export const HelperTextContainer = styled.div`
  display: flex;
  margin-top: 4px;
`

export const HelperTextIconContainer = styled.div`
  display: flex;
  margin-right: 5px;
  justify-content: flex-end;
`

export const HelperText = styled.div`
  font-size: 13px;
  color: ${ ({ error }) => error ? colors.error : colors.mutedText };
  font-style: ${ ({ italic }) => italic ? 'italic' : 'normal' };
`
