import React from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isString from 'lodash/isString'
import map from 'lodash/map'

import colors from '@smartcoop/styles/colors'
import Card from '@smartcoop/web-components/Card'
import FieldAnalysisStatus from '@smartcoop/web-components/FieldAnalysisStatus'
import PolygonToSvg from '@smartcoop/web-components/PolygonToSvg'

import {
  ContainerField,
  TextGroup,
  TextLeft,
  TextBold,
  Text,
  DataTextGroup,
  CropText,
  Row,
  TextRight
} from './styles'

const FieldAnalysisItem = (props) => {
  const {
    analysis,
    onClick,
    selected,
    t
  } = props

  const field = analysis?.field

  return field ? (
    <Card
      style={ { marginBottom: 17, marginTop: 0, cursor: 'pointer' } }
      cardStyle={ { padding: '14px', width: '100%', marginBottom: 0 } }
      selected={ selected }
      noBoxShadow
    >
      <ContainerField>
        <PolygonToSvg
          shape={ map(isString(field?.polygonCoordinates) ? JSON.parse(field?.polygonCoordinates) : field?.polygonCoordinates, ([ lat, lng ]) => ({ lat, lng })) }
          color={ colors.orange }
          width={ 40 }
          onClick={ onClick }
        />
        <TextGroup onClick={ onClick }>
          <TextLeft>
            <Row>
              <TextBold>{field?.fieldName}</TextBold>
            </Row>
            <DataTextGroup>
              <CropText style={ { marginRight: 3 } }>
                {moment(analysis?.dateOfCollection).format('DD/MM/YYYY')}
              </CropText>
              <CropText>
                {t(analysis?.analysisType)}
              </CropText>
            </DataTextGroup>
            <Text>{parseFloat(field?.area).toFixed(2)} ha</Text>
          </TextLeft>
          <TextRight>
            <Row>
              <TextBold>{analysis?.analysisCode}</TextBold>
            </Row>
            <FieldAnalysisStatus status={ analysis?.status } style={ { width:  '10vw', height: '40px' } }/>
          </TextRight>
        </TextGroup>
      </ContainerField>
    </Card>
  ) : null
}

FieldAnalysisItem.propTypes = {
  analysis: PropTypes.object,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  t: PropTypes.func
}

FieldAnalysisItem.defaultProps = {
  analysis: {},
  t: () => {},
  selected: false,
  onClick: () => {}
}

export default FieldAnalysisItem
