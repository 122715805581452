import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import I18n from '@smartcoop/i18n'
import { checked, bell } from '@smartcoop/icons'
import { MessagingActions } from '@smartcoop/stores/messaging'
import { selectLastNotifications, selectNewNotificationsCount } from '@smartcoop/stores/messaging/selectorMessaging'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import Notification from '@smartcoop/web-components/Notification'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { Container, HeaderContainer, FooterContainer, ButtonContainer, Title } from './styles'

const NewNotificationsList = ({ onSeeAllClick }) => {
  const dispatch = useCallback(useDispatch(), [])

  const notifications = useSelector(selectLastNotifications)
  const unreadCount = useSelector(selectNewNotificationsCount)

  const { routes } = useRoutes()

  const handleReadAllNotificationsClick = useCallback(() => {
    dispatch(MessagingActions.readAllNotifications())
  }, [dispatch])

  const handleNotificationClick = useCallback((notification) => {
    if (!notification.read) {
      dispatch(MessagingActions.readNotification(notification.id))
    }
  }, [dispatch])

  return (
    <Container>
      <HeaderContainer>
        <Icon icon={ bell } size={ 20 } />
        <I18n as={ Title } params={ { howMany: 2 } }>
          notification
        </I18n>
      </HeaderContainer>

      {!isEmpty(notifications) ? map(notifications, (notification) => (
        <Notification
          key={ notification.id }
          onClick={ handleNotificationClick }
          notification={ notification }
        />
      )): <I18n>There are no new notifications</I18n>}

      <FooterContainer reverse={ !unreadCount }>
        {!!unreadCount && (
          <div>
            <Button
              id="read-all-notifications"
              variant="outlined"
              size="small"
              onClick={ handleReadAllNotificationsClick }
              style={ { textTransform: 'none', fontSize: 14 } }
            >
              <ButtonContainer>
                <Icon icon={ checked } size={ 16 } />
                <I18n>check all as read</I18n>
              </ButtonContainer>
            </Button>
          </div>
        )}
        <div>
          <RouterLink to={ routes.notifications.path }>
            <Button
              id="see-all-notifications"
              variant="text"
              size="small"
              style={ {
                textTransform: 'none',
                fontSize: 14,
                marginLeft: 10,
                textDecoration: 'underline'
              } }
              onClick={ onSeeAllClick }
            >
              <I18n>see all</I18n>
            </Button>
          </RouterLink>
        </div>
      </FooterContainer>
    </Container>
  )
}

NewNotificationsList.propTypes = {
  onSeeAllClick: PropTypes.func
}

NewNotificationsList.defaultProps = {
  onSeeAllClick: () => {}
}

export default NewNotificationsList
