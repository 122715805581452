import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const registerProductGroup = ({ t }) => Yup.object().shape({
  name: flow(
    required({ t })
  )(Yup.string()),

  description: flow(
    required({ t })
  )(Yup.string()),

  cropsId: flow(
    // required({ t, isNotText: true })
  )(Yup.array().nullable())
})

export default registerProductGroup
