import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { TechnicalActions } from '@smartcoop/stores/technical'
import { selectTechnicalVisits } from '@smartcoop/stores/technical/selectorTechnical'
import { colors } from '@smartcoop/styles'
import Badge from '@smartcoop/web-components/Badge'
import DataTable from '@smartcoop/web-components/DataTable'
import VisitModeStatus from '@smartcoop/web-components/VisitModeStatus'
import PropertyTechnicalVisitModal from '@smartcoop/web-containers/modals/technical/PropertyTechnicalVisitModal/PropertyTechnicalVisitModal'

import { Container } from './styles'

const PropertyTechnicalVisitProducerList = ({ filters }) => {
  const { createDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const tableRef = useRef(null)
  const t = useT()
  const technicalVisits = useSelector(selectTechnicalVisits)
  const propertyId = useSelector(selectCurrentPropertyId)

  const [loading, setLoading] = useState(false)

  const filteredTechnicalVisits = useMemo(() => filter(technicalVisits, item => filters?.fieldId === item?.field?.id || item?.technicalCooperative[0]?.organizationId === filters?.organizationId || item?.technician?.id === filters?.technicianId ||isEmpty(filters) ),[filters, technicalVisits])

  const columns = useMemo(
    () => [
      {
        title: 'Data e Hora',
        field: '[0].visitDateTime',
        align: 'left',
        sorting: true,
        render: (row) => moment(row[0].visitDateTime || row[0]?.predictedDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
      },
      {
        title: 'Cooperativa',
        field: '[0].technicalCooperative[0].organization.tradeName',
        align: 'left',
        sorting: true,
        render: (row) => row[0]?.technicalCooperative[0]?.organization?.tradeName
      },
      {
        title: 'Técnico',
        field: '[0].technician.name',
        align: 'left',
        sorting: true,
        render: (row) => row[0]?.technician?.name
      },
      {
        title: 'Talhão',
        field: '[0].field.fieldName',
        align: 'left',
        sorting: true,
        render: (row) => join(map(row, item => item.field?.fieldName), ', ')
      },
      {
        title: 'Visita',
        field: '[0].visitMode',
        align: 'center',
        sorting: true,
        render: (row) => (
          <VisitModeStatus
            mode={ row[0]?.visitMode }
          />
        )
      },
      {
        title: t('status'),
        field: '[0].cropManagement',
        align: 'center',
        sorting: true,
        render: (row) => (
          <Badge
            backgroundColorBadge={ row[0]?.status === 'agendada' ? colors.secondary : colors.darkGrey  }
            colorBadge={ row[0]?.status === 'agendada' ? colors.secondary : colors.darkGrey  }
            style={ { paddingTop: 8, paddingBottom: 8 } }
          >
            {row[0]?.status === 'agendada' ? t('scheduled') : t('recommended') }
          </Badge>
        )
      }
    ], [t]
  )

  const dataTableOption = useMemo(
    () => ({
      grouping: true,
      defaultExpanded: false,
      hideGroupbar: true
    }),
    []
  )

  const onListLoad = useCallback(
    (page) => {
      dispatch(TechnicalActions.loadTechnicalVisits(
        { limit: 99999, page, propertyId, isProperty: true },
        () => setLoading(false),
        () => setLoading(false)
      ))
    },
    [dispatch, propertyId]
  )

  const openDetailsTechnicalVisit = useCallback(
    (_, row) => {
      createDialog({
        id: 'view-edit-technical-visit',
        Component: PropertyTechnicalVisitModal,
        props: {
          onSuccess: onListLoad,
          technicalVisit: row,
          disabled: true,
          readOnly: true
        }
      })
    },
    [createDialog, onListLoad]
  )


  useEffect(() => {
    onListLoad()
  }, [onListLoad])

  return(
    <Container>
      <DataTable
        tableRef={ tableRef }
        columns={ columns }
        data={  !isEmpty(filteredTechnicalVisits) && !loading  ? map(groupBy(orderBy([...filteredTechnicalVisits], ['visitDateTime', 'predictedDate'], ['desc', 'desc']), 'visitId'), item => item) : [] }
        onRowClick={ openDetailsTechnicalVisit }
        options={ dataTableOption }
        id="technical-visit-table"
      />
    </Container>
  )
}

PropertyTechnicalVisitProducerList.propTypes = {
  filters: PropTypes.object
}

PropertyTechnicalVisitProducerList.defaultProps = {
  filters: {}
}

export default PropertyTechnicalVisitProducerList
