import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const userConfigurePermission = ({ t, props }) => Yup.object().shape({

  permission: flow(
    required({ t })
  )(Yup.string()),

  financialDataAccess: props?.selectedPermission !== 2
    ? flow(
      required({ t })
    ) (Yup.string())
    : Yup.string().nullable()
})

export default userConfigurePermission
