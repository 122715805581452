import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import grazingCropManagementSchema from '@smartcoop/forms/schemas/management/grazingCropManagement.schema'
import { useT } from '@smartcoop/i18n'
import Form from '@smartcoop/web-components/Form'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputUnit from '@smartcoop/web-components/InputUnit'

const GrazingForm = forwardRef(({ defaultValues, handleSubmit, readOnly }, formRef) => {
  const t = useT()

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ grazingCropManagementSchema }
      containerStyle={ { height: 'auto' } }
      onSubmit={ handleSubmit }
    >
      <InputNumber
        name="numberOfAnimals"
        label={ t('number of animals') }
        maxLength={ 4 }
        maxValue={ 9999 }
        type="integer"
        fullWidth
        defaultValue={ defaultValues?.numberOfAnimals }
        disabled={ readOnly }
      />

      <InputUnit
        name="grazingTimePeriod"
        label={ t('grazing time period') }
        maxLength={ 4 }
        maxValue={ 9999 }
        unit="dia(s)"
        type="integer"
        fullWidth
        defaultValue={ defaultValues?.grazingTimePeriod }
        disabled={ readOnly }
      />
    </Form>
  )
})

GrazingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  defaultValues: PropTypes.object
}

GrazingForm.defaultProps = {
  readOnly: false,
  defaultValues: {}
}

export default GrazingForm
