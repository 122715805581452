import React, { useMemo } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'

import { useT } from '@smartcoop/i18n'
import { circleWithBorder } from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import { momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import Icon from '@smartcoop/web-components/Icon'
import {
  Title
} from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

const DemandStatusFragment = (props) => {
  const t = useT()

  const {
    openingDate,
    closingDate,
    proposalDeadlineDate,
    organizationDecisionLimitDate,
    deliveryDate,
    status
  } = props

  const steps = useMemo(
    () => [
      {
        label: t('opening'),
        color: colors.green,
        date: openingDate ? moment(openingDate).format(momentFriendlyDateTimeFormat) : moment().format(momentFriendlyDateTimeFormat)
      },
      {
        label: t('closing of demand'),
        color: (() => {
          if (status === 'aguardando_resposta') {
            return colors.green
          }

          if (status === 'recusada' || status === 'recusado' ) {
            return colors.green
          }

          if (status === 'aguardando_cooperativas') {
            return colors.green
          }

          if (status === 'finalizado') {
            return colors.green
          }

          if (status === 'aguardando_entrega') {
            return colors.green
          }

          if (status === 'proposta_aceita') {
            return colors.green
          }

          if (status === 'aberta') {
            return colors.yellow
          }

          return colors.white
        })(),
        date: closingDate ? moment(closingDate).format(momentFriendlyDateTimeFormat) : null
      },
      {
        label: t('deadline for proposal'),
        color: (() => {
          if (status === 'aguardando_resposta') {
            return colors.yellow
          }

          if (status === 'recusada' || status === 'recusado') {
            return colors.green
          }

          if (status === 'aguardando_cooperativas') {
            return colors.green
          }

          if (status === 'finalizado') {
            return colors.green
          }

          if (status === 'aguardando_entrega') {
            return colors.green
          }

          if (status === 'proposta_aceita') {
            return colors.green
          }

          return colors.white
        })(),
        date: proposalDeadlineDate ? moment(proposalDeadlineDate).format(momentFriendlyDateTimeFormat) : null
      },
      {
        label: t('organization decision limit'),
        color: (() => {
          if (status === 'recusada' || status === 'recusado') {
            return colors.red
          }

          if (status === 'aguardando_cooperativas') {
            return colors.yellow
          }

          if (status === 'finalizado') {
            return colors.green
          }

          if (status === 'aguardando_entrega') {
            return colors.green
          }

          if (status === 'proposta_aceita') {
            return colors.green
          }

          return colors.white
        })(),
        date: organizationDecisionLimitDate ? moment(organizationDecisionLimitDate).format(momentFriendlyDateTimeFormat) : null
      },
      {
        label: t('delivery', { howMany: 1 }),
        color: (() => {
          if (status === 'recusada' || status === 'recusado') {
            return colors.red
          }

          if (status === 'finalizado') {
            return colors.green
          }

          if (status === 'aguardando_entrega') {
            return colors.yellow
          }

          if (status === 'proposta_aceita') {
            return colors.yellow
          }

          return colors.white
        })(),
        date: deliveryDate ? moment(deliveryDate).format(momentFriendlyDateTimeFormat) : null
      }
    ],
    [closingDate, deliveryDate, openingDate, organizationDecisionLimitDate, proposalDeadlineDate, status, t]
  )

  return (
    <Box>
      <Stepper alternativeLabel>
        {steps.map(({ label, color, date }) => (
          <Step key={ label }>
            <StepLabel
              icon={ <Icon icon={ circleWithBorder } color={ color } /> }
              style={ { color } }
              active
            >
              <Title style={ { fontSize: 16, fontWeight: 600, marginBottom: 0 } }>{label}</Title>
              <Title style={ { fontSize: 14, fontWeight: 500, marginTop: 0 } }>{date}</Title>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

DemandStatusFragment.propTypes = {
  openingDate: PropTypes.string.isRequired,
  closingDate: PropTypes.string.isRequired,
  proposalDeadlineDate: PropTypes.string.isRequired,
  organizationDecisionLimitDate: PropTypes.string.isRequired,
  deliveryDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
}

DemandStatusFragment.defaultProps = {
}

export default DemandStatusFragment
