import React, { useCallback, forwardRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import propertyActivitySchema from '@smartcoop/forms/schemas/property/propertyActivity.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectOfflineProperty, selectActivities } from '@smartcoop/stores/property/selectorProperty'
import Button from '@smartcoop/web-components/Button'
import CheckboxGroup from '@smartcoop/web-components/CheckboxGroup'
import Form from '@smartcoop/web-components/Form'

import { Container, ButtonContainer } from './styles'

const PropertyActivityForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, onSubmit, style } = props

  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const property = useSelector(selectOfflineProperty)
  const activities = useSelector(selectActivities)

  const handleSubmit = useCallback(
    (data) => {
      dispatch(PropertyActions.updateOfflineProperty(
        data
      ))
      onSubmit()
    },
    [dispatch, onSubmit]
  )

  const activitiesOptions = useMemo(
    () => map(activities, activity => ({
      label: activity.name,
      value: activity.id
    })),
    [activities]
  )

  const defaultValue = useMemo(
    () => map(property?.activitiesDetails, activity => (
      activity.id
    )),
    [property]
  )

  return (
    <Container>
      <Form
        style={ { ...style } }
        ref={ formRef }
        schemaConstructor={ propertyActivitySchema }
        onSubmit={ handleSubmit }
      >
        <CheckboxGroup
          name="activities"
          label={ t('property activities') }
          options={ activitiesOptions }
          defaultValue={ defaultValue }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-property-identification-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
            >
              <I18n>next</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

PropertyActivityForm.propTypes = {
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  style: PropTypes.object
}

PropertyActivityForm.defaultProps = {
  onSubmit: () => {},
  withoutSubmitButton: false,
  style: {}
}

export default PropertyActivityForm
