import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  width: 100%;
  padding: 2vh 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  width: 40vw;
`

export const RowHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`

export const CheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width:100%;
`

export const Title = styled(Typography)`
  font-size: 18px;
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: 700;
  color: ${ colors.black };
`
