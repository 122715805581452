import React from 'react'

import PropTypes from 'prop-types'

import {
  checkDouble,
  checkSingle,
  clock
} from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import { ChatMessageStatus } from '@smartcoop/utils/constants'
import Icon from '@smartcoop/web-components/Icon'

const MessageStatusIcon = ({ status, size, id }) => {
  const getMessageStatusIcon = (messageStatus) => {
    switch(messageStatus) {
      case ChatMessageStatus.SENDED:
        return checkSingle
      case ChatMessageStatus.RECEIVED:
      case ChatMessageStatus.VISUALIZED:
        return checkDouble
      default:
        return clock
    }
  }

  return (
    <Icon
      id={ id }
      icon={ getMessageStatusIcon(status) }
      color={ status === ChatMessageStatus.VISUALIZED ? colors.secondary : colors.darkGrey }
      size={ size }
    />
  )
}

MessageStatusIcon.propTypes = {
  status: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.number
}

MessageStatusIcon.defaultProps = {
  status: '',
  id: undefined,
  size: 15
}

export default MessageStatusIcon
