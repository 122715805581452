import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'

import I18n from '@smartcoop/i18n'
import {
  emptyFilter,
  noFieldRegistered,
  filter as iconFilter
} from '@smartcoop/icons'
import EmptyState from '@smartcoop/web-components/EmptyState'
import GrowingSeasonHistoryItem from '@smartcoop/web-components/GrowingSeasonHistoryItem'
import Icon from '@smartcoop/web-components/Icon'
import InputSearch from '@smartcoop/web-components/InputSearch'
import Loader from '@smartcoop/web-components/Loader'

import {
  ContainerListFields,
  SearchContainer,
  ButtonFilter
} from './styles'

const FieldListHistory = (props) => {

  const {
    propertyHasFields,
    onChangeSearchFilter,
    filterText,
    t,
    fields,
    loading,
    filterFields,
    filters,
    handleFieldHistoryClick
  } = props

  const dispatch = useDispatch()

  const updatedFieldsWithChildrenPolygons = useMemo(() => {
    const response = filter(fields, item => item.childrenPolygonId)
    return uniqBy(response, 'fieldId')
  },[fields])

  const fieldsWithoutChildrenPolygon = useMemo(() => filter(fields, item => !item.childrenPolygonId),[fields])

  return (
    <>
      {propertyHasFields && (
        <SearchContainer>
          <InputSearch
            style={ { marginRight: 8, marginBottom: 0, flex: 5 } }
            adornmentStyle={ { marginRight: 15 } }
            detached
            onChange={ onChangeSearchFilter }
            value={ filterText }
            placeholder={ t('search') }
          />
          <ButtonFilter
            id="filter-field"
            style={ { marginBottom: 0 } }
            color={ isEmpty(filters) ? 'white' : 'secondary' }
            onClick={ filterFields }
            disabled={ loading }
          >
            <>
              <Icon
                style={ { marginRight: '8px' } }
                size={ 14 }
                icon={ iconFilter }
              />
              <I18n>filtrate</I18n>
            </>
          </ButtonFilter>
        </SearchContainer>
      )}

      <ContainerListFields isCenter={ isEmpty(fields) }>
        {loading ? (
          <Loader width={ 100 } />
        ) : (
          map([...fieldsWithoutChildrenPolygon, ...updatedFieldsWithChildrenPolygons], (item) => (
            <GrowingSeasonHistoryItem
              key={ item.id }
              growingSeason={ item }
              growingSeasons={ fields }
              onClick={ handleFieldHistoryClick }
              dispatch={ dispatch }
            />
          ))
        )}

        {!loading && !propertyHasFields && (
          <EmptyState
            text={ t('there are no closed growing seasons') }
            icon={ noFieldRegistered }
          />
        )}
        {!loading && propertyHasFields && isEmpty(fields) && (
          <EmptyState text={ t('no results found') } icon={ emptyFilter } />
        )}
      </ContainerListFields>
    </>
  )
}

FieldListHistory.propTypes = {
  propertyHasFields: PropTypes.bool.isRequired,
  onChangeSearchFilter: PropTypes.func.isRequired,
  filterFields: PropTypes.func.isRequired,
  handleFieldHistoryClick: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  filterText: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
}

export default FieldListHistory
