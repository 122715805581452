import React from 'react'

import PropTypes from 'prop-types'

import ChevronRight from '@material-ui/icons/ChevronRight'

import { colors } from '@smartcoop/styles'
import Card from '@smartcoop/web-components/Card'

import {
  Container,
  TextGroup,
  TextLeft,
  TextBold,
  Text,
  ViewContainer
} from './styles'

const ReportItem = (props) => {
  const {
    report,
    description,
    onClick
  } = props
  return (
    <Card
      style={ { marginBottom: 17, marginTop: 0, cursor: 'pointer' } }
      cardStyle={ { padding: '14px', width: '100%', marginBottom: 0, border: '2px solid transparent' } }
      onClick={ () => onClick({ report }) }
    >
      <Container>
        <TextGroup>
          <TextLeft>
            <TextBold>{report}</TextBold>
            <Text style={ { marginTop: 5 } }>{description}</Text>
          </TextLeft>
        </TextGroup>
        <ViewContainer>
          <ChevronRight size={ 40 } color={ colors.grey } />
        </ViewContainer>
      </Container>
    </Card>
  )
}

ReportItem.propTypes = {
  report: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func
}

ReportItem.defaultProps = {
  description: '',
  report: '',
  onClick: () => {}
}

export default ReportItem
