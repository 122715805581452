import { call, put, takeLatest, select } from 'redux-saga/effects'

import {
  getBestProposal as getBestProposalService,
  acceptBestProposal as acceptBestProposalService,
  refuseBestProposal as refuseBestProposalService
} from '@smartcoop/services/apis/smartcoopApi/resources/bestProposal'
import {
  createOrder,
  editJoinOrder as editJoinOrderService,
  joinOrder as joinOrderService,
  getOrderById,
  exitOrder as exitOrderService,
  acceptProposal as acceptProposalService,
  rejectProposals as rejectProposalsService,
  receiveProduct as receiveProductService,
  attachBankSlip as attachBankSlipService,
  deleteBankSlip as deleteBankSlipService,
  getPurchaseDemandsStatus
} from '@smartcoop/services/apis/smartcoopApi/resources/order'
import { getSupplierByProduct } from '@smartcoop/services/apis/smartcoopApi/resources/supplierProducts'

import { selectCurrentOrganization } from '../organization/selectorOrganization'
import { OrderActions, OrderTypes } from './duckOrder'
import {
  selectCurrentOrder,
  selectCurrentOrderPurchasesOfCurrentOrganization
} from './selectorOrder'

function* loadCurrentOrder({
  orderId,
  onSuccess = () => {},
  onError = () => {},
  onlyAccepted = null
}) {
  try {
    const { data } = yield call(getOrderById, { orderId }, { onlyAccepted })
    yield put(OrderActions.updateCurrentOrder(data, onSuccess))
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(OrderActions.orderError(error))
    yield call(onError, error)
  }
}

function* loadDemandsStatus({
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { data } = yield call(getPurchaseDemandsStatus, { description: true })
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(OrderActions.orderError(error))
    yield call(onError, error)
  }
}

function* loadSuppliersByProduct({
  productId = null,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { data } = yield call(getSupplierByProduct, { productId })
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(OrderActions.orderError(error))
    yield call(onError, error)
  }
}

function* saveOfflineOrder({
  order,
  onSuccess = () => {},
  onError = () => {}
}) {
  yield put(OrderActions.saveOrder(order, onSuccess, onError))
}

function* saveOrder({ order, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(createOrder, order, { orderId: order.id })

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield call(onError)
    yield put(OrderActions.orderError(error))
  }
}
function* joinOfflineOrder({
  order,
  isEditing,
  organizationPurchaseId,
  onSuccess = () => {},
  onError = () => {}
}) {
  yield put(
    OrderActions.joinOrder(
      order,
      isEditing,
      organizationPurchaseId,
      onSuccess,
      onError
    )
  )
}

function* joinOrder({
  order,
  isEditing,
  organizationPurchaseId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const currentOrder = yield select(selectCurrentOrder)

    yield call(!isEditing ? joinOrderService : editJoinOrderService, order, {
      orderId: currentOrder.id,
      organizationPurchaseId
    })

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(OrderActions.orderError(error))
    yield call(onError)
  }
}

function* exitOfflineOrder({ onSuccess = () => {}, onError = () => {} }) {
  yield put(OrderActions.exitOrder(onSuccess, onError))
}

function* exitOrder({ onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOrder = yield select(selectCurrentOrder)
    const currentOrderPurchase = yield select(
      selectCurrentOrderPurchasesOfCurrentOrganization
    )

    yield call(exitOrderService, {
      orderId: currentOrder.id,
      organizationPurchaseId: currentOrderPurchase.id
    })

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(OrderActions.orderError(error))
    yield call(onError)
  }
}

function* acceptOfflineProposal({ onSuccess = () => {}, onError = () => {} }) {
  yield put(OrderActions.acceptProposal(onSuccess, onError))
}

function* acceptProposal({
  proposalId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    yield call(acceptProposalService, { proposalId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(OrderActions.orderError(error))
    yield call(onError)
  }
}

function* refuseOfflineBestProposal({
  proposalId,
  deniedProposalReason,
  onSuccess = () => {},
  onError = () => {}
}) {
  yield put(OrderActions.refuseBestProposal(proposalId, deniedProposalReason, onSuccess, onError))
}

function* refuseBestProposal({
  proposalId,
  deniedProposalReason,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)

    yield call(
      refuseBestProposalService,
      { organizationId: currentOrganization.id, deniedProposalReason },
      { proposalId }
    )

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(OrderActions.orderError(error))
    yield call(onError)
  }
}

function* rejectOfflineProposals({ onSuccess = () => {}, onError = () => {} }) {
  yield put(OrderActions.rejectProposals(onSuccess, onError))
}

function* rejectProposals({
  mainPurchaseId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    yield call(rejectProposalsService, { mainPurchaseId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(OrderActions.orderError(error))
    yield call(onError)
  }
}

function* acceptOfflineBestProposal({
  proposalId,
  proposalPaymentFormId,
  onSuccess = () => {},
  onError = () => {}
}) {
  yield put(
    OrderActions.acceptBestProposal(
      proposalId,
      proposalPaymentFormId,
      onSuccess,
      onError
    )
  )
}

function* acceptBestProposal({
  proposalId,
  proposalPaymentFormId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)

    yield call(
      acceptBestProposalService,
      { organizationId: currentOrganization.id, proposalPaymentFormId },
      { proposalId }
    )

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(OrderActions.orderError(error))
    yield call(onError)
  }
}

function* loadBestProposal({
  orderId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { data } = yield call(getBestProposalService, {}, { orderId })

    yield put(OrderActions.updateBestProposal(data, onSuccess))
  } catch (err) {
    const error = err.message
    yield call(onError, error)
  }
}

function* acceptReceipt({
  params,
  deliveryLocationId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    yield call(receiveProductService, params, { deliveryLocationId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(OrderActions.orderError(error))
    yield call(onError, error)
  }
}

function* attachBankSlip({
  params,
  deliveryLocationId,
  supplierId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { data } = yield call(attachBankSlipService, params, {
      deliveryLocationId,
      supplierId
    })
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(OrderActions.orderError(error))
    yield call(onError, error)
  }
}

function* deleteBankSlip({
  bankSlipId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    yield call(deleteBankSlipService, { bankSlipId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(OrderActions.orderError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(OrderTypes.SAVE_OFFLINE_ORDER, saveOfflineOrder),
  takeLatest(OrderTypes.SAVE_ORDER, saveOrder),

  takeLatest(OrderTypes.JOIN_OFFLINE_ORDER, joinOfflineOrder),
  takeLatest(OrderTypes.JOIN_ORDER, joinOrder),

  takeLatest(OrderTypes.EXIT_OFFLINE_ORDER, exitOfflineOrder),
  takeLatest(OrderTypes.EXIT_ORDER, exitOrder),

  takeLatest(OrderTypes.LOAD_CURRENT_ORDER, loadCurrentOrder),

  takeLatest(OrderTypes.ACCEPT_PROPOSAL, acceptProposal),
  takeLatest(OrderTypes.ACCEPT_OFFLINE_PROPOSAL, acceptOfflineProposal),

  takeLatest(OrderTypes.REJECT_PROPOSALS, rejectProposals),
  takeLatest(OrderTypes.REJECT_OFFLINE_PROPOSALS, rejectOfflineProposals),
  takeLatest(OrderTypes.LOAD_BEST_PROPOSAL, loadBestProposal),

  takeLatest(
    OrderTypes.REFUSE_OFFLINE_BEST_PROPOSAL,
    refuseOfflineBestProposal
  ),
  takeLatest(OrderTypes.REFUSE_BEST_PROPOSAL, refuseBestProposal),

  takeLatest(
    OrderTypes.ACCEPT_OFFLINE_BEST_PROPOSAL,
    acceptOfflineBestProposal
  ),
  takeLatest(OrderTypes.ACCEPT_BEST_PROPOSAL, acceptBestProposal),

  takeLatest(OrderTypes.ACCEPT_RECEIPT, acceptReceipt),

  takeLatest(OrderTypes.LOAD_DEMANDS_STATUS, loadDemandsStatus),

  takeLatest(OrderTypes.LOAD_SUPPLIERS_BY_PRODUCT, loadSuppliersByProduct),

  takeLatest(OrderTypes.ATTACH_BANK_SLIP, attachBankSlip),
  takeLatest(OrderTypes.DELETE_BANK_SLIP, deleteBankSlip)
]
