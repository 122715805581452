import React, { useRef, useCallback, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import I18n, { useT } from '@meta-react/i18n'

import { isEmpty, values } from 'lodash'

import { useDialog } from '@smartcoop/dialog'
import { getLots as getLotsService } from '@smartcoop/services/apis/smartcoopApi/resources/lot'
import { useSnackbar } from '@smartcoop/snackbar'
import { LotActions } from '@smartcoop/stores/lot'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import { LOT_SLUG_OPTIONS_OBJECT } from '@smartcoop/utils/constants'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import FilterButton from '@smartcoop/web-components/FilterButton'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import FilterLotModal from '@smartcoop/web-containers/modals/dairyFarm/FilterLotModal'
import RegisterLotModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterLotModal'

import { Top } from './styles'

const LotList = () => {
  const { createDialog } = useDialog()
  const t = useT()
  const tableRef = useRef(null)
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()

  const [filters, setFilters] = useState({})
  const currentProperty = useSelector(selectCurrentProperty)
  const userWrite = useSelector(selectUserCanWrite)

  const columns = useMemo(
    () => [
      {
        title: t('code'),
        field: 'code'
      },
      {
        title: t('lot name'),
        field: 'name'
      },
      {
        title: t('lot type'),
        field: 'slug',
        render: (row) => LOT_SLUG_OPTIONS_OBJECT[row?.slug]
      },
      {
        title: t('nº {this}', { this: t('trinkets') }),
        field: 'totalAnimals'
      }
    ], [t]
  )

  const urlParams = useMemo(
    () => ({
      propertyId: currentProperty?.id
    }), [currentProperty]
  )

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )

  const handleSetFilters = useCallback(
    (filterValues) => {
      setFilters({
        ...filterValues,
        q: filterValues?.name ?? null
      })
    }, []
  )

  const openFilterModal = useCallback(
    () => {
      createDialog({
        id: 'filter-fields',
        Component: FilterLotModal,
        props: {
          onSubmit: handleSetFilters,
          filters
        }
      })
    },
    [createDialog, filters, handleSetFilters]
  )

  const onDelete = useCallback(
    (lot) => {
      dispatch(
        LotActions.deleteLot(lot.id,
          () => {
            snackbar.success(
              t('your {this} was deleted', {
                howMany: 1,
                gender: 'male',
                this: t('lot', { howMany: 1 })
              }))
            reloadDataTable()
          }
        )
      )
    }, [dispatch, reloadDataTable, snackbar, t]
  )

  const handleDelete = useCallback(
    (_, lot) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => onDelete(lot),
          textWarning: t('are you sure you want to remove the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('lot', { howMany: 1 })
          })
        }
      })
    },
    [createDialog, onDelete, t]
  )

  const openCreateModal = useCallback(
    (_, lot = {}) => {
      createDialog({
        id: 'create-fields',
        Component: RegisterLotModal,
        props: {
          onSubmit: reloadDataTable,
          lot
        }
      })
    },
    [createDialog, reloadDataTable]
  )

  return (
    <>
      <Top>
        <div>
          <FilterButton
            onClick={ openFilterModal }
            isActive={ !values(filters).every(isEmpty) }
          />
        </div>
        <div>
          <Button
            id="register-lot"
            onClick={ openCreateModal }
            color="secondary"
            disabled={ !userWrite }
          >
            <I18n>register lots</I18n>
          </Button>
        </div>
      </Top>
      <div style={ { marginTop: 20 } }>
        <DataTable
          columns={ columns }
          data={ getLotsService }
          queryParams={ filters }
          tableRef={ tableRef }
          urlParams={ urlParams }
          onDeleteClick={ handleDelete }
          conditionToDelete={ (row) => !row.defaultLot }
          disabledEdit={ (row) => row.defaultLot }
          onEditClick={ openCreateModal }
          disabled={ !userWrite }
          id="lot-list-table"
        />
      </div>
    </>
  )
}

export default LotList
