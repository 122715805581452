import React, { useState, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { edit, trash } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { BarterActions } from '@smartcoop/stores/barter'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import InputQuantity from '@smartcoop/web-components/InputQuantity'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SignatureOrderModal from '@smartcoop/web-containers/modals/commercialization/SignatureOrderModal'

import {
  Container,
  LeftItem,
  Text,
  Row,
  RightItem
} from './styles'

const Footer = ({
  barterOrderNumber,
  packageQuantity: externalPackageQuantity,
  isPackage,
  price,
  closeModal,
  handleEditBarter,
  measureUnit,
  disabled
}) => {
  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()

  const [packageQuantity, setPackageQuantity] = useState(externalPackageQuantity)
  const [isEditing, setIsEditing] = useState(false)

  const totalValue = useMemo(
    () => price ? `${ packageQuantity*price } ${ measureUnit }` : 0,
    [measureUnit, packageQuantity, price]
  )

  const onEditMode = useCallback(
    () => {
      if (isPackage) {
        setIsEditing(true)

      } else {
        handleEditBarter()
        closeModal()
      }
    },
    [closeModal, handleEditBarter, isPackage]
  )

  const onRemove = useCallback(
    () => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            createDialog({
              id: 'signature-order-modal',
              Component: SignatureOrderModal,
              props: {
                onSuccess: () => {
                  dispatch(BarterActions.deleteBarter(
                    barterOrderNumber,
                    () => {
                      snackbar.success(t('your {this} was deleted', {
                        this: t('barter'),
                        gender: 'male',
                        howMany: 1
                      }))
                    },
                    (error) => snackbar.error(error)
                  ))
                  closeModal()
                }
              }
            })
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('barter')
          })
        }
      })
    },
    [barterOrderNumber, closeModal, createDialog, dispatch, snackbar, t]
  )

  const onEditSave = useCallback(
    () => {
      createDialog({
        id: 'signature-order-modal',
        Component: SignatureOrderModal,
        props: {
          onSuccess: () => {
            dispatch(BarterActions.joinBarterPackage(
              {
                barterOrderNumber,
                packageQuantity
              },
              () => {
                snackbar.success(t('your {this} was edited', {
                  this: t('barter'),
                  gender: 'male',
                  howMany: 1
                }))
              },
              (error) => snackbar.error(error)
            ))
            closeModal()
          }
        }
      })
    },
    [barterOrderNumber, closeModal, createDialog, dispatch, packageQuantity, snackbar, t]
  )

  return (
    <Container>
      <LeftItem>
        {isPackage && (
          <Row style={ { alignItems: 'center' } }>
            <I18n as={ Text } style={ { fontWeight: 600 } } params={ { this: t('package quantity'), that: !isEditing ? packageQuantity : '' } }>{'{this}: {that}'}</I18n>
          </Row>
        )}
        <Row style={ { marginLeft: 5 } }>
          <>
            {isEditing && (
              <InputQuantity
                value={ packageQuantity }
                onChange={ setPackageQuantity }
                min={ 1 }
                max={ 999 }
              />
            )}
            {isPackage && Number(price) > 0 && (
              <Row style={ { alignItems: 'center', marginLeft: 5 } }>
                <I18n as={ Text } params={ { howMany: 1 } }>total value</I18n>
                <Text style={ { marginLeft: 5 } }>{totalValue}</Text>
              </Row>
            )}
          </>
        </Row>
      </LeftItem>
      <RightItem>
        <>
          {!isEditing && (
            <ButtonsContainer style={ { justifyContent: 'flex-end' } }>
              <Button
                id="filter"
                onClick={ onEditMode }
                color="white"
                style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 20 } }
                disabled={ disabled }
              >
                <>
                  <Icon style={ { paddingRight: 6 } } icon={ edit } size={ 14 } />
                  <I18n>edit</I18n>
                </>
              </Button>
              <Button
                id="delete"
                color="white"
                onClick={ onRemove }
                style={ { flex: 'none', whiteSpace: 'nowrap' } }
                disabled={ disabled }
              >
                <>
                  <Icon style={ { paddingRight: 6 } } icon={ trash } color={ colors.red } size={ 14 } />
                  <I18n>delete</I18n>
                </>
              </Button>
            </ButtonsContainer>
          )}
          {isEditing && (
            <Button
              id="edit-save"
              onClick={ onEditSave }
              color="secondary"
              style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 20 } }
              disabled={ disabled }
            >
              <>
                <I18n>save</I18n>
              </>
            </Button>
          )}
        </>
      </RightItem>
    </Container>
  )
}

Footer.propTypes = {
  barterOrderNumber: PropTypes.number.isRequired,
  packageQuantity: PropTypes.number,
  isPackage: PropTypes.bool,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  closeModal: PropTypes.func,
  handleEditBarter: PropTypes.func,
  measureUnit: PropTypes.string,
  disabled: PropTypes.bool
}

Footer.defaultProps = {
  isPackage: false,
  packageQuantity: 0,
  price: '',
  closeModal: () => {},
  handleEditBarter: () => {},
  measureUnit: '',
  disabled: false
}

export default Footer