/* eslint-disable no-dupe-keys */
import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
`

export const GrowingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`
export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const GrowingItem = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

export const TextSowingYear = styled.div`
  margin: 10px 0;
  font-size: 16px;
  font-weight: 600;
`

export const TextFieldInfo = styled.div`
  margin: 5px;
  font-size: 14px;
  font-weight: 600;
`

export const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TitleCropManagements = styled.p`
  font-size: 18px;
  font-weight: 600;
  flex: 1;
  align-self: flex-start;
`

export const HeaderCropManagementList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContainerListManagements = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: ${ ({ limit }) => limit ? '42vh' : null };
`

const useStyles = makeStyles(() => ({
  mapImage: {
    imageRendering: 'pixelated',
    msInterpolationMode: 'nearest-neighborpixelated'
  },
  badge: {
    fontSize: 10,
    width: 10,
    maxWidth: 10
  }
}))

export default useStyles

