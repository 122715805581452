import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import { colors } from '@smartcoop/styles'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid ${ colors.lightGrey };
  margin: 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
`

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.backgroundHtml
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    padding: '12px 13px 0 13px'
  }
})

