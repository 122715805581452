import React, { useState, useRef, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import { isEmpty, isUndefined } from 'lodash'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { filter, arrowLeft } from '@smartcoop/icons'
import {
  getMilkQualities as getMilkQualitiesService
} from '@smartcoop/services/apis/smartcoopApi/resources/dairyFarm'
import { useSnackbar } from '@smartcoop/snackbar'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import { formatNumber } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import Loader from '@smartcoop/web-components/Loader'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import FilterMilkQualityModal from '@smartcoop/web-containers/modals/dairyFarm/FilterMilkQualityModal'
import RegisterMilkQualityModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterMilkQualityModal'

import { Top, Container, Row, Title, SingleButtonContainer, WarningDiv } from './styles'


const ListMilkQualityTypeItem = (props) => {
  const { groupedItems } = props
  const t = useT()

  const columns = useMemo(
    () => [
      {
        title: t('type'),
        field: 'type',
        render: (row) => t(row.type)
      },
      {
        title: t('result'),
        field: 'result',
        render: (row) => formatNumber(row.result)
      },
      {
        title: t('limit'),
        field: 'limit',
        render: (row) => isUndefined(row.limit) ? '-' : formatNumber(row.limit)
      }
    ], [t]
  )

  return (
    <DataTable
      darkMode
      data={ groupedItems }
      columns={ columns }
      options={ { paging: false } }
      id="list-milk-quality-type-table"
    />
  )
}


ListMilkQualityTypeItem.propTypes = {
  groupedItems: PropTypes.array
}

ListMilkQualityTypeItem.defaultProps = {
  groupedItems: []
}


const ListMilkQualityScreen = () => {
  const dispatch = useCallback(useDispatch(), [])
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()
  const tableRef = useRef(null)
  const history = useHistory()
  const t = useT()


  const currentProperty = useSelector(selectCurrentProperty)
  const userWrite = useSelector(selectUserCanWrite)

  const [filters, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const dairyFarm = useMemo(
    () => currentProperty?.dairyFarm[0],
    [currentProperty]
  )

  const urlParams = useMemo(
    () => ({ dairyFarmId: dairyFarm?.id }),
    [dairyFarm]
  )

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )

  const columns = useMemo(
    () => [
      {
        title: t('date', { howMany: 1 }),
        field: 'date',
        sorting: true,
        defaultSort: 'desc',
        render: ({ date }) => moment(date, momentBackDateFormat).format(momentFriendlyDateFormat)
      },
      {
        sorting: true,
        title: t('state registration'),
        field: 'userStateRegistration.stateRegistration',
        render: (row) => row?.userStateRegistration?.stateRegistration ?? '-'
      }
    ], [t]
  )

  const filterData = useCallback(
    (data) => {
      setFilters(data)
    }, []
  )

  const registerQuality = useCallback(
    (data = {}) => {
      createDialog({
        id: 'register-quality',
        Component: RegisterMilkQualityModal,
        props: {
          data,
          reloadDataTable
        }
      })},
    [createDialog, reloadDataTable]
  )

  const filterQuality = useCallback(
    () => {
      createDialog({
        id: 'filter-quality',
        Component: FilterMilkQualityModal,
        props: {
          onSubmit: filterData,
          filters
        }
      })
    },
    [createDialog, filterData, filters]
  )

  const handleQualityDelete = useCallback(
    (data) => {
      createDialog({
        id: 'confirm-delete-quality',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            setIsLoading(true)
            dispatch(DairyFarmActions.deleteMilkQuality(
              data.id,
              () => {
                setIsLoading(false)
                reloadDataTable()
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'female',
                    this: t('analysis', { howMany: 1 })
                  })
                )
              },
              () => {
                setIsLoading(false)
                reloadDataTable()
              }
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            gender: 'female',
            howMany: 1,
            this: t('analysis', { howMany: 1 })
          })
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, snackbar, t]
  )

  const onDeleteClick = useCallback(
    (_, data) => handleQualityDelete(data),
    [handleQualityDelete]
  )

  const isCooperative = useCallback(
    (row) => !isEmpty(row.organizationId),
    []
  )

  return (
    isLoading ? <Loader width={ 100 } /> :
      <Container>
        <Top>
          <IconButton
            tooltip={ t('go back') }
            onClick={ () => history.goBack() }
          >
            <Icon icon={ arrowLeft } size={ 16 } />
          </IconButton>
          <Title>
            <I18n>register analysis</I18n>
          </Title>
        </Top>
        <Row>
          <SingleButtonContainer>
            { (isEmpty(dairyFarm) || isCooperative(dairyFarm)) && <WarningDiv>
              <I18n>data inclusion will be done by the organization</I18n>
            </WarningDiv>}
            <Button
              id="filter"
              color="white"
              style={ {
                flex: 'none',
                whiteSpace: 'nowrap',
                marginRight: 10,
                backgroundColor: isEmpty(filters) ? colors.white : colors.secondary
              } }
              onClick={ filterQuality }
              disabled={ isEmpty(dairyFarm) }
            >
              <>
                <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 }/>
                <I18n>filtrate</I18n>
              </>
            </Button>
            <Button
              id="new-milk-quality"
              color="secondary"
              style={ { flex: 'none', whiteSpace: 'nowrap' } }
              onClick={ () => registerQuality() }
              disabled={ isEmpty(dairyFarm) || isCooperative(dairyFarm) || !userWrite }
            >
              <I18n>register quality</I18n>
            </Button>
          </SingleButtonContainer>
        </Row>

        {!isEmpty(dairyFarm) && (
          <DataTable
            data={ getMilkQualitiesService }
            columns={ columns }
            tableRef={ tableRef }
            urlParams={ urlParams }
            queryParams={ filters }
            detailPanel={ row => <ListMilkQualityTypeItem groupedItems={ row.groupedItems } /> }
            onDeleteClick={ onDeleteClick }
            onEditClick={ (_, row) => { registerQuality(row) } }
            disabledEdit={ (row) => isCooperative(row) || !userWrite }
            conditionToDelete={ (row) => !isCooperative(row) && userWrite }
            id="list-milk-quality-table"
          />
        )}
      </Container>
  )
}

export default ListMilkQualityScreen
