import React, { useMemo, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import truncate from 'lodash/truncate'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import {
  getBartersByOrg as getBartersByOrgService
} from '@smartcoop/services/apis/smartcoopApi/resources/barter'
import { momentBackDateTimeFormat } from '@smartcoop/utils/dates'
import Badge from '@smartcoop/web-components/Badge'
import DataTable from '@smartcoop/web-components/DataTable'
import BarterDetailsModal from '@smartcoop/web-containers/modals/commercialization/BarterDetailsModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import Footer from './Footer'

const BarterListFragment = ({
  filters,
  urlParams
}) => {
  const history = useHistory()
  const { routes } = useRoutes()
  const tableRef = useRef(null)

  const t = useT()
  const { createDialog } = useDialog()

  const columns = useMemo(
    () => [
      {
        title: t('name'),
        field: 'barterName',
        cellStyle: { fontWeight: 'bold' },
        render: (row) => row.barterName ? `${ row.barterName } ${ row.packageType ? '(coop)' : '' }` : t('my package')
      },
      {
        title: t('code'),
        field: 'barterOrderNumber'
      },
      {
        title: t('launch'),
        field: 'releaseDate',
        render: (row) => (moment(row.releaseDate, momentBackDateTimeFormat).format('DD/MM/YYYY'))
      },
      {
        title: t('crop', { howMany: 1 }),
        field: 'cropName'
      },
      {
        title: t('products'),
        render: (row) => truncate(map(row.barterProducts, ({ productName }) => productName).join(', '), 32)
      },
      {
        title: t('status'),
        field: 'status.statusName',
        render: (row) => (
          <Badge
            backgroundColorBadge={ row.status.statusColor }
            colorBadge={ row.status.statusColor }
          >
            {row.status.statusName}
          </Badge>
        )
      }
    ], [t]
  )

  const onRowClick = useCallback(
    (_, item) => {
      createDialog({
        id: 'baerter-details-modal',
        Component: BarterDetailsModal,
        props: {
          barterOrderNumber: item.barterOrderNumber,
          title: t('barter'),
          onClose: () => {
            const { query } = tableRef.current.state
            tableRef.current.onQueryChange({
              ...query,
              page: 0
            })},
          FooterComponent:
            (props) =>
              <Footer
                { ...props }
                barterOrderNumber={ item.barterOrderNumber }
                measureUnit={ item?.measureUnit }
                handleEditBarter={
                  () => history.push(routes.editBarter.path.replace(':barterOrderNumber', item.barterOrderNumber))
                }
              />
        }
      })
    },
    [createDialog, history, routes.editBarter.path, t]
  )

  return(
    <DataTable
      tableRef={ tableRef }
      onRowClick={ onRowClick }
      data={ getBartersByOrgService }
      queryParams ={ filters }
      columns={ columns }
      urlParams={ urlParams }
      id="barter-list-table"
    />
  )
}

BarterListFragment.propTypes = {
  filters: PropTypes.object,
  urlParams: PropTypes.object
}

BarterListFragment.defaultProps = {
  filters: {},
  urlParams: {}

}

export default BarterListFragment
