import React, { useRef, useEffect, useCallback, memo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import I18n, { useT } from '@smartcoop/i18n'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import Button from '@smartcoop/web-components/Button'
import RecoverPasswordForm from '@smartcoop/web-containers/forms/auth/RecoverPasswordForm'
import { withLayout } from '@smartcoop/web-containers/layouts'
import { Item, Header, Title, Text, ButtonsContainer } from '@smartcoop/web-containers/layouts/GuestLayout/theme'
import { useRoutes } from '@smartcoop/web-containers/routes/guest'

const RecoverPasswordScreen = () => {
  const formRef = useRef(null)

  const t = useT()
  const dispatch = useCallback(useDispatch())

  const history = useHistory()
  const { routes } = useRoutes()

  const handleSubmit = useCallback(
    () => {
      history.push(routes.resetCode.path, { mode: 'recover' })
    },
    [history, routes.resetCode.path]
  )

  useEffect(
    () => {
      dispatch(AuthenticationActions.resetAuthentication())
    },
    [dispatch]
  )

  return (
    <>
      <Item>
        <Header>
          <Title style={ { alignSelf: 'flex-end' } }>
            <I18n>no problem</I18n>
          </Title>
        </Header>

        <Text>
          {t('to recover your password, confirm your data').replace('\n', ' ')}
        </Text>

        <RecoverPasswordForm
          ref={ formRef }
          onSubmit={ handleSubmit }
          withoutSubmitButton
        />
      </Item>

      <Item>
        <ButtonsContainer>
          <Button
            id="go-back"
            onClick={ () => history.goBack() }
            style={ { width: '48%', flex: '0 0 auto' } }
            variant="outlined"
          >
            <I18n>go back</I18n>
          </Button>
          <Button
            id="recover-password-submit"
            onClick={ () => formRef.current.submit() }
            style={ { width: '48%', flex: '0 0 auto' } }
          >
            <I18n>next</I18n>
          </Button>
        </ButtonsContainer>
      </Item>
    </>
  )
}

export default memo(withLayout('guest')(RecoverPasswordScreen))
