import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import required from '@smartcoop/forms/validators/required.validator'

const medicationControl = ({ t, props }) => Yup.object().shape({
  animalIds: flow(
    required({ t })
  )(Yup.string()),

  type: flow(
    required({ t })
  )(Yup.string()),

  animalDiseaseIds: flow(
    required({ t })
  )(Yup.string()),

  productId: Yup.string().when('type', {
    is: value => (value === 'calendario-de-vacinacao' && props?.realizado) || (value !== 'calendario-de-vacinacao'),
    then: flow(required({ t }))(Yup.string()),
    otherwise: Yup.string()
  }),

  dose: Yup.string().when('type', {
    is: value => (value === 'calendario-de-vacinacao' && props?.realizado) || (value !== 'calendario-de-vacinacao'),
    then: flow(required({ t }))(Yup.string()),
    otherwise: Yup.string()
  }),

  doseUnit: Yup.string().when('type', {
    is: value => (value === 'calendario-de-vacinacao' && props?.realizado) || (value !== 'calendario-de-vacinacao'),
    then: flow(required({ t }))(Yup.string()),
    otherwise: Yup.string()
  }),

  applicationMode: Yup.string().when('type', {
    is: value => (value === 'calendario-de-vacinacao' && props?.realizado) || (value !== 'calendario-de-vacinacao'),
    then: flow(required({ t }))(Yup.string()),
    otherwise: Yup.string()
  }),

  medicationStartDate: flow(
    required({ t }),
    date({ t })
  )(Yup.string()),

  treatmentInterval: Yup.string().when('type', {
    is: value => (value === 'calendario-de-vacinacao' && props?.realizado) || (value !== 'calendario-de-vacinacao'),
    then: flow(required({ t }))(Yup.string()),
    otherwise: Yup.string()
  }),

  treatmentTime: Yup.string().when('type', {
    is: value => (value === 'calendario-de-vacinacao' && props?.realizado) || (value !== 'calendario-de-vacinacao'),
    then: flow(required({ t }))(Yup.string()),
    otherwise: Yup.string()
  }),

  carencyTime: Yup.string().when('type', {
    is: value => (value === 'calendario-de-vacinacao' && props?.realizado) || (value !== 'calendario-de-vacinacao'),
    then: flow(required({ t }))(Yup.string()),
    otherwise: Yup.string()
  }),

  ccsPrepartum: Yup.string(),

  ccsPostpartum: Yup.string(),

  observation: flow(
  )(Yup.string())
})

export default medicationControl
