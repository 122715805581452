import React, { memo } from 'react'

import TermsFragment from '@smartcoop/web-containers/fragments/TermsFragment'
import { withLayout } from '@smartcoop/web-containers/layouts'

const UseTermScreen = () =>
  (
    <TermsFragment slug="use-term" />
  )

export default memo(withLayout('guest')(UseTermScreen))
