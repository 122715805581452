import React, { forwardRef, useMemo } from 'react'

import PropTypes from 'prop-types'

import InputSelectForm from './InputSelectForm'
import InputSelectStyled from './InputSelectStyled'

const InputSelect = forwardRef((props, ref) => {
  const {
    detached,
    value,
    ...rest
  } = props

  const Input = useMemo(
    () => detached ? InputSelectStyled : InputSelectForm,
    [detached]
  )

  return (
    <Input
      ref={ ref }
      value={ value }
      { ...rest }
    />
  )
})

InputSelect.propTypes = {
  detached: PropTypes.bool,
  value: PropTypes.any,
  queryParams: PropTypes.object,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func
  ])
}

InputSelect.defaultProps ={
  detached: false,
  value: '',
  queryParams: {},
  options: []
}

export default InputSelect
