import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0%;
`

export const Title = styled(Typography)`
  font-family: 'Montserrat';
  letter-spacing: 0;
  margin: 0;
  color: ${ colors.black };
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Item = styled.div`
  padding: 5px;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`
