import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { find } from 'lodash'

import I18n from '@smartcoop/i18n'
import { ProposalStatusCode } from '@smartcoop/utils/constants'
import { formatCpfCnpj } from '@smartcoop/utils/formatters'
import DataTable from '@smartcoop/web-components/DataTable'
import Modal from '@smartcoop/web-components/Modal'

import { Badge } from './styles'

const ViewListSuppliersByProducts = (props) => {
  const { id, open, data, proposals } = props

  const columns = useMemo(
    () => [
      {
        title: 'CNPJ',
        field: 'companyDocument',
        sorting: true,
        render: (row) => formatCpfCnpj(row.companyDocument)
      },
      {
        title: 'Razão Social',
        field: 'tradeName',
        defaultSort: 'asc',
        sorting: true
      },
      {
        title: 'Proposta',
        field: 'proposal',
        render: (row) => {
          const currentProposal = find(proposals, proposal => proposal?.supplierId === row.id)

          if(!currentProposal) {
            return (
              <Badge
                backgroundColorBadge="(228, 29, 27, 0.1)"
                colorBadge="(228, 29, 27, 1)"
              >
                <I18n>no</I18n>
              </Badge>
            )
          }

          if(currentProposal?.statusId === ProposalStatusCode.REJEITADO || currentProposal?.statusId === ProposalStatusCode.INVALID) {
            return (
              <Badge
                backgroundColorBadge="(89,89,89, 0.1)"
                colorBadge="(89,89,89, 1)"
              >
                <I18n params={ { gender: 'female' } }>rejected</I18n>
              </Badge>
            )
          }

          return (
            <Badge
              backgroundColorBadge="(25, 156, 216, 0.1)"
              colorBadge="(25, 156, 216, 1)"
            >
              <I18n>yes</I18n>
            </Badge>
          )
        }
      }
    ],
    [proposals]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      contentContainerStyle={ { width: 600 } }
      disableFullScreen
    >
      <div style={ { fontSize: 18, fontWeight: 600, marginBottom: 20 } }>
        <I18n>open suppliers by products</I18n>
      </div>
      <DataTable
        options={ { paging: false } }
        data={ data }
        columns={ columns }
        id="view-list-suppliers-by-product-table"
      />
    </Modal>
  )
}

ViewListSuppliersByProducts.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.string.isRequired,
  proposals: PropTypes.array
}

ViewListSuppliersByProducts.defaultProps = {
  proposals: []
}
export default ViewListSuppliersByProducts
