/* eslint-disable no-unused-vars */
import {
  call,
  put,
  select,
  take,
  takeLatest,
  delay,
  race
} from 'redux-saga/effects'

import trimMask from '@meta-awesome/functions/src/trimMask'
import JwtDecode from 'jwt-decode'

import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import size from 'lodash/size'
import split from 'lodash/split'
import spread from 'lodash/spread'
import toString from 'lodash/toString'
import union from 'lodash/union'
import uniqBy from 'lodash/uniqBy'

import { syncService } from '@smartcoop/database/services/syncService'
import {
  login as loginService,
  logout as logoutService,
  createVerificationCode,
  validateVerificationCode as validateVerificationCodeService,
  getTokenByOrganization as getTokenByOrganizationService,
  getTerms as getTermsService,
  getUserOnboarding as getUserOnboardingService,
  updateTerm as updateTermService
} from '@smartcoop/services/apis/smartcoopApi/resources/authentication'
import { getPermissions as getPermissionsService } from '@smartcoop/services/apis/smartcoopApi/resources/permissions'
import {
  getUserByCpf,
  updateUser as updateUserService
} from '@smartcoop/services/apis/smartcoopApi/resources/user'

import { selectDatabase } from '../database/selectorDatabase'
import rsf from '../firebase/rsf'
import { MessagingTypes, MessagingActions } from '../messaging/duckMessaging'
import { selectFirebaseInitialized } from '../messaging/selectorMessaging'
import { ModuleActions } from '../module/duckModule'
import { selectCurrentModule } from '../module/selectorModule'
import { OrganizationActions } from '../organization'
import { selectCurrentOrganization } from '../organization/selectorOrganization'
import { UserActions } from '../user'
import { selectUser } from '../user/selectorUser'
import {
  AuthenticationActions,
  AuthenticationTypes
} from './duckAuthentication'
import {
  selectAuthenticated,
  selectAccessToken,
  selectRefreshToken,
  selectFirebaseCustomToken,
  selectFirebaseIdToken,
  selectFirebaseAuthenticated
} from './selectorAuthentication'

function* putUserData(doc) {
  const user = yield call(getUserByCpf, {}, { document: doc })

  // Save user on local database
  const database = yield select(selectDatabase)

  if (database) {
    yield call(syncService(database).clearDatabase)
    yield call(syncService(database).syncUser, user)
  }

  yield put(UserActions.loadUserSuccess(
    user
  ))
}

function* login({
  document: doc,
  password,
  onSuccess = () => { },
  onError = () => { }
}) {
  try {
    const { posts, timeout } = yield race({
      posts: call(loginService, {
        document: doc,
        password
      }),
      timeout: delay(10000)
    })
    if (timeout) throw new Error('verify your connection and try again')

    const {
      accessToken,
      refreshToken,
      tokenType,
      iaToken,
      firebaseCustomToken
    } = posts

    yield put(
      AuthenticationActions.refreshTokenSuccess(
        `${ tokenType } ${ accessToken }`,
        refreshToken,
        iaToken
      )
    )

    yield delay(200)

    yield call(putUserData, doc)

    yield put(AuthenticationActions.loadPermissions())

    yield put(AuthenticationActions.setFirebaseCustomToken(firebaseCustomToken))

    yield call(onSuccess)

    yield put(AuthenticationActions.loginSuccess())
  } catch (err) {
    yield put(AuthenticationActions.authenticationError(err?.response?.data?.message || err?.message))
    yield call(onError, err?.response?.data?.message || err?.message)
  }
}

function* setFirebaseCustomToken() {
  yield put(AuthenticationActions.firebaseLogin())
}

function* authenticationError() {
  const authenticated = yield select(selectAuthenticated)
  if (authenticated) {
    yield put(AuthenticationActions.logout())
  }

}

function* validateSignUp({
  document: doc,
  onSuccess = () => { },
  onError = () => { },
  mobile = false
}) {
  const ERRORS = {
    USER_ALREADY_REGISTERED: 'user already registered',
    USER_SUPPLIER_DONT_HAVE_ACCESS_MOBILE:
      'you don\'t have access to this application.\nplease sign up on the desktop version'
  }

  try {
    const data = yield call(getUserOnboardingService, {}, { document: doc })

    const user = {
      ...data,
      number: toString(data.number)
    }

    if (user.activeRegister) {
      throw new Error(ERRORS.USER_ALREADY_REGISTERED)
    }

    if (user.typeSlug === 'supplier' && mobile) {
      throw new Error(ERRORS.USER_SUPPLIER_DONT_HAVE_ACCESS_MOBILE)
    }

    yield put(UserActions.updateUser(user))

    yield put(OrganizationActions.loadUserOrganizations(false, onSuccess, () => { }, user))
  } catch (err) {
    const error = Object.values(ERRORS).includes(err.message)
      ? err.message
      : 'we didn\'t find your data, contact your cooperative'
    yield put(AuthenticationActions.authenticationError(error))
    yield call(onError, error)
  }
}

function* requestVerificationCode({
  protocol,
  onSuccess = () => { },
  onError = () => { }
}) {
  try {
    const user = yield select(selectUser)

    yield call(createVerificationCode, {
      document: user.document,
      protocol
    })

    yield call(onSuccess)
  } catch (error) {
    yield call(onError, error)
  }
}

function* validateVerificationCode({
  code,
  onSuccess = () => { },
  onError = () => { }
}) {
  try {
    const user = yield select(selectUser)

    const {
      accessToken,
      refreshToken,
      tokenType,
      iaToken,
      firebaseCustomToken
    } = yield call(validateVerificationCodeService, {
      document: trimMask(user.document),
      code
    })

    yield put(
      AuthenticationActions.refreshTokenSuccess(
        `${ tokenType } ${ accessToken }`,
        refreshToken,
        iaToken
      )
    )

    yield delay(200)

    yield put(AuthenticationActions.loadPermissions())

    yield put(AuthenticationActions.setFirebaseCustomToken(firebaseCustomToken))

    yield call(onSuccess)
  } catch (error) {
    yield put(AuthenticationActions.authenticationError(error))
    yield call(onError, error)
  }
}

function* changePassword({
  password,
  onSuccess = () => { },
  onError = () => { }
}) {
  try {
    const { id } = yield select(selectUser)

    yield call(updateUserService, { password }, { userId: id })

    yield put(UserActions.loadUser(onSuccess))
  } catch (error) {
    yield put(AuthenticationActions.authenticationError(error))
    yield call(onError, error)
  }
}

function* recoverPassword({
  document: doc,
  onSuccess = () => { },
  onError = () => { }
}) {
  try {
    const user = yield call(getUserOnboardingService, {}, { document: doc })

    if (!user.activeRegister) {
      throw new Error('user doesn\'t registered')
    }

    yield put(UserActions.updateUser(user, onSuccess))
  } catch (err) {
    const error =
      err.message === 'user doesn\'t registered'
        ? err.message
        : 'we didn\'t find your data, contact your cooperative'
    yield put(AuthenticationActions.authenticationError(error))
    yield call(onError, error)
  }
}

function* getTokenByOrganization({
  organization = {},
  onSuccess = () => { },
  onError = () => { }
}) {
  try {
    const oldAccessToken = yield select(selectAccessToken)
    const oldRefreshToken = yield select(selectRefreshToken)

    const { accessToken, refreshToken, tokenType, iaToken } = yield call(
      getTokenByOrganizationService,
      {
        accessToken: split(oldAccessToken, ' ')[1],
        refreshToken: oldRefreshToken,
        registry: organization.registry
      },
      { organizationId: organization.id }
    )

    yield put(
      AuthenticationActions.refreshTokenSuccess(
        `${ tokenType } ${ accessToken }`,
        refreshToken,
        iaToken
      )
    )
  } catch (error) {
    yield call(onError, error)
  } finally {
    yield delay(200)
  }

  yield put(AuthenticationActions.loadPermissions(onSuccess))
  yield delay(200)
}

function* loadPermissions({ onSuccess = () => { } }) {
  try {
    const { data } = yield call(getPermissionsService)
    const permissionWeatherStations = includes(
      map(
        data,
        (permission) =>
          permission?.organizationsUser?.organization?.weatherStationsEnabled
      ),
      true
    )

    const modules = uniqBy(
      spread(union)(map(data, ({ modules: internalModule }) => internalModule)),
      'id'
    )
    const slug = [...map(data, (item) => item.profile.slug)]

    const allModules = modules
    const allSlugs = slug

    yield put(AuthenticationActions.loadAllModules(allModules))
    yield put(AuthenticationActions.loadAllSlugs(allSlugs))

    yield put(
      AuthenticationActions.loadPermissionsSuccess(
        {
          modules,
          profile: { slug },
          loadedPermissions: true,
          permissionWeatherStations
        },
        onSuccess
      )
    )
    yield put(AuthenticationActions.loadPermissionsResponse(data))

  } catch (error) {
    console.error('loadPermissions error', error)
  }
}

function* loadPermissionsSuccess({ permissions, onSuccess = () => { } }) {
  const { modules } = permissions
  const userModules = modules
  const currentModule = yield select(selectCurrentModule)
  const currentOrganization = yield select(selectCurrentOrganization)
  const userModulesSlugs = !isEmpty(userModules)
    ? map(userModules, ({ slug }) => slug)
    : []

  if (
    !!currentModule &&
    !isEmpty(currentOrganization) &&
    !userModulesSlugs.includes(currentModule)
  ) {
    yield put(OrganizationActions.clearCurrentOrganization())
    yield put(ModuleActions.exitCurrentModule())
  }

  if (size(userModules) === 0) {
    yield put(AuthenticationActions.logout())
  }

  if (size(userModules) === 1 && currentModule !== userModules[0].slug) {
    yield put(ModuleActions.initModuleBySlug(userModules[0].slug))
  }

  yield call(onSuccess)
}

function* refreshTokenSuccess({ accessToken, refreshToken, iaToken, onSuccess = () => { } }) {
  if (!isEmpty(accessToken)) {
    const { document: doc } = JwtDecode(split(accessToken, ' ')[1])
    // TODO organizationId tbm retorna no jwt

    yield put(UserActions.updateUser({ document: doc }))
    yield call(onSuccess)
  }
}

function* logout() {
  try {
    // Save user on local database
    const database = yield select(selectDatabase)

    if (database) {
      yield call(syncService(database).clearDatabase)
    }

    const accessToken = yield select(selectAccessToken)
    const refreshToken = yield select(selectRefreshToken)

    if (accessToken) {
      try {
        yield call(logoutService, {
          accessToken,
          refreshToken
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('could not logout on api')
      }
    }

    yield put(AuthenticationActions.logoutSuccess())

    yield put(UserActions.resetUserData())

    const firebaseAuthenticated = yield select(selectFirebaseAuthenticated)
    if (firebaseAuthenticated) {
      yield put(AuthenticationActions.firebaseLogout())
    }
  } catch (err) {
    const error = err.message
    yield put(AuthenticationActions.authenticationError(error))
  }
}

let firebaseWasInitialized = false
function* syncFirebaseUserSaga() {
  const authenticated = yield select(selectAuthenticated)
  const channel = yield call(rsf.auth.channel)
  try {
    if (authenticated && !firebaseWasInitialized) {
      yield put(MessagingActions.initCloudMessaging())
      yield put(MessagingActions.subscribeUserNotifications())
      firebaseWasInitialized = true
    }
    while (true) {
      const { user } = yield take(channel)
      if (user) {
        const accessToken = yield select(selectAccessToken)
        const oldFirebaseIdToken = yield select(selectFirebaseIdToken)
        const firebaseIdToken = yield user.getIdToken()

        if (isEmpty(accessToken)) {
          yield put(AuthenticationActions.firebaseLogout())
          yield put(AuthenticationActions.logout())
        } else if (
          !isEmpty(firebaseIdToken) &&
          !isEqual(oldFirebaseIdToken, firebaseIdToken)
        ) {
          yield put(AuthenticationActions.firebaseLoginSuccess(firebaseIdToken))
          yield delay(500)
          if (!firebaseWasInitialized) {
            yield put(MessagingActions.initCloudMessaging())
            yield put(MessagingActions.subscribeUserNotifications())
            firebaseWasInitialized = true
          }
        }
      } else {
        firebaseWasInitialized = false
      }
    }
  } catch (err) {
    console.error('FIREBASE ERROR', err)
    yield put(AuthenticationActions.syncFirebaseUserSaga())
  } finally {
    channel.close()
  }
}

function* firebaseLogin() {
  try {
    const firebaseCustomToken = yield select(selectFirebaseCustomToken)
    yield call(rsf.auth.signInWithCustomToken, firebaseCustomToken)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error on firebase login', e)
    throw e
  }
}

function* firebaseLogout() {
  try {
    firebaseWasInitialized = false
    yield put({ type: MessagingTypes.UNSUBSCRIBE_USER_NOTIFICATIONS })
    yield put({ type: MessagingTypes.UNSET_CLOUD_MESSAGING_TOKEN })
    yield take(MessagingTypes.UNSET_CLOUD_MESSAGING_TOKEN_SUCCESS)
    yield call(rsf.auth.signOut)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('firebase logout error', e)
  } finally {
    yield put(AuthenticationActions.firebaseLogoutSuccess())
  }
}

function* initAuthSagas() {
  const authenticated = yield select(selectAuthenticated)
  const firebaseInitialized = yield select(selectFirebaseInitialized)

  if (!authenticated && firebaseInitialized) {
    yield put(AuthenticationActions.firebaseLogout())
  }
}

function* loadTermsSuccess({ onSuccess = () => { } }) {
  yield call(onSuccess)
}

function* loadTerms({ onSuccess = () => { }, onError = () => { }, accepted = null }) {
  try {
    const { data } = yield call(getTermsService, { accepted })
    yield put(AuthenticationActions.loadTermsSuccess(data, onSuccess))
  } catch (error) {
    yield put(AuthenticationActions.authenticationError(error))
    yield call(onError, error)
  }
}

function* updateTerm({ params, onSuccess = () => { }, onError = () => { } }) {
  try {
    yield call(updateTermService, params)
    yield put(AuthenticationActions.loadTerms(onSuccess))
  } catch (error) {
    yield call(onError, error)
  }
}

export default [
  takeLatest(AuthenticationTypes.VALIDATE_SIGN_UP, validateSignUp),

  takeLatest(
    AuthenticationTypes.SET_FIREBASE_CUSTOM_TOKEN,
    setFirebaseCustomToken
  ),
  takeLatest(AuthenticationTypes.FIREBASE_LOGIN, firebaseLogin),
  takeLatest(AuthenticationTypes.FIREBASE_LOGOUT, firebaseLogout),
  takeLatest(AuthenticationTypes.SYNC_FIREBASE_USER_SAGA, syncFirebaseUserSaga),

  takeLatest(
    AuthenticationTypes.REQUEST_VERIFICATION_CODE,
    requestVerificationCode
  ),
  takeLatest(
    AuthenticationTypes.VALIDATE_VERIFICATION_CODE,
    validateVerificationCode
  ),

  takeLatest(AuthenticationTypes.UPDATE_TERM, updateTerm),

  takeLatest(AuthenticationTypes.REFRESH_TOKEN_SUCCESS, refreshTokenSuccess),
  takeLatest(AuthenticationTypes.INIT_AUTH_SAGAS, initAuthSagas),

  takeLatest(
    AuthenticationTypes.GET_TOKEN_BY_ORGANIZATION,
    getTokenByOrganization
  ),

  takeLatest(AuthenticationTypes.LOAD_PERMISSIONS, loadPermissions),

  takeLatest(
    AuthenticationTypes.LOAD_PERMISSIONS_SUCCESS,
    loadPermissionsSuccess
  ),

  takeLatest(AuthenticationTypes.CHANGE_PASSWORD, changePassword),
  takeLatest(AuthenticationTypes.RECOVER_PASSWORD, recoverPassword),

  takeLatest(AuthenticationTypes.LOAD_TERMS, loadTerms),
  takeLatest(AuthenticationTypes.LOAD_TERMS_SUCCESS, loadTermsSuccess),

  takeLatest(AuthenticationTypes.AUTHENTICATION_ERROR, authenticationError),
  takeLatest(AuthenticationTypes.LOGIN, login),
  takeLatest(AuthenticationTypes.LOGOUT, logout)
]
