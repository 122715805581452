import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import toString from 'lodash/toString'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { noImage, tractor } from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { useSnackbar } from '@smartcoop/snackbar'
import { MachineActions } from '@smartcoop/stores/machine'
import { selectCurrentProperty, selectFamilyGroupAccess } from '@smartcoop/stores/property/selectorProperty'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import Button from '@smartcoop/web-components/Button'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Icon from '@smartcoop/web-components/Icon'
import InputFile from '@smartcoop/web-components/InputFile'
import Loader from '@smartcoop/web-components/Loader'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import ThumbnailImage from '@smartcoop/web-components/ThumbnailImage'
import CreateMachineryForm from '@smartcoop/web-containers/forms/digitalProperty/machinery/CreateMachineryForm'
import useFile from '@smartcoop/web-containers/hooks/useFile'
import { ButtonsContainer, Item, Title } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { Container, Header, IconContainer, Gallery, Row, RowTitle } from './styles'

const MachineryRegisterScreen = () => {

  const machineryFormRef = useRef(null)
  const history = useHistory()
  const t = useT()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const location = useLocation()
  const { routes } = useRoutes()
  const [machineryRegisterScreenFiles, setMachineryRegisterScreenFiles] = useState([])

  const {
    selectedFiles,
    receivedFiles,
    isEmpty: isEmptyFiles,
    handleAdd,
    createFormData,
    handleRemove
  } = useFile([], location.state?.machine?.machineFiles ?? [])

  const [isLoading, setIsLoading] = useState(false)
  const { createDialog } = useDialog()
  const user = useSelector(selectUser)
  const currentProperty = useSelector(selectCurrentProperty)
  const familyGroupAccess = useSelector(selectFamilyGroupAccess)

  const stateMachine = useMemo(
    () => location?.state?.machine ?? {}, [location]
  )
  const defaultValues = useMemo(
    () => ({
      ...stateMachine,
      year: toString(stateMachine?.year) ?? '',
      availableHours: toString(stateMachine?.availableHours) ?? '',
      property: {
        id: currentProperty?.id,
        ...stateMachine?.property
      },
      machineBrand: {
        id: null,
        ...stateMachine?.machineBrand
      },
      machineType: {
        id: null,
        ...stateMachine?.machineType
      },
      phone: stateMachine?.phone ?? user.cellPhone,
      machineId: stateMachine.id ?? null,
      available: () => {
        if(stateMachine?.rentAvailable) return 'rent'
        if(stateMachine?.saleAvailable) return 'sell'
        if(stateMachine?.serviceProvisionAvailable) return 'service'
        return 'none'
      },
      saleValue: stateMachine?.saleValue
    }), [currentProperty, stateMachine, user]
  )

  const handleClose = useCallback(
    () => {
      history.push(routes.machineryList.path, { activeTab: 'my-machines' })
    },
    [history, routes.machineryList.path]
  )

  const onSuccess = useCallback(
    (operation) => {
      const label = operation === 'update' ? 'edited' : 'registered'
      snackbar.success(
        t(`your {this} was ${  label }`, {
          howMany: 1,
          gender: 'female',
          this: t('machine', { howMany: 1 })
        })
      )
      handleClose()
    },
    [handleClose, snackbar, t]
  )

  const submit = useCallback(
    () => machineryFormRef.current.submit()
    ,
    []
  )

  const submitNewFiles = useCallback(
    (machine, operation) => {
      if (isEmpty(selectedFiles)) {
        onSuccess(operation)
      } else {
        dispatch(MachineActions.addMachineFiles(createFormData('upload'), machine.id,
          () => onSuccess(operation),
          () => setIsLoading(false)
        ))
      }
    },
    [createFormData, dispatch, onSuccess, selectedFiles]
  )

  const submitOldFiles = useCallback(
    (machine) => {
      dispatch(MachineActions.editMachineFiles(receivedFiles, machine.id,
        () => submitNewFiles(machine, 'update'),
        () => setIsLoading(false)
      ))
    },
    [dispatch, receivedFiles, submitNewFiles]
  )

  const submitForms = useCallback(
    (data) => {
      setIsLoading(true)
      if(isEmpty(location.state?.machine)) {
        dispatch(MachineActions.saveMachine(
          data,
          (machine) => submitNewFiles(machine, 'create'),
          () => setIsLoading(false)
        ))
      } else {
        dispatch(MachineActions.updateMachine(
          data,
          defaultValues.machineId,
          (machine) => submitOldFiles(machine),
          () => setIsLoading(false)
        ))
      }
    },
    [location, dispatch, submitNewFiles, defaultValues, submitOldFiles]
  )

  const handleClickClose = useCallback(
    (list, removedFile) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => handleRemove(list, removedFile),
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'female',
            this: t('image', { howMany: 1 })
          })
        }
      })
    },
    [createDialog, handleRemove, t]
  )

  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_MACHINES_ID, fileKey: file?.fileKey })

    return downloadFromBase64(data)
  }, [])

  useEffect(() => {
    const getRenderedFiles = async() => {
      const receivedElements = await Promise.all(map(receivedFiles, async(file) => {
        const fileSrc = await renderFile(file)
        return (
          <ThumbnailImage
            key={ file.id }
            src={ fileSrc }
            onClose={ () => handleClickClose('receivedFiles', file) }
          />
        )}).concat(
        map(selectedFiles, (file, index) => (
          <ThumbnailImage
            key={ `${ index }-${ file.name }` }
            src={ URL.createObjectURL(file) }
            onClose={ () => handleClickClose('selectedFiles', file) }
          />
        ))
      ))

      setMachineryRegisterScreenFiles(receivedElements)
    }

    getRenderedFiles()
  },[handleClickClose, receivedFiles, renderFile, selectedFiles])


  return (
    isLoading ? <Loader width={ 100 } /> :
      <SplitedScreenLayout
        title={ { name: t('machinery', { howMany: 2 }) } }
        divRightStyle={ { paddingTop: 67 } }
        leftChildren={ (
          <Container>
            <Header style={ { paddingLeft: 35 } }>
              <IconContainer>
                <Icon icon={ tractor } size={ 18 } />
              </IconContainer>

              <Title style={ { fontSize: 18, fontWeight: 600 } }>
                <I18n>machinery register</I18n>
              </Title>
            </Header>

            <Item style={ { padding: '0 35px 0 35px' } }>
              <CreateMachineryForm
                ref={ machineryFormRef }
                onSuccess={ onSuccess }
                onSubmit={ submitForms }
                defaultValues={ defaultValues }
                familyGroupAccess={ familyGroupAccess }
                currentProperty={ currentProperty }
                withoutSubmitButton
              />
            </Item>
          </Container>
        ) }
        rightChildren={ (
          <Container style={ { flex: 1, justifyContent: 'space-between' } }>
            <RowTitle>
              <Title style={ { fontSize: 18, fontWeight: 600, margin: 0 } }>
                <I18n params={ { howMany: 2 } }>image</I18n>
              </Title>
              {!isEmptyFiles &&
                <InputFile
                  idInput="contained-btn-file"
                  onChange={ handleAdd }
                  maxNumberFile={ 20 - selectedFiles.length }
                  maxNumberFileDisplay={ 20 }
                  inputProps={ {
                    accept: 'image/png,image/jpeg,image/tiff',
                    multiple: true
                  } }
                  buttonProps={ {
                    id: 'attach-docs',
                    color: 'secondary'
                  } }
                >
                  <I18n>add images</I18n>
                </InputFile>
              }
            </RowTitle>
            <Gallery isCenter={ isEmptyFiles }>
              {isEmptyFiles ?
                <EmptyState
                  text={ t('no image added') }
                  icon={ noImage }
                  isInputFile
                  maxNumberFile={ 20 - selectedFiles.length }
                  maxNumberFileDisplay={ 20 }
                  file={ {
                    idInput: 'contained-button-file',
                    inputProps: {
                      accept: 'image/png,image/jpeg,image/tiff',
                      multiple: true
                    }
                  } }
                  action={ {
                    text: t('add images') ,
                    onClick: handleAdd
                  } }
                  buttonProps={ {
                    id: 'attach-documents',
                    color: 'secondary'
                  } }
                />
                :
                machineryRegisterScreenFiles
              }
            </Gallery>
            <Row>
              <div style={ { flex: 2 } } />
              <ButtonsContainer style={ { flex: 1 } }>
                <Button
                  id="cancel-machinery-form"
                  onClick={ handleClose }
                  style={ { flex: 1 } }
                  variant="outlined"
                >
                  <I18n>cancel</I18n>
                </Button>

                <div style={ { width: '10%' } } />

                <Button
                  id="submit-machinery-form"
                  onClick={ submit }
                  style={ { flex: 1 } }
                >
                  <I18n>save</I18n>
                </Button>
              </ButtonsContainer>
            </Row>
          </Container>
        ) }
      />
  )
}

export default MachineryRegisterScreen
