import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import othersCropManagementSchema from '@smartcoop/forms/schemas/management/othersCropManagement.schema'
import { useT } from '@smartcoop/i18n'
import Form from '@smartcoop/web-components/Form'
import InputText from '@smartcoop/web-components/InputText'

const OthersForm = forwardRef(({ defaultValues, handleSubmit, readOnly }, formRef) => {
  const t = useT()

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ othersCropManagementSchema }
      containerStyle={ { height: 'auto' } }
      onSubmit={ handleSubmit }
    >
      <InputText
        name="description"
        label={ t('description') }
        fullWidth
        multiline
        rows={ 10 }
        defaultValue={ defaultValues?.description }
        disabled={ readOnly }
      />
    </Form>
  )
})

OthersForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  defaultValues: PropTypes.object
}

OthersForm.defaultProps = {
  readOnly: false,
  defaultValues: {}
}

export default OthersForm
