import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'

import {
  Badge
} from './styles'

const MachineStatus = props => {
  const {
    status,
    style
  } = props

  const options = useMemo(() => {
    switch (status) {

      case 'rent':
        return {
          name: <I18n>rent</I18n>,
          background: '(142, 36, 170, 0.1)',
          color: '(142, 36, 170, 1)'
        }
      case 'service':
        return {
          name: <I18n>service</I18n>,
          background: '(22, 67, 183, 0.1)',
          color: '(22, 67, 183, 1)'
        }
      case 'sell':
        return {
          name: <I18n>sell</I18n>,
          background: '(40, 159, 48, 0.1)',
          color: '(40, 159, 48, 1)'
        }
      case 'none':
        return {
          name: <I18n>none</I18n>,
          background: '(29, 29, 27, 0.1)',
          color: '(29, 29, 27, 1)'
        }
      default:
        return {
          name: <I18n>none</I18n>,
          background: '(29, 29, 27, 0.1)',
          color: '(29, 29, 27, 1)'
        }
    }
  }, [status])

  return (
    <Badge
      backgroundColorBadge={ options.background }
      colorBadge={ options.color }
      style={ style }
    >
      { options.name }
    </Badge>
  )
}


MachineStatus.propTypes = {
  status: PropTypes.oneOf([
    'rent',
    'service',
    'sell',
    'none'
  ]),
  style: PropTypes.object
}

MachineStatus.defaultProps = {
  style: {},
  status: 'none'
}

export default MachineStatus
