import { call, put, takeLatest } from 'redux-saga/effects'

import { createNonCompliance, getNonCompliances, createNonComplianceComment, deleteNonCompliance, editNonCompliance } from '@smartcoop/services/apis/smartcoopApi/resources/nonCompliances'

import { NonCompliancesActions, NonCompliancesTypes } from './duckNonCompliance'

function* loadNonCompliances({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: { data, ...pagination } } = yield call(getNonCompliances, {
      limit: 10,
      ...params
    })

    yield put(NonCompliancesActions.loadNonCompliancesSuccess(
      data,
      pagination.page,
      () => onSuccess(pagination)
    ))
  } catch (err) {
    const error = err.message
    yield put(NonCompliancesActions.nonComplianceError(error))
    yield call(onError, error)
  }
}

function* loadNonCompliancesSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* saveNonCompliance({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { deliveryLocation, observations } = params
    yield call(createNonCompliance, { observations }, deliveryLocation)
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(NonCompliancesActions.nonComplianceError(error))
    yield call(onError)
  }
}

function* saveNonComplianceComment({ params = {}, onSuccess = () => {}, onError = () => {} }){
  try{
    const { nonComplianceId, comment } = params
    const { data } = yield call(createNonComplianceComment, { comment }, nonComplianceId)
    yield call(onSuccess, data)
  }
  catch(err){
    yield call(onError)
  }
}

function* updateNonCompliance({ params = {}, onSuccess = () => {}, onError = () => {} }){
  try{
    const { commentId, text } = params
    yield call(editNonCompliance, { text }, commentId)
    yield call(onSuccess)
  }
  catch(err){
    yield call(onError)
  }
}

function* resolveNonCompliance({ params = {}, onSuccess = () => {}, onError = () => {} }){
  try{
    const { nonComplianceId } = params
    yield call(editNonCompliance, { statusId: 2 }, nonComplianceId)
    yield call(onSuccess)
  }
  catch(err){
    yield call(onError)
  }
}

function* eraseNonCompliance({ params = {}, onSuccess = () => {}, onError = () => {} }){
  try{
    const { nonComplianceId } = params
    yield(call(deleteNonCompliance, nonComplianceId))
    yield call(onSuccess)
  }
  catch(err){
    yield call(onError)
  }
}

export default [
  takeLatest(NonCompliancesTypes.LOAD_NON_COMPLIANCES, loadNonCompliances),
  takeLatest(NonCompliancesTypes.LOAD_NON_COMPLIANCES_SUCCESS, loadNonCompliancesSuccess),

  takeLatest(NonCompliancesTypes.SAVE_NON_COMPLIANCE, saveNonCompliance),

  takeLatest(NonCompliancesTypes.UPDATE_NON_COMPLIANCE, updateNonCompliance),
  takeLatest(NonCompliancesTypes.RESOLVE_NON_COMPLIANCE, resolveNonCompliance),
  takeLatest(NonCompliancesTypes.ERASE_NON_COMPLIANCE, eraseNonCompliance),
  takeLatest(NonCompliancesTypes.SAVE_NON_COMPLIANCE_COMMENT, saveNonComplianceComment)
]
