import React, { useState, useEffect, useCallback, forwardRef, useMemo } from 'react'
import { useDispatch , useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import fieldSchema from '@smartcoop/forms/schemas/producer/field.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { FieldActions } from '@smartcoop/stores/field'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

import { Container, ButtonContainer } from './styles'

const FieldForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    loading,
    onSubmit,
    onSuccess,
    onError,
    polygonCoordinates,
    childrenPolygons,
    oldPolygon,
    splitCoordinates,
    area
  } = props
  const t = useT()
  const location = useLocation()

  const dispatch = useCallback(useDispatch(), [])
  const [defaultValue, setDefaultValue] = useState({})

  const currentProperty = useSelector(selectCurrentProperty)

  const handleSubmit = useCallback(
    (data) => {
      onSubmit(data)
      dispatch(FieldActions.saveField(
        {
          ...defaultValue,
          ...data,
          polygonCoordinates: !isEmpty(oldPolygon) ? oldPolygon : polygonCoordinates,
          childrenPolygons,
          propertyId: currentProperty?.id,
          splitLines: splitCoordinates
        },
        onSuccess,
        onError,
        defaultValue?.id
      ))
    },
    [childrenPolygons, currentProperty.id, defaultValue, dispatch, oldPolygon, onError, onSubmit, onSuccess, polygonCoordinates, splitCoordinates]
  )

  const selectOptions = useMemo(
    () => (
      [
        {
          label: t('own'),
          value: 'owned'
        },
        {
          label: t('leased'),
          value: 'rented'
        }
      ]
    ),
    [t]
  )

  const irrigationOptions = useMemo(
    () => (
      [
        {
          label: t('yes'),
          value: true
        },
        {
          label: t('no'),
          value: false
        }
      ]
    ),
    [t]
  )

  useEffect(() => {
    if(location.state && location?.state?.field) {
      setDefaultValue(
        {
          fieldName: location?.state?.field?.fieldName || '',
          fieldMode: location?.state?.field.fieldMode || '',
          irrigated: location?.state?.field.irrigated || false,
          id: location?.state?.field.id
        }
      )
    }
  }, [location.state])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ fieldSchema }
        onSubmit={ handleSubmit }
      >
        <InputText
          name="property"
          label={ t('property', { howMany: 1 }) }
          value={ currentProperty?.name }
          detached
          disabled
        />

        <InputUnit
          name="area"
          label={ t('area') }
          maxLength={ 18 }
          unit="ha"
          type="float"
          disabled
          defaultValue={ area }
        />

        <InputText
          name="fieldName"
          label={ t('field name') }
          disabled={ loading }
          defaultValue={ defaultValue.fieldName }
        />

        <RadioGroup
          label={ t('situation') }
          name="fieldMode"
          options={ selectOptions }
          variant="row"
          style={ { paddingBottom: 10 } }
          defaultValue={ defaultValue.fieldMode }
        />

        <RadioGroup
          label={ t('irrigated?') }
          name="irrigated"
          options={ irrigationOptions }
          variant="row"
          defaultValue={ defaultValue.irrigated }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-field-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>next</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FieldForm.propTypes = {
  loading: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  polygonCoordinates: PropTypes.array,
  area: PropTypes.string,
  childrenPolygons: PropTypes.array,
  oldPolygon: PropTypes.array,
  splitCoordinates: PropTypes.array,
  withoutSubmitButton: PropTypes.bool
}

FieldForm.defaultProps = {
  polygonCoordinates: [],
  oldPolygon: [],
  splitCoordinates: [],
  childrenPolygons: [],
  area: '',
  loading: false,
  onSuccess: () => {},
  onError: () => {},
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FieldForm
