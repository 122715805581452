import styled, { css } from 'styled-components'

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import Button from '@smartcoop/web-components/Button'

export const ContainerListFields = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
  flex: 1;
  max-height: calc(100vh - 225px);

  ${ ({ isCenter }) => isCenter && css`
    justify-content: center;
    align-items: center;
  ` }
`

export const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 15px;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const ButtonFilter = styled(Button)`
  align-self: flex-end;
  flex: 1;
  margin-right: 8px;
  border-color: colors.lightgrey;
`
export const ButtonRegisterAnalysis = styled(Button)`
`

export const ButtonContainer = styled.div`
  margin-right: 20px;
`

export const SpaceButton = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-left: 5px;
`

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  margin-top: 8px;
`

export const TabsGroup = styled(Tabs).attrs({
  variant: 'fullWidth',
  indicatorColor: 'primary'
})`
  color: ${ colors.black };
`

export const TabItem = styled(Tab)`
  min-width: 0;
  min-height: 0;
  pointer-events: inherit !important;
  height: 100%;
`
