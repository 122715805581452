/* eslint-disable no-param-reassign */
import { Database, Q } from '@nozbe/watermelondb'

import first from 'lodash/first'

import { ROUTES } from './routes'
import { syncPendingService } from './syncPendingService'

function transformStringToBoolean(value) {
  return typeof value === 'boolean' ? value : JSON.parse(value)
}

export function groupMemberService(database: Database) {
  async function createOrUpdate(members, group) {
    const promises = members?.map(member => async () => {
      const registers = await database.get('group_member')
        .query(
          Q.where('groupId', group.id),
          Q.where('userId', member.userId)
        )
        .fetch()

      if (registers) {
        registers.map(register => register.prepareUpdate(item => {
          item.userName = member.chatNickname
          item.photo = member.photo
          item.profiles = JSON.stringify(member.profiles)
          item.isAdmin = member.isAdminGroup
          item.isLeft = transformStringToBoolean(member.isLeft)
          item.publicKey = member.publicKey
          item.userCode = member.code
        }))

        await database.batch(registers)
      }

      return database.get('group_member').create(item => {
        item.groupId = group.id
        item.userId = member.userId
        item.userName = member.chatNickname
        item.photo = member.photo
        item.profiles = JSON.stringify(member.profiles)
        item.isAdmin = member.isAdminGroup
        item.isLeft = transformStringToBoolean(member.isLeft)
        item.publicKey = member.publicKey
        item.userCode = member.code
      })
    })

    await Promise.all(promises.map(fn => fn()))
  }

  async function createMembers(groupId, members = []) {
    const promises = members.map(member => async () => {
      await database.get('group_member').create(register => {
        register.groupId = groupId
        register.userId = member.userId
        register.userName = member.userName
        register.isAdmin = member.isAdmin || member.isAdminGroup
        register.publicKey = member.publicKey
        register.userCode = member.code
        register.profiles = member.profiles || '[]'
      })
    })

    await Promise.all(promises.map(fn => fn()))
  }

  async function checkUserIsAdmin(userId, groupId) {
    const register = await getMemberById(userId, groupId)

    return register?.isAdmin || false
  }

  async function checkIsGroupMember(userId, groupId) {
    const register = await getMemberById(userId, groupId)

    return !!register
  }

  async function getMemberById(userId, groupId) {
    const registers = await database.get('group_member')
      .query(
        Q.where('userId', userId || null),
        Q.where('groupId', groupId || null)
      ).fetch()

    return registers ? first(registers) : null
  }

  async function removeMember(userId, groupId, isBroadcast) {
    await database.write(async () => {
      // Remove from local database
      const register = await getMemberById(userId, groupId)

      if (register) {
        // Update isLeft flag in local database
        await register.update(item => {
          item.isLeft = true
        })
      }

      // Create request in queue
      await syncPendingService(database).create({
        type: 'POST',
        body: JSON.stringify({ remove: [userId] }),
        params: {},
        url: `${ isBroadcast ? ROUTES.REMOVE_BROADCAST_MEMBER : ROUTES.REMOVE_GROUP_MEMBER }/${ groupId }`,
        registerType: 'remove_member_group'
      })
    })
  }

  async function getGrupAdminsCount(groupId) {
    return database.get('group_member')
      .query(Q.where('groupId', groupId))
      .fetchCount()
  }

  async function setGroupAdmin(userId, groupId, isAdmin) {
    const count = await getGrupAdminsCount(groupId)

    if (!isAdmin && count <= 1) {
      throw new Error('O grupo deve possuir ao menos num administrador')
    }

    await database.write(async () => {
      const register = await getMemberById(userId, groupId)

      if (register) {
        // Update on local database
        await register.update(item => {
          item.isAdmin = isAdmin
        })
      }

      // Create request in queue
      await syncPendingService(database).create({
        type: 'POST',
        body: JSON.stringify({ userCodes: [{ userId, isAdminGroup: isAdmin }] }),
        params: {},
        url: `${ ROUTES.TURN_ADMIN }/${ groupId }`,
        registerType: 'remove_member_group'
      })
    })
  }

  async function addMembers(groupId, members, isBroadcast = false) {
    await database.write(async () => {
      await createMembers(groupId, members)

      await syncPendingService(database).create({
        type: 'POST',
        body: JSON.stringify({ userCodes: members }),
        params: {},
        url: `${ isBroadcast ? ROUTES.ADD_BROADCAST_MEMBERS : ROUTES.ADD_GROUP_MEMBERS }/${ groupId }`,
        registerType: 'add_member_group'
      })
    })
  }

  async function exitGroup(userId, groupId) {
    await database.write(async () => {
      // Remove from local database
      const registers = await database.get('group_member')
        .query(
          Q.where('userId', userId),
          Q.where('groupId', groupId)
        ).fetch()

      if (registers) {
        registers.map(register => register.prepareUpdate(item => {
          item.isLeft = true
        }))

        database.batch(registers)
      }

      // Create request in queue
      await syncPendingService(database).create({
        type: 'POST',
        body: '{}',
        params: '{}',
        url: `${ ROUTES.EXIT_GROUP }/${ groupId }`,
        registerType: 'exit_group'
      })
    })
  }

  async function getMembersToSendMessage(groupId) {
    return database.get('group_member')
      .query(
        Q.where('groupId', groupId),
        Q.where('isAdmin', Q.eq(false)),
        Q.where('isLeft', Q.eq(false))
      ).fetch()
  }

  function observeGroupMemberByGroupId(groupId, isBroadcast = false) {
    if (isBroadcast) {
      return database.collections.get('group_member')
        .query(
          Q.where('groupId', groupId || null),
          Q.where('isLeft', Q.eq(false)),
          Q.where('isAdmin', Q.eq(true))
        )
        .observe()
    }

    return database.collections.get('group_member')
      .query(
        Q.where('groupId', groupId || null),
        Q.where('isLeft', Q.eq(false))
      )
      .observe()
  }

  function observeGroupMemberByGroupIdAndFilterByName(groupId, searchName = '', isBroadcast = false) {
    if (isBroadcast) {
      if (searchName) {
        return database.collections.get('group_member')
          .query(
            Q.where('groupId', groupId || null),
            Q.where('isLeft', Q.eq(false)),
            Q.where('userName', Q.like(`%${ Q.sanitizeLikeString(searchName) }%`)),
            Q.where('isAdmin', Q.eq(true))
          )
          .observe()
      }

      return database.collections.get('group_member')
        .query(
          Q.where('groupId', groupId || null),
          Q.where('isLeft', Q.eq(false)),
          Q.where('isAdmin', Q.eq(true))
        )
        .observe()
    }

    if (searchName) {
      return database.collections.get('group_member')
        .query(
          Q.where('groupId', groupId || null),
          Q.where('isLeft', Q.eq(false)),
          Q.where('userName', Q.like(`%${ Q.sanitizeLikeString(searchName) }%`))
        )
        .observe()
    }

    return database.collections.get('group_member')
      .query(
        Q.where('groupId', groupId),
        Q.where('isLeft', Q.eq(false))
      )
      .observe()
  }

  function observeLocalUserIsMember(groupId, userId) {
    return database.collections.get('group_member')
      .query(
        Q.where('groupId', groupId || null),
        Q.where('userId', userId || null),
        Q.where('isLeft', Q.eq(false))
      )
      .observeCount()
  }

  function observeGroupMembersCount(groupId) {
    return database.collections.get('group_member')
      .query(
        Q.where('groupId', groupId || null),
        Q.where('isLeft', Q.eq(false))
      )
      .observeCount()
  }

  function observeGroupAdminCount(groupId) {
    return database.collections.get('group_member')
      .query(
        Q.where('groupId', groupId || null),
        Q.where('isLeft', Q.eq(false)),
        Q.where('isAdmin', Q.eq(true))
      )
      .observeCount()
  }

  return {
    createMembers,
    createOrUpdate,
    checkUserIsAdmin,
    checkIsGroupMember,
    removeMember,
    setGroupAdmin,
    addMembers,
    exitGroup,
    getMembersToSendMessage,
    observeLocalUserIsMember,
    observeGroupMemberByGroupId,
    observeGroupMembersCount,
    observeGroupAdminCount,
    observeGroupMemberByGroupIdAndFilterByName
  }
}
