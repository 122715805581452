import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import dateRange from '@smartcoop/forms/validators/dateRange.validator'
import required from '@smartcoop/forms/validators/required.validator'

const advertising = ({ t }) => Yup.object().shape({
  content: Yup.object().shape({
    message: flow(
      required({ t })
    )(Yup.string()) }),
  organizationsIds: flow(
    required({ t })
  )(Yup.string()),
  profilesIds: flow(
    required({ t })
  )(Yup.string()),
  modulesIds: flow(
    required({ t })
  )(Yup.string()),

  rangeDate: dateRange({ t })(Yup.object())
})

export default advertising
