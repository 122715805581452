import styled from 'styled-components'

export const ContainerAccountBalance = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5% 0;
`

export const HeaderAccountBalance = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
`


export const TextNoHaveAccounts = styled.p`
 font-size: 16px;
`
