import styled from 'styled-components'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const AddressesContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
`

export const Cell = styled.div`
  margin-right: 30px;
  line-height: 1.7em;
  padding: 0 0 3px;
  padding: 0 0 3px;
`

export const ProposalFormContainer = styled.div`
  padding: 0 30px;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const LinkCustom = styled(Link)`
  color: ${ colors.blue };
  text-decoration: underline;
`
export const NonComplianceButtonContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
`
