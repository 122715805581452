/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  createContext,
  useContext,
  useRef
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import find from 'lodash/find'
import filterFP from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import mapFP from 'lodash/fp/map'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'

import AppBar from '@material-ui/core/AppBar'
import Badge from '@material-ui/core/Badge'
import Drawer from '@material-ui/core/Drawer'
import Fade from '@material-ui/core/Fade'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'


import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import {
  leftDoubleArrow,
  hamburguerMenu,
  barnYellow,
  smallPlant,
  currencySignRounded,
  arrowDown,
  exitModule,
  shoppingPlatform,
  dashboard
} from '@smartcoop/icons'
import book from '@smartcoop/icons/svgs/book'
import { selectUserHasMultipleModules, selectUserIsSupplier, selectTerms } from '@smartcoop/stores/authentication/selectorAuthentication'
import { selectChatNewNotificationsCount, selectNewNotificationsCount } from '@smartcoop/stores/messaging/selectorMessaging'
import { AVAILABLE_MODULES, ModuleActions } from '@smartcoop/stores/module'
import {
  selectCurrentModule,
  selectModuleIsTechnical
} from '@smartcoop/stores/module/selectorModule'
// import { OrganizationActions } from '@smartcoop/stores/organization'
import {
  selectCurrentOrganization,
  selectUserOrganizationsByModule
} from '@smartcoop/stores/organization/selectorOrganization'
// import { PropertyActions } from '@smartcoop/stores/property'
import { PropertyActions } from '@smartcoop/stores/property'
import {
  selectCurrentProperty,
  selectProperties,
  selectFamilyGroupProperties,
  selectFamilyGroupAccess
} from '@smartcoop/stores/property/selectorProperty'
import { TechnicalActions } from '@smartcoop/stores/technical'
import { selectCurrentOwner } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUser, selectUserExtraAttributes } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import anaIa from '@smartcoop/styles/assets/images/ana-ia.jpg'
import usePagination from '@smartcoop/web-app/src/hooks/usePagination'
import Avatar from '@smartcoop/web-components/Avatar'
import Icon from '@smartcoop/web-components/Icon'
import BellIconButton from '@smartcoop/web-components/IconButton/BellIconButton'
import UserIconButton from '@smartcoop/web-components/IconButton/UserIconButton'
// import PartialLoading from '@smartcoop/web-components/PartialLoading'
import Popover from '@smartcoop/web-components/Popover'
import Tooltip from '@smartcoop/web-components/Tooltip'
import ChatFabButton from '@smartcoop/web-containers/fragments/ChatFabButton'
import NewNotificationsList from '@smartcoop/web-containers/fragments/NewNotificationsList'
import useTerm from '@smartcoop/web-containers/hooks/useTerm'
import ChangeOrganizationModal from '@smartcoop/web-containers/modals/ChangeOrganizationModal'
import ChangePropertyModal from '@smartcoop/web-containers/modals/ChangePropertyModal'
import EbookModal from '@smartcoop/web-containers/modals/EbookModal'
import IAModal from '@smartcoop/web-containers/modals/IAModal'
import { version } from '@smartcoop/web-containers/package.json'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'


import useStyles, { UserMenuContainer } from './styles'
import UserMenu from './UserMenu'

const LayoutContext = createContext()
export const useLayout = () => useContext(LayoutContext)
function AuthenticatedLayout(props) {
  const { container, children } = props
  const mainRef = useRef(null)
  const notificationsRef = useRef(null)

  const history = useHistory()
  const { createDialog, removeDialog } = useDialog()
  const t = useT()
  const { resetPages } = usePagination()

  const dispatch = useCallback(useDispatch(), [])
  const newNotificationsCount = useSelector(selectNewNotificationsCount)
  const newChatNotificationsCount = useSelector(selectChatNewNotificationsCount)

  const userProperties = useSelector(selectProperties)
  const familyGroupProperties = useSelector(selectFamilyGroupProperties)
  const { organizations, module } = useSelector(selectUserOrganizationsByModule)
  const currentOrganization = useSelector(selectCurrentOrganization)
  const userIsSupplier = useSelector(selectUserIsSupplier)
  const currentModule = useSelector(selectCurrentModule)
  const currentProperty = useSelector(selectCurrentProperty)
  const user = useSelector(selectUser)
  const userHasMultipleModules = useSelector(selectUserHasMultipleModules)
  const familyGroupAccess = useSelector(selectFamilyGroupAccess)

  const extraAttributes = useSelector(selectUserExtraAttributes)

  const isTechnicalModule = useSelector(selectModuleIsTechnical)
  const technicalCurrentOwner = useSelector(selectCurrentOwner)
  const terms = useSelector(selectTerms)

  const [privacyModal, selectedPrivacyTerm] = useTerm('privacy-term', false)
  const [termModal] = useTerm('use-term', false)

  const { routes } = useRoutes()

  const classes = useStyles()
  const theme = useTheme()

  const [menuOpen, setMenuOpen] = useState(false)

  const mobileSize = useMediaQuery(theme.breakpoints.down('xs'))

  const [mounted, setMounted] = useState(false)

  const acceptedPrivacyTerm = !!find(terms, term => term.termType.slug === 'privacy-term' && term?.userTerm)
  const acceptedUseTerm = !!find(terms, term => term.termType.slug === 'use-term' && term?.userTerm)

  const wasAcceptedPrivacyAndNotUse = useMemo(() => acceptedPrivacyTerm && !acceptedUseTerm,[acceptedPrivacyTerm, acceptedUseTerm])

  const handleDrawerToggle = useCallback(() => setMenuOpen(!menuOpen), [
    menuOpen
  ])

  const properties = useMemo(() => (
    [...userProperties, ...familyGroupProperties]
  ), [userProperties, familyGroupProperties])

  const changePropertyDialog = useCallback(() => {
    if (size(properties) >= 1 || isEmpty(currentProperty)) {
      createDialog({
        id: 'change-property',
        Component: ChangePropertyModal,
        props: {
          onChooseProperty: () => history.push('/digital-property')
        }
      })
    }
  }, [createDialog, currentProperty, history, properties])

  useEffect(() => {
    if (!isEmpty(currentProperty)) {
      removeDialog({ id: 'change-property' })
    }
  }, [currentProperty, removeDialog])

  const changeOrganizationDialog = useCallback(() => {
    if (module === currentModule) {
      if (size(organizations) > 1 || isEmpty(currentOrganization)) {
        createDialog({
          id: 'change-organization',
          Component: ChangeOrganizationModal,
          props: {
            module: currentModule
          }
        })
      }
    }
  }
  , [createDialog, currentModule, currentOrganization, module, organizations])

  const changeModule = useCallback(() => {
    if (userHasMultipleModules) {
      dispatch(ModuleActions.exitCurrentModule())
    }
    resetPages()
  }, [dispatch, resetPages, userHasMultipleModules])

  const technicalExitProperty = useCallback(() => {
    dispatch(TechnicalActions.resetTechnicalCurrentOwner())
    resetPages()
  }, [dispatch, resetPages])

  const handleOpenEbook = useCallback(() => {
    createDialog({
      id: 'ebook',
      Component: EbookModal
    })
  }, [createDialog])

  const currentModuleOptions = useMemo(() => {
    switch (currentModule) {
      case AVAILABLE_MODULES.commercialization:
        return {
          icon: currencySignRounded,
          color: colors.secondary,
          name:
            currentOrganization?.tradeName || currentOrganization?.companyName,
          changeDialogMethod: changeOrganizationDialog,
          currentItem: currentOrganization,
          items: organizations
        }
      case AVAILABLE_MODULES.shoppingPlatform:
        return {
          icon: shoppingPlatform,
          color: colors.secondary,
          name:
            currentOrganization?.tradeName || currentOrganization?.companyName,
          changeDialogMethod: changeOrganizationDialog,
          currentItem: currentOrganization,
          items: organizations
        }
      case AVAILABLE_MODULES.administration:
        return {
          icon: dashboard,
          color: colors.secondary,
          name:
            currentOrganization?.tradeName || currentOrganization?.companyName,
          currentItem: currentOrganization,
          changeDialogMethod: changeOrganizationDialog,
          items: organizations
        }
      case AVAILABLE_MODULES.technical:
        return {
          icon: smallPlant,
          color: colors.secondary,
          name: !isEmpty(technicalCurrentOwner)
            ? currentProperty?.name
            : currentOrganization?.tradeName ||
              currentOrganization?.companyName,
          changeDialogMethod: !isEmpty(technicalCurrentOwner)
            ? changePropertyDialog
            : changeOrganizationDialog,
          currentItem: !isEmpty(technicalCurrentOwner)
            ? currentProperty
            : currentOrganization,
          items: !isEmpty(technicalCurrentOwner) ? properties : organizations
        }
      case AVAILABLE_MODULES.digitalProperty:
        return {
          icon: barnYellow,
          name: currentProperty?.name,
          changeDialogMethod: changePropertyDialog,
          currentItem: currentProperty,
          items: properties
        }
      default:
        return {
          chooseModuleScreen: true
        }
    }
  }, [
    changeOrganizationDialog,
    changePropertyDialog,
    currentModule,
    currentOrganization,
    currentProperty,
    organizations,
    properties,
    technicalCurrentOwner
  ])

  const isChooseModuleScreen = useMemo(
    () => currentModuleOptions?.chooseModuleScreen || false,
    [currentModuleOptions]
  )

  const menuItens = useMemo(
    () =>
      flow(
        filterFP((item) => item.menu),
        mapFP((item) => (
          <NavLink
            key={ item.path }
            to={ item.path }
            exact={
              item.menu?.exact !== undefined ? item.menu.exact : item.exact
            }
            className={ classes.menuLink }
            activeClassName={ classes.menuActive }
          >
            <div className={ classes.activeIndicator } />
            <ListItem classes={ { root: classes.listItem } } button>
              <ListItemIcon classes={ { root: classes.listItemIcon } }>
                <Tooltip title={ item.menu.title } placement="left">
                  <item.menu.Icon />
                </Tooltip>
              </ListItemIcon>
              <Fade in={ menuOpen }>
                <ListItemText
                  classes={ { root: classes.listItemText } }
                  primary={ item.menu.title }
                />
              </Fade>
            </ListItem>
          </NavLink>
        ))
      )(routes),
    [
      routes,
      classes.menuLink,
      classes.menuActive,
      classes.activeIndicator,
      classes.listItem,
      classes.listItemIcon,
      classes.listItemText,
      menuOpen
    ]
  )

  useEffect(() => {
    if (
      isEmpty(currentModuleOptions.currentItem) &&
      !isEmpty(currentModuleOptions.items) &&
      module === AVAILABLE_MODULES.digitalProperty
    ) {
      currentModuleOptions.changeDialogMethod()
      resetPages()
    }
  }, [changePropertyDialog, currentModuleOptions, module, resetPages])

  useEffect(() => {
    if (mounted) {
      if(wasAcceptedPrivacyAndNotUse) {
        termModal()
      } else {
        privacyModal(userIsSupplier ? () => {} : termModal)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrivacyTerm, mounted])

  const drawer = (
    <div className={ classes.drawerItems }>
      <div className={ classes.menuItensContainer }>
        <List>{menuItens}</List>
      </div>

      {menuOpen && (
        <div className={ classes.drawerFooter }>
          <Typography variant="caption">{`v${ version }`}</Typography>
        </div>
      )}
    </div>
  )

  const layoutProviderValue = useMemo(() => ({ mainRef }), [])

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(
    () => () => {
      setMounted(false)
    },
    []
  )

  useEffect(() => {

    const interval = setInterval(() => {
      if(familyGroupAccess && currentModule === 'digital-property') {
        dispatch(PropertyActions.loadProperties())
      }
      else {
        clearInterval(interval)
      }
    }, 10000)
    return () => clearInterval(interval)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyGroupAccess, currentModule, dispatch])

  const handleIAModal = useCallback(() => {
    createDialog({
      id: 'ia-modal',
      Component: IAModal
    })
  }
  , [createDialog])

  return (
    <div className={ classes.root }>
      <AppBar color="primary" position="fixed" className={ classes.appBar }>
        <Toolbar classes={ { root: classes.toolbarRoot } }>
          <div className={ classes.toolbarRightContainer }>
            {currentProperty?.name && extraAttributes?.enabledBot && (
              <IconButton tooltip="IA" onClick={ handleIAModal } >
                <Avatar src={ anaIa } />
              </IconButton>
            )}
            <Badge
              color="error"
              badgeContent={ newChatNotificationsCount }
              max={ 20 }
              overlap="circle"
              anchorOrigin={ {
                vertical: 'top',
                horizontal: 'right'
              } }
            >
              <ChatFabButton onNavigate={ changeModule } />
            </Badge>
            <IconButton
              color="inherit"
              edge="start"
              onClick={ handleOpenEbook }
              tooltip="E-book"
            >
              <Icon
                icon={ book }
                color={ colors.white }
                size={ 24 }
              />
            </IconButton>
            {routes.notifications && (
              <Badge
                color="error"
                badgeContent={ newNotificationsCount }
                max={ 20 }
                overlap="circle"
                anchorOrigin={ {
                  vertical: 'top',
                  horizontal: 'right'
                } }
              >
                <Popover
                  ref={ notificationsRef }
                  popoverId="notifications"
                  Component={ BellIconButton }
                  ComponentProps={ {
                    style: {
                      color: newNotificationsCount ? colors.red : undefined
                    },
                    edge: 'end',
                    iconColor: colors.white,
                    color: 'inherit',
                    parseColor: false
                  } }
                >
                  <NewNotificationsList
                    onSeeAllClick={ () => notificationsRef.current.onClose() }
                  />
                </Popover>
              </Badge>
            )}
            <Popover
              popoverId="user-menu"
              Component={ UserIconButton }
              ComponentProps={ {
                color: 'white',
                edge: 'end',
                iconColor: colors.white,
                userName: user.name
              } }
            >
              <UserMenuContainer style={ { minWidth: 150 } }>
                <UserMenu />
              </UserMenuContainer>
            </Popover>
          </div>
          {!isChooseModuleScreen && (
            <div className={ classes.leftWrapper }>
              <IconButton
                color="inherit"
                edge="start"
                onClick={ handleDrawerToggle }
                className={ classes.menuButton }
              >
                <Icon
                  icon={ menuOpen ? leftDoubleArrow : hamburguerMenu }
                  color={ colors.white }
                  size={ 22 }
                />
              </IconButton>

              <div
                className={ clsx(classes.leftContainer, classes.changeArea) }
                onClick={ changeModule }
              >
                <Icon
                  icon={ currentModuleOptions.icon }
                  size={ 33 }
                  color={ currentModuleOptions.color || colors.primary }
                />
                {userHasMultipleModules && (
                  <Icon icon={ arrowDown } color={ colors.white } size={ 18 } />
                )}
              </div>

              <div
                className={ clsx(classes.leftContainer, classes.changeProperty) }
                onClick={ currentModuleOptions.changeDialogMethod }
              >
                {currentModuleOptions.name}
                {size(currentModuleOptions.items) > 1 && (
                  <Icon icon={ arrowDown } color={ colors.white } size={ 18 } />
                )}
              </div>

              {isTechnicalModule && !isEmpty(technicalCurrentOwner) && (
                <div
                  className={ clsx(classes.leftContainer, classes.exitProperty) }
                  onClick={ technicalExitProperty }
                >
                  <Tooltip title={ t('comeback to technical area') }>
                    <Icon icon={ exitModule } color={ colors.white } size={ 24 } />
                  </Tooltip>
                </div>
              )}
            </div>
          )}
        </Toolbar>
      </AppBar>
      {!isChooseModuleScreen && (
        <nav className={ classes.drawer } aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={ container }
              variant="temporary"
              anchor={ theme.direction === 'rtl' ? 'right' : 'left' }
              open={ menuOpen && mobileSize }
              onClose={ handleDrawerToggle }
              classes={ {
                paper: classes.drawerPaper
              } }
              ModalProps={ {
                keepMounted: true // Better open performance on mobile.
              } }
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              variant="permanent"
              className={ clsx(classes.drawer, {
                [classes.drawerOpen]: menuOpen,
                [classes.drawerClose]: !menuOpen
              }) }
              classes={ {
                paper: clsx(classes.drawerPaper, {
                  [classes.drawerOpen]: menuOpen,
                  [classes.drawerClose]: !menuOpen
                })
              } }
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      )}
      <main id="main" ref={ mainRef } className={ classes.content }>
        {/* TODO toolbar */}
        <div className={ classes.toolbar } />
        <LayoutContext.Provider value={ layoutProviderValue }>
          {children}
        </LayoutContext.Provider>
      </main>
    </div>
  )
}

AuthenticatedLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.element,
  children: PropTypes.element
}

AuthenticatedLayout.defaultProps = {
  children: null,
  container: null
}

export default AuthenticatedLayout
