/* eslint-disable no-nested-ternary */
import styled from 'styled-components'

import { Typography } from '@material-ui/core'

import { colors } from '@smartcoop/styles'


export const PlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  background-color: ${ colors.lightGrey };
  margin-right: 25px;
  padding: 10px 10px;
  border: none;
  border-radius: 8px;
  height: 50px;
`

export const ButtonControls = styled.button`
  background-color: ${ colors.lightGrey };
  border: none;
`

export const TimerCounter = styled(Typography)`
  color: ${ colors.primary };
  font-size: 18px;
`

export const Recorder = styled.button`
  background-color: ${ ({ disabled, active }) => disabled ? colors.muted : active ? colors.lightYellow : colors.yellow };
  margin-right: 15px;
  box-shadow: ${ ({ active }) => active ? '0 0 20px rgba(255, 200, 10, 0.5)' : '' };
  animation: ${ ({ active }) => active ? 'blink-glow 1.5s infinite' : '' };

  @keyframes blink-glow {
    0%, 100% {
        box-shadow: 0 0 20px rgba(255, 200, 10, 0.5);
    }
    50% {
        box-shadow: 0 0 40px rgba(255, 200, 10, 1);
    }
}
`
