/* eslint-disable no-param-reassign */
import React, { useCallback, forwardRef, useState, useEffect } from 'react'
import  { useDispatch, useSelector } from 'react-redux'

import trimMask from '@meta-awesome/functions/src/trimMask'
import moment from 'moment/moment'
import PropTypes from 'prop-types'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'

import phoneMask from '@smartcoop/forms/masks/phone.mask'
import familyGroupAccessSchema from '@smartcoop/forms/schemas/profile/familyGroupAccess.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { searchCities, searchStates } from '@smartcoop/services/apis/ibgeApi'
import { FamilyGroupActions } from '@smartcoop/stores/familyGroup'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectProperties } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import { formatTextToPhone } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Form from '@smartcoop/web-components/Form'
import InputCep from '@smartcoop/web-components/InputCep'
import InputCpfCnpj from '@smartcoop/web-components/InputCpfCnpj'
import InputDate from '@smartcoop/web-components/InputDate'
import InputEmail from '@smartcoop/web-components/InputEmail'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import { Container, ButtonsContainer } from './styles'

const RegisterFamilyGroupAccessForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    loading,
    onSubmit,
    onCancel,
    propertiesToRegister,
    setPropertiesToRegister,
    userFound,
    setUserFound,
    defaultValues,
    editPermissions
  } = props

  const t = useT()

  const dispatch = useDispatch()
  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [propertyOptions, setPropertyOptions] = useState([])
  const [selectedProperties, setSelectedProperties] = useState(map(defaultValues?.userFamilyGroupProperties, property => property?.propertyId) ?? [])
  const properties = useSelector(selectProperties)

  useEffect(() => {
    if(!isEmpty(properties)){
      setPropertyOptions(map(properties, item => ({
        label: item.name,
        value: item.id
      })))
    }
  }, [properties])

  useEffect(() => {
    setPropertiesToRegister(map(selectedProperties, item => {
      const found = find(propertiesToRegister, property => property?.propertyId === item)

      return ({
        propertyId: item,
        read: found?.read ?? false,
        write: found?.write ?? false,
        financialDataAccess: found?.financialDataAccess ?? false
      })
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperties])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data,
        district: data.neighborhood,
        familyGroupPermissions: propertiesToRegister
      })
    },
    [onSubmit, propertiesToRegister]
  )

  const receiveAddress = useCallback(({ ...address }) => {
    formRef.current.setData({
      ...address
    })
  }, [formRef])

  const handleStateChange = useCallback(
    async (value) => {
      if (value) {
        let data = await searchCities(value)
        data = map(data, ({ nome }) => ({ label: nome, value: nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '') }))
        setCityOptions(data)
      } else {
        setCityOptions([])
        formRef.current.clearField('city')
      }
    },
    [formRef]
  )

  useEffect(() => {
    dispatch(PropertyActions.loadProperties())
    async function findStates() {
      let data = await searchStates()
      data = map(data, ({ sigla }) => ({ label: sigla, value: sigla }))
      setStateOptions(data)
    }
    findStates()
  }, [dispatch])

  const searchUser = useCallback(
    () => {
      setUserFound(null)
      const { document } = formRef.current.getData()
      if(!isEmpty(document)) {
        dispatch(FamilyGroupActions.searchUserByDocument(trimMask(document), (data) => setUserFound(data)))
      }
    },
    [dispatch, formRef, setUserFound]
  )

  useEffect(() => {
    if(userFound) {
      formRef.current.setData({
        ...userFound,
        cellPhone: formatTextToPhone(userFound.cellPhone)
      })
    }
  },[formRef, userFound])

  useEffect(() => {
    setTimeout(() => {
      if(formRef.current && defaultValues?.postalCode) {
        formRef.current.setData({
          postalCode: defaultValues?.postalCode,
          state: defaultValues?.state,
          city: defaultValues?.city
        })
      }
    }, 100)
  }, [defaultValues, formRef])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ defaultValues?.id && editPermissions ? null : familyGroupAccessSchema }
        schemaProps={ { userFound: !!userFound } }
        onSubmit={ handleSubmit }
      >
        {defaultValues?.id && editPermissions ? null : (
          <>
            <InputCpfCnpj
              name="document"
              label="CPF"
              fullWidth
              onBlur={ searchUser }
              defaultValue={ defaultValues?.document }
              disabled={ defaultValues?.document }
            />

            <InputText
              name="name"
              label={ t('name') }
              style={ { width: '100%' } }
              disabled={ !!userFound }
              defaultValue={ defaultValues?.name }
            />

            <InputEmail
              name="email"
              label={ t('email') }
              style={ { width: '100%' } }
              disabled={ !!userFound }
              defaultValue={ defaultValues?.email }
            />

            <InputText
              label={ t('phone') }
              name="cellPhone"
              style={ { width: '100%' } }
              disabled={ !!userFound }
              type={ userFound ? 'text' : 'tel' }
              setMask={ userFound ? undefined : phoneMask }
              defaultValue={ defaultValues?.cellPhone }
            />

            <div style={ { display: userFound ? 'none' : 'flex', flexDirection: 'column', width: '100%' } }>
              <InputDate
                name="dateOfBirth"
                label={ t('date of birth') }
                fullWidth
                pickerProps={ {
                  minDate: moment().subtract(100, 'years').format()
                } }
                minDate={ moment().subtract(100, 'years').format() }
                defaultValue={
                  defaultValues?.dateOfBirth
                    ? moment(defaultValues?.dateOfBirth, momentBackDateFormat).format(
                      momentBackDateFormat
                    )
                    : ''
                }
              />

              <InputCep
                name="postalCode"
                label={ t('cep') }
                fullWidth
                onAddressChange={ receiveAddress }
              />

              <InputSelect
                label={ t('uf') }
                name="state"
                onChange={ (e) => handleStateChange(e.target.value) }
                options={ stateOptions }
                fullWidth
                style={
                  {
                    width: '100%'
                  }
                }
              />

              <InputSelect
                label={ t('city', { howMany: 1 }) }
                name="city"
                options={ cityOptions }
                fullWidth
              />

              <InputText
                name="neighborhood"
                label={ t('neighborhood') }
                fullWidth
                defaultValue={ defaultValues?.district }
              />

              <InputText
                name="street"
                label={ t('street') }
                fullWidth
                defaultValue={ defaultValues?.street }
              />

              <InputText
                name="number"
                label={ t('number') }
                fullWidth
                defaultValue={ defaultValues?.number }
              />
            </div>
          </>
        )}

        { editPermissions && (
          <InputSelect
            detached
            multiple
            checkBoxSelectAll
            label={ t('property', { howMany: 2 }) }
            options={ propertyOptions }
            fullWidth
            value={ selectedProperties }
            onChange={ value => {
              const canSet = find(value, item => !isNil(item))
              setSelectedProperties(canSet ? value : [])
            }
            }
            defaultValue={ map(defaultValues?.userFamilyGroupProperties, property => property?.id) }
          />
        )}

        {(editPermissions && !isEmpty(propertyOptions) && !isEmpty(selectedProperties)) && map(selectedProperties, (item, key) => {
          const prop = find(propertyOptions, property => property?.value === item)

          return (
            <div key={ key } style={ { display: 'flex', flex: 1, flexDirection: 'column', textAlign: 'center', fontWeight: 'bold', justifyContent: 'space-between', alignItems: 'baseline' } }>
              <div style={ {  display: 'flex', flexDirection: 'row', marginBottom: 10 } }>
                {prop?.label}
              </div>
              <div style={ { display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center' } }>
                <CheckboxButton
                  detached
                  label={ t('view') }
                  onChange={ () => {
                    const newPropertyInfos = [...propertiesToRegister]
                    if(newPropertyInfos[key]?.read) {
                      newPropertyInfos[key].read = false
                      newPropertyInfos[key].write = false
                      newPropertyInfos[key].financialDataAccess = false
                    } else {
                      newPropertyInfos[key].read = true
                    }
                    setPropertiesToRegister(newPropertyInfos)
                  } }
                  checked={ propertiesToRegister[key]?.read ?? false }
                  value={ propertiesToRegister[key]?.read ?? false }
                  style={ { marginRight: 5 } }
                  labelStyle={ { fontSize: 14 } }
                />
                <CheckboxButton
                  detached
                  label={ t('edit') }
                  onChange={ () => {
                    const newPropertyInfos = [...propertiesToRegister]
                    if(newPropertyInfos[key]?.write) {
                      newPropertyInfos[key].write = false
                    } else if(newPropertyInfos[key]?.read) {
                      newPropertyInfos[key].write = true
                    }
                    setPropertiesToRegister(newPropertyInfos)
                  } }
                  checked={ (propertiesToRegister[key]?.read && propertiesToRegister[key]?.write) ?? false }
                  value={ propertiesToRegister[key]?.write ?? false }
                  disabled={ !propertiesToRegister[key]?.read }
                  style={ { marginLeft: 5, marginRight: 5 } }
                  labelStyle={ { fontSize: 14 } }
                />
                <CheckboxButton
                  detached
                  label={ t('financial data') }
                  onChange={ () => {
                    const newPropertyInfos = [...propertiesToRegister]
                    if(newPropertyInfos[key].financialDataAccess) {
                      newPropertyInfos[key].financialDataAccess = false
                    } else {
                      newPropertyInfos[key].financialDataAccess = true
                    }
                    setPropertiesToRegister(newPropertyInfos)
                  } }
                  checked={ propertiesToRegister[key]?.financialDataAccess ?? false }
                  value={ propertiesToRegister[key]?.financialDataAccess ?? false }
                  disabled={ !propertiesToRegister[key]?.read }
                  style={ { marginLeft: 5 } }
                  labelStyle={ { fontSize: 14 } }
                />
              </div>
            </div>
          )}
        )}
      </Form>
      {!withoutSubmitButton && (
        <ButtonsContainer>
          <Button
            id="web-cancel-form-button"
            onClick={ onCancel }
            color={ colors.white }
            disabled={ loading }
            style={ { marginRight: 5 } }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="web-save-form-button"
            onClick={ () => formRef.current.submit() }
            disabled={ loading || isEmpty(selectedProperties) }
            style={ { marginLeft: 5 } }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      )}
    </Container>
  )
})

RegisterFamilyGroupAccessForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  propertiesToRegister: PropTypes.array.isRequired,
  setPropertiesToRegister: PropTypes.func.isRequired,
  userFound: PropTypes.object,
  defaultValues: PropTypes.object,
  setUserFound: PropTypes.func.isRequired,
  editPermissions: PropTypes.bool
}

RegisterFamilyGroupAccessForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false,
  editPermissions: true,
  userFound: null,
  defaultValues: {}
}

export default RegisterFamilyGroupAccessForm
