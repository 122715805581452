import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ManagementName = styled(Typography).attrs({
  variant: 'body2'
})`
  margin: 0;
`

export const Date = styled(Typography).attrs({
  variant: 'caption'
})`
  margin: 0;
`

export const FieldName = styled(Typography).attrs({
  variant: 'body2'
})`
  padding-left: 5px;
`

export const DateContent = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
