import trimMask from '@meta-awesome/functions/src/trimMask'

import api from '../api'

export const login = async ({ document: doc, password }) => {
  const { data } = await api.post('/v1/auth', {
    document: trimMask(doc),
    password
  })
  return data
}

export const logout = async ({ accessToken, refreshToken }) => {
  const { data } = await api.post('/v1/auth/logout', {
    accessToken,
    refreshToken
  })
  return data
}

export const createVerificationCode = async ({ document: doc, protocol }) =>
  api.post('/v1/auth/verification-code/create', {
    codeType: 'password',
    document: trimMask(doc),
    protocol
  })

export const validateVerificationCode = async ({ code, document: doc }) => {
  const { data } = await api.post('/v1/auth/verification-code/validate', {
    code: trimMask(code),
    document: trimMask(doc)
  })
  return data
}

export const refreshToken = async (params, isLogout) => {
  const { data } = await api.post(`/v1/auth/refresh-token${ isLogout ? '?isLogout' : '' }`, params)
  return data
}

export const getTokenByOrganization = async (params, { organizationId }) => {
  const { data } = await api.post(`/v1/auth/${ organizationId }`, params)
  return data
}

export const validatePassword = async (params) => {
  const { data } = await api.post('/v1/auth/validate-password', params)
  return data
}

export const resetToken = async (params) => {
  const { data } = await api.post('/v1/auth/reset-token', params)
  return data
}

export const getTerms = async (params) =>{
  const { data } = await api.get('/v1/terms', { params })
  return data
}

export const getUserOnboarding = async (params, { document: doc }) => {
  const { data } = await api.get(`/v1/users/onboarding/${ trimMask(doc) }`, { params })
  return data
}

export const updateTerm = async (params) =>
  api.post('/v1/terms', params)
