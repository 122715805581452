import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import colors from '@smartcoop/styles/colors'

export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid ${ colors.lightGrey };
  margin: 0;
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 20px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 20px;
`

export const ButtonContainer = styled.div`
  width: 80px;
`

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.white
  },

  title: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    padding: '12px 13px 0 13px'
  }
})
