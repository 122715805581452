import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import I18n from '@smartcoop/i18n'
import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
`

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

export const Text = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  font-family: ${ fonts.fontFamilyMontserrat };
  color: ${ colors.black };
`

export const LabelText = styled(I18n)
  .attrs({
    style: {
      fontSize: '14px',
      fontFamily: fonts.fontFamilyMontserrat,
      fontWeight: 600,
      color: colors.grey,
      marginBottom: '3px'
    }
  })``

export const ValueText = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  font-family: ${ fonts.fontFamilyMontserrat };
  color: ${ props => JSON.parse(props.positive.toLowerCase()) ? colors.green : colors.red };
  margin-bottom: 5px;
`