/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Carousel from 'react-elastic-carousel'

import PropTypes from 'prop-types'

import { map } from 'lodash'

import { ThemeProvider } from '@material-ui/core/styles'

import { download } from '@smartcoop/icons'
import Icon from '@smartcoop/web-components/Icon'
import Modal from '@smartcoop/web-components/Modal'

import { Container, ContainerImage } from './style'

const PictureModal = ({
  id,
  open,
  src,
  initialFirstItem
}) => (
  <ThemeProvider
    theme={ (theme) => ({
      ...theme,
      overrides: {
        ...theme.overrides,
        MuiPaper: {
          ...(theme.overrides.MuiPaper || {}),
          root: {
            backgroundColor: 'transparent'
          },
          elevation24: {
            boxShadow: 'none'
          }
        }
      }
    }) }
  >
    <Modal
      id={ id }
      open={ open }
      maxWidth="xl"
      fullScreen
      disableFullScreen
      headerProps={ { closeIconColor: 'white' } }
      style={ { paddingTop: '20px' } }
      contentContainerStyle={ { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' } }
    >
      <Container>
        <Carousel initialFirstItem={ initialFirstItem } itemsToShow={ 1 } style={ { justifyContent: 'center', alignItems: 'center', width: '90vw', height: '90vh' } }>
          {map(src, item => (
            <ContainerImage key={ item?.fileKey }>
              <img src={ item?.fileSrc } style={ { maxWidth: '90vw', maxHeight: '90vh' } } alt=""/>
              <a style={ { alignSelf: 'flex-end' } } download={ item?.fileName || item?.fileKey } href={ item?.fileSrc }>
                <Icon
                  icon={ download }
                  color="white"
                  style={ { cursor: 'pointer', paddingLeft: '10px' } }
                  size={ 40 }
                /></a>
            </ContainerImage>
          ))}
        </Carousel>
      </Container>
    </Modal>
  </ThemeProvider>
)


PictureModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  initialFirstItem: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired
}

export default PictureModal
