import React, { useMemo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'


import { Report } from 'powerbi-report-component'
import PropTypes from 'prop-types'

import { selectBiWeatherStationId } from '@smartcoop/stores/weatherStations/selectorWeatherStations'

const WeatherStationDashboardReportFragment = ({ token, getMicrosoftToken }) => {
  // eslint-disable-next-line no-unused-vars
  const [report, setReport] = useState(null)
  const [showReport, setShowReport] = useState(true)

  const weatherStationId = useSelector(selectBiWeatherStationId)

  const filters = useMemo(() => ({
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'dEquipments',
      column: 'equipment_id'
    },
    operator: 'In',
    values: [Number(weatherStationId)]
  }), [weatherStationId])

  const handleReportLoad = useCallback(async (data) => {
    let currentFilters = []
    await data.getFilters().then((oldFilters => {
      currentFilters = oldFilters
    }))
    await data.setFilters([...currentFilters, filters])
    if (!showReport) {
      setShowReport(true)
    }

    setReport(data)
  }, [filters, setReport, showReport])


  const handleReportRender = useCallback(
    data => setReport(data),
    [setReport]
  )

  return (
    <Report
      tokenType="Aad"
      accessToken={ token }
      embedUrl="https://app.powerbi.com/reportEmbed"
      embedId="34c59494-a8e4-4282-ba91-e9987353347d"
      groupId="049c3fb9-864c-4001-bfc1-1df372818bad"
      pageName="ReportSection"
      reportMode="View"
      permissions="Read"
      onLoad={ handleReportLoad }
      onRender={ handleReportRender }
      style={ {
        width: '100%',
        height: '150%'
      } }
      eventHooks={ { accessTokenProvider: getMicrosoftToken } }
      extraSettings={ {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        hideErrors: false
      } }
    />
  )
}

WeatherStationDashboardReportFragment.propTypes = {
  token: PropTypes.string.isRequired,
  getMicrosoftToken: PropTypes.func.isRequired
}

export default WeatherStationDashboardReportFragment
