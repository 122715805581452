import React, { useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'


import filterInseminationSchema from '@smartcoop/forms/schemas/dairyFarm/filterInsemination.schema'
import { useT } from '@smartcoop/i18n'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputText from '@smartcoop/web-components/InputText'

import { Container } from './styles'

const FilterInseminationForm = forwardRef((props, formRef) => {
  const { onSubmit, filters } = props

  const t = useT()

  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterInseminationSchema }
        onSubmit={ handleSubmit }
      >
        <InputDate
          label={ t('date', { howMany: 1 }) }
          name="date"
          defaultValue={ filters.date }
        />
        <InputText
          label={ t('inseminator') }
          name="inseminator"
          defaultValue={ filters.inseminator }
        />
        <InputText
          label={ t('bull\'s code') }
          name="bullCode"
          defaultValue={ filters.bullCode }
        />
        <InputText
          label={ t('bull\'s name') }
          name="bullName"
          defaultValue={ filters.bullName }
        />
      </Form>
    </Container>
  )
})

FilterInseminationForm.propTypes = {
  filters: PropTypes.object,
  onSubmit: PropTypes.func
}

FilterInseminationForm.defaultProps = {
  filters: {},
  onSubmit: () => {}
}

export default FilterInseminationForm
