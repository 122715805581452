import styled from 'styled-components'

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  margin-right: 30px;
`

export const CheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
`
