import api from '../api'


export const getProductivityChallengeFormData = (params) =>
  api.get('/v1/productivity-challenge', { params })

export const createProductivityChallenge = async (params) => api.post('/v1/productivity-challenge', params)

export const deleteProductivityChallenge = async (params, { productivityChallengeId }) => api.delete(`/v1/productivity-challenge/${ productivityChallengeId }`, params)

export const getTechnicalProductivityChallenges = (params) => api.get('/v1/productivity-challenge/technician', { params })

export const getProductivityChallengeTerm = (params, { fieldId }) => api.get(`/v1/productivity-challenge/verify-accepted-term/field/${ fieldId }`, { params })

export const getProductivityChallengeByProperty = (params, { propertyId }) => api.get(`/v1/productivity-challenge/property/${ propertyId }`, { params })

export const getProductivityChallengeTermByField = (params, { fieldId }) => api.get(`/v1/productivity-challenge/field/${ fieldId }`, params)

export const getProductivityChallengeProducers = (params) => api.get('/v1/productivity-challenge/technician-producers', params)

export const patchProductivityChallengeTerm = (params) => api.patch('/v1/productivity-challenge/accept-productivity-term', params)

export const getProjectTypes = (params) => api.get('/v1/productivity-challenge/projects', params)
