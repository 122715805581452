import React, { useState, useMemo, useCallback, useContext } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import orderBy from 'lodash/orderBy'

import { conversationService } from '@smartcoop/database/services/conversationService'
import { database } from '@smartcoop/database/web-database'
import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { emptyFilter } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Loader from '@smartcoop/web-components/Loader'

import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser'
import DeleteConversationsModal from '../../../modals/DeleteConversationsModal/DeleteConversationsModal'
import { ChatContext } from '../../../providers/ChatProvider'
import { ChatSyncContext } from '../../../providers/ChatSyncProvider'
import ConversationsHeader from '../ConversationHeader'
import ConversationListItem from '../ConversationListItem'
import ConversationSearch from '../ConversationSearch'
import { ListContainer, Container, LoaderContainer } from './styles'


const Conversations = ({ conversations }) => {
  const [openSelectConversations, setOpenSelectConversations] = useState(false)
  const [selectedConversations, setSelectedConversations] = useState([])

  const t = useT()
  const snackbar = useSnackbar()
  const localUser = useAuthenticatedUser()
  const { createDialog } = useDialog()
  const { searchParams } = useContext(ChatContext)
  const { loading } = useContext(ChatSyncContext)

  const allOptionsSelected = useMemo(
    () => conversations.filter(item => !item.group && !item.broadcast).length === selectedConversations.length,
    [conversations, selectedConversations]
  )

  const onSelectConversation = id => {
    if (selectedConversations.includes(id)) {
      const index = selectedConversations.indexOf(id)
      const tmpArray = [...selectedConversations]

      tmpArray.splice(index, 1)

      return setSelectedConversations(tmpArray)
    }
    return setSelectedConversations(state => [...state, id])
  }

  const onSelectAllConversations = useCallback(() => {
    if (allOptionsSelected) {
      return setSelectedConversations([])
    }
    return setSelectedConversations([
      ...conversations.filter(item => !item.group && !item.broadcast)
        .map(item => item.relationalId)
    ])
  }, [allOptionsSelected, conversations])

  const handleOpenSelectConversations = value => {
    setOpenSelectConversations(value)
    setSelectedConversations([])
  }

  const orderedConversations = useMemo(
    () => orderBy(conversations, ['favorite', 'createdAt'], ['desc', 'desc']),
    [conversations]
  )

  const handleDeleteConversations = async () => {
    try {
      await conversationService(database).deleteConversations(selectedConversations)

      snackbar.success(t('conversations deleted successfully'))
    } catch(error) {
      snackbar.error(t('error delete conversations. contact your system administrator'))
    } finally {
      handleOpenSelectConversations(false)
    }
  }

  const onDeleteConversations = () => {
    if (!selectedConversations) {
      return snackbar.warning(t('select at least one conversation to delete'))
    }

    return createDialog({
      id: 'confirm-delete-conversations',
      Component: DeleteConversationsModal,
      props: {
        handleDelete: handleDeleteConversations
      }
    })
  }

  if (loading) {
    return (
      <Container>
        <ConversationsHeader
          onSelectAllConversations={ onSelectAllConversations }
          onOpenSelect={ () => handleOpenSelectConversations(true) }
          onCloseSelect={ () => handleOpenSelectConversations(false) }
          openSelect={ openSelectConversations }
          allOptionsSelected={ allOptionsSelected }
          onDeleteConversations={ onDeleteConversations }
        />
        <LoaderContainer>
          <Loader width={ 100 } height={ 100 } />
        </LoaderContainer>
      </Container>
    )
  }

  return (
    <Container>
      <ConversationsHeader
        onSelectAllConversations={ onSelectAllConversations }
        onOpenSelect={ () => handleOpenSelectConversations(true) }
        onCloseSelect={ () => handleOpenSelectConversations(false) }
        openSelect={ openSelectConversations }
        allOptionsSelected={ allOptionsSelected }
        onDeleteConversations={ onDeleteConversations }
        userId={ localUser?.userId }
      />

      { (searchParams && searchParams?.length < 3) ? (
        <LoaderContainer>
          <p>{t('enter at least 3 characters to start the search')}...</p>
          <Loader width={ 100 } />
        </LoaderContainer>
      ) : (
        <ListContainer>
          { searchParams?.length >= 3 ? (
            <ConversationSearch searchParams={ searchParams } />
          ) : (
            <>
              {orderedConversations.length ? (
                orderedConversations.map(item => (
                  <ConversationListItem
                    key={ item.conversationId }
                    conversation={ item }
                    selected={ selectedConversations.includes(item.relationalId) }
                    onSelect={ onSelectConversation }
                    openSelect={ openSelectConversations }
                    userId={ (item.group || item.broadcast) ? item.conversationId : item?.contactId }
                    isGroup={ item.group || item.broadcast }
                  />
                ))
              ) : (
                <EmptyState
                  text={ t('no conversation found') }
                  icon={ emptyFilter }
                />
              )}
            </>
          ) }
        </ListContainer>
      ) }

    </Container>
  )
}

Conversations.propTypes = {
  conversations: PropTypes.array
}

Conversations.defaultProps = {
  conversations: []
}

const enhance = withObservables([], () => ({
  conversations: conversationService(database).observeConversations()
}))

const EnhancedConversations = enhance(Conversations)

export default EnhancedConversations
