/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { filter } from '@smartcoop/icons'
import { getOrganizations as getOrganizationsService } from '@smartcoop/services/apis/smartcoopApi/resources/organization'
import { selectModuleIsAdmin } from '@smartcoop/stores/module/selectorModule'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import colors from '@smartcoop/styles/colors'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import { formatCpfCnpj } from '@smartcoop/utils/formatters'
import Badge from '@smartcoop/web-components/Badge'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import InputSearchDebounced from '@smartcoop/web-components/InputSearchDebounced'
import FilterOrganizationModal from '@smartcoop/web-containers/modals/shoppingPlatform/FilterOrganizationModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { Container, Title, Row, RightContainer } from './styles'

const ListOrganizationScreen = () => {
  const history = useHistory()
  const { routes } = useRoutes()
  const { createDialog } = useDialog()
  const t = useT()

  const currentOrganization = useSelector(selectCurrentOrganization)
  const isAdminModule = useSelector(selectModuleIsAdmin)

  const [filters, setFilters] = useState({ type: '1' })
  const [debouncedFilterText, setDebouncedFilterText] = useState('')
  const [activeFilter, setActiveFilter] = useState(false)

  const tableFilters = useMemo(
    () =>
      debouncedFilterText
        ? { ...filters, q: debouncedFilterText }
        : { ...filters },
    [debouncedFilterText, filters]
  )

  const columns = useMemo(
    () => [
      {
        title: 'CNPJ',
        field: 'companyDocument',
        render: (row) => formatCpfCnpj(row.companyDocument)
      },
      {
        title: 'Razão Social',
        field: 'companyName',
        cellStyle: { whiteSpace: 'nowrap' }
      },
      {
        title: 'Nome Fantasia',
        field: 'tradeName'
      },
      {
        title: 'Matriz/Filial',
        field: 'isSubsidiary',
        cellStyle: { fontWeight: 'bold' },
        render: (row) =>
          row.isSubsidiary ? 'Unidade/Filial' : t('headquarter', { howMany: 1 })
      },
      {
        title: 'Status',
        field: 'active',
        render: (row) => (
          <div style={ { display: 'flex' } }>
            <Badge
              backgroundColorBadge={ row.active ? '#289F30' : '#E41D1B' }
              colorBadge={ row.active ? 'rgb(40,159,48)' : 'rgb(228,29,27)' }
            >
              {row.active ? 'Ativo' : 'Inativo'}
            </Badge>
          </div>
        )
      }
    ],
    [t]
  )

  const handleFilter = useCallback(
    (values) => {
      setFilters({ type: '1', ...values })
      if(!isEmpty(values)) {
        setActiveFilter(true)
      } else {
        setActiveFilter(false)
      }
    },
    []
  )

  const filterData = useCallback(() => {
    createDialog({
      id: 'filter-organization',
      Component: FilterOrganizationModal,
      props: {
        onSubmit: handleFilter,
        filters
      }
    })
  }, [createDialog, filters, handleFilter])

  const onChangeSearchFilter = useCallback((value) => {
    setDebouncedFilterText(value)
  }, [])

  const goToOrganizationRegister = useCallback(
    () => history.push(routes.createOrganization.path),
    [history, routes.createOrganization.path]
  )

  const onRowClick = useCallback(
    (event, data) => {
      history.push(routes.editOrganization.path, { organization: { ...data } })
    },
    [history, routes.editOrganization.path]
  )

  return (
    <Container>
      <div style={ { display: 'flex', flexDirection: 'row', marginBottom: 15, marginTop: 5 } }>
        {currentOrganization?.logoData && !isAdminModule && (
          <div style={ { display: 'flex', flexDirection: 'row' } }>
            <img alt={ currentOrganization?.tradeName } src={ downloadFromBase64(currentOrganization?.logoData) } style={ { height: 60, width: 120, objectFit: 'contain', padding: 4 } } />
            <div style={ { marginRight: 10, marginLeft: 10, height: '100%', width: 8, backgroundColor: colors?.secondary } }/>
          </div>
        )}
        <Title>
          <I18n>list of organizations</I18n>
        </Title>
      </div>
      <Row>
        <RightContainer>
          <InputSearchDebounced onChange={ onChangeSearchFilter } />
          <Button
            id="filter"
            onClick={ filterData }
            color={ !activeFilter ? 'white' : 'secondary' }
            style={ { flex: 'none', whiteSpace: 'nowrap' } }
          >
            <>
              <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 } />
              <I18n>filtrate</I18n>
            </>
          </Button>
          <Button
            id="web-create-organization-button"
            onClick={ goToOrganizationRegister }
            color="secondary"
            style={ { flex: 'none', whiteSpace: 'nowrap', marginLeft: 16 } }
          >
            <I18n>register</I18n>
          </Button>
        </RightContainer>
      </Row>
      <DataTable
        onRowClick={ onRowClick }
        data={ getOrganizationsService }
        queryParams={ tableFilters }
        urlParams={ {} }
        columns={ columns }
        id="list-organization-table"
      />
    </Container>
  )
}

export default ListOrganizationScreen
