import React, { useState, useMemo } from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { nextPage, previousPage } from '@smartcoop/icons'
import { AllowedImageTypesEnum, AllowedVideoTypesEnum } from '@smartcoop/utils/constants'
import { convertFileSize } from '@smartcoop/utils/files'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import Modal from '@smartcoop/web-components/Modal'

import { ButtonGroup, CarouselWrapper, Image, ImageContainer, Title, Video, Container, FileSizeContainer, UnavailablePreview } from './styles'

const ConfirmSendModal = ({ id, open, files, handleSubmit, handleClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const t = useT()

  const renderItem = (file) => {
    if (AllowedImageTypesEnum.includes(file.type)) {
      return <Image src={ URL.createObjectURL(file) } alt={ file?.name } />
    }

    if (AllowedVideoTypesEnum.includes(file.type)) {
      return <Video src={ URL.createObjectURL(file) } controls />
    }

    return <UnavailablePreview>{file.name} - {t('preview unavailable')}</UnavailablePreview>
  }

  const showCarouselActions = useMemo(() => files.length > 1, [files])

  const nextFile = () => {
    if (currentIndex + 1 < files.length) {
      return setCurrentIndex(state => state + 1)
    }
    return setCurrentIndex(0)
  }

  const previousFile = () => {
    if (currentIndex === 0) {
      return setCurrentIndex(files.length - 1)
    }
    return setCurrentIndex(state => state - 1)
  }

  const onSubmit = () => {
    handleClose()
    handleSubmit(files)
  }

  return (
    <Modal
      id={ id }
      open={ open }
      escape
      hideHeader
      maxWidth="sm"
      fullWidth
      disableFullScreen
      contentContainerStyle={ { padding: 10 } }
    >
      <Container>
        <Title>{t('preview - {index} of {total}', { index: currentIndex + 1, total: files.length })}</Title>

        <CarouselWrapper>
          {showCarouselActions && (
            <IconButton onClick={ previousFile }>
              <Icon icon={ previousPage } size={ 15 } />
            </IconButton>
          )}

          {files.map((file, index) => (
            <ImageContainer key={ file?.name } visible={ currentIndex === index }>
              {renderItem(file)}
            </ImageContainer>
          ))}

          {showCarouselActions && (
            <IconButton onClick={ nextFile }>
              <Icon icon={ nextPage } size={ 15 } />
            </IconButton>
          )}
        </CarouselWrapper>
        <FileSizeContainer>
          {files?.[currentIndex]?.size ? convertFileSize(files?.[currentIndex]?.size) : null}
        </FileSizeContainer>

        <ButtonGroup>
          <Button onClick={ handleClose }>{t('cancel')}</Button>
          <Button color="secondary" onClick={ onSubmit }>{t('send')}</Button>
        </ButtonGroup>
      </Container>
    </Modal>
  )
}

ConfirmSendModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  files: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default ConfirmSendModal
