import React, { useMemo } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import { organizationRounded } from '@smartcoop/icons'
import {
  momentBackDateTimeFormat,
  momentFriendlyDateTimeFormat
} from '@smartcoop/utils/dates'
import Avatar from '@smartcoop/web-components/Avatar'
import Icon from '@smartcoop/web-components/Icon'
import ReadMore from '@smartcoop/web-components/ReadMore'

import {
  Body,
  Col,
  Container,
  Header,
  Identifier,
  Name,
  Text,
  Timer,
  Privacy,
  Type
} from './styles'

const NonCompliance = (props) => {
  const {
    style,
    nonCompliance: { observations, user, organization, createdAt },
    length
  } = props

  const time = useMemo( () => moment(createdAt, momentBackDateTimeFormat).subtract(3, 'hours').format(momentFriendlyDateTimeFormat), [createdAt])

  return (
    <Container style={ style }>
      <Header>
        <Identifier>
          <Avatar alt={ user?.name  } src={ user?.image || user?.name } />
          <Col>
            <Name>{user?.name }</Name>
            <Privacy>
              <Icon icon={ organizationRounded } />
              <Type>{organization?.tradeName || 'Organização'}</Type>
            </Privacy>
          </Col>
        </Identifier>
        <Timer>
          {time}
        </Timer>
      </Header>
      <Body>
        <Text>
          <ReadMore length={ length }>{observations}</ReadMore>
        </Text>
      </Body>
    </Container>
  )
}

NonCompliance.propTypes = {
  style: PropTypes.object,
  nonCompliance: PropTypes.shape({
    commentaries: PropTypes.arrayOf(
      PropTypes.shape({
        images: PropTypes.array,
        id: PropTypes.string,
        comment: PropTypes.string,
        userId: PropTypes.string,
        notComplianceId: PropTypes.string
      })
    ),
    images: PropTypes.array,
    id: PropTypes.string,
    deliveryLocationId: PropTypes.string,
    observations: PropTypes.string,
    createdAt: PropTypes.string,
    statusId: PropTypes.number,
    user: PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.string
    }),
    organization: PropTypes.shape({
      id: PropTypes.string,
      organizationId: PropTypes.string,
      companyName: PropTypes.string,
      tradeName: PropTypes.string,
      companyDocument: PropTypes.string,
      postalCode: PropTypes.string,
      street: PropTypes.string,
      district: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      type: PropTypes.number,
      stateRegistration: PropTypes.string,
      active: PropTypes.bool,
      country: PropTypes.string,
      isSubsidiary: PropTypes.bool,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string
    }),
    deliveryLocation: PropTypes.shape({
      bankSlip: PropTypes.array,
      id: PropTypes.string,
      organizationPurchaseId: PropTypes.string,
      organizationId: PropTypes.string,
      amount: PropTypes.number,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      organization: PropTypes.shape({
        id: PropTypes.string,
        organizationId: PropTypes.string,
        companyName: PropTypes.string,
        tradeName: PropTypes.string,
        companyDocument: PropTypes.string,
        postalCode: PropTypes.string,
        street: PropTypes.string,
        district: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        type: PropTypes.number,
        stateRegistration: PropTypes.string,
        active: PropTypes.bool,
        country: PropTypes.string,
        isSubsidiary: PropTypes.bool,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string
      }),
      received: PropTypes.bool,
      receiptDate: PropTypes.string,
      organizationPurchase: PropTypes.shape({
        mainPurchase: PropTypes.arrayOf(
          {
            paymentForms: PropTypes.arrayOf(
              {
                id: PropTypes.string,
                mainPurchaseId: PropTypes.string,
                deferredPayment: PropTypes.bool,
                daysAmount: PropTypes.number,
                createdAt: PropTypes.string,
                updatedAt: PropTypes.string
              }
            ),
            id: PropTypes.string,
            productId: PropTypes.string,
            receiptDeadline: PropTypes.string,
            participationDeadline: PropTypes.string,
            statusId: PropTypes.number,
            createdAt: PropTypes.string,
            updatedAt: PropTypes.string,
            organizationId: PropTypes.string,
            code: PropTypes.number,
            proposalDeadline: PropTypes.string,
            deliveryPercentage: PropTypes.number,
            decisionDeadline: PropTypes.string
          }
        ),
        id: PropTypes.string,
        organizationId: PropTypes.string,
        mainPurchaseId: PropTypes.string,
        openingDate:PropTypes.string,
        totalAmount: PropTypes.number,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        organization: PropTypes.shape({
          id: PropTypes.string,
          companyName: PropTypes.string,
          tradeName: PropTypes.string,
          companyDocument: PropTypes.string,
          postalCode: PropTypes.string,
          street: PropTypes.string,
          district: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
          type: 1,
          stateRegistration: PropTypes.string,
          active: PropTypes.bool,
          country: PropTypes.string,
          isSubsidiary: PropTypes.bool,
          createdAt: PropTypes.string,
          updatedAt: PropTypes.string,
          dbSchema: PropTypes.string
        }),
        received: PropTypes.bool,
        proposalAccepted: PropTypes.bool
      })
    })

  }),
  length: PropTypes.number
}

NonCompliance.defaultProps = {
  style: {},
  length: 200,
  nonCompliance: {
    commentaries: [],
    images: [],
    id: '',
    deliveryLocationId: '',
    observations: '',
    statusId: PropTypes.number,
    deliveryLocation: PropTypes.shape({
      bankSlip: [],
      id: '',
      organizationPurchaseId: '',
      organizationId: '',
      amount: PropTypes.number,
      createdAt: '',
      updatedAt: '',
      organization: PropTypes.shape({
        id: '',
        organizationId: '',
        companyName: '',
        tradeName: '',
        companyDocument: '',
        postalCode: '',
        street: '',
        district: '',
        city: '',
        state: '',
        type: PropTypes.number,
        stateRegistration: '',
        active: PropTypes.bool,
        country: '',
        isSubsidiary: PropTypes.bool,
        createdAt: '',
        updatedAt: PropTypes.string
      }),
      received: PropTypes.bool,
      receiptDate: '',
      organizationPurchase: PropTypes.shape({
        mainPurchase: PropTypes.arrayOf(
          {
            paymentForms: PropTypes.arrayOf(
              {
                id: '',
                mainPurchaseId: '',
                deferredPayment: PropTypes.bool,
                daysAmount: PropTypes.number,
                createdAt: '',
                updatedAt: PropTypes.string
              }
            ),
            id: '',
            productId: '',
            receiptDeadline: '',
            participationDeadline: '',
            statusId: PropTypes.number,
            createdAt: '',
            updatedAt: '',
            organizationId: '',
            code: PropTypes.number,
            proposalDeadline: '',
            deliveryPercentage: PropTypes.number,
            decisionDeadline: PropTypes.string
          }
        ),
        id: '',
        organizationId: '',
        mainPurchaseId: '',
        openingDate:'',
        totalAmount: PropTypes.number,
        createdAt: '',
        updatedAt: '',
        organization: PropTypes.shape({
          id: '',
          companyName: '',
          tradeName: '',
          companyDocument: '',
          postalCode: '',
          street: '',
          district: '',
          city: '',
          state: '',
          type: 1,
          stateRegistration: '',
          active: PropTypes.bool,
          country: '',
          isSubsidiary: PropTypes.bool,
          createdAt: '',
          updatedAt: '',
          dbSchema: PropTypes.string
        }),
        received: PropTypes.bool,
        proposalAccepted: PropTypes.bool
      })
    })

  }
}

export default NonCompliance
