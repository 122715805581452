import api from '../api'

export const getAdvertisements = (params) =>
  api.get('/v1/chat/advertising/get', { params })

export const createAdvertising = (params) =>
  api.post('/v1/chat/advertising', params)

export const deleteAdvertising = (advertisingId) =>
  api.delete(`/v1/chat/advertising/${ advertisingId }`)

export const createAdvertisingFiles = (params) =>
  api.post('/v1/chat/advertising/files', params, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

export const updateAdvertising = (params, { advertisingId }) =>
  api.patch(`/v1/chat/advertising/${ advertisingId }`, params)

export const sendAdvertising = (advertisingId) =>
  api.post(`/v1/chat/advertising/send/${ advertisingId }`)

export const refuseAdvertising = (advertisingId, reason) =>
  api.patch(`/v1/chat/advertising/${ advertisingId }/block`, { reason })
