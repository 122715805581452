import api from '../api'

const URL_NON_COMPLIANCES = '/non-compliances'

export const getNonCompliances = (params) => api.get(`/v1${ URL_NON_COMPLIANCES }`, { params })

export const createNonCompliance = (params = {}, deliveryLocation = '') => api.post(`/v1${ URL_NON_COMPLIANCES }/${ deliveryLocation }`, params)

export const editNonCompliance = (params = {}, nonComplianceId = '') => api.patch(`/v1${ URL_NON_COMPLIANCES }/${ nonComplianceId }`, params)

export const deleteNonCompliance = (nonComplianceId = '') => api.delete(`/v1${ URL_NON_COMPLIANCES }/${ nonComplianceId }`)

export const getNonCompliance = (params = {}, nonComplianceId = '') => api.get(`/v1${ URL_NON_COMPLIANCES }/${ nonComplianceId }`, { params })

export const createNonComplianceComment = (params = {}, nonComplianceId = '') => api.post(`/v1${ URL_NON_COMPLIANCES }/${ nonComplianceId }/comment`, params)
