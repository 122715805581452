import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import colors from '@smartcoop/styles/colors'
import Button from '@smartcoop/web-components/Button'

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.backgroundHtml
  },
  title: {
    fontWeight: 900,
    fontSize: 16,
    lineHeight: '16px',
    padding: '12px 13px 0 13px',
    textAlign: 'center'
  }
})

export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const CustomButton = styled(Button)`
  margin: 10px 0;
`
export const TextDiv = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #1d1d1b;
  padding: 0 10%;
`
