import * as Yup from 'yup'

import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/isEmpty'

import float from '@smartcoop/forms/validators/float.validator'
import number from '@smartcoop/forms/validators/number.validator'
import required from '@smartcoop/forms/validators/required.validator'


const fertilizationCropManagementSchema = ({ t, props }) => Yup.object().shape({
  formFields: Yup.array()
    .of(
      Yup.object().shape({
        product: props?.fromSeeding ? Yup.string().nullable() : flow(required({ t }))(Yup.string()),

        n: flow(
          number({ t })
        )(Yup.string()),

        p: flow(
          number({ t })
        )(Yup.string()),

        k: flow(
          number({ t })
        )(Yup.string()),

        s: flow(
          number({ t })
        )(Yup.string()),

        ca: flow(
          number({ t })
        )(Yup.string()),

        mg: flow(
          number({ t })
        )(Yup.string()),

        zn: flow(
          number({ t })
        )(Yup.string()),

        bo: flow(
          number({ t })
        )(Yup.string()),

        dose: flow(
          float({ t })
        )(Yup.string()),

        unit:  Yup.string().when('dose', {
          is: val => !isEmpty(val) && val !== '' && val !== 0 && val !== '0' && val !== '0,00',
          then: required({ t })(Yup.string()),
          otherwise: Yup.string()
        }),

        applicationMode: Yup.string().nullable(),

        value: Yup.string().nullable(),
        valueHA: Yup.string().nullable(),
        valueDose: Yup.string().nullable(),

        typeOfApplication: Yup.string().nullable()
      })
    )
})

export default fertilizationCropManagementSchema
