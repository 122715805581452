import React from 'react'

import PropTypes from 'prop-types'

import { InputTextarea } from './styles'

const Textarea = ({
  onSubmit,
  onChange,
  ...props
}) => {
  const onKeyPress = event => {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault()
      onSubmit()
    }
  }

  return (
    <InputTextarea
      onKeyPress={ onKeyPress }
      onChange={ event => onChange(event.target.value) }
      { ...props }
    />
  )
}

Textarea.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func
}

Textarea.defaultProps = {
  onSubmit: () => {},
  onChange: () => {}
}

export default Textarea
