
import React, { useCallback, useMemo, useRef } from 'react'

// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import get from 'lodash/get'
import toNumber from 'lodash/toNumber'

import { useT } from '@smartcoop/i18n'
import colors from '@smartcoop/styles/colors'
import usePagination from '@smartcoop/web-app/src/hooks/usePagination'
import Form from '@smartcoop/web-components/Form'
import FirstPageIconButton from '@smartcoop/web-components/IconButton/FirstPageIconButton'
import LastPageIconButton from '@smartcoop/web-components/IconButton/LastPageIconButton'
import NextPageIconButton from '@smartcoop/web-components/IconButton/NextPageIconButton'
import PreviousPageIconButton from '@smartcoop/web-components/IconButton/PreviousPageIconButton'
import InputSelect from '@smartcoop/web-components/InputSelect'


import {
  Container,
  LastRequestContainer,
  PaginationContainer,
  UnselectedPage,
  SelectedPage,
  ItensPerPageText,
  SelectContainer
} from './styles'

const Pagination = props => {
  const {
    count,
    page: externalPage,
    onChangeRowsPerPage,
    rowsPerPage,
    onChangePage,
    hideLastRequestDate,
    lastRequestDate,
    disabled,
    hidePagination,
    id
  } = props

  const t = useT()

  const { page: contextPage, onChangePage: contextOnChangePage } = usePagination()

  const page = useMemo(() => get(contextPage, id, externalPage), [contextPage, externalPage, id])

  const formRef = useRef(null)

  const schemaConstructor = useCallback(
    () =>
      Yup.object().shape({
        rowsPerPage: Yup.number()
      }),
    []
  )

  const rowsPerPageOptions = useMemo(() => [
    { label: '10', value: '10' },
    { label: '20', value: '20' },
    { label: '50', value: '50' },
    { label: '100', value: '100' }
  ], [])

  const NumberPagination = () => (
    <PaginationContainer>
      { page !== 0 &&
        (
          <>
            { page !== 1 &&
              (
                <UnselectedPage onClick={ e => handlePageClick(e, page - 2) }>
                  {page - 2 + 1 }
                </UnselectedPage>
              )
            }
            <UnselectedPage onClick={ e => handlePageClick(e, page - 1) }>
              {page - 1 + 1}
            </UnselectedPage>
          </>
        )
      }

      <SelectedPage>
        {page + 1}
      </SelectedPage>

      {
        page !== Math.max(0, Math.ceil(count / rowsPerPage) - 1) &&
        (
          <>
            <UnselectedPage onClick={ e => handlePageClick(e, props.page + 1) }>
              {page + 1 + 1}
            </UnselectedPage>
            {page !== Math.max(0, Math.ceil(count / rowsPerPage) - 2) &&
              (
                <UnselectedPage onClick={ e => handlePageClick(e, page + 2) }>
                  {page + 2 + 1}
                </UnselectedPage>
              )
            }
          </>
        )
      }
    </PaginationContainer>
  )

  const handleFirstPageButtonClick = useCallback(event => {
    if (!disabled) {
      onChangePage(event, 0)
      contextOnChangePage({ [id]: 0 })
    }
  }, [contextOnChangePage, disabled, id, onChangePage])

  const handleBackButtonClick = useCallback(event => {
    if (!disabled) {
      onChangePage(event, page - 1)
      contextOnChangePage({ [id]: page - 1 })
    }
  }, [contextOnChangePage, disabled, id, onChangePage, page])

  const handleNextButtonClick = useCallback(event => {
    if (!disabled) {
      onChangePage(event, page + 1)
      contextOnChangePage({ [id]: page + 1 })
    }
  }, [contextOnChangePage, disabled, id, onChangePage, page])

  const handleLastPageButtonClick = useCallback(event => {
    if (!disabled) {
      onChangePage(
        event,
        Math.max(0, Math.ceil(count / rowsPerPage) - 1)
      )
      contextOnChangePage({ [id]: Math.max(0, Math.ceil(count / rowsPerPage) - 1) })
    }
  }, [contextOnChangePage, count, disabled, id, onChangePage, rowsPerPage])

  const handlePageClick = useCallback((event, currentPage) => {
    if (!disabled) {
      onChangePage(event, currentPage)
      contextOnChangePage({ [id]: currentPage })
    }
  }, [contextOnChangePage, disabled, id, onChangePage])

  const handleChangeRowsPerPage = useCallback((event) => {
    const params = {
      target: {
        value: event ? parseInt(event.target.value, 10) : 10
      }
    }
    onChangeRowsPerPage(params)
  }, [onChangeRowsPerPage])

  const showLastRequestDate = useMemo(
    () => !hideLastRequestDate && lastRequestDate,
    [hideLastRequestDate, lastRequestDate]
  )

  return (
    <Container showLastRequestDate={ showLastRequestDate }>
      {
        showLastRequestDate &&
          (
            <LastRequestContainer>
              {`${ t('last update at') }: ${ lastRequestDate }`}
            </LastRequestContainer>
          )
      }
      {!hidePagination && (
        <PaginationContainer>
          {
            page !== 0 &&
          (
            <>
              <FirstPageIconButton
                color={ colors.darkGrey }
                onClick={ handleFirstPageButtonClick }
              />
              <PreviousPageIconButton
                color={ colors.darkGrey }
                onClick={ handleBackButtonClick }
              />
            </>
          )
          }
          <NumberPagination />
          {
            page !== Math.max(0, Math.ceil(count / rowsPerPage) - 1) &&
        (
          <>
            <NextPageIconButton
              color={ colors.darkGrey }
              onClick={ handleNextButtonClick }
            />
            <LastPageIconButton
              color={ colors.darkGrey }
              onClick={ handleLastPageButtonClick }
            />
          </>
        )
          }

          <ItensPerPageText style={ { marginRight: '6px' } }>
        itens per page
          </ItensPerPageText>
          <SelectContainer>
            <Form ref={ formRef } schemaConstructor={ schemaConstructor }>
              <InputSelect
                style={ { marginTop: '16px', paddingRight: '10px' } }
                name="rowsPerPage"
                label=""
                onChange={ e => handleChangeRowsPerPage(e) }
                options={ rowsPerPageOptions }
                defaultValue={ rowsPerPage.toString() }
                disableClearable
                formatOrderByLabel={ (item) => toNumber(item) }
              />
            </Form>
          </SelectContainer>
        </PaginationContainer>
      ) }
    </Container>
  )
}

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  hideLastRequestDate: PropTypes.bool.isRequired,
  hidePagination: PropTypes.bool,
  lastRequestDate: PropTypes.string,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

Pagination.defaultProps = {
  lastRequestDate: '',
  disabled: false,
  hidePagination: false
}

export default Pagination
