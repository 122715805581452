import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
`
