import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import { selectAllModules } from '@smartcoop/stores/authentication/selectorAuthentication'
import { AVAILABLE_MODULES, ModuleActions } from '@smartcoop/stores/module'
import PartialLoading from '@smartcoop/web-components/PartialLoading'
import ChangeOrganizationModal from '@smartcoop/web-containers/modals/ChangeOrganizationModal'

const ChooseOrganizationScreen = (props) => {
  const {
    location
  } = props

  const allModules = useSelector(selectAllModules)

  const module = useMemo(() => location?.state?.module ?? allModules[0].slug, [allModules, location])

  const { createDialog } = useDialog()

  const dispatch = useCallback(useDispatch(), [])

  const [mounted, setMounted] = useState(false)

  const changeOrganizationDialog = useCallback(() => {
    if (module) {
      createDialog({
        id: 'organization-onboarding',
        Component: ChangeOrganizationModal,
        props: {
          module
        }
      })
    }
  }, [createDialog, module])

  useEffect(() => {
    if (mounted && module !== AVAILABLE_MODULES.digitalProperty) {
      changeOrganizationDialog()
    } else if (mounted && module === AVAILABLE_MODULES.digitalProperty) {
      dispatch(
        ModuleActions.initModuleBySlug(AVAILABLE_MODULES.digitalProperty)
      )
    }
  }, [changeOrganizationDialog, dispatch, location, module, mounted])

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(
    () => () => {
      setMounted(false)
    },
    []
  )

  return <PartialLoading open displayContent />
}

ChooseOrganizationScreen.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      module: PropTypes.string
    }).isRequired
  }).isRequired
}

ChooseOrganizationScreen.defaultProps = {}

export default withRouter(ChooseOrganizationScreen)
