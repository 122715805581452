import styled from 'styled-components'

import RadioGroup from '@smartcoop/web-components/RadioGroup'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
`
export const CustomRadioGroup = styled(RadioGroup)`
  margin-right: 10px;
`
