import React from 'react'

import PropTypes from 'prop-types'

import Modal from '@smartcoop/web-components/Modal'
import WeatherForecast from '@smartcoop/web-containers/fragments/WeatherForecast'

const WeatherForecastModal = (props) => {
  const { id, open, handleClose } = props

  return (
    <Modal
      id={ id }
      open={ open }
      maxWidth="lg"
    >
      <WeatherForecast handleClose={ handleClose } />
    </Modal>
  )
}

WeatherForecastModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default WeatherForecastModal
