/* eslint-disable no-unused-expressions */
import React, { forwardRef, useMemo, useCallback, useEffect, useState } from 'react'
import { useSelector , useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import isNumber from 'lodash/isNumber'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'
import uniqBy from 'lodash/uniqBy'

import productivityContestSchema from '@smartcoop/forms/schemas/challenges/productivityContest.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { ChallengesActions } from '@smartcoop/stores/challenges'
import { selectProductivityChallengeFormData, selectProjectTypes } from '@smartcoop/stores/challenges/selectorChallenges'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container, TextDescription } from '../styles'

const ProductivityForm = forwardRef((props, formRef) => {
  const { onSubmit, withoutSubmitButton, defaultValues } = props

  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const currentOrganization = useSelector(selectCurrentOrganization)
  const productivityChallengeFormData = useSelector(selectProductivityChallengeFormData)
  const projectTypes = useSelector(selectProjectTypes)

  const [userId, setUserId] = useState()
  const [propertyId, setPropertyId] = useState()
  const [field, setField] = useState()
  const [project, setProject] = useState()

  const producersOptions = useMemo(
    () => !isNumber(project)
      ? []
      : map(uniqBy(productivityChallengeFormData, 'userProperty.userId'), ({ userProperty: { user } }) => ({ ...user, value: user.id, label: user.name })) || []
    ,[productivityChallengeFormData, project]
  )

  const propertiesOptions = useMemo(
    () => !userId
      ? []
      : map(
        filter(productivityChallengeFormData, item => item.userProperty.userId === userId),
        (item) => ({
          ...item,
          value: item?.id,
          label: item?.name
        })) || [],
    [productivityChallengeFormData, userId]
  )

  const fieldOptions = useMemo(
    () => !propertyId
      ? []
      : map(
        find(productivityChallengeFormData, item => item.id === propertyId)?.fields,
        (item) => ({
          ...item,
          value: item?.id,
          label: item?.fieldName
        })) || [],
    [productivityChallengeFormData, propertyId]
  )

  const urlParams = useMemo(
    () => ({ organizationId: currentOrganization.id }),
    [currentOrganization.id]
  )

  const projectOptions = useMemo(
    () => map(projectTypes, item => ({
      label: item?.projectName,
      value: `${ item?.id }`
    })), [projectTypes]
  )

  const requiredField = useMemo(() => find(projectTypes, item => item?.id === project && item?.requiredField), [project, projectTypes])

  const requiredGrowingSeason = useMemo(() => find(projectTypes, item => item?.id === project && item?.requiredGrowingSeason), [project, projectTypes])

  const handleProjectChange = useCallback((value) => {
    setProject(value)
    setUserId()
    setPropertyId()
    setField()
    formRef.current.getFieldRef('userId').setValue('')
    formRef.current.getFieldRef('propertyId').setValue('')
    if(requiredField) {
      formRef.current.getFieldRef('fieldId').setValue('')
    }
  }, [formRef, requiredField])

  const handleProducerChange = useCallback(
    (value) => {
      setUserId(value)
      setPropertyId()
      setField()
      formRef.current.getFieldRef('propertyId').setValue('')
      if(requiredField) {
        formRef.current.getFieldRef('fieldId').setValue('')
      }
    },
    [formRef, requiredField]
  )

  const handlePropertyChange = useCallback(
    (value) => {
      setPropertyId(value)
      setField()
      if(requiredField) {
        formRef.current.getFieldRef('fieldId')?.setValue('')
      }
    },
    [formRef, requiredField]
  )

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({ ...data, growingSeasonId: field?.growingSeasons[0]?.id, projectId: toNumber(data?.projectId) })
    },
    [field, onSubmit]
  )

  useEffect(() => {
    dispatch(ChallengesActions.loadProductivityChallengeData({ requiredField, requiredGrowingSeason }))
  }, [dispatch, requiredField, requiredGrowingSeason])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ productivityContestSchema }
        schemaProps={ { isFieldRequired: requiredField } }
        onSubmit={ (data) => handleSubmit(data) }
      >
        <InputSelect
          options={ projectOptions }
          label={ t('project type') }
          name="projectId"
          defaultValue={ defaultValues?.projectId }
          onChange={ ({ target: { value } }) => handleProjectChange(toNumber(value)) }
        />

        {isNumber(project) && (
          <TextDescription>{find(projectTypes, item => item?.id === project)?.description}</TextDescription>
        )}

        <InputSelect
          name="userId"
          label={ t('associate name') }
          defaultValue={ defaultValues?.producerId }
          options={ producersOptions }
          onChange={ ({ target: { value } }) => handleProducerChange(value) }
        />

        <InputSelect
          name="propertyId"
          label={ t('property', { howMany: 1 }) }
          defaultValue={ defaultValues?.propertyId }
          options={ propertiesOptions }
          onChange={ ({ target: { value } }) => handlePropertyChange(value) }
        />

        {requiredField && (
          <InputSelect
            name="fieldId"
            label={ t('field', { howMany: 2 }) }
            urlParams={ urlParams }
            defaultValue={ defaultValues?.fieldId }
            options={ fieldOptions }
            onChange={ (_, item) => setField(item) }
          />
        )}

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-productivity-project-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

ProductivityForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  withoutSubmitButton: PropTypes.bool,
  defaultValues: PropTypes.object
}

ProductivityForm.defaultProps = {
  withoutSubmitButton: false,
  defaultValues: {}
}

export default ProductivityForm
