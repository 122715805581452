import api from '../api'

export const getInseminations = (params, { animalId }) =>
  api.get(`/v1/herds-management/animals/${ animalId }/inseminations`, { params })

export const getAllInseminationTypes = (params) =>
  api.get('/v1/herds-management/animals/inseminations/types', { params })

export const getInseminationTypes = (params, { animalId }) =>
  api.get(`/v1/herds-management/animals/${ animalId }/inseminations/types`, { params })

export const getBulls = (params) =>
  api.get('/v1/herds-management/bulls', { params })

export const createInsemination = async (params) => {
  const { data } = await api.post('/v1/herds-management/animals/inseminations', params)
  return data
}

export const updateInsemination = async (params, { animalId, inseminationId }) => {
  const { data } = await api.patch(`/v1/herds-management/animals/${ animalId }/inseminations/${ inseminationId }`, params)
  return data
}

export const deleteInsemination = async (params, { animalId, inseminationId }) => {
  const { data } = await api.delete(`/v1/herds-management/animals/${ animalId }/inseminations/${ inseminationId }`, params)
  return data
}
