import moment from 'moment'

import countBy from 'lodash/countBy'
import entries from 'lodash/entries'
import filter from 'lodash/filter'
import flow from 'lodash/flow'
import forEach from 'lodash/forEach'
import head from 'lodash/head'
import last from 'lodash/last'
import map from 'lodash/map'
import max from 'lodash/max'
import maxBy from 'lodash/maxBy'
import mean from 'lodash/mean'
import min from 'lodash/min'
import partialRight from 'lodash/partialRight'
import sum from 'lodash/sum'


export const selectCurrent = state => state.weatherForecast.current

export const selectForecast = state => state.weatherForecast.forecast

export const selectNextHour = state =>
  state.weatherForecast.forecast?.date.slice(0, 24).map(
    (item, index) => ({
      date: item,
      humidity: state.weatherForecast.forecast?.humidity[index],
      precipChance: state.weatherForecast.forecast?.precipChance[index],
      iconCode: state.weatherForecast.forecast?.iconCode[index],
      precipitation: state.weatherForecast.forecast?.precipitation[index],
      temperature: state.weatherForecast.forecast?.temperature[index],
      wind: state.weatherForecast.forecast?.wind[index],
      windDirection: state.weatherForecast.forecast?.windDirection[index],
      windGust: state.weatherForecast.forecast?.windGust[index]
    })
  )

export const selectNextDays = state => {
  const days = []

  forEach(state.weatherForecast.forecast?.date, (date, index) => {
    const formatedDate = moment(date).format('DD/MM/YYYY')
    const exists = days.find(item => item.date === formatedDate)

    if (exists) {
      days[days.indexOf(exists)] = {
        ...exists,
        humidity: [...exists.humidity, state.weatherForecast.forecast?.humidity[index]],
        precipChance: [...exists.precipChance, state.weatherForecast.forecast?.precipChance[index]],
        iconCode: [...exists.iconCode, state.weatherForecast.forecast?.iconCode[index]],
        precipitation: [...exists.precipitation, state.weatherForecast.forecast?.precipitation[index]],
        temperature: [...exists.temperature, state.weatherForecast.forecast?.temperature[index]],
        wind: [...exists.wind, state.weatherForecast.forecast?.wind[index]],
        windDirection: [...exists.windDirection, state.weatherForecast.forecast?.windDirection[index]],
        windGust: [...exists.windGust, state.weatherForecast.forecast?.windGust[index]]
      }
    } else {
      days.push({
        hours: state.weatherForecast.forecast?.date.slice(index, index + 24).map(
          (item, subIndex) => ({
            date: item,
            humidity: state.weatherForecast.forecast?.humidity[index + subIndex],
            precipChance: state.weatherForecast.forecast?.precipChance[index + subIndex],
            iconCode: state.weatherForecast.forecast?.iconCode[index + subIndex],
            precipitation: state.weatherForecast.forecast?.precipitation[index + subIndex],
            temperature: state.weatherForecast.forecast?.temperature[index + subIndex],
            wind: state.weatherForecast.forecast?.wind[index + subIndex],
            windDirection: state.weatherForecast.forecast?.windDirection[index + subIndex],
            windGust: state.weatherForecast.forecast?.windGust[index + subIndex]
          })),
        date: formatedDate,
        humidity: [state.weatherForecast.forecast?.humidity[index]],
        precipChance: [state.weatherForecast.forecast?.precipChance[index]],
        iconCode: [state.weatherForecast.forecast?.iconCode[index]],
        precipitation: [state.weatherForecast.forecast?.precipitation[index]],
        temperature: [state.weatherForecast.forecast?.temperature[index]],
        wind: [state.weatherForecast.forecast?.wind[index]],
        windDirection: [state.weatherForecast.forecast?.windDirection[index]],
        windGust: [state.weatherForecast.forecast?.windGust[index]]
      })
    }
  })

  return map(
    filter(days, item => item.date !== moment().format('DD/MM/YYYY')),
    item => ({
      ...item,
      humidity: Math.round(mean(item.humidity)),
      precipChance: Math.round(mean(item.precipChance)),
      temperature: Math.round(max(item.temperature)),
      wind: Math.round(max(item.wind)),
      windDirection: Math.round(mean(item?.windDirection)),
      windGust: Math.round(mean(item?.windGust)),
      precipitation: Math.round(sum(item?.precipitation)),
      high: Math.round(max(item.temperature)),
      low: Math.round(min(item.temperature)),
      iconCode: flow(
        countBy,
        entries,
        partialRight(maxBy, last),
        head
      )(item.iconCode)
    })
  )
}

export const selectAggregatedRain = state => state.weatherForecast.aggregatedRain
