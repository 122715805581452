import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import logo from '@smartcoop/styles/assets/images/logoLogin.svg'
import webBackground from '@smartcoop/styles/assets/images/web-background-version-3.png'
import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export default makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    backgroundColor: colors.white
  },
  contentScroll: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    minHeight: '100%',
    zIndex: 5
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    padding: '22vh 50px 0px 50px',
    height: '100%'
  },
  backgroundImage: {
    width: '100%',
    alignItems: 'flex-start',
    backgroundSize: 'cover',
    objectFit: 'contain'
  },
  logoContainer: {
    width: '100%',
    marginTop: '10%',
    display: 'flex',
    justifyContent: 'flex-end',
    objectPosition: '10px 0px'
  },
  gridLogin: {
    backgroundImage: `url('${  logo  }')`,
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat'
  }
})

export const ImageBackground = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  background-image: url('${ webBackground }');
  background-repeat: no-repeat;
  background-position: top 0 right 0;
  background-size: cover;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 130px;
`

export const CompanyName = styled(Typography).attrs({
  variant: 'h5',
  component: 'h1',
  align: 'center'
})`
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: ${ fonts.fontWeight.bold };
`

export const CompanySlogan = styled(Typography).attrs({
  variant: 'h5',
  component: 'h2',
  align: 'center'
})`
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: ${ fonts.fontWeight.light };
`

export const BackgroundContainer = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  overflow: hidden;
`
