/* eslint-disable no-unused-expressions */
import React, { useCallback, forwardRef, useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import join from 'lodash/join'
import last from 'lodash/last'
import map from 'lodash/map'
import split from 'lodash/split'
import uniqBy from 'lodash/uniqBy'

import advertisingSchema from '@smartcoop/forms/schemas/administration/advertising.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { noImage } from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { getOrganizations as getOrganizationsService } from '@smartcoop/services/apis/smartcoopApi/resources/organization'
import { getPermissionsProfiles } from '@smartcoop/services/apis/smartcoopApi/resources/permissions'
import { selectUserProfileIsSmartcoop } from '@smartcoop/stores/authentication/selectorAuthentication'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { AllowedDocumentTypesEnum, AllowedVideoTypesEnum,AllowedAudioTypesEnum, AllowedImageTypesEnum } from '@smartcoop/utils/constants'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import CooperativeDocument from '@smartcoop/web-components/CooperativeDocument'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Form from '@smartcoop/web-components/Form'
import InputDateRange from '@smartcoop/web-components/InputDateRange'
import InputFile from '@smartcoop/web-components/InputFile'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import { Container, Row, CentralizedColumn, Title } from './styles'

const AdvertisingForm = forwardRef((props, formRef) => {
  const { onSubmit, defaultValues, readOnly } = props
  const t = useT()

  const userProfileIsSmartcoop = useSelector(selectUserProfileIsSmartcoop)
  const currentOrganization = useSelector(selectCurrentOrganization)

  const [selectedModules, setSelectedModules] = useState([])
  const [permissionProfiles, setPermissionProfiles] = useState([])
  const [advertisingFiles, setAdvertisingFiles] = useState([])
  const [defaultValuesFiles, setDefaultValuesFiles] = useState([])
  const [rangeDate, setRangeDate] = useState({ from: defaultValues?.startDate, to: defaultValues?.endDate })

  const renderFile = useCallback(async (file) => {
    const fileUrl = split(file, '/')
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_CHAT_ID, fileKey: last(fileUrl) })

    return downloadFromBase64(data)
  }, [])

  useEffect(() => {
    const getFiles = async () => {
      const video = defaultValues?.content?.video || []
      const audio = defaultValues?.content?.audio || []
      const image = defaultValues?.content?.image || []
      const document = defaultValues?.content?.document || []

      const files = await Promise.all(map([...video, ...audio, ...image, ...document], async (item) => {
        const src = await renderFile(item?.url)

        return ({
          url: item?.url,
          src
        })
      }))
      setDefaultValuesFiles(files)
    }
    if(defaultValues?.id) {
      getFiles()
    }
  },[defaultValues, renderFile])

  useEffect(() => {
    const getPermissions = async () => {
      const { data } = await getPermissionsProfiles()
      setPermissionProfiles(data)
    }

    getPermissions()
  }, [])

  const modulesList = useMemo(() => uniqBy(flatMap(permissionProfiles, item => flatMap(item?.modules)), 'id'), [permissionProfiles])

  const profilesList = useMemo(() => uniqBy(map(filter(permissionProfiles, item => find(selectedModules, module => includes(map(item?.modules, internal => internal?.id), module))), item => {
    const updatedData = { ...item }
    delete updatedData.modules
    return updatedData
  }, 'id')), [permissionProfiles, selectedModules])

  useEffect(() => {
    formRef?.current?.getFieldRef?.('profilesIds')?.setValue?.(map(profilesList, item => item?.id))
  }, [formRef, profilesList])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit(
        {
          ...defaultValues,
          ...data,
          startDate: rangeDate?.from,
          endDate: rangeDate?.to
        },
        advertisingFiles,
        defaultValuesFiles
      )
    },
    [advertisingFiles, defaultValues, defaultValuesFiles, onSubmit, rangeDate]
  )

  const handleChangeFile = useCallback(
    (event) => {
      const { files } = event.target
      const filesArray = map(files, item => item)
      setAdvertisingFiles(old => [...old, ...filesArray])
    },
    []
  )

  const removeFile = useCallback(
    (data) => {
      setAdvertisingFiles(old => filter(old, item => item?.name !== data?.name))
    },
    []
  )

  const removeDefaultFile = useCallback(
    (data) => {
      setDefaultValuesFiles(old => filter(old, item => item?.url !== data?.url))
    },
    []
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flex: 1, flexDirection: 'column' } }
        ref={ formRef }
        schemaConstructor={ advertisingSchema }
        onSubmit={ handleSubmit }
      >
        <Row>
          <InputSelect
            label={ t('organization', { howMany: 2 }) }
            name="organizationsIds"
            options={ getOrganizationsService }
            asyncOptionLabelField="tradeName"
            multiple
            disabled={ !userProfileIsSmartcoop || readOnly }
            defaultValue={ defaultValues?.organizationsIds || [currentOrganization?.id] }
          />
        </Row>
        <Row>
          <InputSelect
            label={ t('module', { howMany: 2 }) }
            name="modulesIds"
            onChange={ ({ target: { value } }) => setSelectedModules(value) }
            multiple
            disabled={ readOnly }
            options={ map(modulesList, (item) => ({ value: item?.id, label: item?.name })) }
            defaultValue={ defaultValues?.modulesIds }
          />
        </Row>
        <Row>
          <InputSelect
            label={ t('profile', { howMany: 2 }) }
            name="profilesIds"
            options={ map(profilesList, (item) => ({ value: item?.id, label: item?.name })) }
            multiple
            disabled={ readOnly }
            defaultValue={ defaultValues?.profilesIds }
          />
        </Row>
        <Row>
          <InputText
            label={ t('message') }
            name="content.message"
            defaultValue={ defaultValues?.content?.message }
            multiline
            disabled={ readOnly }
            rows={ 6 }
            fullWidth
          />
        </Row>
        <Row>
          <InputDateRange
            label={ t('period') }
            name="rangeDate"
            value={ rangeDate }
            onChange={ setRangeDate }
            fullWidth
            disabled={ readOnly }
            pickerProps={ {
              fullWidth: true,
              style: {
                width: '100%'
              }
            } }
          />
        </Row>
        <Title style={ { fontSize: 18, fontWeight: 600, margin: 0 } }>
          <I18n params={ { howMany: 2 } }>files</I18n>
        </Title>
        <CentralizedColumn>
          {isEmpty(advertisingFiles) && isEmpty(defaultValuesFiles) ? (
            <EmptyState text={ t('no files added') } icon={ noImage } iconProps={ { size: 150 } }/>
          ) : null}
          {
            map(defaultValuesFiles, (file) => (
              <CooperativeDocument
                key={ file.url }
                documentName={ last(split(file.url, '/')) }
                deleteDocument={ () =>
                  removeDefaultFile(file)
                }
                documentSrc={ file?.src }
                textStyle={ { width: 500, textAlign: 'start' } }
              />
            ))
          }
          {
            map(advertisingFiles, (file) => (
              <CooperativeDocument
                key={ file.name }
                documentName={ file.name }
                deleteDocument={ () =>
                  removeFile(file)
                }
                documentSrc={ isObject(file) ? URL.createObjectURL(file) : file }
                textStyle={ { width: 500, textAlign: 'start' } }
              />
            ))
          }
          <InputFile
            idInput="contained-button-file"
            onChange={ (event) => handleChangeFile(event) }
            inputProps={ {
              accept: join([...AllowedDocumentTypesEnum, ...AllowedImageTypesEnum, ...AllowedVideoTypesEnum, ...AllowedAudioTypesEnum], ','),
              multiple: true
            } }
            disabled={ readOnly }
            buttonProps={ {
              id: 'add-file',
              color: 'secondary',
              disabled: readOnly,
              style: {
                marginTop: 10,
                marginBottom: 10
              }
            } }
          >
            <>
              <I18n>add file</I18n>
            </>
          </InputFile>
        </CentralizedColumn>

      </Form>
    </Container>
  )
})

AdvertisingForm.propTypes = {
  onSubmit: PropTypes.func,
  readOnly: PropTypes.bool,
  defaultValues: PropTypes.object
}

AdvertisingForm.defaultProps = {
  onSubmit: () => {},
  readOnly: false,
  defaultValues: null
}

export default AdvertisingForm
