import React, { useContext, useMemo } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import { contactService } from '@smartcoop/database/services/contactService'
import { conversationService } from '@smartcoop/database/services/conversationService'
import { messageService } from '@smartcoop/database/services/messageService'
import { database } from '@smartcoop/database/web-database'
import { useT } from '@smartcoop/i18n'
import { emptyFilter } from '@smartcoop/icons'
import { uniqBy } from '@smartcoop/utils/functions'
import EmptyState from '@smartcoop/web-components/EmptyState'

import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser'
import { ChatContext } from '../../../providers/ChatProvider'
import ConversationListItem from '../ConversationListItem'
import ConversationSearchContact from '../ConversationSearchContact'
import ConversationSearchMessage from '../ConversationSearchMessage'
import { Title } from './styles'


const ConversationSearch = ({
  searchParams,
  contacts,
  messages,
  conversations,
  allConversations
}) => {
  const t = useT()
  const { openChat, startConversation } = useContext(ChatContext)
  const localUser = useAuthenticatedUser()


  const filteredContacts = useMemo(
    () => {
      const foundContacts = contacts.filter(contact => !conversations.some(conversation => contact.contactId === conversation.contactId))
      return uniqBy(foundContacts, 'contactId')
    },
    [contacts, conversations]
  )

  const orderedMessages = useMemo(() => messages.reverse(), [messages])

  const conversationsNames = useMemo(() => {
    const names = {}

    allConversations.forEach(item => { names[item.relationalId] = item.name })

    return names
  }, [allConversations])

  if (!conversations.length && !contacts.length && !messages.length) {
    return (
      <EmptyState
        text={ t('no conversation found') }
        icon={ emptyFilter }
      />
    )
  }

  return (
    <>
      {/* Conversations */}
      {conversations?.map(item => (
        <ConversationListItem
          key={ item.id }
          conversation={ item }
          userId={ item?.contactId }
          searchParams={ searchParams }
        />
      ))}
      {/* Contacts List */}
      {!!filteredContacts?.length && (
        <Title>{t('contacts')}</Title>
      )}
      {filteredContacts?.map(item => (
        <ConversationSearchContact
          key={ item.id }
          contact={ item }
          userId={ item?.contactId }
          startConversation={ startConversation }
          searchParams={ searchParams }
        />
      ))}
      {!!messages?.length && (
        <Title>{t('messages')}</Title>
      )}
      {/* Messages List */}
      {orderedMessages?.map(item => (
        <ConversationSearchMessage
          key={ item.id }
          message={ item }
          openChat={ openChat }
          searchParams={ searchParams }
          conversationsNames={ conversationsNames }
          userId={ localUser?.userId }
        />
      ))}
    </>
  )
}

ConversationSearch.propTypes = {
  searchParams: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired,
  contacts: PropTypes.array.isRequired,
  conversations: PropTypes.array.isRequired,
  allConversations: PropTypes.array.isRequired
}


const enhance = withObservables(['searchParams'], ({ searchParams }) => ({
  messages: messageService(database).observeMessagesByFilter(searchParams),
  contacts: contactService(database).observeContactsByFilter(searchParams),
  conversations: conversationService(database).observeConversationsByFilter(searchParams),
  allConversations: conversationService(database).observeConversations(),
  allContacts: contactService(database).observeContacts()
}))

const EnhancedConversationSearch = enhance(ConversationSearch)

export default EnhancedConversationSearch
