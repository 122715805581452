import React, { useCallback, forwardRef, useMemo, useState } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import map from 'lodash/map'
import split from 'lodash/split'

import informativeSchema from '@smartcoop/forms/schemas/property/informative.schema'
import { useT } from '@smartcoop/i18n'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import { Container } from './styles'

const InformativeForm = forwardRef((props, formRef) => {
  const { organizationOptions, onSubmit, defaultValues } = props
  const t = useT()

  const [proprietaryAllow, setProprietaryAllow] = useState(defaultValues?.proprietaryAllow ?? 'false')

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...defaultValues,
        ...data,
        keywords: !isEmpty(data?.keywords) ? join(data?.keywords, ', ') : defaultValues?.keywords,
        proprietaryAllow
      })
    },
    [defaultValues, onSubmit, proprietaryAllow]
  )

  const informativeOptions = useMemo(() => [
    {
      label: 'Webinar',
      value: 'webinar'
    },
    {
      label: 'Vídeo técnico',
      value: 'technicalVideos'
    },
    {
      label: 'Boletim técnico',
      value: 'boletim-tecnico'
    },
    {
      label: 'Resultado de Pesquisa',
      value: 'searchResults'
    },
    {
      label: 'Novidades',
      value: 'news'
    },
    {
      label: 'Informativos',
      value: 'informatives'
    }
  ],[])

  const defaultKeyWords = useMemo(() => map(split(defaultValues?.keyWords, ','), item => ({ value: item, label: item })),[defaultValues])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flex: 1, flexDirection: 'column' } }
        ref={ formRef }
        schemaConstructor={ informativeSchema }
        onSubmit={ handleSubmit }
      >
        <InputSelect
          label={ t('select one organization') }
          name="organizationId"
          options={ organizationOptions }
          defaultValue={ defaultValues?.organizationId }
        />
        <InputSelect
          label={ t('file type') }
          name="fileType"
          options={ informativeOptions }
          defaultValue={ defaultValues?.fileType }
        />
        <InputDate
          label={ t('publication date') }
          name="publicationDate"
          fullWidth
          defaultValue={ moment(defaultValues?.publicationDate, momentBackDateFormat).format(momentBackDateFormat) }
        />
        <InputText
          name="name"
          label={ t('file name') }
          defaultValue={ defaultValues?.name }
        />
        <InputSelect
          multiple
          creatable
          name="keyWords"
          label={ t('keywords') }
          options={ !isEmpty(defaultValues?.keyWords) ? defaultKeyWords : [] }
          defaultValue={ !isEmpty(defaultValues?.keyWords) ? split(defaultValues?.keyWords, ',') : null }
        />
        <InputText
          name="fileUrl"
          label={ t('file url') }
          defaultValue={ defaultValues?.fileUrl }
        />
        <InputText
          name="coverUrl"
          label={ t('cover url') }
          defaultValue={ defaultValues?.coverUrl }
        />
        <CheckboxButton
          label={ t('available for producer') }
          onChange={ () => {
            if(proprietaryAllow === 'true') {
              setProprietaryAllow('false')
            } else {
              setProprietaryAllow('true')
            }
          } }
          checked={ proprietaryAllow === 'true' }
          value={ proprietaryAllow }
        />
      </Form>
    </Container>
  )
})

InformativeForm.propTypes = {
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.object,
  organizationOptions: PropTypes.array
}

InformativeForm.defaultProps = {
  onSubmit: () => {},
  defaultValues: null,
  organizationOptions: []
}

export default InformativeForm
