import React, { useCallback } from 'react'

import PropTypes from 'prop-types'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import map from 'lodash/map'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { productivityChallange } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { FieldActions } from '@smartcoop/stores/field'
import colors from '@smartcoop/styles/colors'
import { GROWING_SEASON_COLOR } from '@smartcoop/utils/maps'
import Button from '@smartcoop/web-components/Button'
import Card from '@smartcoop/web-components/Card'
import Icon from '@smartcoop/web-components/Icon'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import PolygonToSvg from '@smartcoop/web-components/PolygonToSvg'
import Tooltip from '@smartcoop/web-components/Tooltip'

import {
  ContainerField,
  TextGroup,
  TextLeft,
  TextBold,
  Text,
  CropTextGroup,
  CropText,
  Row
} from './styles'

const GrowingSeasonHistoryItem = (props) => {
  const {
    growingSeason,
    growingSeasons,
    isPlanned,
    loadFields,
    dispatch,
    onClick
  } = props

  const snackbar = useSnackbar()
  const { createDialog } = useDialog()
  const t = useT()

  const findGrowingSeasonById = useCallback((id) => {
    const foundGrowingSeason = find(growingSeasons, item => item.childrenPolygonId === id)
    return foundGrowingSeason
  },[growingSeasons])

  const findGrowingSeasonPolygonName = useCallback(() => {
    const foundGrowingSeason = find(growingSeason?.fieldData?.childrenPolygons, item => item.id === growingSeason?.childrenPolygonId)
    return foundGrowingSeason && `- ${ foundGrowingSeason?.name }`
  },[growingSeason])

  const onStartGrowingSeason = useCallback(() => {
    dispatch(FieldActions.saveOfflineGrowingSeason(
      {
        ...growingSeason,
        isPlanned: false
      },
      () => {
        snackbar.success(
          t('the cultivation has been started')
        )
        loadFields()
      },
      () => {},
      false,
      true
    ))
  }, [dispatch, growingSeason, loadFields, snackbar, t])

  return !isPlanned && !isEmpty(growingSeason?.fieldData?.childrenPolygons)
    ? (
      <>
        {map(isString(growingSeason?.fieldData?.childrenPolygons) ? JSON.parse(growingSeason?.fieldData?.childrenPolygons) : growingSeason?.fieldData?.childrenPolygons, (item) => {

          const itemGrowingSeason = isEmpty(growingSeasons) ? growingSeason : findGrowingSeasonById(item.id)

          return !isEmpty(itemGrowingSeason) && (
            <Card
              style={ { marginBottom: 17, marginTop: 0, cursor: 'pointer' } }
              cardStyle={ { padding: '14px', width: '100%', marginBottom: 0 } }
              onClick={ () => isPlanned ? onClick({ field: growingSeason?.fieldData, childrenPolygonId: growingSeason?.childrenPolygonId }) : onClick(growingSeason?.fieldData, item.id, itemGrowingSeason, growingSeasons ) }
            >
              <ContainerField>
                <PolygonToSvg
                  shape={ map(isString(item.coordinates) ? JSON.parse(item.coordinates) : item.coordinates, ([ lat, lng ]) => ({ lat, lng })) }
                  color={ GROWING_SEASON_COLOR[itemGrowingSeason?.crop?.slug] || colors.orange }
                  width={ 40 }
                />
                <TextGroup>
                  <TextLeft>
                    <Row>
                      {growingSeason?.fieldData?.productivityChallengeParticipation && (
                        <Tooltip title={ t('field participating in RTC productivity contest') }>
                          <Icon icon={ productivityChallange } size={ 14 } style={ { marginRight: 3 } }/>
                        </Tooltip>
                      )}
                      <TextBold>{`${ itemGrowingSeason?.fieldData?.fieldName } - `}{item?.name || t('no name')}</TextBold>
                    </Row>
                    {
                      !isEmpty(itemGrowingSeason) ? (
                        <CropTextGroup>
                          <CropText style={ { marginRight: 3 } }>
                            {itemGrowingSeason?.crop?.description}
                          </CropText>
                          <CropText>
                            {itemGrowingSeason?.sowingYear}
                          </CropText>
                        </CropTextGroup>
                      )
                        :
                        <Text style={ { fontStyle: 'oblique' } }>
                          <I18n>no crop</I18n>
                        </Text>
                    }
                    <Text>{parseFloat(item.area).toFixed(2)} ha {t('of')} {parseFloat(itemGrowingSeason?.fieldData?.area).toFixed(2)} ha</Text>
                  </TextLeft>
                </TextGroup>
              </ContainerField>
            </Card>
          )
        })}
      </>
    ) : (
      <Card
        style={ { marginBottom: 17, marginTop: 0, cursor: 'pointer' } }
        cardStyle={ { padding: '14px', width: '100%', marginBottom: 0 } }
      >
        <ContainerField>
          <PolygonToSvg
            shape={ map(isString(growingSeason?.fieldData?.polygonCoordinates) ? JSON.parse(growingSeason?.fieldData?.polygonCoordinates) : growingSeason?.fieldData?.polygonCoordinates, ([ lat, lng ]) => ({ lat, lng })) }
            color={ GROWING_SEASON_COLOR[growingSeason?.crop?.slug] || GROWING_SEASON_COLOR[growingSeasons[0]?.crop?.slug]  || colors.orange }
            width={ 40 }
            onClick={ () => isPlanned ? onClick({ field: growingSeason?.fieldData, childrenPolygonId: growingSeason?.childrenPolygonId, itemGrowingSeason: growingSeason }) : onClick(growingSeason?.fieldData, null, growingSeason, growingSeasons) }
          />
          <TextGroup onClick={ () => isPlanned ? onClick({ field: growingSeason?.fieldData, childrenPolygonId: growingSeason?.childrenPolygonId, itemGrowingSeason: growingSeason }) : onClick(growingSeason?.fieldData, null, growingSeason, growingSeasons) }>
            <TextLeft>
              <Row>
                {growingSeason?.fieldData?.productivityChallengeParticipation && (
                  <Tooltip title={ t('field participating in RTC productivity contest') }>
                    <Icon icon={ productivityChallange } size={ 14 } style={ { marginRight: 3 } }/>
                  </Tooltip>
                )}
                <TextBold>{growingSeason?.fieldData?.fieldName} {findGrowingSeasonPolygonName()}</TextBold>
              </Row>
              {
                !isEmpty(growingSeasons) || !isEmpty(growingSeason) ? (
                  <CropTextGroup>
                    <CropText style={ { marginRight: 3 } }>
                      {growingSeason?.crop?.description || growingSeasons?.[0]?.crop?.description}
                    </CropText>
                    <CropText>
                      {growingSeason?.sowingYear || growingSeasons?.[0]?.sowingYear}
                    </CropText>
                  </CropTextGroup>
                )
                  :
                  <Text style={ { fontStyle: 'oblique' } }>
                    <I18n>no crop</I18n>
                  </Text>
              }
              <Text>{parseFloat(growingSeason?.fieldData?.area).toFixed(2)} ha</Text>
            </TextLeft>
          </TextGroup>
          {isPlanned && (
            <Button
              id="register-field-plan"
              color="white"
              onClick={ () => {
                createDialog({
                  id: 'confirm-end-growingSeason',
                  Component: ConfirmModal,
                  props: {
                    onConfirm: onStartGrowingSeason,
                    textWarning: t('are you sure you want to start your cultivation?')
                  }
                })
              } }
              style={ { height: '40px' } }
            >
              {t('start')}
            </Button>
          )}
        </ContainerField>
      </Card>
    )
}

GrowingSeasonHistoryItem.propTypes = {
  growingSeason: PropTypes.object,
  growingSeasons: PropTypes.array,
  isPlanned: PropTypes.bool,
  onClick: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  loadFields: PropTypes.func
}

GrowingSeasonHistoryItem.defaultProps = {
  growingSeason: {},
  isPlanned: false,
  growingSeasons: [],
  onClick: () => {},
  loadFields: () => {}
}

export default GrowingSeasonHistoryItem
