import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Card = styled.div`
  padding: 10px;
  border: 1px solid ${ colors.lightGrey };
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: grid;
  grid-template-columns: max-content max-content auto;
  align-items: center;
  column-gap: 15px;
`

export const TextName = styled.span`
  font-family: 'Open Sans';
  font-size: .95rem;
  font-weight: 600;
  color: ${ colors.text };
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
