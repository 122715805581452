import React, { useState } from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import I18n, { useT } from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import Modal from '@smartcoop/web-components/Modal'

import { Container, MainText } from './styles'

const RejectProposalModal = (props) => {
  const {
    open,
    handleClose,
    id,
    onConfirm
  } = props

  const t = useT()

  const [reason, setReason] = useState('')
  const [anotherReason, setAnotherReason] = useState('')

  const options = [
    {
      value: 'Preço',
      label: 'Preço'
    },
    {
      value: 'Fornecedor',
      label: 'Fornecedor'
    },
    {
      value: 'Marca do produto',
      label: 'Marca do produto'
    },
    {
      value: 'Outro',
      label: 'Outro'
    }
  ]

  return (
    <Modal
      id={ id }
      open={ open }
      title={ <I18n>refuse proposal</I18n> }
      maxWidth="sm"
      fullWidth
      disableFullScreen
      FooterComponent={
        () => (
          <>
            <Button
              id="close"
              onClick={ handleClose }
              variant="outlined"
              style={ { marginRight: 7 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="save"
              onClick={ () => {
                onConfirm(reason === 'Outro' ? `Outro | ${ anotherReason }` : reason)
                handleClose()
              } }
              color="black"
              style={ { marginLeft: 7 } }
              disabled={ isEmpty(reason) || (reason === 'Outro' && anotherReason.length < 10) }
            >
              <I18n>confirm</I18n>
            </Button>
          </>
        )
      }
    >
      <Container>
        <MainText>
          <I18n>inform the reason of the refusal</I18n>
        </MainText>

        <InputSelect
          label={ t('reason ') }
          options={ options }
          style={ { paddingBottom: 10, paddingRight: 5 } }
          clearable="true"
          value={ reason }
          detached
          onChange={ setReason }
        />

        {reason === 'Outro' ? (
          <InputText
            detached
            label={ t('reason ') }
            fullWidth
            onChange={ ({ target: { value } }) => setAnotherReason(value) }
            value={ anotherReason }
          />
        ) : null}
      </Container>
    </Modal>
  )
}

RejectProposalModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onConfirm: PropTypes.func
}

RejectProposalModal.defaultProps = {
  onConfirm: () => {}
}

export default RejectProposalModal
