import styled from 'styled-components'

import Divider from '@material-ui/core/Divider'

import colors from '@smartcoop/styles/colors'

export const CompanyName = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: ${ colors.black };

`

export const DividerMui = styled(Divider)`
  margin: 5px 0;
`

export const LeftData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const RightData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${ colors.black };
`

export const BlackSubtitle = styled(Title)`
  font-size: 16px;
`

export const Subtitle = styled.span`
  font-size: 14px;
  color: ${ colors.darkGrey };
  margin-bottom: 5%;
`

export const Text = styled.span`
  font-size: 16px;
  color: ${ colors.black };
`

export const DetailsContainer = styled.div`
  width: 360px;
  min-height: 60vh;
  max-height: 80vh;
`

export const Item = styled.div`
  width: 100%;
`

export const ItemData = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
`

export const TextUpdate = styled.span`
  color: ${ colors.darkGrey }
  font-size: 15px;
  font-weight: 500;
`

export const DataWeatherStation = styled.div`
  display: flex;
  flex-direction: column;

`

export const TextWeatherStation = styled.span`

`
