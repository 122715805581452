import styled from 'styled-components'

import { Title } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

export const TotalRow = styled(Title)`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  align-self: flex-end;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap-reverse;
  font-size: 11;
`
