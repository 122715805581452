import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

const INITIAL_SUPPLIER_QUOTATION = {
  aggregatedVolume: undefined,
  code: undefined,
  deliveryPercentage: undefined,
  id: undefined,
  organizationId: undefined,
  organizationPurchases: [],
  participationDeadline: undefined,
  paymentForms: [],
  product: {},
  productId: undefined,
  proposalDeadline: undefined,
  receiptDeadline: undefined,
  status: {},
  statusId: undefined
}

/* Initial State */
const INITIAL_STATE = {
  currentSupplierQuotation: {},
  currentProposal: {},
  checkboxesState: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadCurrentSupplierQuotation: ['supplierQuotationId', 'onSuccess', 'onError', 'onlyAccepted'],
  loadCurrentSupplierQuotationProposal: [
    'supplierQuotationId',
    'onSuccess',
    'onError'
  ],

  saveOfflineSupplierQuotationProposal: ['proposal', 'onSuccess', 'onError'],
  saveSupplierQuotationProposal: ['proposal', 'onSuccess', 'onError'],

  updateCurrentSupplierQuotation: ['supplierQuotation', 'onSuccess'],
  updateCurrentSupplierQuotationProposal: ['proposal', 'onSuccess'],

  removeOfflineSupplierQuotationProposal: ['onSuccess', 'onError'],
  removeSupplierQuotationProposal: ['onSuccess', 'onError'],

  refuseOfflineSupplierQuotationProposal: ['onSuccess', 'onError'],
  refuseSupplierQuotationProposal: ['onSuccess', 'onError'],

  resetCurrentSupplierQuotation: [],
  resetCurrentSupplierQuotationProposal: [],
  supplierQuotationError: ['error'],

  setInitialCheckboxesState: ['checkboxesState'],
  setCheckboxesState: ['checked', 'index']
})

/**
 * Assigning offline actions
 */
markActionsOffline(Creators, [
  'saveSupplierQuotationProposal',
  'updateCurrentSupplierQuotation',
  'removeSupplierQuotationProposal',
  'refuseSupplierQuotationProposal'
])

const updateCurrentSupplierQuotation = (
  state = INITIAL_STATE,
  { supplierQuotation }
) => ({
  ...state,
  currentSupplierQuotation: {
    ...INITIAL_SUPPLIER_QUOTATION,
    ...supplierQuotation
  }
})

const updateCurrentSupplierQuotationProposal = (
  state = INITIAL_STATE,
  { proposal }
) => ({
  ...state,
  currentProposal: {
    ...proposal
  }
})

const resetCurrentSupplierQuotation = (state = INITIAL_STATE) => ({
  ...state,
  currentSupplierQuotation: { ...INITIAL_STATE.currentSupplierQuotation },
  checkboxesState: INITIAL_STATE.checkboxesState
})

const resetCurrentSupplierQuotationProposal = (state = INITIAL_STATE) => ({
  ...state,
  currentProposal: {}
})

const supplierQuotationError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const setInitialCheckboxesState = (
  state = INITIAL_STATE,
  { checkboxesState }
) => ({ ...state, checkboxesState })

const setCheckboxesState = (state = INITIAL_STATE, { checked, index }) => {
  const array = state.checkboxesState
  array[index] = checked
  return {
    ...state,
    checkboxesState: array
  }
}

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_CURRENT_SUPPLIER_QUOTATION]: updateCurrentSupplierQuotation,
  [Types.UPDATE_CURRENT_SUPPLIER_QUOTATION_PROPOSAL]: updateCurrentSupplierQuotationProposal,

  [Types.RESET_CURRENT_SUPPLIER_QUOTATION]: resetCurrentSupplierQuotation,
  [Types.RESET_CURRENT_SUPPLIER_QUOTATION_PROPOSAL]: resetCurrentSupplierQuotationProposal,

  [Types.SUPPLIER_QUOTATION_ERROR]: supplierQuotationError,

  [Types.SET_INITIAL_CHECKBOXES_STATE]: setInitialCheckboxesState,
  [Types.SET_CHECKBOXES_STATE]: setCheckboxesState,

  [AuthenticationTypes.LOGOUT]: logout
})

export { Types as SupplierQuotationTypes, Creators as SupplierQuotationActions }
