import React from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import PopupMarker from '../PopupMarker/PopupMarker'

const MarkersList = ({ markers }) => {
  if (isEmpty(markers)) {
    return null
  }
  const items = markers.map(({ key, ...props }) => (
    <PopupMarker key={ key } { ...props } />
  ))
  return <div style={ { display: 'none' } }>{items}</div>
}

MarkersList.propTypes = {
  markers: PropTypes.array
}

MarkersList.defaultProps = {
  markers: []
}

export default MarkersList
