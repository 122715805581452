import React from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { arrowUp } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import IconButton from '../IconButton'

const ArrowUpIconButton = ({ iconColor, ...props }) => {
  const t = useT()
  return (
    <IconButton tooltip={ t('retract') } { ...props }>
      <Icon icon={ arrowUp } color={ iconColor } />
    </IconButton>
  )
}

ArrowUpIconButton.propTypes = {
  iconColor: PropTypes.string
}

ArrowUpIconButton.defaultProps = {
  iconColor: colors.mutedText
}

export default ArrowUpIconButton
