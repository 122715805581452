// eslint-disable-next-line spaced-comment
export default ({ size } = {}) => /*html*/`
<svg width="${ size }" height="${ size }" viewBox="0 0 31 41" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M13.9071 40.1728C2.17727 23.3052 0 21.5741 0 15.375C0 6.88359 6.93956 0 15.5 0C24.0604 0 31 6.88359 31 15.375C31 21.5741 28.8227 23.3052 17.0929 40.1728C16.3232 41.2758 14.6767 41.2757 13.9071 40.1728Z"
    fill="#199CD8" />
  <path
    d="M25.8409 12.84C20.3043 7.71906 11.6934 7.72094 6.15904 12.84C5.95091 13.0325 5.94685 13.3584 6.1481 13.5581L7.2181 14.6197C7.40997 14.8103 7.71872 14.8144 7.9181 14.6316C12.4781 10.4541 19.5212 10.4531 24.0822 14.6316C24.2815 14.8144 24.5903 14.81 24.7822 14.6197L25.8522 13.5581C26.0531 13.3584 26.049 13.0325 25.8409 12.84ZM16 19C14.8953 19 14 19.8953 14 21C14 22.1047 14.8953 23 16 23C17.1047 23 18 22.1047 18 21C18 19.8953 17.1047 19 16 19ZM22.3334 16.3878C18.7315 13.2025 13.2643 13.2059 9.66654 16.3878C9.45091 16.5784 9.44404 16.9094 9.64872 17.1112L10.725 18.1734C10.9125 18.3584 11.2143 18.3709 11.414 18.1984C14.0375 15.9306 17.9684 15.9356 20.5856 18.1984C20.7853 18.3709 21.0872 18.3587 21.2747 18.1734L22.3509 17.1112C22.5559 16.9094 22.5487 16.5781 22.3334 16.3878Z"
    fill="white" />
</svg>
`
