import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import AvatarMui from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

import { hexToRgba, colors } from '@smartcoop/styles'

const useStyles = makeStyles({
  colorDefault: {
    backgroundColor: hexToRgba(colors.secondary, 0.8),
    color: colors.primary
  }
})

const Avatar = (props) => {
  const {
    style,
    className,
    children,
    tooltipProps,
    ...otherProps
  } = props

  const classes = useStyles()

  const avatarMui = useMemo(
    () => (
      <AvatarMui classes={ classes } className={ className } style={ style } { ...otherProps }>
        {children}
      </AvatarMui>
    ),
    [children, className, classes, otherProps, style]
  )

  return (
    <>
      {
        !isEmpty(tooltipProps.title) ? (
          <Tooltip { ...tooltipProps }>
            { avatarMui }
          </Tooltip>
        ) :
          avatarMui
      }
    </>
  )
}

Avatar.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.element,
  tooltipProps: PropTypes.object
}

Avatar.defaultProps = {
  style: {},
  className: '',
  children: null,
  tooltipProps: { title: '' }
}

export default Avatar
