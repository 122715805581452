import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerPev = ({ t }) => Yup.object().shape({
  animalId: required({ t })(Yup.string().nullable()),

  date: flow(
    date({ t }),
    required({ t })
  )(Yup.string()),

  weight: required({ t })(Yup.string()),

  height: Yup.string()
})

export default registerPev
