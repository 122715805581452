import * as Yup from 'yup'

const filterSupplierList = () => Yup.object().shape({

  companyDocument: Yup.string(),

  q: Yup.string(),

  tradeName: Yup.string(),

  productName: Yup.string(),

  isActive: Yup.string()

})

export default filterSupplierList
