import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'

import debounce from 'lodash/debounce'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'



import { ThemeProvider } from '@material-ui/core'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { filter, warning } from '@smartcoop/icons'
import { getOrdersBySupplier as getOrdersBySupplierService } from '@smartcoop/services/apis/smartcoopApi/resources/order'
import {
  selectTerms
} from '@smartcoop/stores/authentication/selectorAuthentication'
import { OrderActions } from '@smartcoop/stores/order'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { colors } from '@smartcoop/styles'
import {
  momentFriendlyDateFormat,
  momentBackDateTimeZoneFormat
} from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import InputSearch from '@smartcoop/web-components/InputSearch'
import SupplierQuotationStatus from '@smartcoop/web-components/SupplierQuotationStatus'
import Tooltip from '@smartcoop/web-components/Tooltip'
import useTerm from '@smartcoop/web-containers/hooks/useTerm'
import HelpModal from '@smartcoop/web-containers/modals/HelpModal/HelpModal'
import FilterSupplierModal from '@smartcoop/web-containers/modals/shoppingPlatform/FilterSupplierModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  Container,
  Row,
  Title,
  ButtonContainer,
  SingleItemContainer,
  inputSearchTheme
} from './styles'

const QuotationListScreen = () => {
  const { createDialog } = useDialog()
  const history = useHistory()
  const { routes } = useRoutes()
  const t = useT()
  const dispatch = useDispatch()

  const [filters, setFilters] = useState({})
  const [filterStatus, setFilterStatus] = useState({})
  const [filterText, setFilterText] = useState('')
  const [debouncedFilterText, setDebouncedFilterText] = useState('')
  const [statusDescriptions, setStatusDescriptions] = useState([])

  const currentOrganization = useSelector(selectCurrentOrganization)
  const terms = useSelector(selectTerms)


  const [termModal] = useTerm('supplier-term', false)

  useEffect(() => {
    termModal()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terms])

  useEffect(() => {
    dispatch(OrderActions.loadDemandsStatus(
      (status) => setStatusDescriptions(status?.proposalStatus)
    ))
  }, [dispatch])

  const redirectToQuotationDetail = useCallback(
    (data) => {
      const path = routes.supplierQuotationDetails.path.replace(
        ':supplierQuotationId',
        data.id
      )
      const hasDeniedProposal = find(data?.purchaseProposals, item => item?.status?.slug === 'recusado' && currentOrganization?.id ===  item?.supplierId)

      history.push(path, { order: { ...data }, accepted: !!(data?.status?.slug === 'aguardando_entrega' || (data?.status?.slug === 'finalizado' && !hasDeniedProposal ))  })
    },
    [currentOrganization.id, history, routes.supplierQuotationDetails.path]
  )

  const columns = useMemo(
    () => [
      {
        title: 'Cód. Demanda',
        field: 'code',
        align: 'center',
        headerStyle: { whiteSpace: 'nowrap' },
        sorting: true,
        defaultSort: 'desc'
      },
      {
        title: 'Produtos',
        field: 'product.shortDescription',
        align: 'left',
        cellStyle: { fontWeight: 'bold' },
        render: (row) => `${ row?.product?.productCode } - ${ row?.product?.shortDescription }`
      },
      {
        title: 'Cód. Proposta',
        align: 'center',
        field: 'purchaseProposals.code',
        headerStyle: { whiteSpace: 'nowrap' },
        render: (row) => {
          const { purchaseProposals } = row
          const supplierPurchase = purchaseProposals.filter(
            (item) => item.supplierId === currentOrganization.id
          )
          const { length } = supplierPurchase

          if (length > 0) {
            return supplierPurchase[0].code
          }
          return '-'
        }
      },
      {
        title: 'Limite para Proposta',
        field: 'proposalDeadline',
        align: 'center',
        headerStyle: { whiteSpace: 'nowrap' },
        render: (row) =>
          row.proposalDeadline
            ? moment(
              row.proposalDeadline,
              momentBackDateTimeZoneFormat
            ).format(momentFriendlyDateFormat)
            : '-',
        sorting: true
      },
      {
        title: 'Limite para Entrega',
        field: 'receiptDeadline',
        align: 'center',
        headerStyle: { whiteSpace: 'nowrap' },
        render: (row) =>
          row.receiptDeadline
            ? moment(row.receiptDeadline, momentBackDateTimeZoneFormat).format(
              momentFriendlyDateFormat
            )
            : '-',
        sorting: true
      },
      {
        title: 'Volume agregado',
        field: 'aggregatedVolume',
        align: 'center',
        headerStyle: { whiteSpace: 'nowrap' },
        render: (row) =>
          `${ Number(row.aggregatedVolume).toLocaleString('pt-BR') } ${
            row.product.unitOfMeasures
          }`
      },
      {
        title: 'Situação',
        field: 'status.slug',
        render: (row) => {
          const proposalDate = moment(row.proposalDeadline)
          const hasProposal = row.purchaseProposals.some(
            (value) => value.supplierId === currentOrganization.id
          )
          let proposal = null
          let slug

          if (hasProposal) {
            proposal = row.purchaseProposals.find(
              (element) => element.supplierId === currentOrganization.id
            )
          }

          if (proposalDate.isBefore(moment()) && !hasProposal) {
            slug = 'expirado'
          } else if (hasProposal && row?.status?.slug !== 'finalizado' && (row?.status?.slug !== 'aguardando_entrega' || proposal?.status?.slug === 'recusado')) {
            if (row?.status?.slug === 'proposta_aceita') {
              slug = 'aguardando_entrega'
            } else {
              slug = proposal?.status?.slug
            }
          } else if(row?.status?.slug === 'aguardando_resposta' && !hasProposal) {
            slug = 'aguardando_proposta'
          } else if (row?.status?.slug === 'finalizado' && proposal?.status?.slug === 'recusado'){
            slug = 'recusado'
          } else {
            slug = row?.status?.slug
          }
          return (
            <div style={ { display: 'flex', alignItems: 'center' } }>
              <SupplierQuotationStatus
                style={ { padding: '6px 10px' } }
                slug={ slug }
              />
              {(row?.status?.slug === 'aguardando_entrega' && proposal?.status?.slug !== 'recusado' && moment().isAfter(moment(row?.receiptDeadline))) && (
                <Tooltip
                  title={ t('late delivery') }
                >
                  <Icon
                    icon={ warning }
                    color={ colors.black }
                    size={ 20 }
                    style={ {
                      marginLeft: 5
                    } }
                  />
                </Tooltip>
              )}
            </div>
          )
        }
      }
    ],
    [currentOrganization.id, t]
  )

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )

  const handleFilter = useCallback((values) => setFilters(values), [])

  const filterData = useCallback(() => {
    createDialog({
      id: 'filter-supplier',
      Component: FilterSupplierModal,
      props: {
        onSubmit: handleFilter,
        filters
      }
    })
  }, [createDialog, filters, handleFilter])

  const onRowDisabled = useCallback(
    (row) => {
      const proposalDate = moment(row.proposalDeadline)
      const hasProposal = row.purchaseProposals.some(
        (value) => value.supplierId === currentOrganization.id
      )
      let slug

      const proposal = row.purchaseProposals.find(
        (element) => element.supplierId === currentOrganization.id
      )

      if ((proposalDate.isBefore(moment(), 'second') && !hasProposal) || (proposal?.status?.slug === 'rejeitado')) {
        slug = 'expirado'
      } else {
        slug = row?.status?.slug
      }

      return slug === 'expirado'
    },
    [currentOrganization.id]
  )

  const onRowClick = useCallback(
    (event, data) => {
      if (!onRowDisabled(data)) {
        redirectToQuotationDetail(data)
      }
    },
    [onRowDisabled, redirectToQuotationDetail]
  )

  const inactiveSupplierFilter = useMemo(
    () =>
      currentOrganization?.inactivatedAt
        ? { maxDate: moment(currentOrganization.inactivatedAt).format('YYYY-MM-DD') }
        : {},
    [currentOrganization]
  )

  const tableFilters = useMemo(
    () =>
      debouncedFilterText || filterStatus
        ? { ...filters, ...inactiveSupplierFilter, ...filterStatus, q: debouncedFilterText }
        : { ...filters, ...inactiveSupplierFilter },
    [debouncedFilterText, filterStatus, filters, inactiveSupplierFilter]
  )

  const urlParams = useMemo(() => ({ supplierId: currentOrganization.id }), [
    currentOrganization.id
  ])

  return (
    <Container>
      <Title>
        <I18n>purchase quotes</I18n>
      </Title>
      <Row>
        <ButtonContainer>
          <Button
            id="all-quotes"
            onClick={ () => setFilterStatus({}) }
            color={
              filterStatus.statusId !== '1' && filterStatus.statusId !== '4'
                ? colors.black
                : colors.white
            }
            style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 10 } }
          >
            <I18n params={ { gender: 'female' } }>all</I18n>
          </Button>

          <Button
            id="open"
            onClick={ () =>
              setFilterStatus({
                statusId: '4',
                minProposalDate: moment().format('YYYY-MM-DD')
              })
            }
            color={ filterStatus.statusId === '4' ? colors.black : colors.white }
            style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 10 } }
          >
            <I18n params={ { gender: 'female' } }>open</I18n>
          </Button>

          <Button
            id="closed"
            onClick={ () => setFilterStatus({ statusId: '2' }) }
            color={ filterStatus.statusId === '2' ? colors.black : colors.white }
            style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 10 } }
          >
            <I18n params={ { gender: 'female' } }>closed</I18n>
          </Button>
        </ButtonContainer>

        <SingleItemContainer>
          <Button
            id="open-help-modal"
            onClick={ () => createDialog({
              id: 'help',
              Component: HelpModal,
              props: {
                statusDescriptions,
                isSupplier: true
              }
            }) }
            style={ { width: 'auto', marginRight: 10 } }
            color={ colors.white }
          >
            <I18n>help</I18n>
          </Button>
          <ThemeProvider theme={ inputSearchTheme }>
            <InputSearch
              detached
              onChange={ onChangeSearchFilter }
              value={ filterText }
              placeholder={ t('search') }
              fullWidth
              style={ { marginRight: 12 } }
            />
          </ThemeProvider>
          <Button
            id="filter"
            onClick={ filterData }
            color={ colors.white }
            style={ { flex: 'none', whiteSpace: 'nowrap' } }
          >
            <>
              <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 } />
              <I18n>filtrate</I18n>
            </>
          </Button>
        </SingleItemContainer>
      </Row>

      {!isEmpty(currentOrganization) && (
        <DataTable
          onRowClick={ onRowClick }
          data={ getOrdersBySupplierService }
          urlParams={ urlParams }
          queryParams={ tableFilters }
          columns={ columns }
          disabledRow={ onRowDisabled }
          id="supplier-quotation-list-table"
        />
      )}
    </Container>
  )
}

export default QuotationListScreen
