import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import InputFloat from '@smartcoop/web-components/InputFloat'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'

const DynamicInput = (props) => {
  const {
    type,
    unit,
    ...rest
  } = props

  const InputType = useMemo(
    () => {
      switch (type) {
        case 'float':
          return InputFloat
        case 'integer':
          return InputNumber
        case 'number':
          return InputNumber
        default:
          return InputText
      }
    },
    [type]
  )

  return (
    <>
      { !unit ? (
        <InputType { ...rest } />
      ) : (
        <InputUnit unit={ unit } type={ type } { ...rest }/>
      ) }
    </>
  )
}

DynamicInput.propTypes = {
  type: PropTypes.string.isRequired,
  unit: PropTypes.string
}

DynamicInput.defaultProps = {
  unit: null
}

export default DynamicInput
