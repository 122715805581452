import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'


/* Initial State */
const INITIAL_STATE = {
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
export const { Types, Creators } = createActions({
  saveCosts: ['params', 'onSuccess', 'onError'],
  saveOfflineCosts: ['params', 'onSuccess', 'onError'],
  costsError: ['error'],
  deleteCosts: ['costId', 'onSuccess', 'onError']
})

markActionsOffline(Creators, ['saveCosts'])

/**
 * Reducers functions
 */

const costsError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

const resetAuthentication = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.COSTS_ERROR]: costsError,

  [AuthenticationTypes.FIREBASE_LOGOUT_SUCCESS]: logout,
  [AuthenticationTypes.RESET_AUTHENTICATION]: resetAuthentication
})

export {
  Types as CostsTypes,
  Creators as CostsActions
}
