import { createActions, createReducer } from 'reduxsauce'

import filter from 'lodash/filter'
import map from 'lodash/map'

import { AuthenticationTypes } from '../../../authentication'

// Initial state
const INITIAL_STATE = {
  animals:[],
  bulls: [],
  breeds: [],
  status: [],
  inseminationTypes: [],
  diagnosticTypes: [],
  unavailaleEarrings: [],
  lots: [],
  milkControls: [],
  slaughterReasons: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadData: ['onFinish'],

  updateOfflineAnimal: ['animal'],
  loadAllAnimals: ['onFinish'],
  loadAllAnimalsSuccess: ['animals'],
  loadUnavailableEarrings: ['onFinish'],
  loadUnavailableEarringsSuccess: ['unavailaleEarrings'],
  loadAllBulls: ['onFinish'],
  loadAllBullsSuccess: ['bulls'],
  loadAllBreeds: ['onFinish'],
  loadAllBreedsSuccess: ['breeds'],
  loadAllStatus: ['onFinish'],
  loadAllStatusSuccess: ['status'],
  loadAllInseminationTypes: ['onFinish'],
  loadAllInseminationTypesSuccess: ['inseminationTypes'],
  loadAllDiagnosticTypes: ['onFinish'],
  loadAllDiagnosticTypesSuccess: ['diagnosticTypes'],
  loadAllLots: ['onFinish'],
  loadAllLotsSuccess: ['lots'],
  loadAllMilkControl: ['onFinish'],
  loadAllMilkControlSuccess: ['milkControls'],
  loadAllSlaughterReasons: ['onFinish'],
  loadAllSlaughterReasonsSuccess: ['slaughterReasons'],
  removeOfflineAnimal: ['animalId'],
  removeOfflineMilkControl: ['milkControlId'],
  removeOfflineInsemination: ['inseminationId'],
  removeOfflineDrying: ['dryingId'],
  removeOfflineProtocol: ['protocolId'],
  removeOfflineDiagnosis: ['diagnosisId'],
  removeOfflinePev: ['pevId'],
  removeOfflineBirth: ['birthId'],
  offlineAnimalUserDataError: ['error']
}, { prefix: 'OFFLINE_ANIMAL_USER_DATA' })

/**
 * Reducers functions
 */
const loadAllAnimalsSuccess = (state = INITIAL_STATE, { animals }) => ({
  ...state,
  error: INITIAL_STATE.error,
  animals
})

const loadAllLotsSuccess = (state = INITIAL_STATE, { lots }) => ({
  ...state,
  error: INITIAL_STATE.error,
  lots
})

const loadAllSlaughterReasonsSuccess = (state = INITIAL_STATE, { slaughterReasons }) => ({
  ...state,
  error: INITIAL_STATE.error,
  slaughterReasons
})

const loadUnavailableEarringsSuccess = (state = INITIAL_STATE, { unavailaleEarrings }) => ({
  ...state,
  error: INITIAL_STATE.error,
  unavailaleEarrings
})

const loadAllBullsSuccess = (state = INITIAL_STATE, { bulls }) => ({
  ...state,
  error: INITIAL_STATE.error,
  bulls
})

const loadAllMilkControlSuccess = (state = INITIAL_STATE, { milkControls }) => ({
  ...state,
  error: INITIAL_STATE.error,
  milkControls
})

const loadAllBreedsSuccess = (state = INITIAL_STATE, { breeds }) => ({
  ...state,
  error: INITIAL_STATE.error,
  breeds
})

const loadAllStatusSuccess = (state = INITIAL_STATE, { status }) => ({
  ...state,
  error: INITIAL_STATE.error,
  status
})

const loadAllInseminationTypesSuccess = (state = INITIAL_STATE, { inseminationTypes }) => ({
  ...state,
  error: INITIAL_STATE.error,
  inseminationTypes
})

const loadAllDiagnosticTypesSuccess = (state = INITIAL_STATE, { diagnosticTypes }) => ({
  ...state,
  error: INITIAL_STATE.error,
  diagnosticTypes
})

const offlineAnimalUserDataError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const updateOfflineAnimal = (state = INITIAL_STATE, { animal }) => ({
  ...state,
  animals: [animal, ...filter(state.animals, (item) => item.id !== animal.id)]
})

const removeOfflineAnimal = (state = INITIAL_STATE, { animalId }) => ({
  ...state,
  animals: filter(state.animals, (item) => item.id !== animalId)
})

const removeOfflineMilkControl = (state = INITIAL_STATE, { milkControlId }) => ({
  ...state,
  milkControls: filter(state.milkControls, (item) => item.id !== milkControlId)
})

const removeOfflineInsemination = (state = INITIAL_STATE, { inseminationId }) => ({
  ...state,
  animals: map(state.animals, (item) => filter(item.inseminations, insemination => insemination.id !== inseminationId))
})

const removeOfflineDrying = (state = INITIAL_STATE, { dryingId }) => ({
  ...state,
  animals: map(state.animals, (item) => filter(item.drying, drying => drying.id !== dryingId))
})

const removeOfflineProtocol = (state = INITIAL_STATE, { protocolId }) => ({
  ...state,
  animals: map(state.animals, (item) => filter(item.protocols, protocol => protocol.id !== protocolId))
})

const removeOfflineDiagnosis = (state = INITIAL_STATE, { diagnosisId }) => ({
  ...state,
  animals: map(state.animals, (item) => filter(item.diagnosis, diagnosis => diagnosis.id !== diagnosisId))
})

const removeOfflinePev = (state = INITIAL_STATE, { pevId }) => ({
  ...state,
  animals: map(state.animals, (item) => filter(item.voluntaryWaitingPeriod, pev => pev.id !== pevId))
})

const removeOfflineBirth = (state = INITIAL_STATE, { birthId }) => ({
  ...state,
  animals: map(state.animals, (item) => filter(item.births, birth => birth.id !== birthId))
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */

export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_OFFLINE_ANIMAL]: updateOfflineAnimal,
  [Types.REMOVE_OFFLINE_ANIMAL]: removeOfflineAnimal,
  [Types.REMOVE_OFFLINE_MILK_CONTROL]: removeOfflineMilkControl,
  [Types.REMOVE_OFFLINE_INSEMINATION]: removeOfflineInsemination,
  [Types.REMOVE_OFFLINE_DRYING]: removeOfflineDrying,
  [Types.REMOVE_OFFLINE_PROTOCOL]: removeOfflineProtocol,
  [Types.REMOVE_OFFLINE_DIAGNOSIS]: removeOfflineDiagnosis,
  [Types.REMOVE_OFFLINE_PEV]: removeOfflinePev,
  [Types.REMOVE_OFFLINE_BIRTH]: removeOfflineBirth,
  [Types.LOAD_ALL_ANIMALS_SUCCESS]: loadAllAnimalsSuccess,
  [Types.LOAD_ALL_LOTS_SUCCESS]: loadAllLotsSuccess,
  [Types.LOAD_ALL_SLAUGHTER_REASONS_SUCCESS]: loadAllSlaughterReasonsSuccess,
  [Types.LOAD_UNAVAILABLE_EARRINGS_SUCCESS]: loadUnavailableEarringsSuccess,
  [Types.LOAD_ALL_BULLS_SUCCESS]: loadAllBullsSuccess,
  [Types.LOAD_ALL_MILK_CONTROL_SUCCESS]: loadAllMilkControlSuccess,
  [Types.LOAD_ALL_BREEDS_SUCCESS]: loadAllBreedsSuccess,
  [Types.LOAD_ALL_STATUS_SUCCESS]: loadAllStatusSuccess,
  [Types.LOAD_ALL_INSEMINATION_TYPES_SUCCESS]: loadAllInseminationTypesSuccess,
  [Types.LOAD_ALL_DIAGNOSTIC_TYPES_SUCCESS]: loadAllDiagnosticTypesSuccess,

  [Types.OFFLINE_ANIMAL_USER_DATA_ERROR]: offlineAnimalUserDataError,
  [AuthenticationTypes.LOGOUT]: logout
})

export { Types as OfflineAnimalUserDataTypes, Creators as OfflineAnimalUserDataActions }
