import React, { useCallback, forwardRef, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import ceil from 'lodash/ceil'
import isEmpty from 'lodash/isEmpty'
import toNumber from 'lodash/toNumber'

import Grid from '@material-ui/core/Grid'

import { reloadSchema } from '@smartcoop/forms'
import createFutureSaleOrderSchema from '@smartcoop/forms/schemas/commercialization/createFutureSaleOrder.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getPropertiesWithData } from '@smartcoop/services/apis/smartcoopApi/resources/property'
import { getProductBatchByOrg } from '@smartcoop/services/apis/smartcoopApi/resources/salesOrders'
import { getUserStateRegistrations as getUserStateRegistrationsService } from '@smartcoop/services/apis/smartcoopApi/resources/stateRegistration'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { formatCurrency, formatNumber } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'

import { Container, ButtonContainer } from './styles'

const FutureSale = forwardRef((props, formRef) => {
  const { withoutSubmitButton, onSubmit, loading } = props

  const t = useT()

  const currentOrganization = useSelector(selectCurrentOrganization)

  const [productUnit, setProductUnit] = useState('kg')
  const [quantityUnit, setQuantityUnit] = useState('kg')

  const urlParams = useMemo(
    () => ({ organizationId: currentOrganization.id }),
    [currentOrganization.id]
  )

  const getQuotationUnit = useCallback((asyncItem) => {
    // eslint-disable-next-line eqeqeq
    const isConversionFactorEmpty = !asyncItem.organizationProduct.conversionFactor || asyncItem.organizationProduct.conversionFactor == 0
    return isConversionFactorEmpty
      ? asyncItem.organizationProduct.measureUnit
      : `${
        t('{this} of', { this: asyncItem.organizationProduct.unitOfMeasuresForConversion })
      } ${
        formatNumber(ceil(Number(asyncItem.organizationProduct.conversionFactor), 2))
      } ${
        asyncItem.organizationProduct.measureUnit
      }`
  }, [t])

  const getQuantityInSacks = useCallback((asyncItem) => {
    // eslint-disable-next-line eqeqeq
    const isConversionFactorEmpty = !asyncItem.organizationProduct.conversionFactor || asyncItem.organizationProduct.conversionFactor == 0
    return isConversionFactorEmpty
      ? asyncItem.volume
      :  Math.floor(Number(asyncItem.volume)/Number(asyncItem.organizationProduct.conversionFactor))
  }, [])

  const onProductBatchChange = useCallback(
    (_, asyncItem) => {
      if (!isEmpty(asyncItem)) {
        setQuantityUnit(getQuotationUnit(asyncItem))
        setProductUnit(asyncItem.organizationProduct.measureUnit)

        formRef.current.getFieldRef('conversionFactor').setValue(asyncItem.organizationProduct?.conversionFactor || 0)

        formRef.current.getFieldRef('exibitionBalance').setValue(`${ formatNumber(getQuantityInSacks(asyncItem)) } ${ getQuotationUnit(asyncItem) }`)
        formRef.current.getFieldRef('productBalance').setValue(asyncItem.volume.toString())
        formRef.current.getFieldRef('exibitionBalanceInUnit').setValue(`${ formatNumber(asyncItem.volume) } ${ asyncItem.organizationProduct.measureUnit }`)

        formRef.current.getFieldRef('settlementDate').setValue(asyncItem.settlementDate)
        formRef.current.getFieldRef('quotationExpirationDate').setValue(asyncItem.expirationDate)

        formRef.current.getFieldRef('batchQuotation').setValue(asyncItem.quotation.toString())
        formRef.current.getFieldRef('exibitionQuotation').setValue(`${ formatCurrency(asyncItem.quotation * asyncItem.organizationProduct?.conversionFactor) }/${ getQuotationUnit(asyncItem) }`)
        formRef.current.getFieldRef('totalValue').setValue(
          (toNumber(formRef.current.getFieldRef('quantity').value) * toNumber(asyncItem.quotation ) * toNumber(asyncItem.organizationProduct?.conversionFactor)).toString()
        )
        formRef.current.getFieldRef('totalValueExibition').setValue(
          formatCurrency(toNumber(formRef.current.getFieldRef('quantity').value) * toNumber(asyncItem.quotation) * toNumber(asyncItem.organizationProduct?.conversionFactor))
        )

        reloadSchema(formRef, true)
      }
    },
    [formRef, getQuantityInSacks, getQuotationUnit]
  )

  const onQuantityChange = useCallback(({ target: { value } }) => {
    formRef.current.getFieldRef('totalValue').setValue(
      (
        toNumber(value) *
        toNumber(formRef.current.getFieldRef('batchQuotation').value) *
        toNumber(formRef.current.getFieldRef('conversionFactor').value)
      ).toString()
    )
    formRef.current.getFieldRef('totalValueExibition').setValue(
      formatCurrency(
        toNumber(value) *
        toNumber(formRef.current.getFieldRef('batchQuotation').value) *
        toNumber(formRef.current.getFieldRef('conversionFactor').value)
      )
    )
  }, [formRef])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ createFutureSaleOrderSchema }
        onSubmit={ (data) => onSubmit(data) }
      >
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 } md={ 6 }>
            <InputSelect
              placeholder={ t('list of available lots') }
              name="productBatchId"
              options={ getProductBatchByOrg }
              urlParams={ urlParams }
              queryParams={ { expired: false } }
              asyncOptionLabelField="description"
              asyncOptionValueField="id"
              onFieldValueChange={ onProductBatchChange }
              disabled={ loading }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputText
              name="exibitionQuotation"
              label={ t('quotation', { howMany: 1 }) }
              fullWidth
              disabled
            />
            <InputText
              style={ { display: 'none' } }
              name="batchQuotation"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputUnit
              name="quantity"
              placeholder={ t('quantity') }
              type="integer"
              unit={ quantityUnit }
              onChange={ onQuantityChange }
              fullWidth
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputText
              name="exibitionBalance"
              label={ t('balance') }
              fullWidth
              disabled
            />
            <InputText
              style={ { display: 'none' } }
              name="productBalance"
              fullWidth
              disabled
            />
            <InputText
              style={ { display: 'none' } }
              name="quotationExpirationDate"
              fullWidth
              disabled={ loading }
            />
            <InputText
              style={ { display: 'none' } }
              name="conversionFactor"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputSelect
              placeholder={ t('property', { howMany: 1 }) }
              name="propertyId"
              options={ getPropertiesWithData }
              asyncOptionLabelField="name"
              disabled={ loading }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputText
              name="exibitionBalanceInUnit"
              label={ t('{this} in {that}', { this: t('balance'), that: productUnit }) }
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputText
              name="settlementDate"
              label={ t('settlement date') }
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputText
              style={ { display: 'none' } }
              name="totalValue"
              fullWidth
              disabled
            />
            <InputText
              name="totalValueExibition"
              label={ t('total value') }
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputSelect
              name="stateRegistrationId"
              placeholder={ t('state registration') }
              urlParams={ urlParams }
              options={ getUserStateRegistrationsService }
              asyncOptionLabelField="stateRegistration"
              asyncOptionValueField="id"
            />
          </Grid>
        </Grid>
        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-in-cash-save-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FutureSale.propTypes = {
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  loading: PropTypes.bool
}

FutureSale.defaultProps = {
  onSubmit: () => {},
  withoutSubmitButton: false,
  loading: false
}

export default FutureSale
