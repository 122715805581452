import React, { useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import Divider from '@material-ui/core/Divider'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { fileText, shoppingCart } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import SupplierQuotationStatus from '@smartcoop/web-components/SupplierQuotationStatus'
import ProductDetailsModal from '@smartcoop/web-containers/modals/ProductDetailsModal'

import {
  OrderContainer,
  OrderSubTitleItem,
  OrderItem,
  PaymentItem,
  OrderItemText,
  CardStyled,
  ItemStatus,
  ContainerDetails
} from './styles'

const SupplierQuotationDetailsFragment = ({ currentDemand, currentOrganization, currentProposal }) => {
  const t = useT()
  const { createDialog } = useDialog()

  const productUnit = useMemo(() => currentDemand.unitOfMeasures || currentDemand.product.unitOfMeasures || 'kg', [currentDemand.product.unitOfMeasures, currentDemand.unitOfMeasures])

  const orderData = useMemo(
    () => [
      {
        subtitle: 'product',
        value: `${ currentDemand?.product?.productCode } - ${ currentDemand?.product?.description }` || ''
      },
      {
        subtitle: 'aggregated volume',
        value:
          `${ Number(currentDemand.aggregatedVolume).toLocaleString(
            'pt-BR'
          ) } ${ productUnit }` || ''
      }
    ],
    [currentDemand, productUnit]
  )

  const paymentMethods = useMemo(
    () =>
      map(
        currentDemand.paymentForms,
        ({ deferredPayment, daysAmount }, index) => ({
          value: `${ index + 1 }. ${
            !deferredPayment
              ? t('in cash')
              : `${ t('deferred') } ${ daysAmount } ${ t('day', { howMany: 2 }) }`
          }`
        })
      ),
    [currentDemand.paymentForms, t]
  )

  const proposalStatus = useMemo( () => {
    const actualPurchase = currentDemand.purchaseProposals.find((item) => item.supplierId === currentOrganization.id)

    return actualPurchase ? actualPurchase.status : { slug:'aguardando_proposta', statusName:'Aguardando proposta' }
  }, [currentDemand.purchaseProposals, currentOrganization])

  const ShowProductDetails = useCallback(() => {
    createDialog({
      id: 'product-details',
      Component: ProductDetailsModal,
      props: {
        product: currentDemand.product,
        t
      }
    })
  }, [createDialog, currentDemand.product, t])

  return (
    <CardStyled
      headerLeft={ <Icon icon={ shoppingCart } size={ 24 } /> }
      title={ t('purchase quotes') }
    >
      <ContainerDetails>
        <Divider />
        <OrderContainer>
          <OrderItem key="order code">
            <OrderSubTitleItem>{t('order code')}</OrderSubTitleItem>
            <OrderItemText>{ currentDemand?.code }</OrderItemText>
          </OrderItem>
          {currentProposal?.code && (
            <OrderItem key="proposal code">
              <OrderSubTitleItem>{t('proposal code')}</OrderSubTitleItem>
              <OrderItemText>{ currentProposal?.code }</OrderItemText>
            </OrderItem>
          )}
        </OrderContainer>
        <OrderContainer>
          {map(orderData, ({ subtitle, value }) => (
            <OrderItem key={ subtitle }>
              <OrderSubTitleItem>{t(subtitle)}</OrderSubTitleItem>
              <OrderItemText>{value}</OrderItemText>
            </OrderItem>
          ))}

          <ItemStatus>
            <OrderSubTitleItem>Situação da Proposta</OrderSubTitleItem>
            {proposalStatus && (
              <SupplierQuotationStatus
                style={ { padding: '10px 15px', fontSize: 14 } }
                slug={ proposalStatus.slug }
              />
            )}
          </ItemStatus>

        </OrderContainer>
        <Button
          id="show-product-details"
          onClick={ ShowProductDetails }
          style={ { marginLeft: '15px', marginRight: '5px', marginBottom: '5px', width: '33vw', fontWeight: 600 } }
          color={ colors.white }
        >
          <Icon size={ 16 } icon={ fileText } style={ { color: colors.black, marginRight: '5px' } } />
          <I18n>product details</I18n>
        </Button>
        <Divider />
        <OrderContainer>
          <OrderItem>
            <I18n as={ OrderSubTitleItem }>payment methods</I18n>
            <PaymentItem>
              {map(paymentMethods, ({ value }) => (
                <OrderItemText key={ value }>{value}</OrderItemText>
              ))}
            </PaymentItem>
          </OrderItem>
          <OrderItem>
            <OrderSubTitleItem>
              <I18n>freight type</I18n>
            </OrderSubTitleItem>
            <OrderItemText>{currentDemand?.freightType}</OrderItemText>
          </OrderItem>
        </OrderContainer>
      </ContainerDetails>
    </CardStyled>
  )
}

SupplierQuotationDetailsFragment.propTypes = {
  currentDemand: PropTypes.object.isRequired,
  currentProposal: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired
}

export default SupplierQuotationDetailsFragment
