import React, { useState, useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { toBeAcceptedAccessDocument, acceptedAccessDocument } from '@smartcoop/utils/documents'
import { formatCpfCnpj } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'

import AcceptTermModalConfirm from '../AcceptTermModalConfirm/AcceptTermModalConfirm'
import {
  Container,
  Content,
  Buttons,
  Title,
  Term,
  Item,
  AcceptCheckbox,
  TermContainer
} from './styles'

const ProducerRegisterTermModal = (props) => {
  const {
    id,
    open,
    handleClose,
    termData,
    onCancel,
    setTermAccepted,
    view,
    orgs
  } = props

  const snackbar = useSnackbar()
  const t = useT()
  const { createDialog } = useDialog()

  const [acceptedData, setAcceptedData] = useState(false)
  const [acceptedPhone, setAcceptedPhone] = useState(false)

  const currentOrgs = useMemo(() => {

    const filteredOrgs = filter(orgs, item => includes(termData?.organizations, item?.id))

    return {
      names: map(filteredOrgs, ({ tradeName }) => tradeName).join(', '),
      documents: map(filteredOrgs, ({ companyDocument }) => formatCpfCnpj(companyDocument)).join(', ')
    }
  },[orgs, termData.organizations])
  const onClose = useCallback(
    () => {
      handleClose()
    }, [handleClose]
  )

  const onSuccess = useCallback(
    () => {
      snackbar.success(t('the term was accepted succesfully'))
      setTermAccepted(true)
      onClose()
    }, [onClose, setTermAccepted, snackbar, t]
  )

  const openConfirmModal = useCallback(
    () => {
      createDialog({
        id: 'confirm-term-modal',
        Component: AcceptTermModalConfirm,
        props: {
          onCancel
        }

      })
    }, [createDialog, onCancel]
  )

  const cancel = useCallback(
    () => {
      openConfirmModal()
    }, [openConfirmModal]
  )

  const onSubmit = useCallback(
    () => {
      onSuccess()
    }, [onSuccess]
  )

  const document = useMemo(() => view ? acceptedAccessDocument : toBeAcceptedAccessDocument,[view])
  return (
    <Modal
      id={ id }
      open={ open }
      escape={ false }
      maxWidth="xl"
      fullScreen
      disableEsc
      title="Termo de Adesão"
      disableFullScreen
      style={ { zIndex: 99999 } }
      FooterComponent={ () => view ? (
        <Button
          id="close-button"
          onClick={ onSuccess }
        >
          <I18n>close</I18n>
        </Button>
      ) : (
        <Content>
          <AcceptCheckbox
            label="CONSINTO com o compartilhamento dos meus dados pessoais com a SmartCoop."
            value={ acceptedData }
            checked={ acceptedData }
            onChange={ (value) => {setAcceptedData(!value)} }
          />
          <AcceptCheckbox
            label="CONSINTO com o armazenamento do meu número de telefone em North Virginia."
            value={ acceptedPhone }
            checked={ acceptedPhone }
            onChange={ (value) => {setAcceptedPhone(!value)} }
          />
          <Buttons>
            <Button
              id="cancel-button"
              onClick={ cancel }
              variant="outlined"
              style={ { marginRight: 7 } }
            >
              <I18n>cancel</I18n>
            </Button>

            <Button
              id="confirm-button"
              onClick={ onSubmit }
              disabled={ !acceptedPhone || !acceptedData }
              style={ { marginLeft: 7 } }
            >
              <I18n>confirm</I18n>
            </Button>
          </Buttons>
        </Content>
      ) }
    >
      <Container>
        <TermContainer>
          <Item>
            <I18n as={ Title }>
              please read the terms below with attention
            </I18n>
          </Item>
          <Term
            dangerouslySetInnerHTML={
              {
                __html: (
                  document
                    .replace('{companyName}', currentOrgs?.names)
                    .replace('{companyDocument}', currentOrgs?.documents)
                )
              }
            }
          />
        </TermContainer>
      </Container>
    </Modal>
  )
}

ProducerRegisterTermModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  view: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  setTermAccepted: PropTypes.func,
  onCancel: PropTypes.func,
  orgs: PropTypes.array,
  termData: PropTypes.object.isRequired
}

ProducerRegisterTermModal.defaultProps = {
  view: false,
  orgs: [],
  setTermAccepted: () => {},
  onCancel: () => {}
}

export default ProducerRegisterTermModal
