import React from 'react'

import PropTypes from 'prop-types'

import TooltipMui from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

const Tooltip = ({ children, enterDelay, ...otherProps }) => (
  <TooltipMui
    enterDelay={ enterDelay }
    TransitionComponent={ Zoom }
    { ...otherProps }
  >
    {children && (
      <div>
        {children}
      </div>
    )}
  </TooltipMui>
)

Tooltip.propTypes = {
  /** Delay in ms to tooltip show */
  enterDelay: PropTypes.number,
  children: PropTypes.any
}

Tooltip.defaultProps = {
  enterDelay: 500,
  children: null
}

export default Tooltip
