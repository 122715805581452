import React, { memo } from 'react'

import DatabaseProvider from '@nozbe/watermelondb/DatabaseProvider'

import { database } from '@smartcoop/database/web-database'
import { DialogProvider } from '@smartcoop/dialog'
import { I18nProvider, dictionaries } from '@smartcoop/i18n'
import { StoreProvider } from '@smartcoop/stores'
import PaginationProvider from '@smartcoop/web-app/src/providers/PaginationProvider'
import { SnackbarsProvider } from '@smartcoop/web-components/Snackbar'
import withGlobalStyles from '@smartcoop/web-components/styles/hoc/withGlobalStyles'

import ScreenRouter from '~/screens/ScreenRouter'
import store from '~/store'

import 'moment/locale/pt-br'

import './styles.css'

// eslint-disable-next-line react/prop-types
const I18nMemorized = memo(({ children }) => (
  <I18nProvider language="pt-BR" dictionaries={ dictionaries }>
    {children}
  </I18nProvider>
))

const ScreenRouterMemorized = memo(() => <ScreenRouter/>)

const App = () =>
  (
    <DatabaseProvider database={ database }>
      <StoreProvider store={ store }>
        <I18nMemorized>
          <SnackbarsProvider>
            <PaginationProvider >
              <DialogProvider>
                <ScreenRouterMemorized />
              </DialogProvider>
            </PaginationProvider>
          </SnackbarsProvider>
        </I18nMemorized>
      </StoreProvider>
    </DatabaseProvider>
  )


export default withGlobalStyles(App)
