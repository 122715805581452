import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 15px;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`

const useStyles = makeStyles(() => ({
  mapImage: {
    imageRendering: 'pixelated',
    msInterpolationMode: 'nearest-neighborpixelated'
  },
  badge: {
    fontSize: 10,
    width: 10,
    maxWidth: 10
  }
}))

export default useStyles