import React, { useCallback, forwardRef } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import recoverPassword from '@smartcoop/forms/schemas/auth/recoverPassword.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputCpfCnpj from '@smartcoop/web-components/InputCpfCnpj'
import ErrorModal from '@smartcoop/web-containers/modals/ErrorModal'
import LoadingModal from '@smartcoop/web-containers/modals/LoadingModal'

import { Container, ButtonContainer } from './styles'

const RecoverPasswordForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, onSubmit } = props

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        formRef.current.submit()
      }
    },
    [formRef]
  )

  const dispatch = useCallback(useDispatch(), [])
  const { createDialog, removeDialog } = useDialog()

  const t = useT()

  const handleSubmit = useCallback(
    async (data) => {
      createDialog({
        id: 'loading',
        Component: LoadingModal
      })

      const removeLoader = () => {
        setTimeout(() => removeDialog({ id: 'loading' }), 150)
      }

      dispatch(AuthenticationActions.recoverPassword(
        data.document,
        () => {
          removeLoader()
          onSubmit()
        },
        (error) => {
          createDialog({
            id: 'user-not-found',
            Component: ErrorModal,
            props: {
              message: t(error)
            }
          })
          removeLoader()
        }
      ))
    },
    [createDialog, dispatch, onSubmit, removeDialog, t]
  )

  return (
    <Container>
      <Form
        ref={ formRef }
        schemaConstructor={ recoverPassword }
        onSubmit={ handleSubmit }
      >
        <InputCpfCnpj
          name="document"
          label="CPF/CNPJ"
          fullWidth
          onKeyDown={ handleKeyDown }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="recover-password-submit"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
            >
              <I18n>next</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

RecoverPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

RecoverPasswordForm.defaultProps = {
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default RecoverPasswordForm
