import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { call, put, select, takeLatest } from 'redux-saga/effects'

import moment from 'moment/moment'
import { v4 as uuidv4 } from 'uuid'

import { getPropertiesByOwner } from '@smartcoop/services/apis/smartcoopApi/resources/property'
import {
  getTechnicalVisits,
  getTechniciansRequestList,
  requestAccessForProprietary,
  revokeAccess,
  acceptAccessTechnical,
  getTechnicalPortfolio,
  deleteVisitById,
  editVisitById,
  postToggleOfflineTechnicalAccess,
  registerPropertyTechnicalVisit,
  getTechnicalVisitsByProperty,
  editVisitImages,
  getTechnicalEvaluation,
  postTechnicalEvaluation,
  postTechnicalEvaluationFiles,
  getTechnicalEvaluationAnswers,
  getTechnicalEvaluationProducers,
  getTechnicalOwnerFamilyGroupMembers,
  createTechnicianGroups as createTechnicianGroupsService,
  updateTechnicianGroups as updateTechnicianGroupsService,
  deleteTechnicianGroups as deleteTechnicianGroupsService,
  editTechnicalEvaluationAnswer
} from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import { OfflineTechnicalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData/duckOfflineTechnicalUserData'
import { momentBackDateTimeFormat } from '@smartcoop/utils/dates'

import { selectIsConnected } from '../network/selectorNetwork'
import { TechnicalActions, TechnicalTypes } from './duckTechnical'

function* saveTechnicalVisit({
  technicalVisit,
  formData,
  propertyId,
  onSuccess = () => {},
  onError = () => {},
  isEdit = false
}) {
  try {
    if(technicalVisit?.visitId || isEdit) {
      yield call(
        // eslint-disable-next-line no-nested-ternary
        editVisitById,
        technicalVisit,
        { propertyId: technicalVisit?.propertyId || propertyId, visitId: technicalVisit?.visitId }
      )
      if(formData) {
        formData.append('images', JSON.stringify({ oldImages: technicalVisit?.oldImages }))
        yield call(editVisitImages, formData, { propertyId: technicalVisit?.propertyId || propertyId, visitId: technicalVisit?.visitId })
      }
    } else {
      let newFormData = formData
      if(!formData) {
        newFormData = new FormData()
      }
      newFormData.append('technicalVisit', JSON.stringify(technicalVisit))
      yield call(
        // eslint-disable-next-line no-nested-ternary
        registerPropertyTechnicalVisit,
        newFormData,
        { propertyId: technicalVisit?.propertyId || propertyId, visitId: technicalVisit?.visitId }
      )

    }
    yield call(onSuccess)
  } catch (err) {
    const error = err?.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* saveOfflineTechnicalVisit({ technicalVisit, formData, onSuccess = () => {},   onError = () => {}, isEdit = false }) {
  const isConnected = yield select(selectIsConnected)

  if(!isConnected) {
    yield call(onSuccess)
    yield put(TechnicalActions.newOfflineVisit(technicalVisit))
  }
  yield put(TechnicalActions.saveTechnicalVisit(technicalVisit, formData, technicalVisit?.propertyId, isConnected ? onSuccess : () => {}, onError, isEdit))
}

function* createOfflineTechnicalEvaluation({ quizId, params, onSuccess = () => {},   onError = () => {} }) {
  const isConnected = yield select(selectIsConnected)

  const currentQuiz = { ...params }

  if(!isConnected) {
    currentQuiz.quizAnswerId = params?.quizAnswerId || uuidv4()
    yield put(TechnicalActions.updateOfflineTechnicalEvaluationAnswers({ ...currentQuiz, createdAt: moment().format(momentBackDateTimeFormat), offline: true }))
    yield call(onSuccess, currentQuiz?.quizAnswerId)
  }
  yield put(TechnicalActions.createTechnicalEvaluation(quizId, currentQuiz, isConnected ? onSuccess : () => {}, onError))
}

function* requestAccessTechnicalForProprietary({
  propertyId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    yield call(requestAccessForProprietary, propertyId)
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* createTechnicalEvaluation({
  quizId,
  params,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { data } = yield call(params?.editing ? editTechnicalEvaluationAnswer : postTechnicalEvaluation, params, { quizId, quizAnswerId: params?.quizAnswerId })
    yield call(onSuccess, data?.id)
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* createTechnicianGroups({
  params,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { data } = yield call(createTechnicianGroupsService, params)
    yield call(onSuccess, data?.id)
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* updateTechnicianGroups({
  groupId,
  params,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { data } = yield call(updateTechnicianGroupsService, params, { groupId })
    yield call(onSuccess, data?.id)
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* deleteTechnicianGroups({
  groupId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { data } = yield call(deleteTechnicianGroupsService, { groupId })
    yield call(onSuccess, data?.id)
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* createTechnicalEvaluationFiles({
  params,
  answerId,
  questionId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    yield call(postTechnicalEvaluationFiles, params, { questionId, answerId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* acceptTechnicianAccess({
  permission,
  accessRequestId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    yield call(
      acceptAccessTechnical,
      permission,
      { accessRequestId }
    )
    yield call(onSuccess)
  } catch (error) {
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* loadTechnicalVisits({
  params = {},
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const {
      data: { data, ...pagination }
    } = yield call(params?.propertyId ? getTechnicalVisitsByProperty : getTechnicalVisits, params, { propertyId: params?.propertyId })

    onSuccess(data)
    yield put(
      TechnicalActions.loadTechnicalVisitsSuccess(data, pagination.page)
    )
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* loadTechnicalVisitsSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* loadRequestAccessList({
  params = {},
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const {
      data: { data, ...pagination }
    } = yield call(getTechniciansRequestList, params)

    yield put(
      TechnicalActions.loadRequestAccessListSuccess(data, pagination.page, () =>
        onSuccess(pagination)
      )
    )
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* loadTechnicalEvaluationAnswers({
  params = {},
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const {
      data: { data }
    } = yield call(getTechnicalEvaluationAnswers, params)

    yield put(
      TechnicalActions.loadTechnicalEvaluationAnswersSuccess(data)
    )
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* loadTechnicalEvaluationProducers({
  params = {},
  onSuccess,
  onError
}) {
  try {
    const {
      data: { data }
    } = yield call(getTechnicalEvaluationProducers, params)

    yield put(
      TechnicalActions.loadTechnicalEvaluationProducersSuccess(data)
    )
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield call(onError)
    yield put(TechnicalActions.technicalError(error))
  }
}

function* loadTechnicalForms({
  params = {},
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const {
      data: { data }
    } = yield call(getTechnicalEvaluation, params)

    yield put(TechnicalActions.loadTechnicalFormsSuccess(data))
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* loadRequestAccessListSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* loadTechnicalOwners({
  params = {},
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const {
      data: { data, ...pagination }
    } = yield call(getTechnicalPortfolio, params)

    yield put(
      TechnicalActions.loadTechnicalOwnersSuccess(data, pagination.page, () =>
        onSuccess(pagination)
      )
    )
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* loadTechnicalOwnersSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* deleteTechnicalVisit({
  technicalVisitId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    yield call(deleteVisitById, { technicalVisitId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* revokeTechnicalAccess({
  id,
  statusId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    yield call(revokeAccess, { id, statusId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

function* toggleOfflineTechnicalAccess({
  availableOffline,
  proprietaryTechnicianId
}) {
  try {
    yield call(postToggleOfflineTechnicalAccess, { availableOffline }, { proprietaryTechnicianId })
    yield put(OfflineTechnicalUserDataActions.loadData())
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
  }
}

function* setTechnicalCurrentOwner({
  currentOwner,
  onSuccess = () => {},
  onError = () => {}
}) {
  yield put(
    TechnicalActions.loadPropertiesByOwner(currentOwner, onSuccess, onError)
  )
}

function* loadPropertiesByOwner({
  currentOwner,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { id: currentOwnerId } = currentOwner

    // request pra descobrir as propriedades
    const {
      data: { data: properties }
    } = yield call(
      getPropertiesByOwner,
      {
        limit:
          process.env.REACT_APP_FAKE_PAGINATION_SIZE ||
          REACT_APP_FAKE_PAGINATION_SIZE
      },
      { ownerId: currentOwnerId }
    )
    const { data } =  yield call(getTechnicalOwnerFamilyGroupMembers, {}, { userId: currentOwnerId })

    yield put(TechnicalActions.setCurrentOwnerFamilyGroup(data))

    if (properties.length > 0) {
      yield put(TechnicalActions.setTechnicalCurrentOwnerSuccess(currentOwner))
      yield put(TechnicalActions.loadPropertiesByOwnerSuccess(properties))
      yield call(onSuccess)
    } else {
      yield call(onError, { message: 'producer has no property' })
    }
  } catch (err) {
    const error = err.message
    yield put(TechnicalActions.technicalError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(TechnicalTypes.CREATE_TECHNICIAN_GROUPS, createTechnicianGroups),
  takeLatest(TechnicalTypes.UPDATE_TECHNICIAN_GROUPS, updateTechnicianGroups),
  takeLatest(TechnicalTypes.DELETE_TECHNICIAN_GROUPS, deleteTechnicianGroups),
  takeLatest(TechnicalTypes.CREATE_TECHNICAL_EVALUATION, createTechnicalEvaluation),
  takeLatest(TechnicalTypes.CREATE_TECHNICAL_EVALUATION_FILES, createTechnicalEvaluationFiles),
  takeLatest(TechnicalTypes.SAVE_TECHNICAL_VISIT, saveTechnicalVisit),
  takeLatest(TechnicalTypes.SAVE_OFFLINE_TECHNICAL_VISIT, saveOfflineTechnicalVisit),
  takeLatest(TechnicalTypes.CREATE_OFFLINE_TECHNICAL_EVALUATION, createOfflineTechnicalEvaluation),
  takeLatest(TechnicalTypes.LOAD_TECHNICAL_FORMS, loadTechnicalForms),
  takeLatest(
    TechnicalTypes.REQUEST_ACCESS_TECHNICAL_FOR_PROPRIETARY,
    requestAccessTechnicalForProprietary
  ),
  takeLatest(TechnicalTypes.LOAD_TECHNICAL_VISITS, loadTechnicalVisits),
  takeLatest(
    TechnicalTypes.LOAD_TECHNICAL_VISITS_SUCCESS,
    loadTechnicalVisitsSuccess
  ),
  takeLatest(TechnicalTypes.LOAD_REQUEST_ACCESS_LIST, loadRequestAccessList),
  takeLatest(
    TechnicalTypes.LOAD_REQUEST_ACCESS_LIST_SUCCESS,
    loadRequestAccessListSuccess
  ),
  takeLatest(TechnicalTypes.LOAD_TECHNICAL_OWNERS, loadTechnicalOwners),
  takeLatest(
    TechnicalTypes.LOAD_TECHNICAL_OWNERS_SUCCESS,
    loadTechnicalOwnersSuccess
  ),
  takeLatest(TechnicalTypes.ACCEPT_TECHNICIAN_ACCESS, acceptTechnicianAccess),
  takeLatest(TechnicalTypes.DELETE_TECHNICAL_VISIT, deleteTechnicalVisit),
  takeLatest(TechnicalTypes.REVOKE_TECHNICAL_ACCESS, revokeTechnicalAccess),
  takeLatest(TechnicalTypes.TOGGLE_OFFLINE_TECHNICAL_ACCESS, toggleOfflineTechnicalAccess),
  takeLatest(TechnicalTypes.LOAD_TECHNICAL_EVALUATION_ANSWERS, loadTechnicalEvaluationAnswers),
  takeLatest(TechnicalTypes.LOAD_TECHNICAL_EVALUATION_PRODUCERS, loadTechnicalEvaluationProducers),
  takeLatest(
    TechnicalTypes.SET_TECHNICAL_CURRENT_OWNER,
    setTechnicalCurrentOwner
  ),
  takeLatest(TechnicalTypes.LOAD_PROPERTIES_BY_OWNER, loadPropertiesByOwner)
]
