import { all } from 'redux-saga/effects'

/* sagas from @smartcoop/stores */
import sagasAbsence from '@smartcoop/stores/absence/sagasAbsence'
import sagasAccount from '@smartcoop/stores/account/sagasAccount'
import sagaAnimal from '@smartcoop/stores/animal/sagasAnimal'
import sagasAnimalBirth from '@smartcoop/stores/animalBirth/sagasAnimalBirth'
import sagasAnimalPregnancyActions from '@smartcoop/stores/animalPregnancyActions/sagasAnimalPregnancyActions'
import sagasAnimalPregnancyDiagnostics from '@smartcoop/stores/animalPregnancyDiagnostics/sagasAnimalPregnancyDiagnostics'
import sagasAnimalProtocol from '@smartcoop/stores/animalProtocol/sagasAnimalProtocol'
import sagasAuthentication from '@smartcoop/stores/authentication/sagasAuthentication'
import sagasBarter from '@smartcoop/stores/barter/sagasBarter'
import sagaChallenges from '@smartcoop/stores/challenges/sagasChallenges'
import sagasChat from '@smartcoop/stores/chat/sagasChat'
import sagasCosts from '@smartcoop/stores/costs/sagasCosts'
import sagasCropManagement from '@smartcoop/stores/cropManagement/sagasCropManagement'
import sagasDairyFarm from '@smartcoop/stores/dairyFarm/sagasDairyFarm'
import sagasFamilyGroup from '@smartcoop/stores/familyGroup/sagasFamilyGroup'
import sagasField from '@smartcoop/stores/field/sagasField'
import sagasFieldsMonitoration from '@smartcoop/stores/fieldsMonitoration/sagasFieldsMonitoration'
import sagasInformatives from '@smartcoop/stores/informatives/sagasInformatives'
import sagasInsemination from '@smartcoop/stores/insemination/sagasInsemination'
import sagasLot from '@smartcoop/stores/lot/sagasLot'
import sagasMachine from '@smartcoop/stores/machine/sagasMachine'
import sagaMedicationControl from '@smartcoop/stores/medicationControl/sagasMedicationControl'
import sagasMessaging from '@smartcoop/stores/messaging/sagasMessaging'
import sagasModule from '@smartcoop/stores/module/sagasModule'
import sagasNfe from '@smartcoop/stores/nfe/sagasNfe'
import sagasNonCompliance from '@smartcoop/stores/nonCompliance/sagasNonCompliance'
import sagasOperations from '@smartcoop/stores/operations/sagasOperations'
import sagasOrder from '@smartcoop/stores/order/sagasOrder'
import sagasOrganization from '@smartcoop/stores/organization/sagasOrganization'
import sagasProductGroup from '@smartcoop/stores/productGroup/sagasProductGroup'
import sagaProducts from '@smartcoop/stores/products/sagasProducts'
import sagasPriceHistory from '@smartcoop/stores/productsPriceHistory/sagasProductsPriceHistory'
import sagasProductsQuotation from '@smartcoop/stores/productsQuotation/sagasProductsQuotation'
import sagasProductWall from '@smartcoop/stores/productWall/sagasProductWall'
import sagasProperty from '@smartcoop/stores/property/sagasProperty'
import sagasSalesOrders from '@smartcoop/stores/salesOrders/sagasSalesOrders'
import sagaSateliteSvc from '@smartcoop/stores/sateliteSvc/sagaSateliteSvc'
import sagaSecuritiesMovement from '@smartcoop/stores/securitiesMovement/sagaSecuritiesMovement'
import sagasSocial from '@smartcoop/stores/social/sagasSocial'
import sagasSupplierQuotation from '@smartcoop/stores/supplierQuotation/sagasSupplierQuotation'
import sagasTechnical from '@smartcoop/stores/technical/sagasTechnical'
import sagasUser from '@smartcoop/stores/user/sagasUser'
import sagaWeatherForecast from '@smartcoop/stores/weatherForecast/sagaWeatherForecast'
import sagaWeatherStations from '@smartcoop/stores/weatherStations/sagasWeatherStations'

export default function* root() {
  yield all([
    ...sagasAuthentication,
    ...sagasField,
    ...sagasAnimalProtocol,
    ...sagasModule,
    ...sagasInformatives,
    ...sagasOrganization,
    ...sagasProductsQuotation,
    ...sagasAccount,
    ...sagasProperty,
    ...sagasUser,
    ...sagasNfe,
    ...sagasOrder,
    ...sagasSocial,
    ...sagasSupplierQuotation,
    ...sagaWeatherForecast,
    ...sagaMedicationControl,
    ...sagaSateliteSvc,
    ...sagasMachine,
    ...sagasChat,
    ...sagasSalesOrders,
    ...sagasCosts,
    ...sagasMessaging,
    ...sagasPriceHistory,
    ...sagasProductGroup,
    ...sagasDairyFarm,
    ...sagasTechnical,
    ...sagasOperations,
    ...sagaProducts,
    ...sagasProductWall,
    ...sagasBarter,
    ...sagasFieldsMonitoration,
    ...sagasNonCompliance,
    ...sagaSecuritiesMovement,
    ...sagasCropManagement,
    ...sagasLot,
    ...sagaAnimal,
    ...sagasAnimalBirth,
    ...sagaWeatherStations,
    ...sagasAnimalPregnancyActions,
    ...sagasInsemination,
    ...sagasAnimalPregnancyDiagnostics,
    ...sagasFamilyGroup,
    ...sagaChallenges,
    ...sagasAbsence
  ])
}
