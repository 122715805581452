// eslint-disable-next-line spaced-comment
export default ({ size, color = '#000' } = {}) => /*html*/`
  <svg width="${ size }" height="${ size }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
  <g transform="matrix(1, 0, 0, 1, 195.33848571777347, 14.186690330505371)" style="">
    <path fill="${ color }" d="M 173.512 222.245 C 209.963 222.245 239.518 251.8 239.518 288.251 C 239.518 324.704 209.963 354.259 173.512 354.259 C 137.059 354.259 107.504 324.704 107.504 288.251 C 107.504 251.8 137.059 222.245 173.512 222.245 M 173.512 387.261 C 246.448 387.261 305.524 416.799 305.524 453.267 L 305.524 486.271 L 41.498 486.271 L 41.498 453.267 C 41.498 416.799 100.574 387.261 173.512 387.261 Z" style=""/>
    <path fill="${ color }" d="M -65.377 124.52 C -29.127 124.52 0.264 153.911 0.264 190.161 C 0.264 226.411 -29.127 255.802 -65.377 255.802 C -101.627 255.802 -131.018 226.411 -131.018 190.161 C -131.018 153.911 -101.627 124.52 -65.377 124.52 M -65.377 288.622 C 7.156 288.622 65.904 317.996 65.904 354.262 L 65.904 387.083 L -196.659 387.083 L -196.659 354.262 C -196.659 317.996 -137.91 288.622 -65.377 288.622 Z" style=""/>
    <path d="M 110.373 43.176 C 107.697 45.847 124.81 64.018 125.48 61.057 C 148.883 40.334 172.589 10.854 172.586 10.848 L 125.498 -41.422 C 122.845 -44.09 107.725 -28.296 110.381 -25.628 L 137.731 -0.205 L 67.975 1.37 C 17.861 1.379 -22.908 54.288 -22.908 119.345 C -22.908 184.391 15.536 247.933 76.067 246.912 L 76.187 228.603 C 32.598 230.715 0.503 176.946 0.503 119.434 C 0.503 61.935 26.85 30.255 66.749 23.221 L 138.061 20.391 L 110.373 43.176 Z" style="stroke-width: 9px;" transform="matrix(0, 1, -1, 0, 178.952344913576, 29.274312139158)"/>
  </g>
</svg>
`
