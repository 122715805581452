import { REACT_APP_AMBIENTE } from 'react-native-dotenv'

import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import { persistStore } from 'redux-persist'
import createSagaMiddleware, { END } from 'redux-saga'

import { database } from '@smartcoop/database/web-database'
import { createRefreshTokenInterceptor, createAccessTokenSubscriber } from '@smartcoop/services/apis/smartcoopApi/interceptors'
import { authenticatedMiddleware } from '@smartcoop/stores'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import { DatabaseActions } from '@smartcoop/stores/database'

import reducers from './reducers'
import sagas from './sagas'

const middlewares = [authenticatedMiddleware]

if (process.env.REACT_APP_AMBIENTE === 'development') {
  middlewares.push(createLogger({}))
}

const sagaMiddleware = createSagaMiddleware()
middlewares.push(sagaMiddleware)

const composeEnhancers =  process.env.REACT_APP_AMBIENTE === 'development' || REACT_APP_AMBIENTE === 'development' ? composeWithDevTools : compose

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middlewares))
)

store.close = () => store.dispatch(END)
store.persist = () =>
  persistStore(store, {}, () => {
    store.dispatch(AuthenticationActions.syncFirebaseUserSaga())
    store.dispatch(DatabaseActions.setDatabase(database))
    store.dispatch(AuthenticationActions.initAuthSagas())
  })
store.runSaga = sagaMiddleware.run
store.runSaga(sagas)

createAccessTokenSubscriber(store)
createRefreshTokenInterceptor(store)

export default store
