import fontMonospaceBold from './assets/fonts/FiraCode-Bold.ttf'
import fontMonospaceLight from './assets/fonts/FiraCode-Light.ttf'
import fontMonospaceMedium from './assets/fonts/FiraCode-Medium.ttf'
import fontMonospaceRegular from './assets/fonts/FiraCode-Regular.ttf'
import fontMontserratBold from './assets/fonts/product/Montserrat_Bold.ttf'
import fontMontserratLight from  './assets/fonts/product/Montserrat_Light.ttf'
import fontMontserratMedium from  './assets/fonts/product/Montserrat_Medium.ttf'
import fontMontserratRegular from  './assets/fonts/product/Montserrat_Regular.ttf'
import fontMontserratSemiBold from  './assets/fonts/product/Montserrat-SemiBold.ttf'
import fontBold from './assets/fonts/product/OpenSans_Bold.ttf'
import fontLight from './assets/fonts/product/OpenSans_Light.ttf'
import fontRegular from './assets/fonts/product/OpenSans_Regular.ttf'
import fontSemiBold from './assets/fonts/product/OpenSans-SemiBold.ttf'

const fontFamilySans = ['\'Open Sans\'', '\'Roboto\'', '-apple-system', 'sans-serif', 'Arial'].join(',')

const fontFamilyMonospace =['\'Fira Code\''].join(',')
const fontFamilyMontserrat =['\'Montserrat\''].join(',')

const fontWeight = {
  thin: '100',
  light: '300',
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700'
}

const fontSize = {
  SSS: 10,
  SS: 13,
  S: 14,
  M: 16,
  L: 18,
  XL: 20,
  XXL: 24,
  XXXL: 30,
  XXXXL: 36,
  XXXXXL: 50,
  IconXXL: 64
}

const fontFaceMontserrat = [
  {
    fontFamily: 'Montserrat',
    src: `url("${ fontMontserratLight }") format("truetype")`,
    fontWeight: fontWeight.light
  },
  {
    fontFamily: 'Montserrat',
    src: `url("${ fontMontserratRegular }") format("truetype")`,
    fontWeight: fontWeight.regular
  },
  {
    fontFamily: 'Montserrat',
    src: `url("${ fontMontserratMedium }") format("truetype")`,
    fontWeight: fontWeight.medium
  },
  {
    fontFamily: 'Montserrat',
    src: `url("${ fontMontserratSemiBold }") format("truetype")`,
    fontWeight: fontWeight.semiBold
  },
  {
    fontFamily: 'Montserrat',
    src: `url("${ fontMontserratBold }") format("truetype")`,
    fontWeight: fontWeight.bold
  }
]

const fontFaceSans = [
  {
    fontFamily: 'Open Sans',
    src: `url("${ fontLight }") format("truetype")`,
    fontWeight: fontWeight.light
  },
  {
    fontFamily: 'Open Sans',
    src: `url("${ fontRegular }") format("truetype")`,
    fontWeight: fontWeight.regular
  },
  {
    fontFamily: 'Open Sans',
    src: `url("${ fontSemiBold }") format("truetype")`,
    fontWeight: fontWeight.semiBold
  },
  {
    fontFamily: 'Open Sans',
    src: `url("${ fontBold }") format("truetype")`,
    fontWeight: fontWeight.bold
  }
]

const fontFaceMonospace = [
  {
    fontFamily: 'Fira Code',
    src: `url("${ fontMonospaceLight }") format("truetype")`,
    fontWeight: fontWeight.light
  },
  {
    fontFamily: 'Fira Code',
    src: `url("${ fontMonospaceRegular }") format("truetype")`,
    fontWeight: fontWeight.regular
  },
  {
    fontFamily: 'Fira Code',
    src: `url("${ fontMonospaceMedium }") format("truetype")`,
    fontWeight: fontWeight.medium
  },
  {
    fontFamily: 'Fira Code',
    src: `url("${ fontMonospaceBold }") format("truetype")`,
    fontWeight: fontWeight.bold
  }
]


export default {
  fontFaceSans,
  fontFaceMonospace,
  fontFaceMontserrat,
  fontFamilySans,
  fontFamilyMonospace,
  fontFamilyMontserrat,
  fontWeight,
  fontSize
}
