import 'leaflet/dist/leaflet.css'

import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  '@global': {
    '.leaflet-container': {
      flex: 1,
      width: '100%',
      height: '100%'
    },
    '.leaflet-popup-content': {
      display: 'flex',
      justifyContent: 'center'
    },
    '.leaflet-div-icon': {
      background: 'transparent !important',
      border: 'none !important'
    }
  }
})
