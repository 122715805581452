import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { split } from 'lodash'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { useT } from '@smartcoop/i18n'
import { organizationRounded } from '@smartcoop/icons'
import { getTokenByOrganization } from '@smartcoop/services/apis/smartcoopApi/resources/authentication'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { useSnackbar } from '@smartcoop/snackbar'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import {
  selectAccessToken,
  selectRefreshToken
} from '@smartcoop/stores/authentication/selectorAuthentication'
import { AVAILABLE_MODULES, ModuleActions } from '@smartcoop/stores/module'
import { OrganizationActions } from '@smartcoop/stores/organization'
import {
  selectUserOrganizationsByModule,
  selectCurrentOrganization
} from '@smartcoop/stores/organization/selectorOrganization'
import colors from '@smartcoop/styles/colors'
import ItemSelection from '@smartcoop/web-components/ItemSelection'
import Modal from '@smartcoop/web-components/Modal'

import useStyles from './styles'

const ChangeOrganizationModal = ({ id, open, handleClose, module }) => {
  const classes = useStyles()

  const t = useT()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const { organizations } = useSelector(selectUserOrganizationsByModule)
  // const currentModule = useSelector(selectCurrentModule)
  const currentOrganization = useSelector(selectCurrentOrganization)
  const oldAccessToken = useSelector(selectAccessToken)
  const oldRefreshToken = useSelector(selectRefreshToken)

  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [mounted, setMounted] = useState(false)

  const beforeSelected = useMemo(
    () => currentOrganization,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (module) {
      const array = map(organizations, (item) => ({
        ...item,
        description: module === AVAILABLE_MODULES.administration && `${ item?.profileName }`,
        value: item.id,
        label: `${ item.tradeName || item.companyName } ${
          item.registry ? `- ${ item.registry }` : ''
        }`
      }))
      setOptions(array)
    }
  }, [module, organizations])

  const onChange = useCallback(
    async (org) => {
      setLoading(true)

      const organization = find(
        organizations,
        (item) => item.id === org.value && item.registry === org.registry
      )

      const updatedOrganization = { ...organization }

      if(!isEmpty(organization?.logo?.fileKey)) {
        const { data: { file } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_ORGANIZATION_FILES_ID, fileKey: organization?.logo?.fileKey })
        updatedOrganization.logoData = file
      }

      getTokenByOrganization(
        {
          accessToken: split(oldAccessToken, ' ')[1],
          refreshToken: oldRefreshToken,
          registry: organization.registry
        },
        { organizationId: organization.id }
      )
        .then(({ accessToken, refreshToken, iaToken, tokenType }) => {
          dispatch(
            AuthenticationActions.refreshTokenSuccess(
              `${ tokenType } ${ accessToken }`,
              refreshToken,
              iaToken,
              () => {
                dispatch(
                  OrganizationActions.setCurrentOrganization(
                    updatedOrganization,
                    () => {
                      setLoading(false)
                      handleClose()
                      dispatch(ModuleActions.initModuleBySlug(module))
                    },
                    (err) => {
                      console.log(
                        '%cMyProject%cline:101%cerr',
                        'color:#fff;background:#ee6f57;padding:3px;border-radius:2px',
                        'color:#fff;background:#1f3c88;padding:3px;border-radius:2px',
                        'color:#fff;background:rgb(248, 147, 29);padding:3px;border-radius:2px',
                        err
                      )
                      setLoading(false)
                    }
                  )
                )
              },
              (err) => {
                console.log(
                  '%cMyProject%cline:105%cerr',
                  'color:#fff;background:#ee6f57;padding:3px;border-radius:2px',
                  'color:#fff;background:#1f3c88;padding:3px;border-radius:2px',
                  'color:#fff;background:rgb(179, 214, 110);padding:3px;border-radius:2px',
                  err
                )
                snackbar.error('an error ocurred')
                setLoading(false)
              }
            )
          )
        })
        .catch((err) => {
          console.log(
            '%cMyProject%cline:105%cerr',
            'color:#fff;background:#ee6f57;padding:3px;border-radius:2px',
            'color:#fff;background:#1f3c88;padding:3px;border-radius:2px',
            'color:#fff;background:rgb(96, 143, 159);padding:3px;border-radius:2px',
            err
          )
          snackbar.error(t('an error ocurred'))
          setLoading(false)
        })
    },
    [
      dispatch,
      handleClose,
      module,
      oldAccessToken,
      oldRefreshToken,
      organizations,
      snackbar,
      t
    ]
  )

  const selectedOrganization = useMemo(() => {
    if (isEmpty(beforeSelected)) return {}

    return {
      ...beforeSelected,
      value: beforeSelected.id,
      description: module === AVAILABLE_MODULES.administration && `${ beforeSelected?.profileName }`,
      label: `${ beforeSelected.tradeName || beforeSelected.companyName } ${
        beforeSelected.registry ? `- ${ beforeSelected.registry }` : ''
      }`
    }
  }, [beforeSelected, module])

  const filterShowItems = useCallback(
    (option, selected) =>
      option.registry !== selected.registry || option.value !== selected.value,
    []
  )

  useEffect(() => {
    if (mounted) {
      dispatch(
        OrganizationActions.loadUserOrganizationsByModule(
          module,
          () => {
            if (!isEmpty(currentOrganization)) setLoading(false)
          },
          (err) => {
            console.log(
              '%cMyProject%cline:174%cerr',
              'color:#fff;background:#ee6f57;padding:3px;border-radius:2px',
              'color:#fff;background:#1f3c88;padding:3px;border-radius:2px',
              'color:#fff;background:rgb(39, 72, 98);padding:3px;border-radius:2px',
              err
            )
            setLoading(false)
          }
        )
      )
    }
  }, [currentOrganization, dispatch, module, mounted])

  useEffect(() => {
    if (!isEmpty(organizations) && mounted) {
      if (organizations.length === 1) {
        const array = map(organizations, (item) => ({
          ...item,
          value: item.id,
          description: module === AVAILABLE_MODULES.administration && `${ item?.profileName }`,
          label: `${ item.tradeName || item.companyName } ${
            item.registry ? `- ${ item.registry }` : ''
          }`
        }))

        onChange(array[0])
      } else {
        setLoading(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations])

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(
    () => () => {
      setMounted(false)
    },
    []
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={ module === AVAILABLE_MODULES.administration ? t('select a profile') : t('select one organization') }
      maxWidth="md"
      disableFullScreen
      escapeWhenLoading
      escape={ !isEmpty(beforeSelected) }
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ {
        padding: '15px 0'
      } }
      headerProps={ {
        titleClass: classes.title
      } }
      loading={ loading }
    >
      <ItemSelection
        title={ !isEmpty(beforeSelected) ? t('other organizations') : '' }
        options={ options }
        filterFunction={ filterShowItems }
        unselectedIcon={ organizationRounded }
        unselectedIconColor={ colors.primary }
        selectedIcon={ organizationRounded }
        selectedIconColor={ colors.green }
        onChange={ onChange }
        selected={ selectedOrganization }
      />
    </Modal>
  )
}

ChangeOrganizationModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  module: PropTypes.string.isRequired
}

export default ChangeOrganizationModal
