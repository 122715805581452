import React, { useState, useMemo } from 'react'

import withObservables from '@nozbe/with-observables'
import PropType from 'prop-types'


import first from 'lodash/first'

import { Avatar } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'

import { database } from '@smartcoop/database'
import { profilePictureService } from '@smartcoop/database/services/profilePictureService'
import I18n from '@smartcoop/i18n'
import { arrowDown, star, trash } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'

import { PROFILES, convertToArray } from '../../utils/validateProfiles'
import { AdminLabel, Card, TextName, ActionButton, ButtonContainer, IconCircle } from './styles'

const GroupMemberCard = ({
  member,
  profilePictures,
  isGroupAdmin,
  isLoggedUser,
  onTurnAdmin,
  onRemoveMember,
  isBroadcast,
  isFamilyGroup
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const profilePicture = first(profilePictures)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const canBeAdmin = useMemo(() => {
    const profiles = convertToArray(member.profiles)
    return (
      profiles.some(item => item !== PROFILES.FAMILY_MEMBER && item !== PROFILES.PRODUCTOR) ||
      (isFamilyGroup && profiles.some(item => item === PROFILES.PRODUCTOR))
    )
  }, [isFamilyGroup, member])

  return (
    <Card isAdmin={ member?.isAdmin } showOptions={ isGroupAdmin }>
      <Avatar src={ profilePicture?.source } size={ 25 } alt={ member?.userName } />
      <TextName>{member?.userName}</TextName>
      {member.isAdmin && (<AdminLabel>Admin</AdminLabel>)}
      {(isGroupAdmin && !isLoggedUser) && (
        <>
          <ActionButton id="action">
            <Icon
              onClick={ handleClick }
              icon={ arrowDown }
              color={ colors.text }
              className="delete-message"
            />
          </ActionButton>
          <Popover
            id={ member?.id }
            open={ open }
            anchorEl={ anchorEl }
            onClose={ handleClose }
            anchorOrigin={ {
              vertical: 'bottom',
              horizontal: 'left'
            } }
          >
            <ButtonContainer>
              { !member.isAdmin && (
                <Button
                  color={ colors.white }
                  onClick={ () => onRemoveMember(member) }
                >
                  <div style={ { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%' } }>
                    <IconCircle circleColor={ colors.error }>
                      <Icon icon={ trash } color={ colors.white } size={ 15 } />
                    </IconCircle>
                    {isBroadcast ? (
                      <I18n>remove from broadcast</I18n>
                    ) : (
                      <I18n>remove from group</I18n>
                    )}
                  </div>

                </Button>
              ) }
              { canBeAdmin && !isBroadcast && (
                <Button
                  color={ colors.white }
                  onClick={ () => onTurnAdmin(member) }
                >
                  <div style={ { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%', alignItems: 'center' } }>
                    <IconCircle circleColor={ colors.orange }>
                      <Icon icon={ star } color={ colors.white } size={ 15 } />
                    </IconCircle>
                    {!member.isAdmin ? (
                      <I18n>make admin</I18n>
                    ) : (
                      <I18n>dismiss as admin</I18n>
                    )}
                  </div>
                </Button>
              ) }
            </ButtonContainer>
          </Popover>
        </>
      )}
    </Card>
  )
}

GroupMemberCard.propTypes = {
  member: PropType.object.isRequired,
  isGroupAdmin: PropType.bool,
  isLoggedUser: PropType.bool,
  profilePictures: PropType.array,
  onRemoveMember: PropType.func.isRequired,
  onTurnAdmin: PropType.func.isRequired,
  isBroadcast: PropType.bool,
  isFamilyGroup: PropType.bool
}

GroupMemberCard.defaultProps = {
  isGroupAdmin: false,
  isLoggedUser: false,
  profilePictures: [],
  isBroadcast: false,
  isFamilyGroup: false
}

const enhance = withObservables(['userId'], ({ userId }) => ({
  profilePictures: profilePictureService(database).observeProfilePictureByUserId(userId)
}))

const EnhancedGroupMemberCard = enhance(GroupMemberCard)

export default EnhancedGroupMemberCard
