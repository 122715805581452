import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 15px 0 15px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
`
