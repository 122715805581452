import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
`
export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
`

export const inputSearchTheme = (theme) => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff'
      }
    }
  }
})

export default makeStyles({
  container: {
    padding: `${ 0 } ${ 20 }px`,
    width: 200
  },
  listItemIcon: {
    minWidth: 40
  }
})

