import api from '../api'

export const getAccountsBalance = (params, { organizationId }) =>
  api.get(`/v1/accounts/organization/${ organizationId }`, { params,
    headers: {
      'pragma': 'no-cache',
      'Cache-Control': 'no-cache'
    } })
export const getAllAccountsBalanceByUser = (params) =>
  api.get('/v1/accounts/organizations/user', { params })
