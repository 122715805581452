import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'
import year from '@smartcoop/forms/validators/year.validator'

const growingSeasonRegisterSchema = ({ t }) => Yup.object().shape({
  cropId: flow(
    required({ t })
  )(Yup.string()),

  sowingYear: flow(
    year({ t }),
    required({ t })
  )(Yup.string()),

  cultivationGoalId: flow(
    required({ t })
  )(Yup.string()),

  activityId: flow(
    required({ t })
  )(Yup.string()),

  userStateRegistrationsIds: flow(
  )(Yup.string())

})
export default growingSeasonRegisterSchema




