import React, { useCallback } from 'react'

import PropTypes from 'prop-types'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const CheckboxMaterial = (props) => {
  const {
    id,
    className,
    label,
    onChange,
    checked,
    value,
    size,
    onClick,
    disabled,
    ...otherProps
  } = props

  const handleChange = useCallback(({ target: { checked: cbChecked } }) => {
    if (!disabled) { onChange(cbChecked, value) }
  }, [disabled, onChange, value])

  return (
    <FormControlLabel
      className={ className }
      id={ id }
      onClick={ onClick }
      { ...otherProps }
      control={ (
        <Checkbox
          value={ value }
          size={ size }
          id={ `${ id }-checkbox-input` }
          disabled={ disabled }
          onChange={ handleChange }
          checked={ !!checked }
          color="primary"
          indeterminate={ checked === null }
        />
      ) }
      label={ label }
    />
  )
}

CheckboxMaterial.propTypes = {
  /** Identificador do Checkbox */
  id: PropTypes.string.isRequired,
  /** Classe para estilos */
  className: PropTypes.string,
  /** Texto que aparece ao lado do Checkbox */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  /** Valor que o Checkbox representa */
  value: PropTypes.any,
  /** Estado do CheckBox (ativo, inativo, indeterminado)  */
  checked: PropTypes.oneOf([true, false, null]),
  /** Função no clique do mouse */
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium'])
}

CheckboxMaterial.defaultProps = {
  className: '',
  value: '',
  onChange: () => {},
  onClick: () => {},
  disabled: false,
  label: null,
  size: 'medium',
  checked: false
}

export default CheckboxMaterial
