import api from '../api'

const URL_POSTS = '/products-forum/posts'
const URL_COMMENTS = '/products-forum/comments'


export const getPosts = (params) => api.get(`/v1${ URL_POSTS }`, { params })

export const createPost = (params) => api.post(`/v1${ URL_POSTS }`, params)

export const getPost = (params, postId = '') => api.get(`/v1${ URL_POSTS }/${ postId }`, { params })

export const editPost = (params = {}, postId = '') => api.patch(`/v1${ URL_POSTS }/${ postId }`, params)

export const deletePost = (params, postId = '') => api.delete(`/v1${ URL_POSTS }/${ postId }`)

export const getPostComments = (params = {}, postId = '' ) => api.get(`/v1${ URL_POSTS }/${ postId }/comments`, params)

export const createPostComment = (params = {}, postId = '' ) => api.post(`/v1${ URL_POSTS }/${ postId }/comments`, params)

export const deletePostComment = (commentId = '') => api.delete(`/v1${ URL_COMMENTS }/${ commentId }`)

export const editPostComment = (params = {}, commentId = '' ) => api.patch(`/v1${ URL_COMMENTS }/${ commentId }`, params)
