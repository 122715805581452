import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { InformativesActions } from '@smartcoop/stores/informatives'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'
import InformativeForm from '@smartcoop/web-containers/forms/digitalProperty/property/InformativeForm'


import useStyles, { Row, Content, ButtonsContainer  } from './styles'

const RegisterInformativeModal = (props) => {
  const classes = useStyles()
  const informativeFormRef = useRef(null)
  const dispatch = useDispatch()
  const t = useT()

  const { open, handleClose, name, defaultValues, reloadDataTable, organizationOptions } = props

  const snackbar = useSnackbar()

  const handleSubmit = (data) => {
    dispatch(InformativesActions.saveInformative(
      data,
      () => {
        snackbar.success(t('informative registered'))
        reloadDataTable()
        handleClose()
      }
    ))
  }

  return (
    <Modal
      id
      open={ open }
      onClose={ handleClose }
      title={
        <Row>
          <I18n>{name}</I18n>
        </Row>
      }
      disableFullScreen
      disableEsc
      maxWidth='md'
      fullWidth
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <Content>
        <InformativeForm
          ref={ informativeFormRef }
          organizationOptions={ organizationOptions }
          onSubmit={ handleSubmit }
          defaultValues={ defaultValues }
        />
        <ButtonsContainer>
          <Button
            id="submit-informative-form"
            onClick={ () => informativeFormRef.current.submit() }
            style={ { flex: 1 } }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Content>
    </Modal>
  )
}

RegisterInformativeModal.propTypes = {
  name: PropTypes.string.isRequired,
  open: PropTypes.bool,
  defaultValues: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  organizationOptions: PropTypes.array.isRequired,
  reloadDataTable: PropTypes.func.isRequired
}

RegisterInformativeModal.defaultProps = {
  open: false,
  defaultValues: null
}

export default RegisterInformativeModal
