export const acceptedAccessDocument = (`
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Century Gothic";
	panose-1:2 11 5 2 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:#954F72;
	text-decoration:underline;}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{mso-style-link:"Parágrafo da Lista Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:253.45pt;
	margin-bottom:.0001pt;
	text-indent:-18.05pt;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Century Gothic",sans-serif;}
span.PargrafodaListaChar
	{mso-style-name:"Parágrafo da Lista Char";
	mso-style-link:"Parágrafo da Lista";
	font-family:"Century Gothic",sans-serif;}
p.artigo, li.artigo, div.artigo
	{mso-style-name:artigo;
	margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.85pt 3.0cm 70.85pt 3.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=PT-BR link=blue vlink="#954F72" style={{ margin: 40 }}>
<div style={{width: '794px'}}>
<div class=WordSection1>


<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b><u><span
style='font-family:"Arial",sans-serif'>TERMO DE CONSENTIMENTO PARA COMPARTILHAMENTO DE DADOS PESSOAIS COM SMARTCOOP</span></u></b></p>

<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b><u><span
style='font-family:"Arial",sans-serif'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>

<p class=MsoNormal style='text-align:justify;line-height:115%'><span
style='font-family:"Arial",sans-serif'>A Cooperativa {companyName}, pessoa
jurídica de direito privado, inscrita no CNPJ/MF sob nº {companyDocument} está
sempre em busca de soluções inovadoras e tecnológicas para melhorar a gestão da
produtividade e a relação que possui com os Cooperados. <b><u>É com este
objetivo que disponibilizará aos Cooperados o aplicativo SmartCoop, que possui
funcionalidades que possibilitarão melhor gestão da produção, da
comercialização da produção e, especialmente, do relacionamento entre
Cooperativa x Cooperado. </u></b></span></p>

<p class=MsoNormal style='text-align:justify;line-height:115%'><span
style='font-family:"Arial",sans-serif'>Como controladora dos dados pessoais, a
Cooperativa preocupa-se com a privacidade dos Cooperados e, portanto, age de forma
integralmente transparente. Justamente por isso, através do presente termo, informamos
que há necessidade de compartilhamento dos seus dados pessoais com o aplicativo
SmartCoop, <b><u>caso você opte por utilizá-lo, especialmente para que seja
possível a confirmação e autenticação da sua identidade. </u></b></span></p>

<p class=MsoNormal style='text-align:justify;line-height:115%'><span
style='font-family:"Arial",sans-serif'>Ou seja, caso você deseje utilizar o
aplicativo, terá de consentir com o compartilhamento dos seus dados pessoais
com a SmartCoop. Porém, você pode optar por não utilizar o aplicativo e não
autorizar o compartilhamento. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:115%'><span
style='font-family:"Arial",sans-serif'>Leia atentamente as informações
relacionadas ao compartilhamento de dados pessoais com a SmartCoop para,
posteriormente, <b><u>decidir</u> </b>se consente ou não com o
compartilhamento:</span></p>

<p class=MsoNormal style='text-align:justify;text-indent:2.0cm;line-height:
115%'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>1)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'>SOBRE O COMPARTILHAMENTO DE DADOS
PESSOAIS</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:5.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Compartilharemos com a SmartCoop
o seu nome, CPF, data de nascimento e telefone. A SmartCoop poderá realizar tratamento
dos seus dados pessoais, tais como: a coleta, produção, recepção,
classificação, utilização, acesso, reprodução, transmissão, distribuição,
processamento, arquivamento, armazenamento, eliminação, avaliação ou controle
da informação, modificação, comunicação, transferência, difusão ou extração.</span></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>2)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'>FINALIDADE DA UTILIZAÇÃO DOS DADOS
PESSOAIS</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:5.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Os dados pessoais serão
utilizados para cadastrar você no aplicativo e para prover o seu acesso ao mesmo.
O CPF e a data de nascimento serão utilizados para validação da sua identidade,
ou seja, para que a SmartCoop tenha certeza de que é você quem está acessando o
aplicativo. Por outro lado, o seu número de telefone será utilizado para
remeter nova senha por SMS, nos casos em que você esquecer ou precisar
redefinir a senha. </span></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><b><span style='font-family:"Arial",sans-serif'>Obs.: Os termos de uso do
aplicativo e a sua respectiva política de privacidade poderão ser avaliados por
você no momento em que realizar seu cadastramento.</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>3)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'>ONDE A SMARTCOOP ARMAZENARÁ SEUS DADOS
PESSOAIS</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:5.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>O banco de dados da SmartCoop,
onde ficarão armazenados os seus dados pessoais, está localizado no Brasil. Entretanto,
quando for necessário enviar SMS para você, a SmartCoop poderá armazenar seu
telefone em North Virginia.</span></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>4)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'>RESPONSABILIDADES DO OPERADOR SMARTCOOP
E MEDIDAS DE SEGURANÇA</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:5.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Todos os dados pessoais que
você autorizar o compartilhamento serão tratados unicamente para atingir as
finalidades acima listadas e para possibilitar que você interaja com as
funcionalidades do aplicativo que optar por interagir.</span></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Contratualmente, a SmartCoop
está obrigada a tratar os seus dados pessoais de acordo com as melhores práticas
em Segurança da Informação e em conformidade com a Lei Geral de Proteção de
Dados Pessoais – LGPD.</span></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>5)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'>QUANTO TEMPO A SMARTCOOP ARMAZENARÁ SEUS
DADOS PESSOAIS</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:5.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>A SmartCoop manterá seus
dados pessoais até que você solicite a eliminação e/ou seja encerrado o
contrato de disponibilização do aplicativo, mantido com a Cooperativa. No caso
da solicitação da eliminação você não conseguirá utilizar o aplicativo
SmartCoop. </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Os dados pessoais poderão
ser mantidos pela SmartCoop quando necessário para o cumprimento de legislações
aplicáveis à relação mantida com você. Ex.: o protocolo IP será mantido por até
6 meses, após o registro.</span></p>

<p class=MsoNoSpacing style='margin-left:36.0pt;text-indent:20.7pt'><b><span
style='font-family:"Arial",sans-serif'>6)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>SEUS DIREITOS</span></b></p>

<p class=MsoNoSpacing style='margin-left:2.0cm'><b><span style='font-size:5.0pt;
font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNoSpacing style='margin-left:2.0cm;text-align:justify'><span
style='font-family:"Arial",sans-serif'>Em cumprimento ao previsto na LGPD, a
qualquer momento você pode solicitar que os direitos abaixo sejam atendidos:</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Confirmação de que
tratamos ou não seus dados pessoais;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Acesso aos seus
dados pessoais, caso sejam tratados por nós;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>c)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Correção de dados
pessoais incompletos, desatualizados ou inexatos;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>d)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Bloqueio,
anonimização ou eliminação de dados pessoais desnecessários ou que você
considere que sejam tratados de forma irregular;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>e)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Portabilidade dos
seus dados pessoais, quando aplicável;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Eliminação de dados
pessoais, ainda que tratados de forma regular;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>g)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Informações sobre o
compartilhamento dos seus dados com outras empresas ou com entidades/órgãos
públicos;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>h)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Informações sobre as
consequências de não consentir com determinado tratamento de dados pessoais
(exemplo: quando você não aceita nossa configuração de cookies, tem direito de
saber quais as consequências de não aceitar); </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Revogação do
consentimento;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>j)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Oposição a qualquer
tratamento de dados que você considera irregular.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>7)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'>COMO ENTRAR EM CONTATO CONOSCO</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:5.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Você poderá exercer seus
direitos, revogar o consentimento, solicitar informações, alteração,
esclarecimentos ou exclusão de seus dados pessoais através de contato com <b>privacidade@smart.coop.br</b>.</span></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>8)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'> CONSENTIMENTO</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:6.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>É opcional utilizar ou não o
aplicativo SmartCoop. Caso assim você desejar e desde que tenha compreendido as
informações indicadas acima, será necessário consentir com o compartilhamento e
com o armazenamento do seu número de telefone em North Virginia. </span></p>

<p class=MsoNormal style='text-align:justify;text-indent:2.0cm;line-height:
115%'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;text-indent:2.0cm;line-height:
115%'><img width=25 height=24 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAAAXNSR0IArs4c6QAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUATWljcm9zb2Z0IE9mZmljZX/tNXEAAAIvSURBVEhLtZbPS1RRFMdNp1RyERNtAiOhdSANIdFAVCAIzsJdSARNtFBoI0q0cJgWLYIWCk0QgQOCirZQmI3owtbRov6AFkFtChf+qsFffb5wbtxeb+a9mWYOfLn33XvO+Z5z7rl3JpHP51uaJblcLo3v9kQzCHB+Dr+vQSsYbjgJBJdxXAK/QIpK7TSUBIIbOF4BZdAHwZYq1TASCAbx9xYcgDQE39xRNIQEggwOl8ApkIHgk3/WCRRu28KJOprgGJtLYMoIRiFYDfpRJmt1OA8zmYWgELbhyvWBzRlruTgZ7aCrQ77rOZWPUHEkH4niZdyMKPF5dCdMf5/xJGiPIumogaAb3XegByijp+A5aIsiKROdIrkProAu8Ibs1n1DdM7wrXsgAskjsGEkFeN05VK6c2DI0+xk/ocEArWn7kGv6SwTxAzrCqqqOJI9tJS6L9dxkMTRpi0WGW/Z/CvjQ5srwFgk6vdJoG45bRZJxmugBJnuwR3PUxby7/Yt21gkXTLCmfp83LMYYO0i36q9k0LYhavGEnxW1CU6/LNmlGXUWTj5zORxVOTB/b9IiPAHkb9A6Zkp+gRaGkFn+79IzHiaUYeqMvlSrLVMzvifVxhHu2TzBAW1tJMvTMZqzaAiiTYgmofoHtN+U3zgtXKQK3Z36f0Jitr5PXgFQbWX+mdUhq5cN4l8AWX//dFvtO7CVfYWGcNe50PW9adBclSJzJFcQEEIk1RUpLZfsWy/Ac2GkPO29nwTAAAAAElFTkSuQmCC" align=left
hspace=12 alt="Forma&#10;&#10;Descrição gerada automaticamente com confiança média"></p>

<p class=MsoNormal style='text-align:justify;text-indent:2.0cm;line-height:
115%'><b><span style='font-family:"Arial",sans-serif'>CONSINTO com o
compartilhamento dos meus dados pessoais com a SmartCoop. </span></b></p>

<p class=MsoNormal style='text-align:justify;text-indent:2.0cm;line-height:
115%'><img width=25 height=24 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAAAXNSR0IArs4c6QAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUATWljcm9zb2Z0IE9mZmljZX/tNXEAAAIvSURBVEhLtZbPS1RRFMdNp1RyERNtAiOhdSANIdFAVCAIzsJdSARNtFBoI0q0cJgWLYIWCk0QgQOCirZQmI3owtbRov6AFkFtChf+qsFffb5wbtxeb+a9mWYOfLn33XvO+Z5z7rl3JpHP51uaJblcLo3v9kQzCHB+Dr+vQSsYbjgJBJdxXAK/QIpK7TSUBIIbOF4BZdAHwZYq1TASCAbx9xYcgDQE39xRNIQEggwOl8ApkIHgk3/WCRRu28KJOprgGJtLYMoIRiFYDfpRJmt1OA8zmYWgELbhyvWBzRlruTgZ7aCrQ77rOZWPUHEkH4niZdyMKPF5dCdMf5/xJGiPIumogaAb3XegByijp+A5aIsiKROdIrkProAu8Ibs1n1DdM7wrXsgAskjsGEkFeN05VK6c2DI0+xk/ocEArWn7kGv6SwTxAzrCqqqOJI9tJS6L9dxkMTRpi0WGW/Z/CvjQ5srwFgk6vdJoG45bRZJxmugBJnuwR3PUxby7/Yt21gkXTLCmfp83LMYYO0i36q9k0LYhavGEnxW1CU6/LNmlGXUWTj5zORxVOTB/b9IiPAHkb9A6Zkp+gRaGkFn+79IzHiaUYeqMvlSrLVMzvifVxhHu2TzBAW1tJMvTMZqzaAiiTYgmofoHtN+U3zgtXKQK3Z36f0Jitr5PXgFQbWX+mdUhq5cN4l8AWX//dFvtO7CVfYWGcNe50PW9adBclSJzJFcQEEIk1RUpLZfsWy/Ac2GkPO29nwTAAAAAElFTkSuQmCC" align=left
hspace=12 alt="Forma&#10;&#10;Descrição gerada automaticamente com confiança média"><b><span
style='font-family:"Arial",sans-serif'>CONSINTO com o armazenamento do meu
número de telefone em North Virginia.</span></b></p>

<p class=MsoNormal align=center style='text-align:center;text-indent:2.0cm;
line-height:115%'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid windowtext 1.5pt;padding:0cm 0cm 1.0pt 0cm;
margin-left:78.0pt;margin-right:77.9pt'>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-indent:
42.5pt;line-height:normal;border:none;padding:0cm'><span style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

</div>

<p class=MsoNormal style='margin-left:106.2pt;text-indent:35.4pt;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Assinatura do Cooperativado</span></p>

<p class=MsoNormal style='margin-left:127.5pt;text-indent:14.1pt;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Nome: {userName}</span></p>

<p class=MsoNormal style='margin-left:106.2pt;text-indent:35.4pt;line-height:
115%'><span style='font-family:"Arial",sans-serif'>CPF: {userDocument}</span></p>

</div>
</div>
</body>

</html>
`)

export const toBeAcceptedAccessDocument = (`
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Century Gothic";
	panose-1:2 11 5 2 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:#954F72;
	text-decoration:underline;}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{mso-style-link:"Parágrafo da Lista Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:253.45pt;
	margin-bottom:.0001pt;
	text-indent:-18.05pt;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Century Gothic",sans-serif;}
span.PargrafodaListaChar
	{mso-style-name:"Parágrafo da Lista Char";
	mso-style-link:"Parágrafo da Lista";
	font-family:"Century Gothic",sans-serif;}
p.artigo, li.artigo, div.artigo
	{mso-style-name:artigo;
	margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.85pt 3.0cm 70.85pt 3.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=PT-BR link=blue vlink="#954F72">

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b><u><span
style='font-family:"Arial",sans-serif'>TERMO DE CONSENTIMENTO PARA
COMPARTILHAMENTO DE DADOS PESSOAIS COM SMARTCOOP</span></u></b></p>

<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b><u><span
style='font-family:"Arial",sans-serif'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>

<p class=MsoNormal style='text-align:justify;line-height:115%'><span
style='font-family:"Arial",sans-serif'>A(s) Cooperativa(s) {companyName}, pessoa
jurídica de direito privado, inscrita no CNPJ/MF sob nº {companyDocument}, respectivamente, estão
sempre em busca de soluções inovadoras e tecnológicas para melhorar a gestão da
produtividade e a relação que possui com os Cooperados. <b><u>É com este
objetivo que disponibilizará aos Cooperados o aplicativo SmartCoop, que possui
funcionalidades que possibilitarão melhor gestão da produção, da
comercialização da produção e, especialmente, do relacionamento entre
Cooperativa x Cooperado. </u></b></span></p>

<p class=MsoNormal style='text-align:justify;line-height:115%'><span
style='font-family:"Arial",sans-serif'>Como controladora dos dados pessoais, a
Cooperativa preocupa-se com a privacidade dos Cooperados e, portanto, age de forma
integralmente transparente. Justamente por isso, através do presente termo, informamos
que há necessidade de compartilhamento dos seus dados pessoais com o aplicativo
SmartCoop, <b><u>caso você opte por utilizá-lo, especialmente para que seja
possível a confirmação e autenticação da sua identidade. </u></b></span></p>

<p class=MsoNormal style='text-align:justify;line-height:115%'><span
style='font-family:"Arial",sans-serif'>Ou seja, caso você deseje utilizar o
aplicativo, terá de consentir com o compartilhamento dos seus dados pessoais
com a SmartCoop. Porém, você pode optar por não utilizar o aplicativo e não
autorizar o compartilhamento. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:115%'><span
style='font-family:"Arial",sans-serif'>Leia atentamente as informações
relacionadas ao compartilhamento de dados pessoais com a SmartCoop para,
posteriormente, <b><u>decidir</u> </b>se consente ou não com o
compartilhamento:</span></p>

<p class=MsoNormal style='text-align:justify;text-indent:2.0cm;line-height:
115%'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>1)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'>SOBRE O COMPARTILHAMENTO DE DADOS
PESSOAIS</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:5.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Compartilharemos com a SmartCoop
o seu nome, CPF, data de nascimento e telefone. A SmartCoop poderá realizar tratamento
dos seus dados pessoais, tais como: a coleta, produção, recepção,
classificação, utilização, acesso, reprodução, transmissão, distribuição,
processamento, arquivamento, armazenamento, eliminação, avaliação ou controle
da informação, modificação, comunicação, transferência, difusão ou extração.</span></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>2)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'>FINALIDADE DA UTILIZAÇÃO DOS DADOS
PESSOAIS</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:5.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Os dados pessoais serão
utilizados para cadastrar você no aplicativo e para prover o seu acesso ao mesmo.
O CPF e a data de nascimento serão utilizados para validação da sua identidade,
ou seja, para que a SmartCoop tenha certeza de que é você quem está acessando o
aplicativo. Por outro lado, o seu número de telefone será utilizado para
remeter nova senha por SMS, nos casos em que você esquecer ou precisar
redefinir a senha. </span></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><b><span style='font-family:"Arial",sans-serif'>Obs.: Os termos de uso do
aplicativo e a sua respectiva política de privacidade poderão ser avaliados por
você no momento em que realizar seu cadastramento.</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>3)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'>ONDE A SMARTCOOP ARMAZENARÁ SEUS DADOS
PESSOAIS</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:5.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>O banco de dados da SmartCoop,
onde ficarão armazenados os seus dados pessoais, está localizado no Brasil. Entretanto,
quando for necessário enviar SMS para você, a SmartCoop poderá armazenar seu
telefone em North Virginia.</span></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>4)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'>RESPONSABILIDADES DO OPERADOR SMARTCOOP
E MEDIDAS DE SEGURANÇA</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:5.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Todos os dados pessoais que
você autorizar o compartilhamento serão tratados unicamente para atingir as
finalidades acima listadas e para possibilitar que você interaja com as
funcionalidades do aplicativo que optar por interagir.</span></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Contratualmente, a SmartCoop
está obrigada a tratar os seus dados pessoais de acordo com as melhores práticas
em Segurança da Informação e em conformidade com a Lei Geral de Proteção de
Dados Pessoais – LGPD.</span></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>5)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'>QUANTO TEMPO A SMARTCOOP ARMAZENARÁ SEUS
DADOS PESSOAIS</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:5.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>A SmartCoop manterá seus
dados pessoais até que você solicite a eliminação e/ou seja encerrado o
contrato de disponibilização do aplicativo, mantido com a Cooperativa. No caso
da solicitação da eliminação você não conseguirá utilizar o aplicativo
SmartCoop. </span></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Os dados pessoais poderão
ser mantidos pela SmartCoop quando necessário para o cumprimento de legislações
aplicáveis à relação mantida com você. Ex.: o protocolo IP será mantido por até
6 meses, após o registro.</span></p>

<p class=MsoNoSpacing style='margin-left:36.0pt;text-indent:20.7pt'><b><span
style='font-family:"Arial",sans-serif'>6)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>SEUS DIREITOS</span></b></p>

<p class=MsoNoSpacing style='margin-left:2.0cm'><b><span style='font-size:5.0pt;
font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNoSpacing style='margin-left:2.0cm;text-align:justify'><span
style='font-family:"Arial",sans-serif'>Em cumprimento ao previsto na LGPD, a
qualquer momento você pode solicitar que os direitos abaixo sejam atendidos:</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Confirmação de que
tratamos ou não seus dados pessoais;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Acesso aos seus
dados pessoais, caso sejam tratados por nós;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>c)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Correção de dados
pessoais incompletos, desatualizados ou inexatos;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>d)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Bloqueio,
anonimização ou eliminação de dados pessoais desnecessários ou que você
considere que sejam tratados de forma irregular;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>e)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Portabilidade dos
seus dados pessoais, quando aplicável;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Eliminação de dados
pessoais, ainda que tratados de forma regular;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>g)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Informações sobre o
compartilhamento dos seus dados com outras empresas ou com entidades/órgãos
públicos;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>h)<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Informações sobre as
consequências de não consentir com determinado tratamento de dados pessoais
(exemplo: quando você não aceita nossa configuração de cookies, tem direito de
saber quais as consequências de não aceitar); </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Revogação do
consentimento;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>j)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Oposição a qualquer
tratamento de dados que você considera irregular.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><span
style='font-size:6.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>7)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'>COMO ENTRAR EM CONTATO CONOSCO</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:5.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>Você poderá exercer seus
direitos, revogar o consentimento, solicitar informações, alteração,
esclarecimentos ou exclusão de seus dados pessoais através de contato com <b>privacidade@smart.coop.br</b>.</span></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-family:"Arial",sans-serif'>8)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PT
style='font-family:"Arial",sans-serif'> CONSENTIMENTO</span></b></p>

<p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=PT style='font-size:6.0pt;
line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:2.0cm;text-align:justify;line-height:
115%'><span style='font-family:"Arial",sans-serif'>É opcional utilizar ou não o
aplicativo SmartCoop. Caso assim você desejar e desde que tenha compreendido as
informações indicadas acima, será necessário consentir com o compartilhamento e
com o armazenamento do seu número de telefone em North Virginia. </span></p>

<p class=MsoNormal style='margin-left:106.2pt;text-indent:35.4pt;line-height:
115%'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

</div>

</body>

</html>
`)
