import first from 'lodash/first'
import includes from 'lodash/includes'
import split from 'lodash/split'

/* eslint-disable no-param-reassign */
export const uniqBy = (arr, key) => Object.values([...arr].reverse().reduce((m, i) => {m[key.split('.').reduce((a, p) => a?.[p], i)] = i; return m}, {}))
export const allEqual = arr => arr.every( v => v === arr[0] )
export const getBaseVolume = (value) => {
  let currentValue = value
  if(includes(value, '/')) {
    currentValue = first(split(value, '/'))
  }
  switch(currentValue) {
    case 'g':
    case 'kg':
    case 'mg':
      return 'kg'
    case 'l':
    case 'ml':
      return 'l'
    default:
      return currentValue
  }
}
export const getExtenseBaseVolume = (value) => {
  let currentValue = value
  if(includes(value, '/')) {
    currentValue = first(split(value, '/'))
  }
  switch(currentValue) {
    case 'g':
    case 'kg':
    case 'mg':
      return 'kg'
    case 'l':
    case 'ml':
      return 'litro'
    case 'ton':
      return 'ton'
    default:
      return null
  }
}
export const convertValues = (value, origin, expected) => {
  switch(expected) {
    case 'l':
      if(origin === 'ml' || origin === 'ml/ha') {
        return value/1000
      }
      return value
    case 'kg':
      if(origin === 'g' || origin === 'g/ha') {
        return value/1000
      }
      if(origin === 'mg' || origin === 'mg/ha') {
        return value/10000
      }
      return value
    default:
      return value
  }
}
