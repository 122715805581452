import React from 'react'

import PropTypes from 'prop-types'

import { useDairyFarmPriceChartOptions } from '@smartcoop/utils/charts'
import Chart from '@smartcoop/web-components/Chart'

const DairyFarmPriceChart = (props) => {

  const {
    shouldDisplayCosts
  } = props

  const chartOptions = useDairyFarmPriceChartOptions(shouldDisplayCosts)

  return (
    <div style={ { height: 300, width: '100%' } }>
      <Chart
        options={ chartOptions }
        containerProps={ { style: { height: 300 } } }
      />
    </div>
  )
}

DairyFarmPriceChart.propTypes = {
  shouldDisplayCosts: PropTypes.bool.isRequired
}

DairyFarmPriceChart.defaultProps = {
}


export default DairyFarmPriceChart
