import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

const filterAccreditments = () => Yup.object().shape({
  requestStatus: flow(
  )(Yup.string()),

  expirationDate: Yup.string().nullable(),

  technicianDocument: flow(
  )(Yup.string())
})

export default filterAccreditments
