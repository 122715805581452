import React, { useContext } from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'

import { ChatContext } from '../../../providers/ChatProvider'
import { formatChatDate } from '../../../utils/chatDateFormat'
import { getHighlightedText } from '../../../utils/chatHighlight'
import { Card, DateSpan, MessageContent, MessageSpan, NicknameSpan } from './styles'

const ConversationSearchMessage = ({ message, searchParams, openChat, conversationsNames, userId }) => {
  const t = useT()
  const { generateContactUuid } = useContext(ChatContext)

  const handleOpenChat = () => {
    openChat({
      conversationId: message?.conversationId,
      relationalId: generateContactUuid(message?.contactId),
      localMessageId: message.id,
      userId: message?.contactId || message?.conversationId
    })
  }

  return (
    <Card onClick={ handleOpenChat }>
      <MessageContent>
        <NicknameSpan>{conversationsNames[message.conversationId] || message.nickname}</NicknameSpan>
        <MessageSpan>
          {message.whoSend === userId ? `${ t('you') }: ` : ''}
          {getHighlightedText(message.message, searchParams)}
        </MessageSpan>
      </MessageContent>
      <DateSpan>{formatChatDate(message.sendAt, t)}</DateSpan>
    </Card>
  )
}

ConversationSearchMessage.propTypes = {
  message: PropTypes.object.isRequired,
  searchParams: PropTypes.string.isRequired,
  openChat: PropTypes.func.isRequired,
  conversationsNames: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired
}

export default ConversationSearchMessage
