import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${ colors.lightGrey };
  padding: 5px 15px;
`

export const ContactInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  align-items: center;
  column-gap: 5px;
`

export const ActionsWrapper = styled.div`
  display: flex;
  column-gap: 5px;
`

export const NameText = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${ colors.black };
  margin-left: 10px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  button {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
    font-size: .9rem;
  }
`

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
`

export const CheckboxLabel = styled.span`
  color: ${ colors.black };
  font-weight: 600;
  font-size: .9rem;
  cursor: pointer;
`
