import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import debounce from 'lodash/debounce'
import map from 'lodash/map'

import { ThemeProvider } from '@material-ui/core'

import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { ProductWallActions } from '@smartcoop/stores/productWall'
import { selectProductWallPosts } from '@smartcoop/stores/productWall/selectorProductWall'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import InputSearch from '@smartcoop/web-components/InputSearch/InputSearch'
import ProductWallFeed from '@smartcoop/web-components/ProductWallFeed'
import ProductWallPost from '@smartcoop/web-components/ProductWallPost'
import { useLayout } from '@smartcoop/web-containers/layouts/AuthenticatedLayout'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'


import { Container, inputSearchTheme, LabelFeed } from './styles'

const ProductWall = () => {
  const divRef = useRef(null)
  const loading = useRef(false)
  const t = useT()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const { mainRef } = useLayout()

  const [filterText, setFilterText] = useState('')
  const [nextPage, setNextPage] = useState(1)

  const posts = useSelector(selectProductWallPosts)
  const user = useSelector(selectUser)
  const currentOrganization = useSelector(selectCurrentOrganization)

  const loadPosts = useCallback(
    (page, next = nextPage) => {
      if (page && page <= next) {
        loading.current = true
        dispatch(ProductWallActions.loadProductWallPosts(
          { page },
          (paginationInfo) => {
            if (paginationInfo) {
              if (paginationInfo.page >= paginationInfo.totalPages) {
                setNextPage(0)
              } else {
                setNextPage(paginationInfo.page + 1)
              }
            } else {
              setNextPage(0)
            }
            loading.current = false
          },
          () => {
            loading.current = false
          }
        ))
      }
    },
    [dispatch, nextPage]
  )

  const onRefresh = useCallback(
    () => {
      setNextPage(1)
      loadPosts(1, 1)
    },
    [loadPosts]
  )

  const handleChangeSearchFilter = useCallback(
    (event) => {
      setFilterText(event.target.value)
    },
    []
  )

  const handleSubmitPost = useCallback(
    (data) => {
      dispatch(ProductWallActions.saveOfflineProductWallPost(
        data,
        () => {
          snackbar.success(t('your post was registered'))
          onRefresh()
        }
      ))
    },
    [dispatch, onRefresh, snackbar, t]
  )

  // infinity scroll => faz o request para pedir novas paginas
  // quando o usuário chega ao final da listagem já carregada
  const infinityScroll = useCallback(
    debounce(() => {
      if (divRef.current && nextPage && !loading.current) {
        const {
          bottom
        } = divRef.current.getBoundingClientRect()
        const distanceOfBottom = bottom - window.innerHeight

        if (distanceOfBottom < 10) {
          loadPosts(nextPage)
        }
      }
    }, 100),
    [
      divRef,
      loading,
      nextPage
    ]
  )

  useEffect(() => {
    const ref = mainRef.current
    ref.addEventListener('scroll', infinityScroll)
    return () => ref.removeEventListener('scroll', infinityScroll)
  }, [infinityScroll, mainRef])


  useEffect(() => {
    onRefresh()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SplitedScreenLayout
      withoutGoBack
      withoutDivider
      withoutTitle
      divRightStyle={ {
        backgroundColor: colors.white,
        width: '100%',
        height: '100%',
        padding: 0
      } }
      rightChildren={ (
        <Container ref={ divRef }>
          <ThemeProvider theme={ inputSearchTheme }>
            <InputSearch
              detached
              onChange={ handleChangeSearchFilter }
              value={ filterText }
              placeholder={ t('search') }
              fullWidth
              disabled
            />
          </ThemeProvider>

          <ProductWallPost
            user={ user }
            organization={ currentOrganization }
            onSubmit={ handleSubmitPost }
          />

          <LabelFeed>{t('product wall')}</LabelFeed>

          <div ref={ divRef }>
            {map(posts, post =>
              <ProductWallFeed key={ post.id } feed={ post } commentClick/>
            )}
          </div>
        </Container>
      ) }
      appendChildren={
        <></>
      }
    />
  )}

export default ProductWall
