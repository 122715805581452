

import styled from 'styled-components'

export const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: 20px;
  column-gap: 10px;
`

export const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 15px;

  > button {
    margin: 0 !important;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
  }
`
