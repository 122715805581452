import styled from 'styled-components'

export const ContainerList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`

export const PrinterText = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: bold;
`
