import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const CurrentImage = styled.img`
  border-radius: 8px;
  background-color: ${ colors.lightGrey };
  object-fit: contain;
  height: 50vh;
  width: 50vh;
`

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  margin-top: 20px;
`

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`

export const ThumbsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 60%;
`

export const CurrentImageContainer = styled.div`
  width: 55vh;
  height: 50vh;
`

export const Thumb = styled.div`
  border-radius: 8px;
  background: ${ colors.lightGrey };
  width: 120px;
  height: 120px;
  margin: 5px;
  cursor: pointer;
  border: 2px solid transparent;
  border-color: ${ props => props.isActive ? colors.yellow : 'transparent' };
  object-fit: contain;

`

export const ThumbImage = styled.img`
  border-radius: 8px;
  background-color: ${ colors.lightGrey };
  height:116px;
  width: 116px;
  object-fit: contain;
`
