import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import { TechnicalPortfolioStatusCode } from '@smartcoop/utils/constants'

import { Badge } from './styles'

const TechnicalPortfolioStatus = (props) => {
  const { statusNumber, style } = props

  const options = useMemo(() => {
    switch (statusNumber) {
      case TechnicalPortfolioStatusCode.AGUARDANDO_RESPOSTA:
        return {
          name: <I18n>waiting answer</I18n>,
          background: '(245, 125, 13, 0.1)',
          color: '(245, 125, 13, 1)'
        }
      case TechnicalPortfolioStatusCode.NEGADO:
        return {
          name: <I18n>denied</I18n>,
          background: '(216, 27, 96, 0.1)',
          color: '(216, 27, 96, 1)'
        }
      case TechnicalPortfolioStatusCode.REVOGADO:
        return {
          name: <I18n>revoked</I18n>,
          background: '(216, 27, 96, 0.1)',
          color: '(216, 27, 96, 1)'
        }
      case TechnicalPortfolioStatusCode.SOMENTE_ASSISTENCIA:
        return {
          name: <I18n>assistance only</I18n>,
          background: '(40, 159, 48, 0.1)',
          color: '(40, 159, 48, 1)'
        }
      case TechnicalPortfolioStatusCode.SOMENTE_VISUALIZAÇÃO:
        return {
          name: <I18n>view only</I18n>,
          background: '(40, 159, 48, 0.1)',
          color: '(40, 159, 48, 1)'
        }
      case TechnicalPortfolioStatusCode.VISUALIZAÇÃO_EDIÇÃO:
        return {
          name: <I18n>viewing and editing</I18n>,
          background: '(40, 159, 48, 0.1)',
          color: '(40, 159, 48, 1)'
        }
      default:
        return {
          name: <I18n>not requested</I18n>,
          background: '(89, 89, 89, 0.1)',
          color: '(89, 89, 89, 1)'
        }
    }
  }, [statusNumber])

  return (
    <Badge
      backgroundColorBadge={ options.background }
      colorBadge={ options.color }
      style={ style }
    >
      {options.name}
    </Badge>
  )
}

TechnicalPortfolioStatus.propTypes = {
  statusNumber: PropTypes.number,
  style: PropTypes.object
}

TechnicalPortfolioStatus.defaultProps = {
  style: {},
  statusNumber: null
}

export default TechnicalPortfolioStatus
