export default ({ size, color = '#000' } = {}) => /* html */`
<svg width="${ size }" height="${ size }" viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.3545 19.5362H27.4182C28.6777 19.5365 29.7684 18.6594 30.0419 17.4263L31.5355 10.6877C31.7119 9.88953 31.5179 9.05421 31.0078 8.41651C30.4978 7.77882 29.7269 7.4076 28.9118 7.40723H24.8624C24.0473 7.4076 23.2764 7.77882 22.7664 8.41651C22.2563 9.05421 22.0623 9.88953 22.2387 10.6877L23.7323 17.4263C24.0057 18.6589 25.0956 19.5358 26.3545 19.5362V19.5362Z" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.2163 9.62988H6.66305C3.33909 9.62988 1.12311 12.9637 1.12311 16.2974V31.855" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.2857 7.78582L31.0391 7.40799C31.6371 7.12416 32.0935 6.60772 32.3027 5.97831C32.5118 5.34889 32.4557 4.6611 32.1471 4.07422" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.7573 7.64506L23.2831 7.40799C22.6852 7.12416 22.2288 6.60772 22.0196 5.97831C21.8104 5.34889 21.8666 4.6611 22.1751 4.07422" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.3909 24.0771V31.8559" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.9589 31.8559V25.1883C19.9589 23.9609 18.9668 22.9658 17.7429 22.9658H7.77103C5.55505 22.9658 5.55505 25.1883 5.55505 26.2996V31.8559" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.419 22.9658C14.419 22.9658 13.311 27.4108 9.98701 27.4108H5.55505" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.98706 27.4102V29.6327" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.0455 25.7666L14.4194 28.521" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.2399 10.6895L19.9589 12.9653" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.6014 10.1953L34.3625 12.9646" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.9991 28.0694C38.0231 29.6766 37.1819 31.1722 35.798 31.9828C34.4141 32.7935 32.702 32.7935 31.3181 31.9828C29.9341 31.1722 29.093 29.6766 29.117 28.0694C29.117 24.2515 32.9236 19.7979 33.5576 19.7979C34.1915 19.7979 37.9991 24.2515 37.9991 28.0694Z" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M33.5551 29.5275C32.7306 29.5275 32.0623 28.8572 32.0623 28.0303" stroke="${ color }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`
