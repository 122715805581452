import { Database } from '@nozbe/watermelondb'
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs'

import { models } from './models'
import { databaseSchema } from './schemas'

const adapter = new LokiJSAdapter({
  dbName: 'database',
  version: 4,
  schema: databaseSchema,
  useWebWorker: false,
  useIncrementalIndexedDB: true
})

export const database = new Database({
  adapter,
  modelClasses: [...models],
  actionsEnabled: true
})
