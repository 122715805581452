import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  border-right: 1px solid #cbcbcb;
`

export const ListContainer = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  max-height: calc(100vh - 160px);
`

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
