// eslint-disable-next-line spaced-comment
export default ({ size, color = '#1D1D1B' } = {}) => /*html*/`
<svg width="${ size }" height="${ size }" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.8397 31.6352H11.1113V21.4076C11.1113 20.8489 11.5657 20.3945 12.1244 20.3945H17.8267C18.3853 20.3945 18.8397 20.8489 18.8397 21.4076V31.6352Z" fill="${ color }"/>
<path d="M23.5596 11.0871C23.5596 11.8246 22.9599 12.4242 22.2225 12.4242H14.9895C14.252 12.4242 13.6523 11.8246 13.6523 11.0871C13.6523 10.3496 14.252 9.75 14.9895 9.75H22.2225C22.9599 9.75 23.5596 10.3496 23.5596 11.0871Z" fill="${ color }"/>
<path d="M15.2866 10.9684C15.6926 11.5829 15.525 12.4135 14.9067 12.8232L8.87297 16.8122C8.25843 17.2181 7.42786 17.0505 7.01816 16.4323C6.61219 15.8177 6.7798 14.9872 7.39807 14.5775L13.4318 10.5885C14.0501 10.1825 14.8806 10.3501 15.2866 10.9684Z" fill="${ color }"/>
<path d="M9.62748 6.69078C10.1042 6.12465 10.946 6.05389 11.5121 6.53063L16.3763 10.6239C16.9424 11.1006 17.0132 11.9424 16.5365 12.5085C16.0597 13.0746 15.218 13.1454 14.6519 12.6686L9.78763 8.57539C9.22523 8.09865 9.15446 7.25691 9.62748 6.69078Z" fill="${ color }"/>
<path d="M14.9522 22.0609C13.9689 22.0609 13.1719 21.2639 13.1719 20.2806V12.6006C13.1719 11.6174 13.9689 10.8203 14.9522 10.8203C15.9355 10.8203 16.7325 11.6174 16.7325 12.6006V20.2806C16.7325 21.2639 15.9355 22.0609 14.9522 22.0609Z" fill="${ color }"/>
<path d="M7.4921 8.76936C9.63755 8.76936 11.3768 7.03013 11.3768 4.88468C11.3768 2.73923 9.63755 1 7.4921 1C5.34665 1 3.60742 2.73923 3.60742 4.88468C3.60742 7.03013 5.34665 8.76936 7.4921 8.76936Z" fill="${ color }" stroke="#FFFDFD" stroke-width="1.257" stroke-miterlimit="10"/>
<path d="M26.1151 15.0975C28.2606 15.0975 29.9998 13.3583 29.9998 11.2128C29.9998 9.06735 28.2606 7.32812 26.1151 7.32812C23.9697 7.32812 22.2305 9.06735 22.2305 11.2128C22.2305 13.3583 23.9697 15.0975 26.1151 15.0975Z" fill="${ color }" stroke="#FFFDFD" stroke-width="1.257" stroke-miterlimit="10"/>
<path d="M4.88468 21.8045C7.03013 21.8045 8.76936 20.0653 8.76936 17.9198C8.76936 15.7744 7.03013 14.0352 4.88468 14.0352C2.73923 14.0352 1 15.7744 1 17.9198C1 20.0653 2.73923 21.8045 4.88468 21.8045Z" fill="${ color }" stroke="#FFFDFD" stroke-width="1.257" stroke-miterlimit="10"/>
</svg>

`
