import * as Yup from 'yup'

import dateRange from '@smartcoop/forms/validators/dateRange.validator'

const filterRainRecord = ({ t }) => Yup.object().shape({

  fieldId: Yup.string(),

  rangeDate: dateRange({ t })(Yup.object())
})

export default filterRainRecord
