import api from '../api'

export const getCropsManagements = async (params, { growingSeasonId }) => {
  const { data } = await api.get(`/v1/growing-season/${ growingSeasonId }/crops-management`, { params })
  return data
}

export const getCropManagement = async (params, { growingSeasonId, cropManagementId }) => api.get(`/v1/growing-season/${ growingSeasonId }/crops-management/${ cropManagementId }`, { params })

export const createCropManagement = (params) => api.post('/v2/growing-season/crops-management', params)

export const addManagementFiles = (params, { managementId }) =>
  api.post(`/v2/growing-season/crops-management/${ managementId }/file`, params, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

export const editManagementFiles = (params, { managementId }) =>
  api.put(`/v2/growing-season/crops-management/${ managementId }/file`, params)


export const editCropManagement = (params) => api.put('/v2/growing-season/crops-management', params)

export const deleteCropManagements = ({ growingSeasonId, cropManagementId }) => api.delete(`/v1/growing-season/${ growingSeasonId }/crops-management/${ cropManagementId }`)


