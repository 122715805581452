import styled from 'styled-components'

import I18n from '@smartcoop/i18n'
import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export const Container = styled.td`
  display: flex;
  justify-content: ${ props => props.showLastRequestDate ? 'space-between' : 'flex-end' };
`

export const LastRequestContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: ${ colors.darkGrey };
  font-style: italic;
`

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
`

export const NumberContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const UnselectedPage = styled.button`
  font-weight: bold !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${ fonts.fontWeight.bold };
  color: ${ colors.darkGrey };
  height: 25px;
  width: 25px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  margin: 0 3px;
`

export const SelectedPage = styled(UnselectedPage)`
  background-color: ${ colors.black };
  color: ${ colors.white };
  cursor: auto;
`
export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const ItensPerPageText = styled(I18n)`
  color: ${ colors.darkGrey };
  padding-left: 6px;
`
