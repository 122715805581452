
import React, { useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import padStart from 'lodash/padStart'

import { useDialog } from '@smartcoop/dialog'
import I18n from '@smartcoop/i18n'
import { arrowDownField, arrowUpField } from '@smartcoop/icons'
import * as weatherIcons  from '@smartcoop/icons/weatherIcons'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { WeatherForecastActions } from '@smartcoop/stores/weatherForecast'
import { selectCurrent, selectNextHour } from '@smartcoop/stores/weatherForecast/selectorWeatherForecast'
import colors from '@smartcoop/styles/colors'
import {
  momentBackDateTimeFormat,
  momentFriendlyExtendedDateFormat
} from '@smartcoop/utils/dates'
import Icon from '@smartcoop/web-components/Icon'
import WeatherForecastModal from '@smartcoop/web-containers/modals/WeatherForecastModal'

import {
  CardContainer,
  CardContent,
  CardButton,
  AverageContainer,
  AverageNumberContainer,
  AverageNumber,
  AverageType,
  Description,
  Title,
  Row,
  MinMaxText,
  DateText
} from './styles'

const WeatherForecastCard = ({ customCoordinates }) => {

  const current = useSelector(selectCurrent)
  const nextHours = useSelector(selectNextHour)
  const currentProperty = useSelector(selectCurrentProperty)
  const { createDialog } = useDialog()

  const dispatch = useCallback(useDispatch(), [])

  const openModal = useCallback(
    () => createDialog({
      id: 'user-not-found',
      Component: WeatherForecastModal,
      props: {}
    }),
    [createDialog]
  )

  const coordinates = useMemo(
    () => ({
      latitude: isEmpty(customCoordinates)  ?  currentProperty?.geolocalization?.latitude : customCoordinates.latitude,
      longitude: isEmpty(customCoordinates) ?  currentProperty?.geolocalization?.longitude : customCoordinates.longitude
    }), [currentProperty, customCoordinates]
  )

  const currentDate = useMemo(
    () => moment(current?.date, momentBackDateTimeFormat)
      .format(momentFriendlyExtendedDateFormat),
    [current]
  )

  useEffect(() => {
    if (!isEmpty(currentProperty?.geolocalization)) {
      dispatch(WeatherForecastActions.searchCurrentWeather(coordinates.latitude, coordinates.longitude))
      dispatch(
        WeatherForecastActions.searchWeatherForecast(coordinates.latitude, coordinates.longitude)
      )
    }
  }, [dispatch, currentProperty, coordinates])

  if (!current) {
    return null
  }

  return (
    <CardContainer>
      <CardContent>
        <Title>
          <I18n>weather forecast</I18n>
        </Title>
        <DateText>{ currentDate }</DateText>
        <Row center style={ { marginTop: 5 } }>
          <MinMaxText>{ current?.high }°C</MinMaxText>
          <Icon
            icon={ arrowUpField }
            size={ 10 }
            color={ colors.orange }
            style={ { marginLeft: 10, marginRight: 10 } }
          />
          <MinMaxText>{ current?.low }°C</MinMaxText>
          <Icon
            icon={ arrowDownField }
            size={ 10 }
            color={ colors.blue }
            style={ { marginLeft: 10 } }
          />
        </Row>
        <AverageContainer>
          <Icon
            icon={ weatherIcons[`icon${ padStart(current.iconCode, 2, '0') }`] }
            size={ 60 }
          />
          <AverageNumberContainer>
            <AverageNumber>{ !isEmpty(nextHours) ? Math.round(nextHours[0]?.temperature) : '-' }</AverageNumber>
            <AverageType>°C</AverageType>
          </AverageNumberContainer>
        </AverageContainer>
        <Description />
      </CardContent>
      <CardButton onClick={ openModal }>
        <I18n>view more</I18n>
      </CardButton>
    </CardContainer>
  )
}

WeatherForecastCard.propTypes = {
  customCoordinates: PropTypes.shape({
    latitude: PropTypes.any,
    longitude: PropTypes.any
  })
}

WeatherForecastCard.defaultProps = {
  customCoordinates: null
}


export default WeatherForecastCard
