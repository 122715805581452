import { colors, hexToRgba } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'

export default {
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.secondary
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.primary
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: fonts.fontFamilySans,
    fontWeight: 400,
    color: colors.text
  },
  overrides: {
    MuiTooltip: {
      popper: {
        zIndex: 5000
      },
      tooltip: {
        margin: '3px !important',
        backgroundColor: colors.primary,
        color: colors.white,
        border: `1px solid ${ hexToRgba(colors.black, 0.14) }`,
        fontSize: 12,
        letterSpacing: 1
      }
    },
    MuiCard: {
      root: {
        boxShadow: `0 1px 4px 0 ${ hexToRgba(colors.black, 0.14) }`
      }
    },
    MuiFab: {
      extended: {
        padding: '0 25px',
        '&$sizeSmall': {
          padding: '0 18px 0 8px'
        }
      }
    },
    MuiLink: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiList: {
      root: {
        width: '100%',
        '& > a': {
          color: colors.white
        }
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: hexToRgba(colors.white, 0.5)
        }
      }
    },
    MuiListItemIcon: {
      root: {
        display: 'flex',
        alignItems: 'center',
        color: colors.primary,
        '& > a': {
          color: colors.primary
        },
        '& > *': {
          display: 'flex'
        }
      }
    },
    MuiListItemText: {
      root: {
        color: colors.primary,
        '& > a': {
          color: colors.primary
        }
      }
    },
    MuiStepper: {
      root: {
        padding: 0,
        background: 'transparent'
      }
    },
    MuiGrid: {
      container: {
        flex: 1
      },
      item: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important'
      }
    },
    MuiChip: {
      label: {
        '& > div': {
          display: 'flex'
        }
      }
    },
    MuiTab: {
      wrapper: {
        '& > *': {
          '&:first-child': {
            marginBottom: '0 !important',
            marginTop: 6
          }
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.9rem'
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.02) !important'
      }
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16
        }
      }
    },
    MuiDivider: {
      root: {
        width: '100%',
        margin: '10px 0'
      }
    },
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: 0
      }
    }
  }
}
