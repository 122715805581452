import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const AccountTitle = styled.p `
  font-size: 20px;
  margin: 0;
`

export const ContentName = styled.div `
  display: flex;
  font-weight: 600;
  color: ${ colors.darkGrey };
  margin: 0;
`

export const ContentBalance = styled.div `
  font-size: 22px;
  font-weight: 600;
  padding-left: 25px;
`

export const HideBalance = styled.div `
  background-color: ${ colors.grey };
  border-radius: 5px;
  margin-top: 6px;
  width: 166px;
  height: 25px;
`

export const TextBalance = styled.p `
  font-size: 22px;
  margin: 0;
  padding-left: 3px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const CreditLimitLabel = styled.p`
  color: ${ colors.darkGrey };
  font-size: 14px;
  margin: 0;
  margin-top: 10px;
`

export const CreditLimit = styled.p`
  color: ${ colors.black };
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`