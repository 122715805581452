import { createActions, createReducer } from 'reduxsauce'

const INITIAL_STATE = {
  absences: [],
  error: null
}

const { Types, Creators } = createActions({
  createAbsence: ['newAbsence', 'onSuccess'],
  loadAbsences: ['onSuccess', 'onError'],
  loadAbsencesSuccess: ['absences'],
  editAbsence: ['id', 'params', 'onSuccess', 'onError'],
  deleteAbsence: ['id', 'onError'],
  absenceError: ['error']
})

const loadAbsencesSuccess = (state = INITIAL_STATE, { absences }) => ({
  ...state,
  error: INITIAL_STATE.error,
  absences
})

const absenceError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

export default createReducer(INITIAL_STATE, {
  [Types.LOAD_ABSENCES_SUCCESS]: loadAbsencesSuccess,
  [Types.ABSENCE_ERROR]: absenceError
})

export {
  Types as AbsenceTypes,
  Creators as AbsenceActions
}