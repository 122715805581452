import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'

import I18n from '@smartcoop/i18n'
import colors from '@smartcoop/styles/colors'
import GoBackIconButton from '@smartcoop/web-components/IconButton/GoBackIconButton'
import AccreditmentList from '@smartcoop/web-containers/fragments/technical/AccreditmentList/AccreditmentList'
import { Title } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import {
  Container,
  UpperRowContainer
} from './styles'

const AccreditmentListScreen = () => {
  const history = useHistory()


  const accreditmentsTableRef = useRef()

  return (
    <Container>
      <UpperRowContainer>
        <GoBackIconButton onClick={ () => history.push('/technical', { fromAccreditments: true }) } iconColor={ colors.primary } />
        <Title>
          <I18n>accreditments</I18n>
        </Title>
      </UpperRowContainer>

      <div>
        <AccreditmentList ref={ accreditmentsTableRef }/>
      </div>
    </Container>
  )
}

export default AccreditmentListScreen
