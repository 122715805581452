import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'



import I18n, { useT } from '@smartcoop/i18n'
import { notFound } from '@smartcoop/icons'
import { AuthenticationActions } from '@smartcoop/stores/authentication'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import Modal from '@smartcoop/web-components/Modal'

import {
  Container,
  Content,
  Buttons
} from './styles'

const AcceptTermModalConfirm = (props) => {
  const {
    id,
    open,
    handleClose,
    onWillClose,
    onCancel
  } = props

  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const onClose = useCallback(
    () => {
      onWillClose()
      handleClose()
    }, [handleClose, onWillClose]
  )

  const cancel = useCallback(
    () => {
      dispatch(AuthenticationActions.logout())
      onCancel()
    }, [dispatch, onCancel]
  )

  const onSubmit = useCallback(
    () => {
      onClose()
    }, [onClose]
  )


  return (
    <Modal
      id={ id }
      open={ open }
      escape={ false }
      title={ t('attention') }
      disableFullScreen
      maxWidth="md"
      style={ { zIndex: 999999 } }
    >
      <Container>
        <Content>
          <Content>
            <Icon icon={ notFound } size={ 125 } style={ { padding: 15 } } />
            <I18n>
            you need to accept the term to proceed
            </I18n>
          </Content>
        </Content>
        <Buttons>
          <Button
            id="cancel-button"
            onClick={ cancel }
            style={ { flex: 1 } }
            variant="outlined"
          >
            <I18n>logout</I18n>
          </Button>
          <Button
            id="confirm-button"
            onClick={ onSubmit }
            style={ { flex: 1 } }
          >
            <I18n>confirm</I18n>
          </Button>
        </Buttons>
      </Container>
    </Modal>
  )
}

AcceptTermModalConfirm.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onWillClose: PropTypes.func,
  onCancel: PropTypes.func
}

AcceptTermModalConfirm.defaultProps = {
  onWillClose: () => {},
  onCancel: () => {}
}



export default AcceptTermModalConfirm
