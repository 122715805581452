import React, { useRef, useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { OrganizationActions } from '@smartcoop/stores/organization'
import Loader from '@smartcoop/web-components/Loader'
import EditSupplierForm from '@smartcoop/web-containers/forms/shoppingPlatform/supplier/EditSupplierForm'


const EditSupplierScreen = () => {
  const createOrganizationRef = useRef(null)

  const history = useHistory()
  const params = useParams()
  const dispatch = useCallback(useDispatch(), [])

  const { organizationId } = params

  const [isLoading, setIsLoading] = useState(true)
  const [supplier, setSupplier] = useState({})

  const onCancel = useCallback(() => history.goBack(), [history])
  const onSuccess = useCallback(() => history.goBack(), [history]
  )

  const mapSupplierObject = (supplierObject) => {
    const productArray = []
    supplierObject.products.forEach((item) => {
      productArray.push(item.productId)
    } )

    const obj = { ...supplierObject,
      phone: supplierObject?.organizationPhones.length > 0 ? supplierObject?.organizationPhones[0].phone : '',
      email: supplierObject?.organizationEmails.length > 0 ? supplierObject?.organizationEmails[0].email : '',
      cep: supplierObject.postalCode,
      neighborhood: supplierObject.district,
      number: supplierObject.number.toString(),
      products: productArray
    }

    return obj
  }
  const getSupplier = useCallback(() => {
    setIsLoading(true)
    dispatch(
      OrganizationActions.loadOrganization(
        {},
        organizationId,
        (data) => {
          setSupplier(mapSupplierObject(data))
          setIsLoading(false)},
        () => {setIsLoading(false)}
      ))
  },[dispatch, organizationId])

  useEffect(() => {
    getSupplier()
  },[getSupplier])

  return (
    isLoading ? <Loader width={ 100 } /> :
      <EditSupplierForm
        ref={ createOrganizationRef }
        onSuccess={ onSuccess }
        onCancel={ onCancel }
        supplier={ supplier }
      />
  )
}

export default EditSupplierScreen
