import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import isNull from 'lodash/isNull'

import { useT } from '@smartcoop/i18n'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'


const LoadingModal = ({ id, open, message }) => {
  const t = useT()

  const defaultMessage = useMemo(
    () => isNull(message) ? t('validating your data...') : message, [message, t]
  )
  return (
    <Modal
      id={ id }
      open={ open }
      escape={ false }
      hideHeader
    >
      <Loader message={ defaultMessage } />
    </Modal>

  )
}

LoadingModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.func
}

LoadingModal.defaultProps = {
  message: null
}

export default LoadingModal
