import React from 'react'

import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import { Container, Title, Top, Text } from './styles'

const ObservationBox = ({ icon, title, style, iconProps, titleStyle, children }) => (
  <Container style={ style } paddingNone={ !title }>
    { !!icon && !!title && <Top>
      {!!icon && <Icon icon={ icon } { ...iconProps } />}
      {!!title && <Title style={ titleStyle }>
        {title}
      </Title>}
    </Top>}
    {!isEmpty(children) && <Text hasChildren={ !isEmpty(children) }>
      {children}
    </Text>}
  </Container>
)

ObservationBox.propTypes = {
  icon: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  titleStyle: PropTypes.object,
  iconProps: PropTypes.object
}

ObservationBox.defaultProps = {
  icon: null,
  title: '',
  style: {},
  iconProps: {
    size: 16,
    color: colors.yellow
  },
  titleStyle: {},
  children: null
}

export default ObservationBox
