import React, { useState, useMemo, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import withObservables from '@nozbe/with-observables'
import moment from 'moment/moment'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import round from 'lodash/round'
import toString from 'lodash/toString'

import { animalService } from '@smartcoop/database/services/animalService'
import { database } from '@smartcoop/database/web-database'
import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { arrowLeft, filter } from '@smartcoop/icons'
import { getAnimalWeightList } from '@smartcoop/services/apis/smartcoopApi/resources/herdsManagement'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import InputSearch from '@smartcoop/web-components/InputSearch'
import Loader from '@smartcoop/web-components/Loader'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import FilterWeightControlModal from '@smartcoop/web-containers/modals/dairyFarm/FilterWeightControlModal'
import ImportWeightControlModal from '@smartcoop/web-containers/modals/dairyFarm/ImportWeightControlModal'
import WeightControlModal from '@smartcoop/web-containers/modals/dairyFarm/WeightControlModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { Top, Container, Row, Title, SingleButtonContainer } from './styles'

const ListWeightControlScreen = ({ animals }) => {
  const dispatch = useCallback(useDispatch(), [])
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()
  const tableRef = useRef(null)
  const history = useHistory()
  const t = useT()
  const { routes } = useRoutes()

  const currentProperty = useSelector(selectCurrentProperty)
  const userWrite = useSelector(selectUserCanWrite)

  const [filters, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [filterText, setFilterText] = useState('')
  const [debouncedFilterText, setDebouncedFilterText] = useState('')

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )

  const columns = useMemo(
    () => [
      {
        title: t('earring', { howMany: 1 }),
        field: 'animalId',
        render: (row) => {
          const found = find(animals, item => item?.animalId === row?.animalId)
          return found?.earringCode || ' - '
        },
        type: 'numeric',
        sorting: true
      },
      {
        title: t('date', { howMany: 1 }),
        field: 'date',
        render: (row) => moment(row?.date, momentBackDateFormat).format(momentFriendlyDateFormat),
        sorting: true,
        defaultSort: 'desc'
      },
      {
        title: `${ t('weight') } (Kg)`,
        field: 'weight',
        render: row => row?.weight
      },
      {
        title: `${ t('height') } (cm)`,
        field: 'height',
        render: row => row?.height || '-'
      },
      {
        title: 'GMD',
        field: 'gmd',
        render: (row) =>  !isEmpty(toString(row?.gmd)) ? round(row?.gmd, 1) : ' - '
      },
      {
        title: 'GPD',
        field: 'gpd',
        render: (row) => !isEmpty(toString(row?.gpd)) ? round(row?.gpd, 1) : ' - '
      }
    ], [animals, t]
  )
  const registerControl = useCallback(
    (data = {}) => {
      createDialog({
        id: 'register-control',
        Component: WeightControlModal,
        props: {
          defaultValues: data,
          onSubmit: reloadDataTable
        }
      })
    },
    [createDialog, reloadDataTable]
  )

  const filterData = useCallback(
    (data) => {
      setFilters(data)
    }, []
  )

  const filterWeightControl = useCallback(
    () => {
      createDialog({
        id: 'filter-control',
        Component: FilterWeightControlModal,
        props: {
          onSubmit: filterData,
          filters
        }
      })
    },
    [createDialog, filterData, filters]
  )

  const importWeightControl = useCallback(
    () => {
      createDialog({
        id: 'filter-control',
        Component: ImportWeightControlModal,
        props: {
          reloadDataTable,
          propertyId: currentProperty?.id
        }
      })
    },
    [createDialog, currentProperty, reloadDataTable]
  )

  const onEditClick = useCallback(
    (_, data) => registerControl(data),
    [registerControl]
  )

  const handleMilControlDelete = useCallback(
    (data) => {
      createDialog({
        id: 'confirm-delete-milk-control',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            setIsLoading(true)
            dispatch(AnimalActions.deleteAnimalWeight(data?.id,
              () => {
                setIsLoading(false)
                reloadDataTable()
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'male',
                    this: t('weight control')
                  })
                )
              },
              () => {
                setIsLoading(false)
                reloadDataTable()
              }
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            gender: 'male',
            howMany: 1,
            this: t('weight control')
          })
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, snackbar, t]
  )

  const onDeleteClick = useCallback(
    (_, data) => handleMilControlDelete(data),
    [handleMilControlDelete]
  )

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )

  const tableFilters = useMemo(
    () =>
      debouncedFilterText
        ? { ...filters, q: debouncedFilterText, startDate: filters?.date?.from, endDate: filters?.date?.to, propertyId: currentProperty?.id }
        : { ...filters, startDate: filters?.date?.from, endDate: filters?.date?.to, propertyId: currentProperty?.id },
    [currentProperty.id, debouncedFilterText, filters]
  )

  return (
    isLoading ? <Loader width={ 100 } /> :
      <Container>
        <Top>
          <IconButton
            tooltip={ t('go back') }
            onClick={ () => {
              dispatch(DairyFarmActions.setCurrentSection('cattleManagement'))
              history.push(routes.dairyFarmDashboard.path)
            } }
          >
            <Icon icon={ arrowLeft } size={ 16 } />
          </IconButton>
          <Title>
            <I18n>weight control</I18n>
          </Title>
        </Top>
        <Row>
          <SingleButtonContainer>
            <InputSearch
              detached
              onChange={ onChangeSearchFilter }
              value={ filterText }
              placeholder={ t('search') }
              style={ { marginRight: 10, backgroundColor: '#fff', marginBottom: -4 } }
            />
            <Button
              id="filter"
              color="white"
              style={ {
                flex: 'none',
                whiteSpace: 'nowrap',
                marginRight: 10,
                backgroundColor: isEmpty(filters) ? colors.white : colors.secondary
              } }
              onClick={ filterWeightControl }
            >
              <>
                <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 }/>
                <I18n>filtrate</I18n>
              </>
            </Button>
            <Button
              id="new-weight-control"
              color="secondary"
              style={ { flex: 'none', whiteSpace: 'nowrap' } }
              onClick={ () => registerControl() }
              disabled={ !userWrite }
            >
              <I18n>new registry</I18n>
            </Button>
            <Button
              id="import-weight-control"
              color="black"
              style={ { flex: 'none', marginLeft: 10, whiteSpace: 'nowrap' } }
              onClick={ importWeightControl }
              disabled={ !userWrite }
            >
              <I18n>sheet</I18n>
            </Button>
          </SingleButtonContainer>
        </Row>

        <DataTable
          tableRef={ tableRef }
          data={ getAnimalWeightList }
          columns={ columns }
          queryParams={ tableFilters }
          onEditClick={ userWrite ? onEditClick : null }
          onDeleteClick={ userWrite ? onDeleteClick : null }
          id="list-milk-delivery-table"
        />
      </Container>
  )
}
ListWeightControlScreen.propTypes = {
  animals: PropTypes.array
}

ListWeightControlScreen.defaultProps = {
  animals: []
}

const enhance = withObservables([], () => ({
  animals: animalService(database).observeAnimals()
}))

const EnhancedListWeightControlScreen = enhance(ListWeightControlScreen)

export default EnhancedListWeightControlScreen
