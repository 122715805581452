import React, { useMemo } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import {
  speaker,
  pause
} from '@smartcoop/icons'
import colors from '@smartcoop/styles/colors'
import { momentBackDateTimeFormat, momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import Icon from '@smartcoop/web-components/Icon'
import Loader from '@smartcoop/web-components/Loader'

import {
  AudioPlayer,
  Card,
  CardDetail,
  DateSpan,
  Line,
  LineWrapper,
  TextContainer
} from './styles'

const IAMessageItem = ({
  message,
  speakText,
  setSpeaking,
  speaking,
  pauseSpeaking,
  resumeSpeaking,
  updateMessageWithAudioStatus
}) => {
  const t = useT()

  const receiver = useMemo(
    () => message?.receiver,
    [message]
  )


  const messageContent = useMemo(() => {

    if (message?.audio) {
      return (
        <AudioPlayer receiver={ receiver } src={ URL.createObjectURL(message?.audio) } preload="auto" controls>
          {t('datagrid fetch load error')}
        </AudioPlayer>
      )
    }

    return  <TextContainer>{message?.message}</TextContainer>
  }, [message, receiver, t])

  return (
    <LineWrapper>
      <Line receiver={ receiver } id={ message.id }>
        <Card receiver={ receiver }>
          {message.sendAt && (
            <CardDetail>
              <DateSpan> {moment(message.sendAt, momentBackDateTimeFormat).format(momentFriendlyDateTimeFormat)}</DateSpan>
              <div style={ { marginLeft: 10, display: receiver ? 'flex' : 'none' } }>
                {message?.id === speaking ? (
                  <Loader width={ 20 } style={ { padding: 0 } }/>
                ) : (
                  <Icon
                    icon={ message?.isAudioPlaying ? pause : speaker }
                    color={ colors.mediumGrey }
                    size={ 20 }
                    style={ { cursor: 'pointer' } }
                    onClick={ () => {
                      if (message?.isAudioPlaying) {
                        pauseSpeaking(message?.message)
                        updateMessageWithAudioStatus(message?.message, false)
                      } else if (message?.isAudioPaused) {
                        resumeSpeaking(message?.message)
                        updateMessageWithAudioStatus(message?.message, true)
                      } else {
                        setSpeaking(message?.id)
                        speakText(message?.message)
                        updateMessageWithAudioStatus(message?.message, true)
                      }
                    } }
                  />
                )}
              </div>
            </CardDetail>
          )}
          {messageContent}
        </Card>
      </Line>
    </LineWrapper>
  )
}

IAMessageItem.propTypes = {
  message: PropTypes.object.isRequired,
  speakText: PropTypes.func,
  setSpeaking: PropTypes.func,
  speaking: PropTypes.string.isRequired,
  pauseSpeaking: PropTypes.func,
  resumeSpeaking: PropTypes.func,
  updateMessageWithAudioStatus: PropTypes.func
}

IAMessageItem.defaultProps = {
  speakText: () => {},
  setSpeaking: () => {},
  pauseSpeaking: () => {},
  resumeSpeaking: () => {},
  updateMessageWithAudioStatus: () => {}
}

export default IAMessageItem
