import * as Yup from 'yup'

const filterMilkDelivery = () => Yup.object().shape({
  userStateRegistrationId: Yup.string(),

  volumeDate: Yup.object().nullable(),

  status: Yup.string()
})

export default filterMilkDelivery
