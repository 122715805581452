import React, { useMemo, useCallback, useRef } from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import I18n from '@smartcoop/i18n'
import Modal from '@smartcoop/web-components/Modal'
import MilkDeliveryForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/MilkDeliveryForm/MilkDeliveryForm'

import useStyles, { Content } from './styles'

const MilkDeliveryModal = ({ id, open, handleClose, onCancel, dairyFarm }) => {
  const classes = useStyles()
  const milkDeliveryFormRef = useRef(null)

  const isCooperative = useMemo(
    () => {
      if (isEmpty(dairyFarm)) {
        return true
      }
      return !isEmpty(dairyFarm?.organizationId)
    },
    [dairyFarm]
  )

  const defaultValues = useMemo(
    () => (
      {
        ...dairyFarm,
        isCooperative,
        companyName: dairyFarm?.companyName ?? '',
        organizationId: dairyFarm?.organizationId ?? '',
        stateRegistrationId: dairyFarm?.stateRegistrationId ?? []
      }
    ), [dairyFarm, isCooperative]
  )

  const clearForm = useCallback(
    () => {
      milkDeliveryFormRef.current.reset()
      handleClose()
      onCancel()
    },
    [handleClose, onCancel]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <I18n>tell who you deliver your milk production to</I18n>
      }
      disableFullScreen
      escape={ false }
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title
      } }
    >
      <Content>
        <MilkDeliveryForm
          ref={ milkDeliveryFormRef }
          handleClose={ handleClose }
          clearForm={ clearForm }
          defaultValues={ defaultValues }
        />
      </Content>
    </Modal>
  )}

MilkDeliveryModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dairyFarm: PropTypes.object
}

MilkDeliveryModal.defaultProps = {
  dairyFarm: {}
}

export default MilkDeliveryModal
