import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const RowHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  width: 100%;
  border-bottom-left-radius: 45px;
  border-bottom-right-radius: 45px;
  margin-right: 10px;
  margin-left: 10px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0,0,0,0.3);
  border-right: 1px solid rgba(0,0,0,0.2);
  border-left: 1px solid rgba(0,0,0,0.2);
`

export const CardContainer = styled.iframe`
  position: relative;
  margin: 20px;
  padding: 20px;
  border-color: rgba(0,0,0,0.1);
  border-style:outset;
  width: 96%;
  height: ${ props => props?.height };
  background: #fff;
  page-break-after: always;
`

export const CardDate = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  background: #fff;
  line-height: 0.3em;
  font-size: 16px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`
export const Title = styled.span`
  margin-right: 10px;
  font-weight: bold;
  width: 120px;
`

export const BiggerTitle = styled.span`
  font-weight: bold;
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 20px;
`

export const Value = styled.span`
`

export const SignatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Divisor = styled.hr`
  border: none;
  border-bottom: 1px solid ${ colors.secondary };
`
