import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty  from 'lodash/isEmpty'
import map from 'lodash/map'

import { useT } from '@smartcoop/i18n'
import { TechnicalActions } from '@smartcoop/stores/technical'
import {  selectEvaluationProducers, selectTechnicalForms } from '@smartcoop/stores/technical/selectorTechnical'
import { momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import InputSelect from '@smartcoop/web-components/InputSelect'
import Loader from '@smartcoop/web-components/Loader'

import { Badge, Container } from './styles'

const TechnicalFormsListScreen = ({ setProducer, producer, currentForm, defaultForm, defaultProducer }) => {
  const t = useT()
  const dispatch = useDispatch()

  const technicalForms = useSelector(selectTechnicalForms)
  const producersOptions = useSelector(selectEvaluationProducers)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(TechnicalActions.loadTechnicalForms())

    return () => {
      dispatch(TechnicalActions.resetCurrentTechnicalForm())
    }
  },[defaultForm, dispatch])

  const updateForm = useCallback((form) => {
    dispatch(TechnicalActions.setCurrentTechnicalForm(form))
  }, [dispatch])

  const handleSelectForm = useCallback((form) => {
    if(!isEmpty(currentForm)) {
      dispatch(TechnicalActions.resetCurrentTechnicalForm())
      setTimeout(() => {
        dispatch(TechnicalActions.setCurrentTechnicalForm(form))
      }, 100)
    } else {
      updateForm(form)
    }
  }, [currentForm, dispatch, updateForm])

  useEffect(() => {
    if(!isEmpty(defaultForm)) {
      const selected = find(technicalForms, item => item?.modelSlug === defaultForm)
      updateForm(selected)
      dispatch(TechnicalActions.loadTechnicalEvaluationProducers({ quizId: selected?.id }, () => setLoading(false), () => setLoading(false)))
    }
  },[defaultForm, dispatch, technicalForms, updateForm])

  return (
    <Container>
      <InputSelect
        label={ t('form') }
        detached
        options={ map(filter(technicalForms, item => item?.modelSlug === (defaultForm || 'pqfl')), item => ({ value: item?.id, label: item?.name, item })) }
        onChange={ (val, item) => {
          handleSelectForm(item?.item)
          dispatch(TechnicalActions.loadTechnicalEvaluationProducers({ quizId: item?.id }, () => setLoading(false), () => setLoading(false)))
        } }
        value={ currentForm?.id }
        disabled={ defaultForm }
      />
      {currentForm?.proprietaryRequired && (
        <>
          {isEmpty(producersOptions) ? (
            <p style={ { alignSelf: 'center' } }>{loading ? <Loader width={ 20 }/> :t('there is no producers on your portfolio')}</p>
          ) : (
            <div>
              <InputSelect
                label={ t('producer', { howMany: 1, gender: 'male' }) }
                detached
                options={ map(producersOptions, (item) => ({ value: item?.id, label: item?.name, item })) }
                onChange={ (val, item) => setProducer(item?.item) }
                value={ producer?.id }
                disabled={ defaultProducer }
                required={ currentForm?.proprietaryRequired }
              />
              {producer?.id ? (
                <Badge
                  backgroundColorBadge="(245, 125, 13, 0.1)"
                  colorBadge="(245, 125, 13)"
                >
                  { `${ t('last evaluation') }: ${ isEmpty(producer?.quizAnswers) ? t('no evaluation registered for this form') : moment(producer?.quizAnswers[0]?.createdAt, 'YYYY-MM-DD HH:mm:ss').format(momentFriendlyDateTimeFormat) }` }
                </Badge>
              ) : null }
            </div>
          )}
        </>
      )}
    </Container>

  )
}

TechnicalFormsListScreen.propTypes = {
  setProducer: PropTypes.func.isRequired,
  currentForm: PropTypes.string,
  defaultForm: PropTypes.string,
  producer: PropTypes.object,
  defaultProducer: PropTypes.object
}

TechnicalFormsListScreen.defaultProps = {
  currentForm: null,
  defaultForm: null,
  defaultProducer: null,
  producer: null
}

export default TechnicalFormsListScreen
