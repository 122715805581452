import React, { useCallback, forwardRef, useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import milkDeliverySchema from '@smartcoop/forms/schemas/dairyFarm/milkDelivery.schema'
import I18n,  { useT } from '@smartcoop/i18n'
import { info } from '@smartcoop/icons'
import { getOrganizationsByUser as getOrganizationsByUserService, getOrganizationsByUserId } from '@smartcoop/services/apis/smartcoopApi/resources/organization'
import { getUserStateRegistrations as getUserStateRegistrationsService, getStateRegistrationsByUserId } from '@smartcoop/services/apis/smartcoopApi/resources/stateRegistration'
import { useSnackbar } from '@smartcoop/snackbar'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectFamilyGroupAccess, selectCurrentPropertyOwnerId } from '@smartcoop/stores/property/selectorProperty'
import { selectCurrentOwner } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import Loader from '@smartcoop/web-components/Loader'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import Tooltip from '@smartcoop/web-components/Tooltip'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container, Text } from './styles'

const MilkDeliveryForm = forwardRef((props, formRef) => {
  const { handleClose, defaultValues, clearForm } = props

  const t = useT()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const isTechnical = useSelector(selectModuleIsTechnical)
  const currentOwner = useSelector(selectCurrentOwner)
  const userWrite = useSelector(selectUserCanWrite)
  const familyGroupAccess = useSelector(selectFamilyGroupAccess)
  const currentPropertyOwnerId = useSelector(selectCurrentPropertyOwnerId)

  const [tamboType, setTamboType] = useState(defaultValues?.companyName ? 'third party' : 'cooperative' )
  const [textInputValue, setTextInputValue] = useState('')
  const [selectInputValue, setSelectInputValue] = useState('')
  const [loading, setLoading] = useState(false)

  const currentOwnerId = useMemo(
    () => currentOwner?.id,
    [currentOwner]
  )

  const onSuccess = useCallback(
    () => {
      snackbar.success(
        t(`your {this} was ${ defaultValues?.id ? 'updated' : 'registered' }`, {
          howMany: 1,
          gender: 'male',
          this: t('dairy farm', { howMany: 1 })
        })
      )
      dispatch(PropertyActions.loadCurrentProperty())
      handleClose()
    },
    [defaultValues, dispatch, handleClose, snackbar, t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setLoading(true)
      dispatch(DairyFarmActions.saveTamboType(
        data,
        () => {
          onSuccess()
          setLoading(false)
        },
        () => {
          setLoading(false)
        }
      ))
    },
    [dispatch, onSuccess]
  )

  const changeTamboType = useCallback(
    (type) => {
      setTamboType(type)
      setSelectInputValue(defaultValues?.organizationId)
      setTextInputValue(defaultValues?.companyName)
    },
    [defaultValues]
  )

  const modalOptions = useMemo(
    () => (
      [
        {
          label: t('cooperative', { howMany: 1 }),
          value: 'cooperative'
        },
        {
          label: t('others'),
          value: 'third party'
        }
      ]
    ),
    [t]
  )

  const isDisabled = useMemo(
    () =>
      tamboType === 'third party' ? isEmpty(textInputValue) : isEmpty(selectInputValue),
    [selectInputValue, tamboType, textInputValue]
  )

  useEffect(() => {
    setSelectInputValue(defaultValues?.organizationId)
    setTextInputValue(defaultValues?.companyName)
  }, [defaultValues])

  const stateRadioGroup = useMemo(
    () => (
      tamboType === 'third party' ?
        <InputText
          name="companyName"
          label={ t('enter the company name') }
          defaultValue={ defaultValues.companyName }
          onChange={ ({ target }) => setTextInputValue(target.value) }
        />
        :
        <InputSelect
          label={ t('select one organization') }
          name="organizationId"
          asyncOptionValueField='id'
          asyncOptionLabelField='tradeName'
          options={ (isTechnical || familyGroupAccess) ? getOrganizationsByUserId : getOrganizationsByUserService }
          urlParams={ (isTechnical || familyGroupAccess) ? { userId: currentOwnerId || currentPropertyOwnerId } :  {} }
          queryParams={ { type: 1, isSubsidiary: false } }
          style={ { paddingBottom: 10 } }
          clearable="true"
          defaultValue={ defaultValues.organizationId }
          onChange={ ({ target }) => setSelectInputValue(target.value) }
        />
    ),
    [currentOwnerId, currentPropertyOwnerId, defaultValues.companyName, defaultValues.organizationId, familyGroupAccess, isTechnical, t, tamboType]
  )
  return loading ? <Loader width={ 100 } /> : (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ milkDeliverySchema }
        onSubmit={ handleSubmit }
      >
        <RadioGroup
          onChange={ (e) => changeTamboType(e.target?.value) }
          variant="row"
          name="tamboType"
          options={ modalOptions }
          style={ { paddingBottom: 20 } }
          defaultValue={ tamboType }
        />
        {stateRadioGroup}
        <div style={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 8 } }>
          <I18n as={ Text }>select your state registrations</I18n>

          <Tooltip title={ t('in the dairy farm only data related to selected state registrations will be displayed') }>
            <Icon
              icon={ info }
              color={ colors.text }
              size={ 15 }
              style={ { marginRight: 10 } }
            />
          </Tooltip>
        </div>
        <InputSelect
          name="stateRegistrationId"
          label={ t('state registration') }
          defaultValue={ defaultValues?.stateRegistrationId }
          options={ (isTechnical || familyGroupAccess) ? getStateRegistrationsByUserId :  getUserStateRegistrationsService }
          urlParams={ (isTechnical || familyGroupAccess) ? { userId: currentOwnerId || currentPropertyOwnerId } : {} }
          queryParams={ { expired: true } }
          asyncOptionLabelField="stateRegistration"
          asyncOptionValueField="id"
          selectAllOptionsByDefault={ isEmpty(defaultValues?.id) }
          multiple
        />
        <ButtonsContainer>
          <Button
            id="clear"
            onClick={ () => clearForm() }
            variant="outlined"
            style={ { marginRight: 7 } }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="save"
            onClick={ () => formRef.current.submit() }
            color="black"
            style={ { marginLeft: 7 } }
            disabled={ !userWrite || isDisabled }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  )
})
MilkDeliveryForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  clearForm: PropTypes.func
}

MilkDeliveryForm.defaultProps = {
  clearForm: () => {}
}
export default MilkDeliveryForm
