import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { download } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import IconButton from '../IconButton'

const DownloadIconButton = ({ disabled, onClick, tooltip, size, loading, color, ...props }) => {
  const handleClick = () => {
    if (!disabled) onClick()
  }

  const styles = useMemo(() => (
    {
      cursor: disabled ? 'inherit' : 'pointer',
      height: size,
      ...(loading && { paddingBottom: 20 })
    }
  ), [disabled, loading, size])

  return (
    <IconButton style={ styles } tooltip={ tooltip } onClick={ handleClick } loading={ loading } { ...props }>
      <Icon icon={ download } color={ disabled ? colors.darkGrey : color } size={ size } />
    </IconButton>
  )
}

DownloadIconButton.propTypes = {
  tooltip: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func
}

DownloadIconButton.defaultProps = {
  disabled: false,
  loading: false,
  size: 20,
  color: colors.white,
  onClick: () => {}
}

export default DownloadIconButton
