export const momentFriendlyDateFormat = 'DD/MM/YYYY'
export const momentFriendlyShortDateFormat = 'DD/MM/YY'
export const momentFriendlyExtendedDateFormat = 'DD [de] MMMM, dddd'
export const momentFriendlyDateTimeFormat = 'DD/MM/YYYY [às] HH:mm'
export const momentFriendlyExtendedDateTimeFormat = 'DD [de] MMMM, dddd [às] HH:mm'
export const momentFriendlyTimeFormat = 'HH:mm'
export const momentFriendlyMonthYearFormat = 'MM/YYYY'

export const momentBackDateTimeZoneFormat = 'YYYY-MM-DD[T]HH:mm:ss.sss[Z]'
export const momentBackDateFormat = 'YYYY-MM-DD'
export const momentBackDateTimeFormat = 'YYYY-MM-DD HH:mm:ss'
export const momentBackTimeFormat = 'HH:mm:ss'
export const momentBackMonthYearFormat = 'YYYY-MM'

export const generateArrayOfDate = (initialDate, endDate, mode = 'day') => {
  const array = []
  const date = initialDate.clone()

  switch (mode) {
    case 'year':
      while (endDate.diff(date, 'years') >= 0) {
        array.push(date.clone())
        date.add(1, 'year')
      }
      break
    case 'month':
      while (endDate.diff(date, 'months') >= 0) {
        array.push(date.clone())
        date.add(1, 'month')
      }
      break
    case 'day':
    default:
      while (endDate.diff(date, 'days') >= 0) {
        array.push(date.clone())
        date.add(1, 'days')
      }
  }

  return array
}
