import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'

import {
  Badge
} from './styles'

const SupplierQuotationStatus = props => {
  const {
    slug,
    style
  } = props

  const options = useMemo(() => {
    switch (slug) {
      case 'aberta':
      case 'aberto':
        return {
          name: <I18n params={ { gender: 'female' } }>opened</I18n>,
          background: '(25, 156, 216, 0.1)',
          color: '(25, 156, 216, 1)'
        }

      case 'expirado':
      case 'expirada':
        return {
          name: <I18n params={ { gender: 'female' } }>expired</I18n>,
          background: '(245, 125, 13, 0.1)',
          color: '(245, 125, 13, 1)'
        }

      case 'recusada':
      case 'recusado':
        return {
          name: <I18n params={ { gender: 'female' } }>refused</I18n>,
          background: '(228, 29, 27, 0.1)',
          color: '(228, 29, 27, 1)'
        }

      case 'cancelada':
      case 'cancelado':
        return {
          name: <I18n params={ { gender: 'female' } }>canceled</I18n>,
          background: '(112, 54, 33, 0.1)',
          color: '(112, 54, 33, 1)'
        }

      case 'rejeitada':
      case 'rejeitado':
        return {
          name: <I18n params={ { gender: 'female' } }>rejected</I18n>,
          background: '(89, 89, 89, 0.1)',
          color: '(89, 89, 89, 1)'
        }

      case 'atrasado':
      case 'atrasada':
        return {
          name: <I18n params={ { gender: 'female' } }>late</I18n>,
          background: '(245, 125, 13, 0.1)',
          color: '(245, 125, 13, 1)'
        }

      case 'participando':
        return {
          name: <I18n>participating</I18n>,
          background: '(22, 67, 183, 0.1)',
          color: '(22, 67, 183, 1)'
        }

      case 'aguardando_resposta':
        return {
          name: <I18n>waiting answer</I18n>,
          background: '(142, 36, 170, 0.1)',
          color: '(142, 36, 170, 1)'
        }

      case 'aguardando_decisao':
        return {
          name: <I18n>waiting decision</I18n>,
          background: '(142, 36, 170, 0.1)',
          color: '(142, 36, 170, 1)'
        }

      case 'entregue':
        return {
          name: <I18n>delivered</I18n>,
          background: '(29, 29, 27, 0.1)',
          color: '(29, 29, 27, 1)'
        }

      case 'aprovada':
      case 'aprovado':
        return {
          name: <I18n params={ { gender: 'female' } }>approved</I18n>,
          background: '(40, 159, 48, 0.1)',
          color: '(40, 159, 48, 1)'
        }

      case 'finalizado':
      case 'finalizada':
        return {
          name: <I18n params={ { gender: 'female' } }>finished</I18n>,
          background: '(29, 29, 27, 0.1)',
          color: '(29, 29, 27, 1)'
        }

      case 'proposta_aceita':
        return {
          name: <I18n params={ { gender: 'female' } }>proposal accepted</I18n>,
          background: '(124, 179, 66, 0.1)',
          color: '(124, 179, 66, 1)'
        }

      case 'aguardando_entrega':
        return {
          name: <I18n>waiting delivery</I18n>,
          background: '(22, 67, 183, 0.1)',
          color: '(22, 67, 183, 1)'
        }

      case 'aguardando_proposta':
        return {
          name: <I18n>waiting proposal</I18n>,
          background: '(142, 36, 170, 0.1)',
          color: '(142, 36, 170, 1)'
        }

      case 'aguardando_cooperativas':
        return {
          name: <I18n>waiting cooperative</I18n>,
          background: '(142, 36, 170, 0.1)',
          color: '(142, 36, 170, 1)'
        }

      default:
        return {
          name: '',
          background: '(40, 159, 48, 0.1)',
          color: '(40, 159, 48, 1)'
        }
    }
  }, [slug])

  return (
    <Badge
      backgroundColorBadge={ options.background }
      colorBadge={ options.color }
      style={ style }
    >
      {options.name}
    </Badge>
  )
}


SupplierQuotationStatus.propTypes = {
  slug: PropTypes.oneOf([
    'aberta',
    'expirado',
    'recusada',
    'rejeitada',
    'cancelada',
    'participando',
    'aguardando_entrega',
    'aguardando_resposta',
    'proposta_aceita',
    'aguardando_decisao',
    'entregue',
    'aprovada',
    'recusado',
    'aguardando_proposta',
    'finalizado'
  ]).isRequired,
  style: PropTypes.object
}

SupplierQuotationStatus.defaultProps = {
  style: {}
}

export default SupplierQuotationStatus
