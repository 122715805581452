import { Model } from '@nozbe/watermelondb'
import { date, field, readonly } from '@nozbe/watermelondb/decorators'


export class SyncFileModel extends Model {
  static table = 'sync_file'

  @field('type')
  type: string

  @field('source')
  source: string

  @field('sourceExtension')
  sourceExtension: string

  @field('params')
  params: string

  @field('url')
  url: string

  @field('sync')
  sync: boolean

  @field('error')
  error: string

  @field('registerType')
  registerType: string

  @field('localMessageId')
  localMessageId: string

  @field('documentType')
  documentType: string

  @field('uri')
  uri: string

  @readonly @date('created_at') createdAt
}
