import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { select, call, put, takeLatest } from 'redux-saga/effects'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

import {
  getMilkDeliveries as  getMilkDeliveriesService,
  getMilkQualities as  getMilkQualitiesService,
  createMilkDelivery as createMilkDeliveryService,
  createMilkQuality as createMilkQualityService,
  createDairyFarm as createDairyFarmService,
  getDairyFarm as getDairyFarmService,
  updateDairyFarm as updateDairyFarmService,
  updateMilkDelivery as updateMilkDeliveryService,
  updateMilkQuality as updateMilkQualityService,
  deleteMilkDelivery as deleteMilkDeliveryService,
  deleteMilkQuality as deleteMilkQualityService,
  getDashboardData as getDashboardDataService,
  getPriceData,
  createPriceData,
  editPriceData,
  deletePriceData as deletePriceDataService,
  deleteMonthlyDataHistory as deleteMonthlyDataHistoryService,
  createMonthlyDataHistory,
  updateMonthlyDataHistory,
  getMonthlyDataHistory,
  getPriceComposition
} from '@smartcoop/services/apis/smartcoopApi/resources/dairyFarm'
import { getPropertiesByOwner } from '@smartcoop/services/apis/smartcoopApi/resources/property'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectCurrentProperty, selectFamilyGroupAccess, selectCurrentPropertyOwnerId } from '@smartcoop/stores/property/selectorProperty'
import { selectCurrentOwnerId } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUser } from '@smartcoop/stores/user/selectorUser'

import { PropertyActions } from '../property/duckProperty'
import { DairyFarmActions, DairyFarmTypes } from './duckDairyFarm'

function* loadTambo({ onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    yield call(getDairyFarmService, { propertyId: currentProperty?.id })
    yield call(onSuccess)
  } catch (err) {
    yield call(onError)
  }
}

function* saveTamboType({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const moduleIsTechnical = yield select(selectModuleIsTechnical)
    const currentOwnerId = yield select(selectCurrentOwnerId)
    const familyGroupAccess = yield select(selectFamilyGroupAccess)
    const currentPropertyOwnerId = yield select(selectCurrentPropertyOwnerId)

    const service = isEmpty(currentProperty?.dairyFarm) ? createDairyFarmService : updateDairyFarmService

    const ownerData = moduleIsTechnical
      ? { ownerId: currentOwnerId }
      : { ownerId: familyGroupAccess ? currentPropertyOwnerId : null }

    const { data } = yield call(
      service,
      { ...params, propertyId: currentProperty?.id, ...ownerData },
      { dairyFarmId: currentProperty?.dairyFarm[0]?.id }
    )

    if (moduleIsTechnical) {
      const { data: { data: properties } } = yield call(
        getPropertiesByOwner,
        {
          limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE
        },
        { ownerId: currentOwnerId }
      )

      const updatedCurrentProperty = find(
        properties,
        ({ id }) => id === currentProperty?.id
      )

      yield put(PropertyActions.saveCurrentProperty(updatedCurrentProperty))
    } else {
      yield put(PropertyActions.loadProperties())
    }
    yield call(onSuccess, data)
  } catch (error) {
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* loadMilkDeliveries({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const dairyFarmId = currentProperty?.dairyFarm[0]?.id || currentProperty?.dairyFarm?.id

    const { data: { data, ...pagination } } = yield call(
      getMilkDeliveriesService,
      {
        limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
        ...params
      },
      { dairyFarmId }
    )
    yield put(DairyFarmActions.loadMilkDeliveriesSuccess(
      data,
      pagination.page
    ))
    onSuccess(data)
  } catch (err) {
    const error = err.message
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* loadPriceComposition({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {

    const { data: { body } } = yield call(
      getPriceComposition,
      {
        limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
        ...params
      }
    )
    onSuccess(body)
  } catch (err) {
    const error = err.message
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* loadMilkDeliveriesSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* saveMilkDelivery({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const companyName = currentProperty?.dairyFarm[0]?.companyName
    const moduleIsTechnical = yield select(selectModuleIsTechnical)
    const currentOwnerId = yield select(selectCurrentOwnerId)
    const currentPropertyOwner = yield select(selectCurrentPropertyOwnerId)

    const ownerData = moduleIsTechnical
      ? { ownerId: currentOwnerId }
      : { ownerId: currentPropertyOwner }

    const service = params?.id ? updateMilkDeliveryService : createMilkDeliveryService

    const { data } = yield call(
      service,
      { ...params, companyName, ...ownerData },
      { milkDeliveryId: params?.id }
    )

    yield call(onSuccess, data)
  } catch (error) {
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* deleteMilkDelivery({ milkDeliveryId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const dairyFarmId = currentProperty?.dairyFarm[0]?.id || currentProperty?.dairyFarm?.id

    yield call(deleteMilkDeliveryService, { dairyFarmId, milkDeliveryId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* loadMilkQualities({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const dairyFarmId = currentProperty?.dairyFarm[0]?.id || currentProperty?.dairyFarm?.id

    const { data: { data, ...pagination } } = yield call(
      getMilkQualitiesService,
      {
        limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
        ...params
      },
      { dairyFarmId }
    )

    yield put(DairyFarmActions.loadMilkQualitiesSuccess(
      data,
      pagination.page
    ))
    onSuccess(pagination)
  } catch (err) {
    const error = err.message
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* saveMilkQuality({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const companyName = currentProperty?.dairyFarm[0]?.companyName
    const moduleIsTechnical = yield select(selectModuleIsTechnical)
    const currentOwnerId = yield select(selectCurrentOwnerId)
    const userId = yield select(selectUser)

    const ownerData = moduleIsTechnical
      ? { ownerId: currentOwnerId }
      : { ownerId: userId }

    const service = params?.id ? updateMilkQualityService : createMilkQualityService

    const { data } = yield call(service, { ...params, companyName, ...ownerData }, { milkQualityId: params.id })
    yield call(onSuccess, data)
  } catch (error) {
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* deleteMilkQuality({ milkQualityId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteMilkQualityService, { milkQualityId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* loadDashboardData({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const dairyFarmId = currentProperty?.dairyFarm[0]?.id || currentProperty?.dairyFarm?.id

    const { data } = yield call(getDashboardDataService, {
      ...params
    },
    { dairyFarmId }
    )
    yield put(DairyFarmActions.loadDashboardDataSuccess(
      data,
      () => onSuccess(data)
    ))
  } catch (err) {
    const error = err.message
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* loadDashboardDataSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* loadPriceData({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const dairyFarmId = currentProperty?.dairyFarm[0]?.id || currentProperty?.dairyFarm?.id

    const { data: { data, ...pagination } } = yield call(getPriceData, {
      limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
      ...params
    },
    { dairyFarmId }
    )

    yield put(DairyFarmActions.loadPriceDataSuccess(
      data,
      pagination.page,
      () => onSuccess(data)
    ))
  } catch (err) {
    const error = err.message
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* loadPriceDataSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* savePriceData({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const companyName = currentProperty?.dairyFarm[0]?.companyName
    const moduleIsTechnical = yield select(selectModuleIsTechnical)
    const currentOwnerId = yield select(selectCurrentOwnerId)

    const ownerData = moduleIsTechnical
      ? { ownerId: currentOwnerId }
      : {}

    const service = params?.id ? editPriceData : createPriceData

    const { data } = yield call(service, { ...params, companyName, ...ownerData }, { priceDataId: params?.id })

    yield call(onSuccess, data)
  } catch (error) {
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* deletePriceData({ priceDataId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const dairyFarmId = currentProperty?.dairyFarm[0]?.id || currentProperty?.dairyFarm?.id

    yield call(deletePriceDataService, { dairyFarmId, priceDataId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* loadMonthlyDataHistory({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)

    const { data: { data, ...pagination } } = yield call(getMonthlyDataHistory, {
      propertyId: currentProperty?.id,
      ...params
    })

    yield put(DairyFarmActions.loadMonthlyDataHistorySuccess(
      data,
      pagination.page,
      () => onSuccess(data)
    ))
  } catch (err) {
    const error = err.message
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* loadMonthlyDataHistorySuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* saveMonthlyDataHistory({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const service = params?.id ? updateMonthlyDataHistory : createMonthlyDataHistory

    const { data } = yield call(service, { ...params }, { id: params?.id })

    yield call(onSuccess, data)
  } catch (error) {
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

function* deleteMonthlyDataHistory({ id, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteMonthlyDataHistoryService, { id })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(DairyFarmActions.dairyFarmError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(DairyFarmTypes.LOAD_DASHBOARD_DATA, loadDashboardData),
  takeLatest(DairyFarmTypes.LOAD_DASHBOARD_DATA_SUCCESS, loadDashboardDataSuccess),

  takeLatest(DairyFarmTypes.LOAD_MILK_DELIVERIES, loadMilkDeliveries),
  takeLatest(DairyFarmTypes.LOAD_MILK_DELIVERIES_SUCCESS, loadMilkDeliveriesSuccess),

  takeLatest(DairyFarmTypes.LOAD_MILK_QUALITIES, loadMilkQualities),

  takeLatest(DairyFarmTypes.LOAD_PRICE_COMPOSITION, loadPriceComposition),

  takeLatest(DairyFarmTypes.SAVE_MILK_QUALITY, saveMilkQuality),
  takeLatest(DairyFarmTypes.DELETE_MILK_QUALITY, deleteMilkQuality),

  takeLatest(DairyFarmTypes.SAVE_MILK_DELIVERY, saveMilkDelivery),
  takeLatest(DairyFarmTypes.DELETE_MILK_DELIVERY, deleteMilkDelivery),

  takeLatest(DairyFarmTypes.LOAD_PRICE_DATA, loadPriceData),
  takeLatest(DairyFarmTypes.LOAD_PRICE_DATA_SUCCESS, loadPriceDataSuccess),

  takeLatest(DairyFarmTypes.SAVE_TAMBO_TYPE, saveTamboType),
  takeLatest(DairyFarmTypes.LOAD_TAMBO, loadTambo),

  takeLatest(DairyFarmTypes.SAVE_PRICE_DATA, savePriceData),
  takeLatest(DairyFarmTypes.DELETE_PRICE_DATA, deletePriceData),

  takeLatest(DairyFarmTypes.SAVE_MONTHLY_DATA_HISTORY, saveMonthlyDataHistory),
  takeLatest(DairyFarmTypes.DELETE_MONTHLY_DATA_HISTORY, deleteMonthlyDataHistory),
  takeLatest(DairyFarmTypes.LOAD_MONTHLY_DATA_HISTORY, loadMonthlyDataHistory),
  takeLatest(DairyFarmTypes.LOAD_MONTHLY_DATA_HISTORY_SUCCESS, loadMonthlyDataHistorySuccess)
]
