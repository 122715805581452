import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import colors from '@smartcoop/styles/colors'

export const Divisor = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 0.935252px solid #E0E0E0;
  margin: 0;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
`

export default makeStyles({
  modalBackground: {
    backgroundColor: colors.white,
    maxWidth: 420

  },
  title: {
    textAlign: 'center',
    fontWeight: `${ 700  } !important`,
    fontSize: 16,
    lineHeight: '16px',
    padding: '20px 13px 0 20px',
    '& h2': {
      fontSize: '16px!important',
      fontWeight: `${ 'inherit' } !important`
    }
  }

})
