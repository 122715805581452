import React, { useState } from 'react'
import { JssProvider } from 'react-jss'

import { create as createJss } from 'jss'
import jssPreset from 'jss-preset-default'

import flow from 'lodash/fp/flow'

import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles'
import { createGenerateClassName } from '@material-ui/styles'


import globalStyles from '../globalStyles'
import materialUiTheme from '../themes/material-ui-theme'

const jss = createJss(jssPreset())

const generateClassName = createGenerateClassName()

const withJssThemeProvider = WrappedComponent => (props) => {
  const [currentTheme] = useState(createMuiTheme(materialUiTheme))

  return (
    <JssProvider jss={ jss } generateClassName={ generateClassName }>
      <MuiThemeProvider theme={ currentTheme }>
        <CssBaseline />
        <WrappedComponent { ...props } />
      </MuiThemeProvider>
    </JssProvider>
  )
}

export default flow(
  withJssThemeProvider,
  withStyles(globalStyles)
)
