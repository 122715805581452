import styled from 'styled-components'

import InputUnit from '@smartcoop/web-components/InputUnit'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: row;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const ButtonText = styled.div`
  margin-left: 10px;
  font-weight: bold;
`

export const TableData = styled.div`
  margin: 0 10px;
  font-weight: bold;
`

export const InputUnitStyled = styled(InputUnit)`
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -5px;
  border: 0;
`

export const CertificateDataTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, .1);
  }

  td {
    border: 1px solid #babaca;
    padding: 10px;
    text-align: center;
    border-collapse: collapse;
  }
`
