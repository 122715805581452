import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import required from '@smartcoop/forms/validators/required.validator'

const informative = ({ t }) => Yup.object().shape({
  organizationId: flow(
    required({ t })
  )(Yup.string()),

  fileType: flow(
    required({ t })
  )(Yup.string()),

  name: flow(
    required({ t })
  )(Yup.string()),

  keyWords: flow(
    required({ t })
  )(Yup.string().nullable()),

  fileUrl: flow(
    required({ t })
  )(Yup.string()),

  coverUrl: flow(
    required({ t })
  )(Yup.string()),

  publicationDate: flow(
    date({ t }),
    required({ t })
  )(Yup.string())
})

export default informative
