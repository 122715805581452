import React, { useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'

import filterFieldSchema from '@smartcoop/forms/schemas/property/fields/filterFields.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getCrops } from '@smartcoop/services/apis/smartcoopApi/resources/crop'
import { getCultivationsGoal } from '@smartcoop/services/apis/smartcoopApi/resources/cultivationsGoal'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputSelect from '@smartcoop/web-components/InputSelect'

import { Container, ButtonContainer } from './styles'

const FilterFieldHistoryForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()

  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterFieldSchema }
        onSubmit={ handleSubmit }
      >

        <InputSelect
          label={ t('crop', { howMany: 1 }) }
          name="cropId"
          options={ getCrops }
          defaultValue={ filters.cropId }
          asyncOptionLabelField="description"
        />

        <InputSelect
          label={ t('goal') }
          name="cultivationGoalId"
          options={ getCultivationsGoal }
          defaultValue={ filters.cultivationGoalId }
          asyncOptionLabelField="description"
        />

        <InputNumber
          maxLength={ 4 }
          name="sowingYear"
          label={ t('sowing year') }
          value={ filters.sowingYear }
          defaultValue={ filters.sowingYear }
          notSeparate
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-order-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterFieldHistoryForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterFieldHistoryForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterFieldHistoryForm
