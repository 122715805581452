import moment from 'moment/moment'
import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import minDate from '@smartcoop/forms/validators/minDate.validator'
import minHour from '@smartcoop/forms/validators/minHour.validator'
import required from '@smartcoop/forms/validators/required.validator'

const joinOrder = ({ t, props }) => Yup.object().shape({
  deliveryLocations: flow(
    required({ t })
  )(Yup.string()),

  freightType: flow(
  )(Yup.string()),

  participationDeadline: props?.isOrganizationTheOwnerOfTheCurrentOrder ? flow(
    date({ t }),
    minDate({ t, minReceiptDate:  moment().format('YYYY-MM-DD'), text: t('date must be greater than {this}', { this: t('today') }) })
  )(Yup.string()) : Yup.string(),

  participationDeadlineHour: Yup.string(),

  proposalDeadline: props?.isOrganizationTheOwnerOfTheCurrentOrder ? flow(
    date({ t }),
    minDate({ t, minReceiptDate:  moment(props?.participationDeadline).format('YYYY-MM-DD'), text: t('date must be greater than {this}', { this: t('demand closing date') }) })
  )(Yup.string()) : Yup.string(),

  proposalDeadlineHour: Yup.string().when('proposalDeadline', {
    is: () => props?.proposalDeadline === props?.participationDeadline && props?.isOrganizationTheOwnerOfTheCurrentOrder,
    then: flow(
      minHour({ t, minHour:  moment(props?.participationDeadlineHour, 'HH:mm'), text: t('hour must be greater than {this}', { this: props?.participationDeadlineHour }) })
    )(Yup.string()),
    otherwise: Yup.string()
  }),

  decisionDeadline: props?.isOrganizationTheOwnerOfTheCurrentOrder ? flow(
    date({ t }),
    minDate({ t, minReceiptDate:  moment(props?.proposalDeadline).format('YYYY-MM-DD'), text: t('date must be greater than {this}', { this: t('proposal deadline') }) })
  )(Yup.string()) : Yup.string(),

  decisionDeadlineHour: Yup.string().when('decisionDeadline', {
    is: () => props?.decisionDeadline === props?.proposalDeadline && props?.isOrganizationTheOwnerOfTheCurrentOrder,
    then: flow(
      minHour({ t, minHour:  moment(props?.proposalDeadlineHour, 'HH:mm'), text: t('hour must be greater than {this}', { this: props?.proposalDeadlineHour }) })
    )(Yup.string()),
    otherwise: Yup.string()
  }),

  receiptDeadline: props?.isOrganizationTheOwnerOfTheCurrentOrder ? flow(
    date({ t }),
    minDate({ t, minReceiptDate:  moment(props?.decisionDeadline).format('YYYY-MM-DD'), text: t('date must be greater than {this}', { this: t('organization decision deadline') }) })
  )(Yup.string()) : Yup.string(),

  receiptDeadlineHour: Yup.string().when('receiptDeadline', {
    is: () => props?.receiptDeadline === props?.decisionDeadline && props?.isOrganizationTheOwnerOfTheCurrentOrder,
    then: flow(
      minHour({ t, minHour:  moment(props?.decisionDeadlineHour, 'HH:mm'), text: t('hour must be greater than {this}', { this: props?.decisionDeadlineHour }) })
    )(Yup.string()),
    otherwise: Yup.string()
  })
})


export default joinOrder
