import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects'

import { find, filter } from 'lodash'

import { validatePassword as validatePasswordService } from '@smartcoop/services/apis/smartcoopApi/resources/authentication'
import {
  getAddressesByOrganization,
  createOrganization as createOrganizationService,
  editOrganization as editOrganizationService,
  addOrganizationFiles as addOrganizationFilesService,
  updateSignature as updateSignatureService,
  deleteOrganization as deleteOrganizationService,
  changeOrganizationLogo as changeOrganizationLogoService,
  deleteOrganizationLogo as deleteOrganizationLogoService,
  getOrganization
} from '@smartcoop/services/apis/smartcoopApi/resources/organization'
import {
  getSupplierProducts,
  postSupplierProducts
} from '@smartcoop/services/apis/smartcoopApi/resources/supplierProducts'
import {
  getUserOrganizations,
  getUserOrganizationsByModule
} from '@smartcoop/services/apis/smartcoopApi/resources/user'

import { AuthenticationActions } from '../authentication'
import { selectAuthenticated, selectPermissionsResponse } from '../authentication/selectorAuthentication'
import { selectUser } from '../user/selectorUser'
import { OrganizationActions, OrganizationTypes } from './duckOrganization'
import { selectCurrentOrganization } from './selectorOrganization'

function* loadUserOrganizations({ webinar = false, onSuccess = () => {}, onError = () => {}, user = {} }) {
  try {
    // yield put(AuthenticationActions.clearSlugs())

    const selectedUser = yield select(selectUser)

    let userOrganizations = []

    if (user?.id || selectedUser?.id) {
      userOrganizations = yield call(getUserOrganizations, { webinar }, {
        userId: user?.id || selectedUser?.id
      })
    }

    yield put(OrganizationActions.loadUserOrganizationsSuccess(userOrganizations))

    yield call(onSuccess, filter(userOrganizations, organization => organization.companyDocument !== '99999999999999'))

  } catch (error) {
    yield put(OrganizationActions.organizationError(error))
    yield call(onError, error)
  }
}

function* loadUserOrganizationsByModule({
  module: mod,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    // yield put(AuthenticationActions.clearSlugs())
    yield put(OrganizationActions.loadUserOrganizations())

    const user = yield select(selectUser)

    // eslint-disable-next-line no-extra-boolean-cast
    if (!!mod) {
      // let userOrganizations

      // if (user.id) {
      //   userOrganizations = yield call(getUserOrganizations, {
      //     userId: user.id
      //   })
      // }

      let organizations

      if (user.id) {
        organizations = yield call(getUserOrganizationsByModule, {
          userId: user.id,
          moduleSlug: mod
        })
      } else {
        throw new Error('User not found')
      }

      // if (size(organizations) === 1 && size(userOrganizations) === 1) {
      //   yield put(OrganizationActions.setCurrentOrganization(organizations[0]))
      // }

      yield put(
        OrganizationActions.loadUserOrganizationsByModuleSuccess(
          organizations,
          mod,
          onSuccess,
          onError
        )
      )
    }
  } catch (error) {
    yield put(OrganizationActions.organizationError(error))
    yield call(onError, error)
  } finally {
    // Se a chamada da saga for cancelada, o finally vai chamar o onSuccess pra finalizar a saga
    if (yield cancelled()) {
      yield call(onSuccess, 'Saga Cancelled')
    }
  }
}

function* loadUserOrganizationsByModuleSuccess({
  organizations,
  module: mod,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    yield call(onSuccess, { organizations, module: mod })
  } catch (error) {
    yield call(onError)
  }
}

function* setCurrentOrganization({
  organization,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const isAuthenticated = yield select(selectAuthenticated)
    if (isAuthenticated) {
      // yield put(
      //   AuthenticationActions.getTokenByOrganization(organization, onSuccess)
      // )

      const permissionsResponse = yield select(selectPermissionsResponse)
      const { id: organizationId, registry } = organization
      const selectedOrganization = find(permissionsResponse, { organizationsUser: { organizationId, registry } })
      const selectedSlug = selectedOrganization?.profile?.slug
      yield put(AuthenticationActions.setSlug([selectedSlug]))

      yield put(OrganizationActions.loadCurrentOrganizationAddresses())
      yield call(onSuccess, organization)
    }
  } catch (error) {
    yield call(onError, error)
  }
}

function* loadCurrentOrganizationAddresses({
  params = {},
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const currentOrganization = yield select(selectCurrentOrganization)

    const {
      data: { data: addresses }
    } = yield call(
      getAddressesByOrganization,
      params,
      { organizationId: currentOrganization.id }
    )

    yield put(
      OrganizationActions.loadCurrentOrganizationAddressesSuccess(addresses)
    )

    yield call(onSuccess, addresses)
  } catch (error) {
    yield put(OrganizationActions.organizationError(error))
    yield call(onError, error)
  }
}

function* createOrganization({
  params,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const type = params.type ? params.type : 1
    const { data } = yield call(createOrganizationService, { type, ...params })

    if (type === 2) {
      const { products } = params
      if (products.length > 0)
        yield call(postSupplierProducts, { products }, { supplierId: data.id })
    }

    yield call(onSuccess)
  } catch (error) {
    yield put(OrganizationActions.organizationError(error))
    yield call(onError, error)
  }
}

function* updateOrganization({
  params,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const type = params.type ? params.type : 1
    const { data } = yield call(editOrganizationService, { type, ...params })

    if (type === 2) {
      const { products } = params
      if (products.length > 0)
        yield call(postSupplierProducts, { products }, { supplierId: data.id })
    }
    yield call(onSuccess, data)
  } catch (error) {
    yield put(OrganizationActions.organizationError(error))
    yield call(onError, error)
  }
}

function* deleteOrganization({
  organizationId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { data } = yield call(
      deleteOrganizationService,
      {},
      { organizationId }
    )

    yield call(onSuccess, data)
    yield put(OrganizationActions.loadUserOrganizations())
  } catch (error) {
    yield put(OrganizationActions.organizationError(error))
    yield call(onError, error)
  }
}

function* addOrganizationFiles({
  params,
  organizationId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    yield call(addOrganizationFilesService, params, { organizationId })
    yield call(onSuccess)
  } catch (error) {
    yield put(OrganizationActions.organizationError(error))
    yield call(onError, error)
  }
}

function* changeOrganizationLogo({
  params,
  organizationId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    yield call(changeOrganizationLogoService, params, { organizationId })
    yield call(onSuccess)
  } catch (error) {
    yield put(OrganizationActions.organizationError(error))
    yield call(onError, error)
  }
}

function* deleteOrganizationLogo({
  logoId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    yield call(deleteOrganizationLogoService,{ logoId })
    yield call(onSuccess)
  } catch (error) {
    yield put(OrganizationActions.organizationError(error))
    yield call(onError, error)
  }
}

function* loadOrganization({
  params,
  organizationId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    let { data } = yield call(getOrganization, params, { organizationId })

    const { type } = data

    if (type === 2) {
      const { id } = data
      const response = yield call(getSupplierProducts, {}, { supplierId: id })
      const {
        data: { products }
      } = response
      data = { ...data, products }
    }

    yield call(onSuccess, data)
  } catch (error) {
    yield call(onError)
  }
}

function* updateSignature({
  params: { password, organizationId },
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { authorization } = yield call(validatePasswordService, { password })
    if (authorization) {
      const { data } = yield call(
        updateSignatureService,
        {},
        { organizationId }
      )
      yield call(onSuccess, data)
    } else {
      throw new Error('please, verify your password')
    }
  } catch (error) {
    yield put(OrganizationActions.organizationError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(OrganizationTypes.LOAD_USER_ORGANIZATIONS, loadUserOrganizations),
  takeLatest(
    OrganizationTypes.LOAD_USER_ORGANIZATIONS_BY_MODULE,
    loadUserOrganizationsByModule
  ),
  takeLatest(
    OrganizationTypes.LOAD_USER_ORGANIZATIONS_BY_MODULE_SUCCESS,
    loadUserOrganizationsByModuleSuccess
  ),
  takeLatest(
    OrganizationTypes.SET_CURRENT_ORGANIZATION,
    setCurrentOrganization
  ),
  takeLatest(
    OrganizationTypes.LOAD_CURRENT_ORGANIZATION_ADDRESSES,
    loadCurrentOrganizationAddresses
  ),
  takeLatest(OrganizationTypes.DELETE_ORGANIZATION, deleteOrganization),

  takeLatest(OrganizationTypes.CREATE_ORGANIZATION, createOrganization),
  takeLatest(OrganizationTypes.UPDATE_ORGANIZATION, updateOrganization),
  takeLatest(OrganizationTypes.ADD_ORGANIZATION_FILES, addOrganizationFiles),

  takeLatest(OrganizationTypes.CHANGE_ORGANIZATION_LOGO, changeOrganizationLogo),
  takeLatest(OrganizationTypes.DELETE_ORGANIZATION_LOGO, deleteOrganizationLogo),

  takeLatest(OrganizationTypes.LOAD_ORGANIZATION, loadOrganization),

  takeLatest(OrganizationTypes.UPDATE_SIGNATURE, updateSignature)
]
