import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import omitBy from 'lodash/omitBy'
import size from 'lodash/size'

import I18n, { useT } from '@smartcoop/i18n'
import { organizationRounded } from '@smartcoop/icons'
import useSmartcoopApi from '@smartcoop/services/hooks/useSmartcoopApi'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { secondaryUnit } from '@smartcoop/utils/barter'
import { momentBackDateTimeFormat } from '@smartcoop/utils/dates'
import { formatNumber } from '@smartcoop/utils/formatters'
import Badge from '@smartcoop/web-components/Badge'
import Icon from '@smartcoop/web-components/Icon'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'

import useStyles, {
  Row,
  Column,
  Divisor,
  Text,
  Section,
  Label
} from './styles'

const BarterDetailsModal = ({ id, open, handleClose, onClose, title, barterOrderNumber, exibitionCode, packageJoinMode, FooterComponent }) => {
  const classes = useStyles()
  const t = useT()

  const currentOrganization = useSelector(selectCurrentOrganization)

  const { data: barterRequest, isValidating } = useSmartcoopApi(packageJoinMode ? `/v1/barters/package/${ barterOrderNumber }/organization/${ currentOrganization.id }` : `/v1/barters/${ barterOrderNumber }/organization/${ currentOrganization.id }`)

  const loaderActive = useMemo(
    () => isEmpty(barterRequest) && isValidating,
    [barterRequest, isValidating]
  )

  const barter = useMemo(
    () => barterRequest || {},
    [barterRequest]
  )

  const isPackage = useMemo(
    () => barter?.packageType,
    [barter.packageType]
  )

  const organizationName = useMemo(
    () => currentOrganization.tradeName,
    [currentOrganization.tradeName]
  )

  const barterProducts = useMemo(
    () => {
      const types = {
        fertilizantes: [],
        herbicidas: [],
        fungicidas: [],
        inseticidas: [],
        sementes: [],
        outros: []
      }

      map(barter.barterProducts, (item) => types[item.productGroupName].push(item))

      return omitBy(types, (item) => isEmpty(item))
    },
    [barter]
  )

  const close = useCallback(
    () => {
      onClose()
      handleClose()
    },
    [handleClose, onClose]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={ <div style={ { paddingLeft: 7 } }>{title}</div> }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      FooterComponent={
        (props) =>
          <FooterComponent
            { ...props }
            isPackage={ isPackage }
            packageQuantity={ barter.packageQuantity }
            price={ barter.price }
            closeModal={ close }
            disabled={ barter?.status?.statusName !== 'Aberta' }
          />
      }
      maxWidth="md"
      fullWidth
    >
      {loaderActive
        ? <Loader width={ 100 } />
        : (
          <>
            <Divisor style={ { marginTop: 10 } }/>
            <Section style={ { alignItems: 'center' } }>
              <Column>
                <Text style={ { fontWeight: 600 } }>{barter.barterName || t('my package')}</Text>
                {barter.packageType && (
                  <Row style={ { justifyContent: 'flex-start', alignItems: 'center' } }>
                    <Icon style={ { paddingRight: 6 } } icon={ organizationRounded } size={ 20 } />
                    <Text>{organizationName}</Text>
                  </Row>
                )}
              </Column>

              <Column>
                <I18n as={ Label }>code</I18n>
                <Text>{exibitionCode || barterOrderNumber}</Text>
              </Column>

              {isPackage && Number(barter.price) >= 0 && (
                <Column>
                  <I18n as={ Label } params={ { howMany: 1 } }>price</I18n>
                  <Text>{barter.price} {barter.measureUnit}</Text>
                </Column>
              )}

              <Column>
                <I18n as={ Label }>launch</I18n>
                <Text>{moment(packageJoinMode ? barter.createdAt : barter.releaseDate, momentBackDateTimeFormat).format('DD/MM/YYYY')}</Text>
              </Column>

              {barter.cropName && (
                <Column>
                  <I18n as={ Label } params={ { howMany: 1 } }>crop</I18n>
                  <Text>{barter.cropName}</Text>
                </Column>
              )}

              {!isEmpty(barter.status) && (
                <Column>
                  <Badge
                    backgroundColorBadge={ barter.status.statusColor }
                    colorBadge={ barter.status.statusColor }
                  >
                    {barter.status.statusName}
                  </Badge>
                </Column>
              )}
            </Section>
            <Divisor style={ { marginTop: 10 } }/>

            <Section style={ { flexDirection: 'column', marginRight: 10, marginLeft: 10 } }>
              {map(barterProducts, (item, type) => (
                <div key={ type }>
                  <Row>
                    <Label>{capitalize(type)}</Label>
                  </Row>
                  {map(item, ({ productName, measureUnit, productQuantity, unitOfMeasuresForConversion, conversionFactor }, index) => (
                    <Row key={ index } style={ { justifyContent: 'space-between' } }>
                      <Row style={ { flex: 2 } }>
                        <Text>{productName}</Text>
                      </Row>
                      <Row style={ { flex: 1, justifyContent: 'flex-end' } }>
                        <Text style={ { paddingRight: 5 } }>{formatNumber(productQuantity)}</Text>
                        <Text>{secondaryUnit(unitOfMeasuresForConversion, conversionFactor, measureUnit)}</Text>
                      </Row>
                      {/* unitOfMeasuresForConversion + "de" + conversionFactor + measureUnit */}
                    </Row>
                  ))}
                  { type !== Object.keys(barterProducts)[size(barterProducts)-1] && (
                    <Divisor style={ { marginTop: 10, marginBottom: 10 } }/>
                  )}
                </div>
              ))}
            </Section>
          </>
        )}
    </Modal>
  )}

BarterDetailsModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  barterOrderNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  exibitionCode: PropTypes.string,
  FooterComponent: PropTypes.func.isRequired,
  packageJoinMode: PropTypes.bool
}

BarterDetailsModal.defaultProps = {
  onClose: () => {},
  packageJoinMode: false,
  exibitionCode: ''
}

export default BarterDetailsModal
