import React, { useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'

import filterWeightMilkControlSchema from '@smartcoop/forms/schemas/dairyFarm/filterWeightControl.schema'
import I18n, { useT } from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDateRange from '@smartcoop/web-components/InputDateRange'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputText from '@smartcoop/web-components/InputText'

import { Container, ButtonContainer } from './styles'

const FilterWeightControlForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()

  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterWeightMilkControlSchema }
        onSubmit={ handleSubmit }
      >
        <InputDateRange
          label={ t('date', { howMany: 1 }) }
          name="date"
          fullWidth
          defaultValue={ filters.date }
        />
        <InputText
          label={ t('earring') }
          name="earring"
          defaultValue={ filters?.earring }
        />
        <InputNumber
          label={ t('weight') }
          name="weight"
          defaultValue={ filters?.weight }
        />
        <InputNumber
          label={ t('height') }
          name="height"
          defaultValue={ filters?.height }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-order-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterWeightControlForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterWeightControlForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterWeightControlForm
