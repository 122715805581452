import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import soilPrepareCropManagementSchema from '@smartcoop/forms/schemas/management/soilPrepareCropManagement.schema'
import { useT } from '@smartcoop/i18n'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'

const SoilPrepareForm = forwardRef(({ defaultValues, handleSubmit, readOnly }, formRef) => {
  const t = useT()

  const soilPrepareOptions = [
    {
      label: 'Aração',
      value: 'Aração'
    },
    {
      label: 'Gradagem',
      value: 'Gradagem'
    },
    {
      label: 'Escarificação ',
      value: 'Escarificação'
    },
    {
      label: 'Sub-solagem',
      value: 'Sub-solagem'
    }
  ]
  return (
    <Form
      ref={ formRef }
      schemaConstructor={ soilPrepareCropManagementSchema }
      containerStyle={ { height: 'auto' } }
      onSubmit={ handleSubmit }
    >
      <InputSelect
        name="type"
        options={ soilPrepareOptions }
        label={ t('type') }
        fullWidth
        multiple
        defaultValue={ defaultValues?.type }
        disabled={ readOnly }
      />
    </Form>
  )
})

SoilPrepareForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  defaultValues: PropTypes.object
}

SoilPrepareForm.defaultProps = {
  readOnly: false,
  defaultValues: {}
}

export default SoilPrepareForm
