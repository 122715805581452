import React, {
  useState,
  useCallback
} from 'react'

import PropTypes from 'prop-types'


import { PaginationContext } from '../hooks/usePagination'

const PaginationProvider = ({ children }) => {
  const [page, setPage] = useState({})

  const onChangePage = useCallback(value => {
    setPage({ ...page, ...value })
  }, [page])

  const resetPages = useCallback(() => {
    setPage({})
  }, [])

  return (
    <PaginationContext.Provider value={ { page, setPage, onChangePage, resetPages } }>
      {children}
    </PaginationContext.Provider>
  )
}

PaginationProvider.propTypes = {
  children: PropTypes.any
}
PaginationProvider.defaultProps = {
  children: null
}
export default PaginationProvider
