import styled from 'styled-components'

export const ContainerList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  column-gap: 10px;
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  overflow-y: scroll;
  height: 90vh;
`

export const PrinterText = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: bold;
`
