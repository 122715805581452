import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'

/* Initial State */
const INITIAL_STATE = {
  operations: [],
  accredited: false,
  accrediteds: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  updateOfflineOperation: ['formData', 'operation', 'onSuccess', 'onError'],
  updateOperation: ['formData', 'operation', 'onSuccess', 'onError'],
  saveOfflineOperation: ['formData', 'operation', 'onSuccess', 'onError'],
  saveOperation: ['formData', 'operation', 'onSuccess', 'onError'],

  loadOperations: ['params', 'onSuccess', 'onError'],
  loadOperationsSuccess: ['operations', 'page'],

  checkAccredited: ['onSuccess', 'onError'],
  loadAccrediteds: ['params', 'onSuccess', 'onError'],
  loadAccreditedsSuccess: ['accrediteds', 'page'],
  requestAccreditment: ['onSuccess', 'onError'],
  editAccreditment: ['accreditmentId', 'params', 'onSuccess', 'onError'],
  deleteAccreditment: ['accreditmentId', 'reason', 'onSuccess', 'onError'],
  checkAccreditedSuccess: ['accredited'],

  deleteOfflineOperation: ['params', 'onSuccess', 'onError'],
  deleteOperation: ['params', 'onSuccess', 'onError'],

  operationsError: ['error']
})

markActionsOffline(Creators, [
  'saveOperation',
  'updateOperation',
  'deleteOperation'
])

const loadOperationsSuccess = (state = INITIAL_STATE, { operations }) => ({
  ...state,
  operations
})

const loadAccreditedsSuccess = (state = INITIAL_STATE, { accrediteds }) => ({
  ...state,
  accrediteds
})

const operationsError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const checkAccreditedSuccess = (state = INITIAL_STATE, { accredited }) => ({
  ...state,
  accredited
})

const logout = () => ({ ...INITIAL_STATE })
const resetAuthentication = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_OPERATIONS_SUCCESS]: loadOperationsSuccess,
  [Types.LOAD_ACCREDITEDS_SUCCESS]: loadAccreditedsSuccess,

  [Types.CHECK_ACCREDITED_SUCCESS]: checkAccreditedSuccess,

  [Types.OPERATIONS_ERROR]: operationsError,

  [AuthenticationTypes.LOGOUT]: logout,
  [AuthenticationTypes.RESET_AUTHENTICATION]: resetAuthentication
})

export {
  Types as OperationsTypes,
  Creators as OperationsActions
}
