// eslint-disable-next-line spaced-comment
export default ({ size, color = '#000' } = {}) => /*html*/`
  <svg width="${ size }" height="${ size }" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 512 512"  xml:space="preserve">
<style type="text/css">
	.st0{fill:${ color };}
</style>
<g>
	<path class="st0" d="M473.236,283.232L276.228,10.34C271.535,3.855,264.009,0,255.996,0c-8.013,0-15.539,3.855-20.222,10.34
		l-197,272.901c-4.975,7.692-4.78,17.622,0.497,25.1c5.277,7.478,14.546,11.022,23.464,8.938l96.465-22.53v184.81
		c-0.01,17.896,14.517,32.422,32.43,32.422l128.74,0.02c17.924-0.02,32.44-14.546,32.44-32.45V294.73l96.455,22.548
		c8.909,2.084,18.197-1.451,23.465-8.938C477.998,300.853,478.202,290.922,473.236,283.232z"/>
</g>
</svg>
`
