// eslint-disable-next-line spaced-comment
export default ({ size, color = '#ffd829' } = {}) => /*html*/`
<svg width="${ size }" height="${ size }" inkscape:version="1.0 (4035a4fb49, 2020-05-01)" sodipodi:docname="trofeu.svg" version="1.1" viewBox="0 0 40.055 46.776" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd">
 <sodipodi:namedview id="base" bordercolor="#666666" borderopacity="1.0" fit-margin-bottom="0" fit-margin-left="0" fit-margin-right="0" fit-margin-top="0" inkscape:current-layer="g1785" inkscape:cx="2.5301473" inkscape:cy="-26.224472" inkscape:document-rotation="0" inkscape:document-units="mm" inkscape:pageopacity="0.0" inkscape:pageshadow="2" inkscape:window-height="706" inkscape:window-maximized="0" inkscape:window-width="1350" inkscape:window-x="0" inkscape:window-y="0" inkscape:zoom="0.86604597" pagecolor="#ffffff" showgrid="false"/>
 <metadata>
  <rdf:RDF>
   <cc:Work rdf:about="">
    <dc:format>image/svg+xml</dc:format>
    <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
    <dc:title/>
   </cc:Work>
  </rdf:RDF>
 </metadata>
 <g transform="translate(-5.6341 -2.1624)" inkscape:groupmode="layer" inkscape:label="Layer 1">
  <g transform="matrix(.34694 0 0 .34694 85.465 48.756)">
   <g transform="matrix(.099557 0 0 .099557 12.728 14.456)"></g>
   <g transform="matrix(2.3769 0 0 2.3769 286.02 163.03)">
    <path d="m-194.26-89.728 4.4008-0.3533 4.2301 8.6437 1.2043 5.6464 5.3938 0.64727 1.2945 4.7465-2.3733 0.32363-27.724 0.75513 0.4315-4.4229 5.0702-1.2945 0.9709-2.6969 0.52087-4.5906z" fill="${ color }" stroke="#000" stroke-width=".11079px" sodipodi:nodetypes="ccccccccccccc"/>
    <path d="m-207.71-122.5 0.68654 10.679 2.5172 10.603 5.2633 8.0094 5.4921 2.0595 6.1024-0.76278 3.4326-3.6614 4.2717-7.1703 2.0595-10.45-0.0762-9.3061-0.30509-1.2968-29.139 0.22899z" fill="${ color }" stroke="#000" stroke-width=".11079px"/>
    <g transform="matrix(.11079 0 0 .11079 -221.21 -125.09)">
     <path d="m466.45 49.374c-7.065-8.308-17.368-13.071-28.267-13.071h-35.773v-11.19c0-13.847-11.267-25.113-25.113-25.113h-242.59c-13.848 0-25.112 11.266-25.112 25.112v11.19h-35.777c-10.899 0-21.203 4.764-28.267 13.071-6.992 8.221-10.014 19.019-8.289 29.624 9.4 57.8 45.775 108.86 97.4 136.87 4.717 11.341 10.059 22.083 16.008 32.091 19.002 31.975 42.625 54.073 68.627 64.76 2.635 26.644-15.094 51.885-41.794 57.9-0.057 0.013-0.097 0.033-0.153 0.046-5.211 1.245-9.09 5.921-9.09 11.513v54.363h-21.986c-19.602 0-35.549 15.947-35.549 35.549v28.058c0 6.545 5.305 11.85 11.85 11.85h267.99c6.545 0 11.85-5.305 11.85-11.85v-28.058c0-19.602-15.947-35.549-35.549-35.549h-21.988v-54.362c0-5.603-3.893-10.286-9.118-11.52-0.049-0.012-0.096-0.028-0.145-0.04-26.902-6.055-44.664-31.55-41.752-58.394 25.548-10.86 48.757-32.761 67.479-64.264 5.949-10.009 11.29-20.752 16.008-32.095 51.622-28.01 87.995-79.072 97.395-136.87 1.725-10.605-1.297-21.402-8.29-29.623zm-405.8 25.818c-0.616-3.787 0.431-7.504 2.949-10.466 2.555-3.004 6.277-4.726 10.214-4.726h35.777v21.802c0 34.186 4.363 67.3 12.632 97.583-32.496-25.679-54.87-62.982-61.572-104.19zm306.21 385.05c6.534 0 11.85 5.316 11.85 11.85v16.208h-244.29v-16.208c0-6.534 5.316-11.85 11.85-11.85zm-45.688-66.213v42.513h-129.21v-42.513zm-98.136-23.699c2.929-3.224 5.607-6.719 8.002-10.46 7.897-12.339 12.042-26.357 12.228-40.674 4.209 0.573 8.457 0.88 12.741 0.88 4.661 0 9.279-0.358 13.852-1.036 0.27 19.239 7.758 37.45 20.349 51.289h-67.172zm155.67-288.53c0 58.379-13.406 113.09-37.747 154.05-23.192 39.03-53.364 60.525-84.956 60.525-31.597 0-61.771-21.494-84.966-60.523-24.342-40.961-37.748-95.671-37.748-154.05v-56.693c0-0.78 0.634-1.413 1.412-1.413h242.59c0.78 0 1.414 0.634 1.414 1.413zm72.639-6.611c-6.702 41.208-29.074 78.51-61.569 104.19 8.268-30.283 12.631-63.395 12.631-97.58v-21.802h35.773c3.938 0 7.66 1.723 10.214 4.726 2.518 2.961 3.566 6.678 2.951 10.465z"/>
    </g>
   </g>
  </g>
  <g transform="matrix(.021965 0 0 .021965 91.6 32.126)"></g>
 </g>
</svg>
`
