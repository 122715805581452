import React, {
  useMemo,
  useCallback
} from 'react'

import withObservables from '@nozbe/with-observables'
import moment from 'moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import Grid from '@material-ui/core/Grid'

import { animalService } from '@smartcoop/database/services/animalService'
import { database } from '@smartcoop/database/web-database'
import registerProtocolSchema from '@smartcoop/forms/schemas/dairyFarm/registerProtocol.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { colors } from '@smartcoop/styles'
import { AnimalStatusCode, PROTOCOL_TYPES } from '@smartcoop/utils/constants'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputSelect from '@smartcoop/web-components/InputSelect'

import {
  Container,
  ButtonsContainer,
  FormContainer,
  Item
} from './styles'

const RegisterProtocolForm = (props) => {
  const { defaultValues, onSubmit, onCancel, loading, animalsList, formRef } = props


  const animals = useMemo(() => {
    const filteredAnimals = filter(animalsList, item => (item?.category === 'vaca' ||
      item?.category === 'novilha') &&
      (toString(item?.statusId) === AnimalStatusCode.VAZIA ||
        toString(item?.statusId) === AnimalStatusCode.APTAS ||
        toString(item?.statusId) === AnimalStatusCode.PEV ||
        toString(item?.statusId) === AnimalStatusCode.NENHUM ||
        item?.animalId === defaultValues?.animal?.id
      ))

    return orderBy(map(filteredAnimals, item => ({ label: item?.earringCode, value: item?.animalId, animal: item })), item => isNaN(toNumber(item?.label)) ? item?.label : toNumber(item?.label))
  }, [animalsList, defaultValues])

  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...defaultValues,
        ...data
      })
    },
    [defaultValues, onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerProtocolSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputSelect
                label={ t('earrings') }
                name="animalsIds"
                options={ animals }
                multiple
                defaultValue={ [defaultValues?.animal?.id] }
                disabled={ !!defaultValues?.id }
              />
            </Item>
            <Item>
              <InputSelect
                label={ t('type') }
                name="type"
                fullWidth
                options={ PROTOCOL_TYPES }
                defaultValue={ defaultValues?.type }
              />
            </Item>
            <Item>
              <InputDate
                label={ t('protocol date') }
                name="protocolDate"
                fullWidth
                defaultValue={ defaultValues.protocolDate }
                pickerProps={ {
                  maxDate: moment().format()
                } }
              />
            </Item>
            <Item>
              <InputNumber
                name="numberOfDays"
                label={ t('Days') }
                maxLength={ 2 }
                fullWidth
                defaultValue={ defaultValues?.numberOfDays }
              />
            </Item>
          </Grid>
        </FormContainer>
        <ButtonsContainer>
          <Button
            id="web-cancel-form-button"
            onClick={ onCancel }
            color={ colors.white }
            disabled={ loading }
            fullWidth
            style={ { marginLeft: 10, marginRight: 10 } }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="web-save-form-button"
            onClick={ () => formRef.current.submit() }
            disabled={ loading }
            fullWidth
            style={ { marginLeft: 10, marginRight: 10 } }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  )
}

RegisterProtocolForm.propTypes = {
  loading: PropTypes.bool,
  animalsList: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  defaultValues: PropTypes.object.isRequired,
  formRef: PropTypes.object.isRequired
}

RegisterProtocolForm.defaultProps = {
  loading: false,
  animalsList: [],
  onSubmit: () => {},
  onCancel: () => {}
}

const enhance = withObservables(['propertyId'], ({ propertyId }) => ({
  animalsList: animalService(database).observeAnimals(propertyId)
}))

const EnhancedRegisterProtocolForm = enhance(RegisterProtocolForm)

export default EnhancedRegisterProtocolForm