import styled from 'styled-components'

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 30px;
`
export const SpreadSheetContainer = styled.div`
  overflow: scroll;
  max-width: 80vw;
  max-height: 50vh;
`
export const AlertText = styled.span`
  color: red;
  font-size: 0.8rem;
`
