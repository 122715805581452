import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const ContainerDataUser = styled.div`
  display: flex;
  width: 500px;
  padding-top: 20px;
  padding-bottom: 20px;
`

export const LabelText = styled.p`
  font-size: 14px;
  padding-right: 100px;
  color: ${ colors.mutedText };
  margin: 0;
`
export const Row = styled.div`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const TextUser = styled.p`
  font-size: 16px;
  color: ${ colors.text };
  margin: 5px 0 15px;
`
