import hexToRgba from 'hex-to-rgba'
import styled from 'styled-components'

import filter from 'lodash/filter'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import points from '@smartcoop/styles/assets/images/points.svg'
import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'
import Button from '@smartcoop/web-components/Button'

export default makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    overflow: 'overlay',
    backgroundColor: colors.black
  },
  contentScroll: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    minHeight: '100%',
    zIndex: 5
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 1
  },
  backgroundImageLeftContainer: {
    flex: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end'
  },
  backgroundImageRightContainer: {
    flex: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start'
  },
  backgroundImage: {
    width: '100%'
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

export const Content = styled.div`
  display: grid;
  grid-template-columns: ${ ({ children }) => `repeat(${ (filter(children, (item) => item)).length }, 1fr)` };
  grid-gap: 30px;
  margin: 20px 0 40px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const Title = styled(Typography).attrs({
  variant: 'h6'
})`
  color: ${ colors.white };
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: ${ fonts.fontWeight.bold };
  font-size: 23px;
  line-height: 1.3em;
`

export const ButtonCard = styled(Button)`
  background-color: ${ colors.blackLight };
  padding: 50px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-image: url("${ points }");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &:hover {
    background-color: ${ hexToRgba(colors.blackLight, 0.6) };
  }
`

export const ButtonCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: start;
`

export const ButtonCardTitle = styled(Typography).attrs({
  variant: 'h5'
})`
  padding: 32px 0 5px;
  color: ${ colors.white };
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: ${ fonts.fontWeight.bold };
  font-size: 25px;
`
