import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  padding: 10px 12px;
  width: 350px;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-bottom: 10px;
`

export const Title = styled(Typography).attrs()`
  font-size: ${ fonts.fontSize.M }px;
  font-family: ${ fonts.fontFamilyMontserrat };
  font-weight: ${ fonts.fontWeight.bold };
`

export const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  flex-direction: ${ ({ reverse }) => reverse ? 'row-reverse' : 'row' };
`

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
  padding: 0 5px;
`
