import { call, put, takeLatest } from 'redux-saga/effects'

import { getCropManagement, addManagementFiles as addManagementFilesService, editManagementFiles as editManagementFilesService } from '@smartcoop/services/apis/smartcoopApi/resources/cropManagement'

import { CropManagementActions, CropManagementTypes } from './duckCropManagement'

function* loadCropManagement({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { growingSeasonId, cropManagementId, externalCropManagementData } = params
    const { data } = yield call(getCropManagement, {}, { growingSeasonId, cropManagementId } )

    const cropManagement = {
      ...externalCropManagementData,
      ...data
    }

    yield put(CropManagementActions.loadCropManagementSuccess(
      cropManagement,
      () => onSuccess()
    ))
  } catch (err) {
    const error = err.message
    yield put(CropManagementActions.cropManagementError(error))
    yield call(onError, error)
  }
}

function* loadCropManagementSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}


function* addManagementFiles({ params, managementId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(addManagementFilesService, params, { managementId })
    yield call(onSuccess)
  } catch (error) {
    yield put(CropManagementActions.cropManagementError(error))
    yield call(onError, error)
  }
}

function* editManagementFiles({ params, managementId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(editManagementFilesService, { oldFiles: params }, { managementId })
    yield call(onSuccess)
  } catch (error) {
    yield put(CropManagementActions.cropManagementError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(CropManagementTypes.LOAD_CROP_MANAGEMENT, loadCropManagement),
  takeLatest(CropManagementTypes.ADD_MANAGEMENT_FILES, addManagementFiles),
  takeLatest(CropManagementTypes.EDIT_MANAGEMENT_FILES, editManagementFiles),
  takeLatest(CropManagementTypes.LOAD_CROP_MANAGEMENT_SUCCESS, loadCropManagementSuccess)

]
