import { tableSchema } from '@nozbe/watermelondb'


export const groupSchema = tableSchema({
  name: 'group',
  columns: [
    { name: 'groupId', type: 'string' },
    { name: 'publicKey', type: 'string' },
    { name: 'name', type: 'string' },
    { name: 'photo', type: 'string' },
    { name: 'isBroadcast', type: 'boolean' }
  ]
})
