import api from '../api'

const URL_DEFAULT = '/suppliers/:supplierId/products'

export const getSupplierProducts = async (params, { supplierId }) =>
  api.get(`/v1${ URL_DEFAULT.replace(':supplierId', supplierId) }`, params)

export const getSupplierByProduct = async ({ productId }) =>
  api.get(`/v1/suppliers/product/${ productId }`)

export const postSupplierProducts = async (params, { supplierId }) =>
  api.post(`/v1${ URL_DEFAULT.replace(':supplierId', supplierId) }`, params)

export const deleteSupplierProduct = async (params, { supplierId, productId }) =>
  api.delete(`/v1/suppliers/${ supplierId }/products/${ productId }`, params)
