import * as Yup from 'yup'

import date from '@smartcoop/forms/validators/date.validator'

const filterPev = ({ t }) => Yup.object().shape({
  registryDate: date({ t })(Yup.string()),

  reason: Yup.string(),

  able: Yup.string().nullable()
})

export default filterPev
