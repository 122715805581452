/* eslint-disable prefer-template */
/* eslint-disable template-curly-spacing */

import React, { useCallback, forwardRef, useState, useMemo, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { useDialog } from '@smartcoop/dialog'
import managementSchemaRegister from '@smartcoop/forms/schemas/management/managementRegister.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { noImage } from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { getOpenGrowingSeasonsByProperty } from '@smartcoop/services/apis/smartcoopApi/resources/growingSeasons'
import { getManagementOperations } from '@smartcoop/services/apis/smartcoopApi/resources/managementOperations'
import { useSnackbar } from '@smartcoop/snackbar'
import { CropManagementActions } from '@smartcoop/stores/cropManagement'
import { FieldActions } from '@smartcoop/stores/field'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputFile from '@smartcoop/web-components/InputFile'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import ThumbnailImage from '@smartcoop/web-components/ThumbnailImage'
import CorrectionForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/CorrectionForm'
import FertilizationForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/FertilizationForm'
import GrazingForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/GrazingForm'
import HarvestForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/HarvestForm'
import HayProductionForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/HayProductionForm'
import IrrigationForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/IrrigationForm'
import OthersForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/OthersForm'
import PhytosanitaryApplicationForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/PhytosanitaryApplicationForm/PhytosanitaryApplicationForm'
import SeedingForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/SeedingForm'
import SeedTreatmentForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/SeedTreatmentForm'
import SilageProductionForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/SilageProductionForm'
import SoilPrepareForm from '@smartcoop/web-containers/forms/digitalProperty/management/ManagementForm/SoilPrepareForm'
import useFile from '@smartcoop/web-containers/hooks/useFile'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container, RowTitle, Title, Gallery, CheckboxContainer } from './styles'

const ManagementForm = forwardRef((props, formRef) => {
  const {
    onSuccess,
    handleClose,
    growingSeason,
    isPlanned,
    isModal,
    externalGrowingSeasons,
    onSubmit,
    onChangeOperation,
    externalDefaultValues,
    onChangeApplyToManagement,
    applyToManagement,
    area,
    producer,
    propertyId: externalPropertyId,
    readOnly
  } = props
  const location = isModal ? {} : useLocation()
  const cropManagementData = isModal ? externalDefaultValues : location?.state?.cropManagementData
  const isEdit = isModal ? externalDefaultValues?.id || externalDefaultValues?.mockedId : location?.state?.isEdit

  const dispatch = useCallback(useDispatch(), [])
  const t = useT()
  const childFormRef = useRef(null)
  const mounted = useRef(false)
  const propertyId = useSelector(selectCurrentPropertyId)
  const isTechnical = useSelector(selectModuleIsTechnical)
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()

  const defaultValues = isEdit ? {
    productGroupSlug: cropManagementData?.operation?.slug,
    operationSlug: cropManagementData?.operation?.slug,
    observations: cropManagementData?.observations,
    concludeManagement: !!cropManagementData?.realizationDate,
    predictedDate: cropManagementData?.predictedDate,
    realizationDate: cropManagementData?.realizationDate,
    operation: cropManagementData?.operation,
    bulkId: cropManagementData?.bulkId
  } : null

  const {
    selectedFiles,
    receivedFiles,
    isEmpty: isEmptyFiles,
    handleAdd,
    createFormData,
    handleRemove
  } = useFile([], cropManagementData?.cropsManagementFiles ?? [])

  const matches = useMediaQuery('(max-height:899px)')

  const [operationSlug, setOperationSlug] = useState(defaultValues?.operationSlug || '')
  const [isValidChildForm, setIsValidChildForm] = useState(false)
  const [isValidMainForm, setIsValidMainForm] = useState(false)
  const [concludeManagement, setConcludeManagement] = useState(defaultValues?.concludeManagement || false)
  const [growingSeasons, setGrowingSeasons] = useState(null)
  const [managementFormFiles, setManagementFormFiles] = useState(null)
  const [operationsOptions, setOperationsOptions] = useState([])
  const [currentGrowingSeasons, setCurrentGrowingSeasons] = useState([growingSeason?.id])
  const [predictedDate, setPredictedDate] = useState(defaultValues?.predictedDate || moment().format(momentBackDateFormat))
  const [blockedSave, setBlockedSave] = useState(false)

  const OperationSelected = useMemo(
    () => ({
      'semeadura': SeedingForm,
      'fertilizacao': FertilizationForm,
      'correcao-de-solo': CorrectionForm,
      'aplicacao-fitossanitaria': PhytosanitaryApplicationForm,
      'tratamento-de-sementes': SeedTreatmentForm,
      'colheita': HarvestForm,
      'irrigacao': IrrigationForm,
      'producao-de-feno': HayProductionForm,
      'pastejo': GrazingForm,
      'outros': OthersForm,
      'preparo-de-solo': SoilPrepareForm,
      'producao-de-silagem':SilageProductionForm
    }[operationSlug]),
    [operationSlug]
  )

  const equalCrops = useMemo(() => {
    const crops = map(externalGrowingSeasons, item => item?.cropId)
    return crops.every(val => val === crops[0])
  },[externalGrowingSeasons])

  const modalBlockSave = useMemo(() => {
    if(!isModal) {
      return false
    }
    if(isModal && (operationSlug === 'semeadura' || operationSlug === 'colheita' || operationSlug === 'producao-de-silagem' || operationSlug === 'producao-de-feno') && !equalCrops){
      snackbar.error(t('all crops must be the same for operation seeding'))
      return true
    }
    return false
  }, [equalCrops, isModal, operationSlug, snackbar, t])

  useEffect(() => {
    const getGrowingSeasons = async () => {
      const { data } = await getOpenGrowingSeasonsByProperty({ propertyId: externalPropertyId || propertyId, bulkId: defaultValues?.bulkId || null })

      switch(operationSlug) {
        case 'semeadura':
        case 'colheita':
        case 'producao-de-silagem':
        case 'producao-de-feno': {
          setCurrentGrowingSeasons([growingSeason.id])
          const filteredDataByCrop = filter(data, item => item?.cropId === growingSeason?.cropId)
          setGrowingSeasons(map(filteredDataByCrop, item => {
            const childrenPolygon = find(item?.field?.childrenPolygons, polygon => polygon.id === item.childrenPolygonId)
            return (
              {
                label: `${ item.field.fieldName } ${!isEmpty(childrenPolygon) ? ('- ' + childrenPolygon.name) : ''} - ${item.crop.description}/${ item.sowingYear }`,
                value: item.id
              }
            )
          }))
          break
        }
        default: {

          setGrowingSeasons(map(data, item => {
            const childrenPolygon = find(item?.field?.childrenPolygons, polygon => polygon.id === item.childrenPolygonId)
            return (
              {
                label: `${ item?.field?.fieldName } ${!isEmpty(childrenPolygon) ? ( '- ' + childrenPolygon.name) : ''} - ${item?.crop?.description}/${ item?.sowingYear }`,
                value: item.id
              }
            )
          }))
          break
        }
      }

    }
    getGrowingSeasons()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[operationSlug])

  useEffect(() => {
    const getOperationsOptions = async () => {
      const { data: { data } } = await getManagementOperations({ propertyId, bulkId: defaultValues?.bulkId || null })

      setOperationsOptions(data)
    }
    getOperationsOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[operationSlug])

  const childHandleSubmit = useCallback((data) => {
    setIsValidChildForm(data)
  },[setIsValidChildForm])


  useEffect(() => {
    if(mounted.current) {
      if(isValidMainForm && isValidChildForm) {
        setBlockedSave(true)
      }
    }
  },[isValidChildForm, isValidMainForm, operationSlug])

  useEffect(() => {
    if(mounted.current) {
      if(isValidMainForm && !isEmpty(operationSlug) && (isValidChildForm || operationSlug === 'rolagem' || operationSlug === 'rocada')) {

        const mainFormData = formRef.current.getData()
        const childFormData = operationSlug === 'rolagem' || operationSlug === 'rocada' ? {} : isValidChildForm

        if(isModal) {
          if(operationSlug === 'semeadura' && !cropManagementData?.id) {
            map(childFormData, (data, index) => {
              onSubmit({
                growingSeasonIds: map(externalGrowingSeasons, item => item?.id),
                cropManagementId: cropManagementData?.id,
                // eslint-disable-next-line no-nested-ternary
                operationSlug: index,
                operation: find(operationsOptions, item => item.slug === index),
                predictedDate: mainFormData.predictedDate,
                observations: mainFormData?.observations,
                items: data,
                bulkId: map(externalGrowingSeasons, item => item?.id).length > 1 ? defaultValues?.bulkId : null,
                id: cropManagementData?.id
              })
            }
            )
          } else {
            onSubmit({
              growingSeasonIds: map(externalGrowingSeasons, item => item?.id),
              cropManagementId: cropManagementData?.id,
              operationSlug,
              operation: find(operationsOptions, item => item.slug === operationSlug),
              predictedDate: mainFormData.predictedDate,
              observations: mainFormData?.observations,
              items: childFormData,
              bulkId: map(externalGrowingSeasons, item => item?.id).length > 1 ? defaultValues?.bulkId : null,
              id: cropManagementData?.id
            })
          }
          handleClose()
        } else {
          dispatch(FieldActions.saveOfflineCropManagement(
            operationSlug === 'semeadura' && !cropManagementData?.id ? map(childFormData, (data, index) => ({
              growingSeasonIds: currentGrowingSeasons.length > 1 ? currentGrowingSeasons : !isEdit && currentGrowingSeasons,
              cropManagementId: (isEdit && currentGrowingSeasons.length === 1) ? cropManagementData?.id : null,
              // eslint-disable-next-line no-nested-ternary
              operationSlug: index,
              predictedDate: mainFormData.predictedDate,
              realizationDate: concludeManagement ? mainFormData?.realizationDate : null,
              observations: mainFormData?.observations,
              items: data,
              bulkId: currentGrowingSeasons.length > 1 ? defaultValues?.bulkId : null,
              id: cropManagementData?.id
            })) : {
              growingSeasonIds: currentGrowingSeasons.length > 1 ? currentGrowingSeasons : !isEdit && currentGrowingSeasons,
              cropManagementId: (isEdit && currentGrowingSeasons.length === 1) ? cropManagementData?.id : null,
              operationSlug,
              predictedDate: mainFormData.predictedDate,
              realizationDate: concludeManagement ? mainFormData?.realizationDate : null,
              observations: mainFormData?.observations,
              items: childFormData,
              bulkId: currentGrowingSeasons.length > 1 ? defaultValues?.bulkId : null,
              id: cropManagementData?.id
            },
            (management) => {
              submitOldFiles(management?.data?.[0] || management)
            },
            () => {
              setBlockedSave(false)
            },
            true
          ))
        }

        setIsValidChildForm(false)
        setIsValidMainForm(false)
      }
    }
    else {
      mounted.current = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidMainForm, isValidChildForm])

  const submitNewFiles = useCallback(
    (management, operation) => {
      if (isEmpty(selectedFiles)) {
        onSuccess(operation)
      } else {
        dispatch(CropManagementActions.addManagementFiles(createFormData('uploads'), management.id,
          () => onSuccess(operation)
        ))
      }
    },
    [createFormData, dispatch, onSuccess, selectedFiles]
  )

  const submitOldFiles = useCallback(
    (management) => {
      dispatch(CropManagementActions.editManagementFiles(receivedFiles, management.id,
        () => submitNewFiles(management, 'update')
      ))
    },
    [dispatch, receivedFiles, submitNewFiles]
  )

  const handleSubmit = useCallback(
    () => {
      setIsValidMainForm(true)
    },
    []
  )


  const handleOperationChange = useCallback(
    (selectedItem) => {
      setOperationSlug(selectedItem.target.value)
      onChangeOperation(selectedItem.target.value)
    },
    [onChangeOperation]
  )

  const handleClickClose = useCallback(
    (list, removedFile) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => handleRemove(list, removedFile),
          textWarning: t('are you sure you want to delete this image?')
        }
      })
    },
    [createDialog, handleRemove, t]
  )

  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_CROP_MANAGEMENT_IMAGES_ID, fileKey: file?.fileKey })

    return downloadFromBase64(data)
  }, [])

  useEffect(() => {
    const getRenderedFiles = async() => {
      const receivedElements = await Promise.all(map(receivedFiles, async(file) => {
        const fileSrc = await renderFile(file)
        return (
          <ThumbnailImage
            key={ file.id }
            src={ fileSrc }
            onClose={ () => handleClickClose('receivedFiles', file) }
          />
        )}).concat(
        map(selectedFiles, (file, index) => (
          <ThumbnailImage
            key={ `${ index }-${ file.name }` }
            src={ URL.createObjectURL(file) }
            onClose={ () => handleClickClose('selectedFiles', file) }
          />
        ))
      ))
      setManagementFormFiles(receivedElements)
    }
    getRenderedFiles()
  },[handleClickClose, receivedFiles, renderFile, selectedFiles]  )

  const recommendationOptions = useMemo(
    () => producer?.proprietaryTechnician?.read ? [
      {
        label: t('recommend'),
        value: false
      },
      {
        label: t('schedule to field'),
        value: true
      }
    ] : [
      {
        label: t('recommend'),
        value: false
      }
    ],
    [producer, t]
  )

  return (
    <Container>
      <Form
        ref={ formRef }
        schemaConstructor={ managementSchemaRegister }
        onSubmit={ handleSubmit }
        containerStyle={ { height: 'auto' } }
        schemaProps={ { closed: concludeManagement } }
      >
        <InputDate
          name="predictedDate"
          label={ t('predicted date') }
          fullWidth
          onChange={ setPredictedDate }
          defaultValue={ defaultValues?.predictedDate || moment().format(momentBackDateFormat) }
          disabled={ readOnly }
        />

        <InputSelect
          label={ t('operation') }
          name="operationId"
          options={ map(operationsOptions, item => ({ label: item?.name, value: item?.slug })) }
          onChange={ handleOperationChange }
          defaultValue={ defaultValues?.operation?.slug }
          disabled={ readOnly }
        />

        {!modalBlockSave && !isEmpty(operationSlug) && operationSlug !== 'rolagem' && operationSlug !== 'rocada' && (
          <OperationSelected
            ref={ childFormRef }
            handleSubmit={ childHandleSubmit }
            defaultValues={ cropManagementData?.cropManagementItem?.items || cropManagementData?.items }
            growingSeason={ isModal ? externalGrowingSeasons[0] : growingSeason }
            isModal={ isModal }
            readOnly={ readOnly }
            area={ area }
          />
        )}

        <InputText
          name="observations"
          label={ t('observation', { howMany: 2 }) }
          fullWidth
          multiline
          rows={ 5 }
          defaultValue={ defaultValues?.observations }
          disabled={ readOnly }
        />

        {!isModal && (
          <>
            <CheckboxButton
              value={ concludeManagement }
              checked={ concludeManagement }
              onChange={ () => setConcludeManagement(!concludeManagement) }
              label={ t('concluded management') }
              disabled={ isPlanned }
            />
            {concludeManagement && (
              <>
                <InputDate
                  name="realizationDate"
                  label={ t('realization date') }
                  fullWidth
                  defaultValue={ defaultValues?.realizationDate || predictedDate }
                />
              </>
            )}
            <div style={ { fontSize: 14, fontWeight: 600, marginBottom: 15 } }>
              <I18n>apply to fields</I18n>
            </div>
            <InputSelect
              checkBoxSelectAll
              multiple
              detached
              label="Selecione"
              options={ growingSeasons }
              value={ currentGrowingSeasons }
              onChange={ setCurrentGrowingSeasons }
              disabled={ isEdit && !defaultValues?.bulkId }
              defaultValue={
                [{
                  label: `${ growingSeason?.field?.fieldName } - ${ growingSeason?.sowingYear }`,
                  value: growingSeason?.id
                }]
              }
            />
          </>
        )}
      </Form>
      {!isModal && (
        <>
          <RowTitle>
            <Title style={ { fontSize: 18, fontWeight: 600, margin: 0 } }>
              <I18n params={ { howMany: 2 } }>image</I18n>
            </Title>
            {!isEmptyFiles &&
          <InputFile
            idInput="contained-btn-file"
            onChange={ handleAdd }
            inputProps={ {
              accept: 'image/png,image/jpeg,image/tiff',
              multiple: true
            } }
            buttonProps={ {
              id: 'attach-docs',
              color: 'secondary'
            } }
            maxNumberFile={ 6 - (receivedFiles.length + selectedFiles.length) }
            maxNumberFileDisplay={ 6 }
          >
            <I18n>add images</I18n>
          </InputFile>
            }
          </RowTitle>
          <Gallery isCenter={ isEmptyFiles }>
            {isEmptyFiles ?
              <EmptyState
                text={ t('no image added') }
                icon={ noImage }
                isInputFile
                maxNumberFile={ 6 - (receivedFiles.length + selectedFiles.length) }
                maxNumberFileDisplay={ 6 }
                file={ {
                  idInput: 'contained-button-file',
                  inputProps: {
                    accept: 'image/png,image/jpeg,image/tiff',
                    multiple: true
                  }
                } }
                iconProps={
                  matches ? {
                    size: 0
                  } : { size: 100 } }
                action={ {
                  text: t('add images') ,
                  onClick: handleAdd
                } }
                buttonProps={ {
                  id: 'attach-documents',
                  color: 'secondary'
                } }
              />
              :
              managementFormFiles
            }
          </Gallery>
        </>
      )}

      {onChangeApplyToManagement && isTechnical && (
        <CheckboxContainer>
          <RadioGroup
            detached
            onChange={ onChangeApplyToManagement }
            style={ { width: '100%' } }
            containerStyle={ { width: '100%', justifyContent: 'center' } }
            itemStyle={ { width: '48%' } }
            options={ recommendationOptions }
            variant="row"
            value={ applyToManagement }
            disabled={ readOnly }
          />
        </CheckboxContainer>
      )}
      {readOnly ? null : (
        <ButtonsContainer style={ { paddingTop: 10 } }>
          <Button
            id="cancel-crop-management-form"
            onClick={ handleClose }
            style={ { flex: 1 } }
            variant="outlined"
          >
            <I18n>cancel</I18n>
          </Button>

          <div style={ { width: '10%' } } />

          <Button
            id="submit-crop-management-form"
            onClick={ () => {
              if(includes(currentGrowingSeasons, growingSeason?.id)) {
                formRef.current.submit()
                if(!isEmpty(operationSlug) && operationSlug !== 'rolagem' && operationSlug !== 'rocada') {
                  childFormRef.current.submit()
                }
              } else {
                snackbar.error(
                  t('missing current field')
                )
              }
            } }
            style={ { flex: 1 } }
            disabled={ modalBlockSave || isEmpty(currentGrowingSeasons) || blockedSave }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      )}
    </Container>
  )
})

ManagementForm.propTypes = {
  onSuccess: PropTypes.func,
  handleClose: PropTypes.function,
  onSubmit: PropTypes.function,
  onChangeOperation: PropTypes.function,
  onChangeApplyToManagement: PropTypes.function,
  applyToManagement: PropTypes.bool,
  isPlanned: PropTypes.bool,
  externalGrowingSeasons: PropTypes.array,
  isModal: PropTypes.bool,
  readOnly: PropTypes.bool,
  producer: PropTypes.object,
  propertyId: PropTypes.string,
  growingSeason: PropTypes.object,
  area: PropTypes.number.isRequired,
  externalDefaultValues: PropTypes.object
}

ManagementForm.defaultProps = {
  onSuccess: () => {},
  externalGrowingSeasons: [],
  handleClose: () => {},
  onChangeApplyToManagement: null,
  readOnly: false,
  applyToManagement: null,
  propertyId: null,
  onChangeOperation: () => {},
  onSubmit: null,
  isPlanned: false,
  isModal: false,
  growingSeason: {},
  producer: {},
  externalDefaultValues: {}
}

export default ManagementForm
