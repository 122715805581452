import { useState, useCallback, useMemo } from 'react'

import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'

const useFile = (initialSelectedFiles = [], initialReceivedFiles = []) => {
  const [selectedFiles, setSelectedFiles] = useState(initialSelectedFiles)
  const [receivedFiles, setReceivedFiles] = useState(initialReceivedFiles)

  const isEmptyFiles = useMemo(
    () => isEmpty(receivedFiles) && isEmpty(selectedFiles),
    [receivedFiles, selectedFiles]
  )

  const handleAdd = useCallback(
    (event) => {
      const { files } = event.target
      const filesArr = Array.prototype.slice.call(files)
      setSelectedFiles((state) => state.concat(filesArr))
    },
    []
  )
  const handleAddTitledDocument = useCallback(async (event, type, setFilesFunction = undefined) => {
    const { files } = event?.target
    const filesArr = Array.prototype.slice.call(files)

    const newDocument = { type, file: filesArr[0] }
    if(isFunction(setFilesFunction)) {
      setFilesFunction(old => [...filter([...old], item => item?.type !== newDocument?.type), newDocument])
    }
    setSelectedFiles(old => [...filter([...old], item => item?.type !== newDocument?.type), newDocument])
  },[])

  const createFormData = useCallback(
    (fileKey = 'uploads', body = {}) => {
      const data = new FormData()

      forEach(selectedFiles, (file) => {
        data.append(fileKey, file)
      })


      Object.keys(body).forEach((key) => {
        data.append(key, body[key])
      })


      return data
    },
    [selectedFiles]
  )

  const handleRemove = useCallback(
    (list, removedFile) => {
      if (list === 'selectedFiles') {
        setSelectedFiles((state) => filter(state, (file) => ( file !== removedFile )))
      } else {
        setReceivedFiles((state) => filter(state, (file) => ( file.id !== removedFile.id )))
      }
    },
    []
  )

  const clearFiles = useCallback(() => {
    setSelectedFiles(initialSelectedFiles)
    setReceivedFiles(initialReceivedFiles)
  },[initialReceivedFiles, initialSelectedFiles])

  return {
    selectedFiles,
    receivedFiles,
    isEmpty: isEmptyFiles,
    handleAdd,
    handleAddTitledDocument,
    createFormData,
    handleRemove,
    clearFiles
  }
}

export default useFile
