import React, { useCallback, forwardRef, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import trimMask from '@meta-awesome/functions/src/trimMask'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'


import ncmMask from '@smartcoop/forms/masks/ncm.mask'
import registerProductSchema from '@smartcoop/forms/schemas/shoppingPlatform/product/registerProduct.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { plus } from '@smartcoop/icons'
import { getSuppliers as getSuppliersService } from '@smartcoop/services/apis/smartcoopApi/resources/organization'
import { getProductGroups as getProductGroupsService } from '@smartcoop/services/apis/smartcoopApi/resources/productGroup'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectUserProfileIsSmartcoop } from '@smartcoop/stores/authentication/selectorAuthentication'
import { ProductsActions } from '@smartcoop/stores/products'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import {
  Item,
  Title
} from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'

import {
  Row,
  ButtonContainer,
  Header,
  Container,
  CustomIconButton,
  ButtonLabel
} from './styles'

const RegisterProductForm = forwardRef((props, formRef) => {
  const { onSuccess, onCancel } = props

  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [attribute, setAttribute] = useState('')
  const [value, setValue] = useState('')
  const [suppliers, setSuppliers] = useState([])

  const userProfileIsSmartcoop = useSelector(selectUserProfileIsSmartcoop)

  const options = useMemo(
    () => [
      { value: true, label: t('yes') },
      { value: false, label: t('no') }
    ],
    [t]
  )

  const purchaseOptions = useMemo(
    () => [
      { value: 'scheduled', label: t('scheduled') },
      { value: 'continuous', label: t('continuous') }
    ],[t]
  )

  const columns = useMemo(
    () => [
      {
        title: 'Atributo',
        field: 'attribute'
      },
      {
        title: 'Valor do Atributo',
        field: 'value'
      }
    ],
    []
  )

  const onDeleteClick = useCallback(
    (event, data) => {
      const array = tableData
      array.splice(data.tableData.id, 1)
      setTableData([...array])
    },
    [tableData]
  )

  const handleSubmit = useCallback(
    (formData) => {
      setLoading(true)
      const data = {
        ...formData,
        dun14: isEmpty(formData.dun14) ? null : formData.dun14,
        ncm: isEmpty(formData?.ncm) ? null : trimMask(formData?.ncm),
        ean: formData.ean,
        slug: formData.shortDescription.replace(/\s/g, '-'),
        extraAttributes: [...tableData],
        scheduled: formData.purchaseType === 'scheduled',
        continuous: formData.purchaseType === 'continuous'
      }

      dispatch(
        ProductsActions.createProduct(
          { data, suppliers },
          () => {
            snackbar.success(
              t('your {this} was registered', {
                howMany: 1,
                this: t('product', { howMany: 1 }),
                gender: 'male'
              })
            )
            setLoading(false)
            onSuccess()
          },
          () => setLoading(false)
        )
      )
      onSuccess()
    },
    [dispatch, onSuccess, snackbar, suppliers, t, tableData]
  )

  const handleAddAttribute = useCallback(() => {
    if (!attribute || !value) {
      snackbar.error(t('insert attribute and value'))
      return
    }
    const obj = {
      attribute,
      value
    }

    setAttribute('')
    setValue('')
    setTableData((state) => [...state, obj])
  }, [attribute, snackbar, t, value])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddAttribute()
    }
  }

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ registerProductSchema }
      onSubmit={ handleSubmit }
      style={ { flex: 1 } }
      loading={ loading }
    >
      <SplitedScreenLayout
        title={ { name: t('products list') } }
        divRightStyle={ { paddingTop: 67 } }
        leftChildren={
          <Container>
            <Header style={ { paddingLeft: 35 } }>
              <Title style={ { fontSize: 16, fontWeight: 600 } }>
                <I18n>pre-registration of products</I18n>
              </Title>
            </Header>

            <Item style={ { padding: '0 35px 0 35px' } }>
              <InputText label={ t('description') } name="description" />
              <InputText
                label={ t('shortDescription') }
                name="shortDescription"
              />
              <InputText label={ t('ncm') } name="ncm" setMask={ ncmMask } />
              <InputText
                label={ t('unitOfMeasure', { howMany: 1 }) }
                name="unitOfMeasures"
              />
              <InputSelect
                creatable
                multiple
                label={ t('ean') }
                name="ean"
                defaultValue={ [] }
              />
              <InputNumber label={ t('dun14') } name="dun14" maxLength={ 14 } />

              <InputSelect
                label={ t('product group') }
                name="productGroupId"
                options={ getProductGroupsService }
              />

              <InputSelect
                label={ t('purchase type') }
                name="purchaseType"
                options={ purchaseOptions }
              />

            </Item>
          </Container>
        }
        rightChildren={
          <Container style={ { flex: 1 } }>
            <Row>
              <InputSelect
                detached
                multiple
                label={ t('supplier', { howMany: 2 }) }
                options={ getSuppliersService }
                asyncOptionLabelField="tradeName"
                value={ suppliers }
                onChange={ setSuppliers }
              />
            </Row>
            <Row>
              <RadioGroup
                label="Ativo?"
                name="active"
                options={ options }
                defaultValue
                variant="row"
              />

              <RadioGroup
                label="Digitalização"
                name="digitalization"
                options={ options }
                defaultValue={ false }
                variant="row"
                style={ { display: userProfileIsSmartcoop ? 'flex' : 'none' } }
              />

              <RadioGroup
                label="Cultivar"
                name="cultivate"
                options={ options }
                defaultValue={ false }
                variant="row"
                style={ { display: userProfileIsSmartcoop ? 'flex' : 'none' } }
              />
            </Row>
            <Row style={ { display: userProfileIsSmartcoop ? 'flex' : 'none' } }>
              <RadioGroup
                label="Compras"
                name="purchasing"
                options={ options }
                defaultValue
                variant="row"
              />

              <RadioGroup
                label="Comercialização"
                name="commercialization"
                options={ options }
                defaultValue={ false }
                variant="row"
              />
            </Row>

            <div style={ { width: '70%', marginBottom: 20 } }>
              <div
                style={ {
                  marginTop: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                } }
              >
                <div>
                  <InputText
                    detached
                    value={ attribute }
                    onChange={ ({ target }) => setAttribute(target.value) }
                    label={ t('attribute') }
                    name="attribute"
                    style={ { marginRight: 10, marginBottom: 0 } }
                  />
                  <InputText
                    detached
                    value={ value }
                    onChange={ ({ target }) => setValue(target.value) }
                    label={ t('attribute value') }
                    name="value"
                    style={ { marginBottom: 0 } }
                    onKeyPress={ handleKeyPress }
                  />
                </div>
                <div>
                  <CustomIconButton onClick={ handleAddAttribute }>
                    <Icon icon={ plus } size={ 12 } />
                    <ButtonLabel>{t('create attribute')}</ButtonLabel>
                  </CustomIconButton>
                </div>
              </div>

              <DataTable
                columns={ columns }
                data={ tableData }
                onDeleteClick={ onDeleteClick }
                id="table-register-product-form"
              />
            </div>

            <Row style={ { justifyContent: 'flex-end' } }>
              <ButtonContainer>
                <Button
                  id="cancel-product-register-form"
                  onClick={ onCancel }
                  style={ { flex: 1 } }
                  variant="outlined"
                >
                  <I18n>cancel</I18n>
                </Button>
              </ButtonContainer>

              <ButtonContainer>
                <Button
                  id="submit-product-register-form"
                  onClick={ () => formRef.current.submit() }
                  style={ { flex: 1 } }
                >
                  <I18n>save</I18n>
                </Button>
              </ButtonContainer>
            </Row>
          </Container>
        }
      />
    </Form>
  )
})

RegisterProductForm.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func
}

RegisterProductForm.defaultProps = {
  onSuccess: () => {},
  onCancel: () => {}
}

export default RegisterProductForm
