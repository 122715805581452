export default ({ size, color } = {}) => /* html */`
<svg width="${ size }" height="${ size }" version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1080 1080" style="enable-background:new 0 0 1080 1080;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
</style>
<g>
	<path class="st0" d="M938.9,285H763.42l75.25-227.27l79.25,0.05l0.61-0.05c1.27-0.05,2.63-0.14,4-0.14
		c13.55,0,23.71,4.8,30.95,14.67C966.65,90.09,981.75,141.31,938.9,285z"/>
	<g>
		<g>
			<path class="st0" d="M744.43,189.05H638.66c-7.43,0-13.44-6.01-13.44-13.44c0-7.43,6.01-13.44,13.44-13.44h105.77
				c7.43,0,13.44,6.01,13.44,13.44C757.86,183.04,751.85,189.05,744.43,189.05z"/>
		</g>
	</g>
	<g>
		<g>
			<path class="st0" d="M699.64,294.31H593.87c-7.43,0-13.44-6.01-13.44-13.44c0-7.43,6.01-13.44,13.44-13.44h105.77
				c7.43,0,13.44,6.01,13.44,13.44C713.08,288.3,707.06,294.31,699.64,294.31z"/>
		</g>
	</g>
	<g>
		<path fill="${ color }" d="M809.61,301.46h141.48c68.05-219.97,14.71-257.75-24.24-260.2c0.19-0.19,0.33-0.33,0.33-0.33H248.33
			c0,0-228.43,207.87-125.37,589.9c103.06,382.15,129.54,355.28-2.42,431.33h678.72c193.89-160.58,49.31-185.3,2.56-431.33
			c-25.27-132.9-7.94-240.8,7.78-329.36C809.61,301.47,809.61,301.46,809.61,301.46z M940.27,82.04
			c6.91,9.41,25.4,49.86-13.69,186.49H816.39c21.63-92.75,59.15-158.11,84.47-194.31l16.5,0.01h1.13l1.18-0.05
			c0.99-0.09,1.93-0.14,2.87-0.14C932.98,74.05,937.35,78.09,940.27,82.04z"/>
		<g>
			<g>
				<path class="st0" d="M531.67,189.05H221.64c-7.43,0-13.44-6.01-13.44-13.44c0-7.43,6.01-13.44,13.44-13.44h310.03
					c7.43,0,13.44,6.01,13.44,13.44C545.11,183.04,539.1,189.05,531.67,189.05z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st0" d="M486.88,294.31H176.85c-7.43,0-13.44-6.01-13.44-13.44c0-7.43,6.01-13.44,13.44-13.44h310.03
					c7.43,0,13.44,6.01,13.44,13.44C500.32,288.3,494.31,294.31,486.88,294.31z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st0" d="M470.09,401.81H160.06c-7.43,0-13.44-6.01-13.44-13.44c0-7.43,6.01-13.44,13.44-13.44h310.03
					c7.43,0,13.44,6.01,13.44,13.44C483.53,395.8,477.51,401.81,470.09,401.81z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st0" d="M682.84,401.81H577.08c-7.43,0-13.44-6.01-13.44-13.44c0-7.43,6.01-13.44,13.44-13.44h105.76
					c7.43,0,13.44,6.01,13.44,13.44C696.28,395.8,690.27,401.81,682.84,401.81z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st0" d="M481.28,521.61H171.25c-7.43,0-13.44-6.01-13.44-13.44s6.01-13.44,13.44-13.44h310.03
					c7.43,0,13.44,6.01,13.44,13.44S488.71,521.61,481.28,521.61z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st0" d="M694.03,521.61H588.27c-7.43,0-13.44-6.01-13.44-13.44s6.01-13.44,13.44-13.44h105.76
					c7.43,0,13.44,6.01,13.44,13.44S701.46,521.61,694.03,521.61z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st0" d="M504.8,641.42H194.77c-7.43,0-13.44-6.01-13.44-13.44s6.01-13.44,13.44-13.44H504.8
					c7.43,0,13.44,6.01,13.44,13.44S512.23,641.42,504.8,641.42z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<path class="st0" d="M717.55,641.42H611.78c-7.43,0-13.44-6.01-13.44-13.44s6.01-13.44,13.44-13.44h105.77
				c7.43,0,13.44,6.01,13.44,13.44S724.98,641.42,717.55,641.42z"/>
		</g>
	</g>
	<g>
		<g>
			<path class="st0" d="M736.59,777.49H630.83c-7.43,0-13.44-6.01-13.44-13.44c0-7.43,6.01-13.44,13.44-13.44h105.76
				c7.43,0,13.44,6,13.44,13.44C750.03,771.48,744.02,777.49,736.59,777.49z"/>
		</g>
	</g>
</g>
</svg>
`
