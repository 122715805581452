import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1;
  padding: 25px 30px;
`

export const Message = styled(Typography)`
  padding-top: 30px;
  color: #000;
  font-weight: bold;
  font-size: 16px;
  background-color: transparent;
  text-align: center;
`
