import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'


import Divider from '@material-ui/core/Divider'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import {
  calendar,
  pencil,
  trash,
  closeBold,
  erlenmeyer } from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { useSnackbar } from '@smartcoop/snackbar'
import { FieldActions } from '@smartcoop/stores/field'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import { momentFriendlyDateFormat, momentBackDateFormat } from '@smartcoop/utils/dates'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import Button from '@smartcoop/web-components/Button'
import CooperativeDocument from '@smartcoop/web-components/CooperativeDocument'
import Icon from '@smartcoop/web-components/Icon'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  Container,
  Header,
  ManagementName,
  DateContent,
  DetailsManagement,
  DetailsText,
  DateSingle,
  TextContent,
  WhiteCard,
  CardChildren,
  WarningDiv
} from './style'

const FieldAnalysisDetailsFragment = (props) => {
  const {
    analysis,
    closeAnalysis,
    startLoading
  } = props

  const history = useHistory()
  const { routes } = useRoutes()
  const t = useT()
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const userWrite = useSelector(selectUserCanWrite)

  const [analysisFiles, setAnalysisFiles] = useState(null)

  const handleFieldAnalysisDelete = useCallback(
    () => {
      createDialog({
        id: 'confirm-delete-cropManagement',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            startLoading(true)
            dispatch(FieldActions.deleteFieldAnalysis(
              analysis?.id,
              () => {
                snackbar.success(
                  t('your {this} was deleted', {
                    gender: 'female',
                    howMany: 1,
                    this: t('soil analysis')
                  })
                )
                dispatch(FieldActions.loadFieldsAnalysis())
                startLoading(false)
                closeAnalysis()
              },
              () => startLoading(false),
              true
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            gender: 'female',
            howMany: 1,
            this: t('soil analysis')
          })
        }
      })
    },
    [analysis, closeAnalysis, createDialog, dispatch, snackbar, startLoading, t]
  )

  const handleFieldAnalysisEdit = useCallback(
    () => history.push(routes.fieldAnalysisEdit.path, { analysis }),
    [analysis, history, routes]
  )

  const handleFieldAnalysisResample = useCallback(
    () => history.push(routes.fieldAnalysisEdit.path, { analysis: {
      fieldId: analysis?.fieldId,
      pinLocation: analysis?.pinLocation
    } }),
    [analysis, history, routes]
  )

  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_SOIL_ANALYSIS_ID, fileKey: file?.fileKey })

    return downloadFromBase64(data)
  }, [])

  useEffect(() => {
    const renderFiles = async () => {
      const responses = await Promise.all(map(analysis?.soilAnalysisDocuments, async (file) => {
        const fileSrc = await renderFile(file)
        return { ...file, fileSrc }
      }))
      setAnalysisFiles(responses)
    }

    renderFiles()
  }, [analysis.soilAnalysisDocuments, renderFile])

  return(
    <Container>
      <>
        <Header>
          <TextContent>
            <ManagementName>
              <I18n>soil analysis</I18n> - {analysis?.analysisCode}
            </ManagementName>
            <DateContent>
              <DateSingle>
                <I18n>sample date</I18n>
                <Icon
                  style={ { margin: '0 5px 0 3px' } }
                  size={ 10 } icon={ calendar }
                  color={ colors.green }
                />
                {moment(analysis?.dateOfCollection, momentBackDateFormat).format(momentFriendlyDateFormat)}
              </DateSingle>
              { analysis?.analysisResultDate && (
                <DateSingle>
                  <I18n>result date</I18n>
                  <Icon
                    style={ { margin: '0 5px 0 3px' } }
                    size={ 10 } icon={ calendar }
                    color={ colors.gray }
                  />
                  {moment(analysis?.analysisResultDate, momentBackDateFormat).format(momentFriendlyDateFormat)}
                </DateSingle>
              )}
            </DateContent>
          </TextContent>
          <div>
            {userWrite && (
              <>
                <Button
                  id="resample-point"
                  style={ { padding: '5.5px 10px', fontSize: '0.875rem', marginRight: 10 } }
                  color={ colors.white }
                  onClick={ handleFieldAnalysisResample }
                >
                  <>
                    <Icon size={ 17 } icon={ erlenmeyer } color={ colors.black } />
                    <I18n>resample point</I18n>
                  </>
                </Button>

                <Button
                  id="edit-soil-analysis"
                  style={ { padding: '5.5px 10px', fontSize: '0.875rem', marginRight: 10 } }
                  color={ colors.white }
                  onClick={ handleFieldAnalysisEdit }
                >
                  <>
                    <Icon size={ 17 } icon={ pencil } color={ colors.black } />
                    <I18n>edit</I18n>
                  </>
                </Button>

                <Button
                  id="delete-soil-analysis"
                  style={ { padding: '5.5px 10px', fontSize: '0.875rem', marginRight: 10 } }
                  color={ colors.white }
                  onClick={ handleFieldAnalysisDelete }
                >
                  <>
                    <Icon size={ 17 } icon={ trash } color={ colors.red } />
                    <I18n>exclude</I18n>
                  </>
                </Button>
              </>
            )}
            <Button
              id="close-analysis"
              variant="outlined"
              color={ colors.white }
              style={ { padding: 0, fontSize: '0.875rem' } }
              onClick={ closeAnalysis }
            >
              <Icon size={ 30 } icon={ closeBold } color={ colors.darkGrey }/>
            </Button>
          </div>
        </Header>
        <Divider/>
        <DetailsManagement>
          { analysis?.analysisType && (
            <DetailsText>
              <div style={ { fontWeight: 600 } }>
                <I18n>analysis type</I18n>
              </div>
              <div style={ { fontSize: 16 } }>
                <I18n>{analysis?.analysisType}</I18n>
              </div>
            </DetailsText>
          )}

          { analysis?.sampleDepth && (
            <DetailsText>
              <div style={ { fontWeight: 600 } }>
                <I18n>sample depth</I18n>
              </div>
              <div style={ { fontSize: 16 } }>
                <I18n>{analysis?.sampleDepth}</I18n>
              </div>
            </DetailsText>
          )}

          { analysis?.laboratory && (
            <DetailsText>
              <div style={ { fontWeight: 600 } }>
                <I18n>laboratory</I18n>
              </div>
              <div style={ { fontSize: 16 } }>
                <I18n>{analysis?.laboratory}</I18n>
              </div>
            </DetailsText>
          )}
          <Divider/>
          {!isEmpty(analysis?.analysisResult) && (
            <div style={ { display: 'flex', flexDirection: 'column' } }>
              <div>
                <div style={ { fontWeight: 600, marginBottom: 15 } }>
                  <I18n>result</I18n>
                </div>
              </div>
              <div style={ { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' } }>
                { map(analysis?.analysisResult, (item, index) => item?.value && (
                  <DetailsText key={ index } style={ { minWidth: '25%', marginBottom: 10 } }>
                    <div style={ { fontWeight: 600 } }>
                      {item?.name}
                    </div>
                    <div style={ { fontSize: 16 } }>
                      {item?.value} {item?.unit}
                    </div>
                  </DetailsText>
                ))}
              </div>
            </div>
          )}
          {isEmpty(analysis?.analysisResult) && (
            <WarningDiv>
              <I18n>the analysis results have not been yet informed</I18n>
            </WarningDiv>
          )}
          <Divider/>
          {analysis.observation && (
            <DetailsText>
              <p style={ { fontWeight: 600 } }>
                <I18n params={ { howMany: 2 } }>observation</I18n>
              </p>
              <div style={ { fontSize: 16 } }>
                <p>{analysis.observation}</p>
              </div>
            </DetailsText>
          )}
        </DetailsManagement>
      </>
      {
        !isEmpty(analysisFiles) && (
          <WhiteCard>
            <CardChildren>
              {map(analysisFiles, (file, index) => (
                <CooperativeDocument
                  key={ `${ index }-${ file?.fileName }` }
                  documentName={ file?.fileName }
                  documentSrc={ file?.fileSrc }
                  alignOnEnd
                />
              ))}
            </CardChildren>
          </WhiteCard>
        )
      }
    </Container>
  )
}

FieldAnalysisDetailsFragment.propTypes = {
  analysis: PropTypes.object,
  closeAnalysis: PropTypes.func,
  startLoading: PropTypes.func
}

FieldAnalysisDetailsFragment.defaultProps = {
  analysis: {},
  closeAnalysis: () => {},
  startLoading: () => {}
}

export default FieldAnalysisDetailsFragment
