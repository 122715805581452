import React from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import { messageService } from '@smartcoop/database/services/messageService'
import { database } from '@smartcoop/database/web-database'
import { useT } from '@smartcoop/i18n'
import { emptyFilter } from '@smartcoop/icons'
import EmptyState from '@smartcoop/web-components/EmptyState'

import ChatSearchMessage from '../ChatSearchMessage'
import { ScrollList } from './styles'


const ChatSearchList = ({ searchParams, messages }) => {
  const t = useT()

  const onFocusElement = data => {
    const element = document.getElementById(data?.localMessageId)

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
      // Add style to highlighting
      element.style.filter = 'brightness(0.90)'
      setTimeout(() => {element.style.filter = null}, 1000)
    }
  }

  if (!messages?.length) {
    return (
      <EmptyState
        text={ t('no message found') }
        icon={ emptyFilter }
      />
    )
  }

  return (
    <ScrollList>
      {messages.map(message => (
        <ChatSearchMessage
          key={ message.id }
          message={ message }
          searchParams={ searchParams }
          openChat={ onFocusElement }
        />
      ))}
    </ScrollList>
  )
}

ChatSearchList.propTypes = {
  searchParams: PropTypes.string,
  messages: PropTypes.array
}

ChatSearchList.defaultProps = {
  searchParams: '',
  messages: []
}

const enhance = withObservables([
  'searchParams',
  'relationalId',
  'conversationId'
], ({
  searchParams,
  relationalId,
  conversationId
}) => ({
  messages: messageService(database).observeMessagesByFilter(searchParams, relationalId, conversationId)
}))

const EnhancedChatSearchList = enhance(ChatSearchList)

export default EnhancedChatSearchList
