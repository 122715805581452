import React, { useCallback, forwardRef, useMemo } from 'react'

import trimMask from '@meta-awesome/functions/src/trimMask'
import PropTypes from 'prop-types'

import filterSupplierListSchema from '@smartcoop/forms/schemas/shoppingPlatform/supplier/filterSupplierList.schema'
import I18n, { useT } from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputCpfCnpj from '@smartcoop/web-components/InputCpfCnpj'
import InputText from '@smartcoop/web-components/InputText'
import RadioGroup from '@smartcoop/web-components/RadioGroup'


import { Container, ButtonContainer } from './styles'

const FilterOrganizationForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()

  const handleSubmit = useCallback(
    (data) => onSubmit({ ...data, companyDocument: trimMask(data.companyDocument) }),
    [onSubmit]
  )

  const statusOptions = useMemo(
    () => [
      {
        label: t('active', { gender: 'male' }),
        value: true
      },
      {
        label: t('inactive', { gender: 'male' }),
        value: false
      }
    ],
    [t]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterSupplierListSchema }
        onSubmit={ handleSubmit }
      >

        <InputCpfCnpj
          name="companyDocument"
          label={ t('company document') }
          onlyCnpj
          fullWidth
          defaultValue={ filters.companyDocument }
        />
        <InputText
          name="q"
          label={ t('company name') }
          fullWidth
          defaultValue={ filters.q }
        />
        <InputText
          name="tradeName"
          label={ t('trade name') }
          fullWidth
          defaultValue={ filters.tradeName }
        />

        <InputText
          name="productName"
          label={ t('product') }
          fullWidth
          defaultValue={ filters.productName }
        />

        <RadioGroup
          label="Status"
          name="isActive"
          options={ statusOptions }
          variant="row"
          style={ { paddingBottom: 10 } }
          defaultValue={ filters.isActive }
          clearable
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-order-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterOrganizationForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterOrganizationForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterOrganizationForm
