import React, { useCallback, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'
import CreateProjectForm from '@smartcoop/web-containers/forms/challenges/CreateProjectForm'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import useStyles, { Row, Content } from './styles'

const CreateProjectModal = ({ id, open, handleClose, onSubmit }) => {
  const classes = useStyles()
  const createOrderFormRef = useRef(null)

  const [loading, setLoading] = useState(false)

  const clearForm = useCallback(
    () => {
      createOrderFormRef.current.reset()
      handleClose()
    },
    [handleClose]
  )

  const onSuccess = useCallback(
    () => {
      setLoading(false)
      onSubmit()
      handleClose()
    },
    [handleClose, onSubmit]
  )

  const onError = useCallback(
    () => {
      setLoading(false)
    },
    []
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row>
          <I18n>register new participant</I18n>
        </Row>
      }
      escape
      loading={ loading }
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 350 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      disableFullScreen
    >
      <>
        <Content>
          <CreateProjectForm
            ref={ createOrderFormRef }
            onSubmit={ () => setLoading(true) }
            onSuccess={ onSuccess }
            onError={ onError }
            withoutSubmitButton
          />
          <ButtonsContainer style={ { marginTop: 10 } }>
            <Button
              id="cancel"
              onClick={ () => clearForm() }
              variant="outlined"
              style={ { marginRight: 7 } }
              disabled={ loading }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="save"
              onClick={ () => createOrderFormRef.current.submit() }
              color="black"
              style={ { marginLeft: 7 } }
              disabled={ loading }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        </Content>
      </>
    </Modal>
  )}

CreateProjectModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
}

CreateProjectModal.defaultProps = {
  onSubmit: () => {}
}

export default CreateProjectModal
