import React, { useCallback, forwardRef, useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import filterMedicationControlSchema from '@smartcoop/forms/schemas/dairyFarm/filterMedicationControl.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getAnimalsDiseases } from '@smartcoop/services/apis/smartcoopApi/resources/herdsManagement'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'

import { Container, ButtonContainer } from './styles'

const FilterMedicationControlForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const [diseasesOptions, setDiseasesOptions] = useState([])

  const t = useT()

  const handleSubmit = useCallback(
    (data) => onSubmit(data),
    [onSubmit]
  )

  const loadDiseases = useCallback(async () => {
    const { data: { data } } = await getAnimalsDiseases()

    setDiseasesOptions(map(data, item => ({
      label: item.name,
      value: item.id
    })))
  },[])

  useEffect(() => {
    loadDiseases()
  },[loadDiseases])

  const medicationOptions = [
    {
      label: 'Calendário de vacinação',
      value: 'Calendário de vacinação'
    },
    {
      label: 'Diagnóstico de doença',
      value: 'Diagnóstico de doença'
    },
    {
      label: 'Secagem',
      value: 'Secagem'
    }
  ]

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterMedicationControlSchema }
        onSubmit={ handleSubmit }
      >
        <InputSelect
          label={ t('type') }
          name="type"
          options={ medicationOptions }
          defaultValue={ filters?.type }
        />

        <InputSelect
          label={ t('reason') }
          name="animalDiseaseIds"
          multiple
          options={ diseasesOptions }
          defaultValue={ filters?.animalDiseaseIds }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-order-filter-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>filter</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

FilterMedicationControlForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterMedicationControlForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterMedicationControlForm
