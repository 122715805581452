import React from 'react'

import PropTypes from 'prop-types'

import { useT } from '@smartcoop/i18n'
import { clips } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'

import IconButton from '../IconButton'

const AttachButton = ({ iconColor, ...props }) => {
  const t = useT()
  return (
    <IconButton
      tooltip={ t('options', { howMany: 1 }) }
      { ...props }
    >
      <Icon icon={ clips } size={ 30 } color={ iconColor } />
    </IconButton>
  )
}
AttachButton.propTypes = {
  iconColor: PropTypes.string
}
AttachButton.defaultProps = {
  iconColor: colors.mutedText
}
export default AttachButton