
import moment from 'moment/moment'
import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import isWeekDay from '@smartcoop/forms/validators/isWeekDay.validator'
import minDate from '@smartcoop/forms/validators/minDate.validator'
import minHour from '@smartcoop/forms/validators/minHour.validator'
import notEmpty from '@smartcoop/forms/validators/notEmpty.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerOrder = ({ t, props }) => Yup.object().shape({
  productGroupId: flow(
    required({ t })
  )(Yup.string()),

  productId: flow(
    required({ t })
  )(Yup.string().nullable()),

  freightType: flow(
    required({ t })
  )(Yup.string()),

  paymentForms: flow(
    required({ t, isNotText: true }),
    notEmpty({ t, text: t('this field is required') })
  )(Yup.object()),

  deliveryLocations: flow(
    required({ t })
  )(Yup.string()),

  participationDeadline: flow(
    date({ t }),
    minDate({ t, minReceiptDate:  moment().format('YYYY-MM-DD'), text: t('date must be greater than {this}', { this: t('today') }) }),
    isWeekDay({ t })
  )(Yup.string()),

  participationDeadlineHour: Yup.string(),

  proposalDeadline: flow(
    date({ t }),
    minDate({ t, minReceiptDate:  moment(props?.participationDeadline).format('YYYY-MM-DD'), text: t('date must be greater than {this}', { this: t('demand closing date') }) }),
    isWeekDay({ t })
  )(Yup.string()),

  proposalDeadlineHour: Yup.string().when('proposalDeadline', {
    is: () => props?.proposalDeadline === props?.participationDeadline,
    then: flow(
      minHour({ t, minHour:  moment(props?.participationDeadlineHour, 'HH:mm'), text: t('hour must be greater than {this}', { this: props?.participationDeadlineHour }) })
    )(Yup.string()),
    otherwise: Yup.string()
  }),

  decisionDeadline: flow(
    date({ t }),
    minDate({ t, minReceiptDate:  moment(props?.proposalDeadline).format('YYYY-MM-DD'), text: t('date must be greater than {this}', { this: t('proposal deadline') }) }),
    isWeekDay({ t })
  )(Yup.string()),

  decisionDeadlineHour: Yup.string().when('decisionDeadline', {
    is: () => props?.decisionDeadline === props?.proposalDeadline,
    then: flow(
      minHour({ t, minHour:  moment(props?.proposalDeadlineHour, 'HH:mm'), text: t('hour must be greater than {this}', { this: props?.proposalDeadlineHour }) })
    )(Yup.string()),
    otherwise: Yup.string()
  }),

  receiptDeadline: flow(
    date({ t }),
    minDate({ t, minReceiptDate:  moment(props?.decisionDeadline).format('YYYY-MM-DD'), text: t('date must be greater than {this}', { this: t('organization decision deadline') }) }),
    isWeekDay({ t })
  )(Yup.string()),

  receiptDeadlineHour: Yup.string().when('receiptDeadline', {
    is: () => props?.receiptDeadline === props?.decisionDeadline,
    then: flow(
      minHour({ t, minHour:  moment(props?.decisionDeadlineHour, 'HH:mm'), text: t('hour must be greater than {this}', { this: props?.decisionDeadlineHour }) })
    )(Yup.string()),
    otherwise: Yup.string()
  })
})

export default registerOrder
