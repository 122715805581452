import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment/moment'

import capitalize from 'lodash/capitalize'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { plantAndHand, reports, calendar } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { TechnicalActions } from '@smartcoop/stores/technical'
import { selectTechnicalVisits } from '@smartcoop/stores/technical/selectorTechnical'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import VisitModeStatus from '@smartcoop/web-components/VisitModeStatus'
import { Badge } from '@smartcoop/web-components/VisitModeStatus/styles'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import PropertyTechnicalVisitModal from '../../../../../modals/technical/PropertyTechnicalVisitModal'
import PropertyTechnicalVisitScheduleModal from '../../../../../modals/technical/PropertyTechnicalVisitScheduleModal'
import { Container, ButtonRow, Title, RowHeader, CheckboxRow } from './styles'

const PropertyTechnicalVisitListScreen = () => {
  const t = useT()
  const { createDialog, removeDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const tableRef = useRef(null)
  const history = useHistory()
  const { routes } = useRoutes()

  const [loading, setLoading] = useState(false)
  const technicalModule = useSelector(selectModuleIsTechnical)
  const technicalVisits = useSelector(selectTechnicalVisits)
  const propertyId = useSelector(selectCurrentPropertyId)

  const columns = useMemo(
    () => [
      {
        title: 'Data e Hora',
        field: 'visitDateTime',
        align: 'left',
        render: (row) => row[0].visitDateTime ? moment(row[0]?.visitDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm') : moment(row[0]?.predictedDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
      },
      {
        title: 'Talhão',
        field: 'fieldName',
        align: 'left',
        render: (row) => join(map(row, item => item.field?.fieldName), ', ') || ' - '
      },
      {
        title: 'Notificado',
        field: 'notify',
        align: 'center',
        render: (row) => (
          <CheckboxRow>
            <CheckboxButton
              value={ row[0].notify }
              checked={ row[0].notify }
              disabled
              style={ { width: '50px' } }
              hideColor
            />
          </CheckboxRow>
        )
      },
      {
        title: 'Modo de visita',
        field: 'visitMode',
        align: 'center',
        render: (row) => (
          <CheckboxRow>
            <VisitModeStatus
              mode={ row[0]?.visitMode }
            />
          </CheckboxRow>
        )
      },
      {
        title: 'Status',
        field: 'status',
        align: 'center',
        render: (row) => row[0]?.status ? (
          <CheckboxRow>
            <Badge
              backgroundColorBadge={ row[0]?.status === 'agendada' ? '(245, 125, 13, 0.1)' : '(29, 29, 27, 0.1)' }
              colorBadge={ row[0]?.status === 'agendada' ? '#f57d0d' : '#1d1d1b' }
            >
              { capitalize(row[0]?.status) }
            </Badge>
          </CheckboxRow>
        ) : '-'
      }
    ], []
  )

  const dataTableOption = useMemo(
    () => ({
      grouping: true,
      defaultExpanded: false,
      hideGroupbar: true
    }),
    []
  )

  const onListLoad = useCallback(
    (page) => {
      dispatch(TechnicalActions.loadTechnicalVisits(
        { page, propertyId, limit: 99999 },
        () => setLoading(false),
        () => setLoading(false)
      ))
    },
    [dispatch, propertyId]
  )

  const onSuccessEditingTechnicalVisit = useCallback(
    (scheduledToVisited = false) => {
      snackbar.success(scheduledToVisited ? t('your visit was effected') :
        t('your {this} was edited', {
          howMany: 1,
          gender: 'female',
          this: t('technical visit')
        })
      )
      onListLoad()
      removeDialog({ id: 'view-edit-technical-visit' })
    },
    [onListLoad, removeDialog, snackbar, t]
  )

  const openDetailsTechnicalVisit = useCallback(
    (_, row) => {
      createDialog({
        id: row[0]?.status === 'agendada' ? 'view-edit-technical-visit-schedule' : 'view-edit-technical-visit',
        Component: row[0]?.status === 'agendada' ? PropertyTechnicalVisitScheduleModal : PropertyTechnicalVisitModal,
        props: {
          technicalVisit: row,
          disabled: true,
          onSuccess: onSuccessEditingTechnicalVisit,
          onVisit: () => createDialog({
            id: 'view-edit-technical-visit',
            Component: PropertyTechnicalVisitModal,
            props: {
              technicalVisit: row,
              onSuccess: () => onSuccessEditingTechnicalVisit(true)
            }
          })
        }
      })
    },
    [createDialog, onSuccessEditingTechnicalVisit]
  )

  const handleDelete = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            setLoading(true)
            dispatch(TechnicalActions.deleteTechnicalVisit(
              row[0].visitId,
              () => {
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'female',
                    this: t('technical visit')
                  })
                )
                onListLoad()
              }
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'female',
            this: t('technical visit')
          })
        }
      })
    },
    [createDialog, dispatch, onListLoad, snackbar, t]
  )

  const onSuccessRegisterTechnicalVisit = useCallback(
    (scheduled = false) => {
      snackbar.success(
        t('your {this} was registered', {
          howMany: 1,
          gender: scheduled ? 'male' : 'female',
          this: scheduled ? t('schedulement') : t('technical visit')
        })
      )
      onListLoad()
      removeDialog({ id: 'register-technical-visit' })
    },
    [onListLoad, removeDialog, snackbar, t]
  )

  const openTechnicalVisitRegister = useCallback((scheduled = false) => {
    createDialog({
      id: 'register-technical-visit',
      Component: scheduled ? PropertyTechnicalVisitScheduleModal : PropertyTechnicalVisitModal,
      props: {
        onSuccess: () => onSuccessRegisterTechnicalVisit(scheduled),
        scheduled
      }
    })
  }, [createDialog, onSuccessRegisterTechnicalVisit])


  useEffect(() => {
    onListLoad()
  }, [onListLoad])

  return(
    <Container>
      <RowHeader>
        <Title>
          {t('technical visits')}
        </Title>
        <ButtonRow>
          {technicalModule && (
            <Button
              id="register-schedule"
              color="secondary"
              style={ {
                padding: '5.5px 10px',
                fontSize: '0.875rem',
                marginRight: 5
              } }
              onClick={ () => openTechnicalVisitRegister(true) }
              fullWidth
            >
              <>
                <Icon
                  icon={ calendar }
                  size={ 17 }
                  style={ { marginRight: 5 } }
                />
                {t('schedule')}
              </>
            </Button>
          )}
          {technicalModule && (
            <Button
              id="register-technical-visit"
              color="secondary"
              style={ {
                padding: '5.5px 10px',
                fontSize: '0.875rem',
                marginLeft: 5
              } }
              onClick={ () => openTechnicalVisitRegister(false) }
              fullWidth
            >
              <>
                <Icon
                  icon={ plantAndHand }
                  size={ 17 }
                  style={ { marginRight: 5 } }
                />
                {t('new visit')}
              </>
            </Button>
          )}
          <Button
            id="create-report"
            onClick={ () => {
              history.push(routes?.reports?.path,
                {
                  selectedForm: 'technicalVisit'
                })
            } }
            color="white"
            style={ {
              padding: '5.5px 10px',
              fontSize: '0.875rem',
              marginLeft: 10
            } }
          >
            <>
              <Icon style={ { paddingRight: 6 } } icon={ reports } size={ 18 }/>
              <I18n>create report</I18n>
            </>
          </Button>
        </ButtonRow>
      </RowHeader>
      <DataTable
        tableRef={ tableRef }
        columns={ columns }
        data={  !isEmpty(technicalVisits) && !loading  ? map(groupBy(orderBy([...technicalVisits], ['visitDateTime', 'predictedDate'], ['desc', 'desc']), 'visitId'), item => item)  : [] }
        onRowClick={ openDetailsTechnicalVisit }
        options={ dataTableOption }
        onDeleteClick={ handleDelete }
        id="technical-visit-table"
      />
    </Container>
  )
}

export default PropertyTechnicalVisitListScreen
