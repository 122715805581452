import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import required from '@smartcoop/forms/validators/required.validator'

const fieldAnalysis = ({ t }) => Yup.object().shape({

  dateOfCollection: flow(
    required({ t }),
    date({ t })
  )(Yup.string()),

  analysisType: flow(
    required({ t })
  )(Yup.string()),

  sampleDepth: flow(
    required({ t })
  )(Yup.string()),

  laboratory: flow(
    required({ t })
  )(Yup.string()),

  analysisResultDate: Yup.string().nullable(),

  analysisResult: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().nullable()
      })
    ).nullable(),

  observation: Yup.string()

})

export default fieldAnalysis
