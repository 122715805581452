import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import { useDialog } from '@smartcoop/dialog'
import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { AnimalBirthActions } from '@smartcoop/stores/animalBirth'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import RegisterAnimalBirthForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterAnimalBirthForm'

import useStyles from './styles'

const RegisterAnimalBirthModal = (props) => {
  const {
    id,
    open,
    onSubmit,
    onAccept,
    handleClose,
    animalBirth,
    animalId
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()
  const currentProperty = useSelector(selectCurrentProperty)
  const { createDialog } = useDialog()

  const [isLoading, setIsLoading] = useState(false)

  const isEditing = useMemo(
    () => (!isEmpty(animalBirth?.id)),[animalBirth.id]
  )
  const defaultValues = useMemo(
    () => (
      {
        code: '',
        name: '',
        property: {
          id: currentProperty?.id
        },
        ...animalBirth
      }
    ), [animalBirth, currentProperty.id]
  )

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      handleClose()
    }, [handleClose]
  )

  const registerAnimalModal = useCallback(
    (response) => {
      if(response.isAlive && !isEditing) {
        createDialog({
          id: 'confirm-register-animal',
          Component: ConfirmModal,
          props: {
            onConfirm: () => {
              const currentAnimal = {
                ...response,
                id: null,
                animalId: null,
                birthDate: response.occurrenceDate,
                animalBirthId: response.id,
                category: 'terneira',
                statusId: '7',
                animalStatus: {
                  id: '7'
                }
              }
              dispatch(AnimalActions.setCurrentAnimal(currentAnimal))
              dispatch(AnimalActions.setIsDetail(false))
              dispatch(AnimalActions.setActiveTab('registerAnimal'))
              onAccept(currentAnimal)
            },
            textWarning: t('do you want to register an animal?'),
            textButtonOnConfirm: t('yes'),
            textButtonOnNegative: t('no')
          }
        })
      }
    }, [createDialog, dispatch, isEditing, onAccept, t]
  )

  const onSuccess = useCallback(
    (response) => {
      snackbar.success(
        t(`your {this} was ${  isEditing ? 'edited' :'registered' }`, {
          howMany: 1,
          gender: 'male',
          this: t('calving')
        })
      )
      onSubmit()
      closeModal()
      registerAnimalModal(response)
    }, [closeModal, isEditing, onSubmit, registerAnimalModal, snackbar, t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(AnimalBirthActions.saveOfflineAnimalBirth(
        {
          ...animalBirth,
          ...data
        },
        (response) => onSuccess({ ...data, ...response, isNewAnimal: true, category: 'terneira' }),
        () => setIsLoading(false),
        true,
        true
      ))
    },
    [animalBirth, dispatch, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n params={ { this: t('calving') } }>{'register {this}'}</I18n>
        </>
      }
      disableFullScreen
      disableEsc
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        {
          isLoading ? <Loader width={ 100 } /> :
            <RegisterAnimalBirthForm
              ref={ formRef }
              defaultValues={ defaultValues }
              onSubmit={ handleSubmit }
              onCancel={ closeModal }
              animalId={ animalId }
            />
        }
      </>
    </Modal>
  )}

RegisterAnimalBirthModal.propTypes = {
  id: PropTypes.string.isRequired,
  animalId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onAccept: PropTypes.func,
  animalBirth: PropTypes.object
}

RegisterAnimalBirthModal.defaultProps = {
  handleClose: () => {},
  animalId: null,
  onSubmit: () => {},
  onAccept: () => {},
  animalBirth: {}
}

export default RegisterAnimalBirthModal
