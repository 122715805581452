import api from '../api'

export const getIndicatorsByDate = async params => {
  const { data } = await api.post('/v1/satelite/find-image', params)
  return data
}

export const getGeotiffImage = async params => {
  const { data } = await api.post('/v1/satelite/download-tiff', params)
  return data
}

