import React from 'react'

import PropTypes from 'prop-types'

import I18n from '@smartcoop/i18n'
import { close } from '@smartcoop/icons'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'

import { HeaderContainer, HeaderTitle } from './styles'

const ChatDetailsHeader = ({ handleCloseDetails }) => (
  <HeaderContainer>
    <IconButton size="medium" onClick={ handleCloseDetails }>
      <Icon icon={ close } size={ 20 } />
    </IconButton>
    <HeaderTitle>
      <I18n>details</I18n>
    </HeaderTitle>
  </HeaderContainer>
)

ChatDetailsHeader.propTypes = {
  handleCloseDetails: PropTypes.func.isRequired
}

export default ChatDetailsHeader
