/* eslint-disable no-param-reassign */
import { Database, Q } from '@nozbe/watermelondb'
import { Buffer } from 'buffer'

import last from 'lodash/last'

import api from '@smartcoop/services/apis/smartcoopApi'

import { extensionToContentType } from '../utils/contentType'

const SYNC_PENDING_FIELDS = [
  'type',
  'source',
  'params',
  'url',
  'sync',
  'error',
  'uri',
  'localMessageId',
  'sourceExtension',
  'documentType'
]

export function syncFileService(database: Database) {
  async function create(data) {
    await database.get('sync_file').create(item => {
      SYNC_PENDING_FIELDS.forEach(field => { item[field] = data[field] })
      item.sync = false
    })
  }

  async function update(register, error = '') {
    await database.write(async () => {
      await register.update(item => {
        item.sync = true
        item.error = error
      })
    })
  }

  async function updateTryAgain(id) {
    const register = await database.get('sync_file').find(id)

    await database.write(async () => {
      await register.update(item => {
        item.sync = false
        item.error = null
      })
    })
  }

  async function getUnsyncChanges() {
    return database
      .get('sync_file')
      .query(
        Q.where('sync', false),
        Q.where('error', '')
      )
      .fetch()
  }

  async function sync(callback = () => {}, callbackError = () => {}) {
    const registers = await getUnsyncChanges()

    if (registers.length) {
      const promises = registers.map(register => async () => {
        try {
          const formData = new FormData()
          const contentType = extensionToContentType[register.sourceExtension]

          if (register.uri) {
            formData.append('upload', {
              name: `smartcoop-file-${ register.localMessageId }.${ register.sourceExtension }`,
              type: contentType,
              uri: register.uri
            })
          } else {
            const base64 = last(register.source.split(','))

            const buffer = Buffer.from(base64, 'base64')
            const fileBlob = new Blob([buffer], { type: contentType })

            formData.append('upload', fileBlob, `smartcoop-file-${ register.localMessageId }.${ register.sourceExtension }`)
          }

          const response = await api.request({
            url: register.url,
            method: register.type,
            params: JSON.parse(register.params || '{}'),
            data: formData,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data'
            }
          })

          await update(register)
          await callback(register.localMessageId, response)
        } catch(error) {
          console.log('[Sync] Error on upload file: ', error)
          await update(register, error.message)
          if (register.localMessageId && register.id) {
            await callbackError(register.localMessageId, register.id)
          }
        }
      }).reverse()

      await Promise.all(promises.map(fn => fn()))
    }
  }

  function observeUnsyncChanges() {
    return database
      .collections.get('sync_file')
      .query(
        Q.where('sync', false),
        Q.where('error', '')
      )
      .observe()
  }

  return {
    sync,
    create,
    update,
    updateTryAgain,
    observeUnsyncChanges
  }
}
