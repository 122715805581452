import { encode, decode } from 'base-64'

export function base64ToArrayBuffer(b64) {
  const byteString = decode(b64)
  const byteArray = new Uint8Array(byteString.length)

  for (let i = 0; i < byteString.length; i += 1) {
    byteArray[i] = byteString.charCodeAt(i)
  }

  return byteArray
}

export function arrayBufferToBase64(buffer) {
  return encode(String.fromCharCode(...new Uint8Array(buffer)))
}
