import React, { useEffect, useMemo, useCallback, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment/moment'

import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { getAllAnimalPregnancyDiagnostics } from '@smartcoop/services/apis/smartcoopApi/resources/animalPregnancyDiagnostics'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { AnimalPregnancyDiagnosticsActions } from '@smartcoop/stores/animalPregnancyDiagnostics'
import { InseminationActions } from '@smartcoop/stores/insemination'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import {
  momentBackDateFormat,
  momentFriendlyDateFormat
} from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import FilterButton from '@smartcoop/web-components/FilterButton'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import FilterDiagnosisActionsModal from '@smartcoop/web-containers/modals/dairyFarm/FilterDiagnosisActionsModal'
import RegisterDiagnosisActionsModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterDiagnosisActionsModal'

import { Top } from './styles'

const DiagnosisListFragment = () => {
  const t = useT()
  const { createDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const tableRef = useRef(null)
  const snackbar = useSnackbar()

  const currentAnimal = useSelector(selectCurrentAnimal)
  const userWrite = useSelector(selectUserCanWrite)

  const [filters, setFilters] = useState({})

  const urlParams = useMemo(() => ({ animalId: currentAnimal.id }), [
    currentAnimal
  ])

  const registerDisabled = useMemo(
    () =>
      (currentAnimal?.animalStatus?.name!== 'Inseminada' &&
      currentAnimal?.animalStatus?.name!== 'Inseminada a confirmar' &&
      currentAnimal?.animalStatus?.name!== 'Prenha')
      || !userWrite,
    [currentAnimal, userWrite]
  )

  const queryParams = useMemo(() => {
    const { earring, ...rest } = filters
    const diagnosis = filters.diagnosisType
      ? { diagnosisType: String(filters.diagnosisType) }
      : {}
    return { ...rest, ...diagnosis }
  }, [filters])

  const columns = useMemo(
    () => [
      {
        title: t('insemination date'),
        field: 'inseminationDate',
        render: (row) =>
          moment(row.insemination.inseminationDate, momentBackDateFormat).format(
            momentFriendlyDateFormat
          ),
        sorting: true
      },
      {
        title: t('type of diagnosis'),
        field: 'typeName',
        sorting: true,
        render: row => row?.diagnosisType?.typeName

      },
      {
        title: t('accomplished date'),
        field: 'realizationDate',
        sorting: true,
        defaultSort: 'desc',
        render: (row) =>
          row.realizationDate
            ? moment(row.realizationDate, momentBackDateFormat).format(
              momentFriendlyDateFormat
            )
            : t('not accomplished', { gender: 'female' })
      },
      {
        title: t('result'),
        field: 'result',
        sorting: true,
        render: (row) => {
          switch (row.result) {
            case 'Positivo':
              return t('pregnant')
            case 'Inconclusivo':
              return t('inconclusive')
            case 'Vazia':
            default:
              return t('empty')
          }
        }
      }
    ],
    [t]
  )

  const reloadDataTable = useCallback(() => {
    tableRef.current.onQueryChange()
  }, [])

  const handleSetFilters = useCallback((filterValues) => {
    setFilters({
      ...filterValues,
      q: filterValues?.earring ?? null
    })
  }, [])

  const openFilterModal = useCallback(() => {
    createDialog({
      id: 'filter-fields',
      Component: FilterDiagnosisActionsModal,
      props: {
        onSubmit: handleSetFilters,
        filters
      }
    })
  }, [createDialog, filters, handleSetFilters])

  const handleDelete = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete-diagnosis',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(
              AnimalPregnancyDiagnosticsActions.deletePregnancyDiagnostic(
                row?.id,
                () => {
                  snackbar.success(
                    t('your {this} was removed', { howmany: 1, gender: 'male', this: t('diagnosis', { howMany: 1 }) })
                  )
                  reloadDataTable()
                }
              )
            )
          },
          textWarning: t('are you sure you want to remove the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('diagnosis', { howMany: 1 })
          })
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, snackbar, t]
  )

  const openCreateModal = useCallback(
    (_, data = {}) => {
      createDialog({
        id: 'create-diagnosis',
        Component: RegisterDiagnosisActionsModal,
        props: {
          onSubmit: reloadDataTable,
          data
        }
      })
    },
    [createDialog, reloadDataTable]
  )

  useEffect(
    () => {
      dispatch(InseminationActions.loadCurrentInsemination())
    },[dispatch]
  )

  useEffect(
    () => {
      dispatch(AnimalActions.loadCurrentAnimal())
    },[dispatch]
  )

  return (
    <>
      <Top>
        <div>
          <FilterButton
            onClick={ openFilterModal }
            isActive={ !values(filters).every(isEmpty) }
          />
        </div>
        <div>
          <Button
            id="register-lot"
            onClick={ openCreateModal }
            color="secondary"
            disabled={ registerDisabled }
          >
            <I18n>register</I18n>
          </Button>
        </div>
      </Top>
      <DataTable
        data={ getAllAnimalPregnancyDiagnostics }
        queryParams={ queryParams }
        columns={ columns }
        urlParams={ urlParams }
        tableRef={ tableRef }
        disabled={ !userWrite }
        id="diagnosis-list-table"
        onDeleteClick={ handleDelete }
      />
    </>
  )
}

export default DiagnosisListFragment
