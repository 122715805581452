/* eslint-disable no-param-reassign */
import { Database, Q } from '@nozbe/watermelondb'
import { blobToBase64 } from 'base64-blob'

import first from 'lodash/first'
import last from 'lodash/last'

import { getFile } from '@smartcoop/services/apis/smartcoopApi/resources/file'

import { extensionToContentType } from '../utils/contentType'

export function conversationFileService(database: Database) {
  async function create(conversationFile) {
    await database.get('conversation_file').create(item => {
      item.registerId = conversationFile.registerId
      item.messageId = conversationFile.messageId
      item.conversationId = conversationFile.conversationId
      item.documentName = conversationFile.documentName
      item.source = conversationFile.source
    })
  }

  async function findDocumentByName(documentName) {
    const registers = await database.get('conversation_file')
      .query(Q.where('documentName', documentName))
      .fetch()

    return registers ? first(registers) : null
  }

  async function fetchDocument({
    messageId,
    conversationId,
    documentName,
    registerId
  }) {
    await database.write(async () => {
      try {
        const document = await findDocumentByName(documentName)

        if (document) {
          await document.update(item => {
            item.messageId = messageId
          })
          return
        }

        const fileExtension = last(documentName.split('.'))
        const params = fileExtension === 'mp3' ? { format: fileExtension } : undefined

        const { data: file } = await getFile({ file: documentName }, params)
        const temporaryBase64 = await blobToBase64(file)

        const source = temporaryBase64.replace('application/octet-stream', extensionToContentType[fileExtension])

        await create({
          messageId,
          conversationId,
          documentName,
          source,
          registerId
        })
      } catch(error) {
        console.log('[Error] Erro ao buscar arquivo: ', error)
      }
    })
  }

  function observeConversationFileByConversationId(conversationId = null) {
    return database.get('conversation_file')
      .query(
        Q.where('conversationId', conversationId)
      ).observe()
  }

  return {
    create,
    fetchDocument,
    findDocumentByName,
    observeConversationFileByConversationId
  }
}
