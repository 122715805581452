import styled from 'styled-components'

import colors from '@smartcoop/styles/colors'

export const RadioTouchableContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

export const Divider = styled.hr`
  border: none;
  border-bottom: 0.935252px solid #E0E0E0;
  margin: 0;
  width: 100%;
`

export const Children = styled.div`
  background-color: ${ colors.white };
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 30px;
  width: 100%;
  padding: 10px;
  margin-top: 16px;
`

export const Container = styled.div`
  flex: 1;
  width: 100%;
  margin: 0;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 11px 0px;
`

export const IconContainer = styled.div`
  max-width: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  margin-top: 2px;
  font-size: 16px;
  flex: 1;
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
`
