import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

// import password from '@smartcoop/forms/validators/password.validator'
import required from '@smartcoop/forms/validators/required.validator'

const signatureOrder = ({ t }) => Yup.object().shape({

  signatureOrder: flow(
    // password({ t }),
    required({ t })
  )(Yup.string())

})

export default signatureOrder
