import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const registerObservations = ({ t }) => Yup.object().shape({
  animalsIds: Yup.string(),
  observation: flow(
    required({ t })
  )(Yup.string())
})

export default registerObservations
