import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { UserActions } from '@smartcoop/stores/user'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import EditAddressForm from '@smartcoop/web-containers/forms/digitalProperty/profile/EditAddressForm'

import useStyles from './styles'

const EditAddressModal = (props) => {
  const {
    id,
    open,
    handleClose
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const [isLoading, setIsLoading] = useState(false)
  const user = useSelector(selectUser)

  const defaultValues = useMemo(
    () => ({ ...user }), [user]
  )

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      handleClose()
    }, [handleClose]
  )

  const loadUserSuccess = useCallback(
    () => {
      snackbar.success(
        t('your {this} was edited', {
          howMany: 1,
          gender: 'male',
          this: t('address', { howMany: 1 })
        })
      )
      closeModal()

    }, [closeModal, snackbar, t]
  )

  const loadUser = useCallback(
    () => {
      dispatch(UserActions.loadUser(
        () => {loadUserSuccess()},
        () => {closeModal()}
      ))
    }, [closeModal, dispatch, loadUserSuccess]
  )


  const submitForms = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(UserActions.saveUser(
        {
          ...data
        },
        () => {loadUser()},
        () => {closeModal()}
      ))
    }, [closeModal, dispatch, loadUser]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n>edit profile</I18n>
        </>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      {
        isLoading ?
          <Loader width={ 100 } /> :
          (
            <>
              <EditAddressForm
                ref={ formRef }
                defaultValues={ defaultValues }
                onSubmit={ submitForms }
                onCancel={ handleClose }
              />
            </>
          )
      }
    </Modal>
  )}

EditAddressModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
}

EditAddressModal.defaultProps = {
  handleClose: () => {}
}

export default EditAddressModal
