// eslint-disable-next-line spaced-comment
export default ({ size, color = '#000' } = {}) => /*html*/`
  <svg width="${ size }" height="${ size }" version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 1080 1080" style="enable-background:new 0 0 1080 1080;" xml:space="preserve">
<g>
 <path fill="${ color }" d="M450.66,476.55c-115.31,4.1-217.08,23.82-291.25,53.85c7.61,3.68,16.07,7.33,25.09,10.95
   c69.45-24.83,160.96-41.62,261.38-45.84C447.22,489.11,448.89,482.81,450.66,476.55z M495.35,803.8c-255.77,0-434-79.08-434-150.04
   v-100.3c67.29,63.42,219.04,107.9,402.38,112.52c-5.42-12.26-9.95-24.88-13.54-37.83C218.86,619.53,61.35,545.7,61.35,479
   c0-70.96,178.23-150.02,434-150.02c18.93,0,37.28,0.56,55.33,1.39c17.67-13.5,36.67-24.8,56.78-33.64
   c-35.84-3.35-73.34-5.17-112.11-5.17c-264.34,0-471.42,82.33-471.42,187.44v174.76c0,105.11,207.08,187.45,471.42,187.45
   c63.02,0,122.71-4.73,177.18-13.28c-28.87-4.82-57.14-13.93-83.6-27.82C559.12,802.49,527.88,803.8,495.35,803.8z M1039.59,794.2
   l-100.14-99.94c-4.85-4.84-10.43-8.42-16.31-11.12c25-38.7,38.55-83.74,38.55-130.94c0.02-63.45-24.19-123.13-68.26-168.53
   c-0.6-0.74-1.26-1.47-1.95-2.17c-45.77-45.81-106.68-71.03-171.49-71.03c-64.82,0-125.72,25.22-171.49,71.03
   c-45.78,45.78-71.09,106.61-71.23,171.24c-0.15,64.74,24.98,125.56,70.74,171.27c47.08,47.08,108.95,70.61,170.88,70.61
   c45.17,0,90.31-12.69,129.83-37.72c2.73,6.08,6.44,11.83,11.44,16.82l100.14,99.94c10.96,10.94,25.3,16.4,39.65,16.4
   c14.38,0,28.76-5.5,39.72-16.48C1061.57,851.63,1061.53,816.09,1039.59,794.2z M850.94,683.85c-72.72,72.7-190.8,72.92-263.26,0.47
   c0,0,0-0.01-0.02-0.02c-35.12-35.07-54.39-81.75-54.28-131.44c0.11-49.69,19.57-96.46,54.81-131.69
   c35.15-35.19,81.96-54.57,131.78-54.57c48.69,0,94.49,18.52,129.39,52.22c0.51,0.59,1.04,1.17,1.59,1.73
   c35.21,35.12,54.61,81.87,54.59,131.65C905.56,601.96,886.17,648.71,850.94,683.85z M622.09,441.92c-3.65-3.65-9.57-3.65-13.23,0
   c-29.63,29.63-46,69-46.1,110.82c-0.09,41.89,16.17,81.23,45.77,110.79c1.83,1.82,4.22,2.73,6.61,2.73c2.39,0,4.79-0.91,6.61-2.74
   c3.66-3.66,3.66-9.58,0-13.24c-26.05-26.01-40.36-60.64-40.29-97.51c0.07-36.84,14.51-71.51,40.61-97.63
   C625.74,451.49,625.74,445.57,622.09,441.92z"/>
</g>
</svg>
`
