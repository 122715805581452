import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import first from 'lodash/first'

import { database } from '@smartcoop/database'
import { conversationService } from '@smartcoop/database/services/conversationService'
import { groupMemberService } from '@smartcoop/database/services/groupMemberService'
import { messageService } from '@smartcoop/database/services/messageService'
import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { exit, group, star, trash } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { colors } from '@smartcoop/styles'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'

import GroupMembers from '../../../containers/GroupMembers'
import AddMembersModal from '../../../modals/AddMembersModal/AddMembersModal'
import { ChatContext } from '../../../providers/ChatProvider'
import ChatDetailsHeader from '../ChatDetailsHeader'
import ChatDetailsImage from '../ChatDetailsImage'
import ChatDetailsName from '../ChatDetailsName'
import {
  ActionButton,
  ButtonsWrapper,
  CodeSpan,
  Container,
  ContentHeader,
  ContentWrapper,
  CustomInput,
  GroupMembersTitle,
  GroupMembersWrapper
} from './styles'

const ChatDetails = ({
  handleCloseDetails,
  conversations,
  localUser,
  groupMember,
  userId,
  membersCount,
  membersAdminCount
}) => {
  const [isAdmin, setIsAdmin] = useState(false)
  const [searchName, setSearchName] = useState('')

  const t = useT()
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()
  const { closeChat } = useContext(ChatContext)
  const conversation = first(conversations)

  const isGroupMember = Boolean(groupMember)

  const searchIsAdmin = useCallback(async () => {
    const isAdminResponse = await groupMemberService(database).checkUserIsAdmin(
      localUser?.userId,
      conversation.conversationId
    )

    setIsAdmin(isAdminResponse)
  }, [conversation, localUser])

  useEffect(() => {
    if (conversation.group || conversation.broadcast) {
      searchIsAdmin()
    }
  }, [conversation, searchIsAdmin])

  const handleFavorite = async () => {
    try {
      await conversationService(database).updateFavorite(
        (
          (conversation.group || conversation.broadcast) ?
            conversation.conversationId :
            conversation.relationalId
        ),
        !conversation.favorite,
        !conversation.conversationId
      )

      snackbar.success(t('action performed successfully'))
    } catch (error) {
      snackbar.warning(t('you can only add 3 favorites'))
    }
  }

  const confirmExitGroup = () => {
    createDialog({
      id: 'confirm-exit-group',
      Component: ConfirmModal,
      props: {
        onConfirm: handleExitGroup,
        textWarning: t(
          conversation.broadcast ?
            'do you really want to leave the "{name}" broadcast?' :
            'do you really want to leave the "{name}" group?',
          { name: conversation.name }
        )
      }
    })
  }

  const handleExitGroup = async () => {
    try {
      await groupMemberService(database).exitGroup(
        localUser?.userId,
        conversation.conversationId
      )

      snackbar.success(t(conversation.broadcast ? 'you left the broadcast' : 'you left the group'))
    } catch (error) {
      snackbar.warning(t('there was an error leaving the group'))
    }
  }

  const confirmDeleteConversation = () => {
    createDialog({
      id: 'confirm-delete-conversation',
      Component: ConfirmModal,
      props: {
        onConfirm: handleDeleteConversation,
        textWarning: t('do you want to delete the conversation?')
      }
    })
  }

  const handleDeleteConversation = async () => {
    try {
      closeChat()
      await conversationService(database).deleteConversations([conversation.conversationId])
      snackbar.success(t('conversation deleted successfully'))
    } catch (error) {
      snackbar.warning(t('there was an error deleting conversation'))
    }
  }

  const handleAddMembers = () => {
    createDialog({
      id: 'add-members-group-modal',
      Component: AddMembersModal,
      props: {
        localUser,
        groupId: conversation.conversationId,
        isBroadcast: conversation.broadcast
      }
    })
  }

  const handleClearChat = async () => {
    try {
      await messageService(database).clearMessagesHistory(conversation.conversationId)
      snackbar.success(t('conversation history deleted successfully'))
    } catch (error) {
      snackbar.warning(t('there was an error deleting conversation history'))
    }
  }

  const confirmClearChat = () => {
    createDialog({
      id: 'confirm-delete-conversation',
      Component: ConfirmModal,
      props: {
        onConfirm: handleClearChat,
        textWarning: t('do you really want to delete the conversation history?')
      }
    })
  }

  const isGroup = useMemo(() => conversation.group || conversation.broadcast, [conversation.broadcast, conversation.group])

  const canDeleteChat = useMemo(() => (
    (!conversation.group && !conversation.broadcast) ||
    (conversation.group && !isGroupMember) ||
    (conversation.broadcast && !isGroupMember)
  ), [conversation, isGroupMember])

  const canAddMembers = useMemo(() => (
    isAdmin &&
      isGroupMember && (
      conversation.group ||
        conversation.broadcast
    )
  ), [conversation, isAdmin, isGroupMember])

  const canExitChat = useMemo(() => (
    isGroupMember && (
      conversation.group ||
      conversation.broadcast
    )
  ), [conversation, isGroupMember])

  const disableExitChat = useMemo(() => (
    isAdmin &&
    membersCount > 1 &&
    membersAdminCount < 2
  ), [isAdmin, membersAdminCount, membersCount])

  return (
    <Container>
      <ChatDetailsHeader handleCloseDetails={ handleCloseDetails } />

      <ContentWrapper>
        <ContentHeader>
          <ChatDetailsImage
            conversation={ conversation }
            userId={ isGroup ? conversation.conversationId : userId }
            isAdmin={ isAdmin }
            isGroup={ isGroup }
          />
          <ChatDetailsName
            conversation={ conversation }
            isAdmin={ isAdmin }
            isGroupMember={ isGroupMember }
          />
          {/* UserCode */}
          { !conversation.group && !conversation.broadcast && (
            <CodeSpan>{conversation.userCode}</CodeSpan>
          ) }
        </ContentHeader>

        <ButtonsWrapper>
          <ActionButton
            icon={ star }
            onClick={ handleFavorite }
          >
            {conversation.favorite ? t('unfavorite') : t('favorite')}
          </ActionButton>

          <ActionButton
            icon={ trash }
            circleColor={ colors.error }
            onClick={ confirmClearChat }
          >
            {t('delete chat history')}
          </ActionButton>

          { canDeleteChat && (
            <ActionButton
              icon={ trash }
              circleColor={ colors.error }
              onClick={ confirmDeleteConversation }
            >
              {t('delete the conversation')}
            </ActionButton>
          ) }

          { canAddMembers && (
            <ActionButton
              icon={ group }
              circleColor={ colors.blackLight }
              onClick={ handleAddMembers }
            >
              {t('add members')}
            </ActionButton>
          ) }
          { canExitChat && (
            <ActionButton
              icon={ exit }
              circleColor={ colors.error }
              onClick={ confirmExitGroup }
              disabled={ disableExitChat }
            >
              {conversation?.broadcast ? t('exit broadcast') : t('exit group')}
            </ActionButton>
          ) }
        </ButtonsWrapper>

        { (conversation.group || conversation.broadcast) && (
          <GroupMembersWrapper>
            <GroupMembersTitle>{t('members')}</GroupMembersTitle>
            <CustomInput
              value={ searchName }
              onChange={ e => setSearchName(e.target?.value) }
              placeholder={ t('search member') }
            />
            <GroupMembers
              groupId={ conversation.conversationId }
              isGroupAdmin={ isAdmin }
              localUserId={ localUser?.userId }
              searchName={ searchName }
              isBroadcast={ (conversation.broadcast || false) }
            />
          </GroupMembersWrapper>
        ) }
      </ContentWrapper>
    </Container>
  )
}

ChatDetails.propTypes = {
  handleCloseDetails: PropTypes.func.isRequired,
  localUser: PropTypes.object.isRequired,
  conversations: PropTypes.array,
  groupMember: PropTypes.number,
  userId: PropTypes.string,
  membersCount: PropTypes.number,
  membersAdminCount: PropTypes.number
}

ChatDetails.defaultProps = {
  conversations: [],
  groupMember: 0,
  userId: null,
  membersCount: 0,
  membersAdminCount: 0
}

const enhance = withObservables(['relationalId', 'conversationId', 'localUserId'], ({ relationalId, conversationId, localUserId }) => ({
  conversations: conversationService(database).observeConversationById(conversationId, relationalId),
  groupMember: groupMemberService(database).observeLocalUserIsMember(conversationId, localUserId),
  membersCount: groupMemberService(database).observeGroupMembersCount(conversationId),
  membersAdminCount: groupMemberService(database).observeGroupAdminCount(conversationId)
}))

const EnhancedChatDetails = enhance(ChatDetails)

export default EnhancedChatDetails
