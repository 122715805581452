/* eslint-disable react/no-this-in-sfc */
import React, { useCallback, useState, useEffect, useRef } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import { useT } from '@smartcoop/i18n'
import { createFieldTimelineChartOptions } from '@smartcoop/utils/charts'
import { momentFriendlyShortDateFormat, momentBackDateFormat } from '@smartcoop/utils/dates'
import Chart from '@smartcoop/web-components/Chart'
import InputDateRange from '@smartcoop/web-components/InputDateRange'


const FieldTimelineChart = ({ field, growingSeason, isFromHistory, fieldHistory, readOnly, defaultDates, accessToken }) => {
  const t = useT()

  const mounted = useRef(false)

  const [dates, setDates] = useState(
    {
      from: !isEmpty(defaultDates) ? defaultDates?.from : moment().startOf('year').format(momentBackDateFormat),
      to: !isEmpty(defaultDates) ? defaultDates?.to : null
    })

  const [chartOptions, setChartOptions] = useState({})

  const getChartOptions = useCallback(
    async (firstTime = false) => {
      const options = await createFieldTimelineChartOptions({
        fieldId: isFromHistory ? fieldHistory.fieldId : field.id,
        dates: firstTime ? { from: isFromHistory ? fieldHistory?.startDate : field?.startDate, to: isFromHistory ? fieldHistory?.endDate : field?.endDate } : dates,
        t,
        growingSeasonId: growingSeason.id,
        closed: isFromHistory || growingSeason?.closed,
        accessToken
      })

      if (mounted.current) {
        setChartOptions(options)
        if(firstTime) {
          setDates(
            isFromHistory
              ? {
                from: moment(fieldHistory?.startDate).format(momentBackDateFormat),
                to: moment(fieldHistory?.endDate).format(momentBackDateFormat)
              }
              : {
                from: moment(options.xAxis.categories[0], momentFriendlyShortDateFormat).format(momentBackDateFormat),
                to: moment(options.xAxis.categories.slice(-1)[0], momentFriendlyShortDateFormat).format(momentBackDateFormat)
              })
        }
      }
    },
    [accessToken, dates, field, fieldHistory, growingSeason, isFromHistory, t]
  )

  useEffect(
    () => {
      if (dates.to && growingSeason?.id) {
        getChartOptions()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dates.to, growingSeason]
  )

  useEffect(
    () => {
      if(((!isFromHistory && field?.id) || (isFromHistory && fieldHistory?.fieldId)) && growingSeason?.id && !readOnly){
        getChartOptions(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    mounted.current = true
  }, [])

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  return (
    <div>
      {!readOnly && (
        <div style={ { padding: '0 20px' } }>
          <InputDateRange
            detached
            label={ t('period') }
            name="period"
            value={ dates }
            minWidth={ 250 }
            onChange={ setDates }
            style={ { marginBottom: 0 } }
            pickerProps={
              {
                minDate: isFromHistory ? moment(fieldHistory?.startDate).format(momentBackDateFormat) : null,
                maxDate: isFromHistory ? moment(fieldHistory?.endDate).format(momentBackDateFormat) : null
              }
            }
          />
        </div>
      )}
      <Chart options={ chartOptions } />
    </div>
  )
}

FieldTimelineChart.propTypes = {
  field: PropTypes.object.isRequired,
  fieldHistory: PropTypes.object,
  defaultDates: PropTypes.object,
  growingSeason: PropTypes.object,
  isFromHistory: PropTypes.bool,
  accessToken: PropTypes.string,
  readOnly: PropTypes.bool
}

FieldTimelineChart.defaultProps = {
  fieldHistory: {},
  growingSeason: {},
  defaultDates: {},
  accessToken: null,
  isFromHistory: false,
  readOnly: false
}

export default FieldTimelineChart
