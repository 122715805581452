import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

const useStylesTreeView = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400
  }
})

const useStylesTreeItem = makeStyles(({
  iconContainer: {
    '& .close': {
      opacity: 0.3
    }
  },
  group: {},
  content: {
    backgroundColor: 'transparent !important',
    marginBottom: 8,
    width: 'fit-content'
  },
  optionWithoutChildrens: {},
  checkboxContainer: {
    backgroundColor: '#F9F8F6',
    display: 'inline-block',
    paddingLeft: 14,
    borderRadius: 5,
    '& > label': {
      marginBottom: 0
    }
  },

  checkedCheckboxContainer: {
    backgroundColor: '#FFC80A',
    display: 'inline-block',
    paddingLeft: 14,
    borderRadius: 5,
    '& > label': {
      marginBottom: 0
    }
  },

  checkboxLabel: {}
}))

export {
  useStylesTreeView,
  useStylesTreeItem
}

export const Label = styled(Typography)`
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const ErrorLabel = styled(Label)`
  font-weight: normal;
  margin-left: 8px;
  font-size: 13px;
  margin-bottom: 10px;
  color: ${ colors.error };
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
