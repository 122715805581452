import React from 'react'

import PropTypes from 'prop-types'

import numberMask from '@smartcoop/forms/masks/number.mask'
import TextField from '@smartcoop/web-components/TextField'

const InputNumber = (props) => {
  const { mask, notSeparate, ...rest } = props
  return (
    <TextField
      numberFormatProps={ {
        decimalSeparator: ',',
        thousandSeparator: notSeparate ? '' : '.'
      } }
      setMask={ mask }
      { ...rest }
      type="tel"
    />
  )
}

InputNumber.propTypes = {
  /** Defines mask characters's limit  */
  maxLength: PropTypes.number,
  notSeparate: PropTypes.bool,
  mask: PropTypes.func
}

InputNumber.defaultProps = {
  maxLength: 10,
  notSeparate: false,
  mask: numberMask
}

export default InputNumber
