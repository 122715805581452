import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'

import I18n from '@smartcoop/i18n'
import { SalesOrdersActions } from '@smartcoop/stores/salesOrders'
import { selectSalesOrdersProducts, selectSalesOrdersBatches, selectSalesOrdersSettlementDates } from '@smartcoop/stores/salesOrders/selectorSalesOrders'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Modal from '@smartcoop/web-components/Modal'
import CreateOrderForm from '@smartcoop/web-containers/forms/commercialization/CreateOrderForm'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import useStyles, { Row, Content } from './styles'

const CreateOrderModal = ({ id, open, handleClose }) => {
  const classes = useStyles()
  const createOrderFormRef = useRef(null)
  const dispatch = useCallback(useDispatch(), [])

  const [formOption, setFormOption] = useState('in_cash')
  const [loading, setLoading] = useState(false)

  const products = useSelector(selectSalesOrdersProducts)
  const batches = useSelector(selectSalesOrdersBatches)
  const settlementDates = useSelector(selectSalesOrdersSettlementDates)

  const notExpiredProducs = useMemo(
    () => filter(products, ({ expirationDate }) => moment(expirationDate).diff(moment()) > 0),
    [products]
  )

  const showWarnText = useMemo(
    () => {
      switch (formOption) {
        case 'future_sale':
          return {
            empty: isEmpty(batches),
            message: <I18n style={ { color: colors.red } }>there are no valid batches</I18n>
          }
        case 'trigger':
          return {
            empty: isEmpty(products),
            message: <I18n style={ { color: colors.red } }>there is no balance for sale</I18n>
          }
        case 'in_cash':
        default:
          if (isEmpty(settlementDates)) {
            return {
              empty: isEmpty(settlementDates),
              message: <I18n style={ { color: colors.red } }>sale mode not available at the moment</I18n>
            }
          }
          return {
            empty: isEmpty(notExpiredProducs),
            message: <I18n style={ { color: colors.red } }>there are no available products for sale</I18n>
          }
      }
    },
    [batches, formOption, notExpiredProducs, products, settlementDates]
  )

  const clearForm = useCallback(
    () => {
      createOrderFormRef.current.reset()
      handleClose()
    },
    [handleClose]
  )

  const onSuccess = useCallback(
    () => {
      setLoading(false)
      handleClose()
    },
    [handleClose]
  )

  const onError = useCallback(
    () => {
      setLoading(false)
    },
    []
  )

  useEffect(() => {
    dispatch(SalesOrdersActions.loadSalesOrdersProducts())
    dispatch(SalesOrdersActions.loadSalesOrdersBatches({ expired: false }))
    dispatch(SalesOrdersActions.loadSalesOrdersSettlementDates())
  }, [dispatch])

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <Row>
          <I18n>new order</I18n>
        </Row>
      }
      disableFullScreen
      escape
      loading={ loading }
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        <Content>
          <CreateOrderForm
            ref={ createOrderFormRef }
            onSubmit={ () => setLoading(true) }
            onSucess={ onSuccess }
            onCancel={ () => setLoading(false) }
            onError={ onError }
            withoutSubmitButton
            onChangeFormType={ (type) => setFormOption(type) }
          />
          {
            showWarnText.empty
              ? showWarnText.message
              : <ButtonsContainer style={ { marginTop: 18 } }>
                <Button
                  id="cancel"
                  onClick={ () => clearForm() }
                  variant="outlined"
                  style={ { marginRight: 7 } }
                  disabled={ loading }
                >
                  <I18n>cancel</I18n>
                </Button>
                <Button
                  id="save"
                  onClick={ () => createOrderFormRef.current.submit() }
                  color="black"
                  style={ { marginLeft: 7 } }
                  disabled={ loading }
                >
                  <I18n>save</I18n>
                </Button>
              </ButtonsContainer>
          }
        </Content>
      </>
    </Modal>
  )}

CreateOrderModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default CreateOrderModal
