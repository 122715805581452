import React, {
  useCallback,
  forwardRef,
  useRef,
  useMemo,
  useState,
  useEffect
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import withObservables from '@nozbe/with-observables'
import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import lowerCase from 'lodash/lowerCase'
import map from 'lodash/map'
import omitBy from 'lodash/omitBy'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import { animalService } from '@smartcoop/database/services/animalService'
import { database } from '@smartcoop/database/web-database'
import { useDialog } from '@smartcoop/dialog'
import registerAnimal from '@smartcoop/forms/schemas/dairyFarm/registerAnimal.schema'
import registerNewAnimal from '@smartcoop/forms/schemas/dairyFarm/registerNewAnimal.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getAnimalBreeds as getAnimalBreedsService } from '@smartcoop/services/apis/smartcoopApi/resources/animalBreed'
import { getBulls as getBullsService } from '@smartcoop/services/apis/smartcoopApi/resources/animalBulls'
import { getAnimalReasons as getAnimalReasonsService } from '@smartcoop/services/apis/smartcoopApi/resources/animalReason'
import { getAnimalStatus as getAnimalStatusService } from '@smartcoop/services/apis/smartcoopApi/resources/animalStatus'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { getLots as getLotsService } from '@smartcoop/services/apis/smartcoopApi/resources/lot'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import {
  selectCurrentAnimal,
  selectIsDetail
} from '@smartcoop/stores/animal/selectorAnimal'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import { selectLotsOptions } from '@smartcoop/stores/lot/selectorLot'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import {
  SLUG_ANIMALS_STATUS,
  TYPE_ANIMALS,
  AnimalStatusCode
} from '@smartcoop/utils/constants'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import { createFormData, downloadFromBase64 } from '@smartcoop/utils/files'
import AvatarInput from '@smartcoop/web-components/AvatarInput'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'
import Loader from '@smartcoop/web-components/Loader'
import LoadingModal from '@smartcoop/web-containers/modals/LoadingModal'

import { Container, ButtonContainer, GridContainerAnimal, AvatarContainer, InfoContainer, SecondRowContainer, RowsContainer } from './styles'

const RegisterAnimalForm = forwardRef(({ currentStatus, setCurrentStatus, animals, loading, propertyId }) => {
  const t = useT()
  const history = useHistory()
  const snackbar = useSnackbar()
  const registerFormRef = useRef(null)
  const dispatch = useCallback(useDispatch(), [])

  const isDetail = useSelector(selectIsDetail)
  const lotsOptions = useSelector(selectLotsOptions)
  const currentAnimal = useSelector(selectCurrentAnimal)
  const currentProperty = useSelector(selectCurrentProperty)
  const userWrite = useSelector(selectUserCanWrite)

  const { createDialog, removeDialog } = useDialog()

  const [slaughterDate, setSlaughterDate] = useState(
    currentAnimal?.slaughterDate || ''
  )
  const [currentCategory, setCurrentCategory] = useState(lowerCase(currentAnimal?.category) || '')
  const [checkboxDiscard, setCheckboxDiscard] = useState(currentAnimal?.discard || false)
  const [isAvatarLoading, setIsAvatarLoading] = useState(false)
  const [avatarAnimalPicture, setAvatarAnimalPicture] = useState('')
  const [animalImage, setAnimalImage] = useState(null)
  const [motivation, setMotivation] = useState(currentAnimal?.motivation || null)

  const motherOptions = useMemo(() => map(filter(animals, item => item?.category === 'vaca'), item => ({
    label: item?.nameAndCode,
    value: item?.earringId
  })), [animals])

  const bullsOptions = useMemo(() => map(filter(animals, item => item?.category === 'touro'), item => ({
    label: item?.nameAndCode,
    value: item?.animalId
  })), [animals])



  useEffect(() => {
    if(!isEmpty(currentAnimal?.animalImage)) {
      setIsAvatarLoading(true)
      getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_HERDS_MANAGEMENT_ID, fileKey: currentAnimal?.animalImage?.fileKey })
        .then(({ data: { file: data } }) => {
          setAvatarAnimalPicture(downloadFromBase64(data))
          setIsAvatarLoading(false)
        }).catch(() => {
          snackbar.error(t('animal avatar load error'))
        })
    }
  }, [currentAnimal.animalImage, snackbar, t])

  const isNewAnimal = useMemo(() => {
    if (currentAnimal.isNewAnimal) {
      return true
    }
    return false
  }, [currentAnimal.isNewAnimal])

  const onSuccess = useCallback(() => {
    snackbar.success(
      t(
        currentAnimal?.id
          ? 'your {this} was edited'
          : 'your {this} was registered',
        {
          howMany: 1,
          gender: 'male',
          this: t('animal', { howMany: 1 })
        }
      )
    )
    dispatch(DairyFarmActions.setCurrentSection('cattleManagement'))
    history.goBack()
  }, [currentAnimal, dispatch, history, snackbar, t])

  const isBullsOrAnimals = useMemo(
    () => {
      const { fatherBull, fatherEarring } = currentAnimal
      if(isUndefined(fatherEarring)) {
        return TYPE_ANIMALS.bulls
      }

      if(isUndefined(fatherBull)) {
        return TYPE_ANIMALS.animals
      }

      return TYPE_ANIMALS.bulls
    },
    [currentAnimal]
  )

  const handleSubmit = useCallback(() => {
    createDialog({
      id: 'loading',
      Component: LoadingModal,
      props: {
        message: t('saving your {this}', {
          howMany: 1,
          gender: 'male',
          this: t('animal', { howMany: 1 })
        })
      }
    })

    const removeLoader = () => {
      setTimeout(() => removeDialog({ id: 'loading' }), 150)
    }

    const newData = omitBy(
      registerFormRef.current.getData(),
      (item) => item === ''
    )
    dispatch(
      AnimalActions.saveOfflineAnimal(
        {
          ...currentAnimal,
          ...newData,
          fatherTable: isBullsOrAnimals,
          maternalGrandfatherTable: TYPE_ANIMALS.bulls,
          maternalGreatGrandfatherTable: TYPE_ANIMALS.bulls,
          discard: checkboxDiscard,
          lactationsNumber: newData?.lactationsNumber ? Number(newData?.lactationsNumber) : null,
          saleValue: newData?.saleValue ? toNumber(newData?.saleValue) : null,
          earring: {
            ...currentAnimal?.earring,
            earringCode: newData?.earringCode
          },
          animalImage: animalImage ? createFormData([animalImage], 'image') : null,
          propertyId
        },
        () => {
          removeLoader()
          onSuccess()
        },
        removeLoader,
        true
      )
    )
  }, [animalImage, checkboxDiscard, createDialog, currentAnimal, dispatch, isBullsOrAnimals, onSuccess, propertyId, removeDialog, t])

  const optionsCategory = useMemo(
    () => [
      {
        label: 'Touro',
        value: 'touro'
      },
      {
        label: 'Boi',
        value: 'boi'
      },
      {
        label: 'Terneira',
        value: 'terneira'
      },
      {
        label: 'Novilha',
        value: 'novilha'
      },
      {
        label: 'Vaca',
        value: 'vaca'
      }
    ],
    []
  )

  const optionsMotivation = useMemo(
    () => [
      {
        label: 'Descarte',
        value: 'descarte'
      },
      {
        label: 'Morte',
        value: 'morte'
      },
      {
        label: 'Venda',
        value: 'venda'
      }
    ],
    []
  )

  const service = useMemo(
    () => {
      const { fatherBull, fatherEarring } = currentAnimal
      if(isUndefined(fatherEarring)) {
        return getBullsService
      }

      if(isUndefined(fatherBull)) {
        return bullsOptions
      }

      return getBullsService
    }, [bullsOptions, currentAnimal]
  )

  const urlParams = useMemo(
    () => ({
      propertyId: currentProperty?.id
    }),
    [currentProperty]
  )

  const defaultValueFather = useMemo(
    () => {
      const { fatherBull, fatherEarring } = currentAnimal
      if(isUndefined(fatherEarring)) {
        return fatherBull?.id
      }

      if(isUndefined(fatherBull)) {
        return fatherEarring?.id
      }

      return fatherBull?.id
    },
    [currentAnimal]
  )

  const asyncValueFather = useMemo(
    () => {
      const { fatherBull, fatherEarring } = currentAnimal
      if(isUndefined(fatherEarring)) {
        return 'id'
      }

      if(isUndefined(fatherBull)) {
        return 'earring.id'
      }

      return 'id'
    },
    [currentAnimal]
  )

  const queryParams = useMemo(() => {
    if (!isEmpty(currentAnimal?.fatherEarring)) {
      return { category: 'touro' }
    }
    return {}
  }, [currentAnimal])

  const queryParamsStatus = useMemo(
    () =>
      !isEmpty(currentAnimal)
        ? {}
        : {
          slug: [
            SLUG_ANIMALS_STATUS.nenhum,
            SLUG_ANIMALS_STATUS.aptas,
            SLUG_ANIMALS_STATUS.vazia
          ]
        },
    [currentAnimal]
  )

  const defaultLot = useMemo(() => {
    if (currentAnimal.isNewAnimal) {
      const terneiraLot = find(lotsOptions, (item) => item.label === 'Terneira')

      return terneiraLot.value
    }
    return currentAnimal?.lot?.id
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnimal, lotsOptions])

  const disableBirthInformation = useMemo(
    () => !isEmpty(currentAnimal?.animalBirth),
    [currentAnimal.animalBirth]
  )

  useEffect(() => {
    setCheckboxDiscard(currentAnimal?.discard || false)
  }, [currentAnimal, isDetail])

  const handleAvatarChange = useCallback(
    (file) => {
      setIsAvatarLoading(true)
      const fileURL = URL.createObjectURL(file)
      setAvatarAnimalPicture(fileURL)
      setAnimalImage(file)
      setIsAvatarLoading(false)
    },
    []
  )

  const renderAvatar = useMemo(() => isAvatarLoading ? (
    <Loader width={ 120 } style={ { padding: 0 } } />
  ) : (
    <div>
      <AvatarInput src={ avatarAnimalPicture } onChange={ handleAvatarChange } animalAvatar hideUpload={ isDetail } />
    </div>
  ), [avatarAnimalPicture, handleAvatarChange, isAvatarLoading, isDetail]
  )

  return loading ? <Loader width={ 120 } /> : (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ registerFormRef }
        schemaConstructor={ isNewAnimal ? registerNewAnimal : registerAnimal }
        onSubmit={ handleSubmit }
      >
        <InfoContainer>
          <AvatarContainer>{renderAvatar}</AvatarContainer>
          <RowsContainer>
            <InputText
              label={ t('{this} name', {
                gender: 'male',
                this: t('animal', { howMany: 1 })
              }) }
              style={ { marginBottom: 0 } }
              name="name"
              defaultValue={ currentAnimal?.name }
              disabled={ isDetail }
              fullWidth
            />
            <SecondRowContainer>
              <InputText
                label={ t('earring') }
                name="earringCode"
                style={ { marginBottom: 0 } }
                defaultValue={ currentAnimal?.earring?.earringCode }
                disabled={ isDetail }
                fullWidth
              />
              <InputDate
                label={ t('date of birth') }
                name="birthDate"
                style={ { marginBottom: 0 } }
                pickerProps={ {
                  maxDate: moment().format(),
                  fullWidth: true
                } }
                defaultValue={
                  currentAnimal?.birthDate
                    ? moment(currentAnimal?.birthDate, momentBackDateFormat).format(
                      momentBackDateFormat
                    )
                    : ''
                }
                disabled={ isDetail || currentAnimal.isNewAnimal || disableBirthInformation }
                fullWidth
              />
            </SecondRowContainer>
          </RowsContainer>
        </InfoContainer>
        <GridContainerAnimal>
          <InputSelect
            label={ t('category') }
            name="category"
            options={ optionsCategory }
            style={ { marginBottom: 0 } }
            defaultValue={ lowerCase(currentAnimal?.category) }
            disabled={ isDetail }
            onChange={ (value) => setCurrentCategory(value.target.value) }
          />
          <InputSelect
            label={ t('lot', { howMany: 1 }) }
            name="lotId"
            style={ { marginBottom: 0 } }
            options={ getLotsService }
            urlParams={ { propertyId } }
            defaultValue={ defaultLot }
            disabled={ isDetail }
          />
          <InputSelect
            label={ t('predominant race') }
            name="predominantBreedId"
            options={ getAnimalBreedsService }
            style={ { marginBottom: 0 } }
            defaultValue={ currentAnimal?.predominantBreed?.id }
            disabled={ isDetail }
          />

          <InputSelect
            label={ t('status') }
            name="statusId"
            style={ { marginBottom: 0 } }
            options={ getAnimalStatusService }
            queryParams={ queryParamsStatus }
            required
            defaultValue={ currentAnimal?.animalStatus?.id && toNumber(currentAnimal?.animalStatus?.id) }
            disabled={
              (!currentAnimal.isNewAnimal &&
                (isDetail || !isEmpty(currentAnimal)))
              || currentAnimal.isNewAnimal
            }
            onChange={ (value) => setCurrentStatus(value.target.value) }
          />
          {
            currentCategory === 'vaca' && (
              <>
                <InputDate
                  label={ t('last calving') }
                  name="lastBirth"
                  style={ { marginBottom: 0 } }
                  pickerProps={ {
                    maxDate: moment().format()
                  } }
                  defaultValue={
                    currentAnimal?.lastBirth
                      ? moment(currentAnimal?.lastBirth, momentBackDateFormat).format(
                        momentBackDateFormat
                      )
                      : ''
                  }
                  disabled={ isDetail || currentStatus === AnimalStatusCode.DESCARTE }
                  fullWidth
                />
                <InputNumber
                  label={ t('number of lactations') }
                  style={ { marginBottom: 0 } }
                  name="lactationsNumber"
                  defaultValue={ toString(currentAnimal?.lactationsNumber || 0) }
                  disabled={ isDetail || currentStatus === AnimalStatusCode.DESCARTE }
                />
              </>
            )
          }
          {!currentAnimal.isNewAnimal && (
            <>
              <InputSelect
                label={ t('{this} code', {
                  this: t('father')
                }) }
                name="fatherCode"
                style={ { marginBottom: 0 } }
                options={ service }
                asyncOptionLabelField={ isBullsOrAnimals === TYPE_ANIMALS.animals ? 'animalListView.nameAndCode' : 'nameAndCode' }
                asyncOptionValueField={ asyncValueFather }
                urlParams={ urlParams }
                queryParams={ queryParams }
                disabled={ isDetail || disableBirthInformation }
                defaultValue={ defaultValueFather }
              />
              <InputSelect
                label={ t('{this} code', {
                  this: t('mother')
                }) }
                name="motherEarringId"
                style={ { marginBottom: 0 } }
                options={ motherOptions }
                asyncOptionLabelField="nameAndCode"
                asyncOptionValueField="earringId"
                disabled={ isDetail || disableBirthInformation }
                defaultValue={ currentAnimal?.motherEarring?.id }
              />
              <InputSelect
                label={ t('{this} code', {
                  this: t('maternal grandfather')
                }) }
                name="maternalGrandfatherCode"
                style={ { marginBottom: 0 } }
                options={ getBullsService }
                asyncOptionLabelField="nameAndCode"
                disabled={ isDetail || disableBirthInformation }
                defaultValue={ currentAnimal?.grandfatherBull?.id }
              />
              <InputSelect
                label={ t('{this} code', {
                  this: t('great grandmother')
                }) }
                name="maternalGreatGrandfatherCode"
                style={ { marginBottom: 0 } }
                options={ getBullsService }
                asyncOptionLabelField="nameAndCode"
                disabled={ isDetail || disableBirthInformation }
                defaultValue={ currentAnimal?.greatGrandfatherBull?.id }
              />
            </>
          )}
          <CheckboxButton
            label={ t('discard') }
            onChange={ () => setCheckboxDiscard(!checkboxDiscard) }
            checked={ checkboxDiscard }
            value={ checkboxDiscard }
            disabled={ isDetail }
          />
          {!currentAnimal.isNewAnimal && (
            <InputDate
              label={ t('date of discharge') }
              name="slaughterDate"
              style={ { marginBottom: 0 } }
              pickerProps={ {
                maxDate: moment().format()
              } }
              defaultValue={
                currentAnimal?.slaughterDate
                  ? moment(currentAnimal?.slaughterDate, momentBackDateFormat)
                  : ''
              }
              disabled={ isDetail }
              onChange={ setSlaughterDate }
              fullWidth
            />
          )}

          {!isEmpty(slaughterDate) && (
            <>
              <InputSelect
                name="motivation"
                label={ t('select the reason for the discharge') }
                style={ { marginBottom: 0 } }
                options={ optionsMotivation }
                defaultValue={ currentAnimal?.motivation }
                disabled={ isDetail }
                onChange={ ({ target: { value } }) => setMotivation(value) }
                fullWidth
              />

              <InputSelect
                label={ t('reason') }
                name="slaughterReasonId"
                options={ getAnimalReasonsService }
                style={ { marginBottom: 0 } }
                asyncOptionLabelField="description"
                defaultValue={ currentAnimal?.slaughterReason?.id }
                disabled={ isDetail }
              />

              {motivation === 'venda' ? (
                <InputUnit
                  label={ t('sale value') }
                  name="saleValue"
                  fullWidth
                  type="float"
                  unit="R$"
                />
              ) : null}

            </>
          )}
        </GridContainerAnimal>
        <ButtonContainer>
          {!isEmpty(currentAnimal) && (
            <div>
              <Button
                id="edit-animal"
                variant="outlined"
                style={ { marginTop: 30, marginRight: 10 } }
                onClick={ () => dispatch(AnimalActions.setIsDetail(!isDetail)) }
                disabled={ !userWrite }
              >
                <I18n>{isDetail ? 'edit' : 'cancel'}</I18n>
              </Button>
            </div>
          )}
          {!isDetail && (
            <div>
              <Button
                id="register-animal"
                style={ { marginTop: 30 } }
                color="secondary"
                onClick={ () => registerFormRef.current.submit() }
              >
                <I18n>complete registration</I18n>
              </Button>
            </div>
          )}
        </ButtonContainer>
      </Form>
    </Container>
  )
})

RegisterAnimalForm.propTypes = {
  currentStatus: PropTypes.string,
  propertyId: PropTypes.string,
  loading: PropTypes.bool,
  animals: PropTypes.array,
  setCurrentStatus: PropTypes.func
}

RegisterAnimalForm.defaultProps = {
  currentStatus: '',
  propertyId: null,
  loading: false,
  animals: [],
  setCurrentStatus: () => {}
}

const enhance = withObservables(['propertyId'], ({ propertyId }) => ({
  animals: animalService(database).observeAnimals(propertyId)
}))

const EnhancedRegisterAnimalForm = enhance(RegisterAnimalForm)

export default EnhancedRegisterAnimalForm