import { call, put, takeLatest } from 'redux-saga/effects'

import { getWebinars, accessWebinar as accessWebinarService, createInformative, deleteInformative as deleteInformativeService, editInformative } from '@smartcoop/services/apis/smartcoopApi/resources/informatives'

import { InformativesActions, InformativesTypes } from './duckInformatives'

function* loadWebinars({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: { data, ...pagination } } = yield call(getWebinars, {
      ...params
    })

    yield put(InformativesActions.loadWebinarsSuccess(
      data,
      () => onSuccess(pagination)
    ))
  } catch (err) {
    const error = err.message
    yield put(InformativesActions.informativesError(error))
    yield call(onError, error)
  }
}

function* accessWebinar({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: { data } } = yield call(accessWebinarService, {
      ...params
    })

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(InformativesActions.informativesError(error))
    yield call(onError, error)
  }
}

function* saveInformative({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: { data } } = yield call(params?.id ? editInformative : createInformative, {
      ...params
    },{ informativeId: params?.id })

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(InformativesActions.informativesError(error))
    yield call(onError, error)
  }
}

function* deleteInformative({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteInformativeService, {
      ...params
    })

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(InformativesActions.informativesError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(InformativesTypes.LOAD_WEBINARS, loadWebinars),
  takeLatest(InformativesTypes.SAVE_INFORMATIVE, saveInformative),
  takeLatest(InformativesTypes.DELETE_INFORMATIVE, deleteInformative),
  takeLatest(InformativesTypes.ACCESS_WEBINAR, accessWebinar)
]
