import React, { useCallback, forwardRef, useMemo } from 'react'

import PropTypes from 'prop-types'

import filterProductSchema from '@smartcoop/forms/schemas/shoppingPlatform/product/filterProduct.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getProductGroups } from '@smartcoop/services/apis/smartcoopApi/resources/productGroup'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import { Item } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container, ButtonContainer, Row } from './styles'

const FilterProductForm = forwardRef((props, formRef) => {
  const { withoutSubmitButton, loading, onSubmit, filters } = props

  const t = useT()

  const handleSubmit = useCallback((data) => onSubmit(data), [onSubmit])

  const options = useMemo(
    () => [
      { value: true, label: t('yes') },
      { value: false, label: t('no') }
    ],
    [t]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ filterProductSchema }
        onSubmit={ handleSubmit }
      >
        <Container style={ { flex: 1 } }>
          <Item style={ { padding: '0 35px 0 35px' } }>
            <InputSelect
              label={ t('product group') }
              name="productGroupId"
              defaultValue={ filters.productGroupId }
              options={ getProductGroups }
            />
            <InputText
              label={ t('description') }
              name="description"
              defaultValue={ filters.description }
            />
            <InputText
              label={ t('unitOfMeasure', { howMany: 1 }) }
              name="unitOfMeasures"
              defaultValue={ filters.unitOfMeasures }
            />
            <InputText label={ t('ean') } name="ean" defaultValue={ filters.ean } />
            <InputNumber
              label={ t('dun14') }
              name="dun14"
              maxLength={ 14 }
              defaultValue={ filters.dun14 }
            />
          </Item>
          <Row>
            <RadioGroup
              setNull
              label="Ativo?"
              name="active"
              options={ options }
              defaultValue={ filters.active ?? null }
              clearable
              style={ { paddingLeft: 10, paddingRight:10 } }
            />
            <RadioGroup
              setNull
              label="Digitalização"
              name="digitalization"
              options={ options }
              defaultValue={ filters.digitalization ?? null }
              style={ { paddingLeft: 10, paddingRight:10 } }
            />
            <RadioGroup
              setNull
              label="Cultivar"
              name="cultivate"
              options={ options }
              defaultValue={ filters.cultivate ?? null }
              style={ { paddingLeft: 10, paddingRight:10 } }
            />
            <RadioGroup
              setNull
              label="Compras"
              name="purchasing"
              options={ options }
              defaultValue={ filters.purchasing ?? null }
              style={ { paddingLeft: 10, paddingRight:10 } }
            />
          </Row>

          <Row>
            <RadioGroup
              setNull
              label="Calendarizado"
              name="scheduled"
              options={ options }
              defaultValue={ filters.scheduled ?? null }
              style={ { paddingLeft: 10, paddingRight:10 } }
            />
            <RadioGroup
              setNull
              label="Contínuo"
              name="continuous"
              options={ options }
              defaultValue={ filters.continuous ?? null }
              style={ { paddingLeft: 10, paddingRight:10 } }
            />

            <RadioGroup
              setNull
              label="Comercialização"
              name="commercialization"
              options={ options }
              defaultValue={ filters.commercialization ?? null }
              style={ { paddingLeft: 10, paddingRight:10 } }
            />
          </Row>

          <Row style={ { justifyContent: 'flex-end' } }>
            {!withoutSubmitButton && (
              <ButtonContainer>
                <Button
                  id="web-order-filter-form-button"
                  style={ { width: '48%' } }
                  onClick={ () => formRef.current.submit() }
                  disabled={ loading }
                >
                  <I18n>filter</I18n>
                </Button>
              </ButtonContainer>
            )}
          </Row>
        </Container>
      </Form>
    </Container>
  )
})

FilterProductForm.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool
}

FilterProductForm.defaultProps = {
  filters: {},
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false
}

export default FilterProductForm
