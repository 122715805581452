import React, { useMemo } from 'react'
import { Marker, Popup } from 'react-leaflet'

import Leaflet from 'leaflet'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

const PinMarker = (props) => {
  const {
    children,
    coordinate,
    size,
    iconAnchor,
    customIcon,
    onClosePopup,
    onOpenPopup,
    iconProps,
    popupProps,
    tooltip,
    ...rest
  } = props

  const icon = useMemo(
    () => new Leaflet.DivIcon({
      html: customIcon({ size, ...iconProps }),
      iconSize: new Leaflet.Point(size, size),
      iconAnchor
    }),
    [customIcon, iconAnchor, size, iconProps]
  )

  const position = useMemo(
    () => {
      const response = []
      if (!isNil(coordinate?.latitude) && !isNil(coordinate?.longitude)) {
        response.push(coordinate.latitude, coordinate.longitude)
      }
      return response
    },
    [coordinate.latitude, coordinate.longitude]
  )

  return !isEmpty(position) && (
    <Marker
      position={ position }
      icon={ icon }
      { ...rest }
    >
      {tooltip}
      {children && (
        <Popup
          onClose={ onClosePopup }
          onOpen={ onOpenPopup }
          { ...popupProps }
        >
          <span>{children}</span>
        </Popup>
      )}
    </Marker>
  )
}


PinMarker.propTypes = {
  coordinate: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  size: PropTypes.number,
  onClosePopup: PropTypes.func,
  onOpenPopup: PropTypes.func,
  iconAnchor: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.any,
  customIcon: PropTypes.any.isRequired,
  iconProps: PropTypes.object,
  popupProps: PropTypes.object,
  tooltip: PropTypes.element
}

PinMarker.defaultProps = {
  tooltip: null,
  coordinate: {},
  onClosePopup: () => {},
  onOpenPopup: () => {},
  size: 50,
  children: null,
  iconAnchor: null,
  iconProps: {},
  popupProps: {}
}

export default PinMarker
