import React, { useCallback, forwardRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import kebabCase from 'lodash/kebabCase'

import registerProductGroupSchema from '@smartcoop/forms/schemas/shoppingPlatform/productGroup/registerProductGroup.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getCrops } from '@smartcoop/services/apis/smartcoopApi/resources/crop'
import { ProductGroupActions } from '@smartcoop/stores/productGroup'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import { Item, Title, ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'

import { Row, Header, Container } from './styles'

const CreateProductGroupForm = forwardRef((props, formRef) => {
  const { defaultValues, isEditing, onSuccess, onCancel } = props
  const [isLoading, setIsLoading] = useState(false)
  const [mounted, setMounted] = useState(false)
  const t = useT()
  const dispatch = useCallback(useDispatch(), [])

  useEffect(
    () => {
      if (mounted) {
        formRef.current.setData(defaultValues)
      } else {
        setMounted(true)
      }
    },
    [defaultValues, formRef, mounted]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      if(isEditing) {
        dispatch(ProductGroupActions.updateProductGroup(
          {
            ...data,
            slug: kebabCase(data.name)
          },
          defaultValues.id,
          () => {
            setIsLoading(false)
            onSuccess()
          },
          () => setIsLoading(false)
        ))
      } else {
        dispatch(ProductGroupActions.saveProductGroup(
          {
            ...data,
            slug: kebabCase(data.name)
          },
          () => {
            setIsLoading(false)
            onSuccess()
          },
          () => setIsLoading(false)
        ))
      }
    },
    [defaultValues, dispatch, isEditing, onSuccess]
  )

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ registerProductGroupSchema }
      onSubmit={ handleSubmit }
      style={ { flex: 1 } }
      loading={ isLoading }
    >
      <SplitedScreenLayout
        title={ { name: t('product group registration') } }
        divRightStyle={ { paddingTop: 67 } }
        leftChildren={
          <Container>
            <Header style={ { paddingLeft: 35 } }>
              <Title style={ { fontSize: 16, fontWeight: 600 } }>
                <I18n>product group pre-registration</I18n>
              </Title>
            </Header>

            <Item style={ { padding: '0 35px 0 35px' } }>
              <InputText
                name="name"
                label={ t('name') }
                fullWidth
              />
              <InputText
                name="description"
                label={ t('description') }
                fullWidth
              />
            </Item>
          </Container>
        }
        rightChildren={
          <Container style={ { flex: 1 } }>
            <Header>
              <Title style={ { fontSize: 16, fontWeight: 600 } }>
                <I18n>add crops</I18n>
              </Title>
            </Header>

            <Item style={ { padding: '0 15px 0 15px' } }>
              <InputSelect
                multiple
                label={ t('search and add') }
                name="cropsId"
                options={ getCrops }
                style= { { marginBottom: 0 } }
                asyncOptionLabelField="description"
              />
            </Item>

            <Row>
              <div style={ { flex: 2 } } />
              <ButtonsContainer style={ { flex: 1 } }>
                <Button
                  id="cancel-order-register-form"
                  onClick={ onCancel }
                  style={ { flex: 1 } }
                  variant="outlined"
                >
                  <I18n>cancel</I18n>
                </Button>

                <div style={ { width: '10%' } } />

                <Button
                  id="submit-order-register-form"
                  onClick={ () => formRef.current.submit() }
                  style={ { flex: 1 } }
                >
                  <I18n>save</I18n>
                </Button>
              </ButtonsContainer>
            </Row>
          </Container>
        }
      />
    </Form>
  )
})

CreateProductGroupForm.propTypes = {
  defaultValues: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func
}

CreateProductGroupForm.defaultProps = {
  isEditing: false,
  onSuccess: () => {},
  onCancel: () => {}
}

export default CreateProductGroupForm
