import trimMask from '@meta-awesome/functions/src/trimMask'
import axios from 'axios'

const api = axios.create({
  baseURL: 'https://viacep.com.br/ws/'
})


const searchCep = async (cep) => {
  try {
    const { data } = await api.get(`${ trimMask(cep) }/json`)
    return {
      state: data?.uf,
      cep: data?.cep,
      city: data?.localidade,
      neighborhood: data?.bairro,
      street: data?.logradouro,
      addressComplement: data?.complemento,
      ibge: data?.ibge
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
}

export { searchCep }

export default api
