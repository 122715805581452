/* eslint-disable no-param-reassign */
import { Database } from '@nozbe/watermelondb'
import moment from 'moment'

import filter from 'lodash/filter'
import find from 'lodash/find'
import first from 'lodash/first'
import flatMap from 'lodash/flatMap'
import join from 'lodash/join'
import map from 'lodash/map'
import round from 'lodash/round'

import api from '@smartcoop/services/apis/smartcoopApi'
import { getUserByCpf } from '@smartcoop/services/apis/smartcoopApi/resources/user'

import { contactService } from './contactService'
import { conversationService } from './conversationService'
import { messageService } from './messageService'
import { ROUTES } from './routes'
import { syncHistoricService } from './syncHistoricService'
import { userService } from './userService'

export function syncService(database: Database) {
  async function syncMessagesByIds(ids = [], totalConversationPages = 0, conversationPage = 0, page = 1) {
    const syncDate = await syncHistoricService(database).getSyncHistoric('message')
    const syncDateMoment = moment(syncDate, 'X').format()

    const { data: { data: response,  totalPages } } = await api.get(
      ROUTES.MESSAGE,
      { params: { ids: ids.join(','), date: syncDateMoment, page, limit: 200 } }
    )


    const step = 100/totalConversationPages
    const percentage = ((page*100)/totalPages)*(step*0.01)
    const integrationPercentage = totalConversationPages && totalPages ? round(((conversationPage - 1)*step)+percentage) : 0

    await syncHistoricService(database).updatePagesData('conversation', integrationPercentage)

    await messageService(database).createOrUpdate(response)

    if (page < totalPages) {
      await syncMessagesByIds(ids, totalConversationPages, conversationPage, page + 1)
    }
  }

  async function syncConversations (page = 1) {
    const syncDate = await syncHistoricService(database).getSyncHistoric('conversation')
    const syncDateMoment = moment(syncDate, 'X').format()

    const { data: { totalOfPages, data: response } } = await api.get(
      ROUTES.CONVERSATION,
      { params: { page, limit: 20, date: syncDateMoment } }
    )

    const { data: { data: responseMembers } } = await api.get(
      ROUTES.MEMBERS,
      { params: { conversationIds: join(map(response, conversation => conversation?.conversationId), ','), limit: 99999 } }
    )

    const updatedResponse = map(response, conversation => ({
      ...conversation,
      members: find(responseMembers, item => item.conversationId === conversation?.conversationId).members
    }))

    await conversationService(database).createOrUpdate(updatedResponse)

    if (updatedResponse?.length) {
      const ids = updatedResponse?.map(item => item.id)
      await syncMessagesByIds(ids, totalOfPages, page)
    }

    if (page < totalOfPages) {
      // Search in background
      await syncConversations(page + 1)
      return
    }

    await syncHistoricService(database).updateSyncHistoric('conversation')
    await syncHistoricService(database).updateSyncHistoric('message')
  }

  async function syncContacts () {
    const syncDate = await syncHistoricService(database).getSyncHistoric('contact')
    const syncDateMoment = moment(syncDate, 'X').format()

    const { data: { result: response } } = await api.get(ROUTES.CONTACT.replace(':date', syncDateMoment))

    const registers = filter(flatMap(map(first(response), (category, index) => map(category, contact => ({
      ...contact,
      contactType: index,
      contactId: contact.id,
      profiles: JSON.stringify(contact.profile)
    })))), item => Boolean(item.id))

    await contactService(database).createOrUpdate(registers)
    await syncHistoricService(database).updateSyncHistoric('contact')
  }

  async function syncAuthenticatedUser (document, userId) {
    const authenticatedUser = await userService(database).getUserData()
    if(authenticatedUser?.userId !== userId) {
      await clearDatabase()
    }
    const user = await getUserByCpf({}, { document })
    await syncUser(user)
  }

  async function syncUser (user) {
    const { data: response } = await api.get('/v1/chat/obtain/parameters')

    await userService(database).saveUserData({
      ...user,
      ...response,
      userId: user.id,
      profilePhoto: user?.profilePhoto?.fileUrl
    })
  }

  async function sync() {
    await syncConversations()
    syncContacts()
  }

  async function clearDatabase() {
    try {
      await database.write(async () => {
        await database.get('conversation').query().destroyAllPermanently()
        await database.get('message').query().destroyAllPermanently()
        await database.get('contact').query().destroyAllPermanently()
        await database.get('user').query().destroyAllPermanently()
        await database.get('sync_pending').query().destroyAllPermanently()
        await database.get('sync_historic').query().destroyAllPermanently()
        await database.get('sync_file').query().destroyAllPermanently()
        await database.get('conversation_file').query().destroyAllPermanently()
        await database.get('profile_picture').query().destroyAllPermanently()
        await database.get('group').query().destroyAllPermanently()
        await database.get('group_member').query().destroyAllPermanently()
      })
    } catch(error) {
      console.log('[Database] Wipe Data Error: ', error)
    }
  }

  return {
    sync,
    syncConversations,
    syncContacts,
    syncUser,
    syncAuthenticatedUser,
    clearDatabase
  }
}

