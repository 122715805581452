export default ({ size, color = '#000' } = {}) => /* html */`
<svg width="${ size }" height="${ size }" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.4314 23.5893H33.1991C34.1081 23.5895 34.8953 22.9584 35.0927 22.071L36.1706 17.2219C36.298 16.6476 36.1579 16.0465 35.7898 15.5876C35.4217 15.1287 34.8653 14.8616 34.277 14.8613H31.3546C30.7663 14.8616 30.2099 15.1287 29.8418 15.5876C29.4737 16.0465 29.3336 16.6476 29.461 17.2219L30.5389 22.071C30.7362 22.958 31.5228 23.589 32.4314 23.5893V23.5893Z" stroke="#FFC80A" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29.4448 16.4609H18.2198C15.8208 16.4609 14.2215 18.8599 14.2215 21.2589V32.4541" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M35.2687 15.1337L35.8124 14.8619C36.244 14.6576 36.5734 14.286 36.7243 13.8331C36.8753 13.3801 36.8348 12.8852 36.6121 12.4629" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.5568 15.0325L30.2145 14.8619C29.783 14.6576 29.4536 14.286 29.3026 13.8331C29.1517 13.3801 29.1922 12.8852 29.4149 12.4629" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.0143 26.8574V32.455" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27.8156 32.4549V27.6569C27.8156 26.7737 27.0996 26.0576 26.2163 26.0576H19.0194C17.4201 26.0576 17.4201 27.6569 17.4201 28.4566V32.4549" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.8173 26.0576C23.8173 26.0576 23.0177 29.2562 20.6187 29.2562H17.4201" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.6187 29.2559V30.8552" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.8263 28.0723L23.8178 30.0543" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29.4618 17.2236L27.8156 18.8613" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.2183 16.8672L38.211 18.8599" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.38736 35.7888H16.4638" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.7365 35.7888H36.1741" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.38736 21.8751L23.4222 1" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.4632 1H23.4216V6.79865" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 29.6875C1 29.6875 4.6445 32.1172 4.6445 35.7617" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.28756 29.6875C8.28756 29.6875 4.64307 32.1172 4.64307 35.7617" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M37.445 35.764H1" stroke="${ color }" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`
