import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import storage from '@react-native-community/async-storage'

import { circularTransform } from '@smartcoop/stores'
/* ducks from @smartcoop/stores */
import absence from '@smartcoop/stores/absence'
import account from '@smartcoop/stores/account'
import animal from '@smartcoop/stores/animal'
import animalPregnancyActions from '@smartcoop/stores/animalPregnancyActions'
import animalPregnancyDiagnostics from '@smartcoop/stores/animalPregnancyDiagnostics'
import animalProtocol from '@smartcoop/stores/animalProtocol'
import authentication from '@smartcoop/stores/authentication'
import barter from '@smartcoop/stores/barter'
import challenges from '@smartcoop/stores/challenges'
import chat from '@smartcoop/stores/chat'
import costs from '@smartcoop/stores/costs'
import cropManagement from '@smartcoop/stores/cropManagement'
import dairyFarm from '@smartcoop/stores/dairyFarm'
import database from '@smartcoop/stores/database'
import familyGroup from '@smartcoop/stores/familyGroup'
import field from '@smartcoop/stores/field'
import fieldsMonitoration from '@smartcoop/stores/fieldsMonitoration'
import informatives from '@smartcoop/stores/informatives'
import insemination from '@smartcoop/stores/insemination'
import lot from '@smartcoop/stores/lot'
import machine from '@smartcoop/stores/machine'
import medicationControl from '@smartcoop/stores/medicationControl'
import messaging from '@smartcoop/stores/messaging'
import moduleReducer from '@smartcoop/stores/module'
import network from '@smartcoop/stores/network'
import nfe from '@smartcoop/stores/nfe'
import nonCompliance from '@smartcoop/stores/nonCompliance'
import operations from '@smartcoop/stores/operations'
import order from '@smartcoop/stores/order'
import organization from '@smartcoop/stores/organization'
import productGroup from '@smartcoop/stores/productGroup'
import products from '@smartcoop/stores/products'
import productsQuotation from '@smartcoop/stores/productsQuotation'
import productWall from '@smartcoop/stores/productWall'
import property from '@smartcoop/stores/property'
import salesOrders from '@smartcoop/stores/salesOrders'
import sateliteSvc from '@smartcoop/stores/sateliteSvc'
import securitiesMovement from '@smartcoop/stores/securitiesMovement'
import social from '@smartcoop/stores/social'
import supplierQuotation from '@smartcoop/stores/supplierQuotation'
import technical from '@smartcoop/stores/technical'
import user from '@smartcoop/stores/user'
import weatherForecast from '@smartcoop/stores/weatherForecast'
import weatherStations from '@smartcoop/stores/weatherStations'

const rootPersistConfig = {
  key: 'root',
  storage
}

const animalProtocolPersistConfig = {
  key: 'animalProtocol',
  storage
}

const medicationControlPersistConfig = {
  key: 'medicationControl',
  storage
}


const informativesPersistConfig = {
  key: 'informatives',
  storage
}

const authenticationPersistConfig = {
  key: 'authentication',
  storage
}

const fieldPersistConfig = {
  key: 'field',
  storage
}

const modulePersistConfig = {
  key: 'module',
  storage
}

const organizationPersistConfig = {
  key: 'organization',
  storage
}

const propertyPersistConfig = {
  key: 'property',
  storage
}

const userPersistConfig = {
  key: 'user',
  storage
}

const nfePersistConfig = {
  key: 'nfe',
  storage
}

const costsPersistConfig = {
  key: 'costs',
  storage
}

const inseminationPersistConfig = {
  key: 'insemination',
  storage
}

const orderPersistConfig = {
  key: 'order',
  storage
}

const productsQuotationPersistConfig = {
  key: 'productsQuotation',
  storage
}

const socialPersistConfig = {
  key: 'social',
  storage
}

const supplierQuotationPersistConfig = {
  key: 'supplierQuotation',
  storage
}

const accountPersistConfig = {
  key: 'account',
  storage
}

const weatherForecastPersistConfig = {
  key: 'weatherForecast',
  storage
}

const sateliteSvcPersistConfig = {
  key: 'sateliteSvc',
  storage
}

const machinePersistConfig = {
  key: 'machine',
  storage
}

const chatPersistConfig = {
  key: 'chat',
  storage
}

const salesOrdersPersistConfig = {
  key: 'salesOrders',
  storage
}

const messagingPersistConfig = {
  key: 'messaging',
  storage,
  tateReconciler: autoMergeLevel2,
  transforms: [circularTransform]
}

const productsPersistConfig = {
  key: 'products',
  storage
}

const lotPersistConfig = {
  key: 'lot',
  storage
}

const productGroupPersistConfig = {
  key: 'productGroup',
  storage
}

const dairyFarmPersistConfig = {
  key: 'dairyFarm',
  storage
}

const technicalPersistConfig = {
  key: 'technical',
  storage
}

const productWallPersistConfig = {
  key: 'productWall',
  storage
}

const barterPersistConfig = {
  key: 'barter',
  storage
}

const fieldsMonitorationPersistConfig = {
  key: 'fieldsMonitoration',
  storage
}

const nonCompliancePersistConfig = {
  key: 'nonCompliance',
  storage
}

const securitiesMovementPersistConfig = {
  key: 'securitiesMovement',
  storage
}

const cropManagementPersistConfig = {
  key: 'cropManagement',
  storage
}

const animalPersistConfig = {
  key: 'animal',
  storage
}

const weatherStationsPersistConfig = {
  key: 'weatherStations',
  storage
}

const animalPregnancyActionsPersistConfig = {
  key: 'animalPregnancyActions',
  storage
}

const animalPregnancyDiagnosticsPersistConfig = {
  key: 'animalPregnancyDiagnostics',
  storage
}

const familyGroupPersistConfig = {
  key: 'familyGroup',
  storage
}

const challengesPersistConfig = {
  key: 'challenges',
  storage
}
const operationsPersistConfig = {
  key: 'operations',
  storage
}

const networkPersistConfig = {
  key: 'network',
  storage
}

const absencePersistConfig = {
  key: 'absence',
  storage
}

const databasePersistConfig = {
  key: 'database',
  storage,
  tateReconciler: autoMergeLevel2,
  transforms: [circularTransform]
}

const appReducer = combineReducers({
  authentication: persistReducer(authenticationPersistConfig, authentication),
  informatives: persistReducer(informativesPersistConfig, informatives),
  field: persistReducer(fieldPersistConfig, field),
  module: persistReducer(modulePersistConfig, moduleReducer),
  organization: persistReducer(organizationPersistConfig, organization),
  property: persistReducer(propertyPersistConfig, property),
  user: persistReducer(userPersistConfig, user),
  nfe: persistReducer(nfePersistConfig, nfe),
  order: persistReducer(orderPersistConfig, order),
  productsQuotation: persistReducer(
    productsQuotationPersistConfig,
    productsQuotation
  ),
  medicationControl: persistReducer(medicationControlPersistConfig, medicationControl),
  account: persistReducer(accountPersistConfig, account),
  social: persistReducer(socialPersistConfig, social),
  supplierQuotation: persistReducer(
    supplierQuotationPersistConfig,
    supplierQuotation
  ),
  weatherForecast: persistReducer(
    weatherForecastPersistConfig,
    weatherForecast
  ),
  sateliteSvc: persistReducer(sateliteSvcPersistConfig, sateliteSvc),
  machine: persistReducer(machinePersistConfig, machine),
  chat: persistReducer(chatPersistConfig, chat),
  salesOrders: persistReducer(salesOrdersPersistConfig, salesOrders),
  messaging: persistReducer(messagingPersistConfig, messaging),
  productGroup: persistReducer(productGroupPersistConfig, productGroup),
  dairyFarm: persistReducer(dairyFarmPersistConfig, dairyFarm),
  technical: persistReducer(technicalPersistConfig, technical),
  insemination: persistReducer(inseminationPersistConfig, insemination),
  products: persistReducer(productsPersistConfig, products),
  productWall: persistReducer(productWallPersistConfig, productWall),
  barter: persistReducer(barterPersistConfig, barter),
  costs: persistReducer(costsPersistConfig, costs),
  fieldsMonitoration: persistReducer(
    fieldsMonitorationPersistConfig,
    fieldsMonitoration
  ),
  nonCompliance: persistReducer(nonCompliancePersistConfig, nonCompliance),
  securitiesMovement: persistReducer(
    securitiesMovementPersistConfig,
    securitiesMovement
  ),
  cropManagement: persistReducer(cropManagementPersistConfig, cropManagement),
  lot: persistReducer(lotPersistConfig, lot),
  animal: persistReducer(animalPersistConfig, animal),
  weatherStations: persistReducer(
    weatherStationsPersistConfig,
    weatherStations
  ),
  animalProtocol: persistReducer(
    animalProtocolPersistConfig,
    animalProtocol
  ),
  animalPregnancyActions: persistReducer(
    animalPregnancyActionsPersistConfig,
    animalPregnancyActions
  ),
  animalPregnancyDiagnostics: persistReducer(
    animalPregnancyDiagnosticsPersistConfig,
    animalPregnancyDiagnostics
  ),
  familyGroup: persistReducer(
    familyGroupPersistConfig,
    familyGroup
  ),
  challenges: persistReducer(
    challengesPersistConfig,
    challenges
  ),
  operations: persistReducer(
    operationsPersistConfig,
    operations
  ),
  network: persistReducer(
    networkPersistConfig,
    network
  ),
  absence: persistReducer(
    absencePersistConfig,
    absence
  ),
  database: persistReducer(
    databasePersistConfig,
    database
  )
})

const rootReducer = (state, action) => {
  if (action.type === 'EXIT_CURRENT_MODULE') {
    return appReducer(
      {
        authentication: state.authentication,
        messaging: state.messaging,
        organization: state.organization,
        user: state.user,
        nfe: state.nfe,
        module: state.module,
        property: state.property,
        challenges: state.challenges,
        absence: state.absence,
        operations: state.operations
      },
      action
    )
  }
  return appReducer(state, action)
}

export default persistReducer(rootPersistConfig, rootReducer)
