import styled from 'styled-components'

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
`

export const AddressContainer = styled.div`
  flex: 2;
  min-width: 400px;
`
export const WrapperCNPJContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 5px;
`
export const CNPJContainer = styled.div`
  min-width: 300px;
`

export const QuantityContainer = styled.div`
  justify-self: flex-end;
  min-width: 300px;
`
