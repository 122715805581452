import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerPev = ({ t }) => Yup.object().shape({
  registryDate: flow(
    date({ t }),
    required({ t })
  )(Yup.string()),

  reason: required({ t })(Yup.string()),

  able: Yup.boolean()
})

export default registerPev
