import axios from 'axios'

const api = axios.create({
  baseURL: 'https://servicodados.ibge.gov.br/api/v1/',
  headers: {
    'Cache-Control': 'no-cache'
  }
})

const searchCityById = async (municipio) => {
  try {
    const { data } = await api.get(`localidades/municipios/${ municipio }`)
    return data
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
}

const searchStates = async () => {
  try {
    const { data } = await api.get('localidades/estados')
    return data
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
}

const searchAllCities = async () => {
  try {
    const { data } = await api.get('localidades/municipios')
    return data
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
}

const searchCities = async (state) => {
  try {
    const { data } = await api.get(`localidades/estados/${ state }/municipios`)
    return data
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
}

export { searchStates, searchAllCities, searchCities, searchCityById }

export default api
