import React from 'react'

import PropTypes from 'prop-types'

import Modal from '@smartcoop/web-components/Modal'

const EbookModal = (props) => {
  const { id, open } = props
  return (
    <Modal
      id={ id }
      open={ open }
      fullScreen
      disableFullScreen
      fullWidth
      title="E-book"
    >
      <iframe title="E-book" style={ { height: '88vh', width: '96vw' } } src="https://smartcoop-email-images.s3.sa-east-1.amazonaws.com/e-book/ebook.pdf" />
    </Modal>
  )
}

EbookModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
}

export default EbookModal
