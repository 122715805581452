import api from '../api'

export const getLots = (params, { propertyId }) =>
  api.get(`/v1/property/${ propertyId }/lots`, { params })

export const createLot = (params, { propertyId }) =>
  api.post(`/v1/property/${ propertyId }/lots`, params)

export const updateLot =  (params, { propertyId, lotId }) =>
  api.patch(`/v1/property/${ propertyId }/lots/${ lotId }`, params)

export const deleteLot = ({ propertyId, lotId }) =>
  api.delete(`/v1/property/${ propertyId }/lots/${ lotId }`)
