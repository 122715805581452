/* eslint-disable no-unused-expressions */
import React, { useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import first from 'lodash/first'

import { Avatar } from '@material-ui/core'

import { profilePictureService } from '@smartcoop/database/services/profilePictureService'
import { database } from '@smartcoop/database/web-database'
import { useT } from '@smartcoop/i18n'
import { trash, user as userIcon } from '@smartcoop/icons'
import close from '@smartcoop/icons/svgs/close'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import CheckboxCard from '@smartcoop/web-components/FilteredCheckboxGroup/CheckboxCard'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import GoBackIconButton from '@smartcoop/web-components/IconButton/GoBackIconButton'
import VerticalDotsIconButton from '@smartcoop/web-components/IconButton/VerticalDotsIconButton'
import Popover from '@smartcoop/web-components/Popover'

import SearchChat from '../../../components/SearchChat/SearchChat'
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser'
import { ChatContext } from '../../../providers/ChatProvider'
import {
  ActionsWrapper,
  ButtonContainer,
  CheckboxContainer,
  CheckboxLabel,
  ContactInfoWrapper,
  HeaderContainer,
  NameText
} from './styles'

const ConversationListHeader = ({
  onSelectAllConversations,
  onOpenSelect,
  onCloseSelect,
  openSelect,
  allOptionsSelected,
  onDeleteConversations,
  profilePictures
}) => {
  const profilePicture = first(profilePictures)

  const t = useT()

  const {
    navigateToContacts,
    searchParams,
    setSearchParams
  } = useContext(ChatContext)

  const popoverRef = useRef()
  const localUser = useAuthenticatedUser()

  const history = useHistory()

  return (
    <div>
      <HeaderContainer>
        <ContactInfoWrapper>
          <GoBackIconButton
            onClick={ () => history.goBack() }
            iconColor={ colors.primary }
          />

          <Avatar size={ 55 } src={ profilePicture?.source } />
          <NameText>{localUser?.name}</NameText>
        </ContactInfoWrapper>

        <ActionsWrapper>
          {openSelect ? (
            <>
              <IconButton size="small" onClick={ onDeleteConversations }>
                <Icon icon={ trash } size={ 20 } />
              </IconButton>

              <IconButton size="small" onClick={ onCloseSelect }>
                <Icon icon={ close } size={ 20 } />
              </IconButton>
            </>
          ) : (
            <IconButton
              id="contacts-list"
              size="small"
              onClick={ navigateToContacts }
            >
              <Icon icon={ userIcon } size={ 20 } />
            </IconButton>
          )}

          <Popover
            ref={ popoverRef }
            popoverId="property-actions"
            Component={ VerticalDotsIconButton }
            ComponentProps={ {
              color: colors.black,
              edge: 'end',
              iconColor: colors.black,
              size: 'small'
            } }
          >
            <ButtonContainer>
              <Button
                color={ colors.white }
                id="select-chat"
                onClick={ () => {
                  popoverRef.current?.onClose()
                  onOpenSelect()
                } }
              >
                {t('delete conversation')}
              </Button>
            </ButtonContainer>
          </Popover>
        </ActionsWrapper>
      </HeaderContainer>
      <div>
        {
          openSelect ? (
            <CheckboxContainer>
              <CheckboxCard
                style={ { backgroundColor: 'transparent', margin: 0 } }
                onChange={ onSelectAllConversations }
                checked={ allOptionsSelected }
                value={ allOptionsSelected }
              />
              <CheckboxLabel onClick={ onSelectAllConversations }>
                { allOptionsSelected ? t('deselect all conversations') : t('select all conversations') }
              </CheckboxLabel>
            </CheckboxContainer>
          ) : (
            <SearchChat
              value={ searchParams }
              onChange={ (e) => setSearchParams(e?.target?.value) }
            />
          )
        }
      </div>
    </div>
  )
}

ConversationListHeader.propTypes = {
  onSelectAllConversations: PropTypes.func,
  onOpenSelect: PropTypes.func,
  onCloseSelect: PropTypes.func,
  onDeleteConversations: PropTypes.func,
  openSelect: PropTypes.bool,
  allOptionsSelected: PropTypes.bool,
  profilePictures: PropTypes.array
}

ConversationListHeader.defaultProps = {
  onSelectAllConversations: () => {},
  onOpenSelect: () => {},
  onCloseSelect: () => {},
  onDeleteConversations: () => {},
  openSelect: false,
  allOptionsSelected: false,
  profilePictures: []
}

const enhance = withObservables(['userId'], ({ userId }) => ({
  profilePictures: profilePictureService(database).observeProfilePictureByUserId(userId)
}))

const EnhancedConversationListHeader = enhance(ConversationListHeader)

export default EnhancedConversationListHeader
